import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import AgedStockComp from '../../../../../components/inventory/warehouse/agedStock/Listing';
import { fetchDataIfNeeded, fetchPaginationDataIfNeeded } from '../../../../../utils';
import { fetchAgedStock, getAgedStockCount, resetPaginatedData } from './action';

class AgedStockListing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageSize: 25,
      pageNumber: 1,
      isDataRendered: false
    };
  }

  componentDidMount() {
    const companyInfo = this.props.companyInfo || {};
    const payload = {
      relationshipId: companyInfo.relationshipId,
      pageNumber: 1,
      pageSize: 25
    };
    fetchPaginationDataIfNeeded('fetchAgedStock', 'agedStockList', this.props, payload);
    fetchDataIfNeeded('getAgedStockCount', 'agedStockCount', this.props, payload);
  }

  render() {
    return (
      <AgedStockComp {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
    )
  }
}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    agedStockList: state.agedStock.agedStockList,
    agedStockCount: state.agedStock.agedStockCount,
    permissions: state.auth.permissions,
    listLoading: state.common.listLoading,
    headerLoading: state.common.headerLoading
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchAgedStock,
  getAgedStockCount,
  resetPaginatedData
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AgedStockListing);