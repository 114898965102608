import { Button, Col, Row, Select, Switch, Tooltip } from "antd";
import React from "react";

import { TextBox } from "../../../../general/TextBox";
import { getPermissionData } from "../../../../../utils";
import { MODULE_CODED_VALUES } from "../../../../../static/constants";

const PromoHubComp = (props) => {
  const {permissions} = props;
  const primaryPerm = (permissions && permissions.primary) || [];
  const permissionsData = getPermissionData(primaryPerm, MODULE_CODED_VALUES.E_STORE_SETTING);
  const handelSave = ()=>{
    const offerValues ={
      isOfferShown: props?.isOfferShown,
        offerDescription: props?.offerDescription,
        link: `${props?.link}`,
    }
    let data = {
      relationshipId: props.companyInfo?.relationshipId,
      ecomSettingId: props.storeValues?.ecomSettingId,
      welcomeTray: JSON.stringify(offerValues),
  
  };
  
  
  props.savePromoHubOffers(data, props);
  }

  return (
    <div className="store-model-container cus-space">
      <div className="store-main-container">
        <div className="model-head ">
          <Row>
            <Col span={23}>
              <p className="heading">PromoHub Offers</p>
              <p className="sub-heading">
                Flexibility to set the type of Pop-up on first Screen.
              </p>
            </Col>

          </Row>

        </div>
        <div className="model-container">
          <Row style={{ marginTop: "10px" }} gutter={[16, 8]}>
            <Col span={12}><h2>Show Promo Offers on Website</h2></Col>
            <Col span={12}> <Switch

              checked={props.isOfferShown}
              onChange={() => {
                props.updateState({ isOfferShown: !(props.isOfferShown) })
              }}
            /></Col>

          </Row>

          {
            props.isOfferShown ? <div>
              <Row style={{ marginTop: "10px" }} gutter={[8, 8]} >
              
              <Col span={12}>

<TextBox
  placeholder="Enter Message"
  type="text"
  maxLength={500}
  value={props.offerDescription}
  onChange={(e) => {
    props.updateState({ offerDescription: e.target.value });
  }}
/> </Col>

<Col span={12}>

<TextBox
  placeholder="Enter Link"
  type="text"
 
  value={props.link}
  onChange={(e) => {
    props.updateState({ link: e.target.value });
  }}
/> 
<span>https://{props.link}</span>
</Col>

              </Row>





            
           
            </div> : ""
          }


        </div>
      </div>
      {
        <div className="footer-btn">
          <Button 
                                disabled={!(permissionsData.update)}

          onClick={()=>{
            handelSave();
          }} type="primary" >
            Submit
          </Button>
        </div>
      }

    </div>
  );
};

export default PromoHubComp;
