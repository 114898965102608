import React from "react";
import ChangePasswordComponent from "../../modal/modalBody/settings/ChangePassword";
import { Button, Col, Divider, Row } from "antd";
import { FormattedMessage } from "react-intl";
import { getCurrencySymbol } from "../../../utils";
import { TextBox } from "../../general/TextBox";
import { Dropdown, DropdownRef } from "../../general/Dropdown";
import { ErrorMsg } from "../../general/ErrorMessage";

const handleSubmit = (props) => {
  const { userInfo } = props;
  if (!props.submittedOnce) {
      props.updateState({ submittedOnce: true });
  }
  if (props.currentPassword && props.newPassword && props.confirmPassword && props.newPassword === props.confirmPassword ) {
      let payload = {
          'password': props.currentPassword,
          'newPassword': props.newPassword,
          'relationshipId': (props.companyInfo || {}).relationshipId,
          'userId': (props.userInfo || {}).userId,
          'email': (userInfo || {}).email || '',
          'isActive': 1,
          // 'dateCreated': '2020-03-13 16:02:59',
          'userCreated': (userInfo || {}).firstName + ((props.userInfo || {}).lastName || '')
      }

      const modalData = {
          modalBody: 
            <FormattedMessage
              id="modal.commmon.submit"
              defaultMessage=""
            />
          ,
          handleSubmit: () => {
            props.changePassword(payload);
            props.popModalFromStack();
          },
        };


        props.pushModalToStack(modalData);



      // props.changePassword(payload);
  } else {
      props.updateState({
          invalidCurrentPasswordClass: props.currentPassword ? '' : 'input-text-error',
          invalidNewPasswordClass: props.newPassword ? '' : 'input-text-error',
          invalidConfirmPasswordClass: (props.confirmPassword && props.confirmPassword === props.newPassword) ? '' : 'input-text-error'
      });

  }

}

function Security(props) {
  const { userInfo ,intl ,submittedOnce=false,invalidNewPasswordClass="",invalidConfirmPasswordClass=""} = props;
    
    return (
        <div className="account-setting">
<div className="store-model-container cus-space">
   
   <div className="store-main-container">
   

    <div>
    <div className="account-heading">
    <p className="heading">Change Password</p>
    <p className="sub-heading">
    Set a new password that meets security requirements.
    </p>

    </div>
    <div className="account-information">
   
    <Row gutter={[16,16]}>
      
      <Col span={12}>
        <div className="i-label">
            Current Password
            <span className="required">*</span>
        </div>
        <TextBox
          placeholder=" Enter Password"
          type="password"
          value={props.currentPassword||""}
          maxLength={50}
          countStyle={{ top: "40px" }}
          onChange ={(e)=>{
            const val = e.target.value;

            props.updateState({
              currentPassword: val,
            })
           
          }}
        />
        <ErrorMsg
   validator={() => { return !(submittedOnce && invalidNewPasswordClass) || !!props.currentPassword }}
message={intl.formatMessage(
     { id: 'modal.settings.changePassword.validation.currentPassword', defaultMessage: '' }
        )} />
      </Col>
     
    </Row>
    <Row gutter={[16,16]}>
      
      <Col span={12}>
        <div className="i-label">
            New Password
            <span className="required">*</span>
        </div>
        <TextBox
          placeholder=" Enter New Password"
          type="password"
          value={props.newPassword||""}
          maxLength={50}
          countStyle={{ top: "40px" }}
          onChange ={(e)=>{
            const val = e.target.value;

            props.updateState({
              newPassword: val,
            })
          }}
        />
         <ErrorMsg
                                                    validator={() => { return !(submittedOnce && invalidNewPasswordClass) || !!props.newPassword }}
                                                    message={intl.formatMessage(
                                                        { id: 'modal.settings.changePassword.validation.newPassword', defaultMessage: '' }
                                                    )} />
      </Col>
     
    </Row>
    <Row gutter={[16,16]}>
      
      <Col span={12}>
        <div className="i-label">
            Confirm Password
            <span className="required">*</span>
        </div>
        <TextBox
          placeholder="Enter new password again"
          type="password"
          value={props.confirmPassword||""}
          maxLength={50}
          countStyle={{ top: "40px" }}
          onChange ={(e)=>{
            const val = e.target.value;

            props.updateState({
              confirmPassword: val,
            })
          }}
        />
         <ErrorMsg
                                                    validator={() => { return !(submittedOnce && invalidConfirmPasswordClass) || !!props.confirmPassword }}
                                                    message={intl.formatMessage(
                                                        { id: 'modal.settings.changePassword.validation.confirmPassword', defaultMessage: '' }
                                                    )} />
      </Col>
     
    </Row>
   


    </div>
    <div className="">
     <Button type="primary" onClick={()=>{
      handleSubmit(props)
     }} >Save Changes</Button>
  </div>
    </div>
   </div>
   

   
   


</div>
        </div>
        
    )
}
export default Security;