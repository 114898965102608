import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { popModalFromStack, pushModalToStack } from './../../../../../actions/commonActions';
import ImageUpload from '../../../../../components/modal/modalBody/product/ImageUpload';
import { fetchInventoryItemDetails } from '../../../../product/detail/action';
import { imagePreview, uploadVariantImage } from './action';
import { BUCKET_NAME } from '../../../../../static/constants';

class ImageUploadContainer extends Component {
    constructor(props) {
        super(props);
        
    }
    componentDidMount() {
        const payload = {
            relationshipId: (this.props.companyInfo || {}).relationshipId,
            itemId: (this.props.product || {}).itemId,
           
        };
      
        this.props.fetchInventoryItemDetails(payload);
      
    }


    render() {
        return <ImageUpload {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
    }

}

const mapStateToProps = (state) => {
    return {
    productDetails: state.product.productDetails,
    variantImage: state.common.variantImage,
    detailLoading: state.common.detailLoading,
    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    popModalFromStack,
    fetchInventoryItemDetails,
    uploadVariantImage,
    pushModalToStack,
    imagePreview
    
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ImageUploadContainer);