import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import EmployeeComp from '../../../../components/company/EmployeeManagement/Employee';
import EmployeeDrawer from '../../../drawer/company/EmployeeManagement/Employee';
import { fetchUserPreferences, popModalFromStack, pushModalToStack, saveUserPrefrences } from '../../../../actions/commonActions';
import { fetchAllEmployeesByPage, getAllEmployeesCount } from './action';
import { getDepartment } from '../../EmployeeMeta/Department/action';
import { getDesignation } from '../../EmployeeMeta/Designation/action';
import { getJobRole } from '../../EmployeeMeta/JobRole/action';
import EmployeeFilterComp from '../../../../components/company/EmployeeManagement/Employee/employeeFilter';
import find from 'lodash.find';


class EmployeeContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedMenuItem : 0,
      pageNumber:1,
      pageSize: 25,
      searchedText: "",
      txColumns: [],
      txColumnSetting: {},
      txPreferences: { txType: "employee", templateName: "listing" },
      txMandatoryColumns: ['employeeName',  'contact'],
      txAvailableColumn: {
        employeeId:true,
        employeeName: true,
        contact: true,
        department: true,
        designation: true,
        jobRole: true,
        team: true,
        employeeType:true,
        hierarchy:true
      
    },
    }
    };
  

  componentDidMount(props) {
    const payload = {
      relationshipId: (this.props.companyInfo || {}).relationshipId,
      pageNumber: 1,
      pageSize: 25,
      userId: (this.props.userInfo || {}).userId,
    }
    this.props.fetchAllEmployeesByPage(payload);
    this.props.getAllEmployeesCount(payload);
    this.props.getDepartment(payload);
    this.props.getDesignation(payload);
    this.props.getJobRole(payload);
    this.props.fetchUserPreferences(payload);


  }
componentWillReceiveProps(props){
  if (props.allPreferences && props.allPreferences.length) {
    let txPreferences = find(props.allPreferences, { txType: "employee", templateName: "listing" });
    if (txPreferences) {
      let txColumns = txPreferences.tablePreference ? ((JSON.parse(txPreferences.tablePreference)) || {}).columnList || [] : [];
      txColumns = txColumns.length? txColumns: (this.state.txMandatoryColumns || []);
      const txColumnSetting = txPreferences.tablePreference ? ((JSON.parse(txPreferences.tablePreference)) || {}).columnSetting || {} : {};
      const isColumnFitTable = (Object.keys(txColumnSetting).length || this.state.isColumnFitTable === false) ? false: true;
      let gridPreference = txPreferences.gridPreference
      this.setState({
        gridPreference,
        isColumnFitTable,
        preferenceId: txPreferences.preferenceId,
        txColumns,
        txColumnSetting,
        txPreferences
      });
    }
    else {
      this.setState({
        txColumns: (this.state.txMandatoryColumns || [])
      });
    }
  }
}
  render() {
    return <>
      <EmployeeComp {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
      {this.state.employeeDrawer && <EmployeeDrawer {...this.state} {...this.props} updateState={(data) => { this.setState(data) }} />}
      {this.state.employeeFilterDrawerVisible && <EmployeeFilterComp {...this.state} {...this.props} updateState={(data) => { this.setState(data) }} />}

    </>
;
  }

}

const mapStateToProps = (state) => {
  return {

    companyInfo: state.common.companyInfo,
    userInfo: state.common.userInfo,
    listLoading: state.common.listLoading,
    drawerLoading:state.common.drawerLoading,
    headerLoading:state.common.headerLoading,
    employeeList: state.employee.employeeList,
    employeesCount: state.employee.employeesCount,
    departmentList: state.employee.departmentList,
    designationList: state.employee.designationList,
    jobRoleList: state.employee.jobRoleList,
    allPreferences: state.common.allPreferences,
    permissions: state.auth.permissions,


  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  pushModalToStack,
  popModalFromStack,
  fetchAllEmployeesByPage,
  getAllEmployeesCount,
  getDepartment,
  getDesignation,
  getJobRole,
  saveUserPrefrences,
  fetchUserPreferences
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeContainer);
