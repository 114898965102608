import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import AddShippingCountryComponent from "../../../../../../components/modal/modalBody/settings/shipping/AddCountry";
import { fetchDataIfNeeded } from "../../../../../../utils";
import { getAllCountries } from '../../../../../settings/shipping/action';

class AddShippingCountry extends Component {

    componentDidMount() {
        const payload = { relationshipId: (this.props.companyInfo || {}).relationshipId };
        // fetchDataIfNeeded("getAllCountries", "countries", this.props, payload);
        this.props.getAllCountries(payload)
    }

    render() {
        return (
            <AddShippingCountryComponent {...this.state} {...this.props} updateState={(data) => {
                this.setState(data);
            }} />
        )
    }

}

const mapStateToProps = (state) => {
    return {
        companyInfo: state.common.companyInfo,
        countries: state.taxSettingReducer.countries,
        permissions: state.auth.permissions,

    }
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    getAllCountries
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AddShippingCountry);
