import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import DailyAttendance from '../../../../components/company/AttendanceManagement/DailyAttendance';


class DailyAttendanceContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedMenuItem : 0
    }
    };
  

  componentDidMount(props) {
    const payload = {
      relationshipId: (this.props.companyInfo || {}).relationshipId,
     
    }
   
  }

  componentWillReceiveProps(props) {
   
  }

  render() {
    return <>
    <DailyAttendance {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
    
    </>
;
  }

}

const mapStateToProps = (state) => {
  return {

    companyInfo: state.common.companyInfo,
    listLoading: state.common.listLoading,
    drawerLoading:state.common.drawerLoading,
    headerLoading:state.common.headerLoading,
    permissions: state.auth.permissions,
   
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(DailyAttendanceContainer);
