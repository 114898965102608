import axios from 'axios';
import config from '../../../config/environmentConfig';
import { COMMON_ACTIONS, FINANCIAL_YEAR_ACTIONS } from '../../../static/constants'
import { showToasterMessage } from '../../../utils';
const { lang } = require('../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');

export const fetchFinancialYears = (payload, history) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LIST_LOADER });
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/financialYear/getFinancialYear?relationshipId=${payload.relationshipId}`)
      .then(res => {
        dispatch({ type: FINANCIAL_YEAR_ACTIONS.FINANCIAL_YEAR_LIST, data: res.data });
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
      }).finally(() => {
        dispatch({
          type: COMMON_ACTIONS.HIDE_LIST_LOADER,
        });
      })
  }
}


export const deleteFY = (payload, props) => {
    // const requestObj = {
    //   financialYearId: payload.financialYearId,
    //   relationshipId: payload.relationshipId,
    // }
    return dispatch => {
      dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
      return axios
        .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/financialYear/delete?relationshipId=${payload.relationshipId}`, payload)
        .then(res => {
          dispatch({ type: FINANCIAL_YEAR_ACTIONS.FINANCIAL_YEAR_LIST_DELETION, data: { id: payload.fyId } })
          ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          showToasterMessage({
            description: (res || {}).message || 'Deleted successfully.',
            messageType: 'success'
          })
          props.fetchFinancialYears(payload);
        })
        
        .catch((err = {}) => {
          ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          if (err.__isRetryRequest) {
            err.actionToCall = FINANCIAL_YEAR_ACTIONS.FINANCIAL_YEAR_LIST_DELETION;
          } else {
            showToasterMessage({
              description: lang[((err.response || {}).data || {}).message] || 'Some error occurred'
            })
          }
        })
    }
}