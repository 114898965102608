import axios from 'axios';
import config from '../../config/environmentConfig';
import { COMMON_ACTIONS, DASHBOARD_ACTION_LIST, CUSTOMER_PAYMENT_ACTION_LIST, CUSTOMER_ACTION_LIST, EXPENSE_ACTION_LIST } from '../../static/constants'
import { showToasterMessage, getFinancialYear } from '../../utils';
const { lang } = require('../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');

export const fetchSalesData = (payload, history) => {
  let FY = getFinancialYear(payload.companyInfo);
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
     // .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/customerTransaction/getSalesData?relationshipId=${payload.relationshipId}&period=${payload.selectedRange}&startDate=${FY.fyStartDate}&endDate=${FY.fyEndDate}`)
     .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/customerTransaction/getSalesChartData?relationshipId=${payload.relationshipId}&period=${payload.selectedRange}&startDate=${FY.fyStartDate}&endDate=${FY.fyEndDate}`)
     .then(res => {
        dispatch({ type: DASHBOARD_ACTION_LIST.SALES_DATA_LIST, data: res.data });
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
      })
  }
}


export const fetchPurchaseData = (payload, history) => {
  let FY = getFinancialYear(payload.companyInfo);
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      //.get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/supplierTransaction/getPurchaseData?relationshipId=${payload.relationshipId}&period=${payload.selectedRange}&startDate=${FY.fyStartDate}&endDate=${FY.fyEndDate}`)
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/supplierTransaction/getPurchaseChartData?relationshipId=${payload.relationshipId}&period=${payload.selectedRange}&startDate=${FY.fyStartDate}&endDate=${FY.fyEndDate}`)
      .then(res => {
        dispatch({ type: DASHBOARD_ACTION_LIST.PURCHASE_DATA_LIST, data: res.data });
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
      })
  }
}



export const fetchTopCustomerBySales = (payload, history) => {
  let FY = getFinancialYear(payload.companyInfo);
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/customerTransaction/getTopCustomersBySales?relationshipId=${payload.relationshipId}&pageNumber=0&pageSize=100&startDate=${FY.fyStartDate}&endDate=${FY.fyEndDate}`)
      .then(res => {
        dispatch({ type: DASHBOARD_ACTION_LIST.TOP_SALES_CUSTOMER, data: res.data });
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        // showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
      })
  }
}




export const fetchTopSupplierByPurchase = (payload, history) => {
  let FY = getFinancialYear(payload.companyInfo);
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/supplierTransaction/getTopSuppliersByPurchase?relationshipId=${payload.relationshipId}&period=${payload.selectedRange}&startDate=${FY.fyStartDate}&endDate=${FY.fyEndDate}`)
      .then(res => {
        dispatch({ type: DASHBOARD_ACTION_LIST.TOP_PURCHASE_SUPPLIER, data: res.data });
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        // showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
      })
  }
}




export const fetchTopCustomerByPayment = (payload, history) => {
  let FY = getFinancialYear(payload.companyInfo);
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/customerTransaction/getTopCustomersByPayment?relationshipId=${payload.relationshipId}&startDate=${FY.fyStartDate}&endDate=${FY.fyEndDate}`)
      .then(res => {
        dispatch({ type: DASHBOARD_ACTION_LIST.TOP_PAYMENT_CUSTOMER, data: res.data });
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        // showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
      })
  }
}


export const fetchTopSupplierByPayment = (payload, history) => {
  let FY = getFinancialYear(payload.companyInfo);
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/supplierTransaction/getTopSuppliersByPayment?relationshipId=${payload.relationshipId}&startDate=${FY.fyStartDate}&endDate=${FY.fyEndDate}`)
      .then(res => {
        dispatch({ type: DASHBOARD_ACTION_LIST.TOP_PAYMENT_SUPPLIER, data: res.data });
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        // showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
      })
  }
}



export const fetchFastestMovingProducts = (payload, history) => {
  let FY = getFinancialYear(payload.companyInfo);
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/inventoryItem/getFasterMovingProducts?relationshipId=${payload.relationshipId}&startDate=${FY.fyStartDate}&endDate=${FY.fyEndDate}`)
      .then(res => {
        dispatch({ type: DASHBOARD_ACTION_LIST.FASTEST_MOVING_PRODUCTS, data: res.data });
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
      })
  }
}

export const fetchCustomerUnpaidInvoices = (payload, history) => {
  let FY = getFinancialYear(payload.companyInfo);
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LIST_LOADER });
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/customerInvoice/getAllUnpaidInvoices?relationshipId=${payload.relationshipId}&pageSize=${payload.pageSize}&pageNumber=${(payload.pageNumber||1)-1}&startDate=${FY.fyStartDate}&endDate=${FY.fyEndDate}`)
      .then(res => {
        const data = {
          pageNo: payload.pageNumber,
          list: res.data
        }
        dispatch({ type: DASHBOARD_ACTION_LIST.CUSTOMER_UNPAID_INVOICES, data });
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        } else {
          err.actionToCall = DASHBOARD_ACTION_LIST.CUSTOMER_UNPAID_INVOICES;
          err.data = {
            pageNo: payload.pageNumber,
            list: []
          };
        }
      }).finally(()=>{
        dispatch({ type: COMMON_ACTIONS.HIDE_LIST_LOADER });
      })
  }
}

export const fetchSupplierUnpaidInvoices = (payload, history) => {
  let FY = getFinancialYear(payload.companyInfo);
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/supplierInvoice/getAllUnpaidInvoices?&relationshipId=${payload.relationshipId}&pageSize=${payload.pageSize}&pageNumber=${payload.pageNumber}&startDate=${FY.fyStartDate}&endDate=${FY.fyEndDate}`)
      .then(res => {
        const data = {
          pageNo: payload.pageNumber,
          list: res.data
        }
        dispatch({ type: DASHBOARD_ACTION_LIST.SUPPLIER_UNPAID_INVOICES, data });
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        } else {
          err.actionToCall = DASHBOARD_ACTION_LIST.SUPPLIER_UNPAID_INVOICES;
          err.data = {
            pageNo: payload.pageNumber,
            list: []
          };
        }
      })
  }
}

export const fetchCashflow = (payload) => {
  return dispatch => {
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/customerPayment/getPayment?relationshipId=${payload.relationshipId}`)
      .then(res => {
        const { data } = res;
        dispatch({ type: CUSTOMER_PAYMENT_ACTION_LIST.CUSTOMER_PAYMENT_CASHFLOW, data });
      })
      .catch((err) => {
        showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
      })
  }
}


export const fetchTotalExpense = (payload) => {
  return dispatch => {
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/relationshipExpense/getTotalExpenseAmount?relationshipId=${payload.relationshipId}`)
      .then(res => {
        const { data } = res;
        dispatch({ type: EXPENSE_ACTION_LIST.RELATIONSHIP_EXPENSE, data });
      })
      .catch((err) => {
        showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
      })
  }
}

export const fetchRecentPayment = (payload) => {
  return dispatch => {
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/customerPayment/get10RecentPayments?relationshipId=${payload.relationshipId}`)
      .then(res => {
        const { data } = res
        dispatch({ type: CUSTOMER_PAYMENT_ACTION_LIST.RECENT_10_PAYMENTS, data });
      })
      .catch((err) => {
        showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
      })
  }
}



export const fetchOrgMember = (payload) => {
  return dispatch => {
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/customers/countOrgMembers?relationshipId=${payload.relationshipId}`)
      .then(res => {
        const { data } = res;
        dispatch({ type: CUSTOMER_ACTION_LIST.MEMBER_COUNT, data });
      })
      .catch((err) => {
        showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
      })
  }
}


// export const getAllRelExpenses =  (payload) => {
//   return dispatch => {
//      return axios
//       .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/customers/countOrgMembers?relationshipId=${payload.relationshipId}`)
//       .then(res => {
//         const { data } = res;
//         dispatch({ type: CUSTOMER_ACTION_LIST.MEMBER_COUNT, data });
//       })
//       .catch((err) => {
//         showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
//       })
//   }
// }