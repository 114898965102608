import React, { Fragment, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import PageBreadcrumb from "../../../PageBreadcrumb";
import { Button, Card, Col, Dropdown, Menu, Pagination, Row, Skeleton, Switch } from "antd";
import { CONSTANTS, ICONS, MODULE_CODED_VALUES } from "../../../../static/constants";
import DropdownAction from "antd/lib/dropdown";
import storeDef from "../../../../assets/images/store-def.jpg";
import moment from "moment-timezone";
import './index.scss'
import { getAWSFileURL, getMomentDateForUIReadOnly, getPermissionData } from "../../../../utils";
import PageHeader from "../../../Common/pageHeader";
const truncateContent = (content, wordLimit) => {
  const tempDiv = document.createElement('div');
  tempDiv.innerHTML = content;
  const textContent = tempDiv.textContent || tempDiv.innerText || "";
  const words = textContent.split(' ');
  if (words.length > wordLimit) {
    return words.slice(0, wordLimit).join(' ') + '...';
  }
  return textContent;
}


const BlogList = (props) => {
  const { permissions, companyInfo, updateState } = props;
  const primaryPerm = (permissions && permissions.primary) || [];
  const permissionData = useMemo(() => {
    return getPermissionData(primaryPerm, MODULE_CODED_VALUES.E_STORE_BLOGS_MGMT);
  }, [])
  const breadCrumbList = [
    {
      name: (
        <FormattedMessage id="sidebar.menuItem.eStores" defaultMessage="" />
      ),
    },
    {
      name: (
        <FormattedMessage id="sidebar.menuItem.blogs" defaultMessage="" />
      ),
    },

  ];


  const showTotal = (total) => {
    return (
      props.intl.formatMessage({
        id: "pagination.text.total",
        defaultMessage: "",
      })
      +
      ` ${total} ` +
      props.intl.formatMessage({
        id: "pagination.text.items",
        defaultMessage: "",
      })
    );
  };
  const loadPageData = (pageNumber, pageSize) => {
    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      pageNumber: pageNumber,
      pageSize: pageSize,
    };
    props.fetchAllBlogsList(payload);
  };

  const handlePageSizeChange = (pageNumber, pageSize) => {

    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      pageNumber: pageNumber,
      pageSize: pageSize,
    };
    props.fetchAllBlogsList(payload);
    props.updateState({ pageSize: pageSize, pageNumber: pageNumber });
  };
  const actionMenu = (blog) => (
    <Menu className="row-action-dropdown">
      <Menu.Item key="0" value="title" disabled={true}>
        Actions
      </Menu.Item>
      <Menu.Divider />
      {/* <Menu.Item key="1" 
      onClick={() => handleViewClick(blog)}>
        <i className="fi fi-tr-overview"></i>
        View
      </Menu.Item> */}
      {
        permissionData?.update ? <Menu.Item key="2"
          onClick={() => {
            props.updateState({ blogCreateDrawerVisible: true, isUpdate: true, blogData: (blog || {}) });

          }}>
          <i className="fi fi-rr-edit"></i>
          Edit
        </Menu.Item> : ""
      }
      {
        permissionData?.delete ? <Menu.Item key="3" onClick={() => {
          const data = {
            title: "Confirmation",


            modalBody: <div>Are you sure you want to delete?</div>,
            handleSubmit: () => {
              props.deleteBlog(blog, props)
            },
          };
          props.pushModalToStack(data)


        }}

        >
          <i className="fi fi-rr-trash"></i>
          Delete
        </Menu.Item> : ""
      }

    </Menu>
  );

  const blogListData = ((props.blogPostList)[props.pageNumber || 1] || []);

  return (
    <>

      <PageHeader {...props}
        pageName="sidebar.menuItem.blogs"
        breadCrumbList={breadCrumbList}
        canCreate={true}
        onCreate={() => {
          props.updateState({ blogCreateDrawerVisible: true, isUpdate: false });
        }}
        moduleCode={MODULE_CODED_VALUES.E_STORE_BLOGS_MGMT}
      />

      <div className="view-container">
        <div className="view-container-actions">
          <div className="left-actions">
            <div className="table-heading">Blog List</div>


            <div className="vertical-sep" />
            <div>{props.blogListCount || 0}</div>


          </div>


        </div>
        <Skeleton loading={props.isLoading}
          active
          paragraph={{ rows: 12 }}
        >
          <div className='table-container'>
            <table>
              <thead>
                <tr>
                  <th className='text-left'>Title</th>
                  <th className='text-left'>Category</th>
                  <th className="text-center">Images(Thumbnails)</th>
                  <th width={"30%"}>Description</th>
                  <th>Date Created</th>
                  <th>Status</th>
                  {
                    (permissionData?.update || permissionData?.delete) ? <th>Action</th> : ""
                  }

                </tr>
              </thead>
              <tbody>
                {(blogListData || []).map(blog => {
                  const image = getAWSFileURL(blog?.awsKeyName, props.companyInfo.bucketName, blog?.folderName)

                  return (
                    <tr key={blog.blogMasterId}>
                      <td>{blog.title || <div className="empty-data-box" />}</td>
                      <td>{blog.categoryName || <div className="empty-data-box" />}</td>
                      <td className="text-center">
                        {image ? (
                          <img

                            src={image}
                            alt="BlogThumbnailImage"
                            width={40}
                            height={40}

                          />
                        ) : (
                          <img
                            src={storeDef}
                            alt="No Thumbnail Available"
                            width={40}
                            height={40}
                          />
                        )}
                      </td>
                      <td>
                        {
                          blog.content ? <div
                            dangerouslySetInnerHTML={{ __html: truncateContent(blog.content, 20) }}
                          /> : <div className="empty-data-box" />
                        }

                      </td>
                      <td> {blog.dateCreated
                        ? getMomentDateForUIReadOnly({
                          date: blog.dateCreated,
                        })
                        : <div className="empty-data-box" />}


                      </td>
                      <td className="switch-status">
                        <Switch
                          disabled={!(permissionData.update)}
                          checked={blog?.isPublished}
                          onChange={(checked) => {
                            const data = {
                              title: "Confirmation",

                              // modalType: MODAL_TYPE.ACTIVATION_MODAL,
                              modalBody: <div>Are you sure you want change the status?</div>,
                              handleSubmit: () => {
                                props.updateBlogPublishStatus({ blogId: blog?.blogMasterId, isPublished: checked ? 1 : 0 }, props)

                              },
                            };

                            props.pushModalToStack(data)
                          }}
                        />
                      </td>
                      {
                        (permissionData?.update || permissionData?.delete) ? <td className='text-center'>
                          <div className="action-icon">
                            <DropdownAction
                              overlay={actionMenu(blog)}
                              trigger={["click"]}
                            >
                              <i className={ICONS.MORE} />
                            </DropdownAction>
                          </div>

                        </td> : ""
                      }

                    </tr>
                  )
                }

                )}
              </tbody>
            </table>
          </div>

        </Skeleton>
        {/* <div className="footer">
          <div className="f-left"></div>
          <div className="f-right">
            <Pagination
              size="small"
              total={props.blogListCount}
              showTotal={showTotal}
              defaultPageSize={props.pageSize}
              showSizeChanger pageSizeOptions={[10, 25, 50, 100, 200]}
              onChange={(pageNumber, pageSize) => {
                loadPageData(pageNumber, pageSize);

                updateState({ pageSize: pageSize, pageNumber: pageNumber })
              }}
              onShowSizeChange={(pageNumber, pageSize) => {
                handlePageSizeChange(pageNumber || 1, pageSize, props)
              }}
            />
          </div>
        </div> */}
      </div>
    </>
  );

};

export default BlogList;
