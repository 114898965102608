import React, {useRef, useEffect,  useState} from 'react';
import { Link } from 'react-router-dom';
// import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Checkbox, Button, Row } from 'antd';
import { injectIntl, FormattedMessage } from 'react-intl';
import * as find from 'lodash.find';
//import * as filter from 'lodash.filter';
import { Dropdown } from '../../../../general/Dropdown';
import { DropdownPriceList } from '../../../../general/MarkupDropdown';
import { addDaysToDate } from '../../../../../utils';
import { MODAL_TYPE, MODULE_CODED_VALUES } from '../../../../../static/constants';
import LoadingIcon from '../../../../../assets/images/loading.gif';
import StandardPurchaseOrder from "../../../../../containers/supplier/invoice/StandardPurchaseInvoice"
import MultiCurrencyPurchaseOrder from "../../../../../containers/supplier/invoice/MultiCurrencyPurchaseInvoice"
import { DetailDropdown } from '../../../../general/DetailDropdown';

const HeaderAction = (props) => {
    const [templateDropdown, updateTemplateDropdown] = useState(false);
    const {
        intl,
        supplierSummary,
        pageNumber,
        supplier,
        suppliers,
        updateState,
        priceListName,
        priceList,
        showModal,
        companyInfo,
    } = props;
   

    const handleNewPriceListAddition = (props, payload) => {
        const formData = payload.formData;
        formData.relationshipId = companyInfo.relationshipId;
        const data = {
            title: <FormattedMessage id='addItem.text.priceList' defaultMessage='' />,
            formData,
            hideFooter: true,
            modalData: {
                modalType: MODAL_TYPE.PRICE_LIST,
                data: payload,

            },
            handleSubmit: (formData = {}) => {
                data.formData.submittedOnce = true;
                showModal(data);
            }
        };
        showModal(data);
    }

    const deleteClickHandler = (props, modalType, payload) => {
        const modalBody =
            <FormattedMessage
                id='deleteItem.confirmation'
                defaultMessage={``}
                values={{ value: payload.text }}
            />;

        let deleteFunc;

        switch (modalType) {
            case MODAL_TYPE.PRICE_LIST: {
                deleteFunc = props.deletePriceList;
                break;
            }
            default: {
                deleteFunc = () => { }
            }
        }
        const modalData = {
            modalBody,
            handleSubmit: () => { deleteFunc({ id: payload.id, relationshipId: companyInfo.relationshipId }) },
        };
        showModal(modalData);
    }


    const useOuterClick = (callback) => {
        const innerRef = useRef();
        const callbackRef = useRef();
    
        // set current callback in ref, before second useEffect uses it
        useEffect(() => { // useEffect wrapper to be safe for concurrent mode
            callbackRef.current = callback;
        });
    
        useEffect(() => {
            document.addEventListener("click", handleClick);
            return () => document.removeEventListener("click", handleClick);
    
            // read most recent callback and innerRef dom node from refs
            function handleClick(e) {
                if (
                    innerRef.current &&
                    callbackRef.current &&
                    !innerRef.current.contains(e.target)
                ) {
                    callbackRef.current(e);
                }
            }
        }, []); // no need for callback + innerRef dep
    
        return innerRef; // return ref; client can omit `useRef`
    }
    
    const innerRef = useOuterClick(e => {
       updateTemplateDropdown(!templateDropdown)
    });

    const getFormatedNextTxNumber = (props) => {
        // const { supplier } = props;
        // if (!supplier || !supplier.supplierId) {
        //     return <span>{props.nextTxNumber}</span>;
        // }
        // return <span>{props.nextTxNumber.replace('INV-00', 'INV-' + (supplier.supplierId < 10 ? ('0' + supplier.supplierId) : supplier.supplierId))}</span>
        return <span>{props.nextTxNumber}</span>;
    }

    return (
        <> 
            <div className='left-itms'>
                <ul>
                    <li className='title'>
                    Purchase Invoice
                    </li>
                    <li className='sep' />
                    <li>
                    {/* <FormattedMessage id='supplier.purchaseinvoice.form.invoiceNumber.label' defaultMessage='' /> &nbsp; - &nbsp;*/}
                            Invoice # &nbsp; - &nbsp;
                    {props.nextTxNumber ?
                        <span>{getFormatedNextTxNumber(props)}</span>
                        //<span>{this.props.nextTxNumber.replace('SO-00', 'SO-'+(this.props.customer || {}).customerId) )}</span>
                        :
                        <img src={LoadingIcon} alt="Invoice" style={{ 'height': '50px' }} />
                    }
                    </li>
                </ul>
            </div>
            <div className="right-itms">
                {!props.isIDConversion ?
                    <Checkbox checked={props.isCashPurchase} onChange={(e) =>
                        props.updateState({
                            isCashPurchase: e.target.checked
                        })
                    } /> : ''
                }
            
                {!props.isIDConversion ?
                    <span className="pr10">
                        <FormattedMessage id='supplier.purchaseinvoice.form.cashPurchase.label' defaultMessage='' />
                    </span>: ''
                }
                    <DropdownPriceList
                    style={{ width: "200px" }}
                        items={priceList}
                        valueKeyName='priceListName'
                        optionKeyName='priceListId'
                        value={priceListName}
                        canAddNew={true}
                        canDelete={true}
                        allowClear={true}
                        deleteClickHandler={(payload) => {
                            deleteClickHandler(props, MODAL_TYPE.PRICE_LIST, payload);
                        }}
                        onAddNewClick={(payload) => {
                            payload.formData = {
                                priceListName: payload.textEntered,
                                submittedOnce: false
                            }
                            handleNewPriceListAddition(props, payload, MODAL_TYPE.PRICE_LIST)
                        }}
                        placeholder={intl.formatMessage({
                            id: 'supplier.purchaseinvoice.form.priceListDropdown.placeholder', defaultMessage: ''
                        })}
                        onSelect={
                            (priceListId) => {
                            const priceListData = find(priceList, {priceListId }) || {};
                            updateState({ toUpdatePriceList: true, priceListId, priceListName: priceListData.priceListName, priceListType: priceListData.type, priceListPercentage: priceListData.percentage });
                            }
                        }
                        onClear={() => {
                            updateState({toUpdatePriceList: true, priceListId: undefined, priceListName: undefined, priceListType: undefined, priceListPercentage: undefined})
                        }}
                    />


                    {(!props.isPoConversionToInvoice && !props.isIDConversion) && 
                    <DetailDropdown
                        items={suppliers[pageNumber] || []}
                        sortItems={true}
                        style={{ width: "200px" }}
                        valueKeyName='supplierStoreName'
                        optionKeyName='supplierId'
                        className="detailDropdown"
                        value={(supplier || {}).name}
                        moduleName={MODULE_CODED_VALUES.SUPPLIERS}
                        placeholder={intl.formatMessage({
                            id: 'supplier.purchaseinvoice.form.supplierName.placeholder', defaultMessage: ''
                        })}
                        onSearch={(searchedText) => {
                            props.fetchSuppliers({
                                searchedText: searchedText,
                                pageNumber: 1,
                                companyInfo:props.companyInfo,
                                hideSpinner: true,
                                pageSize: 100,
                                relationshipId: (props.companyInfo || {}).relationshipId,filterData:true
                            })
                        }}
                        canAddNew={true}
                        canDelete={true}
                        allowClear={true}
                        onClear={() => {
                            updateState({
                                boContactList: undefined,
                                selectedContact: undefined,
                                supplier: undefined,
                                supplierPaymentTerm: undefined,
                                supplierBillingAddress: undefined,
                                placeOfSupply: undefined,
                                invoiceDueDate: undefined,
                            });
                        }}
                        deleteClickHandler={(payload) => {
                            props.deleteSuppliers({ groupSupplierIds: [payload.id], relationshipId: (props.companyInfo || {}).relationshipId })
                        }}
                        onAddNewClick={(payload) => {
                            props.updateHeaderState({
                                supplier: {
                                    supplierDrawerVisible: true,
                                    supplierName: payload.textEntered,
                                }
                            })
                        }}
                        onSelect={
                            (supplierId, optionObj) => {
                                const supplierObj = find(supplierSummary[pageNumber], { supplierId: supplierId }) || {};
                                const supplierPaymentTerm = find(props.paymentTerms, { paymentTermId: Number(supplierObj.paymentTermId) }) || {};
                                let numberOfDays = supplierPaymentTerm.numberOfDays;
                                updateState({
                                    supplierPaymentTerm,
                                    invoiceDueDate: numberOfDays ? addDaysToDate((props.invoiceDate || new Date()), numberOfDays) : null,
                                    stateSupplierId: supplierId
                                });
                                props.fetchSupplierDetailsBySupplierId({
                                    relationshipId: companyInfo.relationshipId,
                                    supplierId: supplierId,
                                    pageNumber: props.pageNumber || 1,
                                    pageSize: props.pageSize || 100
                                });
                            }
                        }
                    />}
                    <Button  style={{height:'38px'}} onClick={() => {
                        props.updateState({
                            ledgerDrawerVisible: true
                        })
                    }} >
                        <i className='fa fa-book fa-lg'> </i>
                    </Button>
                    <div className="currency-converter-modal">
                        <span  style={{height:'38px'}}  className="ant-btn ant-btn-icon-only icon" onClick={() => updateTemplateDropdown(!templateDropdown)}>
                            <i className="fa fa-exchange" />
                        </span>

                        {templateDropdown &&
                            <div ref={innerRef} className="content">
                                <Row className="mb10">
                                    <Link onClick={() => {
                                        updateTemplateDropdown(false)
                                        props.popModalFromStack()
                                        props.pushModalToStack({
                                            modalBody: <StandardPurchaseOrder {...props} type = 'new'/>,
                                            width: '100%',
                                            hideTitle: true,
                                            hideFooter: true,
                                            keyboard: false ,
                                            wrapClassName: 'new-transaction-wrapper'
                                        })
                                    }
                                }
                                        // to="/admin/purchase-invoice"
                                    ><FormattedMessage id='template.standard.invoice' defaultMessage='' /> </Link>
                                </Row>

                                <Row>
                                    <Link onClick={() => {
                                        updateTemplateDropdown(false)
                                        props.popModalFromStack()
                                        props.pushModalToStack({
                                            modalBody: <MultiCurrencyPurchaseOrder {...props} />,
                                            width: '100%',
                                            hideTitle: true,
                                            hideFooter: true,
                                            keyboard: false ,
                                            wrapClassName: 'new-transaction-wrapper'
                                        })
                                    }}
                                        // to="/admin/purchase-invoice-multi-currency"
                                    ><FormattedMessage id='template.standard.invoice.multicurrency' defaultMessage='' /> </Link>
                                </Row>
                            </div>
                        }
                    </div>
                        <Button onClick={() => {
                            props.updateDrawerState({
                                settingPurchaseDrawerVisible: true
                            })
                        }} >
                            <i class="fa fa-cog" aria-hidden="true"></i>
                        </Button>
                    {/* <div className="space-lr">
                        <Button icon={<SwapOutlined />} />
                    </div> */}
            
            </div>
        </>
    );
};

export default injectIntl(HeaderAction);
