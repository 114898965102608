import React, { useMemo } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Row, Col } from 'antd';
import img1 from '../../../assets/images/Usernewstu.png';
import img2 from '../../../assets/images/Userstu.png';
import img5 from '../../../assets/images/Userdue.png';
import img4 from '../../../assets/images/Userpaid.png';
import img3 from '../../../assets/images/UserStaff.png';
import TileIndex from '../../tiles/tilesIndex';
import { ICONS, MODULE_CODED_VALUES } from '../../../static/constants';
import './index.scss';
import Finances from './finances';
import { Dropdown } from '../../general/Dropdown';
import Admissions from './admission';
import TotalAttendance from './totalAttendance';
import EnrollStudents from './enrollStudents';
import FeeDue from './feeDue';
import RecentActivity from './recentActivity';
import RecentNotices from './recentNotices';
import { fixedDecimalNumber, getCurrencySymbol,getPermissionData } from '../../../utils';

const SchoolDashboard = (props) => {
    const { companyInfo,permissions } = props;
    const primaryPerm = (permissions && permissions.primary) || [];

    const studentsPermissionsData = useMemo(() => {
      return getPermissionData(primaryPerm, MODULE_CODED_VALUES.SCHOOL_STUDENT)
    }, [])
    const noticePermissionsData = useMemo(()=>{
        return getPermissionData(primaryPerm, MODULE_CODED_VALUES.SCHOOL_BROADCAST_MESSAGE )
      },[])
    const tilesData = [{
        footer: <FormattedMessage id='totalStudent.text' />,
        count: Number(props.totalStudentCount || 0),
        antIcon: <img src={img1} alt="" />,
        color: 'tile1',
        moduleCode:MODULE_CODED_VALUES.SCHOOL_STUDENT
    },
    {
        footer: <FormattedMessage id='teachers.txt' />,
        count: Number(props.teacherCount || 0),
        antIcon: <img src={img2} alt="" />,
        color: 'tile2',
        moduleCode:MODULE_CODED_VALUES.SCHOOL_TEACHER
    },
    {
        footer: <FormattedMessage id='staff.txt' />,
        count: Number(props.employeesCount || 0),
        antIcon: <img src={img3} alt="" />,
        color: 'tile5',
        moduleCode:MODULE_CODED_VALUES.HRMS_EMPLOYEE
    },

    {
        footer: <FormattedMessage id='feesPaid.text' />,
        count: (fixedDecimalNumber(props.totalFeesDue || 0)),
        antIcon: <img src={img4} alt="" />,
        currencyIcon: companyInfo.currencyIcon,
        color: 'tile4',
        moduleCode:MODULE_CODED_VALUES.SCHOOL_RECURRING_PAYMENTS
    },
    {
        footer: <FormattedMessage id='feesDue.text' />,
        count: (fixedDecimalNumber(props.totalFeesPaid || 0)),
        antIcon: <img src={img5} alt="" />,
        currencyIcon: companyInfo.currencyIcon,
        color: 'tile3',
        moduleCode:MODULE_CODED_VALUES.SCHOOL_RECURRING_PAYMENTS
    },
    ];



    return (
        <div className="school_dashboard_container">

            <TileIndex tilesData={tilesData} {...props} />
            <div className='view_dashboard pt15'>
                <Row gutter={[8, 8]}>
                    <Col span={18}>
                        <Row gutter={[8, 8]}>
                            <Col span={14}>
                                <div className="dashboard_inner_container">
                                    <div className='container_heading'>
                                        <div className="inner_container_heading">
                                            Finances
                                        </div>
                                        <div>
                                            <Dropdown
                                                className="dp-custom"
                                                value="Monthly"
                                            />
                                            <i className={ICONS.MORE} />

                                        </div>
                                    </div>
                                    <Finances {...props} />
                                </div>
                            </Col>
                            <Col span={10}>
                                <div className="dashboard_inner_container">
                                    <div className='container_heading'>
                                        <div className="inner_container_heading">
                                            Admissions
                                        </div>
                                        <div>
                                            <Dropdown
                                                className="dp-custom"
                                                value="Monthly"
                                            />
                                            <i className={ICONS.MORE} />

                                        </div>
                                    </div>
                                    <Admissions {...props} />
                                </div>
                            </Col>

                        </Row>

                        <Row gutter={[8, 8]}>
                            <Col span={14}>
                                <Row gutter={[8, 8]}>
                                    <Col span={24}>
                                        <div className="dashboard_inner_container">
                                            <div className='container_heading'>
                                                <div className="inner_container_heading">
                                                    Total Attendance
                                                </div>
                                                <div>
                                            <Dropdown
                                                className="dp-custom"
                                                value="Monthly"
                                            />
                                            <i className={ICONS.MORE} />

                                        </div>
                                            </div>
                                            <TotalAttendance {...props}/>
                                        </div>
                                    </Col>

                                </Row>
                                <Row gutter={[8, 8]}>
                                    <Col span={24}>
                                        <div className="dashboard_inner_container">
                                            <div className='container_heading'>

                                                <div className="inner_container_heading">
                                                    Student Fees Due
                                                </div>
                                            </div>
                                            <FeeDue {...props}/>
                                        </div>
                                    </Col>

                                </Row>
                            </Col>

                            <Col span={10}>
                                <Row gutter={[8, 8]}>
                                    <Col span={24}>
                                        <div className="dashboard_inner_container half_view">
                                            <div className='container_heading'>

                                                <div className="inner_container_heading">
                                                    Recent Activity
                                                </div>
                                            </div>
                                            <RecentActivity {...props}/>
                                        </div>
                                    </Col>

                                </Row>
                            </Col>
                        </Row>

                    </Col>

                    <Col span={6}>
                        <Row gutter={[8, 8]}>
                            <Col span={24}>
                                <div className="dashboard_inner_container full_view">
                                    {studentsPermissionsData &&
                                    <><div className='container_heading'>

                                            <div className="inner_container_heading">
                                                Enrolled Students
                                            </div>
                                        </div><EnrollStudents {...props} /></>
                                    }
                                  
                                   {noticePermissionsData && <><div className='container_heading'>
                                        <div className="inner_container_heading">
                                            Recent Notices
                                        </div>

                                    </div><RecentNotices {...props} /></>
                                   }
                                </div>
                            </Col>
                        </Row>
                    </Col>

                </Row>


            </div>

        </div>
    );
};

export default injectIntl(SchoolDashboard);
