import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import '@ant-design/compatible/assets/index.css';
import { Drawer, Row, Col, Button } from 'antd';
import ReactHtmlParser from 'react-html-parser';
import { base64Toblob, getAWSFileURL } from '../../../utils';
import { CustomButton } from '../../general/CustomButton';

function CommunicationDrawer(props) {
  const {communicationDetail} = props;
  const closeDrawer = () => {
    props.updateState({
      communicationReviewDrawerVisible: false
    })
  }
  const resendEmail =() =>{
    const payload = {
      to: Array.isArray(communicationDetail.communiatedTo) ? communicationDetail.communiatedTo : [communicationDetail.communiatedTo],
      subject: communicationDetail.emailSubject,
      message: communicationDetail.emailMessage,
      createdByEmpId: communicationDetail.relationshipEmployeeId,
      cc: communicationDetail.emailCC || [],
      bcc: communicationDetail.emailBCC || [],
      customerId: communicationDetail.customerId,
      relationshipId: communicationDetail.relationshipId,
      customerCommunicationTxId:communicationDetail.customerCommunicationTxId,
      refDocId:communicationDetail.refDocId
   
    }
  //   const image = communicationDetail.soAwsKeys.map((val)=>{
  //     return getAWSFileURL(val.awsKey,val.bucketName,val?.folderName)
      
  //   }
  // )
    let formData = new FormData();
    formData.append('payload', JSON.stringify(payload));
    // if (image.length) {
    //   const fileData = base64Toblob(image)
    //   formData.append('files', fileData);
    // }
    
    props.sendEmail(props,formData);
  }
  return (
    <Drawer
      title={<FormattedMessage id='communication.text' defaultMessage='' />}
      className="email-template-modal custom-drawer"
      keyboard={true}
      width={720}
      maskClosable={false}
      zIndex={99999}
      destroyOnClose={true}
      onClose={closeDrawer}
      visible={props.communicationReviewDrawerVisible}
      afterVisibleChange={
        (visible) => {
          if (visible) { }
        }
      }
      footer={
        <><CustomButton
          intlId="confirmation.cancel"
          defaultMessage=""
          type="default"
          key={0}
          onClick={closeDrawer} /><CustomButton
            intlId="Resend Email"
            defaultMessage=""
            htmlType="submit"
            key={1}
            onClick={() => resendEmail(props)} /></>
        }
      >

        <Row gutter={[16, 16]} style={{padding: "20px"}}>
          <Col span={4} className='b-text'><FormattedMessage id='to.text' /> : </Col>
          <Col span={20}>{props.communicationDetail.communiatedTo || ''}</Col>
          {props.communicationDetail.emailCC ?
            <>
              <Col span={4} className='b-text'><FormattedMessage id='cc.text' /> : </Col>
              <Col span={20}>{props.communicationDetail.emailCC}</Col>
            </> : ''
          }

          {props.communicationDetail.emailBCC ?
            <>
              <Col span={4} className='b-text'><FormattedMessage id='bcc.text' /> : </Col>
              <Col span={20}> {props.communicationDetail.emailBCC}</Col>
            </> : ''
          }
      
          {props.communicationDetail.emailSubject ?
            <>
              <Col span={4} className='b-text'><FormattedMessage id='subject.text' /> : </Col>
              <Col span={20}>{props.communicationDetail.emailSubject}</Col>
            </> : ''
          }
          {props.communicationDetail.transactionType ? <>
            <Col span={4} className='b-text'><FormattedMessage id='modal.settings.emailTemplate.transaction' /> : </Col>
            <Col span={20}><FormattedMessage id={props.communicationDetail.transactionType} /></Col>
          </> : ''
          }


          {props.communicationDetail.emailMessage ?
            <>
              <Col span={4} className='b-text'><FormattedMessage id='message.text' /> : </Col>
              <Col span={20}>{ReactHtmlParser(props.communicationDetail?.emailMessage || '')}</Col>
            </> : ''
          }

          {props.communicationDetail.refDocId ?
            <>
              <Col span={4} className='b-text'><FormattedMessage id='modal.txDetails.common.attachments' /> : </Col>
              <Col span={20}>
                <div onClick={() => {
                  props.downloadFileFromBucket({awsKeyName: props.communicationDetail.refDocId, bucketName: props.communicationDetail.bucketName})
                }}>
                  <i className="fi fi-rr-file-download pointer" style={{fontSize: '30px'}}/>
                </div>
              </Col>
            </> : ''
          }
        </Row>

    </Drawer>
  );
}

export default injectIntl(CommunicationDrawer);
