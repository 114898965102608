import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import AssignmentResponseCompo from "../../../components/schoolManagement/AssignmentResponse"
import { fetchDataIfNeeded, fetchPaginationDataIfNeeded } from "../../../utils";
import { getSchoolAssignmentResponse, getSchoolAssignmentResponseCount, resetPaginatedData, deleteAssignedGrade } from "./action";
import { showModal, hideModal, pushModalToStack, popModalFromStack } from '../../../actions/commonActions';
import { getAssignmentData } from '../../modal/modalBody/schoolManagement/CreateAssignment/action';
import { sendResetMail } from '../../Forgot/action';

class AssignmentSubmission extends Component {
  constructor(props) {
    super(props);
    
    const linkProps = (this.props.location && this.props.location.state) || {};
    this.state = {
      pageNumber: 1,
      pageSize:25,
      isSeeMore: false,
      isLoadData: false,
      assignmentId: linkProps.assignmentId,
      classId: linkProps.classId
    };
  }

  componentDidMount() {
    const companyInfo = this.props.companyInfo || {};
   
    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      relationshipEmployeeId: (this.props.userInfo || {}).relationshipEmployeeId,
      pageNumber: 1,
      pageSize: 25,
      formId: this.props.defaultSchoolForm,
      classId: this.state.classId,
      assignmentId: this.state.assignmentId
    };
    fetchPaginationDataIfNeeded("getSchoolAssignmentResponse", "assignmentResponseList", this.props, payload);
    fetchDataIfNeeded("getSchoolAssignmentResponseCount", "assignmentResponseCount", this.props, payload);
    this.props.getAssignmentData(payload);
  }


  render() {
    return (
      <AssignmentResponseCompo
        {...this.props}
        {...this.state}
        updateState={(data) => {
          this.setState(data);
        }}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    userInfo: state.common.userInfo,
    customerInfo: state.common.customerInfo,
    relationshipId: state.common.companyInfo.relationshipId,
    assignmentResponseList: state.school.assignmentResponseList,
    assignmentResponseCount: state.school.assignmentResponseCount,
    subdomain: state.common.subdomain,
    classStudentList: state.school.classStudentList,
    assignmentData: state.school.assignmentData,
    permissions: state.auth.permissions,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getSchoolAssignmentResponse, getSchoolAssignmentResponseCount, resetPaginatedData,
      showModal,
      hideModal,
      pushModalToStack,
      popModalFromStack,
      sendResetMail,
      getAssignmentData,
      deleteAssignedGrade
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(AssignmentSubmission);
