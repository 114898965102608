import React, { Fragment, useEffect, useMemo, useState } from "react";
import { injectIntl, FormattedMessage } from "react-intl";
import "./inventoryDrawer.scss";
import { TextBox } from "../../general/TextBox";
import LoadingIcon from '../../../assets/images/loder2.gif';
import { Dropdown } from "../../general/Dropdown";
import { ErrorMsg } from "../../general/ErrorMessage";
import ProductDetailsTab from "./ProductDetailsTab";
import FinanceTab from "./FinanceTab";
import { MODAL_TYPE, CONSTANTS, MODULE_CODED_VALUES } from "../../../static/constants";
import { getLabelName } from "../../modal/modalBody/customerInfo";
// import * as debounce from 'lodash.debounce';
//import { UploadOutlined } from '@ant-design/icons';
import { Form } from "@ant-design/compatible";
import "@ant-design/compatible/assets/index.css";

import {
  Drawer,
  Button,
  Col,
  Row,
  Input,
  Tabs,
  Switch,
  Tooltip,
  Select,
  Upload,
  Card,
  message,
  Skeleton
} from "antd";
import {
  showToasterMessage,
  base64Toblob,
  getAWSFileURL,
  getCurrencySymbol,
  fixedDecimalAndFormateNumber,
  fixedDecimalNumber,
  getPermissionData,
} from "../../../utils";
import CKEditor from "../../general/CustomCKEditor/ckeditor";
import { CUSTOM_CONFIGURATION } from "../../../static/ckEditorConfigConstants";
import { ImageCropper } from "../../general/ImageCropper";
import config from "../../../config/environmentConfig";
import { QuestionCircleTwoTone, InboxOutlined } from "@ant-design/icons";
import InventoryModel from "./InventoryModel";
// import TextArea from "antd/lib/input/TextArea";
import storeDef from "../../../assets/images/store-def.jpg";
import google from "../../../assets/images/google.png"
import VariantModel from "./variantModal";
import * as XLSX from "xlsx";

const { Option } = Select;
const { TabPane } = Tabs;
const { TextArea } = Input;

function InventoryDrawer(props) {
  const { intl,
    inventoryDrawerVisible,
    productBrands,
    manufacturers,
    allWarehouses,
    hasVariant,
    itemName,
    description,
    brandName,
    manufacturerName,
    warehouseName,
    articleNumber,
    partNumber,
    useSystemSku,
    sku,
    customerStockNumber,
    reorderQty,
    lowStockQty,
    // velocityAlert,
    // aboveThresholdAlert,
    outOfStock,
    updateDrawerState,
    invalidProductNameClass,
    submittedOnce,
    relationshipId,
    upc,
    ean,
    isbn,
    amazonAsinNumber,
    serialNumber,
    isUpdate,
    itemPageTitle,
    itemPageDescription,
    variantObj,
    socialSharingImage,
    allUOM,
    isWholesaleProfitMarginInPercentage,
    isRetailProfitMarginInPercentage,
    inventoryItemVariantList = [],
    companyInfo,
    showModal,
    hideModal,
    discount,
    permissions
  } = props;
  const primaryPerm = (permissions && permissions.primary) || [];
  const categoryPermission = useMemo(()=>{
    return getPermissionData(primaryPerm ,  MODULE_CODED_VALUES.PRODUCT_CATEGORY);
  },[]) 

  

  

 

  const data = {
    homePageTitle: (props?.storeValues?.homePageTitle) ? props?.storeValues?.homePageTitle : '',
    homePageDescription: (props?.storeValues?.homePageDescription) ? props?.storeValues?.homePageDescription : '',
    socialSharingImage: (props?.storeValues?.socialSharingImage) ? props?.storeValues?.socialSharingImage : '',
  }

  const [seoData, setSeoData] = useState(data)
  const [velocityAlert, setVelocityAlert] = useState(1);
  const [aboveThresholdAlert, setAboveThresholdAlert] = useState(1);

  const [fileList, setFileList] = useState([]);
  // const [discount, setDiscount] = useState(0);
  let [isFocus, setIsFocus] = useState(false);

  useEffect(() => {
    const calculatedDiscount =
      ((Number(variantObj.maxRetailPrice) - (variantObj.retailPrice || 0)) / Number(variantObj.maxRetailPrice)) * 100
    props.updateDrawerState({
      discount:calculatedDiscount
    });
  }, [variantObj.maxRetailPrice]);


  const calculateWholesalePrice = (inputValue, isPercentage) => {
    let item =  props.inventoryItemVariantList
    item[0] =  {...item[0],isWholesaleProfitMarginInPercentage: isPercentage}

  
    const totalCostValue = Number(variantObj.purchasedPrice) + Number(variantObj.landedCost) || 0;
    const updatedIsPercentage = isPercentage === undefined ? variantObj.isWholesaleProfitMarginInPercentage : isPercentage;

    const val = updatedIsPercentage === 1 ? fixedDecimalNumber((inputValue / 100) * totalCostValue + totalCostValue) : fixedDecimalNumber(inputValue + totalCostValue);
    updateDrawerState({
      // wholesalePrice: val,
      variantObj: { ...variantObj, wholesaleProfitMargin: inputValue, wholesalePrice: val,isWholesaleProfitMarginInPercentage:isPercentage },
    
      inventoryItemVariantList: item
      // inventoryItemVariantList: [{
      //   ...props.inventoryItemVariantList[0],
      //   isWholesaleProfitMarginInPercentage: updatedIsPercentage
      // }]
    });
  };

  const calculateRetailPrice = (inputValue, isPercentage) => {

  
    const totalCostValue = Number(variantObj.purchasedPrice) + Number(variantObj.landedCost) || 0;
    const updatedIsPercentage = isPercentage === undefined ? variantObj.isRetailProfitMarginInPercentage : isPercentage;
    const val = updatedIsPercentage === 1 ? fixedDecimalNumber((inputValue / 100) * totalCostValue + totalCostValue) : fixedDecimalNumber(inputValue + totalCostValue);
    let item = props.inventoryItemVariantList
    item[0] = {
      ...item[0], maxRetailPrice: val,
      retailPrice: val,
      isRetailProfitMarginInPercentage: isPercentage
    }
    updateDrawerState({
      variantObj: {
        ...variantObj, retailProfitMargin: inputValue,
        maxRetailPrice: val,
        retailPrice: val,
      isRetailProfitMarginInPercentage: isPercentage
      },
      inventoryItemVariantList: item
    });
  };

  const calculateFieldsValue = (purchaseCost) => {
    const totalCost = Number(purchaseCost) + Number(variantObj.landedCost)
    const wholesaleProfitMargin = variantObj.wholesaleProfitMargin || 0
    const retailProfitMargin = variantObj.retailProfitMargin || 0
    const wholeSalePrice = variantObj.isWholesaleProfitMarginInPercentage === 1 ?fixedDecimalNumber((wholesaleProfitMargin / 100) * totalCost + totalCost) :fixedDecimalNumber(wholesaleProfitMargin + totalCost)
    const retailPrice = variantObj.isRetailProfitMarginInPercentage === 1 ?fixedDecimalNumber((retailProfitMargin / 100) * totalCost + totalCost) :fixedDecimalNumber(retailProfitMargin + totalCost)
    // let item = props.inventoryItemVariantList
    // item[0] = { ...item[0],retailPrice:retailPrice }
    let updatedList = props.inventoryItemVariantList.map(item => {
      return { ...item, retailPrice: retailPrice };
    });
    updateDrawerState({

      variantObj: {
        ...variantObj,
        purchasedPrice: purchaseCost,
        wholesalePrice: wholeSalePrice,
        retailPrice: retailPrice,
        // maxRetailPrice: retailPrice
      },
      inventoryItemVariantList: updatedList
    });
  }


  const handleRemove = () => {
    setFileList([]);
  };
  const image = getAWSFileURL(socialSharingImage, 'inventory-u')
 
  const handleChange = (info) => {
    if (info.file.name) {
      setSeoData({ ...seoData, socialSharingImage: info.fileList[0] ? info.fileList[0] : null })

      // Call your function with the uploaded file
      // const payload = {
      //   relationshipId: props.relationshipId,
      //   file: info.file,
      // };
      // props.updateoraddThemeHeaderLogo(payload);
    } else {
    }
  };

  

  function getSKU(productName) {
    if (productName && productName.length > 1 && useSystemSku === 1) {
      props.updateDrawerState({
        isSkuUpdated: false,
      });
      // props.updateState({
      //   isSkuUpdated: false,
      // })
      props.getSkuData({
        relationshipId: relationshipId,
        useSystemSku: 1,
        productName: productName,
      });
    } else {
      // Throw error 404, beer not found
    }
  }

  // const updateSKU = ((productName) => {
  //   getSKU(productName);
  // });
  const [, setIsTyping] = useState(false);
  let typingTimer;
  // Function to be executed when typing stops
  const typingStopped = () => {
    // setIsTyping(false);
    getSKU(itemName, props);
  };

  // Event handler for keyup event
  const handleKeyUp = () => {
    clearTimeout(typingTimer);
    // setIsTyping(true);
    typingTimer = setTimeout(typingStopped, 1000); // Set the desired delay in milliseconds
  };

  // Event handler for keydown event
  const handleKeyDown = () => {
    clearTimeout(typingTimer);
    // setIsTyping(true);
  };
  function closeDrawer() {
    props.updateState({
      inventory: {
        inventoryDrawerVisible: false,

      },
    });
  }

  const handleSubmit = (payload) => {
    
    if (!payload.submittedOnce) {
      payload.updateDrawerState({ submittedOnce: true });
    }

    if(!payload.inventoryItemCategoryRefs?.length){
      props.updateDrawerState({
        activeTabKey: `${1}`
      })
         return (
          showToasterMessage({
            description:
              "Category Name is Required",
          })
         )
    }

    if(payload.hasVariant === 0 && (!variantObj.purchasedPrice && payload.qtyCurrentStock)){
      props.updateDrawerState({
        activeTabKey: `${1}`
      })
      return( showToasterMessage({
        description:
          "Purchase price for current stock is required for inventory valuation",
      }))
    }
    let isPurchasedPriceRequired = false;

    if (
      payload.inventoryItemVariantList &&
      payload.inventoryItemVariantList.length > 0
    ) {
      payload.inventoryItemVariantList.forEach(function (item) {
        if (!item.purchasedPrice && item.currentStock) {
          isPurchasedPriceRequired = true;
        }
      });
    } else if (payload.variantObj) {
      if (
        !payload.variantObj.purchasedPrice &&
        payload.variantObj.currentStock
      ) {
        isPurchasedPriceRequired = true;
      }
    }
    if (isPurchasedPriceRequired) {
      props.updateDrawerState({
        activeTabKey: `${3}`
      })
      showToasterMessage({
        description:
          "Purchase price for current stock is required for inventory valuation",
      });
      return;
    }

    if (!variantObj.purchasedPrice) {
      props.updateDrawerState({
        activeTabKey: `${1}`
      })
    }
   if(props.isDuplicateName){
    return   showToasterMessage({
        description:
          "Duplicate Product Name",
      });
   }
   if (
    ((variantObj.attributeName1) && (variantObj.attributeName1 === variantObj.attributeName2))||
    ((variantObj.attributeName1) &&(variantObj.attributeName1 === variantObj.attributeName3)) ||
    ((variantObj.attributeName2) &&(variantObj.attributeName2 === variantObj.attributeName3))
 
  ) {
  
   return showToasterMessage({
      description:"Attribute Name cannot be same"
    })
  }
    // if (payload && payload.itemName && payload.itemName.length > 1 && variantObj.purchasedPrice && !props.isDuplicateName) {
      if (payload && payload.itemName && payload.itemName.length > 1 && !props.isDuplicateName) {
     
    const modalData = {
        title: (
          <FormattedMessage
            id="drawer.inventory.confirmation.title"
            defaultMessage=""
          />
        ),
        modalBody: getCreateConfirmationModalBody(payload),
        handleSubmit: () => {
          if(!props.isDuplicateName){
            payload.createInventoryItem(payload);
            payload.hideModal();
          }else{
            showToasterMessage({
              description:
                "Duplicate Product Name",
            });
          }
        },
      };
      payload.showModal(modalData);
    } else {
      payload.updateDrawerState({
        activeTabKey: `${1}`,
        invalidProductNameClass: !payload.productName ? "input-text-error" : "",
      });
    }
  };

  const handleNewWarehouseAddition = (props, payload) => {
    const formData = payload.formData;
    const { showModal, companyInfo } = props;
    formData.relationshipId = companyInfo.relationshipId;
    const data = {
      title: <FormattedMessage id="addItem.text.warehouse" defaultMessage="" />,
      formData,
      hideFooter: true,
      modalData: {
        modalType: MODAL_TYPE.WAREHOUSE,
        data: payload,
      },
      handleSubmit: (formData = {}) => {
        data.formData.submittedOnce = true;
        showModal(data);
      },
    };
    showModal(data);
  };

  const addItemConfirmationModal = (props, text, modalType) => {
    const { showModal, companyInfo } = props;
    const labelName = getLabelName(modalType);
    const modalBody = (
      <span>
        <FormattedMessage
          id="addItem.confirmation"
          values={{ value: `${text} to ` }}
          defaultMessage=""
        />
        {labelName} ?
      </span>
    );

    let addFunc;

    switch (modalType) {
      case MODAL_TYPE.PRODUCT_CATEGORY: {
        addFunc = props.addCategory;
        break;
      }
      case MODAL_TYPE.UOM: {
        addFunc = props.addUOM;
        break;
      }
      case MODAL_TYPE.PRODUCT_BRAND: {
        addFunc = props.addBrand;
        break;
      }
      case MODAL_TYPE.PRODUCT_MANUFACTURER: {
        addFunc = props.addManufacturer;
        break;
      }
      default: {
        addFunc = () => { };
      }
    }

    const modalData = {
      modalBody,
      handleSubmit: () => {
        addFunc({ text: text, relationshipId: companyInfo.relationshipId }, props);
        props.hideModal();
      },
    };
    showModal(modalData);
  };

  const getModalTitle = (newTextObj, modalType) => {
    let title;
    switch (modalType) {
      case MODAL_TYPE.PRODUCT_CATEGORY: {
        title = (
          <FormattedMessage
            id="addItem.text.productCategory"
            defaultMessage=""
          />
        );
        break;
      }
      case MODAL_TYPE.PRODUCT_BRAND: {
        title = (
          <FormattedMessage id="addItem.text.productBrand" defaultMessage="" />
        );
        break;
      }
      case MODAL_TYPE.PRODUCT_MANUFACTURER: {
        title = (
          <FormattedMessage
            id="addItem.text.productManufacturer"
            defaultMessage=""
          />
        );
        break;
      }
      case MODAL_TYPE.UOM: {
        title = <FormattedMessage id='addItem.text.uom' defaultMessage='' />;
        break;
      }
      case MODAL_TYPE.WAREHOUSE: {
        title = (
          <FormattedMessage id="addItem.text.warehouse" defaultMessage="" />
        );
        break;
      }
      default:
        title = (
          <FormattedMessage id="signup.confirmation.title" defaultMessage="" />
        );
    }
    return title;
  };

  // const handleNewItemAddition = (props, payload, modalType) => {
  //   const formData = payload.formData;
  //   const { showModal } = props;

  //   if (
  //     formData.textBoxValue &&
  //     formData.textBoxValue.length &&
  //     !payload.existsInData
  //   ) {
  //     addItemConfirmationModal(props, formData.textBoxValue, modalType);
  //   } else {
  //     const data = {
  //       title: getModalTitle(payload, modalType),
  //       formData,
  //       hideFooter: modalType === MODAL_TYPE.PRODUCT_CATEGORY ? true : false,
  //       modalData: { modalType, data: payload },
  //       handleSubmit: (formData = {}) => {
  //         if (formData.textBoxValue && formData.textBoxValue.length) {
  //           addItemConfirmationModal(props, formData.textBoxValue, modalType);
  //         } else {
  //           data.formData.submittedOnce = true;
  //           showModal(data);
  //         }
  //       },
  //     };
  //     showModal(data);
  //   }
  // };

  const handleNewItemAddition = (props, payload, modalType) => {
    const formData = payload.formData;
    formData.relationshipId = companyInfo.relationshipId;
    // const payload = { formData: formData };
    const data = {
      title: getModalTitle(payload, modalType),
      formData,
      modalData: { modalType, data: payload },
      handleSubmit: (formData = {}) => {
        if (formData.textBoxValue && formData.textBoxValue.length) {
          addItemConfirmationModal(props, formData.textBoxValue, modalType);
        } else {
          data.formData.submittedOnce = true;
          showModal(data);


        }
      }
    };
    showModal(data);
  }


  const deleteClickHandler = (props, modalType, payload) => {
    const { showModal, companyInfo ,hideModal } = props;
    const modalBody = (
      <FormattedMessage
        id="deleteItem.confirmation"
        defaultMessage=""
        values={{ value: payload.text }}
      />
    );

    let deleteFunc;

    switch (modalType) {
      case MODAL_TYPE.PRODUCT_CATEGORY: {
        deleteFunc = props.deleteCategory;
        break;
      }
      case MODAL_TYPE.PRODUCT_BRAND: {
        deleteFunc = props.deleteBrand;
        break;
      }
      case MODAL_TYPE.PRODUCT_MANUFACTURER: {
        deleteFunc = props.deleteManufacturer;
        break;
      }
      case MODAL_TYPE.UOM: {
        deleteFunc = props.deleteUOM;
        break;
      }
      default: {
        deleteFunc = () => { };
      }
    }

    const modalData = {
      modalBody,
      handleSubmit: () => {
        deleteFunc({
          id: payload.id,
          relationshipId: companyInfo.relationshipId,
        })
          
        hideModal();
      },
    };
    showModal(modalData);
  };

  const getCreateConfirmationModalBody = (payload) => {
    return (
      <Fragment>
        <p>
          <FormattedMessage
            id="drawer.inventory.confirmation.text"
            defaultMessage=""
          />
        </p>
      </Fragment>
    );
  };

  const handleParentCategory = () => {
    props.pushModalToStack({
      modalBody: <InventoryModel {...props} />,
      title: (
        <FormattedMessage
          id="drawer.inventory.select.category.placeholder"
          defaultMessage=""
        />
      ),
      width: "40%",
      hideTitle: false,
      hideFooter: true,
      wrapClassName: "cat-modal-custom",
    });
  };

  const getCategoryNames = () => {
    const { inventoryItemCategoryRefs } = props;
    const categoryNames = (inventoryItemCategoryRefs || []).map((ref) => {
      const matchingCategory = props.productCategories.find(
        (category) =>
          category.inventoryItemCategoryId === ref.inventoryItemCategoryId
      );
      return matchingCategory ? matchingCategory.categoryName : "";
    });
    return categoryNames?.join(", ");
  };

  const addVariants = () => {
    props.pushModalToStack({
      modalBody: <VariantModel {...props} />,
      title: (
        <FormattedMessage
          id="drawer.inventory.addVariant.placeholder"
          defaultMessage=""
        />
      ),
      width: "40%",
      height: "500px",
      hideTitle: false,
      hideFooter: true,

    });
  };

  const validateTab1 = (props) => {
    let isOK = true
    //  if(props.itemName && variantObj.purchasedPrice && props.itemName && props.inventoryItemCategoryRefs?.length && !props.isDuplicateName){
      if(props.itemName && props.itemName && props.inventoryItemCategoryRefs?.length && !props.isDuplicateName){
     
    isOK = false
     }
    return isOK;
  };

  const validateTab2 = (props) => {
    return false;
  };

  const validateTab3 = (props) => {
   
    let isOK = true
      if (
        variantObj.attributeName1 !== variantObj.attributeName2 &&
        variantObj.attributeName1 !== variantObj.attributeName3 &&
        variantObj.attributeName2 !== variantObj.attributeName3
     
      ) {
     
    isOK = false
     }
    return isOK;
  };

  const validateTab4 = (props) => {
    
    return false;
  };

  const validateTab5 = (props) => {
    return false;
  };

  const validateTab6 = (props) => {
    return false;
  };
  const validateTab7 = (props) => {
    return false;
  };
  const validateTab = (props) => {
    let errorCode = "";
    switch (props.activeTabKey) {

      case "1":
        errorCode = validateTab1(props);
        if (errorCode) {
          props.updateDrawerState({
            submittedOnce: true,
          });
          showToasterMessage({
            description:props.isDuplicateName ?"Duplicate product name":"Please fill the required fields"
          })
          return errorCode;
        } else
          props.updateDrawerState({
            activeTabKey: `${Number(props.activeTabKey || 1) + 1}`,
          });
        break;
      case "2":
        errorCode = validateTab2(props);
        if (errorCode)
          return showToasterMessage({
            description: props.intl.formatMessage({ id: errorCode }),
            messageType: "error",
          });
        else
          props.updateDrawerState({
            activeTabKey: `${Number(props.activeTabKey || 1) + 1}`,
          });
        break;
      case "3":
        errorCode = validateTab3(props);
        if (errorCode) {
          props.updateDrawerState({
            submittedOnce: true,
          });
          showToasterMessage({
            description:"Attribute Name cannot be same"
          })
          return errorCode;
        } else
          props.updateDrawerState({
            activeTabKey: `${Number(props.activeTabKey || 1) + 1}`,
          });
        break;
      case "4":
        errorCode = validateTab4(props);
        if (errorCode)
          return showToasterMessage({
            description: props.intl.formatMessage({ id: errorCode }),
            messageType: "error",
          });
        else
          props.updateDrawerState({
            activeTabKey: `${Number(props.activeTabKey || 1) + 1}`,
          });
        break;
      case "5":
        errorCode = validateTab5(props);
        if (errorCode)
          return showToasterMessage({
            description: props.intl.formatMessage({ id: errorCode }),
            messageType: "error",
          });
        else
          props.updateDrawerState({
            activeTabKey: `${Number(props.activeTabKey || 1) + 1}`,
          });
        break;
      // case "6":
      //   errorCode = validateTab6(props);
      //   if (errorCode)
      //     return showToasterMessage({
      //       description: props.intl.formatMessage({ id: errorCode }),
      //       messageType: "error",
      //     });
      //   else
      //     props.updateDrawerState({
      //       activeTabKey: `${Number(props.activeTabKey || 1) + 1}`,
      //     });
        break;


      case "6":
        errorCode =
          validateTab1(props) ||
          validateTab2(props) ||
          validateTab3(props) ||
          validateTab4(props) ||
          validateTab5(props) ||
          validateTab6(props) ||
          validateTab7(props);
        if (errorCode) {
          showToasterMessage({
            description: props.intl.formatMessage({ id: errorCode }),
            messageType: "error",
          });
          return true;
        } else return false;
      // break;
      default:
        break;
    }
  };
  //   const uploadImage = {
  //     name: 'file',
  //     multiple: true,
  //     customRequest: dummyRequest,
  //     onChange(info) {
  //         const { status } = info.file;
  //         if (status !== 'uploading') {
  //         }
  //         if (status === 'done') {
  //             props.updateState({
  //               productImage: info.file.originFileObj
  //             })
  //         } else if (status === 'error') {
  //         }
  //     },
  //     onDrop(e) {
  //     },
  // };
 

  const [fileList1 ,setFileList1] = useState([])
  const onCustomRequest = async ({ file, onSuccess }) => {
    setTimeout(() => {
        onSuccess("ok");
    }, 0);
}
  const fileUploadHandler = (e)=>{
    const arr = fileList1;
    arr.push(e)
    setFileList1(arr)
    props.updateDrawerState({
      fileToUpload: fileList1,
    });
  }


  return (
    <Drawer
      title={
        !isUpdate ? (
          <FormattedMessage id="drawer.inventory.title" defaultMessage="" />
        ) : (
          "Edit Product"
        )
      }
      width={720}
      destroyOnClose={true}
      onClose={closeDrawer}
      keyboard={false}
      maskClosable={false}
      className="custom-drawer"
      afterVisibleChange={() => {
        props.initCalls();
      }}
      visible={inventoryDrawerVisible}
      footer={
        <>

          {props.activeTabKey === "1" ? (
            <Button

              onClick={() => {
                const modalData = {
                  modalBody: props.intl.formatMessage({
                    id: "close.confirmation",
                  }),
                  footer: {
                    cancel: { intlId: "no" },
                    submit: { intlId: "yes" },
                  },
                  handleSubmit: () => {
                    // props.popModalFromStack();
                    closeDrawer();
                  },
                };
                props.pushModalToStack(modalData);
              }}
            >
              Cancel
            </Button>
          ) : (
            <Button

              onClick={() => {

                props.updateDrawerState({ activeTabKey: `${Number(props.activeTabKey || 1) - 1}` });
              }}
            >
              Back
            </Button>
          )}

          {props.activeTabKey === "6" ? (
            <Button
              type="primary"
              onClick={() => {
                handleSubmit(props);
              }}
            >
              <FormattedMessage
                id="button.save.label"
                defaultMessage=""
              />
            </Button>
          ) : (
            <Button
              type="primary"
              onClick={() => {
                validateTab(props)
                // props.updateDrawerState({ activeTabKey: `${Number(props.activeTabKey || 1) + 1}` });
              }}
            >
              Next
            </Button>
          )}



        </>
      }
    >
      {" "}
      <Tabs type="line"
        activeKey={props.activeTabKey || "1"}

        onChange={(key) => {

          props.updateDrawerState({ activeTabKey: key });
        }}
      >
        {/*Product detail Tab start here  */}

        <TabPane
          tab={intl.formatMessage({
            id: "drawer.inventory.tabNames.productDetails",
            defaultMessage: "",
          })}
          key="1"

        >
          <Skeleton loading={props.drawerLoading} paragraph={{ rows: 18 }} active>
          <Row gutter={[16, 16]}>

            <Col span={24}>

              <div className="drawer-heading">Product Information</div>
              <div>Easily input essential details like name, price, and more to showcase your product.</div>
            </Col>
          </Row>

          <Row gutter={[16, 8]}>
            <Col span={24} className="drawer-label">
              <FormattedMessage
                id="drawer.inventory.skuId"
                defaultMessage=""
              />
              <span className="required">*</span>

            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={24} className="Product-tab">
            <Input
                    style={{ height: "38px" }}
                    disabled={useSystemSku === 1}
                    placeholder={intl.formatMessage({
                      id: "drawer.inventory.sku.placeholder",
                      defaultMessage: "",
                    })}
                    type="text"
                    value={sku}
                    
                    onChange={(e) => {
                      updateDrawerState({
                        sku: e.target.value,
                        // inventoryItemVariantList: [{
                        //   ...props.inventoryItemVariantList[0],
                        //   sku:e.target.value,
    
                        // }]
                      });
                    }}
                    prefix ={
                      (((itemName && itemName.length>2)  && !(sku && sku.length>2))) ?  <img
                    src={LoadingIcon}
                    alt="Invoice"
                    style={{
                       height:"45px",
                      // "margin-top": "-25px",
                      // "margin-bottom": "-25px",
                    }}
                  /> :''
                    }
                    addonAfter={
    
                      <Switch
                        checked={useSystemSku === 1}
                        onClick={(checked, e) => {
                          updateDrawerState({
                            useSystemSku: checked ? 1 : 0,
    
                          });
                        }}
                      />
    
    
                    }
                  />
             

              
              <ErrorMsg
                validator={() => {
                  return !submittedOnce || !!sku;
                }}
                message={intl.formatMessage({
                  id: "drawer.inventory.validation.sku",
                  defaultMessage: "",
                })}
              />
            </Col>


          </Row>
          <Row gutter={[16, 8]}>
            <Col span={24} className="drawer-label">
              <FormattedMessage
                id="drawer.inventory.productName"
                defaultMessage=""
              />
              <span className="required">*</span>

            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col
              className={
                !submittedOnce ||
                  !(itemName && itemName.length < 2) ||
                  !submittedOnce ||
                  !!itemName
                  ? "product-name-input-error"
                  : ""
              }
              span={24}
            >
              <TextBox
                placeholder={intl.formatMessage({
                  id: "drawer.inventory.productName.placeholder",
                  defaultMessage: "",
                })}
                type="text"
                maxLength={150}
                countStyle={{ top: "15px" }}
                value={itemName}
                className={invalidProductNameClass}
                onKeyUp={handleKeyUp}
                onKeyDown={handleKeyDown}
                onChange={(e) => {
                  updateDrawerState({
                    itemName: e.target.value,
                    invalidProductNameClass:
                      e.target.value && e.target.value.length >= 2
                        ? ""
                        : "input-text-error",
                  });
                  // updateSKU(e.target.value);
                }}
                onBlur={(e)=>{
                  const callBack = (value)=>{
                    props.updateDrawerState({
                      isDuplicateName:value
                    })
                  }
                  let needToCheck = true

                  if(props.isUpdate){
                    needToCheck = props.preItemName == itemName ? false :true
                  }
                  if(needToCheck){
                    props.isDuplicateProductName({itemName:itemName,relationshipId:relationshipId},callBack,props)
                  }
                }}
                validator={(val) => {
                  return val && val.length >= 2;
                }}
              />
              <ErrorMsg
                validator={() => {
                  return !submittedOnce || !!itemName;
                }}
                message={intl.formatMessage({
                  id: "drawer.inventory.validation.productName",
                  defaultMessage: "",
                })}
              />
              <ErrorMsg
                validator={() => {
                  return !submittedOnce || !(itemName && itemName.length < 2);
                }}
                message={intl.formatMessage({
                  id: "drawer.inventory.validation.productNameLenght",
                  defaultMessage: "",
                })}
              />
            </Col>

          </Row>
          <Row gutter={[16, 8]}>
            <Col span={24} className="drawer-label"

            >
              <FormattedMessage
                id="drawer.inventory.productCategory"
                defaultMessage=""
              />
              <span className="required">*</span>


            </Col>
          </Row>
          <Row gutter={[16,16]}>
           
            <Col span={20}>
              <div onClick={() => handleParentCategory()} onFocus={handleParentCategory} >
                <TextBox
                  value={getCategoryNames()}
                  placeholder={intl.formatMessage({
                    id: "drawer.inventory.select.category.placeholder",
                    defaultMessage: "",
                  })}
                // onKeyDown={handleKeyDown}

                />
                 <ErrorMsg
                validator={() => {
                  return !submittedOnce || !!props.inventoryItemCategoryRefs?.length;
                }}
                message={"Please Select Category Name"}
              />
              </div>
              {/* <ErrorMsg
                validator={() => {
                  return !submittedOnce || !!props.productCategories.categoryName;
                }}
                message={intl.formatMessage({
                  id: "drawer.inventory.validation.productCategory",
                  defaultMessage: "",
                })}
              /> */}
            </Col>
            {
              categoryPermission?.create ?  <Col span={4}>
              <Button
             style={{height:"38px",width:"100%"}}
  
              onClick={() => {
                props.updateHeaderState({
                  inventory: {
                    ...props.inventory,
                    categoryDrawerVisible: true,
                    inventoryDrawerVisible: true
  
                  },
                });
               
              }}
              >Add New</Button>
              </Col>: ""
            }
           
            
          </Row>
          <Row gutter={[16, 8]}>

            <Col span={12} className="drawer-label">
              <FormattedMessage
                id="drawer.inventory.manufacturer"
                defaultMessage=""
              />
            </Col>
            <Col span={12} className="drawer-label">
              <FormattedMessage
                id="drawer.inventory.brand"
                defaultMessage=""
              />
            </Col>
          </Row>
          <Row gutter={[16, 16]}>

            <Col span={12}>
              <Dropdown
                span={12}
                placeholder={intl.formatMessage({
                  id: "drawer.inventory.manufacturer.placeholder",
                  defaultMessage: "",
                })}
                items={manufacturers}
                valueKeyName="manufacturerName"
                optionKeyName="inventoryProductManufacturerId"
                moduleName={MODULE_CODED_VALUES.PRODUCT_MANUFACTURER}
                canAddNew={true}
                value={manufacturerName}
                canDelete={true}
                deleteClickHandler={(payload) => {
                  deleteClickHandler(
                    props,
                    MODAL_TYPE.PRODUCT_MANUFACTURER,
                    payload
                  );
                }}
                onAddNewClick={(payload) => {
                  payload.formData = {
                    textBoxValue: payload.textEntered,
                    submittedOnce: false,
                  };
                  handleNewItemAddition(
                    props,
                    payload,
                    MODAL_TYPE.PRODUCT_MANUFACTURER
                  );
                }}
                onSelect={(optionValue, elem) => {
                  let obj = manufacturers.find(
                    (manufacturer) =>
                      manufacturer.inventoryProductManufacturerId ===
                      optionValue
                  );
                  updateDrawerState({
                    manufacturerName: obj.manufacturerName,
                    manufacturerId: obj.inventoryProductManufacturerId,
                  });
                }}
                allowClear={true}
                onClear={() => {
                  updateDrawerState({
                    manufacturerName: undefined,
                    manufacturerId: undefined,
                  });
                }}
              />
            </Col>

            <Col span={12}>
              <Dropdown
                span={12}
                placeholder={intl.formatMessage({
                  id: "drawer.inventory.brand.placeholder",
                  defaultMessage: "",
                })}
                items={productBrands}
                valueKeyName="brandName"
                optionKeyName="itemBrandId"
                moduleName={MODULE_CODED_VALUES.PRODUCT_BRAND}
                canAddNew={true}
                value={brandName}
                canDelete={true}
                deleteClickHandler={(payload) => {
                  deleteClickHandler(
                    props,
                    MODAL_TYPE.PRODUCT_BRAND,
                    payload
                  );
                }}
                onAddNewClick={(payload) => {
                  payload.formData = {
                    textBoxValue: payload.textEntered,
                    submittedOnce: false,
                  };
                  handleNewItemAddition(
                    props,
                    payload,
                    MODAL_TYPE.PRODUCT_BRAND
                  );
                }}
                onSelect={(optionValue, elem) => {
                  let obj = productBrands.find(
                    (brand) => brand.itemBrandId === optionValue
                  );
                  updateDrawerState({
                    brandName: obj.brandName,
                    brandId: obj.itemBrandId,
                  });
                }}
                allowClear={true}
                onClear={() => {
                  updateDrawerState({
                    brandName: undefined,
                    brandId: undefined,
                  });
                }}
              />
            </Col>
          </Row>
          <Row gutter={[16, 4]}>
            <Col span={24} className="drawer-label">
              Short Description
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <div>
                <TextBox
                  value={props.shortDescription}
                  maxLength={250}
                  placeholder={intl.formatMessage({
                    id: "drawer.inventory.select.category.placeholder",
                    defaultMessage: "",
                  })}
                  onChange={(event)=>{
                    props.updateDrawerState({
                      shortDescription:event.target.value
                    })
                  }}
                // onKeyDown={handleKeyDown}

                />
              </div>
              {/* <ErrorMsg
                validator={() => {
                  return !submittedOnce || !!props.productCategories.categoryName;
                }}
                message={intl.formatMessage({
                  id: "drawer.inventory.validation.productCategory",
                  defaultMessage: "",
                })}
              /> */}
            </Col>
          </Row>
          <Row gutter={[16, 4]}>
            <Col span={24} className="drawer-label">
              <FormattedMessage
                id="drawer.inventory.select.description"
                defaultMessage=""
              />
            </Col>
          </Row>
         
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <div style={{ display: "flex", gap: "5px" }}>Get high quality product descriptions within seconds!

                <div style={{ color: "#5555E3" }}>Get description.</div>

              </div>

            </Col>
            {/* <Col span={24}>
              <TextArea
                type='text'
                style={{ height: "95px", resize: "none" }}
                maxLength={1000}
                value={description}
                onChange={(e) => {
                  props.updateDrawerState({
                    description: e.target.value,
                  });
                }}
                placeholder={props.intl.formatMessage({ id: 'drawer.inventory.description.placeholder', defaultMessage: '' })}
              />


            </Col> */}
            <Col span={24}>
              <CKEditor
                type="replace"
                className="description-input"
                
                key={`${"description"}`}
                data={description}
                editor={props.editor}
                onInit={(editor) => {
                  props.updateDrawerState({
                    editor,
                  });
                }}
                onChange={(event) => {
                  const data = event.editor.getData();
                  updateDrawerState({
                    description: data,
                  });
                }}
                config={CUSTOM_CONFIGURATION}
              />
            </Col>
          </Row>
          <Row gutter={[16, 8]}>
            <Col span={12} className="drawer-label">
              Total Quantity



            </Col>
            <Col span={12} className="drawer-label">
              <FormattedMessage id='drawer.inventory.uom' defaultMessage='' />



            </Col>
          </Row>
          <Row gutter={[16, 16]}>

            <Col span={12}>
              <div>

              <Input
                  placeholder="Total Qty"
                  style={{ height: '38px' }}
                  type="number"
                  value={props.qtyCurrentStock || 0}
                  onChange={(e)=>{
                    props.updateDrawerState({
                      qtyCurrentStock: e.target.value,
                    })
                  }}
                  disabled = {(props.inventoryItemVariantList||[]).length}
                />
              </div>
            </Col>
            <Col span={12}>

              <Dropdown
                items={allUOM}
                valueKeyName='uomName'
                optionKeyName='uomId'
                value={variantObj.uomName}
                moduleName={MODULE_CODED_VALUES.UOM}
                canAddNew={true}
                canDelete={true}
                deleteClickHandler={(payload) => {
                  deleteClickHandler(props, MODAL_TYPE.UOM, payload);
                }}
                onAddNewClick={(payload) => {
                  payload.formData = {
                    textBoxValue: payload.textEntered,
                    submittedOnce: false
                  }
                  handleNewItemAddition(props, payload, MODAL_TYPE.UOM)
                }}
                placeholder={intl.formatMessage({ id: 'drawer.inventory.uom.placeholder', defaultMessage: '' })}
                onSelect={(optionValue, elem) => {
                  let obj = allUOM.find(uomObj => (uomObj.uomId === optionValue)) || {};
                  updateDrawerState({ variantObj: { ...variantObj, uomName: obj.uomName } });
                }}
                allowClear={true}
                onClear={() => {
                  updateDrawerState({ variantObj: { ...variantObj, uomName: undefined } });
                }}
              />
            </Col>
          </Row>
          <Row gutter={[16, 4]}>

            <Col span={12} className="drawer-label">
              <FormattedMessage
                id="drawer.inventory.select.totalCost"
                defaultMessage=""
              />
              {" "}
              <span style={{ color: "#0075FF" }}>
              {getCurrencySymbol(props.companyInfo?.currencyCode)}{Number(variantObj.purchasedPrice) + Number(variantObj.landedCost)}
              </span>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>

            <Col span={24}>Purchase Cost + Landed cost = Total Cost</Col>
          </Row>
          <Row gutter={[16, 8]}>

            <Col span={12} className="drawer-label">
              <FormattedMessage
                id="drawer.inventory.select.purchaseCost"
                defaultMessage=""
              />
              {/* <span className="required">*</span> */}

            </Col>
            <Col span={12} className="drawer-label">
              <FormattedMessage
                id="drawer.inventory.select.landedCost"
                defaultMessage=""
              />
            </Col>
          </Row>
          <Row gutter={[16, 16]}>

            <Col 
            // className={
            //   !submittedOnce ||
            //     !(variantObj.purchasedPrice && variantObj.purchasedPrice.length < 2) ||
            //     !submittedOnce ||
            //     !!variantObj.purchasedPrice
            //     ? "product-name-input-error"
            //     : ""
            // } 
            span={12}>
              <div>

              <Input
                  addonBefore={getCurrencySymbol(props.companyInfo.currencyCode)}
                  placeholder={intl.formatMessage({ id: 'drawer.inventory.purchasedPrice.placeholder', defaultMessage: '' })}
                  style={{ height: '38px' }}
                  type="number"
                  disabled={(props.inventoryItemVariantList||[])?.length}
                 
                  value={variantObj.purchasedPrice||0}
                  onChange={(e) => {
                    calculateFieldsValue(e.target.value)
                  }}
                />
                <ErrorMsg
                  validator={() => {
                    return !submittedOnce || !props.qtyCurrentStock || !!variantObj.purchasedPrice;
                  }}
                  message={intl.formatMessage({
                    id: "drawer.inventory.validation.purchaseCost",
                    defaultMessage: "",
                  })}
                />
              </div>
            </Col>
            <Col span={12}>
              <div >
                <Input
                  addonBefore={getCurrencySymbol(props.companyInfo.currencyCode)}

                  value={variantObj.landedCost}
                  placeholder="0.00"
                  disabled
                  onChange={(e) => {
                    updateDrawerState({ variantObj: { ...variantObj, landedCost: e.target.value, maxRetailPrice: Number(variantObj.retailProfitMargin) + Number(variantObj.landedCost || 0) + Number(variantObj.purchasedPrice) } });
                  }}
                />

              </div>
            </Col>
          </Row>
          <Row gutter={[16, 4]}>

            <Col span={24} className="drawer-label">
              <FormattedMessage
                id="drawer.inventory.wholeSalePrice"
                defaultMessage=""
              />
              {" "}
              <span style={{ color: "#0075FF" }}>{getCurrencySymbol(props.companyInfo?.currencyCode)}{variantObj.wholesalePrice}</span>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={24}>Total Cost + Wholesale profit margin = Wholesale Price</Col>
            {/* <Col span={24}>Total Cost + Retail Profit Margin = Selling Retail Price</Col> */}
          </Row>
          <Row gutter={[16, 8]}>

            <Col span={24} className="drawer-label">
              <FormattedMessage
                id="drawer.inventory.wholeSaleProfit"
                defaultMessage=""
              />

            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={24}>

              <Input
                addonBefore={variantObj.isWholesaleProfitMarginInPercentage === 1 ? '%' : getCurrencySymbol(props.companyInfo.currencyCode)}
                placeholder={variantObj.isWholesaleProfitMarginInPercentage === 1 ? 'Enter Percentage' : 'Enter Price'}
                style={{ height: '38px' }}
                type="number"
                // disabled={!hasVariant}
                disabled={hasVariant === 1}

                value={variantObj.wholesaleProfitMargin === 0 ? '' : variantObj.wholesaleProfitMargin}
                onChange={(e) => calculateWholesalePrice(Number(e.target.value),variantObj.isWholesaleProfitMarginInPercentage)}
                addonAfter={
                  <div className="profit-toggle">
              
                    <Switch
                      checked={variantObj.isWholesaleProfitMarginInPercentage === 1}
                     
                        onChange={(checked)=>{
                         
                        calculateWholesalePrice(Number(variantObj.wholesaleProfitMargin), checked?1:0);

                        }}

                       // onClick={(checked) => {
                      //   const updatedIsPercentage = checked ? 1 : 0;
                      //   updateDrawerState({
                      //     isWholesaleProfitMarginInPercentage: updatedIsPercentage,
                      //     variantObj: { ...variantObj, isWholesaleProfitMarginInPercentage: updatedIsPercentage },
                      //     inventoryItemVariantList: [{
                      //       ...props.inventoryItemVariantList[0],
                      //       isWholesaleProfitMarginInPercentage: updatedIsPercentage
                      //     }]
                      //   });
                      // }} 
                    
                    />
                  </div>
                }
              />

            </Col>

          </Row>
          <Row gutter={[16, 4]}>

            <Col span={20} className="drawer-label">
              <FormattedMessage
                id="drawer.inventory.sellingRetailPrice"
                defaultMessage=""
              />
              {" "}
              <span style={{ color: "#0075FF" }}>{getCurrencySymbol(props.companyInfo?.currencyCode)}{variantObj.retailPrice}</span>
              {variantObj.maxRetailPrice > variantObj.retailPrice ? (
                <span style={{ color: "#959595", textDecoration: "line-through", marginLeft: "7px" }}>{getCurrencySymbol(props.companyInfo?.currencyCode)}{variantObj.maxRetailPrice}</span>) : ""}
            </Col>
            {variantObj.maxRetailPrice > variantObj.retailPrice ? (
              <Col span={4}>
                <div className="custom-add">

                  Discount

                  <div className="discount-label">

                    {fixedDecimalAndFormateNumber(discount)}%

                  </div>
                </div>
              </Col>

            )
              : ""
            }
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={24}>Total Cost + Retail Profit Margin = Selling Retail Price</Col>
          </Row>
          <Row gutter={[16, 8]}>

            <Col span={12} className="drawer-label">
              <FormattedMessage
                id="drawer.inventory.select.retailProfit"
                defaultMessage=""
              />
            </Col>
            <Col span={12} className="drawer-label">
              <FormattedMessage
                id="drawer.inventory.select.maxRetail"
                defaultMessage=""
              />
            </Col>
          </Row>
          <Row gutter={[16, 16]}>

            {/* // placeholder={intl.formatMessage({ id: 'drawer.inventory.retailPrice.placeholder', defaultMessage: '' })} */}
            <Col span={12}>

              <Input
                addonBefore={variantObj.isRetailProfitMarginInPercentage === 1 ? '%' : getCurrencySymbol(props.companyInfo.currencyCode)}
                placeholder={variantObj.isRetailProfitMarginInPercentage === 1 ? 'Enter Percentage' : 'Enter Price'}
                style={{ height: '38px' }}
                type="number"
                // value={variantObj.retailProfitMargin}
                // disabled={inventoryItemVariantList.length > 1}
                disabled={hasVariant === 1}
                value={variantObj.retailProfitMargin === 0 ? '' : variantObj.retailProfitMargin}

                onChange={(e) => {
                  // const inputValue = Number(e.target.value);
                  // const totalCost = props.totalCost || 0;
                  // let val;

                  // if (isRetailProfitMarginInPercentage === 1) {
                  //   val = inputValue + totalCost;

                  // } else {
                  //   val = (inputValue / 100) * totalCost + totalCost;

                  // }
                  calculateRetailPrice(Number(e.target.value),variantObj.isRetailProfitMarginInPercentage)

                  // updateDrawerState({
                  //   retailProfitMargin: isRetailProfitMarginInPercentage === 1 ? `${inputValue}%` : null,
                  //   variantObj: {
                  //     ...variantObj, retailProfitMargin: e.target.value,
                  //     maxRetailPrice: isRetailProfitMarginInPercentage === 1 ? val : ((inputValue / 100) * totalCost) + totalCost,
                  //     retailPrice: isRetailProfitMarginInPercentage === 1 ? val : ((inputValue / 100) * totalCost) + totalCost,

                  //   },
                  //   inventoryItemVariantList: [{
                  //     ...props.inventoryItemVariantList[0],
                  //     maxRetailPrice: isRetailProfitMarginInPercentage === 1 ? val : (inputValue / 100) * totalCost + totalCost,
                  //     retailPrice: isRetailProfitMarginInPercentage === 1 ? val : ((inputValue / 100) * totalCost) + totalCost,
                  //     retailProfitMargin: e.target.value

                  //   }]

                  // });
                }}
                addonAfter={
                  <div className="profit-toggle">
                    <Switch
                      checked={variantObj.isRetailProfitMarginInPercentage === 1}

                      // onClick={(checked, e) => {
                      //   updateDrawerState({
                      //     isRetailProfitMarginInPercentage: checked ? 1 : 0,
                      //   });

                      // }}
                      onClick={(checked) => {
                        const updatedIsPercentage = checked ? 1 : 0;
                        // updateDrawerState({
                        //   isRetailProfitMarginInPercentage: updatedIsPercentage,
                        //   inventoryItemVariantList: [{
                        //     ...props.inventoryItemVariantList[0],
                        //     isRetailProfitMarginInPercentage: updatedIsPercentage
                        //   }]

                        // });
                        calculateRetailPrice(Number(variantObj.retailProfitMargin), updatedIsPercentage);
                      }}
                    />
                  </div>

                }
              />


            </Col>
            <Col span={12}>

              <Input
                addonBefore={getCurrencySymbol(props.companyInfo.currencyCode)}
                // disabled={!hasVariant}
                disabled={hasVariant === 1}

                // value={variantObj.maxRetailPrice}

                value={variantObj.maxRetailPrice === 0 ? '' : variantObj.maxRetailPrice}

                placeholder={intl.formatMessage({
                  id: "drawer.inventory.select.enterprice.placeholder",
                  defaultMessage: "",
                })}
                onChange={(e) => {
                  const val = ((Number(e.target.value) - (variantObj.retailPrice || 0)) / Number(e.target.value)) * 100

                  updateDrawerState({
                    discount: val,
                    variantObj: { ...variantObj, maxRetailPrice: e.target.value }
                    , inventoryItemVariantList: [{ ...props.inventoryItemVariantList[0], maxRetailPrice: e.target.value }]
                  })

                }}
              />


            </Col>
          </Row>
        </Skeleton>
        </TabPane>
        {/* Inventory Tab Start here */}
        <TabPane
          tab={intl.formatMessage({
            id: "drawer.inventory.tabNames.inventory",
            defaultMessage: "",
          })}
          key="2"
        >
          <Row gutter={[16, 8]}>
            <Col span={24}>
              <div className="drawer-label">
                <FormattedMessage
                  id="drawer.inventory.warehouse"
                  defaultMessage=""
                />
              </div>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>

            <Col span={24}>
              <Dropdown
                placeholder={intl.formatMessage({
                  id: "drawer.inventory.warehouse.placeholder",
                  defaultMessage: "",
                })}
                items={allWarehouses}
                valueKeyName="warehouseName"
                optionKeyName="warehouseMasterId"
                value={warehouseName}
                moduleName={MODULE_CODED_VALUES.MY_WAREHOUSE}
                canAddNew={true}
                onAddNewClick={(payload) => {
                  payload.formData = {
                    warehouseName: payload.textEntered,
                  };
                  handleNewWarehouseAddition(
                    props,
                    payload,
                    MODAL_TYPE.WAREHOUSE
                  );
                }}
                onSelect={(val, options) => {
                  updateDrawerState({
                    warehouseMasterId: val,
                    warehouseName: options.name,
                  });
                }}
                allowClear={true}
                onClear={() => {
                  updateDrawerState({
                    warehouseName: undefined,
                    warehouseMasterId: undefined,
                  });
                }}
              />
            </Col>
          </Row>

          <Row gutter={[16, 8]}>
            <Col span={12} className="drawer-label">
              {intl.formatMessage({
                id: "drawer.inventory.alert.lowStockLevel",
                defaultMessage: "",
              })}
              <Tooltip
                placement="right"
                title={intl.formatMessage({
                  id: "tooltip.recievingLowStock",
                  defaultMessage: "",
                })}
                trigger={"click"}
              >
                &nbsp; <QuestionCircleTwoTone />
              </Tooltip>
            </Col>

            <Col span={12} className="drawer-label">
              {intl.formatMessage({
                id: "drawer.inventory.alert.reorderLevel",
                defaultMessage: "",
              })}
              <Tooltip
                placement="right"
                title={intl.formatMessage({
                  id: "drawer.inventory.alert.reorderLevel.desc",
                  defaultMessage: "",
                })}
                trigger={"click"}
              >
                &nbsp; <QuestionCircleTwoTone />
              </Tooltip>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Input
                placeholder={intl.formatMessage({
                  id: "drawer.inventory.alert.lowStockLevel.placeholder",
                  defaultMessage: "",
                })}
                style={{ height: "38px" }}
                type="number"
                value={lowStockQty}
                onChange={(e) => {
                  updateDrawerState({
                    lowStockQty: e.target.value,
                  });
                }}
                addonAfter={
                  <Switch
                    checked={velocityAlert === 1}
                    onClick={(checked, e) => {
                      updateDrawerState({
                        velocityAlert: checked ? 1 : 0,
                      });
                      if (checked) {
                      }
                    }}
                  />
                }
              />
            </Col>

            <Col span={12}>
              <Input
                placeholder={intl.formatMessage({
                  id: "drawer.inventory.alert.reorderLevel.placeholder",
                  defaultMessage: "",
                })}
                style={{ height: "38px" }}
                type="number"
                value={reorderQty}
                onChange={(e) => {
                  updateDrawerState({
                    reorderQty: e.target.value,
                  });
                }}
                addonAfter={
                  <Switch
                    checked={aboveThresholdAlert === 1}
                    onClick={(checked, e) => {
                      updateDrawerState({
                        aboveThresholdAlert: checked ? 1 : 0,
                      });
                      if (checked) {
                      }
                    }}
                  />
                }
              />
            </Col>
          </Row>

          <Row gutter={[16, 8]}>
            <Col span={24} className="drawer-label">
              {intl.formatMessage({
                id: "drawer.inventory.alert.aboveThresholdLevel",
                defaultMessage: "",
              })}
              <Tooltip
                placement="right"
                title={intl.formatMessage({
                  id: "drawer.inventory.alert.aboveThresholdLevel.desc",
                  defaultMessage: "",
                })}
                trigger={"click"}
              >
                &nbsp; <QuestionCircleTwoTone />
              </Tooltip>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Input
                placeholder={intl.formatMessage({
                  id: "drawer.inventory.alert.aboveThresholdLevel.placeholder",
                  defaultMessage: "",
                })}
                style={{ height: "38px" }}
                type="number"
                value={outOfStock}
                onChange={(e) => {
                  updateDrawerState({
                    outOfStock: e.target.value,
                  });
                }}
              />
            </Col>
            {/* <div>{intl.formatMessage({ id: 'drawer.inventory.alert.aboveThresholdLevel.desc', defaultMessage: '' })}</div> */}
          </Row>
        </TabPane>
        {/* variant tab start here */}
        <TabPane
          tab={intl.formatMessage({
            id: "drawer.inventory.tabNames.variants",
            defaultMessage: "",
          })}
          key="3"
        >

          <Row gutter={[16, 16]}>
            <Col span={20}>
              <div className="drawer-heading">Add Variants</div>
              <div>Customize variants for size, color, and more to cater to all your customers’ preferences.</div>
            </Col>
            {/* <Col span={24} className="custom-add">
            <Button  onClick={() => addVariants()}>Add Variants</Button>
            </Col> */}

          </Row>
          <ProductDetailsTab {...props} />



        </TabPane>
        {/* Media Tab Start here */}
        {/* <TabPane
          tab={intl.formatMessage({
            id: "drawer.inventory.tabNames.image",
            defaultMessage: "",
          })}
          key="4"
        >
          <Row gutter={[16, 16]}>

            <Col span={24}>

              <div className="drawer-heading">Product Image</div>
              <div>Upload captivating images to make your product stand out.</div>
            </Col>
            <Col span={24}>
              <div className="media-content">

                <Dragger {...uploadImage}>
                  <p className="ant-upload-drag-icon" style={{ fontSize: "24px" }}>
                    <InboxOutlined style={{ fontSize: "24px", background: "0075FF" }} />
                  </p>
                  <p className="ant-upload-text">Click or drag file to this area to upload</p>
                  <p className="ant-upload-hint">
                    Support for a single or bulk upload. Strictly prohibited from uploading company data or other
                    banned files.
                  </p>
                </Dragger>
              </div>
            </Col>
          </Row>


        </TabPane> */}
        {/*Shipping & Tax Tab start here  */}
        <TabPane
          tab={intl.formatMessage({
            id: "drawer.inventory.tabNames.shipping",
            defaultMessage: "",
          })}
          key="4"
        >
          <Row gutter={[16, 16]}>

            <Col span={24}>

              <div className="drawer-heading">Shipping & Tax</div>
              <div>Configure shipping options and tax rules to streamline your sales process.</div>
            </Col>
          </Row>
          <Row gutter={[16, 8]}>

            <Col span={24} className="drawer-label">
              <FormattedMessage
                id="drawer.inventory.shipmentWeight"
                defaultMessage=""
              />

            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Input.Group compact>
                <Input
                  placeholder={intl.formatMessage({
                    id: "shippingWeight.text",
                    defaultMessage: "",
                  })}
                  style={{ width: "80%", height: "38px" }}
                  type="number"
                  maxLength={13}
                  value={inventoryItemVariantList[0]?.itemWeight}
                  disabled
                />

                <Select
                  defaultValue="Kg"
                  style={{ width: "20%" }}
                  disabled
                  value={inventoryItemVariantList[0]?.itemWeightUnit}
                >
                  <Option value="Kg">Kg</Option>
                  <Option value="gm">gm</Option>

                </Select>
              </Input.Group>
            </Col>
          </Row>
          <Row gutter={[16, 8]}>
            <Col
              span={24}
            >
              <FinanceTab {...props} />
            </Col>
          </Row>
          {/* <Row gutter={[16, 8]}>
            <Col span={24} className="drawer-label">
              <FormattedMessage
                id="drawer.inventory.gst"
                defaultMessage=""
              />

            </Col>
            <Col span={24}>
              <div style={{ display: "flex", gap: "5px" }}>Setup GST in store settings to enter GST percentage.

                <div style={{ color: "#5555E3" }}>Set up GST</div>

              </div>

            </Col>
          </Row> */}
          {/* <Row gutter={[16, 8]}>
            <Col

              span={24}
            >
              <TextBox
                placeholder="Enter GST"
                type="text"
                maxLength={150}
                value=""
                className=""
                onKeyUp={handleKeyUp}
                onKeyDown={handleKeyDown}
                onChange={(e) => {
                  updateDrawerState({

                  });
                }}

              />
            </Col>
          </Row> */}



        </TabPane>
        {/*Additional Info Tab start here  */}
        <TabPane
          // style={{ paddingBottom: "20px" }}
          tab={intl.formatMessage({
            id: "drawer.inventory.tabNames.addInfo",
            defaultMessage: "",
          })}
          key="5"
        >
          {/* <div className="mt20"> */}
          <div>
            <Row gutter={[16, 8]}>
              <Col span={12} className="drawer-label">
                <FormattedMessage
                  id="drawer.inventory.searchTags"
                  defaultMessage=""
                />

              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <TextBox
                  maxLength={20}
                  placeholder={intl.formatMessage({
                    id: "drawer.inventory.select.searchTags.placeholder",
                    defaultMessage: "",
                  })}
                  type="text"
                  value={customerStockNumber}
                  onChange={(e) => {
                    updateDrawerState({
                      customerStockNumber: e.target.value,
                    });
                  }}
                />
              </Col>
            </Row>
            <Row gutter={[16, 8]}>

              <Col span={12} className="drawer-label">
                <FormattedMessage
                  id="drawer.inventory.stockNumber"
                  defaultMessage=""
                />
                <Tooltip
                  placement="right"
                  title={intl.formatMessage({
                    id: "tooltip.inventory.stock",
                    defaultMessage: "",
                  })}
                  trigger={"click"}
                >
                  &nbsp; <QuestionCircleTwoTone />
                </Tooltip>
              </Col>

              <Col span={12} className="drawer-label">
                <FormattedMessage
                  id="drawer.inventory.arcticleNumber"
                  defaultMessage=""
                />
                <Tooltip
                  placement="right"
                  title={intl.formatMessage({
                    id: "tooltip.article",
                    defaultMessage: "",
                  })}
                  trigger={"click"}
                >
                  &nbsp; <QuestionCircleTwoTone />
                </Tooltip>
              </Col>
            </Row>
            <Row gutter={[16, 16]}>

              <Col span={12}>
                <TextBox
                  maxLength={20}
                  placeholder={intl.formatMessage({
                    id: "drawer.inventory.customerStockNo.placeholder",
                    defaultMessage: "",
                  })}
                  type="text"
                  value={customerStockNumber}
                  onChange={(e) => {
                    updateDrawerState({
                      customerStockNumber: e.target.value,
                    });
                  }}
                />
              </Col>

              <Col span={12}>
                <TextBox
                  placeholder={intl.formatMessage({
                    id: "drawer.inventory.arcticleNumber.placeholder",
                    defaultMessage: "",
                  })}
                  type="text"
                  maxLength={20}
                  value={articleNumber}
                  onChange={(e) => {
                    updateDrawerState({
                      articleNumber: e.target.value,
                    });
                  }}
                />
              </Col>

            </Row>

            <Row gutter={[16, 8]}>
              <Col span={12} className="drawer-label">
                <FormattedMessage id="drawer.inventory.eanNumber" defaultMessage="" />
                <Tooltip
                  placement="right"
                  title={intl.formatMessage({
                    id: "tooltip.internationalArticle",
                    defaultMessage: "",
                  })}
                  trigger={"click"}
                >
                  &nbsp; <QuestionCircleTwoTone />
                </Tooltip>
              </Col>

              <Col span={12} className="drawer-label">
                <FormattedMessage
                  id="drawer.inventory.isbnNumber"
                  defaultMessage=""
                />
                <Tooltip
                  placement="right"
                  title={intl.formatMessage({
                    id: "tooltip.internationalStandard",
                    defaultMessage: "",
                  })}
                  trigger={"click"}
                >
                  &nbsp; <QuestionCircleTwoTone />
                </Tooltip>
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <TextBox
                  placeholder={intl.formatMessage({
                    id: "drawer.inventory.ean.placeholder",
                    defaultMessage: "",
                  })}
                  type="text"
                  maxLength={13}
                  value={ean}
                  onChange={(e) => {
                    updateDrawerState({
                      ean: e.target.value,
                    });
                  }}
                />
              </Col>

              <Col span={12}>
                <TextBox
                  placeholder={intl.formatMessage({
                    id: "drawer.inventory.asin.placeholder",
                    defaultMessage: "",
                  })}
                  type="text"
                  maxLength={13}
                  value={amazonAsinNumber}
                  onChange={(e) => {
                    updateDrawerState({
                      amazonAsinNumber: e.target.value,
                    });
                  }}
                />
              </Col>
            </Row>
            <Row gutter={[16, 8]}>
              <Col span={12} className="drawer-label">
                <FormattedMessage
                  id="drawer.inventory.asin"
                  defaultMessage=""
                />
                <Tooltip
                  placement="right"
                  title={intl.formatMessage({
                    id: "tooltip.asin",
                    defaultMessage: "",
                  })}
                  trigger={"click"}
                >
                  &nbsp; <QuestionCircleTwoTone />
                </Tooltip>
              </Col>
              <Col span={12} className="drawer-label">
                <FormattedMessage id="serialNumber.text" defaultMessage="" />
              </Col>

            </Row>

            <Row gutter={[16, 16]}>
              <Col span={12}>
                <TextBox
                  placeholder={intl.formatMessage({
                    id: "drawer.inventory.isbn.placeholder",
                    defaultMessage: "",
                  })}
                  type="text"
                  maxLength={13}
                  value={isbn}
                  onChange={(e) => {
                    updateDrawerState({
                      isbn: e.target.value,
                    });
                  }}
                />
              </Col>
              <Col span={12}>
                <TextBox
                  placeholder={intl.formatMessage({
                    id: "serialNumber.text",
                    defaultMessage: "",
                  })}
                  maxLength={20}
                  value={serialNumber}
                  onChange={(e) => {
                    updateDrawerState({
                      serialNumber: e.target.value,
                    });
                  }}
                />
              </Col>
            </Row>
              <Row gutter={[16, 8]}>
              <Col span={12} className="drawer-label">
                <FormattedMessage
                  id="drawer.inventory.partNumber"
                  defaultMessage=""
                />
                <Tooltip
                  placement="right"
                  title={intl.formatMessage({
                    id: "tooltip.part",
                    defaultMessage: "",
                  })}
                  trigger={"click"}
                >
                  &nbsp; <QuestionCircleTwoTone />
                </Tooltip>
              </Col>
              <Col span={12} className="drawer-label">
                <FormattedMessage
                  id="drawer.inventory.materialNumber"
                  defaultMessage=""
                />
                <Tooltip
                  placement="right"
                  title={intl.formatMessage({
                    id: "tooltip.material",
                    defaultMessage: "",
                  })}
                  trigger={"click"}
                >
                  &nbsp; <QuestionCircleTwoTone />
                </Tooltip>
              </Col>
            </Row>
            <Row gutter={[16, 16]}>

              <Col span={12}>
                <TextBox
                  placeholder={intl.formatMessage({
                    id: "drawer.inventory.partNo.placeholder",
                    defaultMessage: "",
                  })}
                  type="text"
                  maxLength={45}
                  value={partNumber}
                  onChange={(e) => {
                    updateDrawerState({
                      partNumber: e.target.value,
                    });
                  }}
                />
              </Col>
              <Col span={12}>
                <TextBox
                  placeholder={intl.formatMessage({
                    id: "drawer.inventory.material.placeholder",
                    defaultMessage: "",
                  })}
                  type="text"
                  maxLength={45}
                  value={props.customerMaterialNumber}
                  onChange={(e) => {
                    updateDrawerState({
                      customerMaterialNumber: e.target.value,
                    });
                  }}
                />
              </Col>
            </Row>

            <Row gutter={24}>
              <Col span={12}>
                <Form.Item></Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item></Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={4}></Col>
              <Col span={20} style={{ paddingLeft: "32px" }}>
                <Form.Item></Form.Item>
              </Col>
            </Row>
          </div>
        </TabPane>
        {/* sku tab start here */}
        <TabPane
          tab={intl.formatMessage({
            id: "drawer.category.tabNames.content",
            defaultMessage: "",
          })}
          key="6"
        >

          <Row gutter={[16, 16]}>

            <Col span={24}>

              <div className="drawer-heading">SEO</div>
              <div>Optimize your product with meta tags to boost its visibility on search engines.</div>
            </Col>
          </Row>
          <Row gutter={[16, 8]}>
            <Col span={24} >
              <div className="drawer-label">
                <FormattedMessage id="drawer.inventory.metaTitle" />
              </div>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>

            <Col span={24}>
              <TextBox
                value={itemPageTitle}
                placeholder="Enter Title Tag"
                maxLength={60}
                onChange={(e) => {
                  updateDrawerState({
                    itemPageTitle: e.target.value,
                  });
                }}
              />

            </Col>
          </Row>
          <Row gutter={[16, 8]}>

            <Col span={24}>
              <div className="drawer-label">
                <FormattedMessage id="drawer.inventory.metaDesc" />

              </div>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <TextArea
                showCount
                maxLength={340}
                onChange={(e) => {
                  updateDrawerState({
                    itemPageDescription: e.target.value,
                  });
                }}
                value={itemPageDescription}
                placeholder="Enter Meta Description"
                style={{
                  height: 100,
                  resize: 'none',
                }}
              />
            </Col>
          </Row>

          <Row gutter={[16, 8]}>


            <Col span={24}>
              <div className="drawer-label">
                Permalink
              </div>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <TextBox
                value={itemPageTitle}
                placeholder="https://abc.alpide.com/products/excellent-noodle-pansit-kanton-small"
                maxLength={60}
                onChange={(e) => {
                  updateDrawerState({
                    itemPageTitle: e.target.value,
                  });
                }}
              />

            </Col>
          </Row>
          <Row gutter={[16, 8]}>
            <Col span={24}>
              <div className="drawer-label">
                Social Sharing Image Preview
              </div>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col>
              <div class="seo-image-wrapper">
                <div class="upload-image">

                </div>
                <Upload
                  fileList={fileList}
                  name="file"

                  onChange={handleChange}
                  beforeUpload={(file) => {
                    // Allow only a single file upload, you can adjust this as needed
                    setFileList([file]);
                    return false;
                  }}
                >
                  <div class="seo-image-upload">
                    {
                      (fileList.length > 0) ? (<Card className="card-img-prev">
                        <img src={URL.createObjectURL(fileList[0])} alt="Preview" width="100px" />
                        {/* <Button className="close-btn-pre" onClick={handleRemove}>
                      <CloseOutlined />
                    </Button> */}
                      </Card>) : (
                        <img
                          className="fav-icon-image"
                          width="100px"
                          height="95px"
                          src={image ? image : storeDef}
                          alt="Fallback"
                        />
                      )
                    }
                    <div style={{
                      position: "absolute", top: "auto", right: "auto",
                      display: `${(fileList.length > 0) ? 'none' : 'block'}`
                    }}>
                      {
                        !(fileList.length > 0 || image) && (<>
                          <p class="add-image">+ Add image</p>
                          <p class="picture-size">(Recommended size : 1200 x 628 px)</p>
                        </>)

                      }
                    </div>

                  </div>
                </Upload>
                <div class="seo-info">
                  <p class="section-text-3 mb8 d-block word-break" style={{ color: "#AEAEAE" }}>
                   {itemPageTitle}
                  </p>

                  <a href="https://mydukaan.io/a2zfurnitures/products/boltonrerererererer/" target="_blank"
                    rel="noopener noreferrer" style={{ color: "#AEAEAE" }}> https://mydukaan.io/a2zfurnitures/products/boltonrerererererer/</a>


                  <p class="text-8 c-gray-4 line-clamp-2 word-break" style={{ color: "#AEAEAE" }}>
                    {itemPageDescription}
                  </p>
                </div>
              </div>
            </Col>

            <Col span={24}>
              <div className="seo-preview" style={{ width: "100%", margin: "0", height: "178px", boxShadow: "5px 5px 6px #D1D0D6" }}>
                <div className="preview">
                  <span> <img src={google} width="50px" alt="google" /> </span>
                  <span style={{ fontSize: "14px", fontWeight: "700" }}>Google Search Preview</span>

                </div>
                <div style={{ fontSize: "16px", fontWeight: "500", color: "blue" }}>{itemPageTitle}</div>
                {/* <div className="seo-data-link"><a href="/"  >{seoData.link}</a></div> */}
                <div style={{ fontSize: "12px" }}>
                 {itemPageDescription}           </div>
              </div>
            </Col>
          </Row>
        </TabPane>
      </Tabs>
    </Drawer>
  );
}

export default injectIntl(InventoryDrawer);