import React, { Fragment,useMemo } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
// import { AgGridReact } from "ag-grid-react";
import { Pagination, Tooltip, Row, Col, Progress, Skeleton, Menu,Dropdown } from "antd";
import { fetchPaginationDataIfNeeded, getMomentDateForUIReadOnly, fixedDecimalNumber, sortTable,getPermissionData } from "../../../utils";
import { CONSTANTS, ICONS, MODULE_CODED_VALUES } from '../../../static/constants';
import ShareTvURLComponent from './ShareTvURL';
import SharePaymentURLComponent from './SharePaymentURL';
import { SortAscendingOutlined, SortDescendingOutlined, ExportOutlined, NodeIndexOutlined, DesktopOutlined, ApiOutlined, MoreOutlined } from '@ant-design/icons';
import PageHeader from "../../Common/pageHeader";


const Campaign = (props) => {
  const { campaignList, updateState, companyInfo, campaignListCount, pageNumber, activeCampaignAnalytics, pageSize, dir=0 } = props;
  const primaryPerm = (props.permissions && props.permissions.primary) || [];

  const permissionsData = useMemo(()=>{
    return getPermissionData(primaryPerm, MODULE_CODED_VALUES.FUNDRAISING )
  },[])

  const showTotal = (total) => {
    return (
      props.intl.formatMessage({
        id: "pagination.text.total",
        defaultMessage: "",
      }) +
      ` ${total || 0} ` +
      props.intl.formatMessage({
        id: "pagination.text.items",
        defaultMessage: "",
      })
    );
  };

  const loadPageData = (pageNumber, pageSize) => {
    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      pageNumber: pageNumber,
      pageSize: pageSize,
    };
    fetchPaginationDataIfNeeded("getFundraisingCampaign", "campaignList", props, payload);
  };

  const handlePageSizeChange = (pageNumber, pageSize, props) => {
    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      pageNumber: pageNumber,
      pageSize: pageSize,
    };
    props.resetPaginatedData();
    props.getFundraisingCampaign(payload);
    props.updateState({ pageSize: pageSize, pageNumber: pageNumber });
  };

  const deleteHandle = (campaign) => {
    const modalBody = <FormattedMessage id='commons.delete.popup.confimation.msg' defaultMessage='' />;
    const modalData = {
      modalBody,
      handleSubmit: () => {
        props.deleteFundraisingCampaign(campaign, props);
      },
    };
    props.showModal(modalData);
  }


  const openCreateCampaignDrawer = () => {
    props.updateHeaderState({
      fundraising: {
        ...props.fundraising,
        addCampaignDrawerVisible: true,
        openCampaignDrawerFromListing: true,
      },
    });
  };

  const breadCrumbList = [
    {
      name: <FormattedMessage id='sidebar.menuItem.fundraising' defaultMessage='' />
    },
    {
      name: <FormattedMessage id='sidebar.menuItem.fundraisingCampaign' defaultMessage='' />,
    }
  ];

  const sortColumn = (e) => {
    sortTable(document.getElementById('campaign-table'), e.target.cellIndex, 1 - dir);
    updateState({ dir: 1 - dir })
  }
  const actionMenu = (e) => {
    return (
        <Menu className="row-action-dropdown">

          <Menu.Item key="0" value="title">
            Actions
          </Menu.Item>

          <Menu.Divider />

        {permissionsData.update && <Menu.Item key="1" value="runCampaign" onClick={()=>{
              props.history.push({
                pathname: '/admin/fundraising-t',
                state: {
                  templateType: 'fundraising',
                  alpideFormBuilderId: e.formBuilderId,
                  fundraisingCampaignId: e.fundraisingCampaignId,
                  alpidePaymentGatewayId: e.alpidePaymentGatewayId,
                  projectMasterId: e.projectMasterId,
                  chartOfAccountDetailsId: e.chartOfAccountDetailsId,
                }
              })
            }}
          >
            <ExportOutlined />  
            Run Campaign
          </Menu.Item>}

          {permissionsData.update &&  <Menu.Item key="2" value="p2p" onClick={()=>{
                props.history.push({
                  pathname: '/admin/campaign-detail',
                  state: {
                    activeCampaignAnalytics,
                    campaignData: e
                  }
                })
              }}
            >
            <NodeIndexOutlined /> 
            P2P
          </Menu.Item>}
          {permissionsData.update && <Menu.Item key="3" value="edit" onClick={()=>{
            props.updateHeaderState({
              fundraising: {
                ...props.fundraising,
                campaignRowData: { ...e },
                addCampaignDrawerVisible: true,
                openCampaignDrawerFromListing: true,
              }
            })
           }}
          >
            <i className={ICONS.EDIT} />
            Edit
          </Menu.Item>}
          {permissionsData.delete && <Menu.Item key="4" value="delete" onClick={()=>{
                deleteHandle(e);
              }}
            >
            <i className={ICONS.DELETE} />
            Delete
          </Menu.Item>}

          <Menu.Item key="4" value="liveStream" onClick={()=>
            {
              props.pushModalToStack({
                title: <div>
                    <FormattedMessage id='shareTvUrl.text' defaultMessage='' />
                    <div style={{ 'fontSize': '12px' }}> {e.campaignName|| ''}</div>
                </div>,
                modalBody: <ShareTvURLComponent {...props} campaignRowData={e} />,
                width: '50%',
                hideFooter: true,
              })
            }
          }>
            <DesktopOutlined />
            Live Stream URL
          </Menu.Item>

          <Menu.Item key="4" value="paymentUrl  " onClick={()=>
            {
              props.pushModalToStack({
                title: <div>
                    <FormattedMessage id='sharePaymentUrl.text' defaultMessage='' />
                    <div style={{ 'fontSize': '12px' }}> {e.campaignName|| ''}</div>
                </div>,
                modalBody: <SharePaymentURLComponent {...props} campaignRowData={e} />,
                width: '50%',
                hideFooter: true,
              })
            }
          }>
            <ApiOutlined />
            Payment Url
          </Menu.Item>

        </Menu >
    )
  };

  return (
    <Fragment>
      <PageHeader {...props}
        pageName="heading.fundRaising.campaign"
        breadCrumbList={breadCrumbList}
        canCreate={true}
        onCreate={openCreateCampaignDrawer}
        moduleCode={MODULE_CODED_VALUES.FUNDRAISING}
      />
      <div>
        {activeCampaignAnalytics ?
          <Row className="compain-conter">
            <Col span={6}>
              <div className="compan-boxanew">
                <div className="com-bocsli bott-border">
                  <h3 >    {activeCampaignAnalytics.campaignName}</h3>
                </div>
                <i className="fa fa-bullhorn" ></i>
                <div className=" top-border">
                  <h3 style={{ padding: '3px 5px' }}> <span className="goal-text"> Goal</span>   ${activeCampaignAnalytics.goal.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} </h3>
                </div>
              </div>
            </Col>
            <Col span={1} >
              <div className="compain-boxer">
                <div className="compn-line"></div>
              </div>
            </Col>
            <Col span={14} >

              <div className="compain-boxer">

                <Row>
                  <Col span={12}> <div className="compan-text">
                    Goal ${activeCampaignAnalytics.goal.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  </div>
                  </Col>

                  <Col span={12} style={{ textAlign: 'right' }}>
                    <div className="compan-text" style={{ marginRight: '10px' }}> Received ${(activeCampaignAnalytics.totalReceived).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </div>
                  </Col>

                </Row>

                <Progress
                  percent={(((activeCampaignAnalytics.totalReceived + (activeCampaignAnalytics.pledgeMade - activeCampaignAnalytics.totalPledgeReceived)) / activeCampaignAnalytics.goal) * 100).toFixed(0)}
                  // percent={(75 / activeCampaignAnalytics.goal) * 100}
                  successPercent={((activeCampaignAnalytics.totalReceived / activeCampaignAnalytics.goal) * 100).toFixed(0)}
                  //successPercent={75 - 40}
                  showInfo={false}
                //trailColor={"white"}
                />
                <Row>

                  <Col span={11}>
                    <div className="compan-text">
                      <div className="compan-cirles"><span></span>Total Received <div style={{ float: 'right', paddingRight: '20px', borderRight: '0px solid #bababa' }}>${(activeCampaignAnalytics.totalReceived).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} ({((activeCampaignAnalytics.totalReceived / activeCampaignAnalytics.goal) * 100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}%) </div></div>
                      <div className="compan-cirles"><span style={{ background: '#98d9eb' }}></span> Projected Total <div style={{ float: 'right', paddingRight: '20px', borderRight: '0px solid #bababa' }}> ${(activeCampaignAnalytics.totalReceived + (activeCampaignAnalytics.pledgeMade - activeCampaignAnalytics.totalPledgeReceived)).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}  ({(((activeCampaignAnalytics.totalReceived + (activeCampaignAnalytics.pledgeMade - activeCampaignAnalytics.totalPledgeReceived)) / activeCampaignAnalytics.goal) * 100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}%) </div></div>
                      {/* <div className="compan-cirles"><span></span>Total Donar {activeCampaignAnalytics.totalDonors}</div> */}
                    </div>
                  </Col>
                  <Col span={2}></Col>
                  <Col span={11} style={{ paddingLeft: '20px' }}>
                    <div className="compan-text">
                      <div className="compan-cirles" ><span style={{ background: 'initial', border: 'solid 1px #ccc' }}></span> Pledge Made  <div style={{ float: 'right' }}>${(activeCampaignAnalytics.pledgeMade).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} ({((activeCampaignAnalytics.pledgeMade / activeCampaignAnalytics.goal) * 100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}%)</div> </div>
                      <div className="compan-cirles"><span style={{ background: 'none', border: 'solid 1px #ccc' }}></span> Pledge Recieved <div style={{ float: 'right' }}> ${(activeCampaignAnalytics.totalPledgeReceived).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} ({((activeCampaignAnalytics.totalPledgeReceived / activeCampaignAnalytics.goal) * 100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}%)</div> </div>
                      <div className="compan-cirles"><span style={{ background: 'none', border: 'solid 1px #ccc' }}></span>  Outstanding Pledge <div style={{ float: 'right' }}>${(activeCampaignAnalytics.pledgeMade - activeCampaignAnalytics.totalPledgeReceived).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} ({activeCampaignAnalytics.pledgeMade ? (((activeCampaignAnalytics.pledgeMade - activeCampaignAnalytics.totalPledgeReceived) / activeCampaignAnalytics.pledgeMade) * 100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0}%)</div> </div>
                      {/* <div className="compan-cirles"><span></span> Pledge Count {activeCampaignAnalytics.pledgeCount} </div> */}
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col span={3}>
              <div className="compn-lines">
                <div className="compn-lines2">
                  {((activeCampaignAnalytics.totalReceived / activeCampaignAnalytics.goal) * 100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}% <span style={{ marginLeft: '10px' }}>Received</span>
                  <br />
                  <span>{(((activeCampaignAnalytics.goal - activeCampaignAnalytics.totalReceived) / activeCampaignAnalytics.goal) * 100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}% <span style={{ marginLeft: '10px' }}>Remaining </span></span>
                </div>
              </div>
            </Col>

          </Row>
          : ''
        }
      </div>
      <div className="view-container">
        <Skeleton loading={props.isLoading}>


          <div className="view-container-actions">
            <div className="left-actions">
              <div className="table-heading">
                <FormattedMessage id='schoolManagement.department.table.heading' defaultMessage='' />
              </div>
              {
                pageSize ? <>
                  <div className="vertical-sep" />
                  <div>{pageSize}</div>
                </>:''
              }
            </div>
            <div className="right-actions">
            </div>
          </div>
          <div className="table-container">
            <table id="campaign-table">
                <thead>
                  <tr>
                    <th onClick={sortColumn}># {dir ? <SortAscendingOutlined /> : <SortDescendingOutlined />}</th>
                    <th width={"12%"} onClick={sortColumn}><FormattedMessage id="fundraising.campaign.listing.header.campaign" defaultMessage="" />  <Tooltip placement="top" title={props.intl.formatMessage({ id: 'campaignName.helpMessag' })} trigger="click">
                        <i className="fa fa-question-circle-o pl5 cursor-pointer" />
                      </Tooltip> {dir ? <SortAscendingOutlined /> : <SortDescendingOutlined />}</th>
                    <th onClick={sortColumn}><FormattedMessage id="fundraising.campaign.listing.header.goal" defaultMessage="" /> {dir ? <SortAscendingOutlined /> : <SortDescendingOutlined />}</th>
                    <th onClick={sortColumn}><FormattedMessage id="received" defaultMessage="" /> {dir ? <SortAscendingOutlined /> : <SortDescendingOutlined />}</th>
                    <th onClick={sortColumn}><FormattedMessage id="fundraising.campaign.listing.header.totalDonars" defaultMessage="" /> {dir ? <SortAscendingOutlined /> : <SortDescendingOutlined />}</th>
                    <th onClick={sortColumn}><FormattedMessage id="fundraising.campaign.listing.header.pledgeCount" defaultMessage="" /> {dir ? <SortAscendingOutlined /> : <SortDescendingOutlined />}</th>
                    <th onClick={sortColumn}><FormattedMessage id="fundraising.campaign.listing.header.startsOn" defaultMessage="" /> {dir ? <SortAscendingOutlined /> : <SortDescendingOutlined />}</th>
                    <th onClick={sortColumn}><FormattedMessage id="fundraising.campaign.listing.header.endsOn" defaultMessage="" /> {dir ? <SortAscendingOutlined /> : <SortDescendingOutlined />}</th>
                    <th onClick={sortColumn}><FormattedMessage id="fundraising.campaign.listing.header.status" defaultMessage="" /> {dir ? <SortAscendingOutlined /> : <SortDescendingOutlined />}</th>
                    <th onClick={sortColumn}><FormattedMessage id='common.dateCreated' defaultMessage='' /> {dir ? <SortAscendingOutlined /> : <SortDescendingOutlined />}</th>
                    {permissionsData.update || permissionsData.delete ? <th><FormattedMessage id='common.listing.header.action' defaultMessage='' /></th>: "" }
                  </tr>
                </thead>
                <tbody>
                {campaignList[pageNumber].map((cl, i) => {
                  return <tr key={i}>
                    <td >{i + 1}</td>
                    <td ><div onClick={() => {
                      updateState({ activeCampaignAnalytics: cl });
                    }}>{cl.campaignCode? cl.campaignCode+'-': ''}{cl.campaignName}</div></td>
                    <td style={{textAlign: 'end'}}>{(props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " ")}{fixedDecimalNumber(cl.goal)}</td>
                    <td style={{textAlign: 'end'}}>{(props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " ")}{fixedDecimalNumber(cl.totalReceived)}</td>
                    <td>{cl.totalDonors}</td>
                    <td>{cl.pledgeCount}</td>
                    <td><Tooltip placement="topLeft" title={(cl.startDate && getMomentDateForUIReadOnly({ date: cl.startDate, format: CONSTANTS.DISPLAY_TOOLTIP_DATE_FORMAT }))}>
                      {(cl.startDate && getMomentDateForUIReadOnly({ date: cl.startDate, format: CONSTANTS.DISPLAY_DATE_FORMAT }))} 
                    </Tooltip></td>
                    <td><Tooltip placement="topLeft" title={(cl.endDate && getMomentDateForUIReadOnly({ date: cl.endDate, format: CONSTANTS.DISPLAY_TOOLTIP_DATE_FORMAT }))}>
                      {(cl.endDate && getMomentDateForUIReadOnly({ date: cl.endDate, format: CONSTANTS.DISPLAY_DATE_FORMAT }))}
                    </Tooltip></td>
                    <td>{cl.isActive ? 'Active' : 'Inactive'}</td>
                    <td><Tooltip placement="topLeft" title={(cl.dateCreated && getMomentDateForUIReadOnly({ date: cl.dateCreated, format: CONSTANTS.DISPLAY_TOOLTIP_DATE_FORMAT }))}>
                      {(cl.dateCreated && getMomentDateForUIReadOnly({ date: cl.dateCreated, format: CONSTANTS.DISPLAY_DATE_FORMAT }))}
                    </Tooltip></td>
                   
                      {/* <div>
                      <span className="cursor-pointer" onClick={() => {
                        props.history.push({
                          pathname: '/admin/fundraising-t',
                          state: {
                            templateType: 'fundraising',
                            alpideFormBuilderId: cl.formBuilderId,
                            fundraisingCampaignId: cl.fundraisingCampaignId,
                            alpidePaymentGatewayId: cl.alpidePaymentGatewayId,
                            projectMasterId: cl.projectMasterId,
                            chartOfAccountDetailsId: cl.chartOfAccountDetailsId,
                          }
                        })
                      }}>Run Campaign</span> | <span className="cursor-pointer"  title={props.intl.formatMessage({ id: 'sidebar.menuItem.peerToPeer'})} onClick={() => {
                        props.history.push({
                          pathname: '/admin/campaign-detail',
                          state: {
                            activeCampaignAnalytics,
                            campaignData: cl
                          }
                        })
                      }}>P2P</span> | <span className="cursor-pointer" title={props.intl.formatMessage(
                        {
                          id: 'commons.listing.edit.tooltip.msg',
                          defaultMessage: ''
                        })
                      } onClick={
                        () => {
                          props.updateHeaderState({
                            fundraising: {
                              ...props.fundraising,
                              campaignRowData: { ...cl },
                              addCampaignDrawerVisible: true,
                              openCampaignDrawerFromListing: true,
                            }
                          })
                        }}>
                        <i className='fa fa-pencil' />
                      </span> |<span style={{ marginLeft: '5px' }} className="cursor-pointer" title={props.intl.formatMessage(
                        {
                          id: 'commons.listing.delete.tooltip.msg',
                          defaultMessage: ''
                        })
                      } onClick={
                        () => {
                          deleteHandle(cl);
                        }}>
                          <i className='fa fa-trash' />
                      </span> | <span style={{ marginLeft: '5px' }} className="cursor-pointer" title={props.intl.formatMessage({id: 'campaign.tvURL.tooltip.msg' })} onClick={() => {
                            props.pushModalToStack({
                            title: <div>
                                <FormattedMessage id='shareTvUrl.text' defaultMessage='' />
                                <div style={{ 'fontSize': '12px' }}> {cl.campaignName|| ''}</div>
                            </div>,
                            modalBody: <ShareTvURLComponent {...props} campaignRowData={cl} />,
                            width: '50%',
                            hideFooter: true,
                        })
                        }}>
                          <i className="fa fa-television" />
                      </span> | <span style={{ marginLeft: '5px' }} className="cursor-pointer" title={props.intl.formatMessage({id: 'campaign.sharePaymentUrl.tooltip.msg' })} onClick={() => {
                            props.pushModalToStack({
                            title: <div>
                                <FormattedMessage id='sharePaymentUrl.text' defaultMessage='' />
                                <div style={{ 'fontSize': '12px' }}> {cl.campaignName|| ''}</div>
                            </div>,
                            modalBody: <SharePaymentURLComponent {...props} campaignRowData={cl} />,
                            width: '50%',
                            hideFooter: true,
                        })
                        }}>
                          <i className="fa fa-share" />
                      </span>
                      </div> */}
                      {permissionsData.update || permissionsData.delete ? <td>
                    <div className="action-icon">
                        <Dropdown overlay={actionMenu(cl)} trigger={['click']}>
                          <MoreOutlined />
                        </Dropdown>
                      </div>
                    </td>: "" }
                  </tr>
                })}
              </tbody>
            </table>
          </div>
        </Skeleton>
        <div className="footer">
          <div className="f-left"></div>
          <div className="f-right">
            <Pagination
              size="small"
              total={campaignListCount}
              showTotal={showTotal}
              defaultPageSize={props.pageSize}
              showSizeChanger
              pageSizeOptions={[10, 25, 50, 100, 200]}
              // showQuickJumper
              onChange={(pageNumber, pageSize) => {
                loadPageData(pageNumber, pageSize);
                updateState({ pageSize: pageSize, pageNumber: pageNumber });
              }}
              onShowSizeChange={(pageNumber, pageSize) => {
                handlePageSizeChange(pageNumber || 1, pageSize, props);
              }}
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default injectIntl(Campaign);
