import React, { useMemo } from "react";
import { injectIntl, FormattedMessage } from "react-intl";
import { Skeleton, Popover, Menu, Pagination, Input } from "antd";
import {
  InsertRowRightOutlined,
  MoreOutlined,
  UserOutlined,
} from "@ant-design/icons";
import PageBreadcrumb from "../../PageBreadcrumb";
import DropdownAction from "antd/lib/dropdown";
import {
  checkACLPermission,
  getMomentDateForUIReadOnly,
  getPermissionData,
  sortTable,
} from "../../../utils";
import find from "lodash.find";
import PageHeader from "../../Common/pageHeader";
import {
  CONSTANTS,
  ICONS,
  MODULE_CODED_VALUES,
  PERMISSION_VALUES,
} from "../../../static/constants";
import { DownloadOutlined } from "@ant-design/icons";
const { Search } = Input;

const MyPayroll = (props) => {
  const { permissions } = props;
  const primaryPerm = (permissions && permissions.primary) || [];

  const permissionsData = useMemo(() => {
    return getPermissionData(
      primaryPerm,
      MODULE_CODED_VALUES.HRMS_PAYROLL,

    );
  }, []);

  const breadCrumbList = [
    {
      name: "Dashboard",
    },
    {
      name: <FormattedMessage id="sidebar.menuItems.employee" />,
    },
    {
      name: "My Payroll",
    },
  ];

  const actionMenu = (e) => {
    return (
      <Menu className="row-action-dropdown">
        <Menu.Item key="0" value="title">
          Actions
        </Menu.Item>

        <Menu.Divider />
        {permissionsData?.update ? (
          <Menu.Item key="1" value="edit" onClick={() => {}}>
            <i className={ICONS.EDIT} />
            Edit
          </Menu.Item>
        ) : (
          ""
        )}

        {permissionsData?.delete && e.isAccountHolder ? (
          <Menu.Item key="2">
            <i className={ICONS.DELETE} />
            Delete
          </Menu.Item>
        ) : (
          ""
        )}
      </Menu>
    );
  };

  return (
    <>
      <PageHeader
        {...props}
        pageName="heading.team.payrollManagement.myPayroll"
        breadCrumbList={breadCrumbList}
      />

      <div className="view-container">
        <Skeleton
          loading={props.isLoading && !props.company?.employeeDrawerVisible}
        >
          <div className="view-container-actions">
            <div className="left-actions">
              <div className="table-heading">
                <FormattedMessage
                  id="company.payrollMangement.myPayroll.table.heading"
                  defaultMessage=""
                />
              </div>
              {[]?.length ? (
                <>
                  <div className="vertical-sep" />
                  <div>{[]?.length}</div>
                </>
              ) : (
                ""
              )}
            </div>
            <div className="right-actions">
              <Search
                placeholder="Search Payroll"
                value={props.searchedText}
                onChange={(e) => {
                  // updateState({ searchedText: e.target.value, pageNumber: 1 });
                  // onSearchedInputChange(e.target.value, props);
                }}
                onSearch={(searchedText) => {
                  // updateState({ searchedText, pageNumber: 1 });
                  // onSearchedInputChange(searchedText, props);
                  // handleSearchedText(searchedText, props);
                }}
              />
              <DropdownAction
                //  overlay={downloadMenu()}
                trigger={["click"]}
              >
                <span>
                  <DownloadOutlined /> &nbsp;{" "}
                  <FormattedMessage
                    id="common.action.dropdown.downloadAs"
                    defaultMessage=""
                  />
                </span>
              </DropdownAction>
            </div>
          </div>

          <div className="table-container">
            <table id="employee-table">
              <thead>
                <tr>
                  <th>
                    <FormattedMessage id="serial.no" defaultMessage=" " />
                  </th>

                  <th>
                    <FormattedMessage
                      id="company.employeeMangement.employee"
                      defaultMessage=" "
                    />
                  </th>
                  <th>
                    <FormattedMessage
                      id="company.employeeMangement.department"
                      defaultMessage=" "
                    />
                  </th>
                  <th>Month</th>
                  <th>Pay Grade</th>

                  <th>Net Salary</th>
                  <th>To be Paid</th>
                  <th>
                    <FormattedMessage
                      id="company.employeeMangement.status"
                      defaultMessage=" "
                    />
                  </th>

                  { (permissionsData?.update || permissionsData?.delete) ? (
                    <th className="text-center">
                      <FormattedMessage
                        id="company.employeeMangement.action"
                        defaultMessage=" "
                      />
                    </th>
                  ) : (
                    ""
                  )}
                </tr>
              </thead>
              <tbody>
                {[].map((rowData, index) => {
                  return (
                    <tr key={index}>
                      <td>{index + 1}</td>

                      <td>
                        {rowData.employeeCode ? rowData.employeeCode : "-"}
                      </td>

                      <td>
                        {rowData.firstName +
                          (rowData.lastName ? " " + rowData.lastName : "")}
                      </td>

                      <td>{rowData.empType}</td>

                      <td>{rowData.hierarchyName}</td>
                      <td>{rowData.hierarchyCode}</td>
                      <td>{rowData.jobTitleName}</td>

                      <td>{rowData.jobRoleName}</td>

                      <td>
                        {rowData.dateCreated
                          ? getMomentDateForUIReadOnly({
                              date: rowData.dateCreated,
                              format: CONSTANTS.TABLE_DATE_FORMAT,
                            })
                          : "-"}
                      </td>
                      { (permissionsData?.update || permissionsData?.delete) ? (
                        <td>
                          <DropdownAction
                            overlay={actionMenu(rowData)}
                            trigger={["click"]}
                          >
                            <i className={ICONS.MORE} />
                          </DropdownAction>
                        </td>
                      ) : (
                        ""
                      )}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>

          {/* <div className="footer">
              <div className="f-left"></div>
              <div className="f-right">
                <Pagination
                  size="small"
                  total={30}
                  showTotal={showTotal}
                  defaultPageSize={props.pageSize}
                  showSizeChanger
                  pageSizeOptions={[10, 25, 50, 100, 200]}
                  // showQuickJumper
                  onChange={(pageNumber, pageSize) => {
                    loadPageData(pageNumber, pageSize);
                    updateState({ pageSize: pageSize, pageNumber: pageNumber });
                  }}
                  onShowSizeChange={(pageNumber, pageSize) => {
                    handlePageSizeChange(pageNumber || 1, pageSize, props);
                  }}
                />
              </div>
            </div> */}
        </Skeleton>
      </div>
    </>
  );
};

export default injectIntl(MyPayroll);
