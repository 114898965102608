import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getInventoryItemByIds } from './action';
import { popModalFromStack } from './../../../../../actions/commonActions';
import { fetchPaginationDataIfNeeded } from '../../../../../utils';
import ProductvariantComp from '../../../../../components/modal/modalBody/product/ProductQr';
import { generateLabelPdf,generatePDF } from '../../../../../actions/downloadFileAction';
class ProductVariantQr extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageSize: 100,
            pageNumber: 1,
            searchedText: '',
            copies:{}
        };
    }

    componentWillMount() {
        const companyInfo = this.props.companyInfo || {};
        const payload = {
            relationshipId: companyInfo.relationshipId,
            inventoryItemCol:this.props.id || null
           
        }
        this.props.getInventoryItemByIds(payload)


    }

    render() {
        return <ProductvariantComp {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
    }

}


const mapStateToProps = (state) => {
    return {
        lowStockList: state.product.lowStockList,
        productDetailsById:state.product.productDetailsById
    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    getInventoryItemByIds,
    popModalFromStack,
    generateLabelPdf,generatePDF
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ProductVariantQr);