import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import AnalyticsComp from '../../../../../components/modal/modalBody/eStore/Analytics';
import { updateAnalytics } from '../../../../eStore/menus/action';
import { fetchE_StoreData } from '../../../../../actions/commonActions';
class Analytics extends Component {
    constructor(props){
        super(props);
        this.state= {
            isHeaderMenuLoaded:false
        }
    }
       
    componentWillMount() {
       
        const companyInfo = this.props.userInfo || {};
        const payload = {
            relationshipId: companyInfo.relationshipId,
            
        };
    this.props.fetchE_StoreData(payload);


    }
    

    componentWillReceiveProps(props){
        if(!this.state?.isHeaderMenuLoaded && Object.keys(props.storeValues || {}) && (props.storeValues.headerMenu|| props.storeValues.footerMenu) ){
      
            let analytic=JSON.parse(props.storeValues.analytics || '{}');
            
              
            this.setState({
                 isHeaderMenuLoaded: true ,
                 siteVisitCode:analytic?.siteVisitCode,
                 googleTagManagerCode:analytic?.googleTagManagerCode,
                 metaPixelCode:analytic?.metaPixelCode,

              });
          }
    
      }
    
    render() {
        return <>
            <AnalyticsComp {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
        </>
    }

}


const mapStateToProps = (state) => {
    return {
        userInfo: state.common.userInfo,
        relationshipId: state.common.companyInfo.relationshipId,
        storeValues: state.common.store_values,
        permissions: state.auth.permissions,

    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    updateAnalytics,fetchE_StoreData
   
}, dispatch);

export default connect(mapStateToProps,mapDispatchToProps)(Analytics);