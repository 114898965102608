import React from "react";

import { Button, Col, Divider, Row, Skeleton, Tabs ,Switch } from "antd";
import { FormattedMessage } from "react-intl";
import { getCurrencySymbol } from "../../../utils";
import { TextBox } from "../../general/TextBox";
import { Dropdown, DropdownRef } from "../../general/Dropdown";
import { showToasterMessage } from "../../../utils";
import find from "lodash.find";
const {TabPane} = Tabs;



function Preferences(props) {
    let { intl } = props;
    const updateBusinessData = (props) => {
        if (props.companyInfoData.roundingOffSettings && props.companyInfoData.roundingOffSettings.isRoundingOffTotalAmtOnPurchaseInvoice && !props.companyInfoData.roundingOffSettings.roundingOffPurchaseLedgerAccountId) {
            return showToasterMessage({
                messageType: 'error', description: props.intl.formatMessage({ id: 'roundoff.ledger.validation' })
            });
        }
        if (props.companyInfoData.roundingOffSettings && props.companyInfoData.roundingOffSettings.isRoundingOffTotalAmtOnSalesInvoice && !props.companyInfoData.roundingOffSettings.roundingOffSalesLedgerAccountId) {
            return showToasterMessage({
                messageType: 'error', description: props.intl.formatMessage({ id: 'roundoff.ledger.validation' })
            });
        }
        const modalData = {
            modalBody: 'Are you sure you want to save?',
            handleSubmit: () => {
                props.updateBusinessData(props);
            }
        };
        props.pushModalToStack(modalData);
    }

    const rowGutter = [24, 16]
   
    const values="";
    return (
        <div className="account-setting">
<div className="store-model-container cus-space">
   
   <div className="store-main-container">
   

    <div>
    <div className="account-heading">
    <p className="heading">Preferences Settings</p>
    <p className="sub-heading">
    Configure integrations with other systems and applications to streamline data flow and enhance module functionality.   </p>

    </div>
    <div className="account-information">
    
    <div>
                <Row gutter={rowGutter}>
                    <Col span={8}>
                        <span className="i-lable" > <FormattedMessage id='modal.settings.companyInfo.company.updateInventoryOnSalesInvoice' defaultMessage='' /> </span>
                    </Col>
                    <Col span={4}>
                        <Switch
                            checked={(props.companyInfoData.isUpdateInventoryOnSalesInvoice === 1)}
                            onClick={(checked, e) => {
                                props.updateState({
                                    companyInfoData: {
                                        ...props.companyInfoData, isUpdateInventoryOnSalesInvoice: checked ? 1 : 0
                                    }
                                })
                            }} />
                    </Col>
                </Row>
                <Row gutter={rowGutter}>
                    <Col span={8}>
                        <span className="i-lable"> <FormattedMessage id='modal.settings.companyInfo.company.updateInventoryOnPurchaseInvoice' defaultMessage='' /> </span>
                    </Col>
                    <Col span={4}>
                        <Switch
                            checked={(props.companyInfoData.isUpdateInventoryOnPurchaseInvoice === 1)}
                            onClick={(checked, e) => {
                                props.updateState({
                                    companyInfoData: {
                                        ...props.companyInfoData, isUpdateInventoryOnPurchaseInvoice: checked ? 1 : 0
                                    }
                                });
                            }} />
                    </Col>

                    {/* <Col span={1}>
                            <div className="" style={{ 'font-size': '18px', 'cursor': 'pointer', 'margin': '0px 0px 0px 15px' }} onClick={() => {
                                updateBusinessData(props);
                            }}><button className="save-but"><i className="fa fa-floppy-o"></i></button></div>
                        </Col> */}
                </Row>
                <Row gutter={rowGutter}>
                    <Col span={8}>
                        <span className="i-lable"> <FormattedMessage id='modal.settings.companyInfo.setting.stopSalesInvoiceForOutOfStock' defaultMessage='' /> </span>
                    </Col>
                    <Col span={4}>
                        <Switch
                            checked={(props.companyInfoData.isStopSalesInvoiceForOutOfStock === 1)}
                            onClick={(checked, e) => {
                                props.updateState({
                                    companyInfoData: {
                                        ...props.companyInfoData, isStopSalesInvoiceForOutOfStock: checked ? 1 : 0
                                    }
                                });
                            }} />
                    </Col>
                </Row>
            

                <Row gutter={rowGutter}>
                    <Col span={8}>
                        <span className="i-lable"> <FormattedMessage id='modal.settings.companyInfo.company.rountoffTotalOnPurchaseInvoice' defaultMessage='' /> </span>
                    </Col>
                    <Col span={4}>
                        <Switch
                            checked={(props.companyInfoData.roundingOffSettings?.isRoundingOffTotalAmtOnPurchaseInvoice === 1)}
                            onClick={(checked, e) => {
                                props.updateState({
                                    companyInfoData: {
                                        ...props.companyInfoData,
                                        roundingOffSettings: {
                                            ...props.companyInfoData.roundingOffSettings,
                                            isRoundingOffTotalAmtOnPurchaseInvoice: checked ? 1 : 0
                                        }
                                    }
                                });

                            }} />
                    </Col>
                    {(props.companyInfoData.roundingOffSettings?.isRoundingOffTotalAmtOnPurchaseInvoice === 1) ?
                        <Col span={12}>
                            <Dropdown items={props.allLedgerAccounts}
                                valueKeyName='ledgerAccountName'
                                optionKeyName='chartOfAccountDetailsId'
                                value={(props.allLedgerAccounts.length && props.companyInfoData.roundingOffSettings?.roundingOffPurchaseLedgerAccountId) || ''}
                                onSelect={(optionKey) => {
                                    const defaultPurchageRoundOffLedger = find(props.allLedgerAccounts, { chartOfAccountDetailsId: Number(optionKey) })
                                    props.updateState({
                                        defaultPurchageRoundOffLedger,
                                        companyInfoData: {
                                            ...props.companyInfoData,
                                            roundingOffSettings: {
                                                ...props.companyInfoData.roundingOffSettings,
                                                roundingOffPurchaseLedgerAccountId: defaultPurchageRoundOffLedger?.chartOfAccountDetailsId
                                            }
                                        }
                                    })

                                }}
                                placeholder={intl.formatMessage(
                                    { id: 'company.companySetup.ledgerAccounts.salesLedger.placeholder', defaultMessage: '' }
                                )}
                            />
                        </Col>
                        : ''
                    }
                </Row>

                <Row gutter={rowGutter}>
                    <Col span={8}>
                        <span className="i-lable"> <FormattedMessage id='modal.settings.companyInfo.company.rountoffTotalOnSalesInvoice' defaultMessage='' /> </span>
                    </Col>
                    <Col span={4}>
                        <Switch
                            checked={(props.companyInfoData.roundingOffSettings?.isRoundingOffTotalAmtOnSalesInvoice === 1)}
                            onClick={(checked, e) => {
                                props.updateState({
                                    companyInfoData: {
                                        ...props.companyInfoData,
                                        roundingOffSettings: {
                                            ...props.companyInfoData.roundingOffSettings,
                                            isRoundingOffTotalAmtOnSalesInvoice: checked ? 1 : 0
                                        }
                                    }
                                })

                            }} />
                    </Col>
                    {(props.companyInfoData.roundingOffSettings?.isRoundingOffTotalAmtOnSalesInvoice === 1) ?
                        <Col span={12}>
                            <Dropdown items={props.allLedgerAccounts}
                                valueKeyName='ledgerAccountName'
                                optionKeyName='chartOfAccountDetailsId'
                                value={(props.allLedgerAccounts.length && props.companyInfoData.roundingOffSettings?.roundingOffSalesLedgerAccountId) || ''}
                                onSelect={(optionKey) => {
                                    const defaultSalesRoundOffLedger = find(props.allLedgerAccounts, { chartOfAccountDetailsId: Number(optionKey) })
                                    props.updateState({
                                        defaultSalesRoundOffLedger,
                                        companyInfoData: {
                                            ...props.companyInfoData,
                                            roundingOffSettings: {
                                                ...props.companyInfoData.roundingOffSettings,
                                                roundingOffSalesLedgerAccountId: defaultSalesRoundOffLedger?.chartOfAccountDetailsId
                                            }
                                        }
                                    })

                                }}
                                placeholder={intl.formatMessage(
                                    { id: 'company.companySetup.ledgerAccounts.salesLedger.placeholder', defaultMessage: '' }
                                )}
                            />
                        </Col>
                        : ''
                    }
                </Row>

                <Row gutter={rowGutter}>
                    <Col span={8}>
                        <span className="i-lable"> <FormattedMessage id='modal.settings.companyInfo.company.coupon.applied' defaultMessage='' /> </span>
                    </Col>
                    <Col span={4}>
                        <Switch
                            checked={(props.companyInfoData.couponSetting?.isCoupanAppliedBeforeTax === 1)}
                            onClick={(checked, e) => {
                                props.updateState({
                                    companyInfoData: {
                                        ...props.companyInfoData,
                                        couponSetting: {
                                            ...props.companyInfoData.couponSetting,
                                            isCoupanAppliedBeforeTax: checked ? 1 : 0
                                        }
                                    }
                                })

                            }} />
                    </Col>
                    
                        <Col span={12}>
                            <Dropdown items={props.allLedgerAccounts}
                                valueKeyName='ledgerAccountName'
                                optionKeyName='chartOfAccountDetailsId'
                                value={(props.allLedgerAccounts.length && props.companyInfoData.couponSetting?.couponSalesLedgerAccountId) || ''}
                                onSelect={(optionKey) => {
                                    const defaultSalesRoundOffLedger = find(props.allLedgerAccounts, { chartOfAccountDetailsId: Number(optionKey) })
                                    props.updateState({
                                        defaultSalesRoundOffLedger,
                                        companyInfoData: {
                                            ...props.companyInfoData,
                                            couponSetting: {
                                                ...props.companyInfoData.couponSetting,
                                                couponSalesLedgerAccountId: defaultSalesRoundOffLedger?.chartOfAccountDetailsId
                                            }
                                        }
                                    })

                                }}
                                placeholder={intl.formatMessage(
                                    { id: 'company.companySetup.ledgerAccounts.salesLedger.placeholder', defaultMessage: '' }
                                )}
                            />
                        </Col>
                     
                    
                </Row>

            </div>


    </div>
    <div className="">
     <Button type="primary" onClick={()=>{
                    updateBusinessData(props);
                }} >Save Changes</Button>
  </div>
    </div>
   </div>
   

   
   


</div>
        </div>
        
    )
}
export default Preferences;