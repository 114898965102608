import { Card, Col, Row, Tabs, Collapse, Checkbox, Button, Input } from "antd";
import React, { useMemo, useState } from "react";

import "./menus.scss";
import ArrowDown from "../../../assets/images/arrow-down.png";
import ArrowUp from "../../../assets/images/arrow-up.png";
import EqualIcon from "../../../assets/images/equal.png";
import * as find from "lodash.find";
import { v4 as uuidv4 } from "uuid";
import { MODULE_CODED_VALUES } from "../../../static/constants";
import { getPermissionData } from "../../../utils";
import CategoryModel from "./categoryPicker";

const { TabPane } = Tabs;
const { Panel } = Collapse;

const CuratedMenu = (props) => {
    const { pageNumber, allPageListData = [], permissions, productCategories, products, AdditionalSection } = props;
    const primaryPerm = (permissions && permissions.primary) || [];

    const permissionData = useMemo(() => getPermissionData(primaryPerm, MODULE_CODED_VALUES.E_STORE_APPEARANCE), []);

    const [, setUpdatedDisplayName] = useState("");
    const [customLinkData, setCustomLinkData] = useState({ customlink: "", customLinkName: "" });
    const [childOnList, setChildOnList] = useState([]);
    const [selectedCuratedItems, setSelectedCuratedItems] = useState( props.data ||[]);
    const [filters, setFilters] = useState([]);

    // Toggle child category display state
    const isChildOn = (id) => childOnList.includes(id);

    const setChildOff = (id) => {
        setChildOnList(childOnList.filter((childId) => childId !== id));
    };

    // Remove an item from selected items
    const handleRemove = (itemToRemove) => {
        setSelectedCuratedItems(selectedCuratedItems.filter((item) => item !== itemToRemove));
    };

    // Update category name in selected items
    const updateCategoryName = (categoryId, newName) => {
        return selectedCuratedItems.map((item) =>
            item.inventoryItemCategoryId === categoryId ||
                item.itemId === categoryId ||
                item.ecomHeaderPageId === categoryId ||
                item.flagId === categoryId
                ? { ...item, itemName: newName }
                : item
        );
    };
// Updated subCategoryListHandler function
const subCategoryListHandler = (categoryId) => {
    if (isChildOn(categoryId)) {
      setChildOff(categoryId); // collapse subcategory
    } else {
      setChildOnList([...childOnList, categoryId]); // expand subcategory
    }
  };
  
    // Handle display name update
    const handleUpdateName = (categoryId, displayName) => {
        if (displayName) {
            setSelectedCuratedItems(updateCategoryName(categoryId, displayName));
            setUpdatedDisplayName("");
        }
    };

    // Handle custom link input change
    const handleInputChange = (e, field) => {
        setCustomLinkData({ ...customLinkData, [field]: e.target.value });
    };

    // Add custom link to menu
    const handleAddToMenu = () => {
        setSelectedCuratedItems([...selectedCuratedItems, { ...customLinkData, type: "Custom Link", id: uuidv4() }]);
    };

    // Render subcategories recursively
    const getSubcategories = (subcategories, index = 0) => {
        return subcategories?.map((childRowData) => (
            <>
                <Col span={24} key={childRowData.inventoryItemCategoryId} style={{ display: "flex", marginLeft: "20px" }}>
                    <Checkbox
                        onChange={(e) => handleCheckCategory(e)}
                        value={childRowData.inventoryItemCategoryId}
                    >
                        <div className="category-name-style">{childRowData.categoryName}</div>
                    </Checkbox>
                    <div onClick={() => toggleChildDisplay(childRowData.inventoryItemCategoryId)}>
                        {childRowData.subcategories?.length ? (
                            isChildOn(childRowData.inventoryItemCategoryId) ? (
                                <img className="img-Icon" src={ArrowUp} alt="arrow" />
                            ) : (
                                <img className="img-Icon" src={ArrowDown} alt="arrow" />
                            )
                        ) : null}
                    </div>
                </Col>
                {isChildOn(childRowData.inventoryItemCategoryId) && getSubcategories(childRowData.subcategories, index + 1)}
            </>
        ));
    };

    // Toggle child display
    const toggleChildDisplay = (id) => {
        isChildOn(id) ? setChildOff(id) : setChildOnList([...childOnList, id]);
    };

    // Handle selection changes for various item types
    const handleSelectionChange = (e, items, itemType, idField, type) => {
        let updatedSelectedItems = [...selectedCuratedItems];
        const item = find(items, { [idField]: Number(e.target.value) });

        if (e.target.checked) {
            item.type = itemType;
            updatedSelectedItems.push(item);
        } else {
            updatedSelectedItems = updatedSelectedItems.filter(
                (selectedItem) => selectedItem[idField] !== Number(e.target.value)
            );
        }

        setSelectedCuratedItems(updatedSelectedItems);
    };

    const handleCheckProduct = (e) => handleSelectionChange(e, products[pageNumber], "Products", "itemId");
    const handleCheckPage = (e) => handleSelectionChange(e, allPageListData, "Pages", "ecomHeaderPageId");
    const handleCheckCategory = (e) => handleSelectionChange(e, productCategories, "Categories", "inventoryItemCategoryId");
    const handleCheckFlags = (e) => handleSelectionChange(e, selectedCuratedItems, "Flags", "flagId");
    const handleCheckAdditionalSection = (e) => handleSelectionChange(e, AdditionalSection, "blog", "blogId");

    const handleSave = () => {
        if (!selectedCuratedItems || selectedCuratedItems.length === 0) {
          console.log("No items to save.");
          return;
        }
        // selectedCuratedItems.forEach((item, index) => {
        //   console.log(`Item ${index + 1}:`);
        //   console.log("ID:", item.inventoryItemCategoryId || item.ecomHeaderPageId || item.itemId || item.flagId);
        //   console.log("Type:", item.type);
        //   console.log("Name:", item.displayName || item.itemName || item.categoryName || item.customLinkName || item.pageTitle || item.label);
        //   console.log("Custom Link:", item.customlink || "N/A");
        //   console.log("Is Visible:", item.isVisible);
        //   console.log("----------------------------------");
        // });
      
        console.log("All selected curated items have been logged.",selectedCuratedItems);

        if(props.callBack){
            props.callBack(selectedCuratedItems)
            props.popModalFromStack()
        }
      };
      

    return (
        <div className="store-model-container-f">

            <div className="menuEstore-Container" style={{ position: "relative" }}>
                <Row>
                    <Col lg={6} xxl={4}>
                        <Card className="menu-list-card">
                            <div className="card-header">
                                <h2>Header items</h2>
                            </div>
                            <Collapse
                                accordion
                                expandIconPosition="right"
                                defaultActiveKey={["0"]}
                                className="collapse-container"
                            >
                                {/* Pages Panel */}
                                <Panel header="Pages" key="1">
                                    <div className="menu-list-card-inner-wrapper">
                                        <Checkbox.Group
                                            style={{ width: "100%", height: "210px", overflowY: "scroll" }}
                                        >
                                            <Row>
                                                {allPageListData.length > 0 &&
                                                    allPageListData.map((list, index) => (
                                                        <Col span={24} key={index}>
                                                            <Checkbox
                                                                checked={find(selectedCuratedItems || [], {
                                                                    ecomHeaderPageId: Number(list.ecomHeaderPageId),
                                                                })?.ecomHeaderPageId}
                                                                value={list.ecomHeaderPageId}
                                                                onChange={handleCheckPage}
                                                            >
                                                                <div className="category-name-style">
                                                                    <a href={list.pageLink} style={{ color: "black" }}>
                                                                        {list.pageTitle}
                                                                    </a>
                                                                </div>
                                                            </Checkbox>
                                                        </Col>
                                                    ))}
                                            </Row>
                                        </Checkbox.Group>
                                    </div>
                                </Panel>

                                {/* Custom Link Panel */}
                                <Panel header="Custom link" key="2">
                                    <Row>
                                        <Col span={24}>
                                            <label className="i-label">
                                                Link <span className="required">*</span>
                                            </label>
                                        </Col>
                                        <Col span={24}>
                                            <Input
                                                onChange={(e) => handleInputChange(e, "customlink")}
                                                value={customLinkData.customlink}
                                                placeholder="Enter Name"
                                            />
                                        </Col>
                                        <Col span={24} className="mt10">
                                            <label className="i-label">
                                                Name <span className="required">*</span>
                                            </label>
                                        </Col>
                                        <Col span={24}>
                                            <Input
                                                onChange={(e) => handleInputChange(e, "customLinkName")}
                                                value={customLinkData.customLinkName}
                                                placeholder="Enter Name"
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="mt10">
                                        <Button onClick={handleAddToMenu} type="primary">
                                            Add to Menu
                                        </Button>
                                    </Row>
                                </Panel>

                                {/* Products Panel */}
                                <Panel header="Products" key="3">
                                    <div className="menu-list-card-inner-wrapper">
                                        <Checkbox.Group
                                            style={{ width: "100%", height: "210px", overflowY: "scroll" }}
                                        >
                                            <Row gutter={[0, 5]}>
                                                {(props.products[pageNumber] || []).map((product) => (
                                                    <Col span={24} key={product.itemId}>
                                                        <Checkbox
                                                            value={product.itemId}
                                                            checked={true}
                                                            onChange={handleCheckProduct}
                                                        >
                                                            <div className="category-name-style">{product.itemName}</div>
                                                        </Checkbox>
                                                    </Col>
                                                ))}
                                            </Row>
                                        </Checkbox.Group>
                                    </div>
                                </Panel>

                                {/* Categories Panel */}
                                <Panel header="Categories" key="4">
                                    <div className="menu-list-card-inner-wrapper">
                                        <Checkbox.Group
                                            style={{ width: "100%", height: "210px", overflowY: "scroll" }}
                                        >
                                            <Row gutter={[0, 5]}>
                                                {props?.productCategories
                                                    ?.filter((category) => category.parentId === 0)
                                                    .map((category) => (
                                                        <Col
                                                            span={24}
                                                            key={category.inventoryItemCategoryId}
                                                            style={{ display: "flex" }}
                                                        >
                                                            <Checkbox
                                                                value={category.inventoryItemCategoryId}
                                                                checked={true}
                                                                onChange={handleCheckCategory}
                                                            >
                                                                <div className="category-name-style">
                                                                    {category.categoryName}
                                                                </div>
                                                            </Checkbox>
                                                            <div onClick={subCategoryListHandler}>
                                                                {category.subcategories?.length ? (
                                                                    isChildOn(category.inventoryItemCategoryId) ? (
                                                                        <span
                                                                            className="cursor-pointer"
                                                                            onClick={() =>
                                                                                setChildOff(category.inventoryItemCategoryId)
                                                                            }
                                                                        >
                                                                            <img className="img-Icon" src={ArrowUp} alt="arrow" />
                                                                        </span>
                                                                    ) : (
                                                                        <span
                                                                            className="cursor-pointer"
                                                                            onClick={() =>
                                                                                setChildOnList([
                                                                                    ...childOnList,
                                                                                    category.inventoryItemCategoryId,
                                                                                ])
                                                                            }
                                                                        >
                                                                            <img
                                                                                className="img-Icon"
                                                                                src={ArrowDown}
                                                                                alt="arrow"
                                                                            />
                                                                        </span>
                                                                    )
                                                                ) : null}
                                                            </div>
                                                        </Col>
                                                    ))}
                                            </Row>
                                        </Checkbox.Group>
                                    </div>
                                </Panel>

                                {/* Flags Panel */}
                                <Panel header="Flag" key="5">
                                    <div className="menu-list-card-inner-wrapper">
                                        <Row>
                                            {filters?.map((item, i) => (
                                                <Col span={24} key={i}>
                                                    <Checkbox
                                                        checked={item.isVisible}
                                                        value={item.flagId}
                                                        onChange={(e) => {
                                                            const updatedFilters = filters.map((filter) =>
                                                                filter.flagId === e.target.value
                                                                    ? { ...filter, isVisible: !filter.isVisible }
                                                                    : filter
                                                            );
                                                            setFilters(updatedFilters);
                                                            handleCheckFlags(e);
                                                        }}
                                                    >
                                                        <div className="category-name-style">
                                                            <span>{item.label}</span>
                                                        </div>
                                                    </Checkbox>
                                                </Col>
                                            ))}
                                        </Row>
                                    </div>
                                </Panel>

                                {/* Additional Menu Panel */}
                                <Panel header="Additional Menu" key="6">
                                    <div className="menu-list-card-inner-wrapper">
                                        <Row>
                                            {props.AdditionalSection?.map((item, i) => (
                                                <Col span={24} key={i}>
                                                    <Checkbox
                                                        value={item.blogId}
                                                        onChange={handleCheckAdditionalSection}
                                                    >
                                                        <div className="category-name-style">
                                                            <span>{item.label}</span>
                                                        </div>
                                                    </Checkbox>
                                                </Col>
                                            ))}
                                        </Row>
                                    </div>
                                </Panel>
                            </Collapse>
                        </Card>
                    </Col>

                    <Col lg={18} xxl={20}>
                        <Card className="header-structure-card">
                            <div className="card-header">
                                <h2>Header items</h2>
                            </div>

                            <div className="menuEstore-structure-card-content">
                                {(selectedCuratedItems || []).length > 0 ? (
                                    selectedCuratedItems.map((selectedItems, index) => {
                                        const isVisibleItem = !(selectedItems.type === "Flags") || selectedItems?.isVisible;

                                        return (
                                            <div key={index}>
                                                {isVisibleItem && (
                                                    <Row style={{ marginTop: "10px" }}>
                                                        <Col lg={16} xxl={10}>
                                                            <Collapse
                                                                accordion
                                                                expandIconPosition="right"
                                                                activeKey={props.activeKey || 0}
                                                                onChange={(key) => props.updateState({ activeKey: key })}
                                                            >
                                                                <Panel
                                                                    key={index}
                                                                    header={
                                                                        <div className="header-structure-card-collapse-header">
                                                                            <div className="header-structure-card-collapse-header-child">
                                                                                <span>
                                                                                    <img className="img-Icon" src={EqualIcon} alt="icon" />
                                                                                </span>
                                                                                <span className="collapse-header-right">
                                                                                    <span>
                                                                                        {selectedItems.itemName ||
                                                                                            selectedItems.categoryName ||
                                                                                            selectedItems.customLinkName ||
                                                                                            selectedItems.pageTitle ||
                                                                                            selectedItems.label}
                                                                                        {selectedItems.itemName && selectedItems.label && (
                                                                                            <span>&#40; {selectedItems.label} &#41;</span>
                                                                                        )}
                                                                                    </span>
                                                                                </span>
                                                                            </div>
                                                                            <div>{selectedItems?.type}</div>
                                                                        </div>
                                                                    }
                                                                >
                                                                    <Row>
                                                                        {selectedItems?.customlink && (
                                                                            <Col span={24} className="mb10">
                                                                                <Col span={24}>
                                                                                    <div className="i-label">
                                                                                        Link<span className="required">*</span>
                                                                                    </div>
                                                                                </Col>
                                                                                <Input
                                                                                    onChange={(e) => {
                                                                                        const value = { ...selectedItems, customlink: e.target.value };
                                                                                        selectedCuratedItems[index] = { ...value };
                                                                                        setSelectedCuratedItems([...selectedCuratedItems]);
                                                                                    }}
                                                                                    value={selectedItems?.customlink}
                                                                                    placeholder="Link"
                                                                                />
                                                                            </Col>
                                                                        )}

                                                                        <Col span={24}>
                                                                            <div className="i-label">
                                                                                Name<span className="required">*</span>
                                                                            </div>
                                                                        </Col>
                                                                        <Col span={24}>
                                                                            <Input
                                                                                onChange={(e) => {
                                                                                    const value = { ...selectedItems, displayName: e.target.value };
                                                                                    selectedCuratedItems[index] = { ...value };
                                                                                    setSelectedCuratedItems([...selectedCuratedItems]);
                                                                                }}
                                                                                value={selectedItems?.displayName}
                                                                                placeholder={`Rename ${selectedItems?.type?.slice(0, -1)} Name`}
                                                                            />
                                                                        </Col>
                                                                        <Col span={24}>
                                                                            <div className="header-structure-card-buttons-wrapper mt10">
                                                                                <div className="buttons-inner-wrapper">
                                                                                    <span
                                                                                        className="cursor-pointer remove-btn"
                                                                                        onClick={() => handleRemove(selectedItems)}
                                                                                    >
                                                                                        Remove
                                                                                    </span>
                                                                                    <span className="cursor-pointer cancel-btn">Cancel</span>
                                                                                </div>
                                                                                <div>
                                                                                    <Button
                                                                                        onClick={() =>
                                                                                            handleUpdateName(
                                                                                                selectedItems.inventoryItemCategoryId ||
                                                                                                selectedItems.ecomHeaderPageId ||
                                                                                                selectedItems.itemId ||
                                                                                                selectedItems.flagId,
                                                                                                selectedItems.displayName || selectedItems?.blogId
                                                                                            )
                                                                                        }
                                                                                        type="primary"
                                                                                    >
                                                                                        Update
                                                                                    </Button>
                                                                                </div>
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                </Panel>
                                                            </Collapse>
                                                        </Col>
                                                    </Row>
                                                )}
                                            </div>
                                        );
                                    })
                                ) : (
                                    <div className="empty-list">
                                        <p>Start adding menu elements from header items</p>
                                    </div>
                                )}
                            </div>

                            <div className="footer-btn">
                                <Button
                                    disabled={!permissionData.update}
                                    onClick={() => {
                                        handleSave();
                                    }}
                                    type="primary"
                                >
                                    Submit
                                </Button>
                            </div>
                        </Card>
                    </Col>

                </Row>
            </div>

        </div>
    );
};

export default CuratedMenu;