import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ShippingComp from "../../../components/integrations/ShippingTab";
import { showModal, hideModal, pushModalToStack, popModalFromStack } from '../../../actions/commonActions'
import { saveShipStationConfiguration } from '../Shipping/action';


class Shipping extends Component {

    constructor(props) {
        super(props);
        this.state = {

        }
    }

    componentWillMount() {

    }

    componentDidMount() {

        
    }

    render() {
        return <ShippingComp {...this.state} {...this.props} updateState={(data) => { this.setState(data) }} />
    }

}

const mapStateToProps = (state) => {
    return {
        common: state.common,
        companyInfo: state.common.companyInfo,
        permissions: state.auth.permissions
    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    showModal,
    hideModal,
    pushModalToStack,
    popModalFromStack,
    saveShipStationConfiguration
}, dispatch);


export default connect(mapStateToProps, mapDispatchToProps)(Shipping);
