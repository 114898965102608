import axios from 'axios';
import config from '../../../../config/environmentConfig';
import { COMMON_ACTIONS, CUSTOMER_EXPENSE_ACTION_LIST} from '../../../../static/constants';
import { showToasterMessage,getFinancialYear } from '../../../../utils';
const { lang } = require('../../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');

export const fetchCustomerExpenses = (payload, history) => {
  let FY = getFinancialYear(payload.companyInfo);
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/expense/getAllCustomerExpenses?relationshipId=${payload.relationshipId}&pageSize=${payload.pageSize || 10}&pageNumber=${payload.pageNumber}&startDate=${FY.fyStartDate}&endDate=${FY.fyEndDate}`)
      .then(res => {
        const data = {
          pageNo: payload.pageNumber,
          list: res.data
        }
        dispatch({ type: CUSTOMER_EXPENSE_ACTION_LIST.CUSTOMER_EXPENSE_LIST, data});
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if(!err.__isRetryRequest){
          showToasterMessage({messageType: 'error',  description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        }else{
          err.actionToCall = CUSTOMER_EXPENSE_ACTION_LIST.CUSTOMER_EXPENSE_LIST;
          err.data = {
            pageNo: payload.pageNumber,
            list: []
          };
        }
      })
  }
}
export const getCustomerExpensesCount = (payload, history) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/expense/getAllCustomerExpenseCount?relationshipId=${payload.relationshipId}`)
      .then(res => {
        dispatch({ type: CUSTOMER_EXPENSE_ACTION_LIST.CUSTOMER_EXPENSE_COUNT, data: res.data });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if(!err.__isRetryRequest){
          showToasterMessage({messageType: 'error',  description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        }else{
          err.actionToCall = CUSTOMER_EXPENSE_ACTION_LIST.CUSTOMER_EXPENSE_COUNT;
        }
      })
  }
}
export const resetPaginatedData = () => {
  return dispatch => {
    dispatch({ type: CUSTOMER_EXPENSE_ACTION_LIST.CUSTOMER_EXPENSE_LIST_PAGE_RESET });
  }
}