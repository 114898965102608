import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import { showModal, hideModal } from '../../../../../actions/commonActions';


import { pushModalToStack } from '../../../../../actions/commonActions';
import { fetchE_StoreData } from '../../../../../actions/commonActions';
import { updateOrCreateEcomSettings } from '../../../../eStore/menus/action';

import SeoComp from '../../../../../components/modal/modalBody/eStore/Seo';
//import { info } from 'sass';
class Seo extends Component {
    constructor(props){
        super(props);
        this.state= {
          isLoaded: false,
          socialSharingImage:'',
          homePageDescription:'',
          homePageTitle:'',
        }
    }
   
    componentDidMount() {
      const companyInfo = this.props.companyInfo || {};
      const payload = {
        relationshipId: companyInfo.relationshipId,
        pageNumber: 1,
        pageSize: 100
      };
   
    
     this.props.fetchE_StoreData(payload)
    }
    componentWillReceiveProps(props){
      if(!this.state.isHeaderMenuLoaded && Object.keys(props.storeValues || {}) ){
        
        let value = props.storeValues;
            
            this.setState({ isHeaderMenuLoaded: true ,homePageTitle: value?.homePageTitle , homePageDescription: value?.homePageDescription , socialSharingImage: value?.socialSharingImage})
           


          
       
      }
  
    }
  

    render() {
        return <>
            <SeoComp {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
        </>
    }

}


const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    userInfo: state.common.userInfo,
    relationshipId: state.common.companyInfo.relationshipId,
    storeValues: state.common.store_values,
    permissions: state.auth.permissions,

  };
};

  const mapDispatchToProps = (dispatch) => bindActionCreators({

     pushModalToStack,
     updateOrCreateEcomSettings,
    fetchE_StoreData,
    showModal,
    hideModal
  }, dispatch);
export default connect(mapStateToProps,mapDispatchToProps)(Seo);