import React, { Fragment } from 'react';
import { Row, Col } from 'antd';
import PresentIcon from '../../../assets/images/presentIcon.png';
import AbsentIcon from '../../../assets/images/absentIcon.png';


const TotalAttendance = (props) => {


    return <>
      <div className="coming-soon-text">Coming soon</div>
      <div className="background-blur">
        <div className='attendance_container'>
            <Row gutter={[16, 8]}>
                <Col span={8}>
                    <div className='label_heading'>
                        <span className='ml5'>Teachers</span>

                    </div>
                    <Row gutter={[16, 8]}>
                        <Col span={24}>
                            <div className='attendance_label'>

                                Present
                                <img src={PresentIcon} />
                            </div>
                        </Col>
                        <Col span={24}>
                            <div className='attendance_label'>

                                Absent
                                <img src={AbsentIcon} />

                            </div>
                        </Col>

                    </Row>
                </Col>

                <Col span={8}>
                    <div className='label_heading'>
                        <span className='ml5'>Students</span>

                    </div>

                    <Row gutter={[16, 8]}>
                        <Col span={24}>
                            <div className='attendance_label'>

                                Present
                                <img src={PresentIcon} />

                            </div>
                        </Col>
                        <Col span={24}>
                            <div className='attendance_label'>

                                Absent
                                <img src={AbsentIcon} />

                            </div>
                        </Col>
                    </Row>
                </Col>

                <Col span={8}>
                    <div className='label_heading'>
                        <span className='ml5'>Staff</span>

                    </div>

                    <Row gutter={[16, 8]}>
                        <Col span={24}>
                            <div className='attendance_label'>

                                Present
                                <img src={PresentIcon} />

                            </div>
                        </Col>
                        <Col span={24}>
                            <div className='attendance_label'>

                                Absent
                                <img src={AbsentIcon} />

                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
        </div>
    </>
};

export default TotalAttendance;