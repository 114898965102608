import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import DocumentApprovalComponent from '../../../../../components/modal/modalBody/settings/DocumentApproval';
import { pushModalToStack, fetchAllEmployees } from '../../../../../actions/commonActions';
import { fetchDataIfNeeded } from '../../../../../utils';
import { fetchAllApprovalSetting, saveOrUpdateDocApproval } from './action';
import { CONSTANTS_TRANSACTIONS, CONSTANTS_TRANSACTIONS_KEY } from '../../../../../static/constants';
class DocumentsApproval extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tabDocumentOptions: [
                CONSTANTS_TRANSACTIONS.INQUIRY,
                CONSTANTS_TRANSACTIONS.SALES_QUOTE,
                CONSTANTS_TRANSACTIONS.RFQ,
                CONSTANTS_TRANSACTIONS.SALES_ORDER,
                CONSTANTS_TRANSACTIONS.SALES_INVOICE,
                CONSTANTS_TRANSACTIONS.PURCHASE_ORDER,
                CONSTANTS_TRANSACTIONS.PURCHASE_INVOICE,
                CONSTANTS_TRANSACTIONS.SALES_PAYMENT,
                CONSTANTS_TRANSACTIONS.PURCHASE_PAYMENT,
                CONSTANTS_TRANSACTIONS.CREDIT_MEMO,
                CONSTANTS_TRANSACTIONS.DEBIT_MEMO,
                CONSTANTS_TRANSACTIONS.INBOUND_DELIVERY],
            docName: CONSTANTS_TRANSACTIONS.INQUIRY,
            docNameKey: CONSTANTS_TRANSACTIONS_KEY.Inquiry
        }
    }
    componentDidMount() {
        const companyInfo = this.props.companyInfo || {};
        const payload = { relationshipId: companyInfo.relationshipId};
        fetchDataIfNeeded('fetchAllApprovalSetting', 'docApprovalSettings', this.props, payload);
        this.props.fetchAllEmployees(payload);
    }

    render() {
        return <div>
            <DocumentApprovalComponent {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
        </div>
    }

}


const mapStateToProps = (state) => {
    return {
        companyInfo: state.common.companyInfo,
        docApprovalSettings: state.settings.docApprovalSettings,
        employeeList: state.common.employees,
        permissions: state.auth.permissions,

    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchAllApprovalSetting, 
    saveOrUpdateDocApproval,
    pushModalToStack,
    fetchAllEmployees,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(DocumentsApproval);