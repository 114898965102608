import { Button, Col, Row, Switch, Tabs } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import "../../../../eStore/storeSettings/store-settings.scss";
import { TextBox } from "../../../../general/TextBox";
import { FormattedMessage } from "react-intl";
import { getPermissionData } from "../../../../../utils";
import { MODULE_CODED_VALUES } from "../../../../../static/constants";
const { TabPane } = Tabs;
const CheckoutComp = (props) => {
    const {permissions} = props;
    const primaryPerm = (permissions && permissions.primary) || [];
    const permissionsData = getPermissionData(primaryPerm, MODULE_CODED_VALUES.E_STORE_SETTING);
    const rowGutter = [24, 16];
    function onTabChange(key, props) {

        props.updateState({
            activeTabKey: key
        })
       
      }
    return (
        <div className="store-model-container cus-space">

           
            <div  className="store-main-container">
                <Row>
                    <Col span={24}>
                        <div className="model-head">
                            <p className="heading">Checkout form</p>
                            <p className="sub-heading">
                            Customers will be able to check out as guests without verifying their mobile number.
          </p>
                        </div>
                    </Col>
                </Row>
                <div  className="model-container">
                <Row gutter={rowGutter}>
                    <Col span={12}>
                    <div>
                    <span className="i-label">First Name</span>
                    <span className="required">*</span>
                    </div>
                       
                        <TextBox
                            placeholder="Eg. John"
                            type="text"
                            disabled={true}
                        />
                    </Col>

                    <Col span={12}>
                        <span className="i-label">Last Name</span>
                        <span className="required">*</span>
                        <TextBox
                            placeholder="Eg. Smith"
                            type="text"
                            maxLength={20}
                            disabled={true}
                        />
                    </Col>

                    <Col span={12}>
                        <span className="i-label">Email Address</span>
                        <span className="required">*</span>
                        <TextBox
                            placeholder="Eg. abc@gmail.com"
                            type="text"
                            maxLength={20}
                            disabled={true}
                        />

                    </Col>

                    <Col span={12}>
                        <span className="i-label">Phone No.</span>
                        <span className="required">*</span>
                        <TextBox
                            placeholder="+1-XXX XXX XXX"
                            type="text"
                            maxLength={20}
                            disabled={true}
                        />
                    </Col>
                    <Col span={12}>
                        <span className="i-label">Street Address 1</span>
                        <span className="required">*</span>
                        <TextBox
                            placeholder="Eg. Main Street"
                            type="text"
                            disabled={true}
                        />
                    </Col>

                    <Col span={12}>
                        <div className="i-label">Street Address 2</div>
                        <TextBox
                            placeholder="Eg. 123"
                            type="text"
                            maxLength={20}
                            disabled={true}
                        />
                    </Col>

                    <Col span={12}>
                        <span className="i-label">City</span>
                        <span className="required">*</span>
                        <TextBox
                            placeholder="Eg. abc"
                            type="text"
                            maxLength={20}
                            disabled={true}
                        />

                    </Col>

                    <Col span={12}>
                        <span className="i-label">State</span>
                        <span className="required">*</span>
                        <TextBox
                            placeholder="Eg. abc"
                            type="text"
                            maxLength={20}
                            disabled={true}
                        />
                    </Col>
                    <Col span={12}>
                        <span className="i-label">Country</span>
                        <span className="required">*</span>
                        <TextBox
                            placeholder="Eg. abc"
                            type="text"
                            maxLength={20}
                            disabled={true}
                        />
                    </Col>
                    <Col span={12}>
                        <span className="i-label">Zip Code</span>
                        <span className="required">*</span>
                        <TextBox
                            placeholder="Eg. 123456"
                            type="text"
                            maxLength={20}
                            disabled={true}
                        />

                    </Col>

                </Row>
                <Row >
                    <Col style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }} span={24}>
                        <div className="model-head">
                            <p className="heading">Additional information</p>
                            <p>Create additional fields to collect extra information from your customers.</p>
                        </div>
                        <div>
                            <Button onClick={() => {
                                props.updateState({
                                    addNewField: true
                                })
                            }} type="primary">Add New</Button>
                        </div>
                    </Col>
                </Row>
                <div>
                <Tabs
          activeKey={props.activeTabKey }
          onChange={(key) => {
            onTabChange(key, props);
          }}
          type="line"
        >

<TabPane tab={'Shipping Fields'} key="shipping">
<Row gutter={rowGutter}>
                    {(props?.additionalValue?.checkoutShippingFormValues||[])?.map((item, i) => {
                        return (
                            <Col span={12}>
                                <div style={{display:"flex", gap:"10px",justifyContent:"end"}}>

                                <div className="cursor-pointer" onClick={() => {
                                   const data = {placeholder:item.placeholder,
                                        field_Name:item.title,
                                        key:item.key,
                                        isRequired: item.required,
                                    index:i}
                                    const shippingVal = {...props.additionalValue ,}
                                props.updateState({                                                                  
                                    addNewField: true,
                                    data,
                                    additionalValue:props.additionalValue
                                })
                            }}>Edit</div>
                                <div className="cursor-pointer" onClick={()=>{
                                    let val = (props.additionalValue?.checkoutShippingFormValues||[])?.filter((data)=>data.key !== item.key )
                                    const shippingVal = {...props.additionalValue ,checkoutShippingFormValues: val}
                                    props.updateState({
                                        additionalValue:shippingVal
                                    })
                                    // props.updateAdditionalValue({
                                    //     additionalValues:JSON.stringify(val || []),
                                    //     relationshipId:props.userInfo.relationshipId
                                    // },props)
                                    
                                }}>Delete</div>
                                </div>
                                <span className="i-label">{item.title}</span>
                               {item?.required ? ( <span className="required">*</span>):""}
                                <TextBox
                                    placeholder={item.placeholder}
                                    type="text"
                                    maxLength={20}
                                    disabled={true}
                                />
                            </Col>
                        )
                    })}
                </Row>
          </TabPane>
          <TabPane tab={'Billing Fields'} key="billing">
          <Row gutter={rowGutter}>
                    {(props?.additionalValue?.checkoutBillingFormValues||[])?.map((item, i) => {
                        return (
                            <Col span={12}>
                                <div style={{display:"flex", gap:"10px",justifyContent:"end"}}>

                                <div className="cursor-pointer" onClick={() => {
                                   const data = {placeholder:item.placeholder,
                                        field_Name:item.title,
                                        isRequired: item?.required,
                                        key:item.key,
                                    index:i}
                                props.updateState({                                                                  
                                    addNewField: true,
                                    data,
                                    additionalValue:props.additionalValue
                                })
                            }}>Edit</div>
                                <div className="cursor-pointer" onClick={()=>{
                                    let val = (props.additionalValue?.checkoutBillingFormValues||[])?.filter((data)=>data.key !== item.key )
                                    const billingVal = {...props.additionalValue ,checkoutBillingFormValues: val}
                                    props.updateState({
                                        additionalValue: billingVal
                                    })
                                   
                                    
                                }}>Delete</div>
                                </div>
                                <div className="i-label">{item.title}</div>
                                <TextBox
                                    placeholder={item.placeholder}
                                    type="text"
                                    maxLength={20}
                                    disabled={true}
                                />
                            </Col>
                        )
                    })}
                </Row>
          </TabPane>
            </Tabs>
                </div>
                </div>
              

                
            </div>
            <div className="footer-btn" >
                <Button type="primary"
                 disabled={!(permissionsData.update)}

                onClick={()=>{
                     const modalData ={
                        modalBody: (
                          <FormattedMessage
                            id="customer.salesQuote.edit.form.confirmation"
                            defaultMessage=""
                          />
                        ),
                        handleSubmit: () => {
                          props.hideModal()
                          props.updateAdditionalValue({
                            additionalValues:JSON.stringify(props.additionalValue || {}),
                            relationshipId:props.userInfo.relationshipId
                        },props)
                         
                        }
                       }
                       props.showModal(modalData)
                   
                }} >Submit</Button>
            </div>

        </div>
    );
};

export default CheckoutComp;
