import React, { useMemo } from "react";
import { Empty, Skeleton, Switch, Pagination } from "antd";
import { injectIntl, FormattedMessage } from "react-intl";
import RatingPreview from "../../modal/modalBody/product/RatingPreview";
import ReactHtmlParser from 'react-html-parser';
import { getAWSFileURL, getPermissionData } from "../../../utils";
import { MODULE_CODED_VALUES } from "../../../static/constants";


const PublishedListing = (props) => {
  const { publishedList, companyInfo, pageNumber, permissions } = props;
  const primaryPerm = (permissions && permissions.primary) || [];
  const permissionsData = useMemo(() => {
    return getPermissionData(primaryPerm, MODULE_CODED_VALUES.UOM);
  }, [])
  const showTotal = (total) => {
    return (
      props.intl.formatMessage({
        id: "pagination.text.total",
        defaultMessage: "",
      })
      +
      ` ${total} ` +
      props.intl.formatMessage({
        id: "pagination.text.items",
        defaultMessage: "",
      })
    );
  };
  const loadPageData = (pageNumber, pageSize) => {
    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      pageNumber: pageNumber,
      pageSize: pageSize,
    };
    props.fetchPublishAndUnpublishList({ ...payload, isPublished: 1 });

  };

  const handlePageSizeChange = (pageNumber, pageSize) => {

    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      pageNumber: pageNumber,
      pageSize: pageSize,
    };
    props.fetchPublishAndUnpublishList({ ...payload, isPublished: 1 });
    props.updateState({ pageSize: pageSize, pageNumber: pageNumber });
  };
  function removeTags(html) {
    return html.replace(/<[^>]+>/g, '');
  }
  const openLongtText = (title, Text) => {
    props.showModal({
      title: title,
      modalBody: <div style={{ maxHeight: '550px', overflow: 'auto' }}>{ReactHtmlParser(Text)}</div>,
      hideFooter: true,
      width: '70%'
    })
  }
  return (
    <>
      <div className="view-container-actions">
        <Skeleton loading={props.headerLoading} paragraph={false} rows={1} active>
          <div className="left-actions">
            <div className="table-heading">
              Published List
            </div>

            <>
              <div className="vertical-sep" />
              <div>{props.publishCount || 0}</div>
            </>

          </div>
          <div className="right-actions">

          </div>
        </Skeleton>
      </div>
      <div className="table-container">
        <Skeleton loading={props.listLoading} paragraph={{ rows: 15 }} active>
          <table>
            <thead>
              <tr>
                <th>S.No.</th>
                <th>SO #</th>
                <th>Product Name</th>
                <th>Rating</th>
                <th>Review</th>
                <th>Reviewed By</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {(publishedList[pageNumber || 1] || []).length
                ? publishedList[pageNumber || 1].map((item, i) => {
                  const image = getAWSFileURL(item?.image, props.companyInfo.bucketName, item?.folderName)

                  return (
                    <tr>
                      <td> {(pageNumber - 1) * props.pageSize + (i + 1)}</td>
                      <td>{item.soNumber}</td>
                      <td>{item.itemName}</td>
                      <td>  <span className="link" onClick={() =>
                        props.showModal({
                          title: (<div className="ant-modal-title">
                            <FormattedMessage id="product.rating.heading.productRatingPreview" />
                          </div>),
                          modalBody: <RatingPreview {...props} product={item} />,
                          width: 700,
                          hideFooter: true,

                        })
                      }>

                        {item.rating}
                      </span></td>
                      <td >
                        {
                          item.message ? (<div>
                            {
                              (removeTags(item.message) || '')?.length < 20 ?
                                (<pre

                                >{ReactHtmlParser(item.message)}</pre>) : (<div><pre

                                >{removeTags(item.message).slice(0, 20)}</pre><span className="more-details-btn" onClick={() => { openLongtText('Review', item.message) }}>Show More</span></div>)
                            }


                          </div>) : <div className="empty-data"></div>
                        }

                      </td>
                      <td>{item.customerName}</td>
                      <td>
                        <div className="status-toggle">

                          <Switch
                            checked={item.isPublished}
                            disabled={!(permissionsData?.update)}
                            onChange={(checked) => {
                              const payload = {
                                ...item,
                                relationshipId: props.companyInfo.relationshipId,
                                itemName: item.itemName,
                                rating: item.rating,
                                message: item.message,
                                customerName: item.customerName,
                                updatedByUserId: item.updatedByUserId,
                                feedbackId: item.feedbackId,

                              };
                              props.updateFeedback({ ...payload, isPublished: checked ? 1 : 0 }, image, props);

                            }}
                          />
                        </div>
                      </td>


                    </tr>

                  )
                }) :
                <tr key="empty-data-box">
                  <td colSpan={"100%"} style={{ borderBottom: "none" }}>
                    <Empty />
                  </td>
                </tr>
              }
            </tbody>
          </table>
        </Skeleton>
      </div>

      <div className="footer">
        <div className="f-left"></div>
        <div className="f-right">
          <Pagination
            current={props.pageNumber || 1}
            size="small"
            total={props.publishCount}
            showTotal={showTotal}
            defaultPageSize={props.pageSize || 100}
            showSizeChanger
            pageSizeOptions={[10, 25, 50, 100, 200]}
            onChange={(pageNumber, pageSize) => {
              loadPageData(pageNumber, pageSize);
              props.updateState({ pageSize: pageSize, pageNumber: pageNumber });
            }}
            onShowSizeChange={(pageNumber, pageSize) => {
              handlePageSizeChange(pageNumber || 1, pageSize, props);
            }}
          />
        </div>
      </div>

    </>
  )
}
export default injectIntl(PublishedListing);