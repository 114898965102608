import React, { Component, Fragment } from 'react';
import { Row, Col, Button, Checkbox, Tooltip } from 'antd';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { CustomAgGrid } from '../../../../general/CustomAgGrid';
import ReactHtmlParser from 'react-html-parser';
import { showToasterMessage } from '../../../../../utils';
import filter from 'lodash.filter';
import { CONSTANTS } from '../../../../../static/constants';
import { Dropdown } from '../../../../general/Dropdown';
import { Form } from '@ant-design/compatible';
import find from 'lodash.find';



class PackageLabel extends Component {
    constructor(props){
        super(props);
        this.state={
            slectedPdfSize: '62,100',
            companyRelationshipBillingAddress: props.companyRelationshipBillingAddress,
            shippingAddress: find((this.props.addresses || {}).ShippingAddress || [], { isDefault: 1 })
        }
    }

    
    render() {

        
        let allRelationshipBillingAddress = filter(this.props.companyInfo.boLocationList, { locationType: CONSTANTS.RELATIONSHIP_BILLING_ADDRESS }) || [];
        const columnDefs = [
            {
                field: 'isChecked',
                colId: 'isChecked_1',
                headerComponentFramework: () => <Checkbox
                    onChange={(e) => {
                        this.props.updateState({
                            isAllChecked: e.target.checked
                        })
                        this.gridApi.forEachNode(obj => {
                            obj.setDataValue('isChecked_1', e.target.checked);
                        })
                    }}
                />,
                cellRendererFramework: (params) => {
                    return <Checkbox
                        onChange={(e) => {
                            params.node.setDataValue('isChecked_1', e.target.checked);
                            params.node.data.isChecked = e.target.checked;
                        }}
                        checked={params.data.isChecked} />
                },
                width: 80,
                resizable: false
            },
            {
                headerComponentFramework: () => {
                    return <FormattedMessage id='modal.txDetails.package.labelDownload.itemName' defaultMessage="" />;
                },
                field: "materialName",
                editable: true,
                cellRendererFramework: (params) => {
                    setTimeout(() => {
                        if (params.reactContainer && params.reactContainer.scrollHeight > 30 && params.reactContainer.scrollHeight >= (params.node.data.minHeight || 0)) {
                            params.eParentOfValue.style.height = 'inherit';
                            params.eParentOfValue.style.height = params.reactContainer.scrollHeight;
                            params.node.data.maxHeight = params.reactContainer.scrollHeight;
                            params.node.setRowHeight(params.reactContainer.scrollHeight);
                            this.gridApi && this.gridApi.onRowHeightChanged();
                        }
                    }, 200)
                    return ReactHtmlParser(params.data.materialName)
                },
                resizable: true,
                minWidth: 230
            },

            {
                headerComponentFramework: () => {
                    return <FormattedMessage id='modal.txDetails.package.labelDownload.poNumber' defaultMessage="" />;
                },
                field: "po",
                resizable: true,
                editable: true,
                width: 200
            },
            {
                headerComponentFramework: () => {
                    return <FormattedMessage id='modal.txDetails.package.labelDownload.poLineNumber' defaultMessage="" />;
                },
                field: "poLineNo",
                resizable: true,
                editable: true,
                width: 200
            },
            {
                headerComponentFramework: () => {
                    return <FormattedMessage id='modal.txDetails.package.labelDownload.stockNumber' defaultMessage="" />;
                },
                field: "stockNo",
                editable: true,
                resizable: true,
                width: 200
            },
            {
                headerComponentFramework: () => {
                    return <FormattedMessage id='modal.txDetails.package.labelDownload.qty' defaultMessage="" />;
                },
                field: "qty",
                resizable: true,
                editable: true,
                width: 200
            },
            {
                field: 'action',
                width: 200,
                pinned: 'right',
                cellRendererFramework: (params) => <div>
                    {(params.data.origination !== 'System') ? <div>
                        <div>
                            {params.node.data.isToEdit &&
                                <div className="table-cell-action">
                                    <Link onClick={() => {
                                        this.gridApi.getDisplayedRowAtIndex(params.node.rowIndex).data.relationshipId = (this.props.companyInfo || {}).relationshipId;
                                        this.gridApi.getDisplayedRowAtIndex(params.node.rowIndex).data.isToEdit = false;
                                        this.gridApi.refreshCells({ force: true });
                                        this.gridApi.stopEditing();
                                    }} >
                                        <i className="fa fa-save" />
                                    </Link>
                                    <Link onClick={() => {
                                        this.gridApi.getDisplayedRowAtIndex(params.node.rowIndex).data.isToEdit = false;
                                        this.gridApi.refreshCells({ force: true });
                                        this.gridApi.stopEditing();
                                    }} >
                                        <i className="fa fa-ban" />
                                    </Link>
                                </div>

                            }

                            {!params.node.data.isToEdit &&
                                <div className="table-cell-action" >
                                    <Link onClick={() => {
                                        this.gridApi.forEachNode((rowNode, index) => {
                                            this.gridApi.getDisplayedRowAtIndex(index).data.isToEdit = false;
                                        });
                                        this.gridApi.getDisplayedRowAtIndex(params.node.rowIndex).data.isToEdit = true;
                                        this.gridApi.refreshCells({ columns: ["action"], force: true });
                                        var startEditingParams = {
                                            rowIndex: params.node.rowIndex,
                                            colKey: params.column.getId(),
                                        };
                                        params.api.startEditingCell(startEditingParams);
                                    }}>
                                        <i className="fa fa-edit" />
                                    </Link>

                                    <Link onClick={() => {
                                        downloadSingleLabel(params.node.data)
                                    }}>
                                        <i class="fa fa-download" aria-hidden="true"></i>
                                    </Link>
                                </div>}
                        </div>
                    </div>
                        : <div></div>
                    }
                </div>
            }
        ];

        const getAddressInfoFragment = (obj, additionInfo) => {
            const fragmentObj = <Fragment>
                {
                    obj.streetAddress1
                        ? <div className='billing-address'>{obj.streetAddress1}</div>
                        : ''
                }
                {
                    obj.streetAddress2
                        ? <div className='billing-address'>{obj.streetAddress2}
                        </div>
                        : ''
                }
                {
                    obj.cityName
                        ? <div className='billing-address'>
                            {
                                `${obj.cityName || ''}${obj.stateName ? ', ' + obj.stateName : ''}${obj.zipCode ? ', ' + obj.zipCode : ''}`
                            }
                        </div>
                        : ''
                }
                {
                    additionInfo.workPhone
                        ? <div className='billing-address'>
                            <FormattedMessage id='phone' defaultMessage='' /> - {additionInfo.workPhone}
                        </div> :
                        ''
                }
                {
                    additionInfo.email
                        ? <div className='billing-address'>
                            <FormattedMessage id='email' defaultMessage='' /> - {additionInfo.email}
                        </div> :
                        ''
                }
                {
                    additionInfo.gstNumber
                        ? <div className='billing-address'>
                            <FormattedMessage id='gstIn' defaultMessage='' /> - {additionInfo.gstNumber}
                        </div> :
                        ''
                }
                {
                    additionInfo.panNumber
                        ? <div className='billing-address'>
                            <FormattedMessage id='pan' defaultMessage='' /> - {additionInfo.panNumber}
                        </div> :
                        ''
                }
        
            </Fragment>
        
            return fragmentObj;
        }

        const downloadLabel = () => {
            const pdfList = [];
            this.gridApi.forEachNode(obj => {
                if (obj.data.isChecked) {
                    obj.data.companyInfo = this.props.companyInfo;
                    obj.data.pdfSize = this.state.slectedPdfSize;
                    obj.data.companyRelationshipBillingAddress = this.state.companyRelationshipBillingAddress || '';
                    obj.data.shippingAddress = this.state.shippingAddress || '';
                    pdfList.push(obj.data);
                }
            });
            if (pdfList.length) {
                this.props.generateLabelPdf(pdfList);
            } else {
                showToasterMessage({
                    description: 'Please select package before downloading.',
                    messageType: 'error'
                });
            }
        }


        const downloadSingleLabel = (data) => {
            data.companyInfo = this.props.companyInfo;
            data.pdfSize = this.state.slectedPdfSize;
            data.companyRelationshipBillingAddress = this.state.companyRelationshipBillingAddress;
            data.shippingAddress = this.state.shippingAddress;
            this.props.generateLabelPdf(data);
        }


        return (
            <div>
                  <div className="inner-content">
                        <Row>
                            <Col span={8}>
                                <div className="d-inline-flex w-100">
                                    <Form.Item>
                                        <Row>
                                        <Col span="24" >
                                        <div style={{ textAlign: 'left' }} className='year'>
                                            {this.props.companyInfo.storeName}
                                        </div>
                                        </Col>
                                        <Col span="24" style={{height : "30px"}}>
                                          <span>Bill To</span>
                                          <Tooltip placement="right" title="This is your company billing address. This address will be printed in the footer." trigger="click">
                                            <i className="fa fa-question-circle-o pl5 cursor-pointer" />
                                        </Tooltip>
                                        </Col>
                                         
                                         <Col span="24">
                                         <Dropdown
                                            // style={{ width: '200px', textAlign: 'left' }}
                                            items={allRelationshipBillingAddress}
                                            value={(this.state.companyRelationshipBillingAddress || {}).streetAddress1}
                                            valueKeyName='streetAddress1'
                                            addressLineKey1='streetAddress2'
                                            addressLineKey2='cityName'
                                            addressLineKey3='stateName'
                                            addressLineKey4='zipCode'
                                            optionClassAddress='custom-address-dropdown'
                                            optionKeyName='locationId'
                                            isFavouriteKeyName='isDefault'
                                            onSelect={(value) => {
                                                const selectedRelationshipAddress = find(allRelationshipBillingAddress, { locationId: Number(value) });
                                                this.setState({
                                                    companyRelationshipBillingAddress: selectedRelationshipAddress
                                                });
                                            }}
                                            allowClear={true}
                                            onClear={() => {
                                                this.setState({
                                                    companyRelationshipBillingAddress: undefined,
                                                })
                                            }}
                                        />
                                     

                                        {
                                            getAddressInfoFragment(this.props.relationshipBillingAddress || {}, {}, this.props.companyInfo)
                                        }
                                         </Col>

                                        </Row>
                                    
                                    </Form.Item>


                                </div>
                            </Col>

                            <Col offset={7} span={9}>
                                <div className="d-inline-flex w-100">
                                    <Form.Item>
                                        <Row>
                                          <Col span="24" style={{height : "30px"}}>
                                          <span > Label Size </span>
                                          </Col>
                                          <Col span="24">
                                          <Dropdown
                                                className={"label-dropdown"}
                                                style={{ width: '100%', textAlign: 'left' }}
                                                items={[{ pdfSizeKey: "62,100", pdfSizeValue: "62 x 100 mm" }, { pdfSizeKey: "104,159", pdfSizeValue: "104 x 159 mm" }]}
                                                value={this.state.slectedPdfSize}
                                                onSelect={(value) => {
                                                    this.setState({
                                                        slectedPdfSize: value
                                                    })
                                                }}
                                                valueKeyName='pdfSizeValue'
                                                optionKeyName='pdfSizeKey'
                                            >
                                            </Dropdown>
                                          </Col>

                                          <Col span="24" style={{height : "30px"}}>
                                            <span>Ship To</span>
                                            <Tooltip placement="right" title={`This is  ${this.props.soPackageDetail.companyName} shipping address.`} trigger="click">
                                            <i className="fa fa-question-circle-o pl5 cursor-pointer" />
                                         </Tooltip>
                                          </Col>

                                          <Col span="24">
                                          <Dropdown
                                            // style={{ width: '100%', textAlign: 'left' }}
                                            items={(this.props.addresses || {}).ShippingAddress || []}
                                            value={(this.state.shippingAddress || {}).streetAddress1}
                                            valueKeyName='streetAddress1'
                                            addressLineKey1='streetAddress2'
                                            addressLineKey2='cityName'
                                            addressLineKey3='stateName'
                                            addressLineKey4='zipCode'
                                            optionClassAddress='custom-address-dropdown'
                                            optionKeyName='locationId'
                                            isFavouriteKeyName='isDefault'
                                            onSelect={(value) => {
                                                const selectedShippingAddress = find((this.props.addresses || {}).ShippingAddress || [], { locationId: Number(value) });
                                                this.setState({
                                                    shippingAddress: selectedShippingAddress
                                                });
                                            }}
                                            allowClear={true}
                                            onClear={() => {
                                                this.setState({
                                                    shippingAddress: undefined,
                                                })
                                            }}
                                        />
                                     

                                        {
                                            getAddressInfoFragment(this.props.relationshipBillingAddress || {}, {}, this.props.companyInfo)
                                        }
                                          </Col>
                                     
                                        </Row>
                                       
                                    </Form.Item>


                                </div>
                            </Col>

                        </Row>


                    </div>

                <Fragment>
                    <div className="modal-table-container rfqBidding-supplier-list">
                        <CustomAgGrid
                            columnDefs={columnDefs}
                            rowData={this.props.packageItemList || [{}]}
                            disableFocusOnClickOutside={true}
                            rowDragManaged={true}
                            editType={'fullRow'}
                            suppressClickEdit={true}
                            noSingleClickEdit={true}
                            onGridReady={(params) => {
                                this.gridApi = params.api;
                                this.gridColumnApi = params.columnApi;
                                params.api.sizeColumnsToFit();
                            }}
                            gridStyle={{
                                width: '100%',
                                height: '100%',
                                marginBottom: '10px'
                            }} />
                    </div>
                    <div className="">
                        <div type="flex" className="ant-modal-footer" >
        
                                <Button className="grey-button" onClick={() => {
                                    this.props.popModalFromStack();
                                }}><FormattedMessage id='button.cancel.label' defaultMessage='' /></Button>
        
                                <Button className="ant-btn-primary" onClick={() => {
                                    downloadLabel();
                                }}>Download</Button>
                      
                        

                        </div>
                    </div>
                </Fragment>
            </div>
        )
    }
}

export default PackageLabel;
