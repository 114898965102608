import React, { useMemo } from "react";
import {
  ICONS,
  MODULE_CODED_VALUES,
  PERMISSION_VALUES,
} from "../../../static/constants";
import { FormattedMessage } from "react-intl";
import { Menu, Button, Skeleton, Pagination, Empty } from "antd";
import DropdownAction from "antd/lib/dropdown";
import {
  checkACLPermission,
  getMomentDateForUIReadOnly,
  getPermissionData,
} from "../../../utils";

function BranchComp(props) {
  const { companyInfo, departmentList, pageNumber, pageSize, permissions } =
    props;
  const primaryPerm = (permissions && permissions.primary) || [];
  const permissionsData = useMemo(() => {
    return getPermissionData(primaryPerm, MODULE_CODED_VALUES.COMPANY_HIERARCY);
  }, []);

  const actionMenu = (data) => {
    return (
      <Menu className="row-action-dropdown">
        <Menu.Item key="0" value="title">
          Actions
        </Menu.Item>
        <Menu.Divider />
        {permissionsData?.update ? (
          <Menu.Item
            key="1"
            value="edit"
            onClick={() => {
              props.updateState({
                branchDrawer: true,
                branchInfo: {
                  ...data,
                  location: data.locationList?.length
                    ? data.locationList[0]
                    : [],
                },
              });
            }}
          >
            <i className={ICONS.EDIT} /> Edit
          </Menu.Item>
        ) : (
          ""
        )}

        {permissionsData?.delete ? (
          <Menu.Item
            key="2"
            value="delete"
            onClick={() => {
              props.showModal({
                modalBody: "Are you sure want to delete?",
                handleSubmit: () => {
                  props.deleteBranch(data, props);
                },
              });
            }}
          >
            <i className={ICONS.DELETE} /> Delete
          </Menu.Item>
        ) : (
          ""
        )}
      </Menu>
    );
  };
  return (
    <div className="view-container">
      <div className="view-container-actions">
        <div className="left-actions">
          <div className="table-heading">
            <FormattedMessage
              id="employeeMeta.branch.pageHeading"
              defaultMessage=""
            />
            <div className="table-sub-heading">Manage branch meta here</div>
          </div>
          <div className="vertical-sep" />
          <div>{props.branchCount || ""}</div>
        </div>

        <div className="right-actions">
          <Button
            style={{ backgroundColor: "#1cb961", color: "#ffffff" }}
            className="create-action-btn"
            onClick={() => {
              props.updateState({ branchDrawer: true });
            }}
          >
            <i className={ICONS.ADD} style={{ margin: "4px 5px 0px 0px" }} />{" "}
            <FormattedMessage id="button.create.label" defaultMessage="" />
          </Button>
        </div>
      </div>
      <div className="table-container" style={{ height: "calc(100% - 59px)" }}>
        <table id="leadSource-table">
          <thead>
            <tr>
              <th>
                <FormattedMessage id="serial.no" defaultMessage=" " />
              </th>
              <th>Branch Name</th>
              <th>Primary Branch</th>
              <th>Address</th>
              <th>Date Created</th>
              {permissionsData?.update || permissionsData?.delete ? (
                <th width="6%">Action</th>
              ) : (
                ""
              )}
            </tr>
          </thead>
          <tbody>
            {props.branchList[props.pageNumber || 1].length ? (
              (props.branchList[props.pageNumber || 1] || []).map(
                (rowData, i) => {
                  return (
                    <tr key={"crm" + i}>
                      <td>{i + 1}</td>
                      <td>{rowData.warehouseName || "-"}</td>
                      <td>{rowData.isPrimary ? "Yes" : "No"}</td>
                      <td></td>
                      <td>
                        {rowData.dateCreated
                          ? getMomentDateForUIReadOnly({
                              date: rowData.dateCreated,
                            })
                          : "-"}
                      </td>
                      {permissionsData?.update || permissionsData?.delete ? (
                        <td style={{ paddingLeft: "50px" }}>
                          <DropdownAction
                            overlay={actionMenu(rowData)}
                            trigger={["click"]}
                          >
                            <i className={ICONS.MORE} />
                          </DropdownAction>
                        </td>
                      ) : (
                        ""
                      )}
                    </tr>
                  );
                }
              )
            ) : (
              <tr key="empty-data-box">
                <td colSpan={"100%"} style={{ borderBottom: "none" }}>
                  <Empty />
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div className="footer">
        <Skeleton
          loading={props.headerLoading}
          paragraph={false}
          rows={1}
          active
        >
          <div className="f-left"></div>
          <div className="f-right">
            <Pagination
              size="small"
              total={props.branchCount}
              // showTotal={showTotal}
              defaultPageSize={props.pageSize}
              showSizeChanger
              pageSizeOptions={[10, 25, 50, 100, 200]}
              onChange={(pageNumber, pageSize) => {
                // loadPageData(pageNumber, pageSize);
                props.updateState({
                  pageSize: pageSize,
                  pageNumber: pageNumber,
                });
              }}
              onShowSizeChange={(pageNumber, pageSize) => {
                // handlePageSizeChange(pageNumber || 1, pageSize, props);
              }}
            />
          </div>
        </Skeleton>
      </div>
    </div>
  );
}
export default BranchComp;
