import React , {useMemo} from 'react';
import {  Row, Col, Pagination, Menu, Empty, } from 'antd';
import { CONSTANTS, ICONS,MODULE_CODED_VALUES } from '../../../static/constants';
import { injectIntl, FormattedMessage } from 'react-intl';
import './index.scss';
import DropdownAction from "antd/lib/dropdown";
import PageBreadcrumb from '../../PageBreadcrumb';
import { getMomentLoclDateForUIReadOnly, getMomentDateForUIReadOnly, sortTable, getUserDefinedName, getPermissionData } from '../../../utils';
import ReactHtmlParser from 'react-html-parser';
const format = CONSTANTS.DISPLAY_DATE_FORMAT;


function AssignmentResponse(props) {

    const { companyInfo, assignmentData = {}, updateState, userInfo, assignmentResponseCount, pageNumber,pageSize,permissions, dir = 0 } = props;
const filteredAssignments = (props.assignmentResponseList[pageNumber] || []).filter(e => e.assignmentId === props.assignmentId);
const primaryPerm = (permissions && permissions.primary) || [];

  const permissionsData = useMemo(()=>{
    return getPermissionData(primaryPerm, MODULE_CODED_VALUES.SCHOOL_ASSIGNMENTS )
  },[])
    const openLongtText = (title, Text)=>{
        props.showModal({
            title: title,
            modalBody: <div style={{maxHeight: '550px', overflow: 'auto'}}>{ReactHtmlParser(Text)}</div>,
            hideFooter: true,
            width: '70%'
        })
    }
    const openStudentResponse = (data, responseObj) => {

        props.history.replace({
            pathname: '/admin/school-management/assignment-response-v',
            state: {
              customerId: data.customerId,
              assignmentId: data.assignmentId,
              classId: data.classId,
              studentId: responseObj.studentId,
              assignmentResponseId: responseObj.assignmentResponseId,
            }
          });
    }

    const sortColumn = (e) => {
        let cellIndex = e.target.cellIndex;
        if (!cellIndex) { cellIndex = e.target.parentElement.cellIndex };
        if (!cellIndex) { cellIndex = e.target.parentElement.parentElement.cellIndex };
        sortTable(document.getElementById('studentTable'), cellIndex, 1 - dir);
        updateState({ dir: 1 - dir })
    }


    const showTotal = (total) => {
        return (
            props.intl.formatMessage({
                id: "pagination.text.total",
                defaultMessage: "",
            }) +
            ` ${total || 0} ` +
            props.intl.formatMessage({
                id: "pagination.text.items",
                defaultMessage: "",
            })
        );
    };

    const loadPageData = (pageNumber, pageSize) => {
        const payload = {
            companyInfo,
            relationshipId: companyInfo.relationshipId,
            relationshipEmployeeId: userInfo.relationshipEmployeeId,
            pageNumber: pageNumber || 1,
            pageSize: pageSize || 200,
            assignmentId: (props.assignmentData || {}).assignmentId,
            classId: (props.assignmentData || {}).classId
        };
        props.getSchoolAssignmentResponse(payload);
        props.getSchoolAssignmentResponseCount(payload);
    };

    const handlePageSizeChange = (pageNumber, pageSize, props) => {
        const payload = {
            companyInfo,
            relationshipId: companyInfo.relationshipId,
            relationshipEmployeeId: userInfo.relationshipEmployeeId,
            pageNumber: pageNumber || 1,
            pageSize: pageSize || 200,
            assignmentId: (props.assignmentData || {}).assignmentId,
            classId: (props.assignmentData || {}).classId
        };

        props.resetPaginatedData();
        //props.getTeacher(payload);
        props.updateState({ pageSize: pageSize, pageNumber: pageNumber });
        props.getSchoolAssignmentResponse(payload);
        props.getSchoolAssignmentResponseCount(payload);
    };

    const breadCrumbList = [
        {name: <FormattedMessage id="sidebar.menuItem.school" defaultMessage="" />},
        {
          name: <FormattedMessage id="sidebar.menuItem.assessment" defaultMessage="" />,
        },
        {
            name: 'Assignment List',
            link: '/admin/school-management/assignment'
        },
        {
          name: 'Assignment Response',
        },
      ];


    const actionMenu = (e) =>{
        return <Menu className="row-action-dropdown">
              <Menu.Item value="title"
                key="0"
               
            >
                Actions
            </Menu.Item>
            <Menu.Divider />
          {permissionsData.create ?  <Menu.Item 
                key="1"
                onClick={() => {
                    openStudentResponse(assignmentData, e);
                }}
            >
                <i className={ICONS.VIEW} />  Review & Grade
            </Menu.Item> : "" }
            {permissionsData.delete ? <Menu.Item 
                key="2"
                onClick={() => {
                    props.showModal({
                        modalBody: "Are you sure you want to delete?",
                        handleSubmit: () => {
                            props.deleteAssignedGrade(e, props);
                        }
                    })
                }}
            >
                <i className={ICONS.DELETE} /> Delete
            </Menu.Item> : "" }
    </Menu>
    }

    const labelStyle = {
        fontWeight: 500
    }

    return (
        <>
            <div className="view-top-bar">
                <div className="top-view-left">
                    <div className="page-heading">
                        Assessment
                    </div>
                <div className="vertical-sep" />
                    <PageBreadcrumb breadCrumbList={breadCrumbList} />
                </div>
                <div className="top-view-right">
                </div>
            </div>

            <div className="view-container">
                <div className="view-container-actions">
                    <div className="left-actions">
                        <div className="table-heading">
                        <FormattedMessage
                            id="heading.schoolManagement.assessmentResponse.table"
                            defaultMessage=""
                        />
                        </div>
                        <div className="vertical-sep" />
                        {
                            assignmentResponseCount ? <>
                             
                                <div>{assignmentResponseCount}</div>
                            </>
                            :0
                        }
                    </div>
                    <div className="right-action">
                    </div>
                </div>
                <Row style={{padding: '10px 20px', borderBottom: '1px solid #e7e7e7'}}>
                    <Col span={3} style={labelStyle}>Assignment Name:</Col>
                    <Col span={9}>
                        {assignmentData.assignmentName || ''}
                    </Col>

                    <Col span={3} style={labelStyle}>{getUserDefinedName('Class', props)}:</Col>
                    <Col span={9}>
                        {assignmentData.className || ''}
                    </Col>
            
                    {assignmentData.dueDate ?
                        <>
                            <Col span={3} style={labelStyle}>
                                <FormattedMessage id='common.dueDate' />:</Col>
                            <Col span={9}>
                            { getMomentLoclDateForUIReadOnly({ date: assignmentData.dueDate, format: CONSTANTS.DISPLAY_DATE_FORMAT })}
                            </Col>
                        </>
                        : ''
                    }

                    <Col span={3} style={labelStyle}>Group:</Col>
                    <Col span={9}>
                        {assignmentData.assignmentGroupName}
                    </Col>



                    <Col span={3} style={labelStyle}>Number of Attempts:</Col>
                    <Col span={9}>
                        {assignmentData.maxAttemps || assignmentData.numberOfAttempts}
                    </Col>

                    <Col span={3} style={labelStyle}>Grading System:</Col>
                    <Col span={9}>
                        {assignmentData.gradingSystem}
                    </Col>

                    { (assignmentData?.docDetailList || [])?.length ?
                        <>
                            <Col span={3} style={labelStyle}>Attachemnt:</Col>
                            <Col span={9}>
                                {assignmentData?.docDetailList?.length} <span>{assignmentData?.docDetailList?.length > 1 ? ' Files ' : ' File'}</span>
                            </Col>
                        </>
                        : ''
                    }

                    <Col span={3} style={labelStyle}>Assignment Description:</Col>
                    <Col span={9}>
                        <div style={{ height: props.isSeeMore ? 'auto' : '', overflow: 'hidden' }}>{ReactHtmlParser(assignmentData.assignmentDescription)}</div>
                        {assignmentData.assignmentDescription && <span className='more-details-btn' onClick={()=>{openLongtText('Description', assignmentData.assignmentDescription)}}>Show More</span>}
                    </Col>
                </Row>

                <div className='table-container' style={{height: 'calc(100vh - 330px)'}}>
                    <table id='studentTable'>
                        <thead>
                            <tr>
                                <th width='6%'><FormattedMessage id='serial.no' defaultMessage=''/></th>
                                <th onClick={sortColumn}>Student Name <i className={ICONS.SORT}/></th>
                                <th onClick={sortColumn}>Submission Date <i className={ICONS.SORT}/></th>
                                <th onClick={sortColumn}>Total Attempts <i className={ICONS.SORT}/></th>
                                <th onClick={sortColumn}>{getUserDefinedName('Class', props)} <i className={ICONS.SORT}/></th>
                                <th onClick={sortColumn}>Teacher Comment <i className={ICONS.SORT}/></th>
                                {permissionsData.create || permissionsData.delete ? <th style={{ width: '6%' }}><FormattedMessage id='common.listing.header.action' defaultMessage="" /></th> : "" }
                            </tr>
                        </thead>

                        <tbody>
                            {/* {(props.assignmentResponseList[pageNumber] || []).length ? props.assignmentResponseList[pageNumber]
                                .map((e, i) => { */}
                                {filteredAssignments.length ? filteredAssignments.map((e, i) => { 
                                    return <tr key={'std' + i}>
                                        <td>{(pageNumber-1)*pageSize+(i+1)}</td>

                                        <td>
                                            {`${e.studentFirstName || ''}${e.studentLastName ? ' ' + e.studentLastName : ''}`}
                                        </td>
                                        <td>
                                            {e.dateSubmitted ? getMomentDateForUIReadOnly({ date: new Date(e.dateSubmitted), format }) : ''}
                                        </td>
                                        <td> 
                                            {e.totalAttempts || ''} of {assignmentData.maxAttempts || 1} 
                                        </td>
                                        <td>
                                            {e.className || ''}
                                        </td>
                                        <td>
                                            {ReactHtmlParser(e.teacherComment || '')}
                                        </td>
                                    
                                     {permissionsData.create || permissionsData.delete ?   <td style={{paddingLeft: '50px'}}>
                                            <DropdownAction 
                                                overlay={actionMenu(e)} 
                                                trigger={['click']}
                                            >
                                                <i className={ICONS.MORE_P} />
                                            </DropdownAction>
                                        </td> : "" }
                                    </tr>
                            })
                            :
                            <tr key="empty-data-box">
                                <td colSpan={"100%"} style={{ borderBottom: "none" }}>
                                    <Empty />
                                </td>
                            </tr>
                            }
                        </tbody>
                    </table>
                </div>

                <div className="footer">
                    <div className="f-left"></div>
                    <div className="f-right">
                        <Pagination
                            size="small"
                            total={assignmentResponseCount}
                            current={props.pageNumber || 1}
                            showTotal={showTotal}
                            defaultPageSize={props.pageSize}
                            showSizeChanger
                            pageSizeOptions={[10, 25, 50, 100, 200]}
                            onChange={(pageNumber, pageSize) => {
                                loadPageData(pageNumber, pageSize);
                                updateState({ pageSize: pageSize, pageNumber: pageNumber });
                            }}
                            onShowSizeChange={(pageNumber, pageSize) => {
                                handlePageSizeChange(pageNumber || 1, pageSize, props);
                            }}
                        />
                    </div>
                </div>
            </div>
        </>
    );
}

export default injectIntl(AssignmentResponse);
