import { Button, Input, Checkbox } from 'antd';
import React, { useState, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { getAWSFileURL } from '../../../utils';
import config from '../../../config/environmentConfig';
import ImagePlaceholder from '../../../assets/images/img-placeholder-alpide.jpg';
const { Search } = Input;

const CategoryModel = (props) => {
  // Initialize searchText and selectedCategories states
  const [searchText, setSearchText] = useState("");
  const [selectedCategories, setSelectedCategories] = useState(
    props.editCall ? (props.data.categories||[]): (props.categoryData?.categories || []) // Pre-populate with existing selections
  );

  // Handle checkbox change for category selection
  const selectChangeHandler = (e, category) => {
    const categoryId = category.inventoryItemCategoryId;
    const isChecked = e.target.checked;

    let updatedSelectedCategories;
    if (isChecked) {
      // Add the selected category if it's not already selected
      updatedSelectedCategories = [...new Set([...selectedCategories, category])];
    } else {
      // Remove the category from the selected categories list
      updatedSelectedCategories = selectedCategories.filter(
        (selectedCategory) =>
          selectedCategory.inventoryItemCategoryId !== categoryId
      );
    }

    // Update the selected categories state
    setSelectedCategories(updatedSelectedCategories);

    // Update the parent component's state

    if(props.editCall){

        const value = {
            ...props.data,
            categories: updatedSelectedCategories,
          };
         
          props.categoryGroup[props.currIndex] = { ...value };
          props.updateState({
            categoryGroup: [
              ...props.categoryGroup,
            ],       
          });
    }else{
        props.updateState({
          categoryData: { ...props.categoryData, categories: updatedSelectedCategories },
        });

    }
  };

  // Handle category selection confirmation
  const handleCategorySelect = () => {
    // Update the parent component's state with the selected categories
    props.updateState({
      categoryData: { ...props.categoryData, categories: selectedCategories },
    });
    props.popModalFromStack(); // Close the modal
  };

  // Recursive function to render subcategories
  const getsubcategories = (subcategories, depth = 1) => {
    const indentStyle = {
      paddingLeft: `${depth * 15}px`,
    };
    return subcategories.map((childSubcategories, i) => (
      <React.Fragment key={childSubcategories.inventoryItemCategoryId + i}>
        <div className="checkbox-button-Wrapper" style={indentStyle}>
          <Checkbox
            value={childSubcategories.inventoryItemCategoryId}
            onChange={(e) => selectChangeHandler(e, childSubcategories)}
            checked={selectedCategories.some(
              (cat) => cat.inventoryItemCategoryId === childSubcategories.inventoryItemCategoryId
            )}
          >
            <div className="parent-category-sub-wrapper">
              <div className="category-img">
                {childSubcategories.awsKeyName ? (
                  <img
                    src={getAWSFileURL(
                      childSubcategories.awsKeyName,
                      config.BUCKET_NAME.BO_INVENTORY
                    )}
                    alt="U"
                  />
                ) : (
                  <img style={{ height: "30px" }} src={ImagePlaceholder} alt="U" />
                )}
              </div>
              <div>
                <h2>{childSubcategories.categoryName}</h2>
                <p>{childSubcategories.totalProducts}</p>
              </div>
            </div>
          </Checkbox>
        </div>
        {childSubcategories.subcategories &&
        childSubcategories.subcategories.length
          ? getsubcategories(childSubcategories.subcategories, depth + 1)
          : ""}
      </React.Fragment>
    ));
  };

  // Use memoization to filter categories based on search input
  const filteredCategories = useMemo(() => {
    return searchText
      ? props.productCategories.filter((category) =>
          category.categoryName?.toLowerCase().includes(searchText.toLowerCase())
        )
      : props.productCategories;
  }, [searchText, props.productCategories]);

  return (
    <>
      <div className="cat-search-wrapper">
        <Search
          placeholder="Search categories or subcategories..."
          value={searchText} // Use searchText instead of props.categoryData.categories
          onChange={(e) => setSearchText(e.target.value)} // Update searchText
        />
      </div>
      <div className="parent-category-list">
        {filteredCategories
          .filter((rowData) => rowData.parentId === 0) // Filter to get only parent categories
          .filter(
            (category) =>
              category?.inventoryItemCategoryId !==
              props?.categoryData?.inventoryItemCategoryId
          )
          .map((category) => (
            <React.Fragment key={category.inventoryItemCategoryId}>
              <div className="checkbox-button-Wrapper">
                <Checkbox
                  value={category.inventoryItemCategoryId}
                  onChange={(e) => selectChangeHandler(e, category)}
                  checked={selectedCategories.some(
                    (cat) => cat.inventoryItemCategoryId === category.inventoryItemCategoryId
                  )}
                >
                  <div className="parent-category-sub-wrapper">
                    <div className="category-img">
                      {category.awsKeyName ? (
                        <img
                          src={getAWSFileURL(
                            category.awsKeyName,
                            config.BUCKET_NAME.BO_INVENTORY
                          )}
                          alt="U"
                        />
                      ) : (
                        <img
                          style={{ height: "30px" }}
                          src={ImagePlaceholder}
                          alt="U"
                        />
                      )}
                    </div>
                    <div className="parent-category-details">
                      <h2>{category.categoryName}</h2>
                      <p>{`${category.totalProducts} products`}</p>
                    </div>
                  </div>
                </Checkbox>
              </div>
              {category.subcategories && category.subcategories.length
                ? getsubcategories(category.subcategories)
                : ""}
            </React.Fragment>
          ))}
        <div className="modal-footer">
          <div className="customModal-button-group">
            <Button
              className="grey-button"
              onClick={() => {
                props.popModalFromStack();
              }}
            >
              <FormattedMessage id="button.cancel.label" defaultMessage="" />
            </Button>
            <Button type="primary" onClick={handleCategorySelect}>
              <FormattedMessage id="button.save.label" defaultMessage="" />
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default CategoryModel;
