import React, { Fragment } from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Row, Col,  DatePicker, Tooltip } from 'antd';
import { FormattedMessage, injectIntl } from 'react-intl';
import * as filter from 'lodash.filter';
import * as find from 'lodash.find';
import { Dropdown } from '../../../../general/Dropdown';
// import config from '../../../../../config/environmentConfig';
import { MultipleSelectDropdown } from '../../../../general/MultipleSelectDropdown';
import { CustomAgGrid } from '../../../../general/CustomAgGrid';
import { QuestionCircleTwoTone, EditOutlined } from "@ant-design/icons";

import {
    CONSTANTS, MODAL_TYPE, CONSTANTS_TRANSACTIONS, CONSTANTS_TRANSACTIONS_KEY, MODULE_CODED_VALUES, PERMISSION_VALUES, LEDGER_TYPE
} from '../../../../../static/constants';
import { AG_GRID_CONSTANTS, GRID_PREFERENCE_CONSTANTS } from '../../../../../static/agGridConstants';
import { addDaysToDate, getMomentDateForUI } from '../../../../../utils';
import { getLabelName } from '../../../../modal/modalBody/customerInfo';
import { showToasterMessage } from '../../../../../utils';
// import LoadingIcon from '../../../../../assets/images/loading.gif';
// import { LogoComponent } from '../../../../general/LogoComponent';

const format = CONSTANTS.DISPLAY_DATE_FORMAT;

class CustomerInvoiceDetails extends React.Component {

    constructor(props) {
        super();
        this.state = {
            size: 0
        }


    }

    handleSizeChange = e => {
        this.setState({ size: e.target.value });
    };

    getDueDateChange = () => {
        let selectedterm = this.props.customerPaymentTerm || {};
        if (selectedterm.numberOfDays) {
            const updatedInvoiceDueDate = addDaysToDate((this.props.invoiceDate || new Date()), selectedterm.numberOfDays);
            // this.props.updateState({
            //     invoiceDueDate: updatedInvoiceDueDate
            // });
            return updatedInvoiceDueDate;
        }
        return new Date();
    }

    handleNewTaxIdentificationAddition = (props, payload, modalType) => {
        const formData = payload.formData;
        const { showModal, companyInfo } = props;
        formData.relationshipId = companyInfo.relationshipId;
        formData.callback = function (taxIdentification) {
            if (props.customer && props.customer.customerTaxIdentificationsList) {
                let list = props.customer.customerTaxIdentificationsList || [];
                list.push(taxIdentification)
                props.updateState({ customer: { ...props.customer, customerTaxIdentificationsList: list } })
            }
        }
        const data = {
            title: this.getModalTitle(payload, modalType),
            formData,
            hideFooter: true,
            modalData: {
                modalType: modalType,
                data: payload,
            },
            handleSubmit: (formData = {}) => {
                data.formData.submittedOnce = true;
                props.hideModal(data);
            }
        };
        showModal(data);
    }

    handleNewAddressAddition = (props, payload, modalType) => {
        const formData = payload.formData;
        if (modalType !== MODAL_TYPE.RELATIONSHIP_BILLING_ADDRESS && (!formData || !formData.customerId)) {
            return showToasterMessage({ messageType: 'error', description: this.props.intl.formatMessage({id : 'missingCustomer.errorMsg', defaultMessage : ''}) });
        }
        const { showModal, companyInfo } = props;
        formData.relationshipId = companyInfo.relationshipId;
        if (modalType === MODAL_TYPE.RELATIONSHIP_BILLING_ADDRESS) {
            payload.formData = { addressType:'relationship', locationType: 'RelationshipBillingAddress' };
        }
        payload.formData.callback = function (address) {
            props.hideModal(data);
            if (address.locationType === 'BillingAddress' || address.locationType === 'ShippingAddress') {
              props.fetchAddresses({
                customerId: address.customerId,
                relationshipId: (props.companyInfo || {}).relationshipId,
                locationType: address.locationType
              });
            } else {
              props.getRelationshipAddress({
                relationshipId: (props.companyInfo || {}).relationshipId
              });
            }
        }
        const data = {
            title: this.getModalTitle(payload, modalType),
            formData,
            hideFooter: true,
            modalData: {
                modalType: modalType,
                data: payload,
            },
            handleSubmit: (formData = {}) => {
                data.formData.submittedOnce = true;
                props.hideModal(data);
            }
        };
        showModal(data);
    }

    handleNewContactAddition = (props, payload) => {
        const formData = payload.formData;
        const { showModal, companyInfo } = props;
        formData.relationshipId = companyInfo.relationshipId;
        formData.callback = (contact) => {
            props.hideModal(data);
            if (contact.customerId) {
                props.fetchAllContacts({
                    customerId: contact.customerId,
                    relationshipId: (props.companyInfo || {}).relationshipId,
                });
            }
          }
        const data = {
            title: <div>
                <FormattedMessage id='addItem.text.contact' defaultMessage='' />
                <div style={{ 'font-size': '12px' }}> {formData.customerName || ''}</div>
            </div>,
            formData,
            hideFooter: true,
            modalData: {
                modalType: MODAL_TYPE.CONTACT,
                data: payload,

            },
            handleSubmit: (formData = {}) => {
                data.formData.submittedOnce = true;
                props.hideModal(data);
            }
        };
        showModal(data);
    }

    handleNewProjectAddition = (props, payload) => {
        const formData = payload.formData;
        const { showModal, companyInfo } = props;
        formData.relationshipId = companyInfo.relationshipId;
        const data = {
            title: <FormattedMessage id='addItem.text.project' defaultMessage='' />,
            formData,
            hideFooter: true,
            modalData: {
                modalType: MODAL_TYPE.PROJECT,
                data: payload,

            },
            handleSubmit: (formData = {}) => {
                data.formData.submittedOnce = true;
                props.hideModal(data);
            }
        };
        showModal(data);
    }

    handleNewPaymentTermAddition = (props, payload) => {
        const formData = payload.formData;
        const { showModal, hideModal, companyInfo } = props;
        formData.relationshipId = companyInfo.relationshipId;
        const data = {
            title: <FormattedMessage id='addItem.text.paymentTerm' defaultMessage='' />,
            formData,
            hideFooter: true,
            modalData: { modalType: MODAL_TYPE.PAYMENT_TERM, data: payload },
            handleSubmit: (formData = {}) => {
                if (formData.termName && formData.termName.length
                    && formData.days !== 0 && formData.days) {
                    hideModal();
                    props.addPaymentTerm(formData);
                } else {
                    data.formData.submittedOnce = true;
                    showModal(data);
                }
            }
        };
        showModal(data);
    }

    addItemConfirmationModal = (props, text, modalType) => {
        const { showModal, companyInfo } = props;
        const labelName = getLabelName(modalType);
        const modalBody = <span>
            <FormattedMessage
                id='addItem.confirmation'
                values={{ value: `${text} to ` }}
                defaultMessage={``}
            />
            {labelName} ?
        </span>;

        let addFunc;
        let payload = {
            text: text,
            relationshipId: companyInfo.relationshipId
        }
        switch (modalType) {
            case MODAL_TYPE.SO_SOURCE: {
                addFunc = props.addSalesOrderSources;
                break;
            }
            case MODAL_TYPE.DOCUMENT_NAME: {
                addFunc = props.addDocumentName;
                payload.txType = CONSTANTS_TRANSACTIONS_KEY[CONSTANTS_TRANSACTIONS.SALES_INVOICE];
                break;
            }
            default: {
                addFunc = () => {
                }
            }
        }

        const modalData = {
            modalBody,
            handleSubmit: () => {
                addFunc(payload)
                props.hideModal();
            },
        };
        showModal(modalData);
    }

    getModalTitle = (newTextObj, modalType) => {
        let title;

        switch (modalType) {
            case MODAL_TYPE.PAYMENT_TERM: {
                title = <FormattedMessage id='addItem.text.paymentTerm' defaultMessage='' />;
                break;
            }
            case MODAL_TYPE.SO_SOURCE: {
                title = <FormattedMessage id='addItem.text.soSource' defaultMessage='' />;
                break;
            }
            case MODAL_TYPE.BILLING_ADDRESS: {
                title = <div><FormattedMessage id='addItem.text.billingAddress' defaultMessage='' />
                    <h6>{this.props.customerName || ''} </h6>
                </div>
                break;
            }
            case MODAL_TYPE.SHIPPING_ADDRESS: {
                title = <div><FormattedMessage id='addItem.text.shippingAddress' defaultMessage='' />
                    <h6>{this.props.customerName || ''} </h6>
                </div>
                break;
            }
            case MODAL_TYPE.RELATIONSHIP_BILLING_ADDRESS: {
                title = <div>
                    <FormattedMessage id='addItem.text.billingAddress' defaultMessage='' />
                    <h6>{(this.props.companyInfo || {}).storeName} </h6>
                </div>
                break;
            }
            case MODAL_TYPE.DOCUMENT_NAME: {
                title = <FormattedMessage id='addItem.text.documentName' defaultMessage='' />;
                break;
            }
            case MODAL_TYPE.NEW_TAX_IDENTIFICATION: {
                title = <div>
                    <FormattedMessage id='addItem.text.taxIdentification' defaultMessage='' />
                </div>
                break;
            }
            default:
                title = <FormattedMessage id='signup.confirmation.title' defaultMessage='' />;
        }
        return title;
    }

    handleNewItemAddition = (props, payload, modalType) => {
        const formData = payload.formData;
        const { showModal } = props;

        if (formData.textBoxValue && formData.textBoxValue.length && !payload.existsInData) {
            this.addItemConfirmationModal(props, formData.textBoxValue, modalType);
        } else {
            const data = {
                title: this.getModalTitle(payload, modalType),
                formData,
                modalData: { modalType, data: payload },
                handleSubmit: (formData = {}) => {
                    if (formData.textBoxValue && formData.textBoxValue.length) {
                        this.addItemConfirmationModal(props, formData.textBoxValue, modalType);
                    } else {
                        data.formData.submittedOnce = true;
                        showModal(data);
                    }
                }
            };
            showModal(data);
        }
    }

    deleteClickHandler = (props, modalType, payload) => {
        const { showModal, companyInfo } = props;
        const modalBody =
            <FormattedMessage
                id='deleteItem.confirmation'
                defaultMessage={``}
                values={{ value: payload.text }}
            />;

        let deleteFunc;
        let callback = function (data) {
            if (modalType === MODAL_TYPE.CONTACT && props.customer.customerId) {
                props.fetchAllContacts({
                    customerId: props.customer.customerId,
                    relationshipId: (props.companyInfo || {}).relationshipId,
                });
            }
            if(modalType === MODAL_TYPE.RELATIONSHIP_BILLING_ADDRESS){
                props.getRelationshipAddress({
                  relationshipId: (props.companyInfo || {}).relationshipId
                });
              }
        }
        switch (modalType) {
            case MODAL_TYPE.PAYMENT_TERM: {
                deleteFunc = props.deletePaymentTerm;
                break;
            }
            case MODAL_TYPE.SO_SOURCE: {
                deleteFunc = props.deleteSalesOrderSources;
                break;
            }
            case MODAL_TYPE.PROJECT: {
                deleteFunc = props.deleteProject;
                break;
            }
            case MODAL_TYPE.BILLING_ADDRESS:
            case MODAL_TYPE.SHIPPING_ADDRESS:
            case MODAL_TYPE.RELATIONSHIP_BILLING_ADDRESS: {
                deleteFunc = props.deleteAddress;
                break;
            }
            case MODAL_TYPE.DOCUMENT_NAME: {
                deleteFunc = props.deleteDocumentName;
                break;
            }
            case MODAL_TYPE.CONTACT: {
                deleteFunc = props.deleteContact;
                break;
            }
            case MODAL_TYPE.NEW_TAX_IDENTIFICATION: {
                deleteFunc = props.deleteRelationshipTaxIdentifications;
                break;
            }
            case MODAL_TYPE.NEW_TAX_IDENTIFICATION_CUSTOMER: {
                deleteFunc = props.deleteCustomerTaxIdentifications;
                break;
            }
            default: {
                deleteFunc = () => {
                }
            }
        }

        const modalData = {
            modalBody,
            handleSubmit: () => {
                deleteFunc({ id: payload.id, relationshipId: companyInfo.relationshipId, customerId: (props.customer || {}).customerId, callback });
                if (modalType === MODAL_TYPE.NEW_TAX_IDENTIFICATION_CUSTOMER && props.customer && props.customer.customerTaxIdentificationsList) {
                    let list = (props.customer.customerTaxIdentificationsList || []).filter(x => x.customerTaxIdentificationId !== payload.id);
                    props.updateState({ customer: { ...props.customer, customerTaxIdentificationsList: list } })
                }
                if (modalType === MODAL_TYPE.DOCUMENT_NAME && payload.id === this.props.documentNameId) {
                    const selectedObj = find((this.props.documentList || []), { txType: CONSTANTS_TRANSACTIONS_KEY[CONSTANTS_TRANSACTIONS.SALES_INVOICE], isDefault: 1 }) || {};
                    this.props.updateState({
                        documentNameId: selectedObj.documentNameId,
                        docName: selectedObj.docName
                    });
                }
            },
        };
        showModal(modalData);
    }

    getInvoiceData = (props, soData) => {
        const detailList = [];
        const inheritedTxColumn = [];
        const _emptyColumnCheck = (item, taxPercent) => {
            if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.DESCRIPTION) < 0 && item.description) {
                inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.DESCRIPTION);
            }
            if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.UOM) < 0 && item.uomName) {
                inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.UOM);
            }
            if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.HSN) < 0 && item.hsnCode) {
                inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.HSN);
            }
            // if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.BASE_PRICE) < 0 && item.basePrice) {
            //     inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.BASE_PRICE);
            // }
            if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.SPECIAL_DISCOUNT) < 0 && item.specialDiscount) {
                inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.SPECIAL_DISCOUNT);
            }
            if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.DISCOUNT) < 0 && (find(item.customerSalesOrderCOATxList, { txType: LEDGER_TYPE.TYPE_DISCOUNT }) || {}).amountPercent) {
                inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.DISCOUNT);
            }
            if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.TAX) < 0 && taxPercent) {
                inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.TAX);
            }
            if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.COMMENT) < 0 && item.comment) {
                inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.COMMENT);
            }
            if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.STOCK_NUMBER) < 0 && item.stocknumber) {
                inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.STOCK_NUMBER);
            }
            if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.PART_NUMBER) < 0 && item.partNumber) {
                inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.PART_NUMBER);
            }
            if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.ORIGIN) < 0 && item.origin) {
                inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.ORIGIN);
            }
            if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.HS_CODE) < 0 && item.hsCode) {
                inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.HS_CODE);
            }
        }
        soData.customerSalesOrderDetailsList.map((ele) => {
            const tax = (find(ele.customerSalesOrderCOATxList, (ele) => {
                return ele.txType === LEDGER_TYPE.TYPE_TAX
            }) || {});
            const appliedTax = find(props.taxes, { taxSingleRateId: Number(tax.taxId) }) || {};
            _emptyColumnCheck(ele, tax.amountPercent);
            return detailList.push({
                selectedProductValue: [ele.itemId, ele.inventoryItemVariantId],
                product: ele.itemName ? ele.itemName + ((ele.itemVariantName && " (" + ele.itemVariantName + ") ") || "") : '',
                productObj: ele.itemName ? { itemName: ele.itemName, itemId: ele.itemId, sku: ele.sku } : {},
                itemVariantName: ele.itemVariantName,
                variant: {
                    attributeId1: ele.attributeId1,
                    attributeId2: ele.attributeId2,
                    attributeId3: ele.attributeId3,
                    attributeName1: ele.attributeName1,
                    attributeName2: ele.attributeName2,
                    attributeName3: ele.attributeName3,
                    attributeValue1: ele.attributeValue1,
                    attributeValue2: ele.attributeValue2,
                    attributeValue3: ele.attributeValue3
                },
                description: ele.description,
                scrollHeight: ele.rowHeight,
                qty: ele.quantityToInvoice,
                discount: (find(ele.customerSalesOrderCOATxList, { txType: LEDGER_TYPE.TYPE_DISCOUNT }) || {}).amountPercent,
                tax: (tax.amountPercent || 0),
                taxId: appliedTax.taxSingleRateId,
                taxApplied: appliedTax.taxNameDisplay,
                taxName: appliedTax.taxName,
                quantityOrdered: ele.quantity,
                quantityInvoiced: ele.quantityInvoiced,
                rate: ele.anItemSalePrice,
                baseRate: ele.basePrice,
                uom: ele.uomName,
                hsnCode: ele.hsnCode,
                specialDiscount: ele.basePrice && ele.specialDiscount,
                comment: ele.comment,
                stockNumber: ele.stockNumber,
                partNumber: ele.partNumber,
                origin: ele.origin,
                hsCode: ele.hsCode,
                salesOrderMasterId: soData.salesOrderMasterId,
                soNumber: soData.soNumber,
                customerId: soData.customerId,
                salesQuotationMasterId: soData.salesQuotationMasterId,
                quotationNumber: soData.quotationNumber,
                rfqNumber: soData.rfqNumber,
                rfqMasterId: soData.rfqMasterId,
                parentDetailsId: ele.salesOrderDetailsId
            })
        });
        const { selectedSalesOrder } = props;
        selectedSalesOrder.push({ ...soData, customerSalesOrderDetailsList: detailList });
        props.updateState({
            isSoConversion: true,
            selectedSalesOrder: selectedSalesOrder
        });
    }

    getInvoiceDataFromShipment = (props, shipmentData) => {
        const detailList = [];
        const inheritedTxColumn = [];
        const _emptyColumnCheck = (item, taxPercent) => {
            if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.DESCRIPTION) < 0 && item.description) {
                inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.DESCRIPTION);
            }
            if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.UOM) < 0 && item.uomName) {
                inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.UOM);
            }
            if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.HSN) < 0 && item.hsnCode) {
                inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.HSN);
            }
            // if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.BASE_PRICE) < 0 && item.basePrice) {
            //     inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.BASE_PRICE);
            // }
            if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.SPECIAL_DISCOUNT) < 0 && item.specialDiscount) {
                inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.SPECIAL_DISCOUNT);
            }
            if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.DISCOUNT) < 0 && (find(item.customerSalesOrderCOATxList, { txType: LEDGER_TYPE.TYPE_DISCOUNT }) || {}).amountPercent) {
                inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.DISCOUNT);
            }
            if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.TAX) < 0 && taxPercent) {
                inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.TAX);
            }
            if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.COMMENT) < 0 && item.comment) {
                inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.COMMENT);
            }
            if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.STOCK_NUMBER) < 0 && item.stocknumber) {
                inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.STOCK_NUMBER);
            }
            if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.PART_NUMBER) < 0 && item.partNumber) {
                inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.PART_NUMBER);
            }
            if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.ORIGIN) < 0 && item.origin) {
                inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.ORIGIN);
            }
            if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.HS_CODE) < 0 && item.hsCode) {
                inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.HS_CODE);
            }
        }
        shipmentData.customerSOShipmentDetailsList.map((ele) => {
           
                const tax = (find(ele.customerSalesOrderCOATxList || [], (ele) => {
                    return ele.txType === LEDGER_TYPE.TYPE_TAX
                }) || {});

                const appliedTax = find(props.taxes, { taxSingleRateId: Number(tax.taxId) }) || {};
                const item = { itemId: ele.itemId, itemName: ele.itemName };
                _emptyColumnCheck(ele, tax.amountPercent);
                item.itemId = ele.itemId;
                item.selectedProductValue = [ele.itemId, ele.inventoryItemVariantId];
                item.product = ele.itemName ? ele.itemName + ((ele.itemVariantName && " (" + ele.itemVariantName + ") ") || "") : '';
                item.productObj = ele.itemName ? { itemName: ele.itemName, itemId: ele.itemId, sku: ele.sku } : {};
                item.itemVariantName = ele.itemVariantName;
                item.variant = {
                    attributeId1: ele.attributeId1,
                    attributeId2: ele.attributeId2,
                    attributeId3: ele.attributeId3,
                    attributeName1: ele.attributeName1,
                    attributeName2: ele.attributeName2,
                    attributeName3: ele.attributeName3,
                    attributeValue1: ele.attributeValue1,
                    attributeValue2: ele.attributeValue2,
                    attributeValue3: ele.attributeValue3
                };
                item.description = ele.description;
                item.scrollHeight = ele.rowHeight;
                item.qty = Number(ele.quantityPacked);
                item.discount = (find(ele.customerSalesOrderCOATxList, { txType: LEDGER_TYPE.TYPE_DISCOUNT }) || {}).amountPercent;
                item.tax = (tax.amountPercent || 0);
                item.taxApplied = appliedTax.taxNameDisplay;
                item.taxName = appliedTax.taxName;
                item.taxId = appliedTax.taxSingleRateId;
                item.quantityOrdered = ele.quantity;
                item.quantityInvoiced = ele.quantityInvoiced;
                item.rate = ele.rate;
                item.baseRate = ele.basePrice;
                item.uom = ele.uomName;
                item.hsnCode = ele.hsnCode;
                item.specialDiscount = ele.basePrice && ele.specialDiscount;
                item.comment = ele.comment;
                item.stockNumber = ele.stockNumber;
                item.partNumber = ele.partNumber;
                item.origin = ele.origin;
                item.hsCode = ele.hsCode;
                item.salesOrderMasterId = shipmentData.salesOrderMasterId;
                item.soNumber = shipmentData.soNumber;
                item.customerId = shipmentData.customerId;
                item.salesQuotationMasterId = shipmentData.salesQuotationMasterId;
                item.quotationNumber = shipmentData.quotationNumber;
                item.rfqNumber = shipmentData.rfqNumber;
                item.rfqMasterId = shipmentData.rfqMasterId;
                item.shipmentMasterId = shipmentData.shipmentMasterId;
                return detailList.push(item);
                // if (item.itemId) {
                //     item.qty += Number(ele.quantityPacked);
                //     item.shipmentMasterId = shipmentData.shipmentMasterId;
                // } else {


                // }
        });

        //  const rootDiscount = (find((props.soInvoiceData.customerSalesOrderCOATxList || []), { txType: LEDGER_TYPE.TYPE_DISCOUNT }) || {}).amountPercent || 0;
        //  const expenseAmount = (find((props.soInvoiceData.customerSalesOrderCOATxList || []), { txType: LEDGER_TYPE.TYPE_EXPENSE }) || {}).amount || 0;
        //   let selectedContact = (props.soInvoiceData.additionalInfoList || [])[0] || {};
        //  delete selectedContact.additionalInfoId;
        const { selectedSalesOrder } = props;
        selectedSalesOrder.push({ ...shipmentData, customerSalesOrderDetailsList: detailList });
        props.updateState({
            isSoConversion: true,
            selectedSalesOrder: selectedSalesOrder,
            customerRef: shipmentData.referenceNumber
        });
    }

    render() {
        const { size } = this.state;
        const relationshipBillingAddress = this.props.relationshipBillingAddress || {};
        const customerShippingAddress = this.props.customerShippingAddress || {};
        const customerBillingAddress = this.props.customerBillingAddress || {};
        const customerPaymentTerm = this.props.customerPaymentTerm || {};
        const rowNode = this.gridApi && this.gridApi.getDisplayedRowAtIndex(0);
        if (rowNode) {
            rowNode.setDataValue(AG_GRID_CONSTANTS.STANDARD_SALES_INVOICE_FIELD.TYPE_SHIPPING_ADDRESS_FIELD, (customerShippingAddress.streetAddress1 || ''));
            rowNode.setDataValue(AG_GRID_CONSTANTS.STANDARD_SALES_INVOICE_FIELD.TYPE_BILLING_ADDRESS_FIELD, (customerBillingAddress.streetAddress1 || ''));
            rowNode.setDataValue(AG_GRID_CONSTANTS.STANDARD_SALES_INVOICE_FIELD.PLACE_OF_SUPPLY_FIELD, (this.props.placeOfSupply || ''));
            rowNode.setDataValue(AG_GRID_CONSTANTS.STANDARD_SALES_INVOICE_FIELD.PAYMENT_TERM_FIELD, (customerPaymentTerm.termName || ''));
            rowNode.setDataValue(AG_GRID_CONSTANTS.STANDARD_SALES_INVOICE_FIELD.CUSTOMER_PO_FIELD, (this.props.customerPo || ''));
            rowNode.setDataValue(AG_GRID_CONSTANTS.STANDARD_SALES_INVOICE_FIELD.PROJECT, (this.props.projectName || ''));
            rowNode.setDataValue('ref', (this.props.customerRef || ''));
            this.gridApi.sizeColumnsToFit();
        }


        const columnDefs = [
            {
                headerComponentFramework: () => {
                    return <FormattedMessage id='customer.salesInvoice.form.gridHeader.placeOfSupply'
                        defaultMessage='' />;
                },
                field: AG_GRID_CONSTANTS.STANDARD_SALES_INVOICE_FIELD.PLACE_OF_SUPPLY_FIELD,
                resizable: true,
                editable: true,
                hide: (this.props.companyInfo || {}).countryName !== 'India',
                cellEditor: 'customDropDownEditor',
                pinned: 'left',
                cellEditorParams: (obj) => {
                    return {
                        lastColumnCell: false,
                        items: this.props.states || [],
                        optionKeyName: 'stateId',
                        valueKeyName: 'stateName',
                        // canAddNew: true,
                        // canDelete: true,
                        onSelect: (selectedValue) => {
                            this.props.updateState({
                                placeOfSupply: selectedValue
                            });
                            //obj.node.setDataValue(obj.colDef.field, selectedValue)
                        },
                        // allowClear: true,
                        // onClear: () => {
                        //     //obj.node.setDataValue(obj.colDef.field, undefined);
                        //     this.props.updateState({
                        //         placeOfSupply: undefined
                        //     });
                        // },
                    }
                }
            },
            {
                headerComponentFramework: () => {
                    return <FormattedMessage id='customer.salesInvoice.form.gridHeader.customerPO'
                        defaultMessage='' />;
                },
                field: AG_GRID_CONSTANTS.STANDARD_SALES_INVOICE_FIELD.CUSTOMER_PO_FIELD,
                resizable: true,
                editable: true,
                cellEditor: 'customTextEditor',
                cellEditorParams: (obj) => {
                    return {
                        onChange: (val) => {
                            this.props.updateState({
                                customerPo: val
                            });
                        }
                    }
                }
            },
            {
                headerComponentFramework: () => {
                    return <div>
                        <FormattedMessage id='customer.salesInvoice.form.gridHeader.refNumber' defaultMessage='' />
                        <Tooltip placement="top"
                            title={this.props.intl.formatMessage(
                                {
                                    id: 'tooltip.referenceNumberAssign',
                                    defaultMessage: ''
                                })
                            }
                            trigger="click">
                            <i className="fa fa-question-circle-o pl5 cursor-pointer" />
                        </Tooltip>
                    </div>
                },
                field: 'ref',
                editable: true,
                cellEditor: 'customTextEditor',
                cellEditorParams: (obj) => {
                    return {
                        onChange: (val) => {
                            this.props.updateState({
                                customerRef: val
                            });
                        }
                    }
                }
            },
            {
                headerComponentFramework: () => {
                    return <FormattedMessage id='customer.salesInvoice.form.gridHeader.billingAddress'
                        defaultMessage='' />;
                },
                field: AG_GRID_CONSTANTS.STANDARD_SALES_INVOICE_FIELD.TYPE_BILLING_ADDRESS_FIELD,
                editable: true,
                cellEditor: 'customDropDownEditor',
                cellEditorParams: (obj) => {
                    const allBillingAddresses = this.props.allBillingAddress;
                    return {
                        //TODO need to use the variable declared above
                        lastColumnCell: false,
                        items: allBillingAddresses,
                        optionKeyName: 'locationId',
                        valueKeyName: 'streetAddress1',
                        addressLineKey1: 'streetAddress2',
                        addressLineKey2: 'cityName',
                        addressLineKey3: 'stateName',
                        addressLineKey4: 'zipCode',
                        isFavouriteKeyName: 'isDefault',
                        optionClassAddress: 'custom-address-dropdown',
                        canAddNew: true,
                        canDelete: true,
                        permissions: this.props.permissions,
                        moduleName: MODULE_CODED_VALUES.CUSTOMER_BILLING_ADDRESS,
                        operations: [PERMISSION_VALUES.CREATE, PERMISSION_VALUES.DELETE],
                        deleteClickHandler: (payload) => {
                            this.deleteClickHandler(this.props, MODAL_TYPE.BILLING_ADDRESS, payload);
                        },
                        onAddNewClick: (payload) => {
                            payload.formData = {
                                locationType: CONSTANTS.BILLING_ADDRESS,
                                customerId: (this.props.customer || {}).customerId,
                                pageNumber: this.props.pageNumber,
                            }
                            this.handleNewAddressAddition(this.props, payload, MODAL_TYPE.BILLING_ADDRESS)
                        },
                        onSelect: (selectedValue, optionObj) => {
                            const customerBillingAddress = find(allBillingAddresses, { locationId: Number(optionObj.key) });
                            this.props.updateState({
                                customerBillingAddress
                            })
                            //obj.node.setDataValue(obj.colDef.field, selectedValue)
                        },
                        allowClear: true,
                        onClear: () => {
                            //obj.node.setDataValue(obj.colDef.field, undefined);
                            this.props.updateState({
                                customerBillingAddress: undefined
                            });
                        },
                    }
                }
            },
            {
                headerComponentFramework: () => {
                    return <FormattedMessage id='customer.salesInvoice.form.gridHeader.shippingAddress'
                        defaultMessage='' />;
                },
                field: AG_GRID_CONSTANTS.STANDARD_SALES_INVOICE_FIELD.TYPE_SHIPPING_ADDRESS_FIELD,
                editable: true,
                cellEditor: 'customDropDownEditor',
                cellEditorParams: (obj) => {
                    const allShippingAddresses = this.props.allShippingAddress;
                    return {
                        //TODO need to use the variable declared above
                        lastColumnCell: false,
                        items: allShippingAddresses,
                        optionKeyName: 'locationId',
                        valueKeyName: 'streetAddress1',
                        addressLineKey1: 'streetAddress2',
                        addressLineKey2: 'cityName',
                        addressLineKey3: 'stateName',
                        addressLineKey4: 'zipCode',
                        isFavouriteKeyName: 'isDefault',
                        optionClassAddress: 'custom-address-dropdown',
                        permissions: this.props.permissions,
                        moduleName: MODULE_CODED_VALUES.CUSTOMER_ADDRESS,
                        operations: [PERMISSION_VALUES.CREATE, PERMISSION_VALUES.DELETE],
                        canAddNew: true,
                        canDelete: true,
                        deleteClickHandler: (payload) => {
                            this.deleteClickHandler(this.props, MODAL_TYPE.SHIPPING_ADDRESS, payload);
                        },
                        onAddNewClick: (payload) => {
                            payload.formData = {
                                locationType: CONSTANTS.SHIPPING_ADDRESS,
                                customerId: (this.props.customer || {}).customerId,
                                pageNumber: this.props.pageNumber,
                            }
                            this.handleNewAddressAddition(this.props, payload, MODAL_TYPE.SHIPPING_ADDRESS)
                        },
                        onSelect: (selectedValue, optionObj) => {
                            const customerShippingAddress = find(allShippingAddresses, { locationId: Number(optionObj.key) });
                            this.props.updateState({
                                customerShippingAddress
                            });
                            //obj.node.setDataValue(obj.colDef.field, selectedValue)
                        },
                        allowClear: true,
                        onClear: () => {
                            //obj.node.setDataValue(obj.colDef.field, undefined);
                            this.props.updateState({
                                customerShippingAddress: undefined
                            });
                        },
                    }
                }
            },
            {
                headerComponentFramework: () => {
                    return <FormattedMessage id='customer.salesInvoice.form.gridHeader.paymentTerms'
                        defaultMessage='' />;
                },
                field: AG_GRID_CONSTANTS.STANDARD_SALES_INVOICE_FIELD.PAYMENT_TERM_FIELD,
                editable: true,
                pinned: 'right',
                cellEditor: 'customDropDownEditor',
                cellEditorParams: (obj) => {
                    return {
                        lastColumnCell: false,
                        items: this.props.paymentTerms,
                        optionKeyName: 'paymentTermId',
                        valueKeyName: 'termName',
                        //isFavouriteKeyName: 'isDefault',
                        moduleName: MODULE_CODED_VALUES.PROJECT,

                        canAddNew: true,
                        canDelete: true,
                        isLockKeyName:'isSystemGenerated',
                        //permissions: this.props.permissions,
                        // moduleName: MODULE_CODED_VALUES.PAYMENT_TERM,
                        //operations: [PERMISSION_VALUES.CREATE, PERMISSION_VALUES.DELETE],
                        deleteClickHandler: (payload) => {
                            this.deleteClickHandler(this.props, MODAL_TYPE.PAYMENT_TERM, payload);
                        },
                        onAddNewClick: (payload) => {
                            payload.formData = {
                                termName: payload.textEntered,
                                days: null,
                                description: '',
                                isDefaultPayment: false,
                                submittedOnce: false
                            }
                            this.handleNewPaymentTermAddition(this.props, payload, MODAL_TYPE.PAYMENT_TERM)
                        },
                        onSelect: (selectedValue, optionObj) => {
                            //obj.node.setDataValue(obj.colDef.field, selectedValue)
                            const customerPaymentTerm = find(this.props.paymentTerms, { paymentTermId: Number(optionObj.key) });
                            this.props.updateState({
                                customerPaymentTerm
                            });
                        },
                    }
                }
            },
            {
                headerComponentFramework: () => {
                    return <FormattedMessage id='customer.salesInvoice.form.projectDropdown.label'
                        defaultMessage='' />;
                },
                field: AG_GRID_CONSTANTS.STANDARD_SALES_INVOICE_FIELD.PROJECT,
                editable: true,
                pinned: 'right',
                cellEditor: 'customDropDownEditor',
                cellEditorParams: (obj) => {
                    return {
                        lastColumnCell: false,
                        items: this.props.projectList,
                        valueKeyName: 'projectName',
                        value: this.props.projectName,
                        optionKeyName: 'projectMasterId',
                        moduleName: MODULE_CODED_VALUES.PROJECT,
                        canAddNew: true,
                        canDelete: true,
                        deleteClickHandler: (payload) => {
                            this.deleteClickHandler(this.props, MODAL_TYPE.PROJECT, payload);
                        },
                        onAddNewClick: (payload) => {
                            payload.formData = {
                                projectName: payload.projectName,
                            }
                            this.handleNewProjectAddition(this.props, payload, MODAL_TYPE.PROJECT)
                        },
                        onSelect: (selectedValue, option) => {
                            const selectedProject = find(this.props.projectList, { projectMasterId: Number(option.key) }) || {};
                            this.props.updateState({
                                projectMasterId: selectedProject.projectMasterId,
                                projectName: selectedProject.projectName,
                                projectNumber: selectedProject.projectNumber
                            });
                        },
                        allowClear: true,
                        onClear: () => {
                            //obj.node.setDataValue(obj.colDef.field, undefined);
                            this.props.updateState({
                                projectMasterId: undefined,
                                projectName: undefined,
                                projectNumber: undefined
                            });
                        },
                    }
                }
            }
        ]

        const onGridReady = (params) => {
            this.gridApi = params.api;
            this.gridColumnApi = params.columnApi;
            params.api.sizeColumnsToFit();
        }


        return (
            <Fragment>
                <Row>
                <Col span={5}>
            <Row gutter={[0, 3]}>
              {/* <LogoComponent src={CONSTANTS.BASE_URL_S3 + '/' + config.BUCKET_NAME.BO_RELATIONSHIP + '/' + (this.props.companyInfo || {}).logoAwsObjectKey + "?" + Date.now()} /> */}
              {/* <Avatar shape='square' size={64} icon={<UserOutlined />}
                                src={CONSTANTS.BASE_URL_S3 + '/' + config.BUCKET_NAME.BO_RELATIONSHIP + '/' + (this.props.companyInfo || {}).logoAwsObjectKey + "?" + Date.now()} /> */}
              <Col span={24} className="details-heading">
                Organization Information
              </Col>
              <Col span={24}>
                <div className="details-heading-subheading">
                  <FormattedMessage
                    id="sales.order.create.organization.name"
                    defaultMessage=""
                  />
                </div>
              </Col>
              <Col span={24}>
                <div className="deatils-field-value">
                  {(this.props.companyInfo || {}).storeName}
                </div>
              </Col>
              <Col span={24}>
                <div className="details-heading-subheading">
                  <FormattedMessage
                    id="sales.order.create.organization.adress"
                    defaultMessage=""
                  />
                  <Tooltip
                    placement="right"
                    title={this.props.intl.formatMessage({
                      id: "tooltip.companyBillingAddress",
                      defaultMessage: "",
                    })}
                    trigger="click"
                  >
                    <QuestionCircleTwoTone />
                  </Tooltip>
                </div>
              </Col>
              <Col span={24}>
                <span>
                  {!this.props.addressHide &&
                  this.props.relationshipBillingAddress ? (
                    <>
                      <div className="detail-address-value">
                        <div style={{ height: "96px", paddingTop: "10px" }}>
                          {getAddressInfoFragment(
                            this.props.relationshipBillingAddress || {},
                            {},
                            this.props.companyInfo
                          )}
                        </div>
                        <div>
                          <EditOutlined
                            onClick={() => {
                              this.props.updateState({
                                addressHide: true,
                              });
                            }}
                          />
                        </div>
                      </div>
                    </>
                  ) : (
                    <Dropdown
                      style={{ width: "100%" }}
                      items={this.props.allRelationshipBillingAddress || []}
                      value={relationshipBillingAddress.streetAddress1}
                      onSelect={(value) => {
                        const selectedRelationshipAddress = find(
                          this.props.allRelationshipBillingAddress,
                          { locationId: Number(value) }
                        );
                        this.props.updateState({
                          relationshipBillingAddress:
                            selectedRelationshipAddress,
                          addressHide: false,
                        });
                      }}
                      canAddNew={true}
                      canDelete={true}
                      deleteClickHandler={(payload) => {
                        this.deleteClickHandler(
                          this.props,
                          MODAL_TYPE.RELATIONSHIP_BILLING_ADDRESS,
                          payload
                        );
                      }}
                      onAddNewClick={(payload) => {
                        payload.formData = {
                          locationType: CONSTANTS.RELATIONSHIP_BILLING_ADDRESS,
                        };
                        this.handleNewAddressAddition(
                          this.props,
                          payload,
                          MODAL_TYPE.RELATIONSHIP_BILLING_ADDRESS
                        );
                      }}
                      valueKeyName="streetAddress1"
                      addressLineKey1="streetAddress2"
                      addressLineKey2="cityName"
                      addressLineKey3="stateName"
                      addressLineKey4="zipCode"
                      optionClassAddress="custom-address-dropdown"
                      optionKeyName="locationId"
                      isFavouriteKeyName="isDefault"
                      allowClear={true}
                      onClear={() => {
                        this.props.updateState({
                          relationshipBillingAddress: undefined,
                        });
                      }}
                    />
                  )}
                </span>
              </Col>
              <Col span={24}>
                <div className="details-heading-subheading">
                  <FormattedMessage
                    id="customer.form.taxIdentification.label"
                    defaultMessage=""
                  />
                  <Tooltip
                    placement="right"
                    title={this.props.intl.formatMessage({
                      id: "tooltip.taIdentification",
                      defaultMessage: "",
                    })}
                    trigger="click"
                  >
                    <QuestionCircleTwoTone />
                  </Tooltip>
                </div>
              </Col>
              <Col span={24}>
                <MultipleSelectDropdown
                  style={{ width: "100%" }}
                  items={this.props.allRelationshipTaxIdentificatins || []}
                  placeholder="Tax Identification(s)"
                  mode="multiple"
                  onDeSelect={(value) => {
                    let { selectedRelationshipTaxIdentifications } = this.props;
                    selectedRelationshipTaxIdentifications = filter(
                      selectedRelationshipTaxIdentifications,
                      (taxId) => {
                        return taxId !== value;
                      }
                    );
                    this.props.updateState({
                      selectedRelationshipTaxIdentifications,
                    });
                  }}
                  valueKeyName="taxIdentificationType"
                  valueKeyName2="taxIdentificationNumber"
                  selectedValue={
                    (this.props.allRelationshipTaxIdentificatins || []).length
                      ? this.props.selectedRelationshipTaxIdentifications || []
                      : []
                  }
                  optionKeyName="relationshipTaxIdentificationId"
                  onSelect={(relationshipTaxIdentificationId) => {
                    let selectedRelationshipTaxIdentifications =
                      this.props.selectedRelationshipTaxIdentifications || [];
                    if (
                      selectedRelationshipTaxIdentifications.indexOf(
                        relationshipTaxIdentificationId
                      ) === -1
                    ) {
                      selectedRelationshipTaxIdentifications.push(
                        relationshipTaxIdentificationId
                      );
                    } else {
                      selectedRelationshipTaxIdentifications =
                        selectedRelationshipTaxIdentifications.filter((el) => {
                          return el !== selectedRelationshipTaxIdentifications;
                        });
                    }
                    this.props.updateState({
                      selectedRelationshipTaxIdentifications,
                    });
                  }}
                  canAddNew={true}
                  canDelete={true}
                  deleteClickHandler={(payload) => {
                    this.deleteClickHandler(
                      this.props,
                      MODAL_TYPE.NEW_TAX_IDENTIFICATION,
                      payload
                    );
                  }}
                  onAddNewClick={(payload) => {
                    payload.formData = {
                      taxIdentificationNumber: payload.textEntered,
                      submittedOnce: false,
                    };
                    this.handleNewTaxIdentificationAddition(
                      this.props,
                      payload,
                      MODAL_TYPE.NEW_TAX_IDENTIFICATION
                    );
                  }}
                />
              </Col>
            </Row>
          </Col>
                    <Col span={1}></Col>
                    <Col span={5}>
                        {
                            this.props.customerName
                                ? <Form.Item>
                                    <Row>
                                        <Col span={9} className="text-right">
                                            <div className="title">
                                                <FormattedMessage id='company' defaultMessage='' />
                                            </div>
                                        </Col>
                                        <Col span={15}>
                                            <div className="year ml0 mt5">
                                                {this.props.customerName}
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="mt-12">
                                        <Col span={9} className="text-right">
                                            <div className="title">
                                                <FormattedMessage id='contact' defaultMessage='' />
                                            </div>
                                        </Col>
                                        <Col span={15}>
                                            <Dropdown
                                                style={{ width: '70%' }}
                                                value={((this.props.selectedContact || {}).firstName || "") + " " + ((this.props.selectedContact || {}).lastName || "")}
                                                optionKeyName="contactId"
                                                items={this.props.boContactList}
                                                valueKeyName='firstName'
                                                lastNameKey='lastName'
                                                isFavouriteKeyName='isPrimaryContact'
                                                placeholder='Please select contact name'
                                                moduleName={MODULE_CODED_VALUES.CUSTOMER_CONTACTS} 
                                                canAddNew={true}
                                                canDelete={true}
                                                deleteClickHandler={(payload) => {
                                                    this.deleteClickHandler(this.props, MODAL_TYPE.CONTACT, payload);
                                                }}
                                                onAddNewClick={(payload) => {
                                                    payload.formData = {
                                                        firstName: payload.textEntered,
                                                        submittedOnce: false,
                                                        customerId: (this.props.customer || {}).customerId,
                                                        pageNumber: this.props.pageNumber || 1,
                                                        customerName: this.props.customerName
                                                    }
                                                    this.handleNewContactAddition(this.props, payload, MODAL_TYPE.CONTACT)
                                                }}
                                                onSelect={(contactId) => {
                                                    let contact = find((this.props.boContactList || []), { contactId: +contactId }) || {};
                                                    this.props.updateState({
                                                        selectedContact: contact
                                                    })
                                                }}
                                                allowClear={true}
                                                onClear={() => {
                                                    this.props.updateState({
                                                        selectedContact: undefined,
                                                    })
                                                }}
                                            />
                                            <Tooltip placement="right" title="Customer Contact" trigger="click">
                                                <i className="fa fa-question-circle-o pl5 cursor-pointer" />
                                            </Tooltip>
                                            {
                                                getAddressInfoFragment({}, this.props.selectedContact || {}, this.props.customer)
                                            }
                                        </Col>
                                    </Row>
                                    <div className="inner">
                                        <Row>
                                            <Col span={9} className='text-right'>
                                                <FormattedMessage id='customer.form.taxIdentification.label'
                                                    defaultMessage='' />
                                            </Col>
                                            <Col span={15}>
                                                <MultipleSelectDropdown
                                                    style={{ width: '70%' }}
                                                    items={this.props.customer.customerTaxIdentificationsList || []}
                                                    placeholder="Tax Identification(s)"
                                                    mode='multiple'
                                                    onDeSelect={(value) => {
                                                        let { selectedCustomerTaxIdentifications } = this.props
                                                        selectedCustomerTaxIdentifications = filter(selectedCustomerTaxIdentifications, (taxId) => {
                                                            return taxId !== value;
                                                        });
                                                        this.props.updateState({ selectedCustomerTaxIdentifications });
                                                    }}
                                                    valueKeyName='taxIdentificationType'
                                                    valueKeyName2='taxIdentificationNumber'
                                                    selectedValue={(this.props.customer.customerTaxIdentificationsList || []).length ? (this.props.selectedCustomerTaxIdentifications || []) : []}
                                                    optionKeyName='customerTaxIdentificationId'
                                                    onSelect={(customerTaxIdentificationId) => {
                                                        let selectedCustomerTaxIdentifications = this.props.selectedCustomerTaxIdentifications || [];
                                                        if (selectedCustomerTaxIdentifications.indexOf(customerTaxIdentificationId) === -1) {
                                                            selectedCustomerTaxIdentifications.push(customerTaxIdentificationId);
                                                        } else {
                                                            selectedCustomerTaxIdentifications = selectedCustomerTaxIdentifications.filter((el) => {
                                                                return el !== customerTaxIdentificationId
                                                            });
                                                        }
                                                        this.props.updateState({
                                                            selectedCustomerTaxIdentifications
                                                        });
                                                    }}
                                                    canAddNew={true}
                                                    canDelete={true}
                                                    deleteClickHandler={(payload) => {
                                                        this.deleteClickHandler(this.props, MODAL_TYPE.NEW_TAX_IDENTIFICATION_CUSTOMER, payload);
                                                    }}
                                                    onAddNewClick={(payload) => {
                                                        payload.formData = {
                                                            firstName: payload.textEntered,
                                                            submittedOnce: false,
                                                            customerId: (this.props.customer || {}).customerId,
                                                            pageNumber: this.props.pageNumber || 1,
                                                            customerName: this.props.customerName
                                                        }
                                                        this.handleNewTaxIdentificationAddition(this.props, payload, MODAL_TYPE.NEW_TAX_IDENTIFICATION)
                                                    }}
                                                /><Tooltip placement="right" title={this.props.intl.formatMessage(
                                                    {
                                                        id: 'taxIdentification.tooltip',
                                                        defaultMessage: ''
                                                    })
                                                }
                                                    trigger="click">
                                                    <i className="fa fa-question-circle-o pl5 cursor-pointer" />
                                                </Tooltip>

                                            </Col>
                                        </Row>
                                    </div>

                                    <>
                                        {/* {
                                            (this.props.customerName)
                                                ? <Form.Item>
                                                    <Row>
                                                        <Col span={9} className="text-right">
                                                            <FormattedMessage
                                                                id='customer.salesInvoice.form.loadFromShipment.label'
                                                                defaultMessage=''/>
                                                        </Col>
                                                        <Col span={15}>
                                                            <Checkbox checked={(this.props.isShipmentConversion === 1)} onChange={(e) => {
                                                                this.props.updateState({
                                                                    isShipmentConversion: e.target.checked ? 1 : 0
                                                                });
                                                                if (e.target.checked) {
                                                                    this.props.fetchShipmentForInvoice({
                                                                        relationshipId: (this.props.companyInfo || {}).relationshipId,
                                                                        customerId: this.props.customer.customerId
                                                                    });
                                                                    const selectedSalesOrder = this.props.selectedSalesOrder;
                                                                    this.props.updateState({
                                                                        selectedSalesOrder: [],
                                                                        preservedSalesOrder: selectedSalesOrder,
                                                                        isShipment: true
                                                                    });
                                                                } else {
                                                                    this.props.updateState({
                                                                        selectedSalesOrder: this.props.preservedSalesOrder || [],
                                                                        preservedSalesOrder: [],
                                                                        isShipment: false
                                                                    });
                                                                }
                                                            }}></Checkbox>
                                                            {/* <Switch
                                                                checked={(this.props.isShipmentConversion === 1)}
                                                                onClick={(checked, e) => {
                                                                    this.props.updateState({
                                                                        isShipmentConversion: checked ? 1 : 0
                                                                    });
                                                                    if (checked) {
                                                                        this.props.fetchShipmentForInvoice({
                                                                            relationshipId: (this.props.companyInfo || {}).relationshipId,
                                                                            customerId: this.props.customer.customerId
                                                                        });
                                                                        const selectedSalesOrder = this.props.selectedSalesOrder;
                                                                        this.props.updateState({
                                                                            selectedSalesOrder: [],
                                                                            preservedSalesOrder: selectedSalesOrder,
                                                                            isShipment: true
                                                                        });
                                                                    } else {
                                                                        this.props.updateState({
                                                                            selectedSalesOrder: this.props.preservedSalesOrder || [],
                                                                            preservedSalesOrder: [],
                                                                            isShipment: false
                                                                        });
                                                                    }
                                                                }}/> 
                                                            <Tooltip placement="right" title="Please Enable to Create Invoice from Shipment" trigger="click">
                                                                <i className="fa fa-question-circle-o pl5 cursor-pointer"/>
                                                            </Tooltip>

                                                            {!this.props.isShipmentConversion ?
                                                <span>
                                                 
                                                    <MultipleSelectDropdown
                                                       style={{width: '278px', marginTop: -18, marginLeft: 10}}
                                                        items={this.props.soUnfulfilledList}
                                                        defaultValue={this.props.selectedSalesOrderIds}
                                                        valueKeyName='soNumber'
                                                        optionKeyName='salesOrderMasterId'
                                                        placeholder='Please select sales order'
                                                        mode={this.props.isShipmentConversion ? '' : 'multiple'}
                                                        onSelect={(selectedValue) => {
                                                            let soData = find(this.props.soUnfulfilledList, {salesOrderMasterId: +selectedValue}) || {};
                                                            this.getInvoiceData(this.props, soData);
                                                        }}
                                                        onDeSelect={(value) => {
                                                            let {selectedSalesOrder} = this.props;
                                                            selectedSalesOrder = filter(selectedSalesOrder, (soData) => {
                                                                return soData.salesOrderMasterId !== value;
                                                            });
                                                            let isSoConversion = selectedSalesOrder.length > 0 ? true : false;
                                                            this.props.updateState({selectedSalesOrder, isSoConversion})
                                                        }}
                                                    />
                                                </span>
                                                :
                                                <span>
                                                    <Dropdown
                                                        style={{width: '278px', marginTop: -18, marginLeft: 10}}
                                                        items={this.props.soShipmentList}
                                                        valueKeyName='shipmentNumber'
                                                        optionKeyName='shipmentMasterId'
                                                        placeholder='Please select shipment'
                                                        mode={'multiple'}
                                                        onSelect={(selectedValue) => {
                                                            let shipmentData = find(this.props.soShipmentList, {shipmentMasterId: +selectedValue}) || {};
                                                            this.getInvoiceDataFromShipment(this.props, shipmentData);
                                                        }}
                                                        onDeSelect={(value) => {
                                                            let {selectedSalesOrder} = this.props;
                                                            selectedSalesOrder = filter(selectedSalesOrder, (obj) => {
                                                                return obj.shipmentMasterId !== value;
                                                            });
                                                            this.props.updateState({selectedSalesOrder})
                                                        }}
                                                    />
                                                </span>
                                            }
                                                        </Col>
                                                    </Row>
                                                </Form.Item>
                                                : ''
                                        } */}
                                    </>
                                    <Row>
                                        <Col span={9} />
                                        <Col span={15}>

                                        </Col>
                                    </Row>
                                </Form.Item>
                                : ''
                        }
                    </Col>
                    <Col span={7}></Col>
                    <Col span={6} className="order-value-info">
                    {/* <Skeleton loading={this.props.detailLoading} paragraph={{ rows: 5 }} active></Skeleton> */}
                    <Row gutter={[0, 3]}>
              <Col span={24} className="details-heading">
                Invoice Information
              </Col>
                       
                                {/* <div className="project-dropdown">
                                    <Row>
                                        <Col span={12} className='text-right'>
                                            <Tooltip placement="bottom" title={this.props.intl.formatMessage(
                                                {
                                                    id: 'tooltip.generateSI',
                                                    defaultMessage: ''
                                                })
                                            }>
                                                <span className="tooltip-title">
                                                    <FormattedMessage
                                                        id='customer.salesInvoice.form.invoiceNumber.label'
                                                        defaultMessage='' />
                                                </span>
                                            </Tooltip>
                                        </Col>
                                        {/* <Col span={12} style={{ 'textAlign': 'left' }}>
                                            {this.props.nextTxNumber ?
                                                <span>{getFormatedNextTxNumber(this.props)}</span>
                                                :
                                                <img src={LoadingIcon} alt="Invoice" style={{
                                                    'height': '74px',
                                                    'marginTop': '-25px',
                                                    'marginBottom': '-25px'
                                                }} />
                                            }
                                        </Col> */}
                                    {/* </Row>
                                </div>  */}
                                {
                                    this.props.soNumber &&
                                    <Row>
                                        <Col span={12} className="text-right">
                                            <div className="title">
                                                <FormattedMessage id='customer.salesInvoice.form.soNumber.label'
                                                    defaultMessage='' />
                                            </div>
                                        </Col>
                                        <Col span={12}>
                                            <div className="value">
                                                {this.props.soNumber}
                                            </div>
                                        </Col>
                                    </Row>
                                }

                                <div className='date-picker-container'>
                                    <Row>
                                        <Col span={24} className="text-right">
                                            <div className="title">
                                                <FormattedMessage id='customer.salesInvoice.form.invoiceDate.label'
                                                    defaultMessage='' />
                                            </div>
                                        </Col>
                                        <Col span={24}>
                                            <div className="value">
                                                <DatePicker
                                                    allowClear={false}
                                                    format={format}
                                                    key={`${this.props.invoiceDate ? getMomentDateForUI({date: new Date(this.props.invoiceDate), format}) : getMomentDateForUI({date: new Date(), format})}`}
                                                    defaultValue={this.props.invoiceDate ? getMomentDateForUI({date: new Date(this.props.invoiceDate), format}) : getMomentDateForUI({date: new Date(), format})}
                                                    onChange={(selectedDate) => {
                                                        let selectedterm = this.props.customerPaymentTerm || {};
                                                        const updatedInvoiceDueDate = addDaysToDate((selectedDate || new Date()), selectedterm.numberOfDays);
                                                        this.props.updateState({
                                                            invoiceDate: selectedDate,
                                                            invoiceDueDate: updatedInvoiceDueDate
                                                        })
                                                        // this.props.getNextTxNumber({
                                                        //     date: selectedDate,
                                                        //     relationshipId: (this.props.companyInfo || {}).relationshipId
                                                        // });
                                                    }}
                                                    size={size}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                </div>

                                <div className='date-picker-container mb5'>
                                    <Row>
                                        <Col span={24} className="text-right">
                                            <div className="title">
                                                <FormattedMessage id='customer.salesInvoice.form.invoiceDueDate.label'
                                                    defaultMessage='' />
                                            </div>
                                        </Col>
                                        <Col span={24}>
                                            <div className="value">
                                                <DatePicker
                                                    format={format}
                                                    disabled={true}
                                                    key={`${getMomentDateForUI({date: this.getDueDateChange(), format})}`}
                                                    defaultValue={getMomentDateForUI({date: this.getDueDateChange(), format})}
                                                    size={size} />
                                            </div>
                                        </Col>
                                    </Row>
                                </div>

                                {/* <div className='project-dropdown'>
                                    <Row>
                                        <Col span={12} className="text-right">
                                            <div className="title">
                                                <FormattedMessage id='customer.salesInvoice.form.projectDropdown.label' defaultMessage='' />
                                            </div>
                                        </Col>
                                        <Col span={12}>
                                            <div className="value">
                                                <Dropdown
                                                    items={this.props.projectList}
                                                    valueKeyName='projectName'
                                                    value={this.props.projectName}
                                                    optionKeyName='projectMasterId'
                                                    canAddNew={true}
                                                    canDelete={true}
                                                    deleteClickHandler={(payload) => {
                                                        this.deleteClickHandler(this.props, MODAL_TYPE.PROJECT, payload);
                                                    }}
                                                    onAddNewClick={(payload) => {
                                                        payload.formData = {
                                                            projectName: payload.projectName,
                                                        }
                                                        this.handleNewProjectAddition(this.props, payload, MODAL_TYPE.PROJECT)
                                                    }}
                                                    onSelect={(projectMasterId) => {
                                                        const selectedProject = find(this.props.projectList, { projectMasterId: Number(projectMasterId) }) || {};
                                                        this.props.updateState({
                                                            projectMasterId,
                                                            projectNumber: selectedProject.projectNumber,
                                                            projectName: selectedProject.projectName
                                                        });
                                                    }}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                </div> */}
                                <div className="project-dropdown">
                                    <Row>
                                        <Col span={24} className="text-right">
                                        <FormattedMessage
                                            id='customer.salesOrder.form.documentDropdown.label'
                                            defaultMessage='' />
                                            <Tooltip placement="right"
                                                title={this.props.intl.formatMessage(
                                                    {
                                                        id: 'tooltip.documentAppearInPdf',
                                                        defaultMessage: ''
                                                    })
                                                }>
                                               
                                               <i className="fa fa-question-circle-o  cursor-pointer" />
                                            </Tooltip>
                                        </Col>
                                        <Col span={24}>
                                            <Dropdown
                                                style={{ width: '100%' }}
                                                items={filter((this.props.documentList || []), (document) => {
                                                    return document.txType === CONSTANTS_TRANSACTIONS_KEY[CONSTANTS_TRANSACTIONS.SALES_INVOICE];
                                                })}
                                                placeholder='Document name'
                                                value={this.props.docName && this.props.documentNameId}
                                                canAddNew={true}
                                                canDelete={true}
                                                deleteClickHandler={(payload) => {
                                                    this.deleteClickHandler(this.props, MODAL_TYPE.DOCUMENT_NAME, payload);
                                                }}
                                                onAddNewClick={(payload) => {
                                                    payload.formData = {
                                                        textBoxValue: payload.textEntered,
                                                        submittedOnce: false,
                                                        maxLength: 30
                                                    }
                                                    this.handleNewItemAddition(this.props, payload, MODAL_TYPE.DOCUMENT_NAME)
                                                }}
                                                onSelect={(selectedValue) => {
                                                    const selectedObj = find((this.props.documentList || []), { documentNameId: Number(selectedValue) });
                                                    this.props.updateState({
                                                        documentNameId: selectedValue,
                                                        docName: selectedObj.docName
                                                    })
                                                }}
                                                valueKeyName='docName'
                                                optionKeyName='documentNameId'
                                                allowClear={true}
                                                onClear={() => {
                                                    this.props.updateState({
                                                        documentNameId: undefined,
                                                        docName: undefined
                                                    })
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                </div>

                                <div className="project-dropdown">
                                    <Row>
                                        <Col span={24} >
                                          
                                              <FormattedMessage id='customer.salesInvoice.form.transportData.label' defaultMessage='' />
                                              <Tooltip
                                    placement="right"
                                    title={this.props.intl.formatMessage({
                                      id: "tooltip.qualityChekSetting",
                                      defaultMessage: "",
                                    })}
                                    
                                  >
                                    
                                      <i className="fa fa-question-circle-o  cursor-pointer" />
                                  
                                  </Tooltip>
                                        </Col>
                                        <Col span={24} className="documentTitleDropdown">
                                            <div className="cursor-pointer" onClick={() => {
                                                this.props.updateState({
                                                    title: <div className="drawer-title">Transport Data</div>,
                                                    transportDataDrawerVisible: true,
                                                    addTransportDataToSO: function (transportData) {
                                                        this.updateState({
                                                            ...transportData
                                                        })
                                                    }
                                                })
                                            }}>
                                                Add Transport Data
                                                </div>
                                        </Col>
                                    </Row>
                                </div>
                                </Row>
                            {/* </Form.Item> */}
                        {/* </div> */}
                    </Col>
                </Row>

            
                    <Row>
                        <Col span={24}>
                        
                    <CustomAgGrid
                        columnDefs={columnDefs}
                        onGridReady={onGridReady}
                        disableFocusOnClickOutside={true}
                        rowData={[{}]}
                        gridStyle={{
                            width: '100%',
                            height: '100px'
                        }} />
                        </Col>

                    </Row>


            </Fragment>
        );
    }
}

const getAddressInfoFragment = (obj, customerContactObj, customerObj) => {
    // if (!obj || !Object.keys(obj).length) {
    //     return '';
    // }


    let contactName = "";
    //if (customerContactObj.salutationName) contactName += customerContactObj.salutationName + ' ';
    //if (customerContactObj.firstName) contactName += customerContactObj.firstName;
    //if (customerContactObj.middleName) contactName += ' ' + customerContactObj.middleName;
    //if (customerContactObj.lastName) contactName += ' ' + customerContactObj.lastName;
    const fragmentObj = <Fragment>
        {
            obj.streetAddress1
                ? <div className='billing-address'>{obj.streetAddress1}
                </div>
                : ''
        }
        {
            obj.streetAddress2
                ? <div className='billing-address'>{obj.streetAddress2}
                </div>
                : ''
        }
        {
            obj.cityName
                ? <div className='billing-address'>
                    {
                        `${obj.cityName || ''}${obj.stateName ? ', ' + obj.stateName : ''}${obj.zipCode ? ', ' + obj.zipCode : ''}`
                    }
                </div>
                : ''
        }
        {
            customerContactObj.firstName
                ? <div className='billing-address'>
                    {contactName}
                </div> :
                ''
        }
        {
            customerContactObj.workPhone
                ? <div className='billing-address'>
                    <FormattedMessage id='customer.salesOrder.form.phone.label'
                        defaultMessage='' /> - {customerContactObj.workPhone}
                </div> :
                ''
        }
        {
            customerContactObj.email
                ? <div className='billing-address'>
                    <FormattedMessage id='customer.salesOrder.form.email.label'
                        defaultMessage='' /> - {customerContactObj.email}
                </div> :
                ''
        }
        {/* {
            customerObj.gstNumber
                ? <div className='billing-address'>
                    <FormattedMessage id='customer.salesOrder.form.gstIn.label' defaultMessage='' /> - {customerObj.gstNumber}
                </div> :
                ''
        }
        {
            customerObj.panNumber
                ? <div className='billing-address'>
                    <FormattedMessage id='customer.salesOrder.form.pan.label' defaultMessage='' /> - {customerObj.panNumber}
                </div> :
                ''
        } */}

    </Fragment>

    return fragmentObj;
}



export default injectIntl(CustomerInvoiceDetails);
