import React from "react";
import ReactApexChart from "react-apexcharts"

class BookingProjectChart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      options: {
        chart: {
          id: "basic-bar"
        },
        xaxis: {
          categories: ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug"]
        }
      },
      series: [
        {
          name: "series-1",
          data: [30, 40, 45, 50, 49, 60, 70, 91]
        }
      ]
    };
  }



  render() {

    const series = [{
      name: `Paid in ${(this.props.companyInfo || {}).currencyCode + " "}`,
      data: Object.values(this.props.purchaseData || {})?.map((e) => {

        return e?.paidAmount ? Math.round(e?.paidAmount) : 0;
      })
    }, {
      name: "Due",
      data: Object.values(this.props.purchaseData || {})?.map((e) => {

        return -Math.round((e?.salesAmount) - (e?.paidAmount))
      })

    }, {
      name: "Total Amount",
      data: Object.values(this.props.purchaseData || {})?.map((e) => {

        return Math.round((e?.salesAmount))
      })

    }];
    const options = {
      chart: {
        height: 350,
        type: 'bar',
        events: {
          click: function (chart, w, e) {
          }
        },
        toolbar: {
          show: false
        },
      },
      colors: ['#1CB980', '#ED5F56', '#3150B9'],
      plotOptions: {
        enabled: 'false',
        bar: {
          columnWidth: '80%',
          // distributed: true,
        }
      },
      dataLabels: {
        enabled: false
      },
      legend: {
        show: false
      },
      xaxis: {
        categories: Object.keys(this.props.purchaseData || {}),
      },

    };
    return (
      <div id="chart">
        <ReactApexChart options={this.state.options} series={this.state.series} type="bar" height={260} />
      </div>


    );
  }
}
export default BookingProjectChart;