import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import TeamAndRolesComp from '../../../components/Account/TeamRoles';
import UserManagementComponent from '../../../components/ShowSettings/userManagement';
import { showModal, hideModal, pushModalToStack, popModalFromStack } from '../../../actions/commonActions';
import { fetchAllUsers, deleteUsers } from '../../settings/userManagement/action';

class TeamAndRoles extends Component {

    componentDidMount() {
        const companyInfo = this.props.companyInfo || {};
        this.props.fetchAllUsers({relationshipId: companyInfo.relationshipId});
    }

    render() {
        return <TeamAndRolesComp {...this.props} updateState={(data) =>{
            this.setState(data);
        }}/>
    }

}

const mapStateToProps = (state) => {
    return {
        companyInfo: state.common.companyInfo,
        userInfo: state.common.userInfo,
        isLoading: state.common.isLoading,
        allCompanyUsers: state.settings.allCompanyUsers,
        isAlifApp: state.common.isAlifApp,
        listLoading: state.common.listLoading,
        permissions: state.auth.permissions,

    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    showModal, 
    hideModal, 
    pushModalToStack, 
    popModalFromStack,
    fetchAllUsers,
    deleteUsers
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(TeamAndRoles);
