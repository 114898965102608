import React from "react";
import { Tabs } from "antd";
import PageBreadcrumb from "../../../PageBreadcrumb";
import AllPackage from "./allPackage";
import NotShippdedPackage from "./notShippedPackage";
import ShippdedPackage from "./shippedPackage";
import DeliveredPackage from "./deliveredPackage";
import { FormattedMessage } from "react-intl";
import SalesOrderPackage from "../../../../containers/customer/salesOrder/package/index";
import { ICONS, MODULE_CODED_VALUES } from "../../../../static/constants";
import PageHeader from "../../../Common/pageHeader";
//import { fetchDataIfNeeded, fetchPaginationDataIfNeeded } from '../../../../utils';
const { TabPane } = Tabs;

function callback(key, props) {
  const payload = {
    companyInfo: props.companyInfo,
    relationshipId: props.companyInfo.relationshipId,
    pageNumber: props.pageNumber,
    pageSize: props.pageSize,
  };
  if (key === "1") {
    payload.packageStatus = "all";
    props.updateState({ activeTab: "All", activeTabKey: "1" });
    props.fetchAllPackage(payload);
    props.getSOPackageCount(payload);
    //fetchPaginationDataIfNeeded('fetchAllPackage', 'packageList', props, payload);
    // fetchDataIfNeeded('getSOPackageCount', 'packageCount', props, payload);
  } else if (key === "2") {
    payload.packageStatus = "Not Shipped";
    props.updateState({ activeTab: "Not Shipped", activeTabKey: "2" });
    props.fetchAllPackage(payload);
    props.getSOPackageCount(payload);
    //fetchPaginationDataIfNeeded('fetchAllPackage', 'packageList', props, payload);
    // fetchDataIfNeeded('getSOPackageCount', 'packageCount', props, payload);
  } else if (key === "3") {
    payload.packageStatus = "Shipped";
    props.updateState({ activeTab: "Shipped", activeTabKey: "3" });
    props.fetchAllPackage(payload);
    props.getSOPackageCount(payload);
    // fetchPaginationDataIfNeeded('fetchAllPackage', 'packageList', props, payload);
    //fetchDataIfNeeded('getSOPackageCount', 'packageCount', props, payload);
  } else if (key === "4") {
    payload.packageStatus = "Delivered";
    props.updateState({ activeTab: "Delivered", activeTabKey: "4",pageNumber:1,pageSize:25 });
    props.fetchAllPackage({...payload,pageSize:25,pageNumber:1});
    props.getSOPackageCount(payload);
  }
}

const SalesOrderListingComp = (props) => {
  const breadCrumbList = [
    {
      name: <FormattedMessage id="common.sales" defaultMessage="" />,
    },
    {
      name: <FormattedMessage id="package.text" defaultMessage="" />,
    },
  ];
  const openNewPackage = () => {
    props.updateState({ packageType: "new" });
    setTimeout(() => {}, 2000);
    props.pushModalToStack({
      modalBody: <SalesOrderPackage {...props} packageType="new" />,
      width: "100%",
      hideTitle: true,
      hideFooter: true,
      keyboard:false,
      wrapClassName: "new-transaction-wrapper",
      onCancel: () => {
        props.showModal({
          modalBody: <FormattedMessage id="modal.cancel.confirmation" defaultMessage=""/>,
          footer:{submit:{intlId:"button.close.label"}},
          handleSubmit: () => {
            props.popModalFromStack();
          }
        })
      }
    });
  };
  return (
    <>
    <PageHeader {...props}
        pageName="heading.sales.salesPackages"
        breadCrumbList={breadCrumbList}
        canCreate={true}
        onCreate={openNewPackage}
        moduleCode ={MODULE_CODED_VALUES.SALES_ORDER_PACKAGE}
      />
     
        {/* <Row className="search-container" type='flex' justify='end'>
                <Col span={12}>

                </Col>

                {/* <Col className='align-end' span={12}> */}
        {/* <div className="action-dropdown-btn">
                    <button onClick={() => {
                        props.history.push(
                            {
                                pathname: "/admin/so/package",
                                state: {
                                    type: "new"
                                }
                            }
                        );
                        }
                    }>
                        <i className="fa fa-user" /><FormattedMessage id='newPackage.text' defaultMessage='' />
                    </button>
                    </div> */}
        {/* </Col> */}
        {/* </Row> */}
      <div className="view-container">

        <Tabs
          activeKey={props.activeTabKey || "1"} onChange={(key) => {callback(key, props);}}
          type="line"
        >
          <TabPane tab="All" key="1">
            <AllPackage {...props} />
          </TabPane>
          <TabPane tab="Not Shipped" key="2">
            <NotShippdedPackage {...props} />
          </TabPane>
          <TabPane tab="Shipped" key="3">
            <ShippdedPackage {...props} />
          </TabPane>
          <TabPane tab="Delivered" key="4">
            <DeliveredPackage {...props} />
          </TabPane>
        </Tabs>
      </div>
    </>
  );
};

export default SalesOrderListingComp;
