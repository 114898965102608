import React from 'react';
import { Divider, Empty, Skeleton } from 'antd';
import { getMomentDateForUIReadOnly } from '../../../utils';


const RecentNotices = (props) => {
    function removeTags(html) {
        return html?.replace(/<[^>]+>/g, '');
    }
    const groupMessagesByDate = (messages) => {
        return messages.reduce((acc, message) => {
            const date = new Date(message.startDate).toLocaleDateString();
            if (!acc[date]) {
                acc[date] = [];
            }
            acc[date].push(message);
            return acc;
        }, {});
    };
    
    const groupedMessages = groupMessagesByDate(props.broadcastMessageList[1] || []);
    return <>
    <Skeleton loading={props.listLoading} paragraph={{ rows: 4 }} active>

<div>
        { groupedMessages.length ? Object.entries(groupedMessages).map(([date, messages]) => (
            <div key={date}>
                <div className='fee_container'>{getMomentDateForUIReadOnly({ date: new Date(date), format: "DD MMM" })}
                <Divider />
                </div>
                {messages.map((data, i) => (
                    <div className='notice_wrapper' key={i}>
                        <div>{getMomentDateForUIReadOnly({ date: new Date(data.startDate), format: "HH:mm" })}</div>
                        <div className='notice_name'>
                            <div className='ml10'>
                                {data.subject}
                                <div className='b-text'>
                                    {removeTags(data.message)}
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        )):<Empty />}
    </div>
    </Skeleton>
            {/* {(props.broadcastMessageList[1]  || []).length ? props.broadcastMessageList[1].map((data, i) => {
        return <div>
            <div className='fee_container'>{getMomentDateForUIReadOnly({date: new Date(data.startDate),format:"DD MMM"})}

                <Divider />
            </div>
            <div className='notice_wrapper'>

                <div>{getMomentDateForUIReadOnly({date: new Date(data.startDate),format:"HH:mm"})}</div>
                <div className='notice_name'>
                    <div className='ml10'>
                        {data.subject}
                        <div className='b-text'>
                            {removeTags(data.message)}

                        </div>
                    </div>
                </div>

            </div>

        </div>
            }):""} */}
    </>
};

export default RecentNotices;