import React, { Component, Fragment } from "react";
import { FormattedMessage } from "react-intl";
import {
  CONSTANTS,
  ICONS,
  MODULE_CODED_VALUES,
  PERMISSION_VALUES,
} from "../../../../static/constants";
import LogActivity from "../../../../containers/supplier/Listing/LogActivity";
// import { CustomUploader } from '../../../general/CustomUploader';
import {
  checkACLPermission,
  getMomentDateForUIReadOnly,getPermissionData
} from "../../../../utils";
import { Menu, Dropdown, Skeleton, Button, Empty } from "antd";

// import { AgGridReact } from 'ag-grid-react';
// import ReactHtmlParser from 'react-html-parser';

const format = CONSTANTS.DISPLAY_DATE_FORMAT;

class Tasks extends Component {
  state = {
    buttonDropDown: false,
  };
  toggleDropdown = () => {
    const { buttonDropDown } = this.state;
    this.setState({
      buttonDropDown: !buttonDropDown,
    });
  };
  
  render() {
    const supplierTaskList = this.props.activitiesList || [];
 
    const deleteActivity = (payload) => {
      const modalBody = (
        <FormattedMessage id="deleteActivity.confirmation" defaultMessage="" />
      );
      const modalData = {
        modalBody,
        handleSubmit: () => {
          this.props.deleteSupplierActivities(payload);
        },
      };
      this.props.showModal(modalData);
    };

    const { permissions } = this.props;
    const primaryPerm = (permissions && permissions.primary) || [];

    const permissionData =getPermissionData(primaryPerm, MODULE_CODED_VALUES.SUPPLIER_ACTIVITY )
    const handleAddUpdateActivity = (payload) => {
      const modalData = {
        title: (
          <React.Fragment>
            <div className="modal-title">
              {payload.activityTypeName ? (
               "Edit Task"
              ) : (
                <FormattedMessage id="activity.title" defaultMessage="" />
              )}
            </div>
            <div className="modal-sub-title">{this.props.customerName}</div>
          </React.Fragment>
        ),
        modalBody: (
          <LogActivity
            supplierId={this.props.supplierId}
            supplierName={this.props.supplierName}
            formData={payload}
          />
        ),
        wrapClassName: "log-activity-modal",
        width: 500,
        hideFooter: true,
        onCancel: () => {
          this.props.showModal({
            modalBody: <FormattedMessage id="modal.cancel.confirmation" defaultMessage=""/>,
            footer:{submit:{intlId:"button.close.label"}},
            handleSubmit: () => {
              this.props.popModalFromStack();
            }
          })
        }
      };
      this.props.pushModalToStack(modalData);
    };
    const actionMenu = (e) => {
      return (
        <Menu className="row-action-dropdown">
          <Menu.Item key="0" value="title">
            Actions
          </Menu.Item>

          <Menu.Divider />

       {permissionData.update ?  <Menu.Item
            key="1"
            value="edit"
            onClick={() => {
              // handleNewContactAddition(e, this.props);
              handleAddUpdateActivity(e);
              // this.props.updateState({
              //   supplierTaskDrawerVisible: true,
              //   customerTask: e
              // })
            }}
          >
            <i className={ICONS.EDIT} /> Edit
          </Menu.Item> : "" }

          {permissionData.delete ? <Menu.Item
            key="2"
            onClick={() => {
              deleteActivity(e);
            }}
          >
            <i className={ICONS.DELETE} /> Delete
          </Menu.Item> : "" }
        </Menu>
      );
    };
    return (
        <div className="view-container">
         
          <div className="view-container-actions">
            <div className="left-actions">
              <div className="table-heading">Task List</div>
              {(supplierTaskList || []).length ? (
                <>
                  <div className="vertical-sep" />
                  <div>{supplierTaskList.length}</div>
                </>
              ) : (
                ""
              )}
            </div>
            <div className="right-actions">
              {/* duplicate create button in case viewed in profile view */}
              {/* {checkACLPermission(
                primaryPerm,
                MODULE_CODED_VALUES.CUSTOMER_ACTIVITY,
                PERMISSION_VALUES.CREATE
              ) ? ( */}
              {permissionData.create ?(
                <Button
                  style={{ backgroundColor: "#1cb961", color: "#ffffff" }}
                  className="create-action-btn"
                  onClick={() => {
                    // handleAddUpdateActivity({});
                    this.props.updateState({
                      supplierTaskDrawerVisible: true,
                    });
                  }}
                >
                  <i
                    className={ICONS.ADD}
                    style={{ margin: "4px 5px 0px 0px" }}
                  />{" "}
                  <FormattedMessage
                    id="button.create.label"
                    defaultMessage=""
                  />
                  
                </Button>
              ) : (
                ""
              )}
            </div>
          </div>
          <Skeleton loading={this.props.isLoading}>
            <div className="table-container" style={{ height: 'calc(100vh - 138px)'}}>
              <table>
                <thead>
                  <tr>
                    <th>Task Type</th>
                    <th>
                      <FormattedMessage
                        id="common.description"
                        defaultMessage=""
                      />
                    </th>
                    <th>
                      <FormattedMessage id="common.ref" defaultMessage="" />
                    </th>
                    <th>
                      <FormattedMessage id="common.status" defaultMessage="" />
                    </th>
                    <th>
                      <FormattedMessage
                        id="common.priority"
                        defaultMessage=""
                      />
                    </th>
                    <th>
                      <FormattedMessage
                        id="activity.assignedTo"
                        defaultMessage=""
                      />
                    </th>
                    <th>
                      <FormattedMessage
                        id="activity.dueDate"
                        defaultMessage=""
                      />
                    </th>
                    <th>
                      <FormattedMessage
                        id="activity.closeDate"
                        defaultMessage=""
                      />
                    </th>
                    <th>
                      <FormattedMessage
                        id="common.dateCreated"
                        defaultMessage=""
                      />
                    </th>
                    {permissionData.update || permissionData.delete ? <th>
                      <FormattedMessage
                        id="customer.listing.header.action"
                        defaultMessage=""
                      />
                    </th> : "" }
                  </tr>
                </thead>
                <tbody>
                  {supplierTaskList.length > 0 ? (
                    supplierTaskList.sort((a, b) => (a.activityId < b.activityId ? 1 : -1)).map((rowData, index) => (
                      <tr key={index}>
                        <td>{rowData.activityTypeName?rowData.activityTypeName:<div className="empty-data-box"></div>}</td>
                        <td>{rowData.description?rowData.description:<div className="empty-data-box"></div>}</td>
                        <td>{rowData.refNumber?rowData.refNumber:<div className="empty-data-box"></div>}</td>
                        <td>{rowData.activityStatusName?rowData.activityStatusName:<div className="empty-data-box"></div>}</td>
                        <td>{rowData.activityPriorityName?rowData.activityPriorityName:<div className="empty-data-box"></div>}</td>
                        <td>{rowData.assignedUserName?rowData.assignedUserName:<div className="empty-data-box"></div>}</td>
                        <td>
                          {rowData.activityDueDate ?
                            getMomentDateForUIReadOnly({
                              date: new Date(rowData.activityDueDate),
                              format,
                            }):<div className="empty-data-box"></div>}
                        </td>
                        <td>
                          {rowData.activityClosedDate ?
                            getMomentDateForUIReadOnly({
                              date: new Date(rowData.activityClosedDate),
                              format,
                            }):<div className="empty-data-box"></div>}
                        </td>
                        <td>
                          {rowData.dateCreated ?
                            getMomentDateForUIReadOnly({
                              date: new Date(rowData.dateCreated),
                              format,
                            }):<div className="empty-data-box"></div>}
                        </td>
                      {permissionData.update || permissionData.delete ?  <td style={{ textAlign: "center" }}>
                          <div className="action-icon">
                            <Dropdown
                              overlay={actionMenu(rowData)}
                              trigger={["click"]}
                            >
                              <i className={ICONS.MORE} />
                            </Dropdown>
                          </div>
                          {/* { 
                              <div className="table-cell-action" >
                                <span className='cursor-pointer' onClick={() => {
                                  handleAddUpdateActivity(rowData)}}>
                                  <i className="fa fa-edit" />
                                </span>
                                <span className='cursor-pointer' onClick={() => deleteActivity(rowData)}>
                                    <i className="fa fa-remove" />
                                </span>
                              </div>
                            } */}
                        </td> : "" }
                      </tr>
                    ))
                  ) : (
                    <tr key="empty-data">
                      <td colSpan={"100%"} style={{ borderBottom: "none" }}>
                        <Empty />
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </Skeleton>

          <div className="footer">
            <div className="f-left"></div>
            <div className="f-right"></div>
          </div>
        </div>
    );
  }
}

export default Tasks;
