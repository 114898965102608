import React, { useState } from "react";
import "../../../../eStore/storeSettings/store-settings.scss";
import { Button, Col, Row } from "antd";

import { TextBox } from "../../../../general/TextBox";


import {FacebookFilled,TwitterSquareFilled ,YoutubeFilled ,LinkedinFilled ,InstagramFilled  } from '@ant-design/icons';
import { FormattedMessage } from "react-intl";
import { ErrorMsg } from "../../../../general/ErrorMessage";
import { DropdownRef } from "../../../../general/Dropdown";
import { getPermissionData } from "../../../../../utils";
import { MODULE_CODED_VALUES } from "../../../../../static/constants";
const socialMediaFields = [
  { label: "Facebook URL", stateKey: "facebookUrl" ,icon :<FacebookFilled style={{color:"#1877F2",fontSize:"28px"}}/> },
  { label: "Twitter URL", stateKey: "twitterUrl" ,icon :<TwitterSquareFilled style={{color:"#1DA1F2",fontSize:"28px"}}/>},
  { label: "Instagram URL", stateKey: "instagramUrl",icon :<InstagramFilled style={{color:"#d62976",fontSize:"28px"}}/> },
  { label: "LinkedIn URL", stateKey: "linkedInUrl",icon :<LinkedinFilled style={{color:"#0077b5 ",fontSize:"28px"}}/> },
  { label: "Youtube URL", stateKey: "youtubeUrl" ,icon :<YoutubeFilled style={{color:"#FF0000",fontSize:"28px"}}/>},
  { label: "Pinterest URL", stateKey: "pinterestUrl" ,icon :<i class="fi fi-brands-pinterest" style={{color:"#E60023",fontSize:"28px"}}></i>,},
];
const rowGutter = [24, 16];
const SupportAndSocialComp = (props) => {
  const {permissions} = props;
  const primaryPerm = (permissions && permissions.primary) || [];
  const permissionsData = getPermissionData(primaryPerm, MODULE_CODED_VALUES.E_STORE_SETTING);
  const values =  props?.additionalValue?.customerSupport

    const handleInputChange = (field, value) => {
        let val = props.initialFormState;
        val[field] = value;
       props.updateState({initialFormState: val})
    };


    const HandleSave =()=>{
      
       const modalData ={
        modalBody: (
          <FormattedMessage
            id="customer.salesQuote.edit.form.confirmation"
            defaultMessage=""
          />
        ),
        handleSubmit: () => {
          props.hideModal()
          const val = {...props.storeData ,...props.initialFormState, additionalValues:JSON.stringify(props.additionalValue || {}),
          relationshipId:props.userInfo.relationshipId}
          const payload ={
            ecomSettingSave: val,
          }
          props.updateOrCreateEcomSettings(payload ,props)
        }
       }
       props.showModal(modalData)
    }

  return (
    <div className="store-model-container cus-space">
   
       <div className="store-main-container">
        <div>

      
       <div className="model-head">
        <p className="heading">Social Profiles</p>
        <p className="sub-heading">
        Connect with customers and grow your online presence.
        </p>
        </div>
        <div  className="model-container">
        <Row gutter={rowGutter} >
        
        {socialMediaFields.map((field) => (
      <Col span={12} key={field.label} className="social-icon">
        <div className="i-label">{field.label}</div>
        <TextBox
          placeholder={field.label}
          type="text"
          countStyle={{ top: "40px" }}
          prefix={field.icon}
          maxLength={50}
          value={props.initialFormState[field.stateKey]}
          onChange={(e) => {
            handleInputChange(field.stateKey, e.target.value);
          }}
        />
      </Col>
    ))}
    </Row>
        </div>
        </div>

        <div>
        <div className="model-head">
        <p className="heading">Customer support</p>
        <p className="sub-heading">
        Stay connected and responsive to your customers’ needs.
        </p>
        </div>
        <div className="model-container">
        <Row gutter={rowGutter}>
          
          <Col span={12}>
            <div className="i-label">Email</div>
            <TextBox
              placeholder="Email Address"
              type="email"
              value={values?.email||""}
              maxLength={50}
              countStyle={{ top: "40px" }}
              onChange ={(e)=>{
                const val = props.additionalValue;
               
                val["customerSupport"] = {
                  ...(val?.customerSupport||{}),
                  email: e.target.value
                };
                props.updateState({
                  additionalValue: val
                })
              }}
            />
          </Col>
          <Col span={12}>
            <div className="i-label">Mobile Number</div>
            


<Row>
  <Col style={{width:"10%"}} >
  <DropdownRef
  style={{width:"100%"}}
  items={
    (props.countries || []).filter((el) => {
      return el.countryCallingCode != null;
    }) || []
  }
  optionKeyName="countryId"
  valueKeyName="countryCallingCode"
  defaultValue={values?.countryCallingCode}
  className="currency-tags"
  onSelect={(selectedValue, option) => {
    const selectedCountry = props.countries.find((el) => el.countryId === selectedValue);
    const val = props.additionalValue;
    val["customerSupport"] = {
      ...(val?.customerSupport||{}),
      countryCallingCode: selectedCountry.countryCallingCode
    };
    if (selectedCountry) {
      props.updateState({
        additionalValue: val
          });
    }

  }}
/>
  </Col>
  <Col style={{width:"90%"}}>
  <TextBox
  className="textbox-pd"
  maxLength={10}
  countStyle={{ top: "10px" }}
  value={values?.phone}
  placeholder={'Enter Mobile No.'}
  onChange={(e) => {
    const re = /^[0-9\b]+$/;
    const val = props.additionalValue;
    if (e.target.value === "" || re.test(e.target.value)) {
      val["customerSupport"] = {
        ...(val?.customerSupport||{}),
        phone: e.target.value
      };
     
      
      props.updateState({
        additionalValue: val
      })
    }
  }}
/>
  </Col>
</Row>




            
             
          </Col>
          <Col span={12}>
            <div className="i-label">Whatsapp Number</div>
            <Row>
  <Col style={{width:"10%"}}>
  <DropdownRef
  style={{width:"100%"}}
  items={
    (props.countries || []).filter((el) => {
      return el.countryCallingCode != null;
    }) || []
  }
  optionKeyName="countryId"
  valueKeyName="countryCallingCode"
  defaultValue={values?.countryCallingCode1}
  className="currency-tags"
  onSelect={(selectedValue, option) => {
    const selectedCountry = props.countries.find((el) => el.countryId === selectedValue);
    const val = props.additionalValue;
    val["customerSupport"] = {
      ...(val?.customerSupport||{}),
      countryCallingCode1: selectedCountry.countryCallingCode
    };
    if (selectedCountry) {
      props.updateState({
        additionalValue: val
          });
    }

  }}
/>
  </Col>
  <Col style={{width:"90%"}}>
  <TextBox
  className="textbox-pd"
  maxLength={10}
  countStyle={{ top: "10px" }}
  value={values?.whatsapp}
  placeholder={'Enter Mobile No.'}
  onChange={(e) => {
    const re = /^[0-9\b]+$/;
    const val = props.additionalValue;
    if (e.target.value === "" || re.test(e.target.value)) {
      val["customerSupport"] = {
        ...(val?.customerSupport||{}),
        whatsapp: e.target.value
      };
     
      
      props.updateState({
        additionalValue: val
      })
    }
  }}
/>
  </Col>
</Row>
            
             
          </Col>
        </Row>
        <Row>
          <Col span={24}>
        <b>Address</b>
          </Col>
        </Row>
        <Row gutter={rowGutter}>
        <Col span={12}>
                        <div className="i-label">Street Address 1</div>
                        <TextBox
                            placeholder="Eg. Main Street"
                            type="text"
                            maxLength={50}
                            countStyle={{ top: "40px" }}
                            value={values?.address?.streetAddress1||""}
                            onChange ={(e)=>{
                              const val1 = props.additionalValue
                              const val = val1?.customerSupport
                              val.address  = {...val.address ,streetAddress1: e.target.value}
                              
                              props.updateState({
                                additionalValue: val1
                              })
                           
                              
                            }}
                        />
                    </Col>

                    <Col span={12}>
                        <div className="i-label">Street Address 2</div>
                        <TextBox
                            placeholder="Eg. 123"
                            type="text"
                            maxLength={50}
                            countStyle={{ top: "40px" }}
                            value={values?.address?.streetAddress2||""}
                            onChange ={(e)=>{
                              const val1 = props.additionalValue
                              const val = val1?.customerSupport
                              val.address  = {...val.address ,streetAddress2: e.target.value}
                              
                              props.updateState({
                                additionalValue: val1
                              })
                              
                            }}
                        />
                    </Col>

                    <Col span={12}>
                        <span className="i-label">City </span>
                      
                        <TextBox
                            placeholder="Eg. abc"
                            type="text"
                            maxLength={20} 
                          
                            countStyle={{ top: "40px" }}
                            value={values?.address?.city||""}
                            onChange ={(e)=>{
                              const val1 = props.additionalValue
                              const val = val1?.customerSupport
                              val.address  = {...val.address ,city: e.target.value}
                              
                              props.updateState({
                                additionalValue: val1
                              })
                              
                            }}
                        />

                    </Col>

                    <Col span={12}>
                        <span className="i-label">State </span>
                      
                        <TextBox
                            placeholder="Eg. abc"
                            type="text"
                            maxLength={20}
                            countStyle={{ top: "40px" }}
                            value={values?.address?.stateName||""}
                            onChange ={(e)=>{
                              const val1 = props.additionalValue
                              const val = val1?.customerSupport
                              val.address  = {...val.address ,stateName: e.target.value}
                              
                              props.updateState({
                                additionalValue: val1
                              })
                              
                            }}
                        />
                    </Col>
                    <Col span={12}>
                        <span className="i-label">Country </span>
                        
                        <TextBox
                            placeholder="Eg. abc"
                            type="text"
                            maxLength={20}
                            countStyle={{ top: "40px" }}
                            value={values?.address?.countryName||""}
                            onChange ={(e)=>{
                              const val1 = props.additionalValue
                              const val = val1?.customerSupport
                              val.address  = {...val.address ,countryName: e.target.value}
                              
                              props.updateState({
                                additionalValue: val1
                              })
                              
                            }}
                        />
                    </Col>
                    <Col span={12}>
                        <span className="i-label">Zip Code </span>
                       
                        <TextBox
                            placeholder="Eg. 123456"
                            type="text"
                            value={values?.address?.zipCode||""}
                            maxLength={6}
                            countStyle={{ top: "40px" }}
                            onChange ={(e)=>{
                              const val1 = props.additionalValue
                              const val = val1?.customerSupport
                              val.address  = {...val.address ,zipCode: e.target.value}
                              
                              props.updateState({
                                additionalValue: val1
                              })
                            }}
                        />

                    </Col>
        </Row>
        </div>
        </div>
       </div>
       

       


        <div className="footer-btn">
         <Button type="primary"
          disabled={!(permissionsData.update)}

         onClick={()=>HandleSave()} >Submit</Button>
      </div>
    
  
    </div>
  );
};

export default SupportAndSocialComp;
