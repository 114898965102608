import React, {Fragment,useEffect,useState,useMemo} from 'react';
import { FormattedMessage } from 'react-intl';
import { FilePdfOutlined, MailOutlined,  PrinterOutlined, EditOutlined, DeleteOutlined, CopyOutlined } from '@ant-design/icons';
import {Row, Col, Button,Skeleton} from 'antd'
import * as find from 'lodash.find';
import * as filter from 'lodash.filter';
import ReactHtmlParser from 'react-html-parser';
import config from '../../../../../config/environmentConfig';
import { CONSTANTS, TRANSACTION_TYPES, PERMISSION_VALUES, MODULE_CODED_VALUES } from '../../../../../static/constants';
import { base64Toblob, getAWSFileURL, fixedDecimalNumber, checkACLPermission, getMomentDateForUIReadOnly, numberToWordsWithDecimals, getPermissionData } from '../../../../../utils';
import {ImageCropper} from '../../../../../components/general/ImageCropper';
import {CustomUploader} from "../../../../../components/general/CustomUploader";
import {AWSFileTable} from '../../../../general/AWSFileTable';
import StandardDebitMemo from '../../../../../containers/supplier/debitMemo/StandardDebitMemo';


const DebitMemoDetails =  (props) =>{
    const { debitMemoData, companyInfo, permissions } = props;
    const primaryPerm = permissions.primary;
    const permissionsData = useMemo(()=>{
        return getPermissionData(primaryPerm, MODULE_CODED_VALUES.DEBIT_MEMO )
      },[])
    const { supplierDebitMemoDetailsList } = debitMemoData;
    const relationshipAddress = find(debitMemoData.boLocationDebitMemoList, {locationType: CONSTANTS.RELATIONSHIP_BILLING_ADDRESS}) || {};
    const billingAddress = find(debitMemoData.boLocationDebitMemoList, {locationType: CONSTANTS.BILLING_ADDRESS}) || {};
    let isPlaceOfSupplySameAsRelationship = false
    isPlaceOfSupplySameAsRelationship = (debitMemoData.placeOfSupply && billingAddress.stateName === debitMemoData.placeOfSupply) || false;
    let itemLevelTaxes = [];
    if (supplierDebitMemoDetailsList) {
        itemLevelTaxes = calculateTaxRateList(supplierDebitMemoDetailsList, isPlaceOfSupplySameAsRelationship);
    }
    let isColumnEmpty = {
        product: true,
        service: true,
        variant: true,
        description: true,
        qty: true,
        uomName: true,
        rate: true,
        hsn: true,
        discount: true,
        tax: true,
        specialDiscount: true,
        documentDetails: true
    };
    if (debitMemoData) {
        isColumnEmpty = updateColumnEmptyCheck(isColumnEmpty, debitMemoData, props);
    }

    const customProps = {
        type: CONSTANTS.TYPE_IMAGE,
        imgUrl: getAWSFileURL((debitMemoData || {}).stampAwsKey, config.BUCKET_NAME.BO_DEBIT_MEMO) || '',
        isSignature: true,
        height: 60,
        width: 60,
        name: 'file',
        action: null,
        buttonName: 'Upload Customer PO',
        onChange: (file) => {
            if (file[0]?.originFileObj) {
                var reader = new FileReader();

                reader.onload = function (e) {
                    props.showModal({
                        title: <Fragment>
                            <div className='ant-modal-title'>Crop Image</div>
                        </Fragment>,
                        //modalBody: <ImageCropper cropperRef={cropperRef} onClick={(e) => {
                        modalBody: <ImageCropper onClick={(e) => {
                        }} handleSubmit={(newUrl) => {
                            props.uploadStamp({
                                supplierId: debitMemoData.supplierId,
                                debitMemoMasterId: debitMemoData.debitMemoMasterId,
                                rid: debitMemoData.relationshipId,
                                objectKey: debitMemoData.stampAwsKey,
                                bucketName: config.BUCKET_NAME.BO_RELATIONSHIP,
                                file: base64Toblob(newUrl)
                            })
                        }}{...props} url={e.target.result}/>,
                        width: 800,
                        hideFooter: true,
                        wrapClassName: 'Upload-profile-modal'
                    })
                }

                reader.readAsDataURL(file[0]?.originFileObj); // convert to base64 string
            }
        }
    }
   
    const getPayloadForPdf = () => {
        return {
            fileName : (debitMemoData.debitMemoNumber || 'Debit Memo')+'.pdf',
            transactionName: 'debitMemo',
            debitMemoData: debitMemoData,
            relationshipAddress: relationshipAddress,
            billingAddress: billingAddress,
            companyInfo: companyInfo,
            isColumnEmpty: isColumnEmpty,
            isPlaceOfSupplySameAsRelationship: isPlaceOfSupplySameAsRelationship,
            itemLevelTaxes: itemLevelTaxes,
            bucketName: config.BUCKET_NAME.BO_DEBIT_MEMO,
            stampAwsKey: debitMemoData.stampAwsKey
        }
    }

    const generatePDF = () => {
        let payload = getPayloadForPdf();
        props.generatePDF(payload);
    }

    const printPdf = () =>{
        let payload = getPayloadForPdf();
        props.printPDF(payload);
    }

    const openEmailDrawer = (props) => {
        let payload = getPayloadForPdf();
        payload.fileDataReqeired= true;
        payload.supplierId = debitMemoData.supplierId;
        payload.debitMemoMasterId = debitMemoData.debitMemoMasterId;
        props.updateState({
            emailDrawerVisible: true,
            txData: payload,
            type: TRANSACTION_TYPES.DEBIT_MEMO,
        });
    };

    const [showComp, setShowComp] = useState(false);
  useEffect(()=>{
    const delay = setTimeout(()=>{
      setShowComp(true);
    }, 400);
    return ()=> clearTimeout(delay);
  }, []);
    return (
        <div className={"custom-modal show"}>
        <i className="close-icon">
                <svg width="17" height="17" onClick={() =>{props.popModalFromStack();}}>
                    <title><FormattedMessage id='button.close.label' defaultMessage='' /></title>
                    <path fill="#67757d" fillRule="evenodd"
                          d="M16.803 15.197c-.012.465-.221.818-.64 1.019-.431.207-.872.177-1.227-.15-.587-.542-1.145-1.116-1.712-1.68a1413.788 1413.788 0 0 1-4.242-4.242c-.137-.137-.208-.129-.34.004-1.91 1.915-3.827 3.825-5.741 5.738-.272.271-.569.463-.973.445-.455-.019-.791-.233-.987-.634-.201-.407-.163-.812.106-1.184.076-.106.171-.199.263-.292l5.692-5.683c.182-.182.182-.182.005-.359-1.911-1.907-3.82-3.816-5.734-5.719C.89 2.079.707 1.653.894 1.124A1.136 1.136 0 0 1 2.672.625c.349.284.648.623.966.941 1.67 1.663 3.336 3.328 5.003 4.992.172.172.173.172.347-.002 1.911-1.907 3.822-3.813 5.731-5.723.275-.275.577-.472.988-.451.456.023.789.24.983.641.194.402.158.802-.104 1.169-.079.111-.179.209-.276.306a10168.58 10168.58 0 0 1-5.692 5.683c-.176.175-.176.176-.005.346 1.91 1.907 3.82 3.815 5.733 5.72.266.265.468.553.457.95z"/>
                </svg>
            </i>
            <div className="modal-dialog">
                <div className="modal-content full-width">
                    <div className="modal-header align-left">
                    { debitMemoData.documentName || 'Debit Memo'}
                    </div>
                    {showComp ? 

                    <div className="inner-content">
                        <Row>
                            <Col span={8}>
                                <div className="d-inline-flex w-100">
                                {/* <LogoComponent src={CONSTANTS.BASE_URL_S3 + '/' + config.BUCKET_NAME.BO_RELATIONSHIP + '/' + (props.companyInfo || {}).logoAwsObjectKey + "?" + Date.now()}/> */}
                                    {/* <Avatar shape='square' size={64} icon={<UserOutlined />} src={CONSTANTS.BASE_URL_S3+'/'+ config.BUCKET_NAME.BO_RELATIONSHIP +'/'+(props.companyInfo || {}).logoAwsObjectKey + "?"+Date.now()}/> */}
                                    <Skeleton loading={props.detailLoading} active paragraph={{ rows: 5 }}>

                                    <div className="read-only-content pl10">
                                        <div className="title lh-adjust pb5">
                                            {props.companyInfo.storeName}
                                        </div>
                                        {/* {(relationshipAddress.streetAddress1 && relationshipAddress.streetAddress1+', ' ) || ''}
                                    {(relationshipAddress.streetAddress2 && relationshipAddress.streetAddress2 +', ') || ''}
                                    {(relationshipAddress.cityName && relationshipAddress.cityName+', ') || ''}
                                    {(relationshipAddress.stateName && relationshipAddress.stateName+', ') || ''}
                                    {(relationshipAddress.zipCode && relationshipAddress.zipCode+' ') || ''}
                                    <div>
                                        GSTIN - {companyInfo.gstNumber || ''}
                                    </div>
                                    <div>PAN - {companyInfo.panNumber || ''}</div> */}
                                        <div>{getAddressInfoFragment(relationshipAddress, companyInfo)} </div>
                                        {getTaxIdentificationsFragment( ((debitMemoData.additionalInfoList || [])[0] || {}).relationshipTaxIdentifications ) }
                                    </div>
                                    </Skeleton>
                                </div>
                            </Col>

                            <Col span={8}>
                            <Skeleton loading={props.detailLoading} active paragraph={{ rows: 5 }}>

                                <div className="read-only-content">
                                    <div className="title">
                                    <FormattedMessage id='modal.txDetails.common.billTo' defaultMessage='' />
                                    </div>
                                    <div className="title">
                                        {getContactNameFragment((debitMemoData.additionalInfoList ? debitMemoData.additionalInfoList[0] || {} : {}))}
                                        {debitMemoData.supplierName}
                                    </div>
                                    {/* {(billingAddress.streetAddress1 && billingAddress.streetAddress1+', ' ) || ''}
                                    {(billingAddress.streetAddress2 && billingAddress.streetAddress2 +', ') || ''}
                                    {(billingAddress.cityName && billingAddress.cityName+', ') || ''}
                                    {(billingAddress.stateName && billingAddress.stateName+', ') || ''}
                                    {(billingAddress.zipCode && billingAddress.zipCode+' ') || ''} */}
                                    <div> {getAddressInfoFragment(billingAddress, (debitMemoData.additionalInfoList ? debitMemoData.additionalInfoList[0] ||{} : {}) )}</div>
                                    {getTaxIdentificationsFragment( ((debitMemoData.additionalInfoList || [])[0] || {}).taxIdentifications ) }
                                </div>
                            </Skeleton>
                            </Col>

                            <Col span={6} offset={2} className="read-only-content">
                            <Skeleton loading={props.detailLoading} active paragraph={{ rows: 5 }}>

                                <Row>
                                    <Col span={12} className="text-right">
                                        <span className="title"><FormattedMessage id='modal.txDetails.debitMemo.debitMemoNumber' defaultMessage='' /> </span>
                                    </Col>
                                    <Col span={12}>
                                        {debitMemoData.debitMemoNumber}
                                    </Col>
                                </Row>

                                <Row>
                                    <Col span={12} className="text-right">
                                        <span className="title"><FormattedMessage id='modal.txDetails.debitMemo.debitMemoDate' defaultMessage='' />  </span>
                                    </Col>
                                    <Col span={12}>
                                        {(debitMemoData.debitMemoDate  && getMomentDateForUIReadOnly({date: debitMemoData.debitMemoDate, format: CONSTANTS.DISPLAY_DATE_FORMAT}))}
                                    </Col>
                                </Row>

                                {
                                    !!debitMemoData.isMultiCurrency &&
                                    <Row>
                                        <Col span={12} className="text-right">
                                            <span className="title"><FormattedMessage id='modal.txDetails.common.foreignCurrency' defaultMessage='' /> </span>
                                        </Col>
                                        <Col span={12}>
                                            {debitMemoData.foreignCurrency}
                                        </Col>
                                    </Row>
                                }

                                {
                                    !!debitMemoData.isMultiCurrency &&
                                    <Row>
                                        <Col span={12} className="text-right">
                                            <span className="title"><FormattedMessage id='modal.txDetails.common.exchangeRate' defaultMessage='' /></span>
                                        </Col>
                                        <Col span={12}>
                                            {debitMemoData.exchangeRate}
                                        </Col>
                                    </Row>
                                }
                                {
                                    !!debitMemoData.isDropShipping &&
                                    <Row>
                                        <Col span={12} className="text-right">
                                            <span className="title"><FormattedMessage id='modal.txDetails.common.dropshipOrder' defaultMessage='' /></span>
                                        </Col>
                                        <Col span={12}>
                                            Yes
                                        </Col>
                                    </Row>
                                }

                                {
                                    debitMemoData.reference &&
                                    <Row>
                                        <Col span={12} className="text-right">
                                            <span className="title"><FormattedMessage id='modal.txDetails.common.refNumber' defaultMessage='' /></span>
                                        </Col>
                                        <Col span={12}>
                                            {debitMemoData.reference}
                                        </Col>
                                    </Row>
                                }
                            </Skeleton>
                            </Col>
                        </Row>
                        <Skeleton loading={props.detailLoading} active paragraph={{ rows: 7 }}>

                        <div className="modal-table-container vertical-space">
                            <table className="transaction-details-table">
                                <thead>
                                    <tr>
                                        <th className="cell-size-50">#</th>
                                        {(!isColumnEmpty.product || !isColumnEmpty.description) && <th>
                                            {!isColumnEmpty.product && <span> <FormattedMessage id='modal.txDetails.common.product' defaultMessage='' /></span>}
                                            {(!isColumnEmpty.product && !isColumnEmpty.description) && <span> & </span>}
                                            {!isColumnEmpty.description && <span> <FormattedMessage id='modal.txDetails.common.description' defaultMessage='' /></span>}
                                        </th>}
                                        {/* {!isColumnEmpty.variant && <th>Variant</th>} */}
                                        {!isColumnEmpty.qty && <th className="text-center"><FormattedMessage id='modal.txDetails.common.qty' defaultMessage='' /></th>}
                                        {/* {!isColumnEmpty.uomName && <th>UOM</th>} */}
                                        {!isColumnEmpty.specialDiscount && <th className="text-right"><FormattedMessage id='modal.txDetails.common.basePrice' defaultMessage='' /></th>}
                                        {!isColumnEmpty.specialDiscount && <th className="text-right"><FormattedMessage id='modal.txDetails.common.specialDiscount' defaultMessage='' /></th>}
                                        {!isColumnEmpty.rate && <th className="text-center"><FormattedMessage id='modal.txDetails.common.rate' defaultMessage='' /></th>}
                                        {!isColumnEmpty.hsn && <th className="text-center"><FormattedMessage id='modal.txDetails.common.hsn' defaultMessage='' /></th>}
                                        {!isColumnEmpty.discount && <th className="text-right"><FormattedMessage id='modal.txDetails.common.discount' defaultMessage='' /></th>}
                                        {!isColumnEmpty.tax && <th className="text-right"><FormattedMessage id='modal.txDetails.common.tax' defaultMessage='' /></th>}
                                        <th className="text-right"><FormattedMessage id='modal.txDetails.common.netAmount' defaultMessage='' /></th>
                                    </tr>
                                </thead>
                                <tbody>
                                {(supplierDebitMemoDetailsList || []).sort((a, b)=> Number(a.sNo) > (b.sNo) ? 1 : -1)?.map((item, i) => {
                                    return (
                                        <tr key={i}>
                                            <td className="cell-size-50">{i + 1}</td>
                                            {(!isColumnEmpty.product || !isColumnEmpty.description)&& <td>
                                                <strong>{getVariantFragment(item)}</strong>
                                                {!isColumnEmpty.description && <div className="description-cell">
                                                    <pre>{ReactHtmlParser(item.description || '-')}</pre>
                                                </div>}
                                            </td>}
                                            {/* {!isColumnEmpty.variant && <td>{item.itemVariantName || '-'}</td>} */}
                                            {!isColumnEmpty.qty && <td className="text-center">{item.quantity + ( item.uomName ? ' '+item.uomName : '')}</td>}
                                            {/* {!isColumnEmpty.uomName && <td>{item.uomName || '-'}</td>} */}
                                            {!isColumnEmpty.specialDiscount && <td className="text-right">{fixedDecimalNumber(item.basePrice) || '-'}</td>}
                                            {!isColumnEmpty.specialDiscount && <td className="text-right">{getSpecialDiscount(item, 'amountPercent')}</td>}
                                            {!isColumnEmpty.rate && <td className="text-center">{item.anItemPurchasedPrice || '-'}</td>}
                                            {!isColumnEmpty.hsn && <td className="text-center">{item.hsnCode || '-'}</td>}
                                            {!isColumnEmpty.discount && <td className="text-right">{getTxTypeData(item, 'discount', 'amount')} {getTxTypeData(item, 'discount', 'amountPercent')}</td>}
                                            {!isColumnEmpty.tax && <td className="text-right">{getTotalTaxAmount(item)} {getTotalTaxPercent(item, 'amountPercent')}</td>}
                                            <td className="text-right">{getNetAmount(item) || '-'}</td>
                                        </tr>
                                    );
                                })}
                                </tbody>
                            </table>
                        </div>

                        <Row>
                            <Col span={9}>
                                <Row span={24}>
                                {debitMemoData.docDetailList && debitMemoData.docDetailList.length ? <Row>
                                    <Col span={24} className="title">
                                    <FormattedMessage id='modal.txDetails.common.attachments' defaultMessage='' />
                                    </Col>
                                        <Col span={12}>
                                            <AWSFileTable {...props} docDetailList={debitMemoData.docDetailList} fetchTxDetail={(data) => {
                                                props.fetchDebitMemoDetail({
                                                    supplierId: debitMemoData.supplierId,
                                                    debitMemoMasterId: debitMemoData.debitMemoMasterId,
                                                    relationshipId: debitMemoData.relationshipId
                                                })
                                            }} />
                                        {/* {
                                            debitMemoData.docDetailList.map((doc) => {
                                                return <span className={"cursor-pointer"} onClick={()=>{
                                                    props.downloadFileFromBucket({
                                                        bucketName: doc.bucketName,
                                                        awsKeyName: doc.awsKeyName,
                                                        fileName: doc.fileName
                                                    })
                                                }}>{doc.fileName} &nbsp;</span>
                                            })
                                        } */}
                                    </Col>
                                </Row> : null}
                                <br/>
                               
                                    {debitMemoData.remarksInternal &&
                                        <Row className="read-only-content">
                                            <Col span={7} className="title">
                                            <FormattedMessage id='modal.txDetails.common.internalRemarks' defaultMessage='' />
                                        </Col>
                                            <Col span={17}>
                                                {ReactHtmlParser(debitMemoData.remarksInternal)}
                                            </Col>
                                        </Row>
                                    }
                                </Row>
                                <Row span={24}>
                                    {debitMemoData.remarksSupplier &&
                                        <Row className="read-only-content">
                                            <Col span={7} className="title">
                                            <FormattedMessage id='modal.txDetails.common.supplierRemarks' defaultMessage='' />
                                        </Col>
                                            <Col span={17}>
                                                {ReactHtmlParser(debitMemoData.remarksSupplier)}
                                            </Col>
                                        </Row>
                                    }
                                </Row>
                            </Col>

                            <Col offset={8} span={7}>
                                <div className="bill-summary mt5">
                                    <Row>
                                        <Col span={14}>
                                            <div className="right-txt">
                                            <FormattedMessage id='modal.txDetails.common.subTotal' defaultMessage='' />
                                            </div>
                                        </Col>

                                        <Col span={10} className="text-right pr50">
                                            {debitMemoData.isMultiCurrency ?
                                                <span> {debitMemoData.foreignCurrencyIcon ? <i className={debitMemoData.foreignCurrencyIcon}></i> : debitMemoData.foreignCurrency} </span>
                                                : <span> {props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "} </span>
                                            } {getSubTotalOrTaxOrDiscountAmount(debitMemoData, "SubTotal")}
                                        </Col>
                                    </Row>

                                    <div className="pt10">
                                        <Row>
                                            <Col span={14}>
                                                <div className="right-txt">
                                                <FormattedMessage id='modal.txDetails.common.discount' defaultMessage='' />
                                                </div>
                                            </Col>

                                            <Col span={10} className="text-right pr50">
                                                {debitMemoData.isMultiCurrency ?
                                                    <span> {debitMemoData.foreignCurrencyIcon ? <i className={debitMemoData.foreignCurrencyIcon}></i> : debitMemoData.foreignCurrency} </span>
                                                    : <span> {props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "} </span>
                                                }   {getSubTotalOrTaxOrDiscountAmount(debitMemoData, "Discount")}
                                            </Col>
                                        </Row>
                                    </div>
                                    { getTxTypeData(debitMemoData, 'expense', 'amount') ?
                                        <div className="pt10">
                                            <Row>
                                                <Col span={14}>
                                                    <div className="right-txt">
                                                    <FormattedMessage id='modal.txDetails.common.expense' defaultMessage='' /> 
                                                </div>
                                                </Col>

                                                <Col span={10} className="text-right pr50">
                                                    {debitMemoData.isMultiCurrency ?
                                                        <span> {debitMemoData.foreignCurrencyIcon ? <i className={debitMemoData.foreignCurrencyIcon}></i> : debitMemoData.foreignCurrency} </span>
                                                        : <span> {props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "} </span>
                                                    }
                                                    {getTxTypeData(debitMemoData, 'expense', 'amount')}
                                                </Col>
                                            </Row>
                                        </div> : ''
                                    }
                                    {
                                        (itemLevelTaxes && itemLevelTaxes.length !== 0) && itemLevelTaxes.map((taxObj, i) => {

                                            return (
                                                props.companyInfo.countryName === 'India' ? (isPlaceOfSupplySameAsRelationship ? <div key={i}>
                                                    <div className="pt10">
                                                        <Row>
                                                            <Col span={14}>
                                                                <div className="right-txt">CGST @ {taxObj.taxPercent / 2}% on {fixedDecimalNumber(taxObj.subtotal)} </div>
                                                            </Col>
                                                            <Col span={10} className="text-right pr50">
                                                                {debitMemoData.isMultiCurrency ?
                                                                    <span> {debitMemoData.foreignCurrencyIcon ? <i className={debitMemoData.foreignCurrencyIcon}></i> : debitMemoData.foreignCurrency} </span>
                                                                    : <span> {props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "} </span>
                                                                }
                                                                {fixedDecimalNumber(taxObj.taxAmount / 2)}
                                                            </Col>
                                                        </Row>
                                                    </div>

                                                    <div className="pt10">
                                                        <Row>
                                                            <Col span={14}>
                                                                <div className="right-txt">SGST @ {taxObj.taxPercent / 2}% on {fixedDecimalNumber(taxObj.subtotal)} </div>
                                                            </Col>
                                                            <Col span={10} className="text-right pr50">
                                                                {debitMemoData.isMultiCurrency ?
                                                                    <span> {debitMemoData.foreignCurrencyIcon ? <i className={debitMemoData.foreignCurrencyIcon}></i> : debitMemoData.foreignCurrency} </span>
                                                                    : <span> {props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "} </span>
                                                                }
                                                                {fixedDecimalNumber(taxObj.taxAmount / 2)}
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </div> : <div key={i}>
                                                        <div className="pt10">
                                                            <Row>
                                                                <Col span={14}>
                                                                    <div className="right-txt">{taxObj.taxName} @ {taxObj.taxPercent}% on {fixedDecimalNumber(taxObj.subtotal)} </div>
                                                                </Col>
                                                                <Col span={10} className="text-right pr50">
                                                                    {debitMemoData.isMultiCurrency ?
                                                                        <span> {debitMemoData.foreignCurrencyIcon ? <i className={debitMemoData.foreignCurrencyIcon}></i> : debitMemoData.foreignCurrency} </span>
                                                                        : <span> {props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "} </span>
                                                                    }
                                                                    {fixedDecimalNumber(taxObj.taxAmount)}
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    </div>
                                                ) :
                                                    <div key={i}>
                                                        <div className="pt10">
                                                            <Row>
                                                                <Col span={14}>
                                                                    <div className="right-txt">{taxObj.taxName} @ {taxObj.taxPercent}% on {fixedDecimalNumber(taxObj.subtotal)} </div>
                                                                </Col>
                                                                <Col span={10} className="text-right pr50">
                                                                    {debitMemoData.isMultiCurrency ?
                                                                        <span> {debitMemoData.foreignCurrencyIcon ? <i className={debitMemoData.foreignCurrencyIcon}></i> : debitMemoData.foreignCurrency} </span>
                                                                        : <span> {props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "} </span>
                                                                    }
                                                                    {fixedDecimalNumber(taxObj.taxAmount)}
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    </div>
                                            )
                                        })
                                    }


                                    <div className="pt10">
                                        <Row>
                                            <Col span={14}>
                                                <div className="right-txt bold">
                                                <FormattedMessage id='modal.txDetails.common.grandTotal' defaultMessage='' />  
                                                </div>
                                            </Col>

                                            <Col span={10} className="text-right pr50">
                                                {debitMemoData.isMultiCurrency ?
                                                    <span> {debitMemoData.foreignCurrencyIcon ? <i className={debitMemoData.foreignCurrencyIcon}></i> : debitMemoData.foreignCurrency} </span>
                                                    : <span> {props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "} </span>
                                                }  {(debitMemoData.debitMemoTotalAmount && fixedDecimalNumber(debitMemoData.debitMemoTotalAmount)) || ''}
                                            </Col>
                                            <Col span={24} className="text-right pr50">
                                                {debitMemoData.isMultiCurrency ?
                                                    <span> {debitMemoData.foreignCurrencyIcon ? <i className={debitMemoData.foreignCurrencyIcon}></i> : debitMemoData.foreignCurrency} </span>
                                                    : <span> {props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "} </span>
                                                }  {(debitMemoData.debitMemoTotalAmount && numberToWordsWithDecimals(fixedDecimalNumber(debitMemoData.debitMemoTotalAmount))) || ''}
                                            </Col>
                                        </Row>
                                    </div>
                                </div>

                                <div className="upload-link new-pic" title={props.intl.formatMessage({ id: 'uploadSignature.tooltip' })} style={{width:"50px", height:"50px"}}>
                                    <CustomUploader {...customProps} />
                                    {debitMemoData.stampAwsKey && <div className="cursor-pointer" onClick={() => {
                                        props.deleteStamp({
                                            supplierId: debitMemoData.supplierId,
                                            debitMemoMasterId: debitMemoData.debitMemoMasterId,
                                            relationshipId: debitMemoData.relationshipId,
                                            objectKey: debitMemoData.stampAwsKey
                                        }, props)
                                    }}> <FormattedMessage id='modal.txDetails.common.deleteStamp' defaultMessage='' />  </div>}
                                </div>
                            </Col>
                        </Row>
                        <div className="contact-details">
                            {debitMemoData.footer &&
                            <Fragment>
                                {ReactHtmlParser(debitMemoData.footer)}
                            </Fragment>
                            }
                        </div>
                        </Skeleton>
                    </div>
:""}
                    <div className=" button-group-action header-control">
                    <div className="small-btn-group">
                               {permissionsData.update && <Button style={{height:'38px'}} title={props.intl.formatMessage({ id: 'modal.txDetails.common.edit', defaultMessage: '' })}
                                    icon={<EditOutlined />}
                                    onClick={() => {
                                        props.popModalFromStack();
                                        props.pushModalToStack({
                                            modalBody: <StandardDebitMemo {...props} 
                                            supplierId={debitMemoData.supplierId} 
                                            debitMemoMasterId={debitMemoData.debitMemoMasterId} 
                                            update= {true}
                                            debitMemoEditData ={debitMemoData}
                                            />,
                                            width: '100%',
                                            hideTitle: true,
                                            hideFooter: true,
                                            keyboard: false ,
                                            wrapClassName: 'new-transaction-wrapper'
                                        })
                                        // props.history.push({
                                        //     pathname: '/admin/debit-memo-update',
                                        //     state: {
                                        //         supplierId: debitMemoData.supplierId,
                                        //         debitMemoMasterId: debitMemoData.debitMemoMasterId,
                                        //         update: true
                                        //     }
                                        // })
                                    }}
                                >
                                </Button>}
                                {permissionsData.create && <Button style={{height:'38px'}}
                                    title={props.intl.formatMessage({ id: 'modal.txDetails.common.clone', defaultMessage: '' })}
                                    icon={<CopyOutlined />}
                                    onClick={() => {
                                        props.popModalFromStack();
                                        props.pushModalToStack({
                                            modalBody: <StandardDebitMemo {...props} supplierId={debitMemoData.supplierId} debitMemoMasterId={debitMemoData.debitMemoMasterId} clone= {true}/>,
                                            width: '100%',
                                            hideTitle: true,
                                            hideFooter: true,
                                            keyboard: false ,
                                            wrapClassName: 'new-transaction-wrapper'
                                        })
                                        // props.history.push({
                                        //     pathname: '/admin/debit-memo-create',
                                        //     state: {
                                        //         supplierId: debitMemoData.supplierId,
                                        //         debitMemoMasterId: debitMemoData.debitMemoMasterId,
                                        //         clone: true
                                        //     }
                                        // })
                                    }}
                                >
                                </Button>}
                                {permissionsData.delete && <Button style={{height:'38px'}}
                                    title={props.intl.formatMessage({ id: 'modal.txDetails.common.delete', defaultMessage: '' })}
                                    icon={<DeleteOutlined />}
                                    onClick={() => {
                                        props.showModal({
                                            modalBody: <FormattedMessage id='modal.txDetails.common.delete.confirmation' defaultMessage='' /> ,
                                            handleSubmit: () => { 
                                                props.popModalFromStack();
                                                props.deleteDebitMemo({
                                                    supplierId: debitMemoData.supplierId,
                                                    debitMemoMasterId: debitMemoData.debitMemoMasterId,
                                                    relationshipId: debitMemoData.relationshipId
                                                }, props)
                                            }
                                        })
                                    }}
                                >
                                </Button>}
                            </div>
                            <div className="small-btn-group">

                                <Button style={{height:'38px'}}
                                    title={props.intl.formatMessage({ id: 'modal.txDetails.common.email', defaultMessage: '' })}
                                    icon={<MailOutlined />}
                                    onClick={() => {
                                        openEmailDrawer(props);

                                    }}
                                >
                                </Button>

                                <Button style={{height:'38px'}}
                                    title={props.intl.formatMessage({ id: 'modal.txDetails.common.downloadPDF', defaultMessage: '' })}
                                    icon={<FilePdfOutlined />}
                                    onClick={() => {
                                        generatePDF();
                                    }}
                                >
                                </Button>
                                <Button style={{height:'38px'}}
                                    title={props.intl.formatMessage({ id: 'modal.txDetails.common.print', defaultMessage: '' })}
                                    icon={<PrinterOutlined />}
                                    onClick={() => {
                                        printPdf();
                                    }}
                                >
                                </Button>
                            </div>
                            {/* <div className="small-btn-group">
                                <Button title='Audit Trail'
                                    icon={<HistoryOutlined />}
                                    onClick={() => {
                                      //  openEmailDrawer(props);
                                    }}
                                >
                                </Button>
                            </div> */}
                    </div>
                </div>
            </div>
            </div>
    );

}

const getSubTotalOrTaxOrDiscountAmount = (data, type) => {
    let total = 0;
    data && data.supplierDebitMemoDetailsList && data.supplierDebitMemoDetailsList.forEach(function (d) {
        if (type === 'SubTotal') {
            total = total + d.anItemPurchasedPrice * d.quantity;
        }
        if (type === 'Discount') {
            let discountObject = d.supplierCOATxDebitMemoList.find(tx => (tx.txType === 'discount'));
            if (discountObject) {
                total = total + parseFloat(discountObject.amount);
            }
        }
    });
    return fixedDecimalNumber(total);
}

const getTxTypeData = (row, type, toReturn) => {
  if(!row || !row.supplierCOATxDebitMemoList) return;
  let data = row.supplierCOATxDebitMemoList.find(tx => (tx.txType === type));
  if(data){
    if(data[toReturn] && toReturn === 'amountPercent'){
      if(type === 'VAT'){
        return "VAT("+data[toReturn]+"%)";
      }
      return "("+data[toReturn]+"%)";
    }else if(data[toReturn] && toReturn === 'amount'){
      return (fixedDecimalNumber(data[toReturn]));
    }else{
      return ""
    }

  }else if(!data && toReturn === 'amount'){
    return "";
  }
  else{
    return "-";
  }
}
const getTotalTaxAmount = (row) => {
  let tax1 = getTxTypeData(row, 'tax', 'amount', true) || 0;
 // let tax2 = getTxTypeData(row, 'sgst', 'amount', true) || 0;
 // let tax3 = getTxTypeData(row, 'igst', 'amount', true) || 0;
 // let tax4 = getTxTypeData(row, 'vat', 'amount', true) || 0;
  let totalTax = Number(tax1);
  return totalTax;

}

const getTotalTaxPercent = (row, toReturn) => {
    let tax1 = row.supplierCOATxDebitMemoList.find(tx => (tx.txType === 'tax')) || {};
   // let tax2 = row.supplierCOATxDebitMemoList.find(tx => (tx.txType === 'sgst')) || {};
   // let tax3 = row.supplierCOATxDebitMemoList.find(tx => (tx.txType === 'igst')) || {};
   // let tax4 = row.supplierCOATxDebitMemoList.find(tx => (tx.txType === 'vat')) || {};
    let totalPercent = Number(tax1.amountPercent || 0);
    if (toReturn === 'amountPercent') {
        return totalPercent ? ('(' + totalPercent + "%)") : '';
    }
    else {
        return totalPercent;
    }
}

const getNetAmount = (row) => {
  let tax = getTotalTaxAmount(row) || 0;
  let discount = getTxTypeData(row, 'discount', 'amount', true) || 0;
  let netAmountItem = (Number(row.quantity * row.anItemPurchasedPrice)+Number(tax) - Number(discount));
  return fixedDecimalNumber(netAmountItem);

}

const calculateTaxRateList = (supplierDebitMemoDetailsList, isPlaceOfSupplySameAsRelationship) =>{
  let subtotal = 0;
  let totalDiscount = 0;
  let totalTax = 0;
  let itemLevelTaxes = []

  supplierDebitMemoDetailsList.forEach(function (rowdata, index) {
      let totalAmountOfColumn = 0;
      if (rowdata.quantity && rowdata.anItemPurchasedPrice) {
          let taxObj = {
              subtotal: 0,
              taxPercent: 0,
              taxAmount: 0
          }
          totalAmountOfColumn = rowdata.quantity * rowdata.anItemPurchasedPrice;
          let discount = getTxTypeData(rowdata, 'discount', 'amount');
          if (discount) {
              totalAmountOfColumn = totalAmountOfColumn - discount;
              totalDiscount = totalDiscount + discount;
          }

          let tax1 = rowdata.supplierCOATxDebitMemoList.find(tx => (tx.txType === 'tax')) || {};
          //let tax2 = rowdata.supplierCOATxDebitMemoList.find(tx => (tx.txType === 'sgst'));
         // let tax3 = rowdata.supplierCOATxDebitMemoList.find(tx => (tx.txType === 'igst'));
         // let tax4 = rowdata.supplierCOATxDebitMemoList.find(tx => (tx.txType === 'vat'));
          taxObj.taxPercent = Number(tax1.amountPercent);
          taxObj.taxAmount = Number(fixedDecimalNumber(tax1.amount));
          totalTax = totalTax + taxObj.taxAmount;
          taxObj.taxName = tax1.taxName;

        //   if (isPlaceOfSupplySameAsRelationship && tax1 && tax2) {
        //       taxObj.taxPercent = Number(tax1.amountPercent) + Number(tax2.amountPercent);
        //       taxObj.taxAmount = Number(tax1.amount) + Number(tax2.amount);
        //       totalTax = totalTax + taxObj.taxAmount;
        //   }
        //   else if (!isPlaceOfSupplySameAsRelationship && tax3) {
        //       taxObj.taxPercent = Number(tax3.amountPercent);
        //       taxObj.taxAmount = Number(tax3.amount);
        //       totalTax = totalTax + taxObj.taxAmount;
        //   }
        //   else if (tax4) {
        //       taxObj.taxPercent = Number(tax4.amountPercent);
        //       taxObj.taxAmount = Number(tax4.amount);
        //       totalTax = totalTax + taxObj.taxAmount;
        //   }
          if (taxObj.taxPercent) {
              taxObj.subtotal = Number(fixedDecimalNumber(totalAmountOfColumn));
              const availableTaxObj = find(itemLevelTaxes, { taxPercent: taxObj.taxPercent });
              if (availableTaxObj) {
                  itemLevelTaxes = filter(itemLevelTaxes, (ele) => {
                      if (ele.taxPercent === taxObj.taxPercent) {
                          ele.subtotal = ele.subtotal + taxObj.subtotal;
                          ele.taxAmount = ele.taxAmount + taxObj.taxAmount;
                      }
                      return true;
                  });
              } else {
                taxObj.subtotal = Number(fixedDecimalNumber(taxObj.subtotal || 0));
                  itemLevelTaxes.push(taxObj);
              }
          }
          subtotal = subtotal + totalAmountOfColumn;
      }
  });
  return itemLevelTaxes;
}

const updateColumnEmptyCheck = (isColumnEmpty, debitMemoData, props) => {
  if (!debitMemoData) return isColumnEmpty;
  for (let i in debitMemoData.supplierDebitMemoDetailsList) {
    let row = debitMemoData.supplierDebitMemoDetailsList[i];
    if (row.itemName) { isColumnEmpty.product = false; };
    if (row.serviceName) { isColumnEmpty.service = false; };
    if (row.itemVariantName) { isColumnEmpty.variant = false; }
    if (row.description) { isColumnEmpty.description = false; }
    if (row.quantity) { isColumnEmpty.qty = false; }
    if (row.uomName) { isColumnEmpty.uomName = false; }
    if (row.anItemPurchasedPrice) { isColumnEmpty.rate = false; }
    if (row.specialDiscount){isColumnEmpty.specialDiscount = false;}
    if (row.hsnCode && props.companyInfo.countryName === 'India') { isColumnEmpty.hsn = false; }
    if (!isTxTypeEmpty(row, 'discount')) { isColumnEmpty.discount = false; }
    if (!isTxTypeEmpty(row, 'tax')) { isColumnEmpty.tax = false; }
    if(row.documentDetails && row.documentDetails.length) {isColumnEmpty.documentDetails = false;}
  }
  return isColumnEmpty;
}

const isTxTypeEmpty = (row, type) => {
  if (!row) return true;
  let data = row.supplierCOATxDebitMemoList.find(tx => (tx.txType === type));
  if (data && data.amount) {
    return false;
  }
  else {
    return true;
  }
}

const getSpecialDiscount = (row, toReturn) => {
    if (!row) return;
    let specialDiscount = row.basePrice - row.anItemPurchasePrice;
    let percent, amount = 0;
    if (specialDiscount && row.basePrice) {
      if (toReturn === 'amountPercent') {
         percent = fixedDecimalNumber((specialDiscount * 100)/row.basePrice);
         amount = fixedDecimalNumber(specialDiscount);
        return amount +" ("+ percent+")%";
      } else if (toReturn === 'amount') {
        return fixedDecimalNumber(specialDiscount);
      }

    } else if (!row && toReturn === 'amount') {
      return 0;
    }
    else {
      return "-";
    }
  };

const getAddressInfoFragment = (obj, additionInfo) => {
    const fragmentObj = <Fragment>
        <div className="billing-address first-caps">
            {obj.streetAddress1 || obj.streetAddress2 ? (

                <div>
                    {obj.streetAddress1} {obj.streetAddress2}{", "}
                </div>

            ) : ""}
            {obj.cityName || obj.stateName || obj.zipCode ? (

                <div>
                    {obj.cityName} {obj.stateName}{obj.stateName && obj.zipCode ? ", " : ""}{obj.zipCode}
                </div>

            ) : ""}
             {obj.countryName ? (
     <div>{obj.countryName}</div>
    ):""}
        </div>
        {
            additionInfo.workPhone
                ? <div className='billing-address'>
                    <FormattedMessage id='phone' defaultMessage='' /> - {additionInfo.workPhone}
                </div> :
                ''
        }
        {
            additionInfo.email
                ? <div className='billing-address'>
                    <FormattedMessage id='email' defaultMessage='' /> - {additionInfo.email}
                </div> :
                ''
        }
        {/* {
            additionInfo.gstNumber
                ? <div className='billing-address'>
                    <FormattedMessage id='gstIn' defaultMessage='' /> - {additionInfo.gstNumber}
                </div> :
                ''
        }
        {
            additionInfo.panNumber
                ? <div className='billing-address'>
                    <FormattedMessage id='pan' defaultMessage='' /> - {additionInfo.panNumber}
                </div> :
                ''
        } */}
    </Fragment>
    return fragmentObj;
}

const getTaxIdentificationsFragment = (taxIdentifications) => {
    if (!taxIdentifications) return <Fragment />;
    let taxIdentificationsList = []
    if (taxIdentifications) {
        taxIdentificationsList = JSON.parse(taxIdentifications);
    }
    const fragmentObj = <Fragment>
        {taxIdentificationsList.map((obj, i) => {
            return <div className='billing-address'>
                {obj.taxIdentificationType || ''} - {obj.taxIdentificationNumber}
            </div>
        })}
    </Fragment>

    return fragmentObj;
}

const getContactNameFragment = (additionInfo) => {
    if (!additionInfo) return <Fragment />;
    let contactName = "";
    if (additionInfo.salutationName) contactName += additionInfo.salutationName + ' ';
    if (additionInfo.firstName) contactName += additionInfo.firstName;
    if (additionInfo.middleName) contactName += ' ' + additionInfo.middleName;
    if (additionInfo.lastName) contactName += ' ' + additionInfo.lastName;
    const fragmentObj = <Fragment>
        {
            (contactName && contactName.length)
                ? <div className='billing-address'>
                    {contactName}
                </div> :
                ''
        }
    </Fragment>
    return fragmentObj;
}

const getVariantFragment = (item) => {
    let itemName = null;
    if (item.itemName) {
        itemName = item.itemName;
    }
    if (item.itemVariantName) {
        itemName = itemName + ' (' + item.itemVariantName + ')'
    }
    return itemName ? itemName : '';
}

export default DebitMemoDetails;
