import axios from "axios";
import config from "../../../../../config/environmentConfig";
import { showToasterMessage } from "../../../../../utils";
import { COMMON_ACTIONS } from "../../../../../static/constants";

export const setOnlineOrder = (payload) => {
  const value = {
    isOnlineOrders: payload.isCod,
  };

  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .put(
        `${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/ecom_setting/openOnlineOrder?relationshipId=${payload.relationshipId}`,
        value
      )
      .then((res) => {
        if (payload.isCod) {
          showToasterMessage({
            messageType: "success",
            description: "Active Succssfully.",
          });
        } else {
          showToasterMessage({
            messageType: "success",
            description: "Deactive Succssfully.",
          });
        }
        dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      });
  };
};

export const setCODorder = (payload) => {
  const value = {
    isCod: payload.isCod,
  };
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .put(
        `${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/ecom_setting/openCOD?relationshipId=${payload.relationshipId}`,
        value
      )
      .then((res) => {
        if (payload.isCod) {
          showToasterMessage({
            messageType: "success",
            description: "Active Succssfully.",
          });
        } else {
          showToasterMessage({
            messageType: "success",
            description: "Deactive Succssfully.",
          });
        }
        dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      });
  };
};

export const setCODprefrence = (payload) => {

  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .put(
        `${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/ecom_setting/saveCodPreference?relationshipId=${payload.relationshipId}`,
        payload
      )
      .then((res) => {
        if (payload.isCod) {
          showToasterMessage({
            messageType: "success",
            description: "Active Succssfully.",
          });
        } else {
          showToasterMessage({
            messageType: "success",
            description: "Deactive Succssfully.",
          });
        }
        dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      });
  };
};

export const setMakeAnEnquiry = (payload) => {
  const value = {
    isEnquiry: payload.isCod,
  };

  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .put(
        `${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/ecom_setting/startMakeAnEnquiry?relationshipId=${payload.relationshipId}`,
        value
      )
      .then((res) => {
        if (payload.isCod) {
          showToasterMessage({
            messageType: "success",
            description: "Active Succssfully.",
          });
        } else {
          showToasterMessage({
            messageType: "success",
            description: "Deactive Succssfully.",
          });
        }

        dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      });
  };
};


export const setTaxPreference = (payload) => {
   console.log("relatioshipId",payload)
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(
        `${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/ecom_setting/saveTaxPreference`,
        payload
      )
      .then((res) => {
        
          showToasterMessage({
            messageType: "success",
            description: "Save Succssfully.",
        
      })
        dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      });
  };
};



