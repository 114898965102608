import React, { Fragment } from "react";
import { Row, Col, Button } from "antd";
import { FormattedMessage } from "react-intl";
import {
  capitalizeFirstLetter,
  fixedDecimalAndFormateNumber,
  getMomentDateForUIReadOnly,
} from "../../../../utils";
import { CONSTANTS } from "../../../../static/constants";
import OpenOrders from "../../../../containers/modal/modalBody/supplier/OpenOrders";
import PaidInvoices from "../../../../containers/modal/modalBody/supplier/PaidInvoices";
import UnpaidInvoices from "../../../../containers/modal/modalBody/supplier/UnpaidInvoices";
import { FilePdfOutlined, PrinterOutlined, } from '@ant-design/icons';
import find from "lodash.find";


const Information = (props) => {
  const { paidInvoiceList, unpaidInvoiceList, openOrderList, primaryContact } = props
  const format = CONSTANTS.DISPLAY_DATE_FORMAT;

  const billingAddress =
    find(props.boLocationList, {
      locationType: CONSTANTS.BILLING_ADDRESS,
    }) || {};
  const relationshipAddress =
    find(props.companyInfo.boLocationList, {
      locationType: CONSTANTS.RELATIONSHIP_BILLING_ADDRESS,
    }) || {};

  // const primaryContact =
  //   find(props.boContactList, {
  //     contactType: CONSTANTS.CONTACT_TYPE_CUSTOMER
  //   }) || {};

  const getAddressInfoFragment = (obj) => {
    return (
      <>
        {obj?.streetAddress1 ? (
          <span className="billing-address">{obj?.streetAddress1}</span>
        ) : (
          ""
        )}
        {obj?.streetAddress2 ? (
          <span className="billing-address"> {obj?.streetAddress2}</span>
        ) : (
          ""
        )}
        {obj?.cityName ? (
          <div className="billing-address">
            {`${obj?.cityName || ""}${obj?.stateName ? ", " + obj?.stateName : ""
              }${obj?.zipCode ? ", " + obj?.zipCode : ""}`}
          </div>
        ) : (
          ""
        )}
        {obj?.countryName ? (
          <div
            className="billing-address"
            style={{ textTransform: "uppercase" }}
          >
            {`${capitalizeFirstLetter(obj?.countryName) || ""}`}
          </div>
        ) : (
          ""
        )}
      </>
    );
  };

  const getContactInfoFragment = (obj) => {
    return (
      <>
        {/* <div >{obj?.firstName || "" + " " + obj?.lastName || ""}</div>
         <div >{obj?.emailAddress || ""}</div>
         <div >{obj?.cellPhone || ""}</div> */}
        {obj?.firstName ? (
          <div >{obj.firstName + " " + obj.lastName}</div>
        ) : (
          ""
        )}
        {obj?.emailAddress ? (
          <div > {obj.emailAddress}</div>
        ) : (
          ""
        )}
        {obj?.cellPhone ? (
          <div > {obj.cellPhone}</div>
        ) : (
          ""
        )}

      </>
    );
  };

  const getPayloadForOpenOrderPdf = () => {
    return {
      fileName: (props.supplierStoreName) + " " + 'Open-Order.pdf',
      transactionName: 'supplierSalesOrder',
      companyInfo: props.companyInfo,
      billingAddress: billingAddress,
      openOrderList: JSON.stringify(openOrderList),
      primaryContact: props.primaryContact || {},
      relationshipAddress: relationshipAddress,
      openedOrdersTotalAmount: props.openedOrdersTotalAmount,
      supplierStoreName: props.supplierStoreName

    }
  }

  const generateOpenOrderPDF = () => {
    let payload = getPayloadForOpenOrderPdf();
    props.generatePDF(payload);
  }
  const printPdf = (val) => {
   let payload;
    switch (val) {
      case "openOrder":
        payload = getPayloadForOpenOrderPdf();
        break;
      case "paid":
        payload = getPayloadForPaidPdf();
        break;
      case "unPaid":
        payload = getPayloadForUnPaidPdf();
        break;
    }
    props.printPDF(payload)
  }
  const getPayloadForPaidPdf = () => {
    return {
      // fileName: 'supplierPaidInvoice.pdf',
      fileName: (props.supplierStoreName) + " " + 'Paid-Invoice.pdf',
      transactionName: 'supplierPaidInvoice',
      companyInfo: props.companyInfo,
      billingAddress: billingAddress,
      primaryContact: props.primaryContact || {},
      paidInvoiceList: JSON.stringify(paidInvoiceList),
      relationshipAddress: relationshipAddress,
      paidInvoicesTotalAmount: props.paidInvoicesTotalAmount,
      supplierStoreName: props.supplierStoreName

    }
  }

  const generatePaidPDF = () => {
    let payload = getPayloadForPaidPdf();
    props.generatePDF(payload);
  }

  const getPayloadForUnPaidPdf = () => {
    return {
      fileName: (props.supplierStoreName) + " " + 'Unpaid-Invoice.pdf',
      transactionName: 'supplierUnpaidInvoice',
      companyInfo: props.companyInfo,
      billingAddress: billingAddress,
      primaryContact: props.primaryContact || {},
      unpaidInvoiceList: JSON.stringify(unpaidInvoiceList),
      relationshipAddress: relationshipAddress,
      unpaidInvoicesTotalAmount: props.unpaidInvoicesTotalAmount,
      supplierStoreName: props.supplierStoreName

    }
  }

  const generateUnpaidPDF = () => {
    let payload = getPayloadForUnPaidPdf();
    props.generatePDF(payload);
  }
  return (
    <div className="details">
      <Row gutter={[16, 24]}>
        <Col span={12}>
          <div className="b-text">Total Orders</div>
          <div className="light-txt">
            {props.supplierTotalOrderCount}
          </div>
        </Col>
        <Col span={12}>
          <div className="b-text">Open Purchase Order</div>
          <div className="one-line-view">

            {props.openedOrdersTotalAmount ? (
              props.companyInfo.currencyIcon ? (
                <i
                  className={
                    props.companyInfo.currencyIcon
                  }
                ></i>
              ) : (
                props.companyInfo.currencyCode + " "
              )
            ) : (
              ""
            )}
            {(props.openedOrdersTotalAmount) ?
              fixedDecimalAndFormateNumber(
                props.openedOrdersTotalAmount
              ) : ""}

            {/* {props.openedOrdersCount &&
                                  props.openedOrdersCount === 1
                                  ? "Order "
                                  : props.openedOrdersCount === 0
                                    ? ""
                                    : "Orders "} */}
            <span
              onClick={() => {

                if (props.openedOrdersCount) {

                  props.pushModalToStack({
                    title: (
                      <Fragment>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <div className="ant-modal-title">
                            <FormattedMessage
                              id="open.Orders"
                              defaultMessage=""
                            />{" "}
                          </div>
                          <div className="ant-modal-subtitle extra-sm">
                            {props.supplierStoreName}
                          </div>
                          <div className="modal-info">

                            <div className="w-200">{getAddressInfoFragment(props.primaryAddress)}</div>
                            <div className="w-200">{getContactInfoFragment(props.primaryContact)}</div>
                            <div className="modal-btn">
                              <Button
                                title={props.intl.formatMessage({ id: 'modal.txDetails.common.downloadPDF', defaultMessage: '' })}
                                icon={<FilePdfOutlined />}
                                onClick={() => {
                                  generateOpenOrderPDF();
                                }}
                              >
                              </Button>
                              <Button
                                title={props.intl.formatMessage({ id: 'modal.txDetails.common.print', defaultMessage: '' })}
                                icon={<PrinterOutlined />}
                                onClick={() => {
                                  printPdf('openOrder');
                                }}
                              >
                              </Button>
                            </div>
                          </div>
                        </div>
                      </Fragment>
                    ),
                    modalBody: (
                      <OpenOrders
                        {...props}
                        supplierData={props}
                      />
                    ),
                    width: 1000,
                    hideFooter: true,
                    wrapClassName: "tiles-modal",
                  });
                }
              }}
              className={
                props.openedOrdersCount
                  ? "link-text"
                  : ""
              }
            >
              {" "}
              {`(${props.openedOrdersCount || 0})`}
            </span>
            {/* <p>{props.openedOrdersTotalAmount ? (props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " ") : ''}{(props.openedOrdersTotalAmount || '') && props.openedOrdersTotalAmount.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p> */}

          </div>

          {/* <div className="light-txt">
            {props.supplierStoreName}(<span >{props.openedOrdersCount}</span>)
          </div> */}
        </Col>

        <Col span={12}>
          <div className="b-text">Paid Balance</div>
          <div>

            {" "}
            {props.paidInvoicesTotalAmount ? (
              props.companyInfo.currencyIcon ? (
                <i
                  className={
                    props.companyInfo.currencyIcon
                  }
                ></i>
              ) : (
                props.companyInfo.currencyCode + " "
              )
            ) : (
              ""
            )}

            {props.paidInvoicesTotalAmount ?  (
              fixedDecimalAndFormateNumber(props.paidInvoicesTotalAmount)
            ):""}

            <span
              onClick={() => {
                if (props.paidInvoicesCount) {

                  props.pushModalToStack({
                    title: (
                      <div>
                        <div className="ant-modal-title">
                          Paid Invoices
                        </div>
                        <div className="ant-modal-subtitle extra-sm">
                          {props.companyName}
                        </div>
                        <div className="modal-info">

                          <div className="w-200">{getAddressInfoFragment(props.primaryAddress)}</div>
                          <div className="w-200">{getContactInfoFragment(props.primaryContact)}</div>
                          <div className="modal-btn">
                            <Button
                              title={props.intl.formatMessage({ id: 'modal.txDetails.common.downloadPDF', defaultMessage: '' })}
                              icon={<FilePdfOutlined />}
                              onClick={() => {
                                generatePaidPDF();
                              }}
                            >
                            </Button>
                            <Button
                              title={props.intl.formatMessage({ id: 'modal.txDetails.common.print', defaultMessage: '' })}
                              icon={<PrinterOutlined />}
                              onClick={() => {
                                printPdf("paid");
                              }}
                            >
                            </Button>
                          </div>
                        </div>
                      </div>
                    ),
                    modalBody: (
                      <PaidInvoices
                        supplierData={props}
                        {...props}
                      />
                    ),
                    width: 1000,
                    hideFooter: true,
                    wrapClassName: "tiles-modal paid-invoice",
                  });
                }
              }}
              className={
                props.paidInvoicesCount
                  ? "link-text"
                  : ""
              }
            >
              {" "}
              {`(${props.paidInvoicesCount || 0})`}

            </span>
            {/* <p> {props.paidInvoicesTotalAmount ? (props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " ") : ''}{(props.paidInvoicesTotalAmount || '') && props.paidInvoicesTotalAmount.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p> */}

          </div>
        </Col>
        <Col span={12}>
          <div className="b-text">Unpaid Balance</div>
          <div>

            {" "}
            {props.totalAmountDue ? (
              props.companyInfo.currencyIcon ? (
                <i
                  className={
                    props.companyInfo.currencyIcon
                  }
                ></i>
              ) : (
                props.companyInfo.currencyCode + " "

              )
            ) : (
              ""
            )}

            {(props.unpaidInvoicesTotalAmount) ?
              fixedDecimalAndFormateNumber(
                props.unpaidInvoicesTotalAmount
              ):""}


            {/* {props.unpaidInvoicesCount &&
                                props.unpaidInvoicesCount === 1
                                ? "Invoice "
                                : props.unpaidInvoicesCount === 0
                                  ? ""
                                  : "Invoices "} */}
            <span
              onClick={() => {
                if (props.unpaidInvoicesCount) {
                  props.pushModalToStack({
                    title: (
                      <Fragment>
                        <div>
                          <div className="ant-modal-title">

                            <FormattedMessage
                              id="customer.listing.header.unpaidInvoices"
                              defaultMessage=""
                            />
                          </div>
                          <div className="ant-modal-subtitle extra-sm">
                            {props.companyName}
                          </div>
                          <div className="modal-info">

                            <div className="w-200">{getAddressInfoFragment(props.primaryAddress)}</div>
                            <div className="w-200">{getContactInfoFragment(props.primaryContact)}</div>
                            <div className="modal-btn">
                              <Button
                                title={props.intl.formatMessage({ id: 'modal.txDetails.common.downloadPDF', defaultMessage: '' })}
                                icon={<FilePdfOutlined />}
                                onClick={() => {
                                  generateUnpaidPDF();
                                }}
                              >
                              </Button>
                              <Button
                                title={props.intl.formatMessage({ id: 'modal.txDetails.common.print', defaultMessage: '' })}
                                icon={<PrinterOutlined />}
                                onClick={() => {
                                  printPdf("unPaid");
                                }}
                              >
                              </Button>
                            </div>
                          </div>
                        </div>
                      </Fragment>
                    ),
                    modalBody: (
                      <UnpaidInvoices
                        supplierData={props}
                        {...props}
                      />
                    ),
                    width: 1000,
                    hideFooter: true,
                    wrapClassName: "tiles-modal",
                  });
                }
              }}
              className={
                props.unpaidInvoicesCount
                  ? "link-text"
                  : ""
              }
            >
              {" "}
              {`(${props.unpaidInvoicesCount || 0})`}
            </span>
            {/* <p> {props.unpaidInvoicesTotalAmount ? (props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " ") : ''}{(props.unpaidInvoicesTotalAmount || '') && props.unpaidInvoicesTotalAmount.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p> */}

          </div>
        </Col>
      </Row>

      <hr style={{ marginLeft: "-20px" }} />

      <div className="info-details" style={{ height: "calc(100vh - 430px)", overflowY: 'auto', overflowX: 'hidden' }}>
        <Row gutter={[16, 22]}>
          <Col span={12}>
            <div className="b-text">Supplier Type</div>
            <div className="light-txt">
              {" "}
              {/* {props.supplierTypeName ? (
                <FormattedMessage id={props.supplierTypeName} />
              ) : (
                <div className="empty-data-box"></div>
              )} */}
              {props.businessTypes?.find((item) => item.businessTypeId === props.supplierTypeId)?.businessType || <div className="empty-data-box"></div>}

            </div>
          </Col>
          <Col span={12}>
            <div className="b-text"> Supplier Source</div>
            <div className="light-txt">
              {props.supplierSourceId ? (
                props.supplierSources.filter((item) => item.supplierSourceId === props.supplierSourceId)[0]?.supplierSourceName
              ) : (
                <div className="empty-data-box"></div>
              )}
            </div>
          </Col>

          <Col span={12}>
            <div className="b-text"> Industry</div>
            <div className="light-txt">
              {props.industryName ? (
                <FormattedMessage id={props.industryName} />
              ) : (
                <div className="empty-data-box"></div>
              )}
            </div>
          </Col>
          <Col span={12}>
            <div className="b-text"> GST Mechanism</div>
            <div className="light-txt">
              {" "}
              {props.gstMechanism ? props.gstMechanism : <div className="empty-data-box"></div>}
            </div>
          </Col>
          <Col span={12}>
            <div className="b-text"> Payment Term</div>
            <div className="light-txt">
              {" "}
              {props.paymentTermName ? (
                <FormattedMessage id={props.paymentTermName} />
              ) : (
                <div className="empty-data-box"></div>
              )}
            </div>
          </Col>
          <Col span={12}>
            <div className="b-text">Website Url</div>
            <div className="light-txt">
              {" "}
              {props.webSite ? <FormattedMessage id={props.webSite} /> : <div className="empty-data-box"></div>}
            </div>
          </Col>
          {/* <Col span={12}>
            <div className="b-text">Rate Your Company</div>
            <div className="light-txt">
              {" "}
              {props.starRating ? (
                <FormattedMessage id={props.starRating} />
              ) : (
                <div className="empty-data-box"></div>
              )}
            </div>
          </Col> */}
          <Col span={12}>
            <div className="b-text">Relationship Since</div>
            <div className="light-txt">
              {" "}
              {props.relationshipSince
                ? getMomentDateForUIReadOnly({
                  date: new Date(props.relationshipSince),
                  format,
                })
                : <div className="empty-data-box"></div>}
            </div>
          </Col>
          <Col span={12}>
            <div className="b-text">Total Balance Due</div>
            <div className="light-txt">
              {" "}
              <strong>
                {props.companyInfo.currencyIcon ? (
                  <i className={props.companyInfo.currencyIcon}></i>
                ) : (
                  props.companyInfo.currencyCode + " "
                )}
              </strong>
              {Number(props.totalAmountDue || 0).toFixed(2)}
            </div>
          </Col>
          <Col span={12}>
            <div className="b-text">Available Credit</div>
            <div className="light-txt">
              {" "}
              <strong>
                {props.companyInfo.currencyIcon ? (
                  <i className={props.companyInfo.currencyIcon}></i>
                ) : (
                  props.companyInfo.currencyCode + " "
                )}
              </strong>
              {Number(props.availableCredit || 0).toFixed(2)}
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};
export default Information;
