import React from "react";
import { injectIntl } from "react-intl";
import Return from "./return";
import Replacement from "./replacement";
import CancelComp from "./cancelOrderSetting";
import { Tabs } from "antd";
const { TabPane } = Tabs;

function ReturnOrderComp(props) {

    function callback(key, props) {
      props.clearReturnSettings();
      props.clearItemByCategory(props);
        const payload = {
          companyInfo: props.companyInfo,
          relationshipId: props.companyInfo.relationshipId,
          pageNumber: props.pageNumber,
          pageSize: props.pageSize,
        };
        if (key === "1") {
          props.updateState({ activeTab: "Return", activeTabKey: "1",isHeaderMenuLoaded:false });
          props.getReturnSettings({...payload,type:"Return"})

        } else if (key === "2") {
          props.updateState({ activeTab: "Replace", activeTabKey: "2",isHeaderMenuLoaded:false });
          props.getReturnSettings({...payload,type:"Replace"})
        }
        else if (key === "3") {
          props.updateState({ activeTab: "Cancel", activeTabKey: "3",isHeaderMenuLoaded:false });
          props.getReturnSettings({...payload,type:"Cancel"})
        }
     
      }
   
    return <>
        <div className="store-model-container cus-space">
         
            <Tabs tupe="line" activeKey={props.activeTabKey || "1"} 
                 onChange={(key) => {
                    callback(key, props);
                  }}>
                <TabPane key="1" tab="Return">
                    <Return {...props}/>
                </TabPane>
                <TabPane key="2" tab="Replace">
                    <Replacement {...props}/>
                </TabPane>
                <TabPane key="3" tab="Cancel">
                   <CancelComp {...props}/>
                </TabPane>

            </Tabs>

           
        </div>
    </>
}

export default injectIntl(ReturnOrderComp);