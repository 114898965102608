import React, { Fragment, useMemo } from "react";
import { injectIntl, FormattedMessage } from "react-intl";
// import { CustomAgGrid } from '../../../../general/CustomAgGrid';
import { Skeleton, Menu, Pagination } from "antd";
import PageBreadcrumb from "../../../../PageBreadcrumb";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import "./index.scss";
// import { PictureOutlined,PlusOutlined } from '@ant-design/icons';
import DropdownAction from "antd/lib/dropdown";
import { MoreOutlined } from "@ant-design/icons";

import {
  CONSTANTS,
  ICONS,
  MODAL_TYPE,
  MODULE_CODED_VALUES,
  PERMISSION_VALUES,
} from "../../../../../static/constants";
import {
  checkACLPermission,
  getMomentDateForUIReadOnly,
  getMomentLoclDateForUIReadOnly,
  getPermissionData,
  showToasterMessage,
} from "../../../../../utils";
import PageHeader from "../../../../Common/pageHeader";

// let gridApi;
const BrandListingComp = (props) => {
  const { permissions, productBrands, companyInfo, showModal } = props;
  const primaryPerm = (permissions && permissions.primary) || [];
  const permissionsData = useMemo(()=>{
    return getPermissionData(primaryPerm ,  MODULE_CODED_VALUES.PRODUCT_BRAND);
  },[]) 
  // const getRowHeight = (params) => {
  //   let height = 36;
  //   return height;
  // }

  const addItemConfirmationModal = (text ,e) => {
    const labelName = (
      <FormattedMessage id="addItem.text.productBrand" defaultMessage="" />
    );
    const modalBody = (
      <span>
        <FormattedMessage
          id="addItem.confirmation"
          values={{ value: `${text} to ` }}
          defaultMessage=""
        />
        {labelName} ?
      </span>
    );
    const modalData = {
      modalBody,
      handleSubmit: () => {
        props.addBrand(
          { text: text, relationshipId: companyInfo.relationshipId, id: e.itemBrandId , version: e.version },
          props
        );
        props.hideModal();
        // props.showToasterMessage({
        //   messageType: "error",
        //   description: "Product Name is Required",
        // });
      },
    };
    showModal(modalData);
  };

  const newBrandAddition = (e) => {
   
    const formData = e || {};
    formData.relationshipId = companyInfo.relationshipId;
    formData.textBoxValue = e.brandName;
    formData.selectedValue = e.brandName
    const payload = { formData: formData };
    const data = {
      title: (
        <FormattedMessage id="addItem.text.createBrands" defaultMessage="" />
      ),
      formData,
      modalData: { modalType: MODAL_TYPE.PRODUCT_BRAND, data: {payload ,textBoxValue: e.brandName} },
      handleSubmit: (formData = {}) => {
        if (formData.textBoxValue && formData.textBoxValue.length) {
          addItemConfirmationModal(formData.textBoxValue ,e);
        } else {
          data.formData.submittedOnce = true;
          showModal(data);
        }
      },
    };
    showModal(data);
  };

  const deleteBrand = (payload) => {
    const modalBody = (
      <FormattedMessage
        id="deleteItem.confirmation"
        defaultMessage=""
        values={{ value: payload.brandName }}
      />
    );
    const modalData = {
      modalBody,
      handleSubmit: () => {
        props.deleteBrand({
          id: payload.itemBrandId,
          relationshipId: companyInfo.relationshipId,
        });
      },
    };
    showModal(modalData);
  };

  // const saveBrand = (payload) => {
  //   const modalBody =
  //     <FormattedMessage
  //       id='updateItem.confirmation'
  //       defaultMessage=''
  //       values={{ value: payload.brandName }}
  //     />;
  //   const modalData = {
  //     modalBody,
  //     handleSubmit: () => { props.updateBrand(payload, props)},
  //   };
  //   showModal(modalData);
  // }


  // const columnDefs = [
  //   {
  //     headerName: "#",
  //     resizable: true,
  //     width: 80,
  //     cellRendererFramework: (link) => <div>
  //       {link.rowIndex + 1}
  //     </div>
  //   },
  //   {
  //     headerComponentFramework: () => {
  //       return <FormattedMessage id='inventory.extras.brand.listing.header.brandName' defaultMessage='' />;
  //     },
  //     field: "brandName",
  //     editable: true,
  //     cellEditor: 'customTextEditor',
  //     resizable: true,
  //     onCellClicked: (e) => {
  //       return false;
  //     },
  //     cellClass: 'cellInputControl'
  //   },
  //   {
  //     headerComponentFramework: () => {
  //       return <FormattedMessage id='inventory.extras.brand.listing.header.brandDescription' defaultMessage='' />;
  //     },
  //     field: "description",
  //     resizable: true,
  //     editable: true,
  //     cellEditor: 'customTextEditor',
  //     cellRendererFramework: (params) => <div dangerouslySetInnerHTML={{
  //       __html: params.value || '-'
  //     }}>
  //     </div>,
  //     cellClass: 'cellInputControl'
  //   },
  //   {
  //     headerComponentFramework: () => {
  //       return <FormattedMessage id='inventory.extras.brand.listing.header.dateCreated' defaultMessage='' />;
  //     },
  //     resizable: true,
  //     cellRendererFramework: (link) => <div>
  //       {getMomentDateForUIReadOnly({date: new Date(link.data.dateCreated), format})}
  //     </div>,
  //   },
  //   {
  //     headerComponentFramework: () => {
  //       return <FormattedMessage id='inventory.extras.brand.listing.header.totalProducts' defaultMessage='' />;
  //     },
  //     resizable: true,
  //     field: 'totalProducts'
  //   }
  // ];

  // if (permittedAction.update || permittedAction.delete) {
  //   columnDefs.push({
  //     width: 80,
  //     suppressNavigable: true,
  //     cellClass: 'lastActionCell',
  //     cellRendererFramework: (params) => <div>
  //       {params.node.data.isToEdit &&
  //         <div className="table-cell-action">
  //           <Link onClick={() => {
  //             saveBrand(params.data);
  //             gridApi.getDisplayedRowAtIndex(params.node.rowIndex).data.isToEdit = false;
  //             gridApi.refreshCells({ force: true });
  //           }} >
  //             <i className="fa fa-save" />
  //           </Link>
  //           <Link onClick={() => {
  //             params.api.stopEditing(true);
  //             gridApi.getDisplayedRowAtIndex(params.node.rowIndex).data.isToEdit = false;
  //             gridApi.refreshCells({ force: true });
  //           }} >
  //             <i className="fa fa-ban" />
  //           </Link>
  //         </div>
  //       }

  //       {!params.node.data.isToEdit &&
  //         <div className="table-cell-action" >
  //           {
  //             permittedAction.update && (
  //               <Link onClick={() => {
  //                 gridApi.getDisplayedRowAtIndex(params.node.rowIndex).data.isToEdit = true;
  //                 gridApi.refreshCells({ force: true });
  //               }}>
  //                 <i className="fa fa-edit" />
  //               </Link>
  //             )
  //           }
  //           {
  //             permittedAction.delete && (
  //               <Link onClick={() => deleteBrand(params.data)}>
  //                 <i className="fa fa-remove" />
  //               </Link>
  //             )
  //           }
  //         </div>}
  //     </div>
  //   });
  // }

  // const onFirstDataRendered = (params) => {
  //   //params.api.sizeColumnsToFit();
  // }

  // const agGridStyle = {
  //   height: '100%',
  //   width: '100%'
  // };

  // const onGridReady = (params) => {
  //   gridApi = params.api;
  //   params.api.sizeColumnsToFit();
  // }

  const breadCrumbList = [
    {
      name: "Products",
    },

    {
      name: "Brands",
    },
  ];
  const actionMenu = (e) => {
    return (
      <Menu className="row-action-dropdown">
        <Menu.Item key="0" value="title">
          Actions
        </Menu.Item>

        <Menu.Divider />
         {
          permissionsData?.update ?  <Menu.Item
          key="1"
          value="edit"
          onClick={() => {
            //  function need to be added
            newBrandAddition(e);
          }}
        >
          <i className={ICONS.EDIT} />
          Edit
        </Menu.Item>: ""
         }
       

        {permissionsData.delete && (
          <Menu.Item
            key="2"
            onClick={() => {
              deleteBrand(e);
            }}
          >
            <i className={ICONS.DELETE} />
            Delete
          </Menu.Item>
        )}
      </Menu>
    );
  };

  const showTotal = (total) => {
    return (
      props.intl.formatMessage({
        id: "pagination.text.total",
        defaultMessage: "",
      }) +
      ` ${total || 0} ` +
      props.intl.formatMessage({
        id: "pagination.text.items",
        defaultMessage: "",
      })
    );
  };

  return (
    <Fragment>
        <PageHeader {...props} 
        pageName= "inventory.extras.brand.listing.header.brands"
        breadCrumbList={breadCrumbList}
        canCreate={true}
        
        onCreate={newBrandAddition}
        moduleCode ={MODULE_CODED_VALUES.PRODUCT_BRAND}
      />
      

      <div className="view-container">
        {/* <div className="page-title">
          <div><FormattedMessage id='inventory.extras.brand.listing.header.productBrands' defaultMessage='' /></div>
          <div className="modal-sub-header"><FormattedMessage id='inventory.extras.brand.listing.subHeader.text' defaultMessage='' /></div>
        </div> */}

        {/* <div className="action-dropdown-btn pull-right">
          {
            permittedAction.create && (
              <button className="right-border" onClick={newBrandAddition}>
                <i className="fa fa-user" />
               <FormattedMessage id='inventory.extras.brand.listing.header.newBrand' defaultMessage='' />
              </button>
            )
          }
        </div> */}
        <Skeleton loading={props.isLoading} active paragraph={{ rows: 12 }}>
          <div className="view-container-actions">
            <div className="left-actions">
              <div className="table-heading">
                <FormattedMessage
                  id="inventory.extras.brand.listing.header.productBrands.table.heading"
                  defaultMessage=""
                />
              </div>
              {(props.productBrands || []).length ? (
                <>
                  <div className="vertical-sep" />
                  <div>{props.productBrands.length}</div>
                </>
              ) : (
                ""
              )}
            </div>
            <div className="right-actions"></div>
          </div>
          <div className="table-container">
            <table>
              <thead>
                <tr>
                  <th>
                    <FormattedMessage id="serial.no" defaultMessage="" />
                  </th>
                  <th>
                    <FormattedMessage
                      id="inventory.extras.brand.listing.header.brandName"
                      defaultMessage=""
                    />
                  </th>
                  {/* <th><FormattedMessage id='inventory.extras.brand.listing.header.brandDescription' defaultMessage=''/></th> */}
                  <th>
                    <FormattedMessage
                      id="inventory.extras.brand.listing.header.dateCreated"
                      defaultMessage=""
                    />
                  </th>
                  <th>
                    <FormattedMessage
                      id="inventory.extras.brand.listing.header.totalProducts"
                      defaultMessage=""
                    />
                  </th>
                  {
                    (permissionsData?.delete || permissionsData?.update ) ? <th>
                    <FormattedMessage
                      id="common.listing.header.action"
                      defaultMessage=""
                    />
                  </th>: ""
                  }
                  
                </tr>
              </thead>

              <tbody>
                {(productBrands || []).length &&
                  productBrands.map((rowData, index) => {
                    return (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{rowData.brandName}</td>
                        <td>
                          {rowData.dateCreated
                            ? getMomentDateForUIReadOnly({
                                date: rowData.dateCreated,
                                format: CONSTANTS.DISPLAY_DATE_FORMAT,
                              })
                            : "-"}
                        </td>
                        <td>{rowData.totalProducts}</td>
                        {
                          (permissionsData?.delete || permissionsData?.update ) ?  <td style={{ paddingLeft: "50px" }}>
                          <div className="action-icon">
                            <DropdownAction
                              overlay={actionMenu(rowData)}
                              trigger={["click"]}
                            >
                              <i className={ICONS.MORE} />
                            </DropdownAction>
                          </div>
                        </td>: ""
                        }
                       
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </Skeleton>
        {/* <div className="footer">
          <div className="f-left"></div>

          <div className="f-right">
            <Pagination
              size="small"
              total={props.productBrands.length}
              showTotal={showTotal}
              defaultPageSize={100}
              showSizeChanger
              pageSizeOptions={[10, 25, 50, 100, 200]}
              // showQuickJumper
              onChange={(pageNumber, pageSize) => {
                // loadPageData(pageNumber, pageSize);
                // updateState({ pageSize: pageSize, pageNumber: pageNumber });
              }}
              onShowSizeChange={(pageNumber, pageSize) => {
                // handlePageSizeChange(pageNumber || 1, pageSize, props);
              }}
            />
          </div>
        </div> */}
      </div>
      {/* 
        <div className='agGridWrapper d-flex w-100 pt15'>
          <div className="ag-theme-balham" style={agGridStyle} >
              <CustomAgGrid
                onGridReady={onGridReady}
                columnDefs={columnDefs}
                rowData={productBrands || []}
                rowDragManaged={true}
                domLayout={"autoHeight"}
                animateRows={true}
                getRowHeight={getRowHeight}
                onGridSizeChanged={onFirstDataRendered}
                editType={'fullRow'}
              >
              </CustomAgGrid>

          </div>
        </div> */}
    </Fragment>
  );
};

export default injectIntl(BrandListingComp);
