import axios from 'axios';
import config from '../../../../../config/environmentConfig';
import { COMMON_ACTIONS, INVENTORY_ACTION_LIST } from '../../../../../static/constants';
import {  showToasterMessage } from '../../../../../utils';
const { lang } = require('../../../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');

export const updateCategory = (payload) => {
   // return dispatch => {
     // dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
      // return axios
      //   .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/inventoryItemCategory/createInventoryItemCategory`, payload)
        
        
        const requestObj = {
          relationshipId: payload.relationshipId,
          inventoryItemCategoryId: payload.inventoryItemCategoryId,
          categoryName: payload.categoryName,
          version: payload.version,
          awsKeyName: payload.awsKeyName,
          isPublished: payload?.isPublished,
          //dateCreated: getCurrentDateForBackend(new Date()),
        }
        let formData = new FormData();
        if (payload.fileList && payload.fileList.length) {
          payload.fileList.forEach((file) => {
            formData.append('files', file.originFileObj, file.name);
          })
        }
        console.log(payload.fileList ,"CHECK:::::::::::")
        formData.append('categoryData', JSON.stringify(requestObj, true));
        return dispatch => {
          dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
          return axios
            .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/inventoryItemCategory/createInventoryItemCategory`,
              formData, {
              headers: {
                'content-type': 'multipart/form-data'
              }
            }
            )
        .then(res => {
          ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          dispatch({type: INVENTORY_ACTION_LIST.CATEGORY_LIST_UPDATION, data: res.data})
          showToasterMessage({
            description: (res || {}).message || 'Updated successfully.',
            messageType: 'success'
          })
        })
        .catch((err = {}) => {
          ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          if (err.__isRetryRequest) {
            err.actionToCall = INVENTORY_ACTION_LIST.CATEGORY_LIST_UPDATION;
          } else {
            showToasterMessage({
              description: lang[((err.response || {}).data || {}).message] || 'Some error occurred'
            })
          }
        })
    }
  }