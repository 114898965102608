import React, { Fragment,useMemo} from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { Pagination, Menu, Skeleton, Dropdown, Empty  } from "antd";
import { fetchPaginationDataIfNeeded, getMomentDateForUIReadOnly, getUserDefinedName, sortTable,getPermissionData } from "../../../utils";
import { CONSTANTS, ICONS,MODULE_CODED_VALUES } from '../../../static/constants';
import PageBreadcrumb from "../../PageBreadcrumb";
import { FilterOutlined } from '@ant-design/icons';
import ImportSubjects from "../../../containers/modal/modalBody/schoolManagement/ImportSubjects";
import PageHeader from "../../Common/pageHeader";


const Subject = (props) => {
  const { subjectList, updateState, companyInfo, subjectCount, pageNumber,permissions, dir=0 } = props;

  const breadCrumbList = [
    {
      name: <FormattedMessage id='sidebar.menuItem.school' defaultMessage='' />
    },
    {
      name: <FormattedMessage id='sidebar.menuItem.curriculum' defaultMessage='' />,
    },
    {
      name: getUserDefinedName('sidebar.menuItem.subject',props)
    }
  ];
  const primaryPerm = (permissions && permissions.primary) || [];

  const permissionsData = useMemo(()=>{
    return getPermissionData(primaryPerm, MODULE_CODED_VALUES.SCHOOL_COURSE_DETAIL )
  },[])
  const showTotal = (total) => {
    return (
      props.intl.formatMessage({
        id: "pagination.text.total",
        defaultMessage: "",
      }) +
      ` ${total || 0} ` +
      props.intl.formatMessage({
        id: "pagination.text.items",
        defaultMessage: "",
      })
    );
  };

  const loadPageData = (pageNumber, pageSize) => {
    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      relationshipEmployeeId: (props.userInfo || {}).relationshipEmployeeId,
      pageNumber: pageNumber,
      pageSize: pageSize,
    };
    fetchPaginationDataIfNeeded("getSubject", "subjectList", props, payload);
  };

  const handlePageSizeChange = (pageNumber, pageSize, props) => {
    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      relationshipEmployeeId: (props.userInfo || {}).relationshipEmployeeId,
      pageNumber: pageNumber,
      pageSize: pageSize,
    };
    props.resetPaginatedData();
    props.getSubject(payload);
    props.updateState({ pageSize: pageSize, pageNumber: pageNumber });
  };



  const newSubject = (subjectData) => {
    props.updateHeaderState({
      schoolDrawers: {
        ...props.schoolDrawers,
        addSubjectDrawerVisible: true,
      },
      saveSubject: props.saveSubject,
      getSubject: props.getSubject,
      subjectData: subjectData
    });
  };


  const sortColumn = (e) => {
    sortTable(document.getElementById('subject-table'), e.target.cellIndex, 1 - dir);
    updateState({ dir: 1 - dir })
  }

  const actionMenu = (e) => {
    return (
        <Menu className="row-action-dropdown">

            <Menu.Item key="0" value="title">
              Action
            </Menu.Item>

            <Menu.Divider />

          {permissionsData.update ?  <Menu.Item key="1" value="edit" onClick={()=>{
              newSubject(e);
              }}
            >
              <i className={ICONS.EDIT}/>
                Edit
            </Menu.Item> : "" }

            {permissionsData.delete ? <Menu.Item key="2" onClick={()=>
              {
                props.showModal({
                  modalBody: "Are you sure you want to delete?",
                  handleSubmit: () => {
                    props.deleteSubject(e, props);
                  }
                })
              }
            }>
              <i className={ICONS.DELETE}/>
                Delete
            </Menu.Item>:"" }

        </Menu >
    )
  };

  const handleImportClick = (e) => {
    const modalData = {
      title: getUserDefinedName("Import Subjects", props),
      modalBody: <ImportSubjects {...props} />,
      hideFooter: true,
      width: "100%",
      handleSubmit: () => {
      },
      wrapClassName: "import-details-modal",
    };
    props.pushModalToStack(modalData);

  };



  return (
    <Fragment>
      
      <PageHeader {...props}
        pageName='sidebar.menuItem.curriculum'
        breadCrumbList={breadCrumbList}
        canCreate={true}
        onCreate={newSubject}
        moduleCode = {MODULE_CODED_VALUES.SCHOOL_COURSE_DETAIL}
      
        />
      <div className="view-container">
        <div className="view-container-actions">
        <Skeleton loading={props.headerLoading} paragraph={false} active>

          <div className="left-actions">
            <div className="table-heading">
            {getUserDefinedName('schoolManagement.subject.table.heading',props)}
            </div>
            <div className="vertical-sep" />
            {
              subjectCount ? 
                
                <div>{subjectCount}</div>
              :0
            }
          </div>
          <div className="right-actions">
            {/* <button
              className="action-btn"
              onClick={() => {
                props.updateState({
                  subjectListFilterDrawerVisible: true,
                });
              }}
              >
              <FilterOutlined /> &nbsp;
              <FormattedMessage id="filter.text" defaultMessage="" />
            </button> */}
            <button onClick={handleImportClick}><i className={`${ICONS.DOWNLOAD} mr5`} /> Import</button>
          </div>
        </Skeleton>
        </div>
        <Skeleton loading={props.listLoading} paragraph={{ rows: 13 }} active>
          <div className="table-container">
            <table id="subject-table">
              <thead>
                <tr>
                  <th><FormattedMessage id='serial.no' defaultMessage=''/></th>
                  <th onClick={sortColumn}>{getUserDefinedName('schoolManagement.subject.name', props)} <i className={ICONS.SORT} /></th>
                  <th onClick={sortColumn}>{getUserDefinedName('schoolManagement.subject.code', props)} <i className={ICONS.SORT} /></th>
                  <th onClick={sortColumn}>{getUserDefinedName('schoolManagement.subject.type', props)} <i className={ICONS.SORT} /></th>
                  <th onClick={sortColumn}>{getUserDefinedName('schoolManagement.subject.credit',props)} <i className={ICONS.SORT} /></th>
                  <th onClick={sortColumn}><FormattedMessage id="schoolManagement.school.dateCreated" defaultMessage="" /> <i className={ICONS.SORT} /></th>
                  {permissionsData.delete || permissionsData.update ?   <th width='6%' className="text-center"><FormattedMessage id='common.listing.header.action' defaultMessage="" /></th> :"" }
                </tr>
              </thead>
              <tbody>
                {
                (subjectList[pageNumber] || [])?.length ?  (subjectList[pageNumber] || []).map((e, i) => {
                    return <tr>
                      <td>{i + 1}</td>
                      <td>{e.name || <div className="empty-data-box"/>}</td>
                      <td>{e.subjectCode || <div className="empty-data-box"/>}</td>
                      <td>{e.subjectType && e.subjectType.toLowerCase() === 'core' ? <div className="status-code fulfilled">Core</div> : <div>{e.subjectType && e.subjectType.toLowerCase() === 'elective' ? <div className="status-code open">Elective</div> : ''}</div>}</td>
                      <td>{e.subjectCredit || <div className="empty-data-box"/>}</td>
                      <td>
                        {(e.dateCreated ? getMomentDateForUIReadOnly({ date: e.dateCreated, format: CONSTANTS.TABLE_DATE_FORMAT }):<div className="empty-data-box"/>)}
                      </td>
                      {permissionsData.delete || permissionsData.update ? 
                      <td className="text-center"> 
                        <Dropdown overlay={actionMenu(e)} trigger={['click']}>
                          <i className={ICONS.MORE} />
                        </Dropdown>
                      </td> : "" }
                    </tr>
                  }) : (<tr key="empty">
                    <td colSpan={"100%"}>
                      <Empty />
                    </td>
                  </tr>)
                }
              </tbody>
            </table>
          </div>
        </Skeleton>
        <div className="footer">
        <Skeleton loading={props.headerLoading} paragraph={false} active>
          <div className="f-left"></div>
          <div className="f-right">
            <Pagination
              size="small"
              total={subjectCount}
              showTotal={showTotal}
              defaultPageSize={props.pageSize}
              showSizeChanger
              pageSizeOptions={[10, 25, 50, 100, 200]}
              // showQuickJumper
              onChange={(pageNumber, pageSize) => {
                loadPageData(pageNumber, pageSize);
                updateState({ pageSize: pageSize, pageNumber: pageNumber });
              }}
              onShowSizeChange={(pageNumber, pageSize) => {
                handlePageSizeChange(pageNumber || 1, pageSize, props);
              }}
            />
          </div>
        </Skeleton>
        </div>
      </div>
    
    </Fragment>
  );
};

export default injectIntl(Subject);
