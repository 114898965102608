import React, { Fragment } from "react";
import { FormattedMessage } from "react-intl";
import { Form } from "@ant-design/compatible";
import "@ant-design/compatible/assets/index.css";
import { Menu, Tabs, Empty,Skeleton } from "antd";
import { CustomButton } from "../../../general/CustomButton";
import {
  CONSTANTS,
  MODULE_CODED_VALUES,
  PERMISSION_VALUES,
  // MODAL_TYPE,
  ICONS,
} from "../../../../static/constants";
import { MoreOutlined } from "@ant-design/icons";
// import AddressIcon from "../../../../assets/images/address.jpg";
// import { CustomAgGrid } from "../../../general/CustomAgGrid";
import {
  checkACLPermission,
  getMomentDateForUIReadOnly,
  getPermissionData,
} from "../../../../utils";
import DropdownAction from "antd/lib/dropdown";
// import { Dropdown } from "../../../general/Dropdown";
const format = CONSTANTS.DISPLAY_DATE_FORMAT;

const EditableContext = React.createContext();

class ShowAddress extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editingKey: "",
      locationType: CONSTANTS.BILLING_ADDRESS,
     
    };
  }
  render() {
    const { permissions } = this.props;
    const primaryPerm = (permissions && permissions.primary) || [];

    const permissionData = getPermissionData(primaryPerm, MODULE_CODED_VALUES.SUPPLIER_ADDRESS )
   
    const handleNewAddressAddition = (address) => {
     
      this.props.updateHeaderState({
        address: {
          // isLoaded:true,
          addressDrawer: true,
          formData: {
            isUpdate:address ? true:false,
            ...address,
            supplierId: this.props.supplierId,
          },
        },
      });
    };

    // let payload = {};
    // let formData = {};
    // const locationType =
    //   this.state.tabIndex === 2
    //     ? CONSTANTS.SHIPPING_ADDRESS
    //     : CONSTANTS.BILLING_ADDRESS;
    // payload.formData = {
    //   isUpdate: true,
    //   ...address,
    //   locationType,
    //   supplierId: this.props.supplierId,
    // };
    // const { popModalFromStack, companyInfo } = this.props;
    // formData.relationshipId = companyInfo.relationshipId;
    // payload.formData.callback = (data) => {
    //   getAddress(this.props, locationType);
    //   this.props.hideModal();
    // };

    // const data = {
    //   title:
    //     this.state.tabIndex === 2 ? (
    //       <FormattedMessage
    //         id="addItem.text.shippingAddress"
    //         defaultMessage="Shipping Address"
    //       />
    //     ) : (
    //       <FormattedMessage
    //         id="addItem.text.billingAddress"
    //         defaultMessage="Billing Address"
    //       />
    //     ),
    //   formData,
    //   hideFooter: true,
    //   modalData: {
    //     modalType:
    //       this.state.tabIndex === 2
    //         ? MODAL_TYPE.SHIPPING_ADDRESS
    //         : MODAL_TYPE.BILLING_ADDRESS,
    //     data: payload,
    //   },
    //   handleSubmit: (formData = {}) => {
    //     data.formData.submittedOnce = true;
    //     popModalFromStack(data);
    //     this.props.hideModal();
    //   },
    // };
    // this.props.showModal(data);

    const getModulePermissionData = () => {
      const primaryPerm = permissions.primary || [];
      const permittedModules = {
        supplierBillingAddress: checkACLPermission(
          primaryPerm,
          MODULE_CODED_VALUES.SUPPLIER_BILLING_ADDRESS,
          PERMISSION_VALUES.CREATE
        ),
        supplierBillingAddressDelete: checkACLPermission(
          primaryPerm,
          MODULE_CODED_VALUES.SUPPLIER_BILLING_ADDRESS,
          PERMISSION_VALUES.DELETE
        ),
        supplierBillingAddressUpdate: checkACLPermission(
          primaryPerm,
          MODULE_CODED_VALUES.SUPPLIER_BILLING_ADDRESS,
          PERMISSION_VALUES.UPDATE
        ),
        supplierShippingAddress: checkACLPermission(
          primaryPerm,
          MODULE_CODED_VALUES.SUPPLIER_ADDRESS,
          PERMISSION_VALUES.CREATE
        ),
        supplierShippingAddressUpdate: checkACLPermission(
          primaryPerm,
          MODULE_CODED_VALUES.SUPPLIER_ADDRESS,
          PERMISSION_VALUES.UPDATE
        ),
        supplierShippingAddressDelete: checkACLPermission(
          primaryPerm,
          MODULE_CODED_VALUES.SUPPLIER_ADDRESS,
          PERMISSION_VALUES.DELETE
        ),
      };
      Object.keys(permittedModules).forEach((moduleName) => {
        if (permittedModules[moduleName] === false) {
          delete permittedModules[moduleName];
        }
      });
      return permittedModules;
    };
    const permittedModules = getModulePermissionData();

    // const columnDefs = [
    //   {
    //     headerComponentFramework: () => {
    //       return (
    //         <FormattedMessage
    //           id="supplier.listing.showAddress.gridHeader.primaryAddress"
    //           defaultMessage=""
    //         />
    //       );
    //     },
    //     field: "isDefault",
    //     resizable: true,
    //     editable: true,
    //     width: 100,
    //     suppressKeyboardEvent: (params) => {
    //       const KEY_ENTER = 13;
    //       const keyCode = params.event.keyCode;
    //       if (keyCode === 9) {
    //         params.api.tabToNextCell(true);
    //         return true;
    //       }
    //       const gridShouldDoNothing = params.editing && keyCode === KEY_ENTER;
    //       return gridShouldDoNothing;
    //     },
    //     cellRendererFramework: function (link) {
    //       return <div>{link.value ? "Yes" : "No"}</div>;
    //     },
    //     cellEditor: "customCheckboxEditor",
    //     cellEditorParams: (obj) => {
    //       return {
    //         onChange: (value) => {
    //           obj.node.setDataValue(obj.colDef.field, value ? 1 : 0);
    //         },
    //       };
    //     },
    //   },
    //   // {
    //   //     headerName: "Address Name",
    //   //     field: "locationName",
    //   //     resizable: true,
    //   //     editable: true,
    //   //     cellEditor: 'customTextEditor',
    //   //     cellEditorParams: (obj) => {
    //   //         return {
    //   //             onChange: (value) => {
    //   //                 //obj.node.setDataValue(obj.colDef.field, value);
    //   //             }
    //   //         }
    //   //     }
    //   // },
    //   {
    //     headerComponentFramework: () => {
    //       return (
    //         <FormattedMessage
    //           id="supplier.listing.showAddress.gridHeader.streetAddress1"
    //           defaultMessage=""
    //         />
    //       );
    //     },
    //     field: "streetAddress1",
    //     resizable: true,
    //     editable: true,
    //     width: 150,
    //     cellEditor: "customTextEditor",
    //     suppressKeyboardEvent: (params) => {
    //       const KEY_ENTER = 13;
    //       const keyCode = params.event.keyCode;
    //       if (keyCode === 9) {
    //         params.api.tabToNextCell(true);
    //         return true;
    //       }
    //       const gridShouldDoNothing = params.editing && keyCode === KEY_ENTER;
    //       return gridShouldDoNothing;
    //     },
    //     cellEditorParams: (obj) => {
    //       return {
    //         onChange: (value) => {
    //           obj.node.setDataValue(obj.colDef.field, value);
    //         },
    //       };
    //     },
    //   },
    //   {
    //     headerComponentFramework: () => {
    //       return (
    //         <FormattedMessage
    //           id="supplier.listing.showAddress.gridHeader.streetAddress2"
    //           defaultMessage=""
    //         />
    //       );
    //     },
    //     field: "streetAddress2",
    //     resizable: true,
    //     editable: true,
    //     width: 150,
    //     cellEditor: "customTextEditor",
    //     suppressKeyboardEvent: (params) => {
    //       const KEY_ENTER = 13;
    //       const keyCode = params.event.keyCode;
    //       if (keyCode === 9) {
    //         params.api.tabToNextCell(true);
    //         return true;
    //       }
    //       const gridShouldDoNothing = params.editing && keyCode === KEY_ENTER;
    //       return gridShouldDoNothing;
    //     },
    //     cellEditorParams: (obj) => {
    //       return {
    //         onChange: (value) => {
    //           obj.node.setDataValue(obj.colDef.field, value);
    //         },
    //       };
    //     },
    //   },
    //   {
    //     headerComponentFramework: () => {
    //       return (
    //         <FormattedMessage
    //           id="supplier.listing.showAddress.gridHeader.streetAddress2"
    //           defaultMessage=""
    //         />
    //       );
    //     },
    //     field: "cityName",
    //     editable: true,
    //     width: 100,
    //     resizable: true,
    //     cellEditor: "customTextEditor",
    //     suppressKeyboardEvent: (params) => {
    //       const KEY_ENTER = 13;
    //       const keyCode = params.event.keyCode;
    //       if (keyCode === 9) {
    //         params.api.tabToNextCell(true);
    //         return true;
    //       }
    //       const gridShouldDoNothing = params.editing && keyCode === KEY_ENTER;
    //       return gridShouldDoNothing;
    //     },
    //     cellEditorParams: (obj) => {
    //       return {
    //         onChange: (value) => {
    //           obj.node.setDataValue(obj.colDef.field, value);
    //         },
    //       };
    //     },
    //   },
    //   {
    //     headerComponentFramework: () => {
    //       return (
    //         <FormattedMessage
    //           id="supplier.listing.showAddress.gridHeader.country"
    //           defaultMessage=""
    //         />
    //       );
    //     },
    //     field: "countryName",
    //     editable: true,
    //     width: 100,
    //     resizable: true,
    //     cellEditor: "customDropDownEditor",
    //     suppressKeyboardEvent: (params) => {
    //       const KEY_ENTER = 13;
    //       const keyCode = params.event.keyCode;
    //       if (keyCode === 9) {
    //         params.api.tabToNextCell(true);
    //         return true;
    //       }
    //       const gridShouldDoNothing = params.editing && keyCode === KEY_ENTER;
    //       return gridShouldDoNothing;
    //     },
    //     cellEditorParams: (obj) => {
    //       return {
    //         lastColumnCell: false,
    //         items: this.props.countries || [],
    //         optionKeyName: "countryId",
    //         valueKeyName: "countryName",
    //         onSelect: (selectedValue) => {
    //           obj.node.setDataValue(obj.colDef.field, selectedValue);
    //         },
    //       };
    //     },
    //   },
    //   {
    //     headerComponentFramework: () => {
    //       return (
    //         <FormattedMessage
    //           id="supplier.listing.showAddress.gridHeader.state"
    //           defaultMessage=""
    //         />
    //       );
    //     },
    //     field: "stateName",
    //     editable: true,
    //     width: 100,
    //     resizable: true,
    //     cellEditor: "customTextEditor",
    //     suppressKeyboardEvent: (params) => {
    //       const KEY_ENTER = 13;
    //       const keyCode = params.event.keyCode;
    //       if (keyCode === 9) {
    //         params.api.tabToNextCell(true);
    //         return true;
    //       }
    //       const gridShouldDoNothing = params.editing && keyCode === KEY_ENTER;
    //       return gridShouldDoNothing;
    //     },
    //     cellEditorParams: (obj) => {
    //       return {
    //         onChange: (value) => {
    //           obj.node.setDataValue(obj.colDef.field, value);
    //         },
    //       };
    //     },
    //   },
    //   {
    //     headerComponentFramework: () => {
    //       return (
    //         <FormattedMessage
    //           id="supplier.listing.showAddress.gridHeader.postalCode"
    //           defaultMessage=""
    //         />
    //       );
    //     },
    //     field: "zipCode",
    //     editable: true,
    //     width: 100,
    //     resizable: true,
    //     cellEditor: "customTextEditor",
    //     suppressKeyboardEvent: (params) => {
    //       const KEY_ENTER = 13;
    //       const keyCode = params.event.keyCode;
    //       if (keyCode === 9) {
    //         this.gridApi.getDisplayedRowAtIndex(
    //           params.node.rowIndex
    //         ).data.isToEdit = false;
    //         this.gridApi.refreshCells({ force: true });
    //         params.api.stopEditing(true);
    //         return true;
    //       }
    //       const gridShouldDoNothing = params.editing && keyCode === KEY_ENTER;
    //       return gridShouldDoNothing;
    //     },
    //     cellEditorParams: (obj) => {
    //       return {
    //         onChange: (value) => {
    //           obj.node.setDataValue(obj.colDef.field, value);
    //         },
    //       };
    //     },
    //   },
    //   {
    //     field: "action",
    //     lockPosition: true,
    //     pinned: "right",
    //     maxWidth: 100,
    //     cellRendererFramework: (params) => (
    //       <div>
    //         {params.data.origination !== "System" ? (
    //           <div>
    //             <div>
    //               {params.node.data.isToEdit && (
    //                 <div className="table-cell-action">
    //                   <span
    //                     className="cursor-pointer"
    //                     onClick={() => {
    //                       this.gridApi.getDisplayedRowAtIndex(
    //                         params.node.rowIndex
    //                       ).data.relationshipId = (
    //                         this.props.companyInfo || {}
    //                       ).relationshipId;
    //                       this.gridApi.getDisplayedRowAtIndex(
    //                         params.node.rowIndex
    //                       ).data.isToEdit = false;
    //                       this.gridApi.refreshCells({ force: true });
    //                       addUpdateAddress(
    //                         this.props,
    //                         this.state,
    //                         this.gridApi.getDisplayedRowAtIndex(
    //                           params.node.rowIndex
    //                         ).data
    //                       );
    //                       params.api.stopEditing(true);
    //                       setTimeout(() => {
    //                         params.api.resetRowHeights();
    //                       }, 1000);
    //                     }}
    //                   >
    //                     <i className="fa fa-save" />
    //                   </span>
    //                   <span
    //                     className="cursor-pointer"
    //                     onClick={() => {
    //                       params.api.stopEditing(true);
    //                       this.gridApi.getDisplayedRowAtIndex(
    //                         params.node.rowIndex
    //                       ).data.isToEdit = false;
    //                       this.gridApi.refreshCells({ force: true });
    //                       setTimeout(() => {
    //                         params.api.resetRowHeights();
    //                       }, 1000);
    //                     }}
    //                   >
    //                     <i className="fa fa-ban" />
    //                   </span>
    //                 </div>
    //               )}

    //               {!params.node.data.isToEdit && (
    //                 <div className="table-cell-action">
    //                   {permittedModules.supplierBillingAddressUpdate && (
    //                     <span
    //                       className="cursor-pointer"
    //                       onClick={() => {
    //                         // this.gridApi.forEachNode((rowNode, index) => {
    //                         //     this.gridApi.getDisplayedRowAtIndex(index).data.isToEdit = false;
    //                         // });
    //                         // this.gridApi.getDisplayedRowAtIndex(params.node.rowIndex).data.isToEdit = true;
    //                         // this.gridApi.refreshCells({ columns: ["action"], force: true });
    //                         // var startEditingParams = {
    //                         //     rowIndex: params.node.rowIndex,
    //                         //     colKey: params.column.getId(),
    //                         // };
    //                         // params.api.startEditingCell(startEditingParams);
    //                         handleNewAddressAddition(params.node.data);
    //                       }}
    //                     >
    //                       <i className="fa fa-edit" />
    //                     </span>
    //                   )}
    //                   {permittedModules.supplierBillingAddressDelete && (
    //                     <span
    //                       className="cursor-pointer"
    //                       onClick={() => {
    //                         const modalData = {
    //                           modalBody: (
    //                             <FormattedMessage
    //                               id="customer.address.delete.confirmation"
    //                               defaultMessage=""
    //                             />
    //                           ),
    //                           handleSubmit: () => {
    //                             deleteAddress(
    //                               this.props,
    //                               this.gridApi.getDisplayedRowAtIndex(
    //                                 params.node.rowIndex
    //                               ).data,
    //                               this.state.locationType
    //                             );
    //                           },
    //                         };
    //                         this.props.pushModalToStack(modalData);
    //                       }}
    //                     >
    //                       <i className="fa fa-remove" />
    //                     </span>
    //                   )}
    //                 </div>
    //               )}
    //             </div>
    //           </div>
    //         ) : (
    //           <div></div>
    //         )}
    //       </div>
    //     ),
    //   },
    // ];

    // const onGridReady = (params) => {
    //   this.gridApi = params.api;
    //   params.api.sizeColumnsToFit();
    //   setTimeout(() => {
    //     params.api.resetRowHeights();
    //   }, 1000);
    // };

    // const columns = this.columns.map((col, index) => {
    //     col.key = index;
    //     if (!col.editable) {
    //         return col;
    //     }
    //     let inputType;
    //     if (col.dataIndex === 'defaultAddress') {
    //         inputType = 'checkbox';
    //     } else if (col.dataIndex === 'countryName') {
    //         inputType = 'dropdown'
    //     } else {
    //         inputType = 'text';
    //     }

    //     return {
    //         ...col,
    //         onCell: record => {
    //             const cellProps = {
    //                 record,
    //                 inputType,
    //                 dataIndex: col.dataIndex,
    //                 title: col.title,
    //                 editing: this.isEditing(record),
    //             }
    //             if (inputType === 'dropdown') {
    //                 cellProps.items = this.props.countries;
    //                 cellProps.valueKeyName = 'countryName';
    //                 cellProps.optionKeyName = 'countryId';
    //                 cellProps.style = { width: '100px' };
    //                 cellProps.onSelect = (selectedValue, option) => {
    //                     self.props.form.setFieldsValue({ 'countryId': selectedValue });
    //                     self.props.form.setFieldsValue({ 'countryName': ((option || {}).props || {}).name });
    //                 }
    //             }
    //             return cellProps;
    //         },
    //     };
    // });

    this.data = this.props.addressList.sort((a, b) => (a.locationId < b.locationId ? 1 : -1)).map((addressObj, index) => {
      return {
        sNo: index + 1,
        version: addressObj.version,
        locationId: addressObj.locationId,
        isDefault: addressObj.isDefault,
        locationName: addressObj.locationName,
        streetAddress1: addressObj.streetAddress1,
        streetAddress2: addressObj.streetAddress2,
        cityName: addressObj.cityName,
        zipCode: addressObj.zipCode,
        countryName: addressObj.countryName,
        countryId: addressObj.countryId,
        stateName: addressObj.stateName,
        locationType: addressObj.locationType,
        dateCreated: addressObj.dateCreated,
      };
    });

    // const onFirstDataRendered = (params) => {
    //   //params.api.sizeColumnsToFit();
    // };
    const actionMenu = (param) => (
      <Menu className="row-action-dropdown">
        <Menu.Item key="0" value="title">
          Action
        </Menu.Item>
        {/* {permittedModules.supplierBillingAddressUpdate ? ( */}
        {permissionData.update ? (
          <Menu.Item
            key="5"
            value="edit"
            onClick={() => {
              handleNewAddressAddition(param);
            }}
          >
            <i className={ICONS.EDIT} />
            <div>
             Edit
            </div>
          </Menu.Item>
        ) : (
          <Fragment />
        )}

        {permissionData.delete ? (
          <Menu.Item
            key="6"
            value="Delete"
            onClick={() => {
              const modalData = {
                modalBody: (
                  <FormattedMessage
                    id="customer.address.delete.confirmation"
                    defaultMessage=""
                  />
                ),
                handleSubmit: () => {
                  deleteAddress(
                    this.props,
                    //   this.gridApi.getDisplayedRowAtIndex(
                    //     params.node.rowIndex
                    //   ).data,
                    param,
                    this.state.locationType
                  );
                },
              };
              this.props.pushModalToStack(modalData);
            }}
          >
            <i className={ICONS.DELETE} />
            Delete
          </Menu.Item>
        ) : (
          <Fragment />
        )}
      </Menu>
    );
    return (
      <Fragment>
        {/* <div className="view-top-bar"> */}
        {/* <div className="top-view-left"> */}
        {/* <div className="page-heading">Address</div> */}
        {/* <div className="vertical-sep" /> */}
        {/* <PageBreadcrumb breadCrumbList={breadCrumbList} /> */}
        {/* </div> */}
        {/* <div className="top-view-right">
            <button
              className="create-action-btn"
              onClick={() => {
                handleNewAddressAddition({});
              }}
            >
              <i className={ICONS.ADD} />{" "}
              <FormattedMessage id="button.create.label" defaultMessage="" />
            </button>
          </div> */}
        {/* </div> */}
        <div className="view-container">
          <div className="view-container-actions">
            <div className="left-actions">
              <div className="table-heading">
                <FormattedMessage id="Address" defaultMessage="" />
              </div>
              {this.data ? (
                <>
                  <div className="vertical-sep" />
                  <div>{this.data.length}</div>
                </>
              ) : (
                ""
              )}
            </div>

            <div className="right-actions">
              {permissionData.create ?
              <button
                style={{
                  backgroundColor: "#1cb961",
                  color: "#ffffff",
                  fontSize: "14px",
                }}
                className="create-action-btn"

                onClick={() => {
                  handleNewAddressAddition();
                }}
              >
                <i className={`${ICONS.ADD} mr5`} />
                <FormattedMessage id="button.create.label" defaultMessage="" />
              </button> : "" }
            </div>
          </div>
        <Skeleton loading={this.props.listLoading} active paragraph={{ rows: 6 }}>

          <div className="table-container" style={{ height: 'calc(100vh - 138px)'}}>
            <table id="contacts-table">
              <thead>
                <tr>
                  <th>S. No.</th>
                  <th>
                    <FormattedMessage
                      id="address.addressType"
                      defaultMessage=""
                    />
                  </th>
                  <th>
                    <FormattedMessage
                      id="supplier.listing.showAddress.gridHeader.primaryAddress"
                      defaultMessage=""
                    />
                  </th>
                  <th>
                    <FormattedMessage
                      id="supplier.listing.showAddress.gridHeader.streetAddress1"
                      defaultMessage=""
                    />
                  </th>
                  <th>
                    <FormattedMessage
                      id="supplier.listing.showAddress.gridHeader.streetAddress2"
                      defaultMessage=""
                    />
                  </th>
                  <th>City</th>
                  <th>
                    <FormattedMessage
                      id="supplier.listing.showAddress.gridHeader.country"
                      defaultMessage=""
                    />
                  </th>
                  <th>
                    <FormattedMessage
                      id="supplier.listing.showAddress.gridHeader.state"
                      defaultMessage=""
                    />
                  </th>
                  <th>
                    <FormattedMessage
                      id="supplier.listing.showAddress.gridHeader.postalCode"
                      defaultMessage=""
                    />
                  </th>
                  <th>Date Created</th>
                  {permissionData.update || permissionData.delete ? <th>
                    <FormattedMessage id="Action" defaultMessage="" />
                  </th> : "" }
                </tr>
              </thead>
              <tbody>
                {this.data && this.data.length ? (
                  this.data.map((e, i) => {
                    return (
                      <tr key={i}>
                        <td>{i + 1}</td>
                        <td className="one-line-view">
                        {e.locationType === "BillingAddress"
                                  ? "Billing Address"
                                  : "Shipping Address"}
                        </td>
                        <td>{e.isDefault ? "Yes" : "No"}</td>
                        <td>{e.streetAddress1?e.streetAddress1:<div className="empty-data-box"></div>}</td>
                        <td>{e.streetAddress2?e.streetAddress2:<div className="empty-data-box"></div>}</td>
                        <td>{e.cityName?e.cityName:<div className="empty-data-box"></div>}</td>
                        <td>{e.countryName?e.countryName:<div className="empty-data-box"></div>}</td>
                        <td>{e.stateName?e.stateName:<div className="empty-data-box"></div>}</td>
                        <td>{e.zipCode?e.zipCode:<div className="empty-data-box"></div>}</td>
                        <td className="one-line-view">
                          {" "}
                          {e.dateCreated
                            ? getMomentDateForUIReadOnly({
                                date: new Date(e.dateCreated),
                                format,
                              })
                            : <div className="empty-data-box"></div>}
                        </td>
                        {/* <td>{e.faxNumber}</td> */}
                      {permissionData.update || permissionData.delete ? <td style={{ textAlign: "center" }}>
                          <DropdownAction
                            overlay={actionMenu(e)}
                            trigger={["click"]}
                            overlayClassName="action-listing-dropdown"
                          >
                            <MoreOutlined />
                          </DropdownAction>
                        </td> : "" }
                      </tr>
                    );
                  })
                ) : (
                  <tr key="empty-data">
                    <td colSpan={"100%"} style={{ borderBottom: "none" }}>
                      <Empty />
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          </Skeleton>
        </div>
        {(false &&
          this.props.addresses[CONSTANTS.BILLING_ADDRESS] &&
          this.props.addresses[CONSTANTS.BILLING_ADDRESS].length > 0) ||
        (false &&
          this.props.addresses[CONSTANTS.SHIPPING_ADDRESS] &&
          this.props.addresses[CONSTANTS.SHIPPING_ADDRESS].length > 0) ? (
          <div className="show-address-container">
            <Tabs
              type="line"
              onTabClick={(tabIndex) => {
                const locationType =
                  Number(tabIndex) === 1
                    ? CONSTANTS.BILLING_ADDRESS
                    : CONSTANTS.SHIPPING_ADDRESS;
                this.setState({
                  ...this.state,
                  locationType,
                  tabIndex: Number(tabIndex),
                });
                getAddress(this.props, locationType);
              }}
            >
              <Tabs.TabPane tab="Billing Address" key="1">
                <EditableContext.Provider value={this.props.form}>
                  {/* <CustomAgGrid
                    columnDefs={columnDefs}
                    rowData={this.data || []}
                    rowDragManaged={true}
                    defaultColDef={{
                      flex: 1,
                      autoHeight: true,
                      wrapText: true,
                    }}
                    suppressClickEdit={true}
                    noSingleClickEdit={true}
                    disableFocusOnClickOutside={true}
                    domLayout={"autoHeight"}
                    animateRows={true}
                    onGridSizeChanged={onFirstDataRendered}
                    //getRowHeight={getRowHeight}
                    editType={"fullRow"}
                    onColumnResized={(event) => {
                      event.api.resetRowHeights();
                    }}
                    onGridReady={onGridReady}
                  ></CustomAgGrid> */}
                </EditableContext.Provider>
                {permittedModules.supplierBillingAddress && (
                  <div className="mt20">
                    <CustomButton
                      intlId="addRow"
                      defaultMessage=""
                      onClick={() => {
                        handleNewAddressAddition();
                        // let newRowIndex = -1;
                        // this.gridApi.forEachNode((rowNode, index) => {
                        //     newRowIndex = index;
                        //     this.gridApi.getDisplayedRowAtIndex(index).data.isToEdit = false;
                        // });
                        // this.gridApi.stopEditing();
                        // this.gridApi.updateRowData({ add: [{ isToEdit: true }] });
                        // const rowNode = this.gridApi && this.gridApi.getDisplayedRowAtIndex(newRowIndex + 1);
                        // if (rowNode) { rowNode.data['countryName'] = (this.props.companyInfo || {}).countryName; }
                        // this.gridApi.startEditingCell({
                        //     rowIndex: newRowIndex + 1,
                        //     colKey: "isDefault"
                        // });
                        // this.gridApi.refreshCells({ force: true });
                      }}
                    />
                  </div>
                )}
              </Tabs.TabPane>
              <Tabs.TabPane tab="Shipping Address" key="2">
                <EditableContext.Provider value={this.props.form}>
                  {/* <CustomAgGrid
                    columnDefs={columnDefs}
                    rowData={this.data || []}
                    rowDragManaged={true}
                    suppressClickEdit={true}
                    noSingleClickEdit={true}
                    defaultColDef={{
                      flex: 1,
                      autoHeight: true,
                      wrapText: true,
                    }}
                    disableFocusOnClickOutside={true}
                    domLayout={"autoHeight"}
                    animateRows={true}
                    onGridSizeChanged={onFirstDataRendered}
                    //getRowHeight={getRowHeight}
                    editType={"fullRow"}
                    onGridReady={onGridReady}
                  ></CustomAgGrid> */}
                </EditableContext.Provider>
                <div className="mt20">
                  {permittedModules.supplierBillingAddress && (
                    <CustomButton
                      intlId="addRow"
                      defaultMessage=""
                      onClick={() => {
                        handleNewAddressAddition();
                        // let newRowIndex = -1;
                        // this.gridApi.forEachNode((rowNode, index) => {
                        //     newRowIndex = index;
                        //     this.gridApi.getDisplayedRowAtIndex(index).data.isToEdit = false;
                        // });
                        // this.gridApi.stopEditing();
                        // this.gridApi.updateRowData({ add: [{ isToEdit: true }] });
                        // const rowNode = this.gridApi && this.gridApi.getDisplayedRowAtIndex(newRowIndex + 1);
                        // if (rowNode) { rowNode.data['countryName'] = (this.props.companyInfo || {}).countryName; }
                        // this.gridApi.startEditingCell({
                        //     rowIndex: newRowIndex + 1,
                        //     colKey: "isDefault"
                        // });
                        // this.gridApi.refreshCells({ force: true });
                      }}
                    />
                  )}
                </div>
              </Tabs.TabPane>
            </Tabs>
          </div>
        ) : (
          <div className="create-data-container">
            {/* <h4 className="progressbar-modal-heading">
              {" "}
              <FormattedMessage id="Address.does.not.exist" defaultMessage="" />
              <div
                className="cursor-pointer"
                to={{}}
                onClick={() => {
                  this.props.addAddressRow(this.state.locationType, 0);
                }}
              >
                <span> </span>{" "}
                <FormattedMessage id="clickhere" defaultMessage="" />{" "}
                <span> </span>
              </div>
              <FormattedMessage id="to.create.address" defaultMessage="" />
            </h4>
            <img src={AddressIcon} alt="Invoice" height="275" /> */}
          </div>
        )}
      </Fragment>
    );
  }
}

const getAddress = (props, locationType) => {
  const payload = {
    relationshipId: props.companyInfo.relationshipId,
    locationType: locationType,
    supplierId: props.supplierId,
  };
  props.fetchSupplierAddresses(payload);
};

const deleteAddress = (props, record, locationType) => {
  const payload = {
    locationId: record.locationId,
    relationshipId: props.companyInfo.relationshipId,
    locationType: locationType,
    supplierId: props.supplierId,
    isDefault: record.defaultAddress === true ? 1 : 0,
  };

  props.deleteAddress(payload);
  getAddress(props, locationType);
  //props.resetPaginatedData();
  props.fetchSupplierAddresses({
    relationshipId: props.companyInfo.relationshipId,
    pageNumber: props.pageNumber,
    pageSize: props.pageSize,
    supplierId: props.supplierId,
  });
};

// const addUpdateAddress = (props, state, record) => {
//   record.isDefault = record.isDefault ? 1 : 0;
//   const payload = {
//     ...record,
//     locationType: state.locationType,
//     relationshipId: props.companyInfo.relationshipId,
//     supplierId: props.supplierId,
//   };
//   props.createUpdateAddress(payload);
//   getAddress(props, state.locationType);
//   //props.resetPaginatedData();
//   //props.fetchSuppliers({ relationshipId: props.companyInfo.relationshipId, pageNumber: props.pageNumber, pageSize: props.pageSize });
// };

const EditableAddressTable = Form.create()(ShowAddress);

export default EditableAddressTable;
