import axios from 'axios';
import download from 'downloadjs';
import config from '../../../config/environmentConfig';
import { COMMON_ACTIONS } from '../../../static/constants'
import { showToasterMessage } from '../../../utils';
import { PROFILE_ACCOUNT_INFO } from '../../../static/constants';

const { lang } = require('../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');

export const fetchAllSubscriptions = (payload) => {
    
    return dispatch => {
    //   dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
      return axios
        .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContextSupport}/subscription/getAllSubscriptions?relationshipId=${payload.relationshipId}`)
        .then(res => {
          
            console.log(res.data ,"Rubby")
          
          dispatch({ type: PROFILE_ACCOUNT_INFO.ALL_SUBSCRIBED_PLANS , data: res.data });
        })
        .catch((err) => {
        //   dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          if(!err.__isRetryRequest){
            showToasterMessage({messageType: 'error',  description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
          }else{
            // err.actionToCall = CUSTOMER_PAYMENT_ACTION_LIST.CUSTOMER_SUBSCRIPTION_LIST;
            // err.data = {
            //   pageNo: payload.pageNumber,
            //   list: []
            // };
          }
        })
    }
  }


  export const fetchPackagePlanbyCostId = (payload) => {
    
    return dispatch => {
    //   dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
      return axios
        .get(`${config.API_BASE_URL}/${config.rootContextCMS}/api/v1/subscription/getByCostId?costId=${payload.planCode}`)
        .then(res => {
          
            console.log(res.data ,"Rubby")
          
          dispatch({ type: PROFILE_ACCOUNT_INFO.PACAKGE_PLAN_BY_CODE , data: res.data?.data });
        })
        .catch((err) => {
        //   dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          if(!err.__isRetryRequest){
            showToasterMessage({messageType: 'error',  description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
          }else{
            // err.actionToCall = CUSTOMER_PAYMENT_ACTION_LIST.CUSTOMER_SUBSCRIPTION_LIST;
            // err.data = {
            //   pageNo: payload.pageNumber,
            //   list: []
            // };
          }
        })
    }
  }


  export const fetchBillingHistory = (payload) => {
    
    return dispatch => {
    //   dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
      return axios
        .get(`${config.API_BASE_URL}/${config.rootContextSupport}/subscription/getBillingHistory?relationshipId=${payload.relationshipId}`)
        .then(res => {
          
            console.log(res.data ,"Rubby")
          
          dispatch({ type: PROFILE_ACCOUNT_INFO.BILLING_HISTORY_PACKAGE_PLANS , data: res.data });
        })
        .catch((err) => {
        //   dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          if(!err.__isRetryRequest){
            showToasterMessage({messageType: 'error',  description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
          }else{
            // err.actionToCall = CUSTOMER_PAYMENT_ACTION_LIST.CUSTOMER_SUBSCRIPTION_LIST;
            // err.data = {
            //   pageNo: payload.pageNumber,
            //   list: []
            // };
          }
        })
    }
  }


 

  export const downloadBillingInvoice = (payload) => {
    
    return dispatch => {
    //   dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
      return axios
        .get(`${config.API_BASE_URL}/${config.rootContextSupport}/subscription/downloadInvoice?invoiceId=${payload?.invoiceId}`)
        .then(res => {
            const downloadUrl = res.data?.download_url
           
          if (downloadUrl) {
            // Automatically trigger download using a temporary <a> element
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.download = 'invoice.pdf'; // Filename for the downloaded file
            link.target = '_blank'; // Open in a new tab/window to avoid being blocked
            document.body.appendChild(link); // Append the link to the body
            link.click(); // Programmatically click the link to trigger the download
            document.body.removeChild(link); // Remove the link after triggering the download
          } else {
            console.error('No download URL found');
          }
          dispatch({ type: PROFILE_ACCOUNT_INFO.DOWNLOAD_BILLING_INVOICE , data: res.data });
        })
        .catch((err) => {
        //   dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          if(!err.__isRetryRequest){
            showToasterMessage({messageType: 'error',  description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
          }else{
            // err.actionToCall = CUSTOMER_PAYMENT_ACTION_LIST.CUSTOMER_SUBSCRIPTION_LIST;
            // err.data = {
            //   pageNo: payload.pageNumber,
            //   list: []
            // };
          }
        })
    }
  }


  export const cancelSubscribedPlan = (payload) => {
    
    return dispatch => {
    //   dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
      return axios
        .get(`${config.API_BASE_URL}/${config.rootContextSupport}/subscription/cancelSubscription?subscriptionId=${payload.subscriptionId}`)
        .then(res => {
          
            console.log(res ,"Rubby")
          if(res.data){
            showToasterMessage({
              messageType: "Success",
              description: "Subscribed product cancel Successfully"
            })
          }
          else{
            showToasterMessage({
              messageType: "error",
              description: res.message||"Some Error occured"
            })
          }
               
        })
        .catch((err) => {
        //   dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          if(!err.__isRetryRequest){
            showToasterMessage({messageType: 'error',  description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
          }else{
            // err.actionToCall = CUSTOMER_PAYMENT_ACTION_LIST.CUSTOMER_SUBSCRIPTION_LIST;
            // err.data = {
            //   pageNo: payload.pageNumber,
            //   list: []
            // };
          }
        })
    }
  }


  export const fetchCustomerPaymentAddressInfo = (payload) => {
    
    return dispatch => {
    //   dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
      return axios
        .get(`${config.API_BASE_URL}/${config.rootContextSupport}/subscription/getPaymentMethodInfo?relationshipId=${payload.relationshipId}`)
        .then(res => {
          
            console.log(res.data ,"Rubby")
          
          dispatch({ type: PROFILE_ACCOUNT_INFO.PAYMENT_ADDRESS_INFO , data: res.data });
        })
        .catch((err) => {
        //   dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          if(!err.__isRetryRequest){
            showToasterMessage({messageType: 'error',  description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
          }else{
            // err.actionToCall = CUSTOMER_PAYMENT_ACTION_LIST.CUSTOMER_SUBSCRIPTION_LIST;
            // err.data = {
            //   pageNo: payload.pageNumber,
            //   list: []
            // };
          }
        })
    }
  }