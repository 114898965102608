import React from "react";
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Tabs } from 'antd';
import { CustomButton } from '../../../general/CustomButton';
import { CONSTANTS, MODAL_TYPE } from '../../../../static/constants';
import AddressIcon from '../../../../assets/images/address.jpg';
import { CustomAgGrid } from "../../../general/CustomAgGrid";
import { FormattedMessage } from "react-intl";
import { MODULE_CODED_VALUES, PERMISSION_VALUES } from '../../../../static/constants';
import { checkACLPermission, getPermissionData } from "../../../../utils";

const EditableContext = React.createContext();


class ShowAddress extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            editingKey: '',
            locationType: CONSTANTS.BILLING_ADDRESS
        };
    }


    render() {
        const { permissions } = this.props;
  const primaryPerm = (permissions && permissions.primary) || [];

        const customerShippingAddressPermissions =  getPermissionData(primaryPerm ,  MODULE_CODED_VALUES.CUSTOMER_ADDRESS);
        const customerBillingAddressPermissions =  getPermissionData(primaryPerm ,  MODULE_CODED_VALUES.CUSTOMER_BILLING_ADDRESS);
      
        const handleNewAddressAddition = (address) => {
            let payload = {};
            let formData = {};
            let self = this;
            const locationType = this.state.tabIndex === 2? CONSTANTS.SHIPPING_ADDRESS: CONSTANTS.BILLING_ADDRESS;
            payload.formData = { isUpdate: true, ...address, locationType,  customerId: this.props.customerId};
            const { pushModalToStack, popModalFromStack, companyInfo } = this.props;
            formData.relationshipId = companyInfo.relationshipId;
            payload.formData.callback = (data) => {
                this.props.popModalFromStack();
                getAddress(this.props, locationType);
                if (self.props.fetchCustomers) {
                    const load = {
                        companyInfo,
                        relationshipId: companyInfo.relationshipId,
                        userId: (self.props.userInfo || {}).userId,
                        pageNumber: self.props.pageNumber || 1,
                        pageSize: self.props.pageSize || 25
                    };
                    self.props.fetchCustomers(load);
                }
                if(self.props.fetchCustomerAddresses){
                    const load = {
                        companyInfo,
                        relationshipId: companyInfo.relationshipId,
                        customerId: this.props.customerId,
                        userId: (self.props.userInfo || {}).userId,
                        pageNumber: self.props.pageNumber || 1,
                        pageSize: self.props.pageSize || 25
                    };
                    self.props.fetchCustomerAddresses(load);
                }
            };
            payload.formData.onCancel = ()=>{
                // this.props.popModalFromStack();
                  this.props.pushModalToStack({
                      modalBody: <FormattedMessage id="modal.cancel.confirmation" defaultMessage=""/>,
                      footer:{submit:{intlId:"button.close.label"}},
                      handleSubmit: () => {
                        this.props.popModalFromStack();
                      }
                    })
            }


            const data = {
                // title: this.state.tabIndex === 2 ? <FormattedMessage id='addItem.text.shippingAddress' defaultMessage='Shipping Address' />: <FormattedMessage id='addItem.text.billingAddress' defaultMessage='Billing Address' />,
                title:  !payload.formData?.cityName ? <FormattedMessage id='addItem.text.billingAddress' defaultMessage='Billing Address' />:"Edit Address",
                formData,
                hideFooter: true,
                modalData: {
                    modalType: this.state.tabIndex === 2 ? MODAL_TYPE.SHIPPING_ADDRESS: MODAL_TYPE.BILLING_ADDRESS,
                    data: payload,
                },
                handleSubmit: (formData = {}) => {
                    data.formData.submittedOnce = true;
                    popModalFromStack(data);
                },
                onCancel: () => {
                    this.props.pushModalToStack({
                      modalBody: <FormattedMessage id="modal.cancel.confirmation" defaultMessage=""/>,
                      footer:{submit:{intlId:"button.close.label"}},
                      handleSubmit: () => {
                        this.props.popModalFromStack();
                      }
                    })
                  }
            };
            pushModalToStack(data);
        }

        

        const columnDefs = [
            {
                headerComponentFramework: () => {
                    return <FormattedMessage id='address.primary' defaultMessage='' />;
                },
                field: "isDefault",
                resizable: true,
                editable: true,
                width: 100,
                suppressKeyboardEvent: (params) => {
                    const KEY_ENTER = 13;
                    const keyCode = params.event.keyCode;
                    if (keyCode === 9) {
                        params.api.tabToNextCell();
                        return true;
                    }
                    const gridShouldDoNothing = params.editing && keyCode === KEY_ENTER;
                    return gridShouldDoNothing;
                },
                cellRendererFramework: function (link) {
                    return <div>
                        {link.value ? <FormattedMessage id='yes' defaultMessage='' /> : <FormattedMessage id='no' defaultMessage='' />}
                    </div>
                },
                cellEditor: 'customCheckboxEditor',
                cellEditorParams: (obj) => {
                    return {
                        className: "custom-height",
                        onChange: (value) => {
                            obj.node.setDataValue(obj.colDef.field, value ? 1 : 0);
                        }
                    }
                }
            },
            // {
            //     headerName: "Address Name",
            //     field: "locationName",
            //     resizable: true,
            //     editable: true,
            //     cellEditor: 'customTextEditor',
            //     cellEditorParams: (obj) => {
            //         return {
            //             onChange: (value) => {
            //                 //obj.node.setDataValue(obj.colDef.field, value);
            //             }
            //         }
            //     }
            // },
            {
                headerComponentFramework: () => {
                    return <FormattedMessage id='address.streetAddress1' defaultMessage='' />;
                },
                field: "streetAddress1",
                width: 300,
                resizable: true,
                editable: true,
                cellEditor: 'customTextEditor',
                suppressKeyboardEvent: (params) => {
                    const KEY_ENTER = 13;
                    const keyCode = params.event.keyCode;
                    if (keyCode === 9) {
                        params.api.tabToNextCell();
                        return true;
                    }
                    const gridShouldDoNothing = params.editing && keyCode === KEY_ENTER;
                    return gridShouldDoNothing;
                },
                cellEditorParams: (obj) => {
                    return {
                        className: "custom-height",
                        onChange: (value) => {
                            obj.node.setDataValue(obj.colDef.field, value);
                        }
                    }
                }
            },
            {
                headerComponentFramework: () => {
                    return <FormattedMessage id='address.streetAddress2' defaultMessage=' ' />;
                },
                field: "streetAddress2",
                resizable: true,
                width: 300,
                editable: true,
                cellEditor: 'customTextEditor',
                suppressKeyboardEvent: (params) => {
                    const KEY_ENTER = 13;
                    const keyCode = params.event.keyCode;
                    if (keyCode === 9) {
                        params.api.tabToNextCell();
                        return true;
                    }
                    const gridShouldDoNothing = params.editing && keyCode === KEY_ENTER;
                    return gridShouldDoNothing;
                },
                cellEditorParams: (obj) => {
                    return {
                        className: "custom-height",
                        onChange: (value) => {
                            obj.node.setDataValue(obj.colDef.field, value);
                        }
                    }
                }
            },
            {
                headerComponentFramework: () => {
                    return <FormattedMessage id='address.city' defaultMessage='' />;
                },
                field: "cityName",
                editable: true,
                resizable: true,
                cellEditor: 'customTextEditor',
                suppressKeyboardEvent: (params) => {
                    const KEY_ENTER = 13;
                    const keyCode = params.event.keyCode;
                    if (keyCode === 9) {
                        params.api.tabToNextCell();
                        return true;
                    }
                    const gridShouldDoNothing = params.editing && keyCode === KEY_ENTER;
                    return gridShouldDoNothing;
                },
                cellEditorParams: (obj) => {
                    return {
                        className: "custom-height",
                        onChange: (value) => {
                            obj.node.setDataValue(obj.colDef.field, value);
                        }
                    }
                }
            },
            {
                headerComponentFramework: () => {
                    return <FormattedMessage id='address.country' defaultMessage='' />;
                },
                field: "countryName",
                editable: true,
                resizable: true,
                cellEditor: 'customDropDownEditor',
                suppressKeyboardEvent: (params) => {
                    const KEY_ENTER = 13;
                    const keyCode = params.event.keyCode;
                    if (keyCode === 9) {
                        params.api.tabToNextCell();
                        return true;
                    }
                    const gridShouldDoNothing = params.editing && keyCode === KEY_ENTER;
                    return gridShouldDoNothing;
                },
                cellEditorParams: (obj) => {
                    return {
                        lastColumnCell: false,
                        items: this.props.countries || [],
                        optionKeyName: 'countryId',
                        valueKeyName: 'countryName',
                        className: "custom-height",
                        onSelect: (selectedValue) => {
                            obj.node.setDataValue(obj.colDef.field, selectedValue);
                        },
                    }
                }
            },
            {
                headerComponentFramework: () => {
                    return <FormattedMessage id='address.state' defaultMessage='' />;
                },
                field: "stateName",
                editable: true,
                resizable: true,
                suppressKeyboardEvent: (params) => {
                    const KEY_ENTER = 13;
                    const keyCode = params.event.keyCode;
                    if (keyCode === 9) {
                        params.api.tabToNextCell();
                        return true;
                    }
                    const gridShouldDoNothing = params.editing && keyCode === KEY_ENTER;
                    return gridShouldDoNothing;
                },
                cellEditor: 'customTextEditor',
                cellEditorParams: (obj) => {
                    return {
                        className: "custom-height",
                        onChange: (value) => {
                            obj.node.setDataValue(obj.colDef.field, value);
                        }
                    }
                }
            },
            {
                headerComponentFramework: () => {
                    return <FormattedMessage id='address.zip' defaultMessage='' />;
                },
                field: "zipCode",
                editable: true,
                resizable: true,
                suppressKeyboardEvent: (params) => {
                    const KEY_ENTER = 13;
                    const keyCode = params.event.keyCode;
                    if (keyCode === 9) {
                        this.gridApi.getDisplayedRowAtIndex(params.node.rowIndex).data.isToEdit = false;
                        this.gridApi.refreshCells({ force: true });
                        params.api.stopEditing(true);
                        return true;
                    }
                    const gridShouldDoNothing = params.editing && keyCode === KEY_ENTER;
                    return gridShouldDoNothing;
                },
                cellEditor: 'customTextEditor',
                cellEditorParams: (obj) => {
                    return {
                        className: "custom-height",
                        onChange: (value) => {
                            obj.node.setDataValue(obj.colDef.field, value);
                        }
                    }
                }
            },
            {
                field: 'action',
                lockPosition: true,
                pinned: 'right',
                width: 180,
                cellRendererFramework: (params) => <div>
                    {(params.data.origination !== 'System') ? <div>
                        <div>
                            {params.node.data.isToEdit &&
                                <div className="table-cell-action">
                                    {   customerBillingAddressPermissions?.update && <span className='cursor-pointer' onClick={() => {
                                        this.gridApi.getDisplayedRowAtIndex(params.node.rowIndex).data.relationshipId = (this.props.companyInfo || {}).relationshipId;
                                        this.gridApi.getDisplayedRowAtIndex(params.node.rowIndex).data.isToEdit = false;
                                        params.api.stopEditing(true);
                                        addUpdateAddress(this.props, this.state, this.gridApi.getDisplayedRowAtIndex(params.node.rowIndex).data);
                                        this.gridApi.refreshCells({ force: true });

                                    }} >
                                        <i className="fa fa-save" />
                                    </span>}
                                    
                                    <span className='cursor-pointer' onClick={() => {
                                        params.api.stopEditing(true);
                                        this.gridApi.getDisplayedRowAtIndex(params.node.rowIndex).data.isToEdit = false;
                                        this.gridApi.refreshCells({ force: true });
                                    }} >
                                        <i className="fa fa-ban" />
                                    </span>
                                </div>
                            }

                            {!params.node.data.isToEdit &&
                                <div className="table-cell-action" >
                                    {customerBillingAddressPermissions?.update && <span className='cursor-pointer' onClick={() => {
                                        // this.gridApi.forEachNode((rowNode, index) => {
                                        //     this.gridApi.getDisplayedRowAtIndex(index).data.isToEdit = false;
                                        // });
                                        // this.gridApi.getDisplayedRowAtIndex(params.node.rowIndex).data.isToEdit = true;
                                        // this.gridApi.refreshCells({ columns: ["action"], force: true });
                                        // var startEditingParams = {
                                        //     rowIndex: params.node.rowIndex,
                                        //     colKey: params.column.getId(),
                                        // };
                                        // params.api.startEditingCell(startEditingParams);
                                        handleNewAddressAddition(params.node.data)
                                    }}>
                                        <i className="fa fa-edit" />
                                    </span>}
                                    {customerBillingAddressPermissions?.delete && <span className='cursor-pointer' onClick={() => {
                                         const modalData = {
                                            modalBody: <FormattedMessage
                                                id='customer.address.delete.confirmation'
                                                defaultMessage=''
                                            />,
                                            handleSubmit: () => {
                                                deleteAddress(this.props, this.gridApi.getDisplayedRowAtIndex(params.node.rowIndex).data, this.state.locationType);
                                            }
                                        };
                                        this.props.pushModalToStack(modalData);
                                        
                                    }}>
                                        <i className="fa fa-remove" />
                                    </span>}
                                </div>}
                        </div>
                    </div>
                        : <div></div>
                    }
                </div>
            }
        ]

        const onGridReady = (params) => {
            this.gridApi = params.api;
            params.api.sizeColumnsToFit();
            setTimeout(()=>{
                params.api.resetRowHeights();
            }, 1000)
        }

        // const columns = this.columns.map((col, index) => {
        //     col.key = index;
        //     if (!col.editable) {
        //         return col;
        //     }
        //     let inputType;
        //     if (col.dataIndex === 'defaultAddress') {
        //         Type = 'checkbox';
        //     } else if (col.dataIndex === 'countryName') {
        //         inputType = 'dropdown'
        //     } else {
        //         inputType = 'text';
        //     }

        //     return {
        //         ...col,
        //         onCell: record => {
        //             const cellProps = {
        //                 record,
        //                 inputType,
        //                 dataIndex: col.dataIndex,
        //                 title: col.title,
        //                 editing: this.isEditing(record),
        //             }
        //             if (inputType === 'dropdown') {
        //                 cellProps.items = this.props.countries;
        //                 cellProps.valueKeyName = 'countryName';
        //                 cellProps.optionKeyName = 'countryId';
        //                 cellProps.style = { width: '100px' };
        //                 cellProps.onSelect = (selectedValue, option) => {
        //                     self.props.form.setFieldsValue({ 'countryId': selectedValue });
        //                     self.props.form.setFieldsValue({ 'countryName': ((option || {}).props || {}).name });
        //                 }
        //             }
        //             return cellProps;
        //         },
        //     };
        // });

        this.data = this.props.addresses[this.state.locationType].map((addressObj, index) => {
            return {
                sNo: index + 1,
                version: addressObj.version,
                locationId: addressObj.locationId,
                isDefault: addressObj.isDefault,
                locationName: addressObj.locationName,
                streetAddress1: addressObj.streetAddress1,
                streetAddress2: addressObj.streetAddress2,
                cityName: addressObj.cityName,
                zipCode: addressObj.zipCode,
                countryName: addressObj.countryName,
                countryId: addressObj.countryId,
                stateName: addressObj.stateName
            }
        });

        const onFirstDataRendered = (params) => {
            //params.api.sizeColumnsToFit();
            
        }
       


        return <div>
            {((this.props.addresses[CONSTANTS.BILLING_ADDRESS] && this.props.addresses[CONSTANTS.BILLING_ADDRESS].length > 0) || (this.props.addresses[CONSTANTS.SHIPPING_ADDRESS] && this.props.addresses[CONSTANTS.SHIPPING_ADDRESS].length > 0)) ?
                <div className="show-address-container show-address-popup">
                    <Tabs
                        type='card' onTabClick={(tabIndex) => {
                            const locationType = (Number(tabIndex) === 2 ? CONSTANTS.SHIPPING_ADDRESS: CONSTANTS.BILLING_ADDRESS);
                            this.setState({ ...this.state, locationType, tabIndex: Number(tabIndex) });
                            getAddress(this.props, locationType);
                        }}
                    >
                        <Tabs.TabPane
                            tab='Billing Address'
                            key='1'
                        >
                            <EditableContext.Provider value={this.props.form}>
                                <CustomAgGrid
                                    columnDefs={columnDefs}
                                    rowData={this.data || []}
                                    rowDragManaged={true}
                                    suppressClickEdit={true}
                                    noSingleClickEdit={true}
                                    disableFocusOnClickOutside={true}
                                    domLayout={"autoHeight"}
                                    animateRows={true}
                                    onGridSizeChanged={onFirstDataRendered}
                                    defaultColDef={{
                                        flex: 1,
                                        autoHeight: true,
                                        wrapText: true,
                                    }}
                                    //getRowHeight={getRowHeight}
                                    editType={'fullRow'}
                                    onGridReady={onGridReady}
                                >
                                </CustomAgGrid>
                            </EditableContext.Provider>
                            <div className="mt20">
                                {customerBillingAddressPermissions?.create && <CustomButton intlId='addAddress' defaultMessage='' onClick={() => {
                                    // let newRowIndex = -1;
                                    // this.gridApi.forEachNode((rowNode, index) => {
                                    //     newRowIndex = index;
                                    //     this.gridApi.getDisplayedRowAtIndex(index).data.isToEdit = false;
                                    // });

                                    // this.gridApi.stopEditing();
                                    // this.gridApi.updateRowData({ add: [{ isToEdit: true }] });
                                    // const rowNode = this.gridApi && this.gridApi.getDisplayedRowAtIndex(newRowIndex + 1);
                                    // if (rowNode) { rowNode.data['countryName'] = (this.props.companyInfo || {}).countryName; }
                                    // this.gridApi.startEditingCell({
                                    //     rowIndex: newRowIndex + 1,
                                    //     colKey: "isDefault"
                                    // });
                                    // this.gridApi.refreshCells({ force: true });
                                    handleNewAddressAddition({})
                                }} />}
                            </div>
                        </Tabs.TabPane>
                        <Tabs.TabPane
                            tab='Shipping Address'
                            key='2'
                        >
                            <EditableContext.Provider value={this.props.form}>
                                <CustomAgGrid
                                    columnDefs={columnDefs}
                                    rowData={this.data || []}
                                    rowDragManaged={true}
                                    suppressClickEdit={true}
                                    noSingleClickEdit={true}
                                    disableFocusOnClickOutside={true}
                                    domLayout={"autoHeight"}
                                    animateRows={true}
                                    onGridSizeChanged={onFirstDataRendered}
                                    defaultColDef={{
                                        flex: 1,
                                        autoHeight: true,
                                        wrapText: true,
                                    }}
                                    //getRowHeight={getRowHeight}
                                    editType={'fullRow'}
                                    onGridReady={onGridReady}
                                >
                                </CustomAgGrid>
                            </EditableContext.Provider>
                            <div className="mt20">
                                {customerShippingAddressPermissions?.create && <CustomButton intlId='addAddress' defaultMessage='' onClick={() => {
                                    handleNewAddressAddition({})
                                    // let newRowIndex = -1;
                                    // this.gridApi.forEachNode((rowNode, index) => {
                                    //     newRowIndex = index;
                                    //     this.gridApi.getDisplayedRowAtIndex(index).data.isToEdit = false;
                                    // });

                                    // this.gridApi.stopEditing();
                                    // this.gridApi.updateRowData({ add: [{ isToEdit: true }] });
                                    // const rowNode = this.gridApi && this.gridApi.getDisplayedRowAtIndex(newRowIndex + 1);
                                    // if (rowNode) { rowNode.data['countryName'] = (this.props.companyInfo || {}).countryName; }
                                    // this.gridApi.startEditingCell({
                                    //     rowIndex: newRowIndex + 1,
                                    //     colKey: "isDefault"
                                    // });
                                    // this.gridApi.refreshCells({ force: true });
                                }} />}
                            </div>
                        </Tabs.TabPane>
                    </Tabs>
                </div>
                : <div className="create-data-container">
                    <h4 className="progressbar-modal-heading"> Address does not exist.
                        
                        {
                            customerBillingAddressPermissions?.create ? <div><div className='cursor-pointer' dis onClick={() => {
                                handleNewAddressAddition({})
                           }
                           } >
                               <span> </span> Click here <span> </span>
                           </div>
                           to create an address</div> : ""
                        }
                        
                    </h4>
                    <img src={AddressIcon} alt="Invoice" height="275" />
                </div>
            }
        </div>
    }
}

const getAddress = (props, locationType) => {
    const payload = {
        relationshipId: (props.companyInfo || {}).relationshipId,
        locationType: locationType,
        customerId: props.customerId
    };
    props.fetchAddresses(payload);
}

const deleteAddress = (props, record, locationType) => {
    const payload = {
        locationId: record.locationId,
        relationshipId: props.companyInfo.relationshipId,
        locationType: locationType,
        customerId: props.customerId,
        isDefault: record.defaultAddress === true ? 1 : 0
    };
    props.deleteAddress(payload);
    getAddress(props, locationType);
    //props.resetPaginatedData();
    //props.fetchCustomers({ relationshipId: props.companyInfo.relationshipId, pageNumber: props.pageNumber, pageSize: props.pageSize });
}

const addUpdateAddress = (props, state, record) => {
    record.isDefault = record.isDefault ? 1 : 0;
    const payload = {
        ...record,
        locationType: state.locationType,
        relationshipId: props.companyInfo.relationshipId,
        customerId: props.customerId,
    }
    props.createUpdateAddress(payload);
    getAddress(props, state.locationType);
    //props.resetPaginatedData();
    //props.fetchCustomers({ relationshipId: props.companyInfo.relationshipId, pageNumber: props.pageNumber, pageSize: props.pageSize });

}

const EditableAddressTable = Form.create()(ShowAddress);

export default EditableAddressTable;
