import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import BranchComp from '../../../../components/company/EmployeeMeta/Branch';
import BranchDrawerCon from '../../../drawer/company/EmployeeMeta/Branch';
import { getAllBranches, countTotalBranch, deleteBranch } from './action';
import { hideModal, showModal } from '../../../../actions/commonActions';


class BranchContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
    };
  

  componentDidMount() {
    const payload = {
      relationshipId: (this.props.companyInfo || {}).relationshipId,
    }

    this.props.getAllBranches(payload);
    this.props.countTotalBranch(payload);
   
  }


  render() {
      return <>
        <BranchComp {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
        {this.state.branchDrawer && <BranchDrawerCon {...this.state} {...this.props} updateState={(data) => { this.setState(data) }} />}
      </>
    
    ;
  }

}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    branchList: state.employee.branchList,
    branchCount: state.employee.branchCount,
    permissions: state.auth.permissions,

  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  getAllBranches,
  countTotalBranch,
  deleteBranch,
  showModal,
  hideModal
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(BranchContainer);
