import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Popover, Empty } from 'antd';
import * as moment from "moment"
import { injectIntl, FormattedMessage } from 'react-intl';
import IdleTimer from 'react-idle-timer'
import { headerMenuOptionsData } from './headerContants';
import HeaderMenuOptions from './HeaderMenuOptions';
import SidebarMenu from '../sidebar';
import SidebarMenuNew from '../sidebar/sidebar.js'
import { fetchFromLocalStorage, checkACLPermission } from '../../utils';
import PartialViewRoute from '../../route/partialViewRoute';
import CustomerDrawer from '../../containers/drawer/customer';
import SupplierDrawer from '../../containers/drawer/supplier';
import HierarchyDrawer from '../../containers/drawer/company/CompanyHierarchy';
import EmployeeDrawer from '../../containers/drawer/company/Employee';
import InventoryDrawer from '../../containers/drawer/inventory';
import SalesComplaintDrawer from '../../containers/drawer/salesComplaint';
import { addCategory, addBrand, addManufacturer } from '../../containers/drawer/inventory/action';
import { setLocalStorageData, showModal, hideModal, addUOM, uploadImage, fetchRecentTransactions, pushModalToStack, popModalFromStack, setDefaultSchoolForm } from '../../actions/commonActions';
import './header.scss';
import { LOCAL_STORAGE_KEYS, MODAL_TYPE, CONSTANTS, COMMON_ACTIONS } from '../../static/constants';
import { bindActionCreators } from 'redux';
import ReceivePayment from '../../containers/customer/invoice/PayInvoice';
import PayInvoice from '../../containers/supplier/invoice/PayInvoice';
import NewLedgerComponent from '../../containers/modal/modalBody/finance/LedgerAccount';
import SessionTimeoutComponent from '../../containers/modal/modalBody/common/SessionTimeout';
import { SignoutForm } from './Signout';
// import AlpideLogo from '../../assets/images/alpide-logo.png';
import TermAndFeeDrawer from '../../containers/drawer/membership/termAndFee';
import AddDepartmentDrawer from '../../containers/drawer/schoolManagement/department';
import AddSchoolDrawer from '../../containers/drawer/schoolManagement/school';
import AddSubjectDrawer from '../../containers/drawer/schoolManagement/subject';
import AddClassDrawer from '../../containers/drawer/schoolManagement/class';
import AddSectionDrawer from '../../containers/drawer/schoolManagement/section';
import AddGradeDrawer from '../../containers/drawer/schoolManagement/grade';
import AddAttendenceDrawer from '../../containers/drawer/schoolManagement/attendancce';
import EmployeAttendance from '../../containers/drawer/company/EmployeeAttendance';
import AddInvoiceDrawer from '../../containers/drawer/schoolManagement/invoice';
import LandedCostDrawer from '../../containers/drawer/supplier/landedCostSetup';
import CourseDrawer from '../../containers/drawer/schoolManagement/course';
import CourseTermDrawer from '../../containers/drawer/schoolManagement/courseTerm';
import MembershipOptionDrawer from '../../containers/drawer/membership/membershipOption';
import FundraisingCampaignDrawer from '../../containers/drawer/fundraising/campaign';
import TeamDrawer from '../../containers/drawer/fundraising/campaign/team';
import FundraiserDrawer from '../../containers/drawer/fundraising/campaign/team/fundraiser';
import FundraisingPledgeDrawer from '../../containers/drawer/fundraising/pledges';
import DistributionGroupDrawer from '../../containers/drawer/communication/distribution-group';
// import AlifLogo from '../../assets/images/alifapp-logo.png';
import BrodcastSmsDrawer from '../../containers/drawer/communication/account/brodcast-sms';
import EmailComponent from '../../containers/drawer/email';
import { getAllStudentForm } from '../../containers/Student/studentFormList/action';
// import { ClockCircleOutlined, PlusOutlined, CloseOutlined, BellOutlined, MenuUnfoldOutlined, MenuFoldOutlined } from '@ant-design/icons';
// import PageBreadcrumb from '../PageBreadcrumb';
import { getLogedInUserInfo } from '../../containers/Login/action';
import { withCookies } from 'react-cookie';

import StandardSalesEnquiry from '../../containers/customer/salesEnquiry/StandardSalesEnquiry';
import StandardSalesQuote from '../../containers/customer/salesQuote/StandardSalesQuote';
import StandardSalesOrder from '../../containers/customer/salesOrder/StandardSalesOrder';
import StandardSalesInvoice from '../../containers/customer/invoice/StandardSalesInvoice';
import StandardCreditMemo from '../../containers/customer/creditMemo/StandardCreditMemo';
import StandardPurchaseRequest from '../../containers/supplier/purchaseRequest/StandardPurchaseRequest';
import StandardRFQ from '../../containers/supplier/rfq/StandardRFQ';
import StandardPurchaseOrder from '../../containers/supplier/purchaseOrder/StandardPurchaseOrder';
import StandardPurchaseInvoice from '../../containers/supplier/invoice/StandardPurchaseInvoice';
import StandardDebitMemo from '../../containers/supplier/debitMemo/StandardDebitMemo';
import BusinesExpenseCreate from "../../containers/finance/BusinessExpense/Create/index"
import JournalCreate from '../../containers/finance/Journals/JournalCreate';
import CategoryDrawer from '../../containers/drawer/inventory/CategoryDrawer';
import TimeTableDrawerCont from '../../containers/drawer/schoolManagement/timeTable';
import NewAddressContainer from "../../containers/drawer/Address"
import ContactContainer from "../../containers/drawer/Contact"
import TaxIdentificationContainer from "../../containers/drawer/TaxIdentification"
import CommContainer from "../../containers/drawer/CommContainer"
import UpgradePricingPlan from "../../containers/B2B/PlanPricing";
import { fetchCustomers } from '../../containers/customer/Listing/action.js';
import { NineDots } from '../../assets/images/index.js';
import AlpideProductsDrawer from '../../containers/drawer/alpideProduct'; 
import { AlertMessage } from '../general/AlertMessage.js';

class HeaderComponent extends React.Component {
    constructor(props) {
        super(props);
     
        this.idleTimer = null
        this.onAction = this._onAction.bind(this)
        this.onActive = this._onActive.bind(this)
        this.onIdle = this._onIdle.bind(this)
        this.state = {
            showHeaderMenuOptions: false,
            customer: {
              customerDrawerVisible: false,
              customerData: {},
              isclone: true
            },
            supplier: {
              supplierDrawerVisible: false,
            },
            landedCost:{
              landedCostDrawer:false
            },
            address: {
              addressDrawer: false
            },
            contact: {
              contactDrawer: false
            },
            communication: {
              commDrawer: false
            },
            tax: {
              taxDrawer: false
            },
            inventory: {
                inventoryDrawerVisible: false,
                categoryDrawerVisible  : false,
            },
            company: {
                hierarchyDrawerVisible: false,
                employeeDrawerVisible: false
            },
            salesComplaint: {
                salesComplaintDrawerVisible: false,
              
            },
            membership: {
                addTermAndFeeDrawerVisible: false,
                openTermAndFeeDrawerFromListing: false,
                addMembershipOptionDrawerVisible: false,
                openMembershipOptionDrawerFromListing: false,
            },
            schoolDrawers: {
                addSchoolDrawerVisible: false,
                addSubjectDrawerVisible: false,
                addSectionDrawerVisible: false,
                addGradeDrawerVisible: false,
                addClassDrawerVisible: false,
                addInvoiceDrawerVisible: false,
                addAttendenceDrawerVisible: false,
                addCourseDrawerVisible: false,
                addDepartmentDrawerVisible: false,
                addCourseTermDrawerVisible: false,
                addTimeTableDrawerVisible: false,
            },
            employeeAttendance:
            {
              addEmployeeAttendenceDrawerVisible:false,
            },
            fundraising: {
                addCampaignDrawerVisible: false,
                addTeamDrawerVisible:false,
                addFundraiserDrawerVisible: false,
                openCampaignDrawerFromListing: false,
                addPledgeDrawerVisible: false,
                openPledgeDrawerFromListing: false,
            },
            communication: {
                addDGDrawerVisible: false,
                openDGDrawerFromListing: false,
                addBrodcastSMSDrawerVisible: false,
                brodcastSMSType: 'textSMS'
            },
            alpideProductDrawer: false,
            showUserDropdown: false,
            // isAlifApp: fetchFromLocalStorage(COMMON_ACTIONS.IS_ALIF_APP) ? JSON.parse(fetchFromLocalStorage(COMMON_ACTIONS.IS_ALIF_APP)) : false,
        };

        const loggedInEntityInfo = fetchFromLocalStorage(LOCAL_STORAGE_KEYS.LOGGEDIN_ENTITY_DATA) ? JSON.parse(fetchFromLocalStorage(LOCAL_STORAGE_KEYS.LOGGEDIN_ENTITY_DATA)) : {};
        const isAlifApp = fetchFromLocalStorage(COMMON_ACTIONS.IS_ALIF_APP) ? JSON.parse(fetchFromLocalStorage(COMMON_ACTIONS.IS_ALIF_APP)) : false;

        const payload = {
            companyInfo: loggedInEntityInfo.defaultCompany,
            userInfo: loggedInEntityInfo.userInfo,
            bankInfo: loggedInEntityInfo.bankDetails,
            companyEmpList: loggedInEntityInfo.relationshipEmployeesList,
            isAlifApp: isAlifApp,
            formId: (loggedInEntityInfo.userInfo || {}).formId,
        };
        props.setLocalStorageData(payload);
      }
      
      componentDidMount() {
           
    const { cookies } = this.props;
    const allCookies = cookies.getAll();
        const companyInfo = this.props.companyInfo || {};
        const payload = {
            companyInfo,
            relationshipId: companyInfo.relationshipId,
            pageNumber: 0,
            pageSize: 200
        };
        this.props.fetchRecentTransactions(payload);
        //this.props.getAllStudentForm(payload);

         // const loggedInEntityInfo = fetchFromLocalStorage(LOCAL_STORAGE_KEYS.LOGGEDIN_ENTITY_DATA) ? JSON.parse(fetchFromLocalStorage(LOCAL_STORAGE_KEYS.LOGGEDIN_ENTITY_DATA)) : {};
        // const email = fetchFromLocalStorage(LOCAL_STORAGE_KEYS.EMAIL);
        // const refreshToken = fetchFromLocalStorage(LOCAL_STORAGE_KEYS.REFRESH_TOKEN);
        // const accessToken = fetchFromLocalStorage(LOCAL_STORAGE_KEYS.ACCESS_TOKEN);
        const loggedInEntityInfo = fetchFromLocalStorage(LOCAL_STORAGE_KEYS.LOGGEDIN_ENTITY_DATA) ? JSON.parse(fetchFromLocalStorage(LOCAL_STORAGE_KEYS.LOGGEDIN_ENTITY_DATA)) : {};
        const email = allCookies[LOCAL_STORAGE_KEYS.EMAIL];
        const refreshToken = allCookies[LOCAL_STORAGE_KEYS.REFRESH_TOKEN];
        const accessToken = allCookies[LOCAL_STORAGE_KEYS.ACCESS_TOKEN];
        let isAuthenticated = allCookies[LOCAL_STORAGE_KEYS.ACCESS_TOKEN] ? true : false;

      
        if (email && (!isAuthenticated || !loggedInEntityInfo || !loggedInEntityInfo.defaultCompany)) {
           // this.props.history.replace('/');
            this.props.getLogedInUserInfo({ email: email, refreshToken: refreshToken, accessToken: accessToken, isB2CCustomer: 0 }, this.props.history, this.props);
        }
    }
    // componentWillReceiveProps(props) {
    //     if (props.isAlifApp) {
    //         this.setState({
    //             isAlifApp: props.isAlifApp
    //         });
    //     }
    // }
    componentWillReceiveProps(props) {
        // if (props.studentFormList && props.studentFormList.length && props.userInfo) {
        //     let filterStudentFormList = [];
        //     (props.studentFormList || []).map(formObj => {
        //         let userPermissions = formObj.orgRegFormSettingPermissionList || [];
        //         userPermissions.forEach((permObj) => {
        //             if (permObj.relEmployeeId === props.userInfo.relationshipEmployeeId) {
        //                 filterStudentFormList.push(formObj);
        //             }
        //         })
        //     });
        //     this.setState({
        //         filterStudentFormList
        //     });
        // }
        let labelConfig = props.companyInfo?.labelConfig ? JSON.parse(props.companyInfo.labelConfig) : [];
        let isSchoolUser= false;
        if((props.companyInfo.industryName || '').toLowerCase() === 'education'){
            isSchoolUser= true;
        }
        else{
            isSchoolUser= false;
        }
        this.setState({
            labelConfig: labelConfig,
            isSchoolUser: isSchoolUser,
        })
    }

    _onAction(e) {
    }

    _onActive(e) {

    }

    _onIdle(e) {
        this.props.showModal(
            {
                title: <div className='ant-modal-title'>Need More Time?</div>,
                maskClosable: true,
                modalBody: <SessionTimeoutComponent {...this.props} />,
                hideFooter: true,
                wrapClassName: 'back-blur',
            }
        );
    }

    toggleCollapsed = () => {
        this.setState({
            collapsed: !this.state.collapsed,
        });
    };

    toggleHeaderMenuOptions = () => {
        const { showHeaderMenuOptions } = this.state;
        this.setState({
            showHeaderMenuOptions: !showHeaderMenuOptions
        });
    }

    toggleUserDropdown = () => {
        const { showUserDropdown } = this.state;
        this.setState({
            showUserDropdown: !showUserDropdown
        });
    }

    newPriceListAddition = (props) => {
        const { showModal, companyInfo } = props;
        const formData = {};
        formData.relationshipId = companyInfo.relationshipId;
        const payload = { formData: formData };
        const data = {
            title: <FormattedMessage id='addItem.text.priceList' defaultMessage='' />,
            formData,
            hideFooter: true,
            modalData: {
                modalType: MODAL_TYPE.PRICE_LIST,
                data: payload,

            },
            handleSubmit: (formData = {}) => {
                data.formData.submittedOnce = true;
                showModal(data);
            }
        };
        showModal(data);
    }

    handleNewWarehouseAddition = (props) => {
        const formData = {};
        const { showModal, companyInfo } = props;
        formData.relationshipId = companyInfo.relationshipId;
        const data = {
            title: <FormattedMessage id='addItem.text.warehouse' defaultMessage='' />,
            formData,
            hideFooter: true,
            modalData: {
                modalType: MODAL_TYPE.WAREHOUSE,
                data: { formData: formData },

            },
            handleSubmit: (formData = {}) => {
                data.formData.submittedOnce = true;
                showModal(data);
            }
        };
        showModal(data);
    }

    addItemConfirmationModal = (props, text, modalType) => {
        const { showModal, companyInfo } = props;
        const labelName = this.getModalTitle(modalType);
        const modalBody = <span>
            <FormattedMessage
                id='addItem.confirmation'
                values={{ value: `${text} to ` }}
                defaultMessage=''
            />
            {labelName} ?
        </span>;

        let addFunc;

        switch (modalType) {
            case MODAL_TYPE.PRODUCT_CATEGORY: {
                addFunc = props.addCategory;
                break;
            }
            case MODAL_TYPE.PRODUCT_BRAND: {
                addFunc = props.addBrand;
                break;
            }
            case MODAL_TYPE.PRODUCT_MANUFACTURER: {
                addFunc = props.addManufacturer;
                break;
            }
            default: {
                addFunc = () => { }
            }
        }

        const modalData = {
            modalBody,
            handleSubmit: () => {
                addFunc({ text: text, relationshipId: companyInfo.relationshipId })
                props.hideModal();
            },
        };
        showModal(modalData);
    };

    getModalTitle = (modalType) => {
        let title;
        switch (modalType) {
            case MODAL_TYPE.PRODUCT_CATEGORY: {
                title = <FormattedMessage id='addItem.text.productCategory' defaultMessage='' />;
                break;
            }
            case MODAL_TYPE.PRODUCT_BRAND: {
                title = <FormattedMessage id='addItem.text.productBrand' defaultMessage='' />;
                break;
            }
            case MODAL_TYPE.PRODUCT_MANUFACTURER: {
                title = <FormattedMessage id='addItem.text.productManufacturer' defaultMessage='' />;
                break;
            }
            case MODAL_TYPE.WAREHOUSE: {
                title = <FormattedMessage id='addItem.text.warehouse' defaultMessage='' />;
                break;
            }
            case MODAL_TYPE.UOM: {
                title = <FormattedMessage id='addItem.text.uom' defaultMessage='' />;
                break;
            }
            default:
                title = <FormattedMessage id='signup.confirmation.title' defaultMessage='' />;
        }
        return title;
    };

    handleNewItemAddition = (props, modalType) => {
        const formData = { textBoxValue: '' };
        const { showModal } = props;
        const data = {
            title: this.getModalTitle(modalType),
            formData,
            modalData: { modalType, data: { formData: formData } },
            handleSubmit: (formData = {}) => {
                if (formData.textBoxValue && formData.textBoxValue.length) {
                    this.addItemConfirmationModal(props, formData.textBoxValue, modalType);
                } else {
                    data.formData.submittedOnce = true;
                    showModal(data);
                }
            }
        };
        showModal(data);
    };

   onCancel = ()=>{
    const payload = {relationshipId : this.props.companyInfo?.relationshipId,...this.props}
    this.props.showModal({
      modalBody: <FormattedMessage id="modal.cancel.confirmation" defaultMessage=""/>,
      footer:{submit:{intlId:"button.close.label"}},
      handleSubmit: () => {
        this.props.popModalFromStack();
        this.props.fetchCustomers(payload)
      }
    })
   }

    getSelectedHeaderMenuOption = (selectedOption, props) => {
        switch (selectedOption.name) {
            case 'customer':
                this.setState({
                    customer: {
                        customerDrawerVisible: !this.state.customer.customerDrawerVisible,
                    }
                });
                break;


            case 'supplier':
                this.setState({
                    supplier: {
                        supplierDrawerVisible: !this.state.supplier.supplierDrawerVisible
                    }
                });
                break;
                case 'landedCost':
                this.setState({
                    supplier: {
                      landedCostDrawer: !this.state.supplier.landedCostDrawer
                    }
                });
                break;
            case 'inventory':
                this.setState({
                    inventory: {
                        inventoryDrawerVisible: !this.state.inventory.inventoryDrawerVisible
                    }
                });
                break;
            case 'receivePayment': {
                let modalData1 = {
                    title: <div className='title'>Sales Payment</div>,
                    modalBody: <ReceivePayment {...this.props} invoiceData={{}} />,
                    wrapClassName: 'pay-invoice-modal',
                    width: 500,
                    hideFooter: true,
                    keyboard: false,
                    onCancel: () => {
                      const payload = {relationshipId : this.props.companyInfo?.relationshipId,...this.props}
                      this.props.pushModalToStack({
                        modalBody: <FormattedMessage id="modal.cancel.confirmation" defaultMessage=""/>,
                        footer:{submit:{intlId:"button.close.label"}},
                        handleSubmit: () => {
                          this.props.hideModal();
                          this.props.fetchCustomers(payload)

                        }
                      })
                }
              }
                this.props.showModal(modalData1);
            }
                break;
            case 'payInvoice': {
                let modalData2 = {
                    title: <div className='ant-modal-title'>Purchase Payment</div>,
                    modalBody: <PayInvoice {...this.props} invoiceData={{}} />,
                    width: 600,
                    hideFooter: true,
                    keyboard: false,
                    wrapClassName: 'supplier-payment-modal',
                    onCancel: () => {
                      const payload = {relationshipId : this.props.companyInfo?.relationshipId,...this.props}
                      this.props.pushModalToStack({
                        modalBody: <FormattedMessage id="modal.cancel.confirmation" defaultMessage=""/>,
                        footer:{submit:{intlId:"button.close.label"}},
                        handleSubmit: () => {
                          this.props.hideModal();
                          this.props.fetchCustomers(payload)

                        }
                      })
                }
                };
                this.props.showModal(modalData2);
            }
                break;
            case 'ledgerAccount': {
                let modalData3 = {
                    title: <div className='ant-modal-title'>New Chart of Account</div>,
                    modalBody: <NewLedgerComponent {...this.props} />,
                    width: 1100,
                    hideFooter: true
                };
                this.props.showModal(modalData3);
                break;
            }
            case 'tax': {
                const { showModal, companyInfo } = this.props;
                const formData = {};
                formData.relationshipId = companyInfo.relationshipId;
                const data = {
                    title: <FormattedMessage id='addItem.text.newTax' defaultMessage='' />,
                    formData,
                    hideFooter: true,
                    modalData: {
                        modalType: MODAL_TYPE.NEW_TAX,
                        data: { formData: formData },

                    },
                    handleSubmit: (formData = {}) => {
                        data.formData.submittedOnce = true;
                        showModal(data);
                    }
                };
                showModal(data);
            }
                break;
            case 'category': {
                this.handleNewItemAddition(this.props, MODAL_TYPE.PRODUCT_CATEGORY);
                break;
            }
            case 'uom': {
                this.handleNewItemAddition(this.props, MODAL_TYPE.UOM);
                break;
            }
            case 'brands': {
                this.handleNewItemAddition(this.props, MODAL_TYPE.PRODUCT_BRAND);
                break;
            }
            case 'manufacturer': {
                this.handleNewItemAddition(this.props, MODAL_TYPE.PRODUCT_MANUFACTURER);
                break;
            }
            case 'warehouse': {
                this.handleNewWarehouseAddition(this.props);
                break;
            }
            case 'priceList': {
                this.newPriceListAddition(this.props);
                break;
            }
          
            case 'salesInquiry': {
              this.props.pushModalToStack({
                modalBody: <StandardSalesEnquiry {...this.props} updateHeaderState={(data) => {
                  this.setState(data);
                }} />,
                width: '100%',
                hideTitle: true,
                hideFooter: true,
                keyboard: false,
                wrapClassName: 'new-transaction-wrapper',
                // onCancel:() => {
                //   this.props.popModalFromStack();
                //   this.props.fetchCustomers(this.props);
                  
                // }
                onCancel:this.onCancel
              });
              break;
            }
            case 'salesQuote': {
              this.props.pushModalToStack({
                modalBody: <StandardSalesQuote  {...this.props} updateHeaderState={(data) => {
                  this.setState(data);
                }} />,
                width: '100%',
                hideTitle: true,
                hideFooter: true,
                keyboard: false,
                wrapClassName: 'new-transaction-wrapper',
                onCancel:this.onCancel
              });
              break;
            }
            case 'salesOrder': {
              this.props.pushModalToStack({
                modalBody: <StandardSalesOrder {...this.props}  updateHeaderState={(data) => {
                  this.setState(data);
                }} />,
                width: '100%',
                hideTitle: true,
                hideFooter: true,
                keyboard: false,
                wrapClassName: 'new-transaction-wrapper',
                onCancel:this.onCancel
              });
              break;
            }
            case 'salesInvoice': {
              this.props.pushModalToStack({
                modalBody: <StandardSalesInvoice {...this.props}  updateHeaderState={(data) => {
                  this.setState(data);
                }} />,
                width: '100%',
                hideTitle: true,
                hideFooter: true,
                keyboard: false,
                wrapClassName: 'new-transaction-wrapper',
                onCancel:this.onCancel
              });
              break;
            }
            case 'creditMemo': {
              this.props.pushModalToStack({
                modalBody: <StandardCreditMemo {...this.props}  updateHeaderState={(data) => {
                  this.setState(data);
                }} />,
                width: '100%',
                hideTitle: true,
                hideFooter: true,
                keyboard: false,
                wrapClassName: 'new-transaction-wrapper',
                onCancel:this.onCancel
              });
              break;
            }
            case 'purchaseRequest': {
              this.props.pushModalToStack({
                modalBody: <StandardPurchaseRequest {...this.props}  updateHeaderState={(data) => {
                  this.setState(data);
                }} />,
                width: '100%',
                hideTitle: true,
                hideFooter: true,
                keyboard: false,
                wrapClassName: 'new-transaction-wrapper',
                onCancel:this.onCancel
              });
              break;
            }
            case 'rfq': {
              this.props.pushModalToStack({
                modalBody: <StandardRFQ {...this.props}  updateHeaderState={(data) => {
                  this.setState(data);
                }} />,
                width: '100%',
                hideTitle: true,
                hideFooter: true,
                keyboard: false,
                wrapClassName: 'new-transaction-wrapper',
                onCancel:this.onCancel
              });
              break;
            }
            case 'purchaseOrder': {
              this.props.pushModalToStack({
                modalBody: <StandardPurchaseOrder {...this.props}  updateHeaderState={(data) => {
                  this.setState(data);
                }} />,
                width: '100%',
                hideTitle: true,
                hideFooter: true,
                keyboard: false,
                wrapClassName: 'new-transaction-wrapper',
                onCancel:this.onCancel
              });
              break;
            }
            case 'purchaseInvoice': {
              this.props.pushModalToStack({
                modalBody: <StandardPurchaseInvoice {...this.props}  updateHeaderState={(data) => {
                  this.setState(data);
                }} />,
                width: '100%',
                hideTitle: true,
                hideFooter: true,
                keyboard: false,
                wrapClassName: 'new-transaction-wrapper',
                onCancel:this.onCancel
              });
              break;
            }
            case 'debitMemo': {
              this.props.pushModalToStack({
                modalBody: <StandardDebitMemo {...this.props}  updateHeaderState={(data) => {
                  this.setState(data);
                }} />,
                width: '100%',
                hideTitle: true,
                hideFooter: true,
                keyboard: false,
                wrapClassName: 'new-transaction-wrapper',
                onCancel:this.onCancel
              });
              break;
            }
            case 'businessExpense': {
              this.props.pushModalToStack({
                modalBody: <BusinesExpenseCreate {...this.props}  updateHeaderState={(data) => {
                  this.setState(data);
                }} />,
                width: '100%',
                hideTitle: true,
                hideFooter: true,
                keyboard: false,
                wrapClassName: 'new-transaction-wrapper'
              });
              break;
            }
            case 'journal': {
              this.props.pushModalToStack({
                modalBody: <JournalCreate {...this.props}  updateHeaderState={(data) => {
                  this.setState(data);
                }} />,
                width: '100%',
                hideTitle: true,
                hideFooter: true,
                wrapClassName: 'new-transaction-wrapper'
              });
              break;
            }
            default: {
                break;
            }
        }
    }
    openPricing = (alpideProductId) => {
      this.props.pushModalToStack({
        modalBody: <UpgradePricingPlan {...this.props} isUpgrade={true} alpideProductId={typeof alpideProductId === 'number' ?  alpideProductId : 0} />,
        width: '100%',
        hideTitle: true,
        hideFooter: true,
        keyboard:false,
        wrapClassName: 'full_modal',

        onCancel: () => {
          this.props.showModal({
            modalBody: <FormattedMessage id="modal.cancel.confirmation" defaultMessage=""/>,
            footer:{submit:{intlId:"button.close.label"}},
            handleSubmit: () => {
            
              this.props.popModalFromStack();
              this.state.alpideProductDrawer=false
            }
          })
        }
      })
    
  }
    render() {
        const { showHeaderMenuOptions, collapsed } = this.state;
        const primaryPermissions = (this.props.permissions || {}).primary || [];
        const filteredMenuData = filterOnBasisOfPermission(headerMenuOptionsData, primaryPermissions) || [];
        const RecentTransactionContent = (props) => {
            return <div className="recent-tx-content">
                {
                    props.recentTxList?.length ? props.recentTxList.map((data, i) => {
                        return <Row key={i}>
                            <Col span={10}>{data.documentName || `${data.txType}(${data.txNumber})`} </Col>
                            <Col span={7}><span>
                                {(props.companyInfo || {}).currencyIcon ? <i className={(props.companyInfo || {}).currencyIcon} /> : (props.companyInfo || {}).currencyCode + " "}
                            </span> {(data.txAmount || 0).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} </Col>
                            <Col span={7}>{moment(data.txDate).format(CONSTANTS.DISPLAY_DATE_FORMAT)} </Col>
                        </Row>
                    })
                    :
                    <Empty />
                }
            </div>
        }

        return (
          <>
           <AlertMessage  {...this.props}/>
            <IdleTimer
              ref={(ref) => {
                this.idleTimer = ref;
              }}
              element={document}
              onActive={this.onActive}
              onIdle={this.onIdle}
              onAction={this.onAction}
              debounce={250}
              // timeout={5000} />
              timeout={10000 * 60 * 7.5}
            />

            {this.state.emailDrawerVisible && (
              <EmailComponent
                {...this.state}
                {...this.props}
                updateState={(data) => {
                  this.setState(data);
                }}
              />
            )}
            {this.state.customer.customerDrawerVisible && (
              <CustomerDrawer
                {...this.state.customer}
                updateState={(data) => {
                  this.setState(data);
                }}
              />
            )}
            {this.state.supplier.supplierDrawerVisible && (
              <SupplierDrawer
                {...this.state.supplier}
                updateState={(data) => {
                  this.setState(data);
                }}
              />
            )}
            {this.state.landedCost.landedCostDrawer && (
              <LandedCostDrawer
                {...this.state.landedCost}
                updateState={(data) => {
                  this.setState(data);
                }}
              />
            )}
            {this.state.inventory.inventoryDrawerVisible && (
              <InventoryDrawer
                {...this.state.inventory}
                updateState={(data) => {
                  this.setState(data);
                }}
                updateHeaderState={(data) => {
                  this.setState(data);
                }}
              />
            )}
            {this.state.company.hierarchyDrawerVisible && (
              <HierarchyDrawer
                {...this.state.company}
                updateState={(data) => {
                  this.setState(data);
                }}
              />
            )}
            {this.state.company.employeeDrawerVisible && (
              <EmployeeDrawer
                {...this.state.company}
                updateState={(data) => {
                  this.setState(data);
                }}
              />
            )}
            {this.state.address.addressDrawer && (
              <NewAddressContainer
                {...this.state.address}
                updateState={(data) => {
                  this.setState(data);
                }}
              />
            )}

            {this.state.contact.contactDrawer && (
              <ContactContainer
                {...this.state.contact}
                updateState={(data) => {
                  this.setState(data);
                }}
              />
            )}

          {/* {this.state.communication.commDrawer && (
              <CommContainer
                {...this.state.communication}
                updateState={(data) => {
                  this.setState(data);
                }}
              />
            )} */}
             {this.state.tax.taxDrawer && (
              <TaxIdentificationContainer
                {...this.state.tax}
                updateState={(data) => {
                  this.setState(data);
                }}
              />
            )}
            {this.state.salesComplaint.salesComplaintDrawerVisible && (
              <SalesComplaintDrawer
                {...this.state.salesComplaint}
                updateState={(data) => {
                  this.setState(data);
                }}
                updateHeaderState={(data) => {
                  this.setState(data);
                }}
              />
            )}
            {this.state.membership.addTermAndFeeDrawerVisible && (
              <TermAndFeeDrawer
                {...this.state.membership}
                updateState={(data) => {
                  this.setState(data);
                }}
                updateHeaderState={(data) => {
                  this.setState(data);
                }}
              />
            )}
            {this.state.schoolDrawers.addSchoolDrawerVisible && (
              <AddSchoolDrawer
                {...this.state}
                labelConfig= {this.state.labelConfig}
                updateState={(data) => {
                  this.setState(data);
                }}
                updateHeaderState={(data) => {
                  this.setState(data);
                }}
              />
            )}
            {this.state.schoolDrawers.addSubjectDrawerVisible && (
              <AddSubjectDrawer
                {...this.state}
                labelConfig= {this.state.labelConfig}
                updateState={(data) => {
                  this.setState(data);
                }}
                updateHeaderState={(data) => {
                  this.setState(data);
                }}
              />
            )}
            {this.state.schoolDrawers.addCourseTermDrawerVisible && (
              <CourseTermDrawer
                {...this.state}
                labelConfig= {this.state.labelConfig}
                updateState={(data) => {
                  this.setState(data);
                }}
                updateHeaderState={(data) => {
                  this.setState(data);
                }}
              />
            )}
            {this.state.schoolDrawers.addSectionDrawerVisible && (
              <AddSectionDrawer
                {...this.state}
                labelConfig= {this.state.labelConfig}
                updateState={(data) => {
                  this.setState(data);
                }}
                updateHeaderState={(data) => {
                  this.setState(data);
                }}
              />
            )}

            {this.state.inventory.categoryDrawerVisible && (
              <CategoryDrawer
                {...this.state.inventory}
                labelConfig= {this.state.labelConfig}
                updateState={(data) => {
                  this.setState(data);
                }}
                updateHeaderState={(data) => {
                  this.setState(data);
                }}
              />
            )}

            {this.state.schoolDrawers.addGradeDrawerVisible && (
              <AddGradeDrawer
                {...this.state}
                labelConfig= {this.state.labelConfig}
                updateState={(data) => {
                  this.setState(data);
                }}
                updateHeaderState={(data) => {
                  this.setState(data);
                }}
              />
            )}
            {this.state.schoolDrawers.addClassDrawerVisible && (
              <AddClassDrawer
                {...this.state}
                labelConfig= {this.state.labelConfig}
                updateState={(data) => {
                  this.setState(data);
                }}
                updateHeaderState={(data) => {
                  this.setState(data);
                }}
              />
            )}
            {this.state.schoolDrawers.addAttendenceDrawerVisible && (
              <AddAttendenceDrawer
                {...this.state}
                labelConfig= {this.state.labelConfig}
                updateState={(data) => {
                  this.setState(data);
                }}
                updateHeaderState={(data) => {
                  this.setState(data);
                }}
              />
            )}
            {this.state.employeeAttendance.addEmployeeAttendenceDrawerVisible && (
              <EmployeAttendance
                {...this.state.employeeAttendance}
                
                updateState={(data) => {
                  this.setState(data);
                }}
                updateHeaderState={(data) => {
                  this.setState(data);
                }}
              />
            )}
            {this.state.schoolDrawers.addDepartmentDrawerVisible && (
              <AddDepartmentDrawer
                {...this.state}
                labelConfig= {this.state.labelConfig}
                updateState={(data) => {
                  this.setState(data);
                }}
                updateHeaderState={(data) => {
                  this.setState(data);
                }}
              />
            )}
            {this.state.schoolDrawers.addCourseDrawerVisible && (
              <CourseDrawer
                {...this.state}
                labelConfig= {this.state.labelConfig}
                updateState={(data) => {
                  this.setState(data);
                }}
                updateHeaderState={(data) => {
                  this.setState(data);
                }}
              />
            )}
            {this.state.schoolDrawers.addInvoiceDrawerVisible && (
              <AddInvoiceDrawer
                {...this.state}
                labelConfig= {this.state.labelConfig}
                updateState={(data) => {
                  this.setState(data);
                }}
                updateHeaderState={(data) => {
                  this.setState(data);
                }}
              />
            )}
            {this.state.schoolDrawers.addTimeTableDrawerVisible && (
              <TimeTableDrawerCont
                {...this.state}
                labelConfig= {this.state.labelConfig}
                updateState={(data) => {
                  this.setState(data);
                }}
                updateHeaderState={(data) => {
                  this.setState(data);
                }}
              />
            )}
            
            {this.state.membership.addMembershipOptionDrawerVisible && (
              <MembershipOptionDrawer
                {...this.state.membership}
                updateState={(data) => {
                  this.setState(data);
                }}
                updateHeaderState={(data) => {
                  this.setState(data);
                }}
              />
            )}
            {this.state.fundraising.addCampaignDrawerVisible && (
              <FundraisingCampaignDrawer
                {...this.state.fundraising}
                updateState={(data) => {
                  this.setState(data);
                }}
                updateHeaderState={(data) => {
                  this.setState(data);
                }}
              />
            )}
            {this.state.fundraising.addTeamDrawerVisible && (
              <TeamDrawer
                {...this.state.fundraising}
                updateState={(data) => {
                  this.setState(data);
                }}
                updateHeaderState={(data) => {
                  this.setState(data);
                }}
              />
            )}
            {this.state.fundraising.addFundraiserDrawerVisible && (
              <FundraiserDrawer
                {...this.state.fundraising}
                updateState={(data) => {
                  this.setState(data);
                }}
                updateHeaderState={(data) => {
                  this.setState(data);
                }}
              />
            )}
            {this.state.fundraising.addPledgeDrawerVisible && (
              <FundraisingPledgeDrawer
                {...this.state.fundraising}
                updateState={(data) => {
                  this.setState(data);
                }}
                updateHeaderState={(data) => {
                  this.setState(data);
                }}
              />
            )}
            {this.state.communication.addDGDrawerVisible && (
              <DistributionGroupDrawer
                {...this.state.communication}
                updateState={(data) => {
                  this.setState(data);
                }}
                updateHeaderState={(data) => {
                  this.setState(data);
                }}
              />
            )}
            {this.state.communication.addBrodcastSMSDrawerVisible && (
              <BrodcastSmsDrawer
                {...this.state.communication}
                updateState={(data) => {
                  this.setState(data);
                }}
                updateHeaderState={(data) => {
                  this.setState(data);
                }}
              />
            )}

            {this.state.alpideProductDrawer && (
              <AlpideProductsDrawer
                {...this.state}
                {...this.props}
                updateHeaderState={(data) => {
                  this.setState(data);
                }}
                openPricingPlan={(alpideProductId)=>{
                  this.openPricing(alpideProductId)
                }}
              />
            )} 

            {/* <div className={`header-wrapper ${collapsed ? 'expanded-header':'normal-header'}`}> */}
            
            <div className={`header-wrapper expanded-header`}>
           
              <div className="header-left-section">
                {/* <div className='toggle-menu' onClick={this.toggleCollapsed}>
                            {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                        </div> */}

                {/* <ul className='header-left-functions'>
                            <li onClick={this.toggleCollapsed}
                                span={2}
                                className='header-option menu-option-wrapper'>
                                <div className="menu-icon">
                                    <div className="bar line-1" />
                                    <div className="bar line-2" />
                                    <div className="bar line-3" />
                                </div>

                                <Icon
                                   type={this.state.collapsed ? 'menu-unfold' : 'menu-fold'}
                                />

                            </li>
                         
                            <li>
                                {this.state.collapsed && this.props.companyInfo && this.props.companyInfo.storeLogo ? <span> {(this.props.companyInfo || {}).storeLogo} </span> : ''}
                                {this.state.collapsed && this.props.companyInfo && this.props.companyInfo.storeName ? <span className='collapsed-header-txt'> {(this.props.companyInfo || {}).storeName} </span> : ''}
                                {this.state.collapsed && this.props.companyInfo && !this.props.companyInfo.storeName ? (window.location.host === 'app.alifapp.com' ?
                                    <img src={AlifLogo} alt="" style={{ width: "100px" }} />
                                    :
                                    <img src={AlpideLogo} alt="" style={{ width: "100px" }} />
                                    ):''
                                }
                                
                            </li>
                        </ul> */}
                {/* <div className='page-header-txt'>{this.state.pageHeading ? this.state.pageHeading : ''}</div>
                        {this.state.breadCrumbList && this.state.breadCrumbList.length ? <div className='left-header-sep' /> : ''}
                        <span className='header-breadcrumb'>
                            <PageBreadcrumb breadCrumbList={this.state.breadCrumbList ? this.state.breadCrumbList : []} />
                        </span> */}

                {Array.isArray(filteredMenuData) &&
                filteredMenuData.length ? (
                  <button
                    onClick={this.toggleHeaderMenuOptions}
                    className={!showHeaderMenuOptions ? "active" : ""}
                  >
                    <>
                      <i className="fi fi-br-bars-sort"></i>Menu
                    </>
                    {showHeaderMenuOptions && (
                      <HeaderMenuOptions
                        updateState={(data) => {
                          this.setState(data);
                        }}
                        data={filteredMenuData}
                        getSelectedOption={this.getSelectedHeaderMenuOption}
                      />
                    )}
                  </button>
                ) : (
                  <Fragment />
                )} 
              </div>

              <ul className="header-right-section">
                {/* {
                            (Array.isArray(filteredMenuData) && filteredMenuData.length && !this.state.isAlifApp)
                                ? (
                                    <li
                                        span={2}
                                        onClick={this.toggleHeaderMenuOptions}
                                        className='header-option header-icon menu-option-wrapper'
                                    >
                                        <i className={`fa ${showHeaderMenuOptions ? 'fa-times' : 'fa-plus'}`} />
                                        {showHeaderMenuOptions ? <CloseOutlined /> : <PlusOutlined /> }
                                        {
                                            showHeaderMenuOptions && (
                                                <HeaderMenuOptions
                                                    updateState={(data) => { this.setState(data) }}
                                                    data={filteredMenuData}
                                                    getSelectedOption={this.getSelectedHeaderMenuOption}
                                                />
                                            )
                                        }
                                    </li>
                                )
                                : <Fragment />
                        } */}
             
                <li className="header-item" key="header-item">
                  <Popover
                    overlayClassName="notification-popup"
                    placement="bottomRight"
                    title={
                      <div style={{ textAlign: "left" }}>
                        Recent Transactions
                      </div>
                    } 
                    content={<RecentTransactionContent {...this.props} />}
                    trigger="click"
                  >
                    <i className="fi fi-rr-receipt"></i>
                  </Popover>
                </li>

                {/* <li onClick={this.openPricing} className='user-upgrade'>
                  <div className="grothTrial">Growth-Trial</div>
                  <div className="trialUpgrade">
                    <i className="fi fi-rr-bolt" /> Upgrade
                  </div>
                </li> */}

                <li className={"user-profile"} key={"user-profile"}>
                  <SignoutForm
                    {...this.props}
                    {...this.state}
                    updateState={(data) => {
                      this.setState(data);
                    }}
                  />
                </li>
                
                <li className='mr10' key={'9-dots'}>
                  <img 
                    src={NineDots} 
                    alt='::' 
                    height='28px' 
                    width='28px' 
                    className='pointer'
                    onClick={()=>{
                      this.setState({alpideProductDrawer: true})
                    }}
                 />
                </li> 
              </ul>
            </div>
            <div className="user-view-container">
              {/* <SidebarMenu {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} isCollapsed={collapsed} /> */}
           
              {this.props.permissions && this.props.permissions.primary ?
                <SidebarMenuNew
                  {...this.props}
                  {...this.state}
                  updateState={(data) => {
                    this.setState(data);
                  }}
                  // isCollapsed={collapsed}
                /> : ''
              }

              <PartialViewRoute
                {...this.state}
                permissions={this.props.permissions}
                history={this.props.history}
                updateHeaderState={(data) => {
                  this.setState(data);
                }}
                isCollapsed={collapsed}
              />
            </div>
          </>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        companyInfo: state.common.companyInfo,
        userInfo: state.common.userInfo,
        userbankDetails: state.common.bankInfo,
        companyEmpList: state.common.companyEmpList,
        recentTxList: state.common.recentTxList,
        permissions: state.auth.permissions,
        isAlifApp: state.common.isAlifApp,
        defaultSchoolForm: state.common.defaultSchoolForm,
        studentFormList: state.membershipReducer.studentFormList,
    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    showModal,
    hideModal,
    pushModalToStack,
    popModalFromStack,
    setLocalStorageData,
    addCategory,
    addBrand,
    uploadImage,
    addManufacturer,
    addUOM,
    fetchRecentTransactions,
    getAllStudentForm,
    setDefaultSchoolForm,
    getLogedInUserInfo,
    fetchCustomers
}, dispatch);

const filterOnBasisOfPermission = (data, primaryPermissions) => {
    let result;
    if(!primaryPermissions || Object.keys(primaryPermissions).length < 1 ){return result;}
    const clonedData = [...data];
    if (Array.isArray(Object.keys(data)) && data.length) {
        result = []
        data.forEach((obj, index) => {
            if (Array.isArray(obj.hierarchy) && obj.hierarchy.length) {
                clonedData[index].hierarchy = obj.hierarchy.filter(x => {
                    return checkACLPermission(primaryPermissions, x.moduleCode, x.operation);
                });
                if (clonedData[index].hierarchy.length) {
                    result.push(clonedData[index]);
                }
            }
        });
    } else {
        result = data;
    }
    return result;
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(withCookies(HeaderComponent)));
