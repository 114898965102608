//import React, {useRef, useEffect,  useState} from 'react';
import React from 'react';
// import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button } from 'antd';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Dropdown } from '../../../../general/Dropdown';
import { CONSTANTS, MODULE_CODED_VALUES } from '../../../../../static/constants';
import { showToasterMessage } from '../../../../../utils';
import * as find from 'lodash.find';
import LoadingIcon from "../../../../../assets/images/loading.gif"


const CustomerHeaderAction = (props) => {
   // const [templateDropdown, updateTemplateDropdown] = useState(false);
    const {
        intl,
        customers,
        pageNumber,
        customerName,
        updateState,
    } = props;


    // const useOuterClick = (callback) => {
    //     const innerRef = useRef();
    //     const callbackRef = useRef();

    //     // set current callback in ref, before second useEffect uses it
    //     useEffect(() => { // useEffect wrapper to be safe for concurrent mode
    //         callbackRef.current = callback;
    //     });

    //     useEffect(() => {
    //         document.addEventListener("click", handleClick);
    //         return () => document.removeEventListener("click", handleClick);

    //         // read most recent callback and innerRef dom node from refs
    //         function handleClick(e) {
    //             if (
    //                 innerRef.current &&
    //                 callbackRef.current &&
    //                 !innerRef.current.contains(e.target)
    //             ) {
    //                 callbackRef.current(e);
    //             }
    //         }
    //     }, []); // no need for callback + innerRef dep

    //     return innerRef; // return ref; client can omit `useRef`
    // }

    // const innerRef = useOuterClick(e => {
    //    updateTemplateDropdown(!templateDropdown)
    // });

    // const handleNewPriceListAddition = (props, payload) => {
    //     const formData = payload.formData;
    //     formData.relationshipId = companyInfo.relationshipId;
    //     const data = {
    //         title: <FormattedMessage id='addItem.text.priceList' defaultMessage='' />,
    //         formData,
    //         hideFooter: true,
    //         modalData: {
    //             modalType: MODAL_TYPE.PRICE_LIST,
    //             data: payload,

    //         },
    //         handleSubmit: (formData = {}) => {
    //             data.formData.submittedOnce = true;
    //             showModal(data);
    //         }
    //     };
    //     showModal(data);
    // }

    // const deleteClickHandler = (props, modalType, payload) => {
    //     const modalBody =
    //         <FormattedMessage
    //             id='deleteItem.confirmation'
    //             defaultMessage=''
    //             values={{ value: payload.text }}
    //         />;

    //     let deleteFunc;

    //     switch (modalType) {
    //         case MODAL_TYPE.PRICE_LIST: {
    //             deleteFunc = props.deletePriceList;
    //             break;
    //         }
    //         default: {
    //             deleteFunc = () => { }
    //         }
    //     }
    //     const modalData = {
    //         modalBody,
    //         handleSubmit: () => { deleteFunc({ id: payload.id, relationshipId: companyInfo.relationshipId }) },
    //     };
    //     showModal(modalData);
    // }

    return (
        <>
         <div className='left-itms'>
      <ul>
        <li className='title'>
          Student Invoice
        </li>
        <li className='sep' />
        <li>
          <FormattedMessage id='customer.salesInvoice.form.invoiceNumber.label' defaultMessage='' /> &nbsp; - &nbsp;
          {props.nextTxNumber ?
            <span>{getFormatedNextTxNumber(props)}</span>
            //<span>{this.props.nextTxNumber.replace('SO-00', 'SO-'+(this.props.customer || {}).customerId) )}</span>
            :
            <img src={LoadingIcon} alt="Invoice" style={{ 'height': '50px' }} />
          }
        </li>
      </ul>
     </div>
        <div className="right-itms">
            {/* {(!props.isSoConversionToInvoice && !props.isbankReco) && <Checkbox onChange={(e) => {
                props.updateState({
                    isCashSales: e.target.checked
                });
                
            }} checked= {props.isCashSales} />} */}
      
                {/* {(!props.isSoConversionToInvoice && !props.isbankReco) &&
                    <span className="pr10">
                        <FormattedMessage id='salesInvoice.cashSale.checkbox.text' defaultMessage='' />
                    </span>
                }
                {!props.isSoConversionToInvoice && <DropdownPriceList
                    items={priceList}
                    valueKeyName='priceListName'
                    optionKeyName='priceListId'
                    value={priceListId}
                    canAddNew={true}
                    canDelete={true}
                    deleteClickHandler={(payload) => {
                        deleteClickHandler(props, MODAL_TYPE.PRICE_LIST, payload);
                    }}
                    onAddNewClick={(payload) => {
                        payload.formData = {
                            priceListName: payload.textEntered,
                            submittedOnce: false
                        }
                        handleNewPriceListAddition(props, payload, MODAL_TYPE.PRICE_LIST)
                    }}
                    placeholder={intl.formatMessage({
                        id: 'customer.salesInvoice.form.priceList.placeholder', defaultMessage: ''
                    })}
                    onSelect={
                        (priceListId) => {
                          const priceListData = find(priceList, {priceListId }) || {};
                          updateState({ toUpdatePriceList: true, priceListId, priceListName: priceListData.priceListName, priceListType: priceListData.type, priceListPercentage: priceListData.percentage });
                        }
                      }
                      allowClear={true}
                      onClear={() => {
                        updateState({toUpdatePriceList: true, priceListId: undefined, priceListName: undefined, priceListType: undefined, priceListPercentage: undefined})
                      }}
                />}

                {!props.isSoConversionToInvoice && <Dropdown
                    items={AG_GRID_CONSTANTS.STANDARD_SALES_INVOICE_FIELD.PRICE_TYPE_LIST}
                    value={priceTypeToApply || AG_GRID_CONSTANTS.STANDARD_SALES_INVOICE_FIELD.PRICE_TYPE_LIST[0]}
                    onSelect={(priceTypeToApply) => {
                        updateState({
                            priceTypeToApply,
                            isToPriceTypeToApply: true
                        })
                    }}
                    placeholder={intl.formatMessage({
                        id: 'customer.salesInvoice.form.priceType.placeholder', defaultMessage: ''
                    })}
                    allowClear={true}
                    onClear={() => {
                        updateState({
                            priceTypeToApply: undefined,
                            isToPriceTypeToApply: false,
                        })
                    }}
                />} */}
               

                  <Dropdown
                    items={customers[pageNumber] || []}
                    style={{width: '200px'}}
                    valueKeyName='companyName'
                    optionKeyName='customerId'
                    fatherEmailKey='fatherEmail'
                    motherEmailKey='motherEmail'
                    value={customerName}
                    disabled={props.invoiceForFinancialAssitance}
                    onSearch= {(searchedText)=>{
                        props.fetchCustomers({
                            isCompact: true,
                            searchedText: searchedText,
                            pageNumber: 1,
                            hideSpinner: true,
                            pageSize: 100,
                            relationshipId: (props.companyInfo || {}).relationshipId
                        })
                    }}
                    placeholder={intl.formatMessage({
                        id: 'selectFatherName.text', defaultMessage: ''
                    })}
                    moduleName={MODULE_CODED_VALUES.CUSTOMERS}
                    canAddNew={true}
                    canDelete={true}
                    deleteClickHandler={(payload) => {
                        props.deleteCustomers({ groupCustomerIds: [payload.id], relationshipId: (props.companyInfo || {}).relationshipId })
                    }}
                    onAddNewClick={(payload) => {
                        props.updateHeaderState({
                            customer: {
                                customerDrawerVisible: true,
                                customerName: payload.textEntered,
                            }
                        })
                    }}
                    onSelect={
                        (customerId) => {
                            props.fetchCustomerDetailsByCustomerId({
                                customerId,
                                relationshipId: (props.companyInfo || {}).relationshipId
                            });
                            props.fetchAddresses({ customerId, relationshipId: (props.companyInfo || {}).relationshipId, locationType: CONSTANTS.SHIPPING_ADDRESS });
                            props.fetchAddresses({ customerId, relationshipId: (props.companyInfo || {}).relationshipId, locationType: CONSTANTS.BILLING_ADDRESS });
                            props.fetchAllUnfulfilledSalesOrderByCustomerId({ relationshipId: (props.companyInfo || {}).relationshipId, customerId: customerId });
                            props.fetchAllContacts({ customerId, relationshipId: (props.companyInfo || {}).relationshipId });
                        }
                    }
                    allowClear={true}
                    onClear={() => {
                        updateState({
                            customer: undefined,
                            customerName: undefined,
                            customerPaymentTerm: undefined,
                            customerShippingAddress: undefined,
                            customerBillingAddress: undefined,
                            placeOfSupply: undefined,
                            invoiceDueDate: undefined,
                            selectedContact: undefined,
                            studentList: undefined
                        })
                        props.resetUnfulfilledSalesOrder();
                    }}
                /> 
                 {/* <span className="pr10">
                 <FormattedMessage id='programName.text' /> <span className="required">*</span>
                    </span> */}
                <Dropdown
                    items={props.allForms}
                    style={{width: '200px'}}
                    valueKeyName='formName'
                    optionKeyName='registrationFormSettingId'
                    value={props.registrationFormSettingId || ''}
                    placeholder={intl.formatMessage({
                        id: 'programName.text', defaultMessage: ''
                    })}
                    disabled={!props.customerName || props.invoiceForFinancialAssitance}
                    onSelect={
                        (formSettingId, option) => {
                            const selectedForm = find(props.allForms, { registrationFormSettingId: Number(option.key) }) || {};
                            let studentRegistration = find(props.customer.orgRegistrationList || [], { registrationFormSettingId: Number(option.key) }) || {};
                            props.updateState({
                                projectMasterId: selectedForm.projectMasterId,
                                projectName: selectedForm.projectName,
                                projectNumber: selectedForm.projectNumber,
                                registrationFormSettingId: selectedForm.registrationFormSettingId,
                                formName: selectedForm.formName,
                                studentList: studentRegistration.studentList || [],
                                invoiceDiscountRule: selectedForm.invoiceDiscountRule ? JSON.parse(selectedForm.invoiceDiscountRule) : [],
                                primaryContact: studentRegistration.primaryContact,
                                orgRegistrationId: studentRegistration.orgRegistrationId
                            });

                            if(!studentRegistration || !studentRegistration.studentList || !studentRegistration.studentList.length){
                                return showToasterMessage({
                                    messageType: 'error', description: props.intl.formatMessage({id: 'programName.notStudent.available' })
                                });
                            }

                        }
                    }
                    allowClear={true}
                    onClear={() => {
                        props.updateState({
                            projectMasterId: undefined,
                                projectName: undefined,
                                projectNumber: undefined,
                                registrationFormSettingId: undefined,
                                formName: undefined,
                        })
                    }}
                /> 
                <Button  style={{ height: '38px'}} onClick={() => {
                    props.updateState({
                        ledgerDrawerVisible: true
                    })
                }} >
                <i className='fa fa-book fa-lg'> </i>
                </Button>
                {/* <div className="currency-converter-modal">
                    <span className="ant-btn ant-btn-icon-only icon" onClick={() => updateTemplateDropdown(!templateDropdown)}>
                        <i className="fa fa-exchange" />
                    </span>

                    {templateDropdown &&
                        <div ref={innerRef} className="content">
                            <Row className="mb10">
                                <Link onClick={()=>{
                                    updateTemplateDropdown(false)
                                }}
                                to="/admin/sales-invoice"
                                >Standard Invoice Template</Link>
                            </Row>

                            <Row>
                                <Link onClick={()=>{
                                    updateTemplateDropdown(false)
                                }}
                                to="/admin/sales-invoice-multi-currency"
                                >Multi Currency Invoice Template</Link>
                            </Row>
                        </div>
                    }
                </div> */}
                {/* <div className="space-lr">
                    <Button icon={<SwapOutlined />} />
                </div> */}
           
        </div>
        </>
    );
};
const getFormatedNextTxNumber = (props) => {
    // const { customer } = props;
    // if (!customer || !customer.customerId) {
    //     return <span>{props.nextTxNumber}</span>;
    // }
    // return <span>{props.nextTxNumber.replace('INV-00', 'INV-' + (customer.customerId < 10 ? ('0' + customer.customerId) : customer.customerId))}</span>
    return <span>{props.nextTxNumber}</span>;
}
export default injectIntl(CustomerHeaderAction);
