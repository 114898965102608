import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {  showModal, hideModal, pushModalToStack, popModalFromStack } from '../../../../actions/commonActions';
import { fetchAllBlogsList } from '../../../eStore/blogs/blogList/action';
import { fetchAllCategoriesWithSubCategories ,postBlog } from './action';
import BlogCreateComp from '../../../../components/drawer/eStore/blogCreate';
class BlogCreateDrawer extends Component {

  constructor(props){
    super(props);
    

    this.state = {
      isLoading: true,
      pageNumber:0,
      pageSize:25,
     category: '',
     articleTitle: '',
     pageSlug:'',
     
     blogDescription:'',
     metaTitle:'',
     metaDescription:'',
     isSideMenu:0,

    }
}
 
  componentDidMount() {
    const companyInfo = this.props.companyInfo || {};
    const payload = {
      relationshipId: companyInfo.relationshipId,
      pageNumber:0,
      pageSize:500,
    };
   
    this.props.fetchAllCategoriesWithSubCategories(payload)
    
    
  }
  componentWillReceiveProps(props) {
     if(this.state.isLoading && props.isUpdate && Object.keys(props.blogData||{}).length){
         const data = props.blogData;

         this.setState({
          category: data?.categoryName,
          articleTitle: data?.title,
          pageSlug: data?.blogPageSlug,
          
          blogDescription:data?.content,
          metaTitle:data?.blogMetaTitle,
          metaDescription:data?.blogMetaDescription,
          isSideMenu: data?.sidemenuBlog,
          isLoading: false,
          
         })

     }
  }

  render() {
    return (
      // <ContactCompo {...this.props} {...this.state} />
      <BlogCreateComp {...this.props} {...this.state} updateState={(data) => { this.setState(data) }}/>

    )
  }
}

const mapStateToProps = (state) => {
  return {
   blogCategoryList: state.eStore.blogCategoryList,
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  showModal,
  hideModal,
  pushModalToStack,
  popModalFromStack,
  fetchAllCategoriesWithSubCategories,
  postBlog,
  fetchAllBlogsList
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(BlogCreateDrawer);
