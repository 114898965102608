import React, { useState,Fragment,useRef, useEffect } from "react";
import Resizer from "react-image-file-resizer";
import config from '../../../config/environmentConfig';
import { base64Toblob, showToasterMessage, validateEmail} from '../../../utils';

import { CustomUploader } from "../../general/CustomUploader";
import { ImageCropper } from "../../general/ImageCropper";
import { Button, Col, Divider, Row, Skeleton, Tabs, Tooltip } from "antd";
import { FormattedMessage } from "react-intl";

import { getCurrencySymbol,addToLocalStorage, fetchFromLocalStorage, getMomentDateForUIReadOnly ,getAWSFileURL, getInitialsFromString} from "../../../utils";
import { TextBox } from "../../general/TextBox";
import { Dropdown, DropdownRef } from "../../general/Dropdown";
import { CONSTANTS } from "../../../static/constants";
import find from "lodash.find";
import moment from "moment-timezone";
const format = CONSTANTS.DISPLAY_DATE_FORMAT;
const {TabPane} = Tabs;



function GeneralComp(props) {
  const {userInfo , companyInfo  ,employee ,intl} = props;
  let eventData = (props.eventDetails || {})[props.childId] || {}; 
  let buttonRef = document.getElementById(props.childId) || {};

    let selectedLanguage = fetchFromLocalStorage("language") || "en";
  
   const setEventInfo = () =>{
    let info = `<div style= "text-align: center; padding: 3px 8px;"> <b> ${eventData.eventTitle || ''} </b></div>
    <div style= "text-align: center; padding: 3px 8px;"> ${eventData.eventDate ? getMomentDateForUIReadOnly({ date: new Date(eventData.eventDate), format }) : ''} ${eventData.eventTime || ''} ${eventData.timezone || ''} </div>
    <div style= "text-align: center; padding: 3px 8px;"> ${eventData.street1 || ''} ${eventData.street2 || ''} </div>
    <div style= "text-align: center; padding: 3px 8px;"> ${eventData.cityName || ''}${eventData.stateName ? ', ' + eventData.stateName : ''}${eventData.zipCode ? ', ' + eventData.zipCode : ''} </div>
    `;
    buttonRef.innerHTML = info;
}
   const [profileIcon, updateProfileIcon] = useState();
   const cropperRef = useRef();
   const useOuterClick = (callback) => {
    const innerRef = useRef();
    const callbackRef = useRef();

    // set current callback in ref, before second useEffect uses it
    useEffect(() => { // useEffect wrapper to be safe for concurrent mode
        callbackRef.current = callback;
    });

    useEffect(() => {
        document.addEventListener("click", handleClick);
        return () => document.removeEventListener("click", handleClick);

        // read most recent callback and innerRef dom node from refs
        function handleClick(e) {
            if (
                innerRef.current &&
                callbackRef.current &&
                !innerRef.current.contains(e.target)
            ) {
                if (((cropperRef.current &&
                    cropperRef.current.contains(e.target)) || e.target.contains(cropperRef.current)) ||
                    (e.target.classList.value.includes("ant-btn"))
                ) {
                    return;
                }
                callbackRef.current(e);
            }
        }
    }, []); // no need for callback + innerRef dep

    return innerRef; // return ref; client can omit `useRef`
}

const innerRef = useOuterClick(e => {
    props.updateState({
        showUserDropdown: false
    })
});
  const customProps = {
    type: CONSTANTS.TYPE_IMAGE,
    imgUrl: getAWSFileURL((props.companyInfo || {}).logoAwsObjectKey, config.BUCKET_NAME.BO_RELATIONSHIP) || profileIcon ,
    height: 20,
    width: 20,
    name: 'file',
    action: null,
    buttonName: <FormattedMessage id='button.uploadCustomerPO.label' defaultMessage='' />,
    multiple: false,
    onChange: (fileList) => {
        let file = {file: fileList[0]}; // some untracked chages detected. recieving file list insteed of file object
        if (file.file.originFileObj) {
            new Promise((resolve) => {
                Resizer.imageFileResizer(
                    file.file.originFileObj,
                    230,
                    90,
                    "png",
                    100,
                    0,
                    (uri) => {
                        //updateProfileIconUrl(uri);
                        const fileData = base64Toblob(uri);

                        var reader = new FileReader();

                        reader.onload = function (e) {
                            props.showModal({
                                title: <Fragment>
                                    <div className='ant-modal-title'>Crop Image</div>
                                </Fragment>,
                                modalBody: <ImageCropper cropperRef={cropperRef} onClick={(e) => {
                                }} handleSubmit={(newUrl) => {
                                    props.uploadImage({
                                        objectKey: props.companyInfo.logoAwsObjectKey,
                                        bucketName: config.BUCKET_NAME.BO_RELATIONSHIP,
                                        file: base64Toblob(newUrl)
                                    });
                                    updateProfileIcon(newUrl);
                                        
                                }}{...props} url={e.target.result} />,
                                width: 800,
                                hideFooter: true,
                                wrapClassName: 'Upload-profile-modal'
                            })
                        }
        
                        reader.readAsDataURL(fileData); // convert to base64 string

                    },
                    "base64"
                );
            });
        }
        //   file.file.originFileObj = file.file.originFileObj || {};
        //   file.file.originFileObj.name = `${file.file.originFileObj.name} (${bytesToSize(file.file.originFileObj.size)})`;
        //   this.setState({
        //     fileToUpload: file.file.originFileObj
        //   })
    }
}
    const dateFormats = [
        { formatId: 1, label: "MM/DD/YYYY", format: "MM/dd/yyyy" },            
        { formatId: 2, label: "DD/MM/YYYY", format: "dd/MM/yyyy" },            
        { formatId: 3, label: "YYYY-MM-DD", format: "yyyy-MM-dd" },            
        { formatId: 4, label: "Full Date (MMMM DD, YYYY)", format: "MMMM dd, yyyy" }, 
        { formatId: 5, label: "Short Date (MMM DD, YYYY)", format: "MMM dd, yyyy" },   
        { formatId: 6, label: "Day, Date (E, MMM DD YYYY)", format: "E, MMM dd yyyy" }, 
        { formatId: 7, label: "Day of the Month (Do MMMM, YYYY)", format: "do MMMM, yyyy" }, 
        { formatId: 8, label: "ISO 8601 Date", format: "yyyy-MM-dd'T'HH:mm:ssXXX" },  
        { formatId: 9, label: "RFC 2822 Date", format: "eee, dd MMM yyyy HH:mm:ss xx" },  
        { formatId: 10, label: "Unix Timestamp", format: "T" },                 
      ];
      const timeFormats = [
        { id: 1, label: "12-Hour (AM/PM)", format: "hh:mm a" },       
        { id: 2, label: "24-Hour", format: "HH:mm" },                
        { id: 3, label: "12-Hour with Seconds (AM/PM)", format: "hh:mm:ss a" },
        { id: 4, label: "24-Hour with Seconds", format: "HH:mm:ss" }, 
        { id: 5, label: "Hour and Minutes (AM/PM)", format: "hh:mm a" },
        { id: 6, label: "Hour, Minutes, and Seconds (AM/PM)", format: "hh:mm:ss a" }, 
        { id: 7, label: "24-Hour with Timezone", format: "HH:mm OOOO" }, 
        { id: 8, label: "12-Hour with Timezone (AM/PM)", format: "hh:mm a OOOO" }, 
        { id: 9, label: "ISO 8601 Time", format: "HH:mm:ss.SSS'Z'" },  
        { id: 10, label: "Unix Timestamp", format: "t" },              
      ];
      const updateSubdamain = (props) => {
        
        const modalData = {
          modalBody: "Are you sure you want to save?",
          handleSubmit: () => {
            let payload = {
              alpideSubdomainId: props.subdomain.alpideSubdomainId,
              relationshipId: (props.companyInfo || {}).relationshipId,
              subdomainName: props.subdomainName,
            };
            props.saveClientURL(payload);
            props.updateBusinessData(props);
          },
        };
        props.pushModalToStack(modalData);
      };
      let  timeZones = moment.tz.names();
      let abbrs = {
        EST : 'Eastern Standard Time',
        EDT : 'Eastern Daylight Time',
        CST : 'Central Standard Time',
        CDT : 'Central Daylight Time',
        MST : 'Mountain Standard Time',
        MDT : 'Mountain Daylight Time',
        PST : 'Pacific Standard Time',
        PDT : 'Pacific Daylight Time',
    };
      moment.fn.zoneName = function () {
        var abbr = this.zoneAbbr();
        return abbrs[abbr] || abbr;
    };
    let  offsetTmz = [];

    for (var i in timeZones) {
        offsetTmz.push({name: timeZones[i], value: " (GMT" + moment.tz(timeZones[i]).format('Z') + ") " + moment.tz(timeZones[i]).format('z')+ " "+  timeZones[i]});
    }
      const language = [{label: "English", value:"en" ,},{label:"Arabic", value:"ar"},{label:"French" ,value:"fr"},{label:"German" , value:"de"}]
    return (
        <div className="account-setting">
<div className="store-model-container cus-space">
   
   <div className="store-main-container">
   

    <div>
    <div className="account-heading">
    <p className="heading">General Settings</p>
    <p className="sub-heading">
    Configure fundamental organization details, including company name, logo, and contact information.   </p>

    </div>
    <div className="account-information">
    
    <div>
        <div className="general-setting">
            <Row>
                <Col span={12}
                >
                  <div className="company-logo-uploader">
       <div className="personal-information">
      
       <div ref={innerRef}>
                    <CustomUploader {...customProps} />
                </div>
       </div>
       <div className="company-information">
          <div className="i-label">Organization/Company Name</div>
        <TextBox
          placeholder="Enter Company Name"
          type="text"
          
          value={props.companyInfoData?.storeName}
          onChange={(e) => {
            props.updateState({
              companyInfoData: {
                ...props.companyInfoData,
                storeName: e.target.value,
              },
            });
          }}
          maxLength={50}
          className="mb10"
          countStyle={{ top: "40px" }}
          
        />
        <Button type="primary" onClick={()=>{
          updateSubdamain(props)
        }}>
          Save Changes
        </Button>
        
          </div>
         
    </div>
                     
         
                </Col>
            
         <Col span={12}>
         <div className="organization-info">
          <h5>Organization Owner</h5>
          <div>
          The organization owner has the ultimate control over organization. Nobody can change his role or remove from organization. He is the only one who can delete organization.
          </div>
          <div className="personal-information">
       <div className="name">
      
       <div ref={innerRef}>
                    <CustomUploader {...customProps} />
                </div>
       </div>
       <div>
       <b>{employee?.fullName}</b>
    <p className="sub-heading">
   {employee?.emailAddress}
    </p>
       </div>
    </div>
          </div>
         </Col>
         </Row>
        </div>
        <div>
        <div className="account-heading mt10 mb10">
    <p className="heading">Organization Info</p>
    <p className="sub-heading">
    View and manage key organizational details, including company name, industry, and registration information. </p>

    </div>
    <Row gutter={[16,16]}>
  
    <Col span={12}>
                    <span className="i-lable"> <FormattedMessage id='modal.settings.companyInfo.aboutCompany.industry' defaultMessage='' /></span> 
                        <Dropdown
                            items={props.industries}
                            valueKeyName='industryName'
                            optionKeyName='businessIndustryId'
                            disabled={props.companyInfo?.industryId}
                            value={(props.companyInfo || {})?.industryId}
                            onSelect={(id)=>{
                                props.updateState({
                                    companyInfoData: {
                                        ...props.companyInfo, industryId: id
                                    }
                                })
                            }}
                            placeholder={intl.formatMessage(
                                { id: 'modal.settings.companyInfo.aboutCompany.industry.placeholder', defaultMessage: ''  }
                            )}
                        />
                    </Col>

                    <Col span={12}>
                       <span className="i-lable" > <FormattedMessage id='modal.settings.companyInfo.aboutCompany.companySize' defaultMessage='' /></span>
                        <Dropdown
                            items={CONSTANTS.COMPANY_SIZE_LIST}
                            placeholder={intl.formatMessage(
                                { id: 'modal.settings.companyInfo.aboutCompany.companySize.placeholder', defaultMessage: ''  }
                            )}
                            value= {props.companyInfo?.companySize}
                            onSelect={(val)=>{
                                props.updateState({
                                    companyInfoData: {
                                    ...props.companyInfoData, companySize: val
                                   }
                                });
                            }}
                        />
                    </Col>

                    <Col span={12}>
          <div className="i-lable">
            {" "}
            <FormattedMessage id="signup.email.text" />{" "}
            <Tooltip
              placement="top"
              title={props.intl.formatMessage({
                id: "company.email.helpMessage",
              })}
              trigger="click"
            >
              {" "}
              <i className="fa fa-question-circle-o pl5 cursor-pointer" />{" "}
            </Tooltip>
          </div>
          <TextBox
            maxLength={50}
            countStyle={{ top: "38px" }}
            className="textbox-pd"
            value={props.companyInfoData?.email}
            placeholder={props.intl.formatMessage(
              { id: "modal.settings.companyInfo.company.email.placeholder" }
            )}
            onChange={(e) => {
              props.updateState({
                companyInfoData: {
                  ...props.companyInfoData,
                  email: e.target.value,
                },
              });
            }}
          />
        </Col>
        <Col span={12}>
          <div className="i-lable">
            {" "}
            <FormattedMessage
              id="modal.settings.companyInfo.company.website"
              defaultMessage=""
            />
          </div>
          <TextBox
            maxLength={45}
            countStyle={{ top: "38px" }}
            value={props.companyInfoData?.website}
            placeholder={props.intl.formatMessage({
              id: "modal.settings.companyInfo.company.website.placeholder",
              defaultMessage: "",
            })}
            onChange={(e) => {
              props.updateState({
                companyInfoData: {
                  ...props.companyInfoData,
                  website: e.target.value,
                },
              });
            }}
          />
        </Col>
        <Col span={12}>
          <div className="i-lable">
            <FormattedMessage
              id="modal.settings.companyInfo.company.mobileNumber"
              defaultMessage=""
            />
          </div>
          <div className="d-flex">
            <DropdownRef
              style={{ width: "10%" }}
              items={
                (props.countries || []).filter((el) => {
                  return el.countryCallingCode != null;
                }) || []
              }
              optionKeyName="countryId"
              valueKeyName="countryCallingCode"
              defaultValue={props.countryCallingCode}
              className="currency-tags"
              onSelect={(selectedValue, option) => {
                const selectedCountry = props.countries.find((el) => el.countryId === selectedValue);

                if (selectedCountry) {
                  props.updateState({
                    countryCallingCode: selectedCountry.countryCallingCode,
                    // contact: { ...contact, countryCallingCode: selectedCountry.countryCallingCode },
                  });
                }

              }}
            />
            <TextBox
              value={props.companyInfoData?.phoneNumber}
              placeholder={props.intl.formatMessage({
                id: "modal.settings.companyInfo.company.mobileNumber.placeholder",
                defaultMessage: "",
              })}
              maxLength={10}
             parentStyle={{width:"90%"}}
             
              countStyle={{ top: "38px" }}
              onChange={(e) => {
                props.updateState({
                  companyInfoData: {
                    ...props.companyInfoData,
                    phoneNumber: e.target.value,
                  },
                });
              }}
            />

          </div>
        </Col>
        <Col span={12}>
          <div className="i-lable">
            <FormattedMessage
              id="modal.settings.companyInfo.company.faxNumber"
              defaultMessage=""
            />{" "}
          </div>
          <TextBox
            value={props.companyInfoData?.faxNumber}
            placeholder={props.intl.formatMessage({
              id: "modal.settings.companyInfo.company.faxNumber.placeholder",
              defaultMessage: "",
            })}
            maxLength={15}
            countStyle={{ top: "38px" }}
            onChange={(e) => {
              props.updateState({
                companyInfoData: {
                  ...props.companyInfoData,
                  faxNumber: e.target.value,
                },
              });
            }}
          />
        </Col>
        
        <Col span={12}>
        <div className="title">
                        <FormattedMessage id='language.text' defaultMessage='' />
                    </div>
                    <Dropdown
                            items={language}
                            valueKeyName='label'
                            optionKeyName='value'
                            disabled={true}
                            value={selectedLanguage}
                            onSelect={(id)=>{
                               
                               addToLocalStorage("language", id);
                               window.location.reload();
                            }}
                            placeholder={intl.formatMessage(
                                { id: 'Language', defaultMessage: ''  }
                            )}
                        />
                 
          
        </Col>
        <Col span={12}>
        <div className="i-lable">
            {" "}
            <FormattedMessage
              id="timezon.text"
              defaultMessage=""
            />
          </div>
        <Dropdown
                                        items={offsetTmz}
                                        value={eventData.timezone}
                                        valueKeyName='value'
                                        optionKeyName='name'
                                        placeholder={props.intl.formatMessage({
                                            id: 'signup.timezone.placeholder', defaultMessage: ''
                                        })}
                                        onSelect={(optionValue, elem) => {
                                            eventData.timezone = optionValue;
                                            props.updateState({eventDetails : {...props.eventDetails, [props.childId]: eventData}});
                                            setEventInfo(eventData);
                                        }}
                                    />
        </Col>
        <Col span={12}>
        <span className="i-lable"> <FormattedMessage id='accounts.company.setting.dateFomat' defaultMessage='' /></span> 
                        <Dropdown
                            items={dateFormats}
                            valueKeyName='label'
                            optionKeyName='formatId'
                            
                            value={props.selectedDateFormat ? getMomentDateForUIReadOnly({
                                date: new Date(),
                                format: props.selectedDateFormat
                            }): ''}
                            onSelect={(id)=>{
                                const formattedDated = find(dateFormats ,{formatId: id})
                               props.updateState({
                                selectedDateFormat: formattedDated?.format
                               })
                            }}
                            placeholder={intl.formatMessage(
                                { id: 'accounts.company.setting.dateFomat', defaultMessage: ''  }
                            )}
                        />
        </Col>

        <Col span={12}>
        <span className="i-lable"> <FormattedMessage id='accounts.company.setting.timeFomat' defaultMessage='' /></span> 
                        <Dropdown
                            items={timeFormats}
                            valueKeyName='label'
                            optionKeyName='id'
                            
                            value={props.selectedTimeFormat ? getMomentDateForUIReadOnly({
                                date: new Date(),
                                format: props.selectedDTimeFormat
                            }): ''}
                            onSelect={(id)=>{
                                const formattedTime = find(timeFormats ,{id: id})
                               props.updateState({
                                selectedTimeFormat: formattedTime?.format
                               })
                            }}
                            placeholder={intl.formatMessage(
                                { id: 'accounts.company.setting.timeFomat', defaultMessage: ''  }
                            )}
                        />
        </Col>
{/*       
                            <Col span={24}>
                            <FormattedMessage id='signup.timezone.text' defaultMessage='' />

                                <InputGroup compact>
                                    <Dropdown
                                        items={offsetTmz}
                                        value={eventData.timezone}
                                        valueKeyName='value'
                                        optionKeyName='name'
                                        placeholder={props.intl.formatMessage({
                                            id: 'signup.timezone.placeholder', defaultMessage: ''
                                        })}
                                        onSelect={(optionValue, elem) => {
                                            eventData.timezone = optionValue;
                                            props.updateState({eventDetails : {...props.eventDetails, [props.childId]: eventData}});
                                            setEventInfo(eventData);
                                        }}
                                    />
                                </InputGroup>
                                </Col> */}


    </Row>
        </div>
    </div>

    <div className="">
     <Button type="primary" onClick={() => {
            if (
              props.companyInfoData.email &&
              !validateEmail(props.companyInfoData.email) 
             
            ) {
              return showToasterMessage({
                messageType: "error",
                description: "Please enter correct email",
              });
            } else {
              // updateBusinessData(props);
              updateSubdamain(props);
              // props.saveFromEmail(props);
            }
          }}>Save Changes</Button>
  </div>
    </div>
   
    </div>
   </div>
   

   
   


</div>
        </div>
        
    )
}
export default GeneralComp;