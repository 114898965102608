import React from "react";
import { injectIntl } from "react-intl";
import { Skeleton } from "antd";
import { getTimeFromDate } from "../../../../../utils";

const TableView = (props) => {
  const { timeTableList } = props;

  const getDayDetails = (cellData, day) =>{
    if(cellData.recurrenceDays.includes(day) ){
      return <div className="schedule-detail">
        <div className="line-view"><i className="fi fi-rs-book"></i> Subject : {cellData.subjectName || '-'}</div>
        <div className="line-view"><i className="fi fi-rr-clock"></i> Time : {getTimeFromDate(cellData.startDate)} - {getTimeFromDate(cellData.endDate)}</div>
        <div className="line-view"><i className="fi fi-rs-chalkboard-user"></i> Teacher : {cellData.teacherName || '-'}</div>
        <div className="line-view"><i className="fi fi-rs-hotel"></i> Location : {cellData.location || '-'}</div>
      </div>
    }
    else{
      return <div className="no-schedule"></div>;
    }
  }




  return (
    <>
        <Skeleton loading={props.listLoading} active paragraph={{ rows: 12 }}>  
        {/* <div className="view-container-actions">
          <div className="left-actions">
            <div className="table-heading">
              <FormattedMessage
                id="schoolManagement.timeTable.tableView.heading"
                defaultMessage=""
              />
            </div>
          </div>
          <div className="right-actions">

          </div>
        </div> */}
        <div className="table-container time-table" style={{height: 'calc(100% - 46px)'}}>
          <table>
            <thead>
              <tr>
                <th>Monday</th>
                <th>Tuesday</th>
                <th>Wednesday</th>
                <th>Thursday</th>
                <th>Friday</th>
                <th>Saturday</th>
                <th>Sunday</th>
              </tr>
            </thead>
            <tbody>
              {timeTableList && timeTableList.length ? timeTableList.map((obj, i)=>{
                return <tr key={'xyz'+i}>
                  <td>{getDayDetails(obj, 'MO')}</td>
                  <td>{getDayDetails(obj, 'TU')}</td>
                  <td>{getDayDetails(obj, 'WE')}</td>
                  <td>{getDayDetails(obj, 'TH')}</td>
                  <td>{getDayDetails(obj, 'FR')}</td>
                  <td>{getDayDetails(obj, 'SA')}</td>
                  <td>{getDayDetails(obj, 'SU')}</td>
                </tr>
              })
            :
            [{},{},{},{},{}].map((obj, i)=>{
                return <tr key={'xyz'+i}>
                  <td style={{height: 'calc( 80vh / 5 )', width: 'calc( 100% / 7)'}}></td>
                  <td style={{height: 'calc( 80vh / 5 )', width: 'calc( 100% / 7)'}}></td>
                  <td style={{height: 'calc( 80vh / 5 )', width: 'calc( 100% / 7)'}}></td>
                  <td style={{height: 'calc( 80vh / 5 )', width: 'calc( 100% / 7)'}}></td>
                  <td style={{height: 'calc( 80vh / 5 )', width: 'calc( 100% / 7)'}}></td>
                  <td style={{height: 'calc( 80vh / 5 )', width: 'calc( 100% / 7)'}}></td>
                  <td style={{height: 'calc( 80vh / 5 )', width: 'calc( 100% / 7)'}}></td>
                </tr>
              })
            }
            </tbody>
          </table>
        </div>
      </Skeleton>
    </>
  );
};

export default injectIntl(TableView);
