import React, { Fragment,useState,useEffect,useMemo } from "react";
import { FormattedMessage } from "react-intl";
import {
  FilePdfOutlined,
  MailOutlined,
  PrinterOutlined,
  EditOutlined,
  DeleteOutlined,
  CopyOutlined,
  HistoryOutlined,
} from "@ant-design/icons";
import { Row, Col, Button, Select, Skeleton } from "antd";
import ReactHtmlParser from "react-html-parser";
//import {Link} from 'react-router-dom';
import {
  CONSTANTS,
  TRANSACTION_TYPES,
  MODAL_TYPE,
  CONSTANTS_TRANSACTIONS,
  MODULE_CODED_VALUES,
  PERMISSION_VALUES,
} from "../../../../../static/constants";
import config from "../../../../../config/environmentConfig";
import {
  base64Toblob,
  getAWSFileURL,
  checkACLPermission,
  getMomentDateForUIReadOnly,
  getPermissionData
} from "../../../../../utils";
import { ImageCropper } from "../../../../../components/general/ImageCropper";
import { CustomUploader } from "../../../../../components/general/CustomUploader";
import { AWSFileTable } from "../../../../general/AWSFileTable";

import StandardPurchaseRequest from "../../../../../containers/supplier/purchaseRequest/StandardPurchaseRequest";
import StandardRqf from "../../../../../containers/supplier/rfq/StandardRFQ";
import StandardPurchaseOrder from "../../../../../containers/supplier/purchaseOrder/StandardPurchaseOrder";
const { Option } = Select;

const PRDetails = (props) => {
  const { prData, companyInfo, permissions } = props;
  const primaryPerm = permissions.primary;
  const { purchaseRequestDetailsList } = prData;
  const { relationshipEmployeesList } = companyInfo;
  const permissionsData = useMemo(()=>{
    return getPermissionData(primaryPerm, MODULE_CODED_VALUES.PURCHASE_REQUEST )
  },[])
  const rfqPermissionsData = useMemo(()=>{
    return getPermissionData(primaryPerm, MODULE_CODED_VALUES.RFQ )
  },[])
  const poPermissionsData = useMemo(()=>{
    return getPermissionData(primaryPerm, MODULE_CODED_VALUES.PURCHASE_ORDER )
  },[])
  let isColumnEmpty = {
    product: true,
    service: true,
    variant: true,
    description: true,
    qty: true,
    uomName: true,
  };
  if (prData) {
    isColumnEmpty = updateColumnEmptyCheck(isColumnEmpty, prData);
  }

  const customProps = {
    type: CONSTANTS.TYPE_IMAGE,
    imgUrl:
      getAWSFileURL(
        (prData || {}).stampAwsKey,
        config.BUCKET_NAME.BO_PURCHASE_REQUEST
      ) || "",
    isSignature: true,
    height: 60,
    width: 60,
    name: "file",
    action: null,
    buttonName: "Upload Customer PO",
    onChange: (file) => {
      if (file[0]?.originFileObj) {
        var reader = new FileReader();

        reader.onload = function (e) {
          props.showModal({
            title: (
              <Fragment>
                <div className="ant-modal-title">Crop Image</div>
              </Fragment>
            ),
            //modalBody: <ImageCropper cropperRef={cropperRef} onClick={(e) => {
            modalBody: (
              <ImageCropper
                onClick={(e) => {}}
                handleSubmit={(newUrl) => {
                  props.uploadStamp({
                    supplierId: prData.supplierId || 0,
                    purchaseRequestMasterId: prData.purchaseRequestMasterId,
                    rid: prData.relationshipId,
                    objectKey: prData.stampAwsKey,
                    bucketName: config.BUCKET_NAME.BO_RELATIONSHIP,
                    file: base64Toblob(newUrl),
                  });
                }}
                {...props}
                url={e.target.result}
              />
            ),
            width: 800,
            hideFooter: true,
            wrapClassName: "Upload-profile-modal",
          });
        };

        reader.readAsDataURL(file[0]?.originFileObj); // convert to base64 string
      }
    },
  };

  const getPayloadForPdf = () => {
    return {
      fileName: (prData.purchaseRequestNumber || "Purchase Request") + ".pdf",
      transactionName: "purchaseRequest",
      prData: prData,
      companyInfo: companyInfo,
      isColumnEmpty: isColumnEmpty,
      relationshipEmployeesList: relationshipEmployeesList,
      bucketName: config.BUCKET_NAME.BO_PURCHASE_REQUEST,
      stampAwsKey: prData.stampAwsKey,
    };
  };

  const generatePDF = () => {
    let payload = getPayloadForPdf();
    props.generatePDF(payload);
  };

  const printPdf = () => {
    let payload = getPayloadForPdf();
    props.printPDF(payload);
  };

  const openEmailDrawer = (props) => {
    let payload = getPayloadForPdf();
    payload.fileDataReqeired = true;
    payload.purchaseRequestMasterId = prData.purchaseRequestMasterId;
    props.updateState({
      emailDrawerVisible: true,
      txData: payload,
      type: TRANSACTION_TYPES.PURCHASE_REQUEST,
    });
  };

  const approvePR = () => {
    const modalBody = "Are you sure you want to approve?";
    const modalData = {
      modalBody,
      handleSubmit: () => {
        props.approvePR(
          {
            relationshipId: prData.relationshipId,
            purchaseRequestMasterId: prData.purchaseRequestMasterId,
            approvedByEmpId: (props.userInfo || {}).relationshipEmployeeId,
          },
          props
        );
        props.popModalFromStack();
      },
    };
    props.showModal(modalData);
  };

  const handleDelegateApprover = () => {
    const formData = {
      relationshipId: prData.relationshipId,
      purchaseRequestMasterId: prData.purchaseRequestMasterId,
      approvedByEmpId: (props.userInfo || {}).relationshipEmployeeId,
      txType: TRANSACTION_TYPES.PURCHASE_REQUEST,
      approverEmpId: prData.approverEmpId,
    };
    const { showModal } = props;
    const data = {
      title: "Delegate Approver",
      formData,
      hideFooter: true,
      modalData: {
        modalType: MODAL_TYPE.DELEGATE_APPROVER,
        data: formData,
      },
      handleSubmit: (formData = {}) => {
        data.formData.submittedOnce = true;
        props.hideModal(data);
        props.popModalFromStack();
      },
    };
    showModal(data);
  };

  const handleRejectApproval = () => {
    const formData = {
      relationshipId: prData.relationshipId,
      purchaseRequestMasterId: prData.purchaseRequestMasterId,
      approvedByEmpId: (props.userInfo || {}).relationshipEmployeeId,
      txType: TRANSACTION_TYPES.PURCHASE_REQUEST,
    };
    const { showModal } = props;
    const data = {
      title: "Reject",
      formData,
      hideFooter: true,
      modalData: {
        modalType: MODAL_TYPE.REJECT_APPROVAL,
        data: formData,
      },
      handleSubmit: (formData = {}) => {
        data.formData.submittedOnce = true;
        props.hideModal(data);
        props.popModalFromStack();
      },
    };
    showModal(data);
  };

  const openAuditDrawer = (props, data) => {
    props.updateState({
      title: (
        <div className="drawer-title">
          <FormattedMessage
            id="modal.txDetails.common.auditTrail"
            defaultMessage=""
          />
        </div>
      ),
      auditDrawerVisible: true,
      deletApiUrl: "deleteAuditTrailPurchaseRequest",
      masterKey: "purchaseRequestMasterId",
      masterKeyValue: data.purchaseRequestMasterId,
      apiUrl: "saveAuditTrailPurchaseRequest",
      supplierId: data.supplierId,
      trailList: data.auditTrailPRList || [],
      updateTrailListInTransaction: (list) => {
        data.auditTrailPRList = list;
      },
    });
  };

  const addItemConfirmationModal = (props, text, color, prData) => {
    const { showModal, companyInfo } = props;
   
    const modalBody = (
      <span>
        <FormattedMessage
          id="setItem.confirmation"
          defaultMessage={``}
        />
       
      </span>
    );

    let payload = {
      userStatus: text,
      userStatusColor: color,
      relationshipId: companyInfo.relationshipId,
      purchaseRequestMasterId: prData.purchaseRequestMasterId,
      prMasterId: prData.purchaseRequestMasterId,
      supplierId: prData.supplierId,
    };

    const modalData = {
      modalBody,
      hideFooter: false,
      handleSubmit: () => {
        props.updateUserStatus(payload, props);
        props.popModalFromStack();
        props.hideModal();
      },
    };
    showModal(modalData);
  };

  const updateUserStatus = (props, prData) => {
    let payload = { formData: {}, txData: prData };
    const formData = payload.formData;
    const { pushModalToStack } = props;

    if (
      formData.textBoxValue &&
      formData.textBoxValue.length &&
      !payload.existsInData
    ) {
      addItemConfirmationModal(props, formData.textBoxValue, prData);
    } else {
      const data = {
        title: (
          <FormattedMessage id="addItem.text.userStatus" defaultMessage="" />
        ),
        formData,
        hideFooter: true,
        modalData: {
          modalType: MODAL_TYPE.USER_STATUS,
          data: payload,
          transactionName: CONSTANTS_TRANSACTIONS.PURCHASE_REQUEST,
        },
        handleSubmit: (formData = {}) => {
          if (formData.textBoxValue && formData.textBoxValue.length) {
            addItemConfirmationModal(
              props,
              formData.textBoxValue,
              formData.statusColor,
              prData
            );
          } else {
            data.formData.submittedOnce = true;
            pushModalToStack(data);
          }
        },
      };
      pushModalToStack(data);
    }
  };

  const updateInquiryStatus = (props, prData) => {
    let payload = { formData: { maxLength: 500 }, txData: prData };
    const formData = payload.formData;
    const { pushModalToStack } = props;

    if (
      formData.textBoxValue &&
      formData.textBoxValue.length &&
      !payload.existsInData
    ) {
      updateInquiryStatusConfirmationModal(
        props,
        formData.textBoxValue,
        prData
      );
    } else {
      formData.textBoxValue = prData.statusPRMessage;
      const data = {
        title: (
          <FormattedMessage
            id="modal.txDetails.inquiryStatus"
            defaultMessage=""
          />
        ),
        formData,
        modalData: {
          modalType: MODAL_TYPE.UPDATE_STATUS,
          data: payload,
          transactionName: CONSTANTS_TRANSACTIONS.SALES_ORDER,
        },
        handleSubmit: (formData = {}) => {
          if (formData.textBoxValue && formData.textBoxValue.length) {
            updateInquiryStatusConfirmationModal(
              props,
              formData.textBoxValue,
              prData
            );
          } else {
            data.formData.submittedOnce = true;
            pushModalToStack(data);
          }
        },
      };
      pushModalToStack(data);
    }
  };

  const updateInquiryStatusConfirmationModal = (props, text, prData) => {
    const { showModal, companyInfo } = props;
    const labelName = (
      <FormattedMessage id="modal.txDetails.inquiryStatus" defaultMessage="" />
    );
    const modalBody = (
      <span>
        <FormattedMessage
          id="setItem.confirmation"
          defaultMessage=""
        />
        {labelName} ?
      </span>
    );

    let payload = {
      statusPRMessage: text,
      relationshipId: companyInfo.relationshipId,
      inquiryMasterId: prData.purchaseRequestDetailsList[0].inquiryMasterId,
      customerId: prData.customerId,
    };

    const modalData = {
      modalBody,
      handleSubmit: () => {
        props.updateInquiryPRStatus(payload, props);
        props.hideModal();
        props.popModalFromStack();
      },
    };
    showModal(modalData);
  };

  const updateSOPRStatus = (props, soData) => {
    let payload = { formData: { maxLength: 500 }, txData: soData };
    const formData = payload.formData;
    const { pushModalToStack } = props;

    if (
      formData.textBoxValue &&
      formData.textBoxValue.length &&
      !payload.existsInData
    ) {
      updateSOStatusConfirmationModal(props, formData.textBoxValue, soData);
    } else {
      formData.textBoxValue = soData.statusPRMessage;
      const data = {
        title: (
          <FormattedMessage id="modal.txDetails.soStatus" defaultMessage="" />
        ),
        formData,
        modalData: {
          modalType: MODAL_TYPE.UPDATE_STATUS,
          data: payload,
          transactionName: CONSTANTS_TRANSACTIONS.SALES_ORDER,
        },
        handleSubmit: (formData = {}) => {
          if (formData.textBoxValue && formData.textBoxValue.length) {
            updateSOStatusConfirmationModal(
              props,
              formData.textBoxValue,
              soData
            );
          } else {
            data.formData.submittedOnce = true;
            pushModalToStack(data);
          }
        },
      };
      pushModalToStack(data);
    }
  };

  const updateSOStatusConfirmationModal = (props, text, soData) => {
    const { showModal, companyInfo } = props;
    const labelName = (
      <FormattedMessage id="modal.txDetails.soStatus" defaultMessage="" />
    );
    const modalBody = (
      <span>
        <FormattedMessage
          id="setItem.confirmation"
          defaultMessage=""
        />
        {labelName} ?
      </span>
    );

    let payload = {
      statusPRMessage: text,
      relationshipId: companyInfo.relationshipId,
      salesOrderMasterId: soData.salesOrderMasterId,
      customerId: soData.customerId,
    };

    const modalData = {
      modalBody,
      handleSubmit: () => {
        props.updateSOPRStatus(payload, props);
        props.hideModal();
        props.popModalFromStack();
      },
    };
    showModal(modalData);
  };

  const handleConvertTo = (actionName) => {
    switch (actionName) {
      case "RFQ": {
        props.popModalFromStack();
        props.pushModalToStack({
          modalBody: (
            <StandardRqf
              {...props}
              purchaseRequestMasterId={prData.purchaseRequestMasterId}
              customerId={prData.customerId}
              customerName={prData.customerName}
              update={false}
            />
          ),
          width: "100%",
          hideTitle: true,
          hideFooter: true,
          wrapClassName: "new-transaction-wrapper",
        });
        // props.history.push({
        //     pathname: '/admin/rfq-create',
        //     state: {
        //         purchaseRequestMasterId: prData.purchaseRequestMasterId,
        //         customerId: prData.customerId,
        //         customerName: prData.customerName,
        //     }
        // })
        break;
      }
      case "Purchase Order": {
        props.popModalFromStack();
        props.pushModalToStack({
          modalBody: (
            <StandardPurchaseOrder
              {...props}
              purchaseRequestMasterId={prData.purchaseRequestMasterId}
              customerId={prData.customerId}
              customerName={prData.customerName}
              
            />
          ),
          width: "100%",
          hideTitle: true,
          hideFooter: true,
          wrapClassName: "new-transaction-wrapper",
        });
        // props.history.push({
        //     pathname: '/admin/po-create',
        //     state: {
        //         purchaseRequestMasterId: prData.purchaseRequestMasterId,
        //         customerId: prData.customerId,
        //         customerName: prData.customerName,
        //     }
        // })
        break;
      }
      default: {
        break;
      }
    }
  };
  const [showComp, setShowComp] = useState(false);
  useEffect(()=>{
    const delay = setTimeout(()=>{
      setShowComp(true);
    }, 400);
    return ()=> clearTimeout(delay);
  }, []);
  return (
    <div className={"custom-modal show"}>
      <i className="close-icon">
        <svg
          width="17"
          height="17"
          onClick={() => {
            props.popModalFromStack();
          }}
        >
          <title>
            <FormattedMessage id="button.close.label" defaultMessage="" />
          </title>
          <path
            fill="#67757d"
            fillRule="evenodd"
            d="M16.803 15.197c-.012.465-.221.818-.64 1.019-.431.207-.872.177-1.227-.15-.587-.542-1.145-1.116-1.712-1.68a1413.788 1413.788 0 0 1-4.242-4.242c-.137-.137-.208-.129-.34.004-1.91 1.915-3.827 3.825-5.741 5.738-.272.271-.569.463-.973.445-.455-.019-.791-.233-.987-.634-.201-.407-.163-.812.106-1.184.076-.106.171-.199.263-.292l5.692-5.683c.182-.182.182-.182.005-.359-1.911-1.907-3.82-3.816-5.734-5.719C.89 2.079.707 1.653.894 1.124A1.136 1.136 0 0 1 2.672.625c.349.284.648.623.966.941 1.67 1.663 3.336 3.328 5.003 4.992.172.172.173.172.347-.002 1.911-1.907 3.822-3.813 5.731-5.723.275-.275.577-.472.988-.451.456.023.789.24.983.641.194.402.158.802-.104 1.169-.079.111-.179.209-.276.306a10168.58 10168.58 0 0 1-5.692 5.683c-.176.175-.176.176-.005.346 1.91 1.907 3.82 3.815 5.733 5.72.266.265.468.553.457.95z"
          />
        </svg>
      </i>
      <div className="modal-dialog">
        <div className="modal-content full-width">
        {showComp ? 
          <><div className="modal-header align-left">
              {prData.documentName || "Purchase Request"}
            </div><div className="inner-content">
                <Row>
                  <Col span={13}>
                    <Skeleton loading={props.detailLoading} active paragraph={{ rows: 3 }}>

                      <Row className="read-only-content">
                        <Col span={4}>
                          <span className="title">
                            <FormattedMessage
                              id="modal.txDetails.purchaseRequest.requestor"
                              defaultMessage="" />{" "}
                          </span>
                        </Col>

                        <Col span={8} className="info">
                          {prData.prRequestedBy || ""}
                        </Col>
                      </Row>
                      {prData.customerName ? (
                        <Row className="read-only-content">
                          <Col span={4}>
                            <span className="title">
                              <FormattedMessage
                                id="common.customer.text"
                                defaultMessage="" />{" "}
                            </span>
                          </Col>

                          <Col span={8} className="info">
                            {prData.customerName || ""}
                          </Col>
                        </Row>
                      ) : (
                        ""
                      )}

                      {/* <Row className="read-only-content">
                        {prData.departmentName &&
                        <Fragment>
                            <Col span={4}>
                                <span className="title">Department </span>
                            </Col>

                            <Col span={8} className="info">
                                {prData.departmentName}
                            </Col>
                        </Fragment>
                        }
                    </Row> */}

                      <Row className="read-only-content">
                        {prData.prOwnerName && (
                          <Fragment>
                            <Col span={4}>
                              <span className="title">
                                <FormattedMessage
                                  id="modal.txDetails.purchaseRequest.owner"
                                  defaultMessage="" />{" "}
                              </span>
                            </Col>

                            <Col span={8} className="info">
                              {prData.prOwnerName}
                            </Col>
                          </Fragment>
                        )}
                      </Row>

                      <Row className="read-only-content">
                        {prData.reference && (
                          <Fragment>
                            <Col span={4}>
                              <span className="title">
                                <FormattedMessage
                                  id="modal.txDetails.common.refNumber"
                                  defaultMessage="" />
                              </span>
                            </Col>

                            <Col span={8} className="info">
                              {prData.reference}
                            </Col>
                          </Fragment>
                        )}
                      </Row>
                    </Skeleton>
                  </Col>

                  <Col span={8} offset={3}>
                    <Skeleton loading={props.detailLoading} active paragraph={{ rows: 5 }}>

                      <Row className="read-only-content">
                        <Col span={13} className="text-right">
                          <span className="title">
                            <FormattedMessage
                              id="modal.txDetails.purchaseRequest.purchaseRequest"
                              defaultMessage="" />
                          </span>
                        </Col>

                        <Col span={11} className="info">
                          {prData.purchaseRequestNumber}
                        </Col>
                      </Row>

                      <Row className="read-only-content">
                        <Col span={13} className="text-right">
                          <span className="title">
                            <FormattedMessage
                              id="modal.txDetails.purchaseRequest.prDate"
                              defaultMessage="" />
                          </span>
                        </Col>

                        <Col span={11} className="info">
                          {prData.purchaseRequestDate &&
                            getMomentDateForUIReadOnly({
                              date: prData.purchaseRequestDate,
                              format: CONSTANTS.DISPLAY_DATE_FORMAT,
                            })}
                        </Col>
                      </Row>

                      <Row className="read-only-content">
                        <Col span={13} className="text-right">
                          <span className="title">
                            <FormattedMessage
                              id="modal.txDetails.purchaseRequest.expireDate"
                              defaultMessage="" />{" "}
                          </span>
                        </Col>

                        <Col span={11} className="info">
                          {prData.prExpirationDate &&
                            getMomentDateForUIReadOnly({
                              date: prData.prExpirationDate,
                              format: CONSTANTS.DISPLAY_DATE_FORMAT,
                            })}
                        </Col>
                      </Row>

                      {/* <Row className="read-only-content">
                        <Col span={13} className="text-right">
                            <span className="title">Email if RFQ/PO is Created </span>
                        </Col>

                        <Col span={11} className="info">
                            {prData.emailIfRFQOrPOIsGenerated ? 'Yes' : 'No'}
                        </Col>
                    </Row> */}

                      {/* <Row className="read-only-content">
                        {
                            prData.emailIfRFQOrPOIsGenerated ?
                                <Fragment>
                                    <Col span={13} className="text-right">
                                        <span className="title">Email </span>
                                    </Col>

                                    <Col span={11} className="info">
                                        {prData.emailAddress || ''}
                                    </Col>
                                </Fragment> : <Fragment/>
                        }
                    </Row> */}
                      {prData.orderPriority ? (
                        <Row className="read-only-content">
                          <Col span={13} className="text-right">
                            <span className="title">
                              <FormattedMessage
                                id="modal.txDetails.common.priority"
                                defaultMessage="" />{" "}
                            </span>
                          </Col>

                          <Col span={11} className="info">
                            {prData.orderPriority}
                          </Col>
                        </Row>
                      ): ""}
                      {prData.projectMasterId ? (
                        <Row className="read-only-content">
                          <Col span={13} className="text-right">
                            <span className="title">
                              <FormattedMessage
                                id="modal.txDetails.common.project"
                                defaultMessage="" />
                            </span>
                          </Col>
                          <Col span={11} className="info">
                            {/* {prData.projectMasterId + "-" + prData.projectName} */}
                            {prData.projectName}

                          </Col>
                        </Row>
                      ):""}
                    </Skeleton>
                  </Col>
                </Row>
                <Skeleton loading={props.detailLoading} active paragraph={{ rows: 5 }}>

                  <div className="modal-table-container vertical-space">
                    <table className="transaction-details-table">
                      <thead>
                        <tr>
                          <th className="cell-size-50">#</th>
                          {(!isColumnEmpty.product || !isColumnEmpty.description) && (
                            <th>
                              {!isColumnEmpty.product && (
                                <span>
                                  {" "}
                                  <FormattedMessage
                                    id="modal.txDetails.common.product"
                                    defaultMessage="" />
                                </span>
                              )}
                              {!isColumnEmpty.product &&
                                !isColumnEmpty.description && <span> & </span>}
                              {!isColumnEmpty.description && (
                                <span>
                                  {" "}
                                  <FormattedMessage
                                    id="modal.txDetails.common.description"
                                    defaultMessage="" />
                                </span>
                              )}
                            </th>
                          )}
                          {/* {!isColumnEmpty.variant && <th>Variant</th>} */}
                          {!isColumnEmpty.qty && (
                            <th className="cell-size-100">
                              <FormattedMessage
                                id="modal.txDetails.common.qty"
                                defaultMessage="" />
                            </th>
                          )}
                          {/* {!isColumnEmpty.uomName && <th>UOM</th>} */}
                        </tr>
                      </thead>
                      <tbody>
                        {(purchaseRequestDetailsList || []).sort((a, b) => Number(a.sNo) > (b.sNo) ? 1 : -1)?.map((item, i) => {
                          return (
                            <tr key={i}>
                              <td className="cell-size-50">{i + 1}</td>
                              {(!isColumnEmpty.product ||
                                !isColumnEmpty.description) && (
                                  <td>
                                    <strong>{getVariantFragment(item)}</strong>
                                    {!isColumnEmpty.description && (
                                      <div className="description-cell pr100">
                                        {ReactHtmlParser(item.description || "-")}
                                      </div>
                                    )}
                                  </td>
                                )}
                              {/* {!isColumnEmpty.variant && <td>{item.itemVariantName || '-'}</td>} */}
                              {!isColumnEmpty.qty && (
                                <td className="cell-size-100">
                                  {item.quantity +
                                    (item.uomName ? " " + item.uomName : "")}
                                </td>
                              )}
                              {/* {!isColumnEmpty.uomName && <td>{item.uomName || '-'}</td>} */}
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>

                  <Row>
                    <Col span={9}>
                      <Row className="read-only-content">
                        {prData.docDetailList && prData.docDetailList.length ? (
                          <Row>
                            <Col span={24} className="title">
                              <FormattedMessage
                                id="modal.txDetails.common.attachments"
                                defaultMessage="" />
                            </Col>
                            <Col span={24}>
                              <AWSFileTable
                                {...props}
                                docDetailList={prData.docDetailList}
                                fetchTxDetail={(data) => {
                                  props.fetchPurchaseRequestDetail({
                                    supplierId: prData.supplierId || 0,
                                    purchaseRequestMasterId: prData.purchaseRequestMasterId,
                                    prMasterId: prData.purchaseRequestMasterId,
                                    relationshipId: prData.relationshipId,
                                  });
                                } } />
                              {/* {
                                    prData.docDetailList.map((doc) => {
                                        return <span className={"cursor-pointer"} onClick={() => {
                                            props.downloadFileFromBucket({
                                                bucketName: doc.bucketName,
                                                awsKeyName: doc.awsKeyName,
                                                fileName: doc.fileName
                                            })
                                        }}>{doc.fileName} &nbsp;</span>
                                    })
                                } */}
                            </Col>
                          </Row>
                        ) : null}
                        {prData.reason && (
                          <Fragment>
                            <Col span={8}>
                              <span className="title">
                                <FormattedMessage
                                  id="modal.txDetails.purchaseRequest.reason"
                                  defaultMessage="" />{" "}
                              </span>
                            </Col>

                            <Col span={16} className="info">
                              {ReactHtmlParser(prData.reason)}
                            </Col>
                          </Fragment>
                        )}
                      </Row>

                      <Row className="read-only-content">
                        {prData.shippingInstructions && (
                          <Fragment>
                            <Col span={8}>
                              <span className="title">
                                <FormattedMessage
                                  id="modal.txDetails.common.shippingInstructions"
                                  defaultMessage="" />{" "}
                              </span>
                            </Col>

                            <Col span={16} className="info">
                              {ReactHtmlParser(prData.shippingInstructions)}
                            </Col>
                          </Fragment>
                        )}
                      </Row>

                      <Row className="read-only-content " style={{ display: "flex", flexDirection: "column" }}>
                        {prData.remarksEmployee && (
                          <Fragment>
                            <Col span={8}>
                              <span className="remarks-heading">
                                <FormattedMessage
                                  id="modal.txDetails.common.employeeRemarks"
                                  defaultMessage="" />{" "}
                              </span>
                            </Col>

                            <Col span={16} className="info">
                              {ReactHtmlParser(prData.remarksEmployee)}
                            </Col>
                          </Fragment>
                        )}
                      </Row>

                      <Row className="read-only-content">
                        {prData.remarksSupplier && (
                          <Fragment>
                            <Col span={8}>
                              <span className="title">
                                <FormattedMessage
                                  id="modal.txDetails.common.supplierRemarks"
                                  defaultMessage="" />{" "}
                              </span>
                            </Col>

                            <Col span={16} className="info">
                              {ReactHtmlParser(prData.remarksSupplier)}
                            </Col>
                          </Fragment>
                        )}
                      </Row>
                    </Col>
                    <Col offset={7} span={8}>
                      <div
                        className="upload-link new-pic"
                        title={props.intl.formatMessage({
                          id: "uploadSignature.tooltip",
                        })}
                        style={{ width: "50px", height: "50px" }}
                      >
                        <CustomUploader {...customProps} />
                        {prData.stampAwsKey && (
                          <div
                            className="cursor-pointer"
                            onClick={() => {
                              props.deleteStamp(
                                {
                                  supplierId: prData.supplierId || 0,
                                  purchaseRequestMasterId: prData.purchaseRequestMasterId,
                                  prMasterId: prData.purchaseRequestMasterId,
                                  relationshipId: prData.relationshipId,
                                  objectKey: prData.stampAwsKey,
                                },
                                props
                              );
                            } }
                          >
                            {" "}
                            <FormattedMessage
                              id="modal.txDetails.common.deleteStamp"
                              defaultMessage="" />{" "}
                          </div>
                        )}
                      </div>
                    </Col>
                  </Row>
                </Skeleton>
                <div className="footer-ropage">
                  {prData.footer && (
                    <Fragment>{ReactHtmlParser(prData.footer)}</Fragment>
                  )}
                </div>
              </div></>
:""}
          {prData.status !== "Pending Approval" &&
          prData.status !== "Rejected" ? (
            <div className=" button-group-action header-control">
              <div className="small-btn-group">
                {permissionsData.update
                 && (
                  <Button
                    style={{ height: "38px" }}
                    title={props.intl.formatMessage({
                      id: "modal.txDetails.common.edit",
                      defaultMessage: "",
                    })}
                    icon={<EditOutlined />}
                    onClick={() => {
                      props.popModalFromStack();
                      props.updateState({
                        prMasterId: prData.purchaseRequestMasterId,
                        update: true,
                        
                      });

                      props.pushModalToStack({
                        modalBody: (
                          <StandardPurchaseRequest
                            {...props}
                            prEditData={prData}
                            prMasterId={prData.purchaseRequestMasterId}
                            update={true}
                          />
                        ),
                        width: "100%",
                        hideTitle: true,
                        hideFooter: true,
                        wrapClassName: "new-transaction-wrapper",
                      });
                    }}
                    // onClick={() => {
                    //     props.popModalFromStack();
                    //     props.history.push({
                    //         pathname: '/admin/pr-update',
                    //         state: {
                    //             prMasterId: prData.purchaseRequestMasterId,
                    //             update: true
                    //         }
                    //     })
                    // }}
                  ></Button>
                )}
                {permissionsData.create && (
                  <Button
                    style={{ height: "38px" }}
                    title={props.intl.formatMessage({
                      id: "modal.txDetails.common.clone",
                      defaultMessage: "",
                    })}
                    icon={<CopyOutlined />}
                    onClick={() => {
                      props.popModalFromStack();
                      props.updateState({
                        prMasterId: prData.purchaseRequestMasterId,
                        clone: true,
                      });

                      props.pushModalToStack({
                        modalBody: (
                          <StandardPurchaseRequest
                            {...props}
                            prEditData={prData}
                            prMasterId={prData.purchaseRequestMasterId}
                            clone={true}
                            update={false}
                          />
                        ),
                        width: "100%",
                        hideTitle: true,
                        hideFooter: true,
                        wrapClassName: "new-transaction-wrapper",
                      });
                      // props.history.push({
                      //     pathname: '/admin/pr-create',
                      //     state: {
                      //         prMasterId: prData.purchaseRequestMasterId,
                      //         clone: true
                      //     }
                      // })
                    }}
                  ></Button>
                )}
                {permissionsData.delete && (
                  <Button
                    style={{ height: "38px" }}
                    title={props.intl.formatMessage({
                      id: "modal.txDetails.common.delete",
                      defaultMessage: "",
                    })}
                    icon={<DeleteOutlined />}
                    onClick={() => {
                      props.showModal({
                        modalBody:
                          "Are you sure you want to delete this transaction?",
                        handleSubmit: () => {
                          props.popModalFromStack();
                          props.deletePurchaseRequest(
                            {
                              supplierId: prData.supplierId,
                              prMasterId: prData.purchaseRequestMasterId,
                              purchaseRequestMasterId:
                                prData.purchaseRequestMasterId,
                              relationshipId: prData.relationshipId,
                            },
                            props
                          );
                        },
                      });
                    }}
                  ></Button>
                )}
              </div>
              <div className="small-btn-group">
                <Button
                  style={{ height: "38px" }}
                  title={props.intl.formatMessage({
                    id: "modal.txDetails.common.email",
                    defaultMessage: "",
                  })}
                  icon={<MailOutlined />}
                  onClick={() => {
                    openEmailDrawer(props);
                  }}
                ></Button>

                <Button
                  style={{ height: "38px" }}
                  title={props.intl.formatMessage({
                    id: "modal.txDetails.common.downloadPDF",
                    defaultMessage: "",
                  })}
                  icon={<FilePdfOutlined />}
                  onClick={() => {
                    generatePDF();
                  }}
                ></Button>
                <Button
                  style={{ height: "38px" }}
                  title={props.intl.formatMessage({
                    id: "modal.txDetails.common.print",
                    defaultMessage: "",
                  })}
                  icon={<PrinterOutlined />}
                  onClick={() => {
                    printPdf();
                  }}
                ></Button>
              </div>
              <div className="small-btn-group">
                <Button
                  style={{ height: "38px" }}
                  title={props.intl.formatMessage({
                    id: "modal.txDetails.common.auditTrail",
                    defaultMessage: "",
                  })}
                  icon={<HistoryOutlined />}
                  onClick={() => {
                    openAuditDrawer(props, prData);
                  }}
                ></Button>
              </div>

              <div className="small-btn-group">
                {(prData.statusRFQ !== "converted" ||
                  prData.statusPO !== "converted") && (
                  <Select
                    defaultValue="Convert To"
                    style={{ width: 120 }}
                    onChange={handleConvertTo}
                  >
                    {rfqPermissionsData.create && prData.statusRFQ !== "converted" && (
                      <Option value="RFQ">
                        <FormattedMessage
                          id="modal.txDetails.purchaseRequest.convertTo.rfq"
                          defaultMessage=""
                        />
                      </Option>
                    )}
                    {poPermissionsData.create && prData.statusPO !== "converted" && (
                      <Option value="Purchase Order">
                        <FormattedMessage
                          id="modal.txDetails.purchaseRequest.convertTo.po"
                          defaultMessage=""
                        />
                      </Option>
                    )}
                  </Select>
                )}
              </div>
              {/* {prData.statusRFQ !== 'Converted' ?
                                <Button

                                    onClick={() => {
                                        props.popModalFromStack();

                                    }}
                                    type="primary"
                                    icon={<FileTextOutlined/>}
                                >
                                    <Link
                                        to={
                                            {
                                                pathname: '/admin/rfq-create',
                                                state: {
                                                    purchaseRequestMasterId: prData.purchaseRequestMasterId
                                                }
                                            }}
                                    >
                                        Convert to RFQ
                                    </Link>
                                </Button>
                                :
                                ''}

                            {prData.statusPO !== 'Converted' ?
                                <Button

                                    onClick={() => {
                                        props.popModalFromStack();

                                    }}
                                    type="primary"
                                    icon={<FileTextOutlined/>}
                                >
                                    <Link
                                        to={
                                            {
                                                pathname: '/admin/po-create',
                                                state: {
                                                    purchaseRequestMasterId: prData.purchaseRequestMasterId
                                                }
                                            }}
                                    >
                                        Convert to PO
                                    </Link>
                                </Button>
                                :
                                ''} */}
             {permissionsData.update ? <Button
                style={{ height: "38px" }}
                onClick={() => {
                  updateUserStatus(props, prData);
                }}
              >
                <FormattedMessage
                  id="modal.txDetails.common.updateStatus"
                  defaultMessage=""
                />
              </Button> : "" }
              {prData.isInquiryConversion ? (
                <div className="small-btn-group">
                  <Button
                    style={{ width: "auto" }}
                    onClick={() => {
                      updateInquiryStatus(props, prData);
                    }}
                  >
                    <FormattedMessage
                      id="modal.txDetails.inquiryStatus"
                      defaultMessage=""
                    />
                  </Button>
                </div>
              ) : (
                ""
              )}
              {prData.isSOConversion ? (
                <div className="small-btn-group" style={{ marginLeft: "10px" }}>
                  <Button
                    style={{ width: "auto" }}
                    onClick={() => {
                      updateSOPRStatus(props, prData);
                    }}
                  >
                    <FormattedMessage
                      id="modal.txDetails.soStatus"
                      defaultMessage=""
                    />
                  </Button>
                </div>
              ) : (
                ""
              )}
            </div>
          ) : (
            <div className="button-group-action">
              {prData.status !== "Rejected" &&
              (props.userInfo || {}).relationshipEmployeeId ===
                prData.approverEmpId ? (
                <div className=" button-group-action header-control">
                  <Button
                    type="primary"
                    onClick={() => {
                      approvePR();
                    }}
                  >
                    <FormattedMessage
                      id="modal.txDetails.purchaseRequest.approvePr"
                      defaultMessage=""
                    />
                  </Button>
                  <Button
                    type="primary"
                    onClick={() => {
                      handleDelegateApprover();
                    }}
                  >
                    <FormattedMessage
                      id="modal.txDetails.common.delegateApprover"
                      defaultMessage=""
                    />
                  </Button>
                  <Button
                    type="danger"
                    onClick={() => {
                      handleRejectApproval();
                    }}
                  >
                    <FormattedMessage
                      id="modal.txDetails.common.reject"
                      defaultMessage=""
                    />
                  </Button>
                </div>
              ) : (
                <div></div>
              )}
            </div>
          )}
        </div>
      </div>
         
    </div>
  );
};

const updateColumnEmptyCheck = (isColumnEmpty, prData) => {
  if (!prData) return isColumnEmpty;
  for (let i in prData.purchaseRequestDetailsList) {
    let row = prData.purchaseRequestDetailsList[i];
    if (row.itemName) {
      isColumnEmpty.product = false;
    }
    if (row.serviceName) {
      isColumnEmpty.service = false;
    }
    if (row.itemVariantName) {
      isColumnEmpty.variant = false;
    }
    if (row.description) {
      isColumnEmpty.description = false;
    }
    if (row.quantity) {
      isColumnEmpty.qty = false;
    }
    if (row.uomName) {
      isColumnEmpty.uomName = false;
    }
  }
  return isColumnEmpty;
};

const getVariantFragment = (item) => {
  let itemName = null;
  if (item.itemName) {
    itemName = item.itemName;
  }
  if (item.itemVariantName) {
    itemName = itemName + " (" + item.itemVariantName + ")";
  }
  return itemName ? itemName : "";
};

export default PRDetails;
