import React from 'react';
import { capitalizeFirstLetter, fixedDecimalNumber, getInitialsFromString } from '../../../utils';
import { Col, Row,Carousel,Skeleton } from 'antd';


const FeeDue = (props) => {
  const chunkArray = (array, size) => {
    const result = [];
    for (let i = 0; i < array.length; i += size) {
      result.push(array.slice(i, i + size));
    }
    return result;
  };

  const filteredInvoices = (props.proformaInvoiceList[1] || [])
  .filter(invoice => invoice.module === "school" && invoice.isInvoiceConverted === 0).slice(0, 9);;
  const invoiceData = chunkArray(filteredInvoices, 3);

    return<>
    <Skeleton loading={props.listLoading} paragraph={{ rows: 4 }} active>

    <div className="custom-carousel">
          <Carousel dots autoplay={false} >
      {invoiceData.map((val, i) => (
        <div key={i}>
          {val.map((data, index) => (
            <Row gutter={[16, 16]} key={index}>
              <Col span={8}>
                <div className='fee_container'>
                  <div className="name-icon">
                    {getInitialsFromString(data.customerName) || "AT"}
                  </div>
                  <div>
                    <b>{data.customerName}</b>
                    <div>12th A</div>
                  </div>
                </div>
              </Col>
              <Col span={8} className='dd-flex'>{fixedDecimalNumber(data.invoiceTotalAmount)}</Col>
              <Col span={8} className='dd-flex'>
                <div className='unpaid_label'>{capitalizeFirstLetter(data.status)}</div>
              </Col>
            </Row>
          ))}
        </div>
      ))}
    </Carousel>

    </div>
    </Skeleton>
    </>
};

export default FeeDue;