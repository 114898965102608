import { SO_ACTION_LIST } from '../static/constants';
const initialState = {
  soList: {
    1: []
  },
  isSoListingLoading: false,
  salesOrderCount: 0,
  soDetail: {},
  ecomDetail:{},
  sources: [],
  soPackageList: [],
  soShipmentList: [],
  soPackageDetail: {},
  soPackageData: {},
  soUnShippedList: [],
  freightList: [],
  carrierList: [],
  soInvoiceData: {},
  sqSoData: {},
  soDeliveyNotesDetail: [],
  soShippingInvoiceDetail: [],
  soUnfulfilledList: [],
  shipmentListForInvoice: [],
  soDraftList: {
    1: []
  },

  soEcomList: {
    1: []
  },
  soDraftCount: 0,
  soOnlineOrderCount: 0,
  soUnpackedList: [],
  soDataBySOId: {},
  cancelledSoList: {},
  cancelledSoCount: 0,
  aSoList: {
    1: []
  },
  amendSoInvoiceData:{},
  dataTry:[]
}

const SOReducer = (state = initialState, action) => {
  switch (action.type) {
    case SO_ACTION_LIST.SHOW_SO_LISTING_LOADER:
      return { ...state, isSoListingLoading: true };
    case SO_ACTION_LIST.HIDE_SO_LISTING_LOADER:
      return { ...state, isSoListingLoading: false };
    case SO_ACTION_LIST.SO_LIST:
      return {
        ...state,
        soList: {
          ...state.soList,
          [action.data.pageNo || 1]: action.data.list
        }
      };
    case SO_ACTION_LIST.SO_DATA_BY_CUSTOMER_ID:
      return { ...state, soDataBySOId: action.data };
    case SO_ACTION_LIST.RESET_SO_DATA_BY_CUSTOMER_ID:
      return { ...state, soDataBySOId: initialState.soDataBySOId };
    case SO_ACTION_LIST.SALES_ORDER_COUNT:
      return { ...state, salesOrderCount: action.data };
    case SO_ACTION_LIST.SO_LIST_PAGE_RESET:
      return { ...state, soList: initialState.soList };
    case SO_ACTION_LIST.SO_DETAIL:
      return { ...state, soDetail: action.data };
    case SO_ACTION_LIST.SO_SOURCES:
      return { ...state, sources: action.data };
    case SO_ACTION_LIST.SO_SOURCES_ADDITION:
      return { ...state, sources: [action.data, ...state.sources] };
    case SO_ACTION_LIST.SO_SOURCES_DELETION:
      return { ...state, sources: state.sources.filter(x => x.soSourceId !== action.data.id) };
    case SO_ACTION_LIST.SO_PACKAGE_LIST:
      return { ...state, soPackageList: action.data };
    case SO_ACTION_LIST.SO_SHIPMENT_LIST:
      return { ...state, soShipmentList: action.data };
    case SO_ACTION_LIST.SO_PACKAGE_DETAIL:
      return { ...state, soPackageDetail: action.data };
    case SO_ACTION_LIST.RESET_SO_PACKAGE_DETAIL:
      return { ...state, soPackageDetail: initialState.soPackageDetail };
    case SO_ACTION_LIST.SO_DELIVERY_NOTES_DETAIL:
      return { ...state, soDeliveyNotesDetail: action.data };
    case SO_ACTION_LIST.SO_DELIVERY_NOTES_DETAIL_RESET:
      return { ...state, soDeliveyNotesDetail: initialState.soDeliveyNotesDetail };
    case SO_ACTION_LIST.SO_SHIPPING_INVOICE_DETAIL:
      return { ...state, soShippingInvoiceDetail: action.data };
    case SO_ACTION_LIST.SO_PACKAGE_DATA:
      return { ...state, soPackageData: action.data };
    case SO_ACTION_LIST.SO_PACKAGE_DATA_RESET:
      return { ...state, soPackageData: initialState.soPackageData };
    case SO_ACTION_LIST.SO_UN_SHIPPPED_LIST:
      return { ...state, soUnShippedList: action.data };
    case SO_ACTION_LIST.FRIEGHT_LIST:
      return { ...state, freightList: action.data };
    case SO_ACTION_LIST.ADD_FRIEGHT_TYPE:
      return { ...state, freightList: [action.data, ...state.freightList] };
    case SO_ACTION_LIST.DELETE_FRIEGHT_TYPE:
      return { ...state, freightList: state.freightList.filter(x => x.freightTypeId !== action.data.id) };
    case SO_ACTION_LIST.CARRIER_LIST:
      return { ...state, carrierList: action.data };
    case SO_ACTION_LIST.ADD_CARRIER_NAME:
      return { ...state, carrierList: [action.data, ...state.carrierList] };
    case SO_ACTION_LIST.DELETE_CARRIER_NAME:
      return { ...state, carrierList: state.carrierList.filter(x => x.carrierId !== action.data.id) };
    case SO_ACTION_LIST.SO_INVOICE_DATA:
      return { ...state, soInvoiceData: action.data };
    case SO_ACTION_LIST.RESET_SO_INVOICE_DATA:
      return { ...state, soInvoiceData: initialState.soInvoiceData };
    case SO_ACTION_LIST.SQ_SO_DATA:
      return { ...state, sqSoData: action.data };
    case SO_ACTION_LIST.RESET_SQ_SO_DATA:
      return { ...state, sqSoData: initialState.sqSoData };
    case SO_ACTION_LIST.RESET_SO_DATA:
      return { ...state, soDetail: initialState.soDetail };
    case SO_ACTION_LIST.NEXT_SO_NUMBER:
      return { ...state, nextTxNumber: action.data };
    case SO_ACTION_LIST.NEXT_SO_NUMBER_RESET:
      return { ...state, nextTxNumber: '' };
    case SO_ACTION_LIST.SO_UNFULFILLED_LIST:
      return { ...state, soUnfulfilledList: action.data };
    case SO_ACTION_LIST.SO_UNFULFILLED_LIST_RESET:
      return { ...state, soUnfulfilledList: initialState.soUnfulfilledList };
    case SO_ACTION_LIST.SHIPMENT_LIST_FOR_INVOICE:
      return { ...state, shipmentListForInvoice: action.data };
    case SO_ACTION_LIST.SHIPMENT_LIST_FOR_INVOICE_RESET:
      return { ...state, shipmentListForInvoice: initialState.shipmentListForInvoice };
    case SO_ACTION_LIST.SO_DRAFT_LIST:
      return {
        ...state,
        soDraftList: {
          ...state.soDraftList,
          [action.data.pageNo || 1]: action.data.list
        }
      };
    case SO_ACTION_LIST.SO_DRAFT_COUNT:
      return { ...state, soDraftCount: action.data };
    case SO_ACTION_LIST.SO_DRAFT_LIST_PAGE_RESET:
      return { ...state, soDraftList: initialState.soDraftList };
    case SO_ACTION_LIST.SO_UNPACKED_LIST:
      return { ...state, soUnpackedList: action.data };
    case SO_ACTION_LIST.SO_UNPACKED_LIST_RESET:
      return { ...state, soUnpackedList: initialState.soUnpackedList };

    case SO_ACTION_LIST.SALES_ORDER_ONLINE_COUNT:
      return { ...state, soOnlineOrderCount: action.data };
      case SO_ACTION_LIST.SO_ONLINE_LIST:
    return {
      ...state,
      soEcomList: {
        ...state.soEcomList,
        [action.data.pageNo || 1]: action.data.list
      }
    };
    case SO_ACTION_LIST.ECOM_DETAILS:
      return { ...state, ecomDetail: action.data };
    case SO_ACTION_LIST.CANCELLED_SO_LIST:
      return {
        ...state,
        cancelledSoList: {
          ...state.cancelledSoList,
          [action.data.pageNumber || 1]: action.data.list
        }
      };
    case SO_ACTION_LIST.CANCELLED_SO_COUNT:
      return { ...state, cancelledSoCount: action.data };
      case SO_ACTION_LIST.AMEND_SO_LIST:
        return {
          ...state,
          aSoList: {
            ...state.aSoList,
            [action.data.pageNo || 1]: action.data.list
          }
        };
    case SO_ACTION_LIST.AMEND_SO_INVOICE_DATA:
        return { ...state, amendSoInvoiceData: action.data };

    case SO_ACTION_LIST.SO_UN_SHIPPPED_LIST_RESET:
        return { ...state, soUnShippedList: initialState.soUnShippedList };

        case SO_ACTION_LIST.SO_DRAFT_LIST_PAGE_TRY:
          return { ...state, dataTry: action.data }; 

    case SO_ACTION_LIST.SO_UNSHIPPED_LIST_PAGE_RESET:
        return { ...state, dataTry: initialState.dataTry }; 
    default:
      return state;
  }
};

export default SOReducer;