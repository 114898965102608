import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import TeacherComp from "../../../components/schoolManagement/teacher";
import { fetchPaginationDataIfNeeded } from "../../../utils";
import { getTeacher, getTeacherCount, deleteTeacher, resetPaginatedData, deleteAssignedClass, exportStudentExcelDataForTeacher } from "./action";
import { showModal, hideModal, pushModalToStack, popModalFromStack, deleteEmployee } from './../../../actions/commonActions';
import { getSubdomain } from '../../modal/modalBody/settings/CompanyInfo/action';
import TeacherListFilterComp from "../../../components/schoolManagement/teacher/TeacherListFilter";
import EmployeeDrawer from '../../drawer/company/EmployeeManagement/Employee';

// import { FormattedMessage } from 'react-intl';

class Teacher extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageNumber: 1,
      pageSize: 25,
    };
  }

  componentDidMount() {
    let self = this;
    const companyInfo = this.props.companyInfo || {};
    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      relationshipEmployeeId: (this.props.userInfo || {}).relationshipEmployeeId,
      pageNumber: 1,
      pageSize: 100,
    };
    fetchPaginationDataIfNeeded("getTeacher", "teacherList", this.props, payload);
    this.props.getSubdomain(payload, function (stat) { self.setState({ isSubDomainLoaded: stat }) });
    // fetchDataIfNeeded("getTeacherCount", "teacherCount", this.props, payload);
    this.props.getTeacherCount(payload);
  }
  componentWillReceiveProps(props) {
    if (props.subdomain && props.subdomain.alpideSubdomainId && !this.state.subdomainName) {
      this.setState({
        subdomainName: props.subdomain.subdomainName ? 'https://' + props.subdomain.subdomainName : window.location.origin
      });
    }

  }

  render() {
    return (
      <>
      <TeacherComp
        {...this.props}
        {...this.state}
        updateState={(data) => {
          this.setState(data);
        }}
      ></TeacherComp>
            {this.state.employeeDrawer && <EmployeeDrawer {...this.state} {...this.props} updateState={(data) => { this.setState(data) }} />}

       {this.state.teacherListFilterDrawerVisible && <TeacherListFilterComp {...this.state} {...this.props} updateState={(data) => { this.setState(data) }}  />}
    </>

    );
  }
}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    userInfo: state.common.userInfo,
    relationshipId: state.common.companyInfo.relationshipId,
    teacherList: state.school.teacherList,
    teacherCount: state.school.teacherCount,
    subdomain: state.common.subdomain,
    listLoading: state.common.listLoading,
    headerLoading: state.common.headerLoading,
    permissions: state.auth.permissions,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getTeacher, 
      getTeacherCount, 
      deleteTeacher,
      resetPaginatedData,
      showModal,
      hideModal,
      pushModalToStack, 
      popModalFromStack,
      deleteAssignedClass, 
      deleteEmployee,
      exportStudentExcelDataForTeacher ,
      getSubdomain,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Teacher);
