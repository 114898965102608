import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Warning from '../../../../components/company/EmployeeManagement/Warning';
import WarningDrawer from '../../../drawer/company/EmployeeManagement/Warning';


class WarningContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedMenuItem : 0
    }
    };
  

  componentDidMount(props) {
    const payload = {
      relationshipId: (this.props.companyInfo || {}).relationshipId,
     
    }
   
  }

  componentWillReceiveProps(props) {
   
  }

  render() {
    return <><Warning {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
    {this.state.warningDrawer && <WarningDrawer {...this.state} {...this.props} updateState={(data) => { this.setState(data) }} />}
    </>
    ;
  }

}

const mapStateToProps = (state) => {
  return {

    companyInfo: state.common.companyInfo,
    listLoading: state.common.listLoading,
    drawerLoading:state.common.drawerLoading,
    headerLoading:state.common.headerLoading,
    permissions: state.auth.permissions,
   
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(WarningContainer);
