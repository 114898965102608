import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import TaxComponent from '../../../components/finance/Taxes';
import { fetchTaxes, deleteTax, showModal, hideModal } from './../../../actions/commonActions';
import { fetchDataIfNeeded } from '../../../utils';
import { saveSingleTaxRate } from '../../modal/modalBody/common/NewTax/action';

class JournalComp extends Component {

    constructor(props) {
        super();
        this.state = {
            searchedText: ''
        }
    }

    componentWillMount() {
        this.props.updateHeaderState({
            collapsed: false
        });
    }

    componentDidMount() {
        const companyInfo = this.props.companyInfo || {};
        const payload = {
            relationshipId: companyInfo.relationshipId
        };
        fetchDataIfNeeded('fetchTaxes', 'taxes', this.props, payload);
    }



    render() {
        return <TaxComponent {...this.state} {...this.props} updateState={(data) => { this.setState(data) }} />
    }

}


const mapStateToProps = (state) => {
    return {
        companyInfo: state.common.companyInfo,
        isLoading: state.common.isLoading,
        taxes: state.common.taxes,
        permissions: state.auth.permissions,
        listLoading: state.common.listLoading
    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchTaxes,
    deleteTax,
    saveSingleTaxRate,
    showModal, hideModal
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(JournalComp);
