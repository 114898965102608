import React, { Fragment } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
// import { Link } from 'react-router-dom';
// import { AgGridReact } from 'ag-grid-react';
import { Tooltip, Skeleton, Pagination, Checkbox,Empty } from 'antd';
import { fetchPaginationDataIfNeeded, showToasterMessage } from '../../../../utils'
import SalesInvoiceDetail from '../../../../containers/modal/modalBody/customer/SalesInvoiceDetail';
//import { AG_GRID_CONSTANTS } from '../../../../static/agGridConstants';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import './index.scss';
import checkIcon from '../../../../assets/images/check.png';
import crossIcon from '../../../../assets/images/cross.png';
//const format = CONSTANTS.DISPLAY_DATE_FORMAT;
import find from 'lodash.find';
import CustomerProfile from '../../../../containers/modal/modalBody/customer/Profile'

const InvoiceReminderComp = (props) => {
    const { selectedReminderInvoices, invoiceReminderList, updateState, companyInfo, invoiceReminderCount, pageNumber } = props;

    if (!selectedReminderInvoices) {
        props.updateState({ selectedReminderInvoices: [] });
    }

    const showTotal = (total) => {
        return props.intl.formatMessage({ id: 'pagination.text.total', defaultMessage: '' }) + ` ${total} ` + props.intl.formatMessage({ id: 'pagination.text.items', defaultMessage: '' });
    };

    const loadPageData = (pageNumber, pageSize) => {
        const payload = { companyInfo, relationshipId: companyInfo.relationshipId, pageNumber: pageNumber, pageSize: pageSize };
        fetchPaginationDataIfNeeded('fetchRecurringInvoices', 'invoiceReminderList', props, payload);
    }
    const handlePageSizeChange = (pageNumber, pageSize, props) => {
        const payload = { companyInfo, relationshipId: companyInfo.relationshipId, pageNumber: pageNumber, pageSize: pageSize };
        props.resetPaginatedData();
        props.fetchRecurringInvoices(payload);
        //props.updateState({ pageSize: pageSize, pageNumber: pageNumber })
    }

    // const onGridReady = (params) => {
    //     params.api.sizeColumnsToFit();
    //     props.updateState({
    //         gridApi: params.api,
    //         gridColumnApi: params.columnApi,
    //     });
    // }

    const openSalesInvoiceDetails = (data) => {
        props.pushModalToStack({
            modalBody: <SalesInvoiceDetail {...props} invoicePayload={data} />,
            width: '100%',
            hideFooter: true,
            wrapClassName: 'modal-custom-detail'
        })
    }


    // const _isColumnToHide = (columnName) => {
    //     return (props.txColumns.indexOf(columnName) < 0 && props.txMandatoryColumns.indexOf(columnName) < 0) ? true : false;
    // }

    // const columnDefs = [
    //     {
    //         field: 'isChecked',
    //         colId: 'isChecked_1',
    //         headerComponentFramework: () => <Fragment />,
    //         cellRendererFramework: (params) => {
    //             return <Checkbox
    //                 onChange={(e) => {
    //                     params.node.setDataValue('isChecked_1', e.target.checked);
    //                     params.node.data.isChecked = e.target.checked;
    //                 }}
    //                 checked={params.data.isChecked} />
    //         },
    //         width: 50,
    //         resizable: false
    //     },

    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='customer.salesInvoice.listing.header.invoiceNo' defaultMessage='' />;
    //         },
    //         field: "invoiceNumber",
    //         resizable: true,
    //         //hide: _isColumnToHide('invoiceNumber'),
    //         colId: 'invoiceNumber_1',
    //         width: (props.txColumnSetting['invoiceNumber'] || {}).width,
    //         cellRendererFramework: (link) => <div className="cursor-pointer" onClick={() => {
    //             openSalesInvoiceDetails(link.data)
    //         }}>{link.data.invoiceNumber}</div>,
    //     },

    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='customer.salesInvoice.listing.header.customer' defaultMessage='' />;
    //         },
    //         field: "customerName",
    //         resizable: true,
    //         //hide: _isColumnToHide('customerName'),
    //         colId: 'customerName_1',
    //         width: (props.txColumnSetting['customerName'] || {}).width,
    //         cellRendererFramework: (link) => <div className="cursor-pointer" >
    //             <Link
    //                 to={
    //                     {
    //                         pathname: 'customers/details',
    //                         state: {
    //                             customerId: link.data.customerId,
    //                             customerName: link.data.customerName,
    //                         }
    //                     }}
    //                 className='company-name'
    //             >
    //                 {link.data.customerName}
    //             </Link>
    //         </div>,
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <div><FormattedMessage id='common.active' defaultMessage='' />   </div>;
    //         },
    //         field: "isActive",
    //         colId: 'isActive_1',
    //         width: 80,
    //         cellRendererFramework: (link) => {
    //             return link.value ? <img src={checkIcon} style={{ 'height': '15px', 'width': '15px' }} alt="Invoice" /> : <img src={crossIcon} style={{ 'height': '10px', 'width': '10px' }} alt="Invoice" />;
    //         },
    //         resizable: true,
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <div><FormattedMessage id='recurringInvoice.setup.emailCustomer' defaultMessage='' />   </div>;
    //         },
    //         field: "email",
    //         colId: 'email_1',
    //         width: 120,
    //         cellRendererFramework: (link) => {
    //             return link.value ? <img src={checkIcon} style={{ 'height': '15px', 'width': '15px' }} alt="Invoice" /> : <img src={crossIcon} style={{ 'height': '10px', 'width': '10px' }} alt="Invoice" />;
    //         },
    //         resizable: true,
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <div><FormattedMessage id='drawer.customer.days' defaultMessage='' />   </div>;
    //         },
    //         field: "days",
    //         colId: 'days_1',
    //         width: 80,
    //         resizable: true,
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <div><FormattedMessage id='notify.text' defaultMessage='' />   </div>;
    //         },
    //         field: "isAfterDueDate",
    //         colId: 'isAfterDueDate_1',
    //         width: 120,
    //         cellRendererFramework: (link) => {
    //             return link.value ? <div><FormattedMessage id='after.text' /></div> : <div><FormattedMessage id='before.text' /></div>
    //         },
    //         resizable: true,
    //     }
    // ]


    // const agGridStyle = {
    //     height: '100%',
    //     width: '100%'
    // };

    // const onModelUpdated = (params) => {
    //     //params.api.resetRowHeights();
    //     if ((invoiceReminderList[pageNumber] || []).length && !props.isRowHeightSet) {
    //         setTimeout(() => {
    //             params.api.resetRowHeights();
    //         }, 1000)
    //         props.updateState({
    //             isRowHeightSet: true
    //         })
    //     }
    //     if (props.tableDataReRendered) {
    //         //params.api.resetRowHeights();
    //         props.updateState({
    //             tableDataReRendered: false
    //         })
    //         const txColumnSetting = props.txColumnSetting && props.txColumnSetting.length ? props.txColumnSetting : params.columnApi.getColumnState();
    //         txColumnSetting.forEach((e) => {
    //             let columnName = e.colId.split("_")[0];
    //             if (columnName !== 'action') {
    //                 if (columnName === 'isChecked') {
    //                     e.hide = false;
    //                 } else {
    //                     e.hide = _isColumnToHide(columnName);
    //                 }
    //             } if (columnName === 'action') {
    //                 e.hide = false;
    //                 e.pinned = 'right';
    //             }
    //         });

    //         params.columnApi.setColumnState(txColumnSetting);
    //     }
    // }

    const itemSelection = (item) => {
        let selectItem = find(selectedReminderInvoices, { reminderSalesInvoiceId: Number(item.reminderSalesInvoiceId) });
        let updatedItems = [];
        if (selectItem) {
            updatedItems = selectedReminderInvoices.filter(obj => obj.reminderSalesInvoiceId !== item.reminderSalesInvoiceId);
        } else {
            updatedItems = JSON.parse(JSON.stringify(selectedReminderInvoices));
            updatedItems.push(item);
        }
        props.updateState({ selectedReminderInvoices: updatedItems });
    }

    const selectAll = (context) => {
        if (!context) {
            selectedReminderInvoices.splice(0, selectedReminderInvoices.length);
            props.updateState({ selectedReminderInvoices: selectedReminderInvoices });
            return;
        }
        for (let i = 0; i < invoiceReminderList[pageNumber].length; i++) {
            let item = invoiceReminderList[pageNumber][i];
            let selectItem = find(selectedReminderInvoices, { reminderSalesInvoiceId: Number(item.reminderSalesInvoiceId) });
            if (!selectItem) {
                selectedReminderInvoices.push(item);
            }
        }
        props.updateState({ selectedReminderInvoices: selectedReminderInvoices });
    }

    const isCheckedAll = () => {
        if (!invoiceReminderList || !invoiceReminderList[pageNumber] || invoiceReminderList[pageNumber].length === 0) {
            return false;
        }
        for (let i = 0; i < invoiceReminderList[pageNumber].length; i++) {
            let item = invoiceReminderList[pageNumber][i];
            let selectItem = find(selectedReminderInvoices, { reminderSalesInvoiceId: Number(item.reminderSalesInvoiceId) });
            if (!selectItem) {
                return false;
            }
        }
        return true;
    }

    return (
        <Fragment>
            {/* <div className="invoice-header">
                <PageBreadcrumb breadCrumbList={breadCrumbList} />
            </div> */}
            {/* <div className='view-container mt0'> */}

                    <div className="view-container-actions">
            <Skeleton loading={props.headerLoading} paragraph={false} active>
                    <div className="left-actions">
                        <div className="table-heading">
                            <FormattedMessage id={props.isSchoolUser ? 'heading.fees.feesInvoiceList':"heading.sales.salesInvoice"} defaultMessage='' />
                        </div>
                        {
                            invoiceReminderCount ? <>
                            <div className="vertical-sep" />
                            <div>{invoiceReminderCount}</div>
                            </>:''
                        }
                    </div>
                        <div className="right-action">
                            {/* <button className="all-checkbox-control">
                                <Checkbox
                                    checked={isAllChecked}
                                    onChange={(e) => {
                                        toggleAllChecked(e.target.checked);
                                        props.gridApi.forEachNode(obj => {
                                            obj.setDataValue('isChecked_1', e.target.checked);
                                        })
                                    }}
                                />
                            </button> */}

                            <button className="all-checkbox-control cursor-pointer" onClick={() => {
                                const invoiceList = [];
                                // props.gridApi.forEachNode(obj => {
                                //     if (obj.data.isChecked) {
                                //         invoiceList.push(obj.data);
                                //     }
                                // });
                                selectedReminderInvoices.forEach(obj => {
                                    invoiceList.push(obj);
                                });
                                if (invoiceList.length > 0) {
                                    const modalBody = <FormattedMessage id='common.delete.confirmation' defaultMessage='' />;
                                    const modalData = {
                                        modalBody,
                                        handleSubmit: () => {
                                            props.deleteInvoiceReminder({
                                                relationshipId: props.companyInfo.relationshipId,
                                                reminderSalesInvoiceList: invoiceList,
                                                pageNumber: props.pageNumber,
                                                pageSize: props.pageSize
                                            })
                                            props.hideModal();
                                            // toggleAllChecked(false);
                                        },
                                    };
                                    props.showModal(modalData);
                                } else {
                                    return showToasterMessage({
                                        description: props.intl.formatMessage({ id: 'common.record.notSelected.proceeding', defaultMessage: '' }),
                                        messageType: 'error'
                                    })
                                }
                            }}>
                                <Tooltip placement="bottom" title={props.intl.formatMessage(
                                    { id: 'delete.messaget', defaultMessage: '' }
                                )}>
                                    <i className="fa fa-trash" />
                                </Tooltip>
                            </button>

                            <button className="all-checkbox-control cursor-pointer" onClick={() => {
                                const invoiceList = [];
                                // props.gridApi.forEachNode(obj => {
                                //     if (obj.data.isChecked) {
                                //         invoiceList.push(obj.data.reminderSalesInvoiceId);
                                //     }
                                // });
                                selectedReminderInvoices.forEach(obj => {
                                    invoiceList.push(obj.reminderSalesInvoiceId);
                                });

                                if (invoiceList.length > 0) {
                                    const modalBody = <FormattedMessage id='invoiceReminder.inactive.confirmation' defaultMessage='' />;
                                    const modalData = {
                                        modalBody,
                                        handleSubmit: () => {
                                            props.updateIsActiveInvoicesReminder({
                                                relationshipId: props.companyInfo.relationshipId,
                                                reminderSalesInvoiceList: invoiceList,
                                                isActive: 0,
                                                pageNumber: props.pageNumber,
                                                pageSize: props.pageSize
                                            })
                                            props.hideModal();
                                            // toggleAllChecked(false);
                                        },
                                    };
                                    props.showModal(modalData);
                                } else {
                                    return showToasterMessage({
                                        description: props.intl.formatMessage({ id: 'common.record.notSelected.proceeding', defaultMessage: '' }),
                                        messageType: 'error'
                                    })
                                }
                            }} >
                                <Tooltip placement="bottom" title={props.intl.formatMessage(
                                    { id: 'in.Active', defaultMessage: '' }
                                )}>
                                    <i className="fa fa-times" />
                                </Tooltip>
                            </button>

                            <button className="all-checkbox-control cursor-pointer" onClick={() => {
                                const invoiceList = [];
                                // props.gridApi.forEachNode(obj => {
                                //     if (obj.data.isChecked) {
                                //         invoiceList.push(obj.data.reminderSalesInvoiceId);
                                //     }
                                // });
                                selectedReminderInvoices.forEach(obj => {
                                    invoiceList.push(obj.reminderSalesInvoiceId);
                                });
                                if (invoiceList.length > 0) {
                                    const modalBody = <FormattedMessage id='invoiceReminder.active.confirmation' defaultMessage='' />;
                                    const modalData = {
                                        modalBody,
                                        handleSubmit: () => {
                                            props.updateIsActiveInvoicesReminder({
                                                relationshipId: props.companyInfo.relationshipId,
                                                reminderSalesInvoiceList: invoiceList,
                                                isActive: 1,
                                                pageNumber: props.pageNumber,
                                                pageSize: props.pageSize
                                            })
                                            props.hideModal();
                                            // toggleAllChecked(false);
                                        },
                                    };
                                    props.showModal(modalData);
                                } else {
                                    return showToasterMessage({
                                        description: props.intl.formatMessage({ id: 'common.record.notSelected.proceeding', defaultMessage: '' }),
                                        messageType: 'error'
                                    })
                                }
                            }}>
                                <Tooltip placement="bottom" title={props.intl.formatMessage(
                                    { id: 'make.Active', defaultMessage: '' }
                                )}>
                                    <i className="fa fa-check" />
                                </Tooltip>
                            </button>


                        </div>
                </Skeleton>
                    </div>

                    {/* <div className='agGridWrapper'> */}
                        {/* <div className="ag-theme-balham" style={agGridStyle} >
                            <AgGridReact
                                columnDefs={columnDefs}
                                rowData={invoiceReminderList[pageNumber] || []}
                                rowDragManaged={true}
                                defaultColDef={{
                                    flex: 1,
                                    autoHeight: true,
                                    wrapText: true,
                                }}
                                domLayout={"autoHeight"}
                                animateRows={true}
                                //getRowHeight={getRowHeight}
                                onGridReady={onGridReady}
                                onModelUpdated={onModelUpdated}
                            // onColumnResized={onColumnResized}
                            // onDragStopped={() => {
                            //     onColumnMoved({}, true);
                            // }}
                            // //onColumnMoved={onColumnMoved}
                            >
                            </AgGridReact>
                        </div> */}
                 <Skeleton loading={props.listLoading} active paragraph={{ rows: 12 }}>

                        <div className="table-container">
                            <table id='reminder-invoce-list'>
                                <thead>
                                    <tr>
                                        <th>
                                            <Checkbox
                                                onChange={(e) => {
                                                    selectAll(e.target.checked);
                                                }}
                                                checked={isCheckedAll()}
                                            />
                                        </th>

                                        <th><FormattedMessage id='customer.salesInvoice.listing.header.invoiceNo' defaultMessage='' /></th>

                                        <th><FormattedMessage id='customer.salesInvoice.listing.header.customer' defaultMessage='' /></th>

                                        <th><FormattedMessage id='common.active' defaultMessage='' /></th>

                                        <th><FormattedMessage id='recurringInvoice.setup.emailCustomer' defaultMessage='' /></th>

                                        <th><FormattedMessage id='drawer.customer.days' defaultMessage='' /></th>

                                        <th><FormattedMessage id='notify.text' defaultMessage='' /> </th>

                                    </tr>
                                </thead>
                                <tbody>

                                    {invoiceReminderList && (invoiceReminderList[pageNumber] || []).length ?
                                        invoiceReminderList[pageNumber].map((item, i) => {
                                            return (<tr key={i}>
                                                <td>
                                                    <Checkbox
                                                        onClick={() => {
                                                            itemSelection(item);
                                                        }}
                                                        checked={find(selectedReminderInvoices, { reminderSalesInvoiceId: Number(item.reminderSalesInvoiceId) }) ? true : false}
                                                    />
                                                </td>

                                                <td>
                                                    <div className="cursor-pointer"
                                                        onClick={() => {
                                                            openSalesInvoiceDetails(item)
                                                        }}
                                                    >
                                                        {item.invoiceNumber}
                                                    </div>
                                                </td>

                                                <td>
                                                    <div className="cursor-pointer" >
                                                        <div 
                                                       onClick={() => {
                                                        props.pushModalToStack({  
                                                  
                                                          modalBody: <CustomerProfile {...props}
                                                          customerId= {item.customerId}
                                                        //   customerName={rowData.customerName}
                                                        //   customerDetails={rowData}
                                                      />,
                                                      width: '100%',
                                                      hideTitle: true,  
                                                      hideFooter: true,
                                                      wrapClassName: "new-transaction-wrapper",
                                                      })
                                                      }}
                                                      // onClick={() => {
                                                        //     props.history.push('customers/details', {
                                                        //         customerId: item.customerId,
                                                        //         customerName: item.customerName,
                                                        //     })
                                                        // }}
                                                        >
                                                            {item.customerName}
                                                        </div>
                                                    </div>
                                                </td>

                                                <td>
                                                    {item.isActive ? <img src={checkIcon} style={{ 'height': '15px', 'width': '15px' }} alt="Invoice" /> : <img src={crossIcon} style={{ 'height': '10px', 'width': '10px' }} alt="Invoice" />}
                                                </td>

                                                <td>

                                                    {item.email ? <img src={checkIcon} style={{ 'height': '15px', 'width': '15px' }} alt="Invoice" /> : <img src={crossIcon} style={{ 'height': '10px', 'width': '10px' }} alt="Invoice" />}

                                                </td>

                                                <td>
                                                    {item.days}
                                                </td>

                                                <td>
                                                    {item.isAfterDueDate ? <div><FormattedMessage id='after.text' /></div> : <div><FormattedMessage id='before.text' /></div>}
                                                </td>

                                            </tr>)
                                        })

                                        :  <tr key="empty-data-box">
                                        <td colSpan={"100%"} style={{ borderBottom: "none" }}>
                                            <Empty />
                                        </td>
                                    </tr>}
                                </tbody>
                            </table>
                        </div>

                    {/* </div> */}
                </Skeleton>
                <div className="footer">
                <Skeleton loading={props.headerLoading} paragraph={false} active>

                <div className="f-left"></div>
                <div className="f-right">
                <Pagination
                    size="small"
                    total={invoiceReminderCount}
                    showTotal={showTotal}
                    defaultPageSize={props.pageSize} showSizeChanger pageSizeOptions={[10, 25, 50, 100, 200]}
                    // showQuickJumper
                    onChange={(pageNumber, pageSize) => {
                        loadPageData(pageNumber, pageSize);
                        updateState({ pageSize: pageSize, pageNumber: pageNumber })
                    }}
                    onShowSizeChange={(pageNumber, pageSize) => {
                        handlePageSizeChange(pageNumber, pageSize, props)
                    }}
                />
            </div>
            </Skeleton>
            </div>
        </Fragment>
    );
};

export default injectIntl(InvoiceReminderComp);
