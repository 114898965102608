import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import StudentComp from "../../../components/schoolManagement/student";
import { fetchPaginationDataIfNeeded } from "../../../utils";
import {
  getRegisteredMemberByFormId,
  getRegisteredMemberCount,
  resetPaginatedData,
  getRegisteredMemberByClass,
  getRegisteredMemberCountByClass,
  getRegisteredMemberByCourse,
  getRegisteredMemberCountByCourse,
  deleteAssignedClass,
  updateStudentUserStatus,
  exportStudentExcelData,
  deleteStudent,
  generateInvoice,
  approveAndGenerateInvoice,
  getSchoolStudents,
  countSchoolStudents,
  approveAndEnrollStudent,
} from "./action";
import {
  showModal,
  hideModal,
  pushModalToStack,
  popModalFromStack,
} from "./../../../actions/commonActions";
import { getAllStudentForm } from "./../../Student/studentFormList/action";
import { getSchoolCourse } from "./../../schoolManagement/course/action";
import {
  getClassList,
  getClassListCount,
} from "./../../schoolManagement/class/action";
import EmailComponent from "../../../containers/drawer/recurringInvoiceEmail";
import * as find from "lodash.find";
import StudentlistFiltersComp from "../../../components/schoolManagement/student/StudentListFilter";
import { getSection } from "../section/action";
import { sendFeeReminder } from "../formRegistrations/action";

class Student extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageNumber: 1,
      pageSize: 25,
      isStudentLoaded: false,
      formNameMap: {},
      customerInfo: props.customerInfo,
    };
  }

  componentDidMount() {
    const companyInfo = this.props.companyInfo || {};
    const userInfo = this.props.userInfo || {};
    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      pageNumber: 1,
      pageSize: 25,
      relationshipEmployeeId: userInfo.relationshipEmployeeId,
      formId: 0,
    };
    fetchPaginationDataIfNeeded(
      "getAllStudentForm",
      "studentFormList",
      this.props,
      payload
    );
    this.props.getClassList(payload);
    this.props.getSection({...payload, pageSize: 100});
    this.props.getSchoolStudents(payload);
    this.props.countSchoolStudents(payload);
  }

  componentWillReceiveProps(props) {
    if (
      (props.studentFormList && props.studentFormList.length) ||
      props.userInfo.jobRoleName === "Teacher"
    ) {
      let selectedForm;
      if (
        this.props.defaultSchoolForm &&
        props.userInfo.jobRoleName !== "Teacher"
      ) {
        selectedForm =
          find(props.studentFormList, { registrationFormSettingId: 0 }) || {};
      }
      if (!this.state.isStudentLoaded) {
        const companyInfo = this.props.companyInfo || {};
        const userInfo = this.props.userInfo || {};
        const payload = {
          companyInfo,
          relationshipId: companyInfo.relationshipId,
          relationshipEmployeeId: userInfo.relationshipEmployeeId,
          pageNumber: 1,
          pageSize: 25,
          // formId: this.props.defaultSchoolForm || props.studentFormList[0].registrationFormSettingId
          formId: 0,
        };
        this.props.getRegisteredMemberByFormId(payload);
        this.props.getRegisteredMemberCount(payload);


        this.setState({
          isStudentLoaded: true,
          selectedForm: selectedForm,
        });
      }
    }
    if (
      !this.state.isFormNameMapInitialsed &&
      props.studentFormList?.length
    ) {
      let formNameMap = {};
      props.studentFormList.forEach((obj) => {
        formNameMap[obj.registrationFormSettingId] = obj.formShortName;
      });
      this.setState({
        formNameMap: formNameMap,
        isFormNameMapInitialsed: true,
      });
    }
  }

  render() {
    return (
      <div>
        {this.state.emailDetailDrawerVisible && (
          <EmailComponent
            {...this.state}
            {...this.props}
            updateState={(data) => {
              this.setState(data);
            }}
          />
        )}
        {this.state.studentListFilterDrawerVisible && (
          <StudentlistFiltersComp
            {...this.state}
            {...this.props}
            updateState={(data) => {
              this.setState(data);
            }}
          />
        )}
        <StudentComp
          {...this.props}
          {...this.state}
          updateState={(data) => {
            this.setState(data);
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {

  return {
    companyInfo: state.common.companyInfo,
    userInfo: state.common.userInfo,
    relationshipId: state.common.companyInfo.relationshipId,
    studentList: state.school.studentList,
    studentCount: state.school.studentCount,
    studentFormList: state.membershipReducer.studentFormList,
    courseList: state.school.courseList,
    classList: state.school.classList,
    defaultSchoolForm: state.common.defaultSchoolForm,
    sectionList: state.school.sectionList,
    schoolStudentList: state.school.schoolStudentList,
    schoolStudentsCount: state.school.schoolStudentCount,
    listLoading: state.common.listLoading,
    headerLoading: state.common.headerLoading,
    permissions: state.auth.permissions,
    saveLoading:state.common.saveLoading
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getRegisteredMemberByFormId,
      getRegisteredMemberCount,
      resetPaginatedData,
      getAllStudentForm,
      showModal,
      hideModal,
      pushModalToStack,
      popModalFromStack,
      getRegisteredMemberByClass,
      getRegisteredMemberCountByClass,
      getRegisteredMemberByCourse,
      getRegisteredMemberCountByCourse,
      getClassList,
      getClassListCount,
      getSchoolCourse,
      deleteAssignedClass,
      updateStudentUserStatus,
      exportStudentExcelData,
      deleteStudent,
      generateInvoice,
      approveAndGenerateInvoice,
      getSection,
      getSchoolStudents,
      countSchoolStudents,
      approveAndEnrollStudent,
      sendFeeReminder
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Student);
