import React,{useMemo} from "react";
import { Button } from "antd";
import { FormattedMessage } from "react-intl";
import {
  FilePdfOutlined,
  MailOutlined,
  PrinterOutlined,
  EditOutlined,
  StopOutlined,
  CopyOutlined,
  HistoryOutlined,
} from "@ant-design/icons";
import * as find from "lodash.find";
import * as filter from "lodash.filter";
import config from "../../../../../config/environmentConfig";
import {
  CONSTANTS,
  TRANSACTION_TYPES,
  MODAL_TYPE,
  MODULE_CODED_VALUES,
  PERMISSION_VALUES,
  CONSTANTS_TRANSACTIONS,
} from "../../../../../static/constants";
import { fixedDecimalNumber, checkACLPermission, getPermissionData } from "../../../../../utils";
import PayInvoice from "../../../../../containers/supplier/invoice/PayInvoice";
import { Dropdown } from "../../../../general/Dropdown";
import StandardTemplate from "./standard";
import Template2 from "./template2";
import ApplyPurchaseCredits from "../../../../../containers/modal/modalBody/supplier/ApplyPurchaseCredits";
import StandardPurchaseInvoice from "../../../../../containers/supplier/invoice/StandardPurchaseInvoice";

const PurchaseInvoiceDetails = (props) => {
  const {
    invoiceData,
    companyInfo,
    templateName = "Professional",
    permissions,
  } = props;
  const primaryPerm = permissions.primary;
  const { supplierInvoiceDetailsList } = invoiceData;
  const invoicePermissionsData = useMemo(()=>{
    return getPermissionData(primaryPerm, MODULE_CODED_VALUES.PURCHASE_INVOICE )
  },[])
  const paymentPermissionsData = useMemo(()=>{
    return getPermissionData(primaryPerm, MODULE_CODED_VALUES.PURCHASE_PAYMENT )
  },[])
  const relationshipAddress =
    find(invoiceData.boLocationPurchaseInvoiceList, {
      locationType: CONSTANTS.RELATIONSHIP_BILLING_ADDRESS,
    }) || {};
  const billingAddress =
    find(invoiceData.boLocationPurchaseInvoiceList, {
      locationType: CONSTANTS.BILLING_ADDRESS,
    }) || {};
  const relShippingAddress =
    find(invoiceData.boLocationPurchaseInvoiceList, {
      locationType: CONSTANTS.RELATIONSHIP_SHIPPING_ADDRESS,
    }) || {};
  let isPlaceOfSupplySameAsRelationship = false;
  isPlaceOfSupplySameAsRelationship = (invoiceData.placeOfSupply && billingAddress.stateName === invoiceData.placeOfSupply) ||  false;
  let itemLevelTaxes = [];
  let itemLevelTaxeByHsn = [];
  let uomList = [];
  if (supplierInvoiceDetailsList) {

    itemLevelTaxes = calculateTaxRateList(
      supplierInvoiceDetailsList,
      isPlaceOfSupplySameAsRelationship, invoiceData
    );
    itemLevelTaxeByHsn = calculateTaxRateListbyHsn(supplierInvoiceDetailsList, isPlaceOfSupplySameAsRelationship, invoiceData,props)
    uomList = calculateUomData(supplierInvoiceDetailsList, isPlaceOfSupplySameAsRelationship)
  }
  let isColumnEmpty = {
    product: true,
    service: true,
    variant: true,
    description: true,
    qty: true,
    uomName: true,
    rate: true,
    hsn: true,
    discount: true,
    tax: true,
    specialDiscount: true,
    documentDetails: true,
  };
  if (invoiceData) {
    isColumnEmpty = updateColumnEmptyCheck(isColumnEmpty, invoiceData, props);
  }
  const getPayloadForPdf = () => {
    return {
      templateName: templateName,
      fileName: (invoiceData.invoiceNumber || "Purchase Invoice") + ".pdf",
      transactionName: "purchaseInvoice",
      invoiceData: invoiceData,
      relationshipAddress: relationshipAddress,
      relShippingAddress: relShippingAddress,
      billingAddress: billingAddress,
      companyInfo: companyInfo,
      isColumnEmpty: isColumnEmpty,
      isPlaceOfSupplySameAsRelationship: isPlaceOfSupplySameAsRelationship,
      itemLevelTaxes: itemLevelTaxes,
      bucketName: config.BUCKET_NAME.BO_SUPPLIER_INVOICE,
      stampAwsKey: invoiceData.stampAwsKey,
      // hsnTableData:itemLevelTaxeByHsn?.filter(item=>item.hsnCode > 0)
      hsnTableData:itemLevelTaxeByHsn?.filter(item=>item.hsnCode),
      uomList:uomList?.filter(item=>item.uomName?.length),
    };
  };
 
  const generatePDF = () => {
    let payload = getPayloadForPdf();
    props.generatePDF(payload);
  };

  const printPdf = () => {
    let payload = getPayloadForPdf();
    props.printPDF(payload);
  };

  const openEmailDrawer = (props) => {
    let payload = getPayloadForPdf();
    payload.fileDataReqeired = true;
    payload.supplierId = invoiceData.supplierId;
    payload.invoiceMasterId = invoiceData.invoiceMasterId;
    props.updateState({
      emailDrawerVisible: true,
      txData: payload,
      type: TRANSACTION_TYPES.PURCHASE_INVOICE,
    });
  };

  const approveInvoice = () => {
    const modalBody = "Are you sure you want to approve?";
    const modalData = {
      modalBody,
      handleSubmit: () => {
        props.approveInvoice(
          {
            relationshipId: invoiceData.relationshipId,
            supplierId: invoiceData.supplierId,
            invoiceMasterId: invoiceData.invoiceMasterId,
            approvedByEmpId: (props.userInfo || {}).relationshipEmployeeId,
          },
          props
        );
        props.popModalFromStack();
      },
    };
    props.showModal(modalData);
  };

  const handleDelegateApprover = () => {
    const formData = {
      relationshipId: invoiceData.relationshipId,
      supplierId: invoiceData.supplierId,
      invoiceMasterId: invoiceData.invoiceMasterId,
      approvedByEmpId: (props.userInfo || {}).relationshipEmployeeId,
      txType: TRANSACTION_TYPES.PURCHASE_INVOICE,
      approverEmpId: invoiceData.approverEmpId,
    };
    const { showModal } = props;
    const data = {
      title: "Delegate Approver",
      formData,
      hideFooter: true,
      modalData: {
        modalType: MODAL_TYPE.DELEGATE_APPROVER,
        data: formData,
      },
      handleSubmit: (formData = {}) => {
        data.formData.submittedOnce = true;
        props.hideModal(data);
        props.popModalFromStack();
      },
    };
    showModal(data);
  };

  const handleRejectApproval = () => {
    const formData = {
      relationshipId: invoiceData.relationshipId,
      supplierId: invoiceData.supplierId,
      invoiceMasterId: invoiceData.invoiceMasterId,
      approvedByEmpId: (props.userInfo || {}).relationshipEmployeeId,
      txType: TRANSACTION_TYPES.PURCHASE_INVOICE,
    };
    const { showModal } = props;
    const data = {
      title: "Reject",
      formData,
      hideFooter: true,
      modalData: {
        modalType: MODAL_TYPE.REJECT_APPROVAL,
        data: formData,
      },
      handleSubmit: (formData = {}) => {
        data.formData.submittedOnce = true;
        props.hideModal(data);
        props.popModalFromStack();
      },
    };
    showModal(data);
  };

  const openAuditDrawer = (props, data) => {
    props.updateState({
      title: (
        <div className="drawer-title">
          <FormattedMessage
            id="modal.txDetails.common.auditTrail"
            defaultMessage=""
          />
        </div>
      ),
      auditDrawerVisible: true,
      deletApiUrl: "deleteAuditTrailPurchaseInvoice",
      masterKey: "invoiceMasterId",
      masterKeyValue: data.invoiceMasterId,
      apiUrl: "saveAuditTrailPurchaseInvoice",
      supplierId: data.supplierId,
      trailList: data.auditTrailPurchaseInvoiceList,
      updateTrailListInTransaction: (list) => {
        data.auditTrailPurchaseInvoiceList = list;
      },
    });
  };

  const addItemConfirmationModal = (props, text, color, txData) => {
    const { showModal, companyInfo } = props;
   
    const modalBody = (
      <span>
        <FormattedMessage
          id="setItem.confirmation"
          defaultMessage={``}
        />
       
      </span>
    );

    let payload = {
      userStatus: text,
      userStatusColor: color,
      relationshipId: companyInfo.relationshipId,
      invoiceMasterId: txData.invoiceMasterId,
      supplierId: txData.supplierId,
    };

    const modalData = {
      modalBody,
      handleSubmit: () => {
        props.updateUserStatus(payload, props);
        props.hideModal();
        props.popModalFromStack();
      },
    };
    showModal(modalData);
  };

  const updateUserStatus = (props, txData) => {
    let payload = { formData: {}, txData };
    const formData = payload.formData;
    const { pushModalToStack } = props;

    if (
      formData.textBoxValue &&
      formData.textBoxValue.length &&
      !payload.existsInData
    ) {
      addItemConfirmationModal(props, formData.textBoxValue, txData);
    } else {
      const data = {
        title: (
          <FormattedMessage id="addItem.text.userStatus" defaultMessage="" />
        ),
        formData,
        hideFooter: true,
        modalData: {
          modalType: MODAL_TYPE.USER_STATUS,
          data: payload,
          transactionName: CONSTANTS_TRANSACTIONS.PURCHASE_INVOICE,
        },
        handleSubmit: (formData = {}) => {
          if (formData.textBoxValue && formData.textBoxValue.length) {
            addItemConfirmationModal(
              props,
              formData.textBoxValue,
              formData.statusColor,
              txData
            );
          } else {
            data.formData.submittedOnce = true;
            pushModalToStack(data);
          }
        },
      };
      pushModalToStack(data);
    }
  };

  return (
    <div className={"custom-modal show"}>
      {templateName === "Standard" && <StandardTemplate {...props} />}
      {templateName === "Professional" && <Template2 {...props} />}
      <div className="modal-dialog">
        <div className="modal-content full-width">
          {invoiceData.status !== "Pending Approval" &&
          invoiceData.status !== "Rejected" ? (
            <div className=" button-group-action header-control">
              {props.availableDebits &&
              invoiceData.status !== "paid" &&
              invoiceData.status !== "void" ? (
                <Button
                  style={{ height: "38px" }}
                  onClick={() => {
                    const modalData = {
                      title: (
                        <div>
                          <div>
                            <FormattedMessage
                              id="modal.txDetails.purchaseInvoice.applyDebits"
                              defaultMessage=""
                            />
                          </div>
                          <div style={{ "font-size": "12px" }}>
                            {" "}
                            {invoiceData.supplierName || ""}
                          </div>
                        </div>
                      ),
                      modalBody: (
                        <ApplyPurchaseCredits invoiceData={invoiceData} />
                      ),
                      width: 1000,
                      hideFooter: true,
                      handleSubmit: (prm, prm2) => {},
                    };
                    props.showModal(modalData);
                  }}
                >
                  {" "}
                  <span>
                    <FormattedMessage
                      id="modal.txDetails.purchaseInvoice.availableDebits"
                      defaultMessage=""
                    />{" "}
                    :{" "}
                    {props.companyInfo.currencyIcon ? (
                      <i className={props.companyInfo.currencyIcon}></i>
                    ) : (
                      props.companyInfo.currencyCode + " "
                    )}{" "}
                    {fixedDecimalNumber(props.availableDebits || 0)}{" "}
                  </span>
                </Button>
              ) : (
                ""
              )}
              <div className="small-btn-group">
                {invoiceData.status !== "void" &&
                invoicePermissionsData.update ? (
                  <Button
                    style={{ height: "38px" }}
                    title={props.intl.formatMessage({
                      id: "modal.txDetails.common.edit",
                      defaultMessage: "",
                    })}
                    icon={<EditOutlined />}
                    onClick={() => {
                      props.popModalFromStack();
                      props.updateState({
                        supplierId: invoiceData.supplierId,
                        invoiceMasterId: invoiceData.invoiceMasterId,
                        update: true,
                      });

                      props.pushModalToStack({
                        modalBody: (
                          <StandardPurchaseInvoice
                            {...props}
                            invoiceEditData = {invoiceData}
                            supplierId={invoiceData.supplierId}
                            invoiceMasterId={invoiceData.invoiceMasterId}
                            update={true}
                          />
                        ),
                        width: "100%",
                        hideTitle: true,
                        hideFooter: true,
                        wrapClassName: "new-transaction-wrapper",
                      });
                      // props.history.push({
                      //     pathname: '/admin/purchase-invoice-update',
                      //     state: {
                      //         supplierId: invoiceData.supplierId,
                      //         invoiceMasterId: invoiceData.invoiceMasterId,
                      //         update: true
                      //     }
                      // })
                    }}
                  ></Button>
                ) : (
                  ""
                )}
                {invoicePermissionsData.create && (
                  <Button
                    style={{ height: "38px" }}
                    title={props.intl.formatMessage({
                      id: "modal.txDetails.common.clone",
                      defaultMessage: "",
                    })}
                    icon={<CopyOutlined />}
                    onClick={() => {
                      props.popModalFromStack();
                      props.updateState({
                        supplierId: invoiceData.supplierId,
                        invoiceMasterId: invoiceData.invoiceMasterId,
                        clone: true,
                      });

                      props.pushModalToStack({
                        modalBody: (
                          <StandardPurchaseInvoice
                            {...props}
                            invoiceEditData = {invoiceData}
                            supplierId={invoiceData.supplierId}
                            invoiceMasterId={invoiceData.invoiceMasterId}
                            clone={true}
                          />
                        ),
                        width: "100%",
                        hideTitle: true,
                        hideFooter: true,
                        wrapClassName: "new-transaction-wrapper",
                      });
                      // props.history.push({
                      //     pathname: '/admin/purchase-invoice-update',
                      //     state: {
                      //         supplierId: invoiceData.supplierId,
                      //         invoiceMasterId: invoiceData.invoiceMasterId,
                      //         clone: true
                      //     }
                      // })
                    }}
                  ></Button>
                )}
                {(invoiceData.status === "unpaid" ||
                  invoiceData.status === "open") &&
                  !invoiceData.isCashInvoice &&
                  invoicePermissionsData.delete && (
                    <Button
                      style={{ height: "38px" }}
                      title={props.intl.formatMessage({
                        id: "modal.txDetails.common.void",
                        defaultMessage: "",
                      })}
                      icon={<StopOutlined />}
                      onClick={() => {
                        props.showModal({
                          modalBody: (
                            <FormattedMessage
                              id="modal.txDetails.common.void.confirmation"
                              defaultMessage=""
                            />
                          ),
                          handleSubmit: () => {
                            props.popModalFromStack();
                            props.deleteInvoice(
                              {
                                supplierId: invoiceData.supplierId,
                                invoiceMasterId: invoiceData.invoiceMasterId,
                                relationshipId: invoiceData.relationshipId,
                                poMasterId: invoiceData.poMasterId,
                              },
                              props
                            );
                          },
                        });
                      }}
                    ></Button>
                  )}
              </div>
              <div className="small-btn-group">
                <Button
                  style={{ height: "38px" }}
                  title={props.intl.formatMessage({
                    id: "modal.txDetails.common.email",
                    defaultMessage: "",
                  })}
                  icon={<MailOutlined />}
                  onClick={() => {
                    openEmailDrawer(props);
                  }}
                ></Button>

                <Button
                  style={{ height: "38px" }}
                  title={props.intl.formatMessage({
                    id: "modal.txDetails.common.downloadPDF",
                    defaultMessage: "",
                  })}
                  icon={<FilePdfOutlined />}
                  onClick={() => {
                    generatePDF();
                  }}
                ></Button>
                <Button
                  style={{ height: "38px" }}
                  title={props.intl.formatMessage({
                    id: "modal.txDetails.common.print",
                    defaultMessage: "",
                  })}
                  icon={<PrinterOutlined />}
                  onClick={() => {
                    printPdf();
                  }}
                ></Button>
              </div>
              <div className="small-btn-group">
                <Button
                  style={{ height: "38px" }}
                  title={props.intl.formatMessage({
                    id: "modal.txDetails.common.auditTrail",
                    defaultMessage: "",
                  })}
                  icon={<HistoryOutlined />}
                  onClick={() => {
                    openAuditDrawer(props, invoiceData);
                  }}
                ></Button>
              </div>
               {paymentPermissionsData.create &&
                invoiceData?.status === "paid" ? (
                  "") :( <Button
                    style={{ height: "38px" }}
                    type="primary"
                    onClick={() => {
                      const modalData = {
                        title: (
                          <FormattedMessage
                            id="sidebar.purchasePayment.popup.title"
                            defaultMessage=""
                          />
                        ),
                        modalBody: (
                          <PayInvoice {...props} invoiceData={invoiceData} />
                        ),
                        width: 600,
                        hideFooter: true,
                        wrapClassName: "supplier-payment-modal",
                      };
                      props.showModal(modalData);
                    }}
                  >
                    {" "}
                    <FormattedMessage
                      id="modal.txDetails.purchaseInvoice.payInvoice"
                      defaultMessage=""
                    />
                  </Button>)
               }
             

             {invoicePermissionsData.update ? <Button
                style={{ height: "38px" }}
                onClick={() => {
                  updateUserStatus(props, invoiceData);
                }}
              >
                <FormattedMessage
                  id="modal.txDetails.common.updateStatus"
                  defaultMessage=""
                />
              </Button>: "" }

              <div>
                <Dropdown
                  style={{ width: "110px" }}
                  placeholder="Template"
                  items={["Standard", "Professional"]}
                  onSelect={(selectedValue) => {
                    props.updateState({
                      templateName: selectedValue,
                    });
                  }}
                ></Dropdown>
              </div>
            </div>
          ) : (
            <div className="button-group-action">
              {invoiceData.status !== "Rejected" &&
              (props.userInfo || {}).relationshipEmployeeId ===
                invoiceData.approverEmpId ? (
                <div className=" button-group-action header-control">
                  <Button
                    type="primary"
                    onClick={() => {
                      approveInvoice();
                    }}
                  >
                    <FormattedMessage
                      id="modal.txDetails.purchaseInvoice.approveInvoice"
                      defaultMessage=""
                    />
                  </Button>
                  <Button
                    type="primary"
                    onClick={() => {
                      handleDelegateApprover();
                    }}
                  >
                    <FormattedMessage
                      id="modal.txDetails.common.delegateApprover"
                      defaultMessage=""
                    />
                  </Button>
                  <Button
                    type="danger"
                    onClick={() => {
                      handleRejectApproval();
                    }}
                  >
                    <FormattedMessage
                      id="modal.txDetails.common.reject"
                      defaultMessage=""
                    />
                  </Button>
                  <Button
                    onClick={() => {
                      props.popModalFromStack();
                    }}
                    type="default"
                  >
                    <FormattedMessage
                      id="button.close.label"
                      defaultMessage=""
                    />
                  </Button>
                </div>
              ) : (
                <div>
                  <Button
                    onClick={() => {
                      props.popModalFromStack();
                    }}
                    type="default"
                  >
                    <FormattedMessage
                      id="button.close.label"
                      defaultMessage=""
                    />
                  </Button>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const getTxTypeData = (row, type, toReturn) => {
  if (!row || !row.supplierInvoiceCOATxList) return;
  let data = row.supplierInvoiceCOATxList.find((tx) => tx.txType === type);
  if (data) {
    if (data[toReturn] && toReturn === "amountPercent") {
      if (type === "VAT") {
        return "VAT(" + data[toReturn] + "%)";
      }
      return "(" + data[toReturn] + "%)";
    } else if (data[toReturn] && toReturn === "amount") {
      return fixedDecimalNumber(Number(data[toReturn]));
    } else {
      return "";
    }
  } else if (!data && toReturn === "amount") {
    return "";
  } else {
    return "-";
  }
};

const calculateTaxRateList = (
  supplierInvoiceDetailsList,
  isPlaceOfSupplySameAsRelationship, invoiceData
) => {
  let subtotal = 0;
  let totalDiscount = 0;
  let totalTax = 0;
  let itemLevelTaxes = [];

  supplierInvoiceDetailsList.forEach(function (rowdata, index) {
    let totalAmountOfColumn = 0;
    if (rowdata.quantity && rowdata.itemPurchasedPrice) {
      let taxObj = {
        subtotal: 0,
        taxPercent: 0,
        taxAmount: 0,
      };
      totalAmountOfColumn = rowdata.quantity * rowdata.itemPurchasedPrice;
      let discount = getDiscountAmount(rowdata, "amount");
      if (discount) {
        totalAmountOfColumn = totalAmountOfColumn - discount;
        totalDiscount = totalDiscount + discount;
      }

      let tax1 =
        rowdata.supplierInvoiceCOATxList.find((tx) => tx.txType === "tax") ||
        {};
      taxObj.taxPercent = Number(tax1.amountPercent);
      taxObj.taxAmount = Number(fixedDecimalNumber(tax1.amount));
      totalTax = totalTax + taxObj.taxAmount;
      taxObj.taxName = tax1.taxName;
      //   let tax2 = rowdata.supplierInvoiceCOATxList.find(tx => (tx.txType === 'sgst'));
      //   let tax3 = rowdata.supplierInvoiceCOATxList.find(tx => (tx.txType === 'igst'));
      //   let tax4 = rowdata.supplierInvoiceCOATxList.find(tx => (tx.txType === 'vat'));
      //   if (isPlaceOfSupplySameAsRelationship && tax1 && tax2) {
      //       taxObj.taxPercent = Number(tax1.amountPercent) + Number(tax2.amountPercent);
      //       taxObj.taxAmount = Number(tax1.amount) + Number(tax2.amount);
      //       totalTax = totalTax + taxObj.taxAmount;
      //   }
      //   else if (!isPlaceOfSupplySameAsRelationship && tax3) {
      //       taxObj.taxPercent = Number(tax3.amountPercent);
      //       taxObj.taxAmount = Number(tax3.amount);
      //       totalTax = totalTax + taxObj.taxAmount;
      //   }
      //   else if (tax4) {
      //     taxObj.taxPercent = Number(tax4.amountPercent);
      //     taxObj.taxAmount = Number(tax4.amount);
      //     totalTax = totalTax + taxObj.taxAmount;
      // }
      if (taxObj.taxPercent) {
        taxObj.subtotal = Number(fixedDecimalNumber(totalAmountOfColumn));
        const availableTaxObj = find(itemLevelTaxes, {
          taxPercent: taxObj.taxPercent,
        });

        if (availableTaxObj) {
          itemLevelTaxes = filter(itemLevelTaxes, (ele) => {
            if (ele.taxPercent === taxObj.taxPercent) {
              // if (ele.hsnCode === taxObj.hsnCode) {
              ele.subtotal = ele.subtotal + taxObj.subtotal;
              ele.taxAmount = ele.taxAmount + taxObj.taxAmount;
            }
            return true;
          });
        }
        else {
          taxObj.subtotal = Number(fixedDecimalNumber(taxObj.subtotal || 0));
          itemLevelTaxes.push(taxObj);
        }
      }
      subtotal = subtotal + totalAmountOfColumn;
    }
  });

  let dataAfterExpenseTax = calculteExpenseTax(itemLevelTaxes, invoiceData,isPlaceOfSupplySameAsRelationship)

  return dataAfterExpenseTax;
};

const calculteExpenseTax = (itemLevelTaxes, invoiceData,isPlaceOfSupplySameAsRelationship) => {
  let finalVal = [];

  let expenseTax = invoiceData?.supplierInvoiceCOATxList?.filter(
    (item) => item.txType === "expense" && item.accountingEntry === "credit"
  );

  let v = expenseTax?.forEach((rowdata) => {
    if (rowdata.taxId > 0) {
      let taxObj = {
        subtotal: rowdata.amount,
        taxPercent:isPlaceOfSupplySameAsRelationship?rowdata.taxId/2: rowdata.taxId,
        taxAmount: ((isPlaceOfSupplySameAsRelationship?rowdata.taxId/2: rowdata.taxId) * rowdata.amount) / 100,
        taxName : rowdata.taxName
      };

      let existingTax = itemLevelTaxes.find(
        (tax) => tax.taxPercent === (isPlaceOfSupplySameAsRelationship?rowdata.taxId/2: rowdata.taxId)
      );

      if (existingTax) {
        existingTax.subtotal += taxObj.subtotal;
        existingTax.taxAmount += taxObj.taxAmount;
      } else {
        itemLevelTaxes.push(taxObj);
      }
    }
  });

  return itemLevelTaxes;
};

const calculateTaxRateListbyHsn = (
  supplierInvoiceDetailsList,
  isPlaceOfSupplySameAsRelationship, invoiceData,props
) => {
  let subtotal = 0;
  let totalDiscount = 0;
  let totalTax = 0;
  let itemLevelTaxes = [];

  supplierInvoiceDetailsList.forEach(function (rowdata, index) {
    let totalAmountOfColumn = 0;
    if (rowdata.quantity && rowdata.itemPurchasedPrice) {
      let taxObj = {
        subtotal: 0,
        taxPercent: 0,
        taxAmount: 0,
        hsnCode: null
      };
      totalAmountOfColumn = rowdata.quantity * rowdata.itemPurchasedPrice;
      let discount = getDiscountAmount(rowdata, "amount");
      if (discount) {
        totalAmountOfColumn = totalAmountOfColumn - discount;
        totalDiscount = totalDiscount + discount;
      }

      let tax1 =
        rowdata.supplierInvoiceCOATxList.find((tx) => tx.txType === "tax") ||
        {};
      taxObj.taxPercent = Number(tax1.amountPercent);
      taxObj.taxAmount = Number(fixedDecimalNumber(tax1.amount));
      totalTax = totalTax + taxObj.taxAmount;
      taxObj.taxName = tax1.taxName;
      taxObj.hsnCode = Number(rowdata.hsnCode)

      if (taxObj.taxPercent) {
        taxObj.subtotal = Number(fixedDecimalNumber(totalAmountOfColumn));
        const availableTaxObj = find(itemLevelTaxes, {
          taxPercent: taxObj.taxPercent,
        });
        const availableHsnObj = find(itemLevelTaxes, {
          hsnCode: taxObj.hsnCode,
        });
        if (availableTaxObj && availableHsnObj) {
          itemLevelTaxes = filter(itemLevelTaxes, (ele) => {
            // if (ele.taxPercent === taxObj.taxPercent && ele.hsnCode === taxObj.hsnCode) {
            if (ele.hsnCode === taxObj.hsnCode) {
              ele.subtotal = ele.subtotal + taxObj.subtotal;
              ele.taxAmount = ele.taxAmount + taxObj.taxAmount;
            }
            return true;
          });
        }
        else {
          taxObj.subtotal = Number(fixedDecimalNumber(taxObj.subtotal || 0));
          itemLevelTaxes.push(taxObj);
        }
      }
      subtotal = subtotal + totalAmountOfColumn;
    }
  });
  let dataAfterExpenseTax = calculteExpenseTaxByHsn(itemLevelTaxes, invoiceData,props,isPlaceOfSupplySameAsRelationship)
  return dataAfterExpenseTax;
};
const calculteExpenseTaxByHsn = (itemLevelTaxes, invoiceData,props,isPlaceOfSupplySameAsRelationship) => {
  let finalVal = [];

  let expenseTax = invoiceData?.supplierInvoiceCOATxList?.filter(
    (item) => item.txType === "expense" && item.accountingEntry === "credit"
  );
  let v = expenseTax?.forEach((rowdata) => {
    if (rowdata.taxId > 0) {
      let taxObj = {
        subtotal: rowdata.amount,
        taxPercent:isPlaceOfSupplySameAsRelationship ? (rowdata.taxId/2) : rowdata.taxId,
        taxAmount: ((isPlaceOfSupplySameAsRelationship ? (rowdata.taxId/2) : rowdata.taxId) * rowdata.amount) / 100,
        taxName : rowdata.taxName
      };
      let obj = Number((find(props.allGstRates, { financeGstGoodsRates: rowdata.taxSingleRate }) || {})?.hsnCode || null);
      let existingTax = itemLevelTaxes.find(
        (tax) => tax.hsnCode === obj
      );
      taxObj.hsnCode = obj?obj:null;
      if (existingTax) {
        existingTax.subtotal += taxObj.subtotal;
        existingTax.taxAmount += taxObj.taxAmount;
      } else {
        itemLevelTaxes.push(taxObj);
      }
    }
  });

  return itemLevelTaxes;
};
const getDiscountAmount = (row, toReturn) => {
  if (!row || !row.discountAmount) return;
  if (row?.discountAmount) {
    return  toReturn === "amount"? row?.discountAmount :  "(" + row?.discountPercentage + "%)"
    }
};
const calculateUomData = (
  supplierInvoiceDetailsList,
  isPlaceOfSupplySameAsRelationship
) => {

  let itemLevelUom = [];
  supplierInvoiceDetailsList.forEach(function (rowdata, index) {
    if (rowdata.quantity && rowdata.uomName) {
      let uomObj = {
        uomName: null,
        uomQty: null,
      };

      uomObj.uomName = rowdata.uomName;
      uomObj.uomQty = rowdata.quantity
      if (uomObj.uomName) {
        const availableUomObj = find(itemLevelUom, {
          uomName: uomObj.uomName,
        });
        if (availableUomObj) {
          itemLevelUom = filter(itemLevelUom, (ele) => {
            if (ele.uomName === uomObj.uomName) {
              ele.uomQty = ele.uomQty + uomObj.uomQty;
            }
            return true;
          });
        }
        else {
          itemLevelUom.push(uomObj);
        }
      }
    }
  });

  return itemLevelUom;
};


const updateColumnEmptyCheck = (isColumnEmpty, invoiceData, props) => {
  if (!invoiceData) return isColumnEmpty;
  for (let i in invoiceData.supplierInvoiceDetailsList) {
    let row = invoiceData.supplierInvoiceDetailsList[i];
    if (row.itemName) {
      isColumnEmpty.product = false;
    }
    if (row.serviceName) {
      isColumnEmpty.service = false;
    }
    if (row.itemVariantName) {
      isColumnEmpty.variant = false;
    }
    if (row.description) {
      isColumnEmpty.description = false;
    }
    if (row.quantity) {
      isColumnEmpty.qty = false;
    }
    if (row.uomName) {
      isColumnEmpty.uomName = false;
    }
    if (row.itemPurchasedPrice) {
      isColumnEmpty.rate = false;
    }
    if (row.specialDiscount) {
      isColumnEmpty.specialDiscount = false;
    }
    if (row.hsnCode && props.companyInfo.countryName === "India") {
      isColumnEmpty.hsn = false;
    }
    if (!isTxTypeEmpty(row, "discount")) {
      isColumnEmpty.discount = false;
    }
    if (!isTxTypeEmpty(row, "tax")) {
      isColumnEmpty.tax = false;
    }
    //if (!isTxTypeEmpty(row, 'sgst')) { isColumnEmpty.tax = false; }
    // if (!isTxTypeEmpty(row, 'igst')) { isColumnEmpty.tax = false; }
    // if (!isTxTypeEmpty(row, 'vat')) { isColumnEmpty.tax = false; }
    if (row.documentDetails && row.documentDetails.length) {
      isColumnEmpty.documentDetails = false;
    }
  }
  return isColumnEmpty;
};

const isTxTypeEmpty = (row, type) => {
  if (!row) return true;

  let data = row.supplierInvoiceCOATxList.find((tx) => tx.txType === type);
  if (type === "tax") {

    if (data && data.amount) {
      return false
    } else {
      return true
    }
  }
  if (row && row.discountPercentage) {
    return false;
  } else {
    return true;
  }
};

export default PurchaseInvoiceDetails;
