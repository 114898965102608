import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import CampaignComp from "../../../components/fundraising/campaign";
import { fetchDataIfNeeded, fetchPaginationDataIfNeeded } from "../../../utils";
import { getFundraisingCampaign, deleteFundraisingCampaign, getFundraisingCampaignCount, resetPaginatedData } from "./action";
import { showModal, hideModal, pushModalToStack, popModalFromStack } from './../../../actions/commonActions';
import { getSubdomain } from '../../modal/modalBody/settings/CompanyInfo/action';
import { FormattedMessage } from 'react-intl';

class Campaign extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageNumber: 1,
      pageSize: 25,
      activeCampaignAnalytics: '',
    };
  }

  componentDidMount() {
    this.props.updateHeaderState({
      collapsed: true,
    });
    this.props.updateHeaderState({pageHeading: <FormattedMessage id='heading.fundRaising.campaign' defaultMessage='' />})
    this.props.updateHeaderState({breadCrumbList : [
      {
        name: <FormattedMessage id='sidebar.menuItem.fundraising' defaultMessage='' />
      },
      {
        name: <FormattedMessage id='sidebar.menuItem.fundraisingCampaign' defaultMessage='' />,
      }
    ]})
    let self = this;
    const companyInfo = this.props.companyInfo || {};
    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      pageNumber: 1,
      pageSize: 25,
    };
    fetchPaginationDataIfNeeded("getFundraisingCampaign", "campaignList", this.props, payload);
    fetchDataIfNeeded("getFundraisingCampaignCount", "campaignListCount", this.props, payload);
    this.props.getSubdomain(payload, function (stat) {self.setState({isSubDomainLoaded : stat})} ); 
  }

  componentWillReceiveProps(props) {
   
    if ((!this.state.activeCampaignAnalytics || this.state.activeCampaignAnalytics === '') && props.campaignList && props.campaignList[this.state.pageNumber]) {
    
      let activeCampaignAnalytics = props.campaignList[this.state.pageNumber][0];
      this.setState({ activeCampaignAnalytics: activeCampaignAnalytics });
    }
    if (props.subdomain && props.subdomain.alpideSubdomainId && !this.state.subdomainName) {
      this.setState({
        subdomainName: props.subdomain.subdomainName ? 'https://' + props.subdomain.subdomainName : window.location.origin
      });
    }
  }

  render() {
    return (
      <div>
        
        <CampaignComp
          {...this.props}
          {...this.state}
          updateState={(data) => {
            this.setState(data);
          }}
        ></CampaignComp>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    relationshipId: state.common.companyInfo.relationshipId,
    campaignList: state.fundraisingReducer.campaignList,
    campaignListCount: state.fundraisingReducer.campaignListCount,
    subdomain: state.common.subdomain,
    permissions: state.auth.permissions,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getFundraisingCampaign,
      deleteFundraisingCampaign,
      getFundraisingCampaignCount,
      resetPaginatedData,
      showModal,
      hideModal,
      pushModalToStack, 
      popModalFromStack,
      getSubdomain
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Campaign);
