import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
// import { CustomAgGrid } from '../../../../general/CustomAgGrid';
import { Skeleton, Pagination, Input, Empty } from 'antd';
import { fetchPaginationDataIfNeeded, getMomentDateForUIReadOnly } from '../../../../../utils'
import {  CONSTANTS, ICONS } from '../../../../../static/constants';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import './index.scss';
import * as debounce from 'lodash.debounce';
import PageHeader from '../../../../Common/pageHeader';
const { Search } = Input;
const format = CONSTANTS.DISPLAY_DATE_FORMAT;

const onSearchedInputChange = debounce((searchedText, props) => {
    handleSearchedText(searchedText, props)
}, 500);

const handleSearchedText = (searchedText, props) => {
    const payload = {
        relationshipId: props.companyInfo.relationshipId,
        pageNumber: 1,
        pageSize: props.pageSize,
        searchedText: searchedText
    };
    if (searchedText.length) {
        if (searchedText.length >= 3 && !props.searchedText.toLowerCase().startsWith(searchedText.toLowerCase())) {
            props.resetPaginatedData();
            props.getAgedStockCount(payload);
            props.fetchAgedStock(payload);
        }
    } else {
        props.resetPaginatedData();
        props.getAgedStockCount(payload);
        props.fetchAgedStock(payload);
    }
}

const WarehouseListingComp = (props) => {
    const { agedStockList, agedStockCount, updateState, searchedText, companyInfo, pageNumber } = props;
    // const secondaryPermissions = permissions.secondary;
                                          
    // const getRowHeight = (params) => {
    //     let height = 28;
    //     return height;
    // }

    const getVariantName = (data) => {
        return <div>
            {data.attributeValue1 || ''} {data.attributeValue2 ? ' | ' + data.attributeValue2 : ''} {data.attributeValue3 ? ' | ' + data.attributeValue3 : ''}
        </div>
    }
    // let columnDefs = [
    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='inventory.warehouse.agedStock.listing.header.product' defaultMessage='' />;
    //         },
    //         field: "itemName",
    //         resizable: true
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='inventory.warehouse.agedStock.listing.header.variant' defaultMessage='' />;
    //         },
    //         resizable: true,
    //         cellRendererFramework: function (link) {
    //             return getVariantName(link.data);
    //         },
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='inventory.warehouse.agedStock.listing.header.sku' defaultMessage='' />;
    //         },
    //         field: "sku",
    //         width: 115,
    //         resizable: true,
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='inventory.warehouse.agedStock.listing.header.days' defaultMessage='' />;
    //         },
    //         width: 100,
    //         field: "procurementDays",

    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='inventory.warehouse.agedStock.listing.header.currentStock' defaultMessage='' />;
    //         },
    //         resizable: true,
    //         field: "currentStock"
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='lastPurchasedOn.text' defaultMessage='' />;
    //         },
    //         resizable: true,
    //         field: "procurementDate",
    //         cellRendererFramework: (link) => <div>{link.value ? getMomentDateForUIReadOnly({date: link.value, format: format}) : "" }</div>,
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='inventory.warehouse.agedStock.listing.header.lastPurchasePrice' defaultMessage='' />;
    //         },
    //         resizable: true,
    //         field: "purchasedPrice",
    //         cellRendererFramework: (link) => <div>{(props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " ")}{(link.value || 0).toFixed(2)}</div>,
    //         type: 'rightAligned'
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='product.listing.header.valuation' defaultMessage='' />;
    //         },
    //         resizable: true,
    //         field: "currentStock",
    //         cellRendererFramework: (link) => <div>{(props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " ")}{( (link.data.currentStock || 0)* (link.data.purchasedPrice|| 0)).toFixed(2)}</div>,
    //         type: 'rightAligned'
    //     },
    //     // {
    //     //     headerComponentFramework: () => {
    //     //         return <FormattedMessage id='inventory.warehouse.agedStock.listing.header.lastPurchasePrice' defaultMessage='' />;
    //     //     },
    //     //     resizable: true,
    //     //     field: "purchasedPrice",
    //     //     secondaryPermKey: SECONDARY_PERM_KEYS.AGED_STOCK_LIST_LAST_PURCHASE_PRICE,
    //     //     cellRendererFramework: (link) => <div>{(link.value || 0).toFixed(2)}</div>,
    //     //     cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN,
    //     // },
    // ];

    // columnDefs = columnDefs.filter(obj => {
    //     return !obj.secondaryPermKey || checkSecondaryPermission(secondaryPermissions, obj.secondaryPermKey)
    // });

    // const onFirstDataRendered = (params) => {
    //     params.api.sizeColumnsToFit();
    // }

    const showTotal = (total) => {
        return props.intl.formatMessage({ id: 'pagination.text.total', defaultMessage: '' }) + ` ${total} ` + props.intl.formatMessage({ id: 'pagination.text.items', defaultMessage: '' });
    };

    const loadPageData = (pageNumber, pageSize) => {
        const payload = { relationshipId: companyInfo.relationshipId, pageNumber: pageNumber, pageSize: pageSize };
        fetchPaginationDataIfNeeded('fetchAgedStock', 'agedStockList', props, payload);
    }

    const handlePageSizeChange = (pageNumber, pageSize, props) => {
        const payload = { relationshipId: companyInfo.relationshipId, pageNumber: pageNumber, pageSize: pageSize };
        props.resetPaginatedData();
        props.fetchAgedStock(payload);
        //props.updateState({ pageSize: pageSize, pageNumber: pageNumber })
    }
    
    const breadCrumbList = [
        {
            name: <FormattedMessage id="sidebar.menuItem.inventory" defaultMessage="" />
        },
        {
            name: <FormattedMessage id="sidebar.menuItem.agedStock" defaultMessage="" />,
        }
    ];

    return (
        <>
            <PageHeader {...props}
                pageName = {<FormattedMessage id='heading.inventry.agedStock' defaultMessage="" />}
                breadCrumbList={breadCrumbList}
            />
                
            <div className='view-container'>
                <div className="view-container-actions">
                <Skeleton loading={props.headerLoading} paragraph={false} rows={1} active>
                    <div className="left-actions">
                        <div className="table-heading">
                            <FormattedMessage id='heading.inventry.agedStock.table.heading' defaultMessage='' />
                        </div>
                        {
                            props.agedStockCount ? <>
                            <div className="vertical-sep" />
                            <div>{props.agedStockCount}</div>
                            </>:''
                        }
                    </div>
                    <div className="right-actions">
                        <Search
                            placeholder="Enter 3 characters product name"
                            value={searchedText}
                            onChange={e => {
                                updateState({ searchedText: e.target.value, pageNumber: 1 });
                                onSearchedInputChange(e.target.value, props);
                            }}
                            onSearch={(searchedText) => {
                                updateState({ searchedText, pageNumber: 1 });
                                onSearchedInputChange(searchedText, props);
                                handleSearchedText(searchedText, props);
                            }}
                        />
                    </div>
                </Skeleton>
                </div>
                <Skeleton loading={props.listLoading}
                    active
                    paragraph={{ rows: 12 }}
                >
                    
                    {/* <div className='agGridWrapper d-flex w-100 pt15'>
                        <div className="ag-theme-balham" style={agGridStyle} >
                            <CustomAgGrid
                                columnDefs={columnDefs}
                                rowData={agedStockList[pageNumber] || []}
                                rowDragManaged={true}
                                domLayout={"autoHeight"}
                                animateRows={true}
                                getRowHeight={getRowHeight}
                                onGridSizeChanged={onFirstDataRendered}
                                onGridReady = {(params) => {params.api.sizeColumnsToFit();} }
                            >
                            </CustomAgGrid>
                        </div>
                    </div> */}
                    <div className="table-container">
                        <table id='aged-stock'>
                            <thead>
                                <tr>
                                    <th><FormattedMessage id='serial.no' defaultMessage=''/></th>
                                    <th><FormattedMessage id='inventory.warehouse.agedStock.listing.header.product' defaultMessage=''/> <i className={ICONS.SORT} /></th>
                                    <th><FormattedMessage id='inventory.warehouse.agedStock.listing.header.variant' defaultMessage=''/> <i className={ICONS.SORT} /></th>
                                    <th><FormattedMessage id='inventory.warehouse.agedStock.listing.header.sku' defaultMessage=''/> <i className={ICONS.SORT} /></th>
                                    <th><FormattedMessage id='inventory.warehouse.agedStock.listing.header.days' defaultMessage=''/> <i className={ICONS.SORT} /></th>
                                    <th><FormattedMessage id='inventory.warehouse.agedStock.listing.header.currentStock' defaultMessage=''/> <i className={ICONS.SORT} /></th>
                                    <th><FormattedMessage id='lastPurchasedOn.text' defaultMessage=''/> <i className={ICONS.SORT} /></th>
                                    <th><FormattedMessage id='inventory.warehouse.agedStock.listing.header.lastPurchasePrice' defaultMessage=''/> <i className={ICONS.SORT} /></th>
                                    <th><FormattedMessage id='product.listing.header.valuation' defaultMessage=''/> <i className={ICONS.SORT} /></th>
                                </tr>
                            </thead>
                            <tbody>
                                {(agedStockList[pageNumber] || []).length ? agedStockList[pageNumber].map((rowData,index)=>{
                                    return(
                                        <tr key={index}>
                                            <td>{index+1}</td>
                                            <td>{rowData.itemName}</td>
                                            <td>{getVariantName(rowData)}</td>
                                            <td>{rowData.sku}</td>
                                            <td>{rowData.procurementDays}</td>
                                            <td>{rowData.currentStock}</td>
                                            <td><div>{rowData.procurementDate ? getMomentDateForUIReadOnly({date: rowData.procurementDate, format: format}) : "" }</div></td>
                                            <td><div>{(props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " ")}{(rowData.purchasedPrice || 0).toFixed(2)}</div></td>
                                            <td><div>{(props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " ")}{( (rowData.currentStock || 0)* (rowData.purchasedPrice|| 0)).toFixed(2)}</div></td>
                                        </tr>
                                    )
                                })
                                :
                                <tr key="empty-data-box">
                                    <td colSpan={"100%"} style={{ borderBottom: "none" }}>
                                        <Empty />
                                    </td>
                                </tr>
                            }
                            </tbody>
                        </table>
                    </div>
                </Skeleton>
                <div className="footer">
                <Skeleton loading={props.headerLoading} paragraph={false} rows={1} active>
                    <div className="f-left"></div>
                    <div className="f-right">

                        <Pagination
                            size="small"
                            current={props.pageNumber}
                            total={agedStockCount}
                            showTotal={showTotal}
                            defaultPageSize={100} 
                            showSizeChanger
                            pageSizeOptions={[10, 25, 50, 100, 200]}
                            // showQuickJumper
                            onChange={(pageNumber, pageSize) => {
                                loadPageData(pageNumber, pageSize);
                                updateState({ pageSize: pageSize, pageNumber: pageNumber })
                            }}
                            onShowSizeChange={(pageNumber, pageSize) => {
                                handlePageSizeChange(pageNumber, pageSize, props)
                            }}
                        />
                    </div>
                </Skeleton>
                </div>
            </div>
        </>

    );
};

export default injectIntl(WarehouseListingComp);
