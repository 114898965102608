import React, { Fragment } from "react";
import { injectIntl, FormattedMessage } from "react-intl";
// import { AgGridReact } from "ag-grid-react";
import { Tooltip, Skeleton, Pagination, Checkbox,Empty } from "antd";
import {
  fetchPaginationDataIfNeeded,
  showToasterMessage,
  getMomentDateForUIReadOnly,
  fixedDecimalAndFormateNumber,
} from "../../../../utils";
import SODetail from "../../../../containers/modal/modalBody/customer/SODetail";
import SQDetail from "../../../../containers/modal/modalBody/customer/SQDetail";
// import RFQDetail from '../../../../containers/modal/modalBody/supplier/RFQDetail';
// import RFQSplitDetail from '../../../../containers/modal/modalBody/supplier/RFQSplitDetail';
import XeroInvoice from "../../../../containers/modal/modalBody/common/xero/Invoice";
import SalesInvoiceProformaDetail from "../../../../containers/modal/modalBody/customer/SalesInvoiceProformaDetail";
// import { AG_GRID_CLASS_CONSTANTS } from "../../../../static/cssClassConstants";
// import { AG_GRID_CONSTANTS } from "../../../../static/agGridConstants";
import checkIcon from "../../../../assets/images/check.png";
import crossIcon from "../../../../assets/images/cross.png";

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";

import "./index.scss";
//import PageBreadcrumb from '../../../PageBreadcrumb';
import XeroIcon from "../../../../assets/images/xero_logo.svg";
import { CONSTANTS } from "../../../../static/constants";
import find from "lodash.find";
import CustomerProfile from '../../../../containers/modal/modalBody/customer/Profile'

const format = CONSTANTS.DISPLAY_DATE_FORMAT;

const ProformaInvoiceListingComp = (props) => {
  
  // const [isAllChecked, toggleAllChecked] = useState(false);
  const {
    selectedProformaInvoices,
    proformaInvoiceList,
    updateState,
    companyInfo,
    proformaInvoiceCount,
    pageNumber,
  } = props;
  if (!selectedProformaInvoices) {
    props.updateState({ selectedProformaInvoices: [] });
  }

  const showTotal = (total) => {
    return (
      props.intl.formatMessage({
        id: "pagination.text.total",
        defaultMessage: "",
      }) +
      ` ${total} ` +
      props.intl.formatMessage({
        id: "pagination.text.items",
        defaultMessage: "",
      })
    );
  };

  const loadPageData = (pageNumber, pageSize) => {
    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      pageNumber: pageNumber,
      pageSize: pageSize,
    };
    fetchPaginationDataIfNeeded(
      "fetchProformaInvoices",
      "salesInvoiceList",
      props,
      payload
    );
  };
  const handlePageSizeChange = (pageNumber, pageSize, props) => {
    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      pageNumber: pageNumber,
      pageSize: pageSize,
    };
    props.resetPaginatedData();
    props.fetchProformaInvoices(payload);
    props.updateState({ pageSize: pageSize, pageNumber: pageNumber });
  };

  // const getRowHeight = (params) => {
  //     let height = 35;
  //     return height;
  // }
  const openSoDetails = (data) => {
    const soPayload = {
      customerId: data.customerId,
      salesOrderMasterId: data.salesOrderMasterId,
    };
    props.pushModalToStack({
      modalBody: <SODetail {...props} soPayload={soPayload} />,
      width: "100%",
      hideFooter: true,
      wrapClassName: "modal-custom-detail",
    });
  };

  const openSQDetails = (data) => {
    const sqPayload = {
      customerId: data.customerId,
      salesQuotationMasterId: data.salesQuotationMasterId,
    };
    props.pushModalToStack({
      modalBody: <SQDetail {...props} sqPayload={sqPayload} />,
      width: "100%",
      hideFooter: true,
      wrapClassName: "modal-custom-detail",
    });
  };

  // const openRFQDetails = (data) => {
  //     const rfqPayload = {
  //         rfqMasterId: data.rfqMasterId,
  //         rfqChildMasterId: data.rfqChildMasterId
  //     }
  //     props.pushModalToStack({
  //         modalBody: data.rfqChildMasterId ? <RFQSplitDetail {...props} rfqPayload={rfqPayload} /> : <RFQDetail {...props} rfqPayload={rfqPayload} />,
  //         width: '100%',
  //         hideFooter: true,
  //         wrapClassName: 'modal-custom-detail'
  //     })
  // }

  // const onGridReady = (params) => {
  //   params.api.sizeColumnsToFit();
  //   // onDataRendered(params);
  // };

  const openSalesInvoiceDetails = (data) => {
    props.pushModalToStack({
      modalBody: (
        <SalesInvoiceProformaDetail {...props} proformaInvoiceData={data} />
      ),
      width: "100%",
      hideFooter: true,
      wrapClassName: "modal-custom-detail",
    });
  };

  const _isColumnToHide = (columnName) => {
    return props.txColumns.indexOf(columnName) < 0 &&
      props.txMandatoryColumns.indexOf(columnName) < 0
      ? true
      : false;
  };

  // const saveColumnIndex = (columnState) => {
  //   let data = props.txPreferences || {};
  //   let tablePreference = {};
  //   data.relationshipId = (props.companyInfo || {}).relationshipId;
  //   data.clientUserAccountId = (props.userInfo || {}).userId;
  //   if (data.tablePreference) {
  //     tablePreference = JSON.parse(data.tablePreference);
  //   }
  //   tablePreference.columnSetting = columnState;
  //   data.tablePreference = JSON.stringify(tablePreference);
  //   data.preferenceId = props.preferenceId;
  //   data.gridPreference = props.gridPreference;
  //   props.saveUserPrefrences(data);
  // };

  // const saveColumnWidth = (columnState, width) => {
  //   let data = props.txPreferences || {};
  //   let tablePreference = {};
  //   data.relationshipId = (props.companyInfo || {}).relationshipId;
  //   data.clientUserAccountId = (props.userInfo || {}).userId;
  //   if (data.tablePreference) {
  //     tablePreference = JSON.parse(data.tablePreference);
  //   }
  //   tablePreference.columnSetting = columnState;
  //   tablePreference.columnList = props.txColumns;
  //   data.tablePreference = JSON.stringify(tablePreference);
  //   data.preferenceId = props.preferenceId;
  //   data.gridPreference = props.gridPreference;
  //   props.saveUserPrefrences(data, props);
  // };

  // let moveEvent = {};
  // const onColumnMoved = (event, isDragStopped) => {
  //   if (event.column) {
  //     moveEvent = event;
  //   }
  //   if (
  //     isDragStopped &&
  //     moveEvent.column &&
  //     moveEvent.column.colDef &&
  //     moveEvent.source !== AG_GRID_CONSTANTS.EVENT_SOURCE.API
  //   ) {
  //     saveColumnIndex(moveEvent.columnApi.getColumnState());
  //   }
  // };

  // const onColumnResized = (event) => {
  //   event.api.resetRowHeights();
  //   if (
  //     event.type === "columnResized" &&
  //     event.source !== "sizeColumnsToFit" &&
  //     event.source !== "api" &&
  //     event.finished === true
  //   ) {
  //     event.api.resetRowHeights();
  //     if (
  //       props.gridColumnApi.columnController.bodyWidth <
  //       props.gridColumnApi.columnController.scrollWidth
  //     ) {
  //       const columnArray = event.columnApi.getColumnState();
  //       for (let i = columnArray.length - 1; i >= 0; i--) {
  //         if (
  //           columnArray[i] &&
  //           !columnArray[i].hide &&
  //           columnArray[i].colId !== "action_1"
  //         ) {
  //           columnArray[i].width =
  //             columnArray[i].width +
  //             (props.gridColumnApi.columnController.scrollWidth -
  //               props.gridColumnApi.columnController.bodyWidth);
  //           break;
  //         }
  //       }
  //       saveColumnWidth(columnArray);
  //     } else {
  //       saveColumnWidth(event.columnApi.getColumnState());
  //     }
  //   }
  // };



  // const columnDefs = [
  //   {
  //     field: "isChecked",
  //     colId: "isChecked_1",
  //     headerComponentFramework: () => <Fragment />,
  //     cellRendererFramework: (params) => {
  //       return (
  //         <Checkbox
  //           onChange={(e) => {
  //             params.node.setDataValue("isChecked_1", e.target.checked);
  //             params.node.data.isChecked = e.target.checked;
  //           }}
  //           checked={params.data.isChecked}
  //         />
  //       );
  //     },
  //     width: 60,
  //     resizable: false,
  //   },
  //   // {
  //   //     field: 'isXeroUploaded',
  //   //     headerComponentFramework: () => <Tooltip trigger="click" placement="top" title="Invoice uploaded to Xero?">
  //   //         Xero <i className="fa fa-question-circle-o pl5 cursor-pointer" />
  //   //     </Tooltip>,
  //   //     cellRendererFramework: (params) => {
  //   //         return params.data.isXeroUploaded ? <img src={XeroIcon} alt="" style={{ height: "25px" }} /> : "";
  //   //     },
  //   //     //hide: _isColumnToHide('isXeroUploaded'),
  //   //     colId: 'isXeroUploaded_1',
  //   //     width: 110,
  //   //     resizable: true
  //   // },
  //   {
  //     headerComponentFramework: () => {
  //       return (
  //         <FormattedMessage
  //           id="customer.salesInvoice.listing.header.invoiceNo"
  //           defaultMessage=""
  //         />
  //       );
  //     },
  //     field: "invoiceProformaId",
  //     rowDrag: true,
  //     resizable: true,
  //     //hide: _isColumnToHide('invoiceNumber'),
  //     colId: "invoiceNumber_1",
  //     width: (props.txColumnSetting["invoiceNumber"] || {}).width,
  //     cellRendererFramework: (link) => (
  //       <div
  //         className="cursor-pointer"
  //         onClick={() => {
  //           openSalesInvoiceDetails(link.data);
  //         }}
  //       >
  //         INV-{link.data.invoiceProformaId}
  //       </div>
  //     ),
  //   },
  //   {
  //     headerComponentFramework: () => {
  //       return (
  //         <FormattedMessage
  //           id="customer.salesInvoice.listing.header.soNo"
  //           defaultMessage=""
  //         />
  //       );
  //     },
  //     field: "soNumber",
  //     resizable: true,
  //     //hide: _isColumnToHide('soNumber'),
  //     colId: "soNumber_1",
  //     width: (props.txColumnSetting["soNumber"] || {}).width,
  //     cellRendererFramework: (link) => (
  //       <div
  //         className="cursor-pointer"
  //         onClick={() => {
  //           openSoDetails(link.data);
  //         }}
  //       >
  //         {link.value}
  //       </div>
  //     ),
  //   },
  //   {
  //     headerComponentFramework: () => {
  //       return (
  //         <FormattedMessage
  //           id="customer.salesInvoice.listing.header.quoteNumber"
  //           defaultMessage=""
  //         />
  //       );
  //     },
  //     field: "quotationNumber",
  //     //hide: _isColumnToHide('quotationNumber'),
  //     colId: "quotationNumber_1",
  //     width: (props.txColumnSetting["quotationNumber"] || {}).width,
  //     cellRendererFramework: (link) => (
  //       <div
  //         className="cursor-pointer"
  //         onClick={() => {
  //           openSQDetails(link.data);
  //         }}
  //       >
  //         {link.value}
  //       </div>
  //     ),
  //     resizable: true,
  //   },
  //   // {
  //   //     headerComponentFramework: () => {
  //   //         return <FormattedMessage id='customer.salesInvoice.listing.header.rfqNumber' defaultMessage='' />;
  //   //     },
  //   //     field: "rfqNumber",
  //   //     //hide: _isColumnToHide('rfqNumber'),
  //   //     width: (props.txColumnSetting['rfqNumber'] || {}).width,
  //   //     cellRendererFramework: (link) => <div className="cursor-pointer" onClick={() => {
  //   //         openRFQDetails(link.data)
  //   //     }}>{link.value}</div>,
  //   //     resizable: true
  //   // },
  //   {
  //     headerComponentFramework: () => {
  //       return (
  //         <FormattedMessage
  //           id="customer.salesInvoice.listing.header.customer"
  //           defaultMessage=""
  //         />
  //       );
  //     },
  //     field: "customerName",
  //     resizable: true,
  //     //hide: _isColumnToHide('customerName'),
  //     colId: "customerName_1",
  //     width: (props.txColumnSetting["customerName"] || {}).width,
  //     cellRendererFramework: (link) => (
  //       <div className="cursor-pointer">
  //         <Link
  //           to={{
  //             pathname: "customers/details",
  //             state: {
  //               customerId: link.data.customerId,
  //               customerName: link.data.customerName,
  //             },
  //           }}
  //           className="company-name"
  //         >
  //           {link.data.customerName}
  //         </Link>
  //       </div>
  //     ),
  //   },
  //   {
  //     headerComponentFramework: () => {
  //       return (
  //         <div>
  //           <FormattedMessage
  //             id="customer.salesInvoice.listing.header.invoiceAmount"
  //             defaultMessage=""
  //           />{" "}
  //         </div>
  //       );
  //     },
  //     field: "invoiceTotalAmount",
  //     colId: "invoiceTotalAmount_1",
  //     //hide: _isColumnToHide('invoiceTotalAmount'),
  //     width: (props.txColumnSetting["invoiceTotalAmount"] || {}).width,
  //     cellRendererFramework: (link) => {
  //       //return link.value && link.value !== '-' ? <span> {link.data.foreignCurrencyIcon ? <i className={link.data.foreignCurrencyIcon}></i> : (link.data.foreignCurrency ? <span>{link.data.foreignCurrency}</span> : (props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "))}{link.value.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} </span> : "-"
  //       return link.value && link.value !== "-" ? (
  //         <span>
  //           {" "}
  //           {link.data.foreignCurrencyIcon ? (
  //             <i className={link.data.foreignCurrencyIcon}></i>
  //           ) : link.data.foreignCurrency ? (
  //             <span>{link.data.foreignCurrency}</span>
  //           ) : props.companyInfo.currencyIcon ? (
  //             <i className={props.companyInfo.currencyIcon}></i>
  //           ) : (
  //             props.companyInfo.currencyCode + " "
  //           )}
  //           {fixedDecimalAndFormateNumber(link.value)}{" "}
  //         </span>
  //       ) : (
  //         "-"
  //       );
  //     },
  //     resizable: true,
  //     cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN,
  //   },
  //   {
  //     headerComponentFramework: () => {
  //       return (
  //         <div>
  //           <FormattedMessage
  //             id="customer.salesInvoice.listing.header.invoiceConverted"
  //             defaultMessage=""
  //           />{" "}
  //         </div>
  //       );
  //     },
  //     field: "isInvoiceConverted",
  //     colId: "isProformaConversion_1",
  //     //hide: _isColumnToHide('invoiceTotalAmount'),
  //     width: (props.txColumnSetting["isProformaConversion"] || {}).width,
  //     cellRendererFramework: (link) => {
  //       return link.value ? (
  //         <img
  //           src={checkIcon}
  //           style={{ height: "15px", width: "15px" }}
  //           alt="Invoice"
  //         />
  //       ) : (
  //         <img
  //           src={crossIcon}
  //           style={{ height: "10px", width: "10px" }}
  //           alt="Invoice"
  //         />
  //       );
  //     },
  //     resizable: true,
  //     cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN,
  //   },
  //   // {
  //   //     headerComponentFramework: () => {
  //   //         return <div><FormattedMessage id='customer.salesInvoice.listing.header.paymentAmount' defaultMessage='' />   </div>;
  //   //     },
  //   //     field: "totalPaymentReceived",
  //   //     //hide: _isColumnToHide('totalPaymentReceived'),
  //   //     colId: 'totalPaymentReceived_1',
  //   //     width: (props.txColumnSetting['totalPaymentReceived'] || {}).width,
  //   //     cellRendererFramework: (link) => {
  //   //         return link.value && link.value !== '-' ? <span> {link.data.foreignCurrencyIcon ? <i className={link.data.foreignCurrencyIcon}></i> : (link.data.foreignCurrency ? <span>{link.data.foreignCurrency}</span> : (props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "))}  {link.value} </span> : "-"
  //   //     },
  //   //     valueGetter: (link) => {
  //   //         return link.data.invoiceTotalAmount ? Number(link.data.totalPaymentReceived).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "-";
  //   //     },
  //   //     resizable: true,
  //   //     cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN
  //   // },
  //   // {
  //   //     headerComponentFramework: () => {
  //   //         return <div><FormattedMessage id='customer.salesInvoice.listing.header.dueAmount' defaultMessage='' />   </div>;
  //   //     },
  //   //     field: "invoiceDueAmount",
  //   //     //hide: _isColumnToHide('invoiceDueAmount'),
  //   //     colId: 'invoiceDueAmount_1',
  //   //     width: (props.txColumnSetting['invoiceDueAmount'] || {}).width,
  //   //     cellRendererFramework: (link) => {
  //   //         return link.value && link.value !== '-' ? <span> {link.data.foreignCurrencyIcon ? <i className={link.data.foreignCurrencyIcon}></i> : (link.data.foreignCurrency ? <span>{link.data.foreignCurrency}</span> : (props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "))}  {link.value} </span> : "-"
  //   //     },
  //   //     valueGetter: (link) => {
  //   //         return link.data.invoiceTotalAmount ? Number(link.data.invoiceDueAmount).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "-";
  //   //     },
  //   //     resizable: true,
  //   //     cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN
  //   // },
  //   {
  //     headerComponentFramework: () => {
  //       return (
  //         <FormattedMessage
  //           id="customer.salesInvoice.listing.header.paymentTerm"
  //           defaultMessage=""
  //         />
  //       );
  //     },
  //     field: "paymentTermName",
  //     colId: "paymentTermName_1",
  //     //hide: _isColumnToHide('paymentTermName'),
  //     width: (props.txColumnSetting["paymentTermName"] || {}).width,
  //     resizable: true,
  //   },
  //   {
  //     headerComponentFramework: () => {
  //       return (
  //         <FormattedMessage
  //           id="customer.salesInvoice.listing.header.invoiceDate"
  //           defaultMessage=""
  //         />
  //       );
  //     },
  //     resizable: true,
  //     field: "invoiceDate",
  //     //hide: _isColumnToHide('invoiceDate'),
  //     colId: "invoiceDate_1",
  //     width: (props.txColumnSetting["invoiceDate"] || {}).width,
  //     cellRendererFramework: (link) => (
  //       <div>
  //         <Tooltip
  //           placement="topLeft"
  //           title={
  //             link.data.invoiceDate &&
  //             getMomentDateForUIReadOnly({
  //               date: link.data.invoiceDate,
  //               format: CONSTANTS.DISPLAY_TOOLTIP_DATE_FORMAT,
  //             })
  //           }
  //         >
  //           {getMomentDateForUIReadOnly(
  //             { date: new Date(link.data.invoiceDate) },
  //             format
  //           )}
  //         </Tooltip>
  //       </div>
  //     ),
  //   },
  //   {
  //     headerComponentFramework: () => {
  //       return (
  //         <FormattedMessage
  //           id="customer.salesInvoice.listing.header.invoiceDueDate"
  //           defaultMessage=""
  //         />
  //       );
  //     },
  //     resizable: true,
  //     field: "invoiceDueDate",
  //     //hide: _isColumnToHide('invoiceDueDate'),
  //     colId: "invoiceDueDate_1",
  //     width: (props.txColumnSetting["invoiceDueDate"] || {}).width,
  //     cellRendererFramework: (link) => (
  //       <div>
  //         <Tooltip
  //           placement="topLeft"
  //           title={
  //             link.data.invoiceDueDate &&
  //             getMomentDateForUIReadOnly({
  //               date: link.data.invoiceDueDate,
  //               format: CONSTANTS.DISPLAY_TOOLTIP_DATE_FORMAT,
  //             })
  //           }
  //         >
  //           {getMomentDateForUIReadOnly(
  //             { date: new Date(link.data.invoiceDueDate) },
  //             format
  //           )}
  //         </Tooltip>
  //       </div>
  //     ),
  //   },
  //   {
  //     headerComponentFramework: () => {
  //       return (
  //         <FormattedMessage
  //           id="customer.salesInvoice.listing.header.systemStatus"
  //           defaultMessage=""
  //         />
  //       );
  //     },
  //     field: "status",
  //     //hide: _isColumnToHide('status'),
  //     colId: "status_1",
  //     width: (props.txColumnSetting["status"] || {}).width,
  //     cellRendererFramework: (params) => {
  //       return params.value === "Rejected" && params.data.rejectionReason ? (
  //         <div>
  //           <Tooltip placement="topLeft" title={params.data.rejectionReason}>
  //             <span
  //               className={["status-code", params.value.toLowerCase()].join(
  //                 " "
  //               )}
  //             >
  //               <FormattedMessage
  //                 id={params.value}
  //                 defaultMessage={params.value}
  //               />
  //             </span>
  //           </Tooltip>
  //         </div>
  //       ) : (
  //         <div>
  //           {" "}
  //           <span
  //             className={["status-code", params.value.toLowerCase()].join(" ")}
  //           >
  //             <FormattedMessage
  //               id={params.value}
  //               defaultMessage={params.value}
  //             />
  //           </span>{" "}
  //         </div>
  //       );
  //     },
  //     resizable: true,
  //   },
  //   {
  //     headerComponentFramework: () => {
  //       return <FormattedMessage id="common.project" defaultMessage="" />;
  //     },
  //     field: "project",
  //     hide: _isColumnToHide("project"),
  //     colId: "project_1",
  //     minWidth: 70,
  //     maxWidth: 300,
  //     suppressMovable: true,
  //     cellRendererFramework: (link) => {
  //       return (
  //         <div>
  //           {link.data.projectMasterId ? (
  //             <span>
  //               {link.data.projectMasterId + "-" + link.data.projectName}
  //             </span>
  //           ) : (
  //             ""
  //           )}
  //         </div>
  //       );
  //     },
  //     resizable: true,
  //   },
  //   {
  //     headerComponentFramework: () => {
  //       return (
  //         <div>
  //           <FormattedMessage
  //             id="customer.salesInvoice.listing.header.isProformaConversion"
  //             defaultMessage=""
  //           />{" "}
  //         </div>
  //       );
  //     },
  //     field: "isProformaConversion",
  //     colId: "isProformaConversion_1",
  //     cellRendererFramework: (params) => {
  //       return params.node.data.isInvoiceConverted ? (
  //         <img
  //           src={checkIcon}
  //           style={{ height: "15px", width: "15px" }}
  //           alt="Invoice"
  //         />
  //       ) : (
  //         <img
  //           src={crossIcon}
  //           style={{ height: "10px", width: "10px" }}
  //           alt="Invoice"
  //         />
  //       );
  //     },
  //     hide: _isColumnToHide("isProformaConversion"),
  //     suppressMovable: true,
  //     minWidth: 70,
  //     maxWidth: 400,
  //     resizable: true,
  //   },
  // ];

  // const agGridStyle = {
  //   height: "100%",
  //   width: "100%",
  // };

  // const breadCrumbList = [
  //     {
  //         name: 'Dashboard',
  //         link: 'dashboard'
  //     },
  //     {
  //         name: 'Customer',
  //         link: 'customers'
  //     }, {
  //         name: 'Sales Invoice',
  //     }, {
  //         name: 'List'
  //     }
  // ];

  // const onModelUpdated = (params) => {
  //   //params.api.resetRowHeights();
  //   if (
  //     (proformaInvoiceList[pageNumber] || []).length &&
  //     !props.isRowHeightSet
  //   ) {
  //     setTimeout(() => {
  //       params.api.resetRowHeights();
  //     }, 1000);
  //     props.updateState({
  //       isRowHeightSet: true,
  //     });
  //   }
  //   if (props.tableDataReRendered) {
  //     //params.api.resetRowHeights();
  //     props.updateState({
  //       tableDataReRendered: false,
  //     });
  //     const txColumnSetting =
  //       props.txColumnSetting && props.txColumnSetting.length
  //         ? props.txColumnSetting
  //         : params.columnApi.getColumnState();
  //     txColumnSetting.forEach((e) => {
  //       let columnName = e.colId.split("_")[0];
  //       if (columnName !== "action") {
  //         if (columnName === "isChecked") {
  //           e.hide = false;
  //         } else {
  //           e.hide = _isColumnToHide(columnName);
  //         }
  //       }
  //       if (columnName === "action") {
  //         e.hide = false;
  //         e.pinned = "right";
  //       }
  //     });

  //     params.columnApi.setColumnState(txColumnSetting);
  //   }
  // };

  // const onDataRendered = (params) => {
  //     const txColumnSetting = props.txColumnSetting;
  //     if (Object.keys(txColumnSetting).length) {
  //         txColumnSetting.forEach((e) => {
  //             let columnName = e.colId.split("_")[0];
  //             if (columnName !== 'action') {
  //                 if (columnName === 'isChecked') {
  //                     e.hide = false;
  //                 } else {
  //                     e.hide = _isColumnToHide(columnName);
  //                 }
  //             } if (columnName === 'action') {
  //                 e.hide = false;
  //                 e.width = 52;
  //                 e.pinned = 'right';
  //             }
  //         });
  //         params.columnApi.setColumnState(txColumnSetting);
  //     }
  //     if (props.isColumnFitTable) {
  //         params.api.sizeColumnsToFit();
  //         props.updateState({
  //             isColumnFitTable: false
  //         })
  //     }
  // }

  const itemSelection = (item) => {
    let selectItem = find(selectedProformaInvoices, {
      invoiceProformaId: Number(item.invoiceProformaId),
    });
    let updatedItems = [];
    if (selectItem) {
      updatedItems = selectedProformaInvoices.filter(
        (obj) => obj.invoiceProformaId !== item.invoiceProformaId
      );
    } else {
      updatedItems = JSON.parse(JSON.stringify(selectedProformaInvoices));
      updatedItems.push(item);
    }
    props.updateState({ selectedProformaInvoices: updatedItems });
  };

  const selectAll = (context) => {
    if (!context) {
      selectedProformaInvoices.splice(0, selectedProformaInvoices.length);
      props.updateState({ selectedProformaInvoices: selectedProformaInvoices });
      return;
    }
    for (let i = 0; i < proformaInvoiceList[pageNumber].length; i++) {
      let item = proformaInvoiceList[pageNumber][i];
      let selectItem = find(selectedProformaInvoices, {
        invoiceProformaId: Number(item.invoiceProformaId),
      });
      if (!selectItem) {
        selectedProformaInvoices.push(item);
      }
    }
    props.updateState({ selectedProformaInvoices: selectedProformaInvoices });
  };

  const isCheckedAll = () => {
    if (
      !proformaInvoiceList ||
      !proformaInvoiceList[pageNumber] ||
      proformaInvoiceList[pageNumber].length === 0
    ) {
      return false;
    }
    for (let i = 0; i < proformaInvoiceList[pageNumber].length; i++) {
      let item = proformaInvoiceList[pageNumber][i];
      let selectItem = find(selectedProformaInvoices, {
        invoiceProformaId: Number(item.invoiceProformaId),
      });
      if (!selectItem) {
        return false;
      }
    }
    return true;
  };

  return (
    <Fragment>
      {/* <div className="invoice-header">
                <PageBreadcrumb breadCrumbList={breadCrumbList} />
            </div> */}
      {/* <div className="view-container mt0"> */}
        <div className="view-container-actions">
        <Skeleton loading={props.headerLoading} paragraph={false} active>
          <div className="left-actions">
            <div className="table-heading">
              <FormattedMessage
                id={props.isSchoolUser ? 'heading.fees.feesInvoiceList':"heading.sales.salesInvoice"}
                defaultMessage=""
              />
            </div>
            {proformaInvoiceCount ? (
              <>
                <div className="vertical-sep" />
                <div>{proformaInvoiceCount}</div>
              </>
            ) : (
              ""
            )}
          </div>
          {/* <div className=""> */}
          {/* <button className="all-checkbox-control">
                                <Checkbox
                                    checked={isAllChecked}
                                    onChange={(e) => {
                                        toggleAllChecked(e.target.checked);
                                        props.gridApi.forEachNode(obj => {
                                            obj.setDataValue('isChecked_1', e.target.checked);
                                        })
                                    }}
                                />
                            </button> */}
          <div className="right-actions">
            <button
              className="action-btn"
              onClick={() => {
                const invoiceList = [];
                // props.gridApi.forEachNode(obj => {
                //     if (obj.data.isChecked) {
                //         invoiceList.push(obj.data);
                //     }
                // });

                selectedProformaInvoices.forEach((obj) => {
                  invoiceList.push(obj);
                });

                const modalData = {
                  title: <FormattedMessage id="xero.tile" defaultMessage=" " />,
                  modalBody: (
                    <XeroInvoice {...props} invoiceList={invoiceList} />
                  ),
                  width: 1100,
                  hideFooter: true,
                };
                let isXeroUploaded = false;
                invoiceList.forEach((data) => {
                  if (data.isXeroUploaded) {
                    isXeroUploaded = true;
                  }
                });

                if (isXeroUploaded) {
                  return showToasterMessage({
                    messageType: "error",
                    description: (
                      <FormattedMessage
                        id="xero.invoice.slected.message"
                        defaultMessage=""
                      />
                    ),
                  });
                }

                if (invoiceList.length) {
                  props.showModal(modalData);
                } else {
                  showToasterMessage({
                    messageType: "error",
                    description: (
                      <FormattedMessage
                        id="xero.invoice.slected.before.message"
                        defaultMessage=" "
                      />
                    ),
                  });
                }
              }}
            >
              <Tooltip
                placement="right"
                title={props.intl.formatMessage({
                  id: "tooltip.uploadToXero",
                  defaultMessage: "",
                })}
              >
                <img src={XeroIcon} alt="" style={{ height: "20px" }} />{" "}
                <i className="fa fa-upload" />
              </Tooltip>
            </button>
          </div>
          </Skeleton>
          {/* </div> */}
        </div>

        {/* <div className="agGridWrapper"> */}
        {/* <div className="ag-theme-balham" style={agGridStyle} >
                            <AgGridReact
                                columnDefs={columnDefs}
                                rowData={proformaInvoiceList[pageNumber] || []}
                                rowDragManaged={true}
                                defaultColDef={{
                                    flex: 1,
                                    autoHeight: true,
                                    wrapText: true,
                                }}
                                domLayout={"autoHeight"}
                                animateRows={true}
                                //getRowHeight={getRowHeight}
                                onGridReady={onGridReady}
                                onModelUpdated={onModelUpdated}
                                onColumnResized={onColumnResized}
                                onDragStopped={() => {
                                    onColumnMoved({}, true);
                                }}
                            //onColumnMoved={onColumnMoved}
                            >
                            </AgGridReact>
                        </div> */}
        <Skeleton loading={props.listLoading} paragraph={{ rows: 12 }} active>
        <div className="table-container">
          <table>
            <thead>
              <tr>
                <th>
                  <Checkbox
                    onChange={(e) => {
                      selectAll(e.target.checked);
                    }}
                    checked={isCheckedAll()}
                  />
                </th>

                <th>
                  Proforma #
                </th>

                <th>
                  <FormattedMessage
                    id="customer.salesInvoice.listing.header.soNo"
                    defaultMessage=""
                  />
                </th>

                <th>
                  <FormattedMessage
                    id="customer.salesInvoice.listing.header.quoteNumber"
                    defaultMessage=""
                  />
                </th>

                <th>
                  <FormattedMessage
                    id="customer.salesInvoice.listing.header.customer"
                    defaultMessage=""
                  />
                </th>

                <th>
                  <FormattedMessage
                    id="customer.salesInvoice.listing.header.invoiceAmount"
                    defaultMessage=""
                  />
                </th>

                <th>
                  <FormattedMessage
                    id="customer.salesInvoice.listing.header.invoiceConverted"
                    defaultMessage=""
                  />
                </th>

                <th>
                  <FormattedMessage
                    id="customer.salesInvoice.listing.header.paymentTerm"
                    defaultMessage=""
                  />
                </th>

                <th>
                  <FormattedMessage
                    id="customer.salesInvoice.listing.header.invoiceDate"
                    defaultMessage=""
                  />{" "}
                </th>

                <th>
                  <FormattedMessage
                    id="customer.salesInvoice.listing.header.invoiceDueDate"
                    defaultMessage=""
                  />
                </th>

                <th>
                  <FormattedMessage
                    id="customer.salesInvoice.listing.header.systemStatus"
                    defaultMessage=""
                  />
                </th>

                {!_isColumnToHide("project") ? (
                  <th>
                    <FormattedMessage id="common.project" defaultMessage="" />
                  </th>
                ) : (
                  ""
                )}

                {!_isColumnToHide("isProformaConversion") ? (
                  <th>
                    <FormattedMessage
                      id="customer.salesInvoice.listing.header.isProformaConversion"
                      defaultMessage=""
                    />
                  </th>
                ) : (
                  ""
                )}
              </tr>
            </thead>
            <tbody>
              {proformaInvoiceList && proformaInvoiceList[pageNumber]
                ? proformaInvoiceList[pageNumber].map((item, i) => {
                    return (
                      <tr key={i}>
                        <td>
                          <Checkbox
                            onClick={() => {
                              itemSelection(item);
                            }}
                            checked={
                              find(selectedProformaInvoices, {
                                invoiceProformaId: Number(
                                  item.invoiceProformaId
                                ),
                              })
                                ? true
                                : false
                            }
                          />
                        </td>

                        <td>
                          <div
                            className="cursor-pointer"
                            onClick={() => {
                              openSalesInvoiceDetails(item);
                            }}
                          >
                            PI-{item.invoiceProformaId || i}
                          </div>
                        </td>

                        <td>
                          <div
                            className="cursor-pointer"
                            onClick={() => {
                              openSoDetails(item);
                            }}
                          >
                            {item.soNumber}
                          </div>
                        </td>

                        <td>
                          <div
                            className="cursor-pointer"
                            onClick={() => {
                              openSQDetails(item);
                            }}
                          >
                            {item.quotationNumber}
                          </div>
                        </td>

                        <td>
                          <div className="cursor-pointer">
                            <div
                             onClick={() => {
                              props.pushModalToStack({  
                        
                                modalBody: <CustomerProfile {...props}
                                customerId= {item.customerId}
                              //   customerName={rowData.customerName}
                              //   customerDetails={rowData}
                            />,
                            width: '100%',
                            hideTitle: true,  
                            hideFooter: true,
                            wrapClassName: "new-transaction-wrapper",
                            })
                            }}
                              // onClick={() => {
                              //   props.history.push("customers/details", {
                              //     customerId: item.customerId,
                              //     customerName: item.customerName,
                              //   });
                              // }}
                            >
                              {item.customerName}
                            </div>
                          </div>
                        </td>

                        <td>
                          {/* {item.invoiceTotalAmount && item.invoiceTotalAmount !== '-' ? <span> {item.foreignCurrencyIcon ? <i className={item.foreignCurrencyIcon}></i> : (item.foreignCurrency ? <span>{item.foreignCurrency}</span> : (props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "))}{item.invoiceTotalAmount.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} </span> : "-"} */}
                          {item.invoiceTotalAmount &&
                          item.invoiceTotalAmount !== "-" ? (
                            <span>
                              {" "}
                              {item.foreignCurrencyIcon ? (
                                <i className={item.foreignCurrencyIcon}></i>
                              ) : item.foreignCurrency ? (
                                <span>{item.foreignCurrency}</span>
                              ) : props.companyInfo.currencyIcon ? (
                                <i
                                  className={props.companyInfo.currencyIcon}
                                ></i>
                              ) : (
                                props.companyInfo.currencyCode + " "
                              )}
                              {fixedDecimalAndFormateNumber(
                                item.invoiceTotalAmount
                              )}{" "}
                            </span>
                          ) : (
                            "-"
                          )}
                        </td>

                        <td>
                          {item.isInvoiceConverted ? (
                            <img
                              src={checkIcon}
                              style={{ height: "15px", width: "15px" }}
                              alt="Invoice"
                            />
                          ) : (
                            <img
                              src={crossIcon}
                              style={{ height: "10px", width: "10px" }}
                              alt="Invoice"
                            />
                          )}
                        </td>

                        <td>{item.paymentTermName}</td>

                        <td>
                          <div>
                            <Tooltip
                              placement="topLeft"
                              title={
                                item.dateCreated &&
                                getMomentDateForUIReadOnly({
                                  date: item.dateCreated,
                                  format: CONSTANTS.DISPLAY_TOOLTIP_DATE_FORMAT,
                                })
                              }
                            >
                              {getMomentDateForUIReadOnly(
                                { date: new Date(item.dateCreated) },
                                format
                              )}
                            </Tooltip>
                          </div>
                        </td>

                        <td>
                          <div>
                            <Tooltip
                              placement="topLeft"
                              title={
                                item.expiryDate &&
                                getMomentDateForUIReadOnly({
                                  date: item.expiryDate,
                                  format: CONSTANTS.DISPLAY_TOOLTIP_DATE_FORMAT,
                                })
                              }
                            >
                              {item.expiryDate && getMomentDateForUIReadOnly(
                                { date: new Date(item.expiryDate) },
                                format
                              )}
                            </Tooltip>
                          </div>
                        </td>

                        {/* <td>
                          {item.status === "Rejected" &&
                          item.rejectionReason ? (
                            <div>
                              <Tooltip
                                placement="topLeft"
                                title={item.rejectionReason}
                              >
                                <span
                                  className={[
                                    "status-code",
                                    item.status.toLowerCase(),
                                  ].join(" ")}
                                >
                                  <FormattedMessage
                                    id={item.status}
                                    defaultMessage={item.status}
                                  />
                                </span>
                              </Tooltip>
                            </div>
                          ) : (
                            <div>
                              {" "}
                              <span
                                className={[
                                  "status-code",
                                  item.paymentStatus?.toLowerCase(),
                                ].join(" ")}
                              >
                                <FormattedMessage
                                  id={item?.paymentStatus}
                                  defaultMessage=""
                                />
                              </span>{" "}
                            </div>
                          )}
                        </td> */}

<td>
                          {item.paymentStatus ?
                         (
                              <span
                                className={[
                                  "status-code",
                                  item.paymentStatus?.toLowerCase(),
                                ].join(" ")}
                              >
                                <FormattedMessage
                                  id={item?.paymentStatus}
                                  defaultMessage=""
                                />
                              </span>
                           
                          ):
                          <span
                                className={[
                                  "status-code",
                                  item.status?.toLowerCase(),
                                ].join(" ")}
                              >
                                <FormattedMessage
                                  id={item?.status}
                                  defaultMessage=""
                                />
                              </span>
                          }
                        </td>

                        {!_isColumnToHide("project") ? (
                          <td>
                            {item.projectMasterId ? (
                              <span>
                                {item.projectMasterId + "-" + item.projectName}
                              </span>
                            ) : (
                              ""
                            )}
                          </td>
                        ) : (
                          ""
                        )}

                        {!_isColumnToHide("isProformaConversion") ? (
                          <td>
                            {item.isInvoiceConverted ? (
                              <img
                                src={checkIcon}
                                style={{ height: "15px", width: "15px" }}
                                alt="Invoice"
                              />
                            ) : (
                              <img
                                src={crossIcon}
                                style={{ height: "10px", width: "10px" }}
                                alt="Invoice"
                              />
                            )}
                          </td>
                        ) : (
                          ""
                        )}
                      </tr>
                    );
                  })
                :  <tr key="empty-data-box">
                <td colSpan={"100%"} style={{ borderBottom: "none" }}>
                    <Empty />
                </td>
            </tr>}
            </tbody>
          </table>
        </div>
        {/* </div> */}
      </Skeleton>
      {/* </div> */}
      <div className="footer">
      <Skeleton loading={props.headerLoading} paragraph={false} active>

        <div className="f-left"></div>
        <div className="f-right">
          <Pagination
            size="small"
            total={proformaInvoiceCount}
            showTotal={showTotal}
            defaultPageSize={props.pageSize}
            showSizeChanger
            pageSizeOptions={[10, 25, 50, 100, 200]}
            onChange={(pageNumber, pageSize) => {
              loadPageData(pageNumber, pageSize);
              updateState({ pageSize: pageSize, pageNumber: pageNumber });
            }}
            onShowSizeChange={(pageNumber, pageSize) => {
              handlePageSizeChange(pageNumber, pageSize, props);
            }}
          />
        </div>
      </Skeleton>
      </div>
    </Fragment>
  );
};

export default injectIntl(ProformaInvoiceListingComp);
