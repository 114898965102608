import React, { Fragment } from "react";
import { injectIntl, FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
// import { AgGridReact } from 'ag-grid-react';
import { Skeleton, Pagination, Tooltip } from "antd";
import {
  fetchPaginationDataIfNeeded,
  getMomentDateForUIReadOnly,
  sortTable,
} from "../../../../utils";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
//import { AG_GRID_CLASS_CONSTANTS } from '../../../../static/cssClassConstants'
import { CONSTANTS } from "../../../../static/constants";
import StandardSalesEnquiry from "../../../../containers/customer/salesEnquiry/StandardSalesEnquiry";
import CustomerProfile from "../../../../containers/modal/modalBody/customer/Profile";

const salesInquiryDraft = (props) => {
  const {
    inquiryDraftList,
    updateState,
    companyInfo,
    inquiryDraftCount,
    pageNumber,
    pageSize,
    dir = 0,
  } = props;
  // const getRowHeight = (params) => {
  //     let height = 35;
  //     return height;
  // };

  const showTotal = (total) => {
    return (
      props.intl.formatMessage({
        id: "pagination.text.total",
        defaultMessage: "",
      }) +
      ` ${total} ` +
      props.intl.formatMessage({
        id: "pagination.text.items",
        defaultMessage: "",
      })
    );
  };

  const loadPageData = (pageNumber, pageSize) => {
    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      pageNumber: pageNumber,
      pageSize: pageSize,
    };
    fetchPaginationDataIfNeeded(
      "fetchSalesInquiryDrafts",
      "inquiryDraftList",
      props,
      payload
    );
  };

  const handlePageSizeChange = (pageNumber, pageSize, props) => {
    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      pageNumber: pageNumber,
      pageSize: pageSize,
    };
    props.resetDraftPaginatedData();
    props.fetchSalesInquiryDrafts(payload);
    props.updateState({ pageSize: pageSize, pageNumber: pageNumber });
  };

  //const permittedModules = getActionMenuPermissionData();
  // const columnDefs = [
  //     {
  //         headerComponentFramework: () => {
  //             return <FormattedMessage id='customer.salesOrder.listing.header.draftNo' defaultMessage='' />;
  //         },
  //         field: "inquiryDraftId",

  //         cellRendererFramework: (param) => <div className="cursor-pointer" >
  //             <Link
  //                 to={
  //                     {
  //                         pathname: '/admin/sales-inquiry-create',
  //                         state: {
  //                             customerId: param.data.customerId,
  //                             inquiryData: param.data.inquiryData,
  //                             inquiryDraftId: param.data.inquiryDraftId,
  //                             clone: true
  //                         }
  //                     }}
  //             ><FormattedMessage id='draft.text' defaultMessage='' />-{param.value}</Link>
  //         </div>,
  //         rowDrag: true,
  //         resizable: true,
  //         width: 100,
  //     },
  //     {
  //         headerComponentFramework: () => {
  //             return <FormattedMessage id='customer.salesOrder.listing.header.customer' defaultMessage='' />;
  //         },
  //         field: "customerName",
  //         resizable: true,
  //         width: 100,
  //         cellRendererFramework: (link) => <div className="cursor-pointer" >
  //             <Link
  //                 to={
  //                     {
  //                         pathname: 'customers/details',
  //                         state: {
  //                             customerId: link.data.inquiryData.customerId,
  //                             customerName: link.data.inquiryData.customerName,
  //                         }
  //                     }}
  //                 className='company-name'
  //             >
  //                 {link.data.inquiryData.customerName}
  //             </Link>
  //         </div>,
  //     },

  //     {
  //         headerComponentFramework: () => {
  //             return <FormattedMessage id='customer.salesOrder.listing.header.date' defaultMessage='' />;
  //         },
  //         field: "inquiryDate",
  //         cellRendererFramework: (link) => {
  //             return <div>
  //                 <Tooltip placement="topLeft" title={(link.data.inquiryData.inquiryDate && getMomentDateForUIReadOnly({date: link.data.inquiryData.inquiryDate, format: CONSTANTS.DISPLAY_TOOLTIP_DATE_FORMAT}))}>
  //                     {(link.data.inquiryData.inquiryDate && getMomentDateForUIReadOnly({date: link.data.inquiryData.inquiryDate, format: CONSTANTS.DISPLAY_DATE_FORMAT}))}
  //                 </Tooltip>
  //             </div>
  //         },
  //         resizable: true,
  //         width: 100,
  //     },

  // ]

  // const agGridStyle = {
  //     height: '100%',
  //     width: '100%'
  // };

  // const onGridReady = (params) => {
  //     props.updateState({
  //         gridApi: params.api,
  //         gridColumnApi: params.columnApi
  //     });
  //     params.api.sizeColumnsToFit();
  // }
  const sortColumn = (e) => {
    sortTable(
      document.getElementById("inquiry-draft-table"),
      e.target.cellIndex,
      1 - dir
    );
    updateState({ dir: 1 - dir });
  };

  return (
    <Fragment>
        <div className="view-container-actions">

          <div className="left-actions">
          <Skeleton loading={props.headerLoading} paragraph={false} active>
            <div className="table-heading">
              <FormattedMessage
                id="sales.inquiryDraft.heading"
                defaultMessage=""
              />
            </div>
            {pageSize ? (
              <>
                <div className="vertical-sep" />
                <div>{inquiryDraftCount}</div>
              </>
            ) : (
              ""
            )}
            </Skeleton>
          </div>
          <div className="right-actions"></div>
        </div>

        <div className="table-container">
        <Skeleton loading={props.listLoading} paragraph={{ rows: 11 }} active>
          <table id="inquiry-draft-table">
            <thead>
              <tr>
                <th>S. No.</th>
                <th onClick={sortColumn}>
                  <FormattedMessage
                    id="customer.salesOrder.listing.header.draftNo"
                    defaultMessage=""
                  />
                </th>
                <th onClick={sortColumn}>
                  <FormattedMessage
                    id="customer.salesOrder.listing.header.customer"
                    defaultMessage=""
                  />
                </th>
                <th onClick={sortColumn}>
                  <FormattedMessage
                    id="customer.salesOrder.listing.header.date"
                    defaultMessage=""
                  />
                </th>
              </tr>
            </thead>
            <tbody>
              {inquiryDraftList && inquiryDraftList[pageNumber] ? (
                inquiryDraftList[pageNumber].map((rowData, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>
                      <div
                        className="cursor-pointer"
                        onClick={() => {
                          props.pushModalToStack({
                            modalBody: (
                              <StandardSalesEnquiry
                                {...props}
                                customerId={rowData.customerId}
                                inquiryDraftId={rowData.inquiryDraftId}
                                inquiryData={rowData.inquiryData}
                                clone={true}
                              />
                            ),
                            width: "100%",
                            hideTitle: true,
                            hideFooter: true,
                            wrapClassName: "new-transaction-wrapper",
                          });
                        }}

                        // {
                        //     state: {
                        //         customerId: rowData.customerId,
                        //         inquiryData: rowData.inquiryData,
                        //         inquiryDraftId: rowData.inquiryDraftId,
                        //         clone: true
                        //     }
                        // }
                      >
                        <FormattedMessage id="draft.text" defaultMessage="" />-
                        {rowData.inquiryDraftId}
                      </div>
                    </td>
                    <td>
                      <div
                         onClick={() => {
                          props.pushModalToStack({  
                    
                            modalBody: <CustomerProfile {...props}
                            customerId= {rowData.customerId}
                          //   customerName={rowData.customerName}
                          //   customerDetails={rowData}
                        />,
                        width: '100%',
                        hideTitle: true,  
                        hideFooter: true,
                        wrapClassName: "new-transaction-wrapper",
                        })
                       }}
                        // to={{
                        //   pathname: "customers/details",
                        //   state: {
                        //     customerId: rowData.inquiryData.customerId,
                        //     customerName: rowData.inquiryData.customerName,
                        //   },
                        // }}
                        className="company-name cursor-pointer"
                      >
                        {rowData.inquiryData.customerName}
                      </div>
                    </td>
                    <td>
                      <Tooltip
                        placement="topLeft"
                        title={
                          rowData.inquiryData.inquiryDate &&
                          getMomentDateForUIReadOnly({
                            date: rowData.inquiryData.inquiryDate,
                            format: CONSTANTS.DISPLAY_TOOLTIP_DATE_FORMAT,
                          })
                        }
                      >
                        {rowData.inquiryData.inquiryDate &&
                          getMomentDateForUIReadOnly({
                            date: rowData.inquiryData.inquiryDate,
                            format: CONSTANTS.DISPLAY_DATE_FORMAT,
                          })}
                      </Tooltip>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td>No data exits.</td>
                </tr>
              )}
            </tbody>
          </table>
        </Skeleton>
        </div>

        {/* <div className='agGridWrapper'>
                    <div className="ag-theme-balham" style={agGridStyle} >
                        {props.txColumns.length ? <AgGridReact
                            onGridReady={onGridReady}
                            columnDefs={columnDefs}
                            rowData={inquiryDraftList[pageNumber] || []}
                            rowDragManaged={true}
                            domLayout={"autoHeight"}
                            defaultColDef={{
                                flex:1,
                                autoHeight: true,
                                wrapText: true,
                            }}
                            suppressDragLeaveHidesColumns={true} 
                            animateRows={true}
                            //getRowHeight={getRowHeight}
                        >
                        </AgGridReact>: null}
                    </div>
                </div> */}
   
      <div className="footer">
        <div className="f-left"></div>
        <div className="f-right">
          <Pagination
            size="small"
            total={inquiryDraftCount}
            showTotal={showTotal}
            defaultPageSize={props.pageSize}
            showSizeChanger
            pageSizeOptions={[10, 25, 50, 100, 200]}
            // showQuickJumper
            onChange={(pageNumber, pageSize) => {
              loadPageData(pageNumber, pageSize);
              updateState({ pageSize: pageSize, pageNumber: pageNumber });
            }}
            onShowSizeChange={(pageNumber, pageSize) => {
              handlePageSizeChange(pageNumber || 1, pageSize, props);
            }}
          />
        </div>
      </div>
    </Fragment>
  );
};

export default injectIntl(salesInquiryDraft);
