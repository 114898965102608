import React, { Fragment } from "react";
import { injectIntl, FormattedMessage } from "react-intl";
//import { AgGridReact } from 'ag-grid-react';
import { Skeleton, Pagination, Checkbox, Menu ,Empty} from "antd";
import {
  fetchPaginationDataIfNeeded,
  fixedDecimalNumber,
  showToasterMessage,
} from "../../../utils";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import "./index.scss";
import * as find from "lodash.find";
import { DownOutlined } from "@ant-design/icons";
import DropdownAction from "antd/lib/dropdown";
import { ICONS } from "../../../static/constants";
import CategoryDetailsModel from "../../../containers/modal/modalBody/product/CategoryDetailsModel";

const CollectionComp = (props) => {
  //const [isAllChecked, toggleAllChecked] = useState(false);
  const {
    collectionProducts,
    updateState,
    companyInfo,
    collectionProductCount,
    pageNumber,
    selectedItems = [],
  } = props;

  const showTotal = (total) => {
    return (
      props.intl.formatMessage({
        id: "pagination.text.total",
        defaultMessage: "",
      }) +
      ` ${total} ` +
      props.intl.formatMessage({
        id: "pagination.text.items",
        defaultMessage: "",
      })
    );
  };

  const loadPageData = (pageNumber, pageSize) => {
    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      pageNumber: pageNumber,
      pageSize: pageSize,
    };
    fetchPaginationDataIfNeeded(
      "fetchCollectionProducts",
      "collectionProducts",
      props,
      payload
    );
  };
  const handlePageSizeChange = (pageNumber, pageSize, props) => {
    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      pageNumber: pageNumber,
      pageSize: pageSize,
    };
    props.resetCollectionProductsPage();
    props.fetchCollectionProducts(payload);
    //props.updateState({ pageSize: pageSize, pageNumber: pageNumber })
  };

  const itemSelection = (item) => {
    let selectItem = find(selectedItems, { itemId: Number(item.itemId) });
    let updatedItems = [];
    if (selectItem) {
      updatedItems = selectedItems.filter((obj) => obj.itemId !== item.itemId);
    } else {
      updatedItems = JSON.parse(JSON.stringify(selectedItems));
      updatedItems.push(item);
    }
    props.updateState({ selectedItems: updatedItems });
  };

  const selectAll = (context) => {
    if (!context) {
      selectedItems.splice(0, selectedItems.length);
      props.updateState({ selectedItems: selectedItems });
      return;
    }
    for (let i = 0; i < collectionProducts[pageNumber].length; i++) {
      let item = collectionProducts[pageNumber][i];
      let selectItem = find(selectedItems, { itemId: Number(item.itemId) });
      if (!selectItem) {
        selectedItems.push(item);
      }
    }
  };

  const isCheckedAll = () => {
    if (
      !collectionProducts ||
      !collectionProducts[pageNumber] ||
      collectionProducts[pageNumber].length === 0
    ) {
      return false;
    }
    for (let i = 0; i < collectionProducts[pageNumber].length; i++) {
      let usr = collectionProducts[pageNumber][i];
      let selectItem = find(selectedItems, { itemId: Number(usr.itemId) });
      if (!selectItem) {
        return false;
      }
    }
    return true;
  };

  const checkMenu = (e) => {
    return (
      <Menu className="row-action-dropdown">
        <Menu.Item key="0" value="title">
          Actions
        </Menu.Item>

        <Menu.Divider />

        <Menu.Item
          key="1"
          onClick={() => {
            if (selectedItems.length > 0) {
              const modalBody = (
                <FormattedMessage id="save.confirmation" defaultMessage="" />
              );
              const modalData = {
                modalBody,
                handleSubmit: () => {
                  props.updateCollectiveItems(
                    {
                      relationshipId: props.companyInfo.relationshipId,
                      itemIdList: (selectedItems || []).map(
                        (itemObj) => itemObj.itemId
                      ),
                      isCollectionItem: 0,
                      pageNumber: props.pageNumber,
                      pageSize: props.pageSize,
                    },
                    props
                  );
                  props.hideModal();
                  selectAll(false);
                },
              };
              props.showModal(modalData);
            } else {
              return showToasterMessage({
                description: props.intl.formatMessage({
                  id: "common.record.notSelected.proceeding",
                  defaultMessage: "",
                }),
                messageType: "error",
              });
            }
          }}
        >
          <i className={ICONS.DELETE} /> Remove
        </Menu.Item>
      </Menu>
    );
  };
  const openClassDetailPopup = (item) => {
    const modalData = {
      title: (
        <FormattedMessage
          id="inventory.invValuation.listing.model.title"
          defaultMessage=""
        />
      ),
      modalBody: <CategoryDetailsModel {...props} CategoryObj={item} />,
      width: "50%",
      hideFooter: true,
    };
    props.pushModalToStack(modalData);
  };
  return (
    <Fragment>
      <div className="view-container-actions">
        <div className="left-actions">
          <div className="table-heading"></div>
          Collection List
          <div className="vertical-sep" />
          <div>{collectionProductCount}</div>
        </div>
      </div>
      <Skeleton loading={props.isLoading} active paragraph={{ rows: 12 }}>
        {/* <button className="all-checkbox-control">
                                <Checkbox
                                    checked={isCheckedAll()}
                                    onChange={(e) => {
                                        selectAll(e.target.checked);
                                    }}
                                />
                            </button> */}

        {/* <button className="all-checkbox-control cursor-pointer" 
                            onClick={() => {
                                // const itemList = [];
                                // props.gridApi.forEachNode(obj => {
                                //     if (obj.data.isChecked) {
                                //         itemList.push(obj.data.itemId);
                                //     }
                                // });
                                if (selectedItems.length > 0) {
                                    const modalBody = <FormattedMessage id='save.confirmation' defaultMessage='' />;
                                    const modalData = {
                                        modalBody,
                                        handleSubmit: () => {
                                            props.updateDealOfTheDayItem({
                                                relationshipId: props.companyInfo.relationshipId,
                                                itemIdList: (selectedItems || []).map(itemObj => itemObj.itemId),
                                                isDealOfTheDayItem: 0,
                                                pageNumber: props.pageNumber,
                                                pageSize: props.pageSize
                                            })
                                            props.hideModal();
                                            selectAll(false);
                                        },
                                    };
                                    props.showModal(modalData);
                                } else {
                                    return showToasterMessage({
                                        description: props.intl.formatMessage({ id: 'common.record.notSelected.proceeding', defaultMessage: '' }),
                                        messageType: 'error'
                                    })
                                }
                            }}
                            >
                               <i className="fa fa-trash" />
                            </button> */}

        {/* <div className="ag-theme-balham" style={agGridStyle} >
                        <AgGridReact
                                columnDefs={columnDefs}
                                rowData={dealOfTheDayProducts[pageNumber] || []}
                                rowDragManaged={true}
                                defaultColDef={{
                                    flex:1,
                                    autoHeight: true,
                                    wrapText: true,
                                }}
                                domLayout={"autoHeight"}
                                animateRows={true}
                                onGridReady={onGridReady}
                                onModelUpdated={onModelUpdated}
                            >
                            </AgGridReact>
                        </div> */}
        <div className="table-container">
          <table>
            <thead>
              <tr>
                <th>
                  <div className="check-cell">
                    <Checkbox
                      onChange={(e) => {
                        selectAll(e.target.checked);
                      }}
                      checked={isCheckedAll()}
                    />
                    {props.permissionData.update ||
                    props.permissionData?.create ? (
                      <DropdownAction overlay={checkMenu()} trigger={["click"]}>
                        <DownOutlined />
                      </DropdownAction>
                    ) : (
                      ""
                    )}
                  </div>
                </th>

                <th className="skuCol">
                  <FormattedMessage
                    id="drawer.inventory.sku"
                    defaultMessage=""
                  />
                </th>
                <th className="productCol">
                  <FormattedMessage id="modal.txDetails.common.product" />
                </th>
                <th>
                  <FormattedMessage
                    id="drawer.inventory.category"
                    defaultMessage=""
                  />
                </th>
                <th>
                  <FormattedMessage
                    id="drawer.inventory.manufacturer"
                    defaultMessage=""
                  />
                </th>
                <th>
                  <FormattedMessage
                    id="drawer.inventory.brand"
                    defaultMessage=""
                  />
                </th>
                <th>
                  <FormattedMessage
                    id="drawer.inventory.warehouse"
                    defaultMessage=""
                  />
                </th>
                <th>
                  <FormattedMessage id="b2bPrice.text" defaultMessage="" />
                </th>
              </tr>
            </thead>
            <tbody>
              {collectionProducts && (collectionProducts[pageNumber] || []).length
                ? (collectionProducts[pageNumber] || []).map((item, i) => {
                    return (
                      <tr key={i}>
                        <td>
                          <Checkbox
                            onClick={() => {
                              itemSelection(item);
                            }}
                            checked={
                              find(selectedItems, {
                                itemId: Number(item.itemId),
                              })
                                ? true
                                : false
                            }
                          />
                        </td>
                        <td>{item.sku}</td>
                        <td>
                          {" "}
                          <div>{item.itemName}</div>{" "}
                        </td>
                        <td>
                          {(item.inventoryItemCategoryRefs || [])?.length ? (
                            <span
                              onClick={() => {
                                openClassDetailPopup(item);
                              }}
                              className="cursor-pointer"
                            >
                              {(item.inventoryItemCategoryRefs || [])?.length}
                            </span>
                          ) : (
                            "0"
                          )}
                        </td>

                        <td>
                          {item.manufacturerName || (
                            <div className="empty-data"></div>
                          )}
                        </td>
                        <td>
                          {item.brandName || <div className="empty-data"></div>}
                        </td>
                        <td>
                          {item.warehouseName || (
                            <div className="empty-data"></div>
                          )}
                        </td>
                        <td>
                          {item.retailPrice ? (
                            fixedDecimalNumber(item.retailPrice)
                          ) : (
                            <div className="empty-data"></div>
                          )}
                        </td>
                      </tr>
                    );
                  })
                :  <tr key="empty-data-box">
                <td colSpan={"100%"} style={{ borderBottom: "none" }}>
                    <Empty />
                </td>
            </tr>}
            </tbody>
          </table>
        </div>
      </Skeleton>
      <Pagination
        size="small"
        total={collectionProductCount}
        showTotal={showTotal}
        defaultPageSize={props.pageSize}
        showSizeChanger
        pageSizeOptions={[10, 25, 50, 100, 200]}
        onChange={(pageNumber, pageSize) => {
          loadPageData(pageNumber, pageSize);
          updateState({ pageSize: pageSize, pageNumber: pageNumber });
        }}
        onShowSizeChange={(pageNumber, pageSize) => {
          handlePageSizeChange(pageNumber, pageSize, props);
        }}
      />
    </Fragment>
  );
};

export default injectIntl(CollectionComp);
