import React, { Fragment, useMemo } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import './inventoryDrawer.scss';
import { Dropdown } from '../../general/Dropdown';
// import { ErrorMsg } from '../../general/ErrorMessage';
import * as find from 'lodash.find';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Col, Row, Tooltip } from 'antd';
import { ICONS, MODAL_TYPE, MODULE_CODED_VALUES } from '../../../static/constants';
import { QuestionCircleTwoTone } from "@ant-design/icons"
import { getPermissionData } from '../../../utils';
function FinanceTab(props) {
    const {
        intl,
        updateDrawerState,
        allGstRates,
        // allLedgerAccounts,
        globleTaxes,
        //purchaseLedgerAccountName,
        //salesLedgerAccountName,
        companyInfo,
        defaultTaxObj,
        // isPopulateTax,
        invalidDefaultTaxClass,
        
    } = props;
  
    const handleNewTaxAddition = (props, payload) => {
        const formData = payload.formData;
        const { showModal, companyInfo } = props;
        formData.relationshipId = companyInfo.relationshipId;
        const data = {
            title: <FormattedMessage id='addItem.text.newTax' defaultMessage='' />,
            formData,
            hideFooter: true,
            modalData: {
                modalType: MODAL_TYPE.NEW_TAX,
                data: payload,

            },
            handleSubmit: (formData = {}) => {
                data.formData.submittedOnce = true;
                showModal(data);
            }
        };
        showModal(data);
    }

    const handleNewHSNAddition = (props, payload) => {
        const formData = payload.formData;
        const { showModal, companyInfo } = props;
        formData.relationshipId = companyInfo.relationshipId;
        const data = {
            title: <FormattedMessage id='addItem.text.newHsn' defaultMessage='' />,
            formData,
            hideFooter: true,
            modalData: {
                modalType: MODAL_TYPE.NEW_HSN,
                data: payload,

            },
            handleSubmit: (formData = {}) => {
                data.formData.submittedOnce = true;
                showModal(data);
            }
        };
        showModal(data);
    }

    const deleteClickHandler = (props, modalType, payload) => {
        const { showModal, companyInfo } = props;
        const modalBody =
            <FormattedMessage
                id='deleteItem.confirmation'
                defaultMessage=''
                values={{ value: payload.text }}
            />;

        let deleteFunc;
        let data = { id: payload.id, relationshipId: companyInfo.relationshipId };
        switch (modalType) {
            case MODAL_TYPE.NEW_TAX: {
                deleteFunc = props.deleteTax;
                if (companyInfo.countryName !== "India") { data.taxType = 'VAT' }
                break;
            }
            default: {
                deleteFunc = () => { }
            }
        }

        const modalData = {
            modalBody,
            handleSubmit: () => { deleteFunc(data) },
        };
        showModal(modalData);
    }

    if (companyInfo.countryName === "India") {
        return (
            <div>

                <Row gutter={[16, 8]}>
                    <Col span={24}>
                        <div className="drawer-label">
                            <FormattedMessage id='drawer.inventory.finance.HSNCode' defaultMessage='' />
                            <Tooltip placement="right" title={intl.formatMessage(
                                {
                                    id: 'tooltip.searchHSN',
                                    defaultMessage: ''
                                }
                            )} trigger={"click"}>
                                <span className='cursor-pointer'><i className={`${ICONS.HELP_P} ml5`} /></span>
                            </Tooltip>
                        </div>
                    </Col>
                </Row>
                <Row gutter={[16, 16]}>
                    <Col span={24}>


                        <Dropdown
                            className={invalidDefaultTaxClass}
                            dropdownCustomStyle='dropdownCustomStyle'
                            items={allGstRates}
                            valueKeyName='hsnCode'
                            valueKeyName2='description'
                            optionKeyName='financeGstGoodsRates'
                            value={props.hsnCodeRate}
                            placeholder={intl.formatMessage({ id: 'drawer.inventory.finance.HSNCode.placeholder', defaultMessage: '' })}
                            onSelect={(optionValue) => {
                                let obj = find(allGstRates, { financeGstGoodsRates: optionValue }) || {};
                                updateDrawerState({
                                    hsnCodeRate: obj.hsnCode,
                                    hsnCodeDescription: obj.description,
                                    defaultTaxObj:
                                    {
                                        ...defaultTaxObj,
                                        hsnCode: obj.hsnCode,
                                        cgstRate: obj.cGstRate,
                                        igstRate: obj.iGstRate,
                                        sgstRate: obj.sGstUtGstRate,
                                        cess: obj.cess

                                    },
                                    invalidDefaultTaxClass: ''
                                });
                            }}
                            allowClear={true}
                            onClear={() => {
                                updateDrawerState({
                                    defaultTaxObj:
                                    {
                                        ...defaultTaxObj,
                                        hsnCode: undefined,
                                        cgstRate: undefined,
                                        igstRate: undefined,
                                        sgstRate: undefined,
                                        cess: undefined,
                                        taxNameDisplay: undefined

                                    },
                                    invalidDefaultTaxClass: ''
                                });
                            }}
                            moduleName={MODULE_CODED_VALUES.TAXES}
                            canAddNew={true}
                            onAddNewClick={(payload) => {
                                payload.formData = {
                                    taxName: payload.textEntered,
                                    submittedOnce: false
                                }
                                handleNewHSNAddition(props, payload, MODAL_TYPE.NEW_HSN)
                            }}
                        />
                        {/* <ErrorMsg
                            validator={() => { return !props.isPopulateTaxClicked || !!defaultTaxObj.taxNameDisplay }}
                            message={intl.formatMessage(
                                { id: 'drawer.inventory.validation.defaultHSN', defaultMessage: '' }
                            )} /> */}
                    </Col>
                </Row>


                {/* <Form.Item>
                    <Row>
                        <Col span={8} className="text-right">
                            <Tooltip placement="bottom" title={intl.formatMessage(
                                {
                                    id: 'tooltip.taxPopulateOnItemSelect',
                                    defaultMessage: ''
                                }
                            )}>
                                <span className="tooltip-title">
                                    {intl.formatMessage({ id: 'drawer.inventory.finance.popuplateTaxOnTransactions', defaultMessage: '' })}
                                </span>
                            </Tooltip>
                        </Col>
                        <Col span={8}>
                            <Checkbox style={{ padding: 2 }} checked={isPopulateTax === 1}
                                onChange={(e) => {
                                    if (!props.hsnCodeRate) {
                                        updateDrawerState({ isPopulateTax: 0, invalidDefaultTaxClass: 'input-text-error', isPopulateTaxClicked: true })
                                    } else {
                                        updateDrawerState({ isPopulateTax: e.target.checked ? 1 : 0, invalidDefaultTaxClass: '', isPopulateTaxClicked: false });
                                    }
                                }}
                            />
                        </Col>
                    </Row>
                </Form.Item> */}

                {/* <Form.Item>
                    <Row>
                        <Col span={8} className="text-right">
                            <Tooltip placement="bottom" title="All purchase transactions will be recorded under this account">
                                <span className="tooltip-title">
                                    <FormattedMessage id='drawer.inventory.finance.purchaseLedgerAccount' defaultMessage='' />
                                </span>
                            </Tooltip>
                        </Col>

                        <Col span={8}>
                            <Dropdown
                                style={{ width: 350 }}
                                items={allLedgerAccounts}
                                valueKeyName='ledgerAccountName'
                                optionKeyName='ledgerAccountName'
                                value={purchaseLedgerAccountName}
                                placeholder={intl.formatMessage({ id: 'drawer.inventory.finance.purchaseLedgerAccount.placeholder', defaultMessage: '' })}
                                onSelect={(optionValue, elem) => {
                                    let obj = allLedgerAccounts.find(acc => (acc.ledgerAccountName === optionValue));
                                    updateDrawerState({
                                        purchaseLedgerAccountName: optionValue,
                                        coaPurchaseLedgerAccountId:obj.chartOfAccountDetailsId
                                    });
                                }}
                                allowClear={true}
                                onClear={() => {
                                    updateDrawerState({
                                        purchaseLedgerAccountName: undefined,
                                        coaPurchaseLedgerAccountId: undefined
                                     });
                                }}
                            />
                        </Col>
                    </Row>
                </Form.Item>

                <Form.Item>
                    <Row>
                        <Col span={8} className="text-right">
                            <Tooltip placement="bottom" title="All sales transactions will be recorded under this account">
                                <span className="tooltip-title">
                                    <FormattedMessage id='drawer.inventory.finance.salesLedgerAccount' defaultMessage='' />
                                </span>
                            </Tooltip>
                        </Col>

                        <Col span={8}>
                            <Dropdown
                                style={{ width: 350 }}
                                items={allLedgerAccounts}
                                valueKeyName='ledgerAccountName'
                                optionKeyName='ledgerAccountName'
                                value={salesLedgerAccountName}
                                placeholder={intl.formatMessage({ id: 'drawer.inventory.finance.salesLedgerAccount.placeholder', defaultMessage: '' })}
                                onSelect={(optionValue, elem) => {
                                    let obj = allLedgerAccounts.find(acc => (acc.ledgerAccountName === optionValue));
                                    updateDrawerState({
                                        salesLedgerAccountName: optionValue,
                                        coaSalesLedgerAccountId: obj.chartOfAccountDetailsId
                                    });
                                }}
                                allowClear={true}
                                onClear={() => {
                                    updateDrawerState({
                                        salesLedgerAccountName: undefined,
                                        coaSalesLedgerAccountId: undefined
                                     });
                                }}
                            />
                        </Col>
                    </Row>
                </Form.Item> */}

                <Fragment>
                    <Row>


                        {props.defaultTaxObj.cgstRate ?
                            <Col span={3}>
                                CGST :

                                {props.defaultTaxObj.cgstRate}
                            </Col>
                            : ''}
                        {props.defaultTaxObj.sgstRate ?
                            <Col span={4}>
                                SGST/UGST :

                                {props.defaultTaxObj.sgstRate}
                            </Col>
                            : ''}
                        {props.defaultTaxObj.igstRate ?
                            <Col span={3}>
                                IGST :

                                {props.defaultTaxObj.igstRate}
                            </Col>
                            : ''}


                    </Row>
                </Fragment>
            </div>
        )
    }
    else {
        return (
           
                <Row style={{ display: 'list-item' }} >
                    <Col span={24} >
                        <FormattedMessage id="drawer.inventory.finance.tax" defaultMessage='' />
                    </Col>


                    <Col span={24}>
                        <Dropdown
                            className={invalidDefaultTaxClass}
                            style={{ width: 350 }}
                            items={globleTaxes}
                            valueKeyName='taxNameDisplay'
                            optionKeyName='taxSingleRateId'
                            value={defaultTaxObj.taxSingleRateId}
                            moduleName={MODULE_CODED_VALUES.TAXES}

                            canAddNew={true }
                            canDelete={ true}
                            deleteClickHandler={(payload) => {
                                deleteClickHandler(props, MODAL_TYPE.NEW_TAX, payload);
                            }}
                            onAddNewClick={(payload) => {
                                payload.formData = {
                                    taxName: payload.textEntered,
                                    submittedOnce: false
                                }
                                handleNewTaxAddition(props, payload, MODAL_TYPE.NEW_TAX)
                            }}
                            placeholder={intl.formatMessage({ id: 'drawer.inventory.finance.tax.placeholder', defaultMessage: '' })}
                            onSelect={(optionValue, elem) => {
                                let obj = globleTaxes.find(tax => (tax.taxSingleRateId === optionValue));
                                updateDrawerState({ defaultTaxObj: { ...defaultTaxObj, globleTaxRate: obj.taxPercent, taxNameDisplay: obj.taxNameDisplay, taxSingleRateId: obj.taxSingleRateId }, invalidDefaultTaxClass: '' });
                            }}
                            allowClear={true}
                            onClear={() => {
                                updateDrawerState({
                                    defaultTaxObj: { ...defaultTaxObj, globleTaxRate: 0, taxNameDisplay: undefined, taxSingleRateId: undefined }, invalidDefaultTaxClass: ''
                                });
                            }}
                        />
                        {/* <ErrorMsg
                                validator={() => { return !props.isPopulateTaxClicked || !!defaultTaxObj.taxNameDisplay }}
                                message={intl.formatMessage(
                                    { id: 'drawer.inventory.validation.defaultTax', defaultMessage: '' }
                                )} /> */}
                    </Col>
                </Row>
        )
                {/* <Form.Item>
                    <Row>
                        <Col span={8} className="text-right">
                            <Tooltip placement="bottom" title={intl.formatMessage(
                                {
                                    id: 'tooltip.taxPopulateOnItemSelect',
                                    defaultMessage: ''
                                }
                            )}>
                                <span className="tooltip-title">
                                    {intl.formatMessage({ id: 'drawer.inventory.finance.popuplateTaxOnTransactions', defaultMessage: '' })}
                                </span>
                            </Tooltip>
                        </Col>
                        <Col span={8}>
                            <Checkbox style={{ padding: 2 }} checked={isPopulateTax === 1}
                                onChange={(e) => {
                                    if (!defaultTaxObj || !defaultTaxObj.taxNameDisplay) {
                                        updateDrawerState({ isPopulateTax: 0, invalidDefaultTaxClass: 'input-text-error', isPopulateTaxClicked: true })
                                    } else {
                                        updateDrawerState({ isPopulateTax: e.target.checked ? 1 : 0, invalidDefaultTaxClass: '', isPopulateTaxClicked: false });
                                    }
                                }}
                            />
                        </Col>
                    </Row>
                </Form.Item> */}
                {/* <Form.Item>
                    <Row>
                        <Col span={8} className="text-right">
                            <Tooltip placement="bottom" title="All purchase transactions will be recorded under this account">
                                <span className="tooltip-title">
                                    <FormattedMessage id='drawer.inventory.finance.purchaseLedgerAccount' defaultMessage='' />
                                </span>
                            </Tooltip>
                        </Col>

                        <Col span={8}>
                            <Dropdown
                                style={{ width: 350 }}
                                items={allLedgerAccounts}
                                valueKeyName='ledgerAccountName'
                                optionKeyName='ledgerAccountName'
                                value={purchaseLedgerAccountName}
                                placeholder={intl.formatMessage({ id: 'drawer.inventory.finance.purchaseLedgerAccount.placeholder', defaultMessage: '' })}
                                onSelect={(optionValue, elem) => {
                                    let obj = allLedgerAccounts.find(acc => (acc.ledgerAccountName === optionValue));
                                    updateDrawerState({
                                        purchaseLedgerAccountName: optionValue,
                                        coaPurchaseLedgerAccountId: obj.chartOfAccountDetailsId
                                    });
                                }}
                                allowClear={true}
                                onClear={() => {
                                    updateDrawerState({
                                        purchaseLedgerAccountName: undefined,
                                        coaPurchaseLedgerAccountId: undefined
                                    });
                                }}
                            />
                        </Col>
                    </Row>
                </Form.Item>
                <Form.Item>
                    <Row>
                        <Col span={8} className="text-right">
                            <Tooltip placement="bottom" title="All sales transactions will be recorded under this account">
                                <span className="tooltip-title">
                                    <FormattedMessage id='drawer.inventory.finance.salesLedgerAccount' defaultMessage='' />
                                </span>
                            </Tooltip>
                        </Col>

                        <Col span={8}>
                            <Dropdown
                                style={{ width: 350 }}
                                items={allLedgerAccounts}
                                valueKeyName='ledgerAccountName'
                                optionKeyName='ledgerAccountName'
                                value={salesLedgerAccountName}
                                placeholder={intl.formatMessage({ id: 'drawer.inventory.finance.salesLedgerAccount.placeholder', defaultMessage: '' })}
                                onSelect={(optionValue, elem) => {
                                    let obj = allLedgerAccounts.find(acc => (acc.ledgerAccountName === optionValue));
                                    updateDrawerState({
                                        salesLedgerAccountName: optionValue,
                                        coaSalesLedgerAccountId: obj.chartOfAccountDetailsId
                                    });
                                }}
                                allowClear={true}
                                onClear={() => {
                                    updateDrawerState({
                                        salesLedgerAccountName: undefined,
                                        coaSalesLedgerAccountId: undefined
                                    });
                                }}
                            />
                        </Col>
                    </Row>
                </Form.Item>
             */}
         
        
    }

}

export default injectIntl(FinanceTab);
