import React from "react";
import LeadReminderList from "../../../components/leads/Reminders";
// import LeadSourceDrawer from "../../drawer/leads/leadSourceList";
import { getAllLeadSources } from "../../modal/modalBody/leads/createLead/action";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createLeadSource } from "../../drawer/leads/leadSourceList/action";
import { fetchReminderByUserId,getAllLeadReminderByUserId } from "./action";
import { showModal, hideModal } from "../../../actions/commonActions";
import { getAllLeadReminder } from "../AllLeads/action";
import { checkACLPermission } from "../../../utils";
import { MODULE_CODED_VALUES, PERMISSION_VALUES } from "../../../static/constants";
class LeadReminder extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      pageNumber: 0,
      pageSize: 100,
      isLoaded:false,
      anjali:true
    };
  }

  componentDidMount() {
    const companyInfo = this.props.companyInfo || {};

    const payload = {
      relationshipId: companyInfo.relationshipId,
      pageNumber: 0,
      pageSize: 100,
    }
    const { permissions } = this.props;
    const primaryPerm = (permissions && permissions.primary) || [];
    checkACLPermission(
      primaryPerm,
      MODULE_CODED_VALUES.CRM_LEAD_ALL_REMINDER,
      PERMISSION_VALUES.CREATE
    ) && this.props.getAllLeadReminder(payload);
  }

  componentWillReceiveProps(props){
    const { permissions } = props;
    const primaryPerm = (permissions && permissions.primary) || [];
    const permissionsData = {
      allReminder:checkACLPermission(
        primaryPerm,
        MODULE_CODED_VALUES.CRM_LEAD_ALL_REMINDER,
        PERMISSION_VALUES.CREATE
      )
      // cn add more like this and handel all the tab
    }
    const payload = {
      relationshipId: props.companyInfo.relationshipId,
      pageNumber: 0,
      createdByEmpId: props.companyInfo?.relationshipEmployees?.relationshipEmployeeId,
      pageSize: 25,
    };

    if(!this.state.isLoaded && !permissionsData.allReminder){
      props.getAllLeadReminderByUserId(payload);
      this.setState({
        isMyLeads: true,
        isLoaded:true
      })
    }


  }

  render() {
    return (
      <>
        <LeadReminderList
          {...this.props}
          {...this.state}
          updateState={(data) => {
            this.setState(data);
          }}
        />

       {/* {this.state.leadSourceListDrawer && <LeadSourceDrawer {...this.state} {...this.props} updateState={(data) => { this.setState(data) }} />} */}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
      companyInfo: state.common.companyInfo,
      userInfo: state.common.userInfo,
      allLeadReminderList: state.crm.crmLeadReminderList,
      crmLeadSourceList: state.crm.crmLeadSourceList,
      permissions: state.auth.permissions,
      listLoading: state.common.listLoading

  }
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
  getAllLeadSources,
  createLeadSource,
  fetchReminderByUserId,
  showModal,
  hideModal,getAllLeadReminder,getAllLeadReminderByUserId
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(LeadReminder);
