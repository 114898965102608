import React, { Component } from 'react';
import { connect } from 'react-redux';
import CheckoutComp from '../../../../../components/modal/modalBody/eStore/Checkout';
import { bindActionCreators } from 'redux';
import { showModal, hideModal } from '../../../../../actions/commonActions';

import { fetchE_StoreData } from '../../../../../actions/commonActions';
import AdditionalFieldDrawer from '../../../../drawer/eStore/checkoutAdditionalField/index';
import { updateAdditionalValue } from './action';
class Checkout extends Component {
    constructor(props){
        super(props);
        this.state= {
            isLoading : false,
            activeTabKey : "shipping",
         additionalValue :{},
        }
    }
    componentWillMount() {
       
        const companyInfo = this.props.userInfo || {};
        const payload = {
            relationshipId: companyInfo.relationshipId,  
        };  
        this.props.fetchE_StoreData(payload)
    }
    componentWillReceiveProps(props){
        if(!this.state.isLoading && Object.keys(JSON.parse(props.storeData.additionalValues || "{}"))){
            this.setState({
                additionalValue:JSON.parse(props.storeData.additionalValues || "{}")
            })
        }
      

    }
    render() {
        return <>
            {this.state.addNewField  && <AdditionalFieldDrawer {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} updateDrawerState={(data) => { this.setState(data) }}/>}
            <CheckoutComp {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} updateDrawerState={(data) => { this.setState(data) }} />
        </>
    }

}


const mapStateToProps = (state) => {
    return {
        remarksData: state.settings.remarksData,
        userInfo: state.common.userInfo,
        storeData:state.common.store_values,
        permissions: state.auth.permissions,


    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchE_StoreData,
    updateAdditionalValue,
    showModal,
    hideModal
}, dispatch);

export default connect(mapStateToProps,mapDispatchToProps)(Checkout);