import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PayGrade from '../../../../components/company/PayrollManagement/PayGrade';
import HourlyPayDrawer from '../../../drawer/company/PayrollManagement/HourlyPay';
import MonthlyPayDrawer from '../../../drawer/company/PayrollManagement/MonthlyPay';


class PayGradeContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data:0
    }
    };
  

  componentDidMount(props) {
    const payload = {
      relationshipId: (this.props.companyInfo || {}).relationshipId,
     
    }
   
  }

  componentWillReceiveProps(props) {
   
  }

  render() {
      return <>

<PayGrade {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
{this.state.hourlyPayDrawer && <HourlyPayDrawer {...this.state} {...this.props} updateState={(data) => { this.setState(data) }} />}
{this.state.monthlyPayDrawer && <MonthlyPayDrawer {...this.state} {...this.props} updateState={(data) => { this.setState(data) }} />}

      </>
    
    ;
  }

}

const mapStateToProps = (state) => {
  return {

    companyInfo: state.common.companyInfo,
    permissions: state.auth.permissions,
   
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PayGradeContainer);
