import React, { Fragment, useEffect } from "react";
import { injectIntl, FormattedMessage } from "react-intl";
import { Dropdown } from "../../../general/Dropdown";
import { ErrorMsg } from "../../../general/ErrorMessage";
import find from "lodash.find";
import "@ant-design/compatible/assets/index.css";
import './index.scss'
import {Drawer,Button,Col,Row,Tabs} from "antd";
import Emails from "./emails";
import Sms from "./sms";
import Whatsapp from "./whatsapp";
import NotificationTabComp from "./notificationDetails";


const { TabPane } = Tabs;


function CommunicationTemplateDrawer(props) {

  const closeDrawer = () => {
    props.updateDrawerState({
      communicationTemplateDrawerVisiable: false
    })
  }
  const { moduleTemplateName = [], selectedModuleName = {}, selectedTransactionName = {}, selectedEventName = '' ,submittedOnce = false, emailContent, smsContent, whatsappContent ,emailAutoTrigger ,smsAutoTrigger, whatsappAutoTrigger ,notification,notificationAutoTrigger} = props;

  const handleSelect = (key, value) => {
    props.updateState({ [key]: value });
  };

  const validateTab = (props) =>{
    let isOK = true;
   
      if(selectedModuleName?.moduleName && selectedTransactionName?.transactionName && selectedEventName ){
        isOK = false;
      }
    
      return isOK;
  }
  const handleSubmit = ()=>{
    const val = props.data;

    const payload ={
     "communicationsTemplateSettingId": val?.communicationsTemplateSettingId ? val.communicationsTemplateSettingId: null,
      "moduleName": selectedModuleName?.moduleName,
      "transactionName": selectedTransactionName?.transactionName,
      "eventName": selectedEventName,
      "relationshipId": props.companyInfo?.relationshipId,
      "isEmailAutoTrigger": emailAutoTrigger ? 1 :0,
      "emailSettings": JSON.stringify(emailContent||{}),
      "isSMSAutoTrigger": smsAutoTrigger ? 1: 0,
      "smsSettings": JSON.stringify(smsContent||{}),
      "notificationSettings":JSON.stringify(notification||{}),
      "isNotificationAutoTrigger": notificationAutoTrigger ? 1 : 0,
      "isWhatsAppAutoTrigger": whatsappAutoTrigger ? 1:0,
      "whatsAppSettings": JSON.stringify(whatsappContent||{}),
      "version": val?.version ? val.version : null
      
      }
      props.generateCommunicationTemplate(payload ,props);
  }


      return (
        <Fragment>
           
          <Drawer title={<FormattedMessage id='modal.heading.communicationTemplate.edit-template' defaultMessage='' />}
            width={720}
            destroyOnClose={true}
            onClose={closeDrawer}
            keyboard={false}
            maskClosable={false}
            className="custom-drawer"
            afterVisibleChange={() => {
            }}
            visible={props.communicationTemplateDrawerVisiable}
            footer={
              <>
                <Button onClick={() => {closeDrawer()}}>Cancel</Button>
                <Button type="primary" onClick={()=>{
                  if (!props.submittedOnce) {
                    props.updateState({ submittedOnce: true });
                  }
                  if(!validateTab(props)){
                   
                    if(emailAutoTrigger && !(emailContent?.emailSubject && emailContent?.emailMessage)) {
                      props.updateState({
                        activeTabKey:"1",
                      })
                      return; 
                    }
                   
                   else if (smsAutoTrigger && !(smsContent?.smsMessage)) {
                      props.updateState({
                        activeTabKey:"2",
                      })
                      return; 
                    }
                   else if (whatsappAutoTrigger && !(whatsappContent?.whatsappMessage)) {
                      props.updateState({
                        activeTabKey:"3",
                      })
                      return; 
                    }
                    else{
                      const modalData = {
                        title: (
                          <FormattedMessage
                            id="drawer.inventory.confirmation.title"
                            defaultMessage=""
                          />
                        ),
                        modalBody: <Fragment>
                        <p>
                          <FormattedMessage
                            id="drawer.customer.confirmation.text"
                            defaultMessage=""
                          />
                        </p>
                      </Fragment>,
                        handleSubmit: () => {
                          handleSubmit()
                          props.hideModal();
                        },
                      };
                      props.showModal(modalData);
                    }
                 
                  }

                }}>Update</Button>
              </>
            }>

<Row gutter={[16, 8]}>
      <Col span={24} className="i-label">
        <FormattedMessage id="drawer.labelName.communicationTemplate.module" defaultMessage="" />
        <span className="required">*</span>
      </Col>
      <Col span={24}>
        <Dropdown
          items={moduleTemplateName}
          valueKeyName="moduleName"
          optionKeyName="id"
          value={selectedModuleName.moduleName}
          placeholder="Select Module"
          onSelect={(id) => {
            const selectedModule = moduleTemplateName.find(mod => mod.id === id) || {};
            handleSelect('selectedModuleName', selectedModule);
            handleSelect('selectedTransactionName', {}); // Reset transactionList when module changes
            handleSelect('selectedEventName', ''); // Reset eventName when module changes
          }}
        />
      </Col>

      <ErrorMsg
                validator={() => {
                  return !props.submittedOnce || !!selectedModuleName.moduleName;
                }}
                message={"Module Name is required"}
              />
    </Row>
    
    <Row gutter={[16, 16]}>
      <Col span={12}>
        <Row gutter={[16, 8]}>
          <Col span={24} className="i-label">
            <FormattedMessage id="drawer.labelName.communicationTemplate.transactions" defaultMessage="" />
            <span className="required">*</span>
          </Col>
          <Col span={24}>
            <Dropdown
              items={selectedModuleName?.transactionList || []}
              valueKeyName="transactionName"
              optionKeyName="id"
              value={selectedTransactionName.transactionName}
              placeholder="Select Transaction Page"
              onSelect={(id) => {
                const selectedTransaction = (selectedModuleName.transactionList || []).find(tran => tran.id === id) || {};
                handleSelect('selectedTransactionName', selectedTransaction);
                handleSelect('selectedEventName', ''); // Reset eventName when transaction changes
              }}
            />
          </Col>
          <ErrorMsg

                validator={() => {
                  return !props.submittedOnce || !!selectedTransactionName.transactionName;
                }}
                message={"User Gstin is required"}
              />
        </Row>
      </Col>

      <Col span={12}>
        <Row gutter={[16, 8]}>
          <Col span={24} className="i-label">
            <FormattedMessage id="drawer.labelName.communicationTemplate.event" defaultMessage="" />
           <span className="required">*</span>
          </Col>
          <Col span={24}>
            <Dropdown
              items={selectedTransactionName.eventName || []}
              value={selectedEventName}
              placeholder="Select Event"
              onSelect={(val) => handleSelect('selectedEventName', val)}
            />
          </Col>
          <ErrorMsg
                validator={() => {
                  return !props.submittedOnce || !!selectedEventName;
                }}
                message={"Event Name is required"}
              />
        </Row>
      </Col>
    </Row>
          {
            (selectedModuleName?.moduleName && selectedTransactionName?.transactionName && selectedEventName) ? (
              <Tabs type="line"
              activeKey={props.activeTabKey || "1"}

              onChange={(key) => {
              
                props.updateState({ activeTabKey: key });
              }}
            >
       <TabPane
        // style={{ paddingBottom: "20px" }}
        tab={<FormattedMessage
          id="drawer.tabName.communicationTemplate.email"
          defaultMessage=""
        />}
        key="1"
      >
       <Emails {...props}/>
       
      </TabPane>
      <TabPane
        // style={{ paddingBottom: "20px" }}
        tab={<FormattedMessage
          id="drawer.tabName.communicationTemplate.sms"
          defaultMessage=""
        />}
        key="2"
      >
        {/* <div className="mt20"> */}
        <Sms {...props}/>
      </TabPane>
      <TabPane
        // style={{ paddingBottom: "20px" }}
        tab={<FormattedMessage
          id="drawer.tabName.communicationTemplate.whatsapp"
          defaultMessage=""
        />}
        key="3"
      >
        <Whatsapp {...props}/>
       
      </TabPane>

      <TabPane
        // style={{ paddingBottom: "20px" }}
        tab={<FormattedMessage
          id="drawer.labelName.communicationTemplate.notification"
          defaultMessage=""
        />}
        key="4"
      >
        <NotificationTabComp {...props}/>
       
      </TabPane>
      </Tabs>
            ):""
          }
           
          </Drawer>
          
        </Fragment>
      );
}

export default injectIntl(CommunicationTemplateDrawer);