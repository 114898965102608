import axios from "axios";
import config from "../../../../config/environmentConfig";
import { COMMON_ACTIONS, COMMUNICATION_ACTION_LIST,CUSTOMER_ACTION_LIST } from "../../../../static/constants";
import { showToasterMessage } from "../../../../utils";
const { lang } = require("../../../../translations/" +
    (sessionStorage.getItem("language") || "en") +
    ".js");

export const saveDG = (payload, props) => {
    return (dispatch) => {
        dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
        return axios
            .post(
                `${config.API_BASE_URL}${config.API_VERSION}/${config.rootContextTwilio}/dg/saveOrUpdate`,
                payload
            )
            .then((response) => {
                if (props) {
                    props.updateHeaderState({
                        communication: {
                            addDGDrawerVisible: false
                        }
                    });
                    props.getDGDetails(response.data);
                    props.openDGDrawerFromListing && props.getAllDG && props.getAllDG(props);
                }
            })
            .catch((err) => {
                showToasterMessage({
                    messageType: "error",
                    description:
                        lang[((err.response || {}).data || {}).message] ||
                        "Some error occurred",
                });
            });
    };
};

export const getAllContacts = (payload) => {
    return (dispatch) => {
        dispatch({ type: COMMON_ACTIONS.SHOW_LIST_LOADER });
        return axios
            .get(
                `${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext
                }/boContact/getAllContacts?relationshipId=${payload.relationshipId}&&pageSize=${payload.pageSize || 25
                }&pageNumber=${(payload.pageNumber || 1) - 1}`
            )
            .then((res) => {
                const data = {
                    pageNo: payload.pageNumber,
                    list: res.data
                }
                dispatch({
                    type: COMMUNICATION_ACTION_LIST.CONTACT_LIST,
                    data: data,
                });

            })
            .catch((err) => {
                showToasterMessage({
                    messageType: "error",
                    description:
                        lang[((err.response || {}).data || {}).message] ||
                        "Some error occurred",
                });
            }).finally(() => {
                dispatch({
                  type: COMMON_ACTIONS.HIDE_LIST_LOADER,
                });
              })
    };
};


export const getContactListCount = (payload) => {
    return (dispatch) => {
        return axios
            .get(
                `${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/boContact/countContact?relationshipId=${payload.relationshipId}&contactType=Customer`
            )
            .then((res) => {
                dispatch({
                    type: COMMUNICATION_ACTION_LIST.CONTACT_LIST_COUNT,
                    data: res.data,
                });
            })
            .catch((err) => {
                showToasterMessage({
                    messageType: "error",
                    description:
                        lang[((err.response || {}).data || {}).message] ||
                        "Some error occurred",
                });
            });
    };
};

export const resetContactPaginatedData = () => {
    return (dispatch) => {
        dispatch({ type: COMMUNICATION_ACTION_LIST.CONTACT_LIST_PAGE_RESET });
    };
};

export const getDGDetails = (payload) => {
    return (dispatch) => {
        return axios
            .get(
                `${config.API_BASE_URL}${config.API_VERSION}/${config.rootContextTwilio
                }/dg/getDGById?relationshipId=${payload.relationshipId}&distributionGroupId=${payload.distributionGroupId}`
            )
            .then((res) => {
                const data = {
                    distributionGroupId: payload.distributionGroupId,
                    details: res.data
                }
                dispatch({
                    type: COMMUNICATION_ACTION_LIST.DG_DETAILS,
                    data: data,
                });
            })
            .catch((err) => {
                if (!err.__isRetryRequest) {
                    showToasterMessage({
                        messageType: "error",
                        description:
                            lang[((err.response || {}).data || {}).message] ||
                            "Some error occurred",
                    });
                }
            });
    };
};

export const fetchSubscribers = (payload) => {
    return (dispatch) => {
        dispatch({ type: COMMON_ACTIONS.SHOW_LIST_LOADER });
        return axios
            .get(
                `${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext
                }/subscriberContact/getSubscribedContact?relationshipId=${payload.relationshipId}&pageSize=${payload.pageSize || 25
                }&pageNumber=${(payload.pageNumber || 1) - 1}`
            )
            .then((res) => {
                dispatch({
                    type: CUSTOMER_ACTION_LIST.FETCH_SUBSCRIBERS,
                    data: res.data,
                });
            })
            .catch((err) => {            
                    showToasterMessage({
                        messageType: "error",
                        description:
                            lang[((err.response || {}).data || {}).message] ||
                            "Some error occurred",
                    });
              
            }).finally(() => {
                dispatch({
                  type: COMMON_ACTIONS.HIDE_LIST_LOADER,
                });
              })
    };
  };




