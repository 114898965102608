import React, {useMemo } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { FormattedMessage } from "react-intl";
import PageBreadcrumb from "../../PageBreadcrumb";
import {
  showModal,
  hideModal,
  pushModalToStack,
  popModalFromStack,
} from "../../../actions/commonActions";
import {
  ICONS
} from "../../../static/constants";
import { getPermissionData } from "../../../utils";

const PageHeader = (props) => {
  const {
    pageName = "",
    breadCrumbList,
    canCreate = false,
    permissions,
    moduleCode = "",
  } = props;
  
  const primaryPerm = (permissions && permissions.primary) || [];
  const permissionsData = useMemo(() => {
    return getPermissionData(primaryPerm, moduleCode);
  }, []);
  
  return (
    <>
      <div className="view-top-bar">
        <div className="top-view-left">
          <div className="page-heading">
            {typeof pageName === "string" && pageName.includes(".") ? (
              <FormattedMessage id={pageName} defaultMessage="" />
            ) : (
              pageName
            )}
          </div>
          <div className="vertical-sep" />
          <PageBreadcrumb breadCrumbList={breadCrumbList} />
        </div>
        <div className="top-view-right">
          {canCreate && permissionsData.create ? (
            <button className="create-action-btn" onClick={props.onCreate}>
              <i className={`${ICONS.ADD} mr5`} />
              <FormattedMessage id="button.create.label" defaultMessage="" />
            </button>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    userInfo: state.common.userInfo,

    permissions: state.auth.permissions,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      showModal,
      hideModal,

      popModalFromStack,
      pushModalToStack,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(PageHeader);
