import React, { Fragment } from "react";
import { Row, Col, Button, Popover, Divider,Skeleton,Input } from "antd";
import { injectIntl, FormattedMessage } from "react-intl";
import * as find from "lodash.find";
import ReactHtmlParser from "react-html-parser";

import { CustomAgGrid } from "../../../../general/CustomAgGrid";
import { AddAndRemoveColumn } from "../../../../general/AddAndRemoveColumn";
import {
  AG_GRID_CONSTANTS,
  GRID_PREFERENCE_CONSTANTS,
} from "../../../../../static/agGridConstants";
import { AG_GRID_CLASS_CONSTANTS } from "../../../../../static/cssClassConstants";
import {
  MODAL_TYPE,
  MODULE_CODED_VALUES,
  PERMISSION_VALUES,
  TRANSACTION_COLUMN_INDEX,
} from "../../../../../static/constants";
import { getLabelName } from "../../../../modal/modalBody/customerInfo";
import { getPermissionData, showToasterMessage } from "../../../../../utils";
import { CustomTableUpload } from "../../../../general/UploadTable";
import CKEditor from "../../../../general/CustomCKEditor/ckeditor";
import { CUSTOM_CONFIGURATION } from "../../../../../static/ckEditorConfigConstants";
import { AWSFileTable } from "../../../../general/AWSFileTable";

const {TextArea} = Input

class ProductDetails extends React.Component {
  constructor() {
    super();
    this.state = {
      sectionVisible: {
        discount: false,
        tax: false,
        expense: false,
      },
      total: 0,
      subtotal: 0,
      subtotalWithoutDiscount: 0,
      totalDiscount: 0,
      expenseAmount: 0,
      totalTax: 0,
      itemLevelTaxes: [],
      rootDiscountPercent: 0,
      rootDiscountAmount: 0,
    };
  }

  addItemConfirmationModal = (props, text, modalType) => {
    const { showModal, companyInfo } = props;
    const labelName = getLabelName(modalType);
    const modalBody = (
      <span>
        <FormattedMessage
          id="addItem.confirmation"
          values={{ value: `${text} to ` }}
          defaultMessage={``}
        />
        {labelName} ?
      </span>
    );

    let addFunc;

    switch (modalType) {
      case MODAL_TYPE.UOM: {
        addFunc = props.addUOM;
        break;
      }
      default: {
        addFunc = () => {};
      }
    }

    const modalData = {
      modalBody,
      handleSubmit: () => {
        addFunc({ text: text, relationshipId: companyInfo.relationshipId });
        props.hideModal();
      },
    };
    showModal(modalData);
  };

  getModalTitle = (newTextObj, modalType) => {
    let title;

    switch (modalType) {
      case MODAL_TYPE.UOM: {
        title = <FormattedMessage id="addItem.text.uom" defaultMessage="" />;
        break;
      }
      default:
        title = (
          <FormattedMessage id="signup.confirmation.title" defaultMessage="" />
        );
    }
    return title;
  };

  handleNewItemAddition = (props, payload, modalType) => {
    const formData = payload.formData;
    const { showModal } = props;

    if (
      formData.textBoxValue &&
      formData.textBoxValue.length &&
      !payload.existsInData
    ) {
      this.addItemConfirmationModal(props, formData.textBoxValue, modalType);
    } else {
      const data = {
        title: this.getModalTitle(payload, modalType),
        formData,
        modalData: { modalType, data: payload },
        handleSubmit: (formData = {}) => {
          if (formData.textBoxValue && formData.textBoxValue.length) {
            this.addItemConfirmationModal(
              props,
              formData.textBoxValue,
              modalType
            );
          } else {
            data.formData.submittedOnce = true;
            showModal(data);
          }
        },
      };
      showModal(data);
    }
  };

  deleteClickHandler = (props, modalType, payload) => {
    const { showModal, companyInfo } = props;
    const modalBody = (
      <FormattedMessage
        id="deleteItem.confirmation"
        defaultMessage={``}
        values={{ value: payload.text }}
      />
    );

    let deleteFunc;

    switch (modalType) {
      case MODAL_TYPE.UOM: {
        deleteFunc = props.deleteUOM;
        break;
      }
      default: {
        deleteFunc = () => {};
      }
    }

    const modalData = {
      modalBody,
      handleSubmit: () => {
        deleteFunc({
          id: payload.id,
          relationshipId: companyInfo.relationshipId,
        });
      },
    };
    showModal(modalData);
  };

  render() {
    const self = this;
    const primaryPerm = (this.props.permissions && this.props.permissions.primary) || [];

    const productPermissionsData = getPermissionData(primaryPerm, MODULE_CODED_VALUES.PRODUCTS )
    const onGridReady = (params) => {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      setTimeout(() => {
        onDataRendered(params);
      }, 500);
    };
    // const { txColumnSetting } = this.props;
    const content = <AddAndRemoveColumn {...this.props} />;
    const _isColumnToHide = (columnName) => {
      // if (this.props.forceHideTxColumn && this.props.forceHideTxColumn.indexOf(columnName) > -1) {
      //     return true;
      // }
      return this.props.txColumns.indexOf(columnName) < 0 &&
        this.props.txMandatoryColumns.indexOf(columnName) < 0
        ? true
        : false;
    };

    const columnDefs = [
      {
        headerComponentFramework: () => {
          return "#";
        },
        cellRendererFramework: (params) => {
          return params.node.rowIndex + 1;
        },
        field: "sno",
        pinned: "left",
        rowDrag: true,
        colId: "sno_1",
        minWidth: 70,
        maxWidth: 70,
        suppressMovable: true,
        lockPosition: true,
        sortable: true,
        cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN,
      },
      {
        headerComponentFramework: () => {
          return (
            <FormattedMessage
              id="supplier.rfq.form.gridHeader.product"
              defaultMessage=""
            />
          );
        },
        field: AG_GRID_CONSTANTS.COMMON.PRODUCT_FIELD,
        colId: AG_GRID_CONSTANTS.COMMON.PRODUCT_FIELD + "_1",
        resizable: true,
        editable: true,
        sortable: true,
        hide: _isColumnToHide(GRID_PREFERENCE_CONSTANTS.PRODUCT),
        minWidth: 120,
        suppressMovable: true,
        permissions: this.props.permissions,
        moduleName: MODULE_CODED_VALUES.PRODUCTS,
        operations: [PERMISSION_VALUES.CREATE, PERMISSION_VALUES.DELETE],
        cellEditor: "customTreeDropDown",
        cellRendererFramework: (params) => {
          setTimeout(() => {
            if (
              params.reactContainer &&
              params.reactContainer.scrollHeight >=
                (params.node.data.maxHeight || 30)
            ) {
              params.node.setRowHeight(params.reactContainer.scrollHeight);
              params.node.data.minHeight =
                params.reactContainer.scrollHeight > 30
                  ? params.reactContainer.scrollHeight
                  : 30;
              this.gridApi && this.gridApi.onRowHeightChanged();
            } else {
              params.node.data.minHeight =
                params.reactContainer.scrollHeight > 30
                  ? params.reactContainer.scrollHeight
                  : 30;
            }
          }, 500);
          return ReactHtmlParser(params.data.product);
        },
        suppressKeyboardEvent: (params) => {
          const KEY_ENTER = 13;
          const keyCode = params.event.keyCode;
          const gridShouldDoNothing = params.editing && keyCode === KEY_ENTER;
          return gridShouldDoNothing;
        },
        cellEditorParams: (obj) => {
          return {
            lastColumnCell: false,
            options: this.props.products,
            onSearch: this.props.onSearch,
            permissions: this.props.permissions,
            moduleName: MODULE_CODED_VALUES.PRODUCTS,
            operations: [PERMISSION_VALUES.CREATE, PERMISSION_VALUES.DELETE],
            onAddNewClick: () => {
              self.props.updateHeaderState({
                inventory: {
                  inventoryDrawerVisible: true,
                },
              });
            },
            canAddNew: true,
            value: obj.node.data.selectedProductValue || [],
            selectedLabel: obj.node.data.product || "",
            allowClear: true,
            onSelect: (selectedValue) => {
              //obj.node.setDataValue(obj.colDef.field, selectedValue);
              obj.node.data.selectedProductValue =
                obj.node.selectedProductValue;
              const itemId =
                obj.node.data.selectedProductValue &&
                obj.node.data.selectedProductValue[0];
              if (itemId) {
                const variantId =
                  obj.data.selectedProductValue &&
                  obj.data.selectedProductValue.length === 2
                    ? obj.data.selectedProductValue[1]
                    : 0;
                obj.node.data.productObj =
                  find(this.props.products, { itemId: Number(itemId) }) || {};
                obj.node.data.variant = variantId
                  ? find(obj.node.data.productObj.inventoryItemVariantList, {
                      variantId: Number(variantId),
                    })
                  : obj.node.data.productObj.inventoryItemVariantList[0];
                if (obj.node.data.productObj.shortDescription) {
                  obj.node.data.description =
                    obj.node.data.productObj.shortDescription;
                }

                obj.node.data.uom = obj.node.data.variant.uomName;
                const defaultItemHsnAndTax =
                  obj.node.data.productObj.inventoryItemDefaultTaxList[0] || {};
                obj.node.data.hsnCode = (defaultItemHsnAndTax || {}).hsnCode;
                let selectedTax = {};
                if (defaultItemHsnAndTax.taxSingleRateId) {
                  selectedTax =
                    find(this.props.taxes, {
                      taxSingleRateId: defaultItemHsnAndTax.taxSingleRateId,
                    }) || {};
                } else {
                  selectedTax =
                    find(this.props.taxes, {
                      taxPercent:
                        (defaultItemHsnAndTax || {}).igstRate ||
                        (defaultItemHsnAndTax || {}).cgstRate ||
                        (defaultItemHsnAndTax || {}).globleTaxRate,
                    }) || {};
                }
                obj.node.data.productTaxId = selectedTax.taxSingleRateId;

                let isDuplicateItem = false;
                this.gridApi &&
                  this.gridApi.forEachNode((rowNode, index) => {
                    const { data } = rowNode;
                    if (
                      data.product === selectedValue &&
                      index !== obj.node.rowIndex
                    ) {
                      isDuplicateItem = true;
                    }
                  });
                if (isDuplicateItem) {
                  showToasterMessage({
                    messageType: "warning",
                    description: "Duplicate product is selected",
                  });
                }

                obj.node.data.isDuplicateItem = isDuplicateItem;
                setTimeout(() => {
                  this.gridApi && this.gridApi.stopEditing();
                }, 100);
              }
              const payload = { relationshipId: this.props.companyInfo?.relationshipId};
              this.props.fetchProducts(payload)
            },
          };
        },
        cellClassRules: {
          "ag-grid-cell-warning-boundary": function (obj) {
            let isDuplicateItem = false;
            self.gridApi &&
              self.gridApi.forEachNode((rowNode, index) => {
                const { data } = rowNode;
                if (
                  data.product &&
                  data.product === obj.node.data.product &&
                  obj.node.rowIndex !== index
                ) {
                  isDuplicateItem = true;
                }
              });
            return isDuplicateItem;
          },
        },
      },
      {
        headerComponentFramework: () => {
          return (
            <FormattedMessage
              id="supplier.rfq.form.gridHeader.description"
              defaultMessage=""
            />
          );
        },
        field: AG_GRID_CONSTANTS.COMMON.DESCRIPTION_FIELD,
        cellClass: " ag-cell-description",
        resizable: true,
        editable: true,
        sortable: true,
        minWidth: 120,
        suppressMovable: true,
        hide: _isColumnToHide(GRID_PREFERENCE_CONSTANTS.DESCRIPTION),
        colId: GRID_PREFERENCE_CONSTANTS.DESCRIPTION + "_1",
        cellEditor: 'CKEditorGrid',
        cellEditorParams: (params) => {
          return {
            value: params.node.data.description || "",
            isEditorLoaded: this.props.isEditorLoaded,
            onChange: (value) => {
              params.node.data[params.colDef.field] = value;
              params.node.data.isEditorLoaded = true;
              this.props.updateState({
                isEditorLoaded: true,
              });
            },
          };
        },
        cellRendererFramework: (params) => {
          setTimeout(() => {
            if (
              params.reactContainer &&
              params.reactContainer.scrollHeight > 30 &&
              params.reactContainer.scrollHeight >=
                (params.node.data.minHeight || 0)
            ) {
              params.eParentOfValue.style.height = "inherit";
              params.eParentOfValue.style.height =
                params.reactContainer.scrollHeight;
              params.node.data.maxHeight = params.reactContainer.scrollHeight;
              params.node.setRowHeight(params.reactContainer.scrollHeight);
              this.gridApi && this.gridApi.onRowHeightChanged();
            }
          }, 500);
          return ReactHtmlParser(params.data.description);
        },
        suppressKeyboardEvent: (params) => {
          const KEY_ENTER = 13;
          const keyCode = params.event.keyCode;
          const gridShouldDoNothing =
            params.event.target.value &&
            params.editing &&
            keyCode === KEY_ENTER;
          params.node.data.scrollHeight = `${params.event.target.scrollHeight}px`;
          if (
            params.event.target.scrollHeight > (params.data.minHeight || 30)
          ) {
            if (params.event.path && params.event.path[2]) {
              params.event.path[2].style.height = "inherit";
              params.event.path[2].style.height = `${params.event.target.scrollHeight}px`;
            }
            params.node.setRowHeight(params.event.target.scrollHeight);
            this.gridApi && this.gridApi.onRowHeightChanged();
          } else {
            if (params.event.path && params.event.path[2]) {
              params.event.path[2].style.height = "inherit";
              params.event.path[2].style.height = `${
                params.node.data.minHeight || 30
              }px`;
            }
            params.node.setRowHeight(params.node.data.minHeight || 30);
            this.gridApi && this.gridApi.onRowHeightChanged();
          }
          return gridShouldDoNothing;
        },
      },
      {
        headerComponentFramework: () => {
          return (
            <FormattedMessage
              id="supplier.rfq.form.gridHeader.qty"
              defaultMessage=""
            />
          );
        },
        field: AG_GRID_CONSTANTS.COMMON.QTY_FIELD,
        colId: GRID_PREFERENCE_CONSTANTS.QUANTITY + "_1",
        minWidth: 120,
        suppressMovable: true,
        editable: true,
        sortable: true,
        resizable: true,
        cellEditor: "customNumberEditor",
        cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN,
        cellEditorParams: (obj) => {
          return {
              onChange: (value) => {
                  obj.node.data.qty = value;
                  obj.api.refreshCells();

              }
          }
        },
      },
      {
        headerComponentFramework: () => {
          return (
            <FormattedMessage
              id="supplier.rfq.form.gridHeader.uom"
              defaultMessage=""
            />
          );
        },
        field: AG_GRID_CONSTANTS.COMMON.UOM_FIELD,
        editable: true,
        minWidth: 100,
        maxWidth: 140,
        suppressMovable: true,
        sortable: true,
        resizable: true,
        hide: _isColumnToHide(GRID_PREFERENCE_CONSTANTS.UOM),
        colId: GRID_PREFERENCE_CONSTANTS.UOM + "_1",
        cellEditor: "customDropDownEditor",
        suppressKeyboardEvent: (params) => {
          const KEY_ENTER = 13;
          const keyCode = params.event.keyCode;
          const gridShouldDoNothing = params.editing && keyCode === KEY_ENTER;
          return gridShouldDoNothing;
        },
        cellEditorParams: (obj) => {
          return {
            lastColumnCell: true,
            items: this.props.uomList || [],
            optionKeyName: "uomId",
            valueKeyName: "uomName",
            canAddNew: true,
            canDelete: true,
            allowClear: true,
            onClear: () => {
              //obj.node.setDataValue(obj.colDef.field, undefined);
            },
            permissions: this.props.permissions,
            moduleName: MODULE_CODED_VALUES.UOM,
            operations: [PERMISSION_VALUES.CREATE, PERMISSION_VALUES.DELETE],
            deleteClickHandler: (payload) => {
              this.deleteClickHandler(this.props, MODAL_TYPE.UOM, payload);
            },
            onAddNewClick: (payload) => {
              payload.formData = {
                textBoxValue: payload.textEntered,
                submittedOnce: false,
              };
              this.handleNewItemAddition(this.props, payload, MODAL_TYPE.UOM);
            },
            onSelect: (selectedValue) => {
              //obj.node.setDataValue(obj.colDef.field, selectedValue);
              setTimeout(() => {
                this.gridApi && this.gridApi.stopEditing();
              }, 100);

            },
          };
        },
        cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN,
      },
      {
        headerComponentFramework: () => {
          return (
            <div className="cursor-pointer">
              <Popover
                content={content}
                title={
                  <div className="text-center">
                    <FormattedMessage id="common.addRemove" defaultMessage="" />{" "}
                  </div>
                }
                placement="left"
                trigger="click"
              >
                <i className="fa fa-bars" />
              </Popover>
            </div>
          );
        },
        minWidth: 50,
        maxWidth: 50,
        suppressMovable: true,
        field: "action",
        lockPosition: true,
        colId: "action_1",
        pinned: "right",
        //cellRenderer: !this.props.isRFQFromPr ? 'customActionEditor' : '',
        cellRenderer: "customActionEditor",
        suppressNavigable: true,
        cellRendererParams: (params) => {
          return {
            onClick: () => {
              this.gridApi.updateRowData({
                remove: [
                  this.gridApi.getDisplayedRowAtIndex(params.node.rowIndex)
                    .data,
                ],
              });
              if (this.gridApi.rowRenderer.lastRenderedRow < 0) {
                this.gridApi.updateRowData({ add: [{}] });
              }
            },
          };
        },
      },
    ];

    const draftRfq = () => {
      const modalData = {
        modalBody: (
          <FormattedMessage
            id="supplier.rfq.form.draft.confirmation"
            defaultMessage={``}
          />
        ),
        handleSubmit: () => {
          const payload = {
            props: this.props,
            gridObject: this.gridApi,
            state: this.state,
          };
          this.props.draftRFQ(payload);
          this.props.hideModal();
          this.props.popModalFromStack();
        },
      };
      this.props.showModal(modalData);
    };

    const createRFQ = () => {
      // let isRequiredFiledMissing = false;
      // this.gridApi.forEachNode((rowNode, index) => {
      //     let { data } = rowNode;
      //     if (index === 0 && ((!data.product && !data.description) || !data.qty || data.qty === "0.00")) {
      //         isRequiredFiledMissing = true;
      //     } else if (!data.product && !data.qty) {
      //         data = {};
      //     } else if ((!data.product && !data.description) || !data.qty) {
      //         isRequiredFiledMissing = true;
      //     }
      // });

      // //if (isRequiredFiledMissing || (!(this.props.customer && this.props.customer.customerId) && !(this.props.isConvertedFromPR || this.props.isConvertedFromSQ))) {
      // if (isRequiredFiledMissing) {
      //     return showToasterMessage({
      //         messageType: 'error', description: 'Please select Product/Description and Quantity'
      //     });
      // }

      let errorKey = "";

      this.gridApi.forEachNode((rowNode, index) => {
        let { data } = rowNode;
        if (index === 0 && !data.product && !data.description) {
          errorKey = "missingProduct.errorMsg";
        } else if (index === 0 && !Number(data.qty || 0)) {
          errorKey = "missingQty.errorMsg";
        }
      });

      if (errorKey) {
        return showToasterMessage({
          messageType: "error",
          description: this.props.intl.formatMessage({
            id: errorKey,
            defaultMessage: "",
          }),
        });
      }

      const modalData = {
        modalBody: (
          <FormattedMessage
            id="supplier.rfq.form.confirmation"
            defaultMessage={``}
          />
        ),
        handleSubmit: () => {
          const payload = {
            props: this.props,
            gridObject: this.gridApi,
            state: this.state,
          };
          this.props.createRFQ(payload,this.props);
          this.props.hideModal();
        },
      };
      this.props.showModal(modalData);
    };

    const saveColumnIndex = (columnState) => {
      let data = this.props.txPreferences || {};
      let gridPreference = {};
      data.relationshipId = (this.props.companyInfo || {}).relationshipId;
      data.clientUserAccountId = (this.props.userInfo || {}).userId;
      if (data.tablePreference) {
        gridPreference = JSON.parse(data.tablePreference);
      }
      gridPreference.columnSetting = columnState;
      gridPreference.columnList = this.props.txColumns;
      data.gridPreference = JSON.stringify(gridPreference);
      data.preferenceId = this.props.preferenceId;
      data.tablePreference = this.props.tablePreference;
      this.props.saveUserPrefrences(data);
    };

    const saveColumnWidth = (columnState, width) => {
      let data = this.props.txPreferences || {};
      let gridPreference = {};
      data.relationshipId = (this.props.companyInfo || {}).relationshipId;
      data.clientUserAccountId = (this.props.userInfo || {}).userId;
      if (data.gridPreference) {
        gridPreference = JSON.parse(data.gridPreference);
      }
      gridPreference.columnSetting = columnState;
      gridPreference.columnList = this.props.txColumns;
      data.gridPreference = JSON.stringify(gridPreference);
      data.preferenceId = this.props.preferenceId;
      data.tablePreference = this.props.tablePreference;
      this.props.saveUserPrefrences(data, this.props);
    };

    let moveEvent = {};
    const onColumnMoved = (event, isDragStopped) => {
      if (event.column) {
        moveEvent = event;
      }
      if (
        isDragStopped &&
        moveEvent.column &&
        moveEvent.column.colDef &&
        moveEvent.source !== AG_GRID_CONSTANTS.EVENT_SOURCE.API
      ) {
        saveColumnIndex(moveEvent.columnApi.getColumnState());
      }
    };

    const onColumnResized = (event, tablegridApi, tableGridColumnApi) => {
      const gridColumnApi = tableGridColumnApi || this.gridColumnApi;
      //const gridApi = tablegridApi || this.gridApi;
      event.api.resetRowHeights();
      if (
        event.type === "columnResized" &&
        event.source !== "sizeColumnsToFit" &&
        event.source !== "api" &&
        event.finished === true
      ) {
        //event.api.resetRowHeights();
        if (
          gridColumnApi &&
          gridColumnApi.columnController.bodyWidth <
            gridColumnApi.columnController.scrollWidth
        ) {
          this.gridApi.sizeColumnsToFit();
          // const columnArray = event.columnApi.getColumnState();
          // for (let i = (columnArray.length - 1); i >= 0; i--) {
          //     if (columnArray[i] && !columnArray[i].hide && columnArray[i].colId !== 'action_1') {
          //         columnArray[i].width = columnArray[i].width + (gridColumnApi.columnController.scrollWidth - gridColumnApi.columnController.bodyWidth);
          //         break;
          //     }
          // }
          // saveColumnWidth(columnArray);
          setTimeout(() => {
            const columnArray = event.columnApi.getColumnState();
            saveColumnWidth(columnArray);
          }, 1000);
        } else {
          saveColumnWidth(event.columnApi.getColumnState());
        }
      }
    };

    const getRowHeight = (params) => {
      const rowHeight = params.node.data.scrollHeight
        ? Number(params.node.data.scrollHeight.split("px")[0]) - 30
        : 30;
      return rowHeight < 30 ? 30 : rowHeight;
    };

    const onModelUpdated = (params) => {
      if (this.props.tableDataReRendered) {
        //params.api.resetRowHeights();
        this.props.updateState({
          tableDataReRendered: false,
        });
        let actionIndex = 0;
        let amountIndex = 0;
        let txColumnSetting =
          this.props.txColumnSetting && this.props.txColumnSetting.length
            ? this.props.txColumnSetting
            : params.columnApi.getColumnState();
        const columnToBeSet = [];
        const indexObjArr = TRANSACTION_COLUMN_INDEX.RFQ_FORM;

        indexObjArr.forEach((e) => {
          let colObj = find(txColumnSetting, { colId: e + "_1" });
          if (colObj) {
            columnToBeSet.push(colObj);
          }
        });

        txColumnSetting = txColumnSetting.filter((ele) => {
          let columnName = ele.colId.split("_")[0];
          return indexObjArr.indexOf(columnName) === -1;
        });
        txColumnSetting = columnToBeSet.concat(txColumnSetting);
        txColumnSetting.forEach((e, index) => {
          let columnName = e.colId.split("_")[0];
          if (columnName !== "action" && columnName !== "amount") {
            if (columnName === "isChecked" || columnName === "sno") {
              e.pinned = "left";
              e.lockPosition = true;
              e.hide = false;
            } else {
              e.hide = _isColumnToHide(columnName);
            }
          }
          if (columnName === "amount") {
            e.hide = false;
            amountIndex = index;
            e.lockPosition = true;
            e.pinned = "right";
          }
          if (columnName === "action") {
            actionIndex = index;
            e.width = 52;
            e.hide = false;
            e.pinned = "right";
          }
        });
        const actionObj = txColumnSetting[actionIndex];
        const amountObj = txColumnSetting[amountIndex];
        txColumnSetting = txColumnSetting.filter((e) => {
          let columnName = e.colId.split("_")[0];
          return columnName !== "action" && columnName !== "amount";
        });
        amountObj.lockPosition = true;
        actionObj.lockPosition = true;
        txColumnSetting.push(amountObj);
        txColumnSetting.push(actionObj);
        params.columnApi.setColumnState(txColumnSetting);
        setTimeout(() => {
          params.api.sizeColumnsToFit();
        }, 500);
      }
    };

    const onDataRendered = (params) => {
      let txColumnSetting =
        this.props.txColumnSetting && this.props.txColumnSetting.length
          ? this.props.txColumnSetting
          : params.columnApi.getColumnState();
      let actionIndex = 0;
      let amountIndex = 0;
      if (Object.keys(txColumnSetting).length) {
        const columnToBeSet = [];
        const indexObjArr = TRANSACTION_COLUMN_INDEX.RFQ_FORM;

        indexObjArr.forEach((e) => {
          let colObj = find(txColumnSetting, { colId: e + "_1" });
          if (colObj) {
            columnToBeSet.push(colObj);
          }
        });

        txColumnSetting = txColumnSetting.filter((ele) => {
          let columnName = ele.colId.split("_")[0];
          return indexObjArr.indexOf(columnName) === -1;
        });
        txColumnSetting = columnToBeSet.concat(txColumnSetting);
        txColumnSetting.forEach((e, index) => {
          let columnName = e.colId.split("_")[0];
          if (columnName !== "action" && columnName !== "amount") {
            if (columnName === "isChecked" || columnName === "sno") {
              e.pinned = "left";
              e.hide = false;
            } else {
              e.hide = _isColumnToHide(columnName);
            }
          }
          if (columnName === "action") {
            e.width = 52;
            actionIndex = index;
            e.hide = false;
            e.pinned = "right";
          }
          if (columnName === "amount") {
            e.hide = false;
            e.lockPosition = true;
            amountIndex = index;
            e.pinned = "right";
          }
        });
        const actionObj = txColumnSetting[actionIndex];
        const amountObj = txColumnSetting[amountIndex];
        txColumnSetting = txColumnSetting.filter((e) => {
          let columnName = e.colId.split("_")[0];
          return columnName !== "action" && columnName !== "amount";
        });
        amountObj.lockPosition = true;
        actionObj.lockPosition = true;
        txColumnSetting.push(amountObj);
        txColumnSetting.push(actionObj);
        this.gridColumnApi.setColumnState(txColumnSetting);
        setTimeout(() => {
          if (
            this.gridColumnApi &&
            this.gridColumnApi.columnController.bodyWidth <
              this.gridColumnApi.columnController.scrollWidth
          ) {
            params.api.sizeColumnsToFit();
          }
        }, 500);
      } else {
        params.api.sizeColumnsToFit();
      }
    };


    return (
      <Fragment>
          <Skeleton loading={this.props.detailLoading} active paragraph={{ rows: 5 }}>

        <div className="transaction-table">
          {this.props.txColumns.length ? (
            <CustomAgGrid
              columnDefs={columnDefs}
              getRowHeight={getRowHeight}
              rowData={this.props.rFQDetailsList || [{}]}
              onGridReady={onGridReady}
              onModelUpdated={onModelUpdated}
              onColumnResized={onColumnResized}
              onDragStopped={() => {
                onColumnMoved({}, true);
              }}
              //onColumnMoved={onColumnMoved}
              gridStyle={{
                width: "100%",
                height: "100%",
                marginBottom: "10px",
              }}
            />
          ) : null}
        </div>

        <div style={{ display: "flex", gap: "10px" }}>
          {!this.props.isRFQFromPr ? (
            <Button
              className="grey-button"
              onClick={() => {
                this.gridApi.updateRowData({ add: [{}] });
              }}
            >
              <FormattedMessage
                id="supplier.rfq.form.button.addLine"
                defaultMessage=""
              />{" "}
            </Button>
          ) : (
            ""
          )}
          {!this.props.isRFQFromPr ? (
            <Button
              className="grey-button"
              onClick={() => {
                this.props.showModal({
                  modalBody: "Are you sure you want to clear?",
                  handleSubmit: () => {
                    let gridApi = this.gridApi;
                    this.gridApi.forEachNode(function (rowNode, index) {
                      gridApi.updateRowData({ remove: [rowNode.data] });
                      if (gridApi.rowRenderer.lastRenderedRow < 0) {
                        gridApi.updateRowData({ add: [{}] });
                      }
                    });
                  },
                });
              }}
            >
              <FormattedMessage
                id="supplier.rfq.form.button.clearAllLines"
                defaultMessage=""
              />{" "}
            </Button>
          ) : (
            ""
          )}
        </div>

        <div className="upload-btn-so">
          <CustomTableUpload
            fileList={this.props.fileList}
            onChange={(fileList) => {
              this.props.updateState({
                fileList,
              });
            }}
          />
        </div>
       {(this.props?.docDetailList || []).length ?
       <>
       Previous Data
       <AWSFileTable
                          {...this.props}
                          docDetailList={this.props?.docDetailList}
                          fetchTxDetail={(data) => {
                            const val = this.props.rfqData
                            val.docDetailList = []
                            // this.props.updateState({
                            //   rfqData:val
                            // })
                            this.props.fetchSupplierRFQDetail({
                              supplierId: this.props.rfqData.supplierId,
                              rfqMasterId: this.props.rfqData.rfqMasterId,
                              relationshipId: this.props.rfqData.relationshipId,
                            });
                          }}
                        />
       </>
       :""}
        <Divider style={{ margin: "10px 0px" }} />
        {this.props.isLoded ? (
          <Row>
            <Col span={12}>
              <div>
                <div className="remarks-title">
                  <FormattedMessage
                    id="supplier.rfq.form.internalRemarks.label"
                    defaultMessage=""
                  />
                </div>

                {/* <TextArea
                  type="text"
                  className="description-textarea"
                  key={`${"internalRemarks"}`}
                  value={this.props.internalRemarks}
                  onChange={(event) => {
                    this.props.updateState({ internalRemarks: event.target.value });
                  }}
                  placeholder = "Enter internal remarks"
                 
                /> */}
                 <CKEditor
                  type="inline"
                  className="description-textarea"
                  key={`${"internalRemarks"}`}
                  data={this.props.internalRemarks}
                  onInit={(editor) => {
                    this.editor = editor;
                  }}
                  onChange={(event) => {
                    const data = event.editor.getData();
                    this.props.updateState({ internalRemarks: data });
                  }}
                  config={{
                    ...CUSTOM_CONFIGURATION,
                    placeholder: "Enter internal remarks",
                  }}
                />
              </div>
              <div className="remarks-title">
                <FormattedMessage
                  id="supplier.rfq.form.supplierRemarks.label"
                  defaultMessage=""
                />
              </div>

              {/* <TextArea maxLength={500} className="description-textarea" value={this.props.supplierRemarks} rows={4} onChange={(e) => {
                            this.props.updateState({
                                supplierRemarks: e.target.value
                            })
                        }} placeholder={this.props.intl.formatMessage(
                            { id: 'supplier.rfq.form.supplierRemarks.placeholder', defaultMessage: '' }
                        )}></TextArea> */}
              {
                <CKEditor
                  type="inline"
                  className="description-textarea"
                  key={`${"supplierRemarks"}`}
                  data={this.props.supplierRemarks}
                  onInit={(editor) => {
                    this.editor = editor;
                  }}
                  onChange={(event) => {
                    const data = event.editor.getData();
                    this.props.updateState({ supplierRemarks: data });
                  }}
                  config={{
                    ...CUSTOM_CONFIGURATION,
                    placeholder: "Enter supplier remarks",
                  }}
                />
              }
            </Col>
          </Row>
        ) : (
          ""
        )}

        {/* <TextArea maxLength={500} className="add-footer-textarea" value={this.props.footer} onChange={(e) => {
                    this.props.updateState({
                        footer: e.target.value
                    });
                }}
                    rows={1} placeholder="Add Footer Here"></TextArea> */}
        <CKEditor
          type="inline"
          className="add-footer-textarea"
          key={`${"footer"}`}
          data={this.props.footer}
          onInit={(editor) => {
            this.editor = editor;
          }}
          onChange={(event) => {
            const data = event.editor.getData();
            this.props.updateState({ footer: data });
          }}
          config={{
            ...CUSTOM_CONFIGURATION,
            placeholder: "Add Footer Here",
          }}
        />
<div className="pb40"></div>
        <div className="footer">
          <div className="left-fItems">
            <Button
              className="grey-button"
              onClick={() => {
                this.props.showModal({
                  modalBody: <FormattedMessage id="modal.cancel.confirmation" defaultMessage=""/>,
                  footer:{submit:{intlId:"button.close.label"}},
                  handleSubmit: () => {
                    this.props.popModalFromStack();
                  }
                })
              }}
            >
              <FormattedMessage id="button.cancel.label" defaultMessage="" />
            </Button>
          </div>

          <div className="right-fItems">
            {!(
              this.props.rfqMasterId && this.props.operationType !== "clone"
            ) && (
              <Button
                className="ant-btn ml10"
                loading={this.props.saveLoading}
                disabled={this.props.saveLoading}
                onClick={() => {
                  draftRfq();
                }}
              >
                <FormattedMessage id="button.draft.label" defaultMessage="" />
              </Button>
            )}

            <Button
              type="primary"
              className="mr10"
              loading={this.props.saveLoading}
              disabled={this.props.saveLoading}
              onClick={() => {
                createRFQ();
              }}
            >
              <FormattedMessage id="button.save.label" defaultMessage="" />
            </Button>
          </div>
        </div>
        </Skeleton>
      </Fragment>
    );
  }
}

export default injectIntl(ProductDetails);
