import React, { useRef, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
// import { Form } from '@ant-design/compatible';
import * as debounce from 'lodash.debounce';
import '@ant-design/compatible/assets/index.css';
import { Row } from 'antd';
import { injectIntl, FormattedMessage } from 'react-intl';

import { Dropdown } from '../../../../general/Dropdown';
import { AG_GRID_CONSTANTS } from '../../../../../static/agGridConstants';
import { CONSTANTS, MODULE_CODED_VALUES } from '../../../../../static/constants';
import LoadingIcon from '../../../../../assets/images/loading.gif'
import MultiCurrencySalesQuote from '../../../../../containers/customer/salesQuote/MultiCurrencySalesQuote';
import StandardSalesQuote from '../../../../../containers/customer/salesQuote/StandardSalesQuote';

const CustomerHeaderAction = (props) => {
    const [templateDropdown, updateTemplateDropdown] = useState(false);
    const {
        intl,
        customers,
        pageNumber,
        customerName,
        updateState,
        priceTypeToApply,
        isRFQConversion
    } = props;



    const useOuterClick = (callback) => {
        const innerRef = useRef();
        const callbackRef = useRef();

        // set current callback in ref, before second useEffect uses it
        useEffect(() => { // useEffect wrapper to be safe for concurrent mode
            callbackRef.current = callback;
        });

        useEffect(() => {
            document.addEventListener("click", handleClick);
            return () => document.removeEventListener("click", handleClick);

            // read most recent callback and innerRef dom node from refs
            function handleClick(e) {
                if (
                    innerRef.current &&
                    callbackRef.current &&
                    !innerRef.current.contains(e.target)
                ) {
                    callbackRef.current(e);
                }
            }
        }, []); // no need for callback + innerRef dep

        return innerRef; // return ref; client can omit `useRef`
    }

    const innerRef = useOuterClick(e => {
        updateTemplateDropdown(!templateDropdown)
    });

    return (
        <>
        <div className='left-itms'>
      <ul>
        <li className='title'>
          <FormattedMessage id='heading.sales.salesQuote' />
        </li>
        <li className='sep' />
        <li>
          <FormattedMessage id='customer.salesQuote.form.sqNumber.label' defaultMessage='' /> &nbsp; - &nbsp;
          {props.nextTxNumber ?
            <span>{getFormatedNextTxNumber(props)}</span>
            //<span>{this.props.nextTxNumber.replace('SO-00', 'SO-'+(this.props.customer || {}).customerId) )}</span>
            :
            <img src={LoadingIcon} alt="Invoice" style={{ 'height': '50px' }} />
          }
        </li>
      </ul>
     </div>
        <div className="right-itms">
           

                <Dropdown
                style={{ width: "200px" }}
                    items={AG_GRID_CONSTANTS.STANDARD_SALES_INVOICE_FIELD.PRICE_TYPE_LIST}
                    value={priceTypeToApply || AG_GRID_CONSTANTS.STANDARD_SALES_INVOICE_FIELD.PRICE_TYPE_LIST[0]}
                    onSelect={(priceTypeToApply) => {
                        updateState({
                            priceTypeToApply,
                            isToPriceTypeToApply: true
                        })
                    }}
                    placeholder={intl.formatMessage({
                        id: 'customer.salesQuote.form.priceType.placeholder', defaultMessage: ''
                    })}
                    allowClear={true}
                    onClear={() => {
                        updateState({
                            priceTypeToApply: undefined,
                            isToPriceTypeToApply: undefined
                        })
                    }}
                />
                {(!isRFQConversion && !props.isInquiryConversion) &&
                    <Dropdown
                    style={{ width: "200px" }}
                        items={customers[pageNumber] || []}
                        valueKeyName='companyName'
                        optionKeyName='customerId'
                        value={customerName}
                        onSearch={debounce((searchedText) => {
                            props.fetchCustomers({
                                isCompact: true,
                                searchedText: searchedText,
                                pageNumber: 1,
                                hideSpinner: true,
                                pageSize: 100,
                                relationshipId: (props.companyInfo || {}).relationshipId
                            })
                        }, 2000)}
                        placeholder={intl.formatMessage({
                            id: 'customer.salesQuote.form.customer.placeholder', defaultMessage: ''
                        })}
                        canAddNew={true}
                        canDelete={true}
                  moduleName={MODULE_CODED_VALUES.CUSTOMERS}

                        deleteClickHandler={(payload) => {
                            props.deleteCustomers({ groupCustomerIds: [payload.id], relationshipId: (props.companyInfo || {}).relationshipId })
                        }}
                        onAddNewClick={(payload) => {
                            props.updateHeaderState({
                                customer: {
                                    customerDrawerVisible: true,
                                    customerName: payload.textEntered,
                                }
                            })
                        }}
                        onSelect={
                            (customerId) => {
                                props.fetchCustomerDetailsByCustomerId({
                                    customerId,
                                    relationshipId: (props.companyInfo || {}).relationshipId
                                });

                                props.fetchAddresses({customerId, relationshipId: (props.companyInfo || {}).relationshipId, locationType: CONSTANTS.SHIPPING_ADDRESS});
                                props.fetchAddresses({customerId, relationshipId: (props.companyInfo || {}).relationshipId, locationType: CONSTANTS.BILLING_ADDRESS});
                                props.fetchAllContacts({customerId, relationshipId: (props.companyInfo || {}).relationshipId});
                            }
                        }
                        allowClear={true}
                        onClear={() => {
                            updateState({
                                customer: undefined,
                                customerName: undefined,
                                customerBillingAddress: undefined,
                                selectedContact: undefined
                            })
                        }}
                    />
                }
                {/* <Button  onClick={() => {
                    props.updateState({
                        ledgerDrawerVisible: true
                    })
                }} >
                <i className='fa fa-book fa-lg'> </i>
                </Button> */}
                <div className="currency-converter-modal">
                    <span style={{height:'38px'}} className="ant-btn ant-btn-icon-only icon" onClick={() => updateTemplateDropdown(!templateDropdown)}>
                        <i className="fa fa-exchange" />
                    </span>

                    {templateDropdown &&
                        <div ref={innerRef} className="content">
                            <Row className="mb10">
                                <Link onClick={() => {
                                    updateTemplateDropdown(false)
                                    props.popModalFromStack();
                                    props.pushModalToStack({
                                        modalBody: <StandardSalesQuote {...props} />,
                                        width: '100%',
                                        hideTitle: true,
                                        hideFooter: true,
                                        wrapClassName: 'new-transaction-wrapper'
                                    })
                                }}
                                    // to="/admin/create-sales-quote"
                                ><FormattedMessage id='template.standardSalesQuote' defaultMessage='' /></Link>
                            </Row>

                            <Row>
                                <Link onClick={() => {
                                    updateTemplateDropdown(false)
                                    props.popModalFromStack();
                                    props.pushModalToStack({
                                        modalBody: <MultiCurrencySalesQuote {...props} />,
                                        width: '100%',
                                        hideTitle: true,
                                        hideFooter: true,
                                        wrapClassName: 'new-transaction-wrapper'
                                    })
                                }}
                                    // to="/admin/create-sales-quote-multi-currency"
                                ><FormattedMessage id='template.multicurrencySalesQuote' defaultMessage='' /></Link>
                            </Row>
                        </div>
                    }
                </div>
                {/* <div className="space-lr">
                    <Button icon={<SwapOutlined />} />
                </div> */}
            
        </div>
        </>
    );
};
const getFormatedNextTxNumber = (props) => {
    return <span>{props.nextTxNumber}</span>;}
export default injectIntl(CustomerHeaderAction);
