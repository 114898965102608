import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import BranchComp from '../../../../components/company/EmployeeMeta/Branch';
import BranchDrawerCon from '../../../drawer/company/EmployeeMeta/Branch';
import SalarySheet from '../../../../components/company/PayrollManagement/SalarySheet';
import { hideModal, popModalFromStack, pushModalToStack, showModal } from '../../../../actions/commonActions';


class SalarySheetContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data:0
    }
    };
  

  componentDidMount(props) {
    const payload = {
      relationshipId: (this.props.companyInfo || {}).relationshipId,
     
    }
   
  }

  componentWillReceiveProps(props) {
   
  }

  render() {
      return <>

<SalarySheet {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />

      </>
    
    ;
  }

}

const mapStateToProps = (state) => {
  return {

    companyInfo: state.common.companyInfo,
    permissions: state.auth.permissions,

   
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  showModal,
  hideModal,
  popModalFromStack,
  pushModalToStack,
  
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SalarySheetContainer);
