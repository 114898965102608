import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import EasyPostComponent from '../../../components/shipping/easyPost';
import { fetchEasyPostConfiguration, disconnectConfiguration, saveEasyPostConfiguration } from './action';
import { showModal, hideModal, pushModalToStack, popModalFromStack } from './../../../actions/commonActions';
import { getCompanyInfo } from '../../../utils';
import * as find from 'lodash.find';

class EasyPost extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageSize: 25,
      pageNumber: 1,
    }
  }

  componentDidMount() {
    const companyInfo = this.props.companyInfo || getCompanyInfo() || {};
    const payload = {
      relationshipId: companyInfo.relationshipId
    };
     this.props.fetchEasyPostConfiguration(payload);
   
  }

  componentWillReceiveProps(props) {
    
    if(props.linkedEasyPostList && props.linkedEasyPostList.length && !this.state.linkedEasyPost){
      let linkedEasyPost = find(props.linkedEasyPostList, { carrierName: 'easyPost'});
      this.setState({
        linkedEasyPost
      });
    }
  }

  render() {
    return (
      <EasyPostComponent {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
    )
  }
}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    linkedEasyPostList: state.integration.linkedEasyPostList,
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchEasyPostConfiguration, disconnectConfiguration, saveEasyPostConfiguration,
  showModal, hideModal, pushModalToStack, popModalFromStack 
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(EasyPost);
