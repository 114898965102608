import React, { Fragment, useState, forwardRef, useMemo } from "react";
import { FormattedMessage } from 'react-intl';
import { Select, Divider } from "antd";
import * as getValue from 'lodash.get';
import './general.scss';
import { getPermissionData } from "../../utils";

const { Option } = Select;

export const DropdownPriceList = (props) => {
  const items = props.items || [];
  const {permissions , moduleName} = props;
  const primaryPerm = (permissions && permissions.primary) || [];

  
const permissionData = useMemo(()=>{
  return getPermissionData(primaryPerm ,  moduleName);
},[])
  const canAddNew = props.canAddNew || false;
  const canDelete = props.canDelete || false;
  
   
    if (canAddNew && permissionData?.create) {
        canAddNew = permissionData?.create;
    }
    if (canDelete && permissionData?.delete) {
        canDelete = permissionData?.delete;
    }
  const deleteClickHandler = props.deleteClickHandler || (() => { });
  const [newTextObj, setNewTextObj] = useState({ textEntered: '', existsInData: false });

  return (
    <Select
      mode={props.mode || 'default'}
      showSearch={props.showSearch || true}
      className={props.className || ' custom-dropdown-width cell-dropdown'}
      dropdownClassName={props.dropdownCustomStyle}
      onDeselect={props.onDeSelect ? props.onDeSelect : ()=>{
      }}
      placeholder={props.placeholder}
      allowClear={props.allowClear || true}
      clearIcon={<i className="fa fa-times-circle" onClick={props.onClear}/>}
      disabled={props.disabled}
      style={props.style}
      value={props.value}
      optionFilterProp='name'
      notFoundContent='No record(s) found'
      onSelect={props.onSelect}
      onSearch={searchedText => {
        const searchedResults = items.filter(
          x => (x[props.valueKeyName] || '').toString().toLowerCase().indexOf(searchedText.toString().toLowerCase()) > -1
        );
        if (!searchedResults.length) {
          setNewTextObj({ textEntered: searchedText, existsInData: false });
        } else {
          setNewTextObj({ textEntered: searchedText, existsInData: true });
        }
      }}
      dropdownRender={dropdown => {
        return (
          <Fragment>
            {
              canAddNew ?
                <Fragment>
                  <div
                    style={{ padding: "4px 8px", cursor: "pointer" }}
                    onMouseDown={e => e.preventDefault()}
                    onClick={() => { props.onAddNewClick(newTextObj) }}
                    className='add-new'
                  >
                    <i className="fa fa-plus" />
                    <FormattedMessage id='add' defaultMessage='' />
                    {
                      newTextObj.existsInData || !newTextObj.textEntered
                        ? <FormattedMessage id='new' defaultMessage='' />
                        : ` ${newTextObj.textEntered}`
                    }
                  </div>
                  <Divider style={{ margin: "1px 0" }} />
                </Fragment>
                : ''
            }
            {dropdown}
          </Fragment>
        )
      }}
    >
      {
        items.map((item) => {
          const value = props.valueKeyName ? getValue(item, props.valueKeyName, '') : item;
          const key = typeof item === 'object' ? getValue(item, props.optionKeyName, '') : item;
          return (
            <Option key={key} name={value} value={key} className={props.optionClass || ' custom-dropdown-option'}>
              <span className={props.optionClass || ' custom-dropdown-option'}>
                {value} ({item.percentage}% {item.type === 'Markdown' ? '↓' : '↑'})
              </span>
              {
                  canDelete ?
                    <i
                      className='fa fa-trash'
                      onClick={e => {
                        const payload = {
                          id: key,
                          text: value,
                        };
                        e.preventDefault();
                        e.stopPropagation();
                        deleteClickHandler(payload);
                      }}
                      style={{ float: 'right', marginTop:5 }}
                    />
                    : ''
                }
            </Option>
          )
        })
      }
    </Select >
  );
}

export const DropdownPriceListRef = forwardRef(DropdownPriceList);
