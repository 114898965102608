import React, { Fragment, useMemo } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
// import { AgGridReact } from "ag-grid-react";
import { Pagination, Skeleton,  Menu, Empty  } from "antd";
import { fetchPaginationDataIfNeeded, getMomentDateForUIReadOnly, getPermissionData, getUserDefinedName, sortTable } from "../../../utils";
import { CONSTANTS, ICONS, MODULE_CODED_VALUES } from '../../../static/constants';
// import checkIcon from '../../../assets/images/check.png';
// import crossIcon from '../../../assets/images/cross.png';
import PageBreadcrumb from "../../PageBreadcrumb";
import {MoreOutlined} from '@ant-design/icons';
import { Dropdown } from "../../general/Dropdown";
import DropdownAction from "antd/lib/dropdown";


const Section = (props) => {
  const {classList,  sectionList, updateState, companyInfo, sectionCount, pageNumber,pageSize,permissions, dir=0 } = props;
  sectionList[pageNumber].sort((a, b)=> a.dateCreated < b.dateCreated ? 1 : -1);

  // const agGridStyle = {
  //   height: "100%",
  //   width: "100%",
  // };

  const breadCrumbList = [
    {
      name:  <FormattedMessage id='sidebar.menuItem.school' defaultMessage='' />
    },
    {
      name: getUserDefinedName('sidebar.menuItem.class',props),
    },
    {
      name: getUserDefinedName('sidebar.menuItem.section',props),
    }
  ];
  const primaryPerm = (permissions && permissions.primary) || [];

  const permissionsData = useMemo(()=>{
    return getPermissionData(primaryPerm, MODULE_CODED_VALUES.SCHOOL_CLASS_DETAIL )
  },[])
  const showTotal = (total) => {
    return (
      props.intl.formatMessage({
        id: "pagination.text.total",
        defaultMessage: "",
      }) +
      ` ${total || 0} ` +
      props.intl.formatMessage({
        id: "pagination.text.items",
        defaultMessage: "",
      })
    );
  };

  const loadPageData = (pageNumber, pageSize) => {
    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      relationshipEmployeeId: (props.userInfo || {}).relationshipEmployeeId,
      pageNumber: pageNumber,
      pageSize: pageSize,
    };
    fetchPaginationDataIfNeeded("getSection", "sectionList", props, payload);
  };

  const handlePageSizeChange = (pageNumber, pageSize, props) => {
    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      relationshipEmployeeId: (props.userInfo || {}).relationshipEmployeeId,
      pageNumber: pageNumber,
      pageSize: pageSize,
    };
    props.resetPaginatedData();
    props.getSection(payload);
    props.updateState({ pageSize: pageSize, pageNumber: pageNumber });
  };

  const newSection = (sectionData) => {
    props.updateHeaderState({
      schoolDrawers: {
        ...props.schoolDrawers,
        addSectionDrawerVisible: true,
      },
      saveSection: props.saveSection,
      getSection: props.getSection,
      sectionData: sectionData
    });
  };

  const sortColumn = (e) => {
    sortTable(document.getElementById('section-table'), e.target.cellIndex, 1 - dir);
    updateState({ dir: 1 - dir })
  }

  const actionMenu = (e) => {
    return (
        <Menu className="row-action-dropdown">

          <Menu.Item key="0" value="title">
            Action
          </Menu.Item>

          <Menu.Divider />

          {/* <Menu.Item key="1" value="edit" onClick={()=>{
            newSection(e);
            }}
          >
            <i className={ICONS.EDIT} />
            Edit
          </Menu.Item> */}

     {permissionsData.delete ? <Menu.Item key="2" onClick={()=>{
              props.showModal({
                modalBody: "Are you sure you want to delete?",
                handleSubmit: () => {
                  props.deleteSection(e, props);
                }
              })
            }
          }>
            <i className={ICONS.DELETE}/>
            Delete
          </Menu.Item> : "" }

        </Menu >
    )
  };
  return (
    <Fragment>
      <div className="view-top-bar">
        <div className="top-view-left">
          <div className="page-heading">
          {getUserDefinedName('sidebar.menuItem.class',props)}
          </div>
          <div className="vertical-sep" />
          <PageBreadcrumb breadCrumbList={breadCrumbList} />
        </div>
        {/* <div className="top-view-right">
          <button className="create-action-btn" onClick={()=>{
            newSection();
          }}>
              <i className={ICONS.ADD} /> <FormattedMessage id="button.create.label" defaultMessage="" />
          </button>
        </div> */}
      </div>

      <div className="view-container">

        <div className="view-container-actions">
        <Skeleton loading={props.headerLoading} paragraph={false} active>

          <div className="left-actions">
            <div className="table-heading">
              {getUserDefinedName('schoolManagement.section.table.heading',props)}
            </div>
          
            {
              sectionCount ? <>
                  <div className="vertical-sep" />
                <div>{sectionCount}</div>
              </>:0
            }
          </div>
          <div className="right-actions">
          <Dropdown
              items={classList[1] || []}
              valueKeyName="className"
              optionKeyName="classId"
              value={props.className}
              placeholder={getUserDefinedName('schoolManagement.class.name', props)}
              onSelect={(optionValue, option) => {
                props.updateState({
                  className: option.name,
                  classId: optionValue,
                });
                const payload = {
                  relationshipId: (props.companyInfo || {}).relationshipId,
                  createdByEmpId: (props.userInfo || {}).userId,
                  class_section_id: props.class_section_id,
                  className: option.name,
                };
                props.getsectionByClassName(payload);
                props.getSectionByClassNameCount(payload);
              }}
            />
          </div>
        </Skeleton>
        </div>
        <Skeleton loading={props.listLoading} paragraph={{ rows: 13 }} active>
          <div className="table-container">
            <table id="section-table">
              <thead>
                <tr>
                  <th><FormattedMessage id="serial.no" defaultMessage=""/></th>
                  <th onClick={sortColumn}>{getUserDefinedName('schoolManagement.section.name',props)} <i className={ICONS.SORT} /></th>
                  <th onClick={sortColumn}>{getUserDefinedName('schoolManagement.section.code',props)} <i className={ICONS.SORT} /></th>
                  <th onClick={sortColumn}>{getUserDefinedName('schoolManagement.class.name',props)} <i className={ICONS.SORT} /></th>
                  <th onClick={sortColumn}><FormattedMessage id="schoolManagement.school.dateCreated" defaultMessage="" /> <i className={ICONS.SORT} /></th>
                  {permissionsData.delete ?   <th width={"4%"}><FormattedMessage id='common.listing.header.action' defaultMessage="" /></th> : "" }
                </tr>
              </thead>
              <tbody>
                {
                  (sectionList[pageNumber] || [])?.length ?   (sectionList[pageNumber] || []).map((e, i) => {
                    return <tr key={i}>
                      
                      <td>{(pageNumber-1)*pageSize+(i+1)}</td>
                      <td>{e.sectionName||<div className="empty-data-box"/>}</td>
                      <td>{e.sectionCode||<div className="empty-data-box"/>}</td>
                      <td>{e.className||<div className="empty-data-box"/>}</td>
                      <td>
                          {(e.dateCreated ? getMomentDateForUIReadOnly({ date: e.dateCreated, format: CONSTANTS.DISPLAY_DATE_FORMAT_FY }) :<div className="empty-data-box"/>)}
                      </td>
                      {permissionsData.delete ? 
                      <td className="text-center" > 
                        <div className="action-icon">
                          <DropdownAction overlay={actionMenu(e)} trigger={['click']}>
                            <i className={ICONS.MORE} />
                          </DropdownAction>
                        </div>
                      </td>
                      :""}
                    </tr>
                  }):(<tr key="empty">
                    <td colSpan={"100%"}>
                      <Empty />
                    </td>
                  </tr>)
                
                }
              </tbody>
            </table>
          </div>
        </Skeleton>
        <div className="footer">
        <Skeleton loading={props.headerLoading} paragraph={false} active>

          <div className="f-left"></div>
            <div className="f-right">
              <Pagination
                size="small"
                total={sectionCount}
                showTotal={showTotal}
                defaultPageSize={props.pageSize}
                showSizeChanger
                pageSizeOptions={[10, 25, 50, 100, 200]}
                // showQuickJumper
                onChange={(pageNumber, pageSize) => {
                  loadPageData(pageNumber, pageSize);
                  updateState({ pageSize: pageSize, pageNumber: pageNumber });
                }}
                onShowSizeChange={(pageNumber, pageSize) => {
                  handlePageSizeChange(pageNumber || 1, pageSize, props);
                }}
              />
            </div>
        </Skeleton>
          </div>
        </div>
    </Fragment>
  );
};

export default injectIntl(Section);
