import React, { useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import './index.scss';
import { FormattedMessage } from "react-intl";
import { getPermissionData } from "../../../utils";
import queryString from 'query-string';


const InPageMenu = (props) => {
  const { menuList, onItemClick } = props;
  const linkProps = queryString.parse(decodeURIComponent(props.location?.search || "")) || {};
  const [activeMenu, setActiveMenu] = useState(linkProps.active);
  const primaryPerm = (props.permissions && props.permissions.primary) || [];

  return <div className="menu_container">
    {menuList.map((menu, i) => {
      const permissionsData = getPermissionData(primaryPerm, menu?.moduleCode);
      return (

        <>
          {permissionsData.read ?
            <div
              // className="menu_tile"
              className={`menu_tile ${menu.key === activeMenu ? 'active' : ''}`}
              key={`in_menu` + menu.key} onClick={() => {
                setActiveMenu(menu.key);
                onItemClick(menu.key);
                if (props.location?.pathname === "/admin/integrations" || "/admin/e-store/store-settings" || "/admin/employee-meta") {
                  props.history.push({
                    search: `?active=${menu?.key}`
                  })
                }

              }}>

              <div className="tile_icon">
                <img src={menu.icon} alt="" />
              </div>
              <div class="tile_title">

                <div className="tile_heading">
                  <FormattedMessage
                    id={menu.name}
                    defaultMessage=""
                  />
                </div>

                <div className="tile_description">
                  {menu.description || ''}
                </div>
              </div>
            </div>
            : ""}
        </>
      )


    })}
    <div>

    </div>
  </div>

}
const mapStateToProps = (state) => {
  return {
    permissions: state.auth.permissions,
  };
};
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {

    },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(InPageMenu);
