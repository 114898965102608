import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { showModal, hideModal, pushModalToStack, popModalFromStack ,fetchCountries} from '../../actions/commonActions';

import { CONSTANTS } from '../../static/constants';
import { fetchDataIfNeeded } from "../../utils";

import { changePassword } from '../modal/modalBody/settings/ChangePassword/action';
import { FormattedMessage } from 'react-intl';
import AlpideProductsDrawer from '../drawer/alpideProduct';

import UpgradePricingPlan from "../B2B/PlanPricing";
import { fetchAllEmployees ,createEmployee ,uploadImage } from '../../actions/commonActions';
import AccountComponent from '../../components/Account';
class Account extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...props,
      selectedMenuItem:"Profile",
      currentPassword:'',

      newPassword:'',
      confirmPassword:'',
      alpideProductDrawer: false,
      employee :{},
      isLoading: true,
    }
  }

  componentDidMount() {
    const companyInfo = this.props.companyInfo;
    const payload= {
      relationshipId: companyInfo?.relationshipId,
    }
    fetchDataIfNeeded('fetchCountries', 'countries', this.props);
    this.props.fetchAllEmployees(payload);
  }
   componentWillReceiveProps(props){
    if(this.state.isLoading && (props.allEmployee||[])?.length){
      const loginUser= (props.allEmployee||[]).find(item=>item?.isAccountHolder)

      this.setState({
        employee: loginUser,
        isLoading:false
      })
    }
   }
  openPricing = (alpideProductId) => {
    this.props.pushModalToStack({
      modalBody: <UpgradePricingPlan {...this.props} isUpgrade={true} alpideProductId={typeof alpideProductId === 'number' ?  alpideProductId : 0} />,
      width: '100%',
      hideTitle: true,
      hideFooter: true,
      keyboard:false,
      wrapClassName: 'full_modal',

      onCancel: () => {
        this.props.showModal({
          modalBody: <FormattedMessage id="modal.cancel.confirmation" defaultMessage=""/>,
          footer:{submit:{intlId:"button.close.label"}},
          handleSubmit: () => {
          
            this.props.popModalFromStack();
            this.state.alpideProductDrawer=false
          }
        })
      }
    })
  
}

  render() {
    return (
      <>
     {this.state.alpideProductDrawer && (
              <AlpideProductsDrawer
                {...this.state}
                {...this.props}
                updateHeaderState={(data) => {
                  this.setState(data);
                }}
                openPricingPlan={(alpideProductId)=>{
                  this.openPricing(alpideProductId)
                }}
              />
            )} 
      <AccountComponent  {...this.state} {...this.props}  updateHeaderState={(data) => {
                  this.setState(data);
                }} updateState={(data) => { this.setState(data) }} />
      </>
    );
  }

}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    userInfo: state.common.userInfo,
    countries: state.common.countries,
    allEmployee : state.common.employees,
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  showModal,
  hideModal,
  pushModalToStack, 
  popModalFromStack,
  changePassword,
  fetchCountries,
  fetchAllEmployees,
  createEmployee,
  uploadImage
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Account);
