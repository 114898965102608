import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import LandedCostListingComponent from '../../../components/supplier/landedCostSetup';
import { fetchDataIfNeeded } from '../../../utils';
import { showModal, pushModalToStack, hideModal, popModalFromStack } from '../../../actions/commonActions';
import { fetchAllLedgerAccounts, } from '../../finance/LedgerAccounts/action';
import {fetchLandedCostSetupList,deleteLandedCostSetup} from "./actions"

class LandedCostListing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageSize: 25,
      pageNumber: 1,
    };
  }

  componentDidMount() {
    const companyInfo = this.props.companyInfo || {};
    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      userId: (this.props.userInfo || {}).userId,
      pageNumber: 1,
      pageSize: 25
    };
   
    fetchDataIfNeeded('fetchAllLedgerAccounts', 'allLedgerAccounts', this.props, payload);
    this.props.fetchLandedCostSetupList(payload);
  }

  componentWillReceiveProps(props) {
  }

  render() {
    return (
      <LandedCostListingComponent {...this.props } {...this.state} updateState={(data) => { this.setState(data) }} />
    )
  }
}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    relationshipId: state.common.companyInfo.relationshipId,
    userInfo: state.common.userInfo,
    permissions: state.auth.permissions,
    allLedgerAccounts: state.ledger.allLedgerAccounts,
    landedCostSetupList: state.supplier.landedCostSetupList,
    listLoading:state.common.listLoading,
    permissions: state.auth.permissions,


  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  showModal,
  pushModalToStack,
  hideModal,
  popModalFromStack,
  fetchAllLedgerAccounts,
  fetchLandedCostSetupList,
  deleteLandedCostSetup,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(LandedCostListing);
