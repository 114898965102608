import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import DonationSettingComponent from '../../../../../components/modal/modalBody/settings/DonationSetting';
import { pushModalToStack, popModalFromStack, showModal, hideModal, fetchPaymentTerms, fetchProjects } from '../../../../../actions/commonActions';
import { saveDonationSetting, fetchDonationSetting, resetDonationSetting } from './action';
import { fetchAvailablePaymentGateway } from '../../../../integrations/Payment/action';
import { fetchAllLedgerAccounts } from '../../../../finance/LedgerAccounts/action';
import { getSubdomain } from '../../../../modal/modalBody/settings/CompanyInfo/action'; 
//import EmailComponent from '../../../../../containers/drawer/recurringInvoiceEmail';
class DonationSetting extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isShowDonationMenu: 1
        }
    }
    componentDidMount() {
        let self = this;
        const companyInfo = this.props.companyInfo || {};
        this.props.fetchDonationSetting({ relationshipId: companyInfo.relationshipId }, this.props);
        this.props.fetchPaymentTerms({ relationshipId: companyInfo.relationshipId });
        this.props.fetchAvailablePaymentGateway({ relationshipId: companyInfo.relationshipId });
        this.props.fetchProjects({ relationshipId: companyInfo.relationshipId });
        this.props.fetchAllLedgerAccounts({ relationshipId: companyInfo.relationshipId });
        this.props.getSubdomain({ relationshipId: companyInfo.relationshipId }, function (stat) {self.setState({isSubDomainLoaded : stat})} ); 
    }

    componentWillReceiveProps(props) {
        if (props.donationSetting && props.donationSetting.settingsDonationId && props.projectList && props.projectList.length) {
            let donationContent = JSON.parse(props.donationSetting.donationContent);
            let selectedProjects = donationContent.selectedProjects || {};
            let projectMapedList = props.projectList.map(obj => {
                obj.isListingAllow = (selectedProjects[obj.projectMasterId] || {}).isListingAllow;
                obj.ListingName = (selectedProjects[obj.projectMasterId] || {}).displayName || obj.projectName;
                obj.chartOfAccountDetailsId = (selectedProjects[obj.projectMasterId] || {}).chartOfAccountDetailsId;
                obj.ledgerAccountName = (selectedProjects[obj.projectMasterId] || {}).ledgerAccountName;
                obj.paymentGatewayName = (selectedProjects[obj.projectMasterId] || {}).paymentGatewayName;
                obj.paymentGatewayId = (selectedProjects[obj.projectMasterId] || {}).paymentGatewayId;
                return obj;
            })
            this.setState({
                ...(donationContent || {}),
                settingsDonationIdWeb: props.donationSetting.settingsDonationId || 0,
                version:  props.donationSetting.version,
                projectMapedList,
            })
            this.props.resetDonationSetting();
        }
        if(!this.state.projectMapedList && props.projectList && props.projectList.length ){
            let projectMapedList = props.projectList.map(obj => {
                obj.ListingName = obj.projectName;
                return obj;
            })
            this.setState({
                projectMapedList,
            })
        }
        if (this.state.isSubDomainLoaded && props.subdomain && props.subdomain.alpideSubdomainId && !this.state.subdomainName) {
            this.setState({
              subdomainName: props.subdomain.subdomainName ? 'https://' + props.subdomain.subdomainName : window.location.origin
            });
          }
    }

    render() {
        return <div>
           <DonationSettingComponent {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} /> 
        </div>
    }

}


const mapStateToProps = (state) => {
    return {
        companyInfo: state.common.companyInfo,
        donationSetting: state.donation.donationSetting,
        availablePaymentGateways: state.studentRegistration.availablePaymentGateways,
        projectList: state.common.projectList,
        allLedgerAccounts: state.ledger.allLedgerAccounts,
        subdomain: state.common.subdomain,
        permissions: state.auth.permissions,

        // kioskDonationSetting: state.donation.kioskDonationSetting,
    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    saveDonationSetting,
    fetchDonationSetting,
    resetDonationSetting,
    pushModalToStack,
    popModalFromStack,
    showModal,
    hideModal,
    fetchPaymentTerms,
    fetchAvailablePaymentGateway,
    fetchProjects,
    fetchAllLedgerAccounts,
    getSubdomain
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(DonationSetting);