import React from "react";
import { injectIntl, FormattedMessage } from "react-intl";
import {Col,Row, Input, Popover, Switch, notification} from "antd";
import { Dropdown } from "../../../general/Dropdown";
import "@ant-design/compatible/assets/index.css";
import { PLACEHOLDER_CONSTANTS } from "../../../../static/placeholderConstants";
import { EMAIL_BODY_CONFIGURATION } from "../../../../static/ckEditorConfigConstants";
import CKEditor from "../../../general/CustomCKEditor/ckeditor";
import { ErrorMsg } from "../../../general/ErrorMessage";
import { TextBox } from "../../../general/TextBox";

const { TextArea } = Input;
const onChange = (e) => {

};

const NotificationDetails = (props) => {
  const {updateState ,selectedTransactionName ,notification, notificationAutoTrigger} = props;
  const transactionMode = [
    { trnMode: "Option 1", referCode: "OPT1" },
    { trnMode: "Option 2", referCode: "OPT2" },
    { trnMode: "Option 3", referCode: "OPT3" },
  ];
  const handleSelect = (key, value) => {
    props.updateState({ [key]: value });
  };
  const insertText = (props, textToInsert) => {
    const val = notification;
   
    if (notification?.description) {
        let subject = (notification?.description).slice(0, props.subjectCursor) + textToInsert + (notification?.description).slice(props.subjectCursor + 1);
        val.description = subject;
        props.updateState({ notification: val, subjectCursor: props.subjectCursor+textToInsert?.length })
    } else {
      val.description =textToInsert;
        props.updateState({ notification: val, subjectCursor: props.subjectCursor+textToInsert?.length })
    }
}
  const getPlaceHolders = (props, editorName ) => {
    return <div style={{ width: '400px' }}>
        <Row type='flex' gutter={[8, 24]}>
            {(selectedTransactionName.transactionName === 'School Assignments') ? <Col span={14}>
                <div style={{ fontWeight: '700' }}>Student</div>
                <div className="ml10 pointer" onClick={() => { insertText(props, PLACEHOLDER_CONSTANTS.PARENT_NAME); }}>Father and Mother Name</div>
                <div className="ml10 pointer" onClick={() => {insertText(props,PLACEHOLDER_CONSTANTS.STUDENT_NAME); }}>Student Name</div>
                <div className="ml10 pointer" onClick={() => {insertText(props,PLACEHOLDER_CONSTANTS.TEACHER_NAME); }}>Teacher Name</div>
                <div className="ml10 pointer" onClick={() => {insertText(props,PLACEHOLDER_CONSTANTS.CLASS_NAME); }}>Class Name</div>
                <div className="ml10 pointer" onClick={() => {insertText(props,PLACEHOLDER_CONSTANTS.CLASS_SCHEDULE); }}>Class Schedule</div>
                <div className="ml10 pointer" onClick={() => {insertText(props,PLACEHOLDER_CONSTANTS.ASSIGNMENT_NAME); }}>Assignment Name</div>
                <div className="ml10 pointer" onClick={() => {insertText(props,PLACEHOLDER_CONSTANTS.ASSIGNMENT_DUE_DATE); }}>Assignment Due Date</div>
            </Col> : ''
            }
        {(selectedTransactionName.transactionName === 'Pledge') ? <Col span={8}>
                <div style={{ fontWeight: '700' }}>Donor</div>
                <div className="ml10 pointer" onClick={() => { insertText(props, PLACEHOLDER_CONSTANTS.DONOR_NAME); }}>Name</div>
                <div className="ml10 pointer" onClick={() => { insertText(props, PLACEHOLDER_CONSTANTS.EMAIL); }}>Email</div>
                <div className="ml10 pointer" onClick={() => { insertText(props, PLACEHOLDER_CONSTANTS.MOBILE_NUMBER); }}>Mobile</div>
                <div className="ml10 pointer" onClick={() => { insertText(props, PLACEHOLDER_CONSTANTS.PLEDGE_AMOUNT); }}>Pledge Amount</div>
                <div className="ml10 pointer" onClick={() => { insertText(props, PLACEHOLDER_CONSTANTS.PLEDGE_DATE); }}>Pledge Date</div>
                <div className="ml10 pointer" onClick={() => { insertText(props, PLACEHOLDER_CONSTANTS.PAYMENT_LINK); }}>Payment URL</div>
                <div className="ml10 pointer" onClick={() => { insertText(props, PLACEHOLDER_CONSTANTS.PLEDGE_START_DATE); }}>Pledge Start Date</div>
                <div className="ml10 pointer" onClick={() => { insertText(props, PLACEHOLDER_CONSTANTS.TOTAl_OCCURRENCES); }}>No. of Occurances</div>
                <div className="ml10 pointer" onClick={() => { insertText(props, PLACEHOLDER_CONSTANTS.REMARKS); }}>Donor Remarks</div>
                <div className="ml10 pointer" onClick={() => { insertText(props, PLACEHOLDER_CONSTANTS.CAMPAIGN_NAME); }}>Campaign Name</div>
            </Col> : ''
            }
            {(selectedTransactionName.transactionName === 'Sales Invoice' || selectedTransactionName.transactionName === 'Recurring Sales Invoice') ?
                <Col span={8}>
                    <div style={{ fontWeight: '700' }}>Invoice</div>
                    <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.BALANCE); }}>Amount</div>
                    <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.DUE_DATE); }}>Due Date</div>
                    <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.INVOICE_DATE); }}>Invoice Date</div>
                    <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.INVOICE_NUMBER); }}>Invoice #</div>
                    <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.INVOICE_URL); }}>Invoice URL</div>
                    <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.PROJECT_NAME); }}>Project Name</div>
                    {/* <div className="ml10" style={{cursor: 'pointer'}} onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.PAYMENT_LINK);}}>Payment Link</div> */}
                </Col> : ''
            }
            {(selectedTransactionName.transactionName === 'Sales Quote') ?
                <Col span={8}>
                    <div style={{ fontWeight: '700' }}>Sales Quote</div>
                    <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.QUOTE_BALANCE); }}>Amount</div>
                    <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.BID_CLOSING_DATE); }}>Bid Closing Date</div>
                    <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.QUOTE_DATE); }}>Date Created</div>
                    <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.QUOTE_NUMBER); }}>Quote #</div>
                    <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.PROJECT_NAME); }}>Project Name</div>
                </Col> : ''
            }
            {(selectedTransactionName.transactionName === 'Sales Order') ?
                <Col span={8}>
                    <div style={{ fontWeight: '700' }}>Sales Order</div>
                    <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.SO_BALANCE); }}>Amount</div>
                    <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.SO_DELIVERY_DATE); }}>Delivery Date</div>
                    <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.SO_DATE); }}>Date Created</div>
                    <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.SO_NUMBER); }}>SO #</div>
                    <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.PROJECT_NAME); }}>Project Name</div>
                </Col> : ''
            }
            {(selectedTransactionName.transactionName === 'Online Order Confirmation') ?
                <Col span={8}>
                    <div style={{ fontWeight: '700' }}>Sales Order</div>
                    <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.SO_BALANCE); }}>Amount</div>
                    <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.SO_DATE); }}>Date Created</div>
                    <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.SO_NUMBER); }}>SO #</div>
                </Col> : ''
            }
            {(selectedTransactionName.transactionName === 'Customer Payment') ?
                <Col span={8}>
                    <div style={{ fontWeight: '700' }}>Payment</div>
                    <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.SALES_PAYMENT_BALANCE); }}>Amount</div>
                    <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.SALES_PAYMENT_DATE); }}>Payment Date</div>
                    <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.INVOICE_NUMBER); }}>Invoice #</div>
                    <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.SALES_PAYMENT_NUMBER); }}>Payment #</div>
                    <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.PROJECT_NAME); }}>Project Name</div>
                </Col> : ''
            }
            {(selectedTransactionName.transactionName === 'Credit Memo') ?
                <Col span={8}>
                    <div style={{ fontWeight: '700' }}>Credit Memo</div>
                    <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.CM_BALANCE); }}>Amount</div>
                    <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.CM_EXPIRY_DATE); }}>Expiry Date</div>
                    <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.CM_DATE); }}>Date</div>
                    <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.CM_NUMBER); }}>Credit Memo #</div>
                    <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.PROJECT_NAME); }}>Project Name</div>
                </Col> : ''
            }
            {(selectedTransactionName.transactionName === 'Sales Inquiry') ?
                <Col span={8}>
                    <div style={{ fontWeight: '700' }}>Inquiry</div>
                    <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.INQUIRY_DATE); }}>Date</div>
                    <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.INQUIRY_EXPIRY_DATE); }}>Expiry Date</div>
                    <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.INQUIRY_NUMBER); }}>Inquiry #</div>
                </Col> : ''
            }
             {(selectedTransactionName.transactionName === 'Purchase Invoice') ?
                <Col span={8}>
                    <div style={{ fontWeight: '700' }}>Invoice</div>
                    <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.PI_BALANCE); }}>Amount</div>
                    <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.DUE_DATE); }}>Due Date</div>
                    <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.INVOICE_DATE); }}>Invoice Date</div>
                    <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.INVOICE_NUMBER); }}>Invoice #</div>
                    <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.PROJECT_NAME); }}>Project Name</div>
                </Col> : ''
            }
              {(selectedTransactionName.transactionName === 'Purchase Order') ?
                <Col span={8}>
                    <div style={{ fontWeight: '700' }}>Purchase Order</div>
                    <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.PO_BALANCE); }}>Amount</div>
                    <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.PO_DUE_DATE); }}>Due Date</div>
                    <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.PO_DATE); }}>Date</div>
                    <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.PO_NUMBER); }}>PO #</div>
                    <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.PROJECT_NAME); }}>Project Name</div>
                </Col> : ''
            }
            {(selectedTransactionName.transactionName === 'Supplier Payment') ?
                <Col span={8}>
                    <div style={{ fontWeight: '700' }}>Purchase Payment</div>
                    <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.PURCHASE_PAYMENT_BALANCE); }}>Amount</div>
                    <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.PURCHASE_PAYMENT_DATE); }}>Date</div>
                    <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.PURCHASE_PAYMENT_NUMBER); }}>Payment #</div>
                    <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.PROJECT_NAME); }}>Project Name</div>
                </Col> : ''
            }
            {(selectedTransactionName.transactionName === 'Debit Memo') ?
                <Col span={8}>
                    <div style={{ fontWeight: '700' }}>Debit Memo</div>
                    <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.DM_BALANCE); }}>Amount</div>
                    <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.DM_EXPIRY_DATE); }}>Expiry Date</div>
                    <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.DM_DATE); }}>Date Created</div>
                    <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.DM_NUMBER); }}>Debit Memo #</div>
                    <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.PROJECT_NAME); }}>Project Name</div>
                </Col> : ''
            }
             {(selectedTransactionName.transactionName === 'RFQ') ?
                <Col span={8}>
                    <div style={{ fontWeight: '700' }}>RFQ</div>
                    <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.RFQ_DATE); }}>RFQ Date</div>
                    <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.RFQ_EXPIRY_DATE); }}>Expiration Date</div>
                    <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.RFQ_NUMBER); }}>RFQ #</div>
                    <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.PROJECT_NAME); }}>Project Name</div>
                </Col> : ''
            }
              {(selectedTransactionName.transactionName === 'Purchase Request') ?
                <Col span={8}>
                    <div style={{ fontWeight: '700' }}>Purchase Request</div>
                    <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.PR_DATE); }}>PR Date</div>
                    <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.PR_EXPIRY_DATE); }}>Expiry Date</div>
                    <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.PR_NUMBER); }}>PR #</div>
                    <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.PROJECT_NAME); }}>Project Name</div>
                </Col> : ''
            }
            {(selectedTransactionName.transactionName === 'Sales Quote' || selectedTransactionName.transactionName === 'Sales Order' || selectedTransactionName.transactionName === 'Sales Invoice' || selectedTransactionName.transactionName === 'Customer Payment' || selectedTransactionName.transactionName === 'Credit Memo' || selectedTransactionName.transactionName === 'Sales Inquiry' || selectedTransactionName.transactionName === 'Recurring Sales Invoice' || selectedTransactionName.transactionName === 'School Payment' ) ?
                <Col span={8}>
                    <div style={{ fontWeight: '700' }}>Customer</div>
                    <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.CUSTOMER_NAME); }}>Customer Name</div>
                    <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.SALUTATION); }}>Salutation</div>
                    <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.WEBSITE); }}>Website</div>
                    <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.FIRST_NAME); }}>First Name</div>
                    <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.LAST_NAME); }}>Last Name</div>
                    <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.EMAIL); }}>Email</div>
                </Col> : ''}
            {(selectedTransactionName.transactionName === 'Online Order Confirmation' ) ?
                <Col span={8}>
                    <div style={{ fontWeight: '700' }}>Customer</div>
                    <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.CUSTOMER_NAME); }}>Customer Name</div>
                </Col> 
                :
                ''
            }
            {(selectedTransactionName.transactionName === 'Purchase Order' || selectedTransactionName.transactionName === 'Purchase Invoice' || selectedTransactionName.transactionName === 'Supplier Payment' || selectedTransactionName.transactionName === 'Debit Memo') ?
                <Col span={8}>
                    <div style={{ fontWeight: '700' }}>Supplier</div>
                    <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.SUPPLIER_NAME); }}>Supplier Name</div>
                    <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.SALUTATION); }}>Salutation</div>
                    <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.WEBSITE); }}>Website</div>
                    <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.FIRST_NAME); }}>First Name</div>
                    <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.LAST_NAME); }}>Last Name</div>
                    <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.EMAIL); }}>Email</div>
                </Col> : ''}
                {(selectedTransactionName.transactionName === 'Kiosk Payment' || selectedTransactionName.transactionName === 'Online Payment' || selectedTransactionName.transactionName === 'Text2Donate Payment') ?
                <Col span={8}>
                    <div style={{ fontWeight: '700' }}>Payment</div>
                    <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.CUSTOMER_NAME); }}>Name</div>
                    <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.SALES_PAYMENT_BALANCE); }}>Amount</div>
                    <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.SALES_PAYMENT_DATE); }}>Payment Date</div>
                </Col> : ''
            }
            {(selectedTransactionName.transactionName === 'Sales Shipment') ?
                <Col span={8}>
                    <div style={{ fontWeight: '700' }}>Shipment</div>
                    <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.SHIPMENT_NUMBER); }}>Shipment Number</div>
                    <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.SHIPMENT_DATE); }}>Shipment Date</div>
                    {/* <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.ORDER_DATE); }}>Order Date</div> */}
                    <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.ORDER_NUMBER); }}>Order Number</div>
                    <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.TRACKING_NUMBER); }}>Tracking #</div>
                    <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.CARRIER_NAME); }}>Carrier Name</div>
                    <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.DELIVERY_DATE); }}>Delivery Date</div>
                </Col> : ''
            }
            {( selectedTransactionName.transactionName === 'Sales Order Status Update' || selectedTransactionName.transactionName ===  'Sales Order Cancellation' || selectedTransactionName.transactionName ===  'Sales Order Update') ?
                <Col span={8}>
                    <div style={{ fontWeight: '700' }}>Online Order</div>
                    <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.OOC_ORDER_DATE); }}>Order Date</div>
                    {!selectedTransactionName.transactionName === 'Online Order Confirmation' ? <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.OOC_ORDER_NUMBER); }}>Order Number</div> : ""}
                    <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.OOC_ORDER_AMOUNT); }}>Order Amount</div>
                    <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.ONLINE_ORDER_NUMBER); }}>Online Order Number</div>
                </Col> : ''
            }
            {(selectedTransactionName.transactionName === 'Online Order Confirmation') ?
                <Col span={8}>
                    <div style={{ fontWeight: '700' }}>Online Order</div>
                    <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.ONLINE_ORDER_NUMBER); }}>Online Order Number</div>
                </Col> : ''
            }
            <Col span={8}>
                <div style={{ fontWeight: '700' }}>Organization</div>
                <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.STORE_NAME); }}>Name</div>
                {(selectedTransactionName.transactionName === 'Pledge') ?  <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.USER); }}>User</div> : ''}
                <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.ORG_EMAIL); }}>Email</div>
                <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.ORG_PHONE); }}>Phone #</div>
                <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.ORG_FAX); }}>Fax #</div>
                <div className="ml10 pointer" onClick={() => { insertText(props,PLACEHOLDER_CONSTANTS.WEBSITE); }}>Website</div>
            </Col>
        </Row>
    </div>
  }
  return (
    <>
      <Row gutter={[16, 8]}>
              <Col span={24} className="i-label">
                <FormattedMessage
                  id="drawer.labelName.communicationTemplate.notification.heading"
                  defaultMessage=""
                />
                <span className="required">*</span>
                
              </Col>
              <Col span={24}>
              <TextBox 
                placeholder="Enter Heading"
                value={notification?.title||""}
                onChange={(e)=>{
                    const val = notification
                    val.title = e.target.value
                    props.updateState({
                        notification: val,
                    })
                }}
                />
                  {/* <ErrorMsg
                    message={"Transaction Mode is required"}
                  /> */}
              </Col>
      </Row>
      <Row gutter={[16, 8]}>
        <Col span={24} className="i-label mt15">
          <FormattedMessage
            id="drawer.labelName.communicationTemplate.notification.description"
            defaultMessage=""
          />
          <span className="required">*</span>
          
        </Col>
        <Col span={24}>
        <TextArea
            showCount
            type='text'
            value={notification?.description||""}
            maxLength={100}
            style={{
              
              height: 120,
            }}
           
            onChange={(e) => {
              const data = e.target.value;
              const val = notification;
              val.description = data;
              updateState({ notification: val
           });
          }}
            placeholder="Type description here"
          />
           <span className="placeholder-button" style={{ position:'absolute', right:'10px', top:'-32px'}}>
                                            <Popover  content={getPlaceHolders(props, 'editor')} placement="bottom" trigger="click" onClick={()=>{ updateState({ isPopoverVisible: !props.isPopoverVisible, isSubjectPopoverVisible: false });}}>
                                                Placeholder <i className='fa fa-caret-square-o-down' />
                                            </Popover>
                                        </span> 
        </Col>
      </Row>
     
      
      <Row gutter={[16, 8]}>
        <Col span={24}>
          <span>
            Automate trigger email when clicking on the {selectedTransactionName?.transactionName|| "module_name"} Submit Button.
          </span>  
        </Col>
        <Col span={24}>
          <Switch className="customAutomateSwitch" checkedChildren="Automate" unCheckedChildren="Manual" checked={notificationAutoTrigger ? true: false} onChange={(e)=>{
            updateState({
              notificationAutoTrigger: !(notificationAutoTrigger),
            })
          }} />
        </Col>
      </Row>
    </>
  );
};

export default injectIntl(NotificationDetails);
