import React from "react";
import { Tabs } from 'antd';
import PageBreadcrumb from "../../../PageBreadcrumb";
import SalesQuote from "./salesQuote";
import SalesQuoteDraft from "./salesQuoteDraft";
import { fetchDataIfNeeded, fetchPaginationDataIfNeeded } from '../../../../utils';
import { FormattedMessage } from "react-intl";
import StandardSalesQuote from "../../../../containers/customer/salesQuote/StandardSalesQuote";
import { ICONS, MODULE_CODED_VALUES } from "../../../../static/constants";
import PageHeader from "../../../Common/pageHeader";


const { TabPane } = Tabs;

function callback(key, props) {
    const payload = { companyInfo: props.companyInfo, relationshipId: props.companyInfo.relationshipId, pageNumber: 1, pageSize: 25 };
    if (key === '1') {
        props.updateState({activeTab: 'Quote', activeTabKey: "1",pageNumber:1,pageSize:25});
        props.fetchSalesQuotes(payload);
    } else if (key === '2') {
        props.updateState({activeTab: 'Draft', activeTabKey: "2",pageNumber:1,pageSize:25});
        fetchDataIfNeeded('getSalesQuoteDraftCount', 'sqDraftCount', props, payload);
        fetchPaginationDataIfNeeded('fetchSalesQuoteDrafts', 'sqDraftList', props, payload);
    } 
}

const SalesQuoteListingComp = (props) => {
    const breadCrumbList = [
        {
            name: <FormattedMessage id='common.sales' defaultMessage='' />,
        },
        {
            name: <FormattedMessage id='salesQuote.text' defaultMessage='' />,
        }
    ];
    const openNewSq = () => {
        props.pushModalToStack({
            modalBody: <StandardSalesQuote {...props} />,
            width: '100%',
            hideTitle: true,
            hideFooter: true, keyboard:false,
            wrapClassName: 'new-transaction-wrapper',
            onCancel: () => {
                props.showModal({
                  modalBody: <FormattedMessage id="modal.cancel.confirmation" defaultMessage=""/>,
                  footer:{submit:{intlId:"button.close.label"}},
                  handleSubmit: () => {
                    props.popModalFromStack();
                  }
                })
              }
        })
    }
    return (<>
         
            <PageHeader {...props}
        pageName="heading.sales.salesQuote"
        breadCrumbList={breadCrumbList}
        canCreate={true}
        onCreate={openNewSq}
        moduleCode = {MODULE_CODED_VALUES.SALES_QUOTE}
      />
            <div className="view-container">
                <Tabs activeKey={props.activeTabKey || "1"} onChange={(key) => { callback(key, props); }} type="line">
                    <TabPane tab="Quote" key="1">
                        <SalesQuote {...props} />
                    </TabPane>
                    <TabPane tab="Draft" key="2">
                        <SalesQuoteDraft {...props} />
                    </TabPane>
                </Tabs>
            </div>
        </>

    )
};

export default SalesQuoteListingComp;
