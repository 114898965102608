import React, { Fragment, useMemo } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
// import { AgGridReact } from 'ag-grid-react';
import { Skeleton, Pagination, Tooltip, Empty } from 'antd';
import { fetchPaginationDataIfNeeded, getMomentDateForUIReadOnly, fixedDecimalAndFormateNumber, sortTable, getPermissionData } from '../../../../utils'
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
// import { AG_GRID_CLASS_CONSTANTS } from '../../../../static/cssClassConstants'
import {
    CONSTANTS,
    MODULE_CODED_VALUES
} from '../../../../static/constants';
import StandardSalesQuote from '../../../../containers/customer/salesQuote/StandardSalesQuote';
import CustomerProfile from "../../../../containers/modal/modalBody/customer/Profile"

const SalesQuoteDraft = (props) => {
    const {  sqDraftList, updateState, companyInfo, sqDraftCount, pageNumber, dir=0 ,permissions} = props;
    // const getRowHeight = (params) => {
    //     let height = 35;
    //     return height;
    // };
    const primaryPerm = (permissions && permissions.primary) || [];
    const salesQuotePermission = useMemo(()=>{
      return getPermissionData(primaryPerm ,  MODULE_CODED_VALUES.SALES_QUOTE);
    },[])

    const showTotal = (total) => {
        return props.intl.formatMessage({ id: 'pagination.text.total', defaultMessage: '' }) + ` ${total} ` + props.intl.formatMessage({ id: 'pagination.text.items', defaultMessage: '' });
    };
    
    const loadPageData = (pageNumber, pageSize) => {
        const payload = { companyInfo, relationshipId: companyInfo.relationshipId, pageNumber: pageNumber, pageSize: pageSize };
        fetchPaginationDataIfNeeded('fetchSalesQuoteDrafts', 'sqDraftList', props, payload);
    }

    const handlePageSizeChange = (pageNumber, pageSize, props) => {
        const payload = { companyInfo, relationshipId: companyInfo.relationshipId, pageNumber: pageNumber, pageSize: pageSize };
        props.resetDraftPaginatedData();
        props.fetchSalesQuoteDrafts(payload);
        props.updateState({ pageSize: pageSize, pageNumber: pageNumber });
    }

    //const permittedModules = getActionMenuPermissionData();
    // const columnDefs = [
    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='customer.salesOrder.listing.header.draftNo' defaultMessage='' />;
    //         },
    //         field: "quoatationDraftId",

    //         cellRendererFramework: (param) => <div className="cursor-pointer" >
    //             <Link
    //                 to={
    //                     {
    //                         pathname: '/admin/create-sales-quote',
    //                         state: {
    //                             customerId: param.data.customerId,
    //                             salesQuotationeData: param.data.salesQuotationeData,
    //                             quoatationDraftId: param.data.quoatationDraftId,
    //                             clone: true
    //                         }
    //                     }}
    //             >Draft-{param.value}</Link>
    //         </div>,
    //         rowDrag: true,
    //         resizable: true,
    //         width: 100,
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='customer.salesOrder.listing.header.customer' defaultMessage='' />;
    //         },
    //         field: "customerName",
    //         resizable: true,
    //         width: 100,
    //         cellRendererFramework: (link) => <div className="cursor-pointer" >
    //             <Link
    //                 to={
    //                     {
    //                         pathname: 'customers/details',
    //                         state: {
    //                             customerId: link.data.salesQuotationeData.customerId,
    //                             customerName: link.data.salesQuotationeData.customerName,
    //                         }
    //                     }}
    //                 className='company-name'
    //             >
    //                 {link.data.salesQuotationeData.customerName}
    //             </Link>
    //         </div>,
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <div><FormattedMessage id='customer.salesOrder.listing.header.amount' default='Amount' />  </div>;
    //         },
    //         cellRendererFramework: (link) => {
    //             // return link.data.salesQuotationeData.salesQuoteTotalAmount && link.data.salesQuotationeData.salesQuoteTotalAmount !== '-' ? <span> {link.data.salesQuotationeData.foreignCurrencyIcon ? <i className={link.data.salesQuotationeData.foreignCurrencyIcon}></i> : (link.data.salesQuotationeData.foreignCurrency ? <span>{link.data.salesQuotationeData.foreignCurrency}</span> : (props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "))} {link.data.salesQuotationeData.salesQuoteTotalAmount.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} </span> : "-"
    //             return link.data.salesQuotationeData.salesQuoteTotalAmount && link.data.salesQuotationeData.salesQuoteTotalAmount !== '-' ? <span> {link.data.salesQuotationeData.foreignCurrencyIcon ? <i className={link.data.salesQuotationeData.foreignCurrencyIcon}></i> : (link.data.salesQuotationeData.foreignCurrency ? <span>{link.data.salesQuotationeData.foreignCurrency}</span> : (props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "))} {fixedDecimalAndFormateNumber(link.data.salesQuotationeData.salesQuoteTotalAmount)} </span> : "-"
    //         },
    //         field: "salesOrderTotalAmount",
    //         resizable: true,
    //         cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN,
    //         width: 100,
    //     },
        
       
    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='customer.salesOrder.listing.header.date' defaultMessage='' />;
    //         },
    //         field: "salesQuoteDate",
    //         cellRendererFramework: (link) => {
    //             return <div>
    //                 <Tooltip placement="topLeft" title={(link.data.salesQuotationeData.salesQuoteDate && getMomentDateForUIReadOnly({date: link.data.salesQuotationeData.salesQuoteDate, format: CONSTANTS.DISPLAY_TOOLTIP_DATE_FORMAT}))}>
    //                     {(link.data.salesQuotationeData.salesQuoteDate && getMomentDateForUIReadOnly({date: link.data.salesQuotationeData.salesQuoteDate, format: CONSTANTS.DISPLAY_DATE_FORMAT}))}
    //                 </Tooltip>
    //             </div>
    //         },
    //         resizable: true,
    //         width: 100,
    //     },
       
    // ]

    // const agGridStyle = {
    //     height: '100%',
    //     width: '100%'
    // };

    
    // const onGridReady = (params) => {
    //     props.updateState({
    //         gridApi: params.api,
    //         gridColumnApi: params.columnApi
    //     });
    //     params.api.sizeColumnsToFit();
    // }

    const sortColumn = (e) => {
        sortTable(document.getElementById('sales-quote-draft-table'), e.target.cellIndex, 1 - dir);
        updateState({ dir: 1 - dir })
      }

   
    return (
        <Fragment>
            <Skeleton loading={props.isSoListingLoading}
                active
                paragraph={{ rows: 12 }}
            >
                {/* <div className='agGridWrapper'>
                    <div className="ag-theme-balham" style={agGridStyle} >
                        {props.txColumns.length ? <AgGridReact
                            onGridReady={onGridReady}
                            columnDefs={columnDefs}
                            defaultColDef={{
                                flex:1,
                                autoHeight: true,
                                wrapText: true,
                            }}
                            rowData={sqDraftList[pageNumber] || []}
                            rowDragManaged={true}
                            domLayout={"autoHeight"}
                            suppressDragLeaveHidesColumns={true} 
                            animateRows={true}
                            //getRowHeight={getRowHeight}
                        >
                        </AgGridReact>: null}
                    </div>
                </div> */}
                <div className="view-container-actions">
                    <div className="left-actions">
                    <div className="table-heading">
                        <FormattedMessage id='sales.salesQuoteDraft.table.heading' defaultMessage='' />
                    </div>
                    {
                        props.sqDraftCount ? <>
                        <div className="vertical-sep" />
                        <div>{props.sqDraftCount}</div>
                        </>:''
                    }
                    </div>
                    <div className="right-actions">
                    </div>
                </div>
                <div className='table-container'>
                    <table id='sales-quote-draft-table'>
                        <thead>
                            <tr>
                                <th>S. No.</th>
                                <th onClick={sortColumn}><FormattedMessage id='customer.salesOrder.listing.header.draftNo' defaultMessage='' /></th>
                                <th onClick={sortColumn}><FormattedMessage id='customer.salesOrder.listing.header.customer' defaultMessage='' /></th>
                                <th onClick={sortColumn}><FormattedMessage id='customer.salesOrder.listing.header.amount' default='Amount' /></th>
                                <th onClick={sortColumn}><FormattedMessage id='customer.salesOrder.listing.header.date' defaultMessage='' /></th>
                            </tr>
                        </thead>
                        <tbody>
                        {sqDraftList && (sqDraftList[pageNumber] || []).length ? 
                            sqDraftList[pageNumber].sort((a,b)=>a.salesQuotationeData.salesQuoteDate < b.salesQuotationeData.salesQuoteDate ? 1:-1).map((rowData, i)=> (
                                <tr key={i}>
                                    <td>{i + 1}</td>
                                    <td>
                                        <div className={salesQuotePermission.update ? 'cursor-pointer' : 'cursor-disabled'}
                                        onClick={()=>{
                                            if(salesQuotePermission.update){
                                                props.pushModalToStack({
                                                    modalBody: <StandardSalesQuote {...props}
                                                    customerId= {rowData.customerId}
                                                    salesQuotationeData= {rowData.salesQuotationeData}
                                                    quoatationDraftId= {rowData.quoatationDraftId}
                                                    clone= {true}
                                                     />,
                                                    width: '100%',
                                                    hideTitle: true,
                                                    hideFooter: true,
                                                    wrapClassName: 'new-transaction-wrapper'
                                                })
                                            }
                                            
                                        }}
                                            // to={
                                            //     {
                                            //         pathname: '/admin/create-sales-quote',
                                            //         state: {
                                            //             customerId: rowData.customerId,
                                            //             salesQuotationeData: rowData.salesQuotationeData,
                                            //             quoatationDraftId: rowData.quoatationDraftId,
                                            //             clone: true
                                            //         }
                                            //     }}
                                            >
                                            Draft-{rowData.quoatationDraftId || ''}
                                        </div>
                                    </td>
                                    <td>
                                        <div
                                           onClick={() => {
                                            props.pushModalToStack({  
                                      
                                              modalBody: <CustomerProfile {...props}
                                              customerId= {rowData.salesQuotationeData.customerId}
                                            //   customerName={rowData.customerName}
                                            //   customerDetails={rowData}
                                          />,
                                          width: '100%',
                                          hideTitle: true,  
                                          hideFooter: true,
                                          wrapClassName: "new-transaction-wrapper",
                                          })
                                         }}
                                            // to={
                                            //     {
                                            //         pathname: 'customers/details',
                                            //         state: {
                                            //             customerId: rowData.salesQuotationeData.customerId,
                                            //             customerName: rowData.salesQuotationeData.customerName,
                                            //         }
                                            //     }}
                                            className='company-name cursor-pointer'
                                        >
                                            {rowData.salesQuotationeData.customerName || ''}
                                        </div>
                                    </td>
                                    <td>
                                        {rowData.salesQuotationeData.salesQuoteTotalAmount && rowData.salesQuotationeData.salesQuoteTotalAmount !== '-' ? <span> {rowData.salesQuotationeData.foreignCurrencyIcon ? <i className={rowData.salesQuotationeData.foreignCurrencyIcon}></i> : (rowData.salesQuotationeData.foreignCurrency ? <span>{rowData.salesQuotationeData.foreignCurrency}</span> : (props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "))} {fixedDecimalAndFormateNumber(rowData.salesQuotationeData.salesQuoteTotalAmount)} </span> : "-"}
                                    </td>
                                    <td>
                                        <Tooltip placement="topLeft" title={(rowData.salesQuotationeData.salesQuoteDate && getMomentDateForUIReadOnly({date: rowData.salesQuotationeData.salesQuoteDate, format: CONSTANTS.DISPLAY_TOOLTIP_DATE_FORMAT}))}>
                                            {(rowData.salesQuotationeData.salesQuoteDate && getMomentDateForUIReadOnly({date: rowData.salesQuotationeData.salesQuoteDate, format: CONSTANTS.DISPLAY_DATE_FORMAT}))}
                                        </Tooltip>
                                    </td>
                                </tr>
                        )): <tr key="empty-data-box">
                            <td colSpan={"100%"} style={{ borderBottom: "none" }}>
                                <Empty />
                            </td>
                        </tr>
                        }
                        </tbody>
                    </table>
                </div>
            </Skeleton>
            <div className="footer">
                <div className="f-left"></div>
                <div className="f-right">
                    <Pagination
                        size="small"
                        total={sqDraftCount}
                        showTotal={showTotal}
                        defaultPageSize={props.pageSize} showSizeChanger pageSizeOptions={[10, 25, 50, 100, 200]}
                        // showQuickJumper
                        onChange={(pageNumber, pageSize) => {
                            loadPageData(pageNumber, pageSize);
                            updateState({ pageSize: pageSize, pageNumber: pageNumber })
                        }}
                        onShowSizeChange={(pageNumber, pageSize) => {
                            handlePageSizeChange(pageNumber || 1, pageSize, props)
                        }}
                    />
                </div>
            </div>
        </Fragment>
    );
}

export default injectIntl(SalesQuoteDraft );
