import React, { Fragment } from "react";
import { Skeleton, Input, Empty, } from 'antd';
import * as debounce from 'lodash.debounce';
import { checkACLPermission, getMomentDateForUIReadOnly,sortTable,fixedDecimalAndFormateNumber } from '../../../utils';
import { CONSTANTS, MODULE_CODED_VALUES, PERMISSION_VALUES } from '../../../static/constants';
import { injectIntl, FormattedMessage } from 'react-intl';
// import { CustomAgGrid } from "../../general/CustomAgGrid";
// import { CustomButton } from "../../general/CustomButton";
import JournalDetails from "../../../containers/modal/modalBody/finance/JournalDetail";
import PageHeader from "../../Common/pageHeader";

const { Search } = Input;

let gridApi = {};

// const agGridStyle = {
//     height: '100%',
//     width: '100%'
// };

// const onFirstDataRendered = (params) => {
//     //params.api.sizeColumnsToFit();
// }
// const getRowHeight = (params) => {
//     return 30;
// }

const JournalComp = (props) => {
    const { permissions, allJournals,dir=0 } = props;
    const primaryPerm = permissions.primary;
    // const canCreate = checkACLPermission(primaryPerm, MODULE_CODED_VALUES.JOURNALS, PERMISSION_VALUES.CREATE);

    // const onGridReady = (params) => {
    //     gridApi = params.api;
    //     params.api.sizeColumnsToFit();
    // }

    const openJournalDetails = (data) => {
        const journalPayload = {
            journalId: data.journalId
        }
        props.pushModalToStack({
            modalBody: <JournalDetails {...props} journalPayload={journalPayload} />,
            width: '100%',
            hideFooter: true,
            wrapClassName: 'modal-custom-detail'
        })
    }

    // const columnDefs = [
    //     {
    //         headerComponentFramework: () => {
    //             return <Tooltip placement="top" title={props.intl.formatMessage(
    //                 {
    //                   id: 'tooltip.journalNumber',
    //                   defaultMessage: ''
    //                 }
    //               )}>
    //                 <div className='table-header-column-container'>
    //                     <span className='table-header-column'>Journal Number</span>
    //                 </div>
    //             </Tooltip>;
    //         },
    //         field: "journalNumber",
    //         cellRendererFramework: (link) => <div className="cursor-pointer" onClick={() => {
    //             openJournalDetails(link.data)
    //         }}>{link.value}</div>,
    //         resizable: true,
    //         rowDrag: true,
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <Tooltip placement="top" title={props.intl.formatMessage(
    //                 {
    //                   id: 'tooltip.narration',
    //                   defaultMessage: ''
    //                 }
    //               )}>
    //                 <div className='table-header-column-container'>
    //                     <span className='table-header-column'> Narration</span>
    //                 </div>
    //             </Tooltip>;
    //         },
    //         field: "description",
    //         sortable: true
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <Tooltip placement="top" title={props.intl.formatMessage(
    //                 {
    //                   id: 'tooltip.amount',
    //                   defaultMessage: ''
    //                 }
    //               )}>
    //                 <div className='table-header-column-container'>
    //                     <span className='table-header-column'> Amount   ({props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "})</span>
    //                 </div>
    //             </Tooltip>;
    //         },
    //         field: "txAmount",
    //         resizable: true,
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <Tooltip placement="top" title={props.intl.formatMessage(
    //                 {
    //                   id: 'tooltip.date',
    //                   defaultMessage: ''
    //                 }
    //               )}>
    //                 <div className='table-header-column-container'>
    //                     <span className='table-header-column'>Date</span>
    //                 </div>
    //             </Tooltip>;
    //         },
    //         field: "txDate",
    //         resizable: true,
    //         valueFormatter: (data) => {
    //             data.value = data.value ? getMomentDateForUIReadOnly({date: data.value, format: CONSTANTS.DISPLAY_DATE_FORMAT_FY}) : '';
    //             return data.value;
    //         }
    //     }
    // ]

    const onSearchedInputChange = debounce((searchedText, props) => {
        handleSearchedText(searchedText, props)
    }, 2000);

    const handleSearchedText = (searchedText, props) => {
        gridApi.setQuickFilter(searchedText || '');
    }

    const breadCrumbList = [
        // {
        //     name: 'Dashboard',
        //     link: 'dashboard'
        // },
        {
            name: 'Finance'
        }, {
            name: 'Journals'
        }
    ];

    const sortColumn = (e) => {
        sortTable(document.getElementById("finance-journal"), e.target.cellIndex, 1 - dir);
        props.updateState({ dir: 1 - dir })
      }
    return (
      <Fragment>
      
        <PageHeader {...props}
                pageName="heading.finance.journals"
                breadCrumbList={breadCrumbList}
                canCreate={true}
                onCreate={() => {
                  props.history.push("/admin/create-journal");
                }}
                moduleCode={MODULE_CODED_VALUES.JOURNALS}
            />
        <div className="view-container">

          <div className="view-container-actions">
                    <div className="left-actions">
                        <div className="table-heading">
                            <FormattedMessage id='heading.finance.journals.table.heading' defaultMessage='' />
                        </div>
                        {
                            props.pageSize ? <>
                            <div className="vertical-sep" />
                            <div>{props.pageSize}</div>
                            </>:''
                        }
                    </div>
                    <div className="right-actions">
                    <Search
                  placeholder="Search"
                  value={props.searchedText}
                  onChange={(e) => {
                    props.updateState({ searchedText: e.target.value });
                    onSearchedInputChange(e.target.value, props);
                  }}
                  onSearch={(searchedText) => {
                    props.updateState({ searchedText });
                    onSearchedInputChange(searchedText, props);
                  }}
                />
                    </div>
                </div>
            {/* <Row className="search-container" type="flex" justify="end">
              <Col span={12}>
                <Search
                  placeholder="Search"
                  value={props.searchedText}
                  onChange={(e) => {
                    props.updateState({ searchedText: e.target.value });
                    onSearchedInputChange(e.target.value, props);
                  }}
                  onSearch={(searchedText) => {
                    props.updateState({ searchedText });
                    onSearchedInputChange(searchedText, props);
                  }}
                  style={{ width: 300, height: 38 }}
                />
              </Col>

              {/* <Col className="align-end" span={12}>
                {canCreate && (
                  <CustomButton
                    intlId={"finance.journal.newJournal.button.name"}
                    onClick={() => {
                      props.history.push("/admin/create-journal");
                    }}
                    style={{ height: "38px" }}
                  >
                    <i className="fa fa-user" />
                    <i className="fa fa-angle-down ml5" />
                  </CustomButton>
                )}
              </Col> 
            </Row> */}
            <div className="pb10" />

            {/* <div className="agGridWrapper">
              <div className="ag-theme-balham" style={agGridStyle}>
                <CustomAgGrid
                  columnDefs={columnDefs}
                  rowData={allJournals || []}
                  rowDragManaged={true}
                  onGridReady={onGridReady}
                  domLayout={"autoHeight"}
                  animateRows={true}
                  onGridSizeChanged={onFirstDataRendered}
                  getRowHeight={getRowHeight}
                />
              </div>
            </div> */}
            <div className="table-container">
            <Skeleton loading={props.listLoading} active paragraph={{ rows: 16 }}>
                <table id="finance-journal">
                    <thead>
                        <tr>
                            <th onClick={sortColumn}><FormattedMessage id="serial.no"defaultMessage=" "/><i class="fi fi-rr-sort-alt"></i></th>
                            <th onClick={sortColumn}><FormattedMessage id="tooltip.journalNumber"defaultMessage=" "/><i class="fi fi-rr-sort-alt"></i></th>
                            <th onClick={sortColumn}><FormattedMessage id="tooltip.narration"defaultMessage=" "/><i class="fi fi-rr-sort-alt"></i></th>
                            <th onClick={sortColumn}><FormattedMessage id="tooltip.amount"defaultMessage=" "/><i class="fi fi-rr-sort-alt"></i></th>
                            <th onClick={sortColumn}><FormattedMessage id="tooltip.date"defaultMessage=" "/><i class="fi fi-rr-sort-alt"></i></th>
                            
                        </tr>
                    </thead>
                    <tbody>
                        {(allJournals || []).length ? allJournals.map((rowData,index)=>{
                                return (
                                    <tr key={index}>
                                        <td>{index+1}</td>
                                        <td><div className="cursor-pointer" onClick={() => {
                    openJournalDetails(rowData)
                }}>{rowData.journalNumber}</div></td>
                <td>{rowData.description}</td>
                <td>{props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i>  : props.companyInfo.currencyCode + " "}{fixedDecimalAndFormateNumber(rowData.txAmount)}</td>
                <td>{rowData.txDate = rowData.txDate ? getMomentDateForUIReadOnly({date: rowData.txDate, format: CONSTANTS.DISPLAY_DATE_FORMAT_FY}) : ''}</td>
                                    </tr>
                                    
                                )
                        }):
                        <tr key="empty-data-box">
                <td colSpan={"100%"} style={{ borderBottom: "none" }}>
                    <Empty />
                </td>
            </tr>
                        }
                    </tbody>
                </table>
          </Skeleton>
            </div>
          <div className="footer">
            <div className="f-left"></div>

            <div className="f-right"></div>
          </div>
        </div>
      </Fragment>
    );
};

export default injectIntl(JournalComp);