import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import RecurringInvoiceSetupComponent from '../../../../../components/modal/modalBody/settings/RecurringInvoiceSetupForForm';
import EmailComponent from '../../../../../containers/drawer/recurringInvoiceEmail';

class RecurringInvoiceSetupForForm extends Component {

    constructor(props){
        super(props);
        this.state= {
            emaildrawerVisible: false,
            subject: '',
            emailBody: '',
            period: 'daily',
            startDate: new Date(),
            endDate: new Date(),
          //  customerId: props.salesInvoiceDetail.customerId,
            isBulkInvoice: props.isBulkInvoice || false,
            emailDetails: props.recurringInvoiceSettings ? props.recurringInvoiceSettings.emailDetails : {},
            ...props.recurringInvoiceSettings,
            notifyCustomer: (props.recurringInvoiceSettings|| {}).isSendEmail ? 1: 0
        }
    }
    componentDidMount() {
        //const companyInfo = this.props.companyInfo || {};
    }

   
    render() {
        return <div>
            {this.state.emailDetailDrawerVisible && <EmailComponent {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />}
            <RecurringInvoiceSetupComponent {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
        </div>
    }

}


const mapStateToProps = (state) => {
    return {
        companyInfo: state.common.companyInfo,
        relationshipId: state.common.companyInfo.relationshipId,
        permissions: state.auth.permissions,

    }
  }

const mapDispatchToProps = (dispatch) => bindActionCreators({
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(RecurringInvoiceSetupForForm);