import React, { useMemo } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
// import PageBreadcrumb from "../../PageBreadcrumb";
import { checkACLPermission, getMomentDateForUIReadOnly, getPermissionData, sortTable } from "../../../utils";
import { Menu, Switch, Button,Skeleton } from "antd";
import DropdownAction from "antd/lib/dropdown";
import { ICONS, MODULE_CODED_VALUES, PERMISSION_VALUES } from "../../../static/constants";
import PageBreadcrumb from "../../PageBreadcrumb";

const LeadAllReminderList = (props) => {
  const { dir = 0 } = props;

  const breadCrumbList = [
    {
      name: <FormattedMessage id="sidebar.menuItems.Leads" defaultMessage="" />,
    },
    {
      name: "Reminder",
    },
  
  ];
  const { permissions } = props;
  const primaryPerm = (permissions && permissions.primary) || [];
  const permissionsData = useMemo(()=>{
    return getPermissionData(primaryPerm ,  MODULE_CODED_VALUES.CRM_LEAD_ALL_REMINDER);
  },[])

  const sortColumn = (e) => {
    let cellIndex = e.target.cellIndex;
    if (!cellIndex) {
      cellIndex = e.target.parentElement.cellIndex;
    }
    if (!cellIndex) {
      cellIndex = e.target.parentElement.parentElement.cellIndex;
    }
    sortTable(document.getElementById("leadSource-table"), cellIndex, 1 - dir);
  };

  const actionMenu = (item) => {
    return (
      <Menu className="row-action-dropdown">
        <Menu.Item key="0" value="title">
          Actions
        </Menu.Item>
        <Menu.Divider />

        {/* <Menu.Item
          key="1"
          value="edit"
          onClick={() => {
            props.updateState({
              leadSourceListDrawer: true,
              ...item,
            });
          }}
        >
          <i className={ICONS.EDIT} /> Edit
        </Menu.Item> */}
{
  permissionsData?.delete  ?  <Menu.Item
  key="2"
  value="delete"
  onClick={() => {
    props.showModal({
      modalBody: "Are you sure you want to delete?",
      handleSubmit: () => {
        props.deleteCrmLeadSource(item, props);
      },
    });
  }}
>
  <i className={ICONS.DELETE} /> Delete
</Menu.Item>:""
}
        
      </Menu>
    );
  };

  return (
    // <div className="lead-list">
<>

      <div className="view-container-actions">
        <div className="left-actions">
          <div className="table-heading">
            <FormattedMessage
              id="leads.leadForm.formMeta.leadSource.table.heading"
              defaultMessage=""
            />
          </div>
        </div>

        <div className="right-actions">
          {
            permissionsData?.create ? <Button
            style={{ backgroundColor: "#1cb961", color: "#ffffff" }}
            className="create-action-btn"
            onClick={() => {
              // handleAddUpdateActivity({});
              props.updateState({ leadSourceListDrawer: true });
            }}
          >
            <i className={ICONS.ADD} style={{ margin: "4px 5px 0px 0px" }} />{" "}
            <FormattedMessage id="button.create.label" defaultMessage="" />
          </Button> : ""
          }
         
        </div>
      </div>
      <div className="table-container" style={{ height: "calc(100% - 59px)" }}>
      <Skeleton loading={props.listLoading} paragraph={{ rows: 15 }} active>
        <table id="leadSource-table">
          <thead>
            <tr>
              <th>S.No.</th>
              <th onClick={sortColumn}>
                Title <i className={ICONS.SORT}></i>
              </th>
              {/* <th onClick={sortColumn}>
                      Source Code <i className={ICONS.SORT}></i>
                    </th> */}
              <th onClick={sortColumn}>
                Date Created <i className={ICONS.SORT}></i>
              </th>

        {
          (permissionsData?.update || permissionsData?.delete) ?  <th width="6%">Action</th> : ""
        }
             
            </tr>
          </thead>

          <tbody>
            {(props.allLeadReminderList || []).map((rowData, i) => {
              return (
                <tr key={"crm" + rowData.crmLeadSourceId}>
                  <td>{i + 1}</td>
                  <td>{rowData.reminderTitle || "-"}</td>
                  {/* <td>{e.source_code || "-"}</td> */}
                  <td>
                    {rowData.dateCreated
                      ? getMomentDateForUIReadOnly({
                          date: rowData.dateCreated,
                        })
                      : "-"}
                  </td>
                 {
                  (permissionsData?.update || permissionsData?.delete) ? <td style={{ paddingLeft: "50px" }}>
                  <DropdownAction
                    

                    overlay={actionMenu(rowData)}
                    trigger={["click"]}
                  >
                    <i className={ICONS.MORE} />
                  </DropdownAction>
                </td>: ""
                 }
                  
                </tr>
              );
              // })
            })}
          </tbody>
        </table>
      </Skeleton>
      </div>
</>
  );
};

export default injectIntl(LeadAllReminderList);
