import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { showModal, pushModalToStack, hideModal, popModalFromStack } from '../../../../../actions/commonActions';
import BankAccount from '../../../../../components/modal/modalBody/common/BankAccount';
import { createBankDetail } from '../../settings/CompanyInfo/action';

class BankAccountCon extends Component {

    constructor(props){
        super();
        this.state={                  
        }
    }


    render() {
        return <BankAccount {...this.state} {...this.props} updateModalState={(data) => { this.setState(data) }}/>
    }

}


const mapStateToProps = (state) => {
    return {
        companyInfo: state.common.companyInfo,
    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    showModal,
    hideModal,
    pushModalToStack,
    popModalFromStack,
    createBankDetail
    
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(BankAccountCon);
