import axios from 'axios';
import config from '../../config/environmentConfig';
import { COMMON_ACTIONS, LOCAL_STORAGE_KEYS, LEDGER_TYPE } from '../../static/constants';
import { showToasterMessage, fetchFromLocalStorage, addToLocalStorage, getPartialDateForBackend, formatDateForBackendFY } from '../../utils';
import { CONSTANTS } from '../../static/constants';

const { lang } = require('../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');

export const completeCompanySetup = (payload) => {
    return dispatch => {
        const companyInfo = _getCompanyInfo(payload);
        const formData = new FormData()
        formData.append('clientRelationship', JSON.stringify(companyInfo));

        dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
        return axios.post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/clientRelationship/completeCompanySetup`, formData, {
            headers: {
                'content-type': 'multipart/form-data'
            }
        })
            .then(response => {
                const entityData = fetchFromLocalStorage(LOCAL_STORAGE_KEYS.LOGGEDIN_ENTITY_DATA) ? JSON.parse(fetchFromLocalStorage(LOCAL_STORAGE_KEYS.LOGGEDIN_ENTITY_DATA)) : {};
                const relationshipEmployees = entityData.defaultCompany.relationshipEmployees;
                entityData.defaultCompany = response.data || {};
                entityData.defaultCompany.relationshipEmployees  = relationshipEmployees;
                addToLocalStorage(LOCAL_STORAGE_KEYS.LOGGEDIN_ENTITY_DATA, JSON.stringify(entityData));
                addToLocalStorage(LOCAL_STORAGE_KEYS.ACCESS_TOKEN, fetchFromLocalStorage(LOCAL_STORAGE_KEYS.ACCESS_TOKEN_BACKUP));

                // if((companyInfo?.industryName || '').toLowerCase() === 'education'){
                //     payload.history.push('/admin/school-management/dashboard');
                // }else{
                // }
                payload.history.push('/admin/dashboard');
                ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
            })
            .catch(err => {
                showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
                ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
            })
    }
}



const getDefaultLedgerAccounts = (payload) => {
    const defaulltLedger = [];
    if (payload.salesLedgerAccount) {
        payload.salesLedgerAccount.ledgerAccountId = payload.salesLedgerAccount.chartOfAccountDetailsId;
        payload.salesLedgerAccount.txType = LEDGER_TYPE.TYPE_SALES
        defaulltLedger.push(payload.salesLedgerAccount);

    } if (payload.purchaseLedgerAccount) {
        payload.purchaseLedgerAccount.ledgerAccountId = payload.purchaseLedgerAccount.chartOfAccountDetailsId;
        payload.purchaseLedgerAccount.txType = LEDGER_TYPE.TYPE_PURCHASE
        defaulltLedger.push(payload.purchaseLedgerAccount);

    } if (payload.debtorsLedgerAccount) {
        payload.debtorsLedgerAccount.ledgerAccountId = payload.debtorsLedgerAccount.chartOfAccountDetailsId;
        payload.debtorsLedgerAccount.txType = LEDGER_TYPE.TYPE_DEBTORS
        defaulltLedger.push(payload.debtorsLedgerAccount);

    } if (payload.creditorsLedgerAccount) {
        payload.creditorsLedgerAccount.ledgerAccountId = payload.creditorsLedgerAccount.chartOfAccountDetailsId;
        payload.creditorsLedgerAccount.txType = LEDGER_TYPE.TX_TYPE_CREDITORS
        defaulltLedger.push(payload.creditorsLedgerAccount);

    } if (payload.customerDiscountLedgerAccount) {
        payload.customerDiscountLedgerAccount.ledgerAccountId = payload.customerDiscountLedgerAccount.chartOfAccountDetailsId;
        payload.customerDiscountLedgerAccount.txType = LEDGER_TYPE.TYPE_SALES_DISCOUNT
        defaulltLedger.push(payload.customerDiscountLedgerAccount);

    } if (payload.supplierDiscountLedgerAccount) {
        payload.supplierDiscountLedgerAccount.ledgerAccountId = payload.supplierDiscountLedgerAccount.chartOfAccountDetailsId;
        payload.supplierDiscountLedgerAccount.txType = LEDGER_TYPE.TYPE_PURCHASE_DISCOUNT
        defaulltLedger.push(payload.supplierDiscountLedgerAccount);

    } if (payload.salesOutputTaxLedgerAccount) {
        payload.salesOutputTaxLedgerAccount.ledgerAccountId = payload.salesOutputTaxLedgerAccount.chartOfAccountDetailsId;
        payload.salesOutputTaxLedgerAccount.txType = LEDGER_TYPE.TYPE_SALES_OUTPUT_TAX
        defaulltLedger.push(payload.salesOutputTaxLedgerAccount);

    } if (payload.purchaseInputTaxLedgerAccount) {
        payload.purchaseInputTaxLedgerAccount.ledgerAccountId = payload.purchaseInputTaxLedgerAccount.chartOfAccountDetailsId;
        payload.purchaseInputTaxLedgerAccount.txType = LEDGER_TYPE.TYPE_PURCHASE_INPUT_TAX
        defaulltLedger.push(payload.purchaseInputTaxLedgerAccount);

    } if (payload.creditMemoLedgerAccount) {
        payload.creditMemoLedgerAccount.ledgerAccountId = payload.creditMemoLedgerAccount.chartOfAccountDetailsId;
        payload.creditMemoLedgerAccount.txType = LEDGER_TYPE.TYPE_CREDIT_MEMO
        defaulltLedger.push(payload.creditMemoLedgerAccount);

    } if (payload.debitMemoLedgerAccount) {
        payload.debitMemoLedgerAccount.ledgerAccountId = payload.debitMemoLedgerAccount.chartOfAccountDetailsId;
        payload.debitMemoLedgerAccount.txType = LEDGER_TYPE.TYPE_DEBIT_MEMO
        defaulltLedger.push(payload.debitMemoLedgerAccount);

    } if (payload.customerExpenseLedgerAccount) {
        payload.customerExpenseLedgerAccount.ledgerAccountId = payload.customerExpenseLedgerAccount.chartOfAccountDetailsId;
        payload.customerExpenseLedgerAccount.txType = LEDGER_TYPE.TYPE_CUSTOMER_EXPENSE
        defaulltLedger.push(payload.customerExpenseLedgerAccount);

    } if (payload.supplierExpenseLedgerAccount) {
        payload.supplierExpenseLedgerAccount.ledgerAccountId = payload.supplierExpenseLedgerAccount.chartOfAccountDetailsId;
        payload.supplierExpenseLedgerAccount.txType = LEDGER_TYPE.TYPE_SUPPLIER_EXPENSE
        defaulltLedger.push(payload.supplierExpenseLedgerAccount);

    } if (payload.paymentRecieveLedgerAccount) {
        payload.paymentRecieveLedgerAccount.ledgerAccountId = payload.paymentRecieveLedgerAccount.chartOfAccountDetailsId;
        payload.paymentRecieveLedgerAccount.txType = LEDGER_TYPE.TX_PAYMENT_RECIEVED
        defaulltLedger.push(payload.paymentRecieveLedgerAccount);

    } if (payload.paymentMadeLedgerAccount) {
        payload.paymentMadeLedgerAccount.ledgerAccountId = payload.paymentMadeLedgerAccount.chartOfAccountDetailsId;
        payload.paymentMadeLedgerAccount.txType = LEDGER_TYPE.TX_PAYMENT_MADE
        defaulltLedger.push(payload.paymentMadeLedgerAccount);

    }if (payload.roundingOffLedgerAccount) {
        payload.roundingOffLedgerAccount.ledgerAccountId = payload.roundingOffLedgerAccount.chartOfAccountDetailsId;
        payload.roundingOffLedgerAccount.txType = LEDGER_TYPE.TYPE_ROUNDING_OFF
        defaulltLedger.push(payload.roundingOffLedgerAccount);

    } if (payload.couponLedgerAccount) {
        payload.couponLedgerAccount.ledgerAccountId = payload.couponLedgerAccount.chartOfAccountDetailsId;
        payload.couponLedgerAccount.txType = LEDGER_TYPE.TYPE_COUPON
        defaulltLedger.push(payload.couponLedgerAccount);
    }
    
    return defaulltLedger;
}

const _getCompanyInfo = (payload) => {

    // const currentDate = new Date();
    // const currentMonth = currentDate.getMonth() + 1;
    // let fyStartDate;
    // let fyEndDate;

    // if(currentMonth <= 3){
    //     fyStartDate = new Date("04/01/"+(currentDate.getFullYear() - 1));
    //     fyStartDate = fyStartDate.toDateString();

    //     fyEndDate = new Date("03/31/"+ (currentDate.getFullYear()));
    //     fyEndDate = fyEndDate.toDateString();
    // }else{
    //     fyStartDate = new Date("04/01/"+currentDate.getFullYear());
    //     fyStartDate = fyStartDate.toDateString();
    //     fyEndDate = new Date("03/31/"+ (currentDate.getFullYear() + 1));
    //     fyEndDate = fyEndDate.toDateString();
    // }

    const companyInfo = fetchFromLocalStorage(LOCAL_STORAGE_KEYS.LOGGEDIN_ENTITY_DATA) && ((JSON.parse(fetchFromLocalStorage(LOCAL_STORAGE_KEYS.LOGGEDIN_ENTITY_DATA))).defaultCompany || {});
    companyInfo.howDidYouHear = payload.sourceOfListening;
    companyInfo.companySize = payload.companySize;
    companyInfo.avgMonthlySOorInvoices = payload.averageMonthlyOrders;
    companyInfo.approxInventoryItems = payload.inventoryItemQuantity;
    companyInfo.isCompanySetupRequired = 0;
    companyInfo.accountingType = 'Accrual';
    companyInfo.boFinancialYearList = [{
        // fyStartDate:  formatDateForBackendFY(fyStartDate),
        // fyEndDate:  formatDateForBackendFY(fyEndDate).replace("00:00:00", "") +" 23:59:59",
        relationshipId: companyInfo.relationshipId,
        status : "Active",
        statusColor : "label label-success",
        //dateCreated: formatDateForBackend(new Date() ),
    }];
    companyInfo.defaultCoaLedgerAccountsList = getDefaultLedgerAccounts(payload);
    companyInfo.costCenterList = [];
    companyInfo.industryId = (payload.selectedIndustry || {}).businessIndustryId;
    companyInfo.labelConfig = payload.labelConfig ? JSON.stringify(payload.labelConfig) : '';

    companyInfo.fyStartDate= formatDateForBackendFY(payload.fyStartDate);
    companyInfo.fyEndDate= formatDateForBackendFY(payload.fyEndDate);

    companyInfo.fyStartDay = getPartialDateForBackend(payload.fyStartDate, CONSTANTS.FILTER_DATE_TYPE.START, CONSTANTS.DATE_PART_TYPE.DAY);
    companyInfo.fyEndDay = getPartialDateForBackend(payload.fyEndDate, CONSTANTS.FILTER_DATE_TYPE.END, CONSTANTS.DATE_PART_TYPE.DAY);
    companyInfo.fyStartMonth = getPartialDateForBackend(payload.fyStartDate, CONSTANTS.FILTER_DATE_TYPE.START, CONSTANTS.DATE_PART_TYPE.MONTH);
    companyInfo.fyEndMonth = getPartialDateForBackend(payload.fyEndDate, CONSTANTS.FILTER_DATE_TYPE.END, CONSTANTS.DATE_PART_TYPE.MONTH);
    return companyInfo;
}