import axios from 'axios';
import config from '../../config/environmentConfig';
import { showToasterMessage, addToLocalStorage } from '../../utils';
import { LOCAL_STORAGE_KEYS, AUTH } from '../../static/constants';
import { COMMON_ACTIONS } from "../../static/constants";

const { lang } = require('../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');


export const getAlpideProducts = () => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LIST_LOADER});
    return axios
    .get(`${config.CMS_BASE_URL}/${config.rootContextCMS}/${config.API_VERSION_CMS}/alpideProduct/getAlpideProducts`)
      .then(response => {
        dispatch({ type: COMMON_ACTIONS.ALPIDE_PRODUCT_LIST, data:response.data?.data });
      })
      .catch(err => {
        showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred, please contact support team for upgrade' })
      })
      .finally(()=>{
        dispatch({ type: COMMON_ACTIONS.HIDE_LIST_LOADER});
      })
  }
}


// export const getAllPlanIndustries = (props) => {
//     return dispatch => {
//       dispatch({ type: COMMON_ACTIONS.SHOW_PLAN_LOADER });
//       return axios
//       .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/userPlan/getPlanIndustriesByCountry?countryName=United States (USA)`)
//         .then(response => {
//           dispatch({ type: COMMON_ACTIONS.PLAN_INDUSTRY_LIST, data:response.data });
//         })
//         .catch(err => {
//           showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred, please contact support team for upgrade' })
//         })
//         .finally(()=>{
//             dispatch({ type: COMMON_ACTIONS.HIDE_PLAN_LOADER });
//         })
//     }
//   }

export const completeInitialSetup = (payload, props) => {

  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios.post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/clientRelationship/updateClientData`, payload)
      .then(res => {
        if (res.data) {
          const loginData = { ...props.loginData, defaultCompany: res.data };
          let userInfo = loginData.userInfo || {};

          return axios
            .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/user/getUserPermission?relationshipId=${userInfo.relationshipId}&userId=${userInfo.userId}`
            )

            .then(res => {
              // const result = loginData;
              // let tokenInfo = JSON.parse(result.tokenInfo);
              const permissionData = {};
              permissionData.primary = res.data.primary || [];
              permissionData.secondary = res.data.secondaryPermission || {};

              addToLocalStorage(LOCAL_STORAGE_KEYS.LOGGEDIN_ENTITY_DATA, JSON.stringify(loginData));
              addToLocalStorage(LOCAL_STORAGE_KEYS.PERMISSION_DATA, JSON.stringify(permissionData));
              // addToLocalStorage(LOCAL_STORAGE_KEYS.DEFAULT_SCHOOL_FORM, userInfo.formId);
              dispatch({ type: AUTH.SET_PERMISSIONS, data: permissionData });
              dispatch({ type: COMMON_ACTIONS.LOGGEDIN_COMPANY_INFO, data: loginData.defaultCompany });

              dispatch({ type: AUTH.SESSION_TYPE, data: 'valid' });
              ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
              // setTimeout(()=>{history.push('/admin/dashboard')}, 5000);
              setTimeout(() => {
                // if ((loginData.defaultCompany.industryName || '').toLowerCase() === 'education') {
                //   props.history.push('/admin/school-management/dashboard');
                //   props.popModalFromStack();
                // }
                // else {
                //   props.history.push('/admin/dashboard');
                //   props.popModalFromStack();
                // }
                props.history.push('/admin/dashboard');
                props.popModalFromStack();
              }, 5000);
            })
        }
      })
      .catch(err => {
        showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
      })
  }
}


