import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import DailyInventorySummaryListingComp from '../../../../components/inventory/InventoryValuation/dailyInvSummary';
import { getFirstAndLastDateOfMonth, getCurrentDateForBackend, getFinancialYear } from '../../../../utils';
import { fetchDailyInventoryValuationList } from './action';
import { showModal, hideModal, pushModalToStack, popModalFromStack } from '../../../../actions/commonActions';


class AllInventorySummaryListing extends Component {
  constructor(props) {
    super(props);

    const {firstDay, lastDay} = getFirstAndLastDateOfMonth(this.props.location.state.yearAndMonth);
    const {startDate, endDate} = props.location?.state;

    const FY = getFinancialYear(this.props.companyInfo) || {fyStartDate: '', fyEndDate: ''};

    this.state = {
      warehouseMasterId : 0,
      pageSize: 100,
      pageNumber: 1,
      isDataRendered: false,
      monthStartDate: firstDay,
      monthEndDate: lastDay,
      variantId: props.location.state.variantId,
      startDate: startDate || FY.fyStartDate,
      endDate: endDate || FY.fyEndDate,
      fStartDate: getCurrentDateForBackend(firstDay),
      fEndDate: getCurrentDateForBackend(lastDay)
    };

  }


  componentDidMount() {
    const companyInfo = this.props.companyInfo || {};
    const payload = {
      relationshipId: companyInfo.relationshipId,
      pageNumber: 1,
      pageSize: 100,
      warehouseMasterId : 0,
      filters:{
        startDate: getCurrentDateForBackend(this.state.monthStartDate),
        endDate: getCurrentDateForBackend(this.state.monthEndDate),
        variantId: this.state.variantId
      }
    };
    this.props.fetchDailyInventoryValuationList(payload);

  }

  render() {
    return (
      <DailyInventorySummaryListingComp {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
    )
  }
}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    dailyInventoryValuationList: state.inventoryValuation.dailyInventoryValuationList,
    listLoading: state.common.listLoading,
    permissions: state.auth.permissions,

  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchDailyInventoryValuationList,
    pushModalToStack,
    popModalFromStack,
    hideModal,
    showModal,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AllInventorySummaryListing);
