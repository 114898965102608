import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Deduction from '../../../../components/company/PayrollManagement/Deduction';
import DeductionDrawer from '../../../drawer/company/PayrollManagement/Deduction';
import { createDeduction, getDeduction, getTotalDeductionCount, deleteDeduction } from './action';
import { showModal, hideModal } from '../../../../actions/commonActions';
import { fetchPaginationDataIfNeeded,fetchDataIfNeeded } from '../../../../utils';


class DeductionContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageSize: 25,
      pageNumber: 1,
      searchedText: '',

    }
  };


  componentDidMount(props) {
    const companyInfo = this.props.companyInfo || {};

    const payload = {
      relationshipId: companyInfo.relationshipId,
      pageNumber: 1,
      pageSize: 25,

    }
    fetchPaginationDataIfNeeded('getDeduction', 'deductionList', this.props, payload, true)
    fetchDataIfNeeded('getTotalDeductionCount', 'deductionListCount', this.props, payload)
  }

  render() {
    return <>

      <Deduction {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
      {this.state.deductionDrawer && <DeductionDrawer {...this.state} {...this.props} updateState={(data) => { this.setState(data) }} />}
    </>

  }

}

const mapStateToProps = (state) => {
  return {

    companyInfo: state.common.companyInfo,
    listLoading: state.common.listLoading,
    drawerLoading:state.common.drawerLoading,
    headerLoading:state.common.headerLoading,
    userInfo: state.common.userInfo,
    deductionList: state.employee.deductionList,
    deductionListCount: state.employee.deductionListCount,
    permissions: state.auth.permissions,

  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  showModal,
  hideModal,
  createDeduction,
  getDeduction,
  getTotalDeductionCount,
  deleteDeduction

}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(DeductionContainer);
