import React, { Fragment, useState } from "react";
import PageBreadcrumb from "../../PageBreadcrumb";
import "./store-settings.scss";
import { FormattedMessage } from "react-intl";
import Preferences from "../../../containers/modal/modalBody/eStore/Preferences";
import StoreTimings from "../../../containers/modal/modalBody/eStore/StoreTimings";
import SupportAndSocial from "../../../containers/modal/modalBody/eStore/SupportAndSocial";
import Domains from "../../../containers/modal/modalBody/eStore/Domains";
import Policies from "../../../containers/modal/modalBody/eStore/Policies";
import Rectangle from "../../../assets/images/icon/reaction.png"
import StoreTiming from "../../../assets/images/icon/clock.png"
import Domain from "../../../assets/images/icon/domain.png"
import DeliveryIcon from "../../../assets/images/icon/delivery.png"
import CouponIcon from "../../../assets/images/icon/coupon.png"
import Policy from "../../../assets/images/icon/google-docs.png"
import Social from "../../../assets/images/icon/social-media.png"
import Checkout from "../../../containers/modal/modalBody/eStore/Checkout";
import Seo from "../../../containers/modal/modalBody/eStore/Seo";
import Delivery from "../../../containers/modal/modalBody/eStore/Delivery";
import InPageMenu from "../../Common/InPageMenu"
import LoginWorkFlow from "../../../containers/modal/modalBody/eStore/LoginWorkFlow";
import Coupon from "../../../containers/modal/modalBody/eStore/Coupon";
import Analytics from "../../../containers/modal/modalBody/eStore/Analytics";
import ReturnOrder from "../../../containers/modal/modalBody/eStore/ReturnOrder";
import ReturnIcon from "../../../assets/images/return.png";
import AnalyticsIcon from "../../../assets/images/increasing.png";
import OffersIcon from "../../../assets/images/offers.png";
import LoginWorkFlowIcon from "../../../assets/images/icon/workflow.png"
import PopUpManagement from "../../../containers/modal/modalBody/eStore/PopUpManagement";
import { MODULE_CODED_VALUES } from "../../../static/constants";
import { getPermissionData } from "../../../utils";
import PageHeader from "../../Common/pageHeader";
import SeoIcon from "../../../assets/images/seo.png";
import CheckoutIcon from "../../../assets/images/processing.png";
import PopupIcon from "../../../assets/images/pop-up.png";
import PreferencesIcon from "../../../assets/images/preferences.png";
import PromoHubManagement from "../../../containers/modal/modalBody/eStore/PromoHubManagement";




function StoreSettings(props) {
    const {permissions} = props;
    const primaryPerm = (permissions && permissions.primary) || [];
    const permissionsData = getPermissionData(primaryPerm, MODULE_CODED_VALUES.E_STORE_SETTING);

  const handleActiveTab = (index) => {
      props.updateState({
          selectedMenuItem: index
      })
  };
   
  const activeTab = () => {
      switch (props.selectedMenuItem) {
          case "Login Flow":
              return (permissionsData.read ? <LoginWorkFlow /> : <Fragment/>);
          case "Preferences":
              return (permissionsData.read ?  <Preferences /> : <Fragment/>);   
          case "Store Timings":
            return (permissionsData.read ? <StoreTimings /> : <Fragment/>);

          case "Domains":
            return (permissionsData.read ? <Domains /> : <Fragment/>);

          case "Social and Support":
            return (permissionsData.read ? <SupportAndSocial /> : <Fragment/>);

          case "Delivery":
            return (permissionsData.read ? <Delivery /> : <Fragment/>);

            case "Coupon Management":
                return (permissionsData.read ? <Coupon /> : <Fragment/>);

          case "Policies":
            return (permissionsData.read ? <Policies /> : <Fragment/>);

          case "Seo":
            return (permissionsData.read ? <Seo/> : <Fragment/>);

          case "Checkout":
            return (permissionsData.read ? <Checkout /> : <Fragment/>);

          case "Analytics":
            return (permissionsData.read ? <Analytics /> : <Fragment/>);

        case "Return":
            return (permissionsData.read ? <ReturnOrder /> : <Fragment/>);

        case "Pop-Up Management":
            return (permissionsData.read ? <PopUpManagement /> : <Fragment/>);
        case "Promo Hub":
          return (permissionsData.read ? <PromoHubManagement /> : <Fragment/>);
          default:
              return null;
      }
  };
  const breadCrumbList = [
    {
      name: <FormattedMessage id='sidebar.menuItem.eStores' defaultMessage='' />,
    },
    {
      name: <FormattedMessage id='sidebar.menuItem.storeSettings' defaultMessage='' />,
    },
  ];
  const menuList = [{     
    icon: LoginWorkFlowIcon,
    name: "eStore.storeSettings.login.label",
    description: "Configure user authentication options for secure account access",
    moduleCode: MODULE_CODED_VALUES.E_STORE_SETTING,
    key:"Login Flow"
},
    
    {     
      icon: PreferencesIcon,
      name: "eStore.storeSettings.preferences.label",
      description: "Set your store’s customization options for a tailored experience",
      moduleCode: MODULE_CODED_VALUES.E_STORE_SETTING,
      key:"Preferences"

  },
  {
      icon: StoreTiming,
      name: "eStore.storeSettings.storeTimings.label",
      description: "Define operating hours to inform customers when you’re available",
      moduleCode: MODULE_CODED_VALUES.E_STORE_SETTING,
      key:"Store Timings"

  },
  {
      icon: Domain,
      name: "eStore.storeSettings.domains.label",
      description: "Connect your store to a custom domain for brand identity",
      moduleCode: MODULE_CODED_VALUES.E_STORE_SETTING,
      key:"Domains"

  },
  {
      icon: Social,
      name: "eStore.storeSettings.supportSocial.label",
      description: "Integrate social media links and support channels for customer engagement",
      moduleCode: MODULE_CODED_VALUES.E_STORE_SETTING,
      key:"Social and Support"

  },
  {
      icon: DeliveryIcon,
      name: "eStore.storeSettings.delivery.label",
      description: "Establish shipping costs based on location and order size",
      moduleCode: MODULE_CODED_VALUES.E_STORE_SETTING,
      key:"Delivery"

  },
  {
    icon: CouponIcon,
    name: "eStore.storeSettings.coupon.label",
    description: "Create and manage discount codes to boost sales and customer retention",
    moduleCode: MODULE_CODED_VALUES.E_STORE_SETTING,
    key:"Coupon Management"

},
  {
      icon: Policy,
      name: "eStore.storeSettings.policies.labe",
      description: "Outline your store’s terms, including privacy and refund policies",
      moduleCode: MODULE_CODED_VALUES.E_STORE_SETTING,
      key:"Policies"

  },
  {
      icon: SeoIcon,
      name: "eStore.storeSettings.seoSettings.label",
      description: "Optimize your store for search engines to increase visibility",
      moduleCode: MODULE_CODED_VALUES.E_STORE_SETTING,
      key:"Seo"

  },
  {
    icon: CheckoutIcon,
    name: "eStore.storeSettings.checkout.label",
    description: "Customize fields to streamline the purchasing process for customers",
    moduleCode: MODULE_CODED_VALUES.E_STORE_SETTING,
    key:"Checkout"

},

  {
    icon: AnalyticsIcon,
    name: "eStore.storeSettings.analytics.label",
    description: "Monitor key metrics to evaluate store performance and customer behavior",
    moduleCode: MODULE_CODED_VALUES.E_STORE_SETTING,
    key:"Analytics"

},
{
    icon: ReturnIcon,
    name: "eStore.storeSettings.return.label",
    description: "Set guidelines for processing returns, replacements, and order cancellations",
    moduleCode: MODULE_CODED_VALUES.E_STORE_SETTING,
    key:"Return"

},
{
    icon: PopupIcon,
    name: "eStore.storeSettings.popUpMangement.label",
    description: "Design and manage promotional pop-ups to capture customer attention",
    moduleCode: MODULE_CODED_VALUES.E_STORE_SETTING,
    key:"Pop-Up Management"

},
{
  icon: OffersIcon,
  name: "eStore.storeSettings.promoHub.label",
  description: "Design and manage promotional pop-ups to capture customer attention",
  moduleCode: MODULE_CODED_VALUES.E_STORE_SETTING,
  key:"Promo Hub"

},
  ]
  return (
      <>
 <PageHeader {...props} 
        pageName= "sidebar.menuItem.storeSettings"
        breadCrumbList={breadCrumbList}
       
     
      />
       
          
          <div className="meta-container">
              <div className="meta-sidenav">
                  <InPageMenu menuList={menuList} onItemClick={handleActiveTab} {...props}/>
              </div>
              <div className="meta-main-body">
                  {activeTab()}           
              </div>
          </div>
      </>
  )
}

export default StoreSettings;