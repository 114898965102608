import React, { Fragment } from "react";
// import { AgGridReact } from "ag-grid-react";
import config from '../../../config/environmentConfig';
import { Tooltip, Menu, Skeleton, Empty } from "antd";
import { FormattedMessage, injectIntl } from "react-intl";
import { getMomentDateForUIReadOnly, sortTable } from "../../../utils";
import { CONSTANTS, ICONS } from '../../../static/constants';
import PageBreadcrumb from "../../PageBreadcrumb";
import DropdownAction from "antd/lib/dropdown";
import NewsLetter from "../../../containers/NewsLetter";

const NewLetterListingCompo = (props) => {
  const { newsletterTemplateList, pageSize, dir=0, updateState } = props;

  // const agGridStyle = {
  //   height: "100%",
  //   width: "100%",
  // };

  const deleteHandle = (dg) => {
    const modalBody = <FormattedMessage id='commons.delete.popup.confimation.msg' defaultMessage='' />;
    const modalData = {
      modalBody,
      handleSubmit: () => {
        props.deleteFormBuilder(dg, props);
      },
    }
    props.showModal(modalData);
  };

  // const columnDefs = [
  //   {
  //     headerComponentFramework: () => {
  //       return (
  //         <FormattedMessage
  //           id="setting.student.registration.list.formName"
  //           defaultMessage=""
  //         />
  //       );
  //     },
  //     field: "templateName",
  //     resizable: true,
  //     minWidth: 70,
  //   },
  //   {
  //     headerComponentFramework: () => {
  //       return <FormattedMessage id='common.dateCreated' defaultMessage='' />;
  //     },
  //     cellRendererFramework: (link) => {
  //       return <div>
  //         <Tooltip placement="topLeft" title={(link.value && getMomentDateForUIReadOnly({ date: link.value, format: CONSTANTS.DISPLAY_TOOLTIP_DATE_FORMAT }))}>
  //           {(link.value && getMomentDateForUIReadOnly({ date: link.value, format: CONSTANTS.DISPLAY_DATE_FORMAT }))}
  //         </Tooltip>
  //       </div>
  //     },
  //     field: "dateCreated",
  //     minWidth: 270,
  //     suppressMovable: true,
  //   },
  //   {
  //     field: "action",
  //     headerComponentFramework: () => {
  //       return <div className="cursor-pointer">

  //       </div>;
  //     },
  //     colId: "action_1",
  //     pinned: "right",
  //     lockPosition: true,
  //     cellRendererFramework: (params) => {
  //       return <div>
  //         <span className="cursor-pointer" title={props.intl.formatMessage({ id: 'modal.settings.manageUser.permissions.view' })} onClick={() => {
  //           Object.assign(document.createElement('a'), {
  //             target: '_blank',
  //             href: config.UI_BASE_URL + 'newsletter-v?t=' + props.relationshipId + '_' + params.data?.alpideFormBuilderId + '_' + Math.floor((Math.random() * 10000) + 1),
  //           }).click();
  //         }}>
  //           <i className='fa fa-eye' />
  //         </span>

  //         <span className="cursor-pointer" style={{ marginLeft: '10px' }} onClick={() => {
  //           props.history.push({
  //             pathname: '/admin/newsletter-t',
  //             state: {
  //               templateType: 'newsletter',
  //               alpideFormBuilderId: params.data.alpideFormBuilderId,
  //             }
  //           })
  //         }}><i className='fa fa-pencil' /></span>

  //         <span style={{ marginLeft: '10px' }} className="cursor-pointer" title={props.intl.formatMessage({ id: 'commons.listing.delete.tooltip.msg' })} onClick={
  //           () => { deleteHandle(params.data); }}>
  //           <i className='fa fa-trash' />
  //         </span>

  //       </div>;
  //     },
  //     minWidth: 70,
  //     maxWidth: 300,
  //     suppressMovable: true,
  //   }
  // ];

  const newFormBuilder = (data) => {
    // props.history.push({
    //   pathname: '/admin/newsletter-t',
    //   state: { templateType: 'newsletter'}
    // })

    props.pushModalToStack({  
      modalBody: <NewsLetter {...props} templateType='newsletter' alpideFormBuilderId= {data?.alpideFormBuilderId} />,
      width: '100%',
      hideTitle: true,  
      hideFooter: true,
      wrapClassName: "emailerTemplate_Modal",
      // wrapClassName: "full_modal",
    })
  };

  const breadCrumbList = [
    {
      name: <FormattedMessage id='breadcrum.communications' defaultMessage='' />
    },
    {
      name: <FormattedMessage id='breadcrum.communications.newsletter' defaultMessage='' />,
    }
  ];

  const actionMenu = (e) => {
    return (
        <Menu className="row-action-dropdown">

          <Menu.Item key="0" value="title">
            Actions
          </Menu.Item>

          <Menu.Divider />

          <Menu.Item key="1" value="preview" onClick={()=>{
              Object.assign(document.createElement('a'), {
                target: '_blank',
                href: config.UI_BASE_URL + 'newsletter-v?t=' + props.relationshipId + '_' + e?.alpideFormBuilderId + '_' + Math.floor((Math.random() * 10000) + 1),
              }).click();
            }}
          >
            <i className={ICONS.VIEW} />
            Preview
          </Menu.Item>

          <Menu.Item key="2" value="edit" onClick={()=>{
              // props.history.push({
              //   pathname: '/admin/newsletter-t',
              //   state: {
              //     templateType: 'newsletter',
              //     alpideFormBuilderId: e.alpideFormBuilderId,
              //   }
              // })
              newFormBuilder(e);
            }}
          >
            <i className={ICONS.EDIT} />
            Edit
          </Menu.Item>

          <Menu.Item key="3" onClick={()=>
            {
              deleteHandle(e);
            }
          }>
            <i className={ICONS.DELETE} />
            Delete
          </Menu.Item>

        </Menu >
    )
  };

  const sortColumn = (e) => {
    sortTable(document.getElementById('newsletter-table'), e.target.cellIndex, 1 - dir);
    updateState({ dir: 1 - dir })
  }

  return (
    <Fragment>
      <div className="view-top-bar">
        <div className="top-view-left">
          <div className="page-heading">
            <FormattedMessage id='heading.communications.newsletter' defaultMessage='' />
          </div>
          <div className="vertical-sep" />
          <PageBreadcrumb breadCrumbList={breadCrumbList} />
        </div>
        <div className="top-view-right">
          <button className="create-action-btn" onClick={()=>{
            newFormBuilder();
          }}>
              <i className={ICONS.ADD} /> <FormattedMessage id="button.create.label" defaultMessage="" />
          </button>
        </div>
      </div>
      <div className="view-container">
      
        <div className="view-container-actions">
          <div className="left-actions">
            <div className="table-heading">
              <FormattedMessage id='communication.newsletter.table.heading' defaultMessage='' />
            </div>
            {
              pageSize ? <>
                <div className="vertical-sep" />
                <div>{newsletterTemplateList.length}</div>
              </>:''
            }
          </div>
          <div className="right-actions">
          </div>
        </div>
        <Skeleton loading={props.isLoading}>
          <div className="table-container no-pagination">
            <table id='newsletter-table'>
              <thead>
                <tr>
                  <th>S.No.</th>
                  <th onClick={sortColumn}><FormattedMessage id="setting.student.registration.list.formName" defaultMessage="" /> <i className={ICONS.SORT} /></th>
                  <th onClick={sortColumn}><FormattedMessage id='common.dateCreated' defaultMessage='' /> <i className={ICONS.SORT} /></th>
                  <th width='6%'><FormattedMessage id='common.listing.header.action' defaultMessage="" /></th>
                </tr>
              </thead>
              <tbody>
                {
                  (newsletterTemplateList || []).length ? newsletterTemplateList.map((rowData, i)=>{
                    return <tr key={i}>
                      <td>{i+1}</td>
                      <td>{rowData.templateName}</td>
                      <td>{<Tooltip placement="topLeft" title={(rowData.dateCreated && getMomentDateForUIReadOnly({ date: rowData.dateCreated, format: CONSTANTS.DISPLAY_TOOLTIP_DATE_FORMAT }))}>
                          {(rowData.dateCreated && getMomentDateForUIReadOnly({ date: rowData.dateCreated, format: CONSTANTS.DISPLAY_DATE_FORMAT }))}
                        </Tooltip>}
                      </td>
                      <td style={{paddingLeft: '50px'}}>
                        <DropdownAction overlay={actionMenu(rowData)} trigger={['click']}>
                          <i className={ICONS.MORE} />
                        </DropdownAction>
                      </td>
                    </tr>
                  })
                  :
                  <tr key="empty-data-box">
                    <td colSpan={"100%"} style={{ borderBottom: "none" }}>
                        <Empty />
                    </td>
                  </tr>
                }
              </tbody>
            </table>
          </div>
          {/* <div className="agGridWrapper">
            <div className="ag-theme-balham" style={agGridStyle}>
              <AgGridReact
                columnDefs={columnDefs}
                defaultColDef={{
                  flex: 1,
                  autoHeight: true,
                  wrapText: true,
                }}
                rowData={newsletterTemplateList || []}
                rowDragManaged={true}
                domLayout={"autoHeight"}
                suppressDragLeaveHidesColumns={true}
                animateRows={true}
              ></AgGridReact>
            </div>
          </div> */}
        </Skeleton>
      </div>
    </Fragment>
  );
};

export default injectIntl(NewLetterListingCompo);
