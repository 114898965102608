import React from 'react';
import { Col, Row, Skeleton, Timeline } from 'antd';
import { getMomentDateForUIReadOnly } from '../../../../../utils'
import { connect } from 'react-redux';

//import { Link } from 'react-router-dom';

const TrackingDetailsModal = (props) => {
    const shipmentTrackingDetails = props?.shipmentTrackingDetails;
    const {shipment_track = {}} = shipmentTrackingDetails;
    return (
            <Skeleton loading={props.listLoading} paragraph={{ rows: 7 }} active>
                {shipment_track?.id ? 
                    <Row gutter={[8,8]}>
                        <Col span={8}>
                            <div><strong>AWB Code:</strong></div>
                            <div>{shipment_track.awb_code}</div>
                        </Col>
                        <Col span={8}>
                            <div><strong>Shipment ID:</strong></div>
                            <div>{shipment_track.shipment_id}</div>
                        </Col>
                        <Col span={8}>
                            <div><strong>Order ID:</strong></div>
                            <div>{shipment_track.order_id}</div>
                        </Col>

                        <Col span={8}>
                            <div><strong>Current Status:</strong></div>
                            <div>{shipment_track.current_status}</div>
                        </Col>
                        <Col span={8}>
                            <div><strong>Consignee Name:</strong></div>
                            <div>{shipment_track.consignee_name}</div>
                        </Col>
                        <Col span={8}>
                            <div><strong>Courier Company ID:</strong></div>
                            <div>{shipment_track.courier_company_id}</div>
                        </Col>

                        <Col span={8}>
                            <div><strong>Origin:</strong></div>
                            <div>{shipment_track.origin}</div>
                        </Col>
                        <Col span={8}>
                            <div><strong>Destination:</strong></div>
                            <div>{shipment_track.destination}</div>
                        </Col>
                        <Col span={8}>
                            <div><strong>Delivered To:</strong></div>
                            <div>{shipment_track.delivered_to}</div>
                        </Col>
                        <Col span={8}>
                            <div><strong>Pickup Date:</strong></div>
                            <div>{shipment_track.pickup_date || 'N/A'}</div>
                        </Col>
                        <Col span={8}>
                            <div><strong>Delivered Date:</strong></div>
                            <div>{shipment_track.delivered_date || 'N/A'}</div>
                        </Col>
                        <Col span={8}>
                            <div><strong>Expected Delivery Date:</strong></div>
                            <div>{shipment_track.edd}</div>
                        </Col>

                        <Col span={8}>
                            <div><strong>Weight:</strong></div>
                            <div>{shipment_track.weight} kg</div>
                        </Col>
                        <Col span={8}>
                            <div><strong>Packages:</strong></div>
                            <div>{shipment_track.packages}</div>
                        </Col>

                        <Col span={24}>
                            <div><strong>Tracking URL:</strong></div>
                            <div>
                                <a href={shipment_track.track_url} target="_blank" rel="noopener noreferrer">
                                    {shipment_track.track_url}
                                </a>
                            </div>
                        </Col>
                    </Row>
                : ""}
                {shipmentTrackingDetails?.error ?
                    <div className='required' style={{fontSize: '14px'}}><i class="fi fi-rr-exclamation mr10"/>{shipmentTrackingDetails?.error}</div>
                    :
                    <Timeline>
                        {shipmentTrackingDetails?.shipment_track_activities?.length ? shipmentTrackingDetails?.shipment_track_activities.map((tItem, i)=>{
                            return <Timeline.Item key={i+tItem.date}>
                                <div><strong>{tItem.status}</strong></div>
                                <div><strong>{tItem.activity}</strong></div>
                                <div><i>{tItem.location}</i><i>{tItem.date ? getMomentDateForUIReadOnly(tItem.date) : ''}</i></div>
                            </Timeline.Item>
                        })
                        :
                        ''
                    }
                    </Timeline>
                }
            </Skeleton>
    );

}

// mapStateToProps function
const mapStateToProps = (state) => ({
    shipmentTrackingDetails: state.shipment.shipmentTrackingDetails,
    listLoading: state.common.listLoading,
});

// Connect the component to the Redux store
export default connect(mapStateToProps)(TrackingDetailsModal);


// export default TrackingDetailsModal;
