import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import CompanyHeirarchyComponent from '../../../components/company/CompanyHierarchy';
import { fetchCompanyHierarchy, deleteHierarchy } from './action';
import { showModal, hideModal } from '../../../actions/commonActions';


class CompanyHierarchy extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount() {
    const companyInfo = (this.props.companyInfo || {})
    const payload= {
      relationshipId: companyInfo.relationshipId 
    }
    this.props.fetchCompanyHierarchy(payload);
  }

  render() {
    return <CompanyHeirarchyComponent {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
  }

}

const mapStateToProps = (state) => {
  return {
    companyHierarchyList: state.hierarchy.hierarchyList,
    companyInfo: state.common.companyInfo,
    isLoading: state.common.isLoading,
    permissions: state.auth.permissions,

  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchCompanyHierarchy,
  deleteHierarchy,
  showModal,
  hideModal
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CompanyHierarchy);
