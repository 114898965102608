import React, { Fragment } from "react";
import { Row, Col, Button, Checkbox, Input, Popover, Tooltip, Skeleton } from "antd";
import { injectIntl, FormattedMessage } from "react-intl";
import * as find from "lodash.find";
import * as filter from "lodash.filter";
import { Dropdown } from "../../../../general/Dropdown";
import { CustomAgGrid } from "../../../../general/CustomAgGrid";
import { AddAndRemoveColumn } from "../../../../general/AddAndRemoveColumn";
import {
  AG_GRID_CONSTANTS,
  GRID_PREFERENCE_CONSTANTS,
} from "../../../../../static/agGridConstants";
import { AG_GRID_CLASS_CONSTANTS } from "../../../../../static/cssClassConstants";
import {
  MODAL_TYPE,
  MODULE_CODED_VALUES,
  PERMISSION_VALUES,
  TRANSACTION_COLUMN_INDEX,
} from "../../../../../static/constants";
import { getLabelName } from "../../../../modal/modalBody/customerInfo";
import {
  showToasterMessage,
  fixedDecimalAndFormateNumber,
  fixedDecimalNumber,
  getCurrencySymbol,
  getPermissionData
} from "../../../../../utils";
import { CustomTableUpload } from "../../../../general/UploadTable";
import { CUSTOM_CONFIGURATION } from "../../../../../static/ckEditorConfigConstants";
import CKEditor from "../../../../general/CustomCKEditor/ckeditor";
import ReactHtmlParser from "react-html-parser";
import { AWSFileTable } from "../../../../general/AWSFileTable";
const { TextArea } = Input;

class ProductDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sectionVisible: {
        discount: false,
        tax: false,
        expense: false,
      },
      total: 0,
      subtotal: 0,
      subtotalWithoutDiscount: 0,
      totalDiscount: 0,
      totalTax: 0,
      itemLevelTaxes: [],
      rootDiscountList: props.rootDiscountList || [{}],
      rootDiscountPercent: 0,
      rootDiscountAmount: 0,
      rootExpenseList: props.rootExpenseList || [{}],
      setOnce: false
    };
    let self = this;
    window.document.onkeyup = function (e) {
      var charCode = typeof e.which == "number" ? e.which : e.keyCode;
      if (
        self.state &&
        self.state.scanedEAN &&
        charCode !== 16 &&
        charCode !== 13
      ) {
        self.setState({
          scanedEAN:
            self.state.scanedEAN +
            (e.code.indexOf("Key") > -1
              ? String.fromCharCode(charCode)
              : e.key),
        });
      } else if (charCode !== 16 && charCode !== 13) {
        self.setState({ scanedEAN: e.key });
      }
      if (e.code === "Enter") {
        self.props.getItemByUPC(
          {
            relationshipId: self.props.companyInfo.relationshipId,
            upc: self.state.scanedEAN,
            callback: function (item) {
              let selectedProductValue = "";
              let data = {};
              let valueArray = [item.itemId];
              let variant = {};
              if (
                item.inventoryItemVariantList &&
                item.inventoryItemVariantList[0]
              ) {
                valueArray.push(item.inventoryItemVariantList[0].variantId);
                variant = item.inventoryItemVariantList[0];
              }

              selectedProductValue = item.hasVariant
                ? item.itemName +
                " (" +
                (variant.attributeValue1 || "") +
                (variant.attributeValue2 ? " | " : "") +
                (variant.attributeValue2 || "") +
                "" +
                (variant.attributeValue3
                  ? " | " + variant.attributeValue3
                  : "") +
                ")"
                : item.itemName;
              //updateSelectedValue(selectedProductValue);
              data.selectedLabel = selectedProductValue;
              data.selectedProductValue = valueArray;
              data.selectedOption = item;
              data.product = selectedProductValue;
              data.productObj = null;
              data.variant = null;
              data.upc = self.state.scanedEAN;

              const itemId = item.itemId;

              if (itemId) {
                data.productObj = item;
                data.variant = item.inventoryItemVariantList[0];
                if (data.productObj.shortDescription) {
                  data.description = data.productObj.shortDescription;
                }
              }
              if (data.variant) {
                const rate = Number(data.variant.purchasedPrice || 0).toFixed(
                  2
                );
                data.baseRate = Number(rate);
                data.rate = Number(rate);
                data.uom = data.variant.uomName;
                if (
                  data.productObj.isPopulateTax &&
                  !(
                    self.props.txColumns.indexOf(
                      GRID_PREFERENCE_CONSTANTS.TAX
                    ) < 0 &&
                    self.props.txMandatoryColumns.indexOf(
                      GRID_PREFERENCE_CONSTANTS.TAX
                    ) < 0
                  )
                ) {
                  const defaultItemHsnAndTax =
                    data.productObj.inventoryItemDefaultTaxList[0] || {};
                  data.hsnCode = (defaultItemHsnAndTax || {}).hsnCode;
                  let selectedTax = {};
                  if ((defaultItemHsnAndTax || {}).taxSingleRateId) {
                    selectedTax =
                      find(self.props.taxes, {
                        taxSingleRateId: defaultItemHsnAndTax.taxSingleRateId,
                      }) || {};
                  } else {
                    selectedTax =
                      find(self.props.taxes, {
                        taxPercent:
                          (defaultItemHsnAndTax || {}).igstRate ||
                          (defaultItemHsnAndTax || {}).cgstRate ||
                          (defaultItemHsnAndTax || {}).globleTaxRate,
                      }) || {};
                  }
                  data.taxName = selectedTax.taxName;
                  data.taxApplied = selectedTax.taxNameDisplay;
                  data.tax = selectedTax.taxPercent;
                  data.taxName = selectedTax.taxName;
                  data.taxId = selectedTax.taxSingleRateId;
                }
              }
              if (self.gridApi?.rowRenderer?.lastRenderedRow === 0) {
                let checkEmptyRow = self.gridApi.getDisplayedRowAtIndex(0).data;
                (!checkEmptyRow || !checkEmptyRow.selectedLabel) &&
                  self.gridApi.updateRowData({
                    remove: [self.gridApi.getDisplayedRowAtIndex(0).data],
                  });
              }
              self.gridApi.updateRowData({ add: [data] });
              self.setState({ scanedEAN: "" });
            },
          },
          self.props
        );
      }
    };
  }

  calculateSubtotal = () => {
    let total = 0;
    let subtotal = 0;
    let totalDiscount = 0;
    let totalTax = 0;
    let itemLevelTaxes = [];
    let subtotalWithoutDiscount = 0;
    let rootDiscountAmount = 0;
    // let self = this;
    if (!this.state.rootDiscountPercent && this.props.rootDiscountPercent) {
      this.setState({
        rootDiscountPercent: this.props.rootDiscountPercent,
        rootDiscountAmount: this.props.rootDiscountAmount,
      });
    }

    if (!this.state.expenseAmount && this.props.expenseAmount) {
      this.setState({
        expenseAmount: this.props.expenseAmount,
      });
    }
    let rootDiscountList = this.state.rootDiscountList || [];
    let rootExpenseList = this.props.rootExpenseList || [];
    rootDiscountList.forEach((discountObj, i) => {
      discountObj.rootDiscountAmount = 0;
      rootDiscountList[i] = discountObj;
    });

    this.gridApi &&
      this.gridApi.forEachNode(function (rowNode, index) {
        let stringifiedRowData = JSON.stringify(rowNode.data);
        let totalAmountOfColumn = 0;
        const rowdata = JSON.parse(stringifiedRowData);
        if (rowdata.qty && rowdata.rate) {
          let taxObj = {
            subtotal: 0,
            taxPercent: 0,
            taxAmount: 0,
          };
          totalAmountOfColumn = Number(rowdata.qty) * Number(rowdata.rate);
          subtotalWithoutDiscount =
            Number(totalAmountOfColumn) + Number(subtotalWithoutDiscount);
          if (rowdata.discount) {
            let discountAmount =
              Number(rowdata.discount || 0) * 0.01 * totalAmountOfColumn;
            totalAmountOfColumn = totalAmountOfColumn - discountAmount;
            totalDiscount = totalDiscount + discountAmount;
          }
          rootDiscountList.forEach((discountObj, i) => {
            if (discountObj.rootDiscountPercent) {
              const rootDiscountPercent = discountObj.rootDiscountPercent;
              let itemLevelDiscountAmount =
                Number(rootDiscountPercent) * 0.01 * totalAmountOfColumn;

              if (discountObj.isTaxable) {
                totalAmountOfColumn =
                  totalAmountOfColumn - itemLevelDiscountAmount;
                totalDiscount =
                  totalDiscount +
                  itemLevelDiscountAmount +
                  (Number(rowdata.tax || 0) * 0.01 * itemLevelDiscountAmount ||
                    0);
                rootDiscountAmount =
                  rootDiscountAmount +
                  itemLevelDiscountAmount +
                  (Number(rowdata.tax || 0) * 0.01 * itemLevelDiscountAmount ||
                    0);
                discountObj.rootDiscountAmount = (
                  Number(discountObj.rootDiscountAmount || 0) +
                  itemLevelDiscountAmount +
                  (Number(rowdata.tax || 0) * 0.01 * itemLevelDiscountAmount ||
                    0)
                ).toFixed(2);
                totalTax =
                  totalTax +
                  (Number(rowdata.tax || 0) * 0.01 * itemLevelDiscountAmount ||
                    0);
              } else {
                totalAmountOfColumn =
                  totalAmountOfColumn - itemLevelDiscountAmount;
                totalDiscount = totalDiscount + itemLevelDiscountAmount;
                rootDiscountAmount =
                  rootDiscountAmount + itemLevelDiscountAmount;
                discountObj.rootDiscountAmount = (
                  Number(discountObj.rootDiscountAmount || 0) +
                  itemLevelDiscountAmount
                ).toFixed(2);
              }
              // if (discountObj.taxPercent) {
              //     let taxAmount = ((Number(discountObj.taxPercent) * 0.01) * itemLevelDiscountAmount) || 0;
              //     totalDiscount = totalDiscount + taxAmount;
              //     totalAmountOfColumn = totalAmountOfColumn - taxAmount;
              //     discountObj.taxAmount = (Number(discountObj.taxPercent) * 0.01) * discountObj.rootDiscountAmount;
              // }
              rootDiscountList[i] = discountObj;
            }
          });
          // if (self.state.rootDiscountPercent) {
          //   let itemLevelDiscountAmount =
          //     Number(self.state.rootDiscountPercent) *
          //     0.01 *
          //     totalAmountOfColumn;
          //   totalAmountOfColumn = totalAmountOfColumn - itemLevelDiscountAmount;
          //   totalDiscount = totalDiscount + itemLevelDiscountAmount;
          //   rootDiscountAmount = rootDiscountAmount + itemLevelDiscountAmount;
          // }

          if (rowdata.tax) {
            taxObj.taxPercent = Number(rowdata.tax || 0).toFixed(2);
            taxObj.taxName = rowdata.taxName;
            let taxAmount =
              Number(rowdata.tax || 0) * 0.01 * totalAmountOfColumn;
            totalTax = totalTax + taxAmount;
          }
          if (taxObj.taxPercent) {
            taxObj.subtotal = Number(totalAmountOfColumn || 0).toFixed(2);
            const availableTaxObj = find(itemLevelTaxes, {
              taxPercent: taxObj.taxPercent,
            });
            if (availableTaxObj) {
              itemLevelTaxes = filter(itemLevelTaxes, (ele) => {
                if (ele.taxPercent === taxObj.taxPercent) {
                  ele.subtotal =
                    Number(ele.subtotal || 0) + Number(taxObj.subtotal || 0);
                  ele.taxAmount =
                    Number(ele.taxPercent || 0) *
                    0.01 *
                    Number(ele.subtotal || 0);
                }
                return true;
              });
            } else {
              taxObj.taxAmount = (
                Number(taxObj.taxPercent || 0) *
                0.01 *
                Number(taxObj.subtotal || 0)
              ).toFixed(2);
              itemLevelTaxes.push(taxObj);
            }
          }
          subtotal = Number(subtotal) + totalAmountOfColumn;

        }
      });
    let expenseTax = 0;
    let expenseAmt = 0;
    rootExpenseList.forEach((expenseObj, i) => {
      expenseAmt += Number(expenseObj.expenseAmount || 0);
      if (
        expenseObj.selectedExpenseLedger &&
        expenseObj.selectedExpenseLedger.isLedgerTaxable &&
        expenseObj.expenseLedgerAccountTaxRate &&
        expenseObj.expenseAmount
      ) {
        expenseTax =
          (Number(expenseObj.expenseAmount || 0) *
            (expenseObj.expenseLedgerAccountTaxRate || 0)) /
          100;
      }
    });
    // total = Number(subtotal || 0) + totalTax + this.state.expenseAmount;
    total = Number(subtotal || 0) + totalTax + expenseAmt + expenseTax;


    this.setState({
      total: Number(total || 0).toFixed(2),
      subtotal: Number(subtotal || 0).toFixed(2),
      totalDiscount: Number(totalDiscount || 0).toFixed(2),
      totalTax: Number(totalTax || 0).toFixed(2),
      subtotalWithoutDiscount: Number(subtotalWithoutDiscount || 0).toFixed(2),
      itemLevelTaxes,
      rootDiscountAmount: Number(rootDiscountAmount || 0).toFixed(2),
      rootDiscountList: this.props.rootDiscountList || rootDiscountList,
    });

    this.props.updateState({
      rootDiscountPercent: 0,
      rootDiscountAmount: 0,
      expenseAmount: 0,
      rootDiscountList: null
    });
  };


  handleNewTaxAddition = (props, payload) => {
    const formData = payload.formData;
    const { showModal, companyInfo } = props;
    formData.relationshipId = companyInfo.relationshipId;
    this.gridApi && this.gridApi.stopEditing();
    const data = {
      title: <FormattedMessage id="addItem.text.newTax" defaultMessage="" />,
      formData,
      hideFooter: true,
      modalData: {
        modalType: MODAL_TYPE.NEW_TAX,
        data: payload,
      },
      handleSubmit: (formData = {}) => {
        data.formData.submittedOnce = true;
        showModal(data);
      },
    };
    showModal(data);
  };

  addItemConfirmationModal = (props, text, modalType) => {
    const { showModal, companyInfo } = props;
    const labelName = getLabelName(modalType);
    const modalBody = (
      <span>
        <FormattedMessage
          id="addItem.confirmation"
          values={{ value: `${text} to ` }}
          defaultMessage={``}
        />
        {labelName} ?
      </span>
    );

    let addFunc;

    switch (modalType) {
      case MODAL_TYPE.UOM: {
        addFunc = props.addUOM;
        break;
      }
      case MODAL_TYPE.SHIPPING_INSTRUCTION: {
        addFunc = props.addShippingInstruction;
        break;
      }
      case MODAL_TYPE.EXPENSE_TYPE: {
        addFunc = props.addExpenseTypes;
        break;
      }
      default: {
        addFunc = () => { };
      }
    }

    const modalData = {
      modalBody,
      handleSubmit: () => {
        addFunc({ text: text, relationshipId: companyInfo.relationshipId });
        this.gridApi && this.gridApi.stopEditing();
        props.hideModal();
      },
    };
    showModal(modalData);
  };

  getModalTitle = (newTextObj, modalType) => {
    let title;

    switch (modalType) {
      case MODAL_TYPE.UOM: {
        title = <FormattedMessage id="addItem.text.uom" defaultMessage="" />;
        break;
      }
      case MODAL_TYPE.SHIPPING_INSTRUCTION: {
        title = (
          <FormattedMessage
            id="addItem.text.shippingInstruction"
            defaultMessage=""
          />
        );
        break;
      }
      case MODAL_TYPE.EXPENSE_TYPE: {
        title = (
          <FormattedMessage id="addItem.text.expenseType" defaultMessage="" />
        );
        break;
      }
      default:
        title = (
          <FormattedMessage id="signup.confirmation.title" defaultMessage="" />
        );
    }
    return title;
  };

  handleNewItemAddition = (props, payload, modalType) => {
    const formData = payload.formData;
    const { showModal } = props;

    if (
      formData.textBoxValue &&
      formData.textBoxValue.length &&
      !payload.existsInData
    ) {
      this.addItemConfirmationModal(props, formData.textBoxValue, modalType);
    } else {
      const data = {
        title: this.getModalTitle(payload, modalType),
        // hideFooter: true,
        formData,
        modalData: { modalType, data: payload },
        handleSubmit: (formData = {}) => {
          if (formData.textBoxValue && formData.textBoxValue.length) {
            this.addItemConfirmationModal(
              props,
              formData.textBoxValue,
              modalType
            );
          } else {
            data.formData.submittedOnce = true;
            showModal(data);
          }
        },
      };
      showModal(data);
    }
  };

  deleteClickHandler = (props, modalType, payload) => {
    const { showModal, companyInfo } = props;
    this.gridApi && this.gridApi.stopEditing();
    const modalBody = (
      <FormattedMessage
        id="deleteItem.confirmation"
        defaultMessage={``}
        values={{ value: payload.text }}
      />
    );

    let deleteFunc;

    switch (modalType) {
      case MODAL_TYPE.UOM: {
        deleteFunc = props.deleteUOM;
        break;
      }
      case MODAL_TYPE.NEW_TAX: {
        deleteFunc = props.deleteTax;
        break;
      }
      case MODAL_TYPE.SHIPPING_INSTRUCTION: {
        deleteFunc = props.deleteShippingInstruction;
        break;
      }
      case MODAL_TYPE.EXPENSE_TYPE: {
        deleteFunc = props.deleteExpenseTypes;
        break;
      }
      default: {
        deleteFunc = () => { };
      }
    }

    const modalData = {
      modalBody,
      handleSubmit: () => {
        deleteFunc({
          id: payload.id,
          relationshipId: companyInfo.relationshipId,
        });
        if (
          modalType === MODAL_TYPE.EXPENSE_TYPE &&
          payload.id === props.expenseId
        ) {
          props.updateState({ expenseId: undefined, expensName: undefined });
        }
        props.hideModal();
      },
    };
    showModal(modalData);
  };
  newLedgerAccounts = (props) => {
    props.updateDrawerState({
      ledgerAccountsDrawerVisible: true
    })
  };

  render() {
    //const { txColumnSetting } = this.props;
    const self = this;
    const { itemLevelTaxes } = this.state;
   
    const isPlaceOfSupplySameAsRelationship = (this.props.supplierBillingAddress || {}).stateName === this.props.placeOfSupply;
    // const isPlaceOfSupplySameAsRelationship =
    //   (this.props.relationshipBillingAddress || {}).stateName ===
    //   (this.props.placeOfSupply
    //     ? this.props.placeOfSupply
    //     : this.props.supplierBillingAddress?.stateName);
    const onGridReady = (params) => {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      onDataRendered(params);
    };
    if (this.props.valueSet && !this.state.setOnce) {

      this.setState({
        rootExpenseList: this.props.rootExpenseList,
        rootDiscountList: this.props.rootDiscountList,
        setOnce: true
      })
      // this.calculateSubtotal()

    }
    const content = <AddAndRemoveColumn {...this.props} />;

    const _isColumnToHide = (columnName) => {
      // if (this.props.forceHideTxColumn && this.props.forceHideTxColumn.indexOf(columnName) > -1) {
      //     return true;
      // }
      return this.props.txColumns.indexOf(columnName) < 0 &&
        this.props.txMandatoryColumns.indexOf(columnName) < 0
        ? true
        : false;
    };

    const columnDefs = [
      {
        headerComponentFramework: () => {
          return "#";
        },
        cellRendererFramework: (params) => {
          return params.node.rowIndex + 1;
        },
        field: "sno",
        rowDrag: true,
        colId: "sno_1",
        minWidth: 70,
        maxWidth: 70,
        suppressMovable: true,
        lockPosition: true,
        pinned: "left",
        sortable: true,
        cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN,
      },
      {
        headerComponentFramework: () => {
          return (
            <FormattedMessage
              id="supplier.purchaseOrder.form.gridHeader.product"
              defaultMessage=""
            />
          );
        },
        field: AG_GRID_CONSTANTS.COMMON.PRODUCT_FIELD,
        hide: _isColumnToHide(AG_GRID_CONSTANTS.COMMON.PRODUCT_FIELD),
        colId: AG_GRID_CONSTANTS.COMMON.PRODUCT_FIELD + "_1",
        resizable: true,
        editable: !this.props.isRFQConversion,
        sortable: true,
        minWidth: 120,
        suppressMovable: true,
        permissions: this.props.permissions,
        moduleName: MODULE_CODED_VALUES.PRODUCTS,
        operations: [PERMISSION_VALUES.CREATE, PERMISSION_VALUES.DELETE],
        cellEditor: "customTreeDropDown",
        cellRendererFramework: (params) => {
          setTimeout(() => {
            if (
              params.reactContainer &&
              params.reactContainer.scrollHeight >=
              (params.node.data.maxHeight || 30)
            ) {
              params.node.setRowHeight(params.reactContainer.scrollHeight);
              params.node.data.minHeight =
                params.reactContainer.scrollHeight > 30
                  ? params.reactContainer.scrollHeight
                  : 30;
              this.gridApi && this.gridApi.onRowHeightChanged();
            } else {
              params.node.data.minHeight =
                params.reactContainer.scrollHeight > 30
                  ? params.reactContainer.scrollHeight
                  : 30;
            }
          }, 500);
          return ReactHtmlParser(params.data.product);
        },
        suppressKeyboardEvent: (params) => {
          const KEY_ENTER = 13;
          const keyCode = params.event.keyCode;
          const gridShouldDoNothing = params.editing && keyCode === KEY_ENTER;
          return gridShouldDoNothing;
        },
        cellEditorParams: (obj) => {
          return {
            lastColumnCell: false,
            options: this.props.products,
            onSearch: this.props.onSearch,
            permissions: this.props.permissions,
            moduleName: MODULE_CODED_VALUES.PRODUCTS,
            operations: [PERMISSION_VALUES.CREATE, PERMISSION_VALUES.DELETE],
            onAddNewClick: () => {
              self.props.updateHeaderState({
                inventory: {
                  inventoryDrawerVisible: true,
                },
              });
            },
            canAddNew: true,
            value: obj.node.data.selectedProductValue || [],
            selectedLabel: obj.node.data.product || "",
            allowClear: true,
            onSelect: (selectedValue) => {
              //obj.node.setDataValue(obj.colDef.field, selectedValue);
              obj.node.data.selectedProductValue =
                obj.node.selectedProductValue;
              obj.node.data.productObj = null;
              obj.node.data.variant = null;
              const itemId =
                obj.node.data.selectedProductValue &&
                obj.node.data.selectedProductValue[0];
              if (itemId) {
                const variantId =
                  obj.data.selectedProductValue &&
                    obj.data.selectedProductValue.length === 2
                    ? obj.data.selectedProductValue[1]
                    : 0;
                obj.node.data.productObj =
                  find(this.props.products, { itemId: Number(itemId) }) || {};
                obj.node.data.variant = variantId
                  ? find(obj.node.data.productObj.inventoryItemVariantList, {
                    variantId: Number(variantId),
                  })
                  : obj.node.data.productObj.inventoryItemVariantList[0];
                if (obj.node.data.productObj.shortDescription) {
                  obj.node.data.description =
                    obj.node.data.productObj.shortDescription;
                }
              }
              if (obj.node.data.variant && !this.props.isDataToUpdate) {
                const rate = Number(
                  obj.node.data.variant.purchasedPrice || 0
                ).toFixed(2);
                obj.node.data.baseRate = Number(rate);
                obj.node.data.rate = Number(rate);
                //obj.node.setDataValue('specialDiscount', 0);
                //obj.node.setDataValue('baseRate', Number(rate));
                //obj.node.setDataValue('rate', Number(rate));
                //obj.node.setDataValue('uom', obj.node.data.variant.uomName);
                obj.node.data.uom = obj.node.data.variant.uomName;
                obj.node.data.uom = obj.node.data.variant.uomName;
                if (
                  obj.node.data.productObj.isPopulateTax &&
                  !_isColumnToHide(GRID_PREFERENCE_CONSTANTS.TAX)
                ) {
                  const defaultItemHsnAndTax =
                    obj.node.data.productObj.inventoryItemDefaultTaxList[0] ||
                    {};
                  obj.node.data.hsnCode = (defaultItemHsnAndTax || {}).hsnCode;
                  let selectedTax = {};
                  if ((defaultItemHsnAndTax || {}).taxSingleRateId) {
                    selectedTax =
                      find(this.props.taxes, {
                        taxSingleRateId: defaultItemHsnAndTax.taxSingleRateId,
                      }) || {};
                  } else {
                    selectedTax =
                      find(this.props.taxes, {
                        taxPercent:
                          (defaultItemHsnAndTax || {}).igstRate ||
                          (defaultItemHsnAndTax || {}).cgstRate ||
                          (defaultItemHsnAndTax || {}).globleTaxRate,
                      }) || {};
                  }
                  obj.node.data.taxName = selectedTax.taxName;
                  obj.node.data.taxApplied = selectedTax.taxNameDisplay;
                  obj.node.data.tax = selectedTax.taxPercent;
                  obj.node.data.taxName = selectedTax.taxName;
                  obj.node.data.taxId = selectedTax.taxSingleRateId;
                }
              }
              let isDuplicateItem = false;
              this.gridApi &&
                this.gridApi.forEachNode((rowNode, index) => {
                  const { data } = rowNode;
                  if (
                    data.product === selectedValue &&
                    index !== obj.node.rowIndex
                  ) {
                    isDuplicateItem = true;
                  }
                });
              if (isDuplicateItem) {
                showToasterMessage({
                  messageType: "warning",
                  description: "Duplicate product is selected",
                });
              }

              obj.node.data.isDuplicateItem = isDuplicateItem;
              setTimeout(() => {
                this.gridApi && this.gridApi.stopEditing();
              }, 100);
            },
          };
        },
        cellClassRules: {
          "ag-grid-cell-warning-boundary": function (obj) {
            let isDuplicateItem = false;
            self.gridApi &&
              self.gridApi.forEachNode((rowNode, index) => {
                const { data } = rowNode;
                if (
                  data.product &&
                  data.product === obj.node.data.product &&
                  obj.node.rowIndex !== index
                ) {
                  isDuplicateItem = true;
                }
              });
            return isDuplicateItem;
          },
        },
      },
      // {
      //     headerComponentFramework: () => {
      //         return <FormattedMessage id='drawer.inventory.upc' />;
      //     },
      //     field: 'upc',
      //     //hide: _isColumnToHide(AG_GRID_CONSTANTS.COMMON.PRODUCT_FIELD),
      //     colId: "upc_1",
      //     editable: !this.props.isRFQConversion,
      //     resizable: true,
      //     sortable: true,
      //     minWidth: 120,
      //     suppressMovable: true,
      //     cellEditor: 'customTextEditor',
      //     cellRendererFramework: (params) => {
      //         setTimeout(() => {
      //             if (params.reactContainer && params.reactContainer.scrollHeight >= (params.node.data.maxHeight || 30)) {
      //                 params.node.setRowHeight(params.reactContainer.scrollHeight);
      //                 params.node.data.minHeight = params.reactContainer.scrollHeight > 30 ? params.reactContainer.scrollHeight : 30;
      //                 this.gridApi && this.gridApi.onRowHeightChanged();
      //             } else {
      //                 params.node.data.minHeight = params.reactContainer.scrollHeight > 30 ? params.reactContainer.scrollHeight : 30;
      //             }
      //         }, 500)
      //         return ReactHtmlParser(params.data.upc)
      //     },
      //     suppressKeyboardEvent: (params) => {
      //         const KEY_ENTER = 13;
      //         const keyCode = params.event.keyCode;
      //         const gridShouldDoNothing = params.editing && keyCode === KEY_ENTER;
      //         return gridShouldDoNothing;
      //     },
      //     cellEditorParams: (obj) => {
      //         return {
      //             value: obj.node.data.upc,
      //             onChange: (val) => {
      //                 let self = this;
      //                 this.props.getItemByUPC({
      //                     relationshipId: this.props.companyInfo.relationshipId, upc: val, callback: function (item) {
      //                         let selectedProductValue = '';
      //                         let valueArray = [item.itemId];
      //                         let variant = {};
      //                         if (item.inventoryItemVariantList && item.inventoryItemVariantList[0]) {
      //                             valueArray.push(item.inventoryItemVariantList[0].variantId);
      //                             variant = item.inventoryItemVariantList[0];
      //                         }

      //                         selectedProductValue = item.hasVariant ? item.itemName + " (" + (variant.attributeValue1 || '') + (variant.attributeValue2 ? ' | ' : '') + (variant.attributeValue2 || '') + '' + (variant.attributeValue3 ? ' | ' + variant.attributeValue3 : '') + ")" : item.itemName;
      //                         //updateSelectedValue(selectedProductValue);
      //                         obj.node.data.selectedLabel = selectedProductValue;
      //                         obj.node.data.selectedProductValue = valueArray;
      //                         obj.node.data.selectedOption = item;
      //                         obj.node.data.product = selectedProductValue;
      //                         obj.node.data.productObj = null;
      //                         obj.node.data.variant = null;
      //                         obj.node.data.upc = val;

      //                         const itemId = item.itemId;

      //                         if (itemId) {
      //                             obj.node.data.productObj = item;
      //                             obj.node.data.variant = item.inventoryItemVariantList[0];
      //                             if (obj.node.data.productObj.description) {
      //                                 obj.node.data.description = obj.node.data.productObj.description;
      //                             }
      //                         }
      //                         if (obj.node.data.variant) {
      //                             const rate = Number(obj.node.data.variant.purchasedPrice || 0).toFixed(2);
      //                             obj.node.data.baseRate = Number(rate);
      //                             obj.node.data.rate = Number(rate);
      //                             obj.node.data.uom = obj.node.data.variant.uomName;
      //                             if (obj.node.data.productObj.isPopulateTax && !_isColumnToHide(GRID_PREFERENCE_CONSTANTS.TAX)) {
      //                                 const defaultItemHsnAndTax = obj.node.data.productObj.inventoryItemDefaultTaxList[0] || {};
      //                                 obj.node.data.hsnCode = ((defaultItemHsnAndTax || {}).hsnCode);
      //                                 let selectedTax = {};
      //                                 if ((defaultItemHsnAndTax || {}).taxSingleRateId) {
      //                                     selectedTax = find(self.props.taxes, { taxSingleRateId: defaultItemHsnAndTax.taxSingleRateId }) || {};
      //                                 } else {
      //                                     selectedTax = find(self.props.taxes, { taxPercent: ((defaultItemHsnAndTax || {}).igstRate || (defaultItemHsnAndTax || {}).cgstRate || (defaultItemHsnAndTax || {}).globleTaxRate) }) || {};
      //                                 }
      //                                 obj.node.data.taxName = selectedTax.taxName;
      //                                 obj.node.data.taxApplied = selectedTax.taxNameDisplay;
      //                                 obj.node.data.tax = selectedTax.taxPercent;
      //                                 obj.node.data.taxName = selectedTax.taxName;
      //                                 obj.node.data.taxId = selectedTax.taxSingleRateId;
      //                             }
      //                         }

      //                         self.gridApi.stopEditing(true);
      //                     }

      //                 }, this.props)
      //             }
      //         }
      //     }
      // },
      {
        headerComponentFramework: () => {
          return (
            <FormattedMessage
              id="supplier.purchaseOrder.form.gridHeader.description"
              defaultMessage=""
            />
          );
        },
        field: AG_GRID_CONSTANTS.COMMON.DESCRIPTION_FIELD,
        cellClass: " ag-cell-description",
        colId: AG_GRID_CONSTANTS.COMMON.DESCRIPTION_FIELD + "_1",
        resizable: true,
        editable: true,
        sortable: true,
        minWidth: 120,
        suppressMovable: true,
        hide: _isColumnToHide(GRID_PREFERENCE_CONSTANTS.DESCRIPTION),
        cellEditor: "CKEditorGrid",
        cellEditorParams: (params) => {
          return {
            value: params.node.data.description || "",
            isEditorLoaded: this.props.isEditorLoaded,
            onChange: (value) => {
              params.node.data[params.colDef.field] = value;
              params.node.data.isEditorLoaded = true;
              this.props.updateState({
                isEditorLoaded: true,
              });
            },
          };
        },
        cellRendererFramework: (params) => {
          setTimeout(() => {
            if (
                params.reactContainer && params.reactContainer.scrollHeight > 30 &&params.reactContainer.scrollHeight >= (params.node.data.minHeight || 0 ) 
            ) {
              params.eParentOfValue.style.height = "inherit";
              params.eParentOfValue.style.height = params.reactContainer.scrollHeight;
              params.node.data.maxHeight = params.reactContainer.scrollHeight;
              params.node.setRowHeight(params.reactContainer.scrollHeight);
              this.gridApi && this.gridApi.onRowHeightChanged();
            }
          }, 500);
          return ReactHtmlParser(params.data.description);
        },
        suppressKeyboardEvent: (params) => {
          const KEY_ENTER = 13;
          const keyCode = params.event.keyCode;
          const gridShouldDoNothing =
            params.event.target.value &&
            params.editing &&
            keyCode === KEY_ENTER;
          params.node.data.scrollHeight = `${params.event.target.scrollHeight}px`;
          if (
            params.event.target.scrollHeight > (params.data.minHeight || 30)
          ) {
            if (params.event.path && params.event.path[2]) {
              params.event.path[2].style.height = "inherit";
              params.event.path[2].style.height = `${params.event.target.scrollHeight}px`;
            }
            params.node.setRowHeight(params.event.target.scrollHeight);
            this.gridApi && this.gridApi.onRowHeightChanged();
          } else {
            if (params.event.path && params.event.path[2]) {
              params.event.path[2].style.height = "inherit";
              params.event.path[2].style.height = `${params.node.data.minHeight || 30
                }px`;
            }
            params.node.setRowHeight(params.node.data.minHeight || 30);
            this.gridApi && this.gridApi.onRowHeightChanged();
          }
          return gridShouldDoNothing;
        },
      },
      {
        headerComponentFramework: () => {
          return (
            <FormattedMessage
              id="supplier.purchaseOrder.form.gridHeader.qty"
              defaultMessage=""
            />
          );
        },
        field: AG_GRID_CONSTANTS.COMMON.QTY_FIELD,
        colId: GRID_PREFERENCE_CONSTANTS.QUANTITY + "_1",
        resizable: true,
        minWidth: 100,
        maxWidth: 150,
        suppressMovable: true,
        editable: true, //!this.props.isRFQConversion,
        sortable: true,
        cellEditor: "customNumberEditor",
        cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN,
        cellEditorParams: (obj) => {
          return {
            decimalDigitsLength: (this.props.companyInfo || {})
              .decimalDigitsLengthQty,
            onChange: (value) => {
              obj.node.data.qty = value;
              obj.api.refreshCells();
            },
          };
        },
      },
      {
        headerComponentFramework: () => {
          return (
            <FormattedMessage
              id="supplier.purchaseOrder.form.gridHeader.uom"
              defaultMessage=""
            />
          );
        },
        field: AG_GRID_CONSTANTS.COMMON.UOM_FIELD,
        colId: GRID_PREFERENCE_CONSTANTS.UOM + "_1",
        editable: true,
        minWidth: 100,
        maxWidth: 140,
        suppressMovable: true,
        resizable: true,
        sortable: true,
        hide: _isColumnToHide(GRID_PREFERENCE_CONSTANTS.UOM),
        cellEditor: "customDropDownEditor",
        suppressKeyboardEvent: (params) => {
          const KEY_ENTER = 13;
          const keyCode = params.event.keyCode;
          const gridShouldDoNothing = params.editing && keyCode === KEY_ENTER;
          return gridShouldDoNothing;
        },
        cellEditorParams: (obj) => {
          return {
            lastColumnCell: false,
            items: this.props.uomList,
            optionKeyName: "uomId",
            valueKeyName: "uomName",
            canAddNew: true,
            canDelete: true,
            permissions: this.props.permissions,
            moduleName: MODULE_CODED_VALUES.UOM,
            operations: [PERMISSION_VALUES.CREATE, PERMISSION_VALUES.DELETE],
            deleteClickHandler: (payload) => {
              this.deleteClickHandler(this.props, MODAL_TYPE.UOM, payload);
            },
            onAddNewClick: (payload) => {
              payload.formData = {
                textBoxValue: payload.textEntered,
                submittedOnce: false,
              };
              this.handleNewItemAddition(this.props, payload, MODAL_TYPE.UOM);
            },
            onSelect: (selectedValue) => {
              obj.node.data.uom = obj.node.data.variant?.uomName;
              obj.api.refreshCells();
              //obj.node.setDataValue(obj.colDef.field, selectedValue);
            },
            allowClear: true,
            onClear: () => {
              //obj.node.setDataValue(obj.colDef.field, undefined);
            },
          };
        },
        cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN,
      },
      {
        headerComponentFramework: () => {
          return (
            <FormattedMessage
              id="supplier.purchaseOrder.form.gridHeader.hsn"
              defaultMessage=""
            />
          );
        },
        field: "hsnCode",
        resizable: true,
        colId: GRID_PREFERENCE_CONSTANTS.HSN + "_1",
        minWidth: 100,
        maxWidth: 140,
        suppressMovable: true,
        editable: true,
        sortable: true,
        hide:
          _isColumnToHide(GRID_PREFERENCE_CONSTANTS.HSN) ||
          (this.props.companyInfo || {}).countryName !== "India",
        cellEditor: "customTextEditor",
        cellEditorParams: (obj) => {
          return {
            onChange: (value) => {
              obj.node.data.hsnCode = value;
              obj.api.refreshCells();
            },
          };
        },
      },
      {
        headerComponentFramework: () => {
          return (
            <div>
              {" "}
              <span>MRP</span>
              <Tooltip
                placement="right"
                title={
                  <FormattedMessage
                    id="tooltip.originalRate"
                    defaultMessage=""
                  />
                }
                trigger="click"
              >
                <i className="fa fa-question-circle-o pl5 cursor-pointer" />
              </Tooltip>
            </div>
          );
        },
        hide: _isColumnToHide(GRID_PREFERENCE_CONSTANTS.BASE_PRICE),
        field: "baseRate",
        resizable: true,
        colId: GRID_PREFERENCE_CONSTANTS.BASE_PRICE + "_1",
        cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN,
        valueGetter: (params) => {
          return (
            params.data.baseRate && Number(params.data.baseRate || 0).toFixed(2)
          );
        },
      },
      {
        headerComponentFramework: () => {
          return (
            <FormattedMessage
              id="supplier.purchaseOrder.form.gridHeader.rate"
              defaultMessage=""
            />
          );
        },
        field: AG_GRID_CONSTANTS.COMMON.RATE_FIELD,
        colId: AG_GRID_CONSTANTS.COMMON.RATE_FIELD + "_1",
        editable: true,
        minWidth: 100,
        maxWidth: 150,
        suppressMovable: true,
        sortable: true,
        resizable: true,
        cellEditor: "customNumberEditor",
        cellEditorParams: (obj) => {
          return {
            decimalDigitsLength: (this.props.companyInfo || {})
              .decimalDigitsLengthPrice,
            onChange: (value) => {
              obj.node.data.baseRate = value;
              obj.node.data.rate = value;
              obj.api.refreshCells();
            },
          };
        },
        valueGetter: (params) => {
          if (
            params.data.specialDiscount &&
            params.data.rate &&
            Number(params.data.rate) !== 0
          ) {
            params.data.rate =
              params.data.baseRate -
              params.data.specialDiscount * 0.01 * params.data.baseRate;
          }
          if (!params.data.specialDiscount && params.data.baseRate) {
            params.data.rate = params.data.baseRate;
          }

          if (this.props.priceListId && params.data.rate) {
            params.data.rate =
              params.data.baseRate -
              (this.props.priceListType.toLowerCase() === "markdown"
                ? (params.data.specialDiscount || 0) *
                0.01 *
                params.data.baseRate +
                this.props.priceListPercentage * 0.01 * params.data.baseRate
                : (params.data.specialDiscount || 0) *
                0.01 *
                params.data.baseRate -
                this.props.priceListPercentage * 0.01 * params.data.baseRate);
          }
          return (
            params.data.rate &&
            Number(params.data.rate || 0).toFixed(
              (this.props.companyInfo || {}).decimalDigitsLengthPrice || 0
            )
          );
        },
        cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN,
      },
      {
        headerComponentFramework: () => {
          return (
            <FormattedMessage
              id="supplier.purchaseOrder.form.gridHeader.specialDiscount"
              defaultMessage=""
            />
          );
        },
        field: AG_GRID_CONSTANTS.COMMON.SPL_DISCOUNT_FIELD,
        editable: true,
        sortable: true,
        resizable: true,
        minWidth: 100,
        maxWidth: 140,
        suppressMovable: true,
        colId: GRID_PREFERENCE_CONSTANTS.SPECIAL_DISCOUNT + "_1",
        hide: _isColumnToHide(GRID_PREFERENCE_CONSTANTS.SPECIAL_DISCOUNT),
        cellEditor: "customNumberEditor",
        cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN,
        cellEditorParams: (obj) => {
        
        return {
          decimalDigitsLength: (this.props.companyInfo || {})
            .decimalDigitsLengthPrice,
          maxValue: 100,
          onChange: (value) => {
            obj.node.data.specialDiscount = value;
            obj.api.refreshCells();
          },
        };
        },
      },
      {
        headerComponentFramework: () => {
          return (
            <FormattedMessage
              id="supplier.purchaseOrder.form.gridHeader.discount"
              defaultMessage=""
            />
          );
        },
        field: AG_GRID_CONSTANTS.COMMON.DISCOUNT_FIELD,
        editable: true,
        sortable: true,
        minWidth: 120,
        maxWidth: 120,
        suppressMovable: true,
        resizable: true,
        colId: GRID_PREFERENCE_CONSTANTS.DISCOUNT + "_1",
        hide: _isColumnToHide(GRID_PREFERENCE_CONSTANTS.DISCOUNT),
        cellEditor: "customNumberEditor",
        cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN,
        cellEditorParams: (obj) => {
          return {
            decimalDigitsLength: (this.props.companyInfo || {})
              .decimalDigitsLengthPrice,
            maxValue: 100,
            onChange: (value) => {
              obj.node.data.discount = value;
              obj.api.refreshCells();
            },
          };
        },
      },
      {
        headerComponentFramework: () => {
          return (
            <FormattedMessage
              id="customer.salesQuote.form.gridHeader.tax"
              defaultMessage=""
            />
          );
        },
        field: "taxApplied",
        editable: true,
        sortable: true,
        minWidth: 120,
        suppressMovable: true,
        resizable: true,
        colId: GRID_PREFERENCE_CONSTANTS.TAX + "_1",
        hide: _isColumnToHide(GRID_PREFERENCE_CONSTANTS.TAX),
        cellEditor: "customDropDownEditor",
        suppressKeyboardEvent: (params) => {
          const KEY_ENTER = 13;
          const keyCode = params.event.keyCode;
          const gridShouldDoNothing = params.editing && keyCode === KEY_ENTER;
          return gridShouldDoNothing;
        },
        cellEditorParams: (obj) => {
          return {
            lastColumnCell: true,
            items: this.props.taxes,
            optionKeyName: "taxSingleRateId",
            canAddNew: true,
            canDelete: true,
            permissions: this.props.permissions,
            moduleName: MODULE_CODED_VALUES.TAXES,
            operations: [PERMISSION_VALUES.CREATE, PERMISSION_VALUES.DELETE],
            deleteClickHandler: (payload) => {
              this.deleteClickHandler(this.props, MODAL_TYPE.NEW_TAX, payload);
            },
            onAddNewClick: (payload) => {
              payload.formData = {
                taxName: payload.textEntered,
                submittedOnce: false,
              };
              this.handleNewTaxAddition(
                this.props,
                payload,
                MODAL_TYPE.NEW_TAX
              );
            },
            valueKeyName: "taxNameDisplay",
            onSelect: (selectedValue, option) => {
              const selectedTax = find(this.props.taxes, {
                taxSingleRateId: Number(option.key),
              });
              obj.node.data.tax = selectedTax.taxPercent;
              obj.node.data.taxName = selectedTax.taxName;
              //obj.node.setDataValue(obj.colDef.field, selectedValue);
              obj.node.data.taxId = selectedTax.taxSingleRateId;
            },
            allowClear: true,
            onClear: () => {
              obj.node.data.tax = null;
              obj.node.data.taxId = null;
              obj.node.data.taxApplied = null;
              obj.node.data.taxName = null;
              this.calculateSubtotal();
            },
          };
        },
        cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN,
      },
      {
        headerComponentFramework: () => {
          return (
            "Taxable Amount"
          );
        },
        field: AG_GRID_CONSTANTS.COMMON.AMOUNT_FIELD_WITHOUTTAX,
        colId: AG_GRID_CONSTANTS.COMMON.AMOUNT_FIELD_WITHOUTTAX + "_1",
        resizable: true,
        minWidth: 120,
        maxWidth:120,
        suppressNavigable: true,
        valueGetter: (params) => {
          let totalAmountOfColumn = 0;
          if (params.data.qty && params.data.rate) {
            totalAmountOfColumn = params.data.qty * params.data.rate;
            if (params.data.discount) {
              let discountAmount = params.data.discount * 0.01 * totalAmountOfColumn;
              totalAmountOfColumn = totalAmountOfColumn - discountAmount;
            }
            this.calculateSubtotal();
          }
          return totalAmountOfColumn
            ? fixedDecimalAndFormateNumber(totalAmountOfColumn)
            : "";
        },
        // cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN,
      },
      {
        headerComponentFramework: () => {
          return (
            <FormattedMessage
              id="supplier.purchaseOrder.form.gridHeader.amount"
              defaultMessage=""
            />
          );
        },
        field: AG_GRID_CONSTANTS.COMMON.AMOUNT_FIELD,
        colId: AG_GRID_CONSTANTS.COMMON.AMOUNT_FIELD + "_1",
        resizable: true,
        pinned: "right",
        minWidth: 120,
        lockPosition: true,
        suppressMovable: true,
        suppressNavigable: true,
        valueGetter: (params) => {
          let totalAmountOfColumn = 0;
          if (params.data.qty && params.data.rate) {
            totalAmountOfColumn = params.data.qty * params.data.rate;
            if (params.data.discount) {
              let discountAmount =
                params.data.discount * 0.01 * totalAmountOfColumn;
              totalAmountOfColumn = totalAmountOfColumn - discountAmount;
            }
            if (params.data.tax) {
              totalAmountOfColumn =
                params.data.tax * 0.01 * totalAmountOfColumn +
                totalAmountOfColumn;
            }
            this.calculateSubtotal();
          }
          return totalAmountOfColumn
            ? fixedDecimalAndFormateNumber(totalAmountOfColumn)
            : "";
        },
        cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN,
      },

      {
        headerComponentFramework: () => {
          return (
            <div className="cursor-pointer">
              <Popover
                content={content}
                title={
                  <div className="text-center">
                    {" "}
                    <FormattedMessage
                      id="common.addRemove"
                      defaultMessage=""
                    />{" "}
                  </div>
                }
                placement="left"
                trigger="click"
              >
                <i className="fa fa-bars" />
              </Popover>
            </div>
          );
        },
        minWidth: 50,
        maxWidth: 50,
        suppressMovable: true,
        field: "action",
        lockPosition: true,
        colId: "action_1",
        pinned: "right",
        cellRenderer: "customActionEditor",
        suppressNavigable: true,
        cellClass: this.props.isRFQConversion ? "custom-readonly-cell" : "",
        cellRendererParams: (params) => {
          return {
            onClick: () => {
              this.gridApi.updateRowData({
                remove: [
                  this.gridApi.getDisplayedRowAtIndex(params.node.rowIndex)
                    .data,
                ],
              });
              if (this.gridApi.rowRenderer.lastRenderedRow < 0) {
                this.gridApi.updateRowData({ add: [{}] });
              }
              this.calculateSubtotal();
            },
          };
        },
      },
    ];

    const createPO = (isDraft) => {
      // let isRequiredFiledMissing = false;
      // this.gridApi.forEachNode((rowNode, index) => {
      //     let { data } = rowNode;
      //     if (index === 0 && ((!data.product && !data.description) || !data.rate || !data.qty)) {
      //         isRequiredFiledMissing = true;
      //     } else if (!data.product && !data.rate && !data.qty) {
      //         data = {};
      //     } else if ((!data.product && !data.description) || !data.rate || !data.qty) {
      //         isRequiredFiledMissing = true;
      //     }
      // });

      // if ((isRequiredFiledMissing && !isDraft) || !(this.props.supplier && this.props.supplier.supplierId)) {
      //     return showToasterMessage({  messageType: 'error', description: this.props.intl.formatMessage({ id: 'description.Please.select.Supplier', defaultMessage: '' })});
      // }

      // let isRequiredFieldMissing = false;
      let errorKey = "";
      if (!(this.props.supplier && this.props.supplier.supplierId)) {
        return showToasterMessage({
          messageType: "error",
          description: this.props.intl.formatMessage({
            id: "missingSupplier.errorMsg",
            defaultMessage: "",
          }),
        });
      }
      this.gridApi.forEachNode((rowNode, index) => {
        let { data } = rowNode;
        if (index === 0 && !data.product && !data.description) {
          errorKey = "missingProduct.errorMsg";
        } else if (index === 0 && !Number(data.qty || 0)) {
          errorKey = "missingQty.errorMsg";
        } else if (index === 0 && !Number(data.rate || 0)) {
          errorKey = "missingRate.errorMsg";
        }
      });

      if (errorKey && !isDraft) {
        return showToasterMessage({
          messageType: "error",
          description: this.props.intl.formatMessage({
            id: errorKey,
            defaultMessage: "",
          }),
        });
      }

      const modalData = {
        modalBody: <FormattedMessage id="addUpdateData" defaultMessage={``} />,
        handleSubmit: () => {
          const payload = {
            props: this.props,
            gridObject: this.gridApi,
            isPlaceOfSupplySameAsRelationship:
              isPlaceOfSupplySameAsRelationship,
            state: this.state,
            isDraft: isDraft,
          };
          if (isDraft) {
            this.props.createPODraft(payload);
          } else {
            this.props.createPO(payload);
          }
          this.props.hideModal();
          this.props.popModalFromStack();
        },
      };

      this.props.showModal(modalData);
    };

    const saveColumnIndex = (columnState) => {
      let data = this.props.txPreferences || {};
      let gridPreference = {};
      data.relationshipId = (this.props.companyInfo || {}).relationshipId;
      data.clientUserAccountId = (this.props.userInfo || {}).userId;
      if (data.tablePreference) {
        gridPreference = JSON.parse(data.tablePreference);
      }
      gridPreference.columnSetting = columnState;
      gridPreference.columnList = this.props.txColumns;
      data.gridPreference = JSON.stringify(gridPreference);
      data.preferenceId = this.props.preferenceId;
      data.tablePreference = this.props.tablePreference;
      this.props.saveUserPrefrences(data);
    };

    const saveColumnWidth = (columnState, width) => {
      let data = this.props.txPreferences || {};
      let gridPreference = {};
      data.relationshipId = (this.props.companyInfo || {}).relationshipId;
      data.clientUserAccountId = (this.props.userInfo || {}).userId;
      if (data.gridPreference) {
        gridPreference = JSON.parse(data.gridPreference);
      }
      gridPreference.columnSetting = columnState;
      gridPreference.columnList = this.props.txColumns;
      data.gridPreference = JSON.stringify(gridPreference);
      this.props.updateState({
        isColumnFitTable: false,
      });
      data.preferenceId = this.props.preferenceId;
      data.tablePreference = this.props.tablePreference;
      this.props.saveUserPrefrences(data, this.props);
    };

    let moveEvent = {};
    const onColumnMoved = (event, isDragStopped) => {
      if (event.column) {
        moveEvent = event;
      }
      if (
        isDragStopped &&
        moveEvent.column &&
        moveEvent.column.colDef &&
        moveEvent.source !== AG_GRID_CONSTANTS.EVENT_SOURCE.API
      ) {
        saveColumnIndex(moveEvent.columnApi.getColumnState());
      }
    };

    const onColumnResized = (event, tablegridApi, tableGridColumnApi) => {
      const gridColumnApi = tableGridColumnApi || this.gridColumnApi;
      //event.api.resetRowHeights();
      //const gridApi = tablegridApi || this.gridApi;
      if (
        event.type === "columnResized" &&
        event.source !== "sizeColumnsToFit" &&
        event.source !== "api" &&
        event.finished === true
      ) {
        //event.api.resetRowHeights();
        if (
          gridColumnApi &&
          gridColumnApi.columnController.bodyWidth <
          gridColumnApi.columnController.scrollWidth
        ) {
          this.gridApi.sizeColumnsToFit();
          // const columnArray = event.columnApi.getColumnState();
          // for (let i = (columnArray.length - 1); i >= 0; i--) {
          //     if (columnArray[i] && !columnArray[i].hide && columnArray[i].colId !== 'action_1') {
          //         columnArray[i].width = columnArray[i].width + (gridColumnApi.columnController.scrollWidth - gridColumnApi.columnController.bodyWidth);
          //         break;
          //     }
          // }
          // saveColumnWidth(columnArray);
          setTimeout(() => {
            const columnArray = event.columnApi.getColumnState();
            saveColumnWidth(columnArray);
          }, 1000);
        } else {
          saveColumnWidth(event.columnApi.getColumnState());
        }
      }
    };

    if (this.props.toUpdatePriceList) {
      this.gridApi.refreshCells({ force: true });
      this.props.updateState({ toUpdatePriceList: false });
    }

    const getRowHeight = (params) => {
      const rowHeight = params.node.data.scrollHeight
        ? Number(params.node.data.scrollHeight.split("px")[0]) - 30
        : 30;
      return rowHeight < 30 ? 30 : rowHeight;
    };

    const onModelUpdated = (params) => {
      this.calculateSubtotal();
      if (this.props.tableDataReRendered) {
        //params.api.resetRowHeights();
        this.props.updateState({
          tableDataReRendered: false,
        });
        let actionIndex = 0;
        let amountIndex = 0;
        let txColumnSetting =
          this.props.txColumnSetting && this.props.txColumnSetting.length
            ? this.props.txColumnSetting
            : params.columnApi.getColumnState();
        const columnToBeSet = [];
        const indexObjArr = TRANSACTION_COLUMN_INDEX.PO_FORM;

        indexObjArr.forEach((e) => {
          let colObj = find(txColumnSetting, { colId: e + "_1" });
          if (colObj) {
            columnToBeSet.push(colObj);
          }
        });

        txColumnSetting = txColumnSetting.filter((ele) => {
          let columnName = ele.colId.split("_")[0];
          return indexObjArr.indexOf(columnName) === -1;
        });
        txColumnSetting = columnToBeSet.concat(txColumnSetting);
        txColumnSetting.forEach((e, index) => {
          let columnName = e.colId.split("_")[0];
          if (columnName !== "action" && columnName !== "amount") {
            if (columnName === "isChecked" || columnName === "sno") {
              e.pinned = "left";
              e.lockPosition = true;
              e.hide = false;
            } else {
              e.hide = _isColumnToHide(columnName);
            }
          }
          if (columnName === "amount") {
            e.hide = false;
            amountIndex = index;
            e.lockPosition = true;
            e.pinned = "right";
          }
          if (columnName === "action") {
            actionIndex = index;
            e.width = 52;
            e.hide = false;
            e.pinned = "right";
          }
        });
        const actionObj = txColumnSetting[actionIndex];
        const amountObj = txColumnSetting[amountIndex];
        txColumnSetting = txColumnSetting.filter((e) => {
          let columnName = e.colId.split("_")[0];
          return columnName !== "action" && columnName !== "amount";
        });
        amountObj.lockPosition = true;
        actionObj.lockPosition = true;
        txColumnSetting.push(amountObj);
        txColumnSetting.push(actionObj);
        params.columnApi.setColumnState(txColumnSetting);
        setTimeout(() => {
          if (
            this.gridColumnApi &&
            this.gridColumnApi.columnController.bodyWidth <
            this.gridColumnApi.columnController.scrollWidth
          ) {
            params.api.sizeColumnsToFit();
          }
        }, 500);
      }
    };

    const onDataRendered = (params) => {
      let txColumnSetting =
        this.props.txColumnSetting && this.props.txColumnSetting.length
          ? this.props.txColumnSetting
          : params.columnApi.getColumnState();
      let actionIndex = 0;
      let amountIndex = 0;
      if (Object.keys(txColumnSetting).length) {
        const columnToBeSet = [];
        const indexObjArr = TRANSACTION_COLUMN_INDEX.PO_FORM;

        indexObjArr.forEach((e) => {
          let colObj = find(txColumnSetting, { colId: e + "_1" });
          if (colObj) {
            columnToBeSet.push(colObj);
          }
        });

        txColumnSetting = txColumnSetting.filter((ele) => {
          let columnName = ele.colId.split("_")[0];
          return indexObjArr.indexOf(columnName) === -1;
        });
        txColumnSetting = columnToBeSet.concat(txColumnSetting);
        txColumnSetting.forEach((e, index) => {
          let columnName = e.colId.split("_")[0];
          if (columnName !== "action" && columnName !== "amount") {
            if (columnName === "isChecked" || columnName === "sno") {
              e.pinned = "left";
              e.hide = false;
            } else {
              e.hide = _isColumnToHide(columnName);
            }
          }
          if (columnName === "action") {
            e.width = 52;
            actionIndex = index;
            e.hide = false;
            e.pinned = "right";
          }
          if (columnName === "amount") {
            e.hide = false;
            e.lockPosition = true;
            amountIndex = index;
            e.pinned = "right";
          }
        });
        const actionObj = txColumnSetting[actionIndex];
        const amountObj = txColumnSetting[amountIndex];
        txColumnSetting = txColumnSetting.filter((e) => {
          let columnName = e.colId.split("_")[0];
          return columnName !== "action" && columnName !== "amount";
        });
        amountObj.lockPosition = true;
        actionObj.lockPosition = true;
        txColumnSetting.push(amountObj);
        txColumnSetting.push(actionObj);
        this.gridColumnApi.setColumnState(txColumnSetting);
        setTimeout(() => {
          if (
            this.gridColumnApi &&
            this.gridColumnApi.columnController.bodyWidth <
            this.gridColumnApi.columnController.scrollWidth
          ) {
            params.api.sizeColumnsToFit();
          }
        }, 500);
      } else {
        setTimeout(() => {
          if (
            this.gridColumnApi &&
            this.gridColumnApi.columnController.bodyWidth <
            this.gridColumnApi.columnController.scrollWidth
          ) {
            params.api.sizeColumnsToFit();
          }
        }, 500);
      }
    };
    setTimeout(() => {
   
        if (this.gridApi && this.props.isColumnFitTable) {
          this.gridApi.sizeColumnsToFit();
        }
      
    }, 500);
    

    return (
      <Fragment>
        <Skeleton loading={this.props.detailLoading} active paragraph={{ rows: 5 }}>

          <div className="transaction-table">
            {this.props.txColumns.length ? (
              <CustomAgGrid
                columnDefs={columnDefs}
                onGridReady={onGridReady}
                onModelUpdated={onModelUpdated}
                getRowHeight={getRowHeight}
                onColumnResized={onColumnResized}
                onDragStopped={() => {
                  onColumnMoved({}, true);
                }}
                //onColumnMoved={onColumnMoved}
                rowData={this.props.poDetailsList || [{}]} 
                gridStyle={{
                  width: "100%",
                  height: "100%",
                  marginBottom: "10px",
                }}
              />
            ) : null}
          </div>
          <div style={{ display: "flex", gap: "10px" }}>
            <Button
              className="grey-button"
              onClick={() => {
                this.gridApi.updateRowData({ add: [{}] });
              }}
            >
              {
                <FormattedMessage
                  id="supplier.purchaseOrder.form.button.addLine"
                  defaultMessage=""
                />
              }
            </Button>
            <Button
              className="grey-button"
              onClick={() => {
                this.props.showModal({
                  modalBody: (
                    <FormattedMessage
                      id="common.confirmation.clear"
                      defaultMessage=""
                    />
                  ),
                  handleSubmit: () => {
                    let gridApi = this.gridApi;
                    let self = this;
                    this.gridApi.forEachNode(function (rowNode, index) {
                      gridApi.updateRowData({ remove: [rowNode.data] });
                      if (gridApi.rowRenderer.lastRenderedRow < 0) {
                        gridApi.updateRowData({ add: [{}] });
                      }
                      self.calculateSubtotal();
                    });
                  },
                });
              }}
            >
              <FormattedMessage
                id="supplier.purchaseOrder.form.button.clearAllLines"
                defaultMessage=""
              />
            </Button>
          </div>
          <div className="upload-btn-so">
            <CustomTableUpload
              fileList={this.props.fileList}
              onChange={(fileList) => {
                this.props.updateState({
                  fileList,
                });
              }}
            />
          </div>
          {this.props?.docDetailList?.length ?
            <>
              Previous Data
              <AWSFileTable
                {...this.props}
                docDetailList={this.props.docDetailList}
                fetchTxDetail={(data) => {
                  const val = this.props.poDataEdit
                  val.docDetailList = []
                  // this.props.updateState({
                  //   poDataEdit: val
                  // })
                  this.props.fetchPurchasedOrderDetail({
                    supplierId: this.props.poDataEdit.supplierId,
                    poMasterId: this.props.poDataEdit.poMasterId,
                    relationshipId: this.props.poDataEdit.relationshipId,
                  });
                }}
              />

            </>
            : ""}
          <Row>
            <Col span={12}>
              <div className="remarks-title">
                <FormattedMessage
                  id="supplier.purchaseOrder.form.shippingInstructions.label"
                  defaultMessage=""
                />
              </div>
              <Dropdown
                className="shipping-instructions"
                items={this.props.allShippingInstruction}
                valueKeyName="shippingInstructions"
                optionKeyName="shippingInstructionId"
                value={this.props.shippingInstructions}
                canAddNew={true}
                canDelete={true}
                allowClear={true}
                onClear={() => {
                  this.props.updateState({ shippingInstructions: undefined });
                }}
                deleteClickHandler={(payload) => {
                  this.deleteClickHandler(
                    this.props,
                    MODAL_TYPE.SHIPPING_INSTRUCTION,
                    payload
                  );
                }}
                onAddNewClick={(payload) => {
                  payload.formData = {
                    textBoxValue: payload.textEntered,
                    submittedOnce: false,
                    maxLength: 500,
                  };
                  this.handleNewItemAddition(
                    this.props,
                    payload,
                    MODAL_TYPE.SHIPPING_INSTRUCTION
                  );
                }}
                onSelect={(shippingInstructionId, option) => {
                  this.props.updateState({
                    shippingInstructions: option.name,
                  });
                }}
              />

              <div>
                <div className="remarks-title">
                  <FormattedMessage
                    id="supplier.purchaseOrder.form.internalRemarks.label"
                    defaultMessage=""
                  />
                </div>
                {/* <TextArea value={this.props.internalRemarks} maxLength={500} className="description-textarea" rows={4} onChange={(e) => {
                            this.props.updateState({
                                internalRemarks: e.target.value
                            });
                        }} placeholder={this.props.intl.formatMessage(
                            { id: 'common.Enter.internal.remarks', defaultMessage: '' }
                        )}></TextArea> */}
                <CKEditor
                  type="inline"
                  className="description-textarea"
                  key={`${"internalRemarks"}`}
                  data={this.props.internalRemarks}
                  onInit={(editor) => {
                    this.editor = editor;
                  }}
                  onChange={(event) => {
                    const data = event.editor.getData();
                    this.props.updateState({ internalRemarks: data });
                  }}
                  config={{
                    ...CUSTOM_CONFIGURATION,
                    placeholder: this.props.intl.formatMessage({
                      id: "common.Enter.internal.remarks",
                      defaultMessage: "",
                    }),
                  }}
                />
              </div>

              <div className="remarks-title">
                <FormattedMessage
                  id="supplier.purchaseOrder.form.supplierRemarks.label"
                  defaultMessage=""
                />
              </div>

              {/* <TextArea value={this.props.supplierRemarks} maxLength={500} className="description-textarea" rows={4} onChange={(e) => {
                            this.props.updateState({
                                supplierRemarks: e.target.value
                            });
                        }} placeholder={this.props.intl.formatMessage(
                            { id: 'enter.supplier.remarks', defaultMessage: '' }
                        )}></TextArea> */}
              {
                <CKEditor
                  type="inline"
                  className="description-textarea"
                  key={`${"supplierRemarks"}`}
                  data={this.props.supplierRemarks}
                  onInit={(editor) => {
                    this.editor = editor;
                  }}
                  onChange={(event) => {
                    const data = event.editor.getData();
                    this.props.updateState({ supplierRemarks: data });
                  }}
                  config={{
                    ...CUSTOM_CONFIGURATION,
                    placeholder: this.props.intl.formatMessage({
                      id: "enter.supplier.remarks",
                      defaultMessage: "",
                    }),
                  }}
                />
              }
            </Col>
            <Col span={7} offset={5}>
              <Row gutter={16}>
                <Col span={12}>
                  <Row gutter={[16, 8]}>
                    <Col>
                      {
                        <Checkbox
                          checked={this.props.isRootDiscount}
                          onChange={(e) => {
                            this.props.updateState({
                              isRootDiscount: e.target.checked,
                            });
                          }}
                        >
                          {" "}
                          <FormattedMessage
                            id="supplier.purchaseOrder.form.checkbox.discount"
                            defaultMessage=""
                          />
                        </Checkbox>
                      }
                    </Col>
                    {this.props.isRootDiscount ? (
                      <>
                        {this.state.rootDiscountList &&
                          this.state.rootDiscountList.length > 0
                          ? (this.state.rootDiscountList || []).map(
                            (rootDiscount, i) => {
                              return (
                                <>
                                  {/* <Col>
                                                                <span className="label" ><FormattedMessage id='supplier.purchaseOrder.form.totalDiscount.label' defaultMessage='' /></span>
                                                                <Tooltip placement="right" title={<FormattedMessage id='tooltip.rootDiscount' defaultMessage='' />} trigger="click">
                                                                    <i className="fa fa-question-circle-o pl5 cursor-pointer" />
                                                                </Tooltip>
                                                            </Col> */}

                                  <Col span={24}>

                                    <Input
                                      style={{ height: "38px" }}
                                      addonBefore={
                                        <Checkbox

                                          checked={rootDiscount.isTaxable ? true : false}
                                          onClick={(e) => {
                                            rootDiscount.isTaxable = e.target
                                              .checked
                                              ? 1
                                              : 0;
                                            let rootDiscountList =
                                              this.state.rootDiscountList;
                                            rootDiscountList[i] = rootDiscount;
                                            this.setState({
                                              rootDiscountList: rootDiscountList,
                                            });
                                            if (this.state.subtotal) {
                                              this.calculateSubtotal();
                                            }
                                          }}
                                        ></Checkbox>
                                      }
                                      addonAfter="%"
                                      type="number"
                                      placeholder="0.00"
                                      value={
                                        rootDiscount.rootDiscountPercent || ""
                                      }
                                      onChange={(e) => {
                                        rootDiscount.rootDiscountPercent =
                                          e.target.value;
                                        let rootDiscountList =
                                          this.state.rootDiscountList;
                                        rootDiscountList[i] = rootDiscount;
                                        this.setState({
                                          rootDiscountList: rootDiscountList,
                                        });
                                      }}
                                      onBlur={(e) => {
                                        if (this.state.subtotal) {
                                          this.calculateSubtotal();
                                        }
                                      }}
                                    />
                                  </Col>
                                    {/* <span className="mark" style={{position:'absolute', right:'0px', left:'auto'}}>%</span> */}
                                    {/* <div
                                      className="right1"
                                      style={{ right: "8px", top: "4px" }}
                                    >
                                      %
                                    </div> */}
                              

                                  {/* { rootDiscount.rootDiscountAmount ? <span>{(this.props.companyInfo || {}).currencyIcon ? <i className={(this.props.companyInfo || {}).currencyIcon}></i> : (this.props.companyInfo || {}).currencyCode + " "}{(Number(rootDiscount.rootDiscountAmount) + Number(rootDiscount.taxAmount||0))|| ""}</span>: ''}  */}

                                  {/* <div className="input-label-control" style={{width: '170px'}}>
                                                                        <span className="mark">{(this.props.companyInfo || {}).currencyIcon ? <i className={(this.props.companyInfo || {}).currencyIcon}></i> : (this.props.companyInfo || {}).currencyCode + " "}</span>
                                                                        <Input
                                                                            disabled
                                                                            placeholder="0.00"
                                                                            type='number'
                                                                            value={rootDiscount.rootDiscountAmount || ""}
                                                                            onChange={(e) => {
                                                                                rootDiscount.rootDiscountAmount= Number(e.target.value);
                                                                                let rootDiscountList = this.state.rootDiscountList;
                                                                                rootDiscountList[i] = rootDiscount;
                                                                                this.setState({
                                                                                    rootDiscountList: rootDiscountList
                                                                                })
                                                                            }}
                                                                            onBlur={(e) => {
                                                                                if (this.state.subtotal) {
                                                                                    this.calculateSubtotal();
                                                                                }
                                                                            }}
                                                                        />
                                                                    </div> */}

                                  {/* <Col span={3}>
                                                                <div className="input-group" style={{padding: '5px', width:'100%'}}>
                                                                    <Dropdown
                                                                        items={this.props.taxes}
                                                                        value={rootDiscount.taxName}
                                                                        placeholder={this.props.intl.formatMessage({ id: 'customer.salesQuote.form.gridHeader.tax' })}
                                                                        onSelect={(selectedOption, option) => {
                                                                            const selectedTax = find(this.props.taxes, { taxSingleRateId: Number(option.key) });
                                                                            rootDiscount.taxPercent = selectedTax.taxPercent;
                                                                            rootDiscount.taxName = selectedTax.taxName;
                                                                            rootDiscount.taxId= option.key;
                                                                            let rootDiscountList = this.state.rootDiscountList;
                                                                            rootDiscountList[i] = rootDiscount;
                                                                            this.setState({
                                                                                rootDiscountList: rootDiscountList
                                                                            });
                                                                            if (this.state.subtotal) {
                                                                                this.calculateSubtotal();
                                                                            }
                                                                        }}

                                                                        valueKeyName='taxNameDisplay'
                                                                        optionKeyName='taxSingleRateId'
                                                                        allowClear={true}
                                                                        onClear={() => {
                                                                            rootDiscount.tax = null;
                                                                            rootDiscount.taxName = null;
                                                                            let rootDiscountList = this.state.rootDiscountList;
                                                                            rootDiscountList[i] = rootDiscount;
                                                                            this.setState({
                                                                                rootDiscountList: rootDiscountList
                                                                            })
                                                                        }}
                                                                    />
                                                                </div>
                                                            </Col> */}

                                  <Col span={24}>

                                    <Input
                                      addonBefore={getCurrencySymbol(this.props.companyInfo.currencyCode)}
                                      style={{ height: "38px" }}
                                      disabled
                                      value={rootDiscount.rootDiscountAmount ? Number(
                                        rootDiscount.rootDiscountAmount
                                      ) +
                                        Number(
                                          rootDiscount.taxAmount || 0
                                        ) : " "}
                                    />

                                  </Col>
                                  <Col span={24}>
                                    <Dropdown
                                      items={this.props.allLedgerAccounts}
                                      value={rootDiscount.ledgerAccountName}
                                      placeholder={this.props.intl.formatMessage(
                                        {
                                          id: "selectLedger.text",
                                          defaultMessage: "",
                                        }
                                      )}
                                      onSelect={(selectedOption, option) => {
                                        rootDiscount.chartOfAccountDetailsId =
                                          selectedOption;
                                        rootDiscount.ledgerAccountName =
                                          option.name;
                                        let rootDiscountList =
                                          this.state.rootDiscountList;
                                        rootDiscountList[i] = rootDiscount;
                                        this.setState({
                                          rootDiscountList: rootDiscountList,
                                        });
                                      }}
                                      valueKeyName="ledgerAccountName"
                                      optionKeyName="chartOfAccountDetailsId"
                                      addressLineKey1="accountingEntry"
                                      allowClear={true}
                                      onClear={() => {
                                        rootDiscount.chartOfAccountDetailsId =
                                          null;
                                        rootDiscount.ledgerAccountName = null;
                                        let rootDiscountList =
                                          this.state.rootDiscountList;
                                        rootDiscountList[i] = rootDiscount;
                                        this.setState({
                                          rootDiscountList: rootDiscountList,
                                        });
                                      }}
                                      canAddNew={true}
                                      onAddNewClick={(props) => {
                                        this.newLedgerAccounts(this.props);
                                      }}
                                    />
                                  </Col>
                                  <Col span={12}>
                                    <i
                                      className="fa fa-trash fa-lg cursor-pointer"
                                      onClick={() => {
                                        let rootDiscountList =
                                          this.state.rootDiscountList;
                                        if (
                                          rootDiscountList &&
                                          rootDiscountList.length > 1
                                        ) {
                                          rootDiscountList.splice(i, 1);
                                        } else {
                                          rootDiscountList = [{}];
                                        }
                                        this.setState({
                                          rootDiscountList: rootDiscountList,
                                        });
                                        if (this.state.subtotal) {
                                          setTimeout(()=>{
                                            this.calculateSubtotal();
                                          },500)
                                        }
                                      }}
                                      type="delete"
                                    />
                                  </Col>
                                </>
                              );
                            }
                          )
                          : ""}
                        {this.state.rootDiscountList.length < 5 && (
                          <div
                            style={{
                              width: "100%",
                              textAlign: "right",
                              marginTop: "-21px",
                           
                            }}
                            
                            className="mb20, mr10"
                          >
                            <span
                              className="cursor-pointer"
                              onClick={() => {
                                let discountList = this.state.rootDiscountList;
                                discountList.push({});
                                this.setState({ rootDiscountList: discountList });

                              }}
                            >
                              <FormattedMessage id="add" />{" "}
                              {/* <FormattedMessage id="modal.txDetails.common.discount" /> */}
                            </span>
                          </div>
                        )}
                      </>
                    ) : (
                      ""
                    )}
                  </Row>
                </Col>

                <Col span={12}>
                  <Row gutter={[16, 8]}>
                    <Col span={24}>
                      <Checkbox
                        checked={this.props.isRootExpense}
                        onChange={(e) => {
                          this.props.updateState({
                            isRootExpense: e.target.checked,
                          });
                        }}
                      >
                        <FormattedMessage
                          id="supplier.purchaseOrder.form.checkbox.expense"
                          defaultMessage=""
                        />
                      </Checkbox>
                    </Col>

                    {this.props.isRootExpense ? (
                      <>
                        {this.state.rootExpenseList &&
                          this.state.rootExpenseList.length > 0
                          ? (this.state.rootExpenseList || []).map(
                            (rootExpense, i) => {
                              return (
                                <>
                                  {/*                                                     
                                                        <Col span={7} className="pt5">
                                                            <FormattedMessage id='supplier.purchaseOrder.form.totalExpense.label' defaultMessage='' />
                                                        </Col> */}

                                  {/* <Col span={16}>
                                                             <Dropdown
                                                    style={{ width: '100%' }}
                                                    items={this.props.expenseTypes}
                                                    valueKeyName='expenseType'
                                                    value={this.props.expenseId}
                                                    canAddNew={true}
                                                    canDelete={true}
                                                    allowClear={true}
                                                    onClear={() => {
                                                        this.props.updateState({
                                                            expenseId: undefined,
                                                            expensName: undefined
                                                        })
                                                    }}
                                                    deleteClickHandler={(payload) => {
                                                        this.deleteClickHandler(this.props, MODAL_TYPE.EXPENSE_TYPE, payload);
                                                    }}
                                                    onAddNewClick={(payload) => {
                                                        payload.formData = {
                                                            textBoxValue: payload.textEntered,
                                                            submittedOnce: false
                                                        }
                                                        this.handleNewItemAddition(this.props, payload, MODAL_TYPE.EXPENSE_TYPE)
                                                    }}
                                                    onSelect={(expenseId) => {
                                                        const selectedExpense = find(this.props.expenseTypes, { lkExpenseTypeId: Number(expenseId) })
                                                        this.props.updateState({
                                                            expenseId,
                                                            expensName: selectedExpense.expenseType

                                                        })
                                                    }}
                                                    optionKeyName='lkExpenseTypeId'
                                                /> 
                                                </Col>*/}

                                  {/* <Col span={24}>
                                    <div className="input-group">
                                      <div
                                        className="input-label-control"
                                        style={{
                                          width: "100%",
                                          position: "relative",
                                        }}
                                      >
                                        <span
                                          className="mark"
                                          style={{ height: "32px" }}
                                        >
                                          {(this.props.companyInfo || {})
                                            .currencyIcon ? (
                                            <i
                                              className={
                                                (this.props.companyInfo || {})
                                                  .currencyIcon
                                              }
                                            ></i>
                                          ) : (
                                            (this.props.companyInfo || {})
                                              .currencyCode + " "
                                          )}
                                        </span>
                                        <Input
                                          style={{ height: "32px" }}
                                          placeholder="0.00"
                                          value={rootExpense.expenseAmount}
                                          type="number"
                                          onChange={(e) => {
                                            rootExpense.expenseAmount = Number(
                                              e.target.value
                                            );

                                            let rootExpenseList =
                                              this.props.rootExpenseList;
                                            rootExpenseList[i] = rootExpense;
                                            this.setState(
                                              {
                                                rootExpenseList:
                                                  rootExpenseList,
                                              },
                                              () => {
                                                if (this.state.subtotal) {
                                                  this.calculateSubtotal();
                                                }
                                              }
                                            );

                                            this.props.updateState({
                                              rootExpenseList: rootExpenseList,
                                            });

                                           
                                          }}
                                        />
                                      </div>
                                      </div>
                                  </Col> */}
                                  <Col span={24}>
                                    <Input
                                      addonBefore={getCurrencySymbol(this.props.companyInfo.currencyCode)}
                                      style={{ height: "38px" }}
                                      placeholder="0.00"
                                      value={rootExpense.expenseAmount}
                                      type="number"
                                      onChange={(e) => {
                                        rootExpense.expenseAmount = Number(
                                          e.target.value
                                        );

                                        let rootExpenseList =
                                          this.props.rootExpenseList;
                                        rootExpenseList[i] = rootExpense;
                                        this.setState(
                                          {
                                            rootExpenseList:
                                              rootExpenseList,
                                          },
                                          () => {
                                            if (this.state.subtotal) {
                                              this.calculateSubtotal();
                                            }
                                          }
                                        );

                                        this.props.updateState({
                                          rootExpenseList: rootExpenseList,
                                        });
                                      }}
                                    />
                                  </Col>


                                  {/* <span>
                                                            {(rootExpense.selectedExpenseLedger && rootExpense.selectedExpenseLedger.isLedgerTaxable && rootExpense.expenseLedgerAccountTaxRate && rootExpense.expenseAmount) ? <span>
                                                                <span className="label">Tax </span> {Number((rootExpense.expenseAmount * (rootExpense.expenseLedgerAccountTaxRate || 0)) / 100).toFixed(2)}
                                                            </span>
                                                                : ''
                                                            }
                                                        </span> */}


                                  {/* <Col span={24}>
                                    {" "}
                                    <div
                                      className="price-box1"
                                      style={{ margin: "0px", padding: "0px" }}
                                    >
                                      <div className="icons2">Tax Amt.</div>
                                      <div 
                                      style={{paddingLeft:'70px'}}
                                      >
                                        {rootExpense.selectedExpenseLedger &&
                                        rootExpense.selectedExpenseLedger
                                          .isLedgerTaxable &&
                                        rootExpense.expenseLedgerAccountTaxRate &&
                                        rootExpense.expenseAmount ? (
                                          <span>
                                            {Number(
                                              (rootExpense.expenseAmount *
                                                (rootExpense.expenseLedgerAccountTaxRate ||
                                                  0)) /
                                                100
                                            ).toFixed(2)}
                                          </span>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    </div>{" "}
                                  </Col> */}
                                  <Col span={24}>
                                    <Input
                                      addonBefore="Tax Amt."
                                      style={{ height: "38px" }}
                                      value={rootExpense.selectedExpenseLedger &&
                                        rootExpense.selectedExpenseLedger.isLedgerTaxable &&
                                        rootExpense.expenseLedgerAccountTaxRate &&
                                        rootExpense.expenseAmount ? fixedDecimalAndFormateNumber(Number(
                                          (rootExpense.expenseAmount *
                                            (rootExpense.expenseLedgerAccountTaxRate || 0)) / 100
                                        ))
                                        : (
                                          ""
                                        )}

                                    />
                                  </Col>
                                  <Col span={24}>
                                    <Dropdown
                                      items={this.props.allLedgerAccounts}
                                      value={
                                        rootExpense.ledgerAccountName
                                      }
                                      placeholder={this.props.intl.formatMessage(
                                        {
                                          id: "selectLedger.text",
                                          defaultMessage: "",
                                        }
                                      )}
                                      onSelect={(selectedOption, option) => {
                                        const selectedExpenseLedger = find(
                                          this.props.allLedgerAccounts,
                                          {
                                            chartOfAccountDetailsId:
                                              Number(selectedOption),
                                          }
                                        );
                                        // this.setState({
                                        //     expenseLedgerAccountId: selectedOption,
                                        //     expenseLedgerAccountName: option.name,
                                        //     expenseLedgerAccountTaxRate: selectedExpenseLedger.taxSingleRatePercent || 0,
                                        //     selectedExpenseLedger
                                        // });
                                        rootExpense.chartOfAccountDetailsId =
                                          selectedOption;
                                        rootExpense.ledgerAccountName =
                                          option.name;
                                        rootExpense.expenseLedgerAccountTaxRate =
                                          selectedExpenseLedger.taxSingleRatePercent ||
                                          0;
                                        rootExpense.selectedExpenseLedger =
                                          selectedExpenseLedger;

                                        let rootExpenseList =
                                          this.props.rootExpenseList;
                                        rootExpenseList[i] = rootExpense;
                                        this.setState({
                                          rootExpenseList: rootExpenseList,
                                        });

                                        this.props.updateState({
                                          rootExpenseList: rootExpenseList,
                                        });
                                      }}
                                      valueKeyName="ledgerAccountName"
                                      optionKeyName="chartOfAccountDetailsId"
                                      addressLineKey1="accountingEntry"
                                      allowClear={true}
                                      onClear={() => {
                                        // this.setState({
                                        //     expenseLedgerAccountId: null,
                                        //     expenseLedgerAccountName: null,
                                        //     selectedExpenseLedger: null,
                                        //     expenseLedgerAccountTaxRate: null,
                                        // });
                                        rootExpense.chartOfAccountDetailsId =
                                          null;
                                        rootExpense.ledgerAccountName = null;
                                        rootExpense.expenseLedgerAccountTaxRate =
                                          null;
                                        rootExpense.selectedExpenseLedger =
                                          null;

                                        let rootExpenseList =
                                          this.props.rootExpenseList;
                                        rootExpenseList[i] = rootExpense;
                                        this.setState({
                                          rootExpenseList: rootExpenseList,
                                        });

                                        this.props.updateState({
                                          rootExpenseList: rootExpenseList,
                                        });
                                      }}
                                      canAddNew={true}
                                      onAddNewClick={(props) => {
                                        this.newLedgerAccounts(this.props);
                                      }}
                                    />

                                  </Col>
                                  <Col span={12}>
                                    <i
                                      className="fa fa-trash fa-lg cursor-pointer"
                                      onClick={() => {
                                        let rootExpenseList =
                                          this.props.rootExpenseList;
                                        if (
                                          rootExpenseList &&
                                          rootExpenseList.length > 1
                                        ) {
                                          rootExpenseList.splice(i, 1);
                                        } else {
                                          rootExpenseList = [{}];
                                        }
                                        this.setState({
                                          rootExpenseList: rootExpenseList,
                                        });
                                        if (this.state.subtotal) {
                                          setTimeout(()=>{
                                            this.calculateSubtotal();
                                          },500)
                                        }

                                        this.props.updateState({
                                          rootExpenseList: rootExpenseList,
                                        });
                                      }}
                                      type="delete"
                                    />
                                  </Col>
                                </>
                              );
                            }
                          )
                          : ""}

                        {this.props.rootExpenseList?.length < 5 && (
                          <div
                            style={{
                              width: "47%",
                              textAlign: "right",
                              padding: "6px"
                            }}

                          >
                            <span
                              onClick={() => {
                                let ExpenseList = this.state.rootExpenseList;
                                ExpenseList.push({});
                                this.setState({ rootExpenseList: ExpenseList });

                                this.props.updateState({
                                  rootExpenseList: ExpenseList,
                                });
                              }}
                              className="cursor-pointer"

                            >
                              <FormattedMessage id="add" />{" "}
                              {/* <FormattedMessage id="supplier.purchaseOrder.form.totalExpense.label" /> */}
                            </span>
                          </div>
                        )}
                      </>
                    ) : (
                      ""
                    )}
                  </Row>
                </Col>

              </Row>

              {/* <Row type="flex" justify="end" className="mb20">
                                                        <Col span={8}>
                                                            <span className="label"><FormattedMessage id='supplier.purchaseOrder.form.totalExpenseAmount.label' defaultMessage='' /></span>
                                                        </Col>

                                                        <Col span={8}>
                                                            <div className="input-group">
                                                                <div className="input-label-control">
                                                                    <span className="mark">
                                                                        {(this.props.companyInfo || {}).currencyIcon ? <i className={(this.props.companyInfo || {}).currencyIcon}></i> : (this.props.companyInfo || {}).currencyCode + " "}
                                                                    </span>
                                                                    <Input
                                                                        placeholder="0.00"
                                                                        value={rootExpense.expenseAmount}
                                                                        type='number'
                                                                        onChange={(e) => {
                                                                            rootExpense.expenseAmount = Number(e.target.value);
        
                                                                            let rootExpenseList = this.state.rootExpenseList;
                                                                            rootExpenseList[i] = rootExpense;
                                                                            this.setState({
                                                                                rootExpenseList: rootExpenseList
                                                                            },() => {
                                                                                if (this.state.subtotal) {
                                                                                    this.calculateSubtotal();
                                                                                }
                                                                            });
                                                                            
                                                                            // this.setState({
                                                                            //     expenseAmount: Number(e.target.value)
                                                                            // },
                                                                             
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </Col>

                                                        <Col span={8}>
                                                            {(rootExpense.selectedExpenseLedger && rootExpense.selectedExpenseLedger.isLedgerTaxable && rootExpense.expenseLedgerAccountTaxRate && rootExpense.expenseAmount) ? <div>
                                                                <span className="label">Tax </span> {Number((rootExpense.expenseAmount * (rootExpense.expenseLedgerAccountTaxRate || 0)) / 100).toFixed(2)}
                                                            </div>
                                                                : ''
                                                            }
                                                        </Col>
                                                    </Row> */}

              {/* {
                false && <Checkbox onChange={() => {
                  this.setState({ sectionVisible: { ...sectionVisible, tax: !sectionVisible.tax } })
                }}><FormattedMessage id='customer.salesInvoice.form.checkbox.tax'
                  defaultMessage='' />
                </Checkbox>
              } */}

              {/* <Row type="flex" justify="end">
                {
                  this.props.isRootDiscount
                    ?
                    <Fragment>
                      <Col span={8}>
                        <span className="label"><FormattedMessage id='customer.salesOrder.form.totalDiscount.label' defaultMessage='' /></span>
                      </Col>

                      <Col span={16}>
                        <div className="input-group">
                          <div className="input-label-control">
                            <span className="mark">%</span>
                            <Input
                              type='number'
                              placeholder="0.00"
                              value={this.state.rootDiscountPercent || ""}
                              onChange={(e) => {
                                this.setState({
                                  rootDiscountPercent: e.target.value
                                });
                              }}
                              onBlur={(e) => {
                                if (this.state.subtotal) {
                                  this.calculateSubtotal();
                                }
                              }
                              }
                            />
                          </div>
                          <div className="input-label-control">
                            <span className="mark">{(this.props.companyInfo || {}).currencyIcon ? <i className={(this.props.companyInfo || {}).currencyIcon}></i> : (this.props.companyInfo || {}).currencyCode + " "}</span>
                            <Input
                              disabled
                              placeholder="0.00"
                              type='number'
                              value={this.state.rootDiscountAmount || ""}
                              onChange={(e) => {
                                this.setState({
                                  rootDiscountAmount: Number(e.target.value)
                                });
                              }}
                              onBlur={(e) => {
                                if (this.state.subtotal) {
                                  this.calculateSubtotal();
                                }
                              }}
                            />
                          </div>
                        </div>
                      </Col>
                    </Fragment>
                    : ''
                }
              </Row>

              {
                sectionVisible.tax
                  ? <Fragment>
                    <Row type="flex" justify="end">
                      <Col span={8}>
                        <FormattedMessage id='customer.salesOrder.form.taxName.label' defaultMessage='' />
                      </Col>

                      <Col span={16}>
                        <Dropdown
                          items={this.props.taxes}
                          value={this.props.taxName}
                          onSelect={(selectedTax) => {
                            this.props.updateState({
                              taxName: selectedTax
                            })
                          }}
                          valueKeyName='description'
                          optionKeyName='taxSingleRateId'
                          allowClear={true}
                          onClear={() => {
                            this.props.updateState({
                              taxName: undefined
                            });
                            this.calculateSubtotal();
                          }}
                        />
                      </Col>
                    </Row>

                    <Row type="flex" justify="end">
                      <Col span={8}>
                        <span className="label"><FormattedMessage id='customer.salesOrder.form.tax.label' defaultMessage='' /></span>
                      </Col>

                      <Col span={16}>
                        <div className="input-group">
                          <div className="input-label-control">
                            <span className="mark">%</span>
                            <Input placeholder="0.00" />
                          </div>
                          <div className="input-label-control">
                            <span className="mark">{(this.props.companyInfo || {}).currencyIcon ? <i className={(this.props.companyInfo || {}).currencyIcon}></i> : (this.props.companyInfo || {}).currencyCode + " "}</span>
                            <Input placeholder="0.00" />
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Fragment>
                  : ''
              }

              {
                this.props.isRootExpense
                  ? <Fragment>
                    <Row type="flex" className='amount-summary' justify="end">
                      <Col span={8}>
                        <FormattedMessage id='customer.salesOrder.form.totalExpense.label' defaultMessage='' />
                      </Col>

                      <Col span={16}>
                        <Dropdown
                          style={{ width: '100%' }}
                          items={this.props.expenseTypes}
                          valueKeyName='expenseType'
                          value={this.props.expenseId}
                          canAddNew={true}
                          canDelete={true}
                          deleteClickHandler={(payload) => {
                            this.deleteClickHandler(this.props, MODAL_TYPE.EXPENSE_TYPE, payload);
                          }}
                          onAddNewClick={(payload) => {
                            payload.formData = {
                              textBoxValue: payload.textEntered,
                              submittedOnce: false
                            }
                            this.handleNewItemAddition(this.props, payload, MODAL_TYPE.EXPENSE_TYPE)
                          }}
                          onSelect={(expenseId) => {
                            const selectedExpense = find(this.props.expenseTypes, { lkExpenseTypeId: Number(expenseId) })
                            this.props.updateState({
                              expenseId,
                              expensName: selectedExpense.expenseType

                            })
                          }}
                          optionKeyName='lkExpenseTypeId'
                          allowClear={true}
                          onClear={() => {
                            this.props.updateState({
                              expenseType: undefined,
                              expenseId: undefined
                            })
                          }}
                        />
                      </Col>
                    </Row>

                    <Row type="flex" justify="end" className="mb20">
                      <Col span={8}>
                        <span className="label"><FormattedMessage id='customer.salesOrder.form.totalExpenseAmount.label' defaultMessage='' /></span>
                      </Col>

                      <Col span={16}>
                        <div className="input-group">
                          <div className="input-label-control">
                            <span className="mark">{(this.props.companyInfo || {}).currencyIcon ? <i className={(this.props.companyInfo || {}).currencyIcon}></i> : (this.props.companyInfo || {}).currencyCode + " "}</span>
                            <Input
                              placeholder="0.00"
                              type='number'
                              value={this.state.expenseAmount}
                              onChange={(e) => {
                                this.setState({
                                  expenseAmount: Number(e.target.value)
                                }, () => {
                                  if (this.state.subtotal) {
                                    this.calculateSubtotal();
                                  }
                                });
                              }}
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Fragment>
                  : ''
              } */}

              <div className="product-amount-values">
                <Row>
                  <Col span={15}>
                    <FormattedMessage
                      id="customer.salesOrder.form.subTotal.label"
                      defaultMessage=""
                    />
                  </Col>
                  <Col span={9}>
                    {(this.props.companyInfo || {}).currencyIcon ? (
                      <i
                        className={(this.props.companyInfo || {}).currencyIcon}
                      ></i>
                    ) : (
                      (this.props.companyInfo || {}).currencyCode + " "
                    )}
                    {fixedDecimalAndFormateNumber(
                      this.state.subtotalWithoutDiscount
                    )}
                  </Col>
                </Row>

                {this.state.totalDiscount === "0.00" ||
                  this.state.totalDiscount === 0 ? null : (
                  <Row>
                    <Col span={15}>
                      <FormattedMessage
                        id="customer.salesOrder.form.totalDiscount.label"
                        defaultMessage=""
                      />
                    </Col>
                    <Col span={9}>
                      -
                      {(this.props.companyInfo || {}).currencyIcon ? (
                        <i
                          className={(this.props.companyInfo || {}).currencyIcon}
                        ></i>
                      ) : (
                        (this.props.companyInfo || {}).currencyCode + " "
                      )}
                      {fixedDecimalAndFormateNumber(this.state.totalDiscount)}
                    </Col>
                  </Row>
                )}

                {/* 
                  {
                    false && this.state.totalTax == 0 ? null : <div className="amount-summary">
                        <div className="w-50">
                            <span className="title">Tax</span>
                        </div>
                        <div className="w-50-default">
                            {(this.props.companyInfo || {}).currencyIcon ? <i className={(this.props.companyInfo || {}).currencyIcon}></i> : (this.props.companyInfo || {}).currencyCode + " "}
                            {this.state.totalTax}
                        </div>
                    </div>
                  } 
              */}

                {itemLevelTaxes.length === 0
                  ? null
                  : itemLevelTaxes.map((taxObj, i) => {
                    if (isPlaceOfSupplySameAsRelationship) {
                    }
                    return (this.props.companyInfo || {}).countryName ===
                      "India" ? (
                      isPlaceOfSupplySameAsRelationship ? (
                        <div key={i}>
                          <Row>
                            <Col span={15}>
                              CGST @ {taxObj.taxPercent / 2}% on{" "}
                              {fixedDecimalNumber(taxObj.subtotal)}
                            </Col>
                            <Col span={9}>
                              {(this.props.companyInfo || {}).currencyIcon ? (
                                <i
                                  className={
                                    (this.props.companyInfo || {}).currencyIcon
                                  }
                                ></i>
                              ) : (
                                (this.props.companyInfo || {}).currencyCode +
                                " "
                              )}
                              {(Number(taxObj.taxAmount || 0) / 2).toFixed(2)}
                            </Col>
                          </Row>

                          <Row>
                            <Col span={15}>
                              <span className="title">
                                SGST @ {taxObj.taxPercent / 2}% on{" "}
                                {fixedDecimalNumber(taxObj.subtotal)}{" "}
                              </span>
                            </Col>
                            <Col span={9}>
                              {(this.props.companyInfo || {}).currencyIcon ? (
                                <i
                                  className={
                                    (this.props.companyInfo || {}).currencyIcon
                                  }
                                ></i>
                              ) : (
                                (this.props.companyInfo || {}).currencyCode +
                                " "
                              )}
                              {/* {(Number(taxObj.taxAmount || 0) / 2).toFixed(2)} */}
                              {(Number(taxObj.taxAmount || 0) / 2).toFixed(2)}

                            </Col>
                          </Row>
                        </div>
                      ) : (
                        <div key={i}>
                          <Row>
                            <Col span={15}>
                              <span className="title">
                                IGST @ {taxObj.taxPercent}% on{" "}
                                {fixedDecimalNumber(taxObj.subtotal)}{" "}
                              </span>
                            </Col>
                            <Col span={9}>
                              {(this.props.companyInfo || {}).currencyIcon ? (
                                <i
                                  className={
                                    (this.props.companyInfo || {}).currencyIcon
                                  }
                                ></i>
                              ) : (
                                (this.props.companyInfo || {}).currencyCode +
                                " "
                              )}
                              {Number(taxObj.taxAmount || 0).toFixed(2)}
                            </Col>
                          </Row>
                        </div>
                      )
                    ) : (
                      <div key={i}>
                        <Row>
                          <Col span={15}>
                            <span className="title">
                              {taxObj.taxName} @ {taxObj.taxPercent}% on{" "}
                              {fixedDecimalNumber(taxObj.subtotal)}{" "}
                            </span>
                          </Col>
                          <Col span={9}>
                            {(this.props.companyInfo || {}).currencyIcon ? (
                              <i
                                className={
                                  (this.props.companyInfo || {}).currencyIcon
                                }
                              ></i>
                            ) : (
                              (this.props.companyInfo || {}).currencyCode + " "
                            )}
                            {Number(taxObj.taxAmount || 0).toFixed(2)}
                          </Col>
                        </Row>
                      </div>
                    );
                  })}

                <Row>
                  <Col span={15}>
                    <span className="title">
                      <FormattedMessage
                        id="customer.salesOrder.form.totalAmount.label"
                        defaultMessage=""
                      />
                    </span>
                  </Col>
                  <Col span={9}>
                    {(this.props.companyInfo || {}).currencyIcon ? (
                      <i
                        className={(this.props.companyInfo || {}).currencyIcon}
                      ></i>
                    ) : (
                      (this.props.companyInfo || {}).currencyCode + " "
                    )}
                    {fixedDecimalAndFormateNumber(this.state.total)}
                  </Col>
                </Row>
              </div>
            </Col>
            <Col span={0}>
              {/* <div className="checkbox-control-group">
              {
                <Checkbox
                  checked={this.props.isRootDiscount}
                  onChange={(e) => {
                    this.props.updateState({
                      isRootDiscount: e.target.checked,
                    });
                  }}
                >
                  {" "}
                  <FormattedMessage
                    id="supplier.purchaseOrder.form.checkbox.discount"
                    defaultMessage=""
                  />
                </Checkbox>
              }
              {false && (
                <Checkbox
                  onChange={() => {
                    this.props.updateState({
                      sectionVisible: {
                        ...sectionVisible,
                        tax: !sectionVisible.tax,
                      },
                    });
                  }}
                >
                  <FormattedMessage
                    id="supplier.purchaseOrder.form.checkbox.tax"
                    defaultMessage=""
                  />
                </Checkbox>
              )}
              <Checkbox
                checked={this.props.isRootExpense}
                onChange={(e) => {
                  this.props.updateState({ isRootExpense: e.target.checked });
                }}
              >
                <FormattedMessage
                  id="supplier.purchaseOrder.form.checkbox.expense"
                  defaultMessage=""
                />
              </Checkbox>
            </div> */}

              {/* <div className="product-amount-details">
              <Row className="mb20" type="flex" justify="end">
                {this.props.isRootDiscount ? (
                  <Fragment>
                    <Col span={8}>
                      <span className="label">
                        <FormattedMessage
                          id="supplier.purchaseOrder.form.totalDiscount.label"
                          defaultMessage=""
                        />
                      </span>
                    </Col>

                    <Col span={16}>
                      <div className="input-group">
                        <div className="input-label-control">
                          <span className="mark">%</span>
                          <Input
                            type="number"
                            placeholder="0.00"
                            value={this.state.rootDiscountPercent || ""}
                            onChange={(e) => {
                              this.setState({
                                rootDiscountPercent: e.target.value,
                              });
                            }}
                            onBlur={(e) => {
                              if (this.state.subtotal) {
                                this.calculateSubtotal();
                              }
                            }}
                          />
                        </div>
                        <div className="input-label-control">
                          <span className="mark">
                            {(this.props.companyInfo || {}).currencyIcon ? (
                              <i
                                className={
                                  (this.props.companyInfo || {}).currencyIcon
                                }
                              ></i>
                            ) : (
                              (this.props.companyInfo || {}).currencyCode + " "
                            )}
                          </span>
                          <Input
                            disabled
                            placeholder="0.00"
                            type="number"
                            value={this.state.rootDiscountAmount || ""}
                            onChange={(e) => {
                              this.setState({
                                rootDiscountAmount: Number(e.target.value),
                              });
                            }}
                            onBlur={(e) => {
                              if (this.state.subtotal) {
                                this.calculateSubtotal();
                              }
                            }}
                          />
                        </div>
                      </div>
                    </Col>
                  </Fragment>
                ) : (
                  ""
                )}
              </Row>

              {sectionVisible.tax ? (
                <Fragment>
                  <Row type="flex" justify="end">
                    <Col span={8}>
                      <FormattedMessage
                        id="drawer.invoice.dropdown.taxName"
                        defaultMessage=""
                      />
                    </Col>

                    <Col span={16}>
                      <Dropdown
                        items={this.props.taxes}
                        value={this.props.taxName}
                        onSelect={(selectedTax) => {
                          this.props.updateState({
                            taxName: selectedTax,
                          });
                        }}
                        valueKeyName="description"
                        optionKeyName="taxSingleRateId"
                        allowClear={true}
                        onClear={() => {
                          this.props.updateState({ taxName: undefined });
                          this.calculateSubtotal();
                        }}
                      />
                    </Col>
                  </Row>

                  <Row type="flex" justify="end">
                    <Col span={8}>
                      <span className="label">
                        <FormattedMessage
                          id="supplier.purchaseOrder.form.totalTax.label"
                          defaultMessage=""
                        />
                      </span>
                    </Col>

                    <Col span={16}>
                      <div className="input-group">
                        <div className="input-label-control">
                          <span className="mark">%</span>
                          <Input placeholder="0.00" />
                        </div>
                        <div className="input-label-control">
                          <span className="mark">
                            {(this.props.companyInfo || {}).currencyIcon ? (
                              <i
                                className={
                                  (this.props.companyInfo || {}).currencyIcon
                                }
                              ></i>
                            ) : (
                              (this.props.companyInfo || {}).currencyCode + " "
                            )}
                          </span>
                          <Input placeholder="0.00" />
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Fragment>
              ) : (
                ""
              )}

              {this.props.isRootExpense ? (
                <Fragment>
                  <Row type="flex" className="amount-summary" justify="end">
                    <Col span={8}>
                      <FormattedMessage
                        id="supplier.purchaseOrder.form.totalExpense.label"
                        defaultMessage=""
                      />
                    </Col>

                    <Col span={16}>
                      <Dropdown
                        style={{ width: "100%" }}
                        items={this.props.expenseTypes}
                        valueKeyName="expenseType"
                        value={this.props.expenseId}
                        canAddNew={true}
                        canDelete={true}
                        allowClear={true}
                        onClear={() => {
                          this.props.updateState({
                            expenseId: undefined,
                            expensName: undefined,
                          });
                        }}
                        deleteClickHandler={(payload) => {
                          this.deleteClickHandler(
                            this.props,
                            MODAL_TYPE.EXPENSE_TYPE,
                            payload
                          );
                        }}
                        onAddNewClick={(payload) => {
                          payload.formData = {
                            textBoxValue: payload.textEntered,
                            submittedOnce: false,
                          };
                          this.handleNewItemAddition(
                            this.props,
                            payload,
                            MODAL_TYPE.EXPENSE_TYPE
                          );
                        }}
                        onSelect={(expenseId) => {
                          const selectedExpense = find(
                            this.props.expenseTypes,
                            { lkExpenseTypeId: Number(expenseId) }
                          );
                          this.props.updateState({
                            expenseId,
                            expensName: selectedExpense.expenseType,
                          });
                        }}
                        optionKeyName="lkExpenseTypeId"
                      />
                    </Col>
                  </Row>

                  <Row type="flex" justify="end" className="mb20">
                    <Col span={8}>
                      <span className="label">
                        <FormattedMessage
                          id="supplier.purchaseOrder.form.totalExpenseAmount.label"
                          defaultMessage=""
                        />
                      </span>
                    </Col>

                    <Col span={16}>
                      <div className="input-group">
                        <div className="input-label-control">
                          <span className="mark">
                            {(this.props.companyInfo || {}).currencyIcon ? (
                              <i
                                className={
                                  (this.props.companyInfo || {}).currencyIcon
                                }
                              ></i>
                            ) : (
                              (this.props.companyInfo || {}).currencyCode + " "
                            )}
                          </span>
                          <Input
                            placeholder="0.00"
                            type="number"
                            value={this.state.expenseAmount || ""}
                            onChange={(e) => {
                              this.setState(
                                {
                                  expenseAmount: Number(e.target.value),
                                },
                                () => {
                                  if (this.state.subtotal) {
                                    this.calculateSubtotal();
                                  }
                                }
                              );
                            }}
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Fragment>
              ) : (
                ""
              )}

              <div className="amount-summary">
                <Row>
                  <Col span={15}>
                    <span className="title">
                      <FormattedMessage
                        id="supplier.purchaseOrder.form.subTotal.label"
                        defaultMessage=""
                      />
                    </span>
                  </Col>
                  <Col span={9}>
                    {(this.props.companyInfo || {}).currencyIcon ? (
                      <i
                        className={(this.props.companyInfo || {}).currencyIcon}
                      ></i>
                    ) : (
                      (this.props.companyInfo || {}).currencyCode + " "
                    )}
                    {fixedDecimalAndFormateNumber(
                      this.state.subtotalWithoutDiscount
                    )}
                  </Col>
                </Row>
              </div>

              {this.state.totalDiscount === "0.00" ||
              this.state.totalDiscount === 0 ? null : (
                <div className="amount-summary">
                  <Row>
                    <Col span={15}>
                      <span className="title">
                        <FormattedMessage
                          id="supplier.purchaseOrder.form.totalDiscount.label"
                          defaultMessage=""
                        />
                      </span>
                    </Col>
                    <Col span={9}>
                      -
                      {(this.props.companyInfo || {}).currencyIcon ? (
                        <i
                          className={
                            (this.props.companyInfo || {}).currencyIcon
                          }
                        ></i>
                      ) : (
                        (this.props.companyInfo || {}).currencyCode + " "
                      )}
                      {this.state.totalDiscount}
                    </Col>
                  </Row>
                </div>
              )}

              {/* {
                                false && this.state.totalTax == 0 ? null : <div className="amount-summary">
                                    <div className="w-50">
                                        <span className="title">Tax</span>
                                    </div>
                                    <div className="w-50-default">
                                        {(this.props.companyInfo || {}).currencyIcon ? <i className={(this.props.companyInfo || {}).currencyIcon}></i> : (this.props.companyInfo || {}).currencyCode + " "}
                                        {this.state.totalTax}
                                    </div>
                                </div>
                            } */}

              {/* {itemLevelTaxes.length === 0
                ? null
                : itemLevelTaxes.map((taxObj, i) => {
                    if (isPlaceOfSupplySameAsRelationship) {
                    }
                    return (this.props.companyInfo || {}).countryName ===
                      "India" ? (
                      isPlaceOfSupplySameAsRelationship ? (
                        <div key={i}>
                          <div className="amount-summary">
                            <Row>
                              <Col span={15}>
                                <span className="title">
                                  <FormattedMessage
                                    id="common.CGST"
                                    defaultMessage=""
                                  />{" "}
                                  {taxObj.taxPercent / 2}% on{" "}
                                  {Number(taxObj.subtotal).toFixed(2)}{" "}
                                </span>
                              </Col>
                              <Col span={9}>
                                {(this.props.companyInfo || {}).currencyIcon ? (
                                  <i
                                    className={
                                      (this.props.companyInfo || {})
                                        .currencyIcon
                                    }
                                  ></i>
                                ) : (
                                  (this.props.companyInfo || {}).currencyCode +
                                  " "
                                )}
                                {(Number(taxObj.taxAmount || 0) / 2).toFixed(2)}
                              </Col>
                            </Row>
                          </div>

                          <div className="amount-summary">
                            <Row>
                              <Col span={15}>
                                <span className="title">
                                  <FormattedMessage
                                    id="common.SGST"
                                    defaultMessage=""
                                  />{" "}
                                  {taxObj.taxPercent / 2}% on {taxObj.subtotal}{" "}
                                </span>
                              </Col>
                              <Col span={9}>
                                {(this.props.companyInfo || {}).currencyIcon ? (
                                  <i
                                    className={
                                      (this.props.companyInfo || {})
                                        .currencyIcon
                                    }
                                  ></i>
                                ) : (
                                  (this.props.companyInfo || {}).currencyCode +
                                  " "
                                )}
                                {(Number(taxObj.taxAmount || 0) / 2).toFixed(2)}
                              </Col>
                            </Row>
                          </div>
                        </div>
                      ) : (
                        <div key={i}>
                          <div className="amount-summary">
                            <Row>
                              <Col span={15}>
                                <span className="title">
                                  <FormattedMessage
                                    id="common.IGST"
                                    defaultMessage=""
                                  />{" "}
                                  {taxObj.taxPercent}% on{" "}
                                  {Number(taxObj.subtotal).toFixed(2)}{" "}
                                </span>
                              </Col>
                              <Col span={9}>
                                {(this.props.companyInfo || {}).currencyIcon ? (
                                  <i
                                    className={
                                      (this.props.companyInfo || {})
                                        .currencyIcon
                                    }
                                  ></i>
                                ) : (
                                  (this.props.companyInfo || {}).currencyCode +
                                  " "
                                )}
                                {Number(taxObj.taxAmount || 0).toFixed(2)}
                              </Col>
                            </Row>
                          </div>
                        </div>
                      )
                    ) : (
                      <div key={i}>
                        <div className="amount-summary">
                          <Row>
                            <Col span={15}>
                              <span className="title">
                                {taxObj.taxName} @ {taxObj.taxPercent}% on{" "}
                                {Number(taxObj.subtotal).toFixed(2)}{" "}
                              </span>
                            </Col>
                            <Col span={9}>
                              {(this.props.companyInfo || {}).currencyIcon ? (
                                <i
                                  className={
                                    (this.props.companyInfo || {}).currencyIcon
                                  }
                                ></i>
                              ) : (
                                (this.props.companyInfo || {}).currencyCode +
                                " "
                              )}
                              {Number(taxObj.taxAmount || 0).toFixed(2)}
                            </Col>
                          </Row>
                        </div>
                      </div>
                    );
                  })} */}

              {/* <div className="amount-summary">
                <Row>
                  <Col span={15}>
                    <span className="title">
                      <FormattedMessage
                        id="supplier.purchaseOrder.form.totalAmount.label"
                        defaultMessage=""
                      />
                    </span>
                  </Col>
                  <Col span={9}>
                    {(this.props.companyInfo || {}).currencyIcon ? (
                      <i
                        className={(this.props.companyInfo || {}).currencyIcon}
                      ></i>
                    ) : (
                      (this.props.companyInfo || {}).currencyCode + " "
                    )}
                    {fixedDecimalAndFormateNumber(this.state.total)}
                  </Col>
                </Row>
              </div> */}
              {/* </div>  */}
            </Col>
          </Row>

          {/* <TextArea value={this.props.footer} maxLength={500} className="add-footer-textarea" onChange={(e) => {
                    this.props.updateState({
                        footer: e.target.value
                    });
                }}
                    rows={1} placeholder="Add Footer Here"></TextArea> */}
          <CKEditor
            type="inline"
            className="add-footer-textarea"
            key={`${"footer"}`}
            data={this.props.footer}
            onInit={(editor) => {
              this.editor = editor;
            }}
            onChange={(event) => {
              const data = event.editor.getData();
              this.props.updateState({ footer: data });
            }}
            config={{ ...CUSTOM_CONFIGURATION, placeholder: "Add Footer Here" }}
          />

          <div className="footer">
            <div className="left-fItems">
              <Button
                className="grey-button"
                onClick={() => {
                  this.props.showModal({
                    modalBody: <FormattedMessage id="modal.cancel.confirmation" defaultMessage=""/>,
                    footer:{submit:{intlId:"button.close.label"}},
                    handleSubmit: () => {
                      this.props.popModalFromStack();
                    }
                  })
                }}
              >
                <FormattedMessage id="button.cancel.label" defaultMessage="" />
              </Button>
            </div>

            <div className="right-fItems">
              {!this.props.update && (
                <Button
                  className="ant-btn ml10"
                  loading={this.props.saveLoading}
                  disabled={this.props.saveLoading}
                  onClick={() => {
                    createPO(true);
                  }}
                >
                  <FormattedMessage id="button.draft.label" defaultMessage="" />
                </Button>
              )}
              <Button
                className="ant-btn-primary mr10"
                loading={this.props.saveLoading}
                disabled={this.props.saveLoading}
                onClick={() => {
                  createPO();
                }}
              >
                <FormattedMessage id="button.save.label" defaultMessage="" />
              </Button>
            </div>
          </div>
        </Skeleton>
      </Fragment>
    );
  }
}

export default injectIntl(ProductDetails);
