import React, { useMemo } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import "../leads.scss";
import PageBreadcrumb from "../../PageBreadcrumb";
import { Menu, Switch, Skeleton, Checkbox } from "antd";
import FormbuilderComp from "../../../containers/leads/Formbuilder";
import { getMomentDateForUIReadOnly, getPermissionData, sortTable } from "../../../utils";
import { MoreOutlined } from "@ant-design/icons";
import DropdownAction from "antd/lib/dropdown";
import { CONSTANTS, ICONS, MODULE_CODED_VALUES } from "../../../static/constants";
import PageHeader from "../../Common/pageHeader";

const LeadCampaignListingComp = (props) => {
  const {

    updateState = "false",
    companyInfo,
    userInfo,
    pageNumber = 1,
    pageSize = 25,
    crmCampaignList,
    leadCampaignsCount,
    dir = 0,
    permissions
  } = props;
  const primaryPerm = (permissions && permissions.primary) || [];
  const permissionsData = useMemo(()=>{
    return getPermissionData(primaryPerm ,  MODULE_CODED_VALUES.LEAD_FROM_SETTING);
  },[])

  const breadCrumbList = [
    {
      name: <FormattedMessage id="sidebar.menuItems.Leads" defaultMessage="" />,
    },
    {
      name: (
        <FormattedMessage id="sidebar.menuItems.LeadSetting" defaultMessage="" />
      ),
    },
    {
      name: (
        <FormattedMessage id="sidebar.menuItems.Lead.Campaigns" defaultMessage="" />
      ),
    },
  ];

  const sortColumn = (e) => {
    let cellIndex = e.target.cellIndex;
    if (!cellIndex) {
      cellIndex = e.target.parentElement.cellIndex;
    }
    if (!cellIndex) {
      cellIndex = e.target.parentElement.parentElement.cellIndex;
    }
    sortTable(document.getElementById("lead-form-table"), cellIndex, 1 - dir);
  };

 

  const actionMenu = (e) => {
    return (
      <Menu className="row-action-dropdown">
        <Menu.Item key="0" value="title">
          Actions
        </Menu.Item>
        <Menu.Divider />
            {
              permissionsData?.update ? <Menu.Item
              key="1"
              value="edit"
              onClick={() => {
              const campaignData = {data:e,isUpdate:true}
                openCreateDrawer(campaignData);
              }}
            >
              <i className={ICONS.EDIT} /> Edit
            </Menu.Item>: ""
            }
        

        {/* <Menu.Item
          key="2"
          value="delete"
        //   onClick={() => {
        //     props.showModal({
        //       modalBody: "Are you sure want to delete?",
        //       handleSubmit: () => {
        //         props.deleteCrmLeadFormListItem(e, props);
        //       },
        //     });
        //   }}
        >
          <i className={ICONS.DELETE} /> Delete
        </Menu.Item> */}
      </Menu>
    );
  };


  const openCreateDrawer = (campaignData) => {
       props.updateState({
        leadCompaignDrawer:true,
        campaignData
       })
  };

  const loadPageData = (pageNumber, pageSize) => {
    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      relationshipEmployeeId: userInfo.relationshipEmployeeId,
      pageNumber: pageNumber || 1,
      pageSize: pageSize || 200,
      formId: (props.selectedForm || {}).registrationFormSettingId || 0,
    };
    // props.getRegisteredMemberByFormId(payload);
    // props.getRegisteredMemberCount(payload);
  };

  const handlePageSizeChange = (pageNumber, pageSize, props) => {

    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      relationshipEmployeeId: userInfo.relationshipEmployeeId,
      pageNumber: pageNumber || 1,
      pageSize: pageSize || 200,
      formId: (props.selectedForm || {}).registrationFormSettingId || 0,
    };

    props.resetPaginatedData();
    props.updateState({ pageSize: pageSize, pageNumber: pageNumber });
    props.getRegisteredMemberByFormId(payload);
    props.getRegisteredMemberCount(payload);
  };


  return (
    <div className="lead-form">

<PageHeader {...props}
        pageName="listing.pageHeading.campaign"
        breadCrumbList={breadCrumbList}
        canCreate={true}
        onCreate={openCreateDrawer}
        moduleCode = {MODULE_CODED_VALUES.LEAD_FROM_SETTING}
      
        />
     
      <div className="view-container">
        <div className="view-container-actions">
          <div className="left-actions">
            <div className="table-heading">
              <FormattedMessage id="listing.tableHeading.campaign" defaultMessage="" />
            </div>
            {props.parentCount ? (
              <>
                <div className="vertical-sep" />
                <div>{props.parentCount}</div>
              </>
            ) : (
              ""
            )}
          </div>
          <div className="right-actions">

          </div>
        </div>
        <div className="table-container">
        <Skeleton loading={props.listLoading} paragraph={{ rows: 15 }} active>
          <table id="lead-form-table">
            <thead>
              <tr>
                <th width='50px'>
                  <Checkbox key={'h'}/>
                </th>
                <th width='50px'>S.No.</th>
                <th onClick={sortColumn}>
                Campaign <i className={ICONS.SORT}></i>
                </th>
                <th onClick={sortColumn}>
                Source <i className={ICONS.SORT}></i>
                </th>
                <th onClick={sortColumn}>
                UTM <i className={ICONS.SORT}></i>
                </th>
                <th onClick={sortColumn}>
                Date<i className={ICONS.SORT}></i>
                </th>

                <th onClick={sortColumn}>
                  Status <i className={ICONS.SORT}></i>
                </th>
                {
                  (permissionsData?.delete || permissionsData?.update ) ?  <th width="6%">Action</th>: ""
                }
               
              </tr>
            </thead>
            <tbody>
              {crmCampaignList && (crmCampaignList[pageNumber || 1] || []).map((e, i) => {
                return (
                  <tr key={"std" + i}>
                    <td>
                      <Checkbox key={i}/>
                    </td>
                    <td>{(pageNumber - 1) * pageSize + Number(i + 1)}</td>
                    <td>{e.campaignName || <div className="empty-data"></div>}</td>
                    <td>{e.leadSourceName || <div className="empty-data"></div>}</td>
                    <td>{e.utmCompaign || 
                    <div className="empty-data"></div>
                    }</td>
                    <td>
                      {e.dateCreated
                        ? getMomentDateForUIReadOnly({
                            date: e.dateCreated,
                            format: CONSTANTS.TABLE_DATE_FORMAT,
                          })
                        :
                        <div className="empty-data"></div>                       
                        }
                    </td>
                    <td>
                      <div className="status-toggle">
                        <Switch
                          key={`stdc`+i}
                          disabled={!(permissionsData?.update)}
                          checked={e.isActive ? true : false}
                          onChange={() => {
                            props.createCrmLeadCampaign(
                              { ...e, isActive: e.isActive === 0 ? 1 : 0 },
                              props
                            );
                          }}
                        />
                      </div>
                    </td>
                    {
                      (permissionsData?.delete || permissionsData?.update ) ? <td style={{ paddingLeft: "50px" }}>
                      <div className="action-icon">
                        <DropdownAction
                          overlay={actionMenu(e)}
                          trigger={["click"]}
                        >
                          <i className={ICONS.MORE} />
                        </DropdownAction>
                      </div>
                    </td>: ""
                    }
                    
                  </tr>
                );
                // })
              })}
            </tbody>
          </table>
        </Skeleton>
        </div>

        <div className="footer">
          <div className="f-left"></div>
          <div className="f-right">
            {/* <Pagination
              size="small"
              total={crmLeadFormListCount}
              showTotal={showTotal}
              defaultPageSize={props.pageSize}
              showSizeChanger
              pageSizeOptions={[10, 25, 50, 100, 200]}
              onChange={(pageNumber, pageSize) => {
                loadPageData(pageNumber, pageSize);
                updateState({ pageSize: pageSize, pageNumber: pageNumber });
              }}
              onShowSizeChange={(pageNumber, pageSize) => {
                handlePageSizeChange(pageNumber || 1, pageSize, props);
              }}
            /> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default injectIntl(LeadCampaignListingComp);
