import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import CreditMemoDetailComponent from '../../../../../components/modal/modalBody/customer/CreditMemoDetail';
import { fetchCreditMemoDetail, uploadStamp, deleteStamp } from './action'
import { fetchDataIfNeeded } from '../../../../../utils'
import { generatePDF, printPDF, downloadFileFromBucket } from '../../../../../actions/downloadFileAction';
import EmailComponent from '../../../../../containers/drawer/email';
import { deleteAttectedFile } from '../../../../../actions/commonActions';

class CreditMemoDetail extends Component {
    constructor(props){
        super(props);
        this.state= {
            emailDrawerVisible: false,
            subject: '',
            emailBody: '',
        }
    }
    componentWillMount() {
        const companyInfo = this.props.companyInfo || {};
        const payload = { relationshipId: companyInfo.relationshipId, customerId: this.props.creditMemoPayload.customerId, creditMemoMasterId: this.props.creditMemoPayload.creditMemoMasterId };
        fetchDataIfNeeded('fetchCreditMemoDetail', 'creditMemoDetail', this.props, payload, true);

    }

    render() {
        return <div>
            {this.state.emailDrawerVisible && <EmailComponent {...this.state} {...this.props} updateState={(data) => { this.setState(data) }} />}
            <CreditMemoDetailComponent {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
        </div>
    }

}


const mapStateToProps = (state) => {
    return {
        companyInfo: state.common.companyInfo,
        relationshipId: state.common.companyInfo.relationshipId,
        creditMemoDetail: state.creditMemo.creditMemoDetail,
        detailLoading: state.common.detailLoading,
        permissions: state.auth.permissions


    }
  }

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchCreditMemoDetail,
    downloadFileFromBucket,
    generatePDF, printPDF,
    uploadStamp, 
    deleteStamp,
    deleteAttectedFile
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CreditMemoDetail);