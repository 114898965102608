import React, { useMemo } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Row, Col } from 'antd';
import img2 from '../../assets/images/Userstu.png';
import img5 from '../../assets/images/Userdue.png';
import img4 from '../../assets/images/Userpaid.png';
import img3 from '../../assets/images/UserStaff.png';
import img1 from '../../assets/images/Usernewstu.png';
import TileIndex from '../tiles/tilesIndex';
import { ICONS, MODULE_CODED_VALUES } from '../../static/constants';
import { Dropdown } from '../general/Dropdown';
import { fixedDecimalAndFormateNumber, fixedDecimalNumber, getCurrencySymbol, getPermissionData } from '../../utils';
import img6 from '../../assets/images/profit.png';
import img7 from '../../assets/images/loss.png';
import img8 from "../../assets/images/User (5).jpg";
import img9 from "../../assets/images/User (4).jpg";
import Sales from './sales';
import Purchase from './purchase';
import Attendance from './attendance';
import RecentActivity from './recentActivity';
import TopCustomer from './topCustomer';
import RecentNotices from '../schoolManagement/schoolDashboard/recentNotices';


const ErpDashboard = (props) => {
  const { companyInfo, permissions } = props;
  const primaryPerm = (permissions && permissions.primary) || [];

  const salesPermissionsData = useMemo(() => {
    return getPermissionData(primaryPerm, MODULE_CODED_VALUES.SALES_INVOICE)
  }, [])
  const purchasePermissionsData = useMemo(() => {
    return getPermissionData(primaryPerm, MODULE_CODED_VALUES.PURCHASE_INVOICE)
  }, [])
  const customerPermissionsData = useMemo(() => {
    return getPermissionData(primaryPerm, MODULE_CODED_VALUES.CUSTOMERS)
  }, [])
  const noticePermissionsData = useMemo(()=>{
    return getPermissionData(primaryPerm, MODULE_CODED_VALUES.SCHOOL_BROADCAST_MESSAGE )
  },[])
  const getTotalCustomerDue = () => {

    let total = 0;
    if (
      props.customerUnpaidInvoices &&
      Array.isArray(props.customerUnpaidInvoices[1])
    ) {
      props.customerUnpaidInvoices[1].forEach(function (d) {
        // Ensure d.invoiceDueAmount is a number, use 0 as default if not
        total += (Number(d.invoiceTotalAmount || 0) - Number(d.totalPaymentReceived || 0));
      });
    }
    return total
      .toFixed(2)

  };
  const getTotalSupplierDue = () => {
    let total = 0;
    if (
      props.supplierUnpaidInvoices &&
      props.supplierUnpaidInvoices[1]
    ) {
      props.supplierUnpaidInvoices[1].forEach(function (d) {
        total += (Number(d.invoiceTotalAmount || 0) - Number(d.totalPaymentReceived || 0));
      });
    }
    return total
      .toFixed(2)

  };
  const getAmountByLedgerGroup = (groupName, category, type, toReduceType) => {
    let randomTotalAmount = 0;

    for (var i = 0; i < props.allLedgerAccounts.length; i++) {
      if (
        props.allLedgerAccounts[i].categoryGroupName === groupName &&
        props.allLedgerAccounts[i].categoryName === category &&
        ((props.allLedgerAccounts[i].nature &&
          (props.allLedgerAccounts[i].nature || "").toUpperCase()) ||
          (props.allLedgerAccounts[i].accountingEntry || "").toUpperCase()) !==
        toReduceType
      ) {
        randomTotalAmount =
          randomTotalAmount + props.allLedgerAccounts[i].amountDifference;
      } else if (
        props.allLedgerAccounts[i].categoryGroupName === groupName &&
        props.allLedgerAccounts[i].categoryName === category &&
        ((props.allLedgerAccounts[i].nature &&
          (props.allLedgerAccounts[i].nature || "").toUpperCase()) ||
          (props.allLedgerAccounts[i].accountingEntry || "").toUpperCase()) ===
        toReduceType
      ) {
        randomTotalAmount =
          randomTotalAmount - props.allLedgerAccounts[i].amountDifference;
      }
    }

    return parseFloat((randomTotalAmount || 0).toFixed(2));
  };

  const getTotal = (type) => {
    let totalAmount = 0;
    if (!props.allLedgerAccounts && !props.allLedgerAccounts.length) {
      return;
    }
    let randomTotalAmount = 0;
    switch (type) {
      case "Equity and Liabilities": {
        for (let i = 0; i < props.allLedgerAccounts.length; i++) {
          if (
            (props.allLedgerAccounts[i].categoryName ===
              "Shareholder's Funds" ||
              props.allLedgerAccounts[i].categoryName ===
              "Non Current Liabilities" ||
              props.allLedgerAccounts[i].categoryName ===
              "Current Liabilities") &&
            ((props.allLedgerAccounts[i].nature &&
              (props.allLedgerAccounts[i].nature || "").toUpperCase()) ||
              (props.allLedgerAccounts[i].accountingEntry || "").toUpperCase()) ===
            "CREDIT"
          ) {
            totalAmount =
              totalAmount + props.allLedgerAccounts[i].amountDifference;
            randomTotalAmount = totalAmount.toFixed(2);
          } else if (
            (props.allLedgerAccounts[i].categoryName ===
              "Shareholder's Funds" ||
              props.allLedgerAccounts[i].categoryName ===
              "Non Current Liabilities" ||
              props.allLedgerAccounts[i].categoryName ===
              "Current Liabilities") &&
            ((props.allLedgerAccounts[i].nature &&
              (props.allLedgerAccounts[i].nature || "").toUpperCase()) ||
              (props.allLedgerAccounts[i].accountingEntry || "").toUpperCase()) ===
            "DEBIT"
          ) {
            totalAmount =
              totalAmount - props.allLedgerAccounts[i].amountDifference;
            randomTotalAmount = totalAmount.toFixed(2);
          }
        }
        break;
      }
      case "Assets": {
        for (let i = 0; i < props.allLedgerAccounts.length; i++) {
          if (
            (props.allLedgerAccounts[i].categoryName ===
              "Non - Current Assets" ||
              props.allLedgerAccounts[i].categoryName ===
              "Current Assets") &&
            ((props.allLedgerAccounts[i].nature &&
              (props.allLedgerAccounts[i].nature || "").toUpperCase()) ||
              (props.allLedgerAccounts[i].accountingEntry || "").toUpperCase()) ===
            "DEBIT"
          ) {
            totalAmount =
              totalAmount + props.allLedgerAccounts[i].amountDifference;
            randomTotalAmount = totalAmount.toFixed(2);
          } else if (
            (props.allLedgerAccounts[i].categoryName ===
              "Non - Current Assets" ||
              props.allLedgerAccounts[i].categoryName ===
              "Current Assets") &&
            ((props.allLedgerAccounts[i].nature &&
              (props.allLedgerAccounts[i].nature || "").toUpperCase()) ||
              (props.allLedgerAccounts[i].accountingEntry || "").toUpperCase()) ===
            "CREDIT"
          ) {
            totalAmount =
              totalAmount - props.allLedgerAccounts[i].amountDifference;
            randomTotalAmount = totalAmount.toFixed(2);
          }
        }
        break;
      }
      case "Expenses": {
        for (let i = 0; i < props.allLedgerAccounts.length; i++) {
          if (
            (props.allLedgerAccounts[i].categoryName ===
              "Cost of Materials" ||
              props.allLedgerAccounts[i].categoryName ===
              "Employee Benefit Expense" ||
              props.allLedgerAccounts[i].categoryName ===
              "Other Expenses" ||
              props.allLedgerAccounts[i].categoryName === "Taxes" ||
              props.allLedgerAccounts[i].categoryName ===
              "Finance Cost") &&
            ((props.allLedgerAccounts[i].nature &&
              (props.allLedgerAccounts[i].nature || "").toUpperCase()) ||
              (props.allLedgerAccounts[i].accountingEntry || "").toUpperCase()) ===
            "DEBIT"
          ) {
            totalAmount =
              totalAmount + props.allLedgerAccounts[i].amountDifference;
            randomTotalAmount = totalAmount.toFixed(2);
          } else if (
            (props.allLedgerAccounts[i].categoryName ===
              "Cost of Materials" ||
              props.allLedgerAccounts[i].categoryName ===
              "Employee Benefit Expense" ||
              props.allLedgerAccounts[i].categoryName ===
              "Other Expenses" ||
              props.allLedgerAccounts[i].categoryName === "Taxes" ||
              props.allLedgerAccounts[i].categoryName ===
              "Finance Cost") &&
            ((props.allLedgerAccounts[i].nature &&
              (props.allLedgerAccounts[i].nature || "").toUpperCase()) ||
              (props.allLedgerAccounts[i].accountingEntry || "").toUpperCase()) ===
            "CREDIT"
          ) {
            totalAmount =
              totalAmount - props.allLedgerAccounts[i].amountDifference;
            randomTotalAmount = totalAmount.toFixed(2);
          }
        }
        break;
      }
      case "Income": {
        for (let i = 0; i < props.allLedgerAccounts.length; i++) {
          if (
            (props.allLedgerAccounts[i].categoryName ===
              "Revenue from Operations" ||
              props.allLedgerAccounts[i].categoryName ===
              "Other Income") &&
            ((props.allLedgerAccounts[i].nature &&
              (props.allLedgerAccounts[i].nature || "").toUpperCase()) ||
              (props.allLedgerAccounts[i].accountingEntry || "").toUpperCase()) ===
            "CREDIT"
          ) {
            totalAmount =
              totalAmount + props.allLedgerAccounts[i].amountDifference;
            randomTotalAmount = totalAmount.toFixed(2);
          } else if (
            (props.allLedgerAccounts[i].categoryName ===
              "Revenue from Operations" ||
              props.allLedgerAccounts[i].categoryName ===
              "Other Income") &&
            ((props.allLedgerAccounts[i].nature &&
              (props.allLedgerAccounts[i].nature || "").toUpperCase()) ||
              (props.allLedgerAccounts[i].accountingEntry || "").toUpperCase()) ===
            "DEBIT"
          ) {
            totalAmount =
              totalAmount - props.allLedgerAccounts[i].amountDifference;
            randomTotalAmount = totalAmount.toFixed(2);
          }
        }
        break;
      }
      default:
        break;
    }

    return randomTotalAmount;
  };
  const tilesData = [{
    footer: <FormattedMessage id='tiles.customerDue' />,
    count: getTotalCustomerDue(),
    antIcon: <img src={img2} alt="" />,
    color: 'tile1',
    moduleCode: MODULE_CODED_VALUES.CUSTOMERS
  },
  {
    footer: <FormattedMessage id='tiles.supplierDue' />,
    count: getTotalSupplierDue(),
    antIcon: <img src={img1} alt="" />,
    color: 'tile2',
    moduleCode: MODULE_CODED_VALUES.SUPPLIERS
  },
  {
    footer: <FormattedMessage id='tiles.profit' />,
    count: fixedDecimalAndFormateNumber(
      getAmountByLedgerGroup(
        "Income/Gain",
        "Revenue from Operations",
        "amount",
        "DEBIT"
      ) +
      getAmountByLedgerGroup(
        "Income/Gain",
        "Other Income",
        "amount",
        "DEBIT"
      ) || 0),
    antIcon: <img src={img6} alt="" />,
    color: 'tile3',
    moduleCode: MODULE_CODED_VALUES.PROFIT_LOSS
  },

  {
    footer: <FormattedMessage id='tiles.loss' />,
    count: fixedDecimalAndFormateNumber(
      getAmountByLedgerGroup(
        "Expenses/Loss",
        "Other Expenses",
        "amount",
        "DEBIT"
      ) || 0),
    antIcon: <img src={img7} alt="" />,
    currencyIcon: companyInfo.currencyIcon,
    color: 'tile4',
    moduleCode: MODULE_CODED_VALUES.PROFIT_LOSS
  },
  {
    footer: <FormattedMessage id='tiles.assets' />,
    count: (fixedDecimalNumber(getTotal("Assets"))),
    antIcon: <img src={img9} alt="" />,
    currencyIcon: companyInfo.currencyIcon,
    color: 'tile5',
    moduleCode: MODULE_CODED_VALUES.BALANCE_SHEET
  },
  {
    footer: <FormattedMessage id='tiles.liablities' />,
    count: fixedDecimalAndFormateNumber(getTotal("Equity and Liabilities")),
    antIcon: <img src={img8} alt="" />,
    currencyIcon: companyInfo.currencyIcon,
    color: 'tile6',
    moduleCode: MODULE_CODED_VALUES.BALANCE_SHEET
  },
  ];



  return (
    <div className="school_dashboard_container">

      <TileIndex tilesData={tilesData} {...props} />
      <div className='view_dashboard pt15'>
        <Row gutter={[8, 8]}>
          <Col span={10}>
            <Row gutter={[8, 8]}>
              {salesPermissionsData.read && <Col span={24}>

                <div className="dashboard_inner_container">
                  <div className='container_heading'>
                    <div className="inner_container_heading">
                      Sales
                    </div>
                    <div>
                      <Dropdown
                        className="dp-custom"
                        value="Monthly"
                      />
                      <i className={ICONS.MORE} />

                    </div>
                  </div>
                  <Sales {...props} />
                </div>
              </Col>}


            </Row>
            <Row gutter={[8, 8]}>
           {purchasePermissionsData.read &&   <Col span={24}>
                <div className="dashboard_inner_container">
                  <div className='container_heading'>
                    <div className="inner_container_heading">
                      Purchase
                    </div>
                    <div>
                      <Dropdown
                        className="dp-custom"
                        value="Monthly"
                      />
                      <i className={ICONS.MORE} />

                    </div>
                  </div>
                  <Purchase {...props} />
                </div>
              </Col>}

            </Row>
            <Row gutter={[8, 8]}>
              <Col span={24}>
                <div className="dashboard_inner_container">
                  <div className='container_heading'>

                    <div className="inner_container_heading">
                      Products Sold
                    </div>
                  </div>
                </div>
              </Col>

            </Row>
          </Col>
          <Col span={8}>

            <Row gutter={[8, 8]}>

              <Col span={24}>
                <div className="dashboard_inner_container">
                  <div className='container_heading'>
                    <div className="inner_container_heading">
                      Attendance
                    </div>
                    <div>
                      <Dropdown
                        className="dp-custom"
                        value="Monthly"
                      />
                      <i className={ICONS.MORE} />

                    </div>
                  </div>
                  <Attendance {...props} />
                </div>

              </Col>
            </Row>
            <Row gutter={[8, 8]}>

              <Col span={24}>


                <div className="dashboard_inner_container half_view">
                  <div className='container_heading'>

                    <div className="inner_container_heading">
                      Recent Activity
                    </div>
                  </div>
                  <RecentActivity {...props} />
                </div>
              </Col>

            </Row>
          </Col>




          <Col span={6}>
            <Row gutter={[8, 8]}>
              <Col span={24}>
                <div className="dashboard_inner_container full_view">
                  {customerPermissionsData.read &&
                    <><div className='container_heading'>

                      <div className="inner_container_heading">
                        Top Customers
                      </div>
                    </div><TopCustomer {...props} /></>
                  }

               {noticePermissionsData.read &&  <><div className='container_heading'>
                    <div className="inner_container_heading">
                      Recent Notices
                    </div>

                  </div><RecentNotices {...props} /></>}
                </div>
              </Col>
            </Row>
          </Col>

        </Row>


      </div>

    </div>
  );
};

export default injectIntl(ErpDashboard);
