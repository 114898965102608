import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import GenerateShippingLabelEasyPostComponent from '../../../../../components/modal/modalBody/customer/GenerateShippingLabelEasyPost';
import { fetchFromLocalStorage, getCountryCode, ConvertWeightToOunces, ConvertDimensionsToInches } from '../../../../../utils';
import { getCarriersPrice, createLabel, buyShipment, getShipment, getCarrierDetails, getCarrierPricesShiprocket, createShiprocketShipmentOrder } from './action'
import { popModalFromStack } from '../../../../../actions/commonActions';
import { LOCAL_STORAGE_KEYS, LOGISTIC_CONSTANTS } from '../../../../../static/constants';
import { CONSTANTS } from '../../../../../static/constants';
import { fetchAllShipment } from '../../../../customer/shipment/Listing/action';
import * as find from 'lodash.find';
import { fetchSODetail } from '../SODetail/action';


class GenerateShippingLabelEasyPost extends Component {
  constructor(props) {
    super(props);
    this.state = {
      boughtShipment: this.props.data.boughtShipment,
      pageSize: props.data.formData?.pageSize || 25,
      pageNumber: props.data.formData?.pageNumber || 1,
      logisticName: LOGISTIC_CONSTANTS.EASYPOST
    }
  }

  componentDidMount(props) {
   
    const companyInfo = fetchFromLocalStorage(LOCAL_STORAGE_KEYS.LOGGEDIN_ENTITY_DATA) && JSON.parse(fetchFromLocalStorage(LOCAL_STORAGE_KEYS.LOGGEDIN_ENTITY_DATA));
    let shipmentData = this.props.data?.txData || {};
    const boughtShipment = this.props.data.boughtShipment;
    const relationshipAddress = find(shipmentData.boLocationShipmentList || [], { locationType: CONSTANTS.RELATIONSHIP_BILLING_ADDRESS }) || {};
    const shippingAddress = find(shipmentData.boLocationShipmentList || [], { locationType: CONSTANTS.SHIPPING_ADDRESS }) || {};

    const payload = {
      name: companyInfo?.storeName,
      relationshipId: ((companyInfo || {}).defaultCompany || {}).relationshipId,
      fromStreet1: relationshipAddress.streetAddress1,
      fromState: relationshipAddress.stateName,
      fromCountry: getCountryCode(relationshipAddress.countryName),
      fromCity: relationshipAddress.cityName,
      fromZip: relationshipAddress.zipCode,
      toStreet1: shippingAddress.streetAddress1,
      toState: shippingAddress.stateName,
      toCountry: getCountryCode(shippingAddress.countryName),
      toZip: shippingAddress.zipCode,
      toCity: shippingAddress.cityName,

      weight: ConvertWeightToOunces(shipmentData.weight, shipmentData.weightUnit),
      measurementUnit: shipmentData.measurementUnit,
      length: ConvertDimensionsToInches(shipmentData.length, shipmentData.dimensionUnit),
      width: ConvertDimensionsToInches(shipmentData.width, shipmentData.dimensionUnit),
      height: ConvertDimensionsToInches(shipmentData.height, shipmentData.dimensionUnit),
      // dimensionUnit: shipmentData.dimensionUnit,
      // weightUnit: shipmentData.weightUnit,


      shipmentMasterId: shipmentData.shipmentMasterId
    }

    const payloadShipRocket = {
      relationshipId: ((companyInfo || {}).defaultCompany || {}).relationshipId,
      pickup_postcode: "227817" || relationshipAddress.zipCode,
      delivery_postcode: "201301" || shippingAddress.zipCode,
      cod: 0,
      weight: shipmentData.weight,
      length: shipmentData.length,
      breadth: shipmentData.width,
      height: shipmentData.height,
    }

    if(boughtShipment && boughtShipment.purchasedShipmnetEasyPostId){
      this.props.getShipment({relationshipId: ((companyInfo || {}).defaultCompany || {}).relationshipId,shipmentId: boughtShipment.shipmentId });
      this.setState({current: 1});
    }else{
      if(this.state.logisticName === LOGISTIC_CONSTANTS.EASYPOST){
        this.props.getCarrierDetails(payload);
      }else if(this.state.logisticName === LOGISTIC_CONSTANTS.SHIPROCKET){
        this.props.getCarrierPricesShiprocket(payloadShipRocket);
      }else{
        // nothing to call
      }
    }
    const val = this.props.data.txData.customerSOShipmentDetailsList?.length  ? this.props.data.txData.customerSOShipmentDetailsList[0] :{}
    const payload2 = {
      customerId: val?.customerId,
      salesOrderMasterId: val?.salesOrderMasterId,
      relationshipId:this.props.companyInfo.relationshipId
  }
      this.props.fetchSODetail(payload2)
    
    
  }

 

  render() {
    return <GenerateShippingLabelEasyPostComponent {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />;
  }

}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    relationshipId: state.common.companyInfo.relationshipId,
    carrierRates: state.shipment.carrierRates,
    isLoadingCarriarList: state.shipment.isLoadingCarriarList,
    buyShipmentDetails: state.shipment.buyShipmentDetails,
    boughtShipment: state.shipment.boughtShipment,
    soDetail: state.so.soDetail,
    isLoadingCarriarList: state.shipment.isLoadingCarriarList,
    detailLoading: state.common.detailLoading,
    carrierDetails: state.shipment.carrierDetails,
    shiprocketCarrierDetails: state.shipment.shiprocketCarrierDetails,
    
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  getCarriersPrice,
  createLabel,
  buyShipment,
  popModalFromStack,
  getShipment,
  fetchAllShipment,
  fetchSODetail,
  getCarrierDetails,
  getCarrierPricesShiprocket,
  createShiprocketShipmentOrder,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(GenerateShippingLabelEasyPost);
