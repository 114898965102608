import React from "react";

import { Button, Col, Divider, Row, Skeleton, Tabs } from "antd";
import { FormattedMessage } from "react-intl";
import { getCurrencySymbol, getMomentDateForUIReadOnly } from "../../../utils";
import { TextBox } from "../../general/TextBox";
import { Dropdown, DropdownRef } from "../../general/Dropdown";
import UpgradePricingPlan from "../../../containers/B2B/PlanPricing";
import { CONSTANTS } from "../../../static/constants";
const {TabPane} = Tabs;

function calculateRemainingDays(loginDateStr, trialDays) {
    const loginDate = new Date(loginDateStr);
    const endDate = new Date(loginDate);
    endDate.setDate(loginDate.getDate() + trialDays);
    
    const currentDate = new Date();
    const remainingTime = endDate - currentDate;
    
    const remainingDays = Math.ceil(remainingTime / (1000 * 60 * 60 * 24));

    return remainingDays > 0 ? remainingDays : 0; // If the days are negative, return 0
}

function Subscription(props) {
    const {subscribedProduct ,alpideProducts , companyInfo,packagePlan={}} = props;
    const date = (companyInfo?.dateCreated||"").split(" ")[0];
    const freeTrialdDays= companyInfo?.freeDays; 
 
    const userSubscribedPlans = (props.subscribedPlans||[]).reduce((acc, item) => {
        return [...acc, ...item.userSubscribedPlans];
      }, []);
    console.log(userSubscribedPlans,props.subscribedPlans ,"NATUREEE")
const ModalBody = (props)=>{
  console.log(props)
  const subscriptionInfo = props.data
    return(
        <>
        {
           subscriptionInfo?.isActive ? (<>
            <div>

<h4>Cancel Subscription</h4>
<p>Initiate the process to cancel your subscription.</p>
<p className="error">Please note, cancellation is final and will end your access.</p>
</div>
<div>
    <h5>Your subscription will be canceled on { getMomentDateForUIReadOnly({ date: new Date(), format: CONSTANTS.DISPLAY_DATE_FORMAT })}</h5>
 <div>
 <textarea
placeholder={"Reason for cancellation"}
maxLength={50}
rows={4}
className={''}


onChange={()=>{

}}
style={{width:"100%",resize:"none",padding:"10px 10px", margin:"20px 0px"}}

/>
 </div>
 <Button type="primary" onClick={()=>{
    props.cancelSubscribedPlan({subscriptionId: props.data?.cbSubscriptionId})
 }}>Confirm Cancellation</Button>
</div>
           </>):(<>
           
            <div>

<h4>Subscription  Detail</h4>
<p>Your subscription has been cancelled</p>

</div><div>
<h5>Your subscription will be canceled on { getMomentDateForUIReadOnly({ date: new Date(), format: CONSTANTS.DISPLAY_DATE_FORMAT })}</h5>
<p>You will retain access until the end of your current billing cycle.</p>

<Button type="primary" onClick={()=>{
    props.popModalFromStack()
 }}>Go Back</Button>
</div>
           </>)
        }
       
        </>
    )
}

    const plansTab = [{
        tabName:"Personal",
    },
    {
        tabName:"Education",
    },
    {
        tabName:"Employment",
    }]
    const values="";
    return (
        <div className="account-setting">
<div className="store-model-container cus-space">
   
   <div className="store-main-container">
   

    <div>
    <div className="account-heading">
    <p className="heading">My Subscription</p>
    <p className="sub-heading">
    View details of your current subscription plan, including features, usage limits, and renewal dates.    </p>

    </div>
    <div className="account-information">
    
    <Tabs
                activeKey={props.planKey || "Personal"}onChange={(key) => { props.updateState({
                    planKey: key,
                }) }}
                type="line"
              >
              
                {
                    plansTab?.map((item ,i)=>{
                        return(
                            <TabPane tab={<FormattedMessage id={item.tabName} />} key={item.tabName}>
                            <div className="subscription-tab">
                                <div>
                                <div className="mb20">
                <b>Your Plan</b>
               {
                companyInfo?.isFreeTrial ?  <p className="sub-heading">
                    
                You are currently using a free trial of <b>{packagePlan?.planName||""}</b>, <span className="account-info">it will end in {calculateRemainingDays(date , freeTrialdDays)} days</span>.
                 </p> :""
               }
            
                </div>
                <div className="">
                 <Button type="primary"  onClick={()=>{
                    const alpideProductId = 1;
                    props.pushModalToStack({
                        modalBody: <UpgradePricingPlan {...props} isUpgrade={true} alpideProductId={typeof alpideProductId === 'number' ?  alpideProductId : 0} />,
                        width: '100%',
                        hideTitle: true,
                        hideFooter: true,
                        keyboard:false,
                        wrapClassName: 'full_modal',
                  
                        onCancel: () => {
                      props.showModal({
                            modalBody: <FormattedMessage id="modal.cancel.confirmation" defaultMessage=""/>,
                            footer:{submit:{intlId:"button.close.label"}},
                            handleSubmit: () => {
                            
                              props.popModalFromStack();
                             
                            }
                          })
                        }
                      })
                 }} >Upgrade Alpide Cloud ERP Plan</Button>
              </div>
                                </div>
                     
            <div>
            <div className="mb20">
                <b>Your Plan Details</b>
                <p className="sub-heading">
                Review your current plan details, including features , limits, and renewal information
                 </p>
            
                </div>
                <div>
                <Skeleton loading={props.listLoading} paragraph={{ rows: 7 }} active>
                <div className='table-container'>
                    <table id='coupons-table'>
                    <thead>
                        <tr>
                            <th>
                                <FormattedMessage id="serial.no" />
                            </th>
                            <th >
                                <FormattedMessage id="sidebar.menuItem.products" defaultMessage="" />
                            </th>
                            <th >
                                <FormattedMessage id="accounts.subscription.packageplan.plan" defaultMessage="" /> 
                            </th>
                            <th>
                                <FormattedMessage id="accounts.subscription.packageplan.addOn" defaultMessage="" />
                            </th>
                            <th>
                                <FormattedMessage id="accounts.subscription.packageplan.ExpireOn" defaultMessage="" />
                            </th>
                           
                            <th>
                                <FormattedMessage id="addItem.text.userStatus" defaultMessage="" />
                            </th>
                            <th>
                            <FormattedMessage id="customer.listing.header.action" defaultMessage="" />
                            </th>
                            <th></th>
                        </tr>
                    </thead>
                        <tbody>
                           {
                            (props.subscribedPlans||[]).map((item ,i)=>{
                                const val = (item.userSubscribedPlans||[])[0]||{}
                                console.log(item ,"NATUREE")
                              
                                return(
                                    <tr key={"product"+i}>
                                      <td>{i+1}</td>
                                      <td><p>{val?.alpideProductName}</p>
                                      <p>{val?.planName}</p>
                                      </td>
                                      <td>{val?.planName}</td>
                                      <td className="link">Add +</td>
                                      <td>{item?.expiryDate ? getMomentDateForUIReadOnly({ date: item?.expiryDate, format: CONSTANTS.DISPLAY_DATE_FORMAT }) : <span className="account-info">Expire</span>}</td>
                                      <td>{item?.isActive ? <span className="success">Active</span> : <span className="error">In-Active</span>}</td>
                                      <td >{item?.expiryDate ? <span>You are using {val?.planName} plan</span> :<Button type="primary" onClick={()=>{
 const alpideProductId = 1;
 props.pushModalToStack({
     modalBody: <UpgradePricingPlan {...props} isUpgrade={true} alpideProductId={typeof alpideProductId === 'number' ?  alpideProductId : 0} />,
     width: '100%',
     hideTitle: true,
     hideFooter: true,
     keyboard:false,
     wrapClassName: 'full_modal',

     onCancel: () => {
   props.showModal({
         modalBody: <FormattedMessage id="modal.cancel.confirmation" defaultMessage=""/>,
         footer:{submit:{intlId:"button.close.label"}},
         handleSubmit: () => {
         
           props.popModalFromStack();
          
         }
       })
     }
   })
                                      }}>Upgrade</Button>}</td>
                                      <td><span className="account-info" onClick={()=>{
    console.log(props,"fandaaa")
    const modalData = {
        title: "Confirmation",
        modalBody: <ModalBody data={item} cancelSubscribedPlan={props.cancelSubscribedPlan}/>,
        hideFooter:true,
        width: '734px',
        
    };
    props.pushModalToStack(modalData);
    
   

                                      }}>Cancel Subscriptions</span></td>
                                    </tr>
                                )
                            
                            })
                           }
                        </tbody>
                    </table>
                </div>
            </Skeleton>
                </div>
            <div>
            
            </div>
               
            </div>
              <div>
                                <div className="mb20">
                <b>Explore More</b>
                <p className="sub-heading">
                 Browse other Alpide products that complement your current plan and enhance your operations.
                 </p>
            
                </div>
                <div className="">
                 <Button type="primary" onClick={()=>{

                      props.updateHeaderState({alpideProductDrawer: true})

                    }} >Explore more Alpide Products</Button>
              </div>
                                </div>
                            </div>
                           
                            </TabPane>
                        )
                    })
                }
               
               
              </Tabs>


    </div>
   
    </div>
   </div>
   

   
   


</div>
        </div>
        
    )
}
export default Subscription;