import React, { useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import ErpDashboard from "../../components/erpDashboard";
import { fetchCustomerUnpaidInvoices, fetchSupplierUnpaidInvoices, fetchTopCustomerByPayment, fetchTopCustomerBySales } from "../Dashboard/action";
import { fetchAllLedgerAccounts } from "../finance/LedgerAccounts/action";
import { getActiveBroadcastMessage } from "../schoolManagement/BroadcastMessage/action";


const ErpDashboardCon = (props) => {
  const [state, updateState] = useState({

  })
  useEffect(() => {
    const companyInfo = props.companyInfo || {}
    const payload = {
      companyInfo,
      relationshipId: (props.companyInfo || {}).relationshipId,
      pageSize: 25,
      pageNumber: 1
    }
    props.fetchCustomerUnpaidInvoices(payload);
    props.fetchSupplierUnpaidInvoices(payload);
    props.fetchAllLedgerAccounts(payload)
    props.fetchTopCustomerBySales(payload);
    props.getActiveBroadcastMessage(payload);


  }, [props.companyInfo]);
  return <>
    <ErpDashboard
      {...props}
      updateState={updateState}
      {...state}
    />
  </>
}
const mapStateToProps = (state) => {

  return {
    companyInfo: state.common.companyInfo,
    customerUnpaidInvoices: state.dashboard.customerUnpaidInvoices,
    supplierUnpaidInvoices: state.dashboard.supplierUnpaidInvoices,
    allLedgerAccounts: state.ledger.allLedgerAccounts,
    topSalesCustomer: state.dashboard.topSalesCustomer,
    broadcastMessageList: state.school.broadcastMessageList,
    detailLoading: state.common.detailLoading,
    listLoading: state.common.listLoading,
    permissions: state.auth.permissions


  };
};
const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchCustomerUnpaidInvoices,
  fetchSupplierUnpaidInvoices,
  fetchAllLedgerAccounts,
  fetchTopCustomerBySales,
  fetchTopCustomerByPayment,
  getActiveBroadcastMessage
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ErpDashboardCon)
