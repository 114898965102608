import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import AttendanceComp from "../../../components/schoolManagement/attendance";
import { getSchoolAttendanceByClassId, getClass } from "./action";
import { showModal, hideModal, pushModalToStack } from './../../../actions/commonActions';
import { getRegisteredMemberByClass, getRegisteredMemberCountByClass } from '../../schoolManagement/student/action';
import { getClassList } from "../class/action";
import {saveAttendance } from "../../drawer/schoolManagement/attendancce/action";
import { formatDateForBackend } from "../../../utils";

class School extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAttendenceLoaded: false,
      pageNumber: 1,
      pageSize: 25,
    };
  }

  componentDidMount() {
    const companyInfo = this.props.companyInfo || {};
    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      relationshipEmployeeId: (this.props.userInfo || {}).relationshipEmployeeId,
      pageNumber: 1,
      pageSize: 1000,
    };
    
    this.props.getClassList(payload);
  }

  // componentWillReceiveProps(props) {   
  //   if((props.studentAttendanceList || []).length){
  //     props.studentAttendanceList.forEach((entry, i) => {
  //       let leaveCount = 0;
  //       let absentCount = 0;
  //       let presentCount = 0;
  //       let holidayCount = 0;
  //       let sundayCount = 4;
  //       let tempList = props.studentAttendanceList;

  //       const attendance = entry.attendance;
  //       Object.values(attendance).forEach(status => {
  //         switch (status) {
  //           case "Leave":
  //             leaveCount++;
  //             break;
  //           case "Absent":
  //             absentCount++;
  //             break;
  //           case "Present":
  //             presentCount++;
  //             break;
  //           case "Holiday":
  //             holidayCount++;
  //             break;
  //           default:
  //             break;
  //         }
  //       });
  //       tempList[i] = {...tempList[i], leaveCount, presentCount, absentCount, holidayCount, sundayCount, }
        
  //       this.setState({studentAttendanceList : tempList})
  //     });
  //   }
  // }
  componentWillReceiveProps(props) {
    if (props.studentAttendanceList !== this.props.studentAttendanceList) {
      const tempList = props.studentAttendanceList.map((entry) => {
        let leaveCount = 0;
        let absentCount = 0;
        let presentCount = 0;
        let holidayCount = 0;
        let sundayCount = 4; 
  
        const attendance = entry.attendance;
        Object.values(attendance).forEach(status => {
          switch (status) {
            case "Leave":
              leaveCount++;
              break;
            case "Absent":
              absentCount++;
              break;
            case "Present":
              presentCount++;
              break;
            case "Holiday":
              holidayCount++;
              break;
            default:
              break;
          }
        });
  
        return {
          ...entry,leaveCount,presentCount,absentCount,holidayCount,sundayCount
        };
      });
  
      this.setState({ studentAttendanceList: tempList });
    }
  }
  

  render() {
    return (
      <AttendanceComp
        {...this.props}
        {...this.state}
        updateState={(data) => {
          this.setState(data);
        }}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userInfo: state.common.userInfo,
    companyInfo: state.common.companyInfo,
    relationshipId: state.common.companyInfo.relationshipId,
    schoolList: state.school.schoolList,
    studentAttendanceList: state.school.studentAttendanceList,
    classList: state.school.classList,
    // defaultSchoolForm: state.common.defaultSchoolForm,
    activeStudentList: state.school.studentList,
    activeStudentCount: state.school.studentCount,
    isLoading: state.common.isLoading,
    permissions: state.auth.permissions,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getSchoolAttendanceByClassId,
      getRegisteredMemberByClass,
      getRegisteredMemberCountByClass,
      showModal,
      hideModal,
      getClass,
      pushModalToStack,
      getClassList,
      saveAttendance,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(School);
