import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import TeamComp from '../../../../components/company/EmployeeMeta/Team';
import TeamDrawer from '../../../drawer/company/EmployeeMeta/Team';
import { createTeam, getTeam, getTotalTeamCount, deleteTeam } from './action';
import { fetchDataIfNeeded, fetchPaginationDataIfNeeded } from '../../../../utils';
import { showModal,hideModal } from '../../../../actions/commonActions';


class TeamContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageNumber: 1,
      pageSize: 25
    }
  };


  componentDidMount(props) {
    const payload = {
      relationshipId: (this.props.companyInfo || {}).relationshipId,
      pageNumber: 1,
      pageSize: 25
    }
    fetchPaginationDataIfNeeded('getTeam', 'teamList', this.props, payload, true);
    fetchDataIfNeeded('getTotalTeamCount', 'teamCount', this.props, payload)
  }



  render() {
    return <>

      <TeamComp {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
      {this.state.teamDrawer && <TeamDrawer {...this.state} {...this.props} updateState={(data) => { this.setState(data) }} />}
    </>

      ;
  }

}

const mapStateToProps = (state) => {
  return {

    companyInfo: state.common.companyInfo,
    listLoading: state.common.listLoading,
    drawerLoading:state.common.drawerLoading,
    userInfo: state.common.userInfo,
    teamList: state.employee.teamList,
    teamCount: state.employee.teamCount,
    permissions: state.auth.permissions,


  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  createTeam,
  getTeam,
  getTotalTeamCount,
  deleteTeam,
  showModal,
  hideModal
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(TeamContainer);
