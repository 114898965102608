import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { getMomentDateForUIReadOnly, sortTable, exportExcel } from '../../../utils';
import { Empty, Skeleton, Checkbox, Input, Menu, Dropdown, Button } from 'antd';
import { CONSTANTS, ICONS } from '../../../static/constants';
import * as find from "lodash.find";
import * as debounce from "lodash.debounce";
import { DownloadOutlined, FileExcelOutlined, FilePdfOutlined } from "@ant-design/icons";

const { Search } = Input;

const format = CONSTANTS.DISPLAY_DATE_FORMAT;

const EmployeeContact = (props) => {
    const { allEmployee, updateState, dir = 0, selectedItems = [] } = props;


    const sortColumn = (e) => {
        sortTable(document.getElementById('employee-table'), e.target.cellIndex, 1 - dir);
        updateState({ dir: 1 - dir })
    }
    const handleSearchedText = (searchEmployee, props) => {
        let employeeResult = [];

        if (searchEmployee.length) {
            employeeResult = allEmployee.filter((user) => {
                return (
                    (user?.firstName && user.firstName.toLowerCase().includes(searchEmployee.toLowerCase())) ||
                    (user?.emailAddress && user.emailAddress.toLowerCase().includes(searchEmployee.toLowerCase())) ||
                    (user?.cellPhone && user.cellPhone.includes(searchEmployee))
                );
            });

            props.updateState({
                employeeResult: [...employeeResult],
                selectedType: props.selectedType
            });
        };
    }



    const onSearchedInputChange = debounce((searchEmployee, props) => {
        handleSearchedText(searchEmployee, props);
    }, 100);
    const itemSelection = (item) => {
        let selectItem = find(selectedItems, {
            relationshipEmployeeId: Number(item.relationshipEmployeeId),
        });
        let updatedItems = [];
        if (selectItem) {
            updatedItems = selectedItems.filter(
                (obj) => obj.relationshipEmployeeId !== item.relationshipEmployeeId
            );
        } else {
            updatedItems = JSON.parse(JSON.stringify(selectedItems));
            updatedItems.push(item);
        }
        props.updateState({ selectedItems: updatedItems });
    };
    const selectAll = (context) => {
        if (!context) {
            selectedItems.splice(0, selectedItems.length);
            props.updateState({ selectedItems: selectedItems });
            return;
        }
        for (let i = 0; i < allEmployee?.length; i++) {
            let item = allEmployee[i];
            let selectItem = find(selectedItems, {
                relationshipEmployeeId: Number(item?.relationshipEmployeeId),
            });
            if (!selectItem) {
                selectedItems.push(item);
            }
        }
        props.updateState({ selectedItems: selectedItems });
    };

    const isCheckedAll = () => {
        if (
            !allEmployee ||
            allEmployee.length === 0
        ) {
            return false;
        }
        for (let i = 0; i < allEmployee?.length; i++) {
            let usr = allEmployee[i];
            let selectItem = find(selectedItems, {
                relationshipEmployeeId: Number(usr.relationshipEmployeeId),
            });
            if (!selectItem) {
                return false;
            }
        }
        return true;
    };
    const exportExcelData = (selectedContactList, props) => {
        const dataList = (selectedContactList || []).map((item) => {
            return {
                "Full Name": (item.salutationName || "") + " " + (item.firstName || "") + " " + (item.lastName || ""),
                "Email": item.emailAddress || "",
                "Phone Number": item.cellPhone || "",
                "Job Role": item.jobRoleName || ""

            };
        });
        exportExcel(dataList, "Employees");
    };
    const downloadMenu = () => {
        return (
            <Menu className="row-action-dropdown">
                <Menu.Item key="0" value="title">
                    Download as
                </Menu.Item>

                <Menu.Divider />

                <Menu.Item
                    key="1"
                    value="excel"
                    onClick={() => {
                        if (selectedItems.length) {
                            exportExcelData(selectedItems);
                        } else {
                            const modalData = {
                                title: (
                                    <div className="title">
                                        <i className="fa fa-ban mr15" />
                                        Validation Failed
                                    </div>
                                ),
                                modalBody: (
                                    <Fragment>
                                        <div className="warning-message">
                                            No customer selected. Please select customer before
                                            downloading.
                                        </div>
                                        <div className="button-action">
                                            <Button
                                                className="ant-btn-default"
                                                onClick={() => props.popModalFromStack()}
                                            >
                                                OK
                                            </Button>
                                        </div>
                                    </Fragment>
                                ),
                                hideFooter: true,
                                wrapClassName: "validation-failed-modal",
                            };
                            props.pushModalToStack(modalData);
                        }
                    }}
                >
                    <FileExcelOutlined />
                    Excel File
                </Menu.Item>
                <Menu.Item
                    key="2"
                    value="pdf"
                    onClick={() => {
                        const customerList = [];
                        selectedItems.forEach((data) => {
                            // let data = obj.data;
                            let address =
                                find(data.boLocationList, {
                                    isDefault: 1,
                                    locationType: "BillingAddress",
                                }) || {};
                            let contact =
                                data.boContactList &&
                                find(data.boContactList, { isPrimaryContact: 1 });
                            customerList.push({
                                ...data,
                                defaultAddress: address,
                                defaultContact: contact,
                            });
                        });
                        if (customerList.length) {
                            props.generatePDF({
                                companyInfo: props.companyInfo,
                                fileName: "Employee.pdf",
                                transactionName: "employeeListing",
                                customerList: customerList,
                            });
                        } else {
                            const modalData = {
                                title: (
                                    <div className="title">
                                        <i className="fa fa-ban mr15" />
                                        Validation Failed
                                    </div>
                                ),
                                modalBody: (
                                    <Fragment>
                                        <div className="warning-message">
                                            No customer selected. Please select customer before
                                            downloading.
                                        </div>
                                        <div className="button-action">
                                            <Button
                                                className="ant-btn-default"
                                                onClick={() => props.popModalFromStack()}
                                            >
                                                OK
                                            </Button>
                                        </div>
                                    </Fragment>
                                ),
                                hideFooter: true,
                                wrapClassName: "validation-failed-modal",
                            };
                            props.pushModalToStack(modalData);
                        }
                    }}
                >
                    <FilePdfOutlined />
                    PDF File
                </Menu.Item>
            </Menu>
        );
    };
    return (
        <>
            
                <div className="view-container">
                    <div className="view-container-actions">
                        <div className="left-actions">
                            <div className="table-heading">Employee List</div>

                        </div>

                        <div className="right-actions">
                            <Search
                                placeholder="Search"
                                value={props.searchEmployee}
                                onChange={(e) => {
                                    props.updateState({ searchEmployee: e.target.value, pageNumber: 1 });
                                    onSearchedInputChange(e.target.value, props);
                                }}
                                onSearch={(searchEmployee) => {
                                    props.updateState({ searchEmployee, pageNumber: 1 });
                                    onSearchedInputChange(searchEmployee, props);
                                    handleSearchedText(searchEmployee, props);
                                }}
                            />


                            <Dropdown overlay={downloadMenu()} trigger={["click"]}>
                                <span>
                                    <DownloadOutlined /> &nbsp;{" "}
                                    <FormattedMessage
                                        id="common.action.dropdown.downloadAs"
                                        defaultMessage=""
                                    />
                                </span>
                            </Dropdown>
                        </div>
                    </div>
                    <div className='table-container table-h-profile'
                        style={{ height: "calc(100% - 144px)" }}
                    >
                    <Skeleton loading={props.listLoading} paragraph={{ rows: 17 }} active>

                        <table id='employee-table'>
                            <thead>
                                <tr>
                                    <th>
                                        <div className="check-cell">
                                            <Checkbox
                                                onChange={(e) => {
                                                    selectAll(e.target.checked);
                                                }}
                                                checked={isCheckedAll()}
                                            />

                                        </div>
                                    </th>
                                    <th onClick={sortColumn}><FormattedMessage id='contact.fullName' defaultMessage="" /></th>
                                    <th onClick={sortColumn}><FormattedMessage id='contact.email' defaultMessage="" /></th>
                                    <th onClick={sortColumn}><FormattedMessage id='contact.phone' defaultMessage="" /></th>
                                    <th onClick={sortColumn}><FormattedMessage id='contact.mobile' defaultMessage="" /></th>
                                    {/* <th onClick={sortColumn}><FormattedMessage id='contact.faxNumber' defaultMessage="" /></th> */}
                                    <th>Job Role</th>
                                    <th onClick={sortColumn}><FormattedMessage id='common.dateCreated' defaultMessage="" /></th>
                                    {/* <th className='text-center'><FormattedMessage id='common.listing.header.action' defaultMessage="" /></th> */}
                                </tr>
                            </thead>
                            <tbody>
                                {/* {allEmployee && allEmployee.length ? allEmployee. */}
                                {((props.employeeResult && props.employeeResult.length > 0) || !props.searchEmployee) &&
                                    (props.searchEmployee ? props.employeeResult : allEmployee).
                                        sort((a, b) => (a.dateCreated < b.dateCreated ? 1 : -1)).map((e, i) => {
                                            return <tr key={i}>
                                                {/* <td>{i + 1}</td> */}
                                                <td> <Checkbox
                                                    onClick={() => {
                                                        itemSelection(e);
                                                    }}
                                                    checked={
                                                        find(selectedItems, {
                                                            relationshipEmployeeId: Number(e.relationshipEmployeeId),
                                                        })
                                                            ? true
                                                            : false
                                                    }
                                                /></td>

                                                <td>{e.salutationName}{" "}{e.firstName}{" "}{e.lastName}</td>
                                                <td>
                                                    {e.emailAddress ? e.emailAddress : <div className="empty-data-box"></div>}
                                                </td>
                                                <td>
                                                    {e.workPhone ? e.workPhone : <div className="empty-data-box"></div>}
                                                </td>
                                                <td>
                                                    {e.cellPhone ? e.cellPhone : <div className="empty-data-box"></div>}
                                                </td>
                                                <td>{e?.jobRoleName}</td>
                                                {/* <td>
                                            {e.faxNumber ? e.faxNumber : <div className="empty-data-box"></div>}
                                        </td> */}
                                                <td>{e.dateCreated ? getMomentDateForUIReadOnly({ date: new Date(e.dateCreated), format }) : ''}</td>
                                                {/* <td className='text-center'>


                                        </td> */}
                                            </tr>
                                        }) ||
                                    <tr key="empty-data-box">
                                        <td colSpan={"100%"} style={{ borderBottom: "none" }}>
                                            <Empty />
                                        </td>
                                    </tr>
                                }
                            </tbody>
                        </table>
            </Skeleton>
                    </div>
                </div>
        </>
    )
}

export default EmployeeContact;