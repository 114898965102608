import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as find from "lodash.find";
import * as filter from "lodash.filter";
import { fetchWarehouseNames } from "../../../drawer/inventory/action";
import {
  fetchCustomerDetailsByCustomerId,
  fetchRelationshipTaxIdentifications,
  saveUserPrefrences,
  fetchProducts,
  fetchAllEmployees,
} from "./../../../../actions/commonActions";
import { LEDGER_ACTION_LIST, CONSTANTS } from "../../../../static/constants";
import {
  AG_GRID_CONSTANTS,
  GRID_PREFERENCE_CONSTANTS,
} from "../../../../static/agGridConstants";
import StandardStockTransitComp from "../../../../components/inventory/StockTransit/StandardStockTransit";
import { fetchDataIfNeeded } from "../../../../utils";
import { createStockTransfer, createStockTransferDraft } from "./action";
import {
  addDocumentName,
  fetchDocuments,
} from "./../../../../actions/commonActions";
import { fetchstockTransfer } from "../action";
class StandardStockTransit extends Component {
  constructor(props) {
    super(props);
    const pro = {
      gitMasterId: 1,
      version: 1,
      sourceWarehouseMasterId: 2,
      destinationWarehouseMasterId: 3,
      gitNumber: "GIT-001",
      gitDate: "2023-08-14 12:00:00",
      isApproved: 1,
      fyStartDate: "2023-04-01 00:00:00",
      fyEndDate: "2024-03-31 00:00:00",
      gitDueDate: "2023-08-31 00:00:00",
      relationshipName: "Customer A",
      comments: "Approved",
      placeOfSupply: "State A",
      rid: 1001,
      status: "Active",
      statusColorForUICell: "#00FF00",
      isActive: "Yes",
      dateCreated: "2023-08-14 12:00:00",
      dateUpdated: "2023-08-14 12:00:00",
      createdByUserId: 101,
      updatedByUserId: 102,
      taxId: 201,
      boLocationGITList: [
        {
          locationGitId: 1,
          version: 1,
          locationName: "Warehouse A",
          cityName: "City A",
          streetAddress1: "Street A",
          streetAddress2: "Street B",
          stateName: "State A",
          warehouseId: 2,
          gitMasterId: 1,
          countryName: "Country A",
          rid: 1001,
          isDefault: 1,
          dateCreated: "2023-08-14 12:00:00",
          dateUpdated: "2023-08-14 12:00:00",
          createdBy: "User A",
          updatedBy: "User B",
          locationType: "Warehouse",
          zipCode: "12345",
          gstStateCode: 123,
        },
      ],
      documentName: "GIT Document",
      createdByEmpId: 1001,
      approverEmpId: 1002,
      rejectionReason: "Incomplete Details",
      isRejected: 0,
      gitDetailsList: [
        {
          gitDetailsId: 1,
          version: 1,
          gitMasterId: 1,
          itemId: 101,
          itemName: "Product A",
          uomName: "Each",
          itemDeletedDuringUpdate: 0,
          sNo: 1,
          quantity: 100,
          itemPurchasedPrice: 50.0,
          rid: 1001,
          sku: "SKU001",
          barCode: "123456789",
          hsnCode: "123456",
          attributeName1: "Color",
          attributeName2: "Size",
          attributeName3: "Material",
          attributeValue1: "Red",
          attributeValue2: "Medium",
          attributeValue3: "Cotton",
          attributeId1: 301,
          attributeId2: 302,
          attributeId3: 303,
          cgstTaxId: 401,
          sgstTaxId: 402,
          igstTaxId: 403,
          gitDate: "2023-08-14 12:00:00",
          description: "Product description",
        },
      ],
      gitCOATxList: [],
    };
    const defaultSalesLedger =
      find(props.companyInfo.defaultCoaLedgerAccountsList, {
        txType: LEDGER_ACTION_LIST.SALES,
      }) || {};
    const defaultExpenseLedger =
      find(props.companyInfo.defaultCoaLedgerAccountsList, {
        txType: LEDGER_ACTION_LIST.CUSTOMER_EXPENSE,
      }) || {};
    const defaultSalesOutputLedger =
      find(props.companyInfo.defaultCoaLedgerAccountsList, {
        txType: LEDGER_ACTION_LIST.SALES_OUTPUT_TAX,
      }) || {};
    const defaultSalesDiscountLedger =
      find(props.companyInfo.defaultCoaLedgerAccountsList, {
        txType: LEDGER_ACTION_LIST.SALES_DISCOUNT,
      }) || {};
    const defaultDebtorsLedger =
      find(props.companyInfo.defaultCoaLedgerAccountsList, {
        txType: LEDGER_ACTION_LIST.DEBTORS,
      }) || {};
    const companyInfo = props.companyInfo;
    const allRelationshipBillingAddress =
      filter(companyInfo.boLocationList, {
        locationType: CONSTANTS.RELATIONSHIP_BILLING_ADDRESS,
      }) || [];
    const relationshipBillingAddress = find(allRelationshipBillingAddress, {
      isDefault: 1,
    });
    const linkProps = (this.props.location && this.props.location.state) || {};
    this.state = {
      preservedSalesOrder: [],
      isbankReco: linkProps.isbankReco,
      bankRecoBalance: linkProps.bankRecoBalance,
      bankTxData: linkProps.bankTxData,
      invoiceDraftId: linkProps.invoiceDraftId || 0,
      isClone: linkProps.clone,
      invoiceProformaId: linkProps.invoiceProformaId || 0,
      invoiceDraftData: linkProps.invoiceData || {},
      customer: linkProps.customer,
      customerName: (linkProps.customer || {}).companyName,
      stateCustomerId: linkProps.customerId,
      pageNumber: linkProps.pageNumber || 1,
      pageSize: 100,
      cols: 20,
      pro,
      searchedText: "",
      isSoConversionToInvoice: false,
      ledgerDrawerVisible: false,
      invoiceDate: new Date(),
      salesLedgerId: defaultSalesLedger.ledgerAccountId,
      salesDebtorLedgerId: defaultDebtorsLedger.ledgerAccountId,
      expenseLedgerId: defaultExpenseLedger.ledgerAccountId,
      expenseDebtorLedgerId: defaultDebtorsLedger.ledgerAccountId,
      salesOutputTaxLedgerId: defaultSalesOutputLedger.ledgerAccountId,
      taxDebtorLedgerId: defaultDebtorsLedger.ledgerAccountId,
      salesDiscountLedgerId: defaultSalesDiscountLedger.ledgerAccountId,
      discountDebtorLedgerId: defaultDebtorsLedger.ledgerAccountId,
      allRelationshipBillingAddress,
      relationshipBillingAddress,
      priceTypeToApply:
        AG_GRID_CONSTANTS.STANDARD_SALES_INVOICE_FIELD.PRICE_TYPE_LIST[0],
      customerShippingAddress: undefined,
      customerBillingAddress: undefined,
      customerPaymentTerm: undefined,
      customerRef: undefined,
      customerPo: undefined,
      placeOfSupply: undefined,
      customerRemarks: undefined,
      internalRemarks: undefined,
      footer: undefined,
      inheritedTxColumn: [],
      forceHideTxColumn: [],
      txPreferences: { txType: "salesInvoice", templateName: "standard" },
      txColumns: [
        "product",
        "description",
        "quantity",
        "uom",
        "rate",
        "amount",
      ],
      txColumnSetting: {},
      expenseAmounts: {},
      txMandatoryColumns: [
        GRID_PREFERENCE_CONSTANTS.PRODUCT,
        GRID_PREFERENCE_CONSTANTS.DESCRIPTION,
        GRID_PREFERENCE_CONSTANTS.QUANTITY,
        GRID_PREFERENCE_CONSTANTS.RATE,
        GRID_PREFERENCE_CONSTANTS.AMOUNT,
        "sno",
      ],
      txAvailableColumn: {
        product: true,
        quantity: true,
        rate: true,
        amount: true,
        description: true,
        uom: true,
        hsn: (props.companyInfo || {}).countryName === "India",
        basePrice: true,
        stockNumber: true,
        partNumber: true,
        origin: true,
        hsCode: true,
        salesOrderNumber: true,
      },
      selectedSalesOrder: [],
      customerSalesOrderDetailsList: [{}],
      rootExpenseList: [{}],
    };
  }

  componentDidMount() {
    const companyInfo = this.props.companyInfo || {};
    const linkProps = (this.props.location && this.props.location.state) || {};
    const payload = {
      customerId: this.props.customerId || linkProps.customerId,
      soMasterId: this.props.soMasterId || linkProps.soMasterId,
      shipmentMasterId:
        this.props.shipmentMasterId || linkProps.shipmentMasterId,
      soMasterIdArr: this.props.soMasterIdArr || linkProps.soMasterIdArr,
      invoiceMasterId: this.props.invoiceMasterId || linkProps.invoiceMasterId,
      relationshipId: companyInfo.relationshipId,
      userId: (this.props.userInfo || {}).userId,
      isClone: linkProps.clone,
      pageNumber: 1,
      pageSize: 100,
    };
    const payload2 = {
      relationshipId: companyInfo.relationshipId,
      customerId: this.props.customerId || linkProps.customerId,
      userId: (this.props.userInfo || {}).userId,
      pageNumber: 1,
      pageSize: 100,
    };
    this.props.fetchstockTransfer(payload);
 
    fetchDataIfNeeded(
      "fetchWarehouseNames",
      "allWarehouses",
      this.props,
      payload
    );
    this.props.fetchProducts(payload);
    this.props.fetchAllEmployees(payload);
    this.props.fetchRelationshipTaxIdentifications(payload);

    fetchDataIfNeeded("fetchDocuments", "documentList", this.props, payload2);
  }
  onSearch(text, updateAllOptions, updateFetching) {
    const payload = {
      relationshipId: (this.props || {}).companyInfo.relationshipId,
      pageNumber: 1,
      searchString: text,
      pageSize: 25,
      updateAllOptions,
      updateFetching,
    };
    this.props.fetchProducts(payload);
  }

  render() {
    return (
        <StandardStockTransitComp
          onSearch={(text, updateAllOptions, updateFetching) => {
            this.onSearch(text, updateAllOptions, updateFetching);
          }}
          {...this.props}
          {...this.state}
          openSalesInvoiceDetails={this.openSalesInvoiceDetails}
          updateState={(data) => {
            this.setState(data);
          }}
        />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    relationshipId: state.common.companyInfo.relationshipId,
    paymentTerms: state.common.paymentTerms,
    customers: state.customer.customers,
    companyInfo: state.common.companyInfo,
    allWarehouses: state.inventory.allWarehouses,
    products: state.salesInvoice.products,
    allEmployee: state.common.employees,
    allRelationshipTaxIdentificatins:
      state.common.allRelationshipTaxIdentificatins,
    documentList: state.common.documentList,
    allWarehouses: state.inventory.allWarehouses,
    stockTransferList: state.inventory.stockTransfer,
    userInfo: state.common.userInfo,
    isLoading: state.common.isLoading,
    permissions: state.auth.permissions,

  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchCustomerDetailsByCustomerId,
      fetchWarehouseNames,
      saveUserPrefrences,
      fetchProducts,
      fetchAllEmployees,
      createStockTransfer,
      createStockTransferDraft,
      fetchRelationshipTaxIdentifications,
      addDocumentName,
      fetchDocuments,
      fetchstockTransfer,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StandardStockTransit);
