import React, { useMemo } from 'react';
import '@ant-design/compatible/assets/index.css';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Row, Col, Button } from "antd";
import Icon1 from "../../../assets/images/icon/eazypostIcon.png";
import Icon2 from "../../../assets/images/icon/shipRocketIcon.png";
import BrevoComp from './Brevo';
import { getPermissionData } from '../../../utils';
import { MODULE_CODED_VALUES } from '../../../static/constants';

function EmailComp(props) {
  const primaryPerm = (props.permissions && props.permissions.primary) || [];

  const emailPermission = useMemo(()=>{
    return getPermissionData(primaryPerm, MODULE_CODED_VALUES.INTEGRATION_EMAIL )
  },[])
  return (
    <div className="container">
      <Row>
        <Col span={24}>
          <div className="header">
            <h2>Email</h2>
            <p>Manage your company Email</p>
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={24} sm={24} md={24} lg={20} xl={12} xxl={8}>
          <div className="paymentOptionArea">
            {props.brevoSettings &&
              props.brevoSettings.length !== 0 &&
              props.brevoSettings.map((data, i) => {
                return (
                  <div className="paymentOption" title="">
                    <div className="nameArea">
                      <div>
                        <h2>Brevo</h2>
                        <div className="cardName">
                          {data &&
                            data.brevoEmailSettingId &&
                            data.userDefinedName ? (
                            data.userDefinedName
                          ) : (
                            <FormattedMessage id="authorize.net.logo" />
                          )}
                        </div>
                      </div>

                    </div>
                    <div className="buttonArea">
                      {data &&
                        data.brevoEmailSettingId ? (
                        <div className="activeBtn">Active</div>
                      ) : (
                        ""
                      )}
                      {/* <Button
                              className="ant-btn-primary editButton"
                              title="Edit"
                              onClick={() => {
                                editName(
                                  props,
                                  data,
                                  MODAL_TYPE.CUSTOM_NAME
                                );
                              }}
                            >
                              Edit
                            </Button> */}

                      {data &&
                        data.brevoEmailSettingId && emailPermission.delete ? (
                        <Button
                          className="ant-btn-danger"
                          onClick={() => {
                            const modalData = {
                              modalBody: (
                                <FormattedMessage
                                  id="disconnect.confirmation"
                                  defaultMessage=""
                                />
                              ),
                              handleSubmit: () => {
                                props.disconnectConfiguration(
                                  data,
                                  props
                                );
                              },
                            };
                            props.pushModalToStack(modalData);
                          }}
                        >
                          Disconnect{" "}
                        </Button>
                      ) : (
                        emailPermission.create &&  <Button
                          className="ant-btn-primary"
                          onClick={() => {
                            props.pushModalToStack({
                              modalBody: <BrevoComp {...props} />,
                              title: (
                                <FormattedMessage
                                  id="cardConnect.logo"
                                  defaultMessage=""
                                />
                              ),
                              width: 500,
                              hideFooter: true,
                              wrapClassName: "brevo-modal",
                            });
                          }}
                        >
                          Connect
                        </Button>
                      )}
                    </div>

                  </div>
                );
              })}
            <div className="paymentOption" title="">
              {props.cardConnectConfigData &&
                props.cardConnectConfigData.brevoEmailSettingId ? (
                <div className="cr cr-top cr-left cr-green">Integrated</div>
              ) : (
                ""
              )}
              <div className="nameArea">
                <h2>Brevo</h2>
              </div>
              <div className="buttonArea">
                {props.cardConnectConfigData &&
                  props.cardConnectConfigData.brevoEmailSettingId ? (
                  <Button
                    style={{ marginLeft: "80px" }}
                    className="ant-btn-danger"
                    onClick={() => {
                      const modalData = {
                        modalBody: (
                          <FormattedMessage
                            id="disconnect.confirmation"
                            defaultMessage=""
                          />
                        ),
                        handleSubmit: () => {
                          props.disconnectConfiguration(
                            props.cardConnectConfigData,
                            props
                          );
                        },
                      };
                      props.pushModalToStack(modalData);
                    }}
                  >
                    Disconnect{" "}
                  </Button>
                ) : (
                  <Button
                    style={{ marginLeft: "0px" }}
                    className="ant-btn-primary"
                    onClick={() => {
                      props.pushModalToStack({
                        modalBody: <BrevoComp {...props} />,
                        title: (
                          <FormattedMessage
                            id="cardConnect.logo"
                            defaultMessage=""
                          />
                        ),
                        width: 500,
                        hideFooter: true,
                        wrapClassName: "brevo-modal",
                      });
                    }}
                    
                  >
                    Connect
                  </Button>
                )}
              </div>

            </div>

          </div>


          {/* <div className="paymentOptionArea">
            <div className='paymentOption'>
              <div className='nameArea'>
                <img src={Icon1} alt="" />
                <h2>Brevo</h2>
              </div>
              <div className='buttonArea'>
                <button onClick={() => {
                  props.pushModalToStack({
                    title: "Email",
                    modalBody: <BrevoComp {...props} />,
                    width: 500,
                    hideFooter: true,
                    wrapClassName: "brevo-modal",
                  })
                }} className='notActiveButton cursor-pointer'>Connect</button>
              </div>
            </div>
          </div> */}
        </Col>
      </Row>
    </div>
  );

}

export default injectIntl(EmailComp);