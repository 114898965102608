import React, { Fragment } from 'react';
import { getInitialsFromString } from '../../utils';
import { Divider } from 'antd';


const RecentActivity = (props) => {

    return<>
  <div className="coming-soon-text">Coming soon</div>
<div className="background-blur">
  <div className='icon_name_label'>
    <div className="name-icon">
      {getInitialsFromString("Anjali Tomar") || "AT"}
    </div>
    <div style={{ width: "87%" }}>
      <div className='common_color'>Created Assignment</div>
      <b>Anjali Tomar</b>
      <div>has just created an assignment for class V. has just created an assignment for class V. has just created an assignment for class V.</div>
      <div className='pt5'>Sept 25, 2024  11:20AM</div>
    </div>
  </div>
  <Divider />
  <div className='icon_name_label'>
    <div className="name-icon">
      {getInitialsFromString("Farah") || "AT"}
    </div>
    <div style={{ width: "87%" }}>
      <div className='common_color'>Created Assignment</div>
      <b>Farah</b>
      <div>has just created an assignment for class V. has just created an assignment for class V. has just created an assignment for class V.</div>
      <div className='pt5'>Sept 25, 2024  11:20AM</div>
    </div>
  </div>
  <Divider />
  <div className='icon_name_label'>
    <div className="name-icon">
      {getInitialsFromString("Ruby Pal") || "AT"}
    </div>
    <div style={{ width: "87%" }}>
      <div className='common_color'>Created Assignment</div>
      <b>Ruby Pal</b>
      <div>has just created an assignment for class V. has just created an assignment for class V. has just created an assignment for class V.</div>
      <div className='pt5'>Sept 25, 2024  11:20AM</div>
    </div>
  </div>
</div>

    
    </>
};

export default RecentActivity;