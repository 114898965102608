import axios from 'axios';
import config from '../../../../../config/environmentConfig';
import { COMMON_ACTIONS } from '../../../../../static/constants';
import { showToasterMessage } from '../../../../../utils';
const { lang } = require('../../../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');


export const uploadVariantImage = (payload, props) => {
  var formData = new FormData();
  if (props.files && props.files.length) {
    props.files.forEach((file) => {
      formData.append('files', file.originFileObj, file.originFileObj?.name);
    })
  }

  if (props.videos && props.videos.length) {
    props.videos.forEach((file) => {
      formData.append('video', file.originFileObj, file.originFileObj?.name);
    })
  }

  if(props.link?.length){
    formData.append('link', props.link);

  }
  formData.append('relationshipId', JSON.stringify(payload.relationshipId));
  formData.append('variantId',JSON.stringify(payload.variantId) );
  formData.append('imageSeqList',JSON.stringify(payload.imageval) );
  formData.append('bucketName',payload.bucketName );

  return dispatch => {
    return axios
   
    .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/inventoryItem/addImageWithVariant`,
        formData, {
        headers: {
          'content-type': 'multipart/form-data'
        }
      }
      )
      .then(res => {
        showToasterMessage({ messageType: "success", description: lang[(res.data || {}).message] || "file Uploaded Successfully" });
        let data = {
          relationshipId: payload.relationshipId || 0,
          pageSize: 100,
          pageNumber: 1,
        }

      })
      .catch(err => {
        // if (lang[((err.response || {}).data || {}).message]) {
          showToasterMessage({ messageType: "error", description: lang[((err.response || {}).data || {}).message] || "Please reselect the upload file." });
        // } else {
        //   const errorTime = new Date();
        //   var dif = startTime.getTime() - errorTime.getTime();

        //   var Seconds_from_T1_to_T2 = dif / 1000;
        //   var Seconds_Between_Dates = Math.abs(Seconds_from_T1_to_T2);
          
        //   if (Seconds_Between_Dates > 55){
        //     showToasterMessage({ messageType: "success", description: lang["productUpload.inprogress.messsage"] || "Product upload in progress you will be notified once the upload is done" });
            
        //   }else{
        //     showToasterMessage({ messageType: "error", description: "Please reselect the upload file." });
        //   }
            
        //   props.popModalFromStack();
        // }

      })
  }
}

export const imagePreview = (payload,callBack) => {
  return (dispatch) => {
    return axios
      .get(
        `${config.AWS_BASE_URL}${config.API_VERSION}/${config.rootContext}/api/documents/readDocument?objectKey=${payload.objectKey}&bucketName=${payload.bucketName}&relationshipId=${payload.relationshipId}&folderName=${payload.folderName}`,
        { responseType: "arraybuffer" }
      )
      .then((res) => {
        const content = res.headers["content-type"];
        showToasterMessage({
          description: (res || {}).message || "Download successfully.",
          messageType: "success",
        });
        callBack(res.data)
        dispatch({ type: COMMON_ACTIONS.VARIANT_IMAGE_UPLOAD, data:res.data });
      })
      .catch((err) => {
      });
  };
};
