import React, { Fragment } from "react";
import { injectIntl, FormattedMessage } from "react-intl";
// import { Link } from "react-router-dom";
// import { AgGridReact } from "ag-grid-react";
import { Tooltip, Skeleton, Pagination, Checkbox,Empty } from "antd";
import {
  fetchPaginationDataIfNeeded,
  showToasterMessage,
  getMomentDateForUIReadOnly,
} from "../../../../utils";
import SalesInvoiceDetail from "../../../../containers/modal/modalBody/customer/SalesInvoiceDetail";
//import { AG_GRID_CONSTANTS } from '../../../../static/agGridConstants';
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import "./index.scss";
import { CONSTANTS } from "../../../../static/constants";
import checkIcon from "../../../../assets/images/check.png";
import crossIcon from "../../../../assets/images/cross.png";
import find from "lodash.find";
import CustomerProfile from '../../../../containers/modal/modalBody/customer/Profile'

const format = CONSTANTS.DISPLAY_DATE_FORMAT;

const RecurringInvoiceListingComp = (props) => {
  // const [isAllChecked, toggleAllChecked] = useState(false);
  const {
    recurringSelectedItems,
    recurringInvoiceList,
    updateState,
    companyInfo,
    recurringInvoiceCount,
    pageNumber,
  } = props;

  if (!recurringSelectedItems) {
    props.updateState({ recurringSelectedItems: [] });
  }

  const showTotal = (total) => {
    return (
      props.intl.formatMessage({
        id: "pagination.text.total",
        defaultMessage: "",
      }) +
      ` ${total} ` +
      props.intl.formatMessage({
        id: "pagination.text.items",
        defaultMessage: "",
      })
    );
  };

  const loadPageData = (pageNumber, pageSize) => {
    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      pageNumber: pageNumber,
      pageSize: pageSize,
    };
    fetchPaginationDataIfNeeded(
      "fetchRecurringInvoices",
      "recurringInvoiceList",
      props,
      payload
    );
  };
  const handlePageSizeChange = (pageNumber, pageSize, props) => {
    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      pageNumber: pageNumber,
      pageSize: pageSize,
    };
    props.resetPaginatedData();
    props.fetchRecurringInvoices(payload);
    //props.updateState({ pageSize: pageSize, pageNumber: pageNumber })
  };

  // const onGridReady = (params) => {
  //   params.api.sizeColumnsToFit();
  //   props.updateState({
  //     gridApi: params.api,
  //     gridColumnApi: params.columnApi,
  //   });
  // };

  const openSalesInvoiceDetails = (data) => {
    props.pushModalToStack({
      modalBody: <SalesInvoiceDetail {...props} invoicePayload={data} />,
      width: "100%",
      hideFooter: true,
      wrapClassName: "modal-custom-detail",
    });
  };

  // const saveColumnIndex = (columnState) => {
  //     let data = props.txPreferences || {};
  //     let tablePreference = {};
  //     data.relationshipId = (props.companyInfo || {}).relationshipId;
  //     data.clientUserAccountId = (props.userInfo || {}).userId;
  //     if (data.tablePreference) {
  //         tablePreference = JSON.parse(data.tablePreference);
  //     }
  //     tablePreference.columnSetting = columnState;
  //     data.tablePreference = JSON.stringify(tablePreference);
  //     data.preferenceId = props.preferenceId;
  //     data.gridPreference = props.gridPreference;
  //     props.saveUserPrefrences(data);
  // }

  // const saveColumnWidth = (columnState, width) => {
  //     let data = props.txPreferences || {};
  //     let tablePreference = {};
  //     data.relationshipId = (props.companyInfo || {}).relationshipId;
  //     data.clientUserAccountId = (props.userInfo || {}).userId;
  //     if (data.tablePreference) {
  //         tablePreference = JSON.parse(data.tablePreference);
  //     }
  //     tablePreference.columnSetting = columnState;
  //     tablePreference.columnList = props.txColumns;
  //     data.tablePreference = JSON.stringify(tablePreference);
  //     data.preferenceId = props.preferenceId;
  //     data.gridPreference = props.gridPreference;
  //     props.saveUserPrefrences(data, props);
  // }

  // let moveEvent = {};
  // const onColumnMoved = (event, isDragStopped) => {
  //     if (event.column) {
  //         moveEvent = event;
  //     }
  //     if (isDragStopped && moveEvent.column && moveEvent.column.colDef && moveEvent.source !== AG_GRID_CONSTANTS.EVENT_SOURCE.API) {
  //         saveColumnIndex(moveEvent.columnApi.getColumnState());
  //     }
  // }

  // const onColumnResized = (event) => {
  //     event.api.resetRowHeights();
  //     if (event.type === 'columnResized' && event.source !== 'sizeColumnsToFit' && event.source !== 'api' && event.finished === true) {
  //         event.api.resetRowHeights();
  //         if (props.gridColumnApi.columnController.bodyWidth < props.gridColumnApi.columnController.scrollWidth) {
  //             const columnArray = event.columnApi.getColumnState();
  //             for (let i = (columnArray.length - 1); i >= 0; i--) {
  //                 if (columnArray[i] && !columnArray[i].hide && columnArray[i].colId !== 'action_1') {
  //                     columnArray[i].width = columnArray[i].width + (props.gridColumnApi.columnController.scrollWidth - props.gridColumnApi.columnController.bodyWidth);
  //                     break;
  //                 }
  //             }
  //             saveColumnWidth(columnArray);
  //         } else {
  //             saveColumnWidth(event.columnApi.getColumnState());
  //         }
  //     }
  // }

  // const _isColumnToHide = (columnName) => {
  //   return props.txColumns.indexOf(columnName) < 0 &&
  //     props.txMandatoryColumns.indexOf(columnName) < 0
  //     ? true
  //     : false;
  // };

  // const columnDefs = [
  //   {
  //     field: "isChecked",
  //     colId: "isChecked_1",
  //     headerComponentFramework: () => <Fragment />,
  //     cellRendererFramework: (params) => {
  //       return (
  //         <Checkbox
  //           onChange={(e) => {
  //             params.node.setDataValue("isChecked_1", e.target.checked);
  //             params.node.data.isChecked = e.target.checked;
  //           }}
  //           checked={params.data.isChecked}
  //         />
  //       );
  //     },
  //     width: 50,
  //     resizable: false,
  //   },
  //   // {
  //   //     headerComponentFramework: () => {
  //   //         return <FormattedMessage id='recurringInvoiceNumber.text' defaultMessage='' />;
  //   //     },
  //   //     field: "recurringInvoiceId",
  //   //     resizable: true,
  //   //     //hide: _isColumnToHide('invoiceNumber'),
  //   //     colId: 'recurringInvoiceId_1',
  //   //     width: (props.txColumnSetting['recurringInvoiceId'] || {}).width,
  //   //     cellRendererFramework: (link) => <div className="cursor-pointer" onClick={() => {
  //   //       //  openSalesInvoiceDetails(link.data)
  //   //     }}>INV-{link.data.recurringInvoiceId}</div>,
  //   // },

  //   {
  //     headerComponentFramework: () => {
  //       return (
  //         <FormattedMessage
  //           id="customer.salesInvoice.listing.header.invoiceNo"
  //           defaultMessage=""
  //         />
  //       );
  //     },
  //     field: "invoiceNumber",
  //     resizable: true,
  //     //hide: _isColumnToHide('invoiceNumber'),
  //     colId: "invoiceNumber_1",
  //     width: (props.txColumnSetting["invoiceNumber"] || {}).width,
  //     cellRendererFramework: (link) => (
  //       <div
  //         className="cursor-pointer"
  //         onClick={() => {
  //           openSalesInvoiceDetails(link.data);
  //         }}
  //       >
  //         {link.data.invoiceNumber}
  //       </div>
  //     ),
  //   },

  //   {
  //     headerComponentFramework: () => {
  //       return (
  //         <FormattedMessage
  //           id="customer.salesInvoice.listing.header.customer"
  //           defaultMessage=""
  //         />
  //       );
  //     },
  //     field: "customerName",
  //     resizable: true,
  //     //hide: _isColumnToHide('customerName'),
  //     colId: "customerName_1",
  //     width: (props.txColumnSetting["customerName"] || {}).width,
  //     cellRendererFramework: (link) => (
  //       <div className="cursor-pointer">
  //         <Link
  //           to={{
  //             pathname: "customers/details",
  //             state: {
  //               customerId: link.data.customerId,
  //               customerName: link.data.customerName,
  //             },
  //           }}
  //           className="company-name"
  //         >
  //           {link.data.customerName}
  //         </Link>
  //       </div>
  //     ),
  //   },
  //   {
  //     headerComponentFramework: () => {
  //       return (
  //         <div>
  //           <FormattedMessage id="common.active" defaultMessage="" />{" "}
  //         </div>
  //       );
  //     },
  //     field: "isActive",
  //     colId: "isActive_1",
  //     width: 80,
  //     cellRendererFramework: (link) => {
  //       return link.value ? (
  //         <img
  //           src={checkIcon}
  //           style={{ height: "15px", width: "15px" }}
  //           alt="Invoice"
  //         />
  //       ) : (
  //         <img
  //           src={crossIcon}
  //           style={{ height: "10px", width: "10px" }}
  //           alt="Invoice"
  //         />
  //       );
  //     },
  //     resizable: true,
  //   },
  //   {
  //     headerComponentFramework: () => {
  //       return (
  //         <div>
  //           <FormattedMessage
  //             id="recurringInvoice.setup.emailCustomer"
  //             defaultMessage=""
  //           />{" "}
  //         </div>
  //       );
  //     },
  //     field: "isSendEmail",
  //     colId: "isSendEmail_1",
  //     width: 120,
  //     cellRendererFramework: (link) => {
  //       return link.value ? (
  //         <img
  //           src={checkIcon}
  //           style={{ height: "15px", width: "15px" }}
  //           alt="Invoice"
  //         />
  //       ) : (
  //         <img
  //           src={crossIcon}
  //           style={{ height: "10px", width: "10px" }}
  //           alt="Invoice"
  //         />
  //       );
  //     },
  //     resizable: true,
  //   },
  //   {
  //     headerComponentFramework: () => {
  //       return (
  //         <div>
  //           <FormattedMessage
  //             id="recurringInvoice.setup.period"
  //             defaultMessage=""
  //           />{" "}
  //         </div>
  //       );
  //     },
  //     field: "period",
  //     colId: "period_1",
  //     width: 80,
  //     cellRendererFramework: (link) => {
  //       switch (link.value) {
  //         case "daily": {
  //           return (
  //             <div>
  //               <FormattedMessage
  //                 id="recurringInvoice.setup.daily"
  //                 defaultMessage=""
  //               />{" "}
  //             </div>
  //           );
  //         }
  //         case "weekly": {
  //           return (
  //             <div>
  //               <FormattedMessage
  //                 id="recurringInvoice.setup.weekly"
  //                 defaultMessage=""
  //               />{" "}
  //             </div>
  //           );
  //         }
  //         case "monthly": {
  //           return (
  //             <div>
  //               <FormattedMessage
  //                 id="recurringInvoice.setup.monthly"
  //                 defaultMessage=""
  //               />{" "}
  //             </div>
  //           );
  //         }
  //         case "yearly": {
  //           return (
  //             <div>
  //               <FormattedMessage
  //                 id="recurringInvoice.setup.yearly"
  //                 defaultMessage=""
  //               />{" "}
  //             </div>
  //           );
  //         }
  //         default:
  //           return <div></div>;
  //       }
  //     },
  //     resizable: true,
  //   },
  //   {
  //     headerComponentFramework: () => {
  //       return (
  //         <div>
  //           <FormattedMessage id="dayOfWeek" defaultMessage="" />{" "}
  //         </div>
  //       );
  //     },
  //     field: "dayOfWeek",
  //     colId: "dayOfWeek_1",
  //     width: 120,
  //     cellRendererFramework: (link) => {
  //       //return link.value || '-';
  //       switch (link.value) {
  //         case 1: {
  //           return (
  //             <div>
  //               <FormattedMessage id="sunday" defaultMessage="" />{" "}
  //             </div>
  //           );
  //         }
  //         case 2: {
  //           return (
  //             <div>
  //               <FormattedMessage id="monday" defaultMessage="" />{" "}
  //             </div>
  //           );
  //         }
  //         case 3: {
  //           return (
  //             <div>
  //               <FormattedMessage id="tuesday" defaultMessage="" />{" "}
  //             </div>
  //           );
  //         }
  //         case 4: {
  //           return (
  //             <div>
  //               <FormattedMessage id="wednusday" defaultMessage="" />{" "}
  //             </div>
  //           );
  //         }
  //         case 5: {
  //           return (
  //             <div>
  //               <FormattedMessage id="thursday" defaultMessage="" />{" "}
  //             </div>
  //           );
  //         }
  //         case 6: {
  //           return (
  //             <div>
  //               <FormattedMessage id="friday" defaultMessage="" />{" "}
  //             </div>
  //           );
  //         }
  //         case 7: {
  //           return (
  //             <div>
  //               <FormattedMessage id="saturday" defaultMessage="" />{" "}
  //             </div>
  //           );
  //         }
  //         default:
  //           return <div>-</div>;
  //       }
  //     },
  //     resizable: true,
  //   },
  //   {
  //     headerComponentFramework: () => {
  //       return (
  //         <div>
  //           <FormattedMessage id="dayOfMonth" defaultMessage="" />{" "}
  //         </div>
  //       );
  //     },
  //     field: "dayOfMonth",
  //     colId: "dayOfMonth_1",
  //     width: 120,
  //     cellRendererFramework: (link) => {
  //       return link.value || "-";
  //     },
  //     resizable: true,
  //   },
  //   {
  //     headerComponentFramework: () => {
  //       return (
  //         <div>
  //           <FormattedMessage id="dayOfYear" defaultMessage="" />{" "}
  //         </div>
  //       );
  //     },
  //     field: "dayOfYear",
  //     colId: "dayOfYear_1",
  //     width: 120,
  //     cellRendererFramework: (link) => {
  //       return link.value || "-";
  //     },
  //     resizable: true,
  //   },
  //   {
  //     headerComponentFramework: () => {
  //       return (
  //         <div>
  //           <FormattedMessage id="monthOfYear" defaultMessage="" />{" "}
  //         </div>
  //       );
  //     },
  //     field: "monthOfYear",
  //     colId: "monthOfYear_1",
  //     width: 120,
  //     cellRendererFramework: (link) => {
  //       switch (link.value) {
  //         case 1: {
  //           return (
  //             <div>
  //               <FormattedMessage id="january" defaultMessage="" />{" "}
  //             </div>
  //           );
  //         }
  //         case 2: {
  //           return (
  //             <div>
  //               <FormattedMessage id="february" defaultMessage="" />{" "}
  //             </div>
  //           );
  //         }
  //         case 3: {
  //           return (
  //             <div>
  //               <FormattedMessage id="march" defaultMessage="" />{" "}
  //             </div>
  //           );
  //         }
  //         case 4: {
  //           return (
  //             <div>
  //               <FormattedMessage id="april" defaultMessage="" />{" "}
  //             </div>
  //           );
  //         }
  //         case 5: {
  //           return (
  //             <div>
  //               <FormattedMessage id="may" defaultMessage="" />{" "}
  //             </div>
  //           );
  //         }
  //         case 6: {
  //           return (
  //             <div>
  //               <FormattedMessage id="june" defaultMessage="" />{" "}
  //             </div>
  //           );
  //         }
  //         case 7: {
  //           return (
  //             <div>
  //               <FormattedMessage id="july" defaultMessage="" />{" "}
  //             </div>
  //           );
  //         }
  //         case 8: {
  //           return (
  //             <div>
  //               <FormattedMessage id="august" defaultMessage="" />{" "}
  //             </div>
  //           );
  //         }
  //         case 9: {
  //           return (
  //             <div>
  //               <FormattedMessage id="september" defaultMessage="" />{" "}
  //             </div>
  //           );
  //         }
  //         case 10: {
  //           return (
  //             <div>
  //               <FormattedMessage id="october" defaultMessage="" />{" "}
  //             </div>
  //           );
  //         }
  //         case 11: {
  //           return (
  //             <div>
  //               <FormattedMessage id="november" defaultMessage="" />{" "}
  //             </div>
  //           );
  //         }
  //         case 12: {
  //           return (
  //             <div>
  //               <FormattedMessage id="december" defaultMessage="" />{" "}
  //             </div>
  //           );
  //         }
  //         default:
  //           return <div>-</div>;
  //       }
  //     },
  //     resizable: true,
  //   },

  //   // {
  //   //     headerComponentFramework: () => {
  //   //         return <FormattedMessage id='common.dateCreated' defaultMessage='' />;
  //   //     },
  //   //     resizable: true,
  //   //     field: "dateCreated",
  //   //     //hide: _isColumnToHide('invoiceDate'),
  //   //     colId: 'dateCreated_1',
  //   //     width: (props.txColumnSetting['dateCreated'] || {}).width,
  //   //     cellRendererFramework: (link) => <div>
  //   //         {link.data.dateCreated ? moment(new Date(link.data.dateCreated)).format(format) : ''}
  //   //     </div>,
  //   // },
  //   {
  //     headerComponentFramework: () => {
  //       return (
  //         <FormattedMessage
  //           id="inventory.priceList.listing.header.startDate"
  //           defaultMessage=""
  //         />
  //       );
  //     },
  //     resizable: true,
  //     field: "startDate",
  //     //hide: _isColumnToHide('invoiceDate'),
  //     colId: "startDate_1",
  //     width: (props.txColumnSetting["startDate"] || {}).width,
  //     cellRendererFramework: (link) => (
  //       <div>
  //         <Tooltip
  //           placement="topLeft"
  //           title={
  //             link.data.startDate &&
  //             getMomentDateForUIReadOnly({
  //               date: link.data.startDate,
  //               format: CONSTANTS.DISPLAY_TOOLTIP_DATE_FORMAT,
  //             })
  //           }
  //         >
  //           {link.data.startDate
  //             ? getMomentDateForUIReadOnly({
  //                 date: new Date(link.data.startDate),
  //                 format,
  //               })
  //             : ""}
  //         </Tooltip>
  //       </div>
  //     ),
  //   },
  //   {
  //     headerComponentFramework: () => {
  //       return (
  //         <FormattedMessage
  //           id="inventory.priceList.listing.header.endDate"
  //           defaultMessage=""
  //         />
  //       );
  //     },
  //     resizable: true,
  //     field: "endDate",
  //     //hide: _isColumnToHide('invoiceDate'),
  //     colId: "endDate_1",
  //     width: (props.txColumnSetting["endDate"] || {}).width,
  //     cellRendererFramework: (link) => (
  //       <div>
  //         <Tooltip
  //           placement="topLeft"
  //           title={
  //             link.data.endDate &&
  //             getMomentDateForUIReadOnly({
  //               date: link.data.endDate,
  //               format: CONSTANTS.DISPLAY_TOOLTIP_DATE_FORMAT,
  //             })
  //           }
  //         >
  //           {link.data.endDate
  //             ? getMomentDateForUIReadOnly({
  //                 date: new Date(link.data.endDate),
  //                 format,
  //               })
  //             : ""}
  //         </Tooltip>
  //       </div>
  //     ),
  //   },
  // ];

  // const agGridStyle = {
  //   height: "100%",
  //   width: "100%",
  // };

  // const onModelUpdated = (params) => {
  //   //params.api.resetRowHeights();
  //   if (
  //     (recurringInvoiceList[pageNumber] || []).length &&
  //     !props.isRowHeightSet
  //   ) {
  //     setTimeout(() => {
  //       params.api.resetRowHeights();
  //     }, 1000);
  //     props.updateState({
  //       isRowHeightSet: true,
  //     });
  //   }
  //   if (props.tableDataReRendered) {
  //     //params.api.resetRowHeights();
  //     props.updateState({
  //       tableDataReRendered: false,
  //     });
  //     const txColumnSetting =
  //       props.txColumnSetting && props.txColumnSetting.length
  //         ? props.txColumnSetting
  //         : params.columnApi.getColumnState();
  //     txColumnSetting.forEach((e) => {
  //       let columnName = e.colId.split("_")[0];
  //       if (columnName !== "action") {
  //         if (columnName === "isChecked") {
  //           e.hide = false;
  //         } else {
  //           e.hide = _isColumnToHide(columnName);
  //         }
  //       }
  //       if (columnName === "action") {
  //         e.hide = false;
  //         e.pinned = "right";
  //       }
  //     });

  //     params.columnApi.setColumnState(txColumnSetting);
  //   }
  // };

  const itemSelection = (item) => {
    let selectItem = find(recurringSelectedItems, {
      recurringInvoiceId: Number(item.recurringInvoiceId),
    });
    let updatedItems = [];
    if (selectItem) {
      updatedItems = recurringSelectedItems.filter(
        (obj) => obj.recurringInvoiceId !== item.recurringInvoiceId
      );
    } else {
      updatedItems = JSON.parse(JSON.stringify(recurringSelectedItems));
      updatedItems.push(item);
    }
    props.updateState({ recurringSelectedItems: updatedItems });
  };

  const selectAll = (context) => {
    if (!context) {
      recurringSelectedItems.splice(0, recurringSelectedItems.length);
      props.updateState({ recurringSelectedItems: recurringSelectedItems });
      return;
    }
    for (let i = 0; i < recurringInvoiceList[pageNumber].length; i++) {
      let item = recurringInvoiceList[pageNumber][i];
      let selectItem = find(recurringSelectedItems, {
        recurringInvoiceId: Number(item.recurringInvoiceId),
      });
      if (!selectItem) {
        recurringSelectedItems.push(item);
      }
    }
    props.updateState({ recurringSelectedItems: recurringSelectedItems });
  };

  const isCheckedAll = () => {
    if (
      !recurringInvoiceList ||
      !recurringInvoiceList[pageNumber] ||
      recurringInvoiceList[pageNumber].length === 0
    ) {
      return false;
    }
    for (let i = 0; i < recurringInvoiceList[pageNumber].length; i++) {
      let item = recurringInvoiceList[pageNumber][i];
      let selectItem = find(recurringSelectedItems, {
        recurringInvoiceId: Number(item.recurringInvoiceId),
      });
      if (!selectItem) {
        return false;
      }
    }
    return true;
  };

  const renderPeriod = (period) => {
    switch (period) {
      case "daily": {
        return (
          <div>
            <FormattedMessage
              id="recurringInvoice.setup.daily"
              defaultMessage=""
            />{" "}
          </div>
        );
      }
      case "weekly": {
        return (
          <div>
            <FormattedMessage
              id="recurringInvoice.setup.weekly"
              defaultMessage=""
            />{" "}
          </div>
        );
      }
      case "monthly": {
        return (
          <div>
            <FormattedMessage
              id="recurringInvoice.setup.monthly"
              defaultMessage=""
            />{" "}
          </div>
        );
      }
      case "yearly": {
        return (
          <div>
            <FormattedMessage
              id="recurringInvoice.setup.yearly"
              defaultMessage=""
            />{" "}
          </div>
        );
      }
      default:
        return <div></div>;
    }
  };

  const renderDayOfWeek = (dayNo) => {
    switch (dayNo) {
      case 1: {
        return (
          <div>
            <FormattedMessage id="sunday" defaultMessage="" />{" "}
          </div>
        );
      }
      case 2: {
        return (
          <div>
            <FormattedMessage id="monday" defaultMessage="" />{" "}
          </div>
        );
      }
      case 3: {
        return (
          <div>
            <FormattedMessage id="tuesday" defaultMessage="" />{" "}
          </div>
        );
      }
      case 4: {
        return (
          <div>
            <FormattedMessage id="wednusday" defaultMessage="" />{" "}
          </div>
        );
      }
      case 5: {
        return (
          <div>
            <FormattedMessage id="thursday" defaultMessage="" />{" "}
          </div>
        );
      }
      case 6: {
        return (
          <div>
            <FormattedMessage id="friday" defaultMessage="" />{" "}
          </div>
        );
      }
      case 7: {
        return (
          <div>
            <FormattedMessage id="saturday" defaultMessage="" />{" "}
          </div>
        );
      }
      default:
        return <div>-</div>;
    }
  };

  const renderMonthOfYear = (monthNo) => {
    switch (monthNo) {
      case 1: {
        return (
          <div>
            <FormattedMessage id="january" defaultMessage="" />{" "}
          </div>
        );
      }
      case 2: {
        return (
          <div>
            <FormattedMessage id="february" defaultMessage="" />{" "}
          </div>
        );
      }
      case 3: {
        return (
          <div>
            <FormattedMessage id="march" defaultMessage="" />{" "}
          </div>
        );
      }
      case 4: {
        return (
          <div>
            <FormattedMessage id="april" defaultMessage="" />{" "}
          </div>
        );
      }
      case 5: {
        return (
          <div>
            <FormattedMessage id="may" defaultMessage="" />{" "}
          </div>
        );
      }
      case 6: {
        return (
          <div>
            <FormattedMessage id="june" defaultMessage="" />{" "}
          </div>
        );
      }
      case 7: {
        return (
          <div>
            <FormattedMessage id="july" defaultMessage="" />{" "}
          </div>
        );
      }
      case 8: {
        return (
          <div>
            <FormattedMessage id="august" defaultMessage="" />{" "}
          </div>
        );
      }
      case 9: {
        return (
          <div>
            <FormattedMessage id="september" defaultMessage="" />{" "}
          </div>
        );
      }
      case 10: {
        return (
          <div>
            <FormattedMessage id="october" defaultMessage="" />{" "}
          </div>
        );
      }
      case 11: {
        return (
          <div>
            <FormattedMessage id="november" defaultMessage="" />{" "}
          </div>
        );
      }
      case 12: {
        return (
          <div>
            <FormattedMessage id="december" defaultMessage="" />{" "}
          </div>
        );
      }
      default:
        return <div>-</div>;
    }
  };

  return (
    <Fragment>
      {/* <div className="invoice-header">
                <PageBreadcrumb breadCrumbList={breadCrumbList} />
            </div> */}
      {/* <div className="view-container mt0"> */}
      <Skeleton loading={props.isLoading} active paragraph={{ rows: 12 }}>
        {/* <div className="table-action-container"> */}
        <div className="view-container-actions">
          <div className="left-actions">
            <div className="table-heading">
              <FormattedMessage
                id={props.isSchoolUser ? 'heading.fees.feesInvoiceList':"heading.sales.salesInvoice"}
                defaultMessage=""
              />
            </div>
            {recurringInvoiceCount ? (
              <>
                <div className="vertical-sep" />
                <div>{recurringInvoiceCount}</div>
              </>
            ) : (
              ""
            )}
          </div>
          {/* <button className="all-checkbox-control">
                                <Checkbox
                                    checked={isAllChecked}
                                    onChange={(e) => {
                                        toggleAllChecked(e.target.checked);
                                        props.gridApi.forEachNode(obj => {
                                            obj.setDataValue('isChecked_1', e.target.checked);
                                        })
                                    }}
                                />
                            </button> */}
          <div className="right-actions">
            <button
              className="all-checkbox-control cursor-pointer"
              onClick={() => {
                const invoiceList = [];
                // props.gridApi.forEachNode(obj => {
                //     if (obj.data.isChecked) {
                //         invoiceList.push(obj.data);
                //     }
                // });
                recurringSelectedItems.forEach((obj) => {
                  invoiceList.push(obj);
                });

                if (invoiceList.length > 0) {
                  const modalBody = (
                    <FormattedMessage
                      id="common.delete.confirmation"
                      defaultMessage=""
                    />
                  );
                  const modalData = {
                    modalBody,
                    handleSubmit: () => {
                      props.deleteRecurringInvoices({
                        relationshipId: props.companyInfo.relationshipId,
                        recurringInvoiceList: invoiceList,
                        pageNumber: props.pageNumber,
                        pageSize: props.pageSize,
                      });
                      props.hideModal();
                      // toggleAllChecked(false);
                    },
                  };
                  props.showModal(modalData);
                } else {
                  return showToasterMessage({
                    description: props.intl.formatMessage({
                      id: "common.record.notSelected.proceeding",
                      defaultMessage: "",
                    }),
                    messageType: "error",
                  });
                }
              }}
            >
              <Tooltip
                placement="bottom"
                title={props.intl.formatMessage({
                  id: "delete.messaget",
                  defaultMessage: "",
                })}
              >
                <i className="fa fa-trash" />
              </Tooltip>
            </button>

            <button
              className="all-checkbox-control cursor-pointer"
              onClick={() => {
                const invoiceList = [];
                // props.gridApi.forEachNode(obj => {
                //     if (obj.data.isChecked) {
                //         invoiceList.push(obj.data.recurringInvoiceId);
                //     }
                // });
                recurringSelectedItems.forEach((obj) => {
                  invoiceList.push(obj);
                });

                if (invoiceList.length > 0) {
                  const modalBody = (
                    <FormattedMessage
                      id="recurringInvoice.inactive.confirmation"
                      defaultMessage=""
                    />
                  );
                  const modalData = {
                    modalBody,
                    handleSubmit: () => {
                      props.activeRecurringInvoices({
                        relationshipId: props.companyInfo.relationshipId,
                        recurringInvoiceIdList: invoiceList,
                        isActive: 0,
                        pageNumber: props.pageNumber,
                        pageSize: props.pageSize,
                      });
                      props.hideModal();
                      // toggleAllChecked(false);
                    },
                  };
                  props.showModal(modalData);
                } else {
                  return showToasterMessage({
                    description: props.intl.formatMessage({
                      id: "common.record.notSelected.proceeding",
                      defaultMessage: "",
                    }),
                    messageType: "error",
                  });
                }
              }}
            >
              <Tooltip
                placement="bottom"
                title={props.intl.formatMessage({
                  id: "in.Active",
                  defaultMessage: "",
                })}
              >
                <i className="fa fa-times" />
              </Tooltip>
            </button>

            <button
              className="all-checkbox-control cursor-pointer"
              onClick={() => {
                const invoiceList = [];
                // props.gridApi.forEachNode(obj => {
                //     if (obj.data.isChecked) {
                //         invoiceList.push(obj.data.recurringInvoiceId);
                //     }
                // });
                recurringSelectedItems.forEach((obj) => {
                  invoiceList.push(obj);
                });

                if (invoiceList.length > 0) {
                  const modalBody = (
                    <FormattedMessage
                      id="recurringInvoice.active.confirmation"
                      defaultMessage=""
                    />
                  );
                  const modalData = {
                    modalBody,
                    handleSubmit: () => {
                      props.activeRecurringInvoices({
                        relationshipId: props.companyInfo.relationshipId,
                        recurringInvoiceIdList: invoiceList,
                        isActive: 1,
                        pageNumber: props.pageNumber,
                        pageSize: props.pageSize,
                      });
                      props.hideModal();
                      // toggleAllChecked(false);
                    },
                  };
                  props.showModal(modalData);
                } else {
                  return showToasterMessage({
                    description: props.intl.formatMessage({
                      id: "common.record.notSelected.proceeding",
                      defaultMessage: "",
                    }),
                    messageType: "error",
                  });
                }
              }}
            >
              <Tooltip
                placement="bottom"
                title={props.intl.formatMessage({
                  id: "make.Active",
                  defaultMessage: "",
                })}
              >
                <i className="fa fa-check" />
              </Tooltip>
            </button>
          </div>
        </div>
        {/* </div> */}

        {/* <div className="agGridWrapper"> */}
        {/* <div className="ag-theme-balham" style={agGridStyle} >
                            <AgGridReact
                                columnDefs={columnDefs}
                                rowData={recurringInvoiceList[pageNumber] || []}
                                rowDragManaged={true}
                                defaultColDef={{
                                    flex: 1,
                                    autoHeight: true,
                                    wrapText: true,
                                }}
                                domLayout={"autoHeight"}
                                animateRows={true}
                                //getRowHeight={getRowHeight}
                                onGridReady={onGridReady}
                                onModelUpdated={onModelUpdated}
                            // onColumnResized={onColumnResized}
                            // onDragStopped={() => {
                            //     onColumnMoved({}, true);
                            // }}
                            // //onColumnMoved={onColumnMoved}
                            >
                            </AgGridReact>
                        </div> */}
        <div className="table-container">
          <table id="recurring-invoice-list">
            <thead>
              <tr>
                <th>
                  <Checkbox
                    onChange={(e) => {
                      selectAll(e.target.checked);
                    }}
                    checked={isCheckedAll()}
                  />
                </th>

                <th>
                  <FormattedMessage
                    id="customer.salesInvoice.listing.header.invoiceNo"
                    defaultMessage=""
                  />
                </th>

                <th>
                  <FormattedMessage
                    id="customer.salesInvoice.listing.header.customer"
                    defaultMessage=""
                  />
                </th>

                <th>
                  <FormattedMessage id="common.active" defaultMessage="" />
                </th>

                <th>
                  <FormattedMessage
                    id="recurringInvoice.setup.emailCustomer"
                    defaultMessage=""
                  />
                </th>

                <th>
                  <FormattedMessage
                    id="recurringInvoice.setup.period"
                    defaultMessage=""
                  />
                </th>

                <th>
                  <FormattedMessage id="dayOfWeek" defaultMessage="" />{" "}
                </th>

                <th>
                  <FormattedMessage id="dayOfMonth" defaultMessage="" />{" "}
                </th>

                <th>
                  <FormattedMessage id="dayOfYear" defaultMessage="" />
                </th>

                <th>
                  <FormattedMessage id="monthOfYear" defaultMessage="" />
                </th>

                <th>
                  <FormattedMessage
                    id="inventory.priceList.listing.header.startDate"
                    defaultMessage=""
                  />
                </th>

                <th>
                  <FormattedMessage
                    id="inventory.priceList.listing.header.endDate"
                    defaultMessage=""
                  />
                </th>
              </tr>
            </thead>
            <tbody>
              {recurringInvoiceList && (recurringInvoiceList[pageNumber] || []).length
                ? recurringInvoiceList[pageNumber].map((item, i) => {
                    return (
                      <tr key={i}>
                        <td>
                          <Checkbox
                            onClick={() => {
                              itemSelection(item);
                            }}
                            checked={
                              find(recurringSelectedItems, {
                                recurringInvoiceId: Number(
                                  item.recurringInvoiceId
                                ),
                              })
                                ? true
                                : false
                            }
                          />
                        </td>

                        <td>
                          <div
                            className="cursor-pointer"
                            onClick={() => {
                              openSalesInvoiceDetails(item);
                            }}
                          >
                            {item.invoiceNumber}
                          </div>
                        </td>

                        <td>
                          <div className="cursor-pointer">
                            <div
                             onClick={() => {
                              props.pushModalToStack({  
                        
                                modalBody: <CustomerProfile {...props}
                                customerId= {item.customerId}
                              //   customerName={rowData.customerName}
                              //   customerDetails={rowData}
                            />,
                            width: '100%',
                            hideTitle: true,  
                            hideFooter: true,
                            wrapClassName: "new-transaction-wrapper",
                            })
                            }}
                              // onClick={() => {
                              //   props.history.push("customers/details", {
                              //     customerId: item.customerId,
                              //     customerName: item.customerName,
                              //   });
                              // }}
                            >
                              {item.customerName}
                            </div>
                          </div>
                        </td>

                        <td>
                          {item.isActive ? (
                            <img
                              src={checkIcon}
                              style={{ height: "15px", width: "15px" }}
                              alt="Invoice"
                            />
                          ) : (
                            <img
                              src={crossIcon}
                              style={{ height: "10px", width: "10px" }}
                              alt="Invoice"
                            />
                          )}
                        </td>

                        <td>
                          {item.isSendEmail ? (
                            <img
                              src={checkIcon}
                              style={{ height: "15px", width: "15px" }}
                              alt="Invoice"
                            />
                          ) : (
                            <img
                              src={crossIcon}
                              style={{ height: "10px", width: "10px" }}
                              alt="Invoice"
                            />
                          )}
                        </td>

                        <td>{renderPeriod(item.period)}</td>

                        <td>{renderDayOfWeek(item.dayOfWeek)}</td>

                        <td>{item.dayOfMonth || "-"}</td>

                        <td>{item.dayOfYear || "-"}</td>

                        <td>{renderMonthOfYear(item.monthOfYear)}</td>

                        <td>
                          <div>
                            <Tooltip
                              placement="topLeft"
                              title={
                                item.startDate &&
                                getMomentDateForUIReadOnly({
                                  date: item.startDate,
                                  format: CONSTANTS.DISPLAY_TOOLTIP_DATE_FORMAT,
                                })
                              }
                            >
                              {item.startDate
                                ? getMomentDateForUIReadOnly({
                                    date: new Date(item.startDate),
                                    format,
                                  })
                                : ""}
                            </Tooltip>
                          </div>
                        </td>

                        <td>
                          <div>
                            <Tooltip
                              placement="topLeft"
                              title={
                                item.endDate &&
                                getMomentDateForUIReadOnly({
                                  date: item.endDate,
                                  format: CONSTANTS.DISPLAY_TOOLTIP_DATE_FORMAT,
                                })
                              }
                            >
                              {item.endDate
                                ? getMomentDateForUIReadOnly({
                                    date: new Date(item.endDate),
                                    format,
                                  })
                                : ""}
                            </Tooltip>
                          </div>
                        </td>
                      </tr>
                    );
                  })
                :  <tr key="empty-data-box">
                <td colSpan={"100%"} style={{ borderBottom: "none" }}>
                    <Empty />
                </td>
            </tr>}
            </tbody>
          </table>
        </div>
        {/* </div> */}
      </Skeleton>
      {/* </div> */}
      <div className="footer">
        <div className="f-left"></div>
        <div className="f-right">
          <Pagination
            size="small"
            total={recurringInvoiceCount}
            showTotal={showTotal}
            defaultPageSize={props.pageSize}
            showSizeChanger
            pageSizeOptions={[10, 25, 50, 100, 200]}
            onChange={(pageNumber, pageSize) => {
              loadPageData(pageNumber, pageSize);
              updateState({ pageSize: pageSize, pageNumber: pageNumber });
            }}
            onShowSizeChange={(pageNumber, pageSize) => {
              handlePageSizeChange(pageNumber, pageSize, props);
            }}
          />
        </div>
      </div>
      {/* </div> */}
    </Fragment>
  );
};

export default injectIntl(RecurringInvoiceListingComp);
