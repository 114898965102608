import React, { Fragment, useMemo, useRef } from "react";
import { injectIntl, FormattedMessage } from "react-intl";
import { PictureOutlined, DownloadOutlined, InsertRowRightOutlined } from "@ant-design/icons";
import {
  Pagination,
  Checkbox,
  Input,
  Button,
  Menu,
  Dropdown,
  Popover, Skeleton, Empty
} from "antd";
import Tile from "../../tiles/tilesIndex";
import config from "../../../config/environmentConfig";
import {
  fetchPaginationDataIfNeeded,
  checkACLPermission,
  getAWSFileURL,
  capitalizeFirstLetter,
  exportExcel,
  getPermissionData,
} from "../../../utils";
import {
  ICONS,
  MODULE_CODED_VALUES,
  PERMISSION_VALUES,
} from "../../../static/constants";
import * as debounce from "lodash.debounce";
import "./index.scss";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import { PRODUCT_LISTING_PAGE } from "../../../static/constants";
import LowStock from "../../../containers/modal/modalBody/product/LowStock";
import OutOfStock from "../../../containers/modal/modalBody/product/OutOfStock";
import ReorderStock from "../../../containers/modal/modalBody/product/ReorderStock";
import ProductDetail from "../../../containers/modal/modalBody/product/ProductDetail";
import ImportProducts from "../../../containers/modal/modalBody/product/Import";
import { AddAndRemoveColumn } from "../../general/AddAndRemoveColumn";
import * as find from "lodash.find";
import PageBreadcrumb from "../../PageBreadcrumb";
import DropdownAction from "antd/lib/dropdown";
import {
  AlertTwoTone,
  InteractionTwoTone,
  ProfileOutlined,
  ExclamationCircleTwoTone,
  DollarTwoTone,
  FileExcelOutlined,
  FilePdfOutlined,
  CloseCircleOutlined,
  CheckCircleOutlined,
  DownOutlined,
} from "@ant-design/icons";
import CategoryDetailsModel from "../../../containers/modal/modalBody/product/CategoryDetailsModel";
import ProductQr from "../../../containers/modal/modalBody/product/ProductQr";
import ImageUpload from "../../../containers/modal/modalBody/product/ImageUpload";
import PageHeader from "../../Common/pageHeader";


const { Search } = Input;

// const agGridStyle = {
//   height: "100%",
//   width: "100%",
// };

// const getRowHeight = (params) => {
//     return 32;
// }

const ProductListingComp = (props) => {
  const {
    selectedItems,
    permissions,
    products,
    productCount,
    companyInfo,
    pageNumber,
    searchedText,
    updateState,
    outOfStockCount,
    reorderStockCount,
    lowStockCount,
    inventoryValuation,
  } = props;
  if (!selectedItems) {
    props.updateState({ selectedItems: [] });
  }

  const tilesData = [
    {
      footer: "OUT OF STOCK",
      count: outOfStockCount,
      // icon: "fa fa-minus-circle",
      antIcon: <AlertTwoTone />,
      color: 'tile2',
      type: PRODUCT_LISTING_PAGE.OUT_OF_STOCK,
      moduleCode:MODULE_CODED_VALUES.PRODUCTS,
    },
    {
      footer: "REORDER STOCK",
      count: reorderStockCount,
      // icon: "fa fa-retweet",
      antIcon: <InteractionTwoTone />,
      color: 'tile1',
      type: PRODUCT_LISTING_PAGE.REORDER_STOCK,
      moduleCode:MODULE_CODED_VALUES.PRODUCTS,
    },
    {
      footer: "LOW STOCK",
      count: lowStockCount,
      // icon: "fa fa-exclamation-circle",
      antIcon: <ExclamationCircleTwoTone />,
      color: 'tile4',
      type: PRODUCT_LISTING_PAGE.LOW_STOCK,
      moduleCode:MODULE_CODED_VALUES.PRODUCTS,
    },
    {
      footer: "INVENTORY VALUATION",
      count: inventoryValuation,
      // icon: companyInfo.currencyIcon,
      antIcon: <DollarTwoTone />,
      color: 'tile3',
      moduleCode:MODULE_CODED_VALUES.PRODUCTS,
    },
  ];

  const _isColumnToHide = (columnName) => {
    return props.txColumns.indexOf(columnName) < 0 &&
      props.txMandatoryColumns.indexOf(columnName) < 0
      ? true
      : false;
  };

  //   const onGridReady = (params) => {
  //     props.updateState({
  //       gridApi: params.api,
  //       gridColumnApi: params.columnApi,
  //     });
  //     onDataRendered(params);
  //   };

  // const saveColumnIndex = (columnState) => {
  //   let data = props.txPreferences || {};
  //   let gridPreference = {};
  //   data.relationshipId = (props.companyInfo || {}).relationshipId;
  //   data.clientUserAccountId = (props.userInfo || {}).userId;
  //   if (data.gridPreference) {
  //     gridPreference = JSON.parse(data.gridPreference);
  //   }
  //   gridPreference.columnSetting = columnState;
  //   data.gridPreference = JSON.stringify(gridPreference);

  //   data.preferenceId = props.preferenceId;
  //   data.gridPreference = props.gridPreference;
  //   props.saveUserPrefrences(data);
  // };

  // const saveColumnWidth = (columnState, width) => {
  //   let data = props.txPreferences || {};
  //   let gridPreference = {};
  //   data.relationshipId = (props.companyInfo || {}).relationshipId;
  //   data.clientUserAccountId = (props.userInfo || {}).userId;
  //   if (data.gridPreference) {
  //     gridPreference = JSON.parse(data.gridPreference);
  //   }
  //   gridPreference.columnSetting = columnState;
  //   gridPreference.columnList = props.txColumns;
  //   data.gridPreference = JSON.stringify(gridPreference);
  //   data.preferenceId = props.preferenceId;
  //   data.gridPreference = props.gridPreference;
  //   props.saveUserPrefrences(data, props);
  // };

  // let moveEvent = {};
  //   const onColumnMoved = (event, isDragStopped) => {
  //     if (event.column) {
  //       moveEvent = event;
  //     }
  //     if (
  //       isDragStopped &&
  //       moveEvent.column &&
  //       moveEvent.column.colDef &&
  //       moveEvent.source !== AG_GRID_CONSTANTS.EVENT_SOURCE.API
  //     ) {
  //       saveColumnIndex(moveEvent.columnApi.getColumnState());
  //     }
  //   };

  //   const onColumnResized = (event, tablegridApi, tableGridColumnApi) => {
  //     const gridColumnApi = tableGridColumnApi || props.gridColumnApi;
  //     event.api.resetRowHeights();
  //     if (
  //       event.type === "columnResized" &&
  //       event.source !== "sizeColumnsToFit" &&
  //       event.source !== "api" &&
  //       event.finished === true
  //     ) {
  //       //event.api.resetRowHeights();
  //       if (
  //         gridColumnApi &&
  //         gridColumnApi.columnController.bodyWidth <
  //           gridColumnApi.columnController.scrollWidth
  //       ) {
  //         props.gridApi.sizeColumnsToFit();
  //         setTimeout(() => {
  //           const columnArray = event.columnApi.getColumnState();
  //           saveColumnWidth(columnArray);
  //         }, 1000);
  //       } else {
  //         saveColumnWidth(event.columnApi.getColumnState());
  //       }
  //     }
  //   };

  //   const onModelUpdated = (params) => {
  //     if ((props.products[pageNumber] || []).length && !props.isRowHeightSet) {
  //       setTimeout(() => {
  //         params.api.resetRowHeights();
  //       }, 1000);
  //       props.updateState({
  //         isRowHeightSet: true,
  //       });
  //     }
  //     if (props.tableDataReRendered) {
  //       //params.api.resetRowHeights();
  //       props.updateState({
  //         tableDataReRendered: false,
  //       });
  //       const txColumnSetting =
  //         props.txColumnSetting && props.txColumnSetting.length
  //           ? props.txColumnSetting
  //           : params.columnApi.getColumnState();
  //       txColumnSetting.forEach((e) => {
  //         let columnName = e.colId.split("_")[0];
  //         if (columnName !== "action") {
  //           if (columnName === "isChecked") {
  //             e.hide = false;
  //           } else {
  //             e.hide = _isColumnToHide(columnName);
  //           }
  //         }
  //         if (columnName === "action") {
  //           e.hide = false;
  //           e.pinned = "right";
  //         }
  //         if (columnName === "hsnCodeRate") {
  //           e.hide =
  //             _isColumnToHide("hsnCodeRate") ||
  //             (props.companyInfo || {}).countryName !== "India";
  //         }
  //       });
  //       params.columnApi.setColumnState(txColumnSetting);
  //       setTimeout(() => {
  //         if (
  //           props.gridColumnApi &&
  //           props.gridColumnApi.columnController.bodyWidth <
  //             props.gridColumnApi.columnController.scrollWidth
  //         ) {
  //           params.api.sizeColumnsToFit();
  //         }
  //       }, 500);
  //     }
  //   };

  //   const onDataRendered = (params) => {
  //     const txColumnSetting = props.txColumnSetting;
  //     if (Object.keys(txColumnSetting).length) {
  //       txColumnSetting.forEach((e) => {
  //         let columnName = e.colId.split("_")[0];
  //         if (columnName !== "action") {
  //           if (columnName === "isChecked") {
  //             e.hide = false;
  //           } else {
  //             e.hide = _isColumnToHide(columnName);
  //           }
  //         }
  //         if (columnName === "action") {
  //           e.hide = false;
  //           e.width = 52;
  //           e.pinned = "right";
  //         }
  //         if (columnName === "hsnCodeRate") {
  //           e.hide =
  //             _isColumnToHide("hsnCodeRate") ||
  //             (props.companyInfo || {}).countryName !== "India";
  //         }
  //       });
  //       params.columnApi.setColumnState(txColumnSetting);
  //       setTimeout(() => {
  //         if (
  //           params.columnApi &&
  //           params.columnApi.columnController.bodyWidth <
  //             params.columnApi.columnController.scrollWidth
  //         ) {
  //           params.api.sizeColumnsToFit();
  //         }
  //       }, 500);
  //     } else {
  //       params.api.sizeColumnsToFit();
  //     }
  //   };

  const openModalOfSelectedTile = (data) => {
  
    switch (data.type) {
      case PRODUCT_LISTING_PAGE.OUT_OF_STOCK:
        props.showModal({
          title: (
            <Fragment>
              <div className="ant-modal-title">Out Of Stock</div>
            </Fragment>
          ),
          modalBody: <OutOfStock {...props} />,
          width: 1000,
          hideFooter: true,
          wrapClassName: "tiles-modal",
        });
        break;
      case PRODUCT_LISTING_PAGE.REORDER_STOCK:
        props.showModal({
          title: (
            <Fragment>
              <div className="ant-modal-title">Reorder Stock</div>
            </Fragment>
          ),
          modalBody: <ReorderStock {...props} />,
          width: 1000,
          hideFooter: true,
          wrapClassName: "tiles-modal",
        });
        break;
      case PRODUCT_LISTING_PAGE.LOW_STOCK:
        props.showModal({
          title: (
            <Fragment>
              <div className="ant-modal-title">Low Stock</div>
            </Fragment>
          ),
          modalBody: <LowStock {...props} />,
          width: 1000,
          hideFooter: true,
          wrapClassName: "tiles-modal",
        });
        break;
      default:
        break;
    }
  };

  // const getProductDetailsCell = (data) => {
  //   // return <Link
  //   //     to={
  //   //         {
  //   //             pathname: 'products/details',
  //   //             state: {
  //   //                 itemId: data.itemId,
  //   //                 product: data
  //   //             }
  //   //         }}
  //   //     className='company-name'
  //   // >
  //   //     {data.itemName}
  //   // </Link>;
  //   props.history.push({
  //     pathname: "/admin/products/details",
  //     state: {
  //       itemId: data.itemId,
  //       product: data,
  //     },
  //   });
  // };

  const openVariantDetails = (data) => {
    props.showModal({
      title: (<div className="ant-modal-title">Product Details</div>),
      modalBody: <ProductDetail {...props} product={data} />,
      width: 1000,
      hideFooter: true,
      onClose:()=>{props.resetInventoryItemDetails()}
    });
  };
  const imageUpload = (data) => {
    props.pushModalToStack({
      title: (<div className="ant-modal-title" style={{flexDirection:"column"}}>Upload Variant Image
      <div className="b-text">{capitalizeFirstLetter(data.itemName)}</div>
      <div>{data.sku}</div>
      </div>),
      modalBody: <ImageUpload {...props} product={data} />,
      width: 900,
      hideFooter: true,
      // wrapClassName: "tiles-modal",
      wrapClassName: "image-upload-modal",

    });
  };

  const openQrDetails = (data) => {
    props.showModal({
      title: (
        <Fragment>
          <div className="ant-modal-title">Product QR</div>
        </Fragment>
      ),
      modalBody:<ProductQr id = {data.itemId} companyInfo={companyInfo} />,
      width: 400,
      hideFooter: true,
    });
  };

  const newInventory = () => {
    props.updateHeaderState({
      inventory: {
        ...props.inventory,
        inventoryDrawerVisible: true,
        newProduct: true,
        fromListingPage:true,

      },

    });
  };

  const editInventory = (params) => {
    props.updateHeaderState({
      inventory: {
        ...props.inventory,
        inventoryDrawerVisible: true,
        isUpdate: true,
        productData: params.data,
        fromListingPage:true,
      },

    });
  };
  const primaryPerm = (permissions && permissions.primary) || [];
  const permissionsData = useMemo(()=>{
    return getPermissionData(primaryPerm ,  MODULE_CODED_VALUES.PRODUCTS);
  },[])
  const getActionPermissionData = () => {
    const primaryPerm = permissions.primary || [];
    const permittedAction = {
      create: checkACLPermission(
        primaryPerm,
        MODULE_CODED_VALUES.PRODUCTS,
        PERMISSION_VALUES.CREATE
      ),
      delete: checkACLPermission(
        primaryPerm,
        MODULE_CODED_VALUES.PRODUCTS,
        PERMISSION_VALUES.DELETE
      ),
      update: checkACLPermission(
        primaryPerm,
        MODULE_CODED_VALUES.PRODUCTS,
        PERMISSION_VALUES.UPDATE
      ),
    };
    Object.keys(permittedAction).forEach((operation) => {
      if (permittedAction[operation] === false) {
        delete permittedAction[operation];
      }
    });
    return permittedAction;
  };

  const permittedActions = getActionPermissionData();

  const actionMenu = (param) => {
    return (
      <Menu className="row-action-dropdown">
        <Menu.Item key="0" value="title">
          Actions
        </Menu.Item>
        <Menu.Divider />
        {permissionsData.update ? (
          <Menu.Item key="1" value="edit" onClick={() => editInventory({ ...param, isUpdate: true })}>
            {/* <i className="fa fa-pencil-square-o" /> */}
            <i className={ICONS.EDIT} />
            Edit
          </Menu.Item>
        ) : (
          <Fragment />
        )}
           {
             permissionsData?.read ? <Menu.Item
             key="3"
             value="variantDetails"
             onClick={() =>
               openVariantDetails(param.data)}
           >
             <i className="fi fi-rr-replace"></i> &nbsp;{" "}
             <FormattedMessage id="variantDetails.text" />
           </Menu.Item>: ""
           }
           {
            permissionsData?.update ?   <Menu.Item
            key="4"
            value="uploadImage"
            onClick={() =>
              imageUpload(param.data)}
          >
            <i className={ICONS.UPLOAD}/>
            <FormattedMessage id="uploadImage.text" />
          </Menu.Item>: ""
           }
       {
        permissionsData?.read ?  <Menu.Item
        key="5"
        value="variantDetails"
        onClick={() =>
          openQrDetails(param.data)}
      >
<i className="fi fi-rs-qrcode"></i>
       &nbsp;{" "}
        Qr Code
      </Menu.Item>: ""
       }
      </Menu>
    );
  };
  //   const columnDefs = [
  //     {
  //       field: "isChecked",
  //       headerComponentFramework: () => <Fragment />,
  //       cellRendererFramework: (params) => {
  //         return (
  //           <Checkbox
  //             onClick={() => {
  //               params.node.setDataValue("isChecked", !params.data.isChecked);
  //             }}
  //             checked={params.data.isChecked}
  //           />
  //         );
  //       },
  //       minWidth: 52,
  //       maxWidth: 52,
  //       suppressMovable: true,
  //       pinned: "left",
  //       resizable: false,
  //     },
  //     {
  //       headerComponentFramework: () => {
  //         return (
  //           <FormattedMessage
  //             id="product.listing.header.productDetails"
  //             defaultMessage=""
  //           />
  //         );
  //       },
  //       field: "itemName",
  //       hide: _isColumnToHide("itemName"),
  //       colId: "itemName_1",
  //       minWidth: 150,
  //       maxWidth: 400,
  //       suppressMovable: true,
  //       resizable: true,
  //       // cellRendererFramework: function (param) {
  //       //     return getProductDetailsCell(param.data);
  //       // },
  //     },
  //     {
  //       headerComponentFramework: () => {
  //         return (
  //           <FormattedMessage
  //             id="product.listing.header.variant"
  //             defaultMessage=""
  //           />
  //         );
  //       },
  //       field: "variants",
  //       hide: _isColumnToHide("variants"),
  //       colId: "variants_1",
  //       resizable: true,
  //       cellRendererFramework: (link) => (
  //         <div
  //           className="text-center"
  //           // onClick={() => {openVariantDetails(link.data)}}
  //         >
  //           {link.data.hasVariant
  //             ? link.data.inventoryItemVariantList
  //               ? link.data.inventoryItemVariantList.length
  //               : link.data.totalVariantCount
  //             : "0"}
  //         </div>
  //       ),
  //       minWidth: 65,
  //       maxWidth: 100,
  //       suppressMovable: true,
  //       textAlign: "center",
  //     },
  //     // {
  //     //     headerComponentFramework: () => {
  //     //         return <FormattedMessage id='product.listing.header.valuation' defaultMessage='' />;
  //     //     },
  //     //     field: "valuation",
  //     //     cellRendererFramework: (link) => {
  //     //         return link.value && link.value !== '-' ? <span> {link.data.foreignCurrencyIcon ? <i className={link.data.foreignCurrencyIcon}></i> : (link.data.foreignCurrency ? <span>{link.data.foreignCurrency}</span> : (props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "))}  {link.value.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} </span> : "-"
  //     //     },
  //     //     hide: _isColumnToHide('valuation'),
  //     //     colId: "valuation_1",
  //     //     minWidth: 70,
  //     //     maxWidth: 400,
  //     //     suppressMovable: true,
  //     //     resizable: true,
  //     //     cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN
  //     // },
  //     {
  //       headerComponentFramework: () => {
  //         return (
  //           <FormattedMessage id="product.listing.header.sku" defaultMessage="" />
  //         );
  //       },
  //       field: "sku",
  //       hide: _isColumnToHide("sku"),
  //       colId: "sku_1",
  //       minWidth: 70,
  //       maxWidth: 400,
  //       suppressMovable: true,
  //       resizable: true,
  //     },
  //     {
  //       headerComponentFramework: () => {
  //         return (
  //           <FormattedMessage
  //             id="product.listing.header.category"
  //             defaultMessage=""
  //           />
  //         );
  //       },
  //       field: "categoryName",
  //       hide: _isColumnToHide("categoryName"),
  //       colId: "categoryName_1",
  //       minWidth: 70,
  //       maxWidth: 400,
  //       suppressMovable: true,
  //       resizable: true,
  //     },
  //     {
  //       headerComponentFramework: () => {
  //         return (
  //           <FormattedMessage
  //             id="product.listing.header.hsnCode"
  //             defaultMessage=""
  //           />
  //         );
  //       },
  //       field: "hsnCodeRate",
  //       hide:
  //         _isColumnToHide("hsnCodeRate") ||
  //         (props.companyInfo || {}).countryName !== "India",
  //       colId: "hsnCodeRate_1",
  //       resizable: true,
  //     },
  //     // {
  //     //     headerComponentFramework: () => {
  //     //         return <FormattedMessage id='product.listing.header.currentStock' defaultMessage='' />;
  //     //     },
  //     //     field: "qtyCurrentStock",
  //     //     hide: _isColumnToHide('qtyCurrentStock'),
  //     //     colId: "qtyCurrentStock_1",
  //     //     minWidth: 70,
  //     //     maxWidth: 400,
  //     //     suppressMovable: true,
  //     //     resizable: true
  //     // },
  //     {
  //       headerComponentFramework: () => {
  //         return (
  //           <FormattedMessage
  //             id="product.listing.header.warehouse"
  //             defaultMessage=""
  //           />
  //         );
  //       },
  //       field: "warehouseName",
  //       hide: _isColumnToHide("warehouseName"),
  //       colId: "warehouseName_1",
  //       minWidth: 70,
  //       maxWidth: 400,
  //       suppressMovable: true,
  //       resizable: true,
  //     },
  //     {
  //       headerComponentFramework: () => {
  //         return (
  //           <FormattedMessage
  //             id="inventory.extras.brand.listing.header.brandName"
  //             defaultMessage=""
  //           />
  //         );
  //       },
  //       field: "brandName",
  //       hide: _isColumnToHide("brandName"),
  //       colId: "brandName_1",
  //       minWidth: 70,
  //       maxWidth: 400,
  //       suppressMovable: true,
  //       resizable: true,
  //     },
  //     {
  //       headerComponentFramework: () => {
  //         return (
  //           <FormattedMessage
  //             id="inventory.extras.manufacturer.listing.header.manufacturerName"
  //             defaultMessage=""
  //           />
  //         );
  //       },
  //       field: "manufacturerName",
  //       hide: _isColumnToHide("manufacturerName"),
  //       colId: "manufacturerName_1",
  //       minWidth: 70,
  //       maxWidth: 400,
  //       suppressMovable: true,
  //       resizable: true,
  //     },
  //     {
  //       headerComponentFramework: () => {
  //         return <FormattedMessage id="drawer.inventory.upc" defaultMessage="" />;
  //       },
  //       field: "upc",
  //       hide: _isColumnToHide("upc"),
  //       colId: "upc_1",
  //       minWidth: 70,
  //       maxWidth: 400,
  //       suppressMovable: true,
  //       resizable: true,
  //     },
  //     {
  //       headerComponentFramework: () => {
  //         return (
  //           <FormattedMessage id="drawer.inventory.isbn" defaultMessage="" />
  //         );
  //       },
  //       field: "isbn",
  //       hide: _isColumnToHide("isbn"),
  //       colId: "isbn_1",
  //       minWidth: 70,
  //       maxWidth: 400,
  //       suppressMovable: true,
  //       resizable: true,
  //     },
  //     {
  //       headerComponentFramework: () => {
  //         return <FormattedMessage id="drawer.inventory.ean" defaultMessage="" />;
  //       },
  //       field: "ean",
  //       hide: _isColumnToHide("ean"),
  //       colId: "ean_1",
  //       minWidth: 70,
  //       maxWidth: 400,
  //       suppressMovable: true,
  //       resizable: true,
  //     },
  //     {
  //       headerComponentFramework: () => {
  //         return (
  //           <div className="cursor-pointer">
  //             <Popover
  //               content={<AddAndRemoveColumn {...props} />}
  //               title={<div className="text-center">Add/Remove</div>}
  //               placement="left"
  //               trigger="click"
  //             >
  //               <i className="fa fa-bars" />
  //             </Popover>
  //           </div>
  //         );
  //       },
  //       field: "action",
  //       colId: "action_1",
  //       pinned: "right",
  //       minWidth: 52,
  //       maxWidth: 52,
  //       suppressMovable: true,
  //       resizable: true,
  //       cellClass: "lastActionCell",
  //       cellRendererFramework: (param) => {
  //         return (
  //           <Dropdown
  //             overlay={actionMenu(param, permittedActions)}
  //             trigger={["click"]}
  //           >
  //             <Button className="action-btn small">
  //               <i className="fa fa-ellipsis-v" />
  //             </Button>
  //           </Dropdown>
  //         );
  //       },
  //     },
  //   ];

  //   const onFirstDataRendered = (params) => {
  //     //params.api.sizeColumnsToFit();
  //   };
  const showTotal = (total) => {
    return (
      props.intl.formatMessage({
        id: "pagination.text.total",
        defaultMessage: "",
      }) +
      ` ${total} ` +
      props.intl.formatMessage({
        id: "pagination.text.items",
        defaultMessage: "",
      })
    );
  };

  const loadPageData = (pageNumber, pageSize, searchedText) => {
    const payload = {
      relationshipId: companyInfo.relationshipId,
      pageNumber: pageNumber,
      pageSize: pageSize,
      searchedText: searchedText || props.searchedText,
    };
    fetchPaginationDataIfNeeded("fetchProducts", "products", props, payload);
  };

  const handlePageSizeChange = (pageNumber, pageSize, props) => {
    const payload = {
      relationshipId: companyInfo.relationshipId,
      pageNumber: pageNumber,
      pageSize: pageSize,
      searchedText: props.searchedText,
    };
    props.resetPaginatedData();
    props.fetchProducts(payload);
    // props.updateState({ pageSize: pageSize, pageNumber: pageNumber })
  };

  const handleMenuClick = (e) => {
    switch (e.key) {
      case "upload_products":
        const modalData = {
          title: (
            <div className="ant-modal-title">
              <FormattedMessage
                id="product.listing.buttonMenu.importProducts"
                defaultMessage=""
              />
            </div>
          ),
          modalBody: <ImportProducts {...props} />,
          hideFooter: true,
          maskClosable: true,
          width: "100%",
          handleSubmit: () => {
            //props.createOrUpdateCustomer(payload);
            //props.hideModal();
          },
          wrapClassName: "import-details-modal",
        };
        props.pushModalToStack(modalData);
        break;

      default:
        break;
    }
  };

  const menu = (
    // <Menu onClick={handleMenuClick} className="combined-dropdown">
    //   <Menu.Item key="upload_products">
    //     <i className="fa fa-upload mr5" />
    //     <FormattedMessage
    //       id="product.listing.buttonMenu.importProducts"
    //       defaultMessage=""
    //     />
    //   </Menu.Item>
    // </Menu>
    <Menu className="row-action-dropdown">
      <Menu.Item key="0" value="title">
        Import
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="upload_products" value="upload_customer" onClick={handleMenuClick}>
        <DownloadOutlined />
        <FormattedMessage id='product.listing.buttonMenu.importProduct' defaultMessage='' />
      </Menu.Item>
    </Menu>

  );

  const itemSelection = (item) => {
    let selectItem = find(selectedItems, { itemId: Number(item.itemId) });
    let updatedItems = [];
    if (selectItem) {
      updatedItems = selectedItems.filter((obj) => obj.itemId !== item.itemId);
    } else {
      updatedItems = JSON.parse(JSON.stringify(selectedItems));
      updatedItems.push(item);
    }
    props.updateState({ selectedItems: updatedItems });
  };

  const selectAll = (context) => {
    if (!context) {
      selectedItems.splice(0, selectedItems.length);
      props.updateState({ selectedItems: selectedItems });
      return;
    }
    for (let i = 0; i < products[pageNumber].length; i++) {
      let item = products[pageNumber][i];
      let selectItem = find(selectedItems, { itemId: Number(item.itemId) });
      if (!selectItem) {
        selectedItems.push(item);
      }
    }
  };

  const isCheckedAll = () => {
    if (
      !products ||
      !products[pageNumber] ||
      products[pageNumber].length === 0
    ) {
      return false;
    }
    for (let i = 0; i < products[pageNumber].length; i++) {
      let usr = products[pageNumber][i];
      let selectItem = find(selectedItems, { itemId: Number(usr.itemId) });
      if (!selectItem) {
        return false;
      }
    }
    return true;
  };

  const breadCrumbList = [
    {
      name: <FormattedMessage id="product.breadcrum.list" defaultMessage="" />,
    },
    {
      name: <FormattedMessage id="sidebar.menuItem.products" defaultMessage="" />,
    },
  ];
  const exportExcelData = (selectedProductList, props) => {
   
    const dataList = (selectedProductList || []).map((item) => {
      return {
           "skuId": item?.sku,
           "Product Name": item?.itemName,
           "Variant": item?.hasVariant,
           "Category": item?.categoryCount,
           "WareHouse": item?.warehouseName,
           "BrandName": item?.brandName,
           "price": item?.retailPrice,

        };
    });
    exportExcel(dataList, "Products");
  };
  const downloadMenu = () => {
   
    return (
      <Menu className="row-action-dropdown">
        <Menu.Item key="0" value="title">
          Download as
        </Menu.Item>

        <Menu.Divider />

        <Menu.Item
          key="1"
          value="excel"
          onClick={() => {
            const productList = [];
            // props.gridApi.forEachNode(obj => {
            //     if (obj.data.isChecked) {
            //         ids.push(obj.data.itemId);
            //     }
            // })
           
            selectedItems.forEach((obj) => {
              productList.push(obj);
            });
            if (productList.length) {
             
              exportExcelData(productList);
            } else {
              const modalData = {
                title: (
                  <div className="title">
                    <i className="fa fa-ban mr15" />
                    <FormattedMessage
                      id="product.listing.validation.failed.label"
                      defaultMessage=""
                    />
                  </div>
                ),
                modalBody: (
                  <Fragment>
                    <div className="warning-message">
                      <FormattedMessage
                        id="product.listing.validation.failed.message.download"
                        defaultMessage=""
                      />
                    </div>
                    <div className="button-action">
                      <Button
                        className="ant-btn-default"
                        onClick={() => props.popModalFromStack()}
                      >
                        <FormattedMessage id="ok" defaultMessage="" />
                      </Button>
                    </div>
                  </Fragment>
                ),
                hideFooter: true,
                wrapClassName: "validation-failed-modal",
              };
              props.pushModalToStack(modalData);
            }
          }}
        >
          <FileExcelOutlined />
          Excel File
        </Menu.Item>

        {/* <Menu.Item
          key="2"
          value="pdf"
          onClick={() => {
            const ids = [];
            // props.gridApi.forEachNode(obj => {
            //     if (obj.data.isChecked) {
            //         ids.push(obj.data.itemId);
            //     }
            // })
            selectedItems.forEach((obj) => {
              ids.push(obj.itemId);
            });
            if (ids.length) {
              props.exportPdf({
                relationshipId: props.companyInfo.relationshipId,
                itemIds: ids.toString(),
              });
            } else {
              const modalData = {
                title: (
                  <div className="title">
                    <i className="fa fa-ban mr15" />
                    <FormattedMessage
                      id="product.listing.validation.failed.label"
                      defaultMessage=""
                    />
                  </div>
                ),
                modalBody: (
                  <Fragment>
                    <div className="warning-message">
                      <FormattedMessage
                        id="product.listing.validation.failed.message.download"
                        defaultMessage=""
                      />
                    </div>
                    <div className="button-action">
                      <Button
                        className="ant-btn-default"
                        onClick={() => props.popModalFromStack()}
                      >
                        <FormattedMessage id="ok" defaultMessage="" />
                      </Button>
                    </div>
                  </Fragment>
                ),
                hideFooter: true,
                wrapClassName: "validation-failed-modal",
              };
              props.pushModalToStack(modalData);
            }
          }}
        >
          <FilePdfOutlined />
          PDF File
        </Menu.Item> */}
      </Menu>
    );
  };

  const checkMenu = (e) => {
    return (
      <Menu className="row-action-dropdown">
        <Menu.Item key="0" value="title">
          Actions
        </Menu.Item>

        <Menu.Divider />

        <Menu.Item
          key="1"
          onClick={() => {
            selectAll(true);
          }}
        >
          <CheckCircleOutlined />
          Select all
        </Menu.Item>

        <Menu.Item
          key="2"
          onClick={() => {
            selectAll(false);
          }}
        >
          <CloseCircleOutlined />
          Unselect all
        </Menu.Item>

        {permittedActions.delete && (
          <Menu.Item
            key="3"
            onClick={() => {
              const ids = [];
              // props.gridApi.forEachNode(obj => {
              //     if (obj.data.isChecked) {
              //         ids.push(obj.data.itemId);
              //     }
              // })
              selectedItems.forEach((obj) => {
                ids.push(obj.itemId);
              });
              if (ids.length) {
                const modalData = {
                  modalBody: "Are you sure you want to delete product?",
                  handleSubmit: () => {
                    props.deleteProducts({
                      relationshipId: props.companyInfo.relationshipId,
                      groupInventoryIds: ids,
                      pageNumber: props.pageNumber,
                      pageSize: props.pageSize,
                      warehouseMasterId: 0,
                    });
                    // props.resetPaginatedData();
                    // props.fetchProducts({
                    //     relationshipId: props.companyInfo.relationshipId,
                    //     pageNumber: props.pageNumber,
                    //     pageSize: props.pageSize
                    // })
                    //props.getProductCount({relationshipId: props.companyInfo.relationshipId})
                  },
                };
                props.pushModalToStack(modalData);
              } else {
                const modalData = {
                  title: (
                    <div className="title">
                      <i className="fa fa-ban mr15" />
                      <FormattedMessage
                        id="product.listing.validation.failed.label"
                        defaultMessage=""
                      />
                    </div>
                  ),
                  modalBody: (
                    <Fragment>
                      <div className="warning-message">
                        <FormattedMessage
                          id="product.listing.validation.failed.message.delete"
                          defaultMessage=" "
                        />
                      </div>
                      <div className="button-action">
                        <Button
                          className="ant-btn-default"
                          onClick={() => props.popModalFromStack()}
                        >
                          <FormattedMessage id="ok" defaultMessage="" />
                        </Button>
                      </div>
                    </Fragment>
                  ),
                  hideFooter: true,
                  wrapClassName: "validation-failed-modal",
                };
                props.pushModalToStack(modalData);
              }
            }}
          >
            <i className={ICONS.DELETE} />
            Delete
          </Menu.Item>
        )}
        <Menu.Item
          key="2"
          onClick={() => {
            let item = []
            selectedItems.forEach((obj) => {
              item.push(obj.itemId);
            });

            // download qr function is added inside this method that is bind in the props
            props.getInventoryItemByIds({relationshipId:companyInfo.relationshipId,inventoryItemCol:item},props);
          }}
        >
          <CloseCircleOutlined />
          Download QR
        </Menu.Item>
      </Menu>
    );
  };


  const openClassDetailPopup = (item) => {
    const modalData = {
      title: <FormattedMessage id='inventory.invValuation.listing.model.title' defaultMessage='' />,
      modalBody: <CategoryDetailsModel {...props} CategoryObj={item} />,
      width: '50%',
      hideFooter: true,
    };
    props.pushModalToStack(modalData);
  }
  return (
    <>
     <PageHeader {...props}
        pageName="purchase.product.page.heading"
        breadCrumbList={breadCrumbList}
        canCreate={true}
        onCreate={ newInventory}
        moduleCode = {MODULE_CODED_VALUES.PRODUCTS}
      
        />
     


      <Tile
        tilesData={tilesData}
        {...props}
        getSelectedTile={(data) => {
          openModalOfSelectedTile(data);
        }}
        className="p10"
      />

      <div className="view-container"
        style={{ height: "calc(100vh - 240px)" }}
      >
        <div className="view-container-actions">
        <div className="left-actions">
        <Skeleton loading={props.headerLoading} paragraph={false} rows={1} active>
              <div className="table-heading">
                <FormattedMessage
                  id="purchase.productList.table.heading"
                  defaultMessage=""
                />
              </div>
              {products[pageNumber]?.length ? (
                <>
                  <div className="vertical-sep" />
                  <div>{products[pageNumber].length}</div>
                </>
              ) : (
                ""
              )}
        </Skeleton>

            </div>
            <div className="right-actions">
              <Search
                placeholder="Enter 3 Characters of SKUID or Product Name"
                value={searchedText}
                onChange={(e) => {
                  updateState({ searchedText: e.target.value, pageNumber: 1 });
                  onSearchedInputChange(e.target.value, props);
                }}
                onSearch={(searchedText) => {
                  updateState({ searchedText, pageNumber: 1 });
                  onSearchedInputChange(searchedText, props);
                  handleSearchedText(searchedText, props);
                }}
                className="search-col"
              />

              {
                permittedActions.create &&
                <Dropdown overlay={menu} trigger={['click']}>
                  <span>&nbsp; &nbsp;&nbsp; <DownloadOutlined />  &nbsp; Import &nbsp; &nbsp; &nbsp;</span>
                </Dropdown>
              }
              <DropdownAction overlay={downloadMenu()} trigger={["click"]}>
                <span>
                  <DownloadOutlined /> &nbsp;{" "}
                  <FormattedMessage
                    id="common.action.dropdown.downloadAs"
                    defaultMessage=""
                  />
                </span>
              </DropdownAction>
             
                <Popover
                  content={<AddAndRemoveColumn {...props} />}
                  title="Add/Remove"
                  arrow ="right"
                  placement="left"
                  visible={props.showColPop}
                  trigger="click"
                >
 <button
                onClick={() => {
                  props.updateState({ showColPop: !props.showColPop });
                }}
              >
                 <InsertRowRightOutlined /> Columns
              </button>
                 
                </Popover>
             
              {/* {
                            permittedActions.delete && (
                                <button
                                    className="action-icon-btn"
                                    onClick={() => {
                                        const ids = [];
                                        // props.gridApi.forEachNode(obj => {
                                        //     if (obj.data.isChecked) {
                                        //         ids.push(obj.data.itemId);
                                        //     }
                                        // })
                                        selectedItems.forEach(obj => {
                                            ids.push(obj.itemId);
                                        })
                                        if (ids.length) {
                                            const modalData = {
                                                modalBody: 'Are you sure you want to delete product?',
                                                handleSubmit: () => {
                                                    props.deleteProducts({
                                                        relationshipId: props.companyInfo.relationshipId,
                                                        groupInventoryIds: ids,
                                                        pageNumber: props.pageNumber,
                                                        pageSize: props.pageSize,
                                                        warehouseMasterId: 0
                                                    })
                                                    // props.resetPaginatedData();
                                                    // props.fetchProducts({
                                                    //     relationshipId: props.companyInfo.relationshipId,
                                                    //     pageNumber: props.pageNumber,
                                                    //     pageSize: props.pageSize
                                                    // })
                                                    //props.getProductCount({relationshipId: props.companyInfo.relationshipId})
                                                }
                                            };
                                            props.pushModalToStack(modalData);
                                        } else {
                                            const modalData = {
                                                title:
                                                    <div className="title">
                                                        <i className="fa fa-ban mr15" />
                                                        <FormattedMessage id='product.listing.validation.failed.label' defaultMessage='' />
                                                    </div>,
                                                modalBody:
                                                    <Fragment>
                                                        <div className="warning-message">
                                                            <FormattedMessage id='product.listing.validation.failed.message.delete' defaultMessage=' ' />
                                                        </div>
                                                        <div className="button-action">
                                                            <Button className="ant-btn-default"
                                                                onClick={() => props.popModalFromStack()}><FormattedMessage id='ok' defaultMessage='' /></Button>
                                                        </div>
                                                    </Fragment>,
                                                hideFooter: true,
                                                wrapClassName: 'validation-failed-modal'
                                            };
                                            props.pushModalToStack(modalData);
                                        }
                                    }}
                                >
                                    <i style={{ fontSize: '20px'}} className="fa fa-trash" />
                                </button>
                            )
                        } */}

              {/* <button
                            className="action-icon-btn"
                            onClick={() => {
                                const ids = [];
                                // props.gridApi.forEachNode(obj => {
                                //     if (obj.data.isChecked) {
                                //         ids.push(obj.data.itemId);
                                //     }
                                // })
                                selectedItems.forEach(obj => {
                                    ids.push(obj.itemId);
                                })
                                if (ids.length) {
                                    props.exportPdf({
                                        relationshipId: props.companyInfo.relationshipId,
                                        itemIds: ids.toString()
                                    })
                                } else {
                                    const modalData = {
                                        title:
                                            <div className="title">
                                                <i className="fa fa-ban mr15" />
                                                <FormattedMessage id='product.listing.validation.failed.label' defaultMessage='' />
                                            </div>,
                                        modalBody:
                                            <Fragment>
                                                <div className="warning-message">
                                                    <FormattedMessage id='product.listing.validation.failed.message.download' defaultMessage='' />
                                                </div>
                                                <div className="button-action">
                                                    <Button className="ant-btn-default"
                                                        onClick={() => props.popModalFromStack()}><FormattedMessage id='ok' defaultMessage='' /></Button>
                                                </div>
                                            </Fragment>,
                                        hideFooter: true,
                                        wrapClassName: 'validation-failed-modal'
                                    };
                                    props.pushModalToStack(modalData);
                                }
                            }}
                        >
                            <i className="fa fa-file-pdf-o" />
                        </button> */}
              {/* 
                        <button
                            className="action-icon-btn"
                            onClick={() => {
                                const ids = [];
                                // props.gridApi.forEachNode(obj => {
                                //     if (obj.data.isChecked) {
                                //         ids.push(obj.data.itemId);
                                //     }
                                // })
                                selectedItems.forEach(obj => {
                                    ids.push(obj.itemId);
                                })
                                if (ids.length) {
                                    props.exportExcel({
                                        relationshipId: props.companyInfo.relationshipId,
                                        itemIds: ids.toString()
                                    })
                                } else {
                                    const modalData = {
                                        title:
                                            <div className="title">
                                                <i className="fa fa-ban mr15" />
                                                <FormattedMessage id='product.listing.validation.failed.label' defaultMessage='' />
                                            </div>,
                                        modalBody:
                                            <Fragment>
                                                <div className="warning-message">
                                                    <FormattedMessage id='product.listing.validation.failed.message.download' defaultMessage='' />
                                                </div>
                                                <div className="button-action">
                                                    <Button className="ant-btn-default"
                                                        onClick={() => props.popModalFromStack()}><FormattedMessage id='ok' defaultMessage='' /></Button>
                                                </div>
                                            </Fragment>,
                                        hideFooter: true,
                                        wrapClassName: 'validation-failed-modal'
                                    };
                                    props.pushModalToStack(modalData);
                                }
                            }}
                        >
                            <i className="fa fa-file-excel-o" />
                        </button> */}
            </div>
        </div>
        <div className="table-container">
          <Skeleton loading={props.listLoading} paragraph={{ rows: 15}} active>
            <table>
              <thead>
                <tr>
                  <th width='6%'>
                    <div className="check-cell">
                      <Checkbox
                        onChange={(e) => {
                          selectAll(e.target.checked);
                        }}
                        checked={isCheckedAll()}
                      />
                      <DropdownAction overlay={checkMenu()} trigger={["click"]}>
                        <DownOutlined />
                      </DropdownAction>
                    </div>
                  </th>
                  {!_isColumnToHide("productImage") ? (
                    <th>Product Image</th>
                  ) : (
                    ""
                  )}
                  {!_isColumnToHide("sku") ? <th>SKU</th> : ""}

                  {!_isColumnToHide("itemName") ? <th>Product Name </th> : ""}

                  {!_isColumnToHide("variants") ? <th>Variant</th> : ""}


                  {!_isColumnToHide("categoryName") ? <th>Category</th> : ""}

                  {!_isColumnToHide("warehouseName") ? <th>Warehouse</th> : ""}

                  {!_isColumnToHide("brandName") ? <th>Brand name</th> : ""}

                  {!_isColumnToHide("manufacturerName") ? ( <th>Manufacturer Name</th>) : ( "" )}

                  {!_isColumnToHide("upc") ? <th>UPC</th> : ""}

                  {!_isColumnToHide("isbn") ? <th>ISBN</th> : ""}

                  {!_isColumnToHide("ean") ? <th>EAN</th> : ""}

                  <th width='6%' className="text-center">
                    <FormattedMessage
                      id="customer.listing.header.action"
                      defaultMessage=""
                    />
                  </th>
                </tr>
              </thead>
              <tbody>
                {products && (products[pageNumber] || []).length
                  ? products[pageNumber].map((item, i) => {
                    return (
                      <tr key={i}>
                        <td>
                          <Checkbox
                            onClick={() => {
                              itemSelection(item);
                            }}
                            checked={
                              find(selectedItems, {
                                itemId: Number(item.itemId),
                              })
                                ? true
                                : false
                            }
                          />
                        </td>
                        {!_isColumnToHide("productImage") ? (
                          <td>
                            {item.productImage ? (
                              <img
                                style={{ height: "50px" }}
                                src={getAWSFileURL(
                                  item.productImage,
                                  config.BUCKET_NAME.PRODUCT_IMAGE
                                )}
                                alt="Avatar"
                                className="image"
                              />
                            ) : (
                              <PictureOutlined style={{ fontSize: "50px" }} />
                            )}
                          </td>
                        ) : (
                          ""
                        )}
                        {!_isColumnToHide("sku") ? <td>{item.sku}</td> : ""}

                        {!_isColumnToHide("itemName") ? (
                          <td><div style={{ textTransform: "capitalize" }}
                          //  onClick={() => getProductDetailsCell(item)}
                          >{item.itemName}</div></td>
                        ) : (
                          ""
                        )}

                        {!_isColumnToHide("variants") ? (
                          <td>
                            {item.hasVariant
                                ? item.inventoryItemVariantList
                                  ? item.inventoryItemVariantList.length
                                  : <span onClick={() => { openVariantDetails(item) }} className="cursor-pointer">{item.totalVariantCount}</span>
                                : "0"}
                            {/* {  item.totalVariantCount ? <span onClick={() => { openVariantDetails(item) }} className="cursor-pointer">{item.totalVariantCount}</span> : '0'} */}
                          </td>
                        ) : (
                          ""
                        )}

                        {!_isColumnToHide("categoryName") ? (
                          // <td>{item.categoryCount || '0'}</td>
                          <td>{item.categoryCount ? <span onClick={() => { openClassDetailPopup(item) }} className="cursor-pointer">{item.categoryCount}</span> : '0'}</td>
                        ) : (
                          ""
                        )}

                        {!_isColumnToHide("warehouseName") ? (
                          <td>{item.warehouseName||<div className="empty-data"></div>}</td>
                        ) : (
                          ""
                        )}

                        {!_isColumnToHide("brandName") ? (
                          <td>{item.brandName||<div className="empty-data"></div>}</td>
                        ) : (
                          ""
                        )}

                        {!_isColumnToHide("manufacturerName") ? (
                          <td>{item.manufacturerName||<div className="empty-data"></div>}</td>
                        ) : (
                          ""
                        )}

                        {!_isColumnToHide("upc") ? <td>{item.upc||<div className="empty-data"></div>}</td> : ""}

                        {!_isColumnToHide("isbn") ? <td>{item.isbn||<div className="empty-data"></div>}</td> : ""}

                        {!_isColumnToHide("ean") ? <td>{item.ean||<div className="empty-data"></div>}</td> : ""}

                        <td className="text-center">
                          <Dropdown
                            overlay={actionMenu(
                              { data: item },
                              permittedActions
                            )}
                            trigger={["click"]}
                          >
                            <i className={ICONS.MORE} />
                          </Dropdown>
                        </td>
                      </tr>
                    );
                  })
                  : 
                  <tr key="empty-data-box">
                    <td colSpan={"100%"} style={{ borderBottom: "none" }}>
                      <Empty />
                    </td>
                  </tr>
                }
              </tbody>
            </table>
          </Skeleton>
        </div>
     
        <Skeleton loading={props.headerLoading} paragraph={false} rows={1} active>
          <div className="footer">
            <div className="f-left"></div>
            <div className="f-right">
              <Pagination
                current={props.pageNumber || 1}
                size="small"
                total={productCount}
                showTotal={showTotal}
                defaultPageSize={100}
                showSizeChanger
                pageSizeOptions={[10, 25, 50, 100, 200]}
                // showQuickJumper
                onChange={(pageNumber, pageSize) => {
                  loadPageData(pageNumber, pageSize);
                  updateState({ pageSize: pageSize, pageNumber: pageNumber });
                }}
                onShowSizeChange={(pageNumber, pageSize) => {
                  handlePageSizeChange(pageNumber, pageSize, props);
                }}
              />
            </div>
          </div>
        </Skeleton>
      </div>
    </>
  );
};

const onSearchedInputChange = debounce((searchedText, props) => {
  handleSearchedText(searchedText, props);
}, 500);

const handleSearchedText = (searchedText, props) => {
  const payload = {
    relationshipId: props.companyInfo.relationshipId,
    pageNumber: 1,
    pageSize: props.pageSize,
    searchedText: searchedText,
  };
  if (searchedText.length) {
    if (
      searchedText.length >= 3 &&
      !props.searchedText.toLowerCase().startsWith(searchedText.toLowerCase())
    ) {
      props.resetPaginatedData();
      props.getProductCount(payload);
      props.fetchProducts(payload);
    }
  } else {
    props.resetPaginatedData();
    props.getProductCount(payload);
    props.fetchProducts(payload);
  }
};

export default injectIntl(ProductListingComp);
