import React, { Component, Fragment } from 'react';
import {Skeleton, Button,Menu,Empty} from 'antd';
import { CONSTANTS, ICONS, MODULE_CODED_VALUES, PERMISSION_VALUES } from '../../../../static/constants';
import ShowAddress from './../../../../containers/customer/Listing/ShowAddress';
// import { AgGridReact } from 'ag-grid-react';
import { FormattedMessage } from 'react-intl';
import { capitalizeFirstLetter, checkACLPermission, getMomentDateForUIReadOnly, getPermissionData, sortTable } from '../../../../utils';
import DropdownAction from "antd/lib/dropdown";
import { MoreOutlined } from "@ant-design/icons";

const format = CONSTANTS.DISPLAY_DATE_FORMAT;

class Address extends Component {

     constructor(props){
      super(props);
      
     }
  // state = {
  //     buttonDropDown: false
  // };

  // toggleDropdown = () => {
  //     const { buttonDropDown } = this.state;
  //     this.setState({
  //         buttonDropDown: !buttonDropDown
  //     })
  // };

  render() {
    const addressList = this.props.addressList || [];
    // let rowIndex = 1;
    // const { buttonDropDown } = this.state;
    // const { toggleDropdown } = this;
    // const agGridStyle = {
    //     height: '100%',
    //     width: '100%'
    // };

    // const onFirstDataRendered = (params) => {
    //     //params.api.sizeColumnsToFit();
    // }
    // const getRowHeight = (params) => {
    //     return 30;
    // }

    // const columnDefs = [
    //     {
    //         headerName: "#",
    //         resizable: true,
    //         cellRendererFramework: function (link) {
    //             return <div>
    //                 {link.rowIndex + 1}
    //             </div>
    //         },
    //         width: 50,
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='address.addressType' defaultMessage="" />;
    //         },
    //         headerName: "Address Type",
    //         field: "locationType",
    //         resizable: true,
    //         cellRendererFramework: (link) => <div>
    //             {link.data.locationType === 'BillingAddress' ? 'Billing Address' : 'Shipping Address'}
    //         </div>,
    //         width: 100,
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='address.streetName' defaultMessage="" />;
    //         },
    //         field: "streetAddress1",
    //         resizable: true,

    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='address.streetName' defaultMessage="" />;
    //         },
    //         field: "streetAddress2",
    //         resizable: true,

    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='address.city' defaultMessage="" />;
    //         },
    //         field: "cityName",
    //         resizable: true,

    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='address.state' defaultMessage="" />;
    //         },
    //         resizable: true,
    //         field: "stateName"
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='address.zip' defaultMessage="" />;
    //         },
    //         resizable: true,
    //         field: "zipCode",
    //         width: 100,
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='common.dateCreated' defaultMessage="" />;
    //         },
    //         resizable: true,
    //         width: 120,
    //         cellRendererFramework: (link) => <div>
    //             {link.data.dateCreated ? getMomentDateForUIReadOnly({date: new Date(link.data.dateCreated), format}) : ''}
    //         </div>
    //     },


    // ]
    // const columnDefs = [
    //     {
    //         title: "#",
    //         dataIndex: 'rowIndex',
    //         key: 'rowIndex',
    //         render:(text, record, index)=> <div>
    //             {index + 1}
    //         </div>,
    //         width: 50,
    //     },
    //     {
    //         title: <FormattedMessage id='address.addressType' defaultMessage="" />,
    //         dataIndex: 'locationType',
    //         key: 'locationType',
    //         render: (text, record, index)=><div>
    //             {record.locationType === 'BillingAddress' ? 'Billing Address' : 'Shipping Address'}
    //         </div>,
    //         width: 100,
    //     },
    //     {
    //         title: <FormattedMessage id='address.streetName' defaultMessage="" />,
    //         dataIndex: "streetAddress1",
    //         key: "streetAddress1",
    //     },

    //     {
    //         title: <FormattedMessage id='address.streetName' defaultMessage="" />,
    //         dataIndex: "streetAddress2",
    //         key: "streetAddress2",
    //     },
    //     {
    //         title: <FormattedMessage id='address.city' defaultMessage="" />,
    //         dataIndex: "cityName",
    //         key: "cityName",
    //     },
    //     {
    //         title: <FormattedMessage id='address.state' defaultMessage="" />,
    //         dataIndex: "stateName",
    //         key: "stateName",
    //     },
    //     {
    //         title: <FormattedMessage id='address.zip' defaultMessage="" />,
    //         dataIndex: "zipCode",
    //         key: "zipCode",
    //     },
    //     {
    //         title: <FormattedMessage id='common.dateCreated' defaultMessage="" />,
    //         dataIndex: "dateCreated",
    //         key: "dateCreated",
    //         render: (text, record, index)=><div>
    //             {record.dateCreated ? getMomentDateForUIReadOnly({date: new Date(record.dateCreated), format}) : ''}
    //         </div>
    //     },
    // ]
   
    // const getModulePermissionData = () => {
    //   const primaryPerm = permissions.primary || [];
    //   const permittedModules = {
    //     supplierBillingAddress: checkACLPermission(
    //       primaryPerm,
    //       MODULE_CODED_VALUES.SUPPLIER_BILLING_ADDRESS,
    //       PERMISSION_VALUES.CREATE
    //     ),
    //     supplierBillingAddressDelete: checkACLPermission(
    //       primaryPerm,
    //       MODULE_CODED_VALUES.SUPPLIER_BILLING_ADDRESS,
    //       PERMISSION_VALUES.DELETE
    //     ),
    //     supplierBillingAddressUpdate: checkACLPermission(
    //       primaryPerm,
    //       MODULE_CODED_VALUES.SUPPLIER_BILLING_ADDRESS,
    //       PERMISSION_VALUES.UPDATE
    //     ),
    //     supplierShippingAddress: checkACLPermission(
    //       primaryPerm,
    //       MODULE_CODED_VALUES.SUPPLIER_ADDRESS,
    //       PERMISSION_VALUES.CREATE
    //     ),
    //     supplierShippingAddressUpdate: checkACLPermission(
    //       primaryPerm,
    //       MODULE_CODED_VALUES.SUPPLIER_ADDRESS,
    //       PERMISSION_VALUES.UPDATE
    //     ),
    //     supplierShippingAddressDelete: checkACLPermission(
    //       primaryPerm,
    //       MODULE_CODED_VALUES.SUPPLIER_ADDRESS,
    //       PERMISSION_VALUES.DELETE
    //     ),
    //   };
    //   Object.keys(permittedModules).forEach((moduleName) => {
    //     if (permittedModules[moduleName] === false) {
    //       delete permittedModules[moduleName];
    //     }
    //   });
    //   return permittedModules;
    // };
    // const permittedModules = getModulePermissionData();
    const { permissions ,updateState, dir = 0,} = this.props;
    const primaryPerm = (permissions && permissions.primary) || [];
  
          const customerShippingAddressPermissions =  getPermissionData(primaryPerm ,  MODULE_CODED_VALUES.CUSTOMER_ADDRESS);
          const customerBillingAddressPermissions =  getPermissionData(primaryPerm ,  MODULE_CODED_VALUES.CUSTOMER_BILLING_ADDRESS);
        

    
  const getAddress = (props, locationType) => {
    const payload = {
        relationshipId: (props.companyInfo || {}).relationshipId,
        locationType: locationType,
        customerId: props.customerId
    };
    props.fetchCustomerAddresses(payload);
}

const deleteAddress = (props, record, locationType) => {
    const payload = {
        locationId: record.locationId,
        relationshipId: props.companyInfo.relationshipId,
        locationType: locationType,
        customerId: props.customerId,
        isDefault: record.defaultAddress === true ? 1 : 0
    };
    props.deleteAddress(payload);
    getAddress(props, locationType);
    //props.resetPaginatedData();
    //props.fetchCustomers({ relationshipId: props.companyInfo.relationshipId, pageNumber: props.pageNumber, pageSize: props.pageSize });
}
  
    const actionMenu = (param) => (
      <Menu className="row-action-dropdown">
        <Menu.Item key="0" value="title">
          Action
        </Menu.Item>
        {(customerBillingAddressPermissions?.update || customerShippingAddressPermissions?.update) ? (
          <Menu.Item
            key="1"
            value="edit"
            onClick={() => {
              const modalData = {
              title: (
                  <React.Fragment>
                  <div className="ant-modal-title">
                      <FormattedMessage
                      id="signup.tab.address"
                      defaultMessage=""
                      />
                      <div style={{ "font-size": "12px" }}>
                      {" "}
                      {this.props.customerName || ""}
                      </div>
                  </div>
                  </React.Fragment>
              ),
              modalBody: (
                  <ShowAddress
                  {...this.props}
                  customerId={this.props.customerId}
                  />
              ),
              width: 1100,
              hideFooter: true,
              };
              this.props.pushModalToStack(modalData);
          }}
          >
            <i className={ICONS.EDIT} />
            <div>
             Edit
            </div>
          </Menu.Item>
        ) : (
          <Fragment />
        )}

        {(customerBillingAddressPermissions?.delete || customerShippingAddressPermissions?.delete) ? (
          <Menu.Item
            key="2"
            value="Delete"
            onClick={() => {
              // const modalData = {
              //   modalBody: (
              //     <FormattedMessage
              //       id="customer.address.delete.confirmation"
              //       defaultMessage=""
              //     />
              //   ),
              //   handleSubmit: () => {
              //     deleteAddress(
              //       this.props,        
              //       param,
              //       this.state?.locationType
              //     );
              //   },
              // };
              // this.props.pushModalToStack(modalData);
              this.props.showModal({
                modalBody: "Are you sure want to delete?",
                handleSubmit: () => {
                    this.props.deleteCustomerAddress(param, this.props)
                },
            });
            }}
          >
            <i className={ICONS.DELETE} />
            Delete
          </Menu.Item>
        ) : (
          <Fragment />
        )}
      </Menu>
    );
    const sortColumn = (e) => {
      sortTable(
        document.getElementById("customers-table"),
        e.target.cellIndex,
        1 - dir
      );
      updateState({ dir: 1 - dir });
    };
    const getAddressInfoFragment = (obj) => {
      return (
        <>
          {obj.streetAddress1 ? (
            <span className="billing-address">{obj.streetAddress1}</span>
          ) : (
            ""
          )}
          {obj.streetAddress2 ? (
            <span className="billing-address"> {obj.streetAddress2}</span>
          ) : (
            ""
          )}
          {obj.cityName ? (
            <div className="billing-address">
              {`${obj.cityName || ""}${obj.stateName ? ", " + obj.stateName : ""
                }${obj.zipCode ? ", " + obj.zipCode : ""}`}
            </div>
          ) : (
            ""
          )}
          {obj.countryName ? (
            <div
              className="billing-address"
              style={{ textTransform: "uppercase" }}
            >
              {`${capitalizeFirstLetter(obj.countryName) || ""}`}
            </div>
          ) : (
            ""
          )}
        </>
      );
    };
    return (
      <Fragment>

        {/* <div className="view-top-bar">
              <div className="top-view-left">
                  <div className="page-heading">Address</div>
                  <div className="vertical-sep" />
                  <PageBreadcrumb breadCrumbList={breadCrumbList} />
              </div>
              <div className="top-view-right">
                  <button
                      className="create-action-btn"
                      onClick={() => {
                          const modalData = {
                          title: (
                              <React.Fragment>
                              <div className="ant-modal-title">
                                  <FormattedMessage
                                  id="signup.tab.address"
                                  defaultMessage=""
                                  />
                                  <div style={{ "font-size": "12px" }}>
                                  {" "}
                                  {this.props.customerName || ""}
                                  </div>
                              </div>
                              </React.Fragment>
                          ),
                          modalBody: (
                              <ShowAddress
                              {...this.props}
                              customerId={this.props.customerId}
                              />
                          ),
                          width: 1100,
                          hideFooter: true,
                          };
                          this.props.pushModalToStack(modalData);
                      }}
                  >
                      <i className={ICONS.ADD} /> <FormattedMessage id="button.create.label" defaultMessage="" />
                  </button>
              </div>
            </div> */}


        {/* <div className="right-align pb10"> */}
        {/* <div className="action-dropdown-btn"> */}
        {/* <button className="create-action-btn"  onClick={() => {
                            const modalData = {
                                title: <React.Fragment>
                                    <div className='ant-modal-title'>
                                        <FormattedMessage id='signup.tab.address' defaultMessage="" />
                                        <div style={{ 'font-size': '12px' }}> {this.props.customerName || ''}</div>
                                    </div>
                                </React.Fragment>,
                                modalBody: <ShowAddress {...this.props} customerId={this.props.customerId} />,
                                width: 1100,
                                hideFooter: true
                            };
                            this.props.pushModalToStack(modalData);
                        }}>
                            <i className="fa fa-address-book" />
                            <FormattedMessage id='address.title' defaultMessage="" /> 
                        </button> */}
        {/* <span className={buttonDropDown ? "arrow-down active" : "arrow-down"} onClick={toggleDropdown}>
                            <i className="fa fa-angle-down" />
                        </span>

                        <ul className="dropdown">
                            <li className="option-list">
                                <i className="fa fa-plus" />
                                <FormattedMessage id='address.title' defaultMessage="" /> 
                            </li>
                        </ul> */}
        {/* </div> */}
        {/* </div> */}

        {/* <div className='agGridWrapper'>
                    <div className="ag-theme-balham" style={agGridStyle} >
                        <AgGridReact
                            columnDefs={columnDefs}
                            rowData={addressList || []}
                            rowDragManaged={true}
                            domLayout={"autoHeight"}
                            animateRows={true}
                            onColumnResized={(params) => {
                                params.api.resetRowHeights();
                            }}
                            defaultColDef={{
                                flex: 1,
                                autoHeight: true,
                                wrapText: true,
                            }}
                            onGridSizeChanged={onFirstDataRendered}
                        //getRowHeight={getRowHeight}
                        >
                        </AgGridReact>
                    </div>
                </div> */}
            {/* <Table pagination={false} columns={columnDefs} dataSource={addressList || []} className="customer-transactions-table" /> */}

            <div className="view-container">
                <div className="view-container-actions">
                  <div className="left-actions">
                    <div className="table-heading">Address List</div>
                    {
                      (addressList || []).length ? <>
                        <div className="vertical-sep" />
                        <div>{addressList?.length}</div>
                      </> :''
                    }
                  </div>
                  <div className="right-actions">
                    {
                      customerShippingAddressPermissions?.create ?  <Button
                      className="create-action-btn"
                      style={{backgroundColor:"#1cb961", color: '#ffffff'}}
                      onClick={() => {
                          const modalData = {
                          title: (
                              <React.Fragment>
                              <div className="ant-modal-title">
                                  <FormattedMessage
                                  id="signup.tab.address"
                                  defaultMessage=""
                                  />
                                  <div style={{ "font-size": "12px" }}>
                                  {" "}
                                  {this.props.customerName || ""}
                                  </div>
                              </div>
                              </React.Fragment>
                          ),
                          modalBody: (
                              <ShowAddress
                              {...this.props}
                              customerId={this.props.customerId}
                              />
                          ),
                          width: 1100,
                          hideFooter: true,
                          };
                          this.props.pushModalToStack(modalData);
                      }}
                  >
                      <i className={ICONS.ADD} style={{margin: '4px 5px 0px 0px'}}/> <FormattedMessage id="button.create.label" defaultMessage="" />
                  </Button>: ""
                    }
                   
                     
                  </div>
                </div>
            <Skeleton loading={this.props.listLoading} active paragraph={{ rows: 16 }}>

                <div className="table-container table-h-profile">
                  <table>
                    <thead>
                      <tr>
                        <th>S.No.</th>
                       
                        <th onClick={sortColumn}>
                          <FormattedMessage
                            id="address.addressType"
                            defaultMessage=""
                          />
                        </th>
                        <th onClick={sortColumn}> <FormattedMessage
                            id="address.primaryAddress"
                            defaultMessage=""
                          /></th>
                        <th onClick={sortColumn}>
                          <FormattedMessage
                            id="address.StreetAddress1"
                            defaultMessage=""
                          />
                        </th>
                        <th onClick={sortColumn}>
                          <FormattedMessage
                            id="address.StreetAddress2"
                            defaultMessage=""
                          />
                        </th>
                        <th onClick={sortColumn}>
                          <FormattedMessage
                            id="address.city"
                            defaultMessage=""
                          />
                        </th>
                        <th onClick={sortColumn}>
                          <FormattedMessage
                            id="address.country"
                            defaultMessage=""
                          />
                        </th>
                        <th onClick={sortColumn}>
                          <FormattedMessage
                            id="address.state"
                            defaultMessage=""
                          />
                        </th>
                        <th onClick={sortColumn}>
                          <FormattedMessage
                            id="address.zip"
                            defaultMessage=""
                          />
                        </th>
                        <th onClick={sortColumn}>
                          
                          <FormattedMessage
                            id="common.dateCreated"
                            defaultMessage=""
                          />
                        </th>
                        {
                          (customerBillingAddressPermissions.delete || customerShippingAddressPermissions?.create || customerBillingAddressPermissions?.update || customerShippingAddressPermissions?.update) ?  <th className='text-center'>
                          <FormattedMessage id="Action" defaultMessage="" />
                        </th>: ""
                        }
                       
                      </tr>
                    </thead>
                    <tbody>
                      {(addressList || []).length > 0
                      
                      ? addressList .sort((a, b) => (a.dateCreated < b.dateCreated ? 1 : -1)).map((rowData, index) => (
                          
                            <tr>
                              <td>{index + 1}</td>
                              
                              <td>
                                {rowData.locationType === "BillingAddress"
                                  ? "Billing Address"
                                  : "Shipping Address"}
                              </td>
                              <td>{rowData.isDefault ? "Yes" : "No"}</td>
                              <td>{rowData.streetAddress1 || <div className="empty-data-box"></div>}</td>
                              <td>{rowData.streetAddress2 || <div className="empty-data-box"></div>}</td>
                              <td>{rowData.cityName || <div className="empty-data-box"></div>}</td>
                              <td>{rowData.countryName || <div className="empty-data-box"></div>}</td>
                              <td>{rowData.stateName || <div className="empty-data-box"></div>}</td>
                              <td>{rowData.zipCode || <div className="empty-data-box"></div>}</td>
                              <td>
                                {rowData.dateCreated
                                  ? getMomentDateForUIReadOnly({
                                      date: new Date(rowData.dateCreated),
                                      format,
                                    })
                                  : <div className="empty-data-box"></div>}
                              </td>

                              {
                                (customerBillingAddressPermissions.delete || customerShippingAddressPermissions?.create || customerBillingAddressPermissions?.update || customerShippingAddressPermissions?.update) ? <td className='text-center'>
                                <div className="action-icon">
                              <DropdownAction
                                overlay={actionMenu(rowData)}
                                trigger={["click"]}
                                overlayClassName="action-listing-dropdown"
                              >
                                <MoreOutlined />
                              </DropdownAction>
                              </div>
                            </td>: ""
                              }
                              
                            </tr>
                          ))
                        : 
                        <tr key="empty-data-box">
                        <td colSpan={"100%"} style={{ borderBottom: "none" }}>
                            <Empty />
                        </td>
                    </tr>
                        
                        }
                    </tbody>
                  </table>
                </div>
              </Skeleton>
              <div className="footer">
                <div className="f-left"></div>
                <div className="f-right"></div>
              </div>
            </div>
          </Fragment>
        );
    }
}

export default Address;
