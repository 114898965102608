import React, { Fragment } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
// import { AgGridReact } from 'ag-grid-react';
import { Skeleton, Pagination, Tooltip,Empty } from 'antd';
import moment from 'moment-timezone';
import { fetchPaginationDataIfNeeded, fixedDecimalAndFormateNumber } from '../../../../utils'
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
// import { AG_GRID_CLASS_CONSTANTS } from '../../../../static/cssClassConstants'
import {
    CONSTANTS
} from '../../../../static/constants';
import StandardPurchaseOrder from '../../../../containers/supplier/purchaseOrder/StandardPurchaseOrder';
import SupplierDetails from '../../../../containers/supplier/detail'


const purchaseOrderDraft = (props) => {
    const {  purchaseOrderDraftList, updateState, companyInfo, purchaseOrderDraftCount, pageNumber } = props;
    // const getRowHeight = (params) => {
    //     let height = 35;
    //     return height;
    // };


    const showTotal = (total) => {
        return props.intl.formatMessage({ id: 'pagination.text.total', defaultMessage: '' }) + ` ${total} ` + props.intl.formatMessage({ id: 'pagination.text.items', defaultMessage: '' });
    };
    
    const loadPageData = (pageNumber, pageSize) => {
        const payload = { companyInfo, relationshipId: companyInfo.relationshipId, pageNumber: pageNumber, pageSize: pageSize };
        fetchPaginationDataIfNeeded('fetchPurchaseOrderDrafts', 'purchaseOrderDraftList', props, payload);
    }

    const handlePageSizeChange = (pageNumber, pageSize, props) => {
        const payload = { companyInfo, relationshipId: companyInfo.relationshipId, pageNumber: pageNumber, pageSize: pageSize };
        props.resetDraftPaginatedData();
        props.fetchPurchaseOrderDrafts(payload);
       // props.updateState({ pageSize: pageSize, pageNumber: pageNumber });
    }

    //const permittedModules = getActionMenuPermissionData();
    // const columnDefs = [
    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='customer.salesOrder.listing.header.draftNo' defaultMessage='' />;
    //         },
    //         field: "poDraftId",

    //         cellRendererFramework: (param) => <div className="cursor-pointer" >
    //             <Link
    //                 to={
    //                     {
    //                         pathname: '/admin/po-create',
    //                         state: {
    //                             supplierId: param.data.supplierId,
    //                             purchaseOrderData: param.data.purchaseOrderData,
    //                             poDraftId: param.data.poDraftId,
    //                             clone: true
    //                         }
    //                     }}
    //             >Draft-{param.value}</Link>
    //         </div>,
    //         rowDrag: true,
    //         resizable: true,
    //         width: 100,
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='common.supplier' defaultMessage='' />;
    //         },
    //         field: "supplierName",
    //         resizable: true,
    //         width: 100,
    //         cellRendererFramework: (link) => <div className="cursor-pointer" >
    //             <Link
    //                 to={
    //                     {
    //                         pathname: 'suppliers/details',
    //                         state: {
    //                             supplierId: link.data.purchaseOrderData.supplierId,
    //                             supplierName: link.data.purchaseOrderData.supplierName,
    //                         }
    //                     }}
    //                 className='company-name'
    //             >
    //                 {link.data.purchaseOrderData.supplierName}
    //             </Link>
    //         </div>,
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <div><FormattedMessage id='customer.salesOrder.listing.header.amount' defaultMessage='Amount' />  </div>;
    //         },
    //         cellRendererFramework: (link) => {
    //             // return link.data.purchaseOrderData.totalPOAmount && link.data.purchaseOrderData.totalPOAmount !== '-' ? <span> {link.data.purchaseOrderData.foreignCurrencyIcon ? <i className={link.data.purchaseOrderData.foreignCurrencyIcon}></i> : (link.data.purchaseOrderData.foreignCurrency ? <span>{link.data.purchaseOrderData.foreignCurrency}</span> : (props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "))}{ Number(link.data.purchaseOrderData.totalPOAmount || 0).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} </span> : "-"
    //             return link.data.purchaseOrderData.totalPOAmount && link.data.purchaseOrderData.totalPOAmount !== '-' ? <span> {link.data.purchaseOrderData.foreignCurrencyIcon ? <i className={link.data.purchaseOrderData.foreignCurrencyIcon}></i> : (link.data.purchaseOrderData.foreignCurrency ? <span>{link.data.purchaseOrderData.foreignCurrency}</span> : (props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "))}{ fixedDecimalAndFormateNumber(Number(link.data.purchaseOrderData.totalPOAmount)) || 0} </span> : "-"
    //         },
    //         field: "totalPOAmount",
    //         resizable: true,
    //         cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN,
    //         width: 100,
    //     },
        
       
    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='customer.salesOrder.listing.header.date' defaultMessage='' />;
    //         },
    //         field: "poDate",
    //         cellRendererFramework: (link) => {
    //             return (
    //               <div>
    //                 <Tooltip
    //                   placement="topLeft"
    //                   title={
    //                     link.data.purchaseOrderData.poDate &&
    //                     moment(link.data.purchaseOrderData.poDate).format(
    //                       CONSTANTS.DISPLAY_TOOLTIP_DATE_FORMAT
    //                     )
    //                   }
    //                 >
    //                   {link.data.purchaseOrderData.poDate &&
    //                     moment(link.data.purchaseOrderData.poDate).format(
    //                       CONSTANTS.DISPLAY_DATE_FORMAT
    //                     )}
    //                 </Tooltip>
    //               </div>
    //             );
    //         },
    //         resizable: true,
    //         width: 100,
    //     },
       
    // ]

    // const agGridStyle = {
    //     height: '100%',
    //     width: '100%'
    // };

    
    // const onGridReady = (params) => {
    //     props.updateState({
    //         gridApi: params.api,
    //         gridColumnApi: params.columnApi
    //     });
    //     params.api.sizeColumnsToFit();
    // }

   
    return (
        <Fragment>
           
            <div className="view-container-actions">
            <Skeleton loading={props.headerLoading} paragraph={false} active>

                    <div className="left-actions">
                    <div className="table-heading">
                        <FormattedMessage id='purchase.purchaseOrder.table.heading'defaultMessage='' />
                    </div>
                    
                        <div className="vertical-sep" />
                        <div>{props.purchaseOrderDraftCount}</div>
                     
                    </div>
                    <div className="right-actions">
                    </div>
                    </Skeleton>
                </div>
                    {/* <div className='agGridWrapper'>
                        <div className="ag-theme-balham" style={agGridStyle} >
                            {props.txColumns.length ? <AgGridReact
                                onGridReady={onGridReady}
                                columnDefs={columnDefs}
                                rowData={purchaseOrderDraftList[pageNumber] || []}
                                rowDragManaged={true}
                                domLayout={"autoHeight"}
                                suppressDragLeaveHidesColumns={true} 
                                animateRows={true}
                                onColumnResized ={(params)=>{
                                    params.api.resetRowHeights();
                                }}
                                defaultColDef={{
                                    flex:1,
                                    autoHeight: true,
                                    wrapText: true,
                                }}
                                //getRowHeight={getRowHeight}
                            >
                            </AgGridReact>: null}
                        </div>
                    </div> */}
                   <Skeleton loading={props.listLoading} active paragraph={{ rows: 16 }}>

                    <div className="table-container">
                        <table>
                            <thead>
                                <tr>
                                    <th><FormattedMessage id='serial.no' defaultMessage=''/></th>
                                    <th><FormattedMessage id='customer.salesOrder.listing.header.draftNo' defaultMessage=''/></th>
                                    <th><FormattedMessage id='common.supplier' defaultMessage=''/></th>
                                    <th><FormattedMessage id='customer.salesOrder.listing.header.amount' defaultMessage=''/></th>
                                    <th><FormattedMessage id='customer.salesOrder.listing.header.date' defaultMessage=''/></th>
                                </tr>
                            </thead>
                            <tbody>
                                {(purchaseOrderDraftList[pageNumber] || []).length ?  purchaseOrderDraftList[pageNumber].map((rowData,index)=>{
                                    return (
                                      <tr key={index}>
                                        <td>
                                          {index+1}
                                        </td>
                                        <td>
                                          <div className="cursor-pointer">
                                            <div onClick={()=>{
                                               props.pushModalToStack({
                                                modalBody: <StandardPurchaseOrder {...props} supplierId={rowData.supplierId}   purchaseOrderData={rowData.purchaseOrderData} poDraftId= {rowData.poDraftId} draftVersion={rowData.version} clone={true}/>,
                                                width: '100%',
                                                hideTitle: true,
                                                hideFooter: true,
                                                wrapClassName: 'new-transaction-wrapper'
                                            })

                                            }}
                                              // to={{
                                              //   pathname: "/admin/po-create",
                                              //   state: {
                                              //     supplierId: rowData.supplierId, 
                                              //     purchaseOrderData:rowData.purchaseOrderData,
                                              //     poDraftId: rowData.poDraftId,
                                              //     clone: true,
                                              //   },
                                              // }}
                                            >
                                              Draft-{rowData.poDraftId}
                                            </div>
                                          </div>
                                        </td>
                                        <td>
                                          <div className="cursor-pointer">
                                            <div
                                            onClick={()=>{
                                              props.pushModalToStack({  
                                 
                                                modalBody: <SupplierDetails {...props}
                                                supplierId= {rowData.supplierId}
                                                supplierName={rowData.supplierName}
                                            />,
                                            width: '100%',
                                            hideTitle: true,
                                            hideFooter: true,
                                            wrapClassName: "new-transaction-wrapper",
                                            })
                                            }}
                                              // to={{
                                              //   pathname: "suppliers/details",
                                              //   state: {
                                              //     supplierId:
                                              //       rowData.purchaseOrderData
                                              //         .supplierId,
                                              //     supplierName:
                                              //       rowData.purchaseOrderData
                                              //         .supplierName,
                                              //   },
                                              // }}
                                              
                                              className="company-name"
                                            >
                                              {
                                                rowData.purchaseOrderData
                                                  .supplierName
                                              }
                                            </div>
                                          </div>
                                        </td>
                                        <td>
                                          {rowData.purchaseOrderData
                                            .totalPOAmount &&
                                          rowData.purchaseOrderData
                                            .totalPOAmount !== "-" ? (
                                            <span>
                                              {" "}
                                              {rowData.purchaseOrderData
                                                .foreignCurrencyIcon ? (
                                                <i
                                                  className={
                                                    rowData.purchaseOrderData
                                                      .foreignCurrencyIcon
                                                  }
                                                ></i>
                                              ) : rowData.purchaseOrderData
                                                  .foreignCurrency ? (
                                                <span>
                                                  {
                                                    rowData.purchaseOrderData
                                                      .foreignCurrency
                                                  }
                                                </span>
                                              ) : props.companyInfo
                                                  .currencyIcon ? (
                                                <i
                                                  className={
                                                    props.companyInfo
                                                      .currencyIcon
                                                  }
                                                ></i>
                                              ) : (
                                                props.companyInfo.currencyCode +
                                                " "
                                              )}
                                              {fixedDecimalAndFormateNumber(
                                                Number(
                                                  rowData.purchaseOrderData
                                                    .totalPOAmount
                                                )
                                              ) || 0}{" "}
                                            </span>
                                          ) : (
                                            "-"
                                          )}
                                        </td>
                                        <td>
                                          <div>
                                            <Tooltip
                                              placement="topLeft"
                                              title={
                                                rowData.purchaseOrderData
                                                  .poDate &&
                                                moment(
                                                  rowData.purchaseOrderData
                                                    .poDate
                                                ).format(
                                                  CONSTANTS.DISPLAY_TOOLTIP_DATE_FORMAT
                                                )
                                              }
                                            >
                                              {rowData.purchaseOrderData
                                                .poDate &&
                                                moment(
                                                  rowData.purchaseOrderData
                                                    .poDate
                                                ).format(
                                                  CONSTANTS.DISPLAY_DATE_FORMAT
                                                )}
                                            </Tooltip>
                                          </div>
                                        </td>
                                      </tr>
                                    );
                                })
                              : 
                              <tr key="empty-data-box">
                  <td colSpan={"100%"} style={{ borderBottom: "none" }}>
                    <Empty />
                  </td>
                </tr>
                              }
                            </tbody>
                        </table>
                    </div>
                </Skeleton>
                <div className="footer">
                <Skeleton loading={props.headerLoading} paragraph={false} active>

                    <div className="f-left"></div>
                    <div className="f-right">

                <Pagination
                    size="small"
                    total={purchaseOrderDraftCount}
                    showTotal={showTotal}
                    defaultPageSize={props.pageSize} showSizeChanger pageSizeOptions={[10, 25, 50, 100, 200]}
                    // showQuickJumper
                    onChange={(pageNumber, pageSize) => {
                        loadPageData(pageNumber, pageSize);
                        updateState({ pageSize: pageSize, pageNumber: pageNumber })
                    }}
                    onShowSizeChange={(pageNumber, pageSize) => {
                        handlePageSizeChange(pageNumber || 1, pageSize, props)
                    }}
                />
                    </div>
                    </Skeleton>
                </div>
           
        </Fragment>
    );
}

export default injectIntl(purchaseOrderDraft );
