import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import MyWarehouseComp from '../../../../../components/inventory/warehouse/myWarehouse/Listing';
import { fetchDataIfNeeded } from '../../../../../utils';
import { showModal, pushModalToStack, hideModal, popModalFromStack } from '../../../../../actions/commonActions';
import { fetchWarehouseNames } from '../../../../drawer/inventory/action';
import { deleteWarehouse } from './action';

class WarehouseListing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageSize: 100,
      pageNumber: 1,
      isDataRendered: false
    };
  }

  componentDidMount() {
    const companyInfo = this.props.companyInfo || {};
    const payload = {
      relationshipId: companyInfo.relationshipId,
      pageNumber: 1,
      pageSize: 100
    };
    fetchDataIfNeeded('fetchWarehouseNames', 'allWarehouses', this.props, payload);
  }

  render() {
    return (
      <MyWarehouseComp {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
    )
  }
}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    allWarehouses: state.inventory.allWarehouses,
    permissions: state.auth.permissions,
    listLoading: state.common.listLoading
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchWarehouseNames,
  deleteWarehouse,
  showModal,
  popModalFromStack,
  pushModalToStack,
  hideModal,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(WarehouseListing);
