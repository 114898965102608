import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import FinancialYearsComponent from '../../../components/finance/FinancialYears';
import { fetchFinancialYears, deleteFY, } from './action';
import { showModal, pushModalToStack, hideModal, popModalFromStack } from '../../../actions/commonActions';

class FinancialYearsComp extends Component {

    constructor(props){
        super();
        this.state={
            searchedText: ''
        }
    }

    componentDidMount() {
        const companyInfo = this.props.companyInfo || {};
        const payload = { relationshipId: companyInfo.relationshipId };

        this.props.fetchFinancialYears(payload);
        
    }
    
    componentWillMount() {
        this.props.updateHeaderState({
            collapsed: false
        });
    }


    render() {
        return <FinancialYearsComponent {...this.state} {...this.props} updateState={(data) => { this.setState(data) }}/>
    }

}


const mapStateToProps = (state) => {
    return {
        companyInfo: state.common.companyInfo,
        isLoading: state.common.isLoading,
        financialYearList : state.financialYear.financialYearList,
        permissions: state.auth.permissions,
        listLoading: state.common.listLoading
    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchFinancialYears,
    showModal,
    hideModal,
    pushModalToStack,
    popModalFromStack,
    deleteFY,
    
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(FinancialYearsComp);
