import React, { Fragment, useEffect, useState } from "react";
import UpgradePricingPlan from "../../containers/B2B/PlanPricing";
import { CustomButton } from "./CustomButton";
import { FormattedMessage } from "react-intl";
import { message } from "antd";
function calculateRemainingDays(loginDateStr, trialDays) {
    const loginDate = new Date(loginDateStr);
    const endDate = new Date(loginDate);
    endDate.setDate(loginDate.getDate() + trialDays);
    
    const currentDate = new Date();
    const remainingTime = endDate - currentDate;
    
    const remainingDays = Math.ceil(remainingTime / (1000 * 60 * 60 * 24));

    return remainingDays > 0 ? remainingDays : 0; // If the days are negative, return 0
}
export const AlertMessage = (props) => {
  const {userInfo ,companyInfo } = props;
  const date = (companyInfo?.dateCreated||"").split(" ")[0];
  const freeTrialdDays= companyInfo?.freeDays; 
  const upgradePlan = ()=>{
    const alpideProductId = 1;
    props.pushModalToStack({
        modalBody: <UpgradePricingPlan {...props} isUpgrade={true} alpideProductId={typeof alpideProductId === 'number' ?  alpideProductId : 0} />,
        width: '100%',
        hideTitle: true,
        hideFooter: true,
        keyboard:false,
        wrapClassName: 'full_modal',
  
        onCancel: () => {
      props.showModal({
            modalBody: <FormattedMessage id="modal.cancel.confirmation" defaultMessage=""/>,
            footer:{submit:{intlId:"button.close.label"}},
            handleSubmit: () => {
            
              props.popModalFromStack();
             
            }
          })
        }
      })
  }
  
 

  const [notificationData , setNotificationData] = useState([]);
  const [showAlert , setAlert] = useState(false);
  
  useEffect(() => {
    const notification = [
      {
        icon: <i style={{ fontSize: "15px" }} className="fi fi-rr-hand-wave"></i>,
        message: <div>Hey <b>{userInfo?.firstName + " " + userInfo?.lastName}!</b> Your Alpide Cloud ERP Starter Plan <b>{companyInfo?.freeDays}-days</b> free trial is now active.</div>,
        isAlertVisible: companyInfo?.isFreeTrial && (calculateRemainingDays(date, freeTrialdDays) - 7) ? true : false,
        activate: upgradePlan
      },
      {
        icon: <i className="fi fi-rr-alarm-clock"></i>,
        message: <div><b>Reminder!</b> Your plan expires in ${calculateRemainingDays(date, freeTrialdDays)} days. Renew now to continue uninterrupted access to Alpide Cloud ERP.</div>,
        isAlertVisible: companyInfo?.isFreeTrial && (calculateRemainingDays(date, freeTrialdDays)) <= 7 ? true : false,
        activate: upgradePlan
      },
      // {
      //   icon: <i className="fi fi-rr-alarm-clock"></i>,
      //   message: <div><b>Reminder!</b> Your {companyInfo?.freeDays}-days free trial has ended. Select a plan to continue accessing your Alpide Cloud ERP features.</div>,
      //   isAlertVisible: true,
      //   activate: upgradePlan
      // },
      // {
      //   icon: <i className="fi fi-rr-credit-card"></i>,
      //   message: <div>Payment Issue! We encountered a payment issue. We’ll retry 3 attempts before service interruption. Please update your payment details</div>,
      //   isAlertVisible: true,
      // },
      // {
      //   icon: <i className="fi fi-rr-credit-card"></i>,
      //   message: <div>Payment Failed! Your payment failed after 3 attempts. View-only mode is active. Please resolve payment issues to regain full access to your account.</div>,
      //   isAlertVisible: true,
      // },
    ];
  
    setNotificationData(notification);
    setAlert(notification.some(item => item.isAlertVisible));
  }, []);
  
  useEffect(() => {
    // Check if all notifications are closed
    const allClosed = notificationData.every(item => !item.isAlertVisible);
    setAlert(!allClosed);
  }, [notificationData]);

  const handleClose = (index) => {
    setNotificationData((prevData) =>
      prevData.map((item, i) => (i === index ? { ...item, isAlertVisible: false } : item))
    );
  };
  return (
    <Fragment>
     <div className="notification-wrapper" style={{height: showAlert ? "50px":"0px"}}>
        {
            (notificationData||[]).map((item,i)=>{
                return(
                    <>
                    {
                       item?.isAlertVisible ?  <div key={'notification'+i} className="alert-messages" >
  <div className="left-content">
          <span>
           
          {
            item?.icon
          }
           
          </span>
          <div className="message">
            {item?.message}
          </div>
        </div>
        <div className="right-content">
          <button 
          onClick={()=>item?.activate()}
          >Activate Now</button>
          <button style={{border: "none"}}  onClick={() => handleClose(i)}>X</button>
        </div>
                       </div>:""
                    }
                    </>
                   
                )
            })
        }
      </div>
   
    </Fragment>
  );
};
