import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ShowContact from '../../../../components/customer/listing/ShowContact';
import { fetchSalutation, showModal, hideModal, pushModalToStack, popModalFromStack } from './../../../../actions/commonActions';
import {fetchCustomers, resetPaginatedData} from '../action'
import { fetchAllContacts, deleteContact, createUpdateContact, addContactRow } from './action';
import { fetchDataIfNeeded } from '../../../../utils';

class ShowContactContainer extends Component {

  constructor(props) {
    super(props);
    this.state = {
      pageSize: props.pageSize || 25,
      pageNumber: props.pageNumber || 1,
    };
  }
  componentDidMount() {
    const companyInfo = this.props.companyInfo || {};
    const payload = {
      relationshipId: companyInfo.relationshipId,
      customerId: this.props.customerId
    };
    fetchDataIfNeeded('fetchSalutation', 'salutations', this.props, payload);
    this.props.fetchAllContacts(payload);
  }

  render() {
    return (
      <ShowContact {...this.props} {...this.state} updateState={(data) => { this.setState(data)}}/>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    salutations: state.common.salutations,
    companyInfo: state.common.companyInfo,
    userInfo: state.common.userInfo,
    contacts: state.customer.contacts,
    permissions: state.auth.permissions,

  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchSalutation,
  fetchAllContacts,
  deleteContact,
  createUpdateContact,
  resetPaginatedData,
  fetchCustomers,
  addContactRow,
  showModal,
  hideModal,
  pushModalToStack, 
  popModalFromStack
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ShowContactContainer);
