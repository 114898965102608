import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import queryString from "query-string";
import LoginComponent from "../../components/login/LoginComponent";
import LoginAlifComponent from "../../components/login/LoginAlifComponent";
import {
  verifyCredentials,
  activateCustomerAccount,
  // setIsAlifApp,
  getLogedInUserInfo,
} from "./action";
import { showModal, hideModal } from "../../actions/commonActions";
import { STORAGE_CONSTANTS, LOCAL_STORAGE_KEYS } from "../../static/constants";
import {
  fetchFromLocalMemory,
  fetchFromLocalStorage,
  addToLocalStorage,
} from "./../../utils";
import config from "../../config/environmentConfig";
import { withCookies } from "react-cookie";
import "../../components/login/login.scss";
import { logout } from '../../actions/commonActions';
class Login extends Component {
  constructor(props) {
    super(props);
    const rememberMe = fetchFromLocalMemory(STORAGE_CONSTANTS.REMEMBER_ME);
    const loginEmail = fetchFromLocalMemory(STORAGE_CONSTANTS.LOGIN_EMAIL);
    const loginPassword = fetchFromLocalMemory(
      STORAGE_CONSTANTS.LOGIN_PASSWORD
    );
    // const linkProps = (props.location && props.location.state) || {};
    const linkProps = queryString.parse(this.props.location.search) || {};
    this.state = {
      rememberMe,
      username: loginEmail,
      password: loginPassword,
      usernameClass: "",
      usernameInvalidClass: "",
      passwordClass: "login-form-input",
      source: linkProps.source,
      isAlifApp: window.location.host === "app.alifapp.com",
      isAuthenticated: false,
      isSSOEnable: config.SSO_URL ? true : false,
      isValidateSession: false
    };
  }
  componentDidMount() {
    const { cookies } = this.props;
    // Access all cookies
    const allCookies = cookies.getAll();
    const queryStringObj = queryString.parse(this.props.location.search);
    let adminIds = (queryStringObj.at || "").split("_");
    const payload = {
      token: (queryStringObj || {}).t,
    };
    
    const loggedInEntityInfo = fetchFromLocalStorage(
      LOCAL_STORAGE_KEYS.LOGGEDIN_ENTITY_DATA
    )
      ? JSON.parse(
        fetchFromLocalStorage(LOCAL_STORAGE_KEYS.LOGGEDIN_ENTITY_DATA)
      )
      : {};
    const email = allCookies[LOCAL_STORAGE_KEYS.EMAIL];
    const refreshToken = allCookies[LOCAL_STORAGE_KEYS.REFRESH_TOKEN];
    const accessToken = allCookies[LOCAL_STORAGE_KEYS.ACCESS_TOKEN];
    let isAuthenticated = allCookies[LOCAL_STORAGE_KEYS.ACCESS_TOKEN]
      ? true
      : false;
    this.setState({ isAuthenticated });
    if (!isAuthenticated && this.state.isSSOEnable && !payload.token) {
       window.location.href = config.SSO_URL + `?source=erpApp`;
    } else if (
      this.state.isSSOEnable &&
      isAuthenticated &&
      (!loggedInEntityInfo || !loggedInEntityInfo.defaultCompany) &&
      email
    ) {
      this.props.getLogedInUserInfo(
        {
          email: email,
          refreshToken: refreshToken,
          accessToken: accessToken,
          isB2CCustomer: 0,
        },
        this.props.history,
        this.props
      );
    } else if (
      isAuthenticated &&
      loggedInEntityInfo &&
      loggedInEntityInfo.defaultCompany
    ) {
      addToLocalStorage(LOCAL_STORAGE_KEYS.ACCESS_TOKEN, accessToken);
      addToLocalStorage(LOCAL_STORAGE_KEYS.REFRESH_TOKEN, refreshToken);
      addToLocalStorage(
        LOCAL_STORAGE_KEYS.EXPIRE_TIME,
        allCookies[LOCAL_STORAGE_KEYS.EXPIRE_TIME]
      );
      //addToLocalStorage(LOCAL_STORAGE_KEYS.ACCESS_TOKEN, code);
      //this.props.history.push('/admin/dashboard');
    }
    payload.token && this.props.activateCustomerAccount(payload, this.props);
    // this.props.setIsAlifApp(this.state.isAlifApp);
    queryStringObj.at &&
      this.props.verifyCredentials(
        {
          email: adminIds[2],
          relationshipEmployeeId: Number(adminIds[1]),
          source: "admin",
        },
        this.props.history,
        this.props
      );
  }
  componentWillReceiveProps(props) {
    const cookieOptions = {
      domain: '.' + (config.ROOT_DOMAIN || 'alpide.com'), // Replace with your desired domain
      path: '/', // Replace with the desired path
      expires: new Date(Date.now() + 86400 * 1000), // Replace with the desired expiration time
    };

    const { cookies } = this.props;
    if (props.sessionType && props.sessionType === 'invalid' && !this.state.isValidateSession) {
      this.setState({ isValidateSession: true });
        cookies.remove(LOCAL_STORAGE_KEYS.ACCESS_TOKEN, cookieOptions);
        cookies.remove(LOCAL_STORAGE_KEYS.REFRESH_TOKEN, cookieOptions);
        cookies.remove(LOCAL_STORAGE_KEYS.EXPIRE_TIME, cookieOptions);
        cookies.remove(LOCAL_STORAGE_KEYS.EMAIL, cookieOptions);
        logout();
    }
  }
  render() {
    if (this.state.isSSOEnable) {
      return this.state.isAuthenticated ? (
        <div
          style={{
            height: "100vh",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              height: "100vh",
              width: "100%",
              position: "absolute",
              top: 0,
              left: 0,
              background: "rgba(0,0,0,0)",
              zIndex: 99999,
            }}
          ></div>
          <div>
            <p style={{ fontSize: "16px" }}>
              Please wait while we setup your personalize experience.
            </p>
          </div>
          <iframe
            title=" "
            style={{
              width: "50%",
              height: "50vh",
              border: "none",
              outline: "none",
              zIndex: "1",
            }}
            src="https://lottie.host/?file=8156eef4-e17b-49ac-9420-7c8eb560b46d/NYvQ3Y8VCn.json"
          ></iframe>
        </div>
      ) : (
        <div
          style={{
            height: "100vh",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
          className="lds-ring"
        >
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      );
    } else {
      return (
        <div>
          {" "}
          {this.state.isAlifApp ? (
            <LoginAlifComponent
              {...this.props}
              {...this.state}
              updateState={(data) => {
                this.setState(data);
              }}
            />
          ) : (
            <LoginComponent
              {...this.props}
              {...this.state}
              updateState={(data) => {
                this.setState(data);
              }}
            />
          )}
        </div>
      );
    }
  }
}
const mapStateToProps = (state) => {
  return {
    sessionType: state.auth.sessionType,
    isLoading: state.common.isLoading,
  };
};
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      verifyCredentials,
      activateCustomerAccount,
      getLogedInUserInfo,
      // setIsAlifApp,
      showModal,
      hideModal,
    },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(withCookies(Login));