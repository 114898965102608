import axios from "axios";
import config from "../../../config/environmentConfig";
import { showToasterMessage } from "../../../utils";
import { COMMON_ACTIONS } from "../../../static/constants";
import { E_STORE } from "../../../static/constants";
const { lang } = require('../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');

export const fetchAllTestimonialList = (payload) => {
    return dispatch => {
        dispatch({ type: COMMON_ACTIONS.SHOW_LIST_LOADER });
        return axios.get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/testimonial/getAllTeastimonials?relationshipId=${payload.relationshipId}&pageSize=${payload.pageSize || 10}&pageNumber=${(payload.pageNumber || 1) - 1}`)
          .then(response => {
            const data = {
              pageNo: payload.pageNumber,
              list: response.data,
            };
            dispatch({ type: E_STORE.TESTIMONIAL_LIST, data });
          })
          .catch(err => {
            console.error(err);
          }).finally(()=>{
            dispatch({
              type: COMMON_ACTIONS.HIDE_LIST_LOADER,
            });
          })
      }
};




export const countTestimonialList = (payload) => {
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_HEADER_LOADER });
    return axios
      .get(
        `${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/testimonial/countAllTestimonials?relationshipId=${payload.relationshipId}`
      )
      .then((res) => {
        dispatch({
          type: E_STORE.TESTIMONIAL_COUNT,
          data: res.data,
        });
      })
      .catch((err) => {
        showToasterMessage({
          messageType: "error",
          description:
            "Some error occurred",
        });
      }).finally(()=>{
        dispatch({ type: COMMON_ACTIONS.HIDE_HEADER_LOADER });
      })
  };
};
export const deleteTestimonial = (payload,props)=>{

 
  return dispatch => {
  //   dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios.post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/testimonial/deleteTestimonial`,payload
    )
        .then(response => {
          if(response.status === 200){
            const payload ={
             relationshipId: props.companyInfo.relationshipId,
             pageNumber: props.pageNumber,
             pageSize: props.pageSize
            }
              
              props.fetchAllTestimonialList(payload);
              props.countTestimonialList(payload)
             return showToasterMessage({
                  messageType: "success",
                  description: "Deleted Succssfully.",
                });
         }
         else{
           return showToasterMessage({
             messageType: "success",
             description: response?.message,
           });
         }
        
         
          // window.location.reload();
        })
        .catch(err => {
          showToasterMessage({
              messageType: "error",
              description: "Some error occured",
            });
          //   dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        })
  }
}
