import React, { Component } from "react";
// import { AgGridReact } from "ag-grid-react";
import { Tabs } from "antd";
import { FormattedMessage, injectIntl } from "react-intl";
import { getPermissionData } from "../../../utils";
import {  ICONS,MODULE_CODED_VALUES } from "../../../static/constants";
import PageBreadcrumb from "../../PageBreadcrumb";
import RegistrationFormSetting from "../../../containers/modal/modalBody/settings/RegistrationFormSetting";
// import ShareRegistrationFormCompo from "../../../components/modal/modalBody/settings/ShareRegistrationForm";
// import AssignFormToTeacher from "../../../containers/modal/modalBody/schoolManagement/AssignFormToTeacher";
import "./index.scss";
import FormListTableComp from './formListTable.js';
import FormDraftList from "./formDraftList.js";
import PageHeader from "../../Common/pageHeader";
const { TabPane } = Tabs;

class StudentFormListComp extends Component {
  render() {

    // const format = CONSTANTS.DISPLAY_DATE_FORMAT;
    // const agGridStyle = {
    //   height: "100%",
    //   width: "100%",
    // };

    // const columnDefs = [
    //   {
    //     headerComponentFramework: () => {
    //       return <FormattedMessage id='setting.student.registration.list.formName' defaultMessage='' />;
    //     },
    //     field: 'programName'
    //   },
    //   {
    //     headerComponentFramework: () => {
    //       return <FormattedMessage id='user.text' defaultMessage='' />;
    //     },
    //     cellRendererFramework: (link) => {
    //      let stdNameList = (link.data.orgRegFormSettingPermissionList || []).map(usrObj => `${usrObj.firstName} ${usrObj.lastName || ''}`).join(', ');
    //       return <div>{stdNameList}</div>
    //     },
    //   },
    //   {
    //     headerComponentFramework: () => {
    //       return <FormattedMessage id='setting.student.registration.list.date' defaultMessage='' />;
    //     },
    //     cellRendererFramework: (link) => {
    //       let d = JSON.parse(link.data.formSetting)
    //       return <div>{link.data?.dateCreated ? getMomentDateForUIReadOnly({ date: link.data.dateCreated, format }) : ''}</div>
    //     },
    //   },
    //   {
    //     headerComponentFramework: () => {
    //       return <div>
    //         Action
    //       </div>;
    //     },
    //     field: 'action',
    //     cellRendererFramework: (params) => {

    //       return <div>
    //         <span className="cursor-pointer" title='Edit' onClick={
    //           () => {
    //             const modalData = {
    //               title: <div className='ant-modal-title'><FormattedMessage id='setting.student.registration.form.label' defaultMessage='' /></div>,
    //               modalBody: <RegistrationFormSetting registrationType={params.data.registrationType} {...this.props} registrationFormSettingId={params.data.registrationFormSettingId} style={{ overflow: 'auto' }} />,
    //               width: '100%',
    //               hideFooter: true,
    //               maskClosable: true,
    //               hideCloseIcon: true,
    //               hideTitle: true,
    //               wrapClassName: "modal-custom-registration"
    //             };
    //             this.props.pushModalToStack(modalData);
    //           }}>
    //           <i className='fa fa-pencil' />
    //         </span>
    //         {!params.data.isAccountHolder ?
    //           <span style={{ marginLeft: '10px' }} title='Delete' className="cursor-pointer" onClick={
    //             () => {
    //               const modalData = {
    //                 modalBody: <FormattedMessage
    //                   id='registration.form.delete.confirmation'
    //                   defaultMessage=''
    //                 />,
    //                 handleSubmit: () => {
    //                   this.props.deleteRegistrationForm({
    //                     relationshipId: this.props.relationshipId,
    //                     registrationFormSettingId: params.data.registrationFormSettingId

    //                   }, this.props);
    //                   //deleteContact(this.props, this.gridApi.getDisplayedRowAtIndex(params.node.rowIndex).data)
    //                 }
    //               };
    //               this.props.pushModalToStack(modalData);
    //             }}>
    //             <i className='fa fa-trash' />
    //           </span> : ''
    //         }
    //          <span style={{ marginLeft: '10px' }} className="cursor-pointer" title='Assign Permission' onClick={
    //           () => {
    //             openAssignClass(params.data);
    //           }}>
    //           Review Access
    //         </span>

    // <span style={{ marginLeft: '10px' }} className="cursor-pointer" title='Share' onClick={
    //     () => {
    //       this.props.pushModalToStack({
    //         title: <div>
    //           <FormattedMessage id='shareRegistrationForm.text' defaultMessage='' />
    //           <div style={{ 'fontSize': '12px' }}> {params.data.programName || ''}</div>
    //         </div>,
    //         modalBody: <ShareRegistrationFormCompo {...this.props} formSettingData={params.data} />,
    //         width: '50%',
    //         hideFooter: true,
    //       })
    //     }}>
    //     <i className='fa fa-share' />
    //   </span>
    //       </div >
    //     },
    //     width: 52
    //   }
    // ];

    const breadCrumbList = [
      {
        name: (
          <FormattedMessage id="sidebar.menuItem.school" defaultMessage="" />
        ),
      },
      {
        name: (
          <FormattedMessage id="sidebar.menuItem.forms" defaultMessage="" />
        ),
      },
    ];

    const openRegistrationForm = () => {
      const modalData = {
        title: (
          <div className="ant-modal-title">
            <FormattedMessage
              id="setting.student.registration.form.label"
              defaultMessage=""
            />
          </div>
        ),
        modalBody: (
          <RegistrationFormSetting
            registrationType="student"
            {...this.props}
            style={{ overflow: "auto" }}
          />
        ),
        width: "100%",
        hideFooter: true,
        maskClosable: true,
        hideCloseIcon: true,
        hideTitle: true,
        keyboard: false,
        wrapClassName: "modal-custom-registration",
      };
      this.props.pushModalToStack(modalData);
    };

    const handleTabChange = (key) =>{
      const payload = {
        relationshipId: this.props.companyInfo.relationshipId,
        pageNumber: 1,
        pageSize: 25,
        relationshipEmployeeId: (this.props.userInfo || {}).relationshipEmployeeId
      }
      switch(key){
        case 'formsList':
          this.props.getAllStudentForm(payload);
          this.props.updateState({
            activeTabKey: key
          })
          break;
        case 'formsDraftList':
          this.props.fetchRegistrationFormSettingDraftList(payload);
          this.props.countSchoolFormSettingDraft(payload);
          this.props.updateState({
            activeTabKey: key
          })
          break;
      }
    }

    return (
      <>
       
        <PageHeader {...this.props}
        pageName='sidebar.menuItem.forms'
        breadCrumbList={breadCrumbList}
        canCreate={true}
        onCreate={openRegistrationForm}
        moduleCode = {MODULE_CODED_VALUES.SCHOOL_FORM}
      
        />

        <div className="view-container">
          <Tabs
            activeKey={this.props.activeTabKey || 'formsList'} 
            onChange={handleTabChange}
          >
            <TabPane key="formsList" tab="Form List">
              <FormListTableComp {...this.props} />
            </TabPane>
            <TabPane key="formsDraftList" tab="Draft">
              <FormDraftList {...this.props} />
            </TabPane>
          </Tabs>
        </div>
      </>
    );
  }
}

export default injectIntl(StudentFormListComp);
