import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import EmployeeMeta from '../../../components/company/EmployeeMeta';
import queryString from 'query-string';

class EmployeeMetaContainer extends Component {
  constructor(props) {
    super(props);
    const linkProps = queryString.parse(decodeURIComponent(props.location?.search||"")) || {}
    this.state = {
      selectedMenuItem : linkProps.active || "Department",
      isLoading: true,
   
    }
    };
  
  
 
  render() {
    return <EmployeeMeta {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />;
  }

}

const mapStateToProps = (state) => {
  return {

    companyInfo: state.common.companyInfo,
    permissions: state.auth.permissions,
   
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeMetaContainer);
