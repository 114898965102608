import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';

import { CONSTANTS } from '../../../../../static/constants';
import { getMomentDateForUIReadOnly, } from '../../../../../utils';




const OpeningStockComp = (props) => {

    const openingQuantitydetails = props.openingQuantityDetails || {};
    const poDate = openingQuantitydetails?.poDate;
    const supplierName = openingQuantitydetails?.supplierName;



















    return (
        <div>
            <div className="table-container" style={{ height: "480px" }}>
                <table id="current-stock-table">
                    <thead>
                        <tr>
                            <th className="cell-size-50">#</th>

                            {/* {!isColumnEmpty.studentGrade && <th className="text-center"><FormattedMessage id='grade.text' defaultMessage='' /></th>} */}

                            {<th>
                                {<span>  <FormattedMessage id='modal.txDetails.common.product' defaultMessage='' /></span>}


                            </th>}
                            <th>  {<span>  <FormattedMessage id='modal.txDetails.common.description' defaultMessage='' /></span>}</th>
                           
                            {<th className="text-center"><FormattedMessage id='modal.txDetails.common.qty' defaultMessage='' /></th>}
                           


                            {<th className="text-center"><FormattedMessage id='modal.txDetails.common.sku' defaultMessage='' /></th>}
                            {<th className="text-center"><FormattedMessage id='drawer.supplier.relationshipSince.placeholder' defaultMessage='' /></th>}
                            {<th className="text-center"><FormattedMessage id='drawer.inventory.purchasedPrice' defaultMessage='' /></th>}
                            {<th className="text-center"><FormattedMessage id='product.openingStock.selectedDebitledger' defaultMessage='' /></th>}
                           
                            {<th className="text-center"><FormattedMessage id='product.openingStock.selectedCreditledger' defaultMessage='' /></th>}
                            <th className="text-center"><FormattedMessage id='drawer.supplier.companyName' defaultMessage='' /></th>
                        </tr>
                    </thead>
                    <tbody>
                        {(openingQuantitydetails?.openingQuantityDetailList || [])?.map((val, i) => {

                            const ledegerInfo = (val?.inventoryItemOpeningQuantityCOATxList || []);
                            const creditLedegerInfo = (ledegerInfo).find((item) => item.accountingEntry === "Credit");
                            const debitLedgerInfo = (ledegerInfo).find((item) => item.accountingEntry === "Debit");
                            return (
                                <tr key={i}>
                                    <td className="cell-size-50">{i + 1}</td>
                                    {<td className="text-left">                              {`${val?.itemName}${val?.attributeValue1 ? ` (${val?.attributeValue1}  ${val?.attributeValue2 ? `| ${val?.attributeValue2}` : ''} ${val?.attributeValue3 ? `| ${val?.attributeValue3}` : ''}) ` : ''}` || ""}
                                    </td>}
                                    
                                    {<td>{val?.description || <div className="empty-data-box" />}</td>}

                                 
                                    {<td className="text-center">{val?.quantity}</td>}
                                    {<td className="text-center">{val?.sku}</td>}
                                    
                                    <td className='text-center'>{(poDate || val?.dateCreated) ? getMomentDateForUIReadOnly({ date: (poDate || val?.dateCreated), format: CONSTANTS.DISPLAY_DATE_FORMAT }) : ''}</td>

                                    {<td className="text-center">{val?.anItemPurchasePrice}</td>}
                                    {<td className="text-center">{debitLedgerInfo?.ledgerAccountName || <div className="empty-data-box" />}</td>}
                                    {<td className="text-center">{creditLedegerInfo?.ledgerAccountName || <div className="empty-data-box" />}</td>}

                                    {<td className="text-center">{supplierName || <div className="empty-data-box" />}</td>}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>

        </div>

    );

}










export default OpeningStockComp;
