import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import SetionComp from "../../../components/schoolManagement/section";
import { fetchDataIfNeeded, fetchPaginationDataIfNeeded } from "../../../utils";
import {
  getSection,
  getSectionCount,
  saveSection,
  deleteSection,
  resetPaginatedData,
  getSectionByClassNameCount,
  getsectionByClassName,
} from "./action";
import { showModal, hideModal } from "./../../../actions/commonActions";
import { getClassList } from "../class/action";
// import { FormattedMessage } from 'react-intl';

class School extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageNumber: 1,
      pageSize: 25,
    };
  }

  componentDidMount() {
    const companyInfo = this.props.companyInfo || {};
    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      relationshipEmployeeId: (this.props.userInfo || {})
        .relationshipEmployeeId,
      pageNumber: 1,
      pageSize: 25,
      formId: this.props.defaultSchoolForm,
    };
    fetchPaginationDataIfNeeded(
      "getSection",
      "sectionList",
      this.props,
      payload
    );
    fetchDataIfNeeded("getSectionCount", "sectionCount", this.props, payload);

    this.props.getClassList(payload);
  }

  render() {
    return (
      <SetionComp
        {...this.props}
        {...this.state}
        updateState={(data) => {
          this.setState(data);
        }}
      ></SetionComp>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    userInfo: state.common.userInfo,
    relationshipId: state.common.companyInfo.relationshipId,
    sectionList: state.school.sectionList,
    sectionCount: state.school.sectionCount,
    classList: state.school.classList,
    listLoading: state.common.listLoading,
    headerLoading: state.common.headerLoading,
    permissions: state.auth.permissions,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getSection,
      getSectionCount,
      deleteSection,
      saveSection,
      resetPaginatedData,
      showModal,
      hideModal,
      getClassList,
      getSectionByClassNameCount,
      getsectionByClassName,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(School);
