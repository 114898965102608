import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import EmailCampaign from '../../../../components/modal/modalBody/EmailCampaign';
import MarketingCampaignDrawer from "../../../../containers/drawer/EmailMarketing/MarketingCampaign";
import { fetchProjects, popModalFromStack, pushModalToStack, showModal } from '../../../../actions/commonActions';
import { getAllFormsBuilder } from '../../../communication/NewLetterListing/action';
import { getAllDG } from '../../../communication/distribution-group/action';
import { getFromEmail } from '../../../drawer/email/action';
import { fetchNewsLetter } from '../../../B2B/NewLetterPreview/action';
import { createCampaign,getDGDetails } from './action';
import { fetchCampaign, fetchCampaignEmailStatistics, fetchgAggregatedReportByRid } from '../../../emailMarketing/emailCampaigns/action';
import { fetchAllSettings } from '../../../integrations/Email/action';

// import { getDGDetails } from '../../../drawer/communication/distribution-group/action';


class EmailCampaignCon extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: "1",
            isDisable: true,
            templateType: 'marketingTemplate',
            isReplicate:props.isReplicate
        }
    }
    componentDidMount() {
        const companyInfo = this.props.companyInfo || {};
        const payload = {
            companyInfo,
            relationshipId: companyInfo.relationshipId,
            templateType: 'marketingTemplate',
        };
        this.props.getAllFormsBuilder(payload);
        this.props.fetchProjects(payload);
        this.props.getAllDG(payload);
        this.props.getFromEmail(payload);
        this.props.fetchAllSettings(payload)
    }
    componentWillReceiveProps(props) {
        if (Object.keys(props.dgDetails)?.length) {
            this.setState({
                dgDetails: props.dgDetails
            })
        }
    }

    render() {
        return <>
            {this.state.marketingCampaignDrawer && <MarketingCampaignDrawer {...this.state} {...this.props} updateState={(data) => { this.setState(data) }} />}
            <EmailCampaign {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
        </>
    }
}
const mapStateToProps = (state) => {
    return {
        companyInfo: state.common.companyInfo,
        relationshipId: state.common.companyInfo.relationshipId,
        detailLoading: state.common.detailLoading,
        projectList: state.common.projectList,
        newsletterTemplateList: state.communicationReducer.newsletterTemplateList,
        dgList: state.communicationReducer.dgList,
        emailAddressList: state.common.emailAddressList,
        newsLetterTemplate: state.membershipReducer.newsLetterTemplate,
        dgDetails: state.membershipReducer.dgDetails,
        senderEmails: state.settings.senderEmails,
        userInfo: state.common.userInfo,
        brevoSettings: state.integration.brevoSettings,
        campaignList: state.communicationReducer.campaignList,

    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    pushModalToStack,
    getAllFormsBuilder,
    fetchProjects,
    getAllDG,
    getFromEmail,
    fetchNewsLetter,
    showModal,
    createCampaign,
    getDGDetails,
    fetchCampaign,
    popModalFromStack,
    fetchAllSettings,
    fetchgAggregatedReportByRid,
    fetchCampaignEmailStatistics

}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(EmailCampaignCon);