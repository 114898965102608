import { PROFILE_ACCOUNT_INFO } from '../static/constants';

const initialState = {
  allSubscriptions:[],
  packagePlan:{},
  billingHistory:[],
  downloadInvoiceData:{},
  paymentAddressData:{},
}

const ProfileReducer = (state = initialState, action) => {
  switch (action.type) {
    case PROFILE_ACCOUNT_INFO.ALL_SUBSCRIBED_PLANS:
      return {
        ...state,
        allSubscriptions: action.data,
      };
      case PROFILE_ACCOUNT_INFO.PACAKGE_PLAN_BY_CODE:
        return {
          ...state,
          packagePlan: action.data,
        };
        case PROFILE_ACCOUNT_INFO.BILLING_HISTORY_PACKAGE_PLANS:
          return {
            ...state,
            billingHistory: action.data,
          };
          case PROFILE_ACCOUNT_INFO.DOWNLOAD_BILLING_INVOICE:
            return {
              ...state,
              downloadInvoiceData: action.data,
            }; 
            case PROFILE_ACCOUNT_INFO.PAYMENT_ADDRESS_INFO:
              return {
                ...state,
                paymentAddressData: action.data,
              }; 
    default:
      return state;
  }
};

export default ProfileReducer;