import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getAllCoupons, createCoupon, updateCoupon, deleteCoupon, getAllCouponUsage, getAllCouponsCount, getCouponUsageCount } from './action';
import { showModal, hideModal, pushModalToStack, popModalFromStack } from './../../../../../actions/commonActions';
import CouponComp from '../../../../../components/modal/modalBody/eStore/Coupon';
import CreateCouponDrawer from '../../../../drawer/eStore/createcoupon';

class Coupon extends Component {
    constructor(props){
        super(props);
        this.state= {
            isHeaderMenuLoaded: false,
            websiteUrl: '',
            shopName:'',
            pageNumber: 1,
            pageSize: 25
       
        }
    }

    componentDidMount() {
        const companyInfo = this.props?.userInfo || {};
        const payload = {
            relationshipId: companyInfo?.relationshipId,
        };
        this.props.getAllCoupons(payload);
        this.props.getAllCouponsCount(payload);
    }

    
    render() {
        return <>
            {this.state.createCouponDrawerVisible && (
                <CreateCouponDrawer
                    {...this.props}
                    {...this.state}
                    updateState={(data) => { this.setState(data) }} 
                    updateDrawerState={(data) => { this.setState(data) }}
                />
            )}
            <CouponComp {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
        </>
    }

}


const mapStateToProps = (state) => {
    return {
        couponList: state.eStore.couponList,
        couponCount: state.eStore.couponCount,
        companyInfo: state.common.companyInfo,
        userInfo: state.common.userInfo,
        relationshipId: state.common.companyInfo.relationshipId,
        storeValues: state.common.store_values,
        listLoading: state.common.listLoading,
        saveLoading: state.common.saveLoading,
        couponUsageList: state.eStore.couponUsageList,
        couponUsageCount: state.eStore.couponUsageCount,
        permissions: state.auth.permissions,
    };
  };
  
const mapDispatchToProps = (dispatch) => bindActionCreators({
    getAllCoupons,
    createCoupon,
    updateCoupon,
    deleteCoupon,
    showModal,
    hideModal, 
    pushModalToStack, 
    popModalFromStack,
    getAllCouponUsage, getAllCouponsCount,
    getCouponUsageCount
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Coupon);