import axios from 'axios';
import config from '../../../config/environmentConfig';
import { COMMON_ACTIONS, CRM_ACTION_LIST } from '../../../static/constants';
import {  showToasterMessage } from '../../../utils';
const { lang } = require('../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');

export const fetchCrmLeadCampaign = (payload, ) => {
    return dispatch => {
      dispatch({ type: COMMON_ACTIONS.SHOW_LIST_LOADER });
      return axios
        .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/leadCampaign/getAllCampaigns?relationshipId=${payload.relationshipId}`)
        .then(res => {

            const data = {
                list: res.data,
                pageNo: res.pageNo,
              }
          dispatch({ type: CRM_ACTION_LIST.LEAD_CAMPAIGN_LIST, data: data });
        })
        .catch(err => {
          dispatch({ type: COMMON_ACTIONS.HIDE_LOADER_EMAIL });
        }).finally(() => {
          dispatch({
            type: COMMON_ACTIONS.HIDE_LIST_LOADER,
          });
        })
  }
  }