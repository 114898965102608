import React, { useMemo } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Skeleton, Popover, Menu, Pagination, Input } from 'antd';
import { InsertRowRightOutlined, MoreOutlined, UserOutlined } from '@ant-design/icons';
import PageBreadcrumb from "../../PageBreadcrumb";
import DropdownAction from "antd/lib/dropdown";
import { checkACLPermission, getMomentDateForUIReadOnly, getPermissionData, sortTable } from '../../../utils';
import find from 'lodash.find';
import { CONSTANTS, ICONS, MODULE_CODED_VALUES, PERMISSION_VALUES } from '../../../static/constants';

import { DownloadOutlined } from "@ant-design/icons";
import PageHeader from '../../Common/pageHeader';
const { Search } = Input;
const HourlyPayGrade = (props) => {
  const {permissions} = props
  const primaryPerm = (permissions && permissions.primary) || [];
  const permissionsData = useMemo(()=>{
    return getPermissionData(primaryPerm , MODULE_CODED_VALUES.HRMS_PAYROLL );
  },[])
  
  const breadCrumbList = [
    {
      name: "Dashboard",
    },
    {
      name: <FormattedMessage id='sidebar.menuItems.employee' />,
    },
    {
      name: "Hourly Pay Grade",
    }
  ];



  const actionMenu = (e) => {
    return (
      <Menu className="row-action-dropdown">
        <Menu.Item key="0" value="title">
          Actions
        </Menu.Item>

        <Menu.Divider />
{
  permissionsData?.update ?   <Menu.Item
  key="1"
  value="edit"
  onClick={() => {

  }}
>
  <i className={ICONS.EDIT} />
  Edit
</Menu.Item>: ""
}
      

        {( permissionsData?.delete && e.isAccountHolder) ? (
          <Menu.Item
            key="2"

          >
            <i className={ICONS.DELETE} />
            Delete
          </Menu.Item>
        ) : (
          ""
        )}


      </Menu>
    );
  };

  return (
    <>
     <PageHeader {...props}
        pageName="heading.team.payrollManagement.hourlyPayGrade"
        breadCrumbList={breadCrumbList}
        canCreate={true}
        onCreate={()=>{
          props.updateState({ hourlyPayDrawer: true });
        }}
        moduleCode = {MODULE_CODED_VALUES.HRMS_PAYROLL}
      
        />
     

      <div className="view-container">

        <div className="view-container-actions">
          <Skeleton loading={props.headerLoading} paragraph={false} rows={1} active>
            <div className="left-actions">
              <div className="table-heading">
                <FormattedMessage
                  id="company.payrollMangement.hourlyPayGrade.table.heading"
                  defaultMessage=""
                />
              </div>
              {[]?.length ? (
                <>
                  <div className="vertical-sep" />
                  <div>{[]?.length}</div>
                </>
              ) : (
                ""
              )}
            </div>
            <div className="right-actions">
              <Search
                placeholder="Search Hourly Pay Grade"
                value={props.searchedText}
                onChange={(e) => {
                  // updateState({ searchedText: e.target.value, pageNumber: 1 });
                  // onSearchedInputChange(e.target.value, props);
                }}
                onSearch={(searchedText) => {
                  // updateState({ searchedText, pageNumber: 1 });
                  // onSearchedInputChange(searchedText, props);
                  // handleSearchedText(searchedText, props);
                }}
              />
              <DropdownAction
                //  overlay={downloadMenu()}
                trigger={["click"]}>
                <span>
                  <DownloadOutlined /> &nbsp;{" "}
                  <FormattedMessage
                    id="common.action.dropdown.downloadAs"
                    defaultMessage=""
                  />
                </span>
              </DropdownAction>

            </div>
          </Skeleton>
        </div>

        <div className="table-container">
        <Skeleton loading={props.listLoading} paragraph={{ rows: 17 }} active>

            <table id="employee-table">
              <thead>
                <tr>
                  <th>
                    <FormattedMessage id="serial.no" defaultMessage=" " />
                  </th>

                  <th>
                    <FormattedMessage
                      id="company.payrollManagement.hourlyPayGradeName"
                      defaultMessage=" "
                    />
                  </th>


                  <th>
                    <FormattedMessage
                      id="company.payrollManagement.hourlyRate"
                      defaultMessage=" "
                    />
                  </th>

{
   (permissionsData?.update || permissionsData?.delete) ?     <th className="text-center">
  <FormattedMessage
    id="actions.text"
    defaultMessage=" "
  />
</th>: ""
}

              



                </tr>
              </thead>
              <tbody>
                {([]).map((rowData, index) => {
                  return (
                    <tr key={index}>
                      <td>{index + 1}</td>

                      <td>
                        {rowData.employeeCode ? rowData.employeeCode : "-"}
                      </td>

                      <td>
                        {rowData.firstName +
                          (rowData.lastName
                            ? " " + rowData.lastName
                            : "")}
                      </td>

                      <td>{rowData.empType}</td>

                      <td>{rowData.hierarchyName}</td>
                      <td>{rowData.hierarchyCode}</td>
                      <td>{rowData.jobTitleName}</td>

                      <td>{rowData.jobRoleName}</td>




                      <td>
                        {rowData.dateCreated
                          ? getMomentDateForUIReadOnly({
                            date: rowData.dateCreated,
                            format: CONSTANTS.TABLE_DATE_FORMAT,
                          })
                          : "-"}
                      </td>
{
   (permissionsData?.update || permissionsData?.delete) ? <td>
  <DropdownAction
       

    overlay={actionMenu(rowData)}
    trigger={["click"]}
  >
    <i className={ICONS.MORE} />
  </DropdownAction>
</td>: ""
}
                      
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </Skeleton>
        </div>

        {/* <div className="footer">
          <Skeleton loading={props.headerLoading} paragraph={false} rows={1} active>

              <div className="f-left"></div>
              <div className="f-right">
                <Pagination
                  size="small"
                  total={30}
                  showTotal={showTotal}
                  defaultPageSize={props.pageSize}
                  showSizeChanger
                  pageSizeOptions={[10, 25, 50, 100, 200]}
                  // showQuickJumper
                  onChange={(pageNumber, pageSize) => {
                    loadPageData(pageNumber, pageSize);
                    updateState({ pageSize: pageSize, pageNumber: pageNumber });
                  }}
                  onShowSizeChange={(pageNumber, pageSize) => {
                    handlePageSizeChange(pageNumber || 1, pageSize, props);
                  }}
                />
              </div>
              </Skeleton>
            </div> */}
      </div>
    </>
  );
};




export default injectIntl(HourlyPayGrade);
