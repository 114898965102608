import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchPaymentModes, showModal, hideModal, fetchCashEquivalentLedgers, pushModalToStack, popModalFromStack } from './../../../../actions/commonActions';
import { LEDGER_ACTION_LIST } from '../../../../static/constants';
import { fetchUnpaidInvoices, resetPaginatedData } from '../../../modal/modalBody/customer/UnpaidInvoices/action';
import { fetchCustomers } from '../../Listing/action';
import { fetchDataIfNeeded, fetchPaginationDataIfNeeded } from '../../../../utils';
import PayInvoice from '../../../../components/customer/invoice/PayInvoice';
import { fetchRemarksTemplate, resetRemarksData } from '../../../modal/modalBody/settings/RemarksTemplate/action';
import find from 'lodash.find';
import CashSaleInvoice from '../../../../components/customer/invoice/CashSaleInvoice';
class CashSaleInvoiceContainer extends Component {
  constructor(props) {
    super(props);

    const val =   find(props.paymentDetails?.coaTxPaymentsList, {
      accountingEntry: "DEBIT",
    }) || {};
    this.state = {
        paymentModeDetails: props.paymentDetails?.paymentModeDetail,
        paymentModeName: props.paymentDetails?.paymentModeName,
        paymentModeId: props.paymentDetails?.paymentModeId,
        remarks: props.paymentDetails?.remarks,
        ledgerAccountName: props.paymentDetails?.paymentModeName === 'Cash' ? LEDGER_ACTION_LIST?.DEFAULT_LEDGER_TYPE?.CASH_IN_HAND: LEDGER_ACTION_LIST?.DEFAULT_LEDGER_TYPE?.CASH_AT_BANK,
        chartOfAccountDetailsId: val?.ledgerAccountId,
        reference: props.paymentDetails?.reference,
        
  
    };
  }
   componentDidMount(){
    const companyInfo = this.props.companyInfo || {};
    const payload = {
      relationshipId: companyInfo.relationshipId,
      customerId: this.props.invoiceData?.customerId,
      pageSize: 100,
      pageNumber: 1
    };
    fetchDataIfNeeded('fetchCashEquivalentLedgers','cashEquivalentLedgers', this.props, payload);
   }
  componentWillReceiveProps(props) {
 
    if (!this.state.remarks && props.remarksData &&  props.remarksData.isFetched && !this.state.isRemarksSet) {
      this.setState({ remarks: props.remarksData.content || '', isRemarksSet: true });
      setTimeout(()=>{
        props.resetRemarksData();
    },500)
    }
  }

  render() {
    return (
      <CashSaleInvoice {...this.props} {...this.state}  updateModalState={(data) => { this.setState(data) }} />
    )
  }
}

const mapStateToProps = (state) => {
  return {
    countries: state.common.countries,
    companyInfo: state.common.companyInfo,
    userInfo: state.common.userInfo,
    unpaidInvoiceList: state.customer.unpaidInvoiceList,
    paymentModeList: state.common.paymentModeList,
    customers: state.customer.customers,
    cashEquivalentLedgers: state.common.cashEquivalentLedgers,
    nextTxNumber: state.customerPayment.nextTxNumber,
    remarksData: state.settings.remarksData,
    detailLoading: state.common.detailLoading,
    permissions: state.auth.permissions,

  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchUnpaidInvoices,
  fetchPaymentModes,
  fetchCustomers,
  
  fetchCashEquivalentLedgers,
  resetPaginatedData,
  showModal,
  hideModal,
  resetRemarksData,

  fetchRemarksTemplate,
  pushModalToStack,
  popModalFromStack
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CashSaleInvoiceContainer);
