import axios from 'axios';
import config from '../../../../../config/environmentConfig';
import {  COMMON_ACTIONS, SETTING_ACTION_LIST } from '../../../../../static/constants';
import { showToasterMessage } from '../../../../../utils';
const { lang } = require('../../../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');



  export const createOrUpdteUser = (payload) => {
    return dispatch => {
      dispatch({ type: COMMON_ACTIONS.SHOW_SAVE_LOADER });
      return axios
        .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/user/createOrUpdateUser`, payload)
        .then(res => {
          showToasterMessage({description: (res || {}).message || 'Access has been granted successfully', messageType: 'success' })
          axios
            .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/user/getAllUsers?relationshipId=${payload.relationshipId}`)
            .then(res => {
              dispatch({ type: SETTING_ACTION_LIST.ALL_COMPANY_USERS, data: res.data });
            })
        })
        .catch(err => {
          showToasterMessage({ description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        })
        .finally(()=>{
          dispatch({ type: COMMON_ACTIONS.HIDE_SAVE_LOADER });
        });
    }
  }

  export const fetchAllEmployees = (payload, history) => {
    return dispatch => {
      dispatch({ type: COMMON_ACTIONS.SHOW_LIST_LOADER });
      return axios
        .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/user/getUsersForAlpideAccess?relationshipId=${payload.relationshipId}`)
        .then(res => {
          dispatch({ type: SETTING_ACTION_LIST.USER_FOR_ALPIDE_ACCESS, data: res.data });
        })
        .catch((err) => {
          if (err.__isRetryRequest) {
            err.actionToCall = SETTING_ACTION_LIST.USER_FOR_ALPIDE_ACCESS;
          }
        })
        .finally(()=>{
          dispatch({ type: COMMON_ACTIONS.HIDE_LIST_LOADER });
        })
    }
  }