import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import OpeningStockComp from '../../../../../components/modal/modalBody/product/OpeningQuantity';
import { fetchOpeningQuantityById } from './action';
class OpeningStock extends Component {
       constructor(props){
        super(props)
       }
    
    componentWillMount() {
        const companyInfo = this.props.companyInfo || {};
       const payload ={
        relationshipId: companyInfo?.relationshipId,
        openingQuantityMasterId: this.props.stockPayload?.openingQuantityMasterId
       }
        this.props.fetchOpeningQuantityById(payload)
    }

    render() {
        return <OpeningStockComp  {...this.state} {...this.props} updateState={(data) => { this.setState(data) }} />
    }

}


const mapStateToProps = (state) => {
    return { 
       companyInfo: state.common.companyInfo,
       openingQuantityDetails: state.inventory.openingQuantityData,
    }
  }

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchOpeningQuantityById,

}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(OpeningStock);