import React, {Component} from "react";
import { FormattedMessage } from "react-intl";
import { fixedDecimalAndFormateNumber } from "../../../utils";
import { Skeleton } from "antd";

class TopSupplierPayment extends Component {
    render() {
        //         const keys1 = Object.keys(this.props.topPurchaseSupplier || {});
        // const values1 = Object.values(this.props.topPurchaseSupplier || {});
        const keys = Object.keys(this.props.topPurchaseSupplier || {});
        // const values = Object.values(this.props.topPurchaseSupplier || {});
        return (
            <Skeleton loading={this.props.listLoading} paragraph={{row:4}}  active>

            <div className="table-container">
                <table>
                    <thead>
                    <tr>
                        <th><FormattedMessage id='serial.no' defaultMessage=''/></th>
                        <th>Top Supplier</th>
                        <th>Total Purchase</th>
                        <th>Total Payment</th>
                    </tr>
                    </thead>
                    <tbody>
                    {keys.map((item, i) => {
                        return (
                            <tr key={i}>
                                <td>{i+1}</td>
                                <td>{item}</td>
                                <td>{(this.props.topPurchaseSupplier || {})[item]?(this.props.companyInfo || {}).currencyIcon ? <i className={(this.props.companyInfo || {}).currencyIcon}></i> : (this.props.companyInfo || {}).currencyCode + " ":'-'}{fixedDecimalAndFormateNumber((this.props.topPurchaseSupplier || {})[item] || 0) ||  ''}</td>
                                <td>{(this.props.topPaymentSupplier || {})[item]?(this.props.companyInfo || {}).currencyIcon ? <i className={(this.props.companyInfo || {}).currencyIcon}></i> : (this.props.companyInfo || {}).currencyCode + " ":'-'}{fixedDecimalAndFormateNumber((this.props.topPaymentSupplier || {})[item] || 0) ||  ''}</td>
                                {/* <td>{(this.props.companyInfo || {}).currencyIcon ? <i className={(this.props.companyInfo || {}).currencyIcon}></i> : (this.props.companyInfo || {}).currencyCode + " "}{(values[i]).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td> */}
                            </tr>
                        );
                    })}
                    </tbody>
                </table>
            </div>
            </Skeleton>

        )
    }
}

export default TopSupplierPayment;




// import React from "react";
// import ReactApexChart from "react-apexcharts"

// class TopSupplierPayment extends React.Component {
//     constructor(props) {
//         super(props);
//         const dataArr = [];
//         Object.values(this.props.topPaymentSupplier || {}).forEach((e)=>{
//             dataArr.push(Math.round(e));
//         });
//         this.state = {

//             series: [{
//                 name: 'Amount ('+(this.props.companyInfo ||{}).currencyCode+')',
//                 data: dataArr
//             }],
//             options: {
//                 chart: {
//                     height: 250,
//                     type: 'bar',
//                     events: {
//                         click: function (chart, w, e) {
//                         }
//                     }
//                 },
//                // colors: colors,
//                 plotOptions: {
//                     bar: {
//                         columnWidth: '45%',
//                         distributed: true
//                     }
//                 },
//                 dataLabels: {
//                     enabled: false
//                 },
//                 legend: {
//                     show: false
//                 },
//                 xaxis: {
//                     categories: Object.keys(this.props.topPaymentSupplier || {}),
//                     labels: {
//                         style: {
//                             //colors: colors,
//                             fontSize: '12px'
//                         }
//                     }
//                 }
//             },


//         };
//     }



//     render() {
//         return (


//             <div id="chart">
//                 <ReactApexChart options={this.state.options} series={this.state.series} type="bar" height={350} />
//             </div>


//         );
//     }
// }

// export default TopSupplierPayment;