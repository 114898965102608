import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import LoginWorkFlowComp from '../../../../../components/modal/modalBody/eStore/LoginWorkFlow';
import { fetchE_StoreData } from '../../../../../actions/commonActions'; 
import { loginSettingSave } from './action';
import { showModal,hideModal } from '../../../../../actions/commonActions';
class LoginWorkFlow extends Component {
    constructor(props){
        super(props);
        this.state= {
           dataInit:false,
        //    this is added for the default setting 
        //    
           setting:[1,0,0,0]
        }
    }
       
    componentWillMount() {
       
        const companyInfo = this.props.userInfo || {};
        const payload = {
            relationshipId: companyInfo.relationshipId,
            
        };
        this.props.fetchE_StoreData(payload)

    }
    

    componentWillReceiveProps(props){
      
        if(Object.keys(props.storeData).length && !this.state.dataInit){
            let setting = (props.storeData?.loginSetting || 1000).toString().split("").map(Number);
            console.log("heloo logs","ddd",props.storeData,setting);
             
             this.setState({
                dataInit:true,
                setting
             })
             
        }
      }
    
    render() {
        return <>
        {this.state.dataInit?    <LoginWorkFlowComp {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />:""}
        </>
    }

}


const mapStateToProps = (state) => {
    return {
        userInfo: state.common.userInfo,
        relationshipId: state.common.companyInfo.relationshipId,
        storeData:state.common.store_values,
        permissions: state.auth.permissions,

     
    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchE_StoreData,loginSettingSave, showModal,hideModal
   
}, dispatch);

export default connect(mapStateToProps,mapDispatchToProps)(LoginWorkFlow);