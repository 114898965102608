import React, { Fragment } from "react";
import { Skeleton, Input, Empty } from 'antd';
import * as debounce from 'lodash.debounce';
import { injectIntl,FormattedMessage } from 'react-intl';
// import NewLedgerComponent from '../../../containers/modal/modalBody/finance/LedgerAccount';
// import { AG_GRID_CLASS_CONSTANTS } from "../../../static/cssClassConstants";
// import { CustomAgGrid } from "../../general/CustomAgGrid";
// import { MODULE_CODED_VALUES, PERMISSION_VALUES } from '../../../static/constants';
import { sortTable } from '../../../utils';
import {SortAscendingOutlined,SortDescendingOutlined,LockOutlined,DeleteOutlined } from '@ant-design/icons';


const { Search } = Input;

let gridApi = {};



// const agGridStyle = {
//     height: '100%',
//     width: '100%'
// };

// const onFirstDataRendered = (params) => {
//     params.api.sizeColumnsToFit();
// }
// const getRowHeight = (params) => {
//     return 30;
// }

const CategoryComp = (props) => {
    const { allCategories,dir=0 } = props;

    // const menu = (
    //     <Menu>
    //         <Menu.Item key="upload_customer">
    //             <i className="fa fa-file-pdf-o" />
    //             &nbsp;Download PDF
    //         </Menu.Item>
    //     </Menu>
    // );

    // const onGridReady = (params) => {
    //     gridApi = params.api;
    // }

    // const getActionPermissionData = () => {
    //     const primaryPerm = permissions.primary || [];
    //     const permittedAction = {
    //         create: checkACLPermission(primaryPerm, MODULE_CODED_VALUES.LEDGER_ACCOUNTS, PERMISSION_VALUES.CREATE)
    //     };
    //     Object.keys(permittedAction).forEach((operation) => {
    //         if (permittedAction[operation] === false) {
    //             delete permittedAction[operation];
    //         }
    //     })
    //     return permittedAction;
    // }

    // const permittedActions = getActionPermissionData();
    // const columnDefs = [
    //     {
    //         headerComponentFramework: () => {
    //             return <Tooltip placement="top" title={props.intl.formatMessage(
    //                 {
    //                   id: 'tooltip.category',
    //                   defaultMessage: ''
    //                 }
    //               )}>
    //                 <div className='table-header-column-container'>
    //                     <span className='table-header-column'>Category</span>
    //                 </div>
    //             </Tooltip>;
    //         },
    //         field: "categoryName",
    //         resizable: true,
    //         rowDrag: true,
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <Tooltip placement="top" title={props.intl.formatMessage(
    //                 {
    //                   id: 'tooltip.startAcNumber',
    //                   defaultMessage: ''
    //                 }
    //               )}>
    //                 <div className='table-header-column-container'>
    //                     <span className='table-header-column'> Starting Account No</span>
    //                 </div>
    //             </Tooltip>;
    //         },
    //         field: "minAccountNumber",
    //         cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN,
    //         resizable: true,
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <Tooltip placement="top" title={props.intl.formatMessage(
    //                 {
    //                   id: 'tooltip.endAcNumber',
    //                   defaultMessage: ''
    //                 }
    //               )}>
    //                 <div className='table-header-column-container'>
    //                     <span className='table-header-column'> Ending Account No</span>
    //                 </div>
    //             </Tooltip>;
    //         },
    //         field: "maxAccountNumber",
    //         cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN,
    //         resizable: true,
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <Tooltip placement="top" title={props.intl.formatMessage(
    //                 {
    //                   id: 'tooltip.categoryGrp',
    //                   defaultMessage: ''
    //                 }
    //               )}>
    //                 <div className='table-header-column-container'>
    //                     <span className='table-header-column'>Category Group</span>
    //                 </div>
    //             </Tooltip>;
    //         },
    //         field: "categoryGroupName",
    //         resizable: true,
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <Tooltip placement="top" title={props.intl.formatMessage(
    //                 {
    //                   id: 'tooltip.systemAccount',
    //                   defaultMessage: ''
    //                 }
    //               )}>
    //                 <div className='table-header-column-container'>
    //                     <span className='table-header-column'> System Account?</span>
    //                 </div>
    //             </Tooltip>;
    //         },

    //         field: "inBuilt",
    //         resizable: true,
    //         valueFormatter: (data) => {
    //             data.value = data.value ? 'Yes' : 'No';
    //             return data.value;
    //         }
    //     }
    // ]

    const onSearchedInputChange = debounce((searchedText, props) => {
        handleSearchedText(searchedText, props)
    }, 2000);

    const handleSearchedText = (searchedText, props) => {
        gridApi.setQuickFilter(searchedText || '');
    }


      const sortColumn = (e) => {
        sortTable(document.getElementById("ledger-category"), e.target.cellIndex, 1 - dir);
        props.updateState({ dir: 1 - dir })
      }
    return (
      <Fragment>
   
          <div className="view-container-actions">
            <div className="left-actions">
              <div className="table-heading">
                <FormattedMessage
                  id="heading.finance.ledgerAccountsCategory.table.heading"
                  defaultMessage=""
                />
              </div>
              {props.pageSize ? (
                <>
                  <div className="vertical-sep" />
                  <div>{props.pageSize}</div>
                </>
              ) : (
                ""
              )}
            </div>
            <div className="right-actions">
              <Search
                placeholder="Search"
                value={props.searchedText}
                onChange={(e) => {
                  props.updateState({ searchedText: e.target.value });
                  onSearchedInputChange(e.target.value, props);
                }}
                onSearch={(searchedText) => {
                  props.updateState({ searchedText });
                  onSearchedInputChange(searchedText, props);
                }}
              />
            </div>
          </div>

          {/* <Row className="search-container" type='flex' justify='end'>
                        <Col span={12}>
                            <Search
                                placeholder="Search"
                                value={props.searchedText}
                                onChange={e => {
                                    props.updateState({ searchedText: e.target.value });
                                    onSearchedInputChange(e.target.value, props);
                                }}
                                onSearch={(searchedText) => {
                                    props.updateState({ searchedText });
                                    onSearchedInputChange(searchedText, props);
                                }}
                                style={{ width: 330, height: 36 }}
                            />
                        </Col>

                        <Col className='align-end' span={12}>
                            {
                                permittedActions.create && (
                                    <Dropdown overlay={menu}>
                                        <div className="action-dropdown-btn">
                                            <button onClick={
                                                () => {
                                                    props.showModal({
                                                        title: <Fragment>
                                                            <div className='ant-modal-title'>New Chart of Account</div>
                                                        </Fragment>,
                                                        modalBody: <NewLedgerComponent {...props} />,
                                                        width: 1100,
                                                        hideFooter: true
                                                    })
                                                }
                                            }>
                                                <i className="fa fa-user" />New Ledger
                                    <i className="fa fa-angle-down ml5" />
                                            </button>
                                        </div>
                                    </Dropdown>
                                )
                            }
                        </Col>
                    </Row> */}
          {/* <div className='agGridWrapper'>
                        <div className="ag-theme-balham" style={agGridStyle} >
                            <CustomAgGrid
                                columnDefs={columnDefs}
                                rowData={allCategories || []}
                                rowDragManaged={true}
                                onGridReady={onGridReady}
                                domLayout={"autoHeight"}
                                animateRows={true}
                                onGridSizeChanged={onFirstDataRendered}
                                getRowHeight={getRowHeight}
                            />
                        </div>
                    </div> */}
          <div className="table-container">
          <Skeleton loading={props.listLoading} active paragraph={{ rows: 16 }}>
            <table id="ledger-category">
              <thead>
                <tr>
                  <th >
                    <FormattedMessage id="serial.no" defaultMessage="" />
                    
                  </th>
                  <th onClick={sortColumn}>
                    <FormattedMessage
                      id="tooltip.category"
                      defaultMessage=""
                    />{dir ? <SortAscendingOutlined /> : <SortDescendingOutlined />}
                  </th >
                  <th onClick={sortColumn}>
                    <FormattedMessage id="tooltip.startAcNumber" defaultMessage="" />{dir ? <SortAscendingOutlined /> : <SortDescendingOutlined />}
                  </th>
                  <th onClick={sortColumn}>
                    <FormattedMessage
                      id="tooltip.endAcNumber"
                      defaultMessage=""
                    />{dir ? <SortAscendingOutlined /> : <SortDescendingOutlined />}
                  </th>
                  <th onClick={sortColumn}>
                    <FormattedMessage id="tooltip.categoryGrp" defaultMessage="" />{dir ? <SortAscendingOutlined /> : <SortDescendingOutlined />}
                  </th>
                  <th onClick={sortColumn}>
                    <FormattedMessage id="tooltip.systemAccount" defaultMessage="" />{dir ? <SortAscendingOutlined /> : <SortDescendingOutlined />}
                  </th>
                  <th>
                     <FormattedMessage
                       id="common.listing.header.action"
                       defaultMessage=" "
                     />
                   </th>
                  
                </tr>
              </thead>
              <tbody>
                {(allCategories || []).length ? allCategories.map((rowData,index)=>{
                    return(
                        <tr key={index}>
                            <td>{index+1}</td>
                            <td>{rowData.categoryName}</td>
                            <td>{rowData.minAccountNumber}</td>
                            <td>{rowData.maxAccountNumber}</td>
                            <td>{rowData.categoryGroupName}</td>
                            <td>{rowData.inBuilt ? 'Yes' : 'No'}</td>
                            <td style={{ paddingLeft: "50px" }}>
                           <div>
                             {rowData.inBuilt ? (
                               <span
                                 style={{ fontSize: "20px" }}
                                 title={props.intl.formatMessage({
                                   id: "cant.delete.systemGenerated",
                                 })}
                               >
                                 <LockOutlined />
                               </span>
                             ) : (
                               <span
                                 style={{ fontSize: "20px" }}
                                 title={props.intl.formatMessage({
                                   id: "modal.txDetails.common.delete",
                                 })}
                                 onClick={() => {
                                   props.showModal({
                                     modalBody: (
                                       <FormattedMessage
                                         id="common.delete.confirmation"
                                         defaultMessage=""
                                       />
                                     ),
                                     handleSubmit: () => {
                                       props.deleteLedgerAccount(
                                         rowData,
                                         props
                                       );
                                     },
                                   });
                                 }}
                               >
                                 <DeleteOutlined />
                               </span>
                             )}
                           </div>
                         </td>
                        </tr>
                    )
                }): 
                <tr key="empty-data-box">
                <td colSpan={"100%"} style={{ borderBottom: "none" }}>
                    <Empty />
                </td>
            </tr>
                }
              </tbody>
            </table>
        </Skeleton>
          </div>
        <div className="footer">
            <div className="f-left"></div>
            <div className="f-right">
            
            </div>
         </div>
      </Fragment>
    );
};

export default injectIntl(CategoryComp);