import axios from 'axios';
import config from '../../../../config/environmentConfig';
import { COMMON_ACTIONS, CUSTOMER_ACTION_LIST ,} from '../../../../static/constants';
import { showToasterMessage } from '../../../../utils';
const { lang } = require('../../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');

export const fetchAllContacts = (payload) => {
  return dispatch => {
    let url = `${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/boContact/getAllContacts?relationshipId=${payload.relationshipId}&pageNumber=${(payload.pageNumber || 1) - 1}&pageSize=${payload.pageSize || 10}`;
    if(payload.customerId){
      url = `${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/boContact/getAllContacts?relationshipId=${payload.relationshipId}&customerId=${payload.customerId}&pageNumber=${(payload.pageNumber || 1)-1}&pageSize=${payload.pageSize || 10}`;
    }
    dispatch({ type: COMMON_ACTIONS.SHOW_LIST_LOADER });
    return axios
      .get(url)
      .then(res => {
        dispatch({ type: CUSTOMER_ACTION_LIST.FETCH_CONTACTS, data: res.data });
      })
      .catch((err) => {
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        } else {
          err.actionToCall = CUSTOMER_ACTION_LIST.FETCH_CONTACTS;
        }
      }).finally(() => {
        dispatch({
          type: COMMON_ACTIONS.HIDE_LIST_LOADER,
        });
      })
  }
}

export const deleteContact = (payload, cb) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/boContact/deleteContact`, payload)
      .then(res => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        showToasterMessage({ messageType: 'success', description: 'Contact deleted successfully' });
        cb && cb();
        payload.customerId && axios
          .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/boContact/getAllContacts?relationshipId=${payload.relationshipId}&customerId=${payload.customerId}&pageSize=100&pageNumber=0`)
          .then(res => {
            dispatch({ type: CUSTOMER_ACTION_LIST.FETCH_CONTACTS, data: res.data });
            ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          })
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        } else {
          axios
            .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/boLocationRest/getAddressByCustomerIdAndLocationTpe?relationshipId=${payload.relationshipId}&locationType=${payload.locationType}&customerId=${payload.customerId}`)
            .then(res => {
              dispatch({ type: CUSTOMER_ACTION_LIST.FETCH_CONTACTS, data: res.data });
            });
        }
      })
  }
}

export const createUpdateContact = (payload) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/boContact/createContact`, payload)
      .then(res => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        showToasterMessage({ messageType: 'success', description: 'Contact saved successfully' });
        if(payload.callback){
          payload.callback(res.data);
        }
        axios
          .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/boContact/getAllContacts?relationshipId=${payload.relationshipId}&customerId=${payload.customerId}&pageSize=100&pageNumber=0`)
          .then(res => {
            dispatch({ type: CUSTOMER_ACTION_LIST.FETCH_CONTACTS, data: res.data });
            ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          })
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          //showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        } else {
          axios
            .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/boContact/getAllContacts?relationshipId=${payload.relationshipId}&customerId=${payload.customerId}&pageSize=100&pageNumber=0`)
            .then(res => {
              dispatch({ type: CUSTOMER_ACTION_LIST.FETCH_CONTACTS, data: res.data });
              ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
            })
        }
      })
  }
}

export const addContactRow = (key) => {
  return dispatch => {
    dispatch({ type: CUSTOMER_ACTION_LIST.ADD_CONTACT, data: key });
  }
}
export const fetchCustomerContacts = (payload) => {
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LIST_LOADER });
      return axios
          .get(
              `${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext
              }/boContact/getAllCustomerContact?relationshipId=${payload.relationshipId}&&pageSize=${payload.pageSize || 25
              }&pageNumber=${(payload.pageNumber || 1) - 1}`
          )
          .then((res) => {
              dispatch({
                  type: CUSTOMER_ACTION_LIST.FETCH_CUSTOMER_CONTACTS,
                  data: res.data,
              });
          })
          .catch((err) => {
              if (!err.__isRetryRequest) {
                  showToasterMessage({
                      messageType: "error",
                      description:
                          lang[((err.response || {}).data || {}).message] ||
                          "Some error occurred",
                  });
              }
          }).finally(() => {
            dispatch({
              type: COMMON_ACTIONS.HIDE_LIST_LOADER,
            });
          })
  };
};
export const fetchSupplierContacts = (payload) => {
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LIST_LOADER });
      return axios
          .get(
              `${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext
              }/boContact/getAllSupplierContact?relationshipId=${payload.relationshipId}&&pageSize=${payload.pageSize || 25
              }&pageNumber=${(payload.pageNumber || 1) - 1}`
          )
          .then((res) => {
              dispatch({
                  type: CUSTOMER_ACTION_LIST.FETCH_SUPPLIER_CONTACTS,
                  data: res.data,
              });
          })
          .catch((err) => {
              if (!err.__isRetryRequest) {
                  showToasterMessage({
                      messageType: "error",
                      description:
                          lang[((err.response || {}).data || {}).message] ||
                          "Some error occurred",
                  });
              }
          }).finally(() => {
            dispatch({
              type: COMMON_ACTIONS.HIDE_LIST_LOADER,
            });
          })
  };
};