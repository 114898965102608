import { INVENTORY_ACTION_LIST } from "../static/constants";
const initialState = {
  productCategories: [],
  productBrands: [],
  manufacturers: [],
  allUOM: [],
  allWarehouses: [],
  variantAttributes: [],
  skuData: {},
  allGstRates: [],
  allLedgerAccounts: [],
  salesLedgerAccounts: [],
  purchaseLedgerAccounts: [],
  cgstTaxes: [],
  sgstTaxes: [],
  igstTaxes: [],
  globleTaxes: [],
  stockTransfer: {
    1: [],
  },
  taxjarCategories: [],
  stockTransferData: {},
  stockTransferCount: 0,
  returnProductData: [],
  replaceProductData: [],
  cancelProductData: [],
  openingQuantity: {
    1: [],
  },
  openingQuantityCount:0,

};

const InventoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case INVENTORY_ACTION_LIST.CATEGORY_LIST:
      return { ...state, productCategories: action.data };
    case INVENTORY_ACTION_LIST.CATEGORY_LIST_ADDITION:
      return {
        ...state,
        productCategories: [action.data, ...state.productCategories],
      };
    case INVENTORY_ACTION_LIST.CATEGORY_LIST_DELETION:
      return {
        ...state,
        productCategories: state.productCategories.filter(
          (x) => x.inventoryItemCategoryId !== action.data.id
        ),
      };
    case INVENTORY_ACTION_LIST.BRAND_LIST:
      return { ...state, productBrands: action.data };
    case INVENTORY_ACTION_LIST.BRAND_LIST_ADDITION:
      return { ...state, productBrands: [action.data, ...state.productBrands] };
    case INVENTORY_ACTION_LIST.BRAND_LIST_DELETION:
      return {
        ...state,
        productBrands: state.productBrands.filter(
          (x) => x.itemBrandId !== action.data.id
        ),
      };
    case INVENTORY_ACTION_LIST.MANUFACTURE_LIST:
      return { ...state, manufacturers: action.data };
    case INVENTORY_ACTION_LIST.MANUFACTURE_LIST_ADDITION:
      return { ...state, manufacturers: [action.data, ...state.manufacturers] };
    case INVENTORY_ACTION_LIST.MANUFACTURE_LIST_DELETION:
      return {
        ...state,
        manufacturers: state.manufacturers.filter(
          (x) => x.inventoryProductManufacturerId !== action.data.id
        ),
      };
    case INVENTORY_ACTION_LIST.UOM_LIST:
      return { ...state, allUOM: action.data };
    case INVENTORY_ACTION_LIST.WAREHOUSE_LIST:
      return { ...state, allWarehouses: action.data };
    case INVENTORY_ACTION_LIST.WAREHOUSE_LIST_UPDATION:
      return {
        ...state,
        allWarehouses: state.allWarehouses.map(function (warehouse) {
          return warehouse.warehouseMasterId === action.data.warehouseMasterId
            ? action.data
            : warehouse;
        }),
      };
    case INVENTORY_ACTION_LIST.WAREHOUSE_LIST_ADDITION:
      return { ...state, allWarehouses: [action.data, ...state.allWarehouses] };
    case INVENTORY_ACTION_LIST.WAREHOUSE_LIST_DELETION:
      return {
        ...state,
        allWarehouses: state.allWarehouses.filter(
          (x) => x.warehouseMasterId !== action.data.id
        ),
      };
    case INVENTORY_ACTION_LIST.ITEM_VARIANT_ATTRIBUTE_LIST:
      return { ...state, variantAttributes: action.data };
    case INVENTORY_ACTION_LIST.ITEM_VARIANT_ATTRIBUTE_LIST_ADDITION:
      return {
        ...state,
        variantAttributes: [action.data, ...state.variantAttributes],
      };
    case INVENTORY_ACTION_LIST.ITEM_VARIANT_ATTRIBUTE_LIST_DELETION:
      return {
        ...state,
        variantAttributes: state.variantAttributes.filter(
          (x) => x.itemVariantAttributeId !== action.data.id
        ),
      };
    case INVENTORY_ACTION_LIST.SKU_DATA:
      return { ...state, skuData: action.data };
    case INVENTORY_ACTION_LIST.ALL_GST_RATES:
      return { ...state, allGstRates: action.data };
    case INVENTORY_ACTION_LIST.ALL_LEDGER_ACCOUNTS:
      return { ...state, allLedgerAccounts: action.data };
    case INVENTORY_ACTION_LIST.SALES_LEDGER_ACCOUNTS:
      return { ...state, salesLedgerAccounts: action.data };
    case INVENTORY_ACTION_LIST.PURCHASE_LEDGER_ACCOUNTS:
      return { ...state, purchaseLedgerAccounts: action.data };
    case INVENTORY_ACTION_LIST.CGST_TAXES:
      return { ...state, cgstTaxes: action.data };
    case INVENTORY_ACTION_LIST.SGST_TAXES:
      return { ...state, sgstTaxes: action.data };
    case INVENTORY_ACTION_LIST.IGST_TAXES:
      return { ...state, igstTaxes: action.data };
    case INVENTORY_ACTION_LIST.GLOBLE_TAXES:
      return { ...state, globleTaxes: action.data };
    case INVENTORY_ACTION_LIST.GLOBLE_TAXES_ADDITION:
      return { ...state, globleTaxes: [action.data, ...state.globleTaxes] };
    case INVENTORY_ACTION_LIST.GLOBLE_TAXES_DELETION:
      return {
        ...state,
        globleTaxes: state.globleTaxes.filter(
          (x) => x.taxSingleRateId !== action.data.id
        ),
      };
    case INVENTORY_ACTION_LIST.STOCK_TRANSFER:
      return {
        ...state,
        stockTransfer: {
          ...state.stockTransfer,
          [action.data.pageNo || 1]: action.data.list,
        },
      };

    case INVENTORY_ACTION_LIST.TAXJAR_CATEGORY_LIST:
      return { ...state, taxjarCategories: action.data };
    case INVENTORY_ACTION_LIST.STOCK_TRANSFER_DETAIL:
      return { ...state, stockTransferData: action.data };
    case INVENTORY_ACTION_LIST.STOCK_TRANSFER_COUNT:
      return { ...state, stockTransferCount: action.data };
    case INVENTORY_ACTION_LIST.FETCH_RETURN_PRODUCTS:
      return { ...state, returnProductData: action.data };
    case INVENTORY_ACTION_LIST.FETCH_REPLACE_PRODUCTS:
      return { ...state, replaceProductData: action.data };
    case INVENTORY_ACTION_LIST.FETCH_CANCEL_PRODUCTS:
      return { ...state, cancelProductData: action.data };
    case INVENTORY_ACTION_LIST.OPENING_QUANTITY:
      return { ...state,    openingQuantity: {
        ...state.openingQuantity,
        [action.data.pageNo || 1]: action.data.list,
      }};
      case INVENTORY_ACTION_LIST.OPENING_PRODUCT_QUANTITY_COUNT:
        return { ...state, openingQuantityCount: action.data };
        case INVENTORY_ACTION_LIST.OPENING_BALANCE_BY_ID:
          return { ...state, openingQuantityData: action.data };
    default:
      return state;
  }
};

export default InventoryReducer;
