import React, { Fragment, useState } from "react";
import { FormattedMessage } from "react-intl";
import PageBreadcrumb from "../../PageBreadcrumb";
import "./menus.scss";
import MenuIcon1 from "../../../assets/images/icon/paymentGateWayIcon.png";
import MenuIcon2 from "../../../assets/images/icon/marketPlaceIcon.png";

import FooterItems from "./FooterItems";
import HeaderItems from "./HeaderItems";
// import HomePageSection from "./HomePageSection";
// import Bannermanagement from "../../../containers/eStore/menus/bannerManagement";




const MenusComp = (props) => {


  const [tabKey, setTabKey] = useState("1");
  

  const breadCrumbList = [
    {
      name: <FormattedMessage id="sidebar.menuItem.dashboard" defaultMessage="" />,
    },
    {
      name: (
        <FormattedMessage id="sidebar.menuItem.eStores" defaultMessage="" />
      ),
    },
    {
       name:"Menu"
      
    },
  ];
  const inlineStyle = {
    width:"26.947px",
     height:"26.947px"
  };
  const [componentToDisplay, setcomponentToDisplay] = useState("HeaderItems");

  return (
    <Fragment>

      {/* <div className="view-top-bar">
        <div className="top-view-left">
          <div className="page-heading">
            
          </div>
          <div className="vertical-sep" />
          <PageBreadcrumb breadCrumbList={breadCrumbList} />
        </div>
        <div className="top-view-right"></div>
      </div> */}
      <div className="StoreSettings-container">

       <div className="page-title-bar">
        <div className="page-title">
          <FormattedMessage
              id="ecom.menu.text"
              defaultMessage=""
            />
        </div>
        <div className="sep" />
        <PageBreadcrumb breadCrumbList={breadCrumbList} />
      </div>
      
      <div className="profile-container">
        <div className="sidenav">
          <div className="settings-body" >
            <div className="settings-body-child">
              <div className="child-body">
             
              
                {/* <div className="lead-tile" onClick={() => openPreferences(props)}> */}
                <div className={`lead-tile ${componentToDisplay==='HeaderItems' ? 'lead-back' : ''} ${componentToDisplay==='' ? 'lead-back' : ''}`}
                onClick={()=>setcomponentToDisplay('HeaderItems')}>

                  <div className="tile-icon1">
                    <img src={MenuIcon1} style={inlineStyle} alt="" />
                  </div>
                  <div className="tile-title1 b-text">
                    <FormattedMessage
                      id="ecom.menuName.header"
                      defaultMessage=""
                    />
                  </div>

                  <div className="tile-description1">
                    You are powered with customized your header.
                  </div>
                </div>

                {/* <div className="lead-tile" onClick={() => openSetTimings(props)}> */}
                <div className={`lead-tile ${componentToDisplay==='FooterItems' ? 'lead-back' : ''} `}
                onClick={()=>setcomponentToDisplay('FooterItems')}>
                  <div className="tile-icon1">
                    <img src={MenuIcon2} style={inlineStyle} alt="" />
                  </div>
                  <div className="tile-title1 b-text">
                    <FormattedMessage
                      id="ecom.menuName.footer"
                      defaultMessage=""
                    />
                  </div>
                  <div className="tile-description1">
                    You are powered with customized your footer.
                  </div>
                </div>

               
                
              </div>
            </div>
          </div>
        </div>
        <div className="main-body" style={{padding:"20px"}}>
     
       
         {componentToDisplay === "HeaderItems" && <HeaderItems {...props}/>}
         {componentToDisplay === "FooterItems" &&  <FooterItems {...props}/>}
       
         
 
      
            
        </div>
      </div>

      </div>
      
    </Fragment>
  );
};

export default MenusComp;