import axios from "axios";
import config from "../../../config/environmentConfig";
import { SMS_ACTION_LIST, COMMON_ACTIONS } from "../../../static/constants";
import { showToasterMessage } from "../../../utils";
const { lang } = require("../../../translations/" + (sessionStorage.getItem("language") || "en") + ".js");

export const getBroadcastMessage = (payload) => {
    return (dispatch) => {
        return axios.get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/broadcastMessage/get?relationshipId=${payload.relationshipId}&pageSize=${payload.pageSize || 25}&pageNumber=${(payload.pageNumber || 1)-1}&createdByEmpId=${payload.relationshipEmployeeId}`)
            .then((res) => {
              const data = {
                pageNo: payload.pageNumber,
                list: res.data
              }
                dispatch({
                    type: SMS_ACTION_LIST.BROADCAST_MESSAGE_LIST,
                    data: data,
                });
            })
            .catch((err) => {
              showToasterMessage({
                  messageType: "error",
                  description:
                      lang[((err.response || {}).data || {}).message] ||
                      "Some error occurred",
              });
            })
    };
};

export const getActiveBroadcastMessage = (payload) => {
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LIST_LOADER });

      return axios.get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/broadcastMessage/getActiveBroadcastMsg?relationshipId=${payload.relationshipId}&pageSize=${payload.pageSize || 25}&pageNumber=${(payload.pageNumber || 1)-1}`)
          .then((res) => {
            const data = {
              pageNo: payload.pageNumber,
              list: res.data
            }
              dispatch({
                  type: SMS_ACTION_LIST.BROADCAST_MESSAGE_LIST,
                  data: data,
              });
          })
          .catch((err) => {
            showToasterMessage({
                messageType: "error",
                description:
                    lang[((err.response || {}).data || {}).message] ||
                    "Some error occurred",
            });
          }).finally(() => {
            dispatch({
              type: COMMON_ACTIONS.HIDE_LIST_LOADER,
            });
          })
  };
};



export const getBroadcastMessageCount = (payload) => {
  return (dispatch) => {
    return axios
      .get(
        `${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/broadcastMessage/count?relationshipId=${payload.relationshipId}&createdByEmpId=${payload.relationshipEmployeeId}`
      )
      .then((res) => {
        dispatch({
          type: SMS_ACTION_LIST.BROADCAST_MESSAGE_COUNT,
          data: res.data,
        });
      })
      .catch((err) => {
        showToasterMessage({
          messageType: "error",
          description:
            lang[((err.response || {}).data || {}).message] ||
            "Some error occurred",
        });
      });
  };
};

export const getActiveBroadcastMessageCount = (payload) => {
  return (dispatch) => {
    return axios
      .get(
        `${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/broadcastMessage/countActiveBroadCastMsg?relationshipId=${payload.relationshipId}`
      )
      .then((res) => {
        dispatch({
          type: SMS_ACTION_LIST.BROADCAST_MESSAGE_COUNT,
          data: res.data,
        });
      })
      .catch((err) => {
        showToasterMessage({
          messageType: "error",
          description:
            lang[((err.response || {}).data || {}).message] ||
            "Some error occurred",
        });
      });
  };
};

export const deleteBroadcastMessage = (payload, props) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/broadcastMessage/delete`, payload)
      .then(res => {
        showToasterMessage({
          description: (res || {}).message || 'Deleted successfully.',
          messageType: 'success'
        })
        getBroadcastMessage({...props, relationshipEmployeeId: (props.userInfo || {}).relationshipEmployeeId,formId: props.defaultSchoolForm}, )(dispatch);
        getBroadcastMessageCount({...props, relationshipEmployeeId: (props.userInfo || {}).relationshipEmployeeId,formId: props.defaultSchoolForm}, )(dispatch);
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        } else {
          // err.actionToCall = CUSTOMER_ACTION_LIST.DELETE_ACTIVITY;
        }
      })
  }
}

export const resetPaginatedData = () => {
  return (dispatch) => {
    dispatch({ type: SMS_ACTION_LIST.BROADCAST_MESSAGE_LIST_PAGE_RESET });
  };
};
