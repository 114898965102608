import { Button, Col, Input, Row, Select, Switch, Tooltip } from "antd";
import React from "react";
import { FormattedMessage } from "react-intl";
import { Form } from "@ant-design/compatible";
import "../../../../eStore/storeSettings/store-settings.scss";
import { InfoCircleOutlined, RightOutlined } from "@ant-design/icons";
import cardlogo from "../../../../../assets/images/card-logo.jpg";
import { Dropdown } from "../../../../general/Dropdown";
import find from "lodash.find";
import { getPermissionData } from "../../../../../utils";
import { MODULE_CODED_VALUES } from "../../../../../static/constants";
import TaxPreferenceForm from "./taxPreferences";
const { Option } = Select;
const PreferencesComp = (props) => {
  const { permissions } = props;
  const primaryPerm = (permissions && permissions.primary) || [];
  const permissionsData = getPermissionData(primaryPerm, MODULE_CODED_VALUES.E_STORE_SETTING);
  const SenderEmail = () => { };

  console.log(props.paymentGatewayName, "Payemnt Gateway")

  return (
    <div className="store-model-container cus-space">
      <div className="store-main-container">
        <div className="model-head">
          <p className="heading">Auto-accept Orders</p>
        </div>
        <div className="model-container">
          <Form layout="vertical" hideRequiredMark className="pr30">
            <Form.Item>
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item>
                    <Row className="f-item-center">
                      <Col span={12} className="text-right">
                        <FormattedMessage
                          id="modal.eStore.storeSettings.CodOrders"
                          defaultMessage=""
                        />
                        <Tooltip placement="top" arrow={false} title={"Enables offline payments like cash, UPI or other custom methods"}>
                          <InfoCircleOutlined />
                        </Tooltip>
                      </Col>
                      <Col span={12}>
                        <Switch
                          checked={
                            props.storeData?.isCod
                          }
                          disabled={!(permissionsData.update)}

                          onClick={(checked, e) => {
                            const val = props.storeData;
                            let tempFilter = val;
                            tempFilter.isCod = tempFilter?.isCod === 1 ? 0 : 1;
                            props.updateState({ val: tempFilter })

                            props.setCODorder({
                              relationshipId: props.relationshipId,
                              isCod: checked ? 1 : 0,
                            });
                          }}
                        />
                      </Col>
                      {props.storeData?.isCod ?<Col style={{display:"flex",gap:"10px"}}>
                        <Input
                  style={{ marginLeft: 10 }}
                  placeholder="Message for Cod Orders"
                  value={props.codPrefrence?.message}
                  onChange={(e)=>{
                  props.updateState({
                    codPrefrence:{...props.codPrefrence, message:e.target.value}
                  })
                  }}
                />

                 <Input
                  style={{ marginLeft: 10 }}
                  placeholder="Amount"
                  type="number"
                  value={props.codPrefrence?.amount}
                  onChange={(e)=>{
                  props.updateState({
                    codPrefrence:{...props.codPrefrence, amount:e.target.value}
                  })
                  }}
                />

            <Button type="primary" onClick={()=>{
                  props.setCODprefrence({
                    relationshipId: props.relationshipId,
                    codPrefrence: JSON.stringify(props.codPrefrence||{}),
                  });
            }}>
            Save COD Preference
          </Button>
                      </Col>:""}
                    </Row>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item>
                    <Row className="f-item-center">
                      <Col span={12} className="text-right">
                        <FormattedMessage
                          id="modal.eStore.storeSettings.makeAnEnquiry"
                          defaultMessage=""
                        />
                        <Tooltip placement="top" arrow={false} title={"Enables enquiry on Product or Cart"}>
                          <InfoCircleOutlined />
                        </Tooltip>
                      </Col>
                      <Col span={12}>
                        <Switch
                          disabled={!(permissionsData.update)}

                          checked={
                            props.storeData?.isEnquiry
                          }
                          onClick={(checked, e) => {
                            const val = props.storeData;
                            let tempFilter = val;
                            tempFilter.isEnquiry = tempFilter?.isEnquiry === 1 ? 0 : 1;
                            props.updateState({ val: tempFilter })
                            props.setMakeAnEnquiry({
                              relationshipId: props.relationshipId,
                              isCod: checked ? 1 : 0,
                            });
                          }}
                        />
                      </Col>
                    </Row>
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item>
                    <Row className="f-item-center">
                      <Col span={12} className="text-right">
                        <FormattedMessage
                          id="modal.eStore.storeSettings.onlineOrders"
                          defaultMessage=""
                        />
                        <Tooltip placement="top" arrow={false} title={"Enables online payments from the selected Payment Gateway"}>
                          <InfoCircleOutlined />
                        </Tooltip>
                      </Col>
                      <Col span={12}>
                        <Switch
                          disabled={!(permissionsData.update)}

                          checked={props.storeData?.isOnlineOrders}
                          onClick={(checked, e) => {
                            const val = props.storeData;
                            let tempFilter = val;
                            tempFilter.isOnlineOrders = tempFilter?.isOnlineOrders === 1 ? 0 : 1;
                            props.updateState({ val: tempFilter })
                            props.setOnlineOrder({
                              relationshipId: props.relationshipId,
                              isCod: checked ? 1 : 0,
                            });
                          }}
                        />
                      </Col>
                    </Row>
                  </Form.Item>
                </Col>
              </Row>
              {/* <Row gutter={16}>
                <Col span={24}>
                  <Form.Item>
                    <Row className="f-item-center">
                      <Col span={12} className="text-right">
                        <FormattedMessage
                          id="modal.eStore.storeSettings.CancelOrder"
                          defaultMessage=""
                        />
                        <Tooltip placement="top" arrow={false} title={"Enables cancellation request on successful orders"}>
                          <InfoCircleOutlined />
                        </Tooltip>
                      </Col>
                      <Col span={12}>
                        <Switch
                          disabled={!(permissionsData.update)}

                          checked={props.isallowCancelOrder}
                          onClick={(checked, e) => {

                            const val = props.additionalValue;
                            const additionalValues = {
                              ...val,
                              isallowCancelOrder: checked
                            };

                            props.updateState({ isallowCancelOrder: checked })


                            props.updateAdditionalValue({
                              additionalValues: JSON.stringify(additionalValues || {}),
                              relationshipId: props.userInfo.relationshipId
                            }, props)


                          }}
                        />
                      </Col>
                    </Row>
                  </Form.Item>
                </Col>
              </Row> */}


              {props.storeData?.isOnlineOrders ? (
                <Row gutter={16}>
                  <Col span={10}>
                    <div className="remarks-title">
                      <FormattedMessage
                        id="select.gateway"
                        defaultMessage=""
                      />
                      <img
                        src={cardlogo}
                        alt=""
                        style={{ marginLeft: "10px" }}
                      />
                    </div>
                    <Dropdown
                      items={props.paymentGatewayName || []}
                      valueKeyName='paymentGatewayName'
                      addressLineKey1='EmailAddress'
                      optionKeyName='alpidePaymentGatewayId'
                      value={(props.selectedPaymentGateway || {})?.paymentGatewayName}
                      placeholder={'Paymnt'}
                      onSelect={(id) => {
                        const selectedPayment = find(props.paymentGatewayName || [], { alpidePaymentGatewayId: id });
                        props.updateState({
                          selectedPaymentGateway: selectedPayment,
                        })
                        const val = props.additionalValue;
                        const additionalValues = {
                          ...val,
                          paymentGateway: selectedPayment
                        };


                        //  props.updateState({

                        //    paymentGatewayId: obj.key,
                        //    paymentGatewayName: obj.value,
                        //  });         
                        if (permissionsData?.update) {
                          props.updateAdditionalValue({
                            additionalValues: JSON.stringify(additionalValues || {}),
                            relationshipId: props.userInfo.relationshipId
                          }, props)
                        }

                      }
                      }
                    />



                  </Col>
                  <Col span={2}>
                    <Tooltip
                      className="mt20"
                      placement="top"
                      title={"To add payment gateway, visit Integration -> Payment Page"}
                      trigger="click"
                    >
                      <i className="fa fa-question-circle-o pl5 cursor-pointer" />
                    </Tooltip>
                  </Col>
                </Row>
              ) : (
                ""
              )}



              {/* <Row gutter={16}>
            <Col span={24}>
              <Form.Item>
                <Row className="f-item-center">
                  <Col span={12} className="text-right">
                    <FormattedMessage
                      id="modal.eStore.storeSettings.wireTransfers"
                      defaultMessage=""
                    />
                    <Tooltip placement="top" arrow={false} title={"tool tip"}>
                      <InfoCircleOutlined />
                    </Tooltip>
                  </Col>
                  <Col span={12}>
                    <Switch
                      checked={props.storeSettings?.isWireTransferCheck === 1}
                      onClick={(checked, e) => {
                        props.updateState({
                          storeSettings: {
                            ...props.storeSettings,
                            isWireTransferCheck: checked ? 1 : 0,
                          },
                        });
                      }}
                    />
                  </Col>
                </Row>
              </Form.Item>
            </Col>
          </Row> */}
            </Form.Item>
          </Form>
        </div>

      
      {props.loading ?<TaxPreferenceForm {...props} />:""}
      </div>

      
    


    
    </div>
  );
};

export default PreferencesComp;
