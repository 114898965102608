import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ShippingRuleComponent from '../../../../components/ShowSettings/shipping/shippingRule';
import { showModal, hideModal, pushModalToStack, popModalFromStack } from '../../../../actions/commonActions';
import { getAllShippingChoices, getAllShippingServices, getCountryShippingRule, saveUpdateShippingRule } from "./action"
import { fetchDataIfNeeded } from "../../../../utils";

class ShippingRule extends Component {

    constructor(props) {
        super(props);
        const linkProps = (this.props.location && this.props.location.state) || {};
        this.state = {
            marketplaceShippingCountryId: linkProps.marketplaceShippingCountryId,
        }
    }

    componentWillReceiveProps(props) {
        if (props.countryShippingRule && props.countryShippingRule[this.state.marketplaceShippingCountryId] && props.shippingServices && props.shippingServices.length > 0 && (!props.countryShippingRule[this.state.marketplaceShippingCountryId].carrierService || props.countryShippingRule[this.state.marketplaceShippingCountryId].carrierService.length === 0)) {
            props.countryShippingRule[this.state.marketplaceShippingCountryId].carrierService = [];
            for (let i = 0; i < props.shippingServices.length; i++) {
                let service = props.shippingServices[i];
                service.displayServiceName = JSON.parse(JSON.stringify(service.serviceName));
                service.displayServiceDescription = JSON.parse(JSON.stringify(service.serviceDescription));
                props.countryShippingRule[this.state.marketplaceShippingCountryId].carrierService.push(service);
            }
        }
    }

    componentDidMount() {
        this.props.updateHeaderState({
            collapsed: true
        });
        const companyInfo = this.props.companyInfo || {};
        const payload = {
            companyInfo,
            relationshipId: companyInfo.relationshipId,
            marketplaceShippingCountryId: this.state.marketplaceShippingCountryId,
        };
        fetchDataIfNeeded("getAllShippingServices", "shippingServices", this.props, payload);
        fetchDataIfNeeded("getAllShippingChoices", "shippingChoices", this.props, payload);
        fetchDataIfNeeded("getCountryShippingRule", "countryShippingRule", this.props, payload);
    }

    render() {
        return <ShippingRuleComponent {...this.props}  {...this.state} updateState={(data) => {
            this.setState(data);
        }} />
    }
}

const mapStateToProps = (state) => {
    return {
        companyInfo: state.common.companyInfo,
        relationshipId: state.common.companyInfo.relationshipId,
        shippingChoices: state.shippingReducer.shippingChoices,
        shippingServices: state.shippingReducer.shippingServices,
        countryShippingRule: state.shippingReducer.countryShippingRule,
        permissions: state.auth.permissions
    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    getAllShippingChoices,
    getAllShippingServices,
    getCountryShippingRule,
    saveUpdateShippingRule,
    showModal,
    hideModal,
    pushModalToStack,
    popModalFromStack
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ShippingRule);
