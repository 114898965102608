import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Skeleton, Popover, Menu, Pagination,Input } from 'antd';
import { InsertRowRightOutlined,MoreOutlined,UserOutlined } from '@ant-design/icons';
import PageBreadcrumb from "../../../PageBreadcrumb";
import DropdownAction from "antd/lib/dropdown";
import { getMomentDateForUIReadOnly, sortTable } from '../../../../utils';
import find from 'lodash.find';
import { CONSTANTS, ICONS, MODULE_CODED_VALUES } from '../../../../static/constants';
import {  DownloadOutlined } from "@ant-design/icons";
import PageHeader from '../../../Common/pageHeader';
const { Search } = Input;
const WarningComp = (props) => {

    const breadCrumbList = [
        {
            name: "Dashboard",
        },
        {
            name: <FormattedMessage id='sidebar.menuItems.employeeManagement'/>,
        },
        {
            name: <FormattedMessage id='sidebar.menuItems.warning'/>,
        }
    ];

  

    const actionMenu = (e) => {
      return (
        <Menu className="row-action-dropdown">
          <Menu.Item key="0" value="title">
            Actions
          </Menu.Item>

          <Menu.Divider />

          <Menu.Item
            key="1"
            value="edit"
            onClick={() => {
            
            }}
          >
            <i className={ICONS.EDIT} />
            Edit
          </Menu.Item>

          {e.isAccountHolder ? (
            <Menu.Item
              key="2"
             
            >
              <i className={ICONS.DELETE} />
              Delete
            </Menu.Item>
          ) : (
            ""
          )}

        
        </Menu>
      );
    };

    const createWarning =()=>{

      props.updateState({ warningDrawer: true });
    }
    return (
      <>
       <PageHeader {...props}
        pageName="heading.team.employeeMangement.warning"
        breadCrumbList={breadCrumbList}
        canCreate={true}
        onCreate={createWarning}
        moduleCode = {MODULE_CODED_VALUES.HRMS_EMPLOYEE}
      
        />
        

        <div className="view-container">
         
            <div className="view-container-actions">
            <Skeleton loading={props.headerLoading} paragraph={false} rows={1} active>

              <div className="left-actions">
                <div className="table-heading">
                  <FormattedMessage
                    id="company.employeeMangement.warning.table.heading"
                    defaultMessage=""
                  />
                </div>
                {[]?.length ? (
                  <>
                    <div className="vertical-sep" />
                    <div>{[]?.length}</div>
                  </>
                ) : (
                  ""
                )}
              </div>
              <div className="right-actions">
             
              <Search
                placeholder="Search Warning"
                value={props.searchedText}
                onChange={(e) => {
                  // updateState({ searchedText: e.target.value, pageNumber: 1 });
                  // onSearchedInputChange(e.target.value, props);
                }}
                onSearch={(searchedText) => {
                  // updateState({ searchedText, pageNumber: 1 });
                  // onSearchedInputChange(searchedText, props);
                  // handleSearchedText(searchedText, props);
                }}
              />
             <DropdownAction 
            //  overlay={downloadMenu()}
              trigger={["click"]}>
                <span>
                  <DownloadOutlined /> &nbsp;{" "}
                  <FormattedMessage
                    id="common.action.dropdown.downloadAs"
                    defaultMessage=""
                  />
                </span>
              </DropdownAction>
             
              </div>
              </Skeleton>
            </div>

          
            <div className="table-container">
            <Skeleton loading={props.listLoading} paragraph={{ rows: 17 }} active>

              <table id="employee-table">
                <thead>
                  <tr>
                    <th>
                      <FormattedMessage id="serial.no" defaultMessage=" " />
                    </th>

                    <th>
                      <FormattedMessage
                        id="company.employeeMangement.employeeName"
                        defaultMessage=" "
                      />
                    </th>

                   
                      <th>
                        <FormattedMessage
                          id="company.employeeMangement.warningDate"
                          defaultMessage=" "
                        />
                      </th>
                  
                
                      <th>
                        <FormattedMessage
                          id="company.employeeMangement.subject"
                          defaultMessage=" "
                        />
                      </th>
                  
                    <th>
                      <FormattedMessage
                        id="company.employeeMangement.warningType"
                        defaultMessage=" "
                      />
                    </th>
                    <th>
                      <FormattedMessage
                        id="company.employeeMangement.warningBy"
                        defaultMessage=" "
                      />
                    </th>
               
                      
                   
                    
                      <th className="text-center">
                        <FormattedMessage
                          id="company.employeeMangement.action"
                          defaultMessage=" "
                        />
                      </th>
                  
                  
                      
                  </tr>
                </thead>
                <tbody>
                  {([]).map((rowData, index) => {
                      return (
                        <tr key={index}>
                          <td>{index + 1}</td>

                          <td>
                            {rowData.employeeCode ? rowData.employeeCode : "-"}
                          </td>

                            <td>
                              {rowData.firstName +
                                (rowData.lastName
                                  ? " " + rowData.lastName
                                  : "")}
                            </td>
                          
                            <td>{rowData.empType}</td>
                         
                          <td>{rowData.hierarchyName}</td>
                          <td>{rowData.hierarchyCode}</td>
                            <td>{rowData.jobTitleName}</td>
                        
                            <td>{rowData.jobRoleName}</td>
                          
                        
                      

                          <td>
                            {rowData.dateCreated
                              ? getMomentDateForUIReadOnly({
                                  date: rowData.dateCreated,
                                  format: CONSTANTS.TABLE_DATE_FORMAT,
                                })
                              : "-"}
                          </td>

                          <td>
                            <DropdownAction
                              overlay={actionMenu(rowData)}
                              trigger={["click"]}
                            >
                              <i className={ICONS.MORE} />
                            </DropdownAction>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
              </Skeleton>
            </div>

            {/* <div className="footer">
                        <Skeleton loading={props.headerLoading} paragraph={false} rows={1} active>

              <div className="f-left"></div>
              <div className="f-right">
                <Pagination
                  size="small"
                  total={30}
                  showTotal={showTotal}
                  defaultPageSize={props.pageSize}
                  showSizeChanger
                  pageSizeOptions={[10, 25, 50, 100, 200]}
                  // showQuickJumper
                  onChange={(pageNumber, pageSize) => {
                    loadPageData(pageNumber, pageSize);
                    updateState({ pageSize: pageSize, pageNumber: pageNumber });
                  }}
                  onShowSizeChange={(pageNumber, pageSize) => {
                    handlePageSizeChange(pageNumber || 1, pageSize, props);
                  }}
                />
              </div>
          </Skeleton>
            </div> */}
        </div>
      </>
    );
};




export default injectIntl(WarningComp);
