import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { Col, Drawer, Row, Skeleton, Card, Upload, Button, message, Empty } from "antd";
import { CustomButton } from "../../../general/CustomButton";
import { TextBox } from "../../../general/TextBox";
import { getAWSFileURL } from "../../../../utils";
import { ICONS } from "../../../../static/constants";

const { Meta } = Card;

const FeedbackDrawer = (props) => {
const {feedbackData} = props;
  const closeDrawer = () => {
    props.updateState({
      feedbackDrawer: false,
    });
  };
  // const imageVal = props?.feedbackData.ecomFeedbackImages?.map((val) => {
  //   return getAWSFileURL(val.image, props.companyInfo.bucketName, val.folderName)
  // })
  const handleSubmit = () => {

    const payload = {
      ...feedbackData,
      relationshipId: props.companyInfo.relationshipId,
      itemName: feedbackData.itemName,
      rating: feedbackData.rating,
      message: feedbackData.message,
      customerName: feedbackData.customerName,
      updatedByUserId: feedbackData.updatedByUserId,
      feedbackId: feedbackData.feedbackId,
    }

    const modalData = {
      modalBody: <FormattedMessage id="commons.create.popup.confimation.msg" defaultMessage="" />,
      handleSubmit: () => {
        props.updateFeedback(payload, imageVal, props);
        closeDrawer();
      },
    };
    props.pushModalToStack(modalData);
  }

  const getCreateConfirmationModalBody = (payload) => {
    return (
      <>
        <p>
          <FormattedMessage
            id="drawer.inventory.confirmation.text"
            defaultMessage=""
          />
        </p>
      </>
    );
  };

  const handleDeleteImage = (imageId) => {
    const payload={
      relationshipId:props.companyInfo.relationshipId,
      ecomFeedBackImageId:imageId,
      feedbackId:props.feedbackId
    }
    props.deleteFeedbackImgaes(payload,props)

  };

  const imageVal = props?.feedbackData.ecomFeedbackImages?.map((val) => {
    return {
      url: getAWSFileURL(val.image, props.companyInfo.bucketName, val.folderName),
      id: val.ecomFeedBackImageId,
    };
  });
  return (
    <Drawer
      title="Create New"
      width={720}
      zIndex={1}
      maskClosable={false}
      destroyOnClose={true}
      onClose={closeDrawer}
      className="custom-drawer"
      visible={props.feedbackDrawer}
      footer={
        <>
          <CustomButton
            intlId="confirmation.cancel"
            defaultMessage=""
            type="default"
            key={0}
            style={{ marginRight: 8 }}
            onClick={closeDrawer}
          />

          <CustomButton
            intlId="confirmation.submit"
            defaultMessage=""
            htmlType="submit"
            onClick={() => {
              handleSubmit()
            }
            }
            key={1}
          />
        </>
      }

    >

      <Row gutter={[16, 16]}>
        <Col span={12}>
          <div className="i-label">Product Name
            <span className="required">*</span>

          </div>
          <TextBox
            disabled
            value={feedbackData.itemName}
          />

        </Col>
        <Col span={12}>
          <div className="i-label">Rating
            <span className="required">*</span>

          </div>
          <TextBox
            placeholder="Enter rating"
            type="text"
            maxLength={30}
            value={feedbackData.rating}
            onChange={(e) => {
              props.updateDrawerState({ feedbackData:{
                ...feedbackData,
                rating:e.target.value
              } });
            }}
          />
        </Col>

        <Col span={12}>
          <div className="i-label">Review
            <span className="required">*</span>

          </div>
          <TextBox
            placeholder="Enter review"
            type="text"
            maxLength={30}
            value={feedbackData.message}
           
            onChange={(e) => {
              props.updateDrawerState({ feedbackData:{
                ...feedbackData,
                message:e.target.value
              } });
            }}
          />
        </Col>
        <Col span={12}>
          <div className="i-label">Reviewed By
            <span className="required">*</span>

          </div>
          <TextBox
            disabled
            type="text"
            value={feedbackData.customerName}

          />
        </Col>

        <Col span={24}>

          <div className="i-label">Feedback Images</div>

          <div className="image_con">
            {imageVal && imageVal.length > 0 ? (
              imageVal.map((image, index) => (
                <div className="image_wrapper" key={index}>
                  <img src={image.url} width={200} height={200} className="image_val" />
                  <div className="middle_icon">

                    <i className={`${ICONS.DELETE}`} onClick={() => handleDeleteImage(image.id)} />

                  </div>
                </div>
              ))
            ) : <Empty/>}
          </div>
        </Col>
      </Row>
    </Drawer>
  );
};

export default FeedbackDrawer;
