import React from "react";
import InPageMenu from "../../Common/InPageMenu";
import PageBreadcrumb from "../../PageBreadcrumb";
import { FormattedMessage } from "react-intl";
import { ICONS, MODULE_CODED_VALUES } from '../../../static/constants';
import './_meta-style.scss'
import Department from '../../../assets/images/department.svg';
import Designation from '../../../assets/images/designation.svg'
import Hierarchy from '../../../assets/images/hierarchy.svg'
import Location from '../../../assets/images/location.svg'
import JobRole from '../../../assets/images/recognition.svg'
import Team from '../../../assets/images/team.svg'
import Employee from '../../../assets/images/employee.svg'
// import { Route, Switch, useRouteMatch } from 'react-router-dom';
import DepartmentContainer from '../../../containers/company/EmployeeMeta/Department';
import DesignationContainer from '../../../containers/company/EmployeeMeta/Designation';
import BranchContainer from '../../../containers/company/EmployeeMeta/Branch';
import JobRoleContainer from '../../../containers/company/EmployeeMeta/JobRole'
import EmployeeContainer from '../../../containers/company/EmployeeMeta/EmployeeType'
import HierarchyContainer from '../../../containers/company/EmployeeMeta/OrgHierarchy'
import TeamContainer from '../../../containers/company/EmployeeMeta/Team';
import UnderDev from "../../Common/NotFound";
import PageHeader from "../../Common/pageHeader";
import { getPermissionData } from "../../../utils";
import { ModuleNames } from "ag-grid-community";
// import CompanyHierarchy from "../../../containers/compamy";


function EmployeeMeta(props) {
    // const { path } = useRouteMatch();
    const {permissions} = props;
    const primaryPerm = (permissions && permissions.primary) || [];
    // const permissionsData = useMemo(() => {
    //     return getPermissionData(primaryPerm, MODULE_CODED_VALUES.COMPANY_HIERARCY);
    //   }, []);
   console.log(props ,"CHECKKTHE")
    const handleActiveTab = (index) => {
        props.updateState({
            selectedMenuItem: index
        })
    };
    
    const activeTab = () => {
        switch (props.selectedMenuItem) {
            case "Department":
                return <DepartmentContainer />;
            case "Designation":
                return <DesignationContainer />;
            case "Branch":
                return <BranchContainer />;
            case "Job Role":
                return <JobRoleContainer />;
            case "Employee Type":
                return <EmployeeContainer />;
            case "Hierarchy":
                return <HierarchyContainer />;
            case "Team":
                return <TeamContainer />;
            default:
                return null;
        }
    };
    const breadCrumbList = [
        {
            name: <FormattedMessage id='employeeMeta.pageName.dashboard' defaultMessage='' />,
        },
        {
            name: <FormattedMessage id='employeeMeta.pageName.employee' defaultMessage='' />,
        },
        {
            name: <FormattedMessage id='employeeMeta.pageName.meta' defaultMessage='' />,
        }
    ];
    const menuList = [{
        path: 'a',
        icon: Department,
        name: "employeeMeta.menulabel.department",
        description: "Define and organize various departments for effective workforce management",
        key:"Department",
        moduleCode:MODULE_CODED_VALUES.HRMS_EMPLOYEE,

    },
    {
        icon: Designation,
        name: "employeeMeta.menulabel.designation",
        description: "Set specific job titles for clarity in roles and responsibilities",
        key:"Designation",
        moduleCode:MODULE_CODED_VALUES.HRMS_EMPLOYEE,


    },
    {
        icon: Location,
        name: "employeeMeta.menulabel.branch",
        description: "Manage multiple branches to accommodate diverse operational locations",
        key:"Branch",
        moduleCode:MODULE_CODED_VALUES.HRMS_EMPLOYEE,

    },
    {
        icon: JobRole,
        name: "employeeMeta.menulabel.jobRole",
        description: "Define distinct job roles to clarify responsibilities and expectations",
        key:"Job Role",
        moduleCode:MODULE_CODED_VALUES.HRMS_EMPLOYEE,

    },
    {
        icon: Employee,
        name: "employeeMeta.menulabel.employeeType",
        description: "Classify employees based on their type, such as full-time or part-time",
        key:"Employee Type",
        moduleCode:MODULE_CODED_VALUES.HRMS_EMPLOYEE,

    },
    {
        icon: Hierarchy,
        name: "employeeMeta.menulabel.hierarchy",
        description: "Visualize and manage the organizational structure for better clarity and reporting",
        key:"Hierarchy",
        moduleCode:MODULE_CODED_VALUES.HRMS_EMPLOYEE,

    },
    {
        icon: Team,
        name: "employeeMeta.menulabel.team",
        description: "Our team thrives on collaboration, innovation, and mutual support to achieve excellence together",
        key:"Team",
        moduleCode:MODULE_CODED_VALUES.HRMS_EMPLOYEE,

    }

    ]
    
    
    return (
        <>
        <PageHeader {...props}
        pageName="heading.team.employeeMeta"
        breadCrumbList={breadCrumbList}

        />
           

            <div className="meta-container">
                <div className="meta-sidenav">
                    <InPageMenu menuList={menuList} onItemClick={handleActiveTab} {...props}/>
                </div>
                <div className="meta-main-body">
                    {activeTab()}
                    {/* <Switch>
                        {menuList.map((menuItem, index) => {
                            return (
                                <Route key={index} path={`${path}/a`} component={menuItem.component || 'none'} />
                            );
                        })}
                    </Switch> */}
                </div>
            </div>
        </>
    )
}
export default EmployeeMeta;