import React, { Fragment, useMemo } from "react";
// import { AgGridReact } from "ag-grid-react";
import { Tooltip, Skeleton, Menu, Empty } from "antd";
import { FormattedMessage, injectIntl } from "react-intl";
import { getMomentDateForUIReadOnly, getPermissionData, sortTable } from "../../../utils";
import { CONSTANTS, ICONS, MODULE_CODED_VALUES } from '../../../static/constants';
import PageBreadcrumb from "../../PageBreadcrumb";
import DropdownAction from "antd/lib/dropdown";
import PageHeader from "../../Common/pageHeader";


const DistributionGroup = (props) => {

  const { dgList, dgCount, dir=0, updateState, permissions } = props;
  const primaryPerm = (permissions && permissions.primary) || [];

  const permissionsData = useMemo(()=>{
    return getPermissionData(primaryPerm, MODULE_CODED_VALUES.COMMUNICATION )
  },[])
  const deleteHandle = (dg) => {
    const modalBody = <FormattedMessage id='commons.delete.popup.confimation.msg' defaultMessage='' />;
    const modalData = {
      modalBody,
      handleSubmit: () => {
        props.deleteDG(dg, props);
      },
    };
    props.showModal(modalData);
  };

  // const columnDefs = [
  //   {
  //     headerComponentFramework: () => {
  //       return (
  //         <FormattedMessage
  //           id="communication.dgGroup.listing.header.groupName"
  //           defaultMessage=""
  //         />
  //       );
  //     },
  //     field: "dgName",
  //     resizable: true,
  //     minWidth: 70,
  //     maxWidth: 300,
  //   },
  //   {
  //     headerComponentFramework: () => {
  //       return (
  //         <FormattedMessage
  //           id="communication.dgGroup.listing.header.recipients"
  //           defaultMessage=""
  //         />
  //       );
  //     },
  //     field: "totalContacts",
  //     minWidth: 70,
  //     maxWidth: 250,
  //     suppressMovable: true,
  //   },
  //   {
  //     headerComponentFramework: () => {
  //       return <FormattedMessage id='common.dateCreated' defaultMessage='' />;
  //     },
  //     cellRendererFramework: (link) => {
  //       return <div>
  //         <Tooltip placement="topLeft" title={(link.value && getMomentDateForUIReadOnly({ date: link.value, format: CONSTANTS.DISPLAY_TOOLTIP_DATE_FORMAT }))}>
  //           {(link.value && getMomentDateForUIReadOnly({ date: link.value, format: CONSTANTS.DISPLAY_DATE_FORMAT }))}
  //         </Tooltip>
  //       </div>
  //     },
  //     field: "dateCreated",
  //     minWidth: 270,
  //     maxWidth: 662,
  //     suppressMovable: true,
  //   },
  //   {
  //     field: "action",
  //     headerComponentFramework: () => {
  //       return <div className="cursor-pointer">

  //       </div>;
  //     },
  //     colId: "action_1",
  //     pinned: "right",
  //     lockPosition: true,
  //     cellRendererFramework: (params) => {
  //       return <div>
  //         <span className="cursor-pointer" title={props.intl.formatMessage(
  //           {
  //             id: 'commons.listing.edit.tooltip.msg',
  //             defaultMessage: ''
  //           })
  //         } onClick={
  //           () => {
  //             props.updateHeaderState({
  //               communication: {
  //                 ...props.communication,
  //                 dgData: { ...params.data },
  //                 addDGDrawerVisible: true,
  //                 openDGDrawerFromListing: true,
  //               }
  //             })
  //           }}>
  //           <i className='fa fa-pencil' />
  //         </span>

  //         <span style={{ marginLeft: '10px' }} className="cursor-pointer" title={props.intl.formatMessage(
  //           {
  //             id: 'commons.listing.delete.tooltip.msg',
  //             defaultMessage: ''
  //           })
  //         } onClick={
  //           () => {
  //             deleteHandle(params.data);
  //           }}>
  //           <i className='fa fa-trash' />
  //         </span>

  //       </div>;
  //     },
  //     minWidth: 70,
  //     maxWidth: 300,
  //     suppressMovable: true,
  //   }
  // ];

  const newDGGroup = () => {
    props.updateHeaderState({
      communication: {
        ...props.communication,
        openDGDrawerFromListing: true,
        addDGDrawerVisible: true,
      },
    });
  };

  const breadCrumbList = [
    {
      name: <FormattedMessage id='sidebar.menuItem.communication' defaultMessage='' />
    },
    {
      name: <FormattedMessage id='sidebar.menuItem.distributionGroup' defaultMessage='' />,
    }
  ];

  const actionMenu = (e) => {
    return (
        <Menu className="row-action-dropdown">

          <Menu.Item key="0" value="title">
            Actions
          </Menu.Item>

          <Menu.Divider />

         {permissionsData.update && <Menu.Item key="1" value="edit" onClick={()=>{
              props.updateHeaderState({
                communication: {
                  ...props.communication,
                  dgData: { ...e },
                  addDGDrawerVisible: true,
                  openDGDrawerFromListing: true,
                }
              })
            }}
          >
            <i className={ICONS.EDIT} />
            Edit
          </Menu.Item>}

          {permissionsData.delete && <Menu.Item key="2" onClick={()=>
            {
              deleteHandle(e);
            }
          }>
            <i className={ICONS.DELETE} />
            Delete
          </Menu.Item>}

        </Menu >
    )
  };

  const sortColumn = (e) => {
    sortTable(document.getElementById('dg-table'), e.target.cellIndex, 1 - dir);
    updateState({ dir: 1 - dir })
  }

  return (
    <Fragment>
      <PageHeader {...props}
        pageName="heading.communications.dgroup"
        breadCrumbList={breadCrumbList}
        canCreate={true}
        onCreate={newDGGroup}
        moduleCode={MODULE_CODED_VALUES.COMMUNICATION}
      />
      <div className="view-container">
        <div className="view-container-actions">
          <div className="left-actions">
            <div className="table-heading">
              <FormattedMessage id='communication.dgGroup.table.heading' defaultMessage='' />
            </div>
            {
              dgCount ? <>
                <div className="vertical-sep" />
                <div>{dgCount}</div>
              </>:''
            }
          </div>
          <div className="right-actions">
          </div>
        </div>
        <Skeleton loading={props.listLoading} paragraph={{ rows: 18 }} active>
          <div className="table-container no-pagination">
            <table id='dg-table'>
              <thead>
                <tr>
                  <th>S.No.</th>
                  <th onClick={sortColumn}><FormattedMessage id="communication.dgGroup.listing.header.groupName" defaultMessage="" /> <i className={ICONS.SORT} /></th>
                  <th onClick={sortColumn}><FormattedMessage id="communication.dgGroup.listing.header.recipients" defaultMessage="" /> <i className={ICONS.SORT} /></th>
                  <th onClick={sortColumn}><FormattedMessage id='common.dateCreated' defaultMessage='' /> <i className={ICONS.SORT} /></th>
                 {permissionsData.update || permissionsData.delete ? <th width='6%'><FormattedMessage id='common.actions' defaultMessage='' /></th>: "" }
                </tr>
              </thead>
              <tbody>
                {(dgList || []).length ? dgList.map((rowData, i) => {
                  return <tr key={i}>
                    <td>{i+1}</td>
                    <td>{rowData.dgName}</td>
                    <td>{rowData.totalContacts}</td>
                    <td>
                      <Tooltip placement="topLeft" title={(rowData.dateCreated && getMomentDateForUIReadOnly({ date: rowData.dateCreated, format: CONSTANTS.DISPLAY_TOOLTIP_DATE_FORMAT }))}>
                        {(rowData.dateCreated && getMomentDateForUIReadOnly({ date: rowData.dateCreated, format: CONSTANTS.DISPLAY_DATE_FORMAT }))}
                      </Tooltip>
                    </td>
                    {permissionsData.update || permissionsData.delete ? <td style={{paddingLeft: '50px'}}>
                      <div className="action-icon">
                        <DropdownAction overlay={()=>actionMenu(rowData)} trigger={['click']}>
                          <i className={ICONS.MORE} />
                        </DropdownAction>
                      </div>
                    </td>: "" }
                  </tr>
                })
                :
                <tr key="empty-data-box">
                  <td colSpan={"100%"} style={{ borderBottom: "none" }}>
                    <Empty />
                  </td>
                </tr>
                }
                
              </tbody>
            </table>
          </div>

            {/* <div className="agGridWrapper">
              <div className="ag-theme-balham" style={agGridStyle}>
                <AgGridReact
                  // onGridReady={onGridReady}
                  columnDefs={columnDefs}
                  defaultColDef={{
                    flex: 1,
                    autoHeight: true,
                    wrapText: true,
                  }}
                  rowData={dgList || []}
                  rowDragManaged={true}
                  domLayout={"autoHeight"}
                  //getRowHeight={getRowHeight}
                  suppressDragLeaveHidesColumns={true}
                  animateRows={true}
                // onModelUpdated={onModelUpdated}
                // onColumnResized={onColumnResized}
                // onColumnVisible={(params) => {
                //   params.api.resetRowHeights();
                // }}
                // onDragStopped={() => {
                //   onColumnMoved({}, true);
                // }}
                //onColumnMoved={onColumnMoved}
                ></AgGridReact>
              </div>
            </div> */}

        </Skeleton>
      </div>
    </Fragment>
  );
};

export default injectIntl(DistributionGroup);
