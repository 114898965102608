import React, {useState} from "react";
import { Drawer, Row, Col, } from 'antd';
import { CustomButton } from "../../general/CustomButton";
import { TextBox } from '../../general/TextBox';
import { Dropdown } from '../../general/Dropdown';
import { FilterOutlined, UndoOutlined, } from '@ant-design/icons';
import * as find from 'lodash.find';
import { getUserDefinedName } from "../../../utils";


const StudentlistFiltersComp = (props) => {
    const { updateState, companyInfo } = props;
    const [studentFilters, setStudentFilters] = useState(props.studentFilters || {});

    const closeDrawer = () =>{
      props.updateState({
        studentListFilterDrawerVisible: false,
        studentFilters: studentFilters
      })
    }

    const applyFilter = () => {

        const payload = {
          relationshipId: companyInfo.relationshipId, 
          pageNumber: 0, 
          pageSize: props.pageSize || 25,
          filters: studentFilters,
        };
        
        props.getSchoolStudents(payload);
        props.countSchoolStudents(payload);
        closeDrawer();
    }

    const clearFilter = () =>{
        const payload = {
          relationshipId: companyInfo.relationshipId, 
          pageNumber: 0, 
          pageSize: props.pageSize,
          filters: {},
        };
        setStudentFilters({});
        props.updateState({
          studentFilters: {}
        })
        props.getSchoolStudents(payload);
        props.countSchoolStudents(payload);
    }

    const rowGutter = [24, 16];

    return (
        <Drawer
          title={"Student List Filter"}
          width={720}
          zIndex={1}
          maskClosable={false}
          destroyOnClose={true}
          onClose={closeDrawer}
          className="custom-drawer"
          visible={props.studentListFilterDrawerVisible}
          footer={
            <>
              <CustomButton
                intlId="confirmation.cancel"
                defaultMessage=""
                type="default"
                key={0}
                onClick={closeDrawer}
              />

              <div>
                <CustomButton
                  intlId="common.clearFilter.txt"
                  defaultMessage=""
                  type="default"
                  key={0}
                  onClick={clearFilter}
                  btnIcon={
                    <>
                      <UndoOutlined /> &nbsp;
                    </>
                  }
                  style={{ marginRight: "10px" }}
                />
                <CustomButton
                  intlId="common.applyFilter.txt"
                  defaultMessage=""
                  htmlType="submit"
                  onClick={applyFilter}
                  key={1}
                  btnIcon={
                    <>
                      <FilterOutlined /> &nbsp;
                    </>
                  }
                />
              </div>
            </>
          }
        >
          <Row gutter={rowGutter}>

            {/* <Col span={12}>
              Form Name
              <Dropdown
                items={props.studentFormList || []}
                valueKeyName="formShortName"
                optionKeyName="registrationFormSettingId"
                value={studentFilters.formId}
                placeholder="Filter by form"
                onSelect={(optionValue, option) => {
                  setStudentFilters({...studentFilters, formId: optionValue})
                }}
                // allowClear={true}
                // onClear={() => {
                //   updateState({
                //     selectedClass: undefined,
                //     selectedCourse: undefined,
                //   });
                // }}
              />
            </Col> */}

            <Col span={12}>
              Student Name
              <TextBox
                placeholder="Student Name"
                type="text"
                maxLength={20}
                value={studentFilters.studentName}
                onChange={(e) => {
                  setStudentFilters({...studentFilters, studentName: e.target.value})
                }}
              />
            </Col>

            <Col span={12}>
              Father Name
              <TextBox
                placeholder="father Name"
                type="text"
                maxLength={20}
                value={studentFilters.fatherName}
                onChange={(e) => {
                  setStudentFilters({...studentFilters, fatherName: e.target.value})
                }}
              />
            </Col>

            <Col span={12}>
              Mother Name
              <TextBox
                placeholder="mother Name"
                type="text"
                maxLength={20}
                value={studentFilters.motherName}
                onChange={(e) => {
                  setStudentFilters({...studentFilters, motherName: e.target.value})
                }}
              />
            </Col>

            <Col span={12}>
              <div className="i-label">{getUserDefinedName('Class', props)}</div>
              <Dropdown
                items={props.classList[1] || []}
                valueKeyName="className"
                optionKeyName="classId"
                valueKeyName2="courseName"
                value={studentFilters.classId}
                placeholder="Filter by class"
                onSelect={(optionValue, option) => {
                  setStudentFilters({...studentFilters, classId: optionValue})
                }}
              />
            </Col>

            <Col span={12}>
              <div className="i-label">Section</div>
              <Dropdown
                items={((props.classList[1] || []).find(obj=>obj.classId === studentFilters.classId) || {}).classSectionList || []}
                valueKeyName="sectionName"
                optionKeyName="classSectionId"
                value={studentFilters.classSectionId}
                placeholder="Filter by class"
                onSelect={(optionValue, option) => {
                  setStudentFilters({...studentFilters, classSectionId: optionValue})
                }}
              />
            </Col>

            <Col span={12}>
              <div className="i-lable">Allergies</div>

              <Dropdown
                items={[
                  {
                    label: "No",
                    key: 0,
                  },
                  {
                    label: "Yes",
                    key: 1,
                  },
                ]}
                valueKeyName="label"
                optionKeyName="key"
                value={studentFilters.isAllergy}
                placeholder="Choose allergies"
                onSelect={(optionValue, option) => {
                  setStudentFilters({...studentFilters, isAllergy: optionValue})
                }}
              />
            </Col>

            {/* <Col span={12}>
              <div className="i-label">Payment</div>
              <Dropdown
                items={[ "Paid", "Unpaid" ]}
                // valueKeyName="label"
                // optionKeyName="key"
                value={studentFilters.paymentStatus}
                placeholder="Filter by Payment"
                onSelect={(optionValue, option) => {
                  setStudentFilters({...studentFilters, paymentStatus: optionValue})
                }}
              />
            </Col> */}

            {/* <Col span={12}>
              <div className="i-label">Created By</div>
              <Dropdown
                items={[
                  {
                    label: "All",
                    key: "1",
                  },
                  {
                    label: "Parent",
                    key: "2",
                  },
                  {
                    label: "Admin",
                    key: "3",
                  },
                ]}
                valueKeyName="label"
                optionKeyName="key"
                value={props.createdBy}
                placeholder="Filter by form"
                onSelect={(optionValue, option) => {
                  props.updateState({
                    createdBy: option.name,

                    //   formId: option.value,
                  });
                }}
                allowClear={true}
                onClear={() => {
                  updateState({ createdBy: undefined });
                }}
              />
            </Col> */}

            <Col span={12}>
              <div className="i-label">Status</div>
              <Dropdown
                // items={["ALL", "New", "Registered", "Enrolled"]}
                items={["Enrolled","Pending","Under Review"]}
                // valueKeyName="label"
                // optionKeyName="key"
                value={studentFilters.studentStatus}
                placeholder="Filter by Status"
                onSelect={(optionValue, option) => {
                  setStudentFilters({...studentFilters, studentStatus: optionValue})
                }}
              />
            </Col>

          </Row>
        </Drawer>
    );
};

export default StudentlistFiltersComp;
