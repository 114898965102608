import React, { Component, Fragment } from 'react';
import {Menu ,Empty} from 'antd';
import { FormattedMessage } from 'react-intl';
import { ICONS} from '../../../../../static/constants';
import DropdownAction from "antd/lib/dropdown";
import { MoreOutlined } from '@ant-design/icons';
import BankAccount from '../../../../../containers/modal/modalBody/common/BankAccount';

class BankInfo extends Component {
    constructor(props) {
        super(props)
    }
    render() {
        const { permissionsData } = this.props;
        const bankDetailList = this.props.companyInfoData.bankDetails || [];
        // const agGridStyle = {
        //     height: '100%',
        //     width: '100%'
        // };

        // const onFirstDataRendered = (params) => {
        //     params.api.sizeColumnsToFit();
        // }
        // const getRowHeight = (params) => {
        //     return 30;
        // }

        // const columnDefs = [
        //     {
        //         headerName: "#",
        //         resizable: true,
        //         width: 50,
        //        // rowDrag: true,
        //         cellRendererFramework: function (link) {
        //             return <div>
        //                 {link.rowIndex + 1}
        //             </div>
        //         }
        //     },
        //     {
        //         headerComponentFramework: () => {
        //             return <div><FormattedMessage id='modal.settings.companyInfo.bankInfo.bankName' defaultMessage='' />  </div>;
        //         },
        //         field: "name",
        //         resizable: true,
        //         editable: true,
        //         suppressKeyboardEvent: (params) => {
        //             const KEY_ENTER = 13;
        //             const keyCode = params.event.keyCode;
        //             if(keyCode === 9){
        //                 params.api.tabToNextCell();
        //                 return true;
        //             }
        //             const gridShouldDoNothing = params.editing && keyCode === KEY_ENTER;
        //             return gridShouldDoNothing;
        //         },
        //         cellEditor: 'customTextEditor',
        //         cellEditorParams: (obj) => {
        //             return {
        //                 onChange: (value) => {
        //                     obj.node.setDataValue(obj.colDef.field, value);
        //                 }
        //             }
        //         },
        //         width: 300,
        //     },
        //     {
        //         headerComponentFramework: () => {
        //             return <div><FormattedMessage id='modal.settings.companyInfo.bankInfo.payeeName' defaultMessage='' />  </div>;
        //         },
        //         field: "payeeName",
        //         resizable: true,
        //         editable: true,
        //         suppressKeyboardEvent: (params) => {
        //             const KEY_ENTER = 13;
        //             const keyCode = params.event.keyCode;
        //             if(keyCode === 9){
        //                 params.api.tabToNextCell();
        //                 return true;
        //             }
        //             const gridShouldDoNothing = params.editing && keyCode === KEY_ENTER;
        //             return gridShouldDoNothing;
        //         },
        //         cellEditor: 'customTextEditor',
        //         cellEditorParams: (obj) => {
        //             return {
        //                 onChange: (value) => {
        //                     obj.node.setDataValue(obj.colDef.field, value);
        //                 }
        //             }
        //         },
        //         width: 300,
        //     },
        //     {
        //         headerComponentFramework: () => {
        //             return <div><FormattedMessage id='modal.settings.companyInfo.bankInfo.accountNumber' defaultMessage='' />  </div>;
        //         },
        //         field: "accountNumber",
        //         resizable: true,
        //         editable: true,
        //         cellEditor: 'customTextEditor',
        //         suppressKeyboardEvent: (params) => {
        //             const KEY_ENTER = 13;
        //             const keyCode = params.event.keyCode;
        //             if(keyCode === 9){
        //                 params.api.tabToNextCell();
        //                 return true;
        //             }
        //             const gridShouldDoNothing = params.editing && keyCode === KEY_ENTER;
        //             return gridShouldDoNothing;
        //         },
        //         cellEditorParams: (obj) => {
        //             return {
        //                 onChange: (value) => {
        //                     obj.node.setDataValue(obj.colDef.field, value);
        //                 }
        //             }
        //         }
        //     },
        //     {
        //         headerComponentFramework: () => {
        //             return <div><FormattedMessage id='modal.settings.companyInfo.bankInfo.ifsc' defaultMessage='' />  </div>;
        //         },
        //         field: "ifscCode",
        //         resizable: true,
        //         editable: true,
        //         cellEditor: 'customTextEditor',
        //         suppressKeyboardEvent: (params) => {
        //             const KEY_ENTER = 13;
        //             const keyCode = params.event.keyCode;
        //             if(keyCode === 9){
        //                 this.gridApi.getDisplayedRowAtIndex(params.node.rowIndex).data.isToEdit = false;
        //                 this.gridApi.refreshCells({ force: true });
        //                 params.api.stopEditing(true);
        //                 return true;
        //             }
        //             const gridShouldDoNothing = params.editing && keyCode === KEY_ENTER;
        //             return gridShouldDoNothing;
        //         },
        //         cellEditorParams: (obj) => {
        //             return {
        //                 onChange: (value) => {
        //                     obj.node.setDataValue(obj.colDef.field, value);
        //                 }
        //             }
        //         }
        //     },
        //     {
        //         headerComponentFramework: () => {
        //             return <div><FormattedMessage id='modal.settings.companyInfo.bankInfo.routingNumber' defaultMessage='' />  </div>;
        //         },
        //         field: "routingNumber",
        //         resizable: true,
        //         editable: true,
        //         cellEditor: 'customTextEditor',
        //         suppressKeyboardEvent: (params) => {
        //             const KEY_ENTER = 13;
        //             const keyCode = params.event.keyCode;
        //             if(keyCode === 9){
        //                 params.api.tabToNextCell();
        //                 return true;
        //             }
        //             const gridShouldDoNothing = params.editing && keyCode === KEY_ENTER;
        //             return gridShouldDoNothing;
        //         },
        //         cellEditorParams: (obj) => {
        //             return {
        //                 onChange: (value) => {
        //                     obj.node.setDataValue(obj.colDef.field, value);
        //                 }
        //             }
        //         }
        //     },
        //     {
        //         field: 'action',  lockPosition: true, pinned: 'right',
        //         width: 180,
        //         cellRendererFramework: (params) => <div>
        //             {(params.data.origination !== 'System') ? <div>
        //                 <div>
        //                     {params.node.data.isToEdit &&
        //                         <div className="table-cell-action">
        //                             {
        //                                 permissionsData.create ?  <Link onClick={() => {

        //                                     this.gridApi.refreshCells({ force: true }); // its need to reflact data in node
        //                                     let rowData = this.gridApi.getDisplayedRowAtIndex(params.node.rowIndex).data;
        //                                     if (rowData.accountNumber && rowData.ifscCode && rowData.name && rowData.payeeName) {
        //                                         const modalData = {
        //                                             modalBody: <FormattedMessage id='save.confirmation' defaultMessage='' values={{ value: '' }} />,
        //                                             handleSubmit: () => {
        //                                                 this.gridApi.getDisplayedRowAtIndex(params.node.rowIndex).data.relationshipId = (this.props.companyInfo || {}).relationshipId
        //                                                 this.props.createBankDetail(this.gridApi.getDisplayedRowAtIndex(params.node.rowIndex).data, this.props);
        //                                                 // this.props.popModalFromStack();
        //                                                 this.gridApi.getDisplayedRowAtIndex(params.node.rowIndex).data.isToEdit = false;
        //                                                 this.gridApi.refreshCells({ force: true });
        //                                             }
        //                                         };
        //                                         this.props.pushModalToStack(modalData);
        //                                     } else {
        //                                     if (!rowData.name) {
        //                                         showToasterMessage({ description: this.props.intl.formatMessage({ id: 'modal.settings.companyInfo.bankInfo.name.validation', defaultMessage: '' }) })
        //                                     } else if (!rowData.payeeName) {
        //                                         showToasterMessage({ description: this.props.intl.formatMessage({ id: 'modal.settings.companyInfo.bankInfo.payeeName.validation', defaultMessage: '' }) })
        //                                     } else if (!rowData.accountNumber) {
        //                                         showToasterMessage({ description: this.props.intl.formatMessage({ id: 'modal.settings.companyInfo.bankInfo.accountNumber.validation', defaultMessage: '' }) })
        //                                     } else if (!rowData.ifscCode) {
        //                                         showToasterMessage({ description: this.props.intl.formatMessage({ id: 'modal.settings.companyInfo.bankInfo.ifsc.validation', defaultMessage: '' }) })
        //                                     }
        //                                         this.gridApi.getDisplayedRowAtIndex(params.node.rowIndex).data.isToEdit = false;
        //                                         this.gridApi.refreshCells({ force: true });
        //                                 }

        //                                 }} >
        //                                     <i className="fa fa-save" />
        //                                 </Link>: ""
        //                             }

        //                             {
        //                                 permissionsData.delete ? <Link onClick={() => {
        //                                     params.api.stopEditing(true);
        //                                     this.gridApi.getDisplayedRowAtIndex(params.node.rowIndex).data.isToEdit = false;
        //                                     this.gridApi.refreshCells({ force: true });
        //                                 }} >
        //                                     <i className="fa fa-ban" />
        //                                 </Link>: ""
        //                             }

        //                         </div>
        //                     }

        //                     {!params.node.data.isToEdit &&
        //                         <div className="table-cell-action" >
        //                             {
        //                                 permissionsData.update ?  <Link onClick={() => {
        //                                     this.gridApi.forEachNode((rowNode, index) =>{
        //                                         this.gridApi.getDisplayedRowAtIndex(index).data.isToEdit = false;
        //                                     });
        //                                     this.gridApi.getDisplayedRowAtIndex(params.node.rowIndex).data.isToEdit = true;
        //                                     this.gridApi.refreshCells({ columns: ["action"], force: true });
        //                                     var startEditingParams = {
        //                                         rowIndex: params.node.rowIndex,
        //                                         colKey: params.column.getId(),
        //                                       };
        //                                     params.api.startEditingCell(startEditingParams);
        //                                 }}>
        //                                     <i className="fa fa-edit" />
        //                                 </Link>: ""
        //                             }
        //                            {
        //                             permissionsData.delete ?  <Link onClick={() => {
        //                                 const modalData = {
        //                                     modalBody: <FormattedMessage id='deleteItem.confirmation' defaultMessage='' values={{value:''}} />  ,
        //                                     handleSubmit: () => {
        //                                         this.props.deleteBankDetail(this.gridApi.getDisplayedRowAtIndex(params.node.rowIndex).data, this.props);
        //                                     //   this.props.popModalFromStack()
        //                                     }
        //                                   };
        //                                   this.props.pushModalToStack(modalData);
        //                             }}>
        //                                 <i className="fa fa-remove" />
        //                             </Link>: ""
        //                            }

        //                         </div>}
        //                 </div>
        //             </div>
        //                 : <div></div>
        //             }
        //         </div>
        //     }
        // ]

        // const onGridReady = (params) => {
        //     this.gridApi = params.api;
        // }
        const handleBankAccount = (data) => {
            let modalData = {
                title: (
                    "Adding Bank Account"
                ),
                modalBody: <BankAccount {...this.props} bankDetails={data}/>,
                wrapClassName: "pay-invoice-modal",
                width: 500,
                hideFooter: true,
                keyboard: false,
                onCancel: () => {
                    this.props.pushModalToStack({
                        modalBody: <FormattedMessage id="modal.cancel.confirmation" defaultMessage="" />,
                        footer: { submit: { intlId: "button.close.label" } },
                        handleSubmit: () => {
                            this.props.hideModal();
                        }
                    })
                }
            };
            this.props.showModal(modalData);
        }

        const actionMenu = (e) => {
            return (
                <Menu className="row-action-dropdown">
                    <Menu.Item key="0" value="title">
                        Action
                    </Menu.Item>

                    <Menu.Divider />
                    {
                        permissionsData.update ? <Menu.Item
                            key="1"
                            onClick={() => {
                                handleBankAccount(e);
                            }}
                        >
                            <i className={ICONS.EDIT} />
                            Edit
                        </Menu.Item> : ""
                    }

                    {
                        permissionsData.delete ? <Menu.Item
                            key="2"
                            value="delete"
                            onClick={() => {
                                this.props.showModal({
                                    modalBody: "Are you sure want to delete?",
                                    handleSubmit: () => {
                                        this.props.deleteBankDetail(e, this.props)
                                    },
                                });
                            }}
                        >
                            <i className={ICONS.DELETE} /> Delete
                        </Menu.Item> : ""
                    }

                </Menu>
            );
        };
        return (
            <Fragment>
                <div className=''>
                    {/* <div className="ag-theme-balham" style={agGridStyle} >
                        <CustomAgGrid
                            columnDefs={columnDefs}
                            rowData={bankDetailList || []}
                            rowDragManaged={true}
                            suppressClickEdit={true}
                            noSingleClickEdit={true}
                            domLayout={"autoHeight"}
                            animateRows={true}
                            onGridSizeChanged={onFirstDataRendered}
                            getRowHeight={getRowHeight}
                            editType={'fullRow'}
                            onGridReady={onGridReady}
                        >
                        </CustomAgGrid>
                    </div>
                    <Button className="add-new-record" disabled={!(permissionsData.create)} onClick={
                            () => {
                                let newRowIndex = -1;
                                this.gridApi.forEachNode((rowNode, index) =>{
                                    newRowIndex = index;
                                    this.gridApi.getDisplayedRowAtIndex(index).data.isToEdit = false;
                                });
                                this.gridApi.stopEditing();
                                this.gridApi.updateRowData({ add: [{isToEdit: true}] });
                                this.gridApi.startEditingCell({
                                    rowIndex: newRowIndex+1,
                                    colKey: "name"
                                });
                                this.gridApi.refreshCells({ force: true });
                            }
                        }><FormattedMessage id='modal.settings.companyInfo.bankInfo.newAccount' defaultMessage='' />  </Button> */}


                    <div className="view-top-bar">
                        <div className="top-view-left"></div>
                        <div className="top-view-right">
                            {
                                permissionsData.create ? <button
                                    className="create-action-btn"
                                    onClick={handleBankAccount}
                                >
                                    <i className={`${ICONS.ADD} mr5`} />
                                    <FormattedMessage id="button.create.label" defaultMessage="" />
                                </button> : ""
                            }

                        </div>
                    </div>
                    <div className="table-container">
                        <table>
                            <thead>
                                <tr>
                                    <th>
                                        <FormattedMessage id="serial.no" defaultMessage="" />
                                    </th>
                                    <th>
                                        <FormattedMessage id="modal.settings.companyInfo.bankInfo.bankName" defaultMessage="" />
                                    </th>
                                    <th>
                                        <FormattedMessage
                                            id="modal.settings.companyInfo.bankInfo.payeeName"
                                            defaultMessage=""
                                        />
                                    </th>
                                    <th>
                                        <FormattedMessage
                                            id="modal.settings.companyInfo.bankInfo.accountNumber"
                                            defaultMessage=""
                                        />
                                    </th>
                                    <th>
                                        <FormattedMessage
                                            id="modal.settings.companyInfo.bankInfo.ifsc"
                                            defaultMessage=""
                                        />
                                    </th>
                                    <th>
                                        <FormattedMessage id="modal.settings.companyInfo.bankInfo.routingNumber" defaultMessage="" />
                                    </th>

                                    {
                                        (permissionsData.update || permissionsData.delete) ? <th>
                                            <FormattedMessage id="Action" defaultMessage="" />
                                        </th> : ""
                                    }

                                </tr>
                            </thead>
                            <tbody>
                                {(bankDetailList || []).length ?
                                    bankDetailList.map((rowData, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>{index + 1}</td>

                                                <td>{rowData.name}</td>
                                                <td>

                                                </td>
                                                <td>{rowData.accountNumber}</td>
                                                <td>{rowData.ifscCode}</td>
                                                <td></td>

                                                {
                                                    (permissionsData.update || permissionsData.delete) ? <td className="text-center">
                                                        <DropdownAction
                                                            overlay={actionMenu(rowData)}
                                                            trigger={["click"]}
                                                            overlayClassName="action-listing-dropdown"
                                                        >
                                                            <MoreOutlined />
                                                        </DropdownAction>

                                                    </td> : ""
                                                }

                                            </tr>
                                        );
                                    })
                                    : <tr key="empty-data-box">
                                        <td colSpan={"100%"} style={{ borderBottom: "none" }}>
                                            <Empty />
                                        </td>
                                    </tr>
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default BankInfo;
