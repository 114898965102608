import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { pushModalToStack,  } from '../../../../actions/commonActions';
import FeedbackDrawer from '../../../../components/drawer/inventory/FeedbackDrawer';
import { fetchPublishAndUnpublishList } from '../../../product/RatingAndReview/action';
import { updateFeedback,deleteFeedbackImgaes,getReviewData } from './action';


class FeedbackDrawerCon extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageNumber: 1,
      pageSize: 100,
      selectedItems:[],
      // ...(this.props.feedbackData || {})

    };
  }

  componentWillReceiveProps(props) {
  
  }

  componentDidMount() {
    const payload = {
      relationshipId: (this.props.companyInfo || {}).relationshipId,
      pageNumber: 1,
      pageSize: 100,
      feedbackId:(this.props.data || {})?.feedbackId
    };
      this.props.getReviewData(payload)
  }

  render() {
    return (
      <>
       <FeedbackDrawer {...this.props} {...this.state} updateDrawerState={(data) => { this.setState(data) }} />
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    feedbackData:state.product.feedbackData

  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchPublishAndUnpublishList,
    updateFeedback,
    pushModalToStack,
    deleteFeedbackImgaes,
    getReviewData
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(FeedbackDrawerCon);
