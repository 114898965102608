import React from "react";
import { Row, Col, Button } from 'antd';
import EasyPostIcon from '../../../assets/images/easypost-logo.png';
import { FormattedMessage } from "react-intl";
import EasyPostIntegrateComponent from './easyPostIntegrate' ;
import PageBreadcrumb from "../../PageBreadcrumb";

const EasyPostListingComp = (props) => {
    const breadCrumbList = [
       
        {
            name: <FormattedMessage id='sidebar.menuItem.shipping' defaultMessage='' />
           
        },  {
            name: <FormattedMessage id='easyPost.logo' defaultMessage='' />,
        }
    ];
    return (
        <div className="customer-details-container shadeseasy">
            {/* <div className="pb10">
                <PageBreadcrumb breadCrumbList={breadCrumbList} />
            </div> */}
            <div className="customer-details">
                <div className="prod-ama">
                    <Row >
                    <Col span={2}></Col>
                    <Col span={15}> 
                    <h3>EasyPost provides the complete shipping solution </h3>
                    <div className="line-box"></div>
                    <h4>Save up to 74% for USPS, FedEx, UPS, DHL, and many more.</h4>
                    <ul>
                        <li><span><i class="fa fa-square" aria-hidden="true"></i>
                            </span>Solve complex shipping logistics problems with a single integration  </li>
                        <li><span><i class="fa fa-square" aria-hidden="true"></i>
                        </span>  Join thousands of customers shipping millions of packages.  </li>
                        <li><span><i class="fa fa-square" aria-hidden="true"></i>
                            </span>  Businesses can streamline, automate, and gain end-to-end control of their shipping process  </li>
                        <li><span><i class="fa fa-square" aria-hidden="true"></i>
                            </span> Receive real-time shipment updates.</li>

{/* <Button className="ant-btn cnant-btn ant-btn-primary prod-amab">Start Now</Button> */}

                    </ul>
<div className="prod-amab"><a href="https://www.easypost.com/" target="_blank" rel="noopener noreferrer">Start with Easypost</a></div>
                     </Col>
                    <Col span={6}> <div className="ant-card accounting-market-place text-center integsm ant-card-bordered ">
                        <div className="ant-card-head headr">
                            <div className="ant-card-head-wrapper">
                                <div className="ant-card-extra">
                                <img src={EasyPostIcon} alt="" style={{ height: "50px" }} />
                                </div>
                            </div>
                        </div>
                        {(props.linkedEasyPost && props.linkedEasyPost.alpideCarrierId) ? <div class="cr cr-top cr-left cr-green">Integrated</div> : ''}
                        <div className="ant-card-body">
                            <p> List Product to Amazon</p>
                            <p> Create Customer Profiles</p>
                            <p> Create Subscriptions</p>
                            <p> Create Subscriptions</p>
                            
                                <div className="border-footer mt10"></div>
                                {(props.linkedEasyPost && props.linkedEasyPost.alpideCarrierId) ? <Button className="cnant-btn grey-button" onClick={
                                    () => {
                                        props.showModal({
                                            modalBody: <FormattedMessage id="modal.disconnect.confirmation" defaultMessage=""/>,
                                            footer:{submit:{intlId:"button.disconnect.label"}},
                                            handleSubmit: () => {
                                            props.disconnectConfiguration(props.linkedEasyPost, props);
                                              props.popModalFromStack();
                                            }
                                          })
                                    }
                                }>Disconnect </Button>
                                    :
                                    <Button className="ant-btn cnant-btn ant-btn-primary" onClick={() => {
                                        props.pushModalToStack({
                                            modalBody: <EasyPostIntegrateComponent {...props} />,
                                            title: <FormattedMessage id='easyPost.logo' defaultMessage='' />,
                                            width: '30%',
                                            hideFooter: true,
                                        })
                                    }}>Start Shipping</Button>
                                }
                            </div>
                    </div></Col>
                    <Col span={1}></Col> 
                    </Row>
                
                    
                   
                </div>
                <div className="clearfix"></div>
            </div>
            
        </div>
    )
};

export default EasyPostListingComp;
