import React, {Fragment} from "react";
import PageBreadcrumb from "../../PageBreadcrumb";
import './../finance.scss';
import { CONSTANTS } from "../../../static/constants";
import LedgerDetails from '../../../containers/modal/modalBody/finance/LedgerAccount/LedgerDetails'
import { getFinancialYear, getMomentDateForUIReadOnly } from "../../../utils";
import { FormattedMessage } from 'react-intl';
import { Skeleton,Empty } from "antd";

const TrialBalances = (props) =>  {
    const breadCrumbList = [
        {
            name: <FormattedMessage id='sidebar.menuItem.accounting' defaultMessage=' ' />,
        }, 
        {
            name: <FormattedMessage id='sidebar.menuItem.trialBalance' defaultMessage=' ' />
        }
    ];

const { allLedgerAccounts, companyInfo } = props;
const financialYear = getFinancialYear(companyInfo);

   const  getTotal = function(type){
        let totalAmount = 0;

        for(let i= 0;i < allLedgerAccounts.length;i++){
            if((! allLedgerAccounts[i].nature &&  allLedgerAccounts[i].accountingEntry === type) ||  allLedgerAccounts[i].nature === type){
                totalAmount = totalAmount + allLedgerAccounts[i].amountDifference;
                
            }
        }
        return totalAmount.toFixed(2);
    }

    return (
        <>
            <div className="view-top-bar">
                <div className="top-view-left">
                    <div className="page-heading">
                        <FormattedMessage id="sidebar.menuItem.trialBalance" defaultMessage=""/>
                    </div>
                    <div className="vertical-sep" />
                    <PageBreadcrumb breadCrumbList={breadCrumbList} />
                </div>
                <div className="top-view-right"></div>
            </div>

            <div className="view-container">
                <div className="view-container-actions">
                    <div className="left-actions">
                    <div className="table-heading">
                        {(companyInfo || {}).storeName}<br/>
                        <div className="light-txt">
                            Trial Balance as on {((financialYear || {}).fyEndDate && getMomentDateForUIReadOnly({date: financialYear.fyEndDate, format: CONSTANTS.DISPLAY_DATE_FORMAT_FY}))}
                        </div>
                    </div>

                    </div>
                    <div className="right-actions">
                    </div>
                </div>
                <div style={{height: 'calc(100% - 50px)'}} className="table-container" >
                <Skeleton loading={props.listLoading} active paragraph={{ rows: 16 }}>
                    <table>
                        <thead>
                        {/* <tr>
                            <th colSpan="4">
                                <div className="bold-text text-center pb15">
                                {(companyInfo || {}).storeName}
                                </div>

                                <div className="text-center">
                                    Trial Balance as on {((financialYear || {}).fyEndDate && getMomentDateForUIReadOnly({date: financialYear.fyEndDate, format: CONSTANTS.DISPLAY_DATE_FORMAT_FY}))}
                                </div>
                            </th>
                        </tr> */}
                        <tr>
                            <th>Account Number</th>
                            <th>Particulars</th>
                            <th>Debit ({props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "})</th>
                            <th>Credit ({props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "})</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            (allLedgerAccounts || []).length ? <>
                                {
                                    allLedgerAccounts.map((e, i) =>{
                                        return (<tr key={`tb` + i}>
                                        <td>{e.accountNumber}</td>
                                        <td className="link-color" onClick={()=>{
                                            const payload ={
                                                ledgerAccountId: e.chartOfAccountDetailsId
                                            }
                                            props.showModal(
                                                {
                                                    title: <Fragment>
                                                        <div className='ant-modal-title'>
                                                            Ledger Account  Information
                                                        </div>
                                                    </Fragment>,
                                                    modalBody: <LedgerDetails {...props} {...payload}/>,
                                                    width: 1000,
                                                    hideFooter: true
                                                }
                                            )
                                        }
                                        }>{e.ledgerAccountName}</td>
                                        <td>{
                                            ((!e.nature && e.accountingEntry === 'Debit') || e.nature === 'Debit') ? (e.amountDifference || 0).toFixed(2) : '0.00'
                                        }</td>
                                        <td>{
                                            (!e.nature && e.accountingEntry === 'Credit') || e.nature === 'Credit' ? (e.amountDifference || 0).toFixed(2) : '0.00'
                                        }</td>
                                    </tr>)
                                    })

                                }
                                <tr key={'ex'} className="sticky_row">
                                    <td>&nbsp;</td>
                                    <td><b>Total</b></td>
                                    <td><b>{getTotal("Debit")}</b></td>
                                    <td><b>{getTotal("Credit")}</b></td>
                                </tr>
                            </>

                            :
                            <tr key="empty-data-box">
                <td colSpan={"100%"} style={{ borderBottom: "none" }}>
                    <Empty />
                </td>
            </tr>
                        }
                        

                        </tbody>
                    </table>
                </Skeleton>
                </div>
            </div>
            {/* <div className="finance-container">
            </div> */}
        </>
    )
};

export default TrialBalances;