import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import EmailSignatureComponent from '../../../../../components/modal/modalBody/settings/EmailSignature';
import { fetchEmailTemplate, saveOrUpdateEmailTemplate, fetchSenderEmailsFromRID } from './action';
import { pushModalToStack  } from "../../../../../actions/commonActions";

class EmailSignature extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectDocumentOptions: props.isAlifApp ? ['RFQ', 'Sales Quotation', 'Sales Order', 'Sales Invoice', 'Purchase Order', 'Purchase Invoice', 'Sales Payment', 'Credit Memo', 'Purchase Payment', 'Debit Memo', 'Inquiry', 'Purchase Request', 'Online Payment', 'Kiosk Payment', 'Text2Donate Payment', 'Pledge', 'Event RSVP Response', 'Subscribe', 'Pledge Reminder', 'Recurring Sales Invoice', 'School Payment Receipt', 'Online Order Confirmation', 'Class Assignment', 'Sales Shipment']
                : ['RFQ', 'Sales Quotation', 'Sales Order', 'Sales Invoice', 'Purchase Order', 'Purchase Invoice', 'Sales Payment', 'Credit Memo', 'Purchase Payment', 'Debit Memo', 'Inquiry', 'Purchase Request', 'Online Payment', 'Kiosk Payment', 'RSVP', 'Subscribe', 'Recurring Sales Invoice', 'School Payment Receipt', 'Online Order Confirmation', 'Class Assignment', 'Sales Shipment', 'Sales Order Status Update', 'Sales Order Cancellation', 'Sales Order Update'],
            docName: props.docName || '',
            subject: "",
            content:`<p><span style="font-size:12px;">Best regards,</span></p> 
            <br>
            <br>
            <p><span style="border-top:1px solid #959595">&nbsp;</span></p>
           <p> <span style="line-height:14px;color:#959595;font-size:10px; ">Disclaimer: This email and any files transmitted with it are confidential and intended solely for the use of the individual or entity to whom they are addressed. If you have received this email in error, please notify the sender immediately and delete the email from your system. Any unauthorized use, distribution, or copying of this email is prohibited. This email may contain confidential information that is protected by applicable laws. Any views or opinions presented in this email are solely those of the author and do not necessarily represent those of the company.</span></p>`,
            isFormSetting: props.isFormSetting || false,
            // senderEmails: props.senderEmails || [],
        }
        let CKEDITOR = window.CKEDITOR;
        if (CKEDITOR) {
            CKEDITOR.on('instanceReady', function (ev) {
                document.getElementById(ev.editor.id + '_top').style.display = 'block';
                let edi = document.getElementById('cke_'+ ev.editor.name);
                if (edi) { edi.style.width = '';}
                ev.editor.on('focus', function (e) {
                  document.getElementById(ev.editor.id + '_top').style.display = 'block';
                 
              });
              ev.editor.on('blur', function (e) {
                  let edi = document.getElementById(ev.editor.id + '_top');
                  if (edi) { edi.style.display = 'block'; }
              });
            });
        }
    }

    componentWillMount() {
        const companyInfo = this.props.companyInfo || {};
        const payload = { relationshipId: companyInfo.relationshipId, docName: this.props.docName};
        this.props.docName && this.props.fetchEmailTemplate( payload);
        // this.props.senderEmails && this.props.fetchSenderEmailsFromRID(payload)

    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.templateData !== this.state.templateData && nextProps.templateData) {
          this.setState({ subject: nextProps.templateData.subject || '', content:  this.state.content  + nextProps.templateData.content || '' });
        }
        if(nextProps.senderEmails.length){
            this.setState({senderEmails: nextProps.senderEmails});
        }
      }

    render() {
        return <EmailSignatureComponent {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
    }
}

const mapStateToProps = (state) => {
    return {
        templateData: state.settings.templateData,
        isAlifApp: state.common.isAlifApp,
        senderEmails: state.settings.senderEmails,
        permissions: state.auth.permissions,

    }
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchEmailTemplate,
    saveOrUpdateEmailTemplate,
    pushModalToStack,
    fetchSenderEmailsFromRID,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(EmailSignature);
