import React, { Fragment } from 'react';
import { capitalizeFirstLetter, getInitialsFromString } from '../../utils';
import { Skeleton } from 'antd';


const TopCustomer = (props) => {
    return <div style={{ minHeight: "332px" }}>
        <Skeleton loading={props.detailLoading} paragraph={{ rows: 5 }} active>
        {Object.keys(props.topSalesCustomer || {}).slice(0, 6)?.map(data => {
            return <>
                    <div className='icon_name_label pb15' >

                        <div className="name-icon">
                            {getInitialsFromString(data)
                                || "AT"}
                        </div>
                        <div>
                            <b>{capitalizeFirstLetter(data)}</b>
                            <div>India</div>
                        </div>
                    </div>
            </>

})}
</Skeleton>

    </div>
};

export default TopCustomer;