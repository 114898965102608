import React, {useMemo} from 'react';
import { FormattedMessage } from 'react-intl';
import { FilePdfOutlined, MailOutlined, PrinterOutlined, EditOutlined, DeleteOutlined, CopyOutlined, HistoryOutlined } from '@ant-design/icons';
import {  Button } from 'antd'
import * as find from 'lodash.find';
import * as filter from 'lodash.filter';
import config from '../../../../../config/environmentConfig';
import { CONSTANTS, TRANSACTION_TYPES, MODAL_TYPE, PERMISSION_VALUES, MODULE_CODED_VALUES, CONSTANTS_TRANSACTIONS } from '../../../../../static/constants';
import { fixedDecimalNumber, checkACLPermission, getPermissionData } from '../../../../../utils';
import { Dropdown } from '../../../../general/Dropdown';
import StandardTemplate from "./standard";
import Template2 from "./template2"; 
import StandardPurchaseOrder from '../../../../../containers/supplier/purchaseOrder/StandardPurchaseOrder';
import StandardInboundDelivery from '../../../../../containers/supplier/InboundDelivery';

const PODetails = (props) => {
    const { poData, companyInfo, templateName = 'Professional', permissions } = props;
    const primaryPerm = permissions.primary;
    const { supplierPurchaseOrderDetailList } = poData;
    const poPermissionsData = useMemo(()=>{
        return getPermissionData(primaryPerm, MODULE_CODED_VALUES.PURCHASE_ORDER )
      },[])
    const grnPermissionsData = useMemo(()=>{
        return getPermissionData(primaryPerm, MODULE_CODED_VALUES.PURCHASE_GRN )
      },[])
    const relationshipAddress = find(poData.boLocationPOList, { locationType: CONSTANTS.RELATIONSHIP_BILLING_ADDRESS }) || {};
    const billingAddress = find(poData.boLocationPOList, { locationType: CONSTANTS.BILLING_ADDRESS }) || {};
    const relShippingAddress = find(poData.boLocationPOList, { locationType: CONSTANTS.RELATIONSHIP_SHIPPING_ADDRESS }) || {};
    let isPlaceOfSupplySameAsRelationship = false
    isPlaceOfSupplySameAsRelationship = (poData.placeOfSupply && billingAddress.stateName === poData.placeOfSupply) || false;
    let itemLevelTaxes = [];
    if (supplierPurchaseOrderDetailList) {
        itemLevelTaxes = calculateTaxRateList(supplierPurchaseOrderDetailList, isPlaceOfSupplySameAsRelationship);
    }
    let isColumnEmpty = {
        product: true,
        service: true,
        variant: true,
        description: true,
        qty: true,
        uomName: true,
        rate: true,
        hsn: true,
        discount: true,
        tax: true,
        specialDiscount: true,
    };
    if (poData) {
        isColumnEmpty = updateColumnEmptyCheck(isColumnEmpty, poData, props);
    }

    const getPayloadForPdf = () => {
        return {
            templateName: templateName,
            fileName: (poData.poNumber || 'Purchase Order') + '.pdf',
            transactionName: 'purchaseOrder',
            poData: poData,
            relationshipAddress: relationshipAddress,
            relShippingAddress: relShippingAddress,
            billingAddress: billingAddress,
            companyInfo: companyInfo,
            isColumnEmpty: isColumnEmpty,
            isPlaceOfSupplySameAsRelationship: isPlaceOfSupplySameAsRelationship,
            itemLevelTaxes: itemLevelTaxes,
            bucketName: config.BUCKET_NAME.BO_PURCHASE_ORDER,
            stampAwsKey: poData.stampAwsKey,
            // hsnData:
        }
    }

    const generatePDF = () => {
        let payload = getPayloadForPdf();
        props.generatePDF(payload);
    }

    const printPdf = () =>{
        let payload = getPayloadForPdf();
        props.printPDF(payload);
    }

    const openEmailDrawer = (props) => {
        let payload = getPayloadForPdf();
        payload.fileDataReqeired = true;
        payload.supplierId = poData.supplierId;
        payload.poMasterId = poData.poMasterId;
        props.updateModalState({
            emailDrawerVisible: true,
            txData: payload,
            type: TRANSACTION_TYPES.PURCHASE_ORDER,
        });
    };

    const approvePO = () => {
        const modalBody = <FormattedMessage id='areyou.sure.you.want.approve' defaultMessage='' />  ;
        const modalData = {
            modalBody,
            handleSubmit: () => {
                props.approvePO({
                    relationshipId: poData.relationshipId,
                    supplierId: poData.supplierId,
                    poMasterId: poData.poMasterId,
                    approvedByEmpId: (props.userInfo || {}).relationshipEmployeeId,
                }, props)
                props.popModalFromStack();
            },
        };
        props.showModal(modalData);
    }

    const handleDelegateApprover = () => {
        const formData = {
            relationshipId: poData.relationshipId,
            supplierId: poData.supplierId,
            poMasterId: poData.poMasterId,
            approvedByEmpId: (props.userInfo || {}).relationshipEmployeeId,
            txType: TRANSACTION_TYPES.PURCHASE_ORDER,
            approverEmpId: poData.approverEmpId,
        };
        const { showModal } = props;
        const data = {
            title: 'Delegate Approver',
            formData,
            hideFooter: true,
            modalData: {
                modalType: MODAL_TYPE.DELEGATE_APPROVER,
                data: formData,

            },
            handleSubmit: (formData = {}) => {
                data.formData.submittedOnce = true;
                props.hideModal(data);
                props.popModalFromStack();
            }
        };
        showModal(data);
    }

    const handleRejectApproval = () => {
        const formData = {
            relationshipId: poData.relationshipId,
            supplierId: poData.supplierId,
            poMasterId: poData.poMasterId,
            approvedByEmpId: (props.userInfo || {}).relationshipEmployeeId,
            txType: TRANSACTION_TYPES.PURCHASE_ORDER
        };
        const { showModal } = props;
        const data = {
            title: 'Reject',
            formData,
            hideFooter: true,
            modalData: {
                modalType: MODAL_TYPE.REJECT_APPROVAL,
                data: formData,

            },
            handleSubmit: (formData = {}) => {
                data.formData.submittedOnce = true;
                props.hideModal(data);
                props.popModalFromStack();
            }
        };
        showModal(data);
    }

    const openAuditDrawer = (props, data) => {
        props.updateState({
            title: <div className="drawer-title">
                <FormattedMessage id='modal.txDetails.common.auditTrail' defaultMessage='' />
            </div>,
            auditDrawerVisible: true,
            deletApiUrl: 'deleteAuditTrailPurchaseOrder',
            masterKey: 'poMasterId',
            masterKeyValue: data.poMasterId,
            apiUrl: 'saveAuditTrailPurchaseOrder',
            supplierId: data.supplierId,
            trailList: data.auditTrailPurchaseOrderList || [],
            updateTrailListInTransaction: (list) =>{
                data.auditTrailPurchaseOrderList = list;
            } 
        });
    }

    const addItemConfirmationModal = (props, text, color, poData) => {
        const { showModal, companyInfo } = props;
      
        const modalBody = <span>
            <FormattedMessage
                id='setItem.confirmation'
                defaultMessage={``}
            />
           
      </span>;

        let payload = {
            userStatus: text,
            userStatusColor: color,
            relationshipId: companyInfo.relationshipId,
            poMasterId: poData.poMasterId,
            supplierId: poData.supplierId
        };

        const modalData = {
            modalBody,
            handleSubmit: () => {
                props.updateUserStatus(payload, props);
                props.hideModal();
                props.popModalFromStack();
            },
        };
        showModal(modalData);
    }



    const updateUserStatus = (props, poData) => {
        let payload = { formData: {}, txData: poData };
        const formData = payload.formData;
        const { pushModalToStack } = props;

        if (formData.textBoxValue && formData.textBoxValue.length && !payload.existsInData) {
            addItemConfirmationModal(props, formData.textBoxValue, poData);
        } else {
            const data = {
                title: <FormattedMessage id='addItem.text.userStatus' defaultMessage='' />,
                formData,
                modalData: { modalType: MODAL_TYPE.USER_STATUS, data: payload, transactionName: CONSTANTS_TRANSACTIONS.PURCHASE_ORDER },
                handleSubmit: (formData = {}) => {
                    if (formData.textBoxValue && formData.textBoxValue.length) {
                        addItemConfirmationModal(props, formData.textBoxValue, formData.statusColor, poData);
                    } else {
                        data.formData.submittedOnce = true;
                        pushModalToStack(data);
                    }
                },
                hideFooter: true
            };
            pushModalToStack(data);
        }
    }

    return (
        <div className={"custom-modal show"}>

            {templateName === 'Standard' && <StandardTemplate {...props} />}
            {templateName === 'Professional' && <Template2 {...props} />}

            <div className="modal-dialog">
                <div className="modal-content full-width">
                  
                    {poData.status !== 'Pending Approval' && poData.status !== 'Rejected'?
                    <div className=" button-group-action header-control">
                    <div className="small-btn-group">
                               {poPermissionsData.update &&  <Button style={{height:'38px'}} title={props.intl.formatMessage({ id: 'modal.txDetails.common.edit', defaultMessage: '' })}
                                    icon={<EditOutlined />}
                                    onClick={() => {
                                        props.popModalFromStack();
                                        props.pushModalToStack({
                                            modalBody: <StandardPurchaseOrder {...props} poDataEdit = {poData} supplierId={poData.supplierId} poMasterId={poData.poMasterId} update= {true}/>,
                                            width: '100%',
                                            hideTitle: true,
                                            hideFooter: true,
                                            keyboard: false ,
                                            wrapClassName: 'new-transaction-wrapper'
                                        })
                                        // props.history.push({
                                        //     pathname: '/admin/po-update',
                                        //     state: {
                                        //         supplierId: poData.supplierId,
                                        //         poMasterId: poData.poMasterId,
                                        //         update: true
                                        //     }
                                        // })
                                    }}
                                >
                                </Button>}
                                {poPermissionsData.create && <Button style={{height:'38px'}}
                                    title={props.intl.formatMessage({ id: 'modal.txDetails.common.clone', defaultMessage: '' })}
                                    icon={<CopyOutlined />}
                                    onClick={() => {
                                        props.popModalFromStack();
                                        props.pushModalToStack({
                                            modalBody: <StandardPurchaseOrder {...props}  poDataEdit = {poData} supplierId={poData.supplierId} poMasterId={poData.poMasterId} clone= {true}/>,
                                            width: '100%',
                                            hideTitle: true,
                                            hideFooter: true,
                                            keyboard: false ,
                                            wrapClassName: 'new-transaction-wrapper'
                                        })
                                        // props.history.push({
                                        //     pathname: '/admin/po-update',
                                        //     state: {
                                        //         supplierId: poData.supplierId,
                                        //         poMasterId: poData.poMasterId,
                                        //         clone: true
                                        //     }
                                        // })
                                    }}
                                >
                                </Button>}
                                {poPermissionsData.delete && <Button style={{height:'38px'}}
                                    title={props.intl.formatMessage({ id: 'modal.txDetails.common.delete', defaultMessage: '' })}
                                    icon={<DeleteOutlined />}
                                    onClick={() => {
                                        props.showModal({
                                            modalBody: <FormattedMessage id='common.delete.confirmation' defaultMessage='' /> ,
                                            handleSubmit: () => {
                                                props.popModalFromStack();
                                                props.deletePurchaseOrder({
                                                    supplierId: poData.supplierId,
                                                    poMasterId: poData.poMasterId,
                                                    relationshipId: poData.relationshipId
                                                }, props)
                                            }
                                        })
                                    }}
                                >
                                </Button>}
                            </div>
                            <div className="small-btn-group">

                                <Button style={{height:'38px'}}
                                    title={props.intl.formatMessage({ id: 'modal.txDetails.common.email', defaultMessage: '' })}
                                    icon={<MailOutlined />}
                                    onClick={() => {
                                        openEmailDrawer(props);

                                    }}
                                >
                                </Button>

                                <Button style={{height:'38px'}}
                                    title={props.intl.formatMessage({ id: 'modal.txDetails.common.downloadPDF', defaultMessage: '' })}
                                    icon={<FilePdfOutlined />}
                                    onClick={() => {
                                        generatePDF();
                                    }}
                                >
                                </Button>
                                <Button style={{height:'38px'}}
                                    title={props.intl.formatMessage({ id: 'modal.txDetails.common.print', defaultMessage: '' })}
                                    icon={<PrinterOutlined />}
                                    onClick={() => {
                                        printPdf();
                                    }}
                                >
                                </Button>
                            </div>
                            <div className="small-btn-group">
                                <Button style={{height:'38px'}}title={props.intl.formatMessage({ id: 'modal.txDetails.common.auditTrail', defaultMessage: '' })}
                                    icon={<HistoryOutlined />}
                                    onClick={() => {
                                        openAuditDrawer(props, poData);
                                    }}
                                >
                                </Button>
                            </div>
                            {(grnPermissionsData.create && poData.statusInboundDelivery !== 'received' && poData.statusInboundDelivery !== 'over.tolerance')?
                                <Button style={{height:'38px'}}
                                    onClick={() => {
                                        props.updateState && props.updateState({
                                            isTableRendered: false
                                        });
                                       // props.popModalFromStack();

                                    }}
                                    type='primary'
                                    title={props.intl.formatMessage({ id: 'modal.txDetails.purchaseOrder.convertToInboundDelivery', defaultMessage: '' })}
                                >
                                    <div onClick={()=>{
                                         props.popModalFromStack()
                                        props.pushModalToStack({
                                            modalBody: <StandardInboundDelivery {...props} 
                                            poMasterId= {poData.poMasterId}
                                                    supplierId= {poData.supplierId}
                                            />,
                                            width: '100%',
                                            hideTitle: true,
                                            hideFooter: true,
                                            keyboard: false ,
                                            wrapClassName: 'new-transaction-wrapper'
                                        })
                                    }}
                                        // to={
                                        //     {
                                        //         pathname: '/admin/inbound-delivery-create',
                                        //         state: {
                                        //             poMasterId: poData.poMasterId,
                                        //             supplierId: poData.supplierId
                                        //         }
                                        //     }
                                        // }
                                    >
                                        <FormattedMessage id='modal.txDetails.purchaseOrder.convertToID' defaultMessage='' />
                            </div>
                                </Button> : ''
                            }
                          {poPermissionsData.update ? <Button style={{height:'38px'}}
                                onClick={() => {
                                    updateUserStatus(props, poData);
                                }}
                            >
                               <FormattedMessage id='modal.txDetails.common.updateStatus' defaultMessage='' /> 
                            </Button> : "" }

                            <div >
                                <Dropdown style={{'width': '110px'}}
                                    placeholder="Template"
                                    items={['Standard', 'Professional']}
                                    onSelect={(selectedValue) => {
                                        props.updateModalState({
                                            templateName: selectedValue,
                                        });
                                    }}
                                ></Dropdown>
                            </div>
                    </div>
                        :
                        <div className="button-group-action">
                            {poData.status !== 'Rejected' && (props.userInfo || {}).relationshipEmployeeId === poData.approverEmpId ?
                                <div className=" button-group-action header-control">
                                    <Button style={{height:'38px'}} type="primary" onClick={() => { approvePO() }}>
                                    <FormattedMessage id='modal.txDetails.purchaseOrder.approvePO' defaultMessage='' /> 
                                </Button>
                                    <Button style={{height:'38px'}} type="primary" onClick={() => { handleDelegateApprover(); }}>
                                    <FormattedMessage id='modal.txDetails.common.delegateApprover' defaultMessage='' />
                            </Button>
                                    <Button style={{height:'38px'}} type="danger" onClick={() => { handleRejectApproval(); }}>
                                    <FormattedMessage id='modal.txDetails.common.reject' defaultMessage='' /> 
                            </Button>
                                </div>
                                :
                                <div>
                            </div>
}
                        </div>
                    }
                </div>
            </div>
        </div>
    );

}


const getTxTypeData = (row, type, toReturn) => {
    if (!row || !row.supplierPOCOATxList) return;
    let data = row.supplierPOCOATxList.find(tx => (tx.txType === type));
    if (data) {
        if (data[toReturn] && toReturn === 'amountPercent') {
            if (type === 'VAT') {
                return "VAT(" + data[toReturn] + "%)";
            }
            return "(" + data[toReturn] + "%)";
        } else if (data[toReturn] && toReturn === 'amount') {
            return (fixedDecimalNumber(data[toReturn]));
        } else {
            return ""
        }

    } else if (!data && toReturn === 'amount') {
        return "";
    }
    else {
        return "-";
    }
}


const calculateTaxRateList = (supplierPurchaseOrderDetailList, isPlaceOfSupplySameAsRelationship) => {
    let subtotal = 0;
    let totalDiscount = 0;
    let totalTax = 0;
    let itemLevelTaxes = []
    supplierPurchaseOrderDetailList.forEach(function (rowdata, index) {
        let totalAmountOfColumn = 0;
        if (rowdata.quantity && rowdata.anItemPurchasePrice) {
            let taxObj = {
                subtotal: 0,
                taxPercent: 0,
                taxAmount: 0
            }
            totalAmountOfColumn = rowdata.quantity * rowdata.anItemPurchasePrice;
            let discount = getTxTypeData(rowdata, 'discount', 'amount');
            if (discount) {
                totalAmountOfColumn = totalAmountOfColumn - discount;
                totalDiscount = totalDiscount + discount;
            }

            let tax1 = rowdata.supplierPOCOATxList.find(tx => (tx.txType === 'tax')) || {};
            taxObj.taxPercent = Number(tax1.amountPercent);
            taxObj.taxAmount = Number(fixedDecimalNumber(tax1.amount))
            totalTax = totalTax + taxObj.taxAmount;
            taxObj.taxName = tax1.taxName;
            //let tax2 = rowdata.supplierPOCOATxList.find(tx => (tx.txType === 'sgst'));
            //let tax3 = rowdata.supplierPOCOATxList.find(tx => (tx.txType === 'igst'));
            // let tax4 = rowdata.supplierPOCOATxList.find(tx => (tx.txType === 'vat'));
            //   if (isPlaceOfSupplySameAsRelationship && tax1 && tax2) {
            //       taxObj.taxPercent = Number(tax1.amountPercent) + Number(tax2.amountPercent);
            //       taxObj.taxAmount = Number(tax1.amount) + Number(tax2.amount);
            //       totalTax = totalTax + taxObj.taxAmount;
            //   }
            //   else if (!isPlaceOfSupplySameAsRelationship && tax3) {
            //       taxObj.taxPercent = Number(tax3.amountPercent);
            //       taxObj.taxAmount = Number(tax3.amount);
            //       totalTax = totalTax + taxObj.taxAmount;
            //   }
            //   else if (tax4) {
            //     taxObj.taxPercent = Number(tax4.amountPercent);
            //     taxObj.taxAmount = Number(tax4.amount);
            //     totalTax = totalTax + taxObj.taxAmount;
            // }
            if (taxObj.taxPercent) {
                taxObj.subtotal = Number(fixedDecimalNumber(totalAmountOfColumn));
                const availableTaxObj = find(itemLevelTaxes, { taxPercent: taxObj.taxPercent });
                if (availableTaxObj) {
                    itemLevelTaxes = filter(itemLevelTaxes, (ele) => {
                        if (ele.taxPercent === taxObj.taxPercent) {
                            ele.subtotal = ele.subtotal + taxObj.subtotal;
                            ele.taxAmount = ele.taxAmount + taxObj.taxAmount;
                        }
                        return true;
                    });
                } else {
                    taxObj.subtotal = Number(fixedDecimalNumber(taxObj.subtotal || 0))
                    itemLevelTaxes.push(taxObj);
                }
            }
            subtotal = subtotal + totalAmountOfColumn;
        }
    });
    return itemLevelTaxes;
}

const updateColumnEmptyCheck = (isColumnEmpty, poData, props) => {
    if (!poData) return isColumnEmpty;
    for (let i in poData.supplierPurchaseOrderDetailList) {
        let row = poData.supplierPurchaseOrderDetailList[i];
        if (row.itemName) { isColumnEmpty.product = false; };
        if (row.serviceName) { isColumnEmpty.service = false; };
        if (row.itemVariantName) { isColumnEmpty.variant = false; }
        if (row.description) { isColumnEmpty.description = false; }
        if (row.quantity) { isColumnEmpty.qty = false; }
        if (row.uomName) { isColumnEmpty.uomName = false; }
        if (row.anItemPurchasePrice) { isColumnEmpty.rate = false; }
        if (row.specialDiscount) { isColumnEmpty.specialDiscount = false; }
        if (row.hsnCode && props.companyInfo.countryName === 'India') { isColumnEmpty.hsn = false; }
        if (!isTxTypeEmpty(row, 'discount')) { isColumnEmpty.discount = false; }
        if (!isTxTypeEmpty(row, 'tax')) { isColumnEmpty.tax = false; }
        //if (!isTxTypeEmpty(row, 'sgst')) { isColumnEmpty.tax = false; }
        //if (!isTxTypeEmpty(row, 'igst')) { isColumnEmpty.tax = false; }
        //if (!isTxTypeEmpty(row, 'vat')) { isColumnEmpty.tax = false; }
        if (row.documentDetails && row.documentDetails.length) { isColumnEmpty.documentDetails = false; }
    }
    return isColumnEmpty;
}

const isTxTypeEmpty = (row, type) => {
    if (!row) return true;
    let data = row.supplierPOCOATxList.find(tx => (tx.txType === type));
    if (data && data.amount) {
        return false;
    }
    else {
        return true;
    }
}

export default PODetails;
