import React, { useState,Fragment,useRef, useEffect } from "react";
import Resizer from "react-image-file-resizer";
import config from '../../../config/environmentConfig';
import { base64Toblob, getMomentDateForUI} from '../../../utils';
import { Button, Col, DatePicker, Divider, Row } from "antd";
import { FormattedMessage } from "react-intl";
import { getAWSFileURL, getCurrencySymbol, getInitialsFromString } from "../../../utils";
import { TextBox } from "../../general/TextBox";
import { Dropdown, DropdownRef } from "../../general/Dropdown";
import { CustomUploader } from "../../general/CustomUploader";
import { CONSTANTS } from "../../../static/constants";
import { ImageCropper } from "../../general/ImageCropper";

const format = CONSTANTS.DISPLAY_DATE_FORMAT;

function Profile(props) {
  const {userInfo , companyInfo  ,employee} = props;
  const [profileIcon, updateProfileIcon] = useState();
  const [profilePicVisible, setProfilePicVisible] = useState(true);
  const cropperRef = useRef();
  const location = (companyInfo?.boLocationList||[]).find(item=>item?.locationType==="RelationshipBillingAddress")
    const values="";
    const useOuterClick = (callback) => {
      const innerRef = useRef();
      const callbackRef = useRef();
  
      // set current callback in ref, before second useEffect uses it
      useEffect(() => { // useEffect wrapper to be safe for concurrent mode
          callbackRef.current = callback;
      });
  
      useEffect(() => {
          document.addEventListener("click", handleClick);
          return () => document.removeEventListener("click", handleClick);
  
          // read most recent callback and innerRef dom node from refs
          function handleClick(e) {
              if (
                  innerRef.current &&
                  callbackRef.current &&
                  !innerRef.current.contains(e.target)
              ) {
                  if (((cropperRef.current &&
                      cropperRef.current.contains(e.target)) || e.target.contains(cropperRef.current)) ||
                      (e.target.classList.value.includes("ant-btn"))
                  ) {
                      return;
                  }
                  callbackRef.current(e);
              }
          }
      }, []); // no need for callback + innerRef dep
  
      return innerRef; // return ref; client can omit `useRef`
  }
  
  const innerRef = useOuterClick(e => {
      props.updateState({
          showUserDropdown: false
      })
  });
    const customProps = {
      type: CONSTANTS.TYPE_IMAGE,
      imgUrl: getAWSFileURL((props.companyInfo || {}).logoAwsObjectKey, config.BUCKET_NAME.BO_RELATIONSHIP) || profileIcon ,
      height: 20,
      width: 20,
      name: 'file',
      action: null,
      buttonName: <FormattedMessage id='button.uploadCustomerPO.label' defaultMessage='' />,
      multiple: false,
      onChange: (fileList) => {
          let file = {file: fileList[0]}; // some untracked chages detected. recieving file list insteed of file object
          if (file.file.originFileObj) {
              new Promise((resolve) => {
                  Resizer.imageFileResizer(
                      file.file.originFileObj,
                      230,
                      90,
                      "png",
                      100,
                      0,
                      (uri) => {
                          //updateProfileIconUrl(uri);
                          const fileData = base64Toblob(uri);

                          var reader = new FileReader();

                          reader.onload = function (e) {
                              props.showModal({
                                  title: <Fragment>
                                      <div className='ant-modal-title'>Crop Image</div>
                                  </Fragment>,
                                  modalBody: <ImageCropper cropperRef={cropperRef} onClick={(e) => {
                                  }} handleSubmit={(newUrl) => {
                                      props.uploadImage({
                                          objectKey: props.companyInfo.logoAwsObjectKey,
                                          bucketName: config.BUCKET_NAME.BO_RELATIONSHIP,
                                          file: base64Toblob(newUrl)
                                      });
                                      updateProfileIcon(newUrl);
                                          
                                  }}{...props} url={e.target.result} />,
                                  width: 800,
                                  hideFooter: true,
                                  wrapClassName: 'Upload-profile-modal'
                              })
                          }
          
                          reader.readAsDataURL(fileData); // convert to base64 string

                      },
                      "base64"
                  );
              });
          }
          //   file.file.originFileObj = file.file.originFileObj || {};
          //   file.file.originFileObj.name = `${file.file.originFileObj.name} (${bytesToSize(file.file.originFileObj.size)})`;
          //   this.setState({
          //     fileToUpload: file.file.originFileObj
          //   })
      }
  }

   const handleSubmit =()=>{
      props.createEmployee(employee , props)
   }
    return (
        <div className="account-setting">
<div className="store-model-container cus-space">
   
   <div className="store-main-container">
   

    <div>
    <div className="account-heading">
    <p className="heading">My Profile</p>
    <p className="sub-heading">
    Manage your personal Informations
    </p>
    <div className="personal-information">
       <div className="name">
      
       <div ref={innerRef}>
                    <CustomUploader {...customProps} />
                </div>
       </div>
       <div>
       <b>{employee?.fullName}</b>
    <p className="sub-heading">
   {employee?.emailAddress}
    </p>
       </div>
    </div>
    </div>
    <div className="">
    <Row gutter={[16,16]} className="account-information">
      
      <Col span={12}>
        <div className="i-label">First Name</div>
        <TextBox
          placeholder="Enter FirstName"
          type="text"
          value={employee?.firstName||""}
          maxLength={50}
          countStyle={{ top: "40px" }}
          onChange ={(e)=>{
             const val = employee;
             employee.firstName = e.target.value;
             props.updateState({
              employee: val,
             })
          }}
        />
      </Col>
      <Col span={12}>
        <div className="i-label">Last Name</div>
        <TextBox
          placeholder="Enter LastName"
          type="text"
          value={employee?.lastName||""}
          maxLength={50}
          countStyle={{ top: "40px" }}
          onChange ={(e)=>{
            const val = employee;
             employee.lastName = e.target.value;
             props.updateState({
              employee: val,
             })
          }}
        />
      </Col>
      <Col span={12}>
        <div className="i-label">Country</div>
        <TextBox
          placeholder="Enter CountryName"
          type="text"
          value={companyInfo?.countryName||""}
          maxLength={50}
          countStyle={{ top: "40px" }}
          onChange ={(e)=>{
            
          }}
        />
      </Col>
      <Col span={12}>
        <div className="i-label">Phone Number</div>
        


<Row>
<Col style={{width:"15%"}} >
<DropdownRef
style={{width:"100%"}}
items={
(props.countries || []).filter((el) => {
  return el.countryCallingCode != null;
}) || []
}
optionKeyName="countryId"
valueKeyName="countryCallingCode"
defaultValue={companyInfo?.countryCallingCode}
className="currency-tags"
onSelect={(selectedValue, option) => {
const selectedCountry = props.countries.find((el) => el.countryId === selectedValue);



}}
/>
</Col>
<Col style={{width:"85%"}}>
<TextBox
className="textbox-pd"
maxLength={10}
countStyle={{ top: "10px" }}
value={companyInfo?.phoneNumber}
placeholder={'Enter Mobile No.'}
onChange={(e) => {
const re = /^[0-9\b]+$/;
const val = props.additionalValue;
if (e.target.value === "" || re.test(e.target.value)) {
  
}
}}
/>
</Col>
</Row>




        
         
      </Col>
      <Col span={12}>
        <div className="i-label">Location</div>
        <TextBox
          placeholder="Enter Location"
          type="text"
   
          value={`${location?.streetAddress1},${location?.cityName} ${location?.stateName}`||""}
          maxLength={50}
          countStyle={{ top: "40px" }}
          onChange ={(e)=>{
            const val = props.additionalValue;
           
           
          }}
        />
      </Col>

      <Col span={12}>
        <div className="i-label">Birthday</div>
        <DatePicker onChange = {(date, dateString) => {
  console.log(date, dateString);
}} />
        
      </Col>
    </Row>
    <div className="">
     <Button type="primary" onClick={()=>{
      handleSubmit();
     }} >Save Changes</Button>
  </div>

  <div className="divider mt20"></div>
    </div>
    <div className="mt10">
    <b >Delete Account</b>
    <p className="sub-heading">
    Please be aware that deleting your account will permanently remove all associated information, including tasks, events, projects, and notes. This action cannot be undone, so please consider carefully before proceeding.
    </p>
    <div className="account-info mt15">
       <b>Delete My Account</b>
    </div>
    </div>

    <div className="mt20">
    <b >Account Activation</b>
   
    <div className="account-info">
    Your account has been deactivated. Please contact the admin to reactivate it.
    </div>
    </div>
    </div>
   </div>
   

   
   


</div>
        </div>
        
    )
}
export default Profile;