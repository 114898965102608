import React, { Component, Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { CONSTANTS, ICONS, MODULE_CODED_VALUES, PERMISSION_VALUES } from '../../../../static/constants';
import LogActivity from '../../../../containers/customer/Listing/LogActivity';
// import { CustomUploader } from '../../../general/CustomUploader';
import { checkACLPermission, getMomentDateForUIReadOnly, getPermissionData, sortTable } from '../../../../utils';
import { Menu, Dropdown, Skeleton, Button, Empty } from 'antd';

// import { AgGridReact } from 'ag-grid-react';
// import ReactHtmlParser from 'react-html-parser';

const format = CONSTANTS.DISPLAY_DATE_FORMAT;

class Activity extends Component {

  constructor(props){
    super(props);
  }
  state = {
    buttonDropDown: false
  };

  toggleDropdown = () => {
    const { buttonDropDown } = this.state;
    this.setState({
      buttonDropDown: !buttonDropDown
    })
  };

  render() {
    const { permissions, updateState, dir = 0 } = this.props;
    const primaryPerm = (permissions && permissions.primary) || [];
    const customerTaskList = this.props.customerTaskList || [];

    const customerActivityPermissions =  getPermissionData(primaryPerm ,  MODULE_CODED_VALUES.CUSTOMER_ACTIVITY);
  
    // const { buttonDropDown } = this.state;
    // const { toggleDropdown } = this;

    // const customProps = {
    //   name: 'file',
    //   action: null,
    //   buttonName: <FormattedMessage id='uploadDocument.button' defaultMessage='' />,
    //   onChange: (file) => {
    //     file.file.originFileObj = file.file.originFileObj || {};
    //     file.file.originFileObj.name = `${file.file.originFileObj.name} (${bytesToSize(file.file.originFileObj.size)})`;
    //     this.setState({
    //       fileToUpload: file.file.originFileObj
    //     })
    //   }
    // }

    // const agGridStyle = {
    //   height: '100%',
    //   width: '100%'
    // };

    // const onFirstDataRendered = (params) => {
    //   params.api.sizeColumnsToFit();
    // }
    // const getRowHeight = (params) => {
    //   return 36;
    // }

    // const addItemConfirmationModal = (props, text, modalType) => {
    //   const { showModal, companyInfo } = props;
    //   const labelName = getModalTitle("", modalType);
    //   const modalBody = <span>
    //     <FormattedMessage
    //       id='addItem.confirmation'
    //       values={{ value: `${text} to ` }}
    //       defaultMessage=''
    //     />
    //     {labelName} ?
    //         </span>;

    //   let addFunc;

    //   switch (modalType) {
    //     case MODAL_TYPE.ACTIVITY_STATUS: {
    //       addFunc = props.addActivityStatus;
    //       break;
    //     }
    //     case MODAL_TYPE.ACTIVITY_PRIORITY: {
    //       addFunc = props.addActivityPriorities;
    //       break;
    //     }
    //     case MODAL_TYPE.ACTIVITY_TYPE: {
    //       addFunc = props.addActivityTypes;
    //       break;
    //     }
    //     default: {
    //       addFunc = () => { }
    //     }
    //   }

    //   const modalData = {
    //     modalBody,
    //     handleSubmit: () => {
    //       addFunc({ text: text, relationshipId: companyInfo.relationshipId })
    //       props.hideModal();
    //     },
    //   };
    //   showModal(modalData);
    // };

    // const getModalTitle = (newTextObj, modalType) => {
    //   let title;
    //   switch (modalType) {
    //     case MODAL_TYPE.ACTIVITY_STATUS: {
    //       title = <FormattedMessage id='addItem.text.activityStatus' defaultMessage='' />;
    //       break;
    //     }
    //     case MODAL_TYPE.ACTIVITY_PRIORITY: {
    //       title = <FormattedMessage id='addItem.text.activityPriority' defaultMessage='' />;
    //       break;
    //     }
    //     case MODAL_TYPE.ACTIVITY_TYPE: {
    //       title = <FormattedMessage id='addItem.text.activityType' defaultMessage='' />;
    //       break;
    //     }
    //     default:
    //       title = <FormattedMessage id='signup.confirmation.title' defaultMessage='' />;
    //   }
    //   return title;
    // };

    // const handleNewItemAddition = (props, payload, modalType) => {
    //   const formData = payload.formData;
    //   const { showModal } = props;

    //   if (formData.textBoxValue && formData.textBoxValue.length && !payload.existsInData) {
    //     addItemConfirmationModal(props, formData.textBoxValue, modalType);
    //   } else {
    //     const data = {
    //       title: getModalTitle(payload, modalType),
    //       formData,
    //       modalData: { modalType, data: payload },
    //       handleSubmit: (formData = {}) => {
    //         if (formData.textBoxValue && formData.textBoxValue.length) {
    //           addItemConfirmationModal(props, formData.textBoxValue, modalType);
    //         } else {
    //           data.formData.submittedOnce = true;
    //           showModal(data);
    //         }
    //       }
    //     };
    //     showModal(data);
    //   }
    // };

    // const deleteClickHandler = (props, modalType, payload) => {
    //   const { showModal, companyInfo } = props;
    //   const modalBody =
    //     <FormattedMessage
    //       id='deleteItem.confirmation'
    //       defaultMessage=''
    //       values={{ value: payload.text }}
    //     />;

    //   let deleteFunc;
    //   switch (modalType) {
    //     case MODAL_TYPE.ACTIVITY_STATUS: {
    //       deleteFunc = props.deleteActivityStatus;
    //       break;
    //     }
    //     case MODAL_TYPE.ACTIVITY_PRIORITY: {
    //       deleteFunc = props.deleteActivityPriorities;
    //       break;
    //     }
    //     case MODAL_TYPE.ACTIVITY_TYPE: {
    //       deleteFunc = props.deleteActivityTypes;
    //       break;
    //     }
    //     default: {
    //       deleteFunc = () => { }
    //     }
    //   }

    //   const modalData = {
    //     modalBody,
    //     handleSubmit: () => { deleteFunc({ id: payload.id, relationshipId: companyInfo.relationshipId }) },
    //   };
    //   showModal(modalData);
    // };

    // const updateActivity = (payload) => {

    //   const modalBody =
    //     <FormattedMessage
    //       id='updateActivity.confirmation'
    //       defaultMessage=''
    //     />;
    //   const modalData = {
    //     modalBody,
    //     handleSubmit: () => { this.props.createUpdateActivity(payload) },
    //   };
    //   this.props.showModal(modalData);
    // }

    const deleteActivity = (payload) => {
      const modalBody =
        <FormattedMessage
          id='deleteActivity.confirmation'
          defaultMessage=''
        />;
      const modalData = {
        modalBody,
        handleSubmit: () => { this.props.deleteActivity(payload) },
      };
      this.props.showModal(modalData);
    }

    // const columnDefs = [
    //   // {
    //   //   headerName: "#",
    //   //   resizable: true,
    //   //   cellRendererFramework: function (link) {
    //   //     return <div>
    //   //       {link.rowIndex + 1}
    //   //     </div>
    //   //   }
    //   // },
    //   // {
    //   //     headerName: "Activity",
    //   //     field: "activityDetails", 
    //   //     resizable: true,
    //   //     editable: true,
    //   //     cellEditor: 'customTextEditor',
    //   // },
    //   {
    //     headerComponentFramework: () => {
    //       return <FormattedMessage id='activity.activityType' defaultMessage='' />;
    //     },
    //     field: "activityPriorityName",
    //     resizable: true,
    //     width: 130,
    //     editable: true,
    //     cellEditor: 'customDropDownEditor',
    //     cellEditorParams: (obj) => {
    //       return {
    //         lastColumnCell: false,
    //         items: this.props.activityTypes,
    //         optionKeyName: 'activityTypeId',
    //         valueKeyName: 'activityTypeName',
    //         canAddNew: true,
    //         canDelete: true,
    //         deleteClickHandler: (payload) => {
    //           deleteClickHandler(this.props, MODAL_TYPE.ACTIVITY_TYPE, payload);
    //         },
    //         onAddNewClick: (payload) => {
    //           payload.formData = {
    //             textBoxValue: payload.textEntered,
    //             submittedOnce: false
    //           }
    //           handleNewItemAddition(this.props, payload, MODAL_TYPE.ACTIVITY_TYPE)
    //         },
    //         onSelect: (selectedValue) => {
    //           //obj.node.setDataValue(obj.colDef.field, selectedValue);
    //         },
    //       }
    //     },
    //   },
    //   {
    //     headerComponentFramework: () => {
    //       return <FormattedMessage id='common.description' defaultMessage='' />;
    //     },
    //     field: "description",
    //     resizable: true,
    //     editable: true,
    //     cellEditor: 'customTextEditor',
    //     width: 300,
    //     cellRendererFramework: (params) => {
    //       setTimeout(() => {
    //           if (params.reactContainer && params.reactContainer.scrollHeight > 30 && params.reactContainer.scrollHeight >= (params.node.data.minHeight || 0)) {
    //               params.eParentOfValue.style.height = 'inherit';
    //               params.eParentOfValue.style.height = params.reactContainer.scrollHeight;
    //               params.node.data.maxHeight = params.reactContainer.scrollHeight;
    //               params.node.setRowHeight(params.reactContainer.scrollHeight);
    //               params.api && params.api.onRowHeightChanged();
    //           }
    //       }, 200)
    //       return ReactHtmlParser(params.data.description)
    //   },
    //   },
    //   {
    //     headerComponentFramework: () => {
    //       return <FormattedMessage id='common.ref' defaultMessage='' />;
    //     },
    //     field: "refNumber",
    //     resizable: true,
    //     editable: true,
    //     cellEditor: 'customTextEditor',
    //     width: 110,
    //   },

    //   {
    //     headerComponentFramework: () => {
    //       return <FormattedMessage id='common.status' defaultMessage='' />;
    //     },
    //     field: "activityStatusName",
    //     resizable: true,
    //     editable: true,
    //     cellEditor: 'customDropDownEditor',
    //     cellEditorParams: (obj) => {
    //       return {
    //         lastColumnCell: false,
    //         items: this.props.activityStatus,
    //         optionKeyName: 'activityStatusId',
    //         valueKeyName: 'activityStatusName',
    //         canAddNew: true,
    //         canDelete: true,
    //         deleteClickHandler: (payload) => {
    //           deleteClickHandler(this.props, MODAL_TYPE.ACTIVITY_STATUS, payload);
    //         },
    //         onAddNewClick: (payload) => {
    //           payload.formData = {
    //             textBoxValue: payload.textEntered,
    //             submittedOnce: false
    //           }
    //           handleNewItemAddition(this.props, payload, MODAL_TYPE.ACTIVITY_STATUS)
    //         },
    //         onSelect: (selectedValue) => {
    //           //obj.node.setDataValue(obj.colDef.field, selectedValue);
    //         },
    //       }
    //     },
    //     width: 100,
    //   },
    //   {
    //     headerComponentFramework: () => {
    //       return <FormattedMessage id='common.priority' defaultMessage='' />;
    //     },
    //     resizable: true,
    //     field: "activityPriorityName",
    //     editable: true,
    //     cellEditor: 'customDropDownEditor',
    //     cellEditorParams: (obj) => {
    //       return {
    //         lastColumnCell: false,
    //         items: this.props.activityPriorities,
    //         optionKeyName: 'activityPriorityId',
    //         valueKeyName: 'activityPriorityName',
    //         canAddNew: true,
    //         canDelete: true,
    //         deleteClickHandler: (payload) => {
    //           deleteClickHandler(this.props, MODAL_TYPE.ACTIVITY_PRIORITY, payload);
    //         },
    //         onAddNewClick: (payload) => {
    //           payload.formData = {
    //             textBoxValue: payload.textEntered,
    //             submittedOnce: false
    //           }
    //           handleNewItemAddition(this.props, payload, MODAL_TYPE.ACTIVITY_PRIORITY)
    //         },
    //         onSelect: (selectedValue) => {
    //           //obj.node.setDataValue(obj.colDef.field, selectedValue);
    //         },
    //       }
    //     },
    //     width: 100,
    //   },
    //   {
    //     headerComponentFramework: () => {
    //       return <FormattedMessage id='activity.assignedTo' defaultMessage='' />;
    //     },
    //     resizable: true,
    //     width: 130,
    //     field: "assignedUserName",
    //     editable: true,
    //     cellEditor: 'customDropDownEditor',
    //     cellEditorParams: (obj) => {
    //       return {
    //         lastColumnCell: false,
    //         items: this.props.allEmployee,
    //         optionKeyName: 'relationshipEmployeeId',
    //         valueKeyName: 'fullName',
    //         onSelect: (selectedValue, params) => {
    //           //const employee = find((this.props.allEmployee || []), { relationshipEmployeeId: Number(params.key || 0) }) || {};
    //           //obj.node.setDataValue(obj.colDef.field, employee.fullName);
    //         },
    //       }
    //     },
    //   },
    //   {
    //     headerComponentFramework: () => {
    //       return <FormattedMessage id='activity.dueDate' defaultMessage="" />;
    //     },
    //     resizable: true,
    //     width: 130,
    //     // cellRendererFramework: (link) => <div>
    //     //     {moment(new Date(link.data.activityDueDate)).format(format)}
    //     // </div>
    //     cellRendererFramework: (params) =>
    //       <div>
    //         {params.node.data.isToEdit &&
    //           <div>
    //             <DatePicker
    //               key={`${getMomentDateForUI({date: new Date(params.data.activityDueDate ? params.data.activityDueDate : new Date())})}`}
    //               defaultValue={getMomentDateForUI({date: new Date(params.data.activityDueDate ? params.data.activityDueDate : new Date())})}
    //               onChange={(dateObj) => {
    //                 this.gridApi.getDisplayedRowAtIndex(params.node.rowIndex).data.activityDueDate = dateObj.format(CONSTANTS.BACKEND_FORMAT);
    //                 this.gridApi.refreshCells({ force: true });
    //               }} />
    //           </div>
    //         }
    //         {!params.node.data.isToEdit &&
    //           <div>
    //             {params.data.activityDueDate && getMomentDateForUIReadOnly({date: new Date(params.data.activityDueDate), format})}
    //           </div>
    //         }
    //       </div>
    //   },
    //   {
    //     headerComponentFramework: () => {
    //       return <FormattedMessage id='activity.closeDate' defaultMessage="" />;
    //     },
    //     resizable: true,
    //     width: 130,
    //     cellRendererFramework: (params) =>
    //       <div>
    //         {params.node.data.isToEdit &&
    //           <div>
    //             <DatePicker
    //               key={`${getMomentDateForUI({date: new Date(params.data.activityClosedDate ? params.data.activityClosedDate : new Date())})}`}
    //               defaultValue={getMomentDateForUI({date: new Date(params.data.activityClosedDate ? params.data.activityClosedDate : new Date())})}
    //               onChange={(dateObj = {}) => {
    //                 this.gridApi.getDisplayedRowAtIndex(params.node.rowIndex).data.activityClosedDate = dateObj.format(CONSTANTS.BACKEND_FORMAT);
    //                 this.gridApi.refreshCells({ force: true });
    //               }} />
    //           </div>
    //         }
    //         {!params.node.data.isToEdit &&
    //           <div>
    //             {params.data.activityClosedDate && getMomentDateForUIReadOnly({date: new Date(params.data.activityClosedDate), format})}
    //           </div>
    //         }
    //       </div>
    //   },
    //   {
    //     headerComponentFramework: () => {
    //       return <FormattedMessage id='common.dateCreated' defaultMessage="" />;
    //     },
    //     resizable: true,
    //     width: 130,
    //     cellRendererFramework: (link) => <div>
    //       {getMomentDateForUIReadOnly({date: new Date(link.data.dateCreated), format})}
    //     </div>
    //   },
    //   {
    //     headerComponentFramework: () => {
    //       return <FormattedMessage id='activity.documents' defaultMessage="" />;
    //     },
    //     resizable: true,
    //     width: 170,
    //     cellRendererFramework: (params) => <div>
    //       {params.node.data.isToEdit &&
    //         <div className="remarks-title">
    //           <CustomUploader {...customProps} />
    //         </div>
    //       }
    //       {!params.node.data.isToEdit &&
    //         <div>
    //         </div>
    //       }
    //     </div>
    //   },
    //   {
    //     resizable: true,
    //     width: 120,
    //     cellRendererFramework: (params) => <div>
    //       {params.node.data.isToEdit &&
    //         <div className="table-cell-action">
    //           <div className='cursor-pointer' onClick={() => {
    //             updateActivity(params.data);
    //             this.gridApi.getDisplayedRowAtIndex(params.node.rowIndex).data.isToEdit = false;
    //             this.gridApi.refreshCells({ force: true });
    //           }} >
    //             <i className="fa fa-save" />
    //           </div>
    //           <div className='cursor-pointer' onClick={() => {
    //             params.api.stopEditing(true);
    //             this.gridApi.getDisplayedRowAtIndex(params.node.rowIndex).data.isToEdit = false;
    //             this.gridApi.refreshCells({ force: true });
    //           }} >
    //             <i className="fa fa-ban" />
    //           </div>
    //         </div>
    //       }

    //       {!params.node.data.isToEdit &&
    //         <div className="table-cell-action" >
    //        { checkACLPermission(primaryPerm, MODULE_CODED_VALUES.CUSTOMER_ACTIVITY, PERMISSION_VALUES.UPDATE) &&  <span className='cursor-pointer' onClick={() => {
    //             // this.gridApi.getDisplayedRowAtIndex(params.node.rowIndex).data.isToEdit = true;
    //             // this.gridApi.refreshCells({ force: true });
    //           const modalData = {
    //             title: <React.Fragment>
    //               <div className='modal-title'> <FormattedMessage id='activity.title' /></div>
    //               <div className="modal-sub-title"> {this.props.customerName}</div>
    //             </React.Fragment>,
    //             modalBody: <LogActivity
    //               customerId={this.props.customerId}
    //               customerName={this.props.customerName}
    //               activityData={params.node.data}
    //             />,
    //             wrapClassName: 'log-activity-modal',
    //             width: 500,
    //             hideFooter: true
    //           };
    //           this.props.pushModalToStack(modalData);
    //         }}>
    //             <i className="fa fa-edit" />
    //           </span>}
    //           {checkACLPermission(primaryPerm, MODULE_CODED_VALUES.CUSTOMER_ACTIVITY, PERMISSION_VALUES.DELETE) && <span className='cursor-pointer' onClick={() => deleteActivity(params.data)}>
    //             <i className="fa fa-remove" />
    //           </span>}
    //         </div>}
    //     </div>
    //   },
    // ]

    // const columnDef = [
    //   {
    //     title: <FormattedMessage id='activity.activityType' defaultMessage='' />,
    //     index: 'activityTypeName',
    //     key: 'activityTypeName',
    //     render: (text, record, index)=>(
    //         <div>{record.activityTypeName}</div>
    //     )
    //   },
    //   {
    //     title: <FormattedMessage id='common.description' defaultMessage='' />,
    //     index: 'description',
    //     key: 'description',
    //     render: (text, record, index)=>(
    //         <div>{record.description}</div>
    //     ),
    //     width: '15%'
    //   },
    //   {
    //     title: <FormattedMessage id='common.ref' defaultMessage='' />,
    //     index: 'refNumber',
    //     key: 'refNumber',
    //     render: (text, record, index)=>(
    //         <div>{record.refNumber}</div>
    //     )
    //   },
    //   {
    //     title: <FormattedMessage id='common.status' defaultMessage='' />,
    //     index: 'activityStatusName',
    //     key: 'activityStatusName',
    //     render: (text, record, index)=>(
    //         <div>{record.activityStatusName}</div>
    //     )
    //   },
    //   {
    //     title: <FormattedMessage id='common.priority' defaultMessage='' />,
    //     index: 'activityPriorityName',
    //     key: 'activityPriorityName',
    //     render: (text, record, index)=>(
    //         <div>{record.activityPriorityName}</div>
    //     )
    //   },
    //   {
    //     title: <FormattedMessage id='activity.assignedTo' defaultMessage='' />,
    //     index: 'assignedUserName',
    //     key: 'assignedUserName',
    //     render: (text, record, index)=>(
    //         <div>{record.assignedUserName}</div>
    //     )
    //   },
    //   {
    //     title: <FormattedMessage id='activity.dueDate' defaultMessage="" />,
    //     index: 'activityDueDate',
    //     key: 'activityDueDate',
    //     render: (text, record, index)=>(
    //         <div>{record.activityDueDate && getMomentDateForUIReadOnly({date: new Date(record.activityDueDate), format})}</div>
    //     )
    //   },
    //   {
    //     title: <FormattedMessage id='activity.closeDate' defaultMessage="" />,
    //     index: 'activityClosedDate',
    //     key: 'activityClosedDate',
    //     render: (text, record, index)=>(
    //       <div>
    //         {record.activityClosedDate && getMomentDateForUIReadOnly({date: new Date(record.activityClosedDate), format})}
    //       </div>
    //     )
    //   },
    //   {
    //     title: <FormattedMessage id='common.dateCreated' defaultMessage="" />,
    //     index: 'dateCreated',
    //     key: 'dateCreated',
    //     render: (text, record, index)=>(
    //       <div>
    //         {getMomentDateForUIReadOnly({date: new Date(record.dateCreated), format})}
    //       </div>
    //     )
    //   },
    //   // {
    //   //   title: <FormattedMessage id='activity.documents' defaultMessage="" />,
    //   //   index: 'documents',
    //   //   key: 'documents',
    //   //   render: (text, record, index)=>(
    //   //     <div>
    //   //     </div>
    //   //   )
    //   // },
    //   {
    //     title: <FormattedMessage id='customer.listing.header.action' defaultMessage="" />,
    //     index: 'action',
    //     key: 'action',
    //     render: (text, record, index)=>(
    //       <div className="table-cell-action" >
    //         <span className='cursor-pointer' onClick={() => {
    //           handleAddUpdateActivity(record)}}>
    //           <i className="fa fa-edit" />
    //         </span>
    //         <span className='cursor-pointer' onClick={() => deleteActivity(record)}>
    //             <i className="fa fa-remove" />
    //         </span>
    //       </div>
    //     )
    //   },
    // ]

    // const onGridReady = (params) => {
    //   this.gridApi = params.api;
    // }

    

    const handleAddUpdateActivity = (payload) => {
      const modalData = {
        title: <React.Fragment>
          <div className='modal-title'>
            {payload.activityTypeName ? <FormattedMessage id='activity.edit.title' defaultMessage="" /> : <FormattedMessage id='activity.title' defaultMessage="" />}
          </div>
          <div className="modal-sub-title">
            {this.props.customerName}
          </div>
        </React.Fragment>,
        modalBody: <LogActivity
          customerId={this.props.customerId}
          customerName={this.props.customerName}
          formData={payload}
        />,
        wrapClassName: 'log-activity-modal',
        width: 500,
        hideFooter: true
      };
      this.props.pushModalToStack(modalData);
    }
    const actionMenu = (e) => {
      return (
        <Menu className="row-action-dropdown">

          <Menu.Item key="0" value="title">
            Action
          </Menu.Item>

          <Menu.Divider />

          <Menu.Item key="1" value="edit" onClick={() => {
            // handleNewContactAddition(e, this.props);
            handleAddUpdateActivity(e)
            // this.props.updateState({
            //   customerTaskDrawerVisible: true,
            //   customerTask: e
            // })
          }}
          >
            <i className={ICONS.EDIT} /> Edit
          </Menu.Item>

          <Menu.Item key="2" onClick={() => {
            deleteActivity(e)
          }
          }>
            <i className={ICONS.DELETE} /> Delete
          </Menu.Item>

        </Menu >
      )
    };
    const sortColumn = (e) => {
      sortTable(
        document.getElementById("customers-table"),
        e.target.cellIndex,
        1 - dir
      );
      updateState({ dir: 1 - dir });
    };
    return (
      <Fragment>

        {/* <div className="view-top-bar">
            <div className="top-view-left">
              <div className="page-heading">Activity</div>
              <div className="vertical-sep" />
              <PageBreadcrumb breadCrumbList={breadCrumbList} />
            </div>
            <div className="top-view-right">
              {checkACLPermission(
                primaryPerm,
                MODULE_CODED_VALUES.CUSTOMER_ACTIVITY,
                PERMISSION_VALUES.CREATE
              ) && (
                <button
                  className="create-action-btn"
                  onClick={() => {
                    handleAddUpdateActivity({});
                  }}
                >
                  <i className={ICONS.ADD} /> <FormattedMessage id="activity.title" defaultMessage="" />
                </button>
              )}
            </div>
          </div> */}

        {/* <div className="right-align pb10">
          <div className="action-dropdown-btn">
            {checkACLPermission(primaryPerm, MODULE_CODED_VALUES.CUSTOMER_ACTIVITY, PERMISSION_VALUES.CREATE) && <span className={buttonDropDown ? "arrow-down active" : "arrow-down"} onClick={toggleDropdown}>
              <i className="fa fa-angle-down" />
            </span>}

            <ul className="dropdown">
              <li
                className="option-list"
                onClick={() => {
                  const modalData = {
                    title: <React.Fragment>
                      <div className='modal-title'>
                      <FormattedMessage id='activity.title' defaultMessage="" />
                                            </div>
                      <div className="modal-sub-title">
                        {this.props.customerName}
                      </div>
                    </React.Fragment>,
                    modalBody: <LogActivity
                      customerId={this.props.customerId}
                      customerName={this.props.customerName}
                    />,
                    wrapClassName: 'log-activity-modal',
                    width: 500,
                    hideFooter: true
                  };
                  this.props.pushModalToStack(modalData);
                }}
              >
                <i className="fa fa-plus" />
                <FormattedMessage id='activity.title' defaultMessage="" />
                            </li>
              <li className="option-list">
                <i className="fa fa-file-pdf-o" />
                <FormattedMessage id='activity.dounloadPdf' defaultMessage="" />
                            </li>
              <li className="option-list">
                <i className="fa fa-file-excel-o" />
                <FormattedMessage id='activity.exportExcel' defaultMessage="" />
                            </li>
              <li className="option-list">
                <i className="fa fa-envelope" />
                <FormattedMessage id='activity.email' defaultMessage="" />
                            </li>
            </ul>
          </div>
        </div> */}
        {/* <div className='agGridWrapper'>
          <div className="ag-theme-balham" style={agGridStyle} >
            <AgGridReact
              columnDefs={columnDefs}
              rowData={customerTaskList || []}
              rowDragManaged={true}
              domLayout={"autoHeight"}
              animateRows={true}
              onGridSizeChanged={onFirstDataRendered}
              getRowHeight={getRowHeight}
              editType={'fullRow'}
              onGridReady={onGridReady}
            >
            </AgGridReact>
          </div>
        </div> */}
        {/* <Table dataSource={customerTaskList} columns={columnDef} style={{ width: '100%' }} /> */}
        <div className="view-container">
          <div className="view-container-actions">
          <div className="left-actions">
            <div className="table-heading">
              Task List
            </div>
              {
                (customerTaskList || []).length ? <>
                  <div className="vertical-sep" />
                  <div>{customerTaskList?.length}</div>
                </> : ''
              }
            </div>
          <div className="right-actions">

            <>
            {
              customerActivityPermissions?.create ? <Button
              className="create-action-btn"
              style={{backgroundColor:"#1cb961", color: '#ffffff'}}
              onClick={() => {
                // handleAddUpdateActivity({});
                this.props.updateState({ customerTaskDrawerVisible: true })
              }}
              >
                  <i className={ICONS.ADD} /> <FormattedMessage id="button.create.label" defaultMessage="" />
                </Button>: ""
            }
              
            </>

          </div>
              </div>
     
    
            {/* <div className="view-container-actions">
              <div className="left-actions">
                <div className="table-heading"></div>
                {(customerTaskList || []).length ? 
                  <>
                    <div className="vertical-sep" />
                    <div>{customerTaskList.length}</div>
                  </>
                  :''
                }
              </div>
              <div className="right-actions">
                {/* duplicate create button in case viewed in profile view
               
              </div>
            </div> */}
            <Skeleton loading={this.props.listLoading} active paragraph={{ rows: 16 }}>

              <div className="table-container table-h-profile">
                <table>
                  <thead>
                    <tr>
                      <th onClick={sortColumn}>
                        Task Type
                      </th>
                      <th onClick={sortColumn}>
                        <FormattedMessage
                          id="common.description"
                          defaultMessage=""
                        />
                      </th>
                      <th onClick={sortColumn}>
                        <FormattedMessage id="common.ref" defaultMessage="" />
                      </th>

                      <th onClick={sortColumn}>
                        <FormattedMessage
                          id="common.priority"
                          defaultMessage=""
                        />
                      </th>

                      <th onClick={sortColumn}>
                        <FormattedMessage
                          id="activity.dueDate"
                          defaultMessage=""
                        />
                      </th>
                      <th onClick={sortColumn}>
                        <FormattedMessage
                          id="activity.closeDate"
                          defaultMessage=""
                        />
                      </th>
                      <th onClick={sortColumn}>
                        <FormattedMessage
                          id="common.dateCreated"
                          defaultMessage=""
                        />
                      </th>
                      <th onClick={sortColumn}>
                        <FormattedMessage
                          id="activity.assignedTo"
                          defaultMessage=""
                        />
                      </th>
                      <th onClick={sortColumn}>
                        <FormattedMessage id="common.status" defaultMessage="" />
                      </th>
                      {
                        (customerActivityPermissions?.update || customerActivityPermissions?.delete) ? <th className='text-center'>
                        <FormattedMessage
                          id="customer.listing.header.action"
                          defaultMessage=""
                        />
                      </th>: ""
                      }
                      
                    </tr>
                  </thead>
                  <tbody>
                    {(customerTaskList || []).length > 0 ?
                      //( customerTaskList.sort((a, b) => (a.dateCreated < b.dateCreated ? 1 : -1))
                      (customerTaskList?.map((rowData, index) => (
                        <tr key={index}>
                          <td>{rowData.activityTypeName || <div className="empty-data-box"></div>}</td>
                          <td>{rowData.description || <div className="empty-data-box"></div>}</td>
                          <td>{rowData.refNumber || <div className="empty-data-box"></div>}</td>
                          <td>{rowData.activityPriorityName || <div className="empty-data-box"></div>}</td>

                          <td>
                            {rowData.activityDueDate &&
                              getMomentDateForUIReadOnly({
                                date: new Date(rowData.activityDueDate),
                                format,
                              })}
                          </td>
                          <td>
                            {rowData.activityClosedDate &&
                              getMomentDateForUIReadOnly({
                                date: new Date(rowData.activityClosedDate),
                                format,
                              })}
                          </td>
                          <td>
                            {rowData.dateCreated ?
                              getMomentDateForUIReadOnly({
                                date: new Date(rowData.dateCreated),
                                format: CONSTANTS.TABLE_DATE_FORMAT,
                              }) : ''}
                          </td>
                          <td>{rowData.assignedUserName || <div className="empty-data-box"></div>}</td>
                          <td>{rowData.activityStatusName || <div className="empty-data-box"></div>}</td>
                          {
                            (customerActivityPermissions?.update || customerActivityPermissions?.delete) ?   <td className='text-center'>
                            <div className="action-icon">
                              <Dropdown
                                overlay={actionMenu(rowData)}
                                trigger={["click"]}
                              >
                                <i className={ICONS.MORE} />
                              </Dropdown>
                            </div>
                            {/* { 
                              <div className="table-cell-action" >
                                <span className='cursor-pointer' onClick={() => {
                                  handleAddUpdateActivity(rowData)}}>
                                  <i className="fa fa-edit" />
                                </span>
                                <span className='cursor-pointer' onClick={() => deleteActivity(rowData)}>
                                    <i className="fa fa-remove" />
                                </span>
                              </div>
                            } */}
                          </td>: ""
                          }
                        
                        </tr>
                      ))
                      ) :
                      <tr key="empty-data">
                        <td colSpan={"100%"} style={{ borderBottom: "none" }}>
                          <Empty />
                        </td>
                      </tr>
                    }
                  </tbody>
                </table>
              </div>
            </Skeleton>

            <div className="footer">
              <div className="f-left"></div>
              <div className="f-right"></div>
            </div>
            </div>
      </Fragment>
    );
  }
}

export default Activity;
