import React, { Fragment, useMemo } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { Skeleton, Pagination, Tooltip } from 'antd';
import { fetchPaginationDataIfNeeded, getMomentDateForUIReadOnly, fixedDecimalAndFormateNumber, sortTable, getPermissionData } from '../../../../utils'
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import {
    CONSTANTS,
    MODULE_CODED_VALUES
} from '../../../../static/constants';
import StandardSalesOrder from '../../../../containers/customer/salesOrder/StandardSalesOrder';
import CustomerProfile from "../../../../containers/modal/modalBody/customer/Profile"

const SalesOrderDraftListingComp = (props) => {
    const {  soDraftList, updateState, companyInfo, soDraftCount, pageNumber, dir=0 ,permissions } = props;
    const primaryPerm = (permissions && permissions.primary) || [];
    const salesOnlineOrderPermission = useMemo(()=>{
      return getPermissionData(primaryPerm ,  MODULE_CODED_VALUES.SALES_ORDER);
    },[])
    // const getRowHeight = (params) => {
    //     let height = 35;
    //     return height;
    // };

    // const getPayloadForPdf = (soDetail) => {
    //     return {
    //         fileName: 'Sales Order.pdf',
    //         transactionName: 'salesOrder',
    //         soDetail: soDetail,
    //         companyInfo: props.companyInfo,
    //         customerId: soDetail.customerId
    //     }
    // }

    // const openEmailDrawer = (props, soDetail) => {
    //     let payload = getPayloadForPdf(props, soDetail);
    //     payload.fileDataReqeired = true;
    //     props.updateState({
    //         emailDrawerVisible: true,
    //         txData: payload,
    //         type: TRANSACTION_TYPES.SALES_ORDER,
    //     });
    // };

    // const getActionMenuPermissionData = () => {
    //     const primaryPerm = permissions.primary || [];
    //     const permittedModules = {
    //         firstSection: {
    //             updateSalesOrder: checkACLPermission(primaryPerm, MODULE_CODED_VALUES.SALES_ORDER, PERMISSION_VALUES.UPDATE),
    //             cloneSalesOrder: checkACLPermission(primaryPerm, MODULE_CODED_VALUES.SALES_ORDER, PERMISSION_VALUES.CREATE)
    //         }
    //     };
    //     Object.keys(permittedModules.firstSection).forEach((moduleName) => {
    //         if (permittedModules.firstSection[moduleName] === false) {
    //             delete permittedModules.firstSection[moduleName];
    //         }
    //     })
    //     return permittedModules;
    // }

    // const actionMenu = (param, permittedModules) => {
    //     return (
    //         <Menu className="cell-action-dropdown">

    //             {
    //                 permittedModules.firstSection.cloneSalesOrder
    //                     ? (
    //                         <Menu.Item key="2">
    //                             <i className="fa fa-clone" />
    //                             <Link
    //                                 to={
    //                                     {
    //                                         pathname: '/admin/so-create',
    //                                         state: {
    //                                             customerId: param.data.customerId,
    //                                             soDraftData: param.data.salesOrderData,
    //                                             clone: true
    //                                         }
    //                                     }}
    //                             >Create Sales Order</Link>
    //                         </Menu.Item>
    //                     )
    //                     : <Fragment />
    //             }

    //         </Menu >
    //     );
    // }

    const showTotal = (total) => {
        return props.intl.formatMessage({ id: 'pagination.text.total', defaultMessage: '' }) + ` ${total} ` + props.intl.formatMessage({ id: 'pagination.text.items', defaultMessage: '' });
    };
    
    const loadPageData = (pageNumber, pageSize) => {
        const payload = { companyInfo, relationshipId: companyInfo.relationshipId, pageNumber: pageNumber, pageSize: pageSize };
        fetchPaginationDataIfNeeded('fetchSalesOrderDrafts', 'soDraftList', props, payload);
    }

    const handlePageSizeChange = (pageNumber, pageSize, props) => {
        const payload = { companyInfo, relationshipId: companyInfo.relationshipId, pageNumber: pageNumber, pageSize: pageSize };
        props.resetDraftPaginatedData();
        props.fetchSalesOrderDrafts(payload);
        props.updateState({ pageSize: pageSize, pageNumber: pageNumber });
    }

    //const permittedModules = getActionMenuPermissionData();
    // const columnDefs = [
    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='customer.salesOrder.listing.header.draftNo' defaultMessage='' />;
    //         },
    //         field: "salesOrderDraftId",

    //         cellRendererFramework: (param) => <div className="cursor-pointer" >
    //             <Link
    //                 to={
    //                     {
    //                         pathname: '/admin/so-create',
    //                         state: {
    //                             customerId: param.data.customerId,
    //                             soDraftData: param.data.salesOrderData,
    //                             salesOrderDraftId: param.data.salesOrderDraftId,
    //                             clone: true
    //                         }
    //                     }}
    //             >Draft-{param.value}</Link>
    //         </div>,
    //         rowDrag: true,
    //         resizable: true,
    //         width: 100,
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='customer.salesOrder.listing.header.customer' defaultMessage='' />;
    //         },
    //         field: "customerName",
    //         resizable: true,
    //         width: 100,
    //         cellRendererFramework: (link) => <div className="cursor-pointer" >
    //             <Link
    //                 to={
    //                     {
    //                         pathname: 'customers/details',
    //                         state: {
    //                             customerId: link.data.salesOrderData.customerId,
    //                             customerName: link.data.salesOrderData.customerName,
    //                         }
    //                     }}
    //                 className='company-name'
    //             >
    //                 {link.data.salesOrderData.customerName}
    //             </Link>
    //         </div>,
    //     },
      
        
    //     {
    //         headerComponentFramework: () => {
    //             return <div><FormattedMessage id='customer.salesOrder.listing.header.amount' default='Amount' />  </div>;
    //         },
    //         cellRendererFramework: (link) => {
    //             // return link.data.salesOrderData.salesOrderTotalAmount && link.data.salesOrderData.salesOrderTotalAmount !== '-' ? <span> {link.data.salesOrderData.foreignCurrencyIcon ? <i className={link.data.salesOrderData.foreignCurrencyIcon}></i> : (link.data.salesOrderData.foreignCurrency ? <span>{link.data.salesOrderData.foreignCurrency}</span> : (props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "))}{link.data.salesOrderData.salesOrderTotalAmount.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} </span> : "-"
    //             return link.data.salesOrderData.salesOrderTotalAmount && link.data.salesOrderData.salesOrderTotalAmount !== '-' ? <span> {link.data.salesOrderData.foreignCurrencyIcon ? <i className={link.data.salesOrderData.foreignCurrencyIcon}></i> : (link.data.salesOrderData.foreignCurrency ? <span>{link.data.salesOrderData.foreignCurrency}</span> : (props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "))}{fixedDecimalAndFormateNumber(link.data.salesOrderData.salesOrderTotalAmount)} </span> : "-"
    //         },
    //         field: "salesOrderTotalAmount",
    //         resizable: true,
    //         cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN,
    //         width: 100,
    //     },
       
    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='customer.salesOrder.listing.header.date' defaultMessage='' />;
    //         },
    //         field: "salesOrderDate",
    //         cellRendererFramework: (link) => {
    //             return <div>
                    
    //             </div>
    //         },
    //         resizable: true,
    //         width: 100,
    //     },
       
    // ]

    // if (Object.keys(permittedModules.firstSection).length) {
    //     columnDefs.push({
    //         headerComponentFramework: () => {
    //             return <div className="cursor-pointer">
    //                 {/* <Popover
    //                     content={<AddAndRemoveCustomerListingColumn {...props} />}
    //                     title={<div className="text-center">Add/Remove </div>}
    //                     placement="left"
    //                     trigger="click"
    //                 >
    //                     <i className='fa fa-bars' />
    //                 </Popover> */}
    //             </div>;
    //         },
    //         cellRendererFramework: (param) => {
    //             return <Dropdown overlay={actionMenu(param, permittedModules)} trigger={['click']}>
    //                 <Button className="action-btn small">
    //                     <i className="fa fa-ellipsis-v" />
    //                 </Button>
    //             </Dropdown>
    //         },
    //         resizable: true,
    //         field: 'action',  lockPosition: true,
    //         pinned: 'right',
    //         width: 82,
    //     });
    // }

    // const agGridStyle = {
    //     height: '100%',
    //     width: '100%'
    // };

    
    // const onGridReady = (params) => {
    //     props.updateState({
    //         gridApi: params.api,
    //         gridColumnApi: params.columnApi
    //     });
    //     params.api.sizeColumnsToFit();
    // }

    const sortColumn = (e) => {
        sortTable(document.getElementById('sales-order-draft-table'), e.target.cellIndex, 1 - dir);
        updateState({ dir: 1 - dir })
      }


    return (
        <Fragment>
           
                {/* <div className='agGridWrapper'>
                    <div className="ag-theme-balham" style={agGridStyle} >
                        {props.txColumns.length ? <AgGridReact
                            onGridReady={onGridReady}
                            columnDefs={columnDefs}
                            rowData={soDraftList[pageNumber] || []}
                            rowDragManaged={true}
                            domLayout={"autoHeight"}
                            suppressDragLeaveHidesColumns={true} 
                            animateRows={true}
                            defaultColDef={{
                                flex:1,
                                autoHeight: true,
                                wrapText: true,
                            }}
                            //getRowHeight={getRowHeight}
                        >
                        </AgGridReact>: null}
                    </div>
                </div> */}

                <div className="view-container-actions">
                <Skeleton loading={props.headerLoading} paragraph={false} active>

                    <div className="left-actions">
                        <div className="table-heading">
                            <FormattedMessage id='sales.salesOrderDraft.table.heading' defaultMessage='' />
                        </div>
                        {
                            props.soDraftCount > 0  ? <>
                            <div className="vertical-sep" />
                            <div>{props.soDraftCount }</div>
                            </>:''
                        }
                    </div>
                    <div className="right-actions">
                    </div>
                </Skeleton>
                </div>
                <Skeleton loading={props.listLoading} paragraph={{ rows: 16 }} active>

                <div className='table-container'>
                    <table id='sales-order-draft-table'>
                        <thead>
                            <tr>
                                <th>S. No.</th>
                                <th onClick={sortColumn}><FormattedMessage id='customer.salesOrder.listing.header.draftNo' defaultMessage='' /></th>
                                <th onClick={sortColumn}><FormattedMessage id='customer.salesOrder.listing.header.customer' defaultMessage='' /></th>
                                <th onClick={sortColumn}><FormattedMessage id='customer.salesOrder.listing.header.amount' default='Amount' /></th>
                                <th onClick={sortColumn}><FormattedMessage id='customer.salesOrder.listing.header.date' defaultMessage='' /></th>
                            </tr>
                        </thead>
                        <tbody>
                            {soDraftList && soDraftList[pageNumber] ? 
                                soDraftList[pageNumber].map((rowData, index)=> (
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>
                                            <div className={salesOnlineOrderPermission.update ? 'cursor-pointer' : 'cursor-disabled'} onClick={()=>{
                                                if(salesOnlineOrderPermission.update){
                                                    props.pushModalToStack({
                                                        modalBody: (
                                                          <StandardSalesOrder
                                                            {...props}
                                                            customerId= {rowData.customerId}
                                                                        soDraftData= {rowData.salesOrderData}
                                                                        salesOrderDraftId= {rowData.salesOrderDraftId}
                                                                        clone= {true}
                                                          />
                                                        ),
                                                        width: "100%",
                                                        hideTitle: true,
                                                        hideFooter: true,
                                                        wrapClassName: "new-transaction-wrapper",
                                                      });
                                                }
                                                  
                                            }}
                                                // to={
                                                //     {
                                                //         pathname: '/admin/so-create',
                                                //         state: {
                                                //             customerId: rowData.customerId,
                                                //             soDraftData: rowData.salesOrderData,
                                                //             salesOrderDraftId: rowData.salesOrderDraftId,
                                                //             clone: true
                                                //         }
                                                //     }
                                                // }
                                                >Draft-{rowData.salesOrderDraftId}
                                            </div>
                                        </td>
                                        <td>
                                            <div
                                               onClick={() => {
                                                props.pushModalToStack({  
                                          
                                                  modalBody: <CustomerProfile {...props}
                                                  customerId= {rowData.salesOrderData.customerId}
                                                //   customerName={rowData.customerName}
                                                //   customerDetails={rowData}
                                              />,
                                              width: '100%',
                                              hideTitle: true,  
                                              hideFooter: true,
                                              wrapClassName: "new-transaction-wrapper",
                                              })
                                             }}
                                                // to={
                                                //     {
                                                //         pathname: 'customers/details',
                                                //         state: {
                                                //             customerId: rowData.salesOrderData.customerId,
                                                //             customerName: rowData.salesOrderData.customerName,
                                                //         }
                                                //     }}
                                                className='company-name cursor-pointer'
                                            >
                                                {rowData.salesOrderData.customerName}
                                            </div>
                                        </td>
                                        <td>
                                            {rowData.salesOrderData.salesOrderTotalAmount && rowData.salesOrderData.salesOrderTotalAmount !== '-' ? <span> {rowData.salesOrderData.foreignCurrencyIcon ? <i className={rowData.salesOrderData.foreignCurrencyIcon}></i> : (rowData.salesOrderData.foreignCurrency ? <span>{rowData.salesOrderData.foreignCurrency}</span> : (props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "))}{fixedDecimalAndFormateNumber(rowData.salesOrderData.salesOrderTotalAmount)} </span> : "-"}
                                        </td>
                                        <td>
                                            <Tooltip placement="topLeft" title={(rowData.salesOrderData.salesOrderDate && getMomentDateForUIReadOnly({date: rowData.salesOrderData.salesOrderDate, format: CONSTANTS.DISPLAY_TOOLTIP_DATE_FORMAT}))}>
                                                {(rowData.salesOrderData.salesOrderDate && getMomentDateForUIReadOnly({date: rowData.salesOrderData.salesOrderDate, format: CONSTANTS.DISPLAY_DATE_FORMAT}))}
                                            </Tooltip>
                                        </td>
                                    </tr>
                                )) : ''
                            }
                        </tbody>
                    </table>
                </div>
            </Skeleton>
            <div className="footer">
            <Skeleton loading={props.headerLoading} paragraph={false} active>

                <div className="f-left"></div>
                <div className="f-right">
                    <Pagination
                        size="small"
                        total={soDraftCount}
                        showTotal={showTotal}
                        defaultPageSize={props.pageSize} showSizeChanger pageSizeOptions={[10, 25, 50, 100, 200]}
                        // showQuickJumper
                        onChange={(pageNumber, pageSize) => {
                            loadPageData(pageNumber, pageSize);
                            updateState({ pageSize: pageSize, pageNumber: pageNumber })
                        }}
                        onShowSizeChange={(pageNumber, pageSize) => {
                            handlePageSizeChange(pageNumber || 1, pageSize, props)
                        }}
                    />
                </div>
                </Skeleton>
            </div>
        </Fragment>
    );
}

export default injectIntl(SalesOrderDraftListingComp);
