import React, { Fragment } from "react";
import { Row, Col, Button, Popover,Skeleton,Input } from 'antd';
import { injectIntl, FormattedMessage } from 'react-intl';
import * as find from 'lodash.find';
import * as filter from 'lodash.filter';
import ReactHtmlParser from 'react-html-parser';
import { CustomAgGrid } from "../../../general/CustomAgGrid";
import { AddAndRemoveColumn } from "../../../general/AddAndRemoveColumn";
import { AG_GRID_CONSTANTS, GRID_PREFERENCE_CONSTANTS } from '../../../../static/agGridConstants';
import { AG_GRID_CLASS_CONSTANTS } from '../../../../static/cssClassConstants';
import {
    MODAL_TYPE, MODULE_CODED_VALUES, PERMISSION_VALUES
} from '../../../../static/constants';
import { getLabelName } from '../../../modal/modalBody/customerInfo';
import { showToasterMessage } from "../../../../utils";
import { CustomTableUpload } from "../../../general/UploadTable";
import { AWSFileTable } from "../../../general/AWSFileTable";
import CKEditor from "../../../general/CustomCKEditor/ckeditor";
import { CUSTOM_CONFIGURATION } from "../../../../static/ckEditorConfigConstants";
// const { lang } = require('../../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');

class ProductDetails extends React.Component {

    constructor() {
        super();
        this.state = {
            sectionVisible: {
                discount: false,
                tax: false,
                expense: false
            },
            total: 0,
            subtotal: 0,
            subtotalWithoutDiscount: 0,
            totalDiscount: 0,
            expenseAmount: 0,
            totalTax: 0,
            itemLevelTaxes: [],
            rootDiscountPercent: 0,
            rootDiscountAmount: 0
        }
    }

    calculateSubtotal = () => {
        let total = 0;
        let subtotal = 0;
        let totalDiscount = 0;
        let totalTax = 0;
        let itemLevelTaxes = []
        let subtotalWithoutDiscount = 0;
        let rootDiscountAmount = 0;
        let self = this;

        this.gridApi && this.gridApi.forEachNode(function (rowNode, index) {
            let stringifiedRowData = JSON.stringify(rowNode.data);
            let totalAmountOfColumn = 0;
            const rowdata = JSON.parse(stringifiedRowData);
            if (rowdata.qty && rowdata.rate) {
                let taxObj = {
                    subtotal: 0,
                    taxPercent: 0,
                    taxAmount: 0
                }
                totalAmountOfColumn = Number(rowdata.qty) * Number(rowdata.rate);
                subtotalWithoutDiscount = Number(totalAmountOfColumn) + Number(subtotalWithoutDiscount);
                if (rowdata.discount) {
                    let discountAmount = ((Number(rowdata.discount || 0) * 0.01) * totalAmountOfColumn);
                    totalAmountOfColumn = totalAmountOfColumn - discountAmount;
                    totalDiscount = totalDiscount + discountAmount;
                }

                if (self.state.rootDiscountPercent) {
                    let itemLevelDiscountAmount = (Number(self.state.rootDiscountPercent) * 0.01) * totalAmountOfColumn;
                    totalAmountOfColumn = totalAmountOfColumn - itemLevelDiscountAmount;
                    totalDiscount = totalDiscount + itemLevelDiscountAmount
                    rootDiscountAmount = rootDiscountAmount + itemLevelDiscountAmount;
                }

                if (rowdata.tax) {
                    taxObj.taxPercent = Number(rowdata.tax || 0).toFixed(2);
                    taxObj.taxName = rowdata.taxName;
                    let taxAmount = ((Number(rowdata.tax || 0) * 0.01) * totalAmountOfColumn);
                    totalTax = totalTax + taxAmount;
                }
                if (taxObj.taxPercent) {
                    taxObj.subtotal = Number(totalAmountOfColumn || 0).toFixed(2);
                    const availableTaxObj = find(itemLevelTaxes, { taxPercent: taxObj.taxPercent });
                    if (availableTaxObj) {
                        itemLevelTaxes = filter(itemLevelTaxes, (ele) => {
                            if (ele.taxPercent === taxObj.taxPercent) {
                                ele.subtotal = Number(ele.subtotal || 0) + Number(taxObj.subtotal || 0);
                                ele.taxAmount = (Number(ele.taxPercent || 0) * 0.01) * Number(ele.subtotal || 0);
                            }
                            return true;
                        });
                    } else {
                        taxObj.taxAmount = ((Number(taxObj.taxPercent || 0) * 0.01) * Number(taxObj.subtotal || 0)).toFixed(2);
                        itemLevelTaxes.push(taxObj);
                    }
                }
                subtotal = Number(subtotal) + totalAmountOfColumn;
            }
        });


        total = Number(subtotal || 0) + totalTax + this.state.expenseAmount;

        this.setState({
            total: Number(total || 0).toFixed(2),
            subtotal: Number(subtotal || 0).toFixed(2),
            totalDiscount: Number(totalDiscount || 0).toFixed(2),
            totalTax: Number(totalTax || 0).toFixed(2),
            subtotalWithoutDiscount: Number(subtotalWithoutDiscount || 0).toFixed(2),
            itemLevelTaxes,
            rootDiscountAmount
        });
    }

    handleNewTaxAddition = (props, payload) => {
        const formData = payload.formData;
        const { showModal, companyInfo } = props;
        formData.relationshipId = companyInfo.relationshipId;
        const data = {
            title: <FormattedMessage id='addItem.text.newTax' defaultMessage='' />,
            formData,
            hideFooter: true,
            modalData: {
                modalType: MODAL_TYPE.NEW_TAX,
                data: payload,

            },
            handleSubmit: (formData = {}) => {
                data.formData.submittedOnce = true;
                showModal(data);
            }
        };
        showModal(data);
    }

    addItemConfirmationModal = (props, text, modalType) => {
        const { showModal, companyInfo } = props;
        const labelName = getLabelName(modalType);
        const modalBody = <span>
            <FormattedMessage
                id='addItem.confirmation'
                values={{ value: `${text} to ` }}
                defaultMessage={``}
            />
            {labelName} ?
        </span>;

        let addFunc;

        switch (modalType) {
            case MODAL_TYPE.UOM: {
                addFunc = props.addUOM;
                break;
            }
            case MODAL_TYPE.SHIPPING_INSTRUCTION: {
                addFunc = props.addShippingInstruction;
                break;
            }
            case MODAL_TYPE.EXPENSE_TYPE: {
                addFunc = props.addExpenseTypes;
                break;
            }
            default: {
                addFunc = () => { }
            }
        }

        const modalData = {
            modalBody,
            handleSubmit: () => {
                addFunc({ text: text, relationshipId: companyInfo.relationshipId })
                props.hideModal();
            },
        };
        showModal(modalData);
    }

    getModalTitle = (newTextObj, modalType) => {
        let title;

        switch (modalType) {
            case MODAL_TYPE.UOM: {
                title = <FormattedMessage id='addItem.text.uom' defaultMessage='' />;
                break;
            }
            case MODAL_TYPE.SHIPPING_INSTRUCTION: {
                title = <FormattedMessage id='addItem.text.shippingInstruction' defaultMessage='' />;
                break;
            }
            case MODAL_TYPE.EXPENSE_TYPE: {
                title = <FormattedMessage id='addItem.text.expenseType' defaultMessage='' />;
                break;
            }
            default:
                title = <FormattedMessage id='signup.confirmation.title' defaultMessage='' />;
        }
        return title;
    }

    handleNewItemAddition = (props, payload, modalType) => {
        const formData = payload.formData;
        const { showModal } = props;

        if (formData.textBoxValue && formData.textBoxValue.length && !payload.existsInData) {
            this.addItemConfirmationModal(props, formData.textBoxValue, modalType);
        } else {
            const data = {
                title: this.getModalTitle(payload, modalType),
                formData,
                modalData: { modalType, data: payload },
                handleSubmit: (formData = {}) => {
                    if (formData.textBoxValue && formData.textBoxValue.length) {
                        this.addItemConfirmationModal(props, formData.textBoxValue, modalType);
                    } else {
                        data.formData.submittedOnce = true;
                        showModal(data);
                    }
                }
            };
            showModal(data);
        }
    }

    deleteClickHandler = (props, modalType, payload) => {
        const { showModal, companyInfo } = props;
        const modalBody =
            <FormattedMessage
                id='deleteItem.confirmation'
                defaultMessage={``}
                values={{ value: payload.text }}
            />;

        let deleteFunc;

        switch (modalType) {
            case MODAL_TYPE.UOM: {
                deleteFunc = props.deleteUOM;
                break;
            }
            case MODAL_TYPE.NEW_TAX: {
                deleteFunc = props.deleteTax;
                break;
            }
            case MODAL_TYPE.SHIPPING_INSTRUCTION: {
                deleteFunc = props.deleteShippingInstruction;
                break;
            }
            case MODAL_TYPE.EXPENSE_TYPE: {
                deleteFunc = props.deleteExpenseTypes;
                break;
            }
            default: {
                deleteFunc = () => { }
            }
        }

        const modalData = {
            modalBody,
            handleSubmit: () => { deleteFunc({ id: payload.id, relationshipId: companyInfo.relationshipId }) },
        };
        showModal(modalData);
    }

    render() {
        // const { txColumnSetting } = this.props;
        const self = this;
       
        const isPlaceOfSupplySameAsRelationship = (this.props.relationshipBillingAddress || {}).stateName === this.props.placeOfSupply;
        const onGridReady = (params) => {
            this.gridApi = params.api;
            this.gridColumnApi = params.columnApi;
            setTimeout(() => {
                onDataRendered(params);
            }, 2000)
        }

        const _isColumnToHide = (columnName) => {
            // if (this.props.forceHideTxColumn && this.props.forceHideTxColumn.indexOf(columnName) > -1) {
            //     return true;
            // }
            return (this.props.txColumns.indexOf(columnName) < 0 && this.props.txMandatoryColumns.indexOf(columnName) < 0) ? true : false;
        }



        const columnDefs = [
            {
                headerComponentFramework: () => {
                    return "#";
                },
                cellRendererFramework: (params) => {
                    return params.node.rowIndex + 1
                },
                field: "sno",
                rowDrag: true,
                colId: "sno_1",
                minWidth: 70,
                maxWidth: 70,
                suppressMovable: true,
                lockPosition: true,
                sortable: true,
                cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN
            },
            {
                headerComponentFramework: () => {
                    return <FormattedMessage id='supplier.purchaseOrder.form.gridHeader.product' defaultMessage='' />;
                },
                field: AG_GRID_CONSTANTS.COMMON.PRODUCT_FIELD,
                colId: AG_GRID_CONSTANTS.COMMON.PRODUCT_FIELD + "_1",
                resizable: true,
                editable: !this.props.isRFQConversion,
                sortable: true,
                minWidth: 100,
                permissions: this.props.permissions,
                moduleName: MODULE_CODED_VALUES.PRODUCTS,
                operations: [PERMISSION_VALUES.CREATE, PERMISSION_VALUES.DELETE],
                suppressMovable: true,
                //hide: _isColumnToHide(GRID_PREFERENCE_CONSTANTS.PRODUCT),
                cellEditor: 'customTreeDropDown', suppressKeyboardEvent: (params) => {
                    const KEY_ENTER = 13;
                    const keyCode = params.event.keyCode;
                    const gridShouldDoNothing = params.editing && keyCode === KEY_ENTER;
                    return gridShouldDoNothing;
                },
                cellRendererFramework: (params) => {
                    setTimeout(() => {
                        if (params.reactContainer && params.reactContainer.scrollHeight >= (params.node.data.maxHeight || 30)) {
                            params.node.setRowHeight(params.reactContainer.scrollHeight);
                            params.node.data.minHeight = params.reactContainer.scrollHeight > 30 ? params.reactContainer.scrollHeight : 30;
                            this.gridApi && this.gridApi.onRowHeightChanged();
                        } else {
                            params.node.data.minHeight = params.reactContainer.scrollHeight > 30 ? params.reactContainer.scrollHeight : 30;
                        }
                    }, 500)
                    return ReactHtmlParser(params.data.product)
                },
                cellEditorParams: (obj) => {
                    return {
                        lastColumnCell: false,
                        options: this.props.products,
                        onSearch: this.props.onSearch,
                        permissions: this.props.permissions,
                        moduleName: MODULE_CODED_VALUES.PRODUCTS,
                        operations: [PERMISSION_VALUES.CREATE, PERMISSION_VALUES.DELETE],
                        onAddNewClick: () => {
                            self.props.updateHeaderState({
                                inventory: {
                                    inventoryDrawerVisible: true
                                }
                            });
                        },
                        canAddNew: true,
                        value: obj.node.data.selectedProductValue || [],
                        selectedLabel: obj.node.data.product || "",
                        allowClear: true,
                        onSelect: (selectedValue) => {
                            //obj.node.setDataValue(obj.colDef.field, selectedValue);
                            obj.node.data.selectedProductValue = obj.node.selectedProductValue;
                            obj.node.data.productObj = null;
                            obj.node.data.variant = null;
                            const itemId = obj.node.data.selectedProductValue && obj.node.data.selectedProductValue[0];
                            if (itemId) {
                                const variantId = obj.data.selectedProductValue && obj.data.selectedProductValue.length === 2 ? obj.data.selectedProductValue[1] : 0;
                                obj.node.data.productObj = find(this.props.products, { itemId: Number(itemId) }) || {};
                                obj.node.data.variant = variantId ? find(obj.node.data.productObj.inventoryItemVariantList, { variantId: Number(variantId) }) : obj.node.data.productObj.inventoryItemVariantList[0];
                                if (obj.node.data.productObj.shortDescription) {
                                    obj.node.data.description = obj.node.data.productObj.shortDescription;
                                }
                            }
                            if (obj.node.data.variant && !this.props.isDataToUpdate) {
                                const rate = Number(obj.node.data.variant.purchasedPrice || 0).toFixed(2);
                                obj.node.data.baseRate = Number(rate);
                                obj.node.data.rate = Number(rate);
                                //obj.node.setDataValue('specialDiscount', 0);
                                //obj.node.setDataValue('baseRate', Number(rate));
                                //obj.node.setDataValue('rate', Number(rate));
                                //obj.node.setDataValue('uom', obj.node.data.variant.uomName);
                                obj.node.data.uom = obj.node.data.variant.uomName;
                                obj.node.data.uom = obj.node.data.variant.uomName;
                                if (obj.node.data.productObj.isPopulateTax && !_isColumnToHide(GRID_PREFERENCE_CONSTANTS.TAX)) {
                                    const defaultItemHsnAndTax = obj.node.data.productObj.inventoryItemDefaultTaxList[0] || {};
                                    obj.node.data.hsnCode = ((defaultItemHsnAndTax || {}).hsnCode);
                                    let selectedTax = {};
                                    if (defaultItemHsnAndTax.taxSingleRateId) {
                                        selectedTax = find(this.props.taxes, { taxSingleRateId: defaultItemHsnAndTax.taxSingleRateId }) || {};
                                    } else {
                                        selectedTax = find(this.props.taxes, { taxPercent: ((defaultItemHsnAndTax || {}).igstRate || (defaultItemHsnAndTax || {}).cgstRate || (defaultItemHsnAndTax || {}).globleTaxRate) }) || {};
                                    }
                                    obj.node.data.taxName = selectedTax.taxName;
                                    obj.node.data.taxApplied = selectedTax.taxNameDisplay;
                                    obj.node.data.tax = selectedTax.taxPercent;
                                }
                            }
                            let isDuplicateItem = false;
                            this.gridApi && this.gridApi.forEachNode((rowNode, index) => {
                                const { data } = rowNode;
                                if (data.product === selectedValue && index !== obj.node.rowIndex) {
                                    isDuplicateItem = true;
                                }
                            });
                            if (isDuplicateItem) {
                                showToasterMessage({
                                    messageType: 'warning', description: 'Duplicate product is selected'
                                });
                            }

                            obj.node.data.isDuplicateItem = isDuplicateItem;
                            setTimeout(() => {
                                this.gridApi && this.gridApi.stopEditing();
                            }, 100);
                        }
                    }
                },
                cellClassRules: {
                    'ag-grid-cell-warning-boundary': function (obj) {
                        let isDuplicateItem = false;
                        self.gridApi && self.gridApi.forEachNode((rowNode, index) => {
                            const { data } = rowNode;
                            if (data.product && data.product === obj.node.data.product && obj.node.rowIndex !== index) {
                                isDuplicateItem = true;
                            }
                        });
                        return isDuplicateItem;
                    }
                }
            },
            {
                headerComponentFramework: () => {
                    return <FormattedMessage id='supplier.purchaseOrder.form.gridHeader.description' defaultMessage='' />;
                },
                field: AG_GRID_CONSTANTS.COMMON.DESCRIPTION_FIELD,
                cellClass: " ag-cell-description",
                colId: AG_GRID_CONSTANTS.COMMON.DESCRIPTION_FIELD + "_1",
                minWidth: 120,
                suppressMovable: true,
                resizable: true,
                editable: true,
                hide: _isColumnToHide(AG_GRID_CONSTANTS.COMMON.DESCRIPTION_FIELD),
                cellEditor: 'CKEditorGrid',
                cellEditorParams: (params) => {
                    return {
                        value: params.node.data.description || "",
                        isEditorLoaded: this.props.isEditorLoaded,
                        onChange: (value) => {
                            params.node.data[params.colDef.field] = value;
                            params.node.data.isEditorLoaded = true;
                            this.props.updateState({
                                isEditorLoaded: true
                            })
                        }
                    }
                },
                cellRendererFramework: (params) => {
                    setTimeout(() => {
                        if (params.reactContainer && params.reactContainer.scrollHeight > 30 && params.reactContainer.scrollHeight >= (params.node.data.minHeight || 0)) {
                            params.eParentOfValue.style.height = 'inherit';
                            params.eParentOfValue.style.height = params.reactContainer.scrollHeight;
                            params.node.data.maxHeight = params.reactContainer.scrollHeight;
                            params.node.setRowHeight(params.reactContainer.scrollHeight);
                            this.gridApi && this.gridApi.onRowHeightChanged();
                        }
                    }, 500)
                    return ReactHtmlParser(params.data.description)
                },
                suppressKeyboardEvent: (params) => {
                    const KEY_ENTER = 13;
                    const keyCode = params.event.keyCode;
                    const gridShouldDoNothing = params.event.target.value && params.editing && keyCode === KEY_ENTER;
                    params.node.data.scrollHeight = `${params.event.target.scrollHeight}px`;
                    if (params.event.target.scrollHeight > (params.data.minHeight || 30)) {
                        if (params.event.path && params.event.path[2]) {
                            params.event.path[2].style.height = 'inherit';
                            params.event.path[2].style.height = `${params.event.target.scrollHeight}px`;
                        }
                        params.node.setRowHeight(params.event.target.scrollHeight);
                        this.gridApi && this.gridApi.onRowHeightChanged();
                    } else {
                        if (params.event.path && params.event.path[2]) {
                            params.event.path[2].style.height = 'inherit';
                            params.event.path[2].style.height = `${(params.node.data.minHeight || 30)}px`;
                        }
                        params.node.setRowHeight((params.node.data.minHeight || 30));
                        this.gridApi && this.gridApi.onRowHeightChanged();
                    }
                    return gridShouldDoNothing;
                }
            },
            {
                headerComponentFramework: () => {
                    return <FormattedMessage id='modal.txDetails.shipment.qtyOrdered' defaultMessage='' />;
                },
                field: GRID_PREFERENCE_CONSTANTS.QUANTITY_FROM_PO,
                colId: GRID_PREFERENCE_CONSTANTS.QUANTITY_FROM_PO + "_1",
                sortable: true,
                minWidth: 100,
                maxWidth: 140,
                suppressMovable: true,
                resizable: true,
                cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN
            },
            {
                headerComponentFramework: () => {
                    return <FormattedMessage id='supplier.purchaseOrder.form.gridHeader.qtyReceived' defaultMessage='' />;
                },
                field: GRID_PREFERENCE_CONSTANTS.QUANTITY_RECEIVED,
                colId: GRID_PREFERENCE_CONSTANTS.QUANTITY_RECEIVED + "_1",
                sortable: true,
                minWidth: 100,
                maxWidth: 140,
                suppressMovable: true,
                resizable: true,
                cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN
            },
            {
                headerComponentFramework: () => {
                    return <FormattedMessage id='supplier.purchaseOrder.form.gridHeader.qtyToReceive' defaultMessage='' />;
                },
                field: GRID_PREFERENCE_CONSTANTS.QUANTITY,
                colId: GRID_PREFERENCE_CONSTANTS.QUANTITY + "_1",
                editable: !this.props.isRFQConversion,
                sortable: true,
                minWidth: 100,
                maxWidth: 140,
                suppressMovable: true,
                resizable: true,
                cellEditor: 'customNumberEditor',
                cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN,
                cellEditorParams: (obj) => {
                    return {
                        onChange: (value) => {
                            obj.node.data.quantity = value;
                            obj.api.refreshCells();
                        }
                    }
                }
            },
            {
                headerComponentFramework: () => {
                    return <FormattedMessage id='supplier.purchaseOrder.form.gridHeader.uom' defaultMessage='' />;
                },
                colId: GRID_PREFERENCE_CONSTANTS.UOM + "_1",
                resizable: true,
                field: AG_GRID_CONSTANTS.COMMON.UOM_FIELD,
                editable: true,
                minWidth: 100,
                maxWidth: 140,
                suppressMovable: true,
                sortable: true,
                hide: _isColumnToHide(GRID_PREFERENCE_CONSTANTS.UOM),
                cellEditor: 'customDropDownEditor',
                suppressKeyboardEvent: (params) => {
                    const KEY_ENTER = 13;
                    const keyCode = params.event.keyCode;
                    const gridShouldDoNothing = params.editing && keyCode === KEY_ENTER;
                    return gridShouldDoNothing;
                },
                cellEditorParams: (obj) => {
                    return {
                        lastColumnCell: false,
                        items: this.props.uomList,
                        optionKeyName: 'uomId',
                        valueKeyName: 'uomName',
                        canAddNew: true,
                        canDelete: true,
                        permissions: this.props.permissions,
                        moduleName: MODULE_CODED_VALUES.UOM,
                        operations: [PERMISSION_VALUES.CREATE, PERMISSION_VALUES.DELETE],
                        deleteClickHandler: (payload) => {
                            this.deleteClickHandler(this.props, MODAL_TYPE.UOM, payload);
                        },
                        onAddNewClick: (payload) => {
                            payload.formData = {
                                textBoxValue: payload.textEntered,
                                submittedOnce: false
                            }
                            this.handleNewItemAddition(this.props, payload, MODAL_TYPE.UOM)
                        },
                        onSelect: (selectedValue) => {
                            //obj.node.setDataValue(obj.colDef.field, selectedValue);
                        },
                        allowClear: true,
                        onClear: () => {
                            //obj.node.setDataValue(obj.colDef.field, undefined);
                        },
                    }
                },
                cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN
            },
            {
                headerComponentFramework: () => {
                    return <FormattedMessage id='supplier.purchaseOrder.form.gridHeader.hsn' defaultMessage='' />;
                },
                field: 'hsnCode',
                colId: "hsnCode_1",
                editable: true,
                minWidth: 100,
                maxWidth: 140,
                suppressMovable: true,
                sortable: true,
                resizable: true,
                hide: _isColumnToHide('hsnCode') || (this.props.companyInfo || {}).countryName !== "India",
                cellEditor: 'customTextEditor',
                cellEditorParams: (obj) => {
                    return {
                        onChange: (value) => {
                            obj.node.data.hsnCode = value;
                            obj.api.refreshCells();
                        }
                    }
                },
            },
            {
                headerComponentFramework: () => {
                    return <div className="cursor-pointer">
                        {<Popover
                            content={<AddAndRemoveColumn {...this.props} />}
                            title={<div className="text-center"><FormattedMessage id='common.addRemove' defaultMessage='' /> </div>}
                            placement="left"
                            trigger="click"
                        >
                            <i className='fa fa-bars' />
                        </Popover>}
                    </div>;
                },
                field: 'action',
                lockPosition: true,
                colId: "action_1",
                minWidth: 50,
                maxWidth: 50,
                suppressMovable: true,
                pinned: 'right',
                cellRenderer: 'customActionEditor',
                suppressNavigable: true,
                cellClass: this.props.isRFQConversion ? 'custom-readonly-cell' : "",
                cellRendererParams: (params) => {
                    return {
                        onClick: () => {
                            this.gridApi.updateRowData({ remove: [this.gridApi.getDisplayedRowAtIndex(params.node.rowIndex).data] });
                            if (this.gridApi.rowRenderer.lastRenderedRow < 0) {
                                this.gridApi.updateRowData({ add: [{}] });
                            }
                            this.calculateSubtotal();
                        },
                    }
                }
            }
        ];

        const createInboundDelivery = (isDraft) => {
            // let isRequiredFiledMissing = false;
            // self.gridApi && self.gridApi.forEachNode((rowNode, index) => {
            //     let { data } = rowNode;
            //     if (index === 0 && ((!data.product && !data.description) || !(Number(data.quantity || 0)))) {
            //         isRequiredFiledMissing = true;
            //     } else if (!data.product && !(Number(data.quantity || 0))) {
            //         data = {};
            //     } else if ((!data.product && !data.description) || !(Number(data.quantity || 0))) {
            //         isRequiredFiledMissing = true;
            //     }
            // });

            // if ((isRequiredFiledMissing && !isDraft) || !(this.props.supplier && this.props.supplier.supplierId)) {
            //     return showToasterMessage({
            //         messageType: 'error', description: lang['inboundDelivery.selectPO']
            //     });
            // }

            let errorKey = '';
            if (!(this.props.supplier && this.props.supplier.supplierId)) {
                return showToasterMessage({
                    messageType: 'error',
                    description: this.props.intl.formatMessage({ id: 'inboundDelivery.selectPO', defaultMessage: '' })
                });
            }
            self.gridApi && self.gridApi.forEachNode((rowNode, index) => {
                let { data } = rowNode;
                if (index === 0 && (!data.product && !data.description)) {
                    errorKey = 'missingProduct.errorMsg';
                } else if (index === 0 && !(Number(data.quantity || 0))) {
                    errorKey = 'missingQty.errorMsg';
                }
            });

            if (errorKey && !isDraft) {
                return showToasterMessage({
                    messageType: 'error',
                    description: this.props.intl.formatMessage({ id: errorKey, defaultMessage: '' })
                });
            }

            const modalData = {
                modalBody: <FormattedMessage
                    id='addUpdateData'
                    defaultMessage={``}
                />,
                handleSubmit: () => {
                    const payload = {
                        props: this.props,
                        gridObject: this.gridApi,
                        isPlaceOfSupplySameAsRelationship: isPlaceOfSupplySameAsRelationship,
                        state: this.state,
                        isDraft: isDraft
                    }
                    if (isDraft) {
                        this.props.createInboundDeliveryDraft(payload, this.props);
                    } else {
                        this.props.createInboundDelivery(payload);

                    }

                    this.props.hideModal();
                    this.props.popModalFromStack()
                }
            };
            this.props.showModal(modalData);

        }

        const saveColumnIndex = (columnState) => {
            let data = this.props.txPreferences || {};
            let gridPreference = {};
            data.relationshipId = (this.props.companyInfo || {}).relationshipId;
            data.clientUserAccountId = (this.props.userInfo || {}).userId;
            if (data.tablePreference) {
                gridPreference = JSON.parse(data.tablePreference);
            }
            gridPreference.columnSetting = columnState;
            gridPreference.columnList = this.props.txColumns;
            data.gridPreference = JSON.stringify(gridPreference);
            data.preferenceId = this.props.preferenceId;
            data.tablePreference = this.props.tablePreference;
            //this.props.saveUserPrefrences(data);
        }

        const saveColumnWidth = (columnState, width) => {
            let data = this.props.txPreferences || {};
            let gridPreference = {};
            data.relationshipId = (this.props.companyInfo || {}).relationshipId;
            data.clientUserAccountId = (this.props.userInfo || {}).userId;
            if (data.gridPreference) {
                gridPreference = JSON.parse(data.gridPreference);
            }
            gridPreference.columnSetting = columnState;
            gridPreference.columnList = this.props.txColumns;
            data.gridPreference = JSON.stringify(gridPreference);
            data.preferenceId = this.props.preferenceId;
            data.tablePreference = this.props.tablePreference;
            this.props.saveUserPrefrences(data, this.props);
        }

        let moveEvent = {};
        const onColumnMoved = (event, isDragStopped) => {
            if (event.column) {
                moveEvent = event;
            }
            if (isDragStopped && moveEvent.column && moveEvent.column.colDef && moveEvent.source !== AG_GRID_CONSTANTS.EVENT_SOURCE.API) {
                saveColumnIndex(moveEvent.columnApi.getColumnState());
            }
        }

        const onColumnResized = (event, tablegridApi, tableGridColumnApi) => {
            const gridColumnApi = tableGridColumnApi || this.gridColumnApi;
            //const gridApi = tablegridApi || this.gridApi;
            event.api.resetRowHeights();
            if (event.type === 'columnResized' && event.source !== 'sizeColumnsToFit' && event.source !== 'api' && event.finished === true) {
                // event.api.resetRowHeights();
                if (gridColumnApi && gridColumnApi.columnController.bodyWidth < gridColumnApi.columnController.scrollWidth) {
                    this.gridApi.sizeColumnsToFit();
                    // const columnArray = event.columnApi.getColumnState();
                    // for (let i = (columnArray.length - 1); i >= 0; i--) {
                    //     if (columnArray[i] && !columnArray[i].hide && columnArray[i].colId !== 'action_1') {
                    //         columnArray[i].width = columnArray[i].width + (gridColumnApi.columnController.scrollWidth - gridColumnApi.columnController.bodyWidth);
                    //         break;
                    //     }
                    // }
                    // saveColumnWidth(columnArray);
                    setTimeout(() => {
                        const columnArray = event.columnApi.getColumnState();
                        saveColumnWidth(columnArray);
                    }, 1000);
                } else {
                    saveColumnWidth(event.columnApi.getColumnState());
                }
            }
        }

        const onModelUpdated = (params) => {
            if (this.props.tableDataReRendered) {
                setTimeout(() => {
                    this.calculateSubtotal();
                }, 1500)
                // params.api.resetRowHeights();
                this.props.updateState({
                    tableDataReRendered: false
                })
                let actionIndex = 0;
                let amountIndex = 0;
                let txColumnSetting = this.props.txColumnSetting && this.props.txColumnSetting.length ? this.props.txColumnSetting : params.columnApi.getColumnState();
                txColumnSetting.forEach((e, index) => {
                    let columnName = e.colId.split("_")[0];
                    if (columnName !== 'action' && columnName !== 'amount') {
                        if (columnName === 'isChecked' || columnName === 'sno') {
                            e.pinned = 'left';
                            e.lockPosition = true;
                            e.hide = false;
                        } else {
                            e.hide = _isColumnToHide(columnName);
                        }
                    }
                    if (columnName === 'amount') {
                        e.hide = false;
                        amountIndex = index;
                        e.lockPosition = true;
                        e.pinned = 'right';
                    }
                    if (columnName === 'action') {
                        actionIndex = index;
                        e.width = 52;
                        e.hide = false;
                        e.pinned = 'right';
                    }
                });
                const actionObj = txColumnSetting[actionIndex];
                const amountObj = txColumnSetting[amountIndex];
                txColumnSetting = txColumnSetting.filter((e) => {
                    let columnName = e.colId.split("_")[0];
                    return columnName !== 'action' && columnName !== 'amount';
                });
                amountObj.lockPosition = true;
                actionObj.lockPosition = true;
                txColumnSetting.push(amountObj);
                txColumnSetting.push(actionObj);
                params.columnApi.setColumnState(txColumnSetting);
                setTimeout(() => {
                    if (this.gridColumnApi && this.gridColumnApi.columnController.bodyWidth < this.gridColumnApi.columnController.scrollWidth) {
                        params.api.sizeColumnsToFit();
                    }
                }, 500);

            }
        }

        const onDataRendered = (params) => {
            let txColumnSetting = this.props.txColumnSetting;
            let actionIndex = 0;
            let amountIndex = 0;
            if (Object.keys(txColumnSetting).length) {
                txColumnSetting.forEach((e, index) => {
                    let columnName = e.colId.split("_")[0];
                    if (columnName !== 'action' && columnName !== 'amount') {
                        if (columnName === 'isChecked' || columnName === 'sno') {
                            e.pinned = 'left';
                            e.hide = false;
                        } else {
                            e.hide = _isColumnToHide(columnName);
                        }
                    }
                    if (columnName === 'action') {
                        e.width = 52;
                        actionIndex = index;
                        e.hide = false;
                        e.pinned = 'right';
                    }
                    if (columnName === 'amount') {
                        e.hide = false;
                        e.lockPosition = true;
                        amountIndex = index;
                        e.pinned = 'right';
                    }

                });
                const actionObj = txColumnSetting[actionIndex];
                const amountObj = txColumnSetting[amountIndex];
                txColumnSetting = txColumnSetting.filter((e) => {
                    let columnName = e.colId.split("_")[0];
                    return columnName !== 'action' && columnName !== 'amount';
                });
                amountObj.lockPosition = true;
                actionObj.lockPosition = true;
                txColumnSetting.push(amountObj);
                txColumnSetting.push(actionObj);
                this.gridColumnApi.setColumnState(txColumnSetting);
                setTimeout(() => {
                    if (this.gridColumnApi && this.gridColumnApi.columnController.bodyWidth < this.gridColumnApi.columnController.scrollWidth) {
                        params.api.sizeColumnsToFit();
                    }
                }, 500);
            } else {
                this.gridApi.sizeColumnsToFit();
            }
        }


        const getRowHeight = (params) => {
            const rowHeight = params.node.data.scrollHeight ? (Number(params.node.data.scrollHeight.split("px")[0]) - 30) : 30;
            return rowHeight < 30 ? 30 : rowHeight;
        }

        return (
            <Fragment>
               <Skeleton loading={this.props.detailLoading} active paragraph={{ rows: 5 }}>

                <div className="transaction-table">
                    {this.props.txColumns.length ? <CustomAgGrid
                        columnDefs={columnDefs}
                        onGridReady={onGridReady}
                        getRowHeight={getRowHeight}
                        onModelUpdated={onModelUpdated}
                        onColumnResized={onColumnResized}
                        onDragStopped={() => {
                            onColumnMoved({}, true);
                        }}
                        // //onColumnMoved={onColumnMoved}
                        rowData={this.props.poDetailsList || [{}]}
                        gridStyle={{
                            width: '100%',
                            height: '100%',
                            marginBottom: '10px'
                        }} /> : null}
                </div>

                <div style={{ display: 'flex', gap: '10px' }}>

                    <Button className="grey-button" onClick={
                        () => {
                            this.gridApi.updateRowData({ add: [{}] });
                        }
                    }>{<FormattedMessage id='supplier.purchaseOrder.form.button.addLine' defaultMessage='' />}</Button>
                    <Button className="grey-button" onClick={
                        () => {
                            this.props.showModal({
                                modalBody: "Are you sure you want to clear?",
                                handleSubmit: () => {
                                    let gridApi = this.gridApi;
                                    let self = this;
                                    this.gridApi.forEachNode(function (rowNode, index) {
                                        gridApi.updateRowData({ remove: [rowNode.data] });
                                        if (gridApi.rowRenderer.lastRenderedRow < 0) {
                                            gridApi.updateRowData({ add: [{}] });
                                        }
                                        self.calculateSubtotal();
                                    })
                                }
                            })
                        }
                    }><FormattedMessage id='supplier.purchaseOrder.form.button.clearAllLines' defaultMessage='' /></Button>
                </div>
                <div className="upload-btn-so">

                    <CustomTableUpload fileList={this.props.fileList} onChange={
                        (fileList) => {
                            this.props.updateState({
                                fileList
                            })
                        }
                    } />
                </div>
                
                {this.props.inboundDeliveryEditData?.docDetailList?.length ?
                    <>


                        Previous Data

                        <AWSFileTable
                        {...this.props}
                        docDetailList={this.props.inboundDeliveryEditData.docDetailList}
                        fetchTxDetail={(data) => {
                            const val = this.props.inboundDeliveryEditData
                            val.docDetailList = []
                            this.props.updateState({
                                inboundDeliveryEditData: val
                            })
                          this.props.fetchInboundryDetailDetail({
                            supplierId: this.props.inboundDeliveryEditData.supplierId,
                            inboundDeliveryMasterId:
                              this.props.inboundDeliveryEditData.inboundDeliveryMasterId,
                            relationshipId: this.props.inboundDeliveryEditData.relationshipId,
                            poMasterId: this.props.inboundDeliveryEditData.poMasterId,
                          });
                        }}
                      />
                    </>
                    : ""}

                <Row>
                    <Col span={12}>

                        <div>
                            <div className="remarks-title">
                                <FormattedMessage id='supplier.purchaseOrder.form.internalRemarks.label' defaultMessage='' />
                            </div>
                            <CKEditor
                                type="inline"
                                className="description-textarea"
                                key={`${"internalRemarks"}`}
                                data={this.props.internalRemarks}
                                onInit={editor => {
                                    this.editor = editor;
                                }}
                                onChange={(event) => {
                                    const data = event.editor.getData();
                                    this.props.updateState({ internalRemarks: data });
                                }}
                                config={{
                                    ...CUSTOM_CONFIGURATION, placeholder: this.props.intl.formatMessage(
                                        {
                                            id: 'common.Enter.internal.remarks',
                                            defaultMessage: ''
                                        })
                                }}
                            />
                              {/* <TextArea value={this.props.internalRemarks} maxLength={500} className="description-textarea" rows={4} onChange={(e) => {
                            this.props.updateState({
                                internalRemarks: e.target.value
                            });
                        }}
                        placeholder= {this.props.intl.formatMessage(
                            {
                                id: 'common.Enter.internal.remarks',
                                defaultMessage: ''
                            })}
                        ></TextArea> */}
                        </div>

                        <div className="remarks-title">
                            <FormattedMessage id='supplier.purchaseOrder.form.supplierRemarks.label' defaultMessage='' />
                        </div>

                        {/* <TextArea value={this.props.supplierRemarks} maxLength={500} className="description-textarea" rows={4} onChange={(e) => {
                            this.props.updateState({
                                supplierRemarks: e.target.value
                            });
                        }} placeholder="Enter supplier remarks"
                        ></TextArea> */}
                        <CKEditor
                            type="inline"
                            className="description-textarea"
                            key={`${"supplierRemarks"}`}
                            data={this.props.supplierRemarks}
                            onInit={editor => {
                                this.editor = editor;
                            }}
                            onChange={(event) => {
                                const data = event.editor.getData();
                                this.props.updateState({ supplierRemarks: data });
                            }}
                            config={{ ...CUSTOM_CONFIGURATION, placeholder: 'Enter supplier remarks' }}
                        />
                    </Col>

                </Row>

               
                {/* <TextBox
                    type="text"
                    className="add-footer-textarea"
                    key={`${"footer"}`}
                    value={this.props.footer}
                    onChange={(event) => {
                        this.props.updateState({ footer: event.target.value });
                    }}
                    placeholder='Add Footer Here'
                /> */}
  <CKEditor
                    type="inline"
                    className="add-footer-textarea"
                    key={`${"footer"}`}
                    data={this.props.footer}
                    onInit={editor => {
                        this.editor = editor;
                    }}
                    onChange={(event) => {
                        const data = event.editor.getData();
                        this.props.updateState({ footer: data });
                    }}
                    config={{ ...CUSTOM_CONFIGURATION, placeholder: 'Add Footer Here' }}
                />
                <div className="footer">
                    <div className="left-fItems">
                        <Button className="grey-button"                        
                        onClick={() => {
                            this.props.history.goBack();
                            this.props.showModal({
                              modalBody: <FormattedMessage id="modal.cancel.confirmation" defaultMessage=""/>,
                              footer:{submit:{intlId:"button.close.label"}},
                              handleSubmit: () => {
                                this.props.popModalFromStack();
                              }
                            })
                          }}
                        
                        >
                            <FormattedMessage id='button.cancel.label' defaultMessage='' /></Button>

                    </div>
                    <div className="right-fItems">
                        {!this.props.update &&

                            <Button className="ant-btn  ml10"
                            loading={this.props.saveLoading}
                            disabled={this.props.saveLoading}
                                onClick={() => {
                                    createInboundDelivery(true);
                                }}
                            ><FormattedMessage id='button.draft.label' defaultMessage='' /></Button>

                        }
                        <Button className="ant-btn-primary mr10"
                        loading={this.props.saveLoading}
                        disabled={this.props.saveLoading}
                        onClick={() => {
                            createInboundDelivery();
                        }}><FormattedMessage id='button.save.label' defaultMessage='' /></Button>


                    </div>
                </div>
                </Skeleton>
            </Fragment>
        )
    }
};

export default injectIntl(ProductDetails);
