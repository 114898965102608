import React, { Fragment, useState } from "react";
import { FormattedMessage } from "react-intl";
import PageBreadcrumb from "../../../PageBreadcrumb";
import { Button, Card, Col, Row } from "antd";
import "./index.scss";
import HeaderFaviconComponent from "./compoents/HeaderFaviconComponent";
import Banners from "./compoents/Banners";

import MenuIcon2 from "../../../../assets/images/icon/marketPlaceIcon.png";
import HomePageSection from "../../menus/HomePageSection";
import BannerManagement from "../../../../containers/eStore/menus/bannerManagement";
const CustomizeThemes = (props) => {
  const breadCrumbList = [
    {
      name: (
        <FormattedMessage id="sidebar.menuItem.eStores" defaultMessage="" />
      ),
    },
    {
      name: (
        <FormattedMessage id="sidebar.menuItem.appearance" defaultMessage="" />
      ),
    },

    {
      name: (
        <FormattedMessage
          id="sidebar.menuItem.appearance.themes"
          defaultMessage=""
        />
      ),
    },
    {
      name: (
        <FormattedMessage id="sidebar.menuItem.customize" defaultMessage="" />
      ),
    },
  ];
  const inlineStyle = {
    width:"26.947px",
     height:"26.947px"
  };
  const [componentToDisplay, setcomponentToDisplay] = useState("Header&Favicon");

  const [selectedButton, setSelectedButton] = useState("Header & Favicon");
  const renderRightComponent = () => {
    switch (selectedButton) {
      case "Header & Favicon":
        return <HeaderFaviconComponent {...props}/>;
      case "Banners":
        return <Banners {...props} />;
      case "Sections":
        return <h1>Sections</h1>;
      case "Fonts & Colors":
        return <h1>Fonts & Colors</h1>;
      case "Advanced":
        return <h1>Advanced</h1>;
      case "Homepage":
        return <h1>homepage</h1>;
      default:
        return null;
    }
  };

  return (
    <Fragment>
        <div className="StoreSettings-container">
      <div className="view-top-bar">
        <div className="top-view-left">
          <div className="page-heading">
            <FormattedMessage
              id="sidebar.menuItem.customize"
              defaultMessage=""
            />
          </div>
          <div className="vertical-sep" />
          <PageBreadcrumb breadCrumbList={breadCrumbList} />
        </div>
        <div className="top-view-right"></div>
      </div>
      <div className="profile-container">
        <div className="sidenav">
          <div className="settings-body" >
            <div className="settings-body-child">
              <div className="child-body">
              <div className={`lead-tile ${componentToDisplay==='Header&Favicon' ? 'lead-back' : ''} ${componentToDisplay==='' ? 'lead-back' : ''}`}
                onClick={()=>setcomponentToDisplay('Header&Favicon')}>

                  <div className="tile-icon1">
                    <img src={MenuIcon2} style={inlineStyle} alt="" />
                  </div>
                  <div className="tile-title1 b-text">
                    <FormattedMessage
                      id="Header & Favicon"
                      defaultMessage=""
                    />
                  </div>

                  <div className="tile-description1">
                    You are powered with customized your header.
                  </div>
                </div>
              <div className={`lead-tile ${componentToDisplay==='HomePageSection' ? 'lead-back' : ''} `}
                onClick={()=>setcomponentToDisplay('HomePageSection')}>
                  <div className="tile-icon1">
                    <img src={MenuIcon2} style={inlineStyle} alt="" />
                  </div>
                  <div className="tile-title1 b-text">
                   Home Page Section
                  </div>
                  <div className="tile-description1">
                    You are powered with customized your Home Page Section.
                  </div>
                </div>
                {/* <div className="lead-tile" onClick={() => openPreferences(props)}> */}
             

               

                <div className={`lead-tile ${componentToDisplay==='BannerManagement' ? 'lead-back' : ''} `}
                onClick={()=>setcomponentToDisplay('BannerManagement')}>
                  <div className="tile-icon1">
                    <img src={MenuIcon2} style={inlineStyle} alt="" />
                  </div>
                  <div className="tile-title1 b-text">
                    Banner Management
                  </div>
                  <div className="tile-description1">
                    You are powered with customized your Banners.
                  </div>
                </div>
                
              </div>
            </div>
          </div>
        </div>
        <div className="main-body" style={{padding:"20px"}}>
        {componentToDisplay === "Header&Favicon" && <HeaderFaviconComponent {...props}/>}
         {componentToDisplay === "HomePageSection" && <HomePageSection {...props}/>}
        
         {componentToDisplay === "BannerManagement" &&  <BannerManagement {...props}/>}
         
 
      
            
        </div>
      </div>
      {/* <div className="view-container">
        <div className="customize-main-content">
          <Row gutter={16}>
            <Col span={4} style={
              {borderRight:"1px solid #E7E6E5"}

            }>
              <Card className="left-card">
                <Button onClick={() => setSelectedButton("Header & Favicon")}>
                  Header & Favicon
                </Button>
                <Button>
                <div className={`lead-tile ${selectedButton==='Homepage' ? 'lead-back' : ''} `}>

                <div className="tile-icon1">
                    <img src={MenuIcon2} style={inlineStyle} alt="" />
                  </div>
                  <div className="tile-title1 b-text">
                   Home Page Section
                  </div>
                  <div className="tile-description1">
                    You are powered with customized your Home Page Section.
                  </div>
                  </div>
                </Button>
                <Button onClick={() => setSelectedButton("Banners")}>
                  Banners
                </Button>
                <Button onClick={() => setSelectedButton("Sections")}>
                  Sections
                </Button>
                <Button onClick={() => setSelectedButton("Fonts & Colors")}>
                  Fonts & Colors
                </Button>
                <Button onClick={() => setSelectedButton("Advanced")}>
                  Advanced
                </Button>
                <Button onClick={() => setSelectedButton("Homepage")}>
                  Homepage
                </Button>
              </Card>
            </Col>
            <Col span={20} style={{height: 'calc(100vh - 122px)', overflow: 'auto', paddingBottom: '8px' }}>{renderRightComponent()}</Col>
          </Row>
        </div>
      </div> */}
      </div>
    </Fragment>
  );
};

export default CustomizeThemes;
