import React, { Fragment } from "react";
import { injectIntl, FormattedMessage } from "react-intl";
// import { AgGridReact } from 'ag-grid-react';
import { Skeleton, Pagination, Checkbox, Popover, Tooltip, Menu,Empty } from "antd";
import {
  fetchPaginationDataIfNeeded,
  showToasterMessage,
  getMomentDateForUIReadOnly,
  exportExcel,
  fixedDecimalAndFormateNumber,
} from "../../../../utils";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
// import { AG_GRID_CLASS_CONSTANTS } from '../../../../static/cssClassConstants';
import SODetail from "../../../../containers/modal/modalBody/customer/SODetail";
import SQDetail from "../../../../containers/modal/modalBody/customer/SQDetail";
// import RFQDetail from '../../../../containers/modal/modalBody/supplier/RFQDetail';
// import RFQSplitDetail from '../../../../containers/modal/modalBody/supplier/RFQSplitDetail';
// import XeroPayment from '../../../../containers/modal/modalBody/common/xero/Payment';
import SalesInvoiceDetails from "../../../../containers/modal/modalBody/customer/SalesInvoiceDetail";
import CustomerPaymentDetails from "../../../../containers/modal/modalBody/customer/PaymentDetail";
import { CONSTANTS } from "../../../../static/constants";
import XeroIcon from "../../../../assets/images/xero_logo.svg";
import { AddAndRemoveCustomerListingColumn } from "../../../general/AddAndRemoveCustomerListingColumn";
// import { Dropdown } from '../../../general/Dropdown';
// import { AG_GRID_CONSTANTS } from '../../../../static/agGridConstants';
import find from "lodash.find";
import ReactHtmlParser from "react-html-parser";
import {
  FilterOutlined,
  InsertRowRightOutlined,
  FileExcelOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import DropdownAction from "antd/lib/dropdown";
import checkIcon from "../../../../assets/images/check.png";
import crossIcon from "../../../../assets/images/cross.png";
import CustomerProfile from '../../../../containers/modal/modalBody/customer/Profile'

const format = CONSTANTS.DISPLAY_DATE_FORMAT;
// const { RangePicker } = DatePicker;

const CustomerPaymentListingComp = (props) => {
  //    const [isAllChecked, toggleAllChecked] = useState(false);
  const {
    customerPaymentList,
    updateState,
    companyInfo,
    customerPaymentCount,
    pageNumber,
    pageSize,
    selectedItems = [],
  } = props;

  const showTotal = (total) => {
    return (
      props.intl.formatMessage({
        id: "pagination.text.total",
        defaultMessage: "",
      }) +
      ` ${total} ` +
      props.intl.formatMessage({
        id: "pagination.text.items",
        defaultMessage: "",
      })
    );
  };

  const loadPageData = (pageNumber, pageSize) => {
    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      pageNumber: pageNumber,
      pageSize: pageSize,
    };
    fetchPaginationDataIfNeeded(
      "fetchCustomerPayments",
      "customerPaymentList",
      props,
      payload
    );
  };

  const handlePageSizeChange = (pageNumber, pageSize, props) => {
    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      pageNumber: pageNumber,
      pageSize: pageSize,
    };
    props.resetPaginatedData();
    props.fetchCustomerPayments(payload);
    props.updateState({ pageSize: pageSize, pageNumber: pageNumber });
  };

  // const getRowHeight = (params) => {
  //     let height = 35;
  //     return height;
  // }

  const openSoDetails = (data) => {
    const soPayload = {
      customerId: data.customerId,
      salesOrderMasterId: data.salesOrderMasterId,
    };
    props.pushModalToStack({
      modalBody: <SODetail {...props} soPayload={soPayload} />,
      width: "100%",
      hideFooter: true,
      wrapClassName: "modal-custom-detail",
    });
  };

  const openSalesInvoiceDetails = (data) => {
    const invoicePayload = {
      customerId: data.customerId,
      invoiceMasterId: data.invoiceMasterId,
    };
    props.pushModalToStack({
      modalBody: (
        <SalesInvoiceDetails {...props} invoicePayload={invoicePayload} />
      ),
      width: "100%",
      hideFooter: true,
      wrapClassName: "modal-custom-detail",
    });
  };

  // const actionMenu = (param, permittedModules) => (
  //     <Menu className="cell-action-dropdown">
  //         <Menu.Item key="0">
  //             <i className="fa fa-trash" />
  //             <Link onClick={() => {
  //                 props.showModal({
  //                     modalBody: "Delete payment is not allowed, to fix the problem create the journal entry",
  //                     // handleSubmit: () => {
  //                     //     props.deletePayment({
  //                     //         customerPaymentId: param.data.customerPaymentId,
  //                     //         invoiceMasterId: param.data.invoiceMasterId,
  //                     //         relationshipId: param.data.relationshipId
  //                     //     })
  //                     // }
  //                 })

  //             }}>Delete Payment</Link>
  //         </Menu.Item>
  //     </Menu>
  // );

  const openSQDetails = (data) => {
    const sqPayload = {
      customerId: data.customerId,
      salesQuotationMasterId: data.salesQuotationMasterId,
    };
    props.pushModalToStack({
      modalBody: <SQDetail {...props} sqPayload={sqPayload} />,
      width: "100%",
      hideFooter: true,
      wrapClassName: "modal-custom-detail",
    });
  };

  // const openRFQDetails = (data) => {
  //     const rfqPayload = {
  //         rfqMasterId: data.rfqMasterId,
  //         rfqChildMasterId: data.rfqChildMasterId
  //     }
  //     props.pushModalToStack({
  //         modalBody: data.rfqChildMasterId ? <RFQSplitDetail {...props} rfqPayload={rfqPayload} /> : <RFQDetail {...props} rfqPayload={rfqPayload} />,
  //         width: '100%',
  //         hideFooter: true,
  //         wrapClassName: 'modal-custom-detail'
  //     })
  // }

  const openPaymentDetails = (data) => {
    const paymentPayload = {
      customerId: data.customerId,
      customerPaymentId: data.customerPaymentId,
    };
    const modalData = {
      title: "Sales Payment",
      modalBody: <CustomerPaymentDetails paymentPayload={paymentPayload} />,
      width: 500,
      hideFooter: true,
    };
    props.showModal(modalData);
  };

  const exportExcelData = (selectedInvoiceList, props) => {
    const dataList = (selectedInvoiceList || []).map((item) => {
      return {
        "Payment #": item.paymentNumber || "",
        "Invoice #": item.invoiceNumber || "",
        "Payment Date": getMomentDateForUIReadOnly({
          date: new Date(item.paymentDate),
          format,
        }),
        "Customer Name": item.customerName || "",
        "Invoice Amount": Number(item.invoiceAmount || 0).toFixed(2),
        "Payment Amount": Number(item.paymentAmount || 0).toFixed(2),
        "System Status": item.status,
        "Payment Mode": item.paymentModeName || "",
        "Payment Source": item.paymentSource || "",
        "Project Name": item.projectName || "",
      };
    });
    exportExcel(dataList, "Invoices");
  };

  // const saveColumnIndex = (columnState) => {
  //     let data = props.txPreferences || {};
  //     let tablePreference = {};
  //     data.relationshipId = (props.companyInfo || {}).relationshipId;
  //     data.clientUserAccountId = (props.userInfo || {}).userId;
  //     if (data.tablePreference) {
  //         tablePreference = JSON.parse(data.tablePreference);
  //     }
  //     tablePreference.columnSetting = columnState;
  //     tablePreference.columnList = props.txColumns;
  //     data.tablePreference = JSON.stringify(tablePreference);
  //     data.preferenceId = props.preferenceId;
  //     data.gridPreference = props.gridPreference;
  //     props.saveUserPrefrences(data);
  // }

  // const saveColumnWidth = (columnState, width) => {
  //     let data = props.txPreferences || {};
  //     let tablePreference = {};
  //     data.relationshipId = (props.companyInfo || {}).relationshipId;
  //     data.clientUserAccountId = (props.userInfo || {}).userId;
  //     if (data.tablePreference) {
  //         tablePreference = JSON.parse(data.tablePreference);
  //     }
  //     tablePreference.columnSetting = columnState;
  //     tablePreference.columnList = props.txColumns;
  //     data.tablePreference = JSON.stringify(tablePreference);
  //     data.preferenceId = props.preferenceId;
  //     data.gridPreference = props.gridPreference;
  //     props.saveUserPrefrences(data, props);
  // }

  // let moveEvent = {};
  // const onColumnMoved = (event, isDragStopped) => {
  //     if (event.column) {
  //         moveEvent = event;
  //     }
  //     if (isDragStopped && moveEvent.column && moveEvent.column.colDef && moveEvent.source !== AG_GRID_CONSTANTS.EVENT_SOURCE.API) {
  //         saveColumnIndex(moveEvent.columnApi.getColumnState());
  //     }
  // }

  // const onColumnResized = (event, tablegridApi, tableGridColumnApi) => {
  //     const gridColumnApi = tableGridColumnApi || props.gridColumnApi;
  //     event.api.resetRowHeights();
  //     if (event.type === 'columnResized' && event.source !== 'sizeColumnsToFit' && event.source !== 'api' && event.finished === true) {
  //         //event.api.resetRowHeights();
  //         if (gridColumnApi && gridColumnApi.columnController.bodyWidth < gridColumnApi.columnController.scrollWidth) {
  //             props.gridApi.sizeColumnsToFit();
  //             setTimeout(() => {
  //                 const columnArray = event.columnApi.getColumnState();
  //                 saveColumnWidth(columnArray);
  //             }, 1000);
  //         } else {
  //             saveColumnWidth(event.columnApi.getColumnState());
  //         }
  //     }
  // }

  const _isColumnToHide = (columnName) => {
    return props.txColumns.indexOf(columnName) < 0 &&
      props.txMandatoryColumns.indexOf(columnName) < 0
      ? true
      : false;
  };

  // const columnDefs = [
  //     {
  //         field: 'isChecked',
  //         colId: 'isChecked_1',
  //         headerComponentFramework: () => <Fragment />,
  //         cellRendererFramework: (params) => {
  //             return <Checkbox
  //                 onClick={() => {
  //                     params.node.setDataValue('isChecked_1', !params.data.isChecked)
  //                 }}
  //                 checked={params.data.isChecked} />
  //         },
  //         minWidth: 70,
  //         maxWidth: 70,
  //         suppressMovable: true,
  //         pinned: 'left',
  //         lockPosition: true,
  //         resizable: false
  //     },

  //     {
  //         field: 'isXeroUploaded',
  //         colId: 'isXeroUploaded_1',
  //         headerComponentFramework: () => <Tooltip trigger="click" placement="top" title={props.intl.formatMessage(
  //             {
  //                 id: 'tooltip.isInvoiceUploadedToXero',
  //                 defaultMessage: ''
  //             })
  //         }>
  //             Xero <i className="fa fa-question-circle-o pl5 cursor-pointer" />
  //         </Tooltip>,
  //         cellRendererFramework: (params) => {
  //             return params.data.isXeroUploaded ? <img src={XeroIcon} alt="" style={{ height: "25px" }} /> : "";
  //         },
  //         minWidth: 70,
  //         maxWidth: 70,
  //         pinned: 'left',
  //         lockPosition: true,
  //         suppressMovable: true,
  //         hide: _isColumnToHide('isXeroUploaded'),
  //         resizable: true
  //     },

  //     {
  //         headerComponentFramework: () => {
  //             return <FormattedMessage id='customer.customerPayment.listing.header.customer' defaultMessage='' />;
  //         },
  //         field: "customerName",
  //         hide: _isColumnToHide('customerName'),
  //         colId: 'customerName_1',
  //         minWidth: 70,
  //         maxWidth: 400,
  //         suppressMovable: true,
  //         resizable: true,
  //         cellRendererFramework: (link) => {
  //             return <div className="cursor-pointer" >
  //                 <div onClick={() => {
  //                     props.history.push("customers/details", {
  //                         customerId: link.data.customerId,
  //                         customerName: link.data.customerName,
  //                     })
  //                 }}>{link.data.customerName}</div>
  //             </div>
  //         }
  //     },
  //     {
  //         headerComponentFramework: () => {
  //             return <FormattedMessage id='customer.customerPayment.listing.header.paymentNo' defaultMessage='' />;
  //         },
  //         field: "paymentNumber",
  //         hide: _isColumnToHide('paymentNumber'),
  //         colId: 'paymentNumber_1',
  //         minWidth: 150,
  //         maxWidth: 400,
  //         suppressMovable: true,
  //         rowDrag: true,
  //         resizable: true,
  //         cellRendererFramework: (link) => <div className="cursor-pointer" onClick={() => {
  //             openPaymentDetails(link.data)
  //         }}>{link.value}</div>,
  //     },
  //     {
  //         headerComponentFramework: () => {
  //             return <FormattedMessage id='customer.customerPayment.listing.header.invoiceNo' defaultMessage='' />;
  //         },
  //         field: "invoiceNumber",
  //         hide: _isColumnToHide('invoiceNumber'),
  //         colId: 'invoiceNumber_1',
  //         minWidth: 70,
  //         maxWidth: 400,
  //         suppressMovable: true,
  //         resizable: true,
  //         cellRendererFramework: (link) => <div className="cursor-pointer" onClick={() => {
  //             openSalesInvoiceDetails(link.data)
  //         }}>{link.value}</div>,
  //     },
  //     {
  //         headerComponentFramework: () => {
  //             return <FormattedMessage id='customerSalesEnquiryNumber.text' defaultMessage='' />;
  //         },
  //         field: 'customerInquiryNumber',
  //         hide: _isColumnToHide('customerInquiryNumber'),
  //         colId: "customerInquiryNumber_1",
  //         resizable: true,
  //         suppressMovable: true,
  //         minWidth: 70,
  //         maxWidth: 400,
  //         cellRendererFramework: (link) => <div>
  //             {link.data.customerInquiryNumber || '-'}
  //         </div>,
  //     },

  //     {
  //         headerComponentFramework: () => {
  //             return <FormattedMessage id='customerRfqNumber.text' defaultMessage='' />;
  //         },
  //         field: 'customerRFQNumber',
  //         hide: _isColumnToHide('customerRFQNumber'),
  //         colId: "customerRFQNumber_1",
  //         resizable: true,
  //         suppressMovable: true,
  //         minWidth: 70,
  //         maxWidth: 400,
  //         cellRendererFramework: (link) => <div>
  //             {link.data.customerRFQNumber || '-'}
  //         </div>,
  //     },
  //     {
  //         headerComponentFramework: () => {
  //             return <FormattedMessage id='customerPONumber.text' defaultMessage='' />;
  //         },
  //         field: 'customerPONumber',
  //         hide: _isColumnToHide('customerPONumber'),
  //         colId: "customerPONumber_1",
  //         resizable: true,
  //         suppressMovable: true,
  //         minWidth: 70,
  //         maxWidth: 400,
  //         cellRendererFramework: (link) => <div>
  //             {link.data.customerPONumber || '-'}
  //         </div>,
  //     },
  //     {
  //         headerComponentFramework: () => {
  //             return <FormattedMessage id='customer.customerPayment.listing.header.soNo' defaultMessage='' />;
  //         },
  //         field: "soNumber",
  //         hide: _isColumnToHide('soNumber'),
  //         colId: 'soNumber_1',
  //         minWidth: 70,
  //         maxWidth: 400,
  //         suppressMovable: true,
  //         resizable: true,
  //         cellRendererFramework: (link) => <div className="cursor-pointer" onClick={() => {
  //             openSoDetails(link.data)
  //         }}>{link.value}</div>,
  //     },
  //     {
  //         headerComponentFramework: () => {
  //             return <FormattedMessage id='customer.customerPayment.listing.header.quoteNumber' defaultMessage='' />;
  //         },
  //         field: "quotationNumber",
  //         colId: 'quotationNumber_1',
  //         hide: _isColumnToHide('quotationNumber'),
  //         minWidth: 70,
  //         maxWidth: 400,
  //         suppressMovable: true,
  //         cellRendererFramework: (link) => <div className="cursor-pointer" onClick={() => {
  //             openSQDetails(link.data)
  //         }}>{link.value}</div>,
  //         resizable: true
  //     },
  //     // {
  //     //     headerComponentFramework: () => {
  //     //         return <FormattedMessage id='customer.customerPayment.listing.header.rfqNumber' defaultMessage='' />;
  //     //     },
  //     //     field: "rfqNumber",
  //     //     hide: _isColumnToHide('rfqNumber'),
  //     //     width: (props.txColumnSetting['rfqNumber'] || {}).width,
  //     //     cellRendererFramework: (link) => <div className="cursor-pointer" onClick={() => {
  //     //         openRFQDetails(link.data)
  //     //     }}>{link.value}</div>,
  //     //     resizable: true
  //     // },
  //     {
  //         headerComponentFramework: () => {
  //             return <FormattedMessage id="common.description" />;
  //         },
  //         field: "description",
  //         colId: 'description_1',
  //         hide: _isColumnToHide('description'),
  //         sortable: true,
  //         resizable: true,
  //         minWidth: 200,
  //         cellRendererFramework: (params) => {
  //             setTimeout(() => {
  //                 if (params.reactContainer && params.reactContainer.scrollHeight > 30 && params.reactContainer.scrollHeight >= (params.node.data.minHeight || 0)) {
  //                     params.eParentOfValue.style.height = 'inherit';
  //                     params.eParentOfValue.style.height = params.reactContainer.scrollHeight;
  //                     params.node.data.maxHeight = params.reactContainer.scrollHeight;
  //                     params.node.setRowHeight(params.reactContainer.scrollHeight);
  //                     params.api && params.api.onRowHeightChanged();
  //                 }
  //             }, 200)
  //             return ReactHtmlParser(params.data.description)
  //         },
  //     },
  //     {
  //         headerComponentFramework: () => {
  //             return <div><FormattedMessage id='customer.customerPayment.listing.header.paymentAmount' defaultMessage='' />  </div>;
  //         },
  //         field: "paymentAmount",
  //         colId: 'paymentAmount_1',
  //         hide: _isColumnToHide('paymentAmount'),
  //         minWidth: 70,
  //         maxWidth: 400,
  //         suppressMovable: true,
  //         cellRendererFramework: (link) => {
  //             // return link.value && link.value !== '-' ? <span> {link.data.foreignCurrencyIcon ? <i className={link.data.foreignCurrencyIcon}></i> : (link.data.foreignCurrency ? <span>{link.data.foreignCurrency}</span> : (props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "))}{link.value.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} </span> : "-"
  //             return link.value && link.value !== '-' ? <span> {link.data.foreignCurrencyIcon ? <i className={link.data.foreignCurrencyIcon}></i> : (link.data.foreignCurrency ? <span>{link.data.foreignCurrency}</span> : (props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "))}{fixedDecimalAndFormateNumber(link.value)} </span> : "-"
  //         },
  //         valueFormatter: (link) => {
  //             return link.data.paymentAmount ? Number(link.data.paymentAmount).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0;
  //         },
  //         resizable: true,
  //         cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN
  //     },
  //     {
  //         headerComponentFramework: () => {
  //             return <FormattedMessage id='customer.customerPayment.listing.header.paymentDate' defaultMessage='' />;
  //         },
  //         field: "paymentDate",
  //         hide: _isColumnToHide('paymentDate'),
  //         colId: 'paymentDate_1',
  //         minWidth: 70,
  //         maxWidth: 400,
  //         suppressMovable: true,
  //         resizable: true,
  //         cellRendererFramework: (link) => <div>
  //             <Tooltip placement="topLeft" title={(link.data.paymentDate && getMomentDateForUIReadOnly({ date: link.data.paymentDate, format: CONSTANTS.DISPLAY_TOOLTIP_DATE_FORMAT }))}>
  //                 {getMomentDateForUIReadOnly({ date: new Date(link.data.paymentDate), format })}
  //             </Tooltip>
  //         </div>,
  //     },
  //     {
  //         headerComponentFramework: () => {
  //             return <FormattedMessage id='common.project' defaultMessage='' />;
  //         },
  //         field: "project",
  //         hide: _isColumnToHide('project'),
  //         colId: "project_1",
  //         minWidth: 70,
  //         maxWidth: 300,
  //         suppressMovable: true,
  //         cellRendererFramework: (link) => {
  //             return <div>
  //                 {link.data.projectMasterId ? <span>{link.data.projectMasterId + '-' + link.data.projectName}</span> : ''}
  //             </div>
  //         },
  //         resizable: true
  //     },
  //     {
  //         headerComponentFramework: () => {
  //             return <FormattedMessage id='modal.customer.payInvoice.paymentMode' />;
  //         },
  //         field: "paymentMode",
  //         hide: _isColumnToHide('paymentMode'),
  //         colId: "paymentMode_1",
  //         minWidth: 70,
  //         maxWidth: 300,
  //         suppressMovable: true,
  //         cellRendererFramework: (link) => {
  //             return <div>
  //                 {link.data.paymentMode || ''}
  //             </div>
  //         },
  //         resizable: true
  //     },
  //     {
  //         headerComponentFramework: () => {
  //             return <FormattedMessage id='paymentSource.text' />;
  //         },
  //         field: 'paymentSource',
  //         hide: _isColumnToHide('paymentSource'),
  //         colId: "paymentSource_1",
  //         resizable: true,
  //         suppressMovable: true,
  //         minWidth: 70,
  //         maxWidth: 400,
  //         cellRendererFramework: (link) => <div>
  //             {link.data.paymentSource ? <FormattedMessage id={link.data.paymentSource} /> : ''}
  //         </div>,
  //     },
  //     {
  //         headerComponentFramework: () => {
  //             return <FormattedMessage id='transactionId.text' />;
  //         },
  //         field: "transactionId",
  //         hide: _isColumnToHide('transactionId'),
  //         colId: "transactionId_1",
  //         minWidth: 70,
  //         maxWidth: 300,
  //         suppressMovable: true,
  //         cellRendererFramework: (link) => {
  //             return <div>
  //                 {link.data.transactionId || ''}
  //             </div>
  //         },
  //         resizable: true
  //     },
  //     {
  //         headerComponentFramework: () => {
  //             return <FormattedMessage id='giftAid.text' defaultMessage='' />;
  //         },
  //         field: 'isGiftAidIt',
  //         hide: _isColumnToHide('isGiftAidIt'),
  //         colId: "isGiftAidIt_1",
  //         resizable: true,
  //         suppressMovable: true,
  //         minWidth: 70,
  //         maxWidth: 400,
  //         cellRendererFramework: (link) => {
  //             return link.data.isGiftAidIt ? <img src={checkIcon} style={{ 'height': '15px', 'width': '15px' }} alt="Payment" /> : <img src={crossIcon} style={{ 'height': '10px', 'width': '10px' }} alt="Payment" />;
  //         },
  //     },
  //     {
  //         headerComponentFramework: () => {
  //             return <FormattedMessage id='remarks.text' />;
  //         },
  //         field: 'remarksCustomer',
  //         hide: _isColumnToHide('remarksCustomer'),
  //         colId: "remarksCustomer_1",
  //         resizable: true,
  //         suppressMovable: true,
  //         minWidth: 70,
  //         maxWidth: 400,
  //         cellRendererFramework: (link) => <div>
  //             {link.data.remarks ? ReactHtmlParser(link.data.remarks) : '-'}
  //         </div>,
  //     },
  //     // {
  //     //     headerComponentFramework: () => {
  //     //         return <FormattedMessage id='customer.customerPayment.listing.header.status' defaultMessage='' />;
  //     //     },
  //     //     field: "status",
  //     //     hide: _isColumnToHide('status'),
  //     //     colId: 'status_1',
  //     //     minWidth: 70,
  //     //     maxWidth: 400,
  //     //     suppressMovable: true,
  //     //     cellRendererFramework: (params) => {
  //     //         return <div> <span className={["status-code", params.value.toLowerCase()].join(' ')}>{params.value}</span> </div>
  //     //     },
  //     //     resizable: true,
  //     //     cellClass: 'status-cell payment'
  //     // },
  //     // {
  //     //     headerComponentFramework: () => {
  //     //         return ;
  //     //     },
  //     //     cellRendererFramework: (param) => {
  //     //         return <Dropdown overlay={actionMenu(param)} trigger={['click']}>
  //     //             <Button className="action-btn small">
  //     //                 <i className="fa fa-ellipsis-v" />
  //     //             </Button>
  //     //         </Dropdown>
  //     //     },
  //     //     resizable: true,
  //     //     colId: 'action_1',
  //     //     field: 'action',
  //     //     pinned: 'right',
  //     //     lockPosition: true,
  //     //     minWidth: 52,
  //     //     maxWidth: 52,
  //     //     suppressMovable: true,
  //     // }
  // ];

  // const agGridStyle = {
  //     height: '100%',
  //     width: '100%'
  // };

  // const breadCrumbList = [
  //     // {
  //     //     name: 'Dashboard',
  //     //     link: 'dashboard'
  //     // },
  //     // {
  //     //     name: 'Customer',
  //     //     link: 'customers'
  //     // },
  //     {
  //         name: 'Sales',
  //     },
  //     {
  //         name: 'Payments',
  //     }
  // ];

  // const onGridReady = (params) => {
  //     props.updateState({
  //         gridApi: params.api,
  //         gridColumnApi: params.columnApi
  //     });
  //     if (props.isColumnFitTable && params.api) {
  //         params.api.sizeColumnsToFit();
  //         props.updateState({
  //             isColumnFitTable: false
  //         })
  //     }
  //     onDataRendered(params);
  // }

  // const onModelUpdated = (params) => {
  //     if ((customerPaymentList[pageNumber] || []).length && !props.isRowHeightSet) {
  //         setTimeout(() => {
  //             params.api.resetRowHeights();
  //         }, 1000)
  //         props.updateState({
  //             isRowHeightSet: true
  //         })
  //     }
  //     if (props.tableDataReRendered) {
  //         //params.api.resetRowHeights();
  //         props.updateState({
  //             tableDataReRendered: false
  //         })
  //         let txColumnSetting = props.txColumnSetting && props.txColumnSetting.length ? props.txColumnSetting : params.columnApi.getColumnState();
  //         const columnToBeSet = [];
  //         const indexObjArr = TRANSACTION_COLUMN_INDEX.SALES_PAYMENT_LISTING;

  //         indexObjArr.forEach((e) => {
  //             let colObj = find(txColumnSetting, { colId: e + '_1' });
  //             if (colObj) {
  //                 columnToBeSet.push(colObj);
  //             }
  //         });

  //         txColumnSetting = txColumnSetting.filter((ele) => {
  //             let columnName = ele.colId.split("_")[0];
  //             return indexObjArr.indexOf(columnName) === -1;
  //         })
  //         txColumnSetting = columnToBeSet.concat(txColumnSetting);
  //         txColumnSetting.forEach((e) => {
  //             let columnName = e.colId.split("_")[0];
  //             if (columnName !== 'action') {
  //                 if (columnName === 'isChecked') {
  //                     e.hide = false;
  //                 } else {
  //                     e.hide = _isColumnToHide(columnName);
  //                 }
  //             } if (columnName === 'action') {
  //                 e.hide = false;
  //                 e.pinned = 'right';
  //             }
  //         });
  //         params.columnApi.setColumnState(txColumnSetting);
  //         setTimeout(() => {
  //             if (props.gridColumnApi && props.gridColumnApi.columnController.bodyWidth < props.gridColumnApi.columnController.scrollWidth) {
  //                 params.api.sizeColumnsToFit();
  //             }
  //         }, 500);
  //     }
  // }

  // const onDataRendered = (params) => {
  //     let txColumnSetting = props.txColumnSetting && props.txColumnSetting.length ? props.txColumnSetting : params.columnApi.getColumnState();
  //     if (Object.keys(txColumnSetting).length) {
  //         const columnToBeSet = [];
  //         const indexObjArr = TRANSACTION_COLUMN_INDEX.SALES_PAYMENT_LISTING;

  //         indexObjArr.forEach((e) => {
  //             let colObj = find(txColumnSetting, { colId: e + '_1' });
  //             if (colObj) {
  //                 columnToBeSet.push(colObj);
  //             }
  //         });

  //         txColumnSetting = txColumnSetting.filter((ele) => {
  //             let columnName = ele.colId.split("_")[0];
  //             return indexObjArr.indexOf(columnName) === -1;
  //         })
  //         txColumnSetting = columnToBeSet.concat(txColumnSetting);
  //         txColumnSetting.forEach((e) => {
  //             let columnName = e.colId.split("_")[0];
  //             if (columnName !== 'action') {
  //                 if (columnName === 'isChecked') {
  //                     e.hide = false;
  //                     e.pinned = 'left';
  //                 } else {
  //                     e.hide = _isColumnToHide(columnName);
  //                 }
  //             } if (columnName === 'action') {
  //                 e.hide = false;
  //                 e.width = 52;
  //                 e.pinned = 'right';
  //             }
  //         });
  //         params.columnApi.setColumnState(txColumnSetting);
  //         setTimeout(() => {
  //             if (params.columnApi && params.columnApi.columnController.bodyWidth < params.columnApi.columnController.scrollWidth) {
  //                 params.api.sizeColumnsToFit();
  //             }
  //         }, 500);
  //     } else {
  //         params.api.sizeColumnsToFit();
  //     }
  // }

  const downloadMenu = () => {
    return (
      <Menu className="row-action-dropdown">
        <Menu.Item key="0" value="title">
          Download as
        </Menu.Item>

        <Menu.Divider />

        <Menu.Item
          key="1"
          value="excel"
          onClick={() => {
            const paymentList = [];
            // props.gridApi.forEachNode((obj) => {
            //   if (obj.data.isChecked) {
            //     paymentList.push(obj.data);
            //   }
            // });
            selectedItems.forEach((obj) => {
              paymentList.push(obj);
            });

            if (paymentList.length) {
              exportExcelData(paymentList);
            } else {
              showToasterMessage({
                messageType: "error",
                description: props.intl.formatMessage({
                  id: "common.record.notSelected.proceeding",
                }),
              });
            }
          }}
        >
          <FileExcelOutlined />
          Excel File
        </Menu.Item>
      </Menu>
    );
  };

  const openLongtText = (title, Text) => {
    props.showModal({
      title: title,
      modalBody: (
        <div style={{ maxHeight: "550px", overflow: "auto" }}>
          {ReactHtmlParser(Text)}
        </div>
      ),
      hideFooter: true,
      width: "70%",
    });
  };

  const itemSelection = (item) => {
    let selectItem = find(selectedItems, {
      customerPaymentId: Number(item.customerPaymentId),
    });
    let updatedItems = [];
    if (selectItem) {
      updatedItems = selectedItems.filter(
        (obj) => obj.customerPaymentId !== item.customerPaymentId
      );
    } else {
      updatedItems = JSON.parse(JSON.stringify(selectedItems));
      updatedItems.push(item);
    }
    props.updateState({ selectedItems: updatedItems });
  };

  const selectAll = (context) => {
    if (!context) {
      selectedItems.splice(0, selectedItems.length);
      props.updateState({ selectedItems: selectedItems });
      return;
    }
    for (let i = 0; i < customerPaymentList[pageNumber].length; i++) {
      let item = customerPaymentList[pageNumber][i];
      let selectItem = find(selectedItems, {
        customerPaymentId: Number(item.customerPaymentId),
      });
      if (!selectItem) {
        selectedItems.push(item);
      }
    }
  };

  const isCheckedAll = () => {
    if (
      !customerPaymentList ||
      !customerPaymentList[pageNumber] ||
      customerPaymentList[pageNumber].length === 0
    ) {
      return false;
    }
    for (let i = 0; i < customerPaymentList[pageNumber].length; i++) {
      let usr = customerPaymentList[pageNumber][i];
      let selectItem = find(selectedItems, {
        customerPaymentId: Number(usr.customerPaymentId),
      });
      if (!selectItem) {
        return false;
      }
    }
    return true;
  };

  return (
    <Fragment>
      <div className="view-container-actions">
      <Skeleton loading={props.headerLoading} paragraph={false} active>

        <div className="left-actions">
          <div className="table-heading">
            <FormattedMessage
              id="sales.payments.table.heading"
              defaultMessage=""
            />
          </div>
          {pageSize ? (
            <>
              <div className="vertical-sep" />
              {/* <div>{customerPaymentList[pageNumber].length}</div> */}
              {customerPaymentCount}
            </>
          ) : (
            ""
          )}
        </div>
        <div className="right-actions">
          <button
            onClick={() => {
              props.updateState({
                salesPaymentFilterDrawerVisible: true,
              });
            }}
          >
            <FilterOutlined onClick={() => {}} /> &nbsp;{" "}
            <FormattedMessage id="filter.text" defaultMessage="" />
          </button>
          <Popover
            content={<AddAndRemoveCustomerListingColumn {...props} />}
            title={<div className="text-center">Add/Remove</div>}
            placement="leftTop"
            trigger="click"
            arrow="right"
            visible={props.showColPop}
          >
            <button
              onClick={() => {
                props.updateState({ showColPop: !props.showColPop });
              }}
            >
              <InsertRowRightOutlined className='mr5'/>
             Columns
            </button>
          </Popover>

          <DropdownAction overlay={downloadMenu()} trigger={["click"]}>
            <span>
              <DownloadOutlined /> &nbsp;{" "}
              <FormattedMessage
                id="common.action.dropdown.downloadAs"
                defaultMessage=""
              />
            </span>
          </DropdownAction>
        </div>
        </Skeleton>
      </div>
        {/* <div className="d-inline-flex">
              <div>

                {props.isAlifApp ? (
                  ""
                ) : (
                  <button
                    className="action-btn"
                    onClick={() => {
                      const paymentList = [];
                      props.gridApi.forEachNode((obj) => {
                        if (obj.data.isChecked) {
                          paymentList.push(obj.data);
                        }
                      });
                      const modalData = {
                        title: (
                          <FormattedMessage
                            id="xeroPaymentUpload.text"
                            defaultMessage=""
                          />
                        ),
                        modalBody: (
                          <XeroPayment
                            type={"Supplier"}
                            {...props}
                            paymentList={paymentList}
                          />
                        ),
                        width: 1100,
                        hideFooter: true,
                      };

                      if (paymentList.length) {
                        props.showModal(modalData);
                      } else {
                        showToasterMessage({
                          messageType: "error",
                          description: (
                            <FormattedMessage
                              id="paymentBeforeUpload.message"
                              defaultMessage=""
                            />
                          ),
                        });
                      }
                    }}
                  >
                    <Tooltip
                      placement="right"
                      title={props.intl.formatMessage({
                        id: "tooltip.uploadToXero",
                        defaultMessage: "",
                      })}
                    >
                      <img src={XeroIcon} alt="" style={{ height: "20px" }} />{" "}
                      <i className="fa fa-upload" />
                    </Tooltip>
                  </button>
                )}
                <button
                  className="action-btn"
                  style={{
                    marginTop: "-20px",
                    width: "120px",
                    marginRight: "5px",
                  }}
                  onClick={() => {
                    const paymentList = [];
                    props.gridApi.forEachNode((obj) => {
                      if (obj.data.isChecked) {
                        paymentList.push(obj.data);
                      }
                    });

                    if (paymentList.length) {
                      exportExcelData(paymentList);
                    } else {
                      showToasterMessage({
                        messageType: "error",
                        description: props.intl.formatMessage({
                          id: "common.record.notSelected.proceeding",
                        }),
                      });
                    }
                  }}
                  title="Download Excel"
                >
                  <i className="fa fa-file-excel-o" />
                </button>
              </div>
            </div> */}
        {/* <div>
              <Dropdown
                style={{
                  width: "200px",
                  marginRight: "5px",
                }}
                items={props.customers[pageNumber] || []}
                valueKeyName="companyName"
                optionKeyName="customerId"
                value={props.selectedCustomerName}
                onSearch={(searchedText) => {
                  props.fetchCustomers({
                    isCompact: true,
                    searchedText: searchedText,
                    pageNumber: 1,
                    hideSpinner: true,
                    pageSize: 100,
                    relationshipId: (props.companyInfo || {}).relationshipId,
                  });
                }}
                placeholder={props.intl.formatMessage({
                  id: "customer.salesInvoice.form.customer.placeholder",
                  defaultMessage: "",
                })}
                onSelect={(customerId, option) => {
                  updateState({
                    selectedCustomerId: option.value,
                    selectedCustomerName: option.name,
                  });
                }}
                allowClear={true}
                onClear={() => {
                  updateState({
                    selectedCustomerId: undefined,
                    selectedCustomerName: undefined,
                  });
                }}
              />

              <Input
                style={{
                  width: "160px",
                  marginRight: "5px",
                  top: "2px",
                }}
                value={props.invoiceNumberFilter}
                placeholder={props.intl.formatMessage({
                  id: "modal.customer.payInvoice.invoiceNumber",
                })}
                onChange={(e) => {
                  updateState({ invoiceNumberFilter: e.target.value });
                }}
              />

              <Dropdown
                style={{
                  width: "180px",
                  marginRight: "5px",
                }}
                items={props.projectList}
                valueKeyName="projectName"
                optionKeyName="projectMasterId"
                projectIdKey="projectMasterId"
                value={props.selectedProjectName}
                placeholder={props.intl.formatMessage({
                  id: "salesInvoice.project.placeholder",
                })}
                onSelect={(status, option) => {
                  updateState({
                    selectedProjectName: option.name,
                    selectedProjectMasterId: option.value,
                  });
                }}
                allowClear={true}
                onClear={() => {
                  updateState({
                    selectedProjectName: undefined,
                    selectedProjectMasterId: undefined,
                  });
                }}
              />

              <RangePicker
                style={{
                  width: "300px",
                  marginRight: "5px",
                  top: "2px",
                }}
                allowEmpty={true}
                format={format}
                placeholder={["Start date", "End date"]}
                value={[
                  props.startDate
                    ? getMomentDateForUI({
                        date: new Date(props.startDate),
                        format,
                      })
                    : null,
                  props.endDate
                    ? getMomentDateForUI({
                        date: new Date(props.endDate),
                        format,
                      })
                    : null,
                ]}
                onChange={(dates) => {
                  if (dates) {
                    props.updateState({
                      startDate: dates[0],
                      endDate: dates[1],
                    });
                  } else {
                    props.updateState({ startDate: null, endDate: null });
                  }
                }}
              />

              <button
                className="ant-btn ant-btn-default vab ml5"
                onClick={() => {
                  if (
                    (props.startDate && !props.endDate) ||
                    (!props.startDate && props.endDate)
                  ) {
                    return showToasterMessage({
                      messageType: "error",
                      description: props.intl.formatMessage({
                        id: "invalid.dateRange.message",
                      }),
                    });
                  }
                  const payload = {
                    companyInfo,
                    relationshipId: companyInfo.relationshipId,
                    pageNumber: 0,
                    pageSize: props.pageSize,
                    filters: {
                      customerId: props.selectedCustomerId || 0,
                      invoiceNumber: props.invoiceNumberFilter || null,
                      projectName: props.selectedProjectName || null,
                      startDate: props.startDate
                        ? getCurrentDateForBackend(props.startDate)
                        : null,
                      endDate: props.endDate
                        ? getCurrentDateForBackend(props.endDate)
                        : null,
                    },
                  };
                  fetchPaginationDataIfNeeded(
                    "fetchCustomerPayments",
                    "customerPaymentList",
                    props,
                    payload
                  );
                  fetchDataIfNeeded(
                    "getCustomerPaymentsCount",
                    "customerPaymentCount",
                    props,
                    payload
                  );
                }}
              >
                {<FilterOutlined />}
              </button>
            </div> */}

        {/* {props.txColumns.length ? (
              <div>
                <div className="cursor-pointer">
                  <Tooltip placement='left' title={props.intl.formatMessage({ id: 'tooltip.addRemoveColumn.msg' })} trigger='hover'>
                    <span className="top-button">
                      <Popover
                        content={
                          <AddAndRemoveCustomerListingColumn {...props} />
                        }
                        title={
                          <div className="text-center">
                            <FormattedMessage
                              id="common.addRemove"
                              defaultMessage=""
                            />{" "}
                          </div>
                        }
                        placement="left"
                        trigger="click"
                      >
                        <i className="fa fa-bars" />
                      </Popover>
                    </span>
                  </Tooltip>
                </div>
                <AgGridReact
                  onGridReady={onGridReady}
                  columnDefs={columnDefs}
                  rowData={customerPaymentList[pageNumber] || []}
                  rowDragManaged={true}
                  // onGridReady ={(params) => {
                  //     gridApi = params.api;
                  // }}
                  domLayout={"autoHeight"}
                  defaultColDef={{
                    flex: 1,
                    autoHeight: true,
                    wrapText: true,
                  }}
                  animateRows={true}
                  //getRowHeight={getRowHeight}
                  onModelUpdated={onModelUpdated}
                  onColumnResized={onColumnResized}
                  onDragStopped={() => {
                    onColumnMoved({}, true);
                  }}
                  //onColumnMoved={onColumnMoved}
                ></AgGridReact>
              </div>
            ) : null} */}
        <Skeleton loading={props.listLoading} paragraph={{ rows: 13 }} active>
        <div className="table-container">
          <table id="payments-table">
            <thead>
              <tr>
                <th>
                  <Checkbox
                    onChange={(e) => {
                      selectAll(e.target.checked);
                    }}
                    checked={isCheckedAll()}
                  />
                  {/* <button>
                      </button> */}
                </th>
                {!_isColumnToHide("isXeroUploaded") ? (
                  <th>
                    <Tooltip
                      trigger="click"
                      placement="top"
                      title={props.intl.formatMessage({
                        id: "tooltip.isInvoiceUploadedToXero",
                        defaultMessage: "",
                      })}
                    >
                      Xero{" "}
                      <i className="fa fa-question-circle-o pl5 cursor-pointer" />
                    </Tooltip>
                  </th>
                ) : (
                  ""
                )}

                {!_isColumnToHide("paymentNumber") ? (
                  <th>
                    <FormattedMessage
                      id="customer.customerPayment.listing.header.paymentNo"
                      defaultMessage=""
                    />
                  </th>
                ) : (
                  ""
                )}

                {!_isColumnToHide("customerName") ? (
                  <th>
                    <FormattedMessage
                      id="customer.customerPayment.listing.header.customer"
                      defaultMessage=""
                    />
                  </th>
                ) : (
                  ""
                )}

                {!_isColumnToHide("invoiceNumber") ? (
                  <th>
                    <FormattedMessage
                      id="customer.customerPayment.listing.header.invoiceNo"
                      defaultMessage=""
                    />
                  </th>
                ) : (
                  ""
                )}

                {!_isColumnToHide("soNumber") ? (
                  <th>
                    <FormattedMessage
                      id="customer.customerPayment.listing.header.soNo"
                      defaultMessage=""
                    />
                  </th>
                ) : (
                  ""
                )}

                {!_isColumnToHide("quotationNumber") ? (
                  <th>
                    <FormattedMessage
                      id="customer.customerPayment.listing.header.quoteNumber"
                      defaultMessage=""
                    />
                  </th>
                ) : (
                  ""
                )}

                {!_isColumnToHide("customerInquiryNumber") ? (
                  <th>
                    <FormattedMessage
                      id="customerSalesEnquiryNumber.text"
                      defaultMessage=""
                    />
                  </th>
                ) : (
                  ""
                )}
                {!_isColumnToHide("customerRFQNumber") ? (
                  <th>
                    <FormattedMessage
                      id="customerRfqNumber.text"
                      defaultMessage=""
                    />
                  </th>
                ) : (
                  ""
                )}
                {!_isColumnToHide("customerPONumber") ? (
                  <th>
                    <FormattedMessage
                      id="customerPONumber.text"
                      defaultMessage=""
                    />
                  </th>
                ) : (
                  ""
                )}

                {!_isColumnToHide("description") ? (
                  <th>
                    <FormattedMessage id="common.description" />
                  </th>
                ) : (
                  ""
                )}

                {!_isColumnToHide("project") ? (
                  <th>
                    <FormattedMessage id="common.project" defaultMessage="" />
                  </th>
                ) : (
                  ""
                )}

                {!_isColumnToHide("paymentAmount") ? (
                  <th>
                    <FormattedMessage
                      id="customer.customerPayment.listing.header.paymentAmount"
                      defaultMessage=""
                    />{" "}
                  </th>
                ) : (
                  ""
                )}
                {!_isColumnToHide("paymentDate") ? (
                  <th>
                    <FormattedMessage
                      id="customer.customerPayment.listing.header.paymentDate"
                      defaultMessage=""
                    />
                  </th>
                ) : (
                  ""
                )}

                {!_isColumnToHide("paymentMode") ? (
                  <th>
                    <FormattedMessage id="modal.customer.payInvoice.paymentMode" />
                  </th>
                ) : (
                  ""
                )}
                {!_isColumnToHide("paymentSource") ? (
                  <th>
                    <FormattedMessage id="paymentSource.text" />
                  </th>
                ) : (
                  ""
                )}
                {!_isColumnToHide("transactionId") ? (
                  <th>
                    <FormattedMessage id="transactionId.text" />
                  </th>
                ) : (
                  ""
                )}
                {!_isColumnToHide("isGiftAidIt") ? (
                  <th>
                    <FormattedMessage id="giftAid.text" defaultMessage="" />
                  </th>
                ) : (
                  ""
                )}
                {!_isColumnToHide("remarksCustomer") ? (
                  <th>
                    <FormattedMessage id="remarks.text" />
                  </th>
                ) : (
                  ""
                )}
              </tr>
            </thead>
            <tbody>
              {customerPaymentList && customerPaymentList[pageNumber]
                ? customerPaymentList[pageNumber].map((rowData, index) => (
                    <tr key={index}>
                      <td>
                        {
                          <Checkbox
                            // onClick={() => {
                            //   let paymentList = customerPaymentList;
                            //   paymentList[pageNumber][index].isChecked = !rowData.isChecked;
                            //   props.updateState({
                            //     customerPaymentList: paymentList,
                            //   });
                            // }}
                            // checked={rowData.isChecked}
                            onClick={() => {
                              itemSelection(rowData);
                            }}
                            checked={
                              find(selectedItems, {
                                customerPaymentId: Number(
                                  rowData.customerPaymentId
                                ),
                              })
                                ? true
                                : false
                            }
                          />
                        }
                      </td>
                      {!_isColumnToHide("isXeroUploaded") ? (
                        <td>
                          {rowData.isXeroUploaded ? (
                            <img
                              src={XeroIcon}
                              alt=""
                              style={{ height: "25px" }}
                            />
                          ) : (
                            ""
                          )}
                        </td>
                      ) : (
                        ""
                      )}

                      {!_isColumnToHide("paymentNumber") ? (
                        <td>
                          <div
                            className="cursor-pointer"
                            onClick={() => {
                              openPaymentDetails(rowData);
                            }}
                          >
                            {rowData.paymentNumber||<div className="empty-data"></div>}
                          </div>
                        </td>
                      ) : (
                        ""
                      )}

                      {!_isColumnToHide("customerName") ? (
                        <td>
                          <div className="cursor-pointer">
                            <div
                              // onClick={() => {
                              //   props.history.push("customers/details", {
                              //     customerId: rowData.customerId,
                              //     customerName: rowData.customerName,
                              //   });
                              onClick={() => {
                                props.pushModalToStack({  
                          
                                  modalBody: <CustomerProfile {...props}
                                  customerId= {rowData.customerId}
                                //   customerName={rowData.customerName}
                                //   customerDetails={rowData}
                              />,
                              width: '100%',
                              hideTitle: true,  
                              hideFooter: true,
                              wrapClassName: "new-transaction-wrapper",
                              })
                              }}

                            >
                              {rowData.customerName||<div className="empty-data"></div>}
                            </div>
                          </div>
                        </td>
                      ) : (
                        ""
                      )}

                      {!_isColumnToHide("invoiceNumber") ? (
                        <td>
                          <div
                            className="cursor-pointer"
                            onClick={() => {
                              openSalesInvoiceDetails(rowData);
                            }}
                          >
                            {rowData.invoiceNumber||<div className="empty-data"></div>}
                          </div>
                        </td>
                      ) : (
                        ""
                      )}

                      {!_isColumnToHide("soNumber") ? (
                        <td>
                          <div
                            className="cursor-pointer"
                            onClick={() => {
                              openSoDetails(rowData);
                            }}
                          >
                            {rowData.soNumber||<div className="empty-data"></div>}
                          </div>
                        </td>
                      ) : (
                        ""
                      )}

                      {!_isColumnToHide("quotationNumber") ? (
                        <td>
                          <div
                            className="cursor-pointer"
                            onClick={() => {
                              openSQDetails(rowData);
                            }}
                          >
                            {rowData.quotationNumber||<div className="empty-data"></div>}
                          </div>
                        </td>
                      ) : (
                        ""
                      )}

                      {!_isColumnToHide("customerInquiryNumber") ? (
                        <td>{rowData.customerInquiryNumber ||<div className="empty-data"></div>}</td>
                      ) : (
                        ""
                      )}
                      {!_isColumnToHide("customerRFQNumber") ? (
                        <td>{rowData.customerRFQNumber ||<div className="empty-data"></div>}</td>
                      ) : (
                        ""
                      )}
                      {!_isColumnToHide("customerPONumber") ? (
                        <td>{rowData.customerPONumber ||<div className="empty-data"></div>}</td>
                      ) : (
                        ""
                      )}

                      {!_isColumnToHide("description") ? (
                        <td>{ReactHtmlParser(rowData.description) ||<div className="empty-data"></div>}</td>
                      ) : (
                        ""
                      )}

                      {!_isColumnToHide("project") ? (
                        <td>
                          {rowData.projectMasterId ? (
                            <span>
                              {rowData.projectMasterId +
                                "-" +
                                rowData.projectName}
                            </span>
                          ) : (
                            <div className="empty-data"></div>
                          )}
                        </td>
                      ) : (
                        ""
                      )}

                      {!_isColumnToHide("paymentAmount") ? (
                        <td>
                          {rowData.paymentAmount ?
                          rowData.paymentAmount !== "-" ? (
                            <span>
                              {" "}
                              {rowData.foreignCurrencyIcon ? (
                                <i className={rowData.foreignCurrencyIcon}></i>
                              ) : rowData.foreignCurrency ? (
                                <span>{rowData.foreignCurrency}</span>
                              ) : props.companyInfo.currencyIcon ? (
                                <i
                                  className={props.companyInfo.currencyIcon}
                                ></i>
                              ) : (
                                props.companyInfo.currencyCode + " "
                              )}
                              {fixedDecimalAndFormateNumber(
                                rowData.paymentAmount
                              )}{" "}
                            </span>
                          ) : (
                            "-"
                          ) :<div className="empty-data"></div>}
                        </td>
                      ) : (
                        ""
                      )}
                      {!_isColumnToHide("paymentDate") ? (
                        <td>
                          <div>
                            <Tooltip
                              placement="topLeft"
                              title={
                                rowData.paymentDate &&
                                getMomentDateForUIReadOnly({
                                  date: rowData.paymentDate,
                                  format: CONSTANTS.DISPLAY_TOOLTIP_DATE_FORMAT,
                                })
                              }
                            >
                              {getMomentDateForUIReadOnly({
                                date: new Date(rowData.paymentDate),
                                format,
                              })}
                            </Tooltip>
                          </div>
                        </td>
                      ) : (
                        ""
                      )}

                      {!_isColumnToHide("paymentMode") ? (
                        <td>{rowData.paymentModeName ||<div className="empty-data"></div>}</td>
                      ) : (
                        ""
                      )}
                      {!_isColumnToHide("paymentSource") ? (
                        <td>
                          {rowData.paymentSource ? (
                            <FormattedMessage id={rowData.paymentSource} />
                          ) : (
                            <div className="empty-data"></div>
                          )}
                        </td>
                      ) : (
                        ""
                      )}
                      {!_isColumnToHide("transactionId") ? (
                        <td>{rowData.transactionId ||<div className="empty-data"></div>}</td>
                      ) : (
                        ""
                      )}
                      {!_isColumnToHide("isGiftAidIt") ? (
                        <td>
                          {rowData.isGiftAidIt ? (
                            <img
                              src={checkIcon}
                              style={{ height: "15px", width: "15px" }}
                              alt="Payment"
                            />
                          ) : (
                            <img
                              src={crossIcon}
                              style={{ height: "10px", width: "10px" }}
                              alt="Payment"
                            />
                          )}
                        </td>
                      ) : (
                        ""
                      )}
                      {!_isColumnToHide("remarksCustomer") ? (
                        <td>
                          {rowData.remarks ? (
                            <p className="short-text">
                              {ReactHtmlParser(rowData.remarks || "")||<div className="empty-data"></div>}
                            </p>
                          ) : (
                            ""
                          )}
                          {rowData.remarks ? (
                            <span
                              className="more-details-btn"
                              onClick={() => {
                                openLongtText("Remarks", rowData.remarks);
                              }}
                            >
                              show more
                            </span>
                          ) : (
                            ""
                          )}
                        </td>
                      ) : (
                        ""
                      )}
                    </tr>
                  ))
                :  <tr key="empty-data-box">
                <td colSpan={"100%"} style={{ borderBottom: "none" }}>
                    <Empty />
                </td>
            </tr>}
            </tbody>
          </table>
        </div>
      </Skeleton>
      
      <div className="footer">
      <Skeleton loading={props.headerLoading} paragraph={false} active>
        <div className="f-left"></div>
        <div className="f-right">
          <Pagination
            size="small"
            total={customerPaymentCount}
            showTotal={showTotal}
            defaultPageSize={props.pageSize}
            showSizeChanger
            pageSizeOptions={[10, 25, 50, 100, 200]}
            // showQuickJumper
            onChange={(pageNumber, pageSize) => {
              loadPageData(pageNumber, pageSize);
              updateState({ pageSize: pageSize, pageNumber: pageNumber });
            }}
            onShowSizeChange={(pageNumber, pageSize) => {
              handlePageSizeChange(pageNumber || 1, pageSize, props);
            }}
          />
        </div>
        </Skeleton>
      </div>
    </Fragment>
  );
};

export default injectIntl(CustomerPaymentListingComp);
