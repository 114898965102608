import React from 'react';
import { FormattedMessage } from 'react-intl';
// import { MailOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Row, Col, Button } from 'antd';
import { ErrorMsg } from '../../../../general/ErrorMessage';
import { TextBox } from '../../../../general/TextBox';
import { getPermissionData } from '../../../../../utils';
import { MODULE_CODED_VALUES } from '../../../../../static/constants';

const handleSubmit = (props) => {
    const {userInfo} = props;
    
   
    if (!props.submittedOnce) {
        props.updateState({ submittedOnce: true });
    }
    if (props.currentPassword && props.newPassword && props.confirmPassword && props.newPassword === props.confirmPassword) {
        let payload = {
            'password': props.currentPassword,
            'newPassword': props.newPassword,
            'relationshipId': (props.companyInfo || {}).relationshipId,
            'userId': (props.userInfo || {}).userId,
            'email': (userInfo || {}).email || '',
            'isActive': 1,
            // 'dateCreated': '2020-03-13 16:02:59',
            'userCreated': (userInfo || {}).firstName + ((props.userInfo || {}).lastName || '')
        }

        const modalData = {
            modalBody: 
              <FormattedMessage
                id="modal.commmon.submit"
                defaultMessage=""
              />
            ,
            handleSubmit: () => {
              props.changePassword(payload);
              props.popModalFromStack();
            },
          };


          props.pushModalToStack(modalData);



        // props.changePassword(payload);
    } else {
        props.updateState({
            invalidCurrentPasswordClass: props.currentPassword ? '' : 'input-text-error',
            invalidNewPasswordClass: props.newPassword ? '' : 'input-text-error',
            invalidConfirmPasswordClass: (props.confirmPassword && props.confirmPassword === props.newPassword) ? '' : 'input-text-error'
        });

    }

}
const ChangePasswordComponent = (props) => {
    let { currentPassword, newPassword, confirmPassword, intl, updateState,invalidNewPasswordClass, invalidConfirmPasswordClass, submittedOnce ,permissions} = props;
    const primaryPerm = (permissions && permissions.primary) || [];
    const permissionsData = getPermissionData(primaryPerm, MODULE_CODED_VALUES.SETTINGS);
    return (
        <div>
            <div className="modal-dialog">
                <div className="modal-content full-width modify-width">
                    <div className="inner-content">
                        <Form layout="vertical" hideRequiredMark>
                            <Row gutter={16}>
                                <Col span={24}>
                                    <Form.Item>
                                        <Row>
                                            <Col span={24} >
                                                <FormattedMessage id='modal.settings.changePassword.currentPassword' defaultMessage='' />
                                                <span className="required">*</span>
                                            </Col>
                                            <Col span={24}>
                                                <TextBox
                                                    placeholder={intl.formatMessage(
                                                        { id: 'modal.settings.changePassword.currentPassword.placeholder', defaultMessage: ''  }
                                                    )}
                                                    value={currentPassword}
                                                    type='password'
                                                    onChange={(e) => {
                                                        updateState({ currentPassword: e.target.value });
                                                    }}
                                                />
                                                <ErrorMsg
                                                    validator={() => { return !(submittedOnce && invalidNewPasswordClass) || !!currentPassword }}
                                                    message={intl.formatMessage(
                                                        { id: 'modal.settings.changePassword.validation.currentPassword', defaultMessage: '' }
                                                    )} />
                                            </Col>
                                        </Row>
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row gutter={16}>
                                <Col span={24}>
                                    <Form.Item>
                                        <Row>
                                            <Col span={24} >
                                                <FormattedMessage id='modal.settings.changePassword.newPassword' defaultMessage='' />
                                                <span className="required">*</span>
                                            </Col>
                                            <Col span={24}>
                                                <TextBox
                                                    placeholder={intl.formatMessage(
                                                        { id: 'modal.settings.changePassword.newPassword.placeholder', defaultMessage: '' }
                                                    )}
                                                    value={newPassword}
                                                    type='password'
                                                    onChange={(e) => {
                                                        updateState({ newPassword: e.target.value });
                                                    }}
                                                />
                                                <ErrorMsg
                                                    validator={() => { return !(submittedOnce && invalidNewPasswordClass) || !!newPassword }}
                                                    message={intl.formatMessage(
                                                        { id: 'modal.settings.changePassword.validation.newPassword', defaultMessage: '' }
                                                    )} />
                                            </Col>
                                        </Row>
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row gutter={16}>
                                <Col span={24}>
                                    <Form.Item>
                                        <Row>
                                            <Col span={24} >
                                                <FormattedMessage id='modal.settings.changePassword.confirmPassword' defaultMessage='' />
                                                <span className="required">*</span>
                                            </Col>
                                            <Col span={24}>
                                                <TextBox
                                                    placeholder={intl.formatMessage(
                                                        { id: 'modal.settings.changePassword.confirmPassword.placeholder', defaultMessage: '' }
                                                    )}
                                                    value={confirmPassword}
                                                    type='password'
                                                    onChange={(e) => {
                                                        updateState({ confirmPassword: e.target.value });
                                                    }}
                                                    className={invalidConfirmPasswordClass}
                                                />
                                                <ErrorMsg
                                                    validator={() => { return !(submittedOnce && invalidConfirmPasswordClass) || !!confirmPassword }}
                                                    message={intl.formatMessage(
                                                        { id: 'modal.settings.changePassword.validation.confirmPassword', defaultMessage: '' }
                                                    )} />
                                            </Col>
                                        </Row>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </div>

                    <div className="button-group-action">
                        <div className="align-footer">
                            <Button onClick={() => {
                                props.hideModal();
                            }}
                                    type="default"
                                    className='back-white'
                            >
                                <FormattedMessage id='button.cancel.label' defaultMessage='' />
                            </Button>
                            <Button
                                disabled={!(permissionsData.update)}

                                type="primary"
                                // icon={<MailOutlined />}
                                onClick={() => {
                                    handleSubmit(props);
                                }}
                            >
                                <FormattedMessage id='confirmation.submit' defaultMessage='' />
                            </Button>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );

}


export default ChangePasswordComponent;
