import React, { useState } from "react";
import { injectIntl, FormattedMessage } from 'react-intl';
import PageBreadcrumb from "../../PageBreadcrumb";
import { CAMPAIGN_LISTING_PAGE, CONSTANTS, ICONS, MODULE_CODED_VALUES } from '../../../static/constants';
import { Skeleton, Empty, Pagination, Menu, Popover, Spin,DatePicker } from "antd";
import Tile from "../../tiles/tilesIndex";
import img1 from "../../../assets/images/icon/emailIcon1.svg";
import img2 from "../../../assets/images/icon/emailIcon2.svg";
import img3 from "../../../assets/images/icon/emailIcon3.svg";
import img4 from "../../../assets/images/icon/emailIcon4.svg";
import EmailCampaignCon from "../../../containers/modal/modalBody/EmailCampaign";
import ReactHtmlParser from 'react-html-parser';
import TotalMailCampaign from "../../../containers/modal/modalBody/EmailMarketing/TotalMailCampaign";
import TotalSuccess from "../../../containers/modal/modalBody/EmailMarketing/TotalSuccess";
import TotalSent from "../../../containers/modal/modalBody/EmailMarketing/TotalSent";
import TotalFailed from "../../../containers/modal/modalBody/EmailMarketing/TotalFailed";
import DropdownAction from "antd/lib/dropdown";
import { getEmailStatisticsCounts, getMomentDateForUIReadOnly, camelCaseToTitleCase } from "../../../utils";
import { LoadingOutlined } from '@ant-design/icons';
import moment from "moment-timezone";

const { RangePicker } = DatePicker;
const format = CONSTANTS.DISPLAY_DATE_FORMAT;
const EmailCampaignComp = (props) => {
  const { companyInfo, pageNumber = 1, campaignCount, pageSize, campaignEmailStatistics={}, loadingCampaignIds=[] } = props;

  const [showMoreList, setShowMoreList] = useState([]);

  const breadCrumbList = [
    {
      name: <FormattedMessage id='sidebar.menuItem.emailMarketing' defaultMessage='' />
    },
    {
      name: <FormattedMessage id='sidebar.menuHeading.email' defaultMessage='' />
    },
    {
      name: <FormattedMessage id='sidebar.subMenuItem.emailCampaigns' defaultMessage='' />,
    }
  ];

  const tilesData = [
    {
      footer: (
        <FormattedMessage
          id="emailMarketing.emailCampaigns.tiles.mailCampaigns.label"
          defaultMessage=""
        />
      ),
      count: Number(props.campaignCount || 0),
      type: CAMPAIGN_LISTING_PAGE.TOTAL_MAIL_CAMPAIGN,
      antIcon: <img src={img1} alt="" />,
      color: "tile1",
      moduleCode:MODULE_CODED_VALUES.MARKETING_EMAIL
    },
    {
      footer: (
        <FormattedMessage
          id="emailMarketing.emailCampaigns.tiles.activeCampaigns.label"
          defaultMessage=""
        />
      ),
      count: (props.aggregateReport?.delivered || 0),
      antIcon: <img src={img2} alt="" />,
      type: CAMPAIGN_LISTING_PAGE.TOTAL_SUCCESS,
      color: "tile2",
      moduleCode:MODULE_CODED_VALUES.MARKETING_EMAIL
    },
    {
      footer: (
        <FormattedMessage
          id="emailMarketing.emailCampaigns.tiles.mailCampaignssent.label"
          defaultMessage=""
        />
      ),
      count: Number(props.aggregateReport?.delivered || 0) +  Number(props.aggregateReport?.hardBounces || 0) +  Number(props.aggregateReport?.softBounces || 0) +  Number(props.aggregateReport?.invalid || 0),
      type: CAMPAIGN_LISTING_PAGE.TOTAL_SENT,
      antIcon: <img src={img3} alt="" />,
      color: "tile3",
      moduleCode:MODULE_CODED_VALUES.MARKETING_EMAIL
    },
    {
      footer: (
        <FormattedMessage
          id="emailMarketing.emailCampaigns.tiles.mailCampaignsfailed.label"
          defaultMessage=""
        />
      ),
      count: Number(props.aggregateReport?.blocked || 0) +  Number(props.aggregateReport?.hardBounces || 0) +  Number(props.aggregateReport?.softBounces || 0 ) +  Number(props.aggregateReport?.invalid || 0 ),
      type: CAMPAIGN_LISTING_PAGE.TOTAL_FAILED,
      antIcon: <img src={img4} alt="" />,
      color: "tile4",
      moduleCode:MODULE_CODED_VALUES.MARKETING_EMAIL
    },
  ];

  const newFormBuilder = (data) => {

    props.pushModalToStack({
      modalBody: <EmailCampaignCon {...props} marketingCampaignId={data?.marketingCampaignId}/>,
      width: '100%',
      hideTitle: true,
      hideFooter: true,
      keyboard: false,
      wrapClassName: 'emailerCampaign_Modal',
    })
  };

  const replicateTemplate = (data) => {

    props.pushModalToStack({
      modalBody: <EmailCampaignCon {...props} templateType='marketingTemplate' marketingCampaignId={data?.marketingCampaignId} isReplicate={true} />,
      width: '100%',
      hideTitle: true,
      hideFooter: true,
      keyboard: false,
      wrapClassName: 'emailerCampaign_Modal',
      onCancel: () => {
        props.showModal({
          modalBody: <FormattedMessage id="modal.cancel.confirmation" defaultMessage="" />,
          footer: { submit: { intlId: "button.close.label" } },
          handleSubmit: () => {
            props.popModalFromStack();
          }
        })
      }
    })
  };

  const showTotal = (total) => {
    return (
      props.intl.formatMessage({
        id: "pagination.text.total",
        defaultMessage: "",
      })
      +
      ` ${total} ` +
      props.intl.formatMessage({
        id: "pagination.text.items",
        defaultMessage: "",
      })
    );
  };

  const loadPageData = (pageNumber, pageSize) => {
    const payload = {
      relationshipId: companyInfo.relationshipId,
      pageNumber: pageNumber,
      pageSize: pageSize,
    };

    props.fetchCampaign(payload)

  };

  const handlePageSizeChange = (pageNumber, pageSize) => {
    const payload = {
      relationshipId: companyInfo.relationshipId,
      pageNumber: pageNumber,
      pageSize: pageSize,
    };
    props.fetchCampaign(payload);
    // props.updateState({ pageSize: pageSize, pageNumber: pageNumber });
  };

  const getEmailStatus = (campaignId) => {
    const statusObj = getEmailStatisticsCounts(campaignEmailStatistics[campaignId])
    return Object.keys(campaignEmailStatistics[campaignId] || {}).length ? <>
      <div>Total Sent - {statusObj.sent || 0}</div>
      <div className="success">Delivered - {statusObj.delivered || 0}</div>
      <div className="failed">Failed - {statusObj.failed}</div>
    </> 
    : 
   
    // <span className="required">Error in fetching status</span>
    <Spin indicator={<LoadingOutlined spin />} />
  }

  const getMoreEmailStatus = (campaignId) => {

    const statusObj = campaignEmailStatistics[campaignId];
    const allKeys = Object.keys(campaignEmailStatistics[campaignId] || {});

    return allKeys?.length ? allKeys.map((key, index)=>{
      return key !== 'date' ? <div key={index}>{camelCaseToTitleCase(key)} - {statusObj[key]}</div> : ''
    })
    : 
    <Empty />
  }

  const actionMenu = (item) => {
    return (
      <Menu className="row-action-dropdown">
        <Menu.Item key="0" value="title">
          Action
        </Menu.Item>
        <Menu.Divider />

        {/* <Menu.Item key="1" value="edit" onClick={() => newFormBuilder(item)}>
          <i className={ICONS.EDIT} /> Edit
        </Menu.Item> */}

        <Menu.Item onClick={() => replicateTemplate(item)} key="1">
          <i className={ICONS.COPY} /> Replicate
        </Menu.Item>

        {/* <Menu.Item onClick={() => {
          props.showModal({
            modalBody: "Are you sure want to delete?",
            handleSubmit: () => {
              // props.deleteFormBuilder(item, props)
            },
          });
        }} key="3">
          <i className={ICONS.DELETE} /> Delete
        </Menu.Item> */}
        {/* <Menu.Item onClick={() => { }} key="4">
          <i className={ICONS.LINK} /> Copy Link
        </Menu.Item> */}
      </Menu>
    );
  };
  const [selectedRange, setSelectedRange] = useState([null, null]);

  const handleDateChange = (dates) => {
    if (!dates || dates.length === 0) return;
  
    const startDate = dates[0].clone();
    const endDate = dates.length > 1 
      ? moment.min(dates[1].clone(), startDate.clone().add(30, 'days'), moment())
      : startDate.clone().add(30, 'days');
  
    const newRange = [startDate, endDate];
  
    (props.campaignList[pageNumber] || []).forEach((item) => {
      props.fetchCampaignEmailStatistics({
        relationshipId: props.companyInfo.relationshipId,
        campaignId: item.marketingCampaignId,
        startDate: startDate.format("YYYY-MM-DD HH:mm:ss"),
        endDate: endDate.format("YYYY-MM-DD HH:mm:ss"),
      });
    });
  
    setSelectedRange(newRange);
  };
  
  
  
 
  return (
    <>
      <div className="emailCampaigns">
        <div className="view-top-bar">
          <div className="top-view-left">
            <div className="page-heading">
              <FormattedMessage id='heading.communications.emailMarketing' defaultMessage='' />
            </div>
            <span className="vertical-sep" />
            <PageBreadcrumb breadCrumbList={breadCrumbList} />
          </div>
        </div>
        <Tile
          {...props}
          tilesData={tilesData}
          // getSelectedTile={openModalOfSelectedTile}
          className="p10"
        />
        <div className="view-container" >
          <div className="view-container-actions">
            <div className="left-actions">
              <div className="table-heading">
                <FormattedMessage id='emailMarketing.emailCampaign.table.heading' defaultMessage='' />
              </div>
              {
                campaignCount ? <>
                  <div className="vertical-sep" />
                  <div>{campaignCount}</div>
                </> : ''
              }

            </div>
            <div className="right-actions">
            <RangePicker
            allowEmpty={true}
            format={format}
            value={selectedRange}      
            disabledDate={(current) => current && current > moment().endOf('day')}     
            onChange={handleDateChange}  
         
         />
              <button className="create-action-btn" style={{ backgroundColor: "#1cb961", color: '#ffffff' }} onClick={newFormBuilder}>
                <i className={ICONS.ADD} /> <FormattedMessage id="button.create.label" defaultMessage="" />
              </button>
            </div>
          </div>
          <div className="table-container" style={{ height: "calc(100% - 220px)" }}>
            <Skeleton loading={props.listLoading} paragraph={{ rows: 15 }} active>

              <table>
                <thead>
                  <tr>
                    <th>S.No.</th>
                    <th>Campaign Name</th>
                    <th>Template</th>
                    <th style={{ width: '20%' }}>Subject</th>
                    <th style={{ width: '20%' }}>To</th>
                    <th>From</th>
                    <th>Date Created</th>
                    <th style={{ width: '10%' }}>Status</th>
                    <th className="text-center"><FormattedMessage id='common.listing.header.action' defaultMessage="" /></th>

                  </tr>
                </thead>
                <tbody>
                  {
                    (props.campaignList[pageNumber] || []).length ? props.campaignList[pageNumber]
                      .map((rowData, i) => {
                        return <tr key={i}>
                          {/* <td>{(pageNumber - 1) * pageSize + (i + 1)}</td> */}
                          <td>{(i + 1)}</td>

                          <td>
                            {rowData.campaignName}
                          </td>
                          <td>
                            <div className="cursor-pointer"
                              onClick={() => {
                                props.showModal({
                                  modalBody: ReactHtmlParser(rowData?.emailBody),
                                  width: "100%",
                                  height: "100%",
                                  hideFooter: true,
                                  hideTitle: true,
                                  wrapClassName: "image-preview",
                                });
                              }}
                            >
                              {rowData.templateName}
                            </div>
                          </td>
                          <td>
                            {rowData.emailSubject?.length > 30 ?
                              <>
                                {!showMoreList.includes(rowData.marketingCampaignId) ?
                                  <>
                                    <span>{rowData.emailSubject.slice(0, 30)}...</span>
                                    <span className="link" onClick={() => setShowMoreList([...showMoreList, rowData.marketingCampaignId])}>Show More</span>
                                  </>
                                  :
                                  <>
                                    <div>{rowData.emailSubject} {"  "}</div>
                                    <div className="link" onClick={() => setShowMoreList(showMoreList.filter(num => num !== rowData.marketingCampaignId))}>Show Less</div>
                                  </>
                                }
                              </>
                              :
                              (rowData.emailSubject || '')
                            }
                          </td>
                          <td>
                            {/* {rowData.toEmail?.slice(1, -1)} */}
                            {rowData.toEmail?.slice(1, -1)?.split(', ')?.length > 5 ?
                              <>
                                <span>{rowData.toEmail?.slice(1, -1)?.split(', ').slice(0, 5).join(" ")}...</span>
                                <span className="link" onClick={() => {
                                  props.showModal({
                                    hideFooter: true,
                                    title: "To Emails",
                                    width: '40%',
                                    modalBody: rowData.toEmail?.slice(1, -1)?.split(', ')
                                      .map((emailId, i) => (<span key={emailId + i} className="email-tag">{emailId}</span>))
                                  })
                                }}>Show More</span>
                              </>
                              :
                              (rowData.toEmail?.slice(1, -1) || '')
                            }
                          </td>
                          <td>
                            {rowData.createdByUserName || ''}
                          </td>

                          <td>
                            {rowData.dateCreated ? getMomentDateForUIReadOnly({date: rowData.dateCreated}) : ""}
                          </td>

                          <td>
                            <Popover
                              content={getMoreEmailStatus(rowData.marketingCampaignId)}
                              title="More Status"
                              trigger="hover"
                              placement="left"
                              // visible={}
                              // onVisibleChange={this.handleVisibleChange}
                            >
                              <div
                                className={'pointer'}
                                onClick={() => { props.fetchCampaignEmailStatistics({campaignId: rowData.marketingCampaignId}); }} 
                              >
                                <Skeleton loading={campaignEmailStatistics[rowData.campaignId] ? true : false} paragraph={{ rows: 1 }} block={true} active>
                                  {getEmailStatus(rowData.marketingCampaignId)}
                                </Skeleton>

                              </div>
                            </Popover>
                          </td>
                          <td className="text-center">
                            <DropdownAction
                              overlay={actionMenu(rowData)}
                              trigger={["click"]}
                            >
                              <i className={ICONS.MORE_P} />
                            </DropdownAction>
                          </td>
                        </tr>
                      })
                      :
                      <tr key="empty-data-box">
                        <td colSpan={"100%"} style={{ borderBottom: "none" }}>
                          <Empty />
                        </td>
                      </tr>
                  }
                </tbody>
              </table>
            </Skeleton>
          </div>
          <div className="footer">

            <div className="f-left"></div>
            <div className="f-right">
              <Pagination
                size="small"
                current={pageNumber || 1}
                total={campaignCount}
                showTotal={showTotal}
                defaultPageSize={pageSize}
                showSizeChanger
                pageSizeOptions={[10, 25, 50, 100, 200]}
                onChange={(pageNumber, pageSize) => {
                  loadPageData(pageNumber, pageSize);
                  props.updateState({ pageSize: pageSize, pageNumber: pageNumber });
                }}
                onShowSizeChange={(pageNumber, pageSize) => {
                  handlePageSizeChange(pageNumber || 1, pageSize, props);
                }}

              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
};

export default injectIntl(EmailCampaignComp);
