import React, { Fragment } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Skeleton, Pagination, Popover, Tooltip,Empty } from 'antd';
import { fetchPaginationDataIfNeeded, fixedDecimalAndFormateNumber, getMomentDateForUIReadOnly } from '../../../../utils'
import StandardDebitMemo from "../../../../containers/supplier/debitMemo/StandardDebitMemo"
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import './index.scss';
import DebitMemoDetail from '../../../../containers/modal/modalBody/supplier/DebitMemoDetail';
import { CONSTANTS ,MODULE_CODED_VALUES} from '../../../../static/constants';
import { AddAndRemoveSupplierListingColumn } from "../../../general/AddAndRemoveSupplierListingColumn";
import { InsertRowRightOutlined } from '@ant-design/icons';
import SupplierDetails from '../../../../containers/supplier/detail'
import PageHeader from '../../../Common/pageHeader';

const format = CONSTANTS.DISPLAY_DATE_FORMAT;


const DebitmemoListingComp = (props) => {
    const { debitMemoList, updateState, companyInfo, debitMemoCount, pageNumber } = props;

    const showTotal = (total) => {
        return props.intl.formatMessage({ id: 'pagination.text.total', defaultMessage: '' }) + ` ${total} ` + props.intl.formatMessage({ id: 'pagination.text.items', defaultMessage: '' });
    };

    const loadPageData = (pageNumber, pageSize) => {
        const payload = { companyInfo, relationshipId: companyInfo.relationshipId, pageNumber: pageNumber, pageSize: pageSize };
        fetchPaginationDataIfNeeded('fetchDebitMemos', 'debitMemoList', props, payload);
    }
    const handlePageSizeChange = (pageNumber, pageSize, props) => {
        const payload = { companyInfo, relationshipId: companyInfo.relationshipId, pageNumber: pageNumber, pageSize: pageSize };
        props.resetPaginatedData();
        props.fetchDebitMemos(payload);
    }

    const openDebitMemoDetails = (data) => {
        const debitMemoPayload = {
            supplierId: data.supplierId,
            debitMemoMasterId: data.debitMemoMasterId
        }
        props.pushModalToStack({
            modalBody: <DebitMemoDetail {...props} debitMemoPayload={debitMemoPayload} />,
            width: '100%',
            hideFooter: true,
            wrapClassName: 'modal-custom-detail'
        })
    }


    // const saveColumnIndex = (columnState) => {
    //     let data = props.txPreferences || {};
    //     let tablePreference = {};
    //     data.relationshipId = (props.companyInfo || {}).relationshipId;
    //     data.clientUserAccountId = (props.userInfo || {}).userId;
    //     if (data.tablePreference) {
    //         tablePreference = JSON.parse(data.tablePreference);
    //     }
    //     tablePreference.columnSetting = columnState;
    //     tablePreference.columnList = props.txColumns;
    //     data.tablePreference = JSON.stringify(tablePreference);
    //     data.preferenceId = props.preferenceId;
    //     data.gridPreference = props.gridPreference;
    //     props.saveUserPrefrences(data);
    // }

    // const saveColumnWidth = (columnState, width) => {
    //     let data = props.txPreferences || {};
    //     let tablePreference = {};
    //     data.relationshipId = (props.companyInfo || {}).relationshipId;
    //     data.clientUserAccountId = (props.userInfo || {}).userId;
    //     if (data.tablePreference) {
    //         tablePreference = JSON.parse(data.tablePreference);
    //     }
    //     tablePreference.columnSetting = columnState;
    //     tablePreference.columnList = props.txColumns;
    //     data.tablePreference = JSON.stringify(tablePreference);
    //     data.preferenceId = props.preferenceId;
    //     data.gridPreference = props.gridPreference;
    //     props.saveUserPrefrences(data, props);
    // }

    // let moveEvent = {};
    // const onColumnMoved = (event, isDragStopped) => {
    //     if (event.column) {
    //         moveEvent = event;
    //     }
    //     if (isDragStopped && moveEvent.column && moveEvent.column.colDef && moveEvent.source !== AG_GRID_CONSTANTS.EVENT_SOURCE.API) {
    //         saveColumnIndex(moveEvent.columnApi.getColumnState());
    //     }
    // }

    // const onColumnResized = (event, tablegridApi, tableGridColumnApi) => {
    //     const gridColumnApi = tableGridColumnApi || props.gridColumnApi;
    //     event.api.resetRowHeights();
    //     if (event.type === 'columnResized' && event.source !== 'sizeColumnsToFit' && event.source !== 'api' && event.finished === true) {
    //        // event.api.resetRowHeights();
    //         if (gridColumnApi && gridColumnApi.columnController.bodyWidth < gridColumnApi.columnController.scrollWidth) {
    //             props.gridApi.sizeColumnsToFit();
    //             setTimeout(() => {
    //                 const columnArray = event.columnApi.getColumnState();
    //                 saveColumnWidth(columnArray);
    //             }, 1000);
    //         } else {
    //             saveColumnWidth(event.columnApi.getColumnState());
    //         }
    //     }
    // }

    const _isColumnToHide = (columnName) => {
        return (props.txColumns.indexOf(columnName) < 0 && props.txMandatoryColumns.indexOf(columnName) < 0) ? true : false;
    }

    // const columnDefs = [
    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='supplier.debitMemo.listing.header.debitMemoNo' defaultMessage='' />;
    //         },
    //         field: "debitMemoNumber",
    //         colId: "debitMemoNumber_1",
    //         hide: _isColumnToHide('debitMemoNumber'),
    //         suppressMovable: true,
    //         minWidth: 150,
    //         maxWidth: 400,
    //         rowDrag: true,
    //         resizable: true,
    //         cellRendererFramework: (link) => <div className="cursor-pointer" onClick={() => {
    //             openDebitMemoDetails(link.data)
    //         }}>{link.value}</div>,
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='supplier.debitMemo.listing.header.supplier' defaultMessage='' />;
    //         },
    //         field: "supplierName",
    //         colId: "supplierName_1",
    //         hide: _isColumnToHide('supplierName'),
    //         suppressMovable: true,
    //         minWidth: 70,
    //         maxWidth: 400,
    //         resizable: true,
    //         cellRendererFramework: (link) => <div className="cursor-pointer"><Link
    //             to={
    //                 {
    //                     pathname: 'suppliers/details',
    //                     state: {
    //                         supplierId: link.data.supplierId,
    //                         supplierName: link.data.supplierStoreName,
    //                         // supplier: data
    //                     }
    //                 }}
    //             className='company-name'
    //         >{link.value}</Link></div>,
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='supplier.debitMemo.listing.header.date' defaultMessage='' />;
    //         },
    //         field: "debitMemoDate",
    //         colId: "debitMemoDate_1",
    //         hide: _isColumnToHide('debitMemoDate'),
    //         suppressMovable: true,
    //         minWidth: 70,
    //         maxWidth: 300,
    //         resizable: true,
    //         cellRendererFramework: (link) => <div>
    //             <Tooltip placement="topLeft" title={(link.data.debitMemoDate && getMomentDateForUIReadOnly({date: link.data.debitMemoDate, format: CONSTANTS.DISPLAY_TOOLTIP_DATE_FORMAT}))}> 
    //             {getMomentDateForUIReadOnly({date: link.data.debitMemoDate, format})}
    //             </Tooltip>
    //         </div>,
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <div><FormattedMessage id='supplier.debitMemo.listing.header.amount' defaultMessage='' />  </div>;
    //         },
    //         cellRendererFramework: (link) => {
    //             // return link.value && link.value !== '-' ? <span>{link.data.foreignCurrencyIcon ? <i className={link.data.foreignCurrencyIcon}></i> : (link.data.foreignCurrency ? <span>({link.data.foreignCurrency})</span> : (props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "))}{Number(link.value).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }</span> : "-"
    //             return link.value && link.value !== '-' ? <span>{link.data.foreignCurrencyIcon ? <i className={link.data.foreignCurrencyIcon}></i> : (link.data.foreignCurrency ? <span>({link.data.foreignCurrency})</span> : (props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "))}{fixedDecimalAndFormateNumber(Number(link.value)) }</span> : "-"
    //         },
    //         field: "debitMemoTotalAmount",
    //         colId: "debitMemoTotalAmount_1",
    //         hide: _isColumnToHide('debitMemoTotalAmount'),
    //         suppressMovable: true,
    //         minWidth: 70,
    //         maxWidth: 300,
    //         resizable: true,
    //         cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='supplier.debitMemo.listing.header.systemStatus' defaultMessage='' />;
    //         },
    //         field: "status",
    //         colId: "status_1",
    //         hide: _isColumnToHide('status'),
    //         suppressMovable: true,
    //         minWidth: 70,
    //         maxWidth: 300,
    //         cellRendererFramework: (params) => {
    //             return <div> <span className={["status-code", params.value.toLowerCase()].join(' ')}><FormattedMessage id={params.value} defaultMessage={params.value} /></span> </div>
    //         },
    //         resizable: true
    //     },
    //     // {
    //     //     headerComponentFramework: () => {
    //     //         return <div className="cursor-pointer">
    //     //             <Popover
    //     //                 content={<AddAndRemoveSupplierListingColumn {...props} />}
    //     //                 title={<div className="text-center">Add/Remove </div>}
    //     //                 placement="left"
    //     //                 trigger="click"
    //     //             >
    //     //                 <i className='fa fa-bars' />
    //     //             </Popover>
    //     //         </div>;
    //     //     },
    //     //     field: "action",
    //     //     colId: "action_1",
    //     //     pinned: 'right',
    //     //     lockPosition: true,
    //     //     suppressMovable: true,
    //     //     minWidth: 52,
    //     //     maxWidth: 52,
    //     // }
    // ];

    const breadCrumbList = [
        {
            name: <FormattedMessage id='common.purchase' defaultMessage='' />,
        },
        {
            name: <FormattedMessage id='company.companySetup.ledgerAccounts.debitMemo.title' defaultMessage='' />,
        }
    ];

    // const onGridReady = (params) => {
    //     props.updateState({
    //         gridApi: params.api,
    //         gridColumnApi: params.columnApi
    //     });

    //     onDataRendered(params);
    // }

    // const onModelUpdated = (params) => {
    //     if ((debitMemoList[pageNumber] || []).length && !props.isRowHeightSet) {
    //         setTimeout(() => {
    //             params.api.resetRowHeights();
    //         }, 1000)
    //         props.updateState({
    //             isRowHeightSet: true
    //         })
    //     }
    //     if (props.tableDataReRendered) {
    //        // params.api.resetRowHeights();
    //         props.updateState({
    //             tableDataReRendered: false
    //         })
    //         let txColumnSetting = props.txColumnSetting && props.txColumnSetting.length ? props.txColumnSetting : params.columnApi.getColumnState();
    //         const columnToBeSet = [];
    //         const indexObjArr = TRANSACTION_COLUMN_INDEX.DEBIT_MEMO_LISTING;

    //         indexObjArr.forEach((e) => {
    //             let colObj = find(txColumnSetting, { colId: e + '_1' });
    //             if (colObj) {
    //                 columnToBeSet.push(colObj);
    //             }
    //         });

    //         txColumnSetting = txColumnSetting.filter((ele) => {
    //             let columnName = ele.colId.split("_")[0];
    //             return indexObjArr.indexOf(columnName) === -1;
    //         });
    //         txColumnSetting = columnToBeSet.concat(txColumnSetting);
    //         txColumnSetting.forEach((e) => {
    //             let columnName = e.colId.split("_")[0];
    //             if (columnName !== 'action') {
    //                 if (columnName === 'isChecked') {
    //                     e.hide = false;
    //                 } else {
    //                     e.hide = _isColumnToHide(columnName);
    //                 }
    //             } if (columnName === 'action') {
    //                 e.hide = false;
    //                 e.pinned = 'right';
    //             }
    //         });
    //         params.columnApi.setColumnState(txColumnSetting);
    //         setTimeout(() => {
    //             if (props.gridColumnApi && props.gridColumnApi.columnController.bodyWidth < props.gridColumnApi.columnController.scrollWidth) {
    //                 params.api.sizeColumnsToFit();
    //             }
    //         }, 500);
    //     }
    // }

    // const onDataRendered = (params) => {
    //     let txColumnSetting = props.txColumnSetting && props.txColumnSetting.length ? props.txColumnSetting : params.columnApi.getColumnState();
    //     if (Object.keys(txColumnSetting).length) {
    //         const columnToBeSet = [];
    //         const indexObjArr = TRANSACTION_COLUMN_INDEX.DEBIT_MEMO_LISTING

    //         indexObjArr.forEach((e) => {
    //             let colObj = find(txColumnSetting, { colId: e + '_1' });
    //             if (colObj) {
    //                 columnToBeSet.push(colObj);
    //             }
    //         });

    //         txColumnSetting = txColumnSetting.filter((ele) => {
    //             let columnName = ele.colId.split("_")[0];
    //             return indexObjArr.indexOf(columnName) === -1;
    //         })
    //         txColumnSetting = columnToBeSet.concat(txColumnSetting);
    //         txColumnSetting.forEach((e) => {
    //             let columnName = e.colId.split("_")[0];
    //             if (columnName !== 'action') {
    //                 if (columnName === 'isChecked') {
    //                     e.hide = false;
    //                 } else {
    //                     e.hide = _isColumnToHide(columnName);
    //                 }
    //             } if (columnName === 'action') {
    //                 e.hide = false;
    //                 e.width = 52;
    //                 e.pinned = 'right';
    //             }
    //         });
    //         params.columnApi.setColumnState(txColumnSetting);
    //         setTimeout(() => {
    //             if (params.columnApi && params.columnApi.columnController.bodyWidth < params.columnApi.columnController.scrollWidth) {
    //                 params.api.sizeColumnsToFit();
    //             }
    //         }, 500);
    //     } else {
    //         params.api.sizeColumnsToFit();
    //     }
    // }

    const openDm = () => {
        props.pushModalToStack({
            modalBody: <StandardDebitMemo {...props} />,
            width: '100%',
            hideTitle: true,
            hideFooter: true,
            keyboard: false,
            wrapClassName: 'new-transaction-wrapper',
            onCancel: () => {
                props.showModal({
                  modalBody: <FormattedMessage id="modal.cancel.confirmation" defaultMessage=""/>,
                  footer:{submit:{intlId:"button.close.label"}},
                  handleSubmit: () => {
                    props.popModalFromStack();
                  }
                })
              }
        })
    }
    return (
        <Fragment>
           
            <PageHeader {...props}
                pageName="purchase.debit.memo.page.heading"
                breadCrumbList={breadCrumbList}
                canCreate={true}
                onCreate={openDm}
                moduleCode = {MODULE_CODED_VALUES.DEBIT_MEMO}
            />
            <div className='view-container'>               
                    <div className="view-container-actions">
                    <Skeleton loading={props.headerLoading} paragraph={false} active>

                        <div className="left-actions">
                            <div className="table-heading">
                                <FormattedMessage id='purchase.debit.memo.table.heading' defaultMessage='' />
                            </div>
                            {
                                props.debitMemoCount ? <>
                                    <div className="vertical-sep" />
                                    <div>{props.debitMemoCount}</div>
                                </> : ''
                            }
                        </div>
                        <div className="right-actions">
                            <Popover
                                content={<AddAndRemoveSupplierListingColumn {...props} />}
                                title={<div className="text-center"><FormattedMessage id='common.addRemove' defaultMessage='' />  </div>}
                                placement="left"
                                trigger="click"
                                arrow="right"
                                visible={props.showColPop}
                            >
                                <button onClick={() => { props.updateState({ showColPop: !props.showColPop }) }}>
                                    <InsertRowRightOutlined className='mr5'/> Columns
                                </button>
                            </Popover>

                        </div>
                    </Skeleton>    
                    </div>
                    {/* <div className='agGridWrapper'>
                    {props.txColumns.length ?  <div className="ag-theme-balham" style={agGridStyle} >
                            <div className="cursor-pointer">
                                <span className="top-button">
                                    <Popover
                                        content={<AddAndRemoveSupplierListingColumn {...props} />}
                                        title={<div className="text-center"><FormattedMessage id='common.addRemove' defaultMessage='' /> </div>}
                                        placement="left"
                                        trigger="click"
                                    >
                                        <i className='fa fa-bars' />
                                    </Popover>
                                </span>
                                <div className="clearfix"></div>
                            </div>
                            {/* <AgGridReact
                                onGridReady={onGridReady}
                                columnDefs={columnDefs}
                                rowData={debitMemoList[pageNumber] || []}
                                rowDragManaged={true}
                                domLayout={"autoHeight"}
                                animateRows={true}
                                defaultColDef={{
                                    flex:1,
                                    autoHeight: true,
                                    wrapText: true,
                                }}
                                //getRowHeight={getRowHeight}
                                onModelUpdated={onModelUpdated}
                                onColumnResized={onColumnResized}
                                onDragStopped={() => {
                                    onColumnMoved({}, true);
                                }}
                                //onColumnMoved={onColumnMoved}
                            >
                            </AgGridReact> */}
                    {/* </div>: null} */}
                    {/* </div> */}

                    <div className='table-container'>
                    <Skeleton loading={props.listLoading} active paragraph={{ rows: 13 }}>
                        <table>
                            <thead>
                                <tr>
                                    <th>S.No.</th>
                                    {!_isColumnToHide('debitMemoNumber') ? <th><FormattedMessage id='supplier.debitMemo.listing.header.debitMemoNo' defaultMessage='' /></th> : ''}
                                    {!_isColumnToHide('supplierName') ? <th><FormattedMessage id='supplier.debitMemo.listing.header.supplier' defaultMessage='' /></th> : ''}
                                    {!_isColumnToHide('debitMemoDate') ? <th><FormattedMessage id='supplier.debitMemo.listing.header.date' defaultMessage='' /></th> : ''}
                                    {!_isColumnToHide('status') ? <th><FormattedMessage id='supplier.debitMemo.listing.header.systemStatus' defaultMessage='' /></th> : ''}
                                    {!_isColumnToHide('debitMemoTotalAmount') ? <th><FormattedMessage id='supplier.debitMemo.listing.header.amount' defaultMessage='' /></th> : ''}
                                </tr>
                            </thead>
                            <tbody>
                                {debitMemoList && debitMemoList[pageNumber] ?
                                    debitMemoList[pageNumber].map((rowData, index) => (
                                        <tr key={index}>
                                            <td>{(pageNumber - 1) * props.pageSize + (index + 1)}</td>
                                            {!_isColumnToHide('debitMemoNumber') ? <td>
                                                <div className="cursor-pointer" onClick={() => {
                                                    openDebitMemoDetails(rowData)
                                                }}>{rowData.debitMemoNumber}</div>
                                            </td> : ''}
                                            {!_isColumnToHide('supplierName') ? <td>
                                                <div className="cursor-pointer"
                                                onClick={() => {
                                props.pushModalToStack({  
                                 
                                  modalBody: <SupplierDetails {...props}
                                  supplierId= {rowData.supplierId}
                                  supplierName={rowData.supplierName}
                              />,
                              width: '100%',
                              hideTitle: true,
                              hideFooter: true,
                              wrapClassName: "new-transaction-wrapper",
                              })
                             }}
                              
                              >{rowData.supplierName}
                                                    {/* <Link
                                                    to={
                                                        {
                                                            pathname: 'suppliers/details',
                                                            state: {
                                                                supplierId: rowData.supplierId,
                                                                supplierName: rowData.supplierStoreName,
                                                            }
                                                        }}
                                                    className='company-name'
                                                >{rowData.supplierName}</Link> */}
                                                </div>
                                            </td> : ''}
                                            {!_isColumnToHide('debitMemoDate') ? <td>
                                                <div>
                                                    <Tooltip placement="topLeft" title={(rowData.debitMemoDate && getMomentDateForUIReadOnly({ date: rowData.debitMemoDate, format: CONSTANTS.DISPLAY_TOOLTIP_DATE_FORMAT }))}>
                                                        {getMomentDateForUIReadOnly({ date: rowData.debitMemoDate, format })}
                                                    </Tooltip>
                                                </div>
                                            </td> : ''}
                                            {!_isColumnToHide('status') ? <td>
                                                {<div> <span className={["status-code", rowData.status.toLowerCase()].join(' ')}><FormattedMessage id={rowData.status} defaultMessage={rowData.status} /></span> </div>}
                                            </td> : ''}
                                            {!_isColumnToHide('debitMemoTotalAmount') ? <td>
                                                {rowData.debitMemoTotalAmount && rowData.debitMemoTotalAmount !== '-' ? <span>{rowData.foreignCurrencyIcon ? <i className={rowData.foreignCurrencyIcon}></i> : (rowData.foreignCurrency ? <span>({rowData.foreignCurrency})</span> : (props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "))}{fixedDecimalAndFormateNumber(Number(rowData.debitMemoTotalAmount))}</span> : "-"}
                                            </td> : ''}
                                        </tr>
                                    )) :  <tr key="empty-data-box">
                                    <td colSpan={"100%"} style={{ borderBottom: "none" }}>
                                        <Empty />
                                    </td>
                                </tr>
                                }
                            </tbody>
                        </table>
                </Skeleton>
                    </div>
                <div className="footer">
                <Skeleton loading={props.headerLoading} paragraph={false} active>

                    <div className="f-left"></div>
                    <div className="f-right">

                        <Pagination
                            size="small"
                            total={debitMemoCount}
                            showTotal={showTotal}
                            defaultPageSize={props.pageSize} showSizeChanger pageSizeOptions={[10, 25, 50, 100, 200]}
                            // showQuickJumper
                            onChange={(pageNumber, pageSize) => {
                                loadPageData(pageNumber, pageSize);
                                updateState({ pageSize: pageSize, pageNumber: pageNumber })
                            }}
                            onShowSizeChange={(pageNumber, pageSize) => {
                                handlePageSizeChange(pageNumber, pageSize, props)
                            }}
                        />
                    </div>
                </Skeleton>
                </div>
            </div>
        </Fragment>
    );
};

export default injectIntl(DebitmemoListingComp);
