import { Col, Row, Switch, Tooltip,Upload,Card ,Button } from "antd";
import React, { useState ,useRef } from "react";
import { FormattedMessage } from "react-intl";
import { Form } from "@ant-design/compatible";
import "../../../../eStore/storeSettings/store-settings.scss";
import { UploadOutlined, CloseOutlined ,GoogleOutlined } from "@ant-design/icons";
import { InfoCircleOutlined, RightOutlined } from "@ant-design/icons";
import { getAWSFileURL, getPermissionData } from "../../../../../utils";
import google from "../../../../../assets/images/google.png"
import { TextBox } from "../../../../general/TextBox";
import storeDef from "../../../../../assets/images/store-def.jpg";
import { MODULE_CODED_VALUES } from "../../../../../static/constants";
const SeoComp = (props) => {
  const {permissions} = props;
  const primaryPerm = (permissions && permissions.primary) || [];
  const permissionsData = getPermissionData(primaryPerm, MODULE_CODED_VALUES.E_STORE_SETTING);
  let [isFocus, setIsFocus] = useState(false);
  const len = 340;
 
  const [fileList, setFileList] = useState([]);
  const handleRemove = () => {
    setFileList([]);
  };
  const handleChange = (info) => {
    if (info.file.name) {
      props.updateState({socialSharingImage:info.fileList[0] ? info.fileList[0]: null})
    
      
      
    } else {
    }
  };
  const image = getAWSFileURL(props.storeValues?.socialSharingImage ,'inventory-u')
  const HandleSave =()=>{

  const payload ={
    file: props.socialSharingImage,
    ecomSettingSave: {...props.storeValues ,homePageTitle:props.homePageTitle,
      homePageDescription: props.homePageDescription}
  }
    const modalData ={
        modalBody: (
          <FormattedMessage
            id="customer.salesQuote.edit.form.confirmation"
            defaultMessage=""
          />
        ),
        handleSubmit: () => {
          props.hideModal()
          props.updateOrCreateEcomSettings(payload,props)
         
        }
       }
       props.showModal(modalData)
    
  }

  return (
    <div className="store-model-container cus-space">
      <div className="store-main-container">
      <div className="model-head">
        <p className="heading">Title and Meta Description</p>
        <p className="sub-heading">
          The title and meta description help define how your store shows up on
          search engines.
        </p>
      </div>
      <div  className="model-container">
        <Row>
          <Col span={14}>
          <Row gutter={[0,16]}>
        <Col span={24} className="">
          <div className="i-label"><b>Meta Title Tag</b></div>
          <TextBox
            placeholder="Enter Title"
            type="text"
            countStyle={{top:"30px"}}
            //   prefix={field.icon}
           
            maxLength={100}
            value={props.homePageTitle}
              onChange={(e) =>{
                props.updateState({homePageTitle: e.target.value})
              }}
          />
        </Col>
        </Row>
        <Row gutter={[0,16]}>
        <Col span={24} className="">
          <div className="i-label"><b>Meta Description Tag</b></div>
          <textarea
            value={props.homePageDescription}
            rows={5}
            cols={70}
            maxLength={len}
            onFocus={() => {
              setIsFocus(true);
            }}
            onChange={(e) =>{
              props.updateState({homePageDescription: e.target.value})
            }}
            style={{ width: "100%", padding: "10px" ,resize:"none"}}
            placeholder="Enter Meta Description"
          />
            {isFocus ? (
        <div
          style={{
            right: "20px",
            top: "auto",
            textAlign: "right",
            position: "absolute",
            // top:"40px",
          }}
        >
          {len && (props.homePageDescription || "").length
            ? props.homePageDescription.length + " / " + len + " chars"
            : ""}
        </div>
      ) : null}
        </Col>
        </Row>
        <Row>
        {/* <Col span={24} className="seo-input">
          <div className="i-label"><b>Permalink</b></div>
          <TextBox
            placeholder="Enter Link"
            type="text"
            //   prefix={field.icon}
           
            maxLength={75}
            value={seoData.link}
              onChange={(e) =>setSeoData({...seoData ,link:e.target.value})}
          />
        </Col> */}
        </Row>
        <Row>
        <Col span={24} className="seo-input">
          <div className="">
            {" "}
            <p><b>Social sharing image preview</b></p>
            <p className="sub-heading">
              When you share a link to your store on social media, an image is
              usually shown in your post. This one will be used if another
              relevant image can’t be found.
            </p>
          </div>
          <div class="seo-image-wrapper">
            <div class="upload-image">
        
            </div>
            <Upload
                  fileList={fileList}
                  name="file"
                 
                  onChange={handleChange}
                  beforeUpload={(file) => {
                    // Allow only a single file upload, you can adjust this as needed
                    setFileList([file]);
                    return false;
                  }}
                >
            <div class="seo-image-upload">
                {
                    (fileList.length>0 ) ?  (<Card className="card-img-prev">
                    <img src={URL.createObjectURL(fileList[0])} alt="Preview" width="100px"/>
                    {/* <Button className="close-btn-pre" onClick={handleRemove}>
                      <CloseOutlined />
                    </Button> */}
                  </Card>):( 
                     <img
                     className="fav-icon-image"
                     width="100px"
                     height="95px"
                     src={image?image:storeDef}
                     alt="Fallback"
                   />
                        )
                }
              <div style={{position:"absolute",top:"auto",right:"auto" ,
              display:`${(fileList.length>0)?'none':'block'}`}}>
               
                 
                  {
                    !(fileList.length>0 || image) &&(<>
                     <p class="add-image">+ Add image</p>
                    <p class="picture-size">(Recommended size : 1200 x 628 px)</p>
                    </>)

                  }
                 
                
                     
                        </div>
             
            </div>
                </Upload>
            <div class="seo-info">
              <p class="section-text-3 mb8 d-block word-break">
                {props.homePageTitle}
              </p>
           
                <a href={`https://${props.storeValues?.websiteUrl}`} target="_blank" rel="noopener noreferrer"> {props.storeValues?.websiteUrl}</a>
              
       
              <p class="text-8" style={{wordBreak:"break-word"}}>
               {props.homePageDescription}
              </p>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
      <Col span={24} className="google-search">
         <div className="robots">
          <div>
              <b>Robots.txt</b>
              <p> The title and meta description help define how your store shows up on
          search engines.</p>
          </div>
          <div>
          <Switch
                      checked={
                        
                       
                        props.storeValues?.isRobots
                      }
                      onClick={(checked, e) => {

                         const val = props.storeValues?.isRobots === 1 ? 0 : 1;
                          const value = {...props.storeValues ,isRobots:val}
                          const payload ={
                            ecomSettingSave: value,
                          }
                           props.updateOrCreateEcomSettings(payload ,props)
                        // const val = props.storeData;
                        // let tempFilter = val;
                        //                 tempFilter.isRobots = tempFilter.isRobots === 1 ? 0 : 1;
                        //                 props.updateState({val: tempFilter})
                        // props.setMakeAnEnquiry({
                        //   relationshipId: props.relationshipId,
                        //   isRobots: checked ? 1 : 0,
                        // });
                      }}
                    />
          </div>
         </div>
        </Col>
      </Row>
          </Col>
          <Col span={10}>
            <div className="seo-preview">
            <div className="preview">
              <span> <img src={google} width="50px" alt="google"/> </span>
              <span style={{fontSize:"14px" ,fontWeight:"700"}}>Google Search Preview</span>
              
            </div>
            <div  style={{fontSize:"16px" ,fontWeight:"500" ,color:"blue"}}>{props.homePageTitle}</div>
            {/* <div className="seo-data-link"><a href="/"  >{seoData.link}</a></div> */}
            <div style={{fontSize:"12px",wordBreak:"break-word"}}> 
            {props.homePageDescription}
            </div>
            </div>
          </Col>
        </Row>
      
      </div>
      </div>
    
      <div className="footer-btn" >
      <Button type="primary"
        disabled={!(permissionsData.update)}

      onClick={()=>HandleSave()}>Submit</Button>
      </div>
    </div>
  );
};

export default SeoComp;
