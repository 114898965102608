import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import TimeTableModalComp from '../../../../../components/modal/modalBody/schoolManagement/TimeTable';
import { getTimeTableByClassSection } from '../../../../schoolManagement/TimeTable/action';
// import { getTimeTableByClassSection } from './action';

class TimeTableModalCont extends Component {

    constructor(props){
        super(props);
        this.state= {
        }
    }

    componentWillMount() {
        // const companyInfo = this.props.companyInfo || {};
    }

    render() {
        return <div>
            <TimeTableModalComp {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
        </div>
    }
}

const mapStateToProps = (state) => {
    return {
        companyInfo: state.common.companyInfo,
        relationshipId: state.common.companyInfo.relationshipId,
        timeTableList: state.school.timeTableList,
        listloading:state.common.listLoading
    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    getTimeTableByClassSection,
    
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(TimeTableModalCont);