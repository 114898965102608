import axios from 'axios';
import config from '../../../config/environmentConfig';
import { COMMON_ACTIONS, CUSTOMER_ACTION_LIST } from '../../../static/constants';
import { showToasterMessage, getFinancialYear } from '../../../utils';
import download from 'downloadjs';
const { lang } = require('../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');

export const fetchCustomerTransactions = (payload) => {
  let FY = getFinancialYear(payload.companyInfo);
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/customerTransaction/getCustomerTransactions?customerId=${payload.customerId}&relationshipId=${payload.relationshipId}&pageSize=${payload.pageSize || 10}&pageNumber=${(payload.pageNumber || 1) - 1}&startDate=${FY.fyStartDate}&endDate=${FY.fyEndDate}&filterStartDate=&filterEndDate=`)
      .then(res => {
        const data = {
          pageNo: payload.pageNumber,
          list: res.data
        }
        dispatch({ type: CUSTOMER_ACTION_LIST.FETCH_TRANSACTIONS, data });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        } else {
          err.actionToCall = CUSTOMER_ACTION_LIST.FETCH_TRANSACTIONS;
          err.data = {
            pageNo: payload.pageNumber,
            list: []
          };
        }
      })
  }
}

export const fetchCustomerTransactionCount = (payload) => {
  let FY = getFinancialYear(payload.companyInfo);
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/customerTransaction/getCustomerTransactionsCount?customerId=${payload.customerId}&relationshipId=${payload.relationshipId}&startDate=${FY.fyStartDate}&endDate=${FY.fyEndDate}&filterStartDate=&filterEndDate=`)
      .then(res => {
        dispatch({ type: CUSTOMER_ACTION_LIST.FETCH_TRANSACTIONS_COUNT, data: res.data });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        } else {
          err.actionToCall = CUSTOMER_ACTION_LIST.FETCH_TRANSACTIONS_COUNT;
        }
      })
  }
}



export const fetchCustomerRfqTransactions = (payload) => {
  let FY = getFinancialYear(payload.companyInfo);
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/rfqRest/getRFQsByCustomerId?customerId=${payload.customerId}&relationshipId=${payload.relationshipId}&pageSize=${payload.pageSize || 10}&pageNumber=${payload.pageNumber - 1}&startDate=${FY.fyStartDate}&endDate=${FY.fyEndDate}&filterStartDate=&filterEndDate=`)
      .then(res => {
        const data = {
          pageNo: payload.pageNumber,
          list: res.data
        }
        dispatch({ type: CUSTOMER_ACTION_LIST.FETCH_RFQ_TRANSACTIONS, data });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        } else {
          err.actionToCall = CUSTOMER_ACTION_LIST.FETCH_RFQ_TRANSACTIONS;
          err.data = {
            pageNo: payload.pageNumber,
            list: []
          };
        }
      })
  }
}

export const fetchCustomerRfqCount = (payload) => {
  let FY = getFinancialYear(payload.companyInfo);
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/rfqRest/countRFQByCustomerId?customerId=${payload.customerId}&relationshipId=${payload.relationshipId}&startDate=${FY.fyStartDate}&endDate=${FY.fyEndDate}&filterStartDate=&filterEndDate=`)
      .then(res => {
        dispatch({ type: CUSTOMER_ACTION_LIST.FETCH_RFQ_TRANSACTIONS_COUNT, data: res.data });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        } else {
          err.actionToCall = CUSTOMER_ACTION_LIST.FETCH_RFQ_TRANSACTIONS_COUNT;
        }
      })
  }
}


export const fetchCustomerSqTransactions = (payload) => {
  let FY = getFinancialYear(payload.companyInfo);
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/salesQuotation/getAllSalesQuotations?customerId=${payload.customerId}&relationshipId=${payload.relationshipId}&pageSize=${payload.pageSize || 10}&pageNumber=${payload.pageNumber - 1}&startDate=${FY.fyStartDate}&endDate=${FY.fyEndDate}&filterStartDate=&filterEndDate=`)
      .then(res => {
        const data = {
          pageNo: payload.pageNumber,
          list: res.data
        }
        dispatch({ type: CUSTOMER_ACTION_LIST.FETCH_SQ_TRANSACTIONS, data });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        } else {
          err.actionToCall = CUSTOMER_ACTION_LIST.FETCH_SQ_TRANSACTIONS;
          err.data = {
            pageNo: payload.pageNumber,
            list: []
          };
        }
      })
  }
}

export const fetchCustomerSqCount = (payload) => {
  let FY = getFinancialYear(payload.companyInfo);
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/salesQuotation/getTotalQuotationsCountByRid?customerId=${payload.customerId}&relationshipId=${payload.relationshipId}&startDate=${FY.fyStartDate}&endDate=${FY.fyEndDate}&filterStartDate=&filterEndDate=`)
      .then(res => {
        dispatch({ type: CUSTOMER_ACTION_LIST.FETCH_SQ_TRANSACTIONS_COUNT, data: res.data });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        } else {
          err.actionToCall = CUSTOMER_ACTION_LIST.FETCH_SQ_TRANSACTIONS_COUNT;
        }
      })
  }
}




export const fetchCustomerSalesOrderTransactions = (payload) => {
  let FY = getFinancialYear(payload.companyInfo);
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/customerTransaction/getSalesTransacions?customerId=${payload.customerId}&relationshipId=${payload.relationshipId}&pageSize=${payload.pageSize || 10}&pageNumber=${payload.pageNumber - 1}&startDate=${FY.fyStartDate}&endDate=${FY.fyEndDate}&filterStartDate=&filterEndDate=`)
      .then(res => {
        const data = {
          pageNo: payload.pageNumber,
          list: res.data
        }
        dispatch({ type: CUSTOMER_ACTION_LIST.FETCH_SALES_ORDER_TRANSACTIONS, data });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        } else {
          err.actionToCall = CUSTOMER_ACTION_LIST.FETCH_SALES_ORDER_TRANSACTIONS;
          err.data = {
            pageNo: payload.pageNumber,
            list: []
          };
        }
      })
  }
}

export const fetchCustomerSalesOrderTransactionCount = (payload) => {
  let FY = getFinancialYear(payload.companyInfo);
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/customerTransaction/getSalesTransactionsCount?customerId=${payload.customerId}&relationshipId=${payload.relationshipId}&startDate=${FY.fyStartDate}&endDate=${FY.fyEndDate}&filterStartDate=&filterEndDate=`)
      .then(res => {
        dispatch({ type: CUSTOMER_ACTION_LIST.FETCH_SALES_ORDER_TRANSACTIONS_COUNT, data: res.data });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        } else {
          err.actionToCall = CUSTOMER_ACTION_LIST.FETCH_SALES_ORDER_TRANSACTIONS_COUNT;
        }
      })
  }
}

export const fetchCustomerInvoiceList = (payload) => {
  let FY = getFinancialYear(payload.companyInfo);
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LIST_LOADER });
    return axios
      // .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/customerInvoice/getInvoiceByCustomerId?customerId=${payload.customerId}&relationshipId=${payload.relationshipId}&pageSize=${payload.pageSize || 10}&pageNumber=${payload.pageNumber - 1}&startDate=${FY.fyStartDate}&endDate=${FY.fyEndDate}&filters=${encodeURIComponent(JSON.stringify(payload.filters || {}))}`)
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/customerInvoice/getAllInvoices?customerId=${payload.customerId}&relationshipId=${payload.relationshipId}&pageSize=${payload.pageSize || 10}&pageNumber=${(payload.pageNumber || 1) - 1}&startDate=${FY.fyStartDate}&endDate=${FY.fyEndDate}&filters=${encodeURIComponent(JSON.stringify(payload.filters || {}))}`)
      .then(res => {
        const data = {
          pageNo: payload.pageNumber,
          list: res.data
        }
        dispatch({ type: CUSTOMER_ACTION_LIST.FETCH_INVOICE_LIST, data });
      })
      .catch((err) => {
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        } else {
          err.actionToCall = CUSTOMER_ACTION_LIST.FETCH_INVOICE_LIST;
          err.data = {
            pageNo: payload.pageNumber,
            list: []
          };
        }
      }).finally(() => {
        dispatch({
          type: COMMON_ACTIONS.HIDE_LIST_LOADER,
        });
      })
  }
}

export const fetchCustomerInvoiceCount = (payload) => {
  let FY = getFinancialYear(payload.companyInfo);
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/customerInvoice/getTotalInvoiceCountByCustomerId?customerId=${payload.customerId}&relationshipId=${payload.relationshipId}&startDate=${FY.fyStartDate}&endDate=${FY.fyEndDate}&filterStartDate=&filterEndDate=&filters=${encodeURIComponent(JSON.stringify(payload.filters || {}))}`)
      .then(res => {
        dispatch({ type: CUSTOMER_ACTION_LIST.FETCH_INVOICE_LIST_COUNT, data: res.data });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        } else {
          err.actionToCall = CUSTOMER_ACTION_LIST.FETCH_INVOICE_LIST_COUNT;
        }
      })
  }
}

export const fetchCustomerCreditMemoList = (payload) => {
  let FY = getFinancialYear(payload.companyInfo);
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/creditMemo/getCreditMemosByCustomerId?customerId=${payload.customerId}&relationshipId=${payload.relationshipId}&pageSize=${payload.pageSize || 10}&pageNumber=${payload.pageNumber - 1}&startDate=${FY.fyStartDate}&endDate=${FY.fyEndDate}&filterStartDate=&filterEndDate=`)
      .then(res => {
        const data = {
          pageNo: payload.pageNumber,
          list: res.data
        }
        dispatch({ type: CUSTOMER_ACTION_LIST.FETCH_CREDIT_MEMO_LIST, data });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        } else {
          err.actionToCall = CUSTOMER_ACTION_LIST.FETCH_CREDIT_MEMO_LIST;
          err.data = {
            pageNo: payload.pageNumber,
            list: []
          };
        }
      })
  }
}

export const fetchCustomerCreditMemoListCount = (payload) => {
  let FY = getFinancialYear(payload.companyInfo);
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/creditMemo/getCreditMemoTransactionsCount?customerId=${payload.customerId}&relationshipId=${payload.relationshipId}&startDate=${FY.fyStartDate}&endDate=${FY.fyEndDate}&filterStartDate=&filterEndDate=`)
      .then(res => {
        dispatch({ type: CUSTOMER_ACTION_LIST.FETCH_CREDIT_MEMO_LIST_COUNT, data: res.data });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        } else {
          err.actionToCall = CUSTOMER_ACTION_LIST.FETCH_CREDIT_MEMO_LIST_COUNT;
        }
      })
  }
}

export const fetchCustomerPaymentList = (payload) => {
  let FY = getFinancialYear(payload.companyInfo);
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/customerPayment/getAllPaymentsByCustomerId?customerId=${payload.customerId}&relationshipId=${payload.relationshipId}&pageSize=${payload.pageSize || 10}&pageNumber=${payload.pageNumber - 1}&startDate=${FY.fyStartDate}&endDate=${FY.fyEndDate}&filterStartDate=&filterEndDate=`)
      .then(res => {
        const data = {
          pageNo: payload.pageNumber,
          list: res.data
        }
        dispatch({ type: CUSTOMER_ACTION_LIST.FETCH_PAYMENT_LIST, data });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        } else {
          err.actionToCall = CUSTOMER_ACTION_LIST.FETCH_PAYMENT_LIST;
          err.data = {
            pageNo: payload.pageNumber,
            list: []
          };
        }
      })
  }
}

export const fetchCustomerPaymentListCount = (payload) => {
  let FY = getFinancialYear(payload.companyInfo);
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/customerPayment/getTotalPaymentCountByCustomerId?customerId=${payload.customerId}&relationshipId=${payload.relationshipId}&startDate=${FY.fyStartDate}&endDate=${FY.fyEndDate}&filterStartDate=&filterEndDate=`)
      .then(res => {
        dispatch({ type: CUSTOMER_ACTION_LIST.FETCH_PAYMENT_LIST_COUNT, data: res.data });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        } else {
          err.actionToCall = CUSTOMER_ACTION_LIST.FETCH_SALES_ORDER_TRANSACTIONS_COUNT;
        }
      })
  }
}

export const resetTransactionListData = () => {
  return dispatch => {
    dispatch({ type: CUSTOMER_ACTION_LIST.TRANSACTIONS_RESET });
  }
}

export const resetcustomerRfqListData = () => {
  return dispatch => {
    dispatch({ type: CUSTOMER_ACTION_LIST.TRANSACTIONS_RFQ_RESET });
  }
}

export const resetcustomerSqListData = () => {
  return dispatch => {
    dispatch({ type: CUSTOMER_ACTION_LIST.TRANSACTIONS_SQ_RESET });
  }
}

export const resetCommunications = () => {
  return dispatch => {
    dispatch({ type: CUSTOMER_ACTION_LIST.RESET_COMMUNICATIONS });
  }
}

export const resetActivities = () => {
  return dispatch => {
    dispatch({ type: CUSTOMER_ACTION_LIST.RESET_ACTIVITIES });
  }
}

export const resetSalesOrderTransactionsListData = () => {
  return dispatch => {
    dispatch({ type: CUSTOMER_ACTION_LIST.SALES_ORDER_TRANSACTIONS_RESET });
  }
}

export const resetInvoiceTransactionListData = () => {
  return dispatch => {
    dispatch({ type: CUSTOMER_ACTION_LIST.INVOICE_TRANSACTIONS_RESET });
  }
}

export const resetPaymentTransactionListData = () => {
  return dispatch => {
    dispatch({ type: CUSTOMER_ACTION_LIST.PAYMENT_TRANSACTIONS_RESET });
  }
}

export const resetCreditMemoTransactionListData = () => {
  return dispatch => {
    dispatch({ type: CUSTOMER_ACTION_LIST.CREDIT_MEMO_TRANSACTIONS_RESET });
  }
}

export const fetchCustomerCommunications = (payload) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LIST_LOADER });
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/customerCommunication/getCustomerCommunication?relationshipId=${payload.relationshipId}&customerId=${payload.customerId}&pageSize=500&pageNumber=0`)
      .then(res => {
        dispatch({ type: CUSTOMER_ACTION_LIST.FETCH_COMMUINACTIONS, data: res.data });
      })
      .catch((err) => {
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        } else {
          err.actionToCall = CUSTOMER_ACTION_LIST.FETCH_COMMUINACTIONS;
        }
      }).finally(() => {
        dispatch({
          type: COMMON_ACTIONS.HIDE_LIST_LOADER,
        });
      })
  }
}

export const fetchCustomerActivities = (payload) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LIST_LOADER });
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/customerActivity/getAllCustomerActivities?relationshipId=${payload.relationshipId}&customerId=${payload.customerId}&pageSize=500&pageNumber=0`)
      .then(res => {
        dispatch({ type: CUSTOMER_ACTION_LIST.FETCH_ACTIVITIES, data: res.data });
      })
      .catch((err) => {
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        } else {
          err.actionToCall = CUSTOMER_ACTION_LIST.FETCH_ACTIVITIES;
        }
      }).finally(() => {
        dispatch({
          type: COMMON_ACTIONS.HIDE_LIST_LOADER,
        });
      })
  }
}

export const fetchCustomerAddresses = (payload) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LIST_LOADER });
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/boLocationRest/getAddressByCustomerId?relationshipId=${payload.relationshipId}&customerId=${payload.customerId}&pageSize=500&pageNumber=0`)
      .then(res => {
        dispatch({ type: CUSTOMER_ACTION_LIST.FETCH_ADDRESSES, data: res.data });
      })
      .catch((err) => {
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        } else {
          err.actionToCall = CUSTOMER_ACTION_LIST.FETCH_ADDRESSES;
        }
      }).finally(() => {
        dispatch({
          type: COMMON_ACTIONS.HIDE_LIST_LOADER,
        });
      })
  }
}

export const fetchCustomerToDoTasks = (payload) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/toDoTask/getCustomerTasks?relationshipId=${payload.relationshipId}&customerId=${payload.customerId}&pageSize=500&pageNumber=0`)
      .then(res => {
        dispatch({ type: CUSTOMER_ACTION_LIST.FETCH_TASKS, data: res.data });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        } else {
          err.actionToCall = CUSTOMER_ACTION_LIST.FETCH_TASKS;
        }
      })
  }
}

export const createUpdateTask = (payload, toasterMessage = 'Data saved successfully') => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/toDoTask/saveOrUpdate`, payload)
      .then(res => {
        dispatch({ type: CUSTOMER_ACTION_LIST.ADD_UPDATE_TASK, data: res.data });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        showToasterMessage({ messageType: 'success', description: toasterMessage });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        } else {
          err.actionToCall = CUSTOMER_ACTION_LIST.ADD_UPDATE_TASK;
        }
      })
  }
}

export const deleteTask = (payload) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/toDoTask/saveOrUpdate`, payload)
      .then(res => {
        dispatch({ type: CUSTOMER_ACTION_LIST.DELETE_TASK, data: res.data });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        showToasterMessage({ messageType: 'success', description: 'Task deleted successfully' });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        } else {
          err.actionToCall = CUSTOMER_ACTION_LIST.DELETE_TASK;
        }
      })
  }
}

export const exportTxPdf = (payload) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios.post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/customerPdf/getCustomerTxPdf`,
      payload,
      {
        responseType: 'blob',
      }
    )
      .then(response => {
        const content = response.headers['content-type'];
        download(response.data, `${payload.txType.length === 1 ? payload.txType[0] : 'AllTransactions'}.pdf`, content);
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch(err => {
        showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
  }
} 

export const exportLedgerPdf = (payload, props) => {
  let FY = getFinancialYear(props.companyInfo);
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios.get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/customerTransaction/getCustomerLedgerData?relationshipId=${props.companyInfo.relationshipId}&customerId=${payload.customerId}&pageSize=50&pageNumber=0&&startDate=${FY.fyStartDate}&endDate=${FY.fyEndDate}&filterStartDate=${payload.startDate}&filterEndDate=${payload.endDate}`)
      .then(response => {
        dispatch({ type: CUSTOMER_ACTION_LIST.CUSTOMER_LEDGER_LIST, data: response.data });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch(err => {
        showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
  }
}

export const resetCustomerLedgerData = () => {
  return dispatch => {
    dispatch({ type: CUSTOMER_ACTION_LIST.RESET_CUSTOMER_LEDGER_LIST });
  }
}

export const deleteActivity = (payload) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/customerActivity/deleteActivity`, payload)
      .then(res => {
        dispatch({ type: CUSTOMER_ACTION_LIST.DELETE_ACTIVITY, data: payload });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        showToasterMessage({ messageType: 'success', description: 'Task deleted successfully' });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        } else {
          err.actionToCall = CUSTOMER_ACTION_LIST.DELETE_ACTIVITY;
        }
      })
  }
}

export const fetchCustomerLedgerTxSummaryList = (payload) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LIST_LOADER });
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/customerInvoice/getCustomerLedgerSummary?&relationshipId=${payload.relationshipId}&pageSize=${payload.pageSize || 10}&pageNumber=${(payload.pageNumber || 1) - 1}&filters=${encodeURIComponent(JSON.stringify(payload.filters || {}))}`)
      .then(res => {
       
        dispatch({ type: CUSTOMER_ACTION_LIST.CUSTOMER_INVOICE_LEDGER_SUMMARY_LIST, data: res.data });
      })
      .catch((err) => {
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        } else {
          err.actionToCall = CUSTOMER_ACTION_LIST.CUSTOMER_INVOICE_LEDGER_SUMMARY_LIST;
          err.data =[];
        }
      }).finally(() => {
        dispatch({
          type: COMMON_ACTIONS.HIDE_LIST_LOADER,
        });
      })
  }
}

export const getCustomerPurchaseItems = (payload) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LIST_LOADER });
    return axios
    .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/customers/getCustomerPurchaseItems?relationshipId=${payload.relationshipId}&customerId=${payload.customerId}&pageSize=${payload.pageSize || 25}&pageNumber=${payload.pageNumber || 0}`)
      .then(res => {
        const data = {
          pageNo: payload.pageNumber,
          list: res.data
        }
        dispatch({ type: CUSTOMER_ACTION_LIST.CUSTOMER_PURCHASE_ITEMS, data });

      })
      .catch((err) => {
        showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
      }).finally(() => {
        dispatch({
          type: COMMON_ACTIONS.HIDE_LIST_LOADER,
        });
      })
  }
}

export const fetchCustomerPurchaseItemsCount = (payload) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/customers/countCustomerPurchaseItems?customerId=${payload.customerId}&relationshipId=${payload.relationshipId}`)
      .then(res => {
        dispatch({ type: CUSTOMER_ACTION_LIST.COUNT_CUSTOMER_PURCHASE_ITEMS, data: res.data });
      })
      .catch((err) => {
        showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
      })
  }
}

export const getCustomerActivitiesTrack = (payload) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
    .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/customers/getCustomerActivitiesTrack?relationshipId=${payload.relationshipId}&customerId=${payload.customerId}`)
      .then(res => {
        const data = {
          pageNo: payload.pageNumber,
          list: res.data
        }
        dispatch({ type: CUSTOMER_ACTION_LIST.CUSTOMER_ACTIVITIES_TRACK_LIST, data });
      })
      .catch((err) => {
        showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
      })
  }
}
export const deleteCustomerAddress = (payload,props) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/boLocationRest/deleteAddress`, payload)
      .then(res => {
        showToasterMessage({ messageType: 'success', description: 'Address deleted successfully' });
        props.fetchCustomerAddresses(payload);
      })
      .catch((err) => {
   
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })       
      })
  }
}