import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ShowAddress from '../../../../components/customer/listing/ShowAddress';
import { fetchCountries, showModal, hideModal } from './../../../../actions/commonActions';
import { fetchAddresses, deleteAddress, createUpdateAddress, addAddressRow } from './action';
import { fetchDataIfNeeded } from '../../../../utils';
import { CONSTANTS } from '../../../../static/constants';

class ShowAddressContainer extends Component {
  componentDidMount() {
    const companyInfo = this.props.companyInfo || {};
    const payload = {
      relationshipId: companyInfo.relationshipId,
      locationType: CONSTANTS.BILLING_ADDRESS,
      customerId: this.props.customerId
    };
    fetchDataIfNeeded('fetchCountries', 'countries', this.props, payload);
    this.props.fetchAddresses(payload);
  }

  render() {
    return (
      <ShowAddress {...this.props} {...this.state} updateState={(data) => { this.setState(data)}}/>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    countries: state.common.countries,
    companyInfo: state.common.companyInfo,
    addresses: state.customer.addresses,
    permissions: state.auth.permissions,

  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchCountries,
  fetchAddresses,
  deleteAddress,
  createUpdateAddress,
  addAddressRow,
  showModal,
  hideModal,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ShowAddressContainer);
