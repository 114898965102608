import React, { Fragment } from "react";
import { FormattedMessage } from "react-intl";
// import PageBreadcrumb from "../../../PageBreadcrumb";
import { Menu, Pagination, Row, Skeleton, Switch } from "antd";
import { CONSTANTS, ICONS, MODULE_CODED_VALUES } from "../../../static/constants";
import DropdownAction from "antd/lib/dropdown";
import storeDef from "../../../assets/images/store-def.jpg";
import { getAWSFileURL, getMomentDateForUIReadOnly } from "../../../utils";
import PageHeader from "../../Common/pageHeader";


const Testimonials = (props) => {
  const format = CONSTANTS.DISPLAY_DATE_FORMAT;
const {companyInfo} = props;
  const breadCrumbList = [
    {
      name: (
        <FormattedMessage id="sidebar.menuItem.eStores" defaultMessage="" />
      ),
    },
    {
      name: (
        <FormattedMessage id="sidebar.menuItem.blogs" defaultMessage="" />
      ),
    },

  ];



  const actionMenu = (data) => (
    <Menu className="row-action-dropdown">
      <Menu.Item key="0" value="title" disabled={true}>
        Actions
      </Menu.Item>
      <Menu.Divider />

      <Menu.Item key="2"
        onClick={() => {
          props.updateState({ testimonialCreateDrawerVisible: true, isUpdate: true, testimonialData: data });

        }}>
        <i className="fi fi-rr-edit"></i>
        Edit
      </Menu.Item>

      <Menu.Item
        key="2"
        value="delete"
        onClick={() => {
          props.showModal({
            modalBody: "Are you sure want to delete?",
            handleSubmit: () => {
              props.deleteTestimonial(data, props)
            },
          });
        }}
      >
        <i className={ICONS.DELETE} /> Delete
      </Menu.Item>
    </Menu>
  );


  const showTotal = (total) => {
    return (
        props.intl.formatMessage({
            id: "pagination.text.total",
            defaultMessage: "",
        })
        +
        ` ${total} ` +
        props.intl.formatMessage({
            id: "pagination.text.items",
            defaultMessage: "",
        })
    );
};
const loadPageData = (pageNumber, pageSize) => {
    const payload = {
        companyInfo,
        relationshipId: companyInfo.relationshipId,
        pageNumber: pageNumber,
        pageSize: pageSize,
    };
    props.fetchAllTestimonialList(payload);

};

const handlePageSizeChange = (pageNumber, pageSize) => {

    const payload = {
        companyInfo,
        relationshipId: companyInfo.relationshipId,
        pageNumber: pageNumber,
        pageSize: pageSize,
    };
    props.fetchAllTestimonialList(payload);
    props.updateState({ pageSize: pageSize, pageNumber: pageNumber });
};
  return (
    <>
      
        <PageHeader {...props}
        pageName="sidebar.menuItem.testimonials"
        breadCrumbList={""}
        canCreate={true}
        moduleCode={MODULE_CODED_VALUES.E_STORE_TESTIMONIALS}
        onCreate={() => {
          props.updateState({ testimonialCreateDrawerVisible: true, isUpdate: false,testimonialData:{} });
        }}
      />
        <div className="view-container">
        <div className="view-container-actions">
          <div className="left-actions">
            <div className="table-heading">Testimonial List</div>


            <div className="vertical-sep" />
            <div>{props.testimonialListCount || 0}</div>

          </div>
        </div>
       

          <div className='table-container'>
            <Skeleton loading={props.listLoading} paragraph={{ rows: 11 }} active>

              <table>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Position</th>
                    <th>Title</th>
                    <th>Testimonial</th>
                    <th>Company Logo</th>
                    <th>Date Created</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {(props.testimonialList[props.pageNumber || 1]).length ? (props.testimonialList[props.pageNumber || 1] || []).map(rowData => {
                    const image = getAWSFileURL(rowData?.awsKeyName, props.companyInfo.bucketName, rowData?.folderName)
                    return (
                      <tr>
                        <td>{rowData.customerName}</td>
                        <td>{rowData.description}</td>
                        <td>{rowData.title}</td>
                        <td>{rowData.message}</td>
                        <td>
                          {image ? (
                            <img

                              src={image}
                              alt="BlogThumbnailImage"
                              width={40}
                              height={40}

                            />
                          ) : (
                            <img
                              src={storeDef}
                              alt="No Thumbnail Available"
                              width={40}
                              height={40}
                            />
                          )}
                        </td>

                        <td>
                          {getMomentDateForUIReadOnly({ date: rowData.dateCreated, format })}
                        </td>
                        <td className="switch-status">
                          <Switch

                            checked={rowData?.isActive}
                            onChange={(checked) => {
                              const data = {
                                title: "Confirmation",

                                // modalType: MODAL_TYPE.ACTIVATION_MODAL,
                                modalBody: <div>Are you sure you want change the status?</div>,
                                handleSubmit: () => {
                                  const payload = {
                                    ...rowData,
                                    relationshipId: props.companyInfo.relationshipId,
                                    description: rowData.description,
                                    name: rowData.name,
                                    title: rowData.title,
                                    message: rowData.message,
                                    testimonialsId: rowData.testimonialsId,
                                    pageNumber: props.pageNumber,
                                    pageSize: props.pageSize,


                                  };
                                  props.createTestimonial({ ...payload, isActive: checked ? 1 : 0 }, image, props);
                                },
                              };

                              props.pushModalToStack(data)
                            }}
                          />
                        </td>
                        <td className='text-center'>
                          <div className="action-icon">
                            <DropdownAction
                              overlay={actionMenu(rowData)}
                              trigger={["click"]}
                            >
                              <i className={ICONS.MORE} />
                            </DropdownAction>
                          </div>

                        </td>
                      </tr>
                    )
                  }

                  ) : ""}
                </tbody>
              </table>
            </Skeleton>
          </div>

          <div className="footer">
        <div className="f-left"></div>
        <div className="f-right">
        <Pagination
          current={props.pageNumber || 1}
          size="small"
          total={props.testimonialListCount}
          showTotal={showTotal}
          defaultPageSize={props.pageSize}
          showSizeChanger pageSizeOptions={[10, 25, 50, 100, 200]}
          onChange={(pageNumber, pageSize) => {
            loadPageData(pageNumber, pageSize);
            props.updateState({ pageSize: pageSize, pageNumber: pageNumber });

        }}
        onShowSizeChange={(pageNumber, pageSize) => {
            handlePageSizeChange(pageNumber, pageSize, props)
        }}
        />
        </div>
      </div>
      

      </div>
      
     
    </>
  );

};

export default Testimonials;
