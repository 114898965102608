import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import IntegrationsComp from "../../components/integrations";
import { showModal, hideModal, pushModalToStack, popModalFromStack } from '../../actions/commonActions'
import { saveRazorpayConfiguration} from '../../containers/integrations/Payment/action';
import queryString from 'query-string';

class Integrations extends Component {

    constructor(props) {
        super(props);
        const linkProps = queryString.parse(decodeURIComponent(props.location?.search||"")) || {}
        this.state = {
            activeTab: 'Payment Gateway',
            selectedMenuItem : linkProps.active || 'Payment Gateway',
        }
    }

    componentWillMount() {

    }

    componentDidMount() {
        const companyInfo = this.props.companyInfo || {};
        const linkProps = queryString.parse(this.props.location?.search) || {}
        const payload = {
            code: this.props.code || linkProps.code,
            relationshipId: companyInfo.relationshipId,
            accountId: this.props.accountId || linkProps.accountId,
            state: this.props.state || linkProps.state,
        };
        
        (linkProps.code && linkProps.state && linkProps.state === "razorpay") && this.props.saveRazorpayConfiguration(payload, this.props);
    }

    render() {
        return <IntegrationsComp {...this.state} {...this.props} updateState={(data) => { this.setState(data) }} />
    }

}

const mapStateToProps = (state) => {
    return {
        common: state.common,
        companyInfo: state.common.companyInfo,
        permissions: state.auth.permissions
    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    showModal,
    hideModal,
    pushModalToStack,
    popModalFromStack,
    saveRazorpayConfiguration
}, dispatch);


export default connect(mapStateToProps, mapDispatchToProps)(Integrations);
