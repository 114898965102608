import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import SchoolDashboard from '../../../components/schoolManagement/schoolDashboard';
import { getTotalStudentcCountByGrade, getTotalStudentsCount } from '../dashboard/action';
import { getTeacherCount } from '../teacher/action';
import { getAllEmployeesCount } from '../../company/EmployeeManagement/Employee/action';
import { fetchProformaInvoices } from '../../customer/invoice/Listing/action';
import { getActiveBroadcastMessage } from '../BroadcastMessage/action';
import { getSchoolStudents } from '../student/action';

class SchoolDashboardCon extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageSize: 25,
      pageNumber: 1,
      isDataRendered: false,
      isLoaded:false
    
    };
  }

  componentDidMount() {
 
    const companyInfo = this.props.companyInfo || {};
    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      pageNumber: 1,
      pageSize: 25
    };
    this.props.getTotalStudentsCount(payload);
    this.props.getTeacherCount(payload);
    this.props.getAllEmployeesCount(payload);
    this.props.fetchProformaInvoices({...payload,pageSize:5000});
    this.props.getTotalStudentcCountByGrade(payload);
    this.props.getActiveBroadcastMessage(payload);
    this.props.getSchoolStudents(payload)
  }


  componentWillReceiveProps(props) {
   
    if(!this.state.isLoaded && (props.proformaInvoiceList[1] || []).length){
      let totalFeesDue = (props.proformaInvoiceList[1]
        .filter(invoice => invoice.module === "school" && invoice.isInvoiceConverted === 0)
        .reduce((sum, invoice) => sum + (invoice.invoiceTotalAmount || 0),0));

      let totalFeesPaid = ((props.proformaInvoiceList[1]
        .filter(invoice => invoice.module === "school" && invoice.isInvoiceConverted === 1)
        .reduce((sum, invoice) => sum + (invoice.invoiceTotalAmount || 0),0)));

       let totalInvoiceGenerated = (props.proformaInvoiceList[1]
        .reduce((sum, invoice) => sum + (invoice.invoiceTotalAmount || 0),0))   

        this.setState({
          isLoaded:true,
          totalFeesDue:totalFeesDue,
          totalFeesPaid:totalFeesPaid,
          totalInvoiceGenerated:totalInvoiceGenerated
        })
    }
    
  }

  render() {
    return ( <div>
    
      <SchoolDashboard {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    totalStudentCount: state.studentRegistration.totalStudentCount,
    teacherCount: state.school.teacherCount,
    employeesCount: state.employee.employeesCount,
    proformaInvoiceList: state.salesInvoice.proformaInvoiceList,
    totalStudentCountByGrade: state.studentRegistration.totalStudentCountByGrade,
    broadcastMessageList: state.school.broadcastMessageList,
    schoolStudentList: state.school.schoolStudentList,
    listLoading:state.common.listLoading

  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  getTotalStudentsCount,
  getTeacherCount,
  getAllEmployeesCount,
  fetchProformaInvoices,
  getTotalStudentcCountByGrade,
  getActiveBroadcastMessage,
  getSchoolStudents
 
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SchoolDashboardCon);
