import React, { Component } from 'react';
import { connect } from 'react-redux';
import { showModal , hideModal } from '../../../../../actions/commonActions';
import StoreTimingsComp from '../../../../../components/modal/modalBody/eStore/StoreTimings';
import { TimeSettings } from './action';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { fetchE_StoreData } from '../../../../../actions/commonActions';
import { bindActionCreators } from 'redux';
class StoreTimings extends Component {
    constructor(props){
        super(props);
        this.state= {
            isHeaderMenuLoaded: true,
            storeTiming: [
                {
                  day: "Sunday",
                  online: 0,
                  from_time: "24 hours",
                  to_time: "24 hours",
                },
                {
                  day: "Monday",
                  online: 0,
                  from_time: "24 hours",
                  to_time: "24 hours",
                },
                {
                  day: "Tuesday",
                  online: 0,
                  from_time: "24 hours",
                  to_time: "24 hours",
                },
                {
                  day: "Wednesday",
                  online: 0,
                  from_time: "24 hours",
                  to_time: "24 hours",
                },
                {
                  day: "Thursday",
                  online: 0,
                  from_time: "24 hours",
                  to_time: "24 hours",
                },
                {
                  day: "Friday",
                  online: 0,
                  from_time: "24 hours",
                  to_time: "24 hours",
                },
                {
                  day: "Saturday",
                  online: 0,
                  from_time: "24 hours",
                  to_time: "24 hours",
                },
              ],
              storeValue:{},
              message:'',
        }
    }
    componentDidMount(){
        const companyInfo = this.props.companyInfo || {}
        const payload ={
            relationshipId:companyInfo.relationshipId,
        }
        fetchE_StoreData(payload)
        if(this.state.isHeaderMenuLoaded && Object.keys(this.props.storeValues || {}) && this.props.storeValues.storeTiming ){
          let StoreTimingData=JSON.parse(this.props.storeValues.storeTiming || '{}');
          
          this.setState({storeTiming:(StoreTimingData?.results?.length)? StoreTimingData?.results : this.state.storeTiming, isHeaderMenuLoaded: false ,storeValue:StoreTimingData ,message: StoreTimingData?.message})
        }
     }
   
      render() {
        return  <DndProvider backend={HTML5Backend}>
            <StoreTimingsComp {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
        </DndProvider>
    }

}


const mapStateToProps = (state) => {
    return {
        remarksData: state.settings.remarksData,
        userInfo: state.common.userInfo,
        relationshipId: state.common.companyInfo.relationshipId,
        storeValues: state.common.store_values,
        permissions: state.auth.permissions,

    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
   TimeSettings,
   fetchE_StoreData,
   showModal,
   hideModal,
}, dispatch);

export default connect(mapStateToProps,mapDispatchToProps)(StoreTimings);