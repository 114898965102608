import { Button, Col, Row } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import "../../../../eStore/storeSettings/store-settings.scss";
import { TextBox } from "../../../../general/TextBox";
import { FormattedMessage } from "react-intl";
import { getPermissionData } from "../../../../../utils";
import { MODULE_CODED_VALUES } from "../../../../../static/constants";

const DomainsComp = (props) => {
  const {permissions ,ecomDomain} = props;
  const primaryPerm = (permissions && permissions.primary) || [];
  const permissionsData = getPermissionData(primaryPerm, MODULE_CODED_VALUES.E_STORE_SETTING);
    const HandleSave =()=>{
         const value ={...props.storeValues ,shopName: props.shopName ,websiteUrl: props.websiteUrl}
         const modalData ={
          modalBody: (
            <FormattedMessage
              id="customer.salesQuote.edit.form.confirmation"
              defaultMessage=""
            />
          ),
          handleSubmit: () => {
            props.hideModal()
            const payload = {
              ecomSettingSave: value,
             }
             props.saveEcomWebsiteUrl({
              ecomSubdomainId: props.ecomDomain?.ecomSubdomainId,
              ecomSubdomainName:`https://${props.websiteUrl}`,
              relationshipId:props.companyInfo?.relationshipId
             })
    
             props.updateOrCreateEcomSettings(payload ,props)
           
          }
         }
         props.showModal(modalData)
        
    }
    
  return (
    <div className="store-model-container cus-space">
    <div className="store-main-container">
      <div className="model-head" >
        <p className="heading">Domains</p>
        <p className="sub-heading">
        Set up and personalize your store’s web address.
        </p>
        </div>
      <div  className="model-container" >
      <Row gutter={[16,16]}>
          

          <Col span={12} >
            <div className="i-label">Website URL</div>
            <TextBox
              placeholder="Enter Website URL"
              type="text"
              maxLength={100}
              value={props.websiteUrl}
              onChange={(e) => {
                props.updateState({ websiteUrl: e.target.value });
              }}
            />
            {(props.websiteUrl) ?
            <span>{`https://${props.websiteUrl}`}</span>
            :""}
          </Col>

          <Col span={12}>
            <div className="i-label">Shop Name</div>
            <TextBox
              placeholder="Enter Shop Name"
              type="text"
              maxLength={40}
              value={props.shopName}
              onChange={(e) => {
                props.updateState({ shopName: e.target.value });
              }}
            />
          </Col>
        </Row>
        
    
      </div>
      </div>
      <div className="footer-btn" >
      <Button                       
       disabled={!(permissionsData.update)}
      type="primary" onClick={()=>HandleSave()}>Submit</Button>
      </div>
    </div>
  );
};

export default DomainsComp;
