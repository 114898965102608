import React, { Fragment, useEffect, } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
// import { AgGridReact } from 'ag-grid-react';
import ReactHtmlParser from 'react-html-parser';
import { Skeleton, Pagination, Popover, Tooltip,Empty  } from 'antd';
import { fetchPaginationDataIfNeeded, getMomentDateForUIReadOnly, fixedDecimalAndFormateNumber,
     sortTable } from '../../../../utils'
     import ReactDOMServer from 'react-dom/server';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
//import PageBreadcrumb from '../../../PageBreadcrumb';
// import { AG_GRID_CLASS_CONSTANTS } from '../../../../static/cssClassConstants';
import SQDetail from '../../../../containers/modal/modalBody/customer/SQDetail';
import { CONSTANTS } from '../../../../static/constants';
import { AddAndRemoveCustomerListingColumn } from "../../../general/AddAndRemoveCustomerListingColumn";
// import { AG_GRID_CONSTANTS } from '../../../../static/agGridConstants';
// import { Dropdown } from '../../../general/Dropdown';
// import { TextBox } from '../../../general/TextBox';
// import checkIcon from '../../../../assets/images/check.png';
// import crossIcon from '../../../../assets/images/cross.png';
import { FilterOutlined, InsertRowRightOutlined } from '@ant-design/icons';
// import find from 'lodash.find';
import CustomerProfile from '../../../../containers/modal/modalBody/customer/Profile'


const format = CONSTANTS.DISPLAY_DATE_FORMAT;
// const { RangePicker } = DatePicker;
// const { Panel } = Collapse;

const SalesQuoteListingComp = (props) => {
    const { sqList, updateState, companyInfo, salesQuoteCount,pageNumber, pageSize, dir=0 } = props;
    // const [collapseActiveKey, setCollapseActiveKey] = useState('');


    // const openAuditDrawer = (props, data) => {
    //     props.updateState({
    //         title: <div className="drawer-title">
    //             Audit Trail
    //         </div>,
    //         auditDrawerVisible: true,
    //         deletApiUrl: 'deleteAuditTrailSalesQuotation',
    //         masterKey: 'salesQuotationMasterId',
    //         masterKeyValue: data.salesQuotationMasterId,
    //         apiUrl: 'saveAuditTrailSalesQuotation',
    //         customerId: data.customerId,
    //         trailList: data.auditTrailSalesQuotationList,
    //         updateTrailListInTransaction: (list) =>{
    //             data.auditTrailSalesQuotationList = list;
    //         } 
    //     });
    // }

  

 
    const showTotal = (total) => {
        return props.intl.formatMessage({ id: 'pagination.text.total', defaultMessage: '' }) + ` ${total} ` + props.intl.formatMessage({ id: 'pagination.text.items', defaultMessage: '' });
    };

    const loadPageData = (pageNumber, pageSize) => {
        const payload = { companyInfo, relationshipId: companyInfo.relationshipId, pageNumber: pageNumber, pageSize: pageSize };
        // fetchPaginationDataIfNeeded('fetchSalesQuotes', 'sqList', props, payload);
        props.fetchSalesQuotes(payload);
    }

    const handlePageSizeChange = (pageNumber, pageSize, props) => {
        const payload = { companyInfo, relationshipId: companyInfo.relationshipId, pageNumber: pageNumber, pageSize: pageSize };
        props.resetPaginatedData();
        props.fetchSalesQuotes(payload);
       props.updateState({ pageSize: pageSize, pageNumber: pageNumber })
    }

    // const getRowHeight = (params) => {
    //     let height = 35;
    //     return height;
    // }

    const openSQDetails = (data) => {
        const sqPayload = {
            customerId: data.customerId,
            salesQuotationMasterId: data.salesQuotationMasterId
        }
        props.pushModalToStack({
            modalBody: <SQDetail {...props} sqPayload={sqPayload} />,
            width: '100%',
            hideFooter: true,
            wrapClassName: 'modal-custom-detail'
        })
    }
  
   function removeTags(html) {
    return html.replace(/<[^>]+>/g, '');
}
    // const openCustomerDetails = (data) => {
    //     // const rfqPayload = {
    //     //     supplierId: data.supplierId,
    //     //     rfqMasterId: data.rfqMasterId
    //     // }
    //     // props.pushModalToStack({
    //     //     modalBody: <RFQDetail {...props} rfqPayload={rfqPayload} />,
    //     //     width: '100%',
    //     //     hideFooter: true,
    //     //     wrapClassName: 'modal-custom-detail'
    //     // })
    // }

    // const saveColumnIndex = (columnState) => {
    //     let data = props.txPreferences || {};
    //     let tablePreference = {};
    //     data.relationshipId = (props.companyInfo || {}).relationshipId;
    //     data.clientUserAccountId = (props.userInfo || {}).userId;
    //     if (data.tablePreference) {
    //         tablePreference = JSON.parse(data.tablePreference);
    //     }
    //     tablePreference.columnSetting = columnState;
    //     tablePreference.columnList = props.txColumns;
    //     data.tablePreference = JSON.stringify(tablePreference);
    //     data.preferenceId = props.preferenceId;
    //     data.gridPreference = props.gridPreference;
    //     props.saveUserPrefrences(data);
    // }

    // const saveColumnWidth = (columnState, width) => {
    //     let data = props.txPreferences || {};
    //     let tablePreference = {};
    //     data.relationshipId = (props.companyInfo || {}).relationshipId;
    //     data.clientUserAccountId = (props.userInfo || {}).userId;
    //     if (data.tablePreference) {
    //         tablePreference = JSON.parse(data.tablePreference);
    //     }
    //     tablePreference.columnSetting = columnState;
    //     tablePreference.columnList = props.txColumns;
    //     data.tablePreference = JSON.stringify(tablePreference);
    //     data.preferenceId = props.preferenceId;
    //     data.gridPreference = props.gridPreference;
    //     props.saveUserPrefrences(data, props);
    // }

    // let moveEvent = {};
    // const onColumnMoved = (event, isDragStopped) => {
    //     if (event.column) {
    //         moveEvent = event;
    //     }
    //     if (isDragStopped && moveEvent.column && moveEvent.column.colDef && moveEvent.source !== AG_GRID_CONSTANTS.EVENT_SOURCE.API) {
    //         saveColumnIndex(moveEvent.columnApi.getColumnState());
    //     }
    // }

    // const onColumnResized = (event, tablegridApi, tableGridColumnApi) => {
    //     const gridColumnApi = tableGridColumnApi || props.gridColumnApi;
    //     event.api.resetRowHeights();
    //     if (event.type === 'columnResized' && event.source !== 'sizeColumnsToFit' && event.source !== 'api' && event.finished === true) {
    //         //event.api.resetRowHeights();
    //         if (gridColumnApi && gridColumnApi.columnController.bodyWidth < gridColumnApi.columnController.scrollWidth) {
    //             props.gridApi.sizeColumnsToFit();
    //             setTimeout(() => {
    //                 const columnArray = event.columnApi.getColumnState();
    //                 saveColumnWidth(columnArray);
    //             }, 1000);
    //         } else {
    //             saveColumnWidth(event.columnApi.getColumnState());
    //         }
    //     }
    // }


    const _isColumnToHide = (columnName) => {
        return (props.txColumns.indexOf(columnName) < 0 && props.txMandatoryColumns.indexOf(columnName) < 0) ? true : false;
    }

    const getQuantityOnSO = (data) => {
        return <div className='circles-ling'>
        {data.statusSO === 'converted' ? <i className="fa fa-circle"></i>  : (data.statusSO === 'converted.partially' ? <i className="fa fa-adjust"></i> : <i className="fa fa-circle-o" ></i> )}
        </div>;
    }

    //const permittedModules = getActionMenuPermissionData();
    // const columnDefs = [
    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='customer.salesQuote.listing.header.sqNo' defaultMessage='' />;
    //         },
    //         field: "quotationNumber",
    //         colId: "quotationNumber_1",
    //         hide: _isColumnToHide('quotationNumber'),
    //         suppressMovable: true,
    //         minWidth: 160,
    //         maxWidth: 400,
    //         rowDrag: true,
    //         resizable: true,
    //         cellRendererFramework: (link) => <div className="cursor-pointer" onClick={() => {
    //             openSQDetails(link.data)
    //         }}>{link.value}</div>,
    //     },
    //     // {
    //     //     headerComponentFramework: () => {
    //     //         return <FormattedMessage id='customer.salesQuote.listing.header.rfqNumber' defaultMessage='' />;
    //     //     },
    //     //     field: "rfqNumber",
    //     //     hide: _isColumnToHide('rfqNumber'),
    //     //     width: (props.txColumnSetting['rfqNumber'] || {}).width,
    //     //     resizable: true
    //     // },
    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='customer.salesQuote.listing.header.customer' defaultMessage='' />;
    //         },
    //         field: "customerName",
    //         hide: _isColumnToHide('customerName'),
    //         colId: "customerName_1",
    //         suppressMovable: true,
    //         minWidth: 130,
    //         resizable: true,
    //         cellRendererFramework: (link) => <div className="cursor-pointer" >
    //             <div onClick={() => {
    //                 props.history.push("customers/details", {
    //                     customerId: link.data.customerId,
    //                     customerName: link.data.customerName,
    //                 })
    //             }}>{link.data.customerName}</div>
    //         </div>,
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <div><FormattedMessage id='customer.salesQuote.listing.header.amount' defaultMessage='' />  </div>;
    //         },
    //         field: "salesQuoteTotalAmount",
    //         suppressMovable: true,
    //         minWidth: 70,
    //         maxWidth: 400,
    //         hide: _isColumnToHide('salesQuoteTotalAmount'),
    //         colId: "salesQuoteTotalAmount_1",
    //         cellRendererFramework: (link) => {
    //             // return link.value && link.value !== '-' ? <span> {link.data.foreignCurrencyIcon ? <i className={link.data.foreignCurrencyIcon}></i> : (link.data.foreignCurrency ? <span>{link.data.foreignCurrency}</span> : (props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "))}{link.value.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} </span> : "-"
    //             return link.value && link.value !== '-' ? <span> {link.data.foreignCurrencyIcon ? <i className={link.data.foreignCurrencyIcon}></i> : (link.data.foreignCurrency ? <span>{link.data.foreignCurrency}</span> : (props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "))}{fixedDecimalAndFormateNumber(link.value)} </span> : "-"
    //         },
    //         resizable: true,
    //         cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <div><FormattedMessage id='customer.salesQuote.listing.header.priority' defaultMessage='' /> </div>;
    //         },
    //         field: "orderPriority",
    //         suppressMovable: true,
    //         minWidth: 70,
    //         maxWidth: 400,
    //         hide: _isColumnToHide('orderPriority'),
    //         colId: "orderPriority_1",
    //         resizable: true,
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='customer.salesQuote.listing.header.refNumber' defaultMessage='' />;
    //         },
    //         field: "referenceNumber",
    //         hide: _isColumnToHide('referenceNumber'),
    //         colId: "referenceNumber_1",
    //         suppressMovable: true,
    //         minWidth: 70,
    //         maxWidth: 400,
    //         resizable: true
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='customer.salesQuote.listing.header.date' defaultMessage='' />;
    //         },
    //         field: 'salesQuoteDate',
    //         hide: _isColumnToHide('salesQuoteDate'),
    //         colId: "salesQuoteDate_1",
    //         suppressMovable: true,
    //         minWidth: 70,
    //         maxWidth: 400,
    //         resizable: true,
    //         cellRendererFramework: (link) => <div>
    //             <Tooltip placement="topLeft" title={(link.value && getMomentDateForUIReadOnly({date: link.value, format: CONSTANTS.DISPLAY_TOOLTIP_DATE_FORMAT}))}>
    //             {getMomentDateForUIReadOnly({date: new Date(link.data.salesQuoteDate), format})}
    //             </Tooltip>
    //         </div>,
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='customer.salesQuote.listing.header.rfqExpirationDate' defaultMessage='' />;
    //         },
    //         field: 'rfqExpirationDate',
    //         hide: _isColumnToHide('rfqExpirationDate'),
    //         colId: "rfqExpirationDate_1",
    //         resizable: true,
    //         suppressMovable: true,
    //         minWidth: 70,
    //         maxWidth: 400,
    //         cellRendererFramework: (link) => <div>
    //              <Tooltip placement="topLeft" title={(link.data.salesQuoteExpiryDate && getMomentDateForUIReadOnly({date: link.data.salesQuoteExpiryDate, format: CONSTANTS.DISPLAY_TOOLTIP_DATE_FORMAT}))}>
    //             {link.data.salesQuoteExpiryDate && getMomentDateForUIReadOnly({date: new Date(link.data.salesQuoteExpiryDate), format})}
    //             </Tooltip>
    //         </div>,
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='customer.salesQuote.listing.header.isInquiryConversion' defaultMessage='' />;
    //         },
    //         field: 'isInquiryConversion',
    //         cellRendererFramework: (params) => {
    //             return params.node.data.isInquiryConversion ? <img src={checkIcon} style={{ 'height': '15px', 'width': '15px' }} alt="Invoice" /> : <img src={crossIcon} style={{ 'height': '10px', 'width': '10px' }} alt="Invoice" />;
    //         },
    //         hide: _isColumnToHide('isInquiryConversion'),
    //         colId: "isInquiryConversion_1",
    //         suppressMovable: true,
    //         minWidth: 70,
    //         maxWidth: 400,
    //         resizable: true,
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='customer.salesQuote.listing.header.remarksInternal' defaultMessage='' />;
    //         },
    //         field: 'remarksInternal',
    //         hide: _isColumnToHide('remarksInternal'),
    //         colId: "remarksInternal_1",
    //         suppressMovable: true,
    //         minWidth: 70,
    //         maxWidth: 400,
    //         resizable: true,
    //         cellRendererFramework: (params) => {
    //             return <pre>{ReactHtmlParser(params.data.remarksInternal)}</pre>
    //         },
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='customer.salesQuote.listing.header.remarksCustomer' defaultMessage='' />;
    //         },
    //         field: 'remarksCustomer',
    //         hide: _isColumnToHide('remarksCustomer'),
    //         colId: "remarksCustomer_1",
    //         suppressMovable: true,
    //         minWidth: 70,
    //         maxWidth: 400,
    //         resizable: true,
    //         cellRendererFramework: (params) => {
    //             return <pre>{ReactHtmlParser(params.data.remarksCustomer)}</pre>
    //         },
    //     },
        
    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='customer.salesQuote.listing.header.qtyOnSO' defaultMessage='' />;
    //         },
    //         field: "qtyOnSalesOrder",
    //         hide: _isColumnToHide('qtyOnSalesOrder'),
    //         colId: "qtyOnSalesOrder_1",
    //         suppressMovable: true,
    //         minWidth: 70,
    //         maxWidth: 100,
    //         cellRendererFramework: function (param) {
    //             return getQuantityOnSO(param.data);
    //         },
    //         resizable: true,
    //         cellClass: 'sales-order-progressbar'
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='common.status' defaultMessage='' />;
    //         },
    //         field: "userStatus",
    //         hide: _isColumnToHide('userStatus'),
    //         colId: "userStatus_1",
    //         suppressMovable: true,
    //         minWidth: 70,
    //         maxWidth: 400,
    //         cellRendererFramework: (link) => {
    //             return <div>
    //                {link.value? <span className="status-code" style={{background: link.data.userStatusColor}}>{link.value || ''}</span>: ''}
    //             </div>
    //         },
    //         resizable: true
    //     },
    //     // {
    //     //     headerComponentFramework: () => {
    //     //         return <FormattedMessage id='customer.salesQuote.listing.header.systemStatus' defaultMessage='System Status' />;
    //     //     },
    //     //     field: "status",
    //     //     colId: "status_1",
    //     //     hide: _isColumnToHide('status'),
    //     //     suppressMovable: true,
    //     //     minWidth: 70,
    //     //     maxWidth: 400,
    //     //     cellRendererFramework: (params) => {
    //     //         return (params.value === 'Rejected' && params.data.rejectionReason) ?
    //     //             <div>
    //     //                 <Tooltip placement="topLeft" title={params.data.rejectionReason}>
    //     //                     <span className={["status-code", params.value.toLowerCase()].join(' ')}><FormattedMessage id={params.value} defaultMessage={params.value} /></span>
    //     //                 </Tooltip>
    //     //             </div> :
    //     //             <div> <span className={["status-code", params.value.toLowerCase(), 'new'].join(' ')}><FormattedMessage id={params.value} defaultMessage={params.value} /></span> </div>
    //     //     },
    //     //     resizable: true
    //     // },
    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='customerSalesEnquiryNumber.text' defaultMessage='' />;
    //         },
    //         field: 'customerInquiryNumber',
    //         hide: _isColumnToHide('customerInquiryNumber'),
    //         colId: "customerInquiryNumber_1",
    //         resizable: true,
    //         suppressMovable: true,
    //         minWidth: 70,
    //         maxWidth: 400,
    //         cellRendererFramework: (link) => <div>
    //             {link.data.customerInquiryNumber || '-'}
    //         </div>,
    //     },

    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='customerRfqNumber.text' defaultMessage='' />;
    //         },
    //         field: 'customerRFQNumber',
    //         hide: _isColumnToHide('customerRFQNumber'),
    //         colId: "customerRFQNumber_1",
    //         resizable: true,
    //         suppressMovable: true,
    //         minWidth: 70,
    //         maxWidth: 400,
    //         cellRendererFramework: (link) => <div>
    //             {link.data.customerRFQNumber || '-'}
    //         </div>,
    //     },

    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='common.salesPerson' defaultMessage='' />;
    //         },
    //         field: 'salesPersonFullName',
    //         hide: _isColumnToHide('salesPersonFullName'),
    //         colId: "salesPersonFullName_1",
    //         resizable: true,
    //         suppressMovable: true,
    //         minWidth: 70,
    //         maxWidth: 400,
    //         cellRendererFramework: (link) => <div>
    //             {link.data.salesPersonFullName || '-'}
    //         </div>,
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='common.project' defaultMessage='' />;
    //         },
    //         field: "project",
    //         hide: _isColumnToHide('project'),
    //         colId: "project_1",
    //         minWidth: 70,
    //         maxWidth: 300,
    //         suppressMovable: true,
    //         cellRendererFramework: (link) => {
    //             return <div>
    //                  {link.data.projectMasterId ? <span>{link.data.projectMasterId + '-' +link.data.projectName }</span> : ''}
    //             </div>
    //         },
    //         resizable: true
    //     }
    // ]

    // const agGridStyle = {
    //     height: '100%',
    //     width: '100%'
    // };

    // const breadCrumbList = [
    //     {
    //         name: 'Dashboard',
    //         link: 'dashboard'
    //     },
    //     {
    //         name: 'Customer',
    //         link: 'customers'
    //     }, {
    //         name: 'Sales Quote',
    //     }, {
    //         name: 'List'
    //     }
    // ];

    // const onGridReady = (params) => {
    //     props.updateState({
    //         gridApi: params.api,
    //         gridColumnApi: params.columnApi
    //     });
    //     onDataRendered(params);
    // }


    // const onModelUpdated = (params) => {
    //     if ((sqList[pageNumber] || []).length && !props.isRowHeightSet) {
    //         setTimeout(() => {
    //             params.api.resetRowHeights();
    //         }, 1000)
    //         props.updateState({
    //             isRowHeightSet: true
    //         })
    //     }
    //     if (props.tableDataReRendered) {
    //         //params.api.resetRowHeights();
    //         props.updateState({
    //             tableDataReRendered: false
    //         })
    //         let txColumnSetting = props.txColumnSetting && props.txColumnSetting.length ? props.txColumnSetting : params.columnApi.getColumnState();
    //         const columnToBeSet = [];
    //         const indexObjArr = TRANSACTION_COLUMN_INDEX.SALES_QUOTE_LISTING;

    //         indexObjArr.forEach((e) => {
    //             let colObj = find(txColumnSetting, { colId: e + '_1' });
    //             if (colObj) {
    //                 columnToBeSet.push(colObj);
    //             }
    //         });

    //         txColumnSetting = txColumnSetting.filter((ele) => {
    //             let columnName = ele.colId.split("_")[0];
    //             return indexObjArr.indexOf(columnName) === -1;
    //         })
    //         txColumnSetting = columnToBeSet.concat(txColumnSetting);
    //         txColumnSetting.forEach((e) => {
    //             let columnName = e.colId.split("_")[0];
    //             if (columnName !== 'action') {
    //                 if (columnName === 'isChecked') {
    //                     e.hide = false;
    //                 } else {
    //                     e.hide = _isColumnToHide(columnName);
    //                 }
    //             } if (columnName === 'action') {
    //                 e.hide = false;
    //                 e.pinned = 'right';
    //             }
    //         });
    //         params.columnApi.setColumnState(txColumnSetting);
    //         setTimeout(() => {
    //             if (props.gridColumnApi && props.gridColumnApi.columnController.bodyWidth < props.gridColumnApi.columnController.scrollWidth) {
    //                 params.api.sizeColumnsToFit();
    //             }
    //         }, 500);
    //     }
    // }

    // const onDataRendered = (params) => {
    //     let txColumnSetting = props.txColumnSetting && props.txColumnSetting.length ? props.txColumnSetting : params.columnApi.getColumnState();
    //     if (Object.keys(txColumnSetting).length) {
    //         const columnToBeSet = [];
    //         const indexObjArr = TRANSACTION_COLUMN_INDEX.SALES_QUOTE_LISTING;

    //         indexObjArr.forEach((e) => {
    //             let colObj = find(txColumnSetting, { colId: e + '_1' });
    //             if (colObj) {
    //                 columnToBeSet.push(colObj);
    //             }
    //         });

    //         txColumnSetting = txColumnSetting.filter((ele) => {
    //             let columnName = ele.colId.split("_")[0];
    //             return indexObjArr.indexOf(columnName) === -1;
    //         })
    //         txColumnSetting = columnToBeSet.concat(txColumnSetting);
    //         txColumnSetting.forEach((e) => {
    //             let columnName = e.colId.split("_")[0];
    //             if (columnName !== 'action') {
    //                 if (columnName === 'isChecked') {
    //                     e.hide = false;
    //                 } else {
    //                     e.hide = _isColumnToHide(columnName);
    //                 }
    //             } if (columnName === 'action') {
    //                 e.hide = false;
    //                 e.width = 52;
    //                 e.pinned = 'right';
    //             }
    //         });
    //         params.columnApi.setColumnState(txColumnSetting);
    //         setTimeout(() => {
    //             if (params.columnApi && params.columnApi.columnController.bodyWidth < params.columnApi.columnController.scrollWidth) {
    //                 params.api.sizeColumnsToFit();
    //             }
    //         }, 500);
    //     } else {
    //         params.api.sizeColumnsToFit();
    //     }
    // }

    const sortColumn = (e) => {
        sortTable(document.getElementById('sales-quote-table'), e.target.cellIndex, 1 - dir);
        updateState({ dir: 1 - dir })
      }

    const openLongtText = (title, Text)=>{
        props.showModal({
            title: title,
            modalBody: <div style={{maxHeight: '550px', overflow: 'auto'}}>{ReactHtmlParser(Text)}</div>,
            hideFooter: true,
            width: '70%'
        })
    }

    return (
        <Fragment>
           

                {/* <Collapse activeKey={collapseActiveKey} onChange  = {()=>{
                    setCollapseActiveKey(collapseActiveKey ? '' : '1')
                }}>
                    <Panel header="Apply Filter" key="1"  style={{ width : '100%' }}>
                        
<div className='qute-sect1'>
                        <Dropdown
                            // style={{ marginTop: '-20px', width: '200px', marginRight: '5px', float:'left' }}
                            className='drop-fileds1'
                            items={props.customers[pageNumber] || []}
                            valueKeyName='companyName'
                            optionKeyName='customerId'
                            value={props.selectedCustomerName}
                            onSearch={(searchedText) => {
                                props.fetchCustomers({
                                    isCompact: true,
                                    searchedText: searchedText,
                                    pageNumber: 1,
                                    hideSpinner: true,
                                    pageSize: 100,
                                    relationshipId: (props.companyInfo || {}).relationshipId
                                })
                            }}
                            placeholder={props.intl.formatMessage({
                                id: 'customer.salesInvoice.form.customer.placeholder', defaultMessage: ''
                            })}

                            onSelect={
                                (customerId, option) => {
                                    updateState({
                                        selectedCustomerId: option.value,
                                        selectedCustomerName: option.name,
                                    })
                                }
                            }
                            allowClear={true}
                            onClear={() => {
                                updateState({
                                    selectedCustomerId: undefined,
                                    selectedCustomerName: undefined,
                                })
                            }}
                        />

                        <TextBox 
                            // style={{ marginTop: '-0px', width: '140px', marginRight: '5px', float:'left'}}
                            className="drop-filedsd"
                            placeholder='Quote #'
                            type='text'
                            maxLength={15}
                            value={props.quoteNum}
                            onChange={(e) => {
                                props.updateState({ quoteNum: e.target.value });
                            }}
                        />
                        <RangePicker
                            // style={{ marginTop: '-0px', width: '300px', marginRight: '5px',float:'left' }}
                            className='drop-fileds1'
                            allowClear={false}
                            format={format}
                            placeholder={['Quote start date', 'Quote end date']}
                            value={[props.startDate ? getMomentDateForUI({ date: new Date(props.startDate), format }) : null, props.endDate ? getMomentDateForUI({ date: new Date(props.endDate), format }) : null]}
                            onChange={(dates) => {
                                props.updateState({ startDate: dates[0], endDate: dates[1] })
                            }}
                        />

                        <div className='solpty' style={{float:'left', marginTop:'0px'}}>
                            <i className={props.companyInfo.currencyIcon}></i> 
                            <TextBox 
                                style={{ width: '140px' }}
                                placeholder='Enter Amount'
                                type='Number'
                                className='certso'
                                // maxLength={12}
                                value={props.selectedAmount}
                                onChange={(e) => {
                                    props.updateState({ selectedAmount: e.target.value });
                                }}
                            />
                        </div>

                        <Select
                            // style={{ marginTop: '0px', width: '60px', marginRight: '5px',float:'left' }}
                            className='drop-filed3'
                            options={[{ label: '<', value: '<' }, { label: '>', value: '>' }, { label: '=', value: '=' }]}
                            value={props.selectedAmountTerm}
                            placeholder='='
                            defaultValue= '='
                            onSelect={(value) => {
                                updateState({ selectedAmountTerm: value });
                            }}
                        />

                        <Dropdown
                            // style={{ marginTop: '-20px', width: '140px', marginRight: '5px',float:'left' }}
                            className='drop-fileds'
                            items={[{ name: 'Open', value: 'open' }, { name: 'Partially Fullfilled', value: 'partially.fulfilled' }, { name: 'Fullfilled', value: 'fulfilled' }]}
                            valueKeyName='name'
                            optionKeyName='value'
                            value={props.selectedStatusName}
                            placeholder={props.intl.formatMessage({ id: 'common.systemStatus' })}
                            onSelect={(status, option) => {
                                updateState({ selectedStatusName: option.name, selectedStatus: option.value })
                            }}
                            allowClear={true}
                            onClear={() => {
                                updateState({ selectedStatusName: undefined, selectedStatus: undefined })
                            }}
                        />
                        <div style={{clear:'both'}}></div>
</div>
<div style={{clear:'both'}}></div>
<div className='qute-sect1'>
                        <Dropdown
                            // style={{ marginTop: '-20px', width: '140px', marginRight: '5px',float:'left' }}
                            className='drop-fileds'
                            items={props.userStatus || []}
                            valueKeyName='statusName'
                            optionKeyName={'salesQuoteStatusId'}
                            statusColorKey='statusColor'
                            // style={{ marginTop: '-20px', width: '140px', marginRight: '5px' }}
                            value={props.selectedUserStatusName}
                            placeholder={props.intl.formatMessage({ id: 'common.status' })}
                            onSelect={(selectedValue, option) => {
                                updateState({ selectedUserStatusName: option.name, selectedUserStatus: option.value })
                            }}
                            allowClear={true}
                            onClear={() => {
                                updateState({ selectedUserStatusName: undefined, selectedUserStatus: undefined })
                            }}
                        />


                        <Dropdown
                            // style={{ marginTop: '-20px', width: '140px', marginRight: '5px' ,float:'left'}}
                            className='drop-fileds'
                            items={[{ name: 'Low', value: 'low' }, { name: 'Medium', value: 'medium' }, { name: 'High', value: 'high' }, { name: 'Urgent', value: 'urgent' }]}
                            valueKeyName='name'
                            optionKeyName='value'
                            value={props.selectedPriority}
                            placeholder={props.intl.formatMessage({ id: 'customer.filter.priority.placeholder' })}
                            onSelect={(status, option) => {
                                updateState({ selectedPriority: option.name })
                            }}
                            allowClear={true}
                            onClear={() => {
                                updateState({ selectedPriority: undefined })
                            }}
                        />

                        <RangePicker
                            // style={{ marginTop: '-0px', width: '300px', marginRight: '5px', float:'left' }}
                            className='drop-fileds2'
                            allowEmpty={true}
                            format={format}
                            placeholder={['Bid start date', 'Bid end date']}
                            value={[props.bidStartDate ? getMomentDateForUI({ date: new Date(props.bidStartDate), format }) : null, props.bidEndDate ? getMomentDateForUI({ date: new Date(props.bidEndDate), format }) : null]}
                            onChange={(dates) => {
                                if (dates) {
                                    props.updateState({ bidStartDate: dates[0], bidEndDate: dates[1] });
                                } else {
                                    props.updateState({ bidStartDate: null, bidEndDate: null });
                                }
                            }}
                        />
                        <TextBox 
                            // className='new-fed1'
                            // style={{ marginTop: '-0px', width: '140px', marginRight: '5px',float:'left'}}
                            className='drop-fileds'
                            placeholder='Enter Ref #'
                            type='text'
                            maxLength={15}
                            value={props.refNum}
                            onChange={(e) => {
                                props.updateState({ refNum: e.target.value });
                            }}
                        />
                        <Dropdown
                            // style={{ marginTop: '-20px', width: '140px', marginRight: '5px',float:'left' }}
                            className='drop-fileds'
                            items={props.salesPersonList}
                            valueKeyName='fullName'
                            optionKeyName='relationshipEmployeeId'
                            value={props.selectedSalesPersonName}
                            placeholder={props.intl.formatMessage({ id: 'filter.salesPerson.placeholder' })}
                            onSelect={(status, option) => {
                                updateState({ selectedSalesPersonName: option.name, selectedSalesPersonId: option.value })
                            }}
                            allowClear={true}
                            onClear={() => {
                                updateState({ selectedSalesPersonName: undefined, selectedSalesPersonId: undefined })
                            }}
                        />

                        <Dropdown
                            // style={{ marginTop: '-20px', width: '160px', marginRight: '5px', float:'left' }}
                            className='drop-fileds1'
                            items={props.projectList}
                            valueKeyName='projectName'
                            optionKeyName='projectMasterId'
                            projectIdKey='projectMasterId'
                            value={props.selectedProjectName}
                            placeholder={props.intl.formatMessage({ id: 'salesInvoice.project.placeholder' })}
                            onSelect={(status, option) => {
                                updateState({ selectedProjectName: option.name, selectedProjectMasterId: option.value })
                            }}
                            allowClear={true}
                            onClear={() => {
                                updateState({ selectedProjectName: undefined, selectedProjectMasterId: undefined })
                            }}
                        />
                        <div style={{clear:'both'}}></div>
                        </div>

                        <div style={{clear:'both'}}></div>

                        <div className='qute-sect1'>
                        <button className="ant-btn ant-btn-default vab ml5" onClick={() => {
                            if(!props.selectedCustomerId && !props.quoteNum && !props.startDate && !props.endDate && !(props.selectedAmount && props.selectedAmountTerm) && !props.selectedStatus && !props.selectedUserStatus && !props.selectedPriority && !props.bidStartDate && !props.bidEndDate && !props.refNum && !props.selectedSalesPersonId && !props.selectedProjectMasterId ){
                                return showToasterMessage({ messageType: 'error', description: 'Please select at least one filter' });
                            }
                            const payload = {
                                companyInfo, relationshipId: companyInfo.relationshipId, pageNumber: 0, pageSize: props.pageSize,
                                filters: {
                                    customerId: props.selectedCustomerId || 0,
                                    quoteNumber: props.quoteNum || null,
                                    startDate: props.startDate ? getCurrentDateForBackend(props.startDate) : null,
                                    endDate: props.endDate ? getCurrentDateForBackend(props.endDate) : null,
                                    amount: props.selectedAmount || 0,
                                    amountSymbol: props.selectedAmount ? props.selectedAmountTerm : null,
                                    status: props.selectedStatus || null,
                                    userStatus: props.selectedUserStatusName || null,
                                    priority : props.selectedPriority || null,
                                    bidStartDate: props.bidStartDate ? getCurrentDateForBackend(props.bidStartDate) : null,
                                    bidEndDate: props.bidEndDate ? getCurrentDateForBackend(props.bidEndDate) : null,
                                    reference: props.refNum || null,
                                    salesPersonId: props.selectedSalesPersonId || 0,
                                    salesPersonName: props.selectedSalesPersonName || null,
                                    projectMasterId: props.selectedProjectMasterId || 0,
                                }
                            };
                            
                            props.fetchSalesQuotesByFilter(payload); 
                            props.getSalesQuotesCountByFilter(payload);
                        }}>
                            {<FilterOutlined /> } Apply Filter
                        </button>
                        <button className="ant-btn ant-btn-default vab ml5" onClick={() => {
                            props.updateState({
                                selectedCustomerId: null, selectedCustomerName: null,
                                quoteNum: null,
                                startDate:null, endDate:null,
                                selectedAmount: null, selectedAmountTerm: null,
                                selectedStatus:null, selectedStatusName: null,
                                selectedUserStatusName: undefined, selectedUserStatus: undefined,
                                selectedPriority: null,
                                bidStartDate:null, bidEndDate:null,
                                refNum: null,
                                selectedSalesPersonId: null, selectedSalesPersonName:null, 
                                selectedProjectName:null, selectedProjectMasterId: null 
                            });
                            const payload = {
                                companyInfo, relationshipId: companyInfo.relationshipId, pageNumber: 0, pageSize: props.pageSize,
                                filters: {}
                            };
                            props.fetchSalesQuotes(payload); 
                            props.getSalesQuoteCount(payload);
                        }}>
                            {<UndoOutlined />} Clear Filter
                        </button>
                        <div style={{clear:'both'}}></div>
                        </div>
                    </Panel>
                </Collapse> */}

                <div className="view-container-actions">
                <Skeleton loading={props.headerLoading} paragraph={false} active>

                    <div className="left-actions">
                    <div className="table-heading">
                        <FormattedMessage id='sales.salesQuote.table.heading' defaultMessage='' />
                    </div>
                    {
                        props.salesQuoteCount ? <>
                        <div className="vertical-sep" />
                        <div>{props.salesQuoteCount}</div>
                        </>:''
                    }
                    </div>
                    <div className="right-actions">
                        <button onClick={()=>{
                            props.updateState({
                                salesQuoteFilterDrawerVisible:true
                            })
                        }}>
                            <FilterOutlined /> &nbsp; Filters
                        </button>
                        <Popover
                            content={<AddAndRemoveCustomerListingColumn {...props} />}
                            title={<div className="text-center">Add/Remove</div>}
                            placement="leftTop"
                            trigger="click"
                            visible = {props.showColPop}
                        >
                            <button onClick={()=>{props.updateState({showColPop: !props.showColPop})}}>
                                <InsertRowRightOutlined className='mr5'/> Columns
                            </button>
                        </Popover>

                    </div>
                    </Skeleton>
                </div>

                {/* {props.txColumns.length ? <div>
                <div className="cursor-pointer">
                    <Tooltip placement='left' title={props.intl.formatMessage({ id: 'tooltip.addRemoveColumn.msg' })} trigger='hover'>
                        <span className="top-button">
                            <Popover
                                content={<AddAndRemoveCustomerListingColumn {...props} />}
                                title={<div className="text-center"><FormattedMessage id='common.addRemove' defaultMessage='' /> </div>}
                                placement="left"
                                trigger="click"
                            >
                                <i className='fa fa-bars' />
                            </Popover>
                        </span>
                    </Tooltip>
                </div>
                    <AgGridReact
                        onGridReady={onGridReady}
                        columnDefs={columnDefs}
                        defaultColDef={{
                            flex: 1,
                            autoHeight: true,
                            wrapText: true,
                        }}
                        rowData={sqList[pageNumber] || []}
                        rowDragManaged={true}
                        domLayout={"autoHeight"}
                        animateRows={true}
                        onModelUpdated={onModelUpdated}
                        //getRowHeight={getRowHeight}
                        onColumnResized={onColumnResized}
                        onDragStopped={() => {
                            onColumnMoved({}, true);
                        }}
                        //onColumnMoved={onColumnMoved}
                    >
                    </AgGridReact>
                </div> : null} */}
                <Skeleton loading={props.listLoading} paragraph={{ rows: 13 }} active>

                <div className='table-container'>

                    <table id='sales-quote-table'>
                        <thead>
                            <tr>
                                <th><FormattedMessage id='serial.no' defaultMessage='' /></th>
                                {!_isColumnToHide('quotationNumber') ? <th onClick={sortColumn}><FormattedMessage id='customer.salesQuote.listing.header.Quote' defaultMessage='' /></th>: ''}
                                {!_isColumnToHide('customerName') ? <th onClick={sortColumn}><FormattedMessage id='customer.salesQuote.listing.header.customer' defaultMessage='' /></th>: ''}
                                {!_isColumnToHide('salesQuoteTotalAmount') ? <th onClick={sortColumn}><FormattedMessage id='customer.salesQuote.listing.header.amount' defaultMessage='' /></th>: ''}
                                {!_isColumnToHide('orderPriority') ? <th onClick={sortColumn}><FormattedMessage id='customer.salesQuote.listing.header.priority' defaultMessage='' /></th>: ''}
                                {!_isColumnToHide('referenceNumber') ? <th onClick={sortColumn}><FormattedMessage id='customer.salesQuote.listing.header.refNumber' defaultMessage='' /></th>: ''}
                                {!_isColumnToHide('salesQuoteDate') ? <th onClick={sortColumn}><FormattedMessage id='customer.salesQuote.listing.header.date' defaultMessage='' /></th>: ''}
                                {!_isColumnToHide('rfqExpirationDate') ? <th onClick={sortColumn}><FormattedMessage id='customer.salesQuote.listing.header.rfqExpirationDate' defaultMessage='' /></th>: ''}
                                {!_isColumnToHide('isInquiryConversion') ? <th onClick={sortColumn}><FormattedMessage id='customer.salesQuote.listing.header.isInquiryConversion' defaultMessage='' /></th>: ''}
                                {!_isColumnToHide('remarksInternal') ? <th onClick={sortColumn}><FormattedMessage id='customer.salesQuote.listing.header.remarksInternal' defaultMessage='' /></th>: ''}
                                {!_isColumnToHide('remarksCustomer') ? <th onClick={sortColumn}><FormattedMessage id='customer.salesQuote.listing.header.remarksCustomer' defaultMessage='' /></th>: ''}
                                {!_isColumnToHide('qtyOnSalesOrder') ? <th onClick={sortColumn}><FormattedMessage id='customer.salesQuote.listing.header.qtyOnSO' defaultMessage='' /></th>: ''}
                                {!_isColumnToHide('userStatus') ? <th onClick={sortColumn}><FormattedMessage id='common.status' defaultMessage='' /></th>: ''}
                                {!_isColumnToHide('customerInquiryNumber') ? <th onClick={sortColumn}><FormattedMessage id='customerSalesEnquiryNumber.text' defaultMessage='' /></th>: ''}
                                {!_isColumnToHide('customerRFQNumber') ? <th onClick={sortColumn}><FormattedMessage id='customerRfqNumber.text' defaultMessage='' /></th>: ''}
                                {!_isColumnToHide('salesPersonFullName') ? <th onClick={sortColumn}><FormattedMessage id='common.salesPerson' defaultMessage='' /></th>: ''}
                                {!_isColumnToHide('project') ? <th onClick={sortColumn}><FormattedMessage id='common.project' defaultMessage='' /></th>: ''}
                            </tr>
                        </thead>
                        <tbody>
                            {sqList && (sqList[pageNumber] || []).length ? 
                                sqList[pageNumber].map((rowData, i)=> {
                                    
                                 return <tr key={i}>
                                      
                                        <td>{(pageNumber-1)*pageSize+(i+1)}</td>
                                        {!_isColumnToHide('quotationNumber') ? <td className='one-line-view'>
                                            <div className="table-link-text" onClick={() => {
                                                openSQDetails(rowData)
                                            }}>{rowData.quotationNumber|| <div className="empty-data"></div>}</div>
                                        </td>: ''}
                                        {!_isColumnToHide('customerName') ? <td className='one-line-view'>
                                            <div className="table-link-text" >
                                                <div
                                                
                                                // onClick={() => {
                                                //     props.history.push("customers/details", {
                                                //         customerId: rowData.customerId,
                                                //         customerName: rowData.customerName,
                                                //     })
                                                onClick={() => {
                                                    props.pushModalToStack({  
                                              
                                                      modalBody: <CustomerProfile {...props}
                                                      customerId= {rowData.customerId}
                                                    //   customerName={rowData.customerName}
                                                    //   customerDetails={rowData}
                                                  />,
                                                  width: '100%',
                                                  hideTitle: true,  
                                                  hideFooter: true,
                                                  wrapClassName: "new-transaction-wrapper",
                                                  })
                                                }}>{rowData.customerName|| <div className="empty-data"></div>}</div>
                                            </div>
                                        </td>: ''}
                                        {!_isColumnToHide('salesQuoteTotalAmount') ? <td className='one-line-view'>
                                            {rowData.salesQuoteTotalAmount && rowData.salesQuoteTotalAmount !== '-' ? <span> {rowData.foreignCurrencyIcon ? <i className={rowData.foreignCurrencyIcon}></i> : (rowData.foreignCurrency ? <span>{rowData.foreignCurrency}</span> : (props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "))}{fixedDecimalAndFormateNumber(rowData.salesQuoteTotalAmount)} </span> :  <div className="empty-data"></div>}
                                        </td>: ''}
                                        {!_isColumnToHide('orderPriority') ? <td>
                                            {rowData.orderPriority|| <div className="empty-data"></div>}
                                        </td>: ''}
                                        {!_isColumnToHide('referenceNumber') ? <td>
                                            {rowData.referenceNumber|| <div className="empty-data"></div>}
                                        </td>: ''}
                                        {!_isColumnToHide('salesQuoteDate') ? <td>
                                            <Tooltip placement="topLeft" title={(rowData.salesQuoteDate && getMomentDateForUIReadOnly({date: rowData.salesQuoteDate, format: CONSTANTS.DISPLAY_TOOLTIP_DATE_FORMAT}))}>
                                                {getMomentDateForUIReadOnly({date: new Date(rowData.salesQuoteDate), format})}
                                            </Tooltip>
                                        </td>: ''}
                                        {!_isColumnToHide('rfqExpirationDate') ? <td>
                                            <Tooltip placement="topLeft" title={(rowData.salesQuoteExpiryDate && getMomentDateForUIReadOnly({date: rowData.salesQuoteExpiryDate, format: CONSTANTS.DISPLAY_TOOLTIP_DATE_FORMAT}))}>
                                                {rowData.salesQuoteExpiryDate && getMomentDateForUIReadOnly({date: new Date(rowData.salesQuoteExpiryDate), format})}
                                            </Tooltip>
                                        </td>: ''}
                                        {!_isColumnToHide('isInquiryConversion') ? <td>
                                            {/* {rowData.isInquiryConversion ? <img src={checkIcon} style={{ 'height': '15px', 'width': '15px' }} alt="Invoice" /> : <img src={crossIcon} style={{ 'height': '10px', 'width': '10px' }} alt="Invoice" />} */}
                                            {rowData.isInquiryConversion ? "Yes" : "No"}
                                        </td>: ''}
                                        {!_isColumnToHide('remarksInternal') ? <td>
                                           
                                           
                                        { 
                                              rowData.remarksInternal ? (<div>
                                              {
                                                (removeTags(rowData.remarksInternal)||'')?.length<20 ? 
                                                ( <pre 
                                               
                                                >{ReactHtmlParser(rowData.remarksInternal)}</pre>):(<div><pre 
                                               
                                                >{removeTags(rowData.remarksInternal).slice(0,20)}</pre><span className="more-details-btn"  onClick={()=>{openLongtText('Remarks', rowData.remarksInternal)}}>Show More</span></div>)
                                              }
                                              
                                              
                                              </div> ) : <div className="empty-data"></div> 
                                            }
                                        </td>: ''}
                                        {!_isColumnToHide('remarksCustomer') ? <td>
                                        { 
                                              rowData.remarksCustomer ? (<div>
                                              {
                                                (removeTags(rowData.remarksCustomer)||'')?.length<20 ? 
                                                ( <pre 
                                               
                                                >{ReactHtmlParser(rowData.remarksCustomer)}</pre>):(<div><pre 
                                               
                                                >{removeTags(rowData.remarksCustomer).slice(0,20)}</pre><span className="more-details-btn"  onClick={()=>{openLongtText('Remarks', rowData.remarksCustomer)}}>Show More</span></div>)
                                              }
                                              
                                              
                                              </div> ) : <div className="empty-data"></div> 
                                            }
                                          
                                           
                                        </td>: ''}
                                        {!_isColumnToHide('qtyOnSalesOrder') ? <td>
                                            {getQuantityOnSO(rowData)|| <div className="empty-data"></div>}
                                        </td>: ''}
                                        {!_isColumnToHide('userStatus') ? <td>
                                            {rowData.status ? <span className="status-code" style={{background: rowData.userStatusColor}}>{rowData.status || ''}</span>:  <div className="empty-data"></div>}
                                        </td>: ''}
                                        {!_isColumnToHide('customerInquiryNumber') ? <td>
                                            {rowData.customerInquiryNumber ||  <div className="empty-data"></div>}
                                        </td>: ''}
                                        {!_isColumnToHide('customerRFQNumber') ? <td>
                                            {rowData.customerRFQNumber ||  <div className="empty-data"></div>}
                                        </td>: ''}
                                        {!_isColumnToHide('salesPersonFullName') ? <td>
                                            {rowData.salesPersonFullName ||  <div className="empty-data"></div>}
                                        </td>: ''}
                                        {!_isColumnToHide('project') ? <td>
                                        {/* rowData.projectMasterId + '-' +? */}
                                            {rowData.projectMasterId ? <span>{rowData.projectName }</span> :  <div className="empty-data"></div>}
                                        </td>: ''
                                      
                                        }
                                    </tr>
                                }):                                
                                <tr key="empty-data-box">
                                <td colSpan={"100%"} style={{ borderBottom: "none" }}>
                                    <Empty />
                                </td>
                            </tr>
                            }
                        </tbody>
                    </table>
                </div>
            </Skeleton>
            <div className="footer">
            <Skeleton loading={props.headerLoading} paragraph={false} active>

                <div className="f-left"></div>
                <div className="f-right">
                    <Pagination
                        size="small"
                        total={salesQuoteCount}
                        showTotal={showTotal}
                        defaultPageSize={pageSize} showSizeChanger pageSizeOptions={[10, 25, 50, 100, 200]}
                        // showQuickJumper
                        onChange={(pageNumber, pageSize) => {
                            
                            loadPageData(pageNumber, pageSize);
                            updateState({ pageSize: pageSize, pageNumber: pageNumber })
                        }}
                        onShowSizeChange={(pageNumber, pageSize) => {
                            handlePageSizeChange(pageNumber || 1, pageSize, props)
                        }}
                    />
                </div>
                </Skeleton>
            </div>
        </Fragment>
    );
}

export default injectIntl(SalesQuoteListingComp);
