import React, { useMemo, useState } from "react";
import { injectIntl, FormattedMessage } from "react-intl";
// import { CustomAgGrid } from '../../../general/CustomAgGrid';
import { Skeleton, Menu, Input, Pagination, Empty } from "antd";
import PageBreadcrumb from "../../PageBreadcrumb";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
// import { PictureOutlined,PlusOutlined } from '@ant-design/icons';
import {
  FilterOutlined,
} from "@ant-design/icons";
import DropdownAction from "antd/lib/dropdown";
import { ICONS, MODULE_CODED_VALUES } from "../../../static/constants";
import StandardStockTransit from "../../../containers/inventory/stockInTransit/StandardStockTransit";
import find from "lodash.find";
import { getMomentDateForUIReadOnly, getPermissionData } from "../../../utils";
import StockTransit from "../../../containers/modal/modalBody/inventory/StockTransit";
import * as debounce from "lodash.debounce";
import PageHeader from "../../Common/pageHeader";


const { Search } = Input;

const StockTransitComp = (props) => {
  const { pageNumber, stockTransferList, stockTransferCount, companyInfo, updateState ,permissions} = props;
  const primaryPerm = (permissions && permissions.primary) || [];
  const permissionsData = useMemo(()=>{
    return getPermissionData(primaryPerm ,  MODULE_CODED_VALUES.STOCK_TRANSFER);
  },[]) 
  const [typingTimeout, setTypingTimeout] = useState(0);
  const [searchedText, setSearchedText] = useState('');

  const handleSearchInputChange = (e) => {
    const inputString = e.target.value;
    setSearchedText(inputString);

    clearTimeout(typingTimeout);

    if (inputString.length > 2) {
      setTypingTimeout(setTimeout(()=>SearchApi(inputString), 1000));
    }
  };

  const SearchApi = (inputString) =>{
    const payload = {
      relationshipId: props.companyInfo.relationshipId,
      pageNumber: 1,
      pageSize: props.pageSize,
      gitNumber: inputString
    };
    props.updateState({pageNumber: 1})
    props.fetchstockTransfer(payload);
    props.getStockTransferCount(payload);
  }

  const breadCrumbList = [
    {
      name: <FormattedMessage id="sidebar.menuItem.inventory" defaultMessage="" />
    },
    {
      name: <FormattedMessage id="sidebar.menuItem.stock.InTransit" defaultMessage="" />
    },
  ];

  const openTIT = (gitData) => {
    props.pushModalToStack({
      modalBody: <StandardStockTransit {...props} gitData={gitData} />,
      width: "100%",
      hideTitle: true,
      hideFooter: true,
      wrapClassName: "new-transaction-wrapper",
    });
  };

  const showTotal = (total) => {
    return (
      props.intl.formatMessage({
        id: "pagination.text.total",
        defaultMessage: "",
      }) +
      ` ${total} ` +
      props.intl.formatMessage({
        id: "pagination.text.items",
        defaultMessage: "",
      })
    );
  };

  const getWarehouse = (id) => {
    return find(props.allWarehouses, { warehouseMasterId: id });
  };

  const openStockTransferDetails = (data) => {
    const stPayload = {
      gitMasterId: data.gitMasterId
    }
    props.pushModalToStack({
      modalBody: <StockTransit {...props}  stPayload={stPayload}/>,
      width: "100%",
      hideFooter: true,
      wrapClassName: "modal-custom-detail",
    });
  };

  const handlePageSizeChange = (pageNumber, pageSize) => {
    const payload = {
      relationshipId: companyInfo.relationshipId,
      pageNumber: pageNumber,
      pageSize: pageSize,
    };
    props.fetchstockTransfer(payload);
    props.updateState({ pageSize: pageSize, pageNumber: pageNumber });
  };

  const loadPageData = (pageNumber, pageSize) => {
    const payload = {
        relationshipId: companyInfo.relationshipId,
        pageNumber: pageNumber,
        pageSize: pageSize,
    };
    props.fetchstockTransfer(payload);
  };

  const approveGIT = (rowData) =>{
    props.showModal({
      modalBody: `Are you sure you want to approve ${rowData.gitNumber || ''}?`,
      handleSubmit: () => {
        props.ApproveStockTransfer({...rowData, approvedByUserId: props.userInfo?.userId}, props);
        props.hideModal();
      }
    })
  }

  const deleteGIT = (rowData) =>{
    props.showModal({
      modalBody: `Are you sure you want to delete ${rowData.gitNumber || ''}?`,
      handleSubmit: () => {
        props.DeleteStockTransfer({...rowData, approvedByUserId: props.userInfo?.userId}, props);
        props.hideModal();
      }
    })
  }

  const actionMenu = (rowData) => {
    return (
        <Menu className="row-action-dropdown">

          <Menu.Item key="0" value="title">
            Actions
          </Menu.Item>

          <Menu.Divider />
         
          <Menu.Item key="approve" disabled={rowData.isApproved ? true : false} value="edit" onClick={()=>{
            approveGIT(rowData)
            }}
          >
            <i className="fi fi-rr-memo-circle-check" />
            Approve
          </Menu.Item>
       {
        permissionsData.update ?  <Menu.Item key="edit" value="edit" onClick={()=>{
          openTIT(rowData);
        }}
      >
        <i className={ICONS.EDIT} />
        Edit
      </Menu.Item>: ""
       }
         
         {
          permissionsData.delete ?  <Menu.Item key="delete" disabled={rowData.isApproved ? true : false} onClick={()=>{
            deleteGIT(rowData);
          }}
        >
          <i className={ICONS.DELETE} />
          Delete
        </Menu.Item>: ""
         }
         

        </Menu >
    )
  };


  return (
    <>
      <PageHeader {...props}
        pageName= "sidebar.menuItem.stock.InTransit"
        breadCrumbList={breadCrumbList}
        canCreate={true}
        onCreate={openTIT}
        moduleCode={MODULE_CODED_VALUES.STOCK_TRANSFER}
      />

      <div className="view-container">
        <div className="view-container-actions">
        <Skeleton loading={props.headerLoading} paragraph={false} rows={1} active>
          <div className="left-actions">
            <div className="table-heading">Stock Transfer List</div>
            {stockTransferCount ? (
              <>
                <div className="vertical-sep" />
                <div>{stockTransferCount}</div>
              </>
            ) : (
              ""
            )}
          </div>
          <div className="right-actions">
            <Search
              placeholder="Enter to search"
              value={searchedText}
              onChange={handleSearchInputChange}
              onSearch={(searchedText) => {
                if(searchedText?.length > 2){
                  setSearchedText(searchedText);
                  updateState({ pageNumber: 1 });
                  SearchApi(searchedText);
                }
              }}
            />

            <button
              onClick={() => {
                props.updateState({
                  StockTransferFilterDrawerVisible: true,
                });
              }}
            >
              <FilterOutlined /> &nbsp;{" "}
              <FormattedMessage id="filter.text" defaultMessage="" />
            </button>
          </div>
        </Skeleton>
        </div>
        
        <Skeleton loading={props.listLoading} active paragraph={{ rows: 10 }}>
          <div className="table-container">
            <table>
              <thead>
                <tr>
                  <th>
                    <FormattedMessage id="serial.no" defaultMessage="" />
                  </th>
                  <th>
                    <FormattedMessage
                      id="inventory.stockTransfar.listing.gitNo"
                      defaultMessage=""
                    />
                  </th>
                  <th>
                    <FormattedMessage
                      id="inventory.stockTransfar.listing.sourceWarehouse"
                      defaultMessage=""
                    />
                  </th>
                  <th>
                    <FormattedMessage
                      id="inventory.stockTransfar.listing.destinationWarehouse"
                      defaultMessage=""
                    />
                  </th>
                  <th>
                    <FormattedMessage
                      id="inventory.createDated"
                      defaultMessage=""
                    />
                  </th>
                  <th>
                    <FormattedMessage
                      id="inventory.stockTransfar.listing.status"
                      defaultMessage=""
                    />
                  </th>
                  
                  <th>
                    <FormattedMessage
                      id="common.listing.header.action"
                      defaultMessage=""
                    />
                  </th>
                </tr>
              </thead>
              <tbody>
                {(stockTransferList[pageNumber] || []).length ? 
                  stockTransferList[pageNumber].map((rowData, index) => {
                    return (
                      <tr key={index + "ef"}>
                        <td>{index + 1}</td>
                        <td>
                          <div
                            className="cursor-pointer"
                            onClick={() => {
                              openStockTransferDetails(rowData);
                            }}
                          >
                            {rowData.gitNumber}
                          </div>
                        
                        </td>
                        <td>
                          {
                            (
                              getWarehouse(rowData.sourceWarehouseMasterId) ||
                              {}
                            ).warehouseName
                          }
                        </td>
                        <td>
                          {
                            (
                              getWarehouse(
                                rowData.destinationWarehouseMasterId
                              ) || {}
                            ).warehouseName
                          }
                        </td>

                        <td>
                          {getMomentDateForUIReadOnly({date: rowData.dateCreated})}
                        </td>
                        <td>
                          <div className={`status-label ${rowData.status ? rowData.status.toLowerCase() : ''}`}>
                            {rowData.status}
                          </div>
                        </td>
                        <td style={{paddingLeft: '50px'}}>
                          {rowData.isApproved ? 
                          
                            <i className="fi fi-rr-lock" />
                            : (permissionsData.delete || permissionsData.update ||permissionsData.read) ? <DropdownAction
                            overlay={actionMenu(rowData)}
                            trigger={["click"]}
                          >
                            <i className={ICONS.MORE} />
                          </DropdownAction>: ""
                            

                          }
                        </td>
                      </tr>
                    );
                  })
                :
                <tr key="empty-data-box">
                  <td colSpan={"100%"} style={{ borderBottom: "none" }}>
                    <Empty />
                  </td>
                </tr>
                }
              </tbody>
            </table>
          </div>
        </Skeleton>

        <div className="footer">
        <Skeleton loading={props.headerLoading} paragraph={false} rows={1} active>
          <div className="f-left"></div>

          <div className="f-right">
            <Pagination
              size="small"
              total={stockTransferCount}
              current={props.pageNumber || 1}
              showTotal={showTotal}
              defaultPageSize={props.pageSize}
              showSizeChanger
              pageSizeOptions={[10, 25, 50, 100, 200]}
              onChange={(pageNumber, pageSize) => {
                loadPageData(pageNumber, pageSize);
                props.updateState({ pageSize: pageSize, pageNumber: pageNumber })
              }}
              onShowSizeChange={(pageNumber, pageSize) => {
                handlePageSizeChange(1, pageSize, props)
              }}
            />
          </div>
        </Skeleton>
        </div>
      </div>
    </>
  );
};

export default injectIntl(StockTransitComp);
