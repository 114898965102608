import React, { useRef, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
// import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Checkbox, Button, Row } from 'antd';
import { injectIntl, FormattedMessage } from 'react-intl';
import * as find from 'lodash.find';

import { DropdownPriceList } from '../../../../general/MarkupDropdown';
import { Dropdown } from '../../../../general/Dropdown';
import {  MODAL_TYPE, MODULE_CODED_VALUES } from '../../../../../static/constants';
import { AG_GRID_CONSTANTS } from '../../../../../static/agGridConstants';
import MultiCurrencySalesInvoice from '../../../../../containers/customer/invoice/MultiCurrencySalesInvoice';
import StandardSalesInvoice from '../../../../../containers/customer/invoice/StandardSalesInvoice';
import LoadingIcon from "../../../../../assets/images/loading.gif"

const CustomerHeaderAction = (props) => {
    const [templateDropdown, updateTemplateDropdown] = useState(false);
    const {
        intl,
        updateState,
        priceListId,
        priceTypeToApply,
        priceList,
        showModal,
        companyInfo
    } = props;

    const handleNewPriceListAddition = (props, payload) => {
        const formData = payload.formData;
        formData.relationshipId = companyInfo.relationshipId;
        const data = {
            title: <FormattedMessage id='addItem.text.priceList' defaultMessage='' />,
            formData,
            hideFooter: true,
            modalData: {
                modalType: MODAL_TYPE.PRICE_LIST,
                data: payload,

            },
            handleSubmit: (formData = {}) => {
                data.formData.submittedOnce = true;
                showModal(data);
            }
        };
        showModal(data);
    }

    const deleteClickHandler = (props, modalType, payload) => {
        const modalBody =
            <FormattedMessage
                id='deleteItem.confirmation'
                defaultMessage={``}
                values={{ value: payload.text }}
            />;

        let deleteFunc;

        switch (modalType) {
            case MODAL_TYPE.PRICE_LIST: {
                deleteFunc = props.deletePriceList;
                break;
            }
            default: {
                deleteFunc = () => { }
            }
        }
        const modalData = {
            modalBody,
            handleSubmit: () => { deleteFunc({ id: payload.id, relationshipId: companyInfo.relationshipId }) },
        };
        showModal(modalData);
    }

    const useOuterClick = (callback) => {
        const innerRef = useRef();
        const callbackRef = useRef();

        // set current callback in ref, before second useEffect uses it
        useEffect(() => { // useEffect wrapper to be safe for concurrent mode
            callbackRef.current = callback;
        });

        useEffect(() => {
            document.addEventListener("click", handleClick);
            return () => document.removeEventListener("click", handleClick);

            // read most recent callback and innerRef dom node from refs
            function handleClick(e) {
                if (
                    innerRef.current &&
                    callbackRef.current &&
                    !innerRef.current.contains(e.target)
                ) {
                    callbackRef.current(e);
                }
            }
        }, []); // no need for callback + innerRef dep

        return innerRef; // return ref; client can omit `useRef`
    }

    const innerRef = useOuterClick(e => {
        updateTemplateDropdown(!templateDropdown)
    });

    return (
        <>
        <div className='left-itms'>
      <ul>
        <li className='title'>
          <FormattedMessage id='common.proformaInvoice' />
        </li>
        <li className='sep' />
        <li>
          <FormattedMessage id='customer.salesInvoice.form.invoiceNumber.label' defaultMessage='' /> &nbsp; - &nbsp;
          {props.nextTxNumber ?
            <span>{getFormatedNextTxNumber(props)}</span>
            //<span>{this.props.nextTxNumber.replace('SO-00', 'SO-'+(this.props.customer || {}).customerId) )}</span>
            :
            <img src={LoadingIcon} alt="Invoice" style={{ 'height': '50px' }} />
          }
        </li>
      </ul>
     </div>

     <div className="right-itms">

            {!props.isSoConversionToInvoice && <Checkbox onChange={(e) => {
                props.updateState({
                    isCashSales: e.target.checked
                });
            }} />}
             
                {!props.isSoConversionToInvoice &&
                    <span className="pr10">
                        <FormattedMessage id='salesInvoice.cashSale.checkbox.text' defaultMessage='' />
                    </span>
                }
                {!props.isSoConversionToInvoice && <DropdownPriceList 
                style={{ width: "200px" }}
                    items={priceList}
                    valueKeyName='priceListName'
                    optionKeyName='priceListId'
                    value={priceListId}
                    moduleName={MODULE_CODED_VALUES.PRICE_LIST}
                    canAddNew={true}
                    canDelete={true}
                    deleteClickHandler={(payload) => {
                        deleteClickHandler(props, MODAL_TYPE.PRICE_LIST, payload);
                    }}
                    onAddNewClick={(payload) => {
                        payload.formData = {
                            priceListName: payload.textEntered,
                            submittedOnce: false
                        }
                        handleNewPriceListAddition(props, payload, MODAL_TYPE.PRICE_LIST)
                    }}
                    placeholder={intl.formatMessage({
                        id: 'customer.salesInvoice.form.priceList.placeholder', defaultMessage: ''
                    })}
                    onSelect={
                        (priceListId) => {
                            const priceListData = find(priceList, { priceListId }) || {};
                            updateState({ toUpdatePriceList: true, priceListId, priceListName: priceListData.priceListName, priceListType: priceListData.type, priceListPercentage: priceListData.percentage });
                        }
                    }
                    allowClear={true}
                    onClear={() => {
                        updateState({ toUpdatePriceList: true, priceListId: undefined, priceListName: undefined, priceListType: undefined, priceListPercentage: undefined })
                    }}
                />}

                {!props.isSoConversionToInvoice && <Dropdown
                style={{ width: "200px" }}
                    items={AG_GRID_CONSTANTS.STANDARD_SALES_INVOICE_FIELD.PRICE_TYPE_LIST}
                    value={priceTypeToApply || AG_GRID_CONSTANTS.STANDARD_SALES_INVOICE_FIELD.PRICE_TYPE_LIST[0]}
                    onSelect={(priceTypeToApply) => {
                        updateState({
                            priceTypeToApply,
                            isToPriceTypeToApply: true
                        })
                    }}
                    placeholder={intl.formatMessage({
                        id: 'customer.salesInvoice.form.priceType.placeholder', defaultMessage: ''
                    })}
                    allowClear={true}
                    onClear={() => {
                        updateState({
                            priceTypeToApply: undefined,
                            isToPriceTypeToApply: false,
                        })
                    }}
                />}
{/* 
                {!props.isSoConversionToInvoice ? <Dropdown
                    items={customers[pageNumber] || []}
                    valueKeyName='companyName'
                    optionKeyName='customerId'
                    value={customerName}
                    onSearch={(searchedText) => {
                        props.fetchCustomers({
                            isCompact: true,
                            searchedText: searchedText,
                            pageNumber: 1,
                            hideSpinner: true,
                            pageSize: 100,
                            relationshipId: (props.companyInfo || {}).relationshipId
                        })
                    }}
                    placeholder={intl.formatMessage({
                        id: 'customer.salesInvoice.form.customer.placeholder', defaultMessage: ''
                    })}
                    canAddNew={true}
                    canDelete={true}
                    deleteClickHandler={(payload) => {
                        props.deleteCustomers({ groupCustomerIds: [payload.id], relationshipId: (props.companyInfo || {}).relationshipId })
                    }}
                    onAddNewClick={(payload) => {
                        props.updateHeaderState({
                            customer: {
                                customerDrawerVisible: true,
                                customerName: payload.textEntered,
                            }
                        })
                    }}
                    onSelect={
                        (customerId) => {
                            props.fetchCustomerDetailsByCustomerId({
                                customerId,
                                relationshipId: (props.companyInfo || {}).relationshipId
                            });
                            props.fetchAddresses({customerId, relationshipId: (props.companyInfo || {}).relationshipId, locationType: CONSTANTS.SHIPPING_ADDRESS});
                            props.fetchAddresses({customerId, relationshipId: (props.companyInfo || {}).relationshipId, locationType: CONSTANTS.BILLING_ADDRESS});
                            props.fetchAllUnfulfilledSalesOrderByCustomerId({ relationshipId: (props.companyInfo || {}).relationshipId, customerId: customerId });
                        }
                    }
                    allowClear={true}
                    onClear={() => {
                        updateState({
                            customer: undefined,
                            customerName: undefined,
                            customerPaymentTerm: undefined,
                            customerShippingAddress: undefined,
                            customerBillingAddress: undefined,
                            placeOfSupply: undefined,
                            invoiceDueDate: undefined,
                            selectedContact: undefined,
                        })
                        props.resetUnfulfilledSalesOrder();
                    }}
                /> : null} */}
                <Button onClick={() => {
                    props.updateState({
                        ledgerDrawerVisible: true
                    })
                }} >
                    <i className='fa fa-book fa-lg'> </i>
                </Button>
                <div className="currency-converter-modal">
                    <span className="ant-btn ant-btn-icon-only icon" onClick={() => updateTemplateDropdown(!templateDropdown)}>
                        <i className="fa fa-exchange" />
                    </span>

                    {templateDropdown &&
                        <div ref={innerRef} className="content">
                            <Row className="mb10">
                                <Link onClick={() => {
                                    updateTemplateDropdown(false)
                                    
                                    props.popModalFromStack();
                                    props.pushModalToStack({
                                        modalBody: <StandardSalesInvoice {...props} />,
                                        width: '100%',
                                        hideTitle: true,
                                        hideFooter: true,
                                        wrapClassName: 'new-transaction-wrapper'
                                    })
                                }}
                                    // to="/admin/sales-invoice"
                                ><FormattedMessage id='template.standard.invoice' defaultMessage='' /></Link>
                            </Row>

                            <Row>
                                <Link onClick={() => {
                                    updateTemplateDropdown(false)
                                    props.popModalFromStack();
                                    props.pushModalToStack({
                                        modalBody: <MultiCurrencySalesInvoice {...props} />,
                                        width: '100%',
                                        hideTitle: true,
                                        hideFooter: true,
                                        wrapClassName: 'new-transaction-wrapper'
                                    })
                                }}
                                    // to="/admin/sales-invoice-multi-currency"
                                ><FormattedMessage id='template.standard.invoice.multicurrency' defaultMessage='' /></Link>
                            </Row>
                        </div>
                    }
                </div>
                {/* <div className="space-lr">
                    <Button icon={<SwapOutlined />} />
                </div> */}
          
     </div>
        </>
    );
};
const getFormatedNextTxNumber = (props) => {
    // const { customer } = props;
    // if (!customer || !customer.customerId) {
    //     return <span>{props.nextTxNumber}</span>;
    // }
    // return <span>{props.nextTxNumber.replace('INV-00', 'INV-' + (customer.customerId < 10 ? ('0' + customer.customerId) : customer.customerId))}</span>
    return <span>{props.nextTxNumber}</span>;
}

export default injectIntl(CustomerHeaderAction);
