import React from "react";
import { Button, Empty, Skeleton } from 'antd';
import './style.scss';
import {
    USPSLOGO,
    CareemLogo,
    UpsLogo,
    FedexLogo,
    AustraliaPostLogo,
    CanadaPostLogo,
    ChinaPostLogo,
    DeutschePost,
    DhlLogo,
    JapanPostLogo,
    RoyalMailLogo,
    Accurate,
    Amazon,
    Apc,
    Asendia,
    BetterTrucks,
    Canpar,
    Cdl,
    Chronopost,
    CirroEcom,
    CloudSort,
    LSO,
    CourierExpress,
    CourierPlease,
    DaiPost,
    DeliverIt,
    DhlEcom,
    DhlExpress,
    DpdLogo,
    EpostGlobal,
    Estafeta,
    DhlPacket,
    EvriLogo,
    Fastway,
    FirstChoice,
    FirstMile,
    Flexport,
    GIO,
    GSO,
    Hailify,
    InterlinkExpress,
    JetLogo,
    JitsuLogo,
    JpPost,
    KuronekoYamato,
    LaPoste,
    Lasership,
    LoomisExpress,
    Maergo,
    Newgistics,
    Omniparcel,
    Ontrac,
    Optima,
    OsmWorldwide,
    Pandion,
    ParcelForce,
    Purolator,
    RoyalMail,
    Sendle,
    Passport,
    Postnl,
    TforceLogistics,
    SfExpress,
    Smartkargo,
    Speedee,
    SwyftLogo,
    TollLogo,
    UdsLogo,
    UpsIParcel,
    UpsMailInnovations,
    Ups,
    Veho,
    Yanwen
} from '../../../../../assets/images';
import { getCurrencySymbol, showToasterMessage, } from '../../../../../utils';


const ShippingPrices = (props) => {

    const { shiprocketCarrierDetails={} } = props;

    const { data: { available_courier_companies = [] } = {} } = shiprocketCarrierDetails || {};

    const getIconForCarrier = (carrierName) => {
        const imgStyle = { maxWidth: 100, maxHeight: 40 }
        const cName = (carrierName || '').toLowerCase().replace(/\s/g, "");
        switch (true) {
            case cName.includes('usps'):
                return <img src={USPSLOGO} alt="source-logo" style={imgStyle} />
            case cName.includes('careem'):
                return <img src={CareemLogo} alt="source-logo" style={imgStyle} />
            case cName.includes('fedex'):
                return <img src={FedexLogo} alt="source-logo" style={imgStyle} />
            case cName.includes('ups'):
                return <img src={UpsLogo} alt="source-logo" style={imgStyle} />
            case cName.includes('australiapost'):
                return <img src={AustraliaPostLogo} alt="source-logo" style={imgStyle} />
            case cName.includes('canadapost'):
                return <img src={CanadaPostLogo} alt="source-logo" style={imgStyle} />
            case cName.includes('chinapost'):
                return <img src={ChinaPostLogo} alt="source-logo" style={imgStyle} />
            case cName.includes('deutschepost'):
                return <img src={DeutschePost} alt="source-logo" style={imgStyle} />
            case cName.includes('dhl'):
                return <img src={DhlLogo} alt="source-logo" style={imgStyle} />
            case cName.includes('japanpost'):
                return <img src={JapanPostLogo} alt="source-logo" style={imgStyle} />
            case cName.includes('royalmail'):
                return <img src={RoyalMailLogo} alt="source-logo" style={imgStyle} />
            case cName.includes('accurate'):
                return <img src={Accurate} alt="source-logo" style={imgStyle} />
            case cName.includes('amazon'):
                return <img src={Amazon} alt="source-logo" style={imgStyle} />
            case cName.includes('apc'):
                return <img src={Apc} alt="source-logo" style={imgStyle} />
            case cName.includes('asendiausa'):
                return <img src={Asendia} alt="source-logo" style={imgStyle} />
            case cName.includes('bettertrucks'):
                return <img src={BetterTrucks} alt="source-logo" style={imgStyle} />
            case cName.includes('canpar'):
                return <img src={Canpar} alt="source-logo" style={imgStyle} />
            case cName.includes('cdl'):
                return <img src={Cdl} alt="source-logo" style={imgStyle} />
            case cName.includes('chronopost'):
                return <img src={Chronopost} alt="source-logo" style={imgStyle} />
            case cName.includes('cirroecom'):
                return <img src={CirroEcom} alt="source-logo" style={imgStyle} />
            case cName.includes('cloudsort'):
                return <img src={CloudSort} alt="source-logo" style={imgStyle} />
            case cName.includes('lso'):
                return <img src={LSO} alt="source-logo" style={imgStyle} />
            case cName.includes('courierexpress'):
                return <img src={CourierExpress} alt="source-logo" style={imgStyle} />
            case cName.includes('courierplease'):
                return <img src={CourierPlease} alt="source-logo" style={imgStyle} />
            case cName.includes('daipost'):
                return <img src={DaiPost} alt="source-logo" style={imgStyle} />
            case cName.includes('deliverit'):
                return <img src={DeliverIt} alt="source-logo" style={imgStyle} />
            case cName.includes('dhlecom'):
                return <img src={DhlEcom} alt="source-logo" style={imgStyle} />
            case cName.includes('dhlexpress'):
                return <img src={DhlExpress} alt="source-logo" style={imgStyle} />
            case cName.includes('dhlpacket'):
                return <img src={DhlPacket} alt="source-logo" style={imgStyle} />
            case cName.includes('dpdlogo'):
                return <img src={DpdLogo} alt="source-logo" style={imgStyle} />
            case cName.includes('epostglobal'):
                return <img src={EpostGlobal} alt="source-logo" style={imgStyle} />
            case cName.includes('estafeta'):
                return <img src={Estafeta} alt="source-logo" style={imgStyle} />
            case cName.includes('evrilogo'):
                return <img src={EvriLogo} alt="source-logo" style={imgStyle} />
            case cName.includes('fastway'):
                return <img src={Fastway} alt="source-logo" style={imgStyle} />
            case cName.includes('firstchoice'):
                return <img src={FirstChoice} alt="source-logo" style={imgStyle} />
            case cName.includes('firstmile'):
                return <img src={FirstMile} alt="source-logo" style={imgStyle} />
            case cName.includes('flexport'):
                return <img src={Flexport} alt="source-logo" style={imgStyle} />
            case cName.includes('gio'):
                return <img src={GIO} alt="source-logo" style={imgStyle} />
            case cName.includes('gso'):
                return <img src={GSO} alt="source-logo" style={imgStyle} />
            case cName.includes('hailify'):
                return <img src={Hailify} alt="source-logo" style={imgStyle} />
            case cName.includes('interlinkexpress'):
                return <img src={InterlinkExpress} alt="source-logo" style={imgStyle} />
            case cName.includes('jetlogo'):
                return <img src={JetLogo} alt="source-logo" style={imgStyle} />
            case cName.includes('jitsulogo'):
                return <img src={JitsuLogo} alt="source-logo" style={imgStyle} />
            case cName.includes('jppost'):
                return <img src={JpPost} alt="source-logo" style={imgStyle} />
            case cName.includes('kuronekoyamato'):
                return <img src={KuronekoYamato} alt="source-logo" style={imgStyle} />
            case cName.includes('laposte'):
                return <img src={LaPoste} alt="source-logo" style={imgStyle} />
            case cName.includes('lasership'):
                return <img src={Lasership} alt="source-logo" style={imgStyle} />
            case cName.includes('loomisexpress'):
                return <img src={LoomisExpress} alt="source-logo" style={imgStyle} />
            case cName.includes('maergo'):
                return <img src={Maergo} alt="source-logo" style={imgStyle} />
            case cName.includes('newgistics'):
                return <img src={Newgistics} alt="source-logo" style={imgStyle} />
            case cName.includes('omniparcel'):
                return <img src={Omniparcel} alt="source-logo" style={imgStyle} />
            case cName.includes('ontrac'):
                return <img src={Ontrac} alt="source-logo" style={imgStyle} />
            case cName.includes('pptima'):
                return <img src={Optima} alt="source-logo" style={imgStyle} />
            case cName.includes('osmworldwide'):
                return <img src={OsmWorldwide} alt="source-logo" style={imgStyle} />
            case cName.includes('pandion'):
                return <img src={Pandion} alt="source-logo" style={imgStyle} />
            case cName.includes('parcelforce'):
                return <img src={ParcelForce} alt="source-logo" style={imgStyle} />
            case cName.includes('passport'):
                return <img src={Passport} alt="source-logo" style={imgStyle} />
            case cName.includes('postnl'):
                return <img src={Postnl} alt="source-logo" style={imgStyle} />
            case cName.includes('purolator'):
                return <img src={Purolator} alt="source-logo" style={imgStyle} />
            case cName.includes('royalmail'):
                return <img src={RoyalMail} alt="source-logo" style={imgStyle} />
            case cName.includes('sendle'):
                return <img src={Sendle} alt="source-logo" style={imgStyle} />
            case cName.includes('tforcelogistics'):
                return <img src={TforceLogistics} alt="source-logo" style={imgStyle} />
            case cName.includes('sfexpress'):
                return <img src={SfExpress} alt="source-logo" style={imgStyle} />
            case cName.includes('smartkargo'):
                return <img src={Smartkargo} alt="source-logo" style={imgStyle} />
            case cName.includes('speedee'):
                return <img src={Speedee} alt="source-logo" style={imgStyle} />
            case cName.includes('swyftlogo'):
                return <img src={SwyftLogo} alt="source-logo" style={imgStyle} />
            case cName.includes('tolllogo'):
                return <img src={TollLogo} alt="source-logo" style={imgStyle} />
            case cName.includes('udslogo'):
                return <img src={UdsLogo} alt="source-logo" style={imgStyle} />
            case cName.includes('upsiparcel'):
                return <img src={UpsIParcel} alt="source-logo" style={imgStyle} />
            case cName.includes('upsmailinnovations'):
                return <img src={UpsMailInnovations} alt="source-logo" style={imgStyle} />
            case cName.includes('ups'):
                return <img src={Ups} alt="source-logo" style={imgStyle} />
            case cName.includes('veho'):
                return <img src={Veho} alt="source-logo" style={imgStyle} />
            case cName.includes('yanwen'):
                return <img src={Yanwen} alt="source-logo" style={imgStyle} />
            default:
                return <span style={{ fontSize: '18px', fontWeight: 700 }}>{carrierName}</span>
        }
    }

    return (<>
        <Skeleton loading={props.listLoading} paragraph={{ rows: 5 }} active>
            <table style={{ width: '100%' }} className='mt20'>
                <thead style={{ borderBottom: '1px solid gray' }}>
                    <tr>
                        <th style={{ width: '50px' }}></th>
                        <th>Carrier & Service</th>
                        <th>Delivery Date</th>
                        <th>Delivery in (days)</th>
                        <th>Rate</th>
                    </tr>
                </thead>

                <tbody>
                    {
                        available_courier_companies?.length ?
                            available_courier_companies.map((item, i) => {
                                return <tr key={'carr' + i} style={{ height: '40px' }}>
                                    <td>
                                        <input type="radio" id='regular' name="optradio" onClick={() => {
                                                props.updateState({ selectedCarrierItem: item });
                                            }}
                                        />
                                    </td>
                                    <td>{getIconForCarrier(item.courier_name)}</td>
                                    <td>{item.etd}</td>
                                    <td>{item.estimated_delivery_days}</td>
                                    <td>{getCurrencySymbol(shiprocketCarrierDetails?.currency || 'USD')}{item.rate}</td>
                                </tr>
                            })
                            :
                            <tr key="empty-data-box">
                                <td colSpan={"100%"} style={{ borderBottom: "none" }}>
                                    <Empty />
                                </td>
                            </tr>
                    }
                </tbody>
            </table>
            <div className="fot-fix" style={{ maxWidth: '100%' }}>
                <div className="text-center">
                    <Button
                        disabled={props.stepError || props.saveLoading}
                        loading={props.saveLoading}
                        type="primary"
                        onClick={() => {
                            if (!(Object.keys(props?.selectedCarrierItem || {}).length)) {
                                return showToasterMessage({ description: 'Please select carrier service' });
                            } else {
                                const payload = {
                                    shipment_id: props.shiprocketOrderDetails?.shipment_id,
                                    courier_id: props.selectedCarrierItem?.courier_company_id,
                                    status: props.shiprocketOrderDetails.status || ""
                                }
                                props.generateAwbForShipment(payload, props);
                            }
                        }}
                    >
                        Schedule Pickup & Buy Shipping Label
                        {props.selectedCarrierItem?.rate ? `(${getCurrencySymbol(shiprocketCarrierDetails?.currency)}${props.selectedCarrierItem?.rate})` : ''}
                    </Button>
                </div>
            </div>
        </Skeleton>
    </>
    )
}

export default ShippingPrices;