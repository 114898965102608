import React from "react";

import { Button, Col, Divider, Empty, Menu, Row, Skeleton, Tabs } from "antd";
import { FormattedMessage } from "react-intl";
import { getCurrencySymbol } from "../../../utils";
import { TextBox } from "../../general/TextBox";
import { Dropdown, DropdownRef } from "../../general/Dropdown";
import { CONSTANTS, MODAL_TYPE } from "../../../static/constants";
import {
    PlusOutlined,
    MoreOutlined,
    EditOutlined,
    DeleteOutlined,
  } from "@ant-design/icons";
  import DropdownAction from "antd/lib/dropdown";
const {TabPane} = Tabs;



function Address(props) {

   console.log(props.companyInfo ,"HOMEEEE::::::::::::::::::::")
    const alladdressList = props.companyInfo?.boLocationList || [];
    const addressList = alladdressList.filter((ele) => {
      return (
        ele.locationType === CONSTANTS.RELATIONSHIP_BILLING_ADDRESS ||
        ele.locationType === CONSTANTS.RELATIONSHIP_SHIPPING_ADDRESS
      );
    });

    const handleNewAddressAddition = (address) => {
        let payload = {};
        let formData = {};
        payload.formData = {
          isUpdate: true,
          ...address,
          addressType: "relationship",
        };
        const { pushModalToStack, popModalFromStack, companyInfo } = props;
        formData.relationshipId = companyInfo.relationshipId;
        payload.formData.callback = (data) => {
          props.popModalFromStack();
          props.getRelationshipAddress({
            relationshipId: data.relationshipId,
          });
        };
        // payload.formData.onCancel = (data) => {
        //  props.popModalFromStack(data);
       
        // };
        payload.formData.onCancel = ()=>{
          // props.popModalFromStack();
            props.pushModalToStack({
                modalBody: <FormattedMessage id="modal.cancel.confirmation" defaultMessage=""/>,
                footer:{submit:{intlId:"button.close.label"}},
                handleSubmit: () => {
                  props.popModalFromStack();
                }
              })
      }
        const data = {
          // title: <FormattedMessage id='addItem.text.billingAddress' defaultMessage='Billing Address' />,
          title: address.cityName ? (
            "Edit Address"
          ) : (
            <FormattedMessage
              id="addItem.text.billingAddress"
              defaultMessage="Create Address"
            />
          ),
  
          formData,
          hideFooter: true,
          modalData: {
            modalType: MODAL_TYPE.BILLING_ADDRESS,
            data: payload,
          },
          handleSubmit: (formData = {}) => {
            data.formData.submittedOnce = true;
            popModalFromStack(data);
          },
          onCancel: () => {
            props.pushModalToStack({
              modalBody: <FormattedMessage id="modal.cancel.confirmation" defaultMessage=""/>,
              footer:{submit:{intlId:"button.close.label"}},
              handleSubmit: () => {
                props.popModalFromStack();
              }
            })
          }
        };
        // props.showModal(data);
        pushModalToStack(data);
  
      };
      const actionMenu = (e) => {
        return (
          <Menu className="row-action-dropdown">
            <Menu.Item key="0" value="title">
              Action
            </Menu.Item>
  
            <Menu.Divider />
  
            <Menu.Item
              key="1"
              onClick={() => {
                handleNewAddressAddition(e);
              }}
            >
              <EditOutlined />
              Edit
            </Menu.Item>
  
            <Menu.Item
              key="2"
              onClick={() => {
                const modalData = {
                  modalBody: (
                    <FormattedMessage
                      id="customer.address.delete.confirmation"
                      defaultMessage=""
                    />
                  ),
                  handleSubmit: () => {
                    props.deleteLocation(e.data, {
                      callback: () => {
                        props.getRelationshipAddress({
                          relationshipId: (props.companyInfo || {})
                            .relationshipId,
                        });
                      },
                    });
                    props.popModalFromStack();
                  },
                };
                props.pushModalToStack(modalData);
              }}
            >
              <DeleteOutlined />
              Delete
            </Menu.Item>
          </Menu>
        );
      };
    return (
        <div className="account-setting">
<div className="store-model-container cus-space">
   
   <div className="store-main-container">
   

    <div>
      <div>
      <div className="account-address-heading">
        <div>
        <p className="heading">Address</p>
    <p className="sub-heading">
    Manage and update your organization's physical address for correspondence and legal purposes.</p>
        </div>
        <div>
    <button
            className="create-action-btn"
            onClick={() => {
              handleNewAddressAddition({
                relationshipId: (props.companyInfo || {}).relationshipId,
                locationType: "RelationshipBillingAddress",
              });
            }}
          >
            <i className="fi fi-br-plus mr5"></i>
              <FormattedMessage id="button.create.label" defaultMessage="" />
          </button>
    </div>
    </div>
    
      </div>
   
    <div className="account-information">
    
    <div className="bank-info">
          <div className="table-container">
            <table>
              <thead>
                <tr>
                  <th>
                    <FormattedMessage id="serial.no" defaultMessage="" />
                  </th>
                  <th>
                    <FormattedMessage id="address.primary" defaultMessage="" />
                  </th>
                  <th>
                    <FormattedMessage
                      id="address.addressType"
                      defaultMessage=""
                    />
                  </th>
                  <th>
                    <FormattedMessage
                      id="address.streetAddress1"
                      defaultMessage=""
                    />
                  </th>
                  <th>
                    <FormattedMessage
                      id="address.streetAddress2"
                      defaultMessage=""
                    />
                  </th>
                  <th>
                    <FormattedMessage id="address.city" defaultMessage="" />
                  </th>
                  <th>
                    <FormattedMessage id="address.country" defaultMessage="" />
                  </th>
                  <th>
                    <FormattedMessage id="address.state" defaultMessage="" />
                  </th>
                  <th>
                    <FormattedMessage id="address.zip" defaultMessage="" />
                  </th>
                  <th>
                    <FormattedMessage id="customer.listing.header.action" defaultMessage="" />
                  </th>
                </tr>
              </thead>
              <tbody>
                {(addressList || []).length ? 
                  addressList.map((rowData, index) => {
                    return (
                      <tr key={index}>
                        <td>{index + 1}</td>

                        <td>{rowData.isDefault ? "Yes" : "No"}</td>
                        <td>
                          <div>
                            {rowData.locationType ===
                            "RelationshipBillingAddress"
                              ? "Billing"
                              : "Shipping"}
                          </div>
                        </td>
                        <td>{rowData.streetAddress1}</td>
                        <td>{rowData.streetAddress2}</td>
                        <td>{rowData.cityName}</td>
                        <td>{rowData.countryName}</td>
                        <td>{rowData.stateName}</td>
                        <td>{rowData.zipCode}</td>
                        <td className="text-center">
                          <DropdownAction
                            overlay={actionMenu(rowData)}
                            trigger={["click"]}
                            overlayClassName="action-listing-dropdown"
                          >
                            <MoreOutlined />
                          </DropdownAction>
                          
                        </td>
                      </tr>
                    );
                  })
                : <tr key="empty-data-box">
                <td colSpan={"100%"} style={{ borderBottom: "none" }}>
                    <Empty />
                </td>
            </tr>
                }
              </tbody>
            </table>
          </div>
          {/* <div className="ag-theme-balham" style={agGridStyle} >
                        <CustomAgGrid
                            columnDefs={columnDefs}
                            rowData={addressList || []}
                            rowDragManaged={true}
                            defaultColDef={{
                                flex:1,
                                autoHeight: true,
                                wrapText: true,
                            }}
                            suppressClickEdit={true}
                            noSingleClickEdit={true}
                            disableFocusOnClickOutside={true}
                            domLayout={"autoHeight"}
                            animateRows={true}
                            onGridSizeChanged={onFirstDataRendered}
                            //getRowHeight={getRowHeight}
                            editType={'fullRow'}
                            onGridReady={onGridReady}
                        >
                        </CustomAgGrid>
                    </div> */}
          {/* <Button className="add-new-record" onClick={
                        () => {
                            handleNewAddressAddition({ relationshipId: (props.companyInfo || {}).relationshipId, locationType: 'RelationshipBillingAddress' })
                        }
                    }>Add New Adddress </Button> */}
        </div>


    </div>
    
    </div>
   </div>
   

   
   


</div>
        </div>
        
    )
}
export default Address;