import React, { Component, Fragment } from "react";
import { FormattedMessage } from "react-intl";
// import { AgGridReact } from 'ag-grid-react';
// import ShowContact from "./../../../../containers/supplier/Listing/ShowConact";
// import moment from "moment-timezone";
import { CONSTANTS, ICONS,MODULE_CODED_VALUES } from "../../../../static/constants";
import { Menu, Skeleton, Empty } from "antd";
import { getMomentDateForUIReadOnly, getPermissionData, sortTable } from "../../../../utils";
import DropdownAction from "antd/lib/dropdown";

const format = CONSTANTS.DISPLAY_DATE_FORMAT;
class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...props,
    };
  }
  state = {
    buttonDropDown: false,
  };

  toggleDropdown = () => {
    const { buttonDropDown } = this.state;
    this.setState({
      buttonDropDown: !buttonDropDown,
    });
  };

  render() {
    const { pageSize, updateState, dir = 0, contacts,permissions } = this.props;
    const primaryPerm = (permissions && permissions.primary) || [];

    const permissionData =getPermissionData(primaryPerm, MODULE_CODED_VALUES.SUPPLIER_CONTACTS )
  
    const deleteContact = (props, record) => {
      const payload = {
        contactId: record.contactId,
        relationshipId: props.companyInfo.relationshipId,
        supplierId: props.supplierId,
        isPrimaryContact: record.isPrimaryContact,
      };
      props.deleteContact(payload);
      //props.resetPaginatedData();
      //props.fetchSuppliers({ relationshipId: props.companyInfo.relationshipId, pageNumber: props.pageNumber, pageSize: props.pageSize });
    };
    // const handleNewContactAddition = (contactData) => {
    //   const { pushModalToStack, companyInfo } = this.props;
    //   let payload = {};
    //   let formData = {
    //     supplierId: this.props.supplierId,
    //     contactData,

    //   };
    //   payload.formData = formData;
    //   const data = {
    //     title: <FormattedMessage id="addItem.text.contact" defaultMessage="" />,
    //     formData,
    //     hideFooter: true,
    //     modalData: {
    //       modalType: MODAL_TYPE.CONTACT,
    //       data: payload,
    //     },
    //     handleSubmit: (formData = {}) => {
    //       data.formData.submittedOnce = true;
    //       setTimeout(() => {
    //         this.props.fetchAllContacts({
    //           relationshipId: companyInfo.relationshipId,
    //           supplierId: this.props.supplierId,
    //         });
    //       }, 2000);
    //     },
    //   };
    //   pushModalToStack(data);
    // };

    const handleNewContactAddition = (contact) => {
      this.props.updateHeaderState({
        contact: {
          contactDrawer: true,
          // isLoaded:false,
          formData: {
            isUpdate: contact? true:false,
            ...contact,
            supplierId: this.props.supplierId,
          },
        },
      });
    };

    const actionMenu = (param) => (
      <Menu className="row-action-dropdown">
        <Menu.Item key="0" value="title">
          Actions
        </Menu.Item>

       {permissionData.update ? <Menu.Item
          key="5"
          value="edit"
          onClick={() => {
            handleNewContactAddition(param);
          }}
        >
          <i className={ICONS.EDIT} />
          <div>
            Edit
          </div>
        </Menu.Item>:""}

        {permissionData.delete ? <Menu.Item
          key="6"
          value="Delete"
          onClick={() => {
            const modalData = {
              modalBody: (
                <FormattedMessage
                  id="customer.address.delete.confirmation"
                  defaultMessage=""
                />
              ),
              handleSubmit: () => {
                deleteContact(this.props, param);
              },
            };
            this.props.pushModalToStack(modalData);
          }}
        >
          <i className={ICONS.DELETE} />
          Delete
        </Menu.Item>:""}
      </Menu>
    );
    // const agGridStyle = {
    //     height: '100%',
    //     width: '100%'
    // };

    // const onFirstDataRendered = (params) => {
    //     params.api.sizeColumnsToFit();
    // }
    // const getRowHeight = (params) => {
    //     return 30;
    // }

    // const columnDefs = [
    //     {
    //         headerName: "#",
    //         resizable: true,
    //         cellRendererFramework: function (link) {
    //             return <div>
    //                 {link.rowIndex + 1}
    //             </div>
    //         },
    //         width:50,
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='contact.title' defaultMessage='' />;
    //           },
    //         field: "salutationName",
    //         resizable: true,
    //         width:100,
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='contact.firstName' defaultMessage='' />;
    //           },
    //         field: "firstName",
    //         resizable: true,

    //     },
    //     // {
    //     //     headerComponentFramework: () => {
    //     //         return <FormattedMessage id='contact.middleName' defaultMessage='' />;
    //     //       },
    //     //     field: "middleName",
    //     //     resizable: true,

    //     // },
    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='contact.lastName' defaultMessage='' />;
    //           },
    //         field: "lastName",
    //         resizable: true,

    //     },
    //     // {
    //     //     headerName: "Preferred Call Name",
    //     //     resizable: true,
    //     //     field: "preferCallName",
    //     // },
    //     // {
    //     //     headerName: "Job Title",
    //     //     resizable: true,
    //     //     field: "jobTitle",
    //     // },
    //     // {
    //     //     headerName: "Department",
    //     //     resizable: true,
    //     //     field: "department",
    //     // },
    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='contact.email' defaultMessage='' />;
    //           },
    //         resizable: true,
    //         field: "emailAddress",
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='contact.workPhone' defaultMessage='' />;
    //           },
    //         resizable: true,
    //         field: "workPhone",
    //         width: 130,
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='contact.mobile' defaultMessage='' />;
    //         },
    //         resizable: true,
    //         field: "cellPhone",
    //         width: 130,
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='contact.faxNumber' defaultMessage='' />;
    //         },
    //         resizable: true,
    //         field: "faxNumber",
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='common.dateCreated' defaultMessage='' />;
    //         },
    //         resizable: true,
    //         width: 130,
    //         cellRendererFramework: (link) => <div>
    //             {moment(new Date(link.data.dateCreated)).format(format)}
    //         </div>
    //     },
    // ]

    // const columnDefs = [
    //     {
    //         title: "#",
    //         dataIndex: 'rowIndex',
    //         key: 'rowIndex',
    //         render:(text, record, index)=> <div>
    //             {index + 1}
    //         </div>,
    //         width: 50,
    //     },
    //     {
    //         title: <FormattedMessage id='contact.title' defaultMessage='' />,
    //         dataIndex: "salutationName",
    //         key: "salutationName",
    //         width:50,
    //     },
    //     {
    //         title: <FormattedMessage id='contact.firstName' defaultMessage='' />,
    //         dataIndex: "firstName",
    //         key: "firstName",
    //     },

    //     {
    //         title: <FormattedMessage id='contact.lastName' defaultMessage='' />,
    //         dataIndex: "lastName",
    //         key: "lastName",
    //     },

    //     {
    //         title: <FormattedMessage id='contact.email' defaultMessage='' />,
    //         dataIndex: "emailAddress",
    //         key: "emailAddress",
    //     },
    //     {
    //         title: <FormattedMessage id='contact.workPhone' defaultMessage='' />,
    //         dataIndex: "workPhone",
    //         key: "workPhone",
    //         width: 130,
    //     },
    //     {
    //         title: <FormattedMessage id='contact.mobile' defaultMessage='' />,
    //         dataIndex: "cellPhone",
    //         key: "cellPhone",
    //         width: 130,
    //     },
    //     {
    //         title: <FormattedMessage id='contact.faxNumber' defaultMessage='' />,
    //         dataIndex: "faxNumber",
    //         key: "faxNumber",
    //     },
    //     {
    //         title: <FormattedMessage id='common.dateCreated' defaultMessage='' />,
    //         dataIndex: "dateCreated",
    //         key: "dateCreated",
    //         width: 130,
    //         cellRendererFramework: (text, record, index) => <div>
    //             {moment(new Date(record.dateCreated)).format(format)}
    //         </div>
    //     },
    // ]

    const sortColumn = (e) => {
      sortTable(
        document.getElementById("contacts-table"),
        e.target.cellIndex,
        1 - dir
      );
      updateState({ dir: 1 - dir });
    };
    // const { buttonDropDown } = this.state;
    // const { toggleDropdown } = this;
    return (
      <Fragment>
        {/* <div className="view-top-bar">
          <div className="top-view-left">
            <div className="page-heading">
              <FormattedMessage
                id="heading.communications.contact"
                defaultMessage=""
              />
            </div>
          </div>
          <div className="top-view-right">
            <button
              className="create-action-btn"
             
              onClick={() => {
              
                handleNewContactAddition();
              }}
            >
              <i className={ICONS.ADD} />{" "}
              <FormattedMessage id="button.create.label" defaultMessage="" />
            </button>
          </div>
        </div> */}

        {/* <span className={buttonDropDown ? "arrow-down active" : "arrow-down"} onClick={toggleDropdown}>
                            <i className="fa fa-angle-down" />
                        </span> */}

        {/* <ul className="dropdown">
                        <li className="option-list">
                                <i className="fa fa-file-pdf-o" />
                                <FormattedMessage id='contact.downloadPdf' defaultMessage='' />
                            </li>
                            <li className="option-list">
                                <i className="fa fa-file-excel-o" />
                                <FormattedMessage id='contact.exportExcel' defaultMessage='' />
                            </li>
                            <li className="option-list">
                                <i className="fa fa-envelope" />
                                <FormattedMessage id='email' defaultMessage='' />
                            </li>
                        </ul> */}

        {/* <div className='agGridWrapper'>
                        <div className="ag-theme-balham" style={agGridStyle} >
                            <AgGridReact
                                columnDefs={columnDefs}
                                rowData={contacts || []}
                                rowDragManaged={true}
                                domLayout={"autoHeight"}
                                animateRows={true}
                                onGridSizeChanged={onFirstDataRendered}
                                getRowHeight={getRowHeight}
                            >
                            </AgGridReact>
                        </div>
                    </div> */}
        {/* <Table pagination={false} columns={columnDefs} dataSource={contacts || []} className="customer-transactions-table" /> */}
        <div className="view-container">
          <div className="view-container-actions">
            <div className="left-actions">
              <div className="table-heading">
                <FormattedMessage
                  id="communication.contacts.table.heading"
                  defaultMessage=""
                />
              </div>
              {pageSize ? (
                <>
                  <div className="vertical-sep" />
                  <div>{contacts.length}</div>
                </>
              ) : (
                ""
              )}
            </div>
            <div className="right-actions">
              {permissionData.create ?
              <button
                  className="create-action-btn"
                style={{
                  backgroundColor: "#1cb961",
                  color: "#ffffff",
                  fontSize: "14px",
                }}
                onClick={() => {
                  handleNewContactAddition();
                }}
              >
                <i className={`${ICONS.ADD} mr5`} />{" "}
                <FormattedMessage
                  id="button.create.label"
                  defaultMessage=""
                />
              </button> :"" }
            </div>
          </div>
          <Skeleton loading={this.props.listLoading} active paragraph={{ rows: 9 }}>
            <div className="table-container" style={{ height: 'calc(100vh - 138px)'}}>
              <table id="contacts-table">
                <thead>
                  <tr>
                    <th>S. No.</th>
                    {/* <th onClick={sortColumn}>
                      <FormattedMessage id="contact.title" defaultMessage="" />
                    </th> */}
                    <th onClick={sortColumn}>
                      <FormattedMessage
                        id="contact.primary"
                        defaultMessage=""
                      />
                    </th>
                    <th onClick={sortColumn}>
                      <FormattedMessage
                        id="contact.fullName"
                        defaultMessage=""
                      />
                    </th>
                    <th onClick={sortColumn}>
                      <FormattedMessage id="contact.email" defaultMessage="" />
                    </th>
                    <th onClick={sortColumn}>
                      <FormattedMessage id="contact.phone" defaultMessage="" />
                    </th>
                    <th onClick={sortColumn}>
                      <FormattedMessage id="contact.mobile" defaultMessage="" />
                    </th>
                    <th onClick={sortColumn}>
                      <FormattedMessage
                        id="contact.faxNumber"
                        defaultMessage=""
                      />
                    </th>
                    <th onClick={sortColumn}>
                      <FormattedMessage
                        id="common.dateCreated"
                        defaultMessage=""
                      />
                    </th>
                   {permissionData.update || permissionData.delete ? <th onClick={sortColumn}>
                      <FormattedMessage id="Action" defaultMessage="" />
                    </th> :"" }
                  </tr>
                </thead>
                <tbody>
                  {(contacts || []).length ? (
                    contacts.sort((a, b) => (a.contactId < b.contactId ? 1 : -1)).map((e, i) => {
                      return (
                        <tr key={i}>
                          <td>{i + 1}</td>
                          <td>{e.isPrimaryContact ? "Yes" : "No"}</td>
                          <td>
                            {`${e.salutationName} ${e.firstName}
                            ${e.lastName}`}
                          </td>
                          {/* <td>{e.firstName}</td>
                          <td>{e.lastName}</td> */}
                          <td>{e.emailAddress || <div className="empty-data-box"></div>}</td>
                          <td>{e.workPhone || <div className="empty-data-box"></div>}</td>
                          <td>{e.cellPhone || <div className="empty-data-box"></div>}</td>
                          <td>{e.faxNumber?e.faxNumber:<div className="empty-data-box"></div>}</td>
                          <td>
                            {e.dateCreated
                              ? getMomentDateForUIReadOnly({
                                  date: new Date(e.dateCreated),
                                  format,
                                })
                              : <div className="empty-data-box"></div>}
                          </td>
                          {permissionData.update || permissionData.delete ? <td style={{ textAlign: "center" }}>
                            <DropdownAction
                              overlay={actionMenu(e)}
                              trigger={["click"]}
                              overlayClassName="action-listing-dropdown"
                            >
                              <i className={ICONS.MORE} />
                            </DropdownAction>
                          </td> : "" }
                        </tr>
                      );
                    })
                  ) : (
                    <tr key="empty-data">
                      <td colSpan={"100%"} style={{ borderBottom: "none" }}>
                        <Empty />
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </Skeleton>
        </div>
      </Fragment>
    );
  }
}

export default Contact;
