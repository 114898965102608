import React from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { formatDateForBackendPlain, getMomentDateForUIReadOnly, sortTable } from "../../../utils";
import { Menu, Skeleton } from "antd";
import { ICONS } from "../../../static/constants";

import LeadProfile from "../../../containers/modal/modalBody/leads/profile";

const LeadAllTaskList = (props) => {
  const { dir = 0 } = props;

  const breadCrumbList = [
    {
      name: <FormattedMessage id="sidebar.menuItems.Leads" defaultMessage="" />,
    },
    {
      name: "Reminder",
    },
  
  ];

  const sortColumn = (e) => {
    let cellIndex = e.target.cellIndex;
    if (!cellIndex) {
      cellIndex = e.target.parentElement.cellIndex;
    }
    if (!cellIndex) {
      cellIndex = e.target.parentElement.parentElement.cellIndex;
    }
    sortTable(document.getElementById("leadSource-table"), cellIndex, 1 - dir);
  };

  const actionMenu = (item) => {
    return (
      <Menu className="row-action-dropdown">
        <Menu.Item key="0" value="title">
          Actions
        </Menu.Item>
        <Menu.Divider />

        {/* <Menu.Item
          key="1"
          value="edit"
          onClick={() => {
            props.updateState({
              leadSourceListDrawer: true,
              ...item,
            });
          }}
        >
          <i className={ICONS.EDIT} /> Edit
        </Menu.Item> */}

        <Menu.Item
          key="2"
          value="delete"
          onClick={() => {
            props.showModal({
              modalBody: "Are you sure you want to delete?",
              handleSubmit: () => {
                props.deleteCrmLeadSource(item, props);
              },
            });
          }}
        >
          <i className={ICONS.DELETE} /> Delete
        </Menu.Item>
      </Menu>
    );
  };
  const openLeadProfile = (rowData) => {
    
    
    let leadData = props.allLeadList[1].find((item)=>item.crmLeadId === rowData.leadId);
    console.log("scsdcsdcscscsc",rowData,"sdsfsd",leadData,props);
    const modalData = {
      title: (
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>Lead profile</div>
        </div>
      ),
      modalBody: <LeadProfile {...props} leadData={leadData} activeTabKey={"tasksTab"} />,
      alertCancel: true,
      onCancel: () => {
        props.showModal({
          modalBody: <FormattedMessage id="modal.cancel.confirmation" defaultMessage=""/>,
          footer:{submit:{intlId:"button.close.label"}},
          handleSubmit:handelClose
        })
      },
      hideFooter: true,
      hideTitle: true,
      width: "100%",
      height: "100%",
      maskClosable: true,
      keyboard: false,
      wrapClassName: "new-transaction-wrapper",
    };
    props.pushModalToStack(modalData);
  };

  const handelClose = ()=>{
    props.updateStateLayer({
      endTime:formatDateForBackendPlain(new Date())
    })
    
props.popModalFromStack();

  }
  return (
    // <div className="lead-list">
<>

      <div className="view-container-actions">
        <div className="left-actions">
          <div className="table-heading">
            <FormattedMessage
              id="leads.leadForm.formMeta.leadSource.table.heading"
              defaultMessage=""
            />
          </div>
        </div>

        <div className="right-actions">
      
        </div>
      </div>
      <div className="table-container" style={{ height: "calc(100% - 59px)" }}>
      <Skeleton loading={props.listLoading} paragraph={{ rows: 12 }} active>
        <table id="leadSource-table">
          <thead>
            <tr>
              <th>S.No.</th>
              <th onClick={sortColumn}>
                Created By <i className={ICONS.SORT}></i>
              </th>
            
              <th onClick={sortColumn}>
              Priority <i className={ICONS.SORT}></i>
                    </th>
              <th onClick={sortColumn}>
             Ref Number <i className={ICONS.SORT}></i>
              </th>
              <th onClick={sortColumn}>
                Lead Name <i className={ICONS.SORT}></i>
              </th>
              <th onClick={sortColumn}>
             Task Due Date <i className={ICONS.SORT}></i>
              </th>
              <th onClick={sortColumn}>
             Task Close Date <i className={ICONS.SORT}></i>
              </th>


              <th onClick={sortColumn}>
            Resolved<i className={ICONS.SORT}></i>
              </th>


        
              {/* <th width="6%">Action</th> */}
            </tr>
          </thead>

          <tbody>
            {(props.leadTaskListPage || []).map((rowData, i) => {
              return (
                <tr key={"crm" + rowData.leadtaskId}>
                  <td>{i + 1}</td>
                  <td>{rowData.userCreatedName || "-"}</td>
                  <td>
                    {rowData.priority}
                  </td>

                             
                  <td>{rowData.refNumber || "-"}</td>
                  <td   className="cursor-pointer"
                  style={{color:"blue"}}
                        onClick={() => openLeadProfile(rowData, props)}>{rowData.leadName || "-"}</td>
                  <td>{getMomentDateForUIReadOnly(rowData.taskClosedDate)}</td>
                  <td>{getMomentDateForUIReadOnly(rowData.taskDueDate)}</td>
                  <td>{rowData.resolved ? "Yes":"NO"}</td>
                  
                </tr>
              );
              // })
            })}
          </tbody>
        </table>
      </Skeleton>
      </div>
</>
  );
};

export default injectIntl(LeadAllTaskList);
