import axios from "axios";
import config from "../../../../../config/environmentConfig";
import {
  COMMON_ACTIONS,
  CUSTOMER_ACTION_LIST,
} from "../../../../../static/constants";
import { showToasterMessage,getFinancialYear } from "../../../../../utils";
const { lang } = require("../../../../../translations/" +
  (sessionStorage.getItem("language") || "en") +
  ".js");

export const fetchUnpaidInvoices = (payload, history) => {
  let FY = getFinancialYear(payload.companyInfo);
  return (dispatch) => {
    //dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    let url = "";
    if (payload.customerId) {
      url = `${
        config.rootContext
      }/customerInvoice/getUnpaidInvoicesByCustomerId?customerId=${
        payload.customerId
      }&relationshipId=${payload.relationshipId}&pageSize=${
        payload.pageSize || 10
      }&pageNumber=${(payload.pageNumber || 1) - 1}`;
    } else {
      url = `${
        config.rootContext
      }/customerInvoice/getAllUnpaidInvoices?relationshipId=${
        payload.relationshipId
      }&pageSize=${payload.pageSize || 10}&pageNumber=${
        (payload.pageNumber || 1) - 1
      }&startDate=${FY.fyStartDate}&endDate=${FY.fyEndDate}`;
    }
    dispatch({ type: COMMON_ACTIONS.SHOW_MODAL_LOADER });

    return axios
      .get(`${config.API_BASE_URL}/${config.API_VERSION}` + url)
      .then((res) => {
        const data = {
          pageNo: payload.pageNumber,
          list: res.data,
        };
        dispatch({
          type: CUSTOMER_ACTION_LIST.CUSTOMER_UNPAID_INVOICES_BY_CUSTOMER,
          data,
        });
        dispatch({ type: COMMON_ACTIONS.HIDE_MODAL_LOADER });
      })
      .catch((err) => {
        dispatch({ type: COMMON_ACTIONS.HIDE_MODAL_LOADER });
          showToasterMessage({
            messageType: "error",
            description:
              lang[((err.response || {}).data || {}).message] ||
              "Some error occurred",
          });
       
      });
  };
};

export const fetchUnpaidInvoicesCount = (payload, history) => {
  let FY = getFinancialYear(payload.companyInfo);
  const searchedTextQuery =
    payload.searchedText && payload.searchedText.length
      ? `&searchString=${payload.searchedText}`
      : "";
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    let url = "";
    if (payload.customerId) {
      url = `${config.rootContext}/customerInvoice/countUnpaidInvoicesByCustomerId?relationshipId=${payload.relationshipId}&customerId=${payload.customerId}&startDate=${FY.fyStartDate}&endDate=${FY.fyEndDate}${searchedTextQuery}`;
    } else {
      url = `${config.rootContext}/customerInvoice/countUnpaidInvoicesByRId?relationshipId=${payload.relationshipId}&startDate=${FY.fyStartDate}&endDate=${FY.fyEndDate}${searchedTextQuery}`;
    }
    return axios
      .get(`${config.API_BASE_URL}/${config.API_VERSION}` + url)
      .then((res) => {
        dispatch({
          type: CUSTOMER_ACTION_LIST.CUSTOMER_UNPAID_INVOICES_COUNT_BY_CUSTOMER,
          data: res.data,
        });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          showToasterMessage({
            messageType: "error",
            description:
              lang[((err.response || {}).data || {}).message] ||
              "Some error occurred",
          });
        } else {
          err.actionToCall =
            CUSTOMER_ACTION_LIST.CUSTOMER_UNPAID_INVOICES_COUNT_BY_CUSTOMER;
        }
      });
  };
};

export const resetPaginatedData = () => {
  return (dispatch) => {
    dispatch({ type: CUSTOMER_ACTION_LIST.CUSTOMER_UNPAID_INVOICES_RESET });
  };
};
