import React, { useState } from "react";
import DashboardComp from "../../components/dashboard/dashboard";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

const Dashboard = (props) =>{
const [state,updateState] = useState({
  // selectedDasboard:"cloud_erp"
}) 
return <> 
  <DashboardComp 
        {...props} 
        state={state} 
        updateState={updateState} 
        {...state} 
      />
</>
}
const mapStateToProps = (state) => {
 
    return {
      companyInfo:state.common.companyInfo,
      permissions: state.auth.permissions
    };
  };
  const mapDispatchToProps = (dispatch) => bindActionCreators({
    
  }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)
