import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  showModal,
  pushModalToStack,
  hideModal,
  popModalFromStack,
} from "../../../actions/commonActions";
import LeadForm from "../../../components/leads/LeadForm";
import {
  SaveCrmLeadFormSetting,
  fetchCrmLeadFormList,
  getCrmLeadFormListCount,
  deleteCrmLeadFormListItem,
} from "./action";

class LeadFormComp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pageNumber: 1,
      pageSize: 25,
    };
  }

  componentDidMount() {
    const companyInfo = this.props.companyInfo || {};

    const payload = {
      relationshipId: companyInfo.relationshipId,
      pageNumber: 1,
      pageSize: 25,
    };

    this.props.fetchCrmLeadFormList(payload);
    this.props.getCrmLeadFormListCount(payload);
  }

  render() {
    return (
      <LeadForm
        {...this.props}
        {...this.state}
        updateState={(data) => {
          this.setState(data);
        }}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    isLoading: state.common.isLoading,
    permissions: state.auth.permissions,
    crmLeadFormList: state.crm.crmLeadFormList,
    crmLeadFormListCount: state.crm.crmLeadFormListCount,
    listLoading: state.common.listLoading
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      showModal,
      popModalFromStack,
      pushModalToStack,
      hideModal,
      SaveCrmLeadFormSetting,
      fetchCrmLeadFormList,
      getCrmLeadFormListCount,
      deleteCrmLeadFormListItem,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(LeadFormComp);
