import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import ProfitAndLossComponent from '../../../components/finance/ProfitAndLoss';
import { fetchAllLedgerAccounts } from '../LedgerAccounts/action';
import { fetchDataIfNeeded } from '../../../utils';
import { showModal, hideModal, pushModalToStack, popModalFromStack } from '../../../actions/commonActions';

class ProfitAndLossComp extends Component {

    componentWillMount(){
        this.props.updateHeaderState({
            collapsed: false
          });
    }

    componentDidMount() {
        const companyInfo = this.props.companyInfo || {};
        const payload = {
            relationshipId: companyInfo.relationshipId
        };
        fetchDataIfNeeded('fetchAllLedgerAccounts', 'allLedgerAccounts', this.props, payload);
    }

    render() {
        return <ProfitAndLossComponent {...this.props}/>
    }

}

const mapStateToProps = (state) => {
    return {
        companyInfo: state.common.companyInfo,
        isLoading: state.common.isLoading,
        allLedgerAccounts: state.ledger.allLedgerAccounts,
        listLoading: state.common.listLoading
    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchAllLedgerAccounts,
    showModal, 
    hideModal, 
    pushModalToStack, 
    popModalFromStack
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ProfitAndLossComp);
