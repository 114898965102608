import React, { useMemo } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Skeleton, Menu, Pagination, Input, Empty } from 'antd';
import PageBreadcrumb from "../../PageBreadcrumb";
import DropdownAction from "antd/lib/dropdown";
import { checkACLPermission, fetchPaginationDataIfNeeded, getPermissionData } from '../../../utils';
import { CONSTANTS, ICONS, MODULE_CODED_VALUES, PERMISSION_VALUES } from '../../../static/constants';
import { DownloadOutlined } from "@ant-design/icons";
import debounce from 'lodash.debounce';
import PageHeader from '../../Common/pageHeader';
const { Search } = Input;

const Allowance = (props) => {
  const { companyInfo, allAllowance, pageNumber, pageSize ,permissions} = props
  const primaryPerm = (permissions && permissions.primary) || [];
  const permissionsData = useMemo(()=>{
    return getPermissionData(primaryPerm , MODULE_CODED_VALUES.HRMS_PAYROLL);
  },[])
 
  const breadCrumbList = [
    {
      name: "Dashboard",
    },
    {
      name: <FormattedMessage id='sidebar.menuItems.employee' />,
    },
    {
      name: "Allowance",
    }
  ];

  const showTotal = (total) => {
    return (
      props.intl.formatMessage({
        id: "pagination.text.total",
        defaultMessage: "",
      })
      +
      ` ${total} ` +
      props.intl.formatMessage({
        id: "pagination.text.items",
        defaultMessage: "",
      })
    );
  };
  const loadPageData = (pageNumber, pageSize) => {
    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      pageNumber: pageNumber,
      pageSize: pageSize,
    };
    fetchPaginationDataIfNeeded('getAllowance', 'allAllowance', props, payload);

  };

  const handlePageSizeChange = (pageNumber, pageSize) => {
    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      pageNumber: pageNumber,
      pageSize: pageSize,
    };
    // props.resetPaginatedData();
    props.getAllowance(payload);
    props.updateState({ pageSize: pageSize, pageNumber: pageNumber });
  };

  const actionMenu = (e) => {
    return (
      <Menu className="row-action-dropdown">
        <Menu.Item key="0" value="title">
          Actions
        </Menu.Item>
        <Menu.Divider />
         {
          permissionsData ?  <Menu.Item
          key="1"
          value="edit"
          onClick={() => {
            props.updateState({
              allowanceDrawer: true,
              allowanceData: e,
            })
          }}
        >
          <i className={ICONS.EDIT} /> Edit
        </Menu.Item>: ""
         }
       
       {
        permissionsData?.delete ? <Menu.Item
        key="2"
        value="delete"
        onClick={() => {
          props.showModal({
            modalBody: "Are you sure want to delete?",
            handleSubmit: () => {
              props.deleteAllowance(e, props)
            },
          });
        }}
      >
        <i className={ICONS.DELETE} /> Delete
      </Menu.Item>: ""
       }

        
      </Menu>
    );
  };

  const onSearchedInputChange = debounce((searchedText, props) => {
    handleSearchedText(searchedText, props);
  }, 100);

  const handleSearchedText = (searchedText, props) => {
    const payload = {
      relationshipId: props.companyInfo.relationshipId,
      pageNumber: 1,
      pageSize: props.pageSize,
      searchedText: searchedText,
    };
    if (searchedText.length) {
      if (
        searchedText.length >= 3 &&
        !props.searchedText.toLowerCase().startsWith(searchedText.toLowerCase())
      ) {
        props.getTotalAllowanceCount(payload);
        props.getAllowance(payload);
      }
    } else {
      props.getTotalAllowanceCount(payload);
      props.getAllowance(payload);
    }

  };

  return (
    <>
    <PageHeader {...props}
        pageName="heading.team.payrollManagement.allowance"
        breadCrumbList={breadCrumbList}
        canCreate={true}
        onCreate={()=>{ props.updateState({ allowanceDrawer: true, allowanceData: {} });
      }}
        moduleCode = {MODULE_CODED_VALUES.HRMS_PAYROLL}
      
        />
      

      <div className="view-container">
        <div className="view-container-actions">
          <Skeleton loading={props.headerLoading} paragraph={false} rows={1} active>
            <div className="left-actions">
              <div className="table-heading">
                <FormattedMessage
                  id="company.payrollMangement.allowance.table.heading"
                  defaultMessage=""
                />
              </div>
              {/* {[]?.length ? (
                <>
                  <div className="vertical-sep" />
                  <div>{[]?.length}</div>
                </>
              ) : (
                ""
              )} */}
            </div>
            <div className="right-actions">

              <Search
                placeholder="Search Allowance"
                value={props.searchedText}
                onChange={(e) => {
                  props.updateState({ searchedText: e.target.value, pageNumber: 1 });
                  onSearchedInputChange(e.target.value, props);
                }}
                onSearch={(searchedText) => {
                  props.updateState({ searchedText, pageNumber: 1 });
                  onSearchedInputChange(searchedText, props);
                  handleSearchedText(searchedText, props);
                }}
              />
              <DropdownAction
                //  overlay={downloadMenu()}
                trigger={["click"]}>
                <span>
                  <DownloadOutlined /> &nbsp;{" "}
                  <FormattedMessage
                    id="common.action.dropdown.downloadAs"
                    defaultMessage=""
                  />
                </span>
              </DropdownAction>
            </div>
          </Skeleton>
        </div>

        <div className="table-container">
          <Skeleton loading={props.listLoading} paragraph={{ rows: 17 }} active>
            <table id="employee-table">
              <thead>
                <tr>
                  <th>
                    <FormattedMessage id="serial.no" defaultMessage=" " />
                  </th>

                  <th>
                    <FormattedMessage
                      id="company.payrollManagement.allowanceName"
                      defaultMessage=" "
                    />
                  </th>


                  <th>
                    <FormattedMessage
                      id="company.payrollManagement.allowanceType"
                      defaultMessage=" "
                    />
                  </th>


                  <th>
                    Allowance Rate
                  </th>

                  <th>
                    <FormattedMessage
                      id="company.payrollManagement.limit"
                      defaultMessage=" "
                    />
                  </th>
                     {
                       (permissionsData?.update || permissionsData?.delete) ?  <th className="text-center">
                      <FormattedMessage
                        id="actions.text"
                        defaultMessage=" "
                      />
                    </th>: ""
                     }
                 
                </tr>
              </thead>

              <tbody>
                {allAllowance && allAllowance[pageNumber]
                  ? allAllowance[pageNumber]
                    // ?.sort((a, b) => (a.allowanceId < b.allowanceId ? 1 : -1))
                    .map((rowData, index) => {
                      return (
                        <tr key={index}>
                          <td>{(pageNumber - 1) * pageSize + (index + 1)}</td>
                          <td>
                            {rowData?.allowanceName}
                          </td>
                          <td>
                            {rowData?.allowanceType}
                          </td>
                          <td>{rowData.allowanceRate}</td>
                          <td>{rowData.limitAmount}</td>
                          {
                             (permissionsData?.update || permissionsData?.delete) ?  <td className='text-center'>
                            <DropdownAction
                              overlay={actionMenu(rowData)}
                             
                              trigger={["click"]}
                            >
                              <i className={ICONS.MORE} />
                            </DropdownAction>
                          </td>: ""
                          }
                         
                        </tr>
                      );
                    })
                  :
                  <tr key="empty-data-box">
                    <td colSpan={"100%"} style={{ borderBottom: "none" }}>
                      <Empty />
                    </td>
                  </tr>
                }
              </tbody>

            </table>
          </Skeleton>
        </div>
        <div className="footer">
          <Skeleton loading={props.headerLoading} paragraph={false} rows={1} active>

            <div className="f-left"></div>
            <div className="f-right">
              <Pagination
                size="small"
                total={props.allowanceCount}
                showTotal={showTotal}
                defaultPageSize={props.pageSize}
                showSizeChanger
                pageSizeOptions={[10, 25, 50, 100, 200]}
                onChange={(pageNumber, pageSize) => {
                  loadPageData(pageNumber, pageSize);
                  props.updateState({ pageSize: pageSize, pageNumber: pageNumber });
                }}
                onShowSizeChange={(pageNumber, pageSize) => {
                  handlePageSizeChange(pageNumber || 1, pageSize, props);
                }}
              />
            </div>
          </Skeleton>
        </div>

      </div>
    </>
  );
};


export default injectIntl(Allowance);
