import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ClassComp from "../../../../components/drawer/schoolManagement/class";
import { pushModalToStack, popModalFromStack, showModal, hideModal, fetchAllEmployees } from './../../../../actions/commonActions';
import { saveSchool } from '../../../schoolManagement/school/action';
import { getSchoolCourse } from '../../../schoolManagement/course/action';
import { getGrade } from '../../../schoolManagement/grade/action';
import { getSection } from '../../../schoolManagement/section/action';
import { getSubject } from '../../../schoolManagement/subject/action';
import { getTeacher } from '../../../schoolManagement/teacher/action';
import { saveClass, fetchClassSchedule, addClassSchedule, deleteClassSchedule } from './action';
import { fetchPaginationDataIfNeeded } from "../../../../utils";
import { getClassList, getClassListCount } from '../../../schoolManagement/class/action';
import { getAllStudentForm } from './../../../Student/studentFormList/action';
import { getDepartmentList } from "../../../schoolManagement/department/action";
import { getCourseTermList } from "../../../schoolManagement/courseTerm/action";
import find from 'lodash.find';

class CourseDrawer extends Component {
  constructor(props) {
    super(props);
    let classData = props.classData || {};
    this.state={
      ...classData,
      selectedSubjects: (classData.subjectList || [])?.map(obj=>obj.subjectId),
      addSectionList:  !(classData.classSectionList || [{}]).length ? classData.classSectionList[0] : (classData.classSectionList || [{}]),
      enableSections: ((classData.classSectionList || []).filter(obj=>obj.sectionName != obj.className).length > 0) ? 1 : 0
    }

  }

  componentDidMount() {

    const companyInfo = this.props.companyInfo || {};
    const payload = {
      relationshipId: companyInfo.relationshipId,
      relationshipEmployeeId: (this.props.userInfo || {}).relationshipEmployeeId,
      pageSize: 100,
      pageNumber: 1
    };
    fetchPaginationDataIfNeeded("getSchoolCourse", "courseList", this.props, payload);
    fetchPaginationDataIfNeeded("getSection", "sectionList", this.props, payload);
    // fetchPaginationDataIfNeeded("getTeacher", "teacherList", this.props, payload);
    // fetchPaginationDataIfNeeded("getAllStudentForm", "studentFormList", this.props, payload);
    this.props.getDepartmentList(payload);
    this.props.getCourseTermList(payload);

  }

  componentWillReceiveProps(props){
    let classData = props.classData || {};

    if(Object.keys(this.props.classData || {}).length && this.props.courseList && props.courseList[1] && this.props.courseList[1].length && !this.state.subjectListloaded){
      const selectedCourse = find(this.props.courseList[1] || [], {
        courseId: Number(this.props.classData.courseId),
      });
     
      this.setState({   
        selectedCourse : selectedCourse,
        subjectListloaded:true,
        courseId: this.props.classData.courseId,
        selectedSubjects: (this.props.classData.subjectList || [])?.map(obj=>obj.subjectId)  })
    }
  }

  render() {
    return (
      <ClassComp
      {...this.state}
      {...this.props}
        updateState={(data) => {
          this.setState(data);
        }}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    userInfo: state.common.userInfo,
    relationshipId: state.common.companyInfo.relationshipId,
    allEmployee: state.common.employees,
    sectionList: state.school.sectionList,
    gradeList: state.school.gradeList,
    subjectList: state.school.subjectList,
    courseList: state.school.courseList,
    teacherList: state.school.teacherList,
    classScheduleList: state.school.classScheduleList,
    studentFormList: state.membershipReducer.studentFormList,
    departmentList: state.school.departmentList,
    courseTermList: state.school.courseTermList,
    listLoading: state.common.listLoading,
    saveLoading: state.common.saveLoading
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      saveSchool,
      fetchAllEmployees,
      getSchoolCourse,
      getGrade,
      getSection,
      pushModalToStack, popModalFromStack,showModal, hideModal,
      getSubject,
      saveClass,
      getTeacher,
      getClassList, 
      getClassListCount,
      fetchClassSchedule, 
      addClassSchedule, 
      deleteClassSchedule,
      getAllStudentForm,
      getDepartmentList,
      getCourseTermList,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(CourseDrawer);
