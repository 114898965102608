import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";


import { pushModalToStack ,popModalFromStack } from '../../../../../actions/commonActions';
import { fetchE_StoreData } from '../../../../../actions/commonActions';
import { showModal, hideModal } from '../../../../../actions/commonActions';

import { postDeliveryCharges } from './action';

import DeliveryComp from '../../../../../components/modal/modalBody/eStore/Delivery';
//import { info } from 'sass';
class Delivery extends Component {
    constructor(props){
        super(props);
        this.state= {
          isLoaded: false,
          isFixed:1,
          fixedError:'',
          index:0,
          variableErrorMessage:'',
          variableError:[],
          variableError2:[],
          variableErrorMessage2:'',
          isHeaderMenuLoaded: false,
          deliveryValue :{
            "isDeliveryCharge":0,
            "byCurrency":1,
            "weightUnit":"Grams",
            "isFixed":1,
            "chargesInfo":[{
                        id: 1,
                        "starting":0,
                        "ending":500,
                        "amount":0,
                      },
                      {
                         id:2 ,
                        "starting":500,
                        "ending":9999999,
                        "amount":0,
                      }]
          ,
            "deliveryTime":[{
              
              time:3,
              unit:"hours",
              id:1,
            },
            {
              time:15,
              unit:"minutes",
              id:2,
            }],
            "selectedDeliveryTime":{
              
              time:3,
              unit:"hours",
              id:1,
            },
          }
        }
    }
   
    componentDidMount() {
      const companyInfo = this.props.companyInfo || {};
      const payload = {
        relationshipId: companyInfo.relationshipId,
        pageNumber: 1,
        pageSize: 100
      };
   
    
     this.props.fetchE_StoreData(payload)
    }
    componentWillReceiveProps(props){
      if(!this.state.isHeaderMenuLoaded && Object.keys(props.storeValues || {}) && props.storeValues.deliveryChargesInfo ){
        
        let chargesInfo =JSON.parse(props.storeValues.deliveryChargesInfo || '{}');
          // if(chargesInfo?.chargesInfo?.length === 0){
          //   const arr = [{
          //     id: 1,
          //     "starting":0,
          //     "ending":500,
          //     "amount":0,
          //   },
          //   {
          //      id:2 ,
          //     "starting":500,
          //     "ending":9999999,
          //     "amount":0,
          //   }]

          //   const val = this.state.deliveryValue;
          //               val.chargesInfo = arr;
          //   this.setState({deliveryValue: val, isHeaderMenuLoaded: true})
          // }
          // else{
            this.setState({deliveryValue: chargesInfo, isHeaderMenuLoaded: true})


          
       
      }
  
    }
  
    render() {
        return <>
            <DeliveryComp {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
        </>
    }

}


const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    userInfo: state.common.userInfo,
    relationshipId: state.common.companyInfo.relationshipId,
    storeValues: state.common.store_values,
    permissions: state.auth.permissions,

  };
};

  const mapDispatchToProps = (dispatch) => bindActionCreators({

     pushModalToStack,
     popModalFromStack,
     postDeliveryCharges,
    fetchE_StoreData,
    showModal,
    hideModal
  }, dispatch);
export default connect(mapStateToProps,mapDispatchToProps)(Delivery);