import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import LeaveComp from '../../../../components/company/LeaveMeta/Leave';
import LeaveDrawerCon from '../../../drawer/company/LeaveMeta/Leave';


class LeaveContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data:0
    }
    };
  

  componentDidMount(props) {
    const payload = {
      relationshipId: (this.props.companyInfo || {}).relationshipId,
     
    }
   
  }

  componentWillReceiveProps(props) {
   
  }

  render() {
      return <>

<LeaveComp {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
{this.state.leaveDrawer && <LeaveDrawerCon {...this.state} {...this.props} updateState={(data) => { this.setState(data) }} />}
      </>
    
    ;
  }

}

const mapStateToProps = (state) => {
  return {

    companyInfo: state.common.companyInfo,
    listLoading: state.common.listLoading,
    drawerLoading:state.common.drawerLoading,
    headerLoading:state.common.headerLoading,
    permissions: state.auth.permissions,
   
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(LeaveContainer);
