import React, { useMemo } from "react";
import { injectIntl, FormattedMessage } from "react-intl";
import { Empty, Checkbox, Dropdown, Menu, Skeleton, Switch, Pagination } from "antd";
import { ICONS, MODULE_CODED_VALUES } from "../../../static/constants";
import find from "lodash.find";
import RatingPreview from "../../modal/modalBody/product/RatingPreview";
import { getAWSFileURL, getPermissionData, showToasterMessage } from "../../../utils";
import ReactHtmlParser from 'react-html-parser';


const UnPublishedListing = (props) => {
  const { pageNumber, companyInfo, selectedItems, publishedList, permissions } = props;
  const primaryPerm = (permissions && permissions.primary) || [];
  const permissionsData = useMemo(() => {
    return getPermissionData(primaryPerm, MODULE_CODED_VALUES.UOM);
  }, [])

  const publishProductReview = () => {

    if (props.selectedItems.length) {
      props.showModal({

        modalBody: <>
          Are you sure you want to Publish ?
        </>,

        handleSubmit: () => {
          props.updatePublishFeedback({
            relationshipId: props.companyInfo.relationshipId,
            isPublished: 1,
          }, props)
        },
      });
    }
    else {
      showToasterMessage({
        messageType: "error",
        description: props.intl.formatMessage({
          id: "common.record.notSelected.proceeding",
        }),
      });
    }

  }
  const actionMenu = (data) => {
    return (
      <Menu className="row-action-dropdown">

        <Menu.Item key="0" value="title">
          Actions
        </Menu.Item>

        <Menu.Divider />
        {
          permissionsData?.update ? <Menu.Item
            key="1"
            value=""
            onClick={() => {
              props.updateState({
                feedbackDrawer: true,
                data: data
                // feedbackData: data,
              })
            }}
          >
            <i className={ICONS.EDIT} />
            Edit
          </Menu.Item> : ""
        }

        {/* {
      permissionsData?.delete ?   <Menu.Item
      key="2"
      value=""

        >
          <i className={ICONS.UPLOAD} />
          Publish
        </Menu.Item>
        <Menu.Item
          key="3"
          value=""

        >
          <i class="fi fi-rr-folder-download"></i>
          Archieve
        </Menu.Item>

        {/* <Menu.Item
          key="1"
          value=""
          onClick={() =>
            props.showModal({
              title: (<div className="ant-modal-title">Product Preview</div>),
              modalBody: <RatingPreview {...props} product={data} />,
              width: 700,
              hideFooter: true,

            })
          }
        >
          <i class="fi fi-sr-preview"></i>
          Preview
        </Menu.Item> */}


      </Menu>
    );
  };

  const itemSelection = (item) => {
    const feedbackId = Number(item.feedbackId);
    const selectItem = selectedItems.find(id => id === feedbackId);
    let updatedItems = [];

    if (selectItem) {
      updatedItems = selectedItems.filter(id => id !== feedbackId);
    } else {
      updatedItems = [...selectedItems, feedbackId];
    }

    props.updateState({ selectedItems: updatedItems });
  };

  const selectAll = (context) => {
    if (!context) {
      selectedItems.splice(0, selectedItems.length);
      props.updateState({ selectedItems: selectedItems });
      return;
    }

    const feedbackIds = publishedList[pageNumber].map(item => Number(item.feedbackId));

    feedbackIds.forEach(feedbackId => {
      if (!selectedItems.includes(feedbackId)) {
        selectedItems.push(feedbackId);
      }
    });

    props.updateState({ selectedItems: selectedItems });
  };

  const isCheckedAll = () => {
    if (!publishedList || !publishedList[pageNumber] || publishedList[pageNumber].length === 0) {
      return false;
    }

    const feedbackIds = publishedList[pageNumber].map(item => Number(item.feedbackId));

    return feedbackIds.every(feedbackId => selectedItems.includes(feedbackId));
  };


  const showTotal = (total) => {
    return (
      props.intl.formatMessage({
        id: "pagination.text.total",
        defaultMessage: "",
      })
      +
      ` ${total} ` +
      props.intl.formatMessage({
        id: "pagination.text.items",
        defaultMessage: "",
      })
    );
  };
  const loadPageData = (pageNumber, pageSize) => {
    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      pageNumber: pageNumber,
      pageSize: pageSize,
    };
    props.fetchPublishAndUnpublishList({ ...payload, isPublished: 0 });
  };

  const handlePageSizeChange = (pageNumber, pageSize) => {

    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      pageNumber: pageNumber,
      pageSize: pageSize,
    };
    props.fetchPublishAndUnpublishList({ ...payload, isPublished: 0 });
    props.updateState({ pageSize: pageSize, pageNumber: pageNumber });
  };
  function removeTags(html) {
    return html.replace(/<[^>]+>/g, '');
  }
  const openLongtText = (title, Text) => {
    props.showModal({
      title: title,
      modalBody: <div style={{ maxHeight: '550px', overflow: 'auto' }}>{ReactHtmlParser(Text)}</div>,
      hideFooter: true,
      width: '70%'
    })
  }
  return (
    <>
      <div className="view-container-actions">
        <Skeleton loading={props.headerLoading} paragraph={false} rows={1} active>
          <div className="left-actions">
            <div className="table-heading">
              Unpublished List
            </div>

            <>
              <div className="vertical-sep" />
              <div>{props.publishCount || 0}</div>
            </>

          </div>
          <div className="right-actions">
            {
              permissionsData?.create ? <button className="upload-button" onClick={() => {
                publishProductReview()
              }}>

                <i className={ICONS.UPLOAD} />{" "}
                Publish</button> : ""
            }

          </div>
        </Skeleton>
      </div>
      <div className="table-container">
        <Skeleton loading={props.listLoading} paragraph={{ rows: 15 }} active>
          <table>
            <thead>
              <tr>
                <th width='6%'>
                  <div className="check-cell">
                    <Checkbox
                      onChange={(e) => {
                        selectAll(e.target.checked);
                      }}
                      checked={isCheckedAll()}
                    />

                  </div>
                </th>
                <th>SO #</th>
                <th>Product Name</th>
                <th>Rating</th>

                <th>Review</th>
                <th>Reviewed By</th>

                <th>Status</th>
                {
                  (permissionsData?.read || permissionsData?.update) ? <th>Action</th> : ""
                }


              </tr>
            </thead>
            <tbody>
              {(props.publishedList[pageNumber] || []).length
                ? props.publishedList[pageNumber].map((item, i) => {
                  const image = getAWSFileURL(item?.image, props.companyInfo.bucketName, item?.folderName)

                  return (
                    <tr>
                      <td>

                        <Checkbox
                          onClick={() => {
                            itemSelection(item);
                          }}
                          checked={selectedItems.includes(Number(item.feedbackId))}
                        />

                      </td>
                      <td>{item.soNumber}</td>
                      <td>{item.itemName}</td>
                      <td>

                        <span className="link" onClick={() =>
                          props.showModal({
                            title: (<div className="ant-modal-title">
                              <FormattedMessage id="product.rating.heading.productRatingPreview" />

                            </div>),
                            modalBody: <RatingPreview {...props} product={item} />,
                            width: 700,
                            hideFooter: true,

                          })
                        }>

                          {item.rating}
                        </span>
                      </td>

                      <td>

                        {
                          item.message ? (<div>
                            {
                              (removeTags(item.message) || '')?.length < 20 ?
                                (<pre

                                >{ReactHtmlParser(item.message)}</pre>) : (<div><pre

                                >{removeTags(item.message).slice(0, 20)}</pre><span className="more-details-btn" onClick={() => { openLongtText('Review', item.message) }}>Show More</span></div>)
                            }


                          </div>) : <div className="empty-data"></div>
                        }
                      </td>
                      <td>{item.customerName}</td>
                      <td>
                        <div className="status-toggle">

                          <Switch
                            checked={item.isPublished}
                            disabled={!(permissionsData?.update)}
                            onChange={(checked) => {
                              const payload = {
                                ...item,
                                relationshipId: props.companyInfo.relationshipId,
                                itemName: item.itemName,
                                rating: item.rating,
                                message: item.message,
                                customerName: item.customerName,
                                updatedByUserId: item.updatedByUserId,
                                feedbackId: item.feedbackId,

                              };
                              props.updateFeedback({ ...payload, isPublished: checked ? 1 : 0 }, image, props);

                            }}
                          />
                        </div>
                      </td>
                      {
                        (permissionsData?.read || permissionsData?.update) ? <td>
                          <Dropdown
                            overlay={actionMenu(item)}
                            trigger={["click"]}
                          >
                            <i className={ICONS.MORE} />
                          </Dropdown>
                        </td> : ""
                      }

                    </tr>

                  )
                }) :
                <tr key="empty-data-box">
                  <td colSpan={"100%"} style={{ borderBottom: "none" }}>
                    <Empty />
                  </td>
                </tr>
              }
            </tbody>
          </table>
        </Skeleton>
      </div>

      <div className="footer">
        <div className="f-left"></div>
        <div className="f-right">
          <Pagination
            current={props.pageNumber || 1}
            size="small"
            total={props.publishCount}
            showTotal={showTotal}
            defaultPageSize={props.pageSize || 100}
            showSizeChanger
            pageSizeOptions={[10, 25, 50, 100, 200]}
            onChange={(pageNumber, pageSize) => {
              loadPageData(pageNumber, pageSize);
              props.updateState({ pageSize: pageSize, pageNumber: pageNumber });
            }}
            onShowSizeChange={(pageNumber, pageSize) => {
              handlePageSizeChange(pageNumber || 1, pageSize, props);
            }}
          />
        </div>
      </div>
    </>
  )
}
export default injectIntl(UnPublishedListing);