import React from "react";
import { injectIntl } from "react-intl";
import "./../../../../assets/stylesheets/common/invoice.scss";
import ShipmentDetail from "./ShipmentDetail";
import ProductDetail from "./ProductDetail";
import HeaderAction from "./HeaderAction";
import { Divider } from "antd";

const ShipmentComponent = (props) => {
  console.log("dfjvbdfjvbdfjkvdfv",props);
  
  return (
    <>
      <div className="txn-header">
        <HeaderAction {...props} />
      </div>
      <div className="txn-body">
        <div className="txn-details-container">
          <ShipmentDetail {...props} />
        </div>

        <Divider style={{ margin: "0 0 10px 0px" }} />
        <div className="product-details-container">
          <ProductDetail {...props} />
        </div>
      </div>
    </>
  );
};

export default injectIntl(ShipmentComponent);
