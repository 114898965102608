import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {  showModal, hideModal, pushModalToStack, popModalFromStack } from '../../../../actions/commonActions';
import { fetchCategory } from "../../../drawer/inventory/action"; 
import { updateOrCreateEcomSettings } from "../../menus/action";  
import CustomizeThemes from "../../../../components/eStore/themes/customizeThemes";
import { fetchE_StoreData } from "../../../../actions/commonActions";
import { updateoraddThemeHeaderLogo,updateoraddThemeStoreFavicon,updateoraddThemeHeaderLogo1,uploadPopUpIcon } from "../action";
import { updateHomePageSettings } from "../../menus/action"; 
class CustomizeThemesComp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageNumber: 1,
      pageSize: 25,
      isHeaderMenuLoaded: false,
      sections:[
        {"label":"On Sale","sectionId":1,"isVisible":0,"type":"Sections"}
      ,{"label":"Clearance","sectionId":2,"isVisible":0,"type":"Sections"}
      ,{"label":"New Arrival's","sectionId":3,"isVisible":0,"type":"Sections"}
      ,{"label":"Best Selling Item","sectionId":4,"isVisible":0,"type":"Sections"}
      ,{"label":"Deal of the Day","sectionId":5,"isVisible":0,"type":"Sections"}
    ],
    };
  }
  componentDidMount() {
    const companyInfo = this.props.companyInfo || {};
    const payload = {
      relationshipId: companyInfo.relationshipId,
      pageNumber: 1,
      pageSize: 100
    };
    this.props.fetchCategory(payload);
    this.props.fetchE_StoreData({relationshipId: companyInfo.relationshipId})
  }
  componentWillReceiveProps(props){
    if(!this.state.isHeaderMenuLoaded && Object.keys(props.storeData || {})?.length){
      
     
      let sectiondata = JSON.parse(props.storeData?.homePageSetting || "[]");
      
     
        
      this.setState({
       
       
          sections:sectiondata?.length ? sectiondata : this.state.sections,
           isHeaderMenuLoaded: true ,
          
        })
    }

  }
  render() {
    return (
      <CustomizeThemes
        {...this.props}
        {...this.state}
        updateState={(data) => {
          this.setState(data);
        }}
      ></CustomizeThemes>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    userInfo: state.common.userInfo,
    relationshipId: state.common.companyInfo.relationshipId,
    schoolList: state.school.schoolList,
    schoolCount: state.school.schoolCount,
    productCategories: state.inventory.productCategories,
    storeData:state.common.store_values,
    permissions: state.auth.permissions,

    storeValues:state.common.store_values
  };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
  showModal,
  fetchCategory,
  hideModal,
  pushModalToStack,
  updateoraddThemeStoreFavicon,
  updateoraddThemeHeaderLogo,
  updateoraddThemeHeaderLogo1,
  popModalFromStack,
  updateOrCreateEcomSettings, uploadPopUpIcon,
  fetchE_StoreData,updateHomePageSettings
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CustomizeThemesComp);
