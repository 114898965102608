import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import AssignmentComp from "../../../components/schoolManagement/assignment"
import { fetchDataIfNeeded, fetchPaginationDataIfNeeded } from "../../../utils";
import { getSchoolAssignment, getSchoolAssignmentCount, resetPaginatedData, deleteAssignment, deleteAssignmentDoc } from "./action";
import { showModal, hideModal, pushModalToStack, popModalFromStack } from '../../../actions/commonActions';
import { downloadFileFromBucket } from '../../../actions/downloadFileAction';
import { getSubdomain } from '../../modal/modalBody/settings/CompanyInfo/action';
import { sendResetMail } from '../../Forgot/action';
import { getClassList } from './../../schoolManagement/class/action';
import EmailComponent from '../../../containers/drawer/recurringInvoiceEmail';
import { getAllStudentForm } from './../../Student/studentFormList/action';
import * as find from 'lodash.find';

class Assignment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageNumber: 1,
      pageSize: 200,
      selectedClass: {className:'All Classes', classId: 0}
    };
  }

  componentDidMount() {
    const companyInfo = this.props.companyInfo || {};
    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      relationshipEmployeeId: (this.props.userInfo || {}).relationshipEmployeeId,
      pageNumber: 1,
      pageSize: 200,
      formId: 0
    };
    fetchPaginationDataIfNeeded("getSchoolAssignment", "assignmentList", this.props, payload);
    fetchDataIfNeeded("getSchoolAssignmentCount", "assignmentCount", this.props, payload);
    this.props.getClassList(payload);
    fetchPaginationDataIfNeeded("getAllStudentForm", "studentFormList", this.props, payload);
    // this.props.getSubdomain(payload, function (stat) { self.setState({ isSubDomainLoaded: stat }) });
  }
  componentWillReceiveProps(props) {
    // if (props.subdomain && props.subdomain.alpideSubdomainId && !this.state.subdomainName) {
    //   this.setState({
    //     subdomainName: props.subdomain.subdomainName ? 'https://' + props.subdomain.subdomainName : window.location.origin
    //   });
    // }
    if (props.defaultSchoolForm && props.studentFormList && props.studentFormList.length && !this.state.selectedForm) {
      let selectedForm = find(props.studentFormList, { registrationFormSettingId: Number(props.defaultSchoolForm) });
      this.setState({
        selectedForm
      });
    }

  }


  render() {
    return (
      <div>
      {this.state.emailDetailDrawerVisible && <EmailComponent {...this.state} {...this.props} updateState={(data) => { this.setState(data) }} />}
      <AssignmentComp
        {...this.props}
        {...this.state}
        updateState={(data) => {
          this.setState(data);
        }}
      ></AssignmentComp>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    userInfo: state.common.userInfo,
    relationshipId: state.common.companyInfo.relationshipId,
    assignmentList: state.school.assignmentList,
    assignmentCount: state.school.assignmentCount,
    subdomain: state.common.subdomain,
    classList: state.school.classList,
    defaultSchoolForm: state.common.defaultSchoolForm,
    studentFormList: state.membershipReducer.studentFormList,
    listLoading: state.common.listLoading,
    headerLoading: state.common.headerLoading,
    permissions: state.auth.permissions,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getSchoolAssignment, getSchoolAssignmentCount,
      resetPaginatedData,
      showModal,
      hideModal,
      pushModalToStack,
      popModalFromStack,
      getSubdomain,
      sendResetMail,
      deleteAssignment,
      getClassList,
      getAllStudentForm,
      downloadFileFromBucket,
      deleteAssignmentDoc
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Assignment);
