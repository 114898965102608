import React from "react";
import LeadNotesListComp from "../../../components/leads/LeadsNotes";
import LeadSourceDrawer from "../../drawer/leads/leadSourceList";
import { getAllLeadSources } from "../../modal/modalBody/leads/createLead/action";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createLeadSource } from "../../drawer/leads/leadSourceList/action";
import { deleteCrmLeadSource } from "./action";
import { showModal, hideModal } from "../../../actions/commonActions";
import { getAllLeadNotes ,getAllLeadNotesByUserId,resetAllLeadNotes} from "../AllLeads/action";
import { checkACLPermission } from "../../../utils";
import { MODULE_CODED_VALUES, PERMISSION_VALUES } from "../../../static/constants";
class LeadNoteList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      pageNumber: 0,
      pageSize: 100,
      isLoaded:false,
    };
  }

  componentDidMount() {
    this.props.resetAllLeadNotes();
    const companyInfo = this.props.companyInfo || {};

    const payload = {
      relationshipId: companyInfo.relationshipId,
      pageNumber: 0,
      pageSize: 100,
    }
    const { permissions } = this.props;
    const primaryPerm = (permissions && permissions.primary) || [];
    // this.props.getAllLeadSources(payload);
    checkACLPermission(
      primaryPerm,
      MODULE_CODED_VALUES.CRM_LEAD_NOTES,
      PERMISSION_VALUES.CREATE
    )  && this.props.getAllLeadNotes(payload);
  }

  componentWillReceiveProps(props){
    const { permissions } = props;
    const primaryPerm = (permissions && permissions.primary) || [];
    const permissionsData = {
      allTask:checkACLPermission(
        primaryPerm,
        MODULE_CODED_VALUES.CRM_LEAD_NOTES,
        PERMISSION_VALUES.CREATE
      )
      // cn add more like this and handel all the tab
    }

    if(!this.state.isLoaded && !permissionsData.allTask){
      const payload = {
        relationshipId: props.companyInfo.relationshipId,
        pageNumber: 0,
        createdByEmpId: props.companyInfo?.relationshipEmployees?.relationshipEmployeeId,
        pageSize: 25,
      };
      props.getAllLeadNotesByUserId(payload);
      this.setState({
        isMyLeads: true,
        isLoaded:true

      })
    }
  }

  render() {
    return (
      <>
        <LeadNotesListComp
          {...this.props}
          {...this.state}
          updateState={(data) => {
            this.setState(data);
          }}
        />

       {/* {this.state.leadSourceListDrawer && <LeadSourceDrawer {...this.state} {...this.props} updateState={(data) => { this.setState(data) }} />} */}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
      companyInfo: state.common.companyInfo,
      userInfo: state.common.userInfo,
      crmLeadSourceList: state.crm.crmLeadSourceList,
      allLeadNotesList: state.crm.crmLeadNotesList,
      permissions: state.auth.permissions,
      listLoading:state.common.listLoading


  }
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
  getAllLeadSources,
  createLeadSource,
  deleteCrmLeadSource,
  showModal,
  hideModal,getAllLeadNotes,getAllLeadNotesByUserId,resetAllLeadNotes
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(LeadNoteList);
