import React, { Fragment, useMemo } from "react";
import { injectIntl, FormattedMessage } from "react-intl";
import './dashboard.scss';
import { Col, Row, Button, Menu, Spin, Skeleton, } from "antd";
import moment from "moment"
import MovingProduct from "./MovingProduct";
import TopCustomerPayment from "./TopCustomerPayment";
import TopSupplierPayment from "./TopSupplierPayment";
import SalesStatisticChart from "./salesStatisticChart";
import PurchaseChart from "./purchaseChart";
import {
  CONSTANTS,
  DASHBOARD_PAGE_CONSTANTS,
  MODULE_CODED_VALUES,
  PERMISSION_VALUES,
} from "../../static/constants";
import { Loading3QuartersOutlined } from "@ant-design/icons";
import UnpaidInvoices from "../../containers/modal/modalBody/customer/UnpaidInvoices";
import SupplierUnpaidInvoices from "../../containers/modal/modalBody/supplier/UnpaidInvoices";
import CashFlowChart from "./CashflowChart";
import {
  getMomentDateForUIReadOnly,
  fixedDecimalAndFormateNumber,
  checkACLPermission,getGreetingFromTime, capitalizeFirstLetter,
  getCurrencySymbol,
  getPermissionData

} from "../../utils";
import img1 from "../../assets/images/User (1).jpg";
import img2 from "../../assets/images/User (2).jpg";
import img3 from "../../assets/images/User (3).jpg";
import img4 from "../../assets/images/User (4).jpg";
import img5 from "../../assets/images/User (5).jpg";
import img6 from "../../assets/images/User(6).png";
import SchoolDashboard from "../../containers/schoolManagement/dashboard";
import CrmDashBoard from "../../containers/CRMDashboard";
import { Dropdown } from '../general/Dropdown';

class DashboardComponent extends React.Component {

    constructor(props){
      super(props);
    }
  getTotalCustomerDue = () => {
    console.log(this.props.customerUnpaidInvoices[1], "CustomerInvoicesss");
  
    let total = 0;
    if (
      this.props.customerUnpaidInvoices &&
      Array.isArray(this.props.customerUnpaidInvoices[1])
    ) {
      this.props.customerUnpaidInvoices[1].forEach(function (d) {
        // Ensure d.invoiceDueAmount is a number, use 0 as default if not
        total += (Number(d.invoiceTotalAmount||0) -Number(d.totalPaymentReceived||0));
      });
    }
    console.log(total)
    return total
      .toFixed(2)
      
  };

  getAmountByLedgerGroup = (groupName, category, type, toReduceType) => {
    let randomTotalAmount = 0;

    for (var i = 0; i < this.props.allLedgerAccounts.length; i++) {
      if (
        this.props.allLedgerAccounts[i].categoryGroupName === groupName &&
        this.props.allLedgerAccounts[i].categoryName === category &&
        ((this.props.allLedgerAccounts[i].nature &&
          (this.props.allLedgerAccounts[i].nature||"").toUpperCase()) ||
          (this.props.allLedgerAccounts[i].accountingEntry||"").toUpperCase()) !==
          toReduceType
      ) {
        randomTotalAmount =
          randomTotalAmount + this.props.allLedgerAccounts[i].amountDifference;
      } else if (
        this.props.allLedgerAccounts[i].categoryGroupName === groupName &&
        this.props.allLedgerAccounts[i].categoryName === category &&
        ((this.props.allLedgerAccounts[i].nature &&
          (this.props.allLedgerAccounts[i].nature||"").toUpperCase()) ||
          (this.props.allLedgerAccounts[i].accountingEntry||"").toUpperCase()) ===
          toReduceType
      ) {
        randomTotalAmount =
          randomTotalAmount - this.props.allLedgerAccounts[i].amountDifference;
      }
    }

    return parseFloat((randomTotalAmount || 0).toFixed(2));
  };

  getTotalSupplierDue = () => {
    let total = 0;
    if (
      this.props.supplierUnpaidInvoices &&
      this.props.supplierUnpaidInvoices[1]
    ) {
      this.props.supplierUnpaidInvoices[1].forEach(function (d) {
       total +=  (Number(d.invoiceTotalAmount||0) -Number(d.totalPaymentReceived||0));
      });
    }
    return total
      .toFixed(2)
      
  };

  getTotal = (type) => {
    let totalAmount = 0;
    if (!this.props.allLedgerAccounts && !this.props.allLedgerAccounts.length) {
      return;
    }
    let randomTotalAmount = 0;
    switch (type) {
      case "Equity and Liabilities": {
        for (let i = 0; i < this.props.allLedgerAccounts.length; i++) {
          if (
            (this.props.allLedgerAccounts[i].categoryName ===
              "Shareholder's Funds" ||
              this.props.allLedgerAccounts[i].categoryName ===
                "Non Current Liabilities" ||
              this.props.allLedgerAccounts[i].categoryName ===
                "Current Liabilities") &&
            ((this.props.allLedgerAccounts[i].nature &&
              (this.props.allLedgerAccounts[i].nature||"").toUpperCase()) ||
              (this.props.allLedgerAccounts[i].accountingEntry||"").toUpperCase()) ===
              "CREDIT"
          ) {
            totalAmount =
              totalAmount + this.props.allLedgerAccounts[i].amountDifference;
            randomTotalAmount = totalAmount.toFixed(2);
          } else if (
            (this.props.allLedgerAccounts[i].categoryName ===
              "Shareholder's Funds" ||
              this.props.allLedgerAccounts[i].categoryName ===
                "Non Current Liabilities" ||
              this.props.allLedgerAccounts[i].categoryName ===
                "Current Liabilities") &&
            ((this.props.allLedgerAccounts[i].nature &&
              (this.props.allLedgerAccounts[i].nature||"").toUpperCase()) ||
              (this.props.allLedgerAccounts[i].accountingEntry||"").toUpperCase()) ===
              "DEBIT"
          ) {
            totalAmount =
              totalAmount - this.props.allLedgerAccounts[i].amountDifference;
            randomTotalAmount = totalAmount.toFixed(2);
          }
        }
        break;
      }
      case "Assets": {
        for (let i = 0; i < this.props.allLedgerAccounts.length; i++) {
          if (
            (this.props.allLedgerAccounts[i].categoryName ===
              "Non - Current Assets" ||
              this.props.allLedgerAccounts[i].categoryName ===
                "Current Assets") &&
            ((this.props.allLedgerAccounts[i].nature &&
              (this.props.allLedgerAccounts[i].nature||"").toUpperCase()) ||
              (this.props.allLedgerAccounts[i].accountingEntry||"").toUpperCase()) ===
              "DEBIT"
          ) {
            totalAmount =
              totalAmount + this.props.allLedgerAccounts[i].amountDifference;
            randomTotalAmount = totalAmount.toFixed(2);
          } else if (
            (this.props.allLedgerAccounts[i].categoryName ===
              "Non - Current Assets" ||
              this.props.allLedgerAccounts[i].categoryName ===
                "Current Assets") &&
            ((this.props.allLedgerAccounts[i].nature &&
              (this.props.allLedgerAccounts[i].nature||"").toUpperCase()) ||
              (this.props.allLedgerAccounts[i].accountingEntry||"").toUpperCase()) ===
              "CREDIT"
          ) {
            totalAmount =
              totalAmount - this.props.allLedgerAccounts[i].amountDifference;
            randomTotalAmount = totalAmount.toFixed(2);
          }
        }
        break;
      }
      case "Expenses": {
        for (let i = 0; i < this.props.allLedgerAccounts.length; i++) {
          if (
            (this.props.allLedgerAccounts[i].categoryName ===
              "Cost of Materials" ||
              this.props.allLedgerAccounts[i].categoryName ===
                "Employee Benefit Expense" ||
              this.props.allLedgerAccounts[i].categoryName ===
                "Other Expenses" ||
              this.props.allLedgerAccounts[i].categoryName === "Taxes" ||
              this.props.allLedgerAccounts[i].categoryName ===
                "Finance Cost") &&
            ((this.props.allLedgerAccounts[i].nature &&
              (this.props.allLedgerAccounts[i].nature||"").toUpperCase()) ||
              (this.props.allLedgerAccounts[i].accountingEntry||"").toUpperCase()) ===
              "DEBIT"
          ) {
            totalAmount =
              totalAmount + this.props.allLedgerAccounts[i].amountDifference;
            randomTotalAmount = totalAmount.toFixed(2);
          } else if (
            (this.props.allLedgerAccounts[i].categoryName ===
              "Cost of Materials" ||
              this.props.allLedgerAccounts[i].categoryName ===
                "Employee Benefit Expense" ||
              this.props.allLedgerAccounts[i].categoryName ===
                "Other Expenses" ||
              this.props.allLedgerAccounts[i].categoryName === "Taxes" ||
              this.props.allLedgerAccounts[i].categoryName ===
                "Finance Cost") &&
            ((this.props.allLedgerAccounts[i].nature &&
              (this.props.allLedgerAccounts[i].nature||"").toUpperCase()) ||
              (this.props.allLedgerAccounts[i].accountingEntry||"").toUpperCase()) ===
              "CREDIT"
          ) {
            totalAmount =
              totalAmount - this.props.allLedgerAccounts[i].amountDifference;
            randomTotalAmount = totalAmount.toFixed(2);
          }
        }
        break;
      }
      case "Income": {
        for (let i = 0; i < this.props.allLedgerAccounts.length; i++) {
          if (
            (this.props.allLedgerAccounts[i].categoryName ===
              "Revenue from Operations" ||
              this.props.allLedgerAccounts[i].categoryName ===
                "Other Income") &&
            ((this.props.allLedgerAccounts[i].nature &&
              (this.props.allLedgerAccounts[i].nature||"").toUpperCase()) ||
              (this.props.allLedgerAccounts[i].accountingEntry||"").toUpperCase()) ===
              "CREDIT"
          ) {
            totalAmount =
              totalAmount + this.props.allLedgerAccounts[i].amountDifference;
            randomTotalAmount = totalAmount.toFixed(2);
          } else if (
            (this.props.allLedgerAccounts[i].categoryName ===
              "Revenue from Operations" ||
              this.props.allLedgerAccounts[i].categoryName ===
                "Other Income") &&
            ((this.props.allLedgerAccounts[i].nature &&
              (this.props.allLedgerAccounts[i].nature||"").toUpperCase()) ||
              (this.props.allLedgerAccounts[i].accountingEntry||"").toUpperCase()) ===
              "DEBIT"
          ) {
            totalAmount =
              totalAmount - this.props.allLedgerAccounts[i].amountDifference;
            randomTotalAmount = totalAmount.toFixed(2);
          }
        }
        break;
      }
      default:
        break;
    }

    return randomTotalAmount;
  };

  openModalOfSelectedTile = (data) => {
    switch (data.type) {
      case DASHBOARD_PAGE_CONSTANTS.CUSTOMER_DUE:
        this.props.showModal({
          title: <div className="ant-modal-title">Customer Due</div>,
          modalBody: <UnpaidInvoices {...this.props} />,
          width: 1000,
          hideFooter: true,
          wrapClassName: "tiles-modal",
        });
        break;
      case DASHBOARD_PAGE_CONSTANTS.SUPPLIER_DUE:
        this.props.showModal({
          title: <div className="ant-modal-title">Supplier Due</div>,
          modalBody: <SupplierUnpaidInvoices {...this.props} />,
          width: 1000,
          hideFooter: true,
          wrapClassName: "tiles-modal",
        });
        break;
      case DASHBOARD_PAGE_CONSTANTS.BALANCE_SHEET: {
        this.props.history.push({
          pathname: "/admin/balance-sheet",
        });
        break;
      }
      case DASHBOARD_PAGE_CONSTANTS.PROFIT_AND_LOSS: {
        this.props.history.push({
          pathname: "/admin/profit-loss",
        });
        break;
      }
      default:
        break;
    }
  };

  getCashFlow = (data) => {
    let total = 0;
    for(const num of Object.keys(data || {})){
      total = total + Number(num)
    };
    return fixedDecimalAndFormateNumber(total);
  };

  render() {

    
    const primaryPerm = (this.props.permissions || {}).primary || [];
    const customerDuePermission = getPermissionData(primaryPerm ,  MODULE_CODED_VALUES.CUSTOMERS)

    const supplierDuePermission =  getPermissionData(primaryPerm ,  MODULE_CODED_VALUES.SUPPLIERS)

    const expensePermission = getPermissionData(primaryPerm ,  MODULE_CODED_VALUES.BUSINESS_EXPENSE)
    const salesPermission  = getPermissionData(primaryPerm ,  MODULE_CODED_VALUES.SALES_INVOICE)
    const purchasePermission = getPermissionData(primaryPerm ,  MODULE_CODED_VALUES.PURCHASE_INVOICE)
    const profitPermission = getPermissionData(primaryPerm ,  MODULE_CODED_VALUES.PROFIT_LOSS)

    const productPermission = getPermissionData(primaryPerm ,  MODULE_CODED_VALUES.PRODUCTS)
    const balancesheetPermission =  getPermissionData(primaryPerm ,  MODULE_CODED_VALUES.BALANCE_SHEET)
    const menuForSales = (
      <Menu>
        <Menu.Item
          key="0"
          onClick={() => {
            this.props.updateState({
              salesSelectedDate:
                moment(new Date()).format(CONSTANTS.DISPLAY_DATE_FORMAT_FY) +
                "-" +
                moment(new Date()).format(CONSTANTS.DISPLAY_DATE_FORMAT_FY),
            });
            this.props.fetchSalesData({
              relationshipId: (this.props.companyInfo || {}).relationshipId,
              selectedRange: "today",
            });
          }}
        >
          Today
        </Menu.Item>
        <Menu.Item
          key="1"
          onClick={() => {
            this.props.updateState({
              salesSelectedDate:
                moment()
                  .subtract("days", 1)
                  .format(CONSTANTS.DISPLAY_DATE_FORMAT_FY) +
                "-" +
                moment()
                  .subtract("days", 1)
                  .format(CONSTANTS.DISPLAY_DATE_FORMAT_FY),
            });
            this.props.fetchSalesData({
              relationshipId: (this.props.companyInfo || {}).relationshipId,
              selectedRange: "yesterday",
            });
          }}
        >
          Yesterday
        </Menu.Item>
        <Menu.Item
          key="2"
          onClick={() => {
            this.props.updateState({
              salesSelectedDate:
                moment()
                  .startOf("month")
                  .format(CONSTANTS.DISPLAY_DATE_FORMAT_FY) +
                "-" +
                moment()
                  .endOf("month")
                  .format(CONSTANTS.DISPLAY_DATE_FORMAT_FY),
            });
            this.props.fetchSalesData({
              relationshipId: (this.props.companyInfo || {}).relationshipId,
              selectedRange: "thisMonth",
            });
          }}
        >
          This Month
        </Menu.Item>
        <Menu.Item
          key="3"
          onClick={() => {
            this.props.updateState({
              salesSelectedDate:
                moment()
                  .subtract("month", 1)
                  .startOf("month")
                  .format(CONSTANTS.DISPLAY_DATE_FORMAT_FY) +
                "-" +
                moment()
                  .subtract("month", 1)
                  .endOf("month")
                  .format(CONSTANTS.DISPLAY_DATE_FORMAT_FY),
            });
            this.props.fetchSalesData({
              relationshipId: (this.props.companyInfo || {}).relationshipId,
              selectedRange: "lastMonth",
            });
          }}
        >
          Last Month
        </Menu.Item>
      </Menu>
    );

    const menuForPurchase = (
      <Menu>
        <Menu.Item
          key="0"
          onClick={() => {
            this.props.updateState({
              purchaseSelectedDate:
                moment(new Date()).format(CONSTANTS.DISPLAY_DATE_FORMAT_FY) +
                "-" +
                moment(new Date()).format(CONSTANTS.DISPLAY_DATE_FORMAT_FY),
            });
            this.props.fetchPurchaseData({
              relationshipId: (this.props.companyInfo || {}).relationshipId,
              selectedRange: "today",
            });
          }}
        >
          Today
        </Menu.Item>
        <Menu.Item
          key="1"
          onClick={() => {
            this.props.updateState({
              purchaseSelectedDate:
                moment()
                  .subtract("days", 1)
                  .format(CONSTANTS.DISPLAY_DATE_FORMAT_FY) +
                "-" +
                moment()
                  .subtract("days", 1)
                  .format(CONSTANTS.DISPLAY_DATE_FORMAT_FY),
            });
            this.props.fetchPurchaseData({
              relationshipId: (this.props.companyInfo || {}).relationshipId,
              selectedRange: "yesterday",
            });
          }}
        >
          Yesterday
        </Menu.Item>
        <Menu.Item
          key="2"
          onClick={() => {
            this.props.updateState({
              purchaseSelectedDate:
                moment()
                  .startOf("month")
                  .format(CONSTANTS.DISPLAY_DATE_FORMAT_FY) +
                "-" +
                moment()
                  .endOf("month")
                  .format(CONSTANTS.DISPLAY_DATE_FORMAT_FY),
            });
            this.props.fetchPurchaseData({
              relationshipId: (this.props.companyInfo || {}).relationshipId,
              selectedRange: "thisMonth",
            });
          }}
        >
          This Month
        </Menu.Item>
        <Menu.Item
          key="3"
          onClick={() => {
            this.props.updateState({
              purchaseSelectedDate:
                moment()
                  .subtract("month", 1)
                  .startOf("month")
                  .format(CONSTANTS.DISPLAY_DATE_FORMAT_FY) +
                "-" +
                moment()
                  .subtract("month", 1)
                  .endOf("month")
                  .format(CONSTANTS.DISPLAY_DATE_FORMAT_FY),
            });
            this.props.fetchPurchaseData({
              relationshipId: (this.props.companyInfo || {}).relationshipId,
              selectedRange: "lastMonth",
            });
          }}
        >
          Last Month
        </Menu.Item>
      </Menu>
    );

    // const antIcon = <Loading3QuartersOutlined className="spinnerIcon" spin />;

    const {
      common: { isLoading },
    } = this.props;

    const tilesData = [
      {
        footer: <FormattedMessage id="tiles.customerDue" defaultMessage="" />,
        count: fixedDecimalAndFormateNumber(this.getTotalCustomerDue()),
        type: DASHBOARD_PAGE_CONSTANTS.CUSTOMER_DUE,
        antIcon: <img src={img6} alt="" />,
        currencyIcon: (this.props.companyInfo || {}).currencyCode,
        color: "tile1",
        permission: customerDuePermission.read,
      },
      {
        footer: <FormattedMessage id="tiles.supplierDue" defaultMessage=" " />,
        count: fixedDecimalAndFormateNumber(this.getTotalSupplierDue()),
        antIcon: <img src={img1} alt="" />,
        currencyIcon: (this.props.companyInfo || {}).currencyCode,
        type: DASHBOARD_PAGE_CONSTANTS.SUPPLIER_DUE,
        color: "tile2",
        permission: supplierDuePermission.read,
      },

      {
        footer: "PROFIT",
        count: fixedDecimalAndFormateNumber((
          this.getAmountByLedgerGroup(
            "Income/Gain",
            "Revenue from Operations",
            "amount",
            "DEBIT"
          ) +
            this.getAmountByLedgerGroup(
              "Income/Gain",
              "Other Income",
              "amount",
              "DEBIT"
            ) || 0
        ).toFixed(2)),
        antIcon: <img src={img2} alt="" />,
        currencyIcon: (this.props.companyInfo || {}).currencyCode,
        type: DASHBOARD_PAGE_CONSTANTS.PROFIT_AND_LOSS,
        color: "tile3",
        permission: profitPermission.read,
      },
      {
        footer: "EXPENSE",
        count: fixedDecimalAndFormateNumber((
          this.getAmountByLedgerGroup(
            "Expenses/Loss",
            "Cost of Materials",
            "amount",
            "CREDIT"
          ) +
            this.getAmountByLedgerGroup(
              "Expenses/Loss",
              "Purchases",
              "amount",
              "CREDIT"
            ) +
            this.getAmountByLedgerGroup(
              "Expenses/Loss",
              "Employee Benefit Expense",
              "amount",
              "CREDIT"
            ) +
            this.getAmountByLedgerGroup(
              "Expenses/Loss",
              "Finance Cost",
              "amount",
              "CREDIT"
            ) +
            this.getAmountByLedgerGroup(
              "Expenses/Loss",
              "Depreciation & Amortisation",
              "amount",
              "CREDIT"
            ) +
            this.getAmountByLedgerGroup(
              "Expenses/Loss",
              "Other Expenses",
              "amount",
              "CREDIT"
            ) +
            this.getAmountByLedgerGroup("Expenses/Loss", "Taxes", "amount") || 0
        ).toFixed(2)),
        antIcon: <img src={img3} alt="" />,
        currencyIcon: (this.props.companyInfo || {}).currencyCode,
        type: DASHBOARD_PAGE_CONSTANTS.PROFIT_AND_LOSS,
        color: "tile4",
        permission: expensePermission.read,
      },
      {
        footer: <FormattedMessage id="tile.balanceSheet" defaultMessage=" " />,
        count: fixedDecimalAndFormateNumber(this.getTotal("Assets")),
        // leble: 'Assets',
        // count2: this.getTotal('Equity and Liabilities'),
        // leble2: 'Liabilities',
        antIcon: <img src={img4} alt="" />,
        currencyIcon: (this.props.companyInfo || {}).currencyCode,
        type: DASHBOARD_PAGE_CONSTANTS.BALANCE_SHEET,
        color: "tile5",
        permission: balancesheetPermission.read,
      },
      {
        footer: "LIABILITIES",
        count: fixedDecimalAndFormateNumber(this.getTotal("Equity and Liabilities")),
        // leble: 'Liabilities',
        antIcon: <img src={img5} alt="" />,
        currencyIcon: (this.props.companyInfo || {}).currencyCode,
        type: DASHBOARD_PAGE_CONSTANTS.BALANCE_SHEET,
        color: "tile6",
        permission: balancesheetPermission.read,
      },
    ];

   

    return (
      <div className="dash_container">
        <>
          {/* <div style={{ display: "flex", justifyContent: "space-between", width: "98%", alignItems: "center" }}> */}

            {/* <div className="dashboard-welcome-heading">{`${getGreetingFromTime(new Date())} ${capitalizeFirstLetter(this.props.companyInfo.relationshipEmployees?.firstName || '')}`}

            </div> */}
            {/* <div>

              <Dropdown
                style={{ width: "230px" }}
                items={["CRM", "ERP", "Education"]}
                value={this.props.selectedDasboard || "ERP"}
                onChange={(val) => {
                  this.props.updateState({
                    selectedDasboard: val
                  })
                }}
              />
              

              </div> */}

        {/* </div> */}
           
            {/* {
              this.props.selectedDasboard === "ERP"?  */}
            <div>
              <div className={`tiles-wrapper-dashboard`}>
                {tilesData?.length > 0 && tilesData
                  .map((option, i) => {
                    const {
                      footer,
                      count,
                      icon,
                      currencyIcon,
                      color = "default",
                      leble,
                      antIcon,
                      permission
                    } = option;
                    if(permission){
                      return (
                        <div
                          key={i}
                          className={`tile-container-dashboard ${color}`}
                          onClick={() => this.openModalOfSelectedTile(option)}
                        >
                          <div className="tile-body-dashboard">
                            <div className="left-tile-body-dashboard">
                              <div className="tile-name-dashboard">
                                {footer}
                              </div>
                            <Skeleton loading={this.props.listLoading} paragraph={false}  active className="custom-Skeleton">
                              <div style={{ fontSize: "16px" }}>
                                {leble && <span>{leble} </span>}
                                {currencyIcon ? getCurrencySymbol(currencyIcon) : currencyIcon + " "}
                                <span
                                  style={{
                                    fontSize: "16px",
                                    fontWeight: "700",
                                  }}
                                >
                                  {count}
                                </span>
                              </div>
                              </Skeleton>
                            </div>
                            
  
                            <div className="count-value-dashboard">
                              
                                <div className="ant-icons-dashboard">
                                  {antIcon ? antIcon : ""}
                                </div>
                              
                              <div className="ant-icons-dashboard">
                                {icon ? icon : ""}
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    }
                   else{
                    return ""
                   }
                  })}
               
              </div>
              <div className="view-dashboard">
                
                <Row gutter={[10,10]}>
                  {
                  salesPermission.read ?  <Col span={12}>
                  <div className="inner-view-dashboard">

                <div className="row-data-dashboard">
                      <div className="inner-heading-title">
                        Sales Statistic
                      </div>
                      <div style={{paddingTop:'10px'}}>
                        
                      </div>
                      <div style={{paddingTop:'10px'}}>

                      <Dropdown
                          overlay={menuForSales}
                          trigger={["click"]}
                        >
                          <Button
                            className="dropdown-button-calender"
                            onClick={(e) => e.preventDefault()}
                          >
                            <i className="fa fa-calendar" /> &nbsp;{" "}
                            {this.props.salesSelectedDate}
                          </Button>
                        </Dropdown>
                      </div>
                    </div>
                    <SalesStatisticChart {...this.props} />
                  </div>
                </Col>:""
                  }
                 {
                  purchasePermission.read ?  <Col span={12}>

                  <div className="inner-view-dashboard">

                <div className="row-data-dashboard">
                      <div className="inner-heading-title">
                        Purchase
                      </div>
                      <div style={{paddingTop:'10px'}}>

                      <Dropdown
                        overlay={menuForPurchase}
                        trigger={["click"]}
                      >
                        <Button
                          className="dropdown-button-calender"
                          onClick={(e) => e.preventDefault()}
                        >
                          <i className="fa fa-calendar" /> &nbsp;{" "}
                          {this.props.salesSelectedDate}
                        </Button>
                      </Dropdown>
                      </div>
                    </div>
                    <PurchaseChart {...this.props}/>
                  </div>
                </Col>: ""
                 }
                 
                </Row>
                <Row gutter={[10,10]}>
                  {
                    customerDuePermission.read ?  <Col span={12}>
                    <div className="inner-view-dashboard">
  
                    <div className="row-data-dashboard">
                          <div className="inner-heading-title">
                          Top Customer
                          </div>
                        </div>
                        <TopCustomerPayment {...this.props} />
                    </div>
  
                    </Col>: ""
                  }
                 {
                  supplierDuePermission.read ? <Col span={12}>
                  <div className="inner-view-dashboard">

                  <div className="row-data-dashboard">
                        <div className="inner-heading-title">
                          Top Supplier
                        </div>
                      </div>
                      <TopSupplierPayment {...this.props} />
                  </div>

                  </Col>: ""
                 }
                  
                </Row>
                {
                  productPermission.read ?   <Row>
                  <Col span={24}>
                  <div className="inner-view-dashboard-b">

                  <div className="row-data-dashboard-b">
                        <div className="inner-heading-title-b">
                        Fastest Moving Product
                        </div>
                      </div>
                  {/* <div className="row-data-dashboard-b"> */}
                  <div className="inner-view tabular-data">
                      <MovingProduct {...this.props} />
                    {/* </div> */}
                      </div>
                  </div>
                  </Col>
                  
                </Row>: ""
                }
              
              </div>
              <div className="view-container dashboard-wrapper">
                {/* <Row >
                  <Col span={12}>
                    <div className="inner-view">
                      <div className="row-data">
                        <div className="inner-heading-title">
                          <FormattedMessage
                            id="common.sales"
                            defaultMessage=""
                          />{" "}
                          {(this.props.companyInfo || {}).currencyIcon ? (
                            <i
                              className={
                                (this.props.companyInfo || {}).currencyIcon
                              }
                            ></i>
                          ) : (
                            (this.props.companyInfo || {}).currencyCode +
                            " "
                          )}
                          {getSalesTotal()}
                        </div>
                        <div>
                          
                        </div>
                      </div> */}
                      {/* <Spin
                        indicator={antIcon}
                        className="spinner"
                        tip="Loading..."
                        spinning={
                          !isLoading &&
                          !Object.keys(this.props.salesData).length
                        }
                      >
                        <SalesLineChart {...this.props} />
                      </Spin> */}
                      {/* <div className="summary-details prl60 pb30">

                                        </div> */}
                    {/* </div>
                  </Col> */}

              
                    {/* <div className="inner-view">
                      <div className="row-data">
                        <div className="inner-heading-title">
                          <FormattedMessage
                            id="common.purchase"
                            defaultMessage=""
                          />{" "}
                          {(this.props.companyInfo || {}).currencyIcon ? (
                            <i
                              className={
                                (this.props.companyInfo || {}).currencyIcon
                              }
                            ></i>
                          ) : (
                            (this.props.companyInfo || {}).currencyCode +
                            " "
                          )}
                          {getPurchaseTotal()}
                        </div>
                        
                      </div>

                      {/* <Spin
                        indicator={antIcon}
                        className="spinner"
                        tip="Loading..."
                        spinning={
                          !isLoading &&
                          !Object.keys(this.props.purchaseData).length
                        }
                      >
                        <PurchaseLineChart {...this.props} />
                      </Spin> */}
                      {/* <div className="summary-details prl60 pb30">
                                            <Row>
                                    <Col span={8}>

                                        <div className="info">
                                            <span className="symbol">
                                                {(this.props.companyInfo || {}).currencyIcon ? <i className={(this.props.companyInfo || {}).currencyIcon}></i> : (this.props.companyInfo || {}).currencyCode + " "}
                                            </span>
                                            <span className="value">
                                                0.00
                                            </span>
                                            <span className="timeline">
                                                Yesterday
                                            </span>
                                        </div>
                                    </Col>
                                    <Col span={8}>
                                        <div className="info">
                                            <span className="symbol">
                                                {(this.props.companyInfo || {}).currencyIcon ? <i className={(this.props.companyInfo || {}).currencyIcon}></i> : (this.props.companyInfo || {}).currencyCode + " "}
                                            </span>
                                            <span className="value">
                                                766.98
                                            </span>
                                            <span className="timeline">
                                                This Month
                                            </span>
                                        </div>
                                    </Col>
                                    <Col span={8}>
                                        <div className="info">
                                            <span className="symbol">
                                                {(this.props.companyInfo || {}).currencyIcon ? <i className={(this.props.companyInfo || {}).currencyIcon}></i> : (this.props.companyInfo || {}).currencyCode + " "}
                                            </span>
                                            <span className="value">
                                                0.00
                                            </span>
                                            <span className="timeline">
                                                Last Month
                                            </span>
                                        </div>
                                    </Col>
                                </Row>

                                        </div> */}
                    {/* </div> */} 
                  
                {/* </Row> */}

                {/* <Row>
                    <Col span={12}>
                        <div className="inner-view">
                            <div className="inner-heading-title">
                                Sales {(this.props.companyInfo || {}).currencyIcon ? <i className={(this.props.companyInfo || {}).currencyIcon}></i> : (this.props.companyInfo || {}).currencyCode + " "} 0.00
                            </div>
                            <SalesPieChart {...this.props}/>
                        </div>
                    </Col>

                    <Col span={12}>
                        <div className="inner-view">
                            <div className="inner-heading-title">
                                Purchase {(this.props.companyInfo || {}).currencyIcon ? <i className={(this.props.companyInfo || {}).currencyIcon}></i> : (this.props.companyInfo || {}).currencyCode + " "} 0.00
                            </div>
                            <PurchasePieChart {...this.props}/>
                        </div>
                    </Col>
                </Row> */}

                {/* <Row>
                  <Col span={12}> */}
                    {/* {Object.keys(this.props.topSalesCustomer || {}).length ? */}
                    {/* <div className="inner-view tabular-data">
                      <div className="inner-table-caption">
                        <FormattedMessage
                          id="dashboard.topCustomerSales"
                          defaultMessage=""
                        />
                      </div>
                      <TopCustomerSales {...this.props} />
                    </div> */}
                    {/* : <img className="default-image-top-transaction" alt="user" src={TopCustomerBySalesImage} />} */}
                  {/* </Col> */}

                  {/* <Col span={12}> */}
                    {/* {Object.keys(this.props.topPurchaseSupplier || {}).length ? */}
                    {/* <div className="inner-view tabular-data">
                      <div className="inner-table-caption">
                        <FormattedMessage
                          id="dashboard.topCustomerPurchase"
                          defaultMessage=" "
                        />
                      </div>
                      <TopSupplierPurchase {...this.props} />
                    </div> */}
                    {/* : <img className="default-image-top-transaction" alt="user" src={TopSupplierByPurchaseImage} />} */}
                  {/* </Col> */}
                {/* </Row> */}

                <Row>
                  {/* <Col span={12}>
                        <div className="inner-view tabular-data">
                            <div className="title">Task Lists</div>
                            <TodoComponents {...this.props} />
                        </div>
                    </Col> */}
                  <Col span={12}>
                    {/* {Object.keys(this.props.topPaymentSupplier || {}).length ? */}
                    {/* <div className="inner-view tabular-data">
                      <div className="inner-table-caption">
                        <FormattedMessage
                          id="dashboard.topSupplierPayment"
                          defaultMessage=" "
                        />
                      </div>
                      <TopSupplierPayment {...this.props} />
                    </div> */}
                    {/* : <img className="default-image-top-transaction" alt="user" src={TopSupplierPaymentImage} />} */}
                  </Col>

                  {/* <Col span={12}>
                    {/* {Object.keys(this.props.topPaymentCustomer || {}).length ?  */}
                    {/* <div className="inner-view tabular-data">
                      <div className="inner-table-caption">
                        <FormattedMessage
                          id="dashboard.topCustomerPayment"
                          defaultMessage=""
                        />
                      </div>
                      <TopCustomerPayment {...this.props} />
                    </div> */}
                    {/* : <img className="default-image-top-transaction" alt="user" src={TopCustomerPaymentImage} />} */}
                  {/* </Col> */} 
                </Row>

                <Row>
                  {/* <Col span={12}>
                        {Object.keys(this.props.topPaymentSupplier || {}).length ? <div className="inner-view tabular-data">
                            <div className="inner-table-caption">Top Supplier by Payment</div>
                            <TopSupplierPayment {...this.props} />
                        </div> : <img className="default-image-top-transaction" alt="user" src={TopSupplierPaymentImage} />}
                    </Col> */}

                  {/* <Col span={24}>
                    <div className="inner-view tabular-data">
                      <div className="inner-table-caption">
                        <FormattedMessage
                          id="dashboard.fasetesProduct"
                          defaultMessage=" "
                        />
                      </div>
                      <MovingProduct {...this.props} />
                    </div>
                  </Col> */}
                </Row>
              </div>
            </div>
          {/* : ""  } */}

          {this.props.selectedDasboard === "Education"? 
          <SchoolDashboard {...this.props}/>
        :""} 
        {this.props.selectedDasboard === "CRM" ? 
         <CrmDashBoard {...this.props}/> 
       :""}  
            {/* {
              !this.props.isAlifApp ? (
                ''
                // content for dashboard moved above
              ) 
              :  
              (
                <div>
                  <Row>
                    <Col span={6}>
                      <div
                        onClick={() => {
                          this.props.history.push({
                            pathname: "/admin/customer-payment-list",
                          });
                        }}
                        className="new-checks"
                      >
                        <input
                          className="checkbox-toolss"
                          type="radio"
                          name="tools"
                          id="tool-1"
                          checked
                        />
                        <label className="for-checkbox-toolss" for="tool-1">
                          Cash Flow
                          <div className="libe-box"></div>
                          <div className="cltys1 dontaion"></div>
                          <span className="lafr">
                            {(this.props.companyInfo || {}).currencyIcon ? (
                              <i
                                className={
                                  (this.props.companyInfo || {}).currencyIcon
                                }
                              ></i>
                            ) : (
                              (this.props.companyInfo || {}).currencyCode + " "
                            )}
                            {this.getCashFlow(this.props.customerCashflow) || 0}{" "}
                          </span>
                        </label>
                      </div>
                    </Col>

                    <Col span={6}>
                      <div
                        onClick={() => {
                          this.props.history.push({
                            pathname: "/admin/business-expense-listing",
                          });
                        }}
                        className="new-checks"
                      >
                        <input
                          className="checkbox-toolss"
                          type="radio"
                          name="tools"
                          id="tool-1"
                        />
                        <label className="for-checkbox-toolss" for="tool-1">
                          Expense
                          <div className="libe-box"></div>
                          <div className="cltys1 income-ex"></div>
                          <span className="lafr">
                            ${this.props.totalRelationshipExpense}{" "}
                          </span>
                        </label>
                      </div>
                    </Col>

                    <Col span={6}>
                      <div
                        onClick={() => {
                          this.props.history.push({
                            pathname: "/admin/balance-sheet",
                          });
                        }}
                        className="new-checks"
                      >
                        <input
                          className="checkbox-toolss"
                          type="radio"
                          name="tools"
                          id="tool-1"
                        />
                        <label className="for-checkbox-toolss" for="tool-1">
                          Balance Sheetrr
                          <div className="libe-box"></div>
                          <div className="cltys1 balance-sheet "></div>
                          <span className="lafr">
                            ${this.getTotal("Assets")}{" "}
                          </span>
                        </label>
                      </div>
                    </Col>

                    <Col span={6}>
                      <div
                        onClick={() => {
                          this.props.history.push({
                            pathname: "/admin/profit-loss",
                          });
                        }}
                        className="new-checks"
                      >
                        <input
                          className="checkbox-toolss"
                          type="radio"
                          name="tools"
                          id="tool-1"
                        />
                        <label className="for-checkbox-toolss" for="tool-1">
                          Profit & Loss
                          <div className="libe-box"></div>
                          <div className="cltys1 pl-icon"></div>
                          <span className="lafr">
                            $
                            {(
                              this.getAmountByLedgerGroup(
                                "Income",
                                "Revenue from Operations",
                                "amount",
                                "DEBIT"
                              ) +
                                this.getAmountByLedgerGroup(
                                  "Income",
                                  "Other Income",
                                  "amount",
                                  "DEBIT"
                                ) -
                                (this.getAmountByLedgerGroup(
                                  "Expenses",
                                  "Cost of Materials",
                                  "amount",
                                  "CREDIT"
                                ) +
                                  this.getAmountByLedgerGroup(
                                    "Expenses",
                                    "Purchases",
                                    "amount",
                                    "CREDIT"
                                  ) +
                                  this.getAmountByLedgerGroup(
                                    "Expenses",
                                    "Employee Benefit Expense",
                                    "amount",
                                    "CREDIT"
                                  ) +
                                  this.getAmountByLedgerGroup(
                                    "Expenses",
                                    "Finance Cost",
                                    "amount",
                                    "CREDIT"
                                  ) +
                                  this.getAmountByLedgerGroup(
                                    "Expenses",
                                    "Depreciation & Amortisation",
                                    "amount",
                                    "CREDIT"
                                  ) +
                                  this.getAmountByLedgerGroup(
                                    "Expenses",
                                    "Other Expenses",
                                    "amount",
                                    "CREDIT"
                                  )) -
                                this.getAmountByLedgerGroup(
                                  "Expenses",
                                  "Taxes",
                                  "amount"
                                ) || 0
                            ).toFixed(2)}{" "}
                          </span>
                        </label>
                      </div>
                    </Col>
                  </Row>
                  <div className="view-container dashboard-wrapper">
                    <br />
                    <Row>
                      <Col span={24}>
                        <div className="inner-view">
                          <div className="row-data">
                            <div className="inner-heading-title">
                              <FormattedMessage
                                id="dashboard.cashFlow"
                                defaultMessage=""
                              />
                            </div>
                          </div>
                          <Spin
                            indicator={antIcon}
                            className="spinner"
                            tip="Loading..."
                            spinning={
                              !isLoading &&
                              !Object.keys(this.props.salesData).length
                            }
                          >
                            <CashFlowChart {...this.props} />
                          </Spin>
                          <div className="summary-details prl60 pb30"></div>
                        </div>
                      </Col>
                    </Row>
                    <br />
                    <Row>
                      <Col span={8}>
                        <div className=" inner-view new-tablesc">
                          <div className="inner-table-caption">
                            <FormattedMessage
                              id="dashboard.recentPayment"
                              defaultMessage=""
                            />
                          </div>
                          <table>
                            <tbody>
                              <tr>
                                <th>Name</th>
                                <th>Date</th>
                                <th>Amount</th>
                              </tr>
                              {(this.props.recent10Payments || []).map(
                                (e, i) => {
                                  return (
                                    <tr key={i}>
                                      <td>{e.merchantName}</td>
                                      <td>
                                        {(e.paymentDate || "") &&
                                          getMomentDateForUIReadOnly(
                                            e.paymentDate
                                          )}
                                      </td>
                                      <td>
                                        {Number(e.paymentAmount).toFixed(2)}
                                      </td>
                                    </tr>
                                  );
                                }
                              )}
                            </tbody>
                          </table>
                        </div>
                      </Col>
                      <Col span={8}>
                        <div className="inner-view new-tablesc">
                          <div className="inner-table-caption">
                            <FormattedMessage
                              id="dashboard.fundraisingCampaing"
                              defaultMessage=""
                            />
                          </div>
                          <table>
                            <tbody>
                              <tr>
                                <th>Name</th>
                                <th>Goal</th>
                                <th>code</th>
                                <th>Start Date</th>
                                <th>End Date</th>
                              </tr>
                              {(this.props.allCampaignList || []).map(
                                (e, i) => {
                                  return (
                                    <tr>
                                      <td>{e.campaignName}</td>
                                      <td>{e.goal}</td>
                                      <td>{e.campaignCode}</td>
                                      <td>
                                        {(e.startDate || "") &&
                                          getMomentDateForUIReadOnly(
                                            e.startDate
                                          )}
                                      </td>
                                      <td>
                                        {(e.endDate || "") &&
                                          getMomentDateForUIReadOnly(e.endDate)}
                                      </td>
                                    </tr>
                                  );
                                }
                              )}
                            </tbody>
                          </table>
                        </div>
                      </Col>
                      <Col span={8}>
                        <div className=" inner-view new-tablesc">
                          <div className="inner-table-caption">
                            <FormattedMessage
                              id="dashboard.accountsReceivable"
                              defaultMessage=""
                            />
                          </div>
                          <hr />
                          <br />
                          <span style={{ "margin-left": "20%" }}>
                            There is no outstanding balance
                          </span>
                        </div>
                      </Col>
                    </Row>
                    <br />

                    <Row>
                      <Col span={8}>
                        <div className=" inner-view new-tablesc">
                          <div className="inner-table-caption">
                            <FormattedMessage
                              id="dashboard.member"
                              defaultMessage=""
                            />
                          </div>
                          <hr />
                          <br />
                          <span style={{ "margin-left": "20%" }}>
                            {this.props.memberCount
                              ? "There are " +
                                this.props.memberCount +
                                " members"
                              : "There is no member"}
                          </span>
                        </div>
                      </Col>
                      <Col span={8}>
                        <div className="inner-view new-tablesc">
                          <div className="inner-table-caption">
                            <FormattedMessage
                              id="dashboard.event"
                              defaultMessage=""
                            />
                          </div>
                          <table>
                            <tbody>
                              <tr>
                                <th>Name</th>
                                <th>Date</th>
                                <th>Address</th>
                              </tr>
                              {(this.props.eventTemplateList || []).map(
                                (e, i) => {
                                  return (
                                    <tr>
                                      <td>{e.templateName}</td>
                                      <td>
                                        {(e.eventTime || "") &&
                                          getMomentDateForUIReadOnly(
                                            e.eventTime
                                          )}
                                      </td>
                                      <td>
                                        {(e.eventStreet1 || "") +
                                          " " +
                                          (e.eventStreet2 || "")}
                                      </td>
                                    </tr>
                                  );
                                }
                              )}
                            </tbody>
                          </table>
                        </div>
                      </Col>
                      <Col span={8}>
                        <div className=" inner-view new-tablesc">
                          <div className="inner-table-caption">
                            <FormattedMessage
                              id="dashboard.expense"
                              defaultMessage=""
                            />
                          </div>
                          <table>
                            <tbody>
                              <tr>
                                <th>Exp #</th>
                                <th>Date</th>
                                <th>Amount</th>
                              </tr>

                              {(this.props.allExpensesList[0] || []).map(
                                (e, i) => {
                                  return (
                                    <tr key={i}>
                                      <td>{e.expenseNumber}</td>
                                      <td>
                                        {(e.expenseDate || "") &&
                                          getMomentDateForUIReadOnly(
                                            e.expenseDate
                                          )}
                                      </td>
                                      <td>{e.expenseTotalAmount}</td>
                                    </tr>
                                  );
                                }
                              )}
                            </tbody>
                          </table>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              )
            } */}
          </>
      </div>
    );
  }
}

export default injectIntl(DashboardComponent);