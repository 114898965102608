import React, { Fragment } from "react";
// import { AgGridReact } from "ag-grid-react";
// import config from '../../../config/environmentConfig';
import { Tooltip, Skeleton, Menu, Empty } from "antd";
import { FormattedMessage, injectIntl } from "react-intl";
import { getMomentDateForUIReadOnly, sortTable } from "../../../utils";
import { CONSTANTS, ICONS } from '../../../static/constants';
import PageBreadcrumb from "../../PageBreadcrumb";
import DropdownAction from "antd/lib/dropdown";
import NewsLetter from "../../../containers/NewsLetter";

const EventTemplateListing = (props) => {

  const { updateState, eventTemplateList, pageSize, dir=0 } = props;

  // const agGridStyle = {
  //   height: "100%",
  //   width: "100%",
  // };

  const deleteHandle = (dg) => {
    const modalBody = <FormattedMessage id='commons.delete.popup.confimation.msg' defaultMessage='' />;
    const modalData = {
      modalBody,
      handleSubmit: () => {
        props.deleteFormBuilder(dg, props);
      },
    };
    props.showModal(modalData);
  };

  // const columnDefs = [
  //   {
  //     headerComponentFramework: () => {
  //       return (
  //         <FormattedMessage
  //           id="setting.student.registration.list.formName"
  //           defaultMessage=""
  //         />
  //       );
  //     },
  //     field: "templateName",
  //     resizable: true,
  //     minWidth: 70,
  //   },
  //   {
  //     headerComponentFramework: () => {
  //       return <FormattedMessage id='common.dateCreated' defaultMessage='' />;
  //     },
  //     cellRendererFramework: (link) => {
  //       return <div>
  //         <Tooltip placement="topLeft" title={(link.value && getMomentDateForUIReadOnly({ date: link.value, format: CONSTANTS.DISPLAY_TOOLTIP_DATE_FORMAT }))}>
  //           {(link.value && getMomentDateForUIReadOnly({ date: link.value, format: CONSTANTS.DISPLAY_DATE_FORMAT }))}
  //         </Tooltip>
  //       </div>
  //     },
  //     field: "dateCreated",
  //     minWidth: 270,
  //     suppressMovable: true,
  //   },
  //   {
  //     field: "action",
  //     headerComponentFramework: () => {
  //       return <div className="cursor-pointer">

  //       </div>;
  //     },
  //     colId: "action_1",
  //     pinned: "right",
  //     lockPosition: true,
  //     cellRendererFramework: (params) => {
  //       return <div>
  //         <span className="cursor-pointer" title={props.intl.formatMessage({ id: 'modal.settings.manageUser.permissions.view'})} onClick={() => { 
  //           Object.assign(document.createElement('a'), {
  //             target: '_blank',
  //             href: window.location.origin + '/app/event-v?t=' +props.relationshipId+'_'+params.data?.alpideFormBuilderId+ '_'+Math.floor((Math.random() * 10000) + 1) ,
  //           }).click();
  //         }}>
  //           <i className='fa fa-eye' />
  //         </span>

  //         <span style={{ marginLeft: '10px' }} className="cursor-pointer" title={props.intl.formatMessage( { id: 'commons.listing.delete.tooltip.msg'})} onClick={
  //           () => { deleteHandle(params.data);}}>
  //           <i className='fa fa-trash' />
  //         </span>

  //       </div>;
  //     },
  //     minWidth: 70,
  //     maxWidth: 300,
  //     suppressMovable: true,
  //   }
  // ];

  const newFormBuilder = (data) => {
    // props.history.push({
    //   pathname: '/admin/event-t',
    //   state: { templateType: 'event'}
    // })

    props.pushModalToStack({  
      modalBody: <NewsLetter {...props} templateType= 'event' alpideFormBuilderId= {data?.alpideFormBuilderId} />,
      width: '100%',
      hideTitle: true,  
      hideFooter: true,
      wrapClassName: "emailerTemplate_Modal",
      // wrapClassName: "full_modal",
    })
  };

  const breadCrumbList = [
    {
      name: <FormattedMessage id='sidebar.menuItem.communication' defaultMessage='' />
    },
    {
      name: <FormattedMessage id='sidebar.menuItem.events' defaultMessage='' />,
    }
  ];

  const actionMenu = (e) => {
    return (
        <Menu className="row-action-dropdown">

          <Menu.Item key="0" value="title">
            Actions
          </Menu.Item>

          <Menu.Divider />

          <Menu.Item key="1" value="preview" onClick={()=>{
              Object.assign(document.createElement('a'), {
                target: '_blank',
                href: window.location.origin + '/app/event-v?t=' + props.relationshipId + '_' + e.alpideFormBuilderId + '_' + Math.floor((Math.random() * 10000) + 1),
              }).click();
            }}
          >
            <i className={ICONS.VIEW} />
            Preview
          </Menu.Item>

          <Menu.Item key="2" value="edit" onClick={()=>{
              // props.history.push({
              //   pathname: '/admin/event-t',
              //   state: {
              //     templateType: 'event',
              //     alpideFormBuilderId: e.alpideFormBuilderId,
              //   }
              // })
              newFormBuilder(e);
            }}
          >
            <i className={ICONS.EDIT} />
            Edit
          </Menu.Item>

          <Menu.Item key="3" onClick={()=>
            {
              deleteHandle(e);;
            }
          }>
            <i className={ICONS.DELETE} />
            Delete
          </Menu.Item>

        </Menu >
    )
  };

  const sortColumn = (e) => {
    sortTable(document.getElementById('events-table'), e.target.cellIndex, 1 - dir);
    updateState({ dir: 1 - dir })
  }

  return (
    <>
      <div className="view-top-bar">
        <div className="top-view-left">
          <div className="page-heading">
            <FormattedMessage id='heading.communications.events' defaultMessage='' />
          </div>
          <div className="vertical-sep" />
          <PageBreadcrumb breadCrumbList={breadCrumbList} />
        </div>
        <div className="top-view-right">
          <button className="create-action-btn" onClick={()=>{
            newFormBuilder();
          }}>
              <i className={ICONS.ADD}/> <FormattedMessage id="button.create.label" defaultMessage="" />
          </button>
        </div>
      </div>

      <div className="view-container">

        <div className="view-container-actions">
          <div className="left-actions">
            <div className="table-heading">
              <FormattedMessage id='communication.events.table.heading' defaultMessage='' />
            </div>
            {
              pageSize ? <>
                <div className="vertical-sep" />
                <div>{eventTemplateList.length}</div>
              </>:''
            }
          </div>
          <div className="right-actions">
          </div>
        </div>

        <Skeleton loading={props.listLoading} paragraph={{ rows: 12 }} active>
          <div className="table-container">
            <table id='events-table'>
              <thead>
                <tr>
                  <th>S.No.</th>
                  <th onClick={sortColumn}>Event Name <i className={ICONS.SORT} /></th>
                  <th onClick={sortColumn}>Event Address <i className={ICONS.SORT} /></th>
                  <th onClick={sortColumn}>Event Date <i className={ICONS.SORT} /></th>
                  <th onClick={sortColumn}>Adult Attending <i className={ICONS.SORT} /></th>
                  <th onClick={sortColumn}>Child Attending <i className={ICONS.SORT} /></th>
                  <th onClick={sortColumn}>Total Declined <i className={ICONS.SORT} /></th>
                  <th onClick={sortColumn}>Total Tentative <i className={ICONS.SORT} /></th>
                  <th onClick={sortColumn}>Date Created <i className={ICONS.SORT} /></th>
                  <th width='5%'>Action</th>
                </tr>
              </thead>
              <tbody>
                {(eventTemplateList || []).length ? eventTemplateList.map((item, i) => {
                  return (<tr key={i}>
                    <td>{i+1}</td>
                    <td>{item.templateName || ''} </td>
                    <td>
                      <div> {item.eventStreet1 || ''} {item.eventStreet2 || ''} </div>
                      <div> {item.eventCity || ''} {item.eventState ? ', ' + item.eventState : ''} {item.eventZipCode ? ', ' + item.eventZipCode : ''} </div>
                    </td>

                    <td>
                      <Tooltip placement="topLeft" title={(item.eventDate && getMomentDateForUIReadOnly({ date: item.eventDate, format: CONSTANTS.DISPLAY_TOOLTIP_DATE_FORMAT }))}>
                        {(item.eventDate && getMomentDateForUIReadOnly({ date: item.eventDate, format: CONSTANTS.DISPLAY_DATE_FORMAT }))} {item.eventTime}
                      </Tooltip>
                    </td>
                    <td>{item.totalAdultAttending || ''} </td>
                    <td>{item.totalChildAttending || ''} </td>
                    <td>{item.totalDeclined || ''} </td>
                    <td> {item.totalTentative || ''}</td>
                    <td>
                      <Tooltip placement="topLeft" title={(item.dateCreated && getMomentDateForUIReadOnly({ date: item.dateCreated, format: CONSTANTS.DISPLAY_TOOLTIP_DATE_FORMAT }))}>
                        {(item.dateCreated && getMomentDateForUIReadOnly({ date: item.dateCreated, format: CONSTANTS.DISPLAY_DATE_FORMAT }))}
                      </Tooltip>
                    </td>
                    <td style={{paddingLeft: '50px'}}>
                        {/* <span className="cursor-pointer" title={props.intl.formatMessage({ id: 'modal.settings.manageUser.permissions.view' })} onClick={() => {
                          Object.assign(document.createElement('a'), {
                            target: '_blank',
                            href: window.location.origin + '/app/event-v?t=' + props.relationshipId + '_' + item.alpideFormBuilderId + '_' + Math.floor((Math.random() * 10000) + 1),
                          }).click();
                        }}>
                          <i className='fa fa-eye' />
                        </span>

                        <span className="cursor-pointer" style={{ marginLeft: '10px' }} onClick={() => {
                          props.history.push({
                            pathname: '/admin/event-t',
                            state: {
                              templateType: 'event',
                              alpideFormBuilderId: item.alpideFormBuilderId,
                            }
                          })
                        }}><i className='fa fa-pencil' /></span>

                        <span style={{ marginLeft: '10px' }} className="cursor-pointer" title={props.intl.formatMessage({ id: 'commons.listing.delete.tooltip.msg' })} onClick={
                          () => { deleteHandle(item); }}>
                          <i className='fa fa-trash' />
                        </span> */}

                        <DropdownAction overlay={actionMenu(item)} trigger={['click']}>
                          <i className={ICONS.MORE} />
                        </DropdownAction>
                    </td>
                  </tr>)})
                  : 
                  <tr key="empty-data-box">
                    <td colSpan={"100%"} style={{ borderBottom: "none" }}>
                      <Empty />
                    </td>
                  </tr>
                }
              </tbody>
            </table>
          </div>
        </Skeleton>

        {/* 
          <div className="agGridWrapper">
            <div className="ag-theme-balham" style={agGridStyle}>
              <AgGridReact
                columnDefs={columnDefs}
                defaultColDef={{
                  flex: 1,
                  autoHeight: true,
                  wrapText: true,
                }}
                rowData={eventTemplateList || []}
                rowDragManaged={true}
                domLayout={"autoHeight"}
                suppressDragLeaveHidesColumns={true}
                animateRows={true}
              ></AgGridReact>
            </div>
          </div>
        */}

      </div>
    </>
  );
};

export default injectIntl(EventTemplateListing);
