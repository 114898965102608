import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Col, Row, DatePicker, Tooltip, Switch, Drawer, Skeleton } from 'antd';
import { Form } from '@ant-design/compatible';
import { TextBox } from '../../../../general/TextBox';
import { Dropdown } from '../../../../general/Dropdown';
import { ErrorMsg } from '../../../../general/ErrorMessage';
import { CustomButton } from '../../../../general/CustomButton';
import InventoryModel from '../../../../drawer/inventory/InventoryModel';
import ProductSelectModal from './productsSelect';
import { getCurrencySymbol, getMomentDateForUI, getMomentDateForUIReadOnly } from '../../../../../utils';
import { CONSTANTS, ICONS, LIST_CONSTANTS } from '../../../../../static/constants';
import moment from 'moment-timezone';

const format = CONSTANTS.DISPLAY_DATE_FORMAT;

const CreateCoupon = (props) => {
    const {
        intl, updateState, companyInfo, autoApply,
        couponType, couponCode, couponDiscount, usageLimitPerCustomer,
        maxDiscount, orderType, orderValue, applyOn, applyOnId, discountPercentage,
        applicableTo, startDate, endDate, limitUsagePerCoupon, usageLimitPerCoupon, limitUsagePerCustomer
    } = props;
    const closeDrawer = () => {
        props.updateDrawerState({
            createCouponDrawerVisible: false,

        });
    };

    function invalidStartDate(current) {
        return current && current < moment().endOf('day').subtract(1, 'days');
    }

    function invalidEndDate(current) {
        return current && current < (props.startDate ? moment(props.startDate).endOf('day') : moment().endOf('day') );
    }

    function updateCouponState(type) {
        updateState({
            couponDiscount: 0,
            maxDiscount : '',
            orderType : '',
            orderValue : 0,
            applyOn : '',
            applyOnId : '',
            autoApply : false,
            applicableTo : '',
            startDate : undefined,
            endDate : undefined,
            usageLimitPerCustomer : 1
        });
    }

    function handleSubmit() {
        let isValid = false;
        let couponCreateRq = {couponId : props.couponId || 0};
        if(defaultValuesValid()) {
            // validation based on coupon type
            switch (props.couponType) {
                case 'PERCENTAGE': {
                    if(props.discountPercentage > 0 && props.discountPercentage <= 100) {
                        couponCreateRq.discountPercentage = props.discountPercentage;
                        addMinAndMaxOrderType(couponCreateRq);
                        isValid = true;
                    }
                    break;
                }
                case 'FLAT': {
                    if(props.maxDiscount > 0) {

                        couponCreateRq.discountAmount = props.maxDiscount;
                        addMinAndMaxOrderType(couponCreateRq);
                        isValid = true;
                    }
                    break;
                }

                
            }

            if(isValid) {
                if(props.applicableTo) {
                    if(props.applicableTo === 'NEW_CUSTOMERS') {
                        couponCreateRq.newCustomerOnly = true;
                    } else if(props.applicableTo === 'ONLINE_PAYMENTS') {
                        couponCreateRq.validOnlinePayment = true;
                    } else if(props.applicableTo === 'ALL_CUSTOMERS') {
                        couponCreateRq.applyToAllCustomers = true;
                    }
                }
                couponCreateRq = {
                    ...couponCreateRq,
                    couponType: props.couponType,
                    couponCode: props.couponCode,
                    limitUsagePerCoupon: props.limitUsagePerCoupon,
                    usageLimitPerCoupon: props.usageLimitPerCoupon,
                    limitUsagePerCustomer: props.limitUsagePerCustomer,
                    usageLimitPerCustomer: props.usageLimitPerCustomer,
                    startDate: getMomentDateForUIReadOnly({ date: props.startDate || new Date(), format: CONSTANTS.BACKEND_FORMAT_FORMAT_SHORT }),
                    endDate: getMomentDateForUIReadOnly({ date: props.endDate || new Date(), format: CONSTANTS.BACKEND_FORMAT_FORMAT_SHORT }),
                    relationshipId: props?.companyInfo?.relationshipId,
                    currencyCode: props?.companyInfo?.currencyCode,
                    active: isValid,
                    autoApply: props.autoApply,
                    couponInvItemRef: applyOn === 'Category' ? 
                                                    (props.inventoryItemCategoryRefs || []).map(obj=>({couponInvRefId: props.couponInvRefId, relationshipId: props.companyInfo?.relationshipId, couponId: props.couponId, inventoryItemType: 'CATEGORY', invItemId: obj.inventoryItemCategoryId}))
                                                    :
                                                    (props.inventoryItems || []).map(obj=>({couponInvRefId: props.couponInvRefId, relationshipId: props.companyInfo?.relationshipId, couponId: props.couponId,  inventoryItemType: 'PRODUCT', invItemId: obj.itemId}))
                }
            }
            props.createCoupon(couponCreateRq, props);
        }else{
            return
        }
    }

    function addMinAndMaxOrderType(couponCreateRq) {
        if(props.orderType && props.orderValue) {
            let type = props.orderType && props.orderType === 'Amount' ? 'minPurchaseAmount' : 'minOrderQuantity';
            couponCreateRq[type] = props.orderValue;
        }
        if(props.maxDiscount > 0) {
            couponCreateRq.maxDiscount = props.maxDiscount;
        }
    }

    function defaultValuesValid() {

        if(
            (props.couponType && props.couponCode && props.couponCode?.length>3 && props.applicableTo && props.startDate && props.endDate) ||
            (limitUsagePerCoupon && usageLimitPerCoupon < 1) || //if limit is set for coupon, it must be greater than 1
            (limitUsagePerCustomer && usageLimitPerCustomer < 1) //if limit is set for customer, it must be greater than 1
        ) {
            const stDate = new Date(props.startDate).getTime();
            const edDate = new Date(props.endDate).getTime();
            if(stDate < edDate) {
                return true;
            }
        }
        
        
        return false;
    }

    function getItems(optionValue) {
        const payload = {
            relationshipId: companyInfo.relationshipId,
            pageNumber: 1,
            pageSize: 500
        };
        if(optionValue === 'Category') {
            props.fetchCategory(payload)
        } else if(optionValue === 'Product') {
            props.fetchProducts(payload)
        }
    }

    const handleParentCategory = () => {
        props.pushModalToStack({
            modalBody: <InventoryModel {...props} />,
            title: (
                <FormattedMessage
                    id="drawer.inventory.select.category.placeholder"
                    defaultMessage=""
                />
            ),
            width: "25%",
            hideTitle: false,
            hideFooter: true,
            wrapClassName: "cat-modal-custom",
        });
    };
    const handleProductSelection = () => {
        props.pushModalToStack({
            modalBody: <ProductSelectModal {...props} />,
            title: (
                <FormattedMessage
                    id="drawer.inventory.select.category.placeholder"
                    defaultMessage=""
                />
            ),
            width: "25%",
            hideTitle: false,
            hideFooter: true,
            wrapClassName: "cat-modal-custom",
        });
    };

    const getCategoryNames = () => {
        const { inventoryItemCategoryRefs } = props;
        const categoryNames = (inventoryItemCategoryRefs || []).map((ref) => {
            const matchingCategory = props.productCategories.find(
                (category) => category.inventoryItemCategoryId === ref.inventoryItemCategoryId
            );
            return matchingCategory ? matchingCategory.categoryName : "";
        });
        return categoryNames?.join(", ");
    };

    const getProductNames = () => {
        const { inventoryItems } = props;
        const productNames = (inventoryItems || []).map((ref) => {
            const matchingProduct = props.products.find(
                (product) => product.itemId === ref.itemId
            );
            return matchingProduct ? matchingProduct.itemName : "";
        });
        return productNames?.join(", ");
    };

    return (
        <Drawer
            title={<FormattedMessage id='eStore.coupons.create.drawer.heading' defaultMessage='' />}
            className="custom-drawer"
            width={720}
            maskClosable={false}
            zIndex={99999}
            destroyOnClose={true}
            onClose={closeDrawer}
            visible={props.createCouponDrawerVisible}
            footer = {
                <>
                    <CustomButton
                        intlId={'confirmation.cancel'}
                        defaultMessage={''}
                        type={ 'default'}
                        key={1}
                        onClick={closeDrawer}
                    />
                    <CustomButton
                        intlId={'button.create.label'}
                        defaultMessage={ ''}
                        htmlType='submit '
                        key={2}
                        loading={props.submitClicked && props.saveLoading}
                        onClick={() => {
                            updateState({submitClicked: true});
                            handleSubmit();
                        }}
                    />
                </>
            }
        >
            <Row gutter={[16, 16]}>
                <Col span={12}>
                    <div className='i-label'>
                        <FormattedMessage id='eStore.coupons.create.couponType.text' defaultMessage='' /><span className='required'>*</span>
                    </div>
                    <Dropdown
                        items={LIST_CONSTANTS.COUPON_TYPE_LIST || []}
                        valueKeyName='name'
                        optionKeyName='value'
                        value={couponType}
                        placeholder={intl.formatMessage(
                            { id: 'eStore.coupons.create.couponType.select', defaultMessage: '' }
                        )}
                        onSelect={
                            (optionValue) => {
                                if(optionValue && props.couponType != optionValue) {
                                    updateState({ couponType: optionValue });
                                    updateCouponState(optionValue);
                                }
                            }
                        }
                        allowClear={true}
                        onClear={() => {
                            updateState({ couponType: undefined });
                        }}
                        showSearch={false}
                        className={(props.submitClicked && !props.couponType) ? 'input-text-error' : ''}
                    />
                    <ErrorMsg
                        validator={() => {
                                return !props.submitClicked || !!props.couponType 
                        }}
                        message={<FormattedMessage id='eStore.coupons.create.couponType.errorMsg' defaultMessage='' />}
                    />
                </Col> 

                <Col span={12}>
                    <div className='i-label'>
                        <FormattedMessage id='eStore.coupons.create.code.text' defaultMessage='' /><span className='required'>*</span>
                    </div>
                    <TextBox type='text' value={couponCode}  placeholder="Coupon Code"
                        onChange={(e) => {
                            updateState({
                                couponCode: e.target.value.toUpperCase()
                            });
                        }}
                        className={(props.submitClicked && (props.couponCode || '').length < 3) ? 'input-text-error' : ''}
                    />
                    <ErrorMsg
                        validator={() => { return !props.submitClicked || !((props.couponCode || '').length < 3)}}
                        message={<FormattedMessage id={props.couponCode?.length < 3 ? 'eStore.coupons.create.code.lengthErrorMsg':'eStore.coupons.create.code.errorMsg' } defaultMessage='V' />}
                    />
                </Col>

                <Col span={12}>
                    <div className='i-label'>
                        <FormattedMessage id='eStore.coupons.create.usageLimit.text' defaultMessage='' />
                        <Tooltip placement="bottom" title="Number of times coupon can be used per customer. Defaulted to unlimited">
                            <i className={`${ICONS.HELP} ml5 cursor-pointer`} />
                        </Tooltip>
                    </div>
                    <TextBox 
                        disabled={!limitUsagePerCoupon}
                        placeholder={!limitUsagePerCoupon ? 'Unlimited' : '1'}
                        type='number'
                        defaultValue={Number(usageLimitPerCoupon)}
                        value={Number(usageLimitPerCoupon)}
                        addonBefore={                        
                            <Switch
                                checked={limitUsagePerCoupon}
                                onClick={(checked, e) => {
                                    updateState({ limitUsagePerCoupon: !props.limitUsagePerCoupon, usageLimitPerCoupon: !props.limitUsagePerCoupon ? 1 : undefined });
                                }}
                            />
                        }
                        addonAfter={<span style={{fontSize: '12px'}}>Per Coupon</span>}  
                        onChange={(e) => {
                            updateState({
                                usageLimitPerCoupon: Number(e.target.value).toFixed(2)
                            });
                        }} 
                        className={(props.submitClicked && limitUsagePerCoupon && !Number(usageLimitPerCoupon) > 0) ? 'input-text-error' : ''}
                    />
                    <ErrorMsg
                        validator={() => { return !props.submitClicked || !(Number(usageLimitPerCoupon) < 1) }}
                        message={<FormattedMessage id='eStore.coupons.create.usageLimit1.errorMsg' defaultMessage='' />}
                    />
                </Col>

                <Col span={12}>
                    <div className='i-label'>
                        <FormattedMessage id='eStore.coupons.create.usageLimit.text' defaultMessage='' />
                        <Tooltip placement="bottom" title="Number of times coupon can be used per customer. Defaulted to 1">
                            <i className={`${ICONS.HELP} ml5 cursor-pointer`} />
                        </Tooltip>
                    </div>
                    <TextBox 
                        type='number'
                        disabled={!limitUsagePerCustomer}
                        placeholder={!limitUsagePerCustomer ? 'Upto Coupon Limit' : '1'}
                        defaultValue={Number(usageLimitPerCustomer)}
                        value={Number(usageLimitPerCustomer)}
                        addonBefore={                        
                            <Switch
                                checked={limitUsagePerCustomer}
                                onClick={(checked, e) => {
                                    updateState({ limitUsagePerCustomer: !props.limitUsagePerCustomer, usageLimitPerCustomer: !props.limitUsagePerCustomer ? 1 : undefined });
                                }}
                            />
                        }   
                        addonAfter={<span style={{fontSize: '12px'}}>Per Customer</span>}
                        onChange={(e) => {
                            updateState({
                                usageLimitPerCustomer: Number(e.target.value).toFixed(2)
                            });
                        }} 
                        className={(props.submitClicked && (usageLimitPerCustomer == null || !(Number(usageLimitPerCustomer) >= 1))) ? 'input-text-error' : ''}
                    />
                    <ErrorMsg
                        validator={() => { return !props.submitClicked || !(Number(usageLimitPerCustomer) < 1) }}
                        message={<FormattedMessage id='eStore.coupons.create.usageLimit1.errorMsg' defaultMessage='' />}
                    />
                </Col>

                {(couponType === 'PERCENTAGE') && <>
                    <Col span={12}>
                        <div className='i-label'>
                            <FormattedMessage id='eStore.coupons.create.discountP.text' defaultMessage='' /><span className='required'>*</span>
                        </div>
                        <TextBox 
                            type='number' 
                            value={Number(discountPercentage)} 
                            addonAfter={'%'}
                            onChange={(e) => {
                                updateState({
                                    discountPercentage: Number(e.target.value).toFixed(2)
                                });
                            }}
                            className={(props.submitClicked && (props.discountPercentage <= 0 || props.discountPercentage > 100 || !props.discountPercentage)) ? 'input-text-error' : ''}
                        />
                        <ErrorMsg
                            validator={() => { return !(props.submitClicked && (props.discountPercentage <= 0 || discountPercentage > 100 || !props.discountPercentage)) }}
                            message={<FormattedMessage id='eStore.coupons.create.discountCouponP.errorMsg' defaultMessage='' />}
                        />
                    </Col>
                </>
                }

                {(couponType === 'FLAT' || couponType === 'PERCENTAGE') && <>
                    <Col span={12}>
                        <div className='i-label'>
                            <FormattedMessage id='eStore.coupons.create.maxDiscount.text' defaultMessage='' /><span className='required'>*</span>
                            <Tooltip placement="bottom" title="Maximum discount amount capped for this coupon usage per cusotmer">
                                <i className={`${ICONS.HELP} ml5 cursor-pointer`} />
                            </Tooltip>
                        </div>
                        <TextBox 
                            type='text' 
                            value={maxDiscount}
                            addonBefore={getCurrencySymbol(props.companyInfo?.currencyCode)}  
                            onChange={(e) => {
                                updateState({
                                    maxDiscount: e.target.value
                                });
                            }} 
                        />
                    </Col>
                    <Col span={12}>
                        <div className='i-label'>
                            <FormattedMessage id='eStore.coupons.create.minOrderType.text' defaultMessage='' />
                        </div>
                        <Dropdown
                            items={LIST_CONSTANTS.COUPON_ODER_TYPE_LIST || []}
                            valueKeyName='name'
                            optionKeyName='value'
                            value={orderType}
                            placeholder={intl.formatMessage(
                                { id: 'eStore.coupons.create.minOrderType.select', defaultMessage: '' }
                            )}
                            onSelect={
                                (optionValue) => {
                                    if(optionValue && props.orderType != optionValue) {
                                        updateState({ orderType: optionValue, orderValue : undefined });
                                    }
                                }
                            }
                            allowClear={true}
                            onClear={() => {
                                updateState(
                                {
                                    orderType: undefined,
                                    orderValue : undefined
                                });
                            }}
                            showSearch={false}
                        />
                    </Col>
                </>
                }

                {(orderType) &&
                    <Col span={12}>
                        <div className='i-label'>
                            <FormattedMessage id={orderType === 'Amount' ? 'eStore.coupons.create.minOrderAmount.text' : 'eStore.coupons.create.minOrderQty.text'} defaultMessage='' /><span className='required'>*</span>
                        </div>
                        <TextBox 
                            type='number'
                            value={Number(orderValue)} 
                            addonBefore={orderType === 'Amount' ? getCurrencySymbol(props.companyInfo?.currencyCode) : ''}  
                            onChange={(e) => {
                                updateState({
                                    orderValue: Number(e.target.value).toFixed(2)
                                });
                            }} 
                        />
                    </Col>
                }

                {(couponType && (couponType === 'FLAT' || couponType === 'PERCENTAGE')) &&
                    <Col span={12}>
                        <div className='i-label'>
                            <FormattedMessage id='eStore.coupons.create.applyOn.text' defaultMessage='' />
                        </div>
                        <Dropdown
                            items={LIST_CONSTANTS.COUPON_APPLY_ON_LIST || []}
                            valueKeyName='name'
                            optionKeyName='value'
                            value={applyOn}
                            placeholder={intl.formatMessage(
                                { id: 'eStore.coupons.create.applyOn.select', defaultMessage: '' }
                            )}
                            onSelect={
                                (optionValue) => {
                                    if(optionValue && props.applyOn != optionValue) {
                                        updateState({ applyOn: optionValue });
                                        getItems(optionValue);
                                    }
                                }
                            }
                            allowClear={true}
                            onClear={() => {
                                updateState(
                                {
                                    applyOn: undefined,
                                    inventoryItemCategoryRefs : [],
                                    inventoryItems: []
                                });
                            }}
                            showSearch={false}
                        />
                    </Col>
                }
                
                {(applyOn === 'Category') &&
                    <Col span={12}>
                        <div className='i-label'>
                            <FormattedMessage id='eStore.coupons.create.applyOnCatId.select' defaultMessage='' />
                        </div>
                        {props.listLoading ? 
                            <Skeleton.Input active block={true} style={{height: '38px'}}/>
                            :
                            <div onClick={() => handleParentCategory()} onFocus={handleParentCategory} >
                                <TextBox
                                    value={getCategoryNames()}
                                    placeholder={intl.formatMessage({
                                        id: "drawer.inventory.select.category.placeholder",
                                        defaultMessage: "",
                                    })}
                                />
                                <ErrorMsg
                                    validator={() => {
                                        return !props.submitClicked || !!props.inventoryItemCategoryRefs?.length;
                                    }}
                                    message={"Please Select Category Name"}
                                />
                            </div>
                        }
                    </Col>
                }

                {(applyOn === 'Product') &&
                    <Col span={12}>
                        <div className='i-label'>
                            <FormattedMessage id='eStore.coupons.create.applyOnProdId.select' defaultMessage='' />
                        </div>
                        {props.listLoading ? 
                            <Skeleton.Input active block={true} style={{height: '38px'}}/> 
                            :
                            <div onClick={() => handleProductSelection()} onFocus={handleProductSelection} >
                                <TextBox
                                    value={getProductNames()}
                                    placeholder={intl.formatMessage({
                                        id: "eStore.coupons.create.product.select",
                                        defaultMessage: "",
                                    })}
                                />
                                <ErrorMsg
                                    validator={() => {
                                        return !props.submitClicked || !!props.inventoryItems?.length;
                                    }}
                                    message={"Please Select Category Name"}
                                />
                            </div>
                        }
                    </Col>
                }
                

                <Col span={12}>
                    <div className='i-label'>
                        <FormattedMessage id='eStore.coupons.create.autoApply.text' defaultMessage='' />
                        <Tooltip placement="bottom" title="Auto Apply this coupon in cart">
                            <i className={`${ICONS.HELP} ml5 cursor-pointer`} />
                        </Tooltip>
                    </div>
                    <Switch
                        checked={autoApply}
                        onClick={(checked, e) => {
                            updateState({ autoApply: !props.autoApply });
                        }}
                    />
                </Col>

                <Col span={12}>
                    <div className='i-label'>
                        <FormattedMessage id='eStore.coupons.create.couponApplicable.text' defaultMessage='' /><span className='required'>*</span>
                    </div>
                    <Dropdown
                        items={LIST_CONSTANTS.COUPON_APPLICABLE_LIST || []}
                        valueKeyName='name'
                        optionKeyName='value'
                        value={applicableTo}
                        placeholder={intl.formatMessage(
                            { id: 'eStore.coupons.create.applyOn.select', defaultMessage: '' }
                        )}
                        onSelect={
                            (optionValue) => {
                                if(optionValue && props.applicableTo != optionValue) {
                                    updateState({ applicableTo: optionValue });
                                }
                            }
                        }
                        allowClear={false}
                        showSearch={false}
                        className={(props.submitClicked && !props.applicableTo) ? 'input-text-error' : ''}
                    />
                    <ErrorMsg
                        validator={() => { return !props.submitClicked || !!props.applicableTo }}
                        message={<FormattedMessage id='eStore.coupons.create.applicableTo.errorMsg' defaultMessage='' />}
                    />
                </Col>

                <Col span={12}>
                    <div className='i-label'>
                        <FormattedMessage id='eStore.coupons.create.startDate.text' defaultMessage='' /><span className='required'>*</span>
                    </div>
                    <DatePicker
                        allowClear={true}
                        format={format}
                        key='startDate'
                        placeholder='Start Date'
                        defaultValue={props.startDate ?  getMomentDateForUI({ date: new Date(props.startDate), format }) : ''}

                        disabledDate={invalidStartDate}
                        onChange={(selectedDate) => {
                            updateState({ startDate: selectedDate })
                        }}
                        onClear={() => {
                            updateState({ startDate: null })
                        }}
                        className={(props.submitClicked && !props.startDate) ? 'input-text-error' : ''}
                    />
                    <ErrorMsg
                        validator={() => { return !props.submitClicked || !!props.startDate }}
                        message={<FormattedMessage id='eStore.coupons.create.startDate.errorMsg' defaultMessage='' />}
                    />
                </Col>

                <Col span={12}>
                    <div className='i-label'>
                        <FormattedMessage id='eStore.coupons.create.endDate.text' defaultMessage='' /><span className='required'>*</span>
                    </div>
                    <DatePicker
                        allowClear={true}
                        format={format}
                        key='endDate'
                        placeholder='End Date'
                        value={endDate ? getMomentDateForUI({date: new Date(endDate), format}): ''}
                        disabledDate={invalidEndDate}
                        onChange={(selectedDate) => {
                            updateState({ endDate: selectedDate })
                        }}
                        onClear={() => {
                            updateState({ endDate: undefined })
                        }}
                        className={(props.submitClicked && !props.endDate) ? 'input-text-error' : ''}
                    />
                    <ErrorMsg
                        validator={() => { return !props.submitClicked || !!props.endDate }}
                        message={<FormattedMessage id='eStore.coupons.create.endDate.errorMsg' defaultMessage='' />}
                    />
                </Col>

            </Row>
        </Drawer>
    );
};

export default injectIntl(CreateCoupon);
