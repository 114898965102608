import React, { Component, Fragment } from "react";
// import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Row, Col, DatePicker, Tooltip, Skeleton } from 'antd';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Dropdown } from "../../../general/Dropdown";
//import { MultipleSelectDropdown } from "../../../general/MultipleSelectDropdown";
import * as find from 'lodash.find';
import moment from 'moment-timezone';
import * as filter from 'lodash.filter';
// import { CustomAgGrid } from '../../../general/CustomAgGrid';
import {
    CONSTANTS, MODAL_TYPE, CONSTANTS_TRANSACTIONS, CONSTANTS_TRANSACTIONS_KEY, MODULE_CODED_VALUES, PERMISSION_VALUES
} from '../../../../static/constants';
// import config from '../../../../config/environmentConfig';
import { AG_GRID_CONSTANTS } from '../../../../static/agGridConstants';
import { addDaysToDate } from '../../../../utils';
import { getLabelName } from '../../../modal/modalBody/customerInfo';
import { showToasterMessage,getPermissionData } from '../../../../utils';
// import LoadingIcon from '../../../../assets/images/loading.gif';
// import { LogoComponent } from "../../../general/LogoComponent";
//import reduxStore from '../../../../store.js';
//const { dispatch } = reduxStore;
import { QuestionCircleTwoTone ,EditOutlined} from '@ant-design/icons';
import { TextBox } from "../../../general/TextBox";

const format = CONSTANTS.DISPLAY_DATE_FORMAT;
class InboundDeliveryDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            size: 'default'
        };
    }

    handleSizeChange = e => {
        this.setState({ size: e.target.value });
    };

    getDueDateChange = () => {
        const supplierObj = this.props.supplierDetail || {};
        const selectedterm = this.props.paymentTerms.length ? find(this.props.paymentTerms, { paymentTermId: supplierObj.paymentTermId || 0 }) || {} : {};
        if (selectedterm.numberOfDays) {
            return addDaysToDate((this.props.poDate || new Date()), selectedterm.numberOfDays);
        }
        return new Date();
    }

    handleNewTaxIdentificationAddition = (props, payload, modalType) => {
        const formData = payload.formData;
        const { showModal, companyInfo } = props;
        formData.relationshipId = companyInfo.relationshipId;
        formData.callback = function (taxIdentification) {
            if (props.supplier) {
                let list = props.supplier.supplierTaxIdentificationsList || [];
                list.push(taxIdentification)
                props.updateState({ supplier: { ...props.supplier, supplierTaxIdentificationsList: list } })
            }
        }
        const data = {
            title: <FormattedMessage id='addItem.text.taxIdentification' defaultMessage='' />,
            formData,
            hideFooter: true,
            modalData: {
                modalType: modalType,
                data: payload,
            },
            handleSubmit: (formData = {}) => {
                data.formData.submittedOnce = true;
                props.hideModal(data);
            }
        };
        showModal(data);
    }

    handleNewAddressAddition = (props, payload, modalType) => {
        const formData = payload.formData;
        if (!formData || (modalType === MODAL_TYPE.BILLING_ADDRESS && !formData.supplierId)) {
            return showToasterMessage({ messageType: 'error', description: 'Please Select Supplier' });
        }
        const { showModal, companyInfo } = props;
        formData.relationshipId = companyInfo.relationshipId;
        payload.formData.callback = function (address) {
            props.hideModal(data);
            if (address.locationType === 'BillingAddress' || address.locationType === 'ShippingAddress') {
              props.fetchAddresses({
                supplierId: address.supplierId,
                relationshipId: (props.companyInfo || {}).relationshipId,
                locationType: address.locationType
              });
            } else {
              props.getRelationshipAddress({
                relationshipId: (props.companyInfo || {}).relationshipId
              });
            }
          }
        const data = {
            title: this.getModalTitle(payload, modalType),
            formData,
            hideFooter: true,
            keyboard:false,
            modalData: {
                modalType: modalType,
                data: payload,
            },
            handleSubmit: (formData = {}) => {
                data.formData.submittedOnce = true;
                props.hideModal(data);
            }
        };
        showModal(data);
    }

    handleNewContactAddition = (props, payload) => {
        const formData = payload.formData;
        const { showModal, companyInfo } = props;
        formData.relationshipId = companyInfo.relationshipId;
        formData.callback = function (contact) {
            props.hideModal(data);
            if (contact.supplierId) {
                props.fetchAllContacts({
                    supplierId: contact.supplierId,
                    relationshipId: (props.companyInfo || {}).relationshipId,
                });
            }
        }
        const data = {
            title: <div>
                <FormattedMessage id='addItem.text.contact' defaultMessage='' />
                <div style={{ 'font-size': '12px' }}> {formData.supplierName || ''}</div>
            </div>,
            formData,
            hideFooter: true,
            modalData: {
                modalType: MODAL_TYPE.CONTACT,
                data: payload,

            },
            handleSubmit: (formData = {}) => {
                data.formData.submittedOnce = true;
                props.hideModal(data);
            }
        };
        showModal(data);
    }

    handleNewProjectAddition = (props, payload) => {
        const formData = payload.formData;
        const { showModal, companyInfo } = props;
        formData.relationshipId = companyInfo.relationshipId;
        const data = {
            title: <FormattedMessage id='addItem.text.project' defaultMessage='' />,
            formData,
            hideFooter: true,
            modalData: {
                modalType: MODAL_TYPE.PROJECT,
                data: payload,

            },
            handleSubmit: (formData = {}) => {
                data.formData.submittedOnce = true;
                props.hideModal(data);
            }
        };
        showModal(data);
    }

    handleNewPaymentTermAddition = (props, payload) => {
        const formData = payload.formData;
        const { showModal, hideModal, companyInfo } = props;
        formData.relationshipId = companyInfo.relationshipId;
        const data = {
            title: <FormattedMessage id='addItem.text.paymentTerm' defaultMessage='' />,
            formData,
            hideFooter: true,
            modalData: { modalType: MODAL_TYPE.PAYMENT_TERM, data: payload },
            handleSubmit: (formData = {}) => {
                if (formData.termName && formData.termName.length
                    && formData.days !== 0 && formData.days) {
                    hideModal();
                    props.addPaymentTerm(formData);
                } else {
                    data.formData.submittedOnce = true;
                    showModal(data);
                }
            }
        };
        showModal(data);
    }

    addItemConfirmationModal = (props, text, modalType) => {
        const { showModal, companyInfo } = props;
        const labelName = getLabelName(modalType);
        const modalBody = <span>
            <FormattedMessage
                id='addItem.confirmation'
                values={{ value: `${text} to ` }}
                defaultMessage={``}
            />
            {labelName} ?
        </span>;

        let addFunc;
        let payload = {
            text: text,
            relationshipId: companyInfo.relationshipId
        }

        switch (modalType) {
            case MODAL_TYPE.SO_SOURCE: {
                addFunc = props.addSalesOrderSources;
                break;
            }
            case MODAL_TYPE.DOCUMENT_NAME: {
                addFunc = props.addDocumentName;
                payload.txType = CONSTANTS_TRANSACTIONS_KEY[CONSTANTS_TRANSACTIONS.INBOUND_DELIVERY];
                break;
            }
            default: {
                addFunc = () => { }
            }
        }

        const modalData = {
            modalBody,
            handleSubmit: () => {
                addFunc(payload)
                props.hideModal();
            },
        };
        showModal(modalData);
    }

    getModalTitle = (newTextObj, modalType) => {
        let title;

        switch (modalType) {
            case MODAL_TYPE.PAYMENT_TERM: {
                title = <FormattedMessage id='addItem.text.paymentTerm' defaultMessage='' />;
                break;
            }
            case MODAL_TYPE.BILLING_ADDRESS: {
                title = <div><FormattedMessage id='addItem.text.billingAddress' defaultMessage='' />
                    <h6>{(this.props.supplier || {}).name} </h6>
                </div >
                break;
            }
            case MODAL_TYPE.RELATIONSHIP_BILLING_ADDRESS: {
                title = <div><FormattedMessage id='addItem.text.relationshipBillingAddress'
                    defaultMessage='' />
                    <h6>{(this.props.companyInfo || {}).storeName} </h6>
                </div>
                break;
            }
            case MODAL_TYPE.RELATIONSHIP_SHIPPING_ADDRESS: {
                title = <div><FormattedMessage id='addItem.text.relationshipShippingAddress'
                    defaultMessage='' />
                    <h6>{(this.props.companyInfo || {}).storeName} </h6>
                </div>
                break;
            }
            case MODAL_TYPE.DOCUMENT_NAME: {
                title = <FormattedMessage id='addItem.text.documentName' defaultMessage='' />;
                break;
            }
            default:
                title = <FormattedMessage id='signup.confirmation.title' defaultMessage='' />;
        }
        return title;
    }

    handleNewItemAddition = (props, payload, modalType) => {
        const formData = payload.formData;
        const { showModal } = props;

        if (formData.textBoxValue && formData.textBoxValue.length && !payload.existsInData) {
            this.addItemConfirmationModal(props, formData.textBoxValue, modalType);
        } else {
            const data = {
                title: this.getModalTitle(payload, modalType),
                formData,
                modalData: { modalType, data: payload },
                handleSubmit: (formData = {}) => {
                    if (formData.textBoxValue && formData.textBoxValue.length) {
                        this.addItemConfirmationModal(props, formData.textBoxValue, modalType);
                    } else {
                        data.formData.submittedOnce = true;
                        showModal(data);
                    }
                }
            };
            showModal(data);
        }
    }

    deleteClickHandler = (props, modalType, payload) => {
        const { showModal, companyInfo } = props;
        const modalBody =
            <FormattedMessage
                id='deleteItem.confirmation'
                defaultMessage={``}
                values={{ value: payload.text }}
            />;

        let deleteFunc;
        let callback = function (data) {
            props.getRelationshipAddress({
                relationshipId: (props.companyInfo || {}).relationshipId
              });
            if (modalType === MODAL_TYPE.CONTACT && props.supplier.supplierId) {
                props.fetchAllContacts({
                    supplierId: props.supplier.supplierId,
                    relationshipId: (props.companyInfo || {}).relationshipId,
                });
            }
            if (modalType === MODAL_TYPE.BILLING_ADDRESS && props.supplier.supplierId) {
                props.fetchAddresses({
                    supplierId: props.supplier.supplierId,
                    relationshipId: (props.companyInfo || {}).relationshipId,
                    locationType: 'BillingAddress'
                });
            }
        }

        switch (modalType) {
            case MODAL_TYPE.PAYMENT_TERM: {
                deleteFunc = props.deletePaymentTerm;
                break;
            }
            case MODAL_TYPE.PROJECT: {
                deleteFunc = props.deleteProject;
                break;
            }
            case MODAL_TYPE.BILLING_ADDRESS:
            case MODAL_TYPE.RELATIONSHIP_BILLING_ADDRESS:
            case MODAL_TYPE.RELATIONSHIP_SHIPPING_ADDRESS: {
                deleteFunc = props.deleteAddress;
                break;
            }
            case MODAL_TYPE.DOCUMENT_NAME: {
                deleteFunc = props.deleteDocumentName;
                break;
            }
            case MODAL_TYPE.CONTACT: {
                deleteFunc = props.deleteContact;
                break;
            }
            case MODAL_TYPE.NEW_TAX_IDENTIFICATION: {
                deleteFunc = props.deleteRelationshipTaxIdentifications;
                break;
            }
            case MODAL_TYPE.NEW_TAX_IDENTIFICATION_SUPPLIER: {
                deleteFunc = props.deleteSupplierTaxIdentifications;
                break;
            }
            default: {
                deleteFunc = () => { }
            }
        }

        const modalData = {
            modalBody,
            handleSubmit: () => {
                
                deleteFunc({ id: payload.id, relationshipId: companyInfo.relationshipId, callback: callback })
                if (modalType === MODAL_TYPE.NEW_TAX_IDENTIFICATION_SUPPLIER && props.supplier && props.supplier.supplierTaxIdentificationsList) {
                    let list = (props.supplier.supplierTaxIdentificationsList || []).filter(x => x.supplierTaxIdentificationId !== payload.id);
                    props.updateState({ supplier: { ...props.supplier, supplierTaxIdentificationsList: list } })
                }
                if (modalType === MODAL_TYPE.DOCUMENT_NAME && payload.id === this.props.documentNameId) {
                    const selectedObj = find((this.props.documentList || []), { txType: CONSTANTS_TRANSACTIONS_KEY[CONSTANTS_TRANSACTIONS.INBOUND_DELIVERY], isDefault: 1 }) || {};
                    this.props.updateState({
                        documentNameId: selectedObj.documentNameId,
                        docName: selectedObj.docName
                    });
                }
            },
        };
        showModal(modalData);
    }

    render() {

        const { size } = this.state;
        const { supplier = {} } = this.props;
      

       // const supplierObj = this.props.supplierDetail || {};
       // const supplierAddressObjs = (supplierObj.boLocationList || []).filter(x => x.locationType === CONSTANTS.BILLING_ADDRESS) || [];
        const supplierPaymentTerm = this.props.supplierPaymentTerm || {};
        const rowNode = this.gridApi && this.gridApi.getDisplayedRowAtIndex(0);
        if (rowNode) {
            rowNode.data['customerInquiryNumber'] = this.props.customerInquiryNumber;
            rowNode.data['customerPONumber'] = this.props.customerPONumber;
            rowNode.data['customerRFQNumber'] = this.props.customerRFQNumber;
            rowNode.data['supplierPONumber'] = this.props.supplierPONumber;
            rowNode.data[CONSTANTS.SHIPPING_ADDRESS] =  ((this.props.relationshipShippingAddress || {}).streetAddress1 || '');
            rowNode.data[AG_GRID_CONSTANTS.COMMON.PLACE_OF_SUPPLY_FIELD] =  ((this.props.supplierBillingAddress || {}).stateName || '');
            rowNode.data[AG_GRID_CONSTANTS.STANDARD_SALES_INVOICE_FIELD.PAYMENT_TERM_FIELD] =  (supplierPaymentTerm.termName || '');
            rowNode.data['supplierQuoteNumber'] = this.props.supplierQuoteNumber;
            rowNode.data['reference'] =  this.props.reference;
            rowNode.data[AG_GRID_CONSTANTS.STANDARD_SALES_INVOICE_FIELD.PROJECT] =  this.props.projectName;
            this.gridApi.sizeColumnsToFit();
            this.gridApi.refreshCells({ force: true });
        }

        // const columnDefs = [
        //     {
        //         headerComponentFramework: () => {
        //             return <FormattedMessage id='supplier.purchaseOrder.form.gridHeader.placeOfSupply' defaultMessage='' />;
        //         },
        //         field: AG_GRID_CONSTANTS.COMMON.PLACE_OF_SUPPLY_FIELD,
        //         resizable: true,
        //         editable: true,
        //         cellEditor: 'customDropDownEditor',
        //         //pinned: 'left',
        //         hide: (this.props.companyInfo || {}).countryName !== 'India',
        //         cellEditorParams: (obj) => {
        //             return {
        //                 lastColumnCell: false,
        //                 items: this.props.states || [],
        //                 optionKeyName: 'stateId',
        //                 valueKeyName: 'stateName',
        //                 onSelect: (selectedValue, optionObj) => {
        //                     const state = find(this.props.states, { stateId: Number(optionObj.key) });
        //                     this.props.updateState({
        //                         placeOfSupply: state.stateName
        //                     })
        //                     //obj.node.setDataValue(obj.colDef.field, state.stateName)
        //                 },
        //                 // allowClear: true,
        //                 // onClear: () => {
        //                 //     this.props.updateState({
        //                 //         placeOfSupply: undefined
        //                 //     })
        //                 //     //obj.node.setDataValue(obj.colDef.field, undefined);
        //                 // },
        //             }
        //         }
        //     },
        //     {
        //         headerComponentFramework: () => {
        //             return <div>
        //                 <FormattedMessage id='supplierPONumber.text' defaultMessage='' />
        //             </div>
        //         },
        //         field: 'supplierPONumber',
        //         hide: this.props.supplierPONumber && this.props.supplierPONumber !== '0' ? false : true,
        //     },
        //     {
        //         headerComponentFramework: () => {
        //             return <div>
        //                 <FormattedMessage id='modal.txDetails.inquiry.customerInquiryNumber' defaultMessage='' />
        //             </div>
        //         },
        //         field: 'customerInquiryNumber',
        //         hide: this.props.customerInquiryNumber && this.props.customerInquiryNumber !== '0' ? false : true,
        //     },
        //     {
        //         headerComponentFramework: () => {
        //             return <div>
        //                 <FormattedMessage id='customerPONumber.text' defaultMessage='' />
        //             </div>
        //         },
        //         field: 'customerPONumber',
        //         hide: this.props.customerPONumber && this.props.customerPONumber !== '0' ? false : true,
        //     },
        //     {
        //         headerComponentFramework: () => {
        //             return <div>
        //                 <FormattedMessage id='supplier.rfq.form.gridHeader.customerRFQNumber' defaultMessage='' />
        //             </div>
        //         },
        //         field: 'customerRFQNumber',
        //         hide: this.props.customerRFQNumber && this.props.customerRFQNumber !== '0' ? false : true,
        //     },
        //     {
        //         headerComponentFramework: () => {
        //             return <div>
        //                 <FormattedMessage id='supplier.rfq.form.gridHeader.supplierQuoteNumber' defaultMessage='' />
        //             </div>
        //         },
        //         field: 'supplierQuoteNumber',
        //     },
        //     {
        //         headerComponentFramework: () => {
        //             return <div>
        //                 <FormattedMessage id='supplier.purchaseOrder.form.gridHeader.refNumber' defaultMessage='' />
        //                 <Tooltip placement="top" title="Reference number is an identifier assigned to any transaction. This number can be used to associate or refer to another transaction" trigger="click">
        //                     <i className="fa fa-question-circle-o pl5 cursor-pointer" />
        //                 </Tooltip>
        //             </div>
        //         },
        //         field: 'reference',
        //         editable: true,
        //         cellEditor: 'customTextEditor',
        //         cellEditorParams: (obj) => {
        //             return {
        //                 maxLength: 20,
        //                 onChange: (val) => {
        //                     this.props.updateState({
        //                         reference: val
        //                     });
        //                 }
        //             }
        //         }
        //     },
        //     {
        //         headerComponentFramework: () => {
        //             return <FormattedMessage id='supplier.purchaseOrder.form.gridHeader.shippingAddress' defaultMessage='' />;
        //         },
        //         field: CONSTANTS.SHIPPING_ADDRESS, editable: true,
        //         cellEditor: 'customDropDownEditor',
        //         cellEditorParams: (obj) => {
        //             return {
        //                 lastColumnCell: false,
        //                 items: this.props.allRelationshipShippingAddress || [],
        //                 optionKeyName: 'locationId',
        //                 valueKeyName: 'streetAddress1',
        //                 addressLineKey1: 'streetAddress2',
        //                 addressLineKey2: 'cityName',
        //                 addressLineKey3: 'stateName',
        //                 addressLineKey4: 'zipCode',
        //                 isFavouriteKeyName: 'isDefault',
        //                 optionClassAddress: 'custom-address-dropdown',
        //                 canAddNew: true,
        //                 canDelete: true,
        //                 deleteClickHandler: (payload) => {
        //                     this.deleteClickHandler(this.props, MODAL_TYPE.RELATIONSHIP_SHIPPING_ADDRESS, payload);
        //                 },
        //                 onAddNewClick: (payload) => {
        //                     payload.formData = {
        //                         locationType: CONSTANTS.RELATIONSHIP_SHIPPING_ADDRESS
        //                     }
        //                     this.handleNewAddressAddition(this.props, payload, MODAL_TYPE.RELATIONSHIP_SHIPPING_ADDRESS)
        //                 },
        //                 onSelect: (selectedValue, optionObj) => {
        //                     //obj.node.setDataValue(obj.colDef.field, selectedValue)
        //                     const relationshipShippingAddress = find((this.props.allRelationshipShippingAddress || []), { locationId: Number(optionObj.key) });
        //                     this.props.updateState({
        //                         relationshipShippingAddress
        //                     });
        //                 },
        //                 allowClear: true,
        //                 onClear: () => {
        //                     this.props.updateState({
        //                         relationshipShippingAddress: undefined
        //                     })
        //                     //obj.node.setDataValue(obj.colDef.field, undefined);
        //                 },
        //             }
        //         }
        //     },
        //     {
        //         headerComponentFramework: () => {
        //             return <FormattedMessage id='supplier.purchaseOrder.form.gridHeader.paymentTerms' defaultMessage='' />;
        //         },
        //         field: AG_GRID_CONSTANTS.STANDARD_SALES_INVOICE_FIELD.PAYMENT_TERM_FIELD,
        //         editable: true,
        //         hide: true,
        //         //pinned: 'right',
        //         cellEditor: 'customDropDownEditor',
        //         cellEditorParams: (obj) => {
        //             return {
        //                 lastColumnCell: false,
        //                 items: this.props.paymentTerms,
        //                 optionKeyName: 'paymentTermId',
        //                 valueKeyName: 'termName',
        //                 isLockKeyName: 'isSystemGenerated',
        //                 //isFavouriteKeyName: 'isDefault',
        //                 canAddNew: true,
        //                 canDelete: true,
        //                 // permissions: this.props.permissions,
        //                 // moduleName: MODULE_CODED_VALUES.PAYMENT_TERM,
        //                 // operations: [PERMISSION_VALUES.CREATE, PERMISSION_VALUES.DELETE],
        //                 deleteClickHandler: (payload) => {
        //                     this.deleteClickHandler(this.props, MODAL_TYPE.PAYMENT_TERM, payload);
        //                 },
        //                 onAddNewClick: (payload) => {
        //                     payload.formData = {
        //                         termName: payload.textEntered,
        //                         days: null,
        //                         description: '',
        //                         isDefaultPayment: false,
        //                         submittedOnce: false
        //                     }
        //                     this.handleNewPaymentTermAddition(this.props, payload, MODAL_TYPE.PAYMENT_TERM)
        //                 },
        //                 onSelect: (selectedValue, optionObj) => {
        //                     //obj.node.setDataValue(obj.colDef.field, selectedValue)
        //                     const supplierPaymentTerm = find(this.props.paymentTerms, { paymentTermId: Number(optionObj.key) });
        //                     const poDueDate = addDaysToDate((this.props.poDate || new Date()), supplierPaymentTerm.numberOfDays);
        //                     this.props.updateState({
        //                         supplierPaymentTerm: supplierPaymentTerm,
        //                         poDueDate: poDueDate
        //                     });
        //                 },
        //             }
        //         }
        //     },
        //     {
        //         headerComponentFramework: () => {
        //             return <FormattedMessage id='supplier.purchaseOrder.form.projectDropdown.label' defaultMessage='' />;
        //         },
        //         field: AG_GRID_CONSTANTS.STANDARD_SALES_INVOICE_FIELD.PROJECT,
        //         editable: true,
        //         //pinned: 'right',
        //         cellEditor: 'customDropDownEditor',
        //         cellEditorParams: (obj) => {
        //             return {
        //                 lastColumnCell: false,
        //                 items: this.props.projectList,
        //                 valueKeyName: 'projectName',
        //                 value: this.props.projectName,
        //                 optionKeyName: 'projectMasterId',
        //                 projectIdKey: 'projectMasterId',
        //                 canAddNew: true,
        //                 canDelete: true,
        //                 deleteClickHandler: (payload) => {
        //                     this.deleteClickHandler(this.props, MODAL_TYPE.PROJECT, payload);
        //                 },
        //                 onAddNewClick: (payload) => {
        //                     payload.formData = {
        //                         projectName: payload.projectName,
        //                     }
        //                     this.handleNewProjectAddition(this.props, payload, MODAL_TYPE.PROJECT)
        //                 },
        //                 onSelect: (selectedValue, option) => {
        //                     const selectedProject = find(this.props.projectList, { projectMasterId: Number(option.key) }) || {};
        //                     this.props.updateState({
        //                         projectMasterId: selectedProject.projectMasterId,
        //                         projectName: selectedProject.projectName,
        //                         projectNumber: selectedProject.projectNumber
        //                     });
        //                 },
        //                 allowClear: true,
        //                 onClear: () => {
        //                     this.props.updateState({
        //                         projectMasterId: undefined,
        //                         projectName: undefined,
        //                         projectNumber: undefined
        //                     })
        //                 },
        //             }
        //         }
        //     }
        // ]

        // const onGridReady = (params) => {
        //     this.gridApi = params.api;
        //     this.gridColumnApi = params.columnApi;
        //     setTimeout(()=>{
        //         this.gridApi.sizeColumnsToFit()
        //     },2000);
        // }

        return (
            <Fragment>
                <Row>
                   <Col span={5}>
                             {/* <LogoComponent src={CONSTANTS.BASE_URL_S3 + '/' + config.BUCKET_NAME.BO_RELATIONSHIP + '/' + (this.props.companyInfo || {}).logoAwsObjectKey + "?" + Date.now()}/> */}
                                {/* <Avatar shape='square' size={64} icon={<UserOutlined />} src={CONSTANTS.BASE_URL_S3 +'/'+ config.BUCKET_NAME.BO_RELATIONSHIP +'/' + (this.props.companyInfo || {}).logoAwsObjectKey + "?"+Date.now()} /> */}
                                <Skeleton loading={this.props.detailLoading} active paragraph={{ rows: 5 }}>
                                <Row gutter={[0,3]}>
                                <Col span={24} className='details-heading'>
                                    Organization Information
                                </Col>
                                <Col span={24}>
                                <div className='details-heading-subheading'>

                                <FormattedMessage id="sales.order.create.organization.name" defaultMessage=''/>
                                </div>
                                </Col>
                                <Col span={24}>
                                <div className='deatils-field-value'>
                                {(this.props.companyInfo || {}).storeName}
                                     </div>
                                </Col>
                                <Col span={24}>
                                <div className='details-heading-subheading'>

                                <FormattedMessage id="sales.order.create.organization.adress" defaultMessage=''/>
                                <Tooltip placement="right" title={this.props.intl.formatMessage(
                                    {
                                    id: 'tooltip.companyBillingAddress',
                                    defaultMessage: ''
                                    }
                                )} trigger="click">
                                    <QuestionCircleTwoTone />
                                </Tooltip>
                                </div>
                                </Col> 
                                <Col span={24}>
                <span>{
                  !this.props.addressHide && this.props.relationshipBillingAddress ?
                  <>
                  
                  <div className='detail-address-value'>

                     <div style={{height:'96px',paddingTop:'10px'}}>
                     {
                             getAddressInfoFragment(this.props.relationshipBillingAddress || {}, {}, (this.props.companyInfo || {}))
                     }
                     </div>
                     <div onClick={()=>{
                        
                        this.props.updateState({
                          addressHide: true,
                        })
                      }}>
                        <EditOutlined />
                     </div>
                  </div>
                  </> 
                  : 
                  <Dropdown
                  style={{ width: '100%'}}
                  value={(this.props.relationshipBillingAddress || {}).streetAddress1}
                  items={this.props.allRelationshipBillingAddress || []}
                  valueKeyName='streetAddress1'
                  addressLineKey1='streetAddress2'
                  addressLineKey2='cityName'
                  addressLineKey3='stateName'
                  addressLineKey4='zipCode'
                  optionClassAddress='custom-address-dropdown'
                  isFavouriteKeyName='isDefault'
                  optionKeyName='locationId'
                  canAddNew={true}
                  canDelete={true}
                  allowClear={true}
                  onClear={() => {
                      this.props.updateState({ relationshipBillingAddress: undefined })
                  }}
                  permissions={this.props.permissions}
                  moduleName={MODULE_CODED_VALUES.SUPPLIER_BILLING_ADDRESS}
                  operations={[PERMISSION_VALUES.CREATE, PERMISSION_VALUES.DELETE]}
                  deleteClickHandler={(payload) => {
                      this.deleteClickHandler(this.props, MODAL_TYPE.RELATIONSHIP_BILLING_ADDRESS, payload);
                  }}
                  onAddNewClick={(payload) => {
                      payload.formData = {
                          locationType: CONSTANTS.RELATIONSHIP_BILLING_ADDRESS
                      }
                      this.handleNewAddressAddition(this.props, payload, MODAL_TYPE.RELATIONSHIP_BILLING_ADDRESS)
                  }}
                  onSelect={(locationId) => {
                      this.props.updateState({
                          relationshipBillingAddress: (this.props.allRelationshipBillingAddress || []).find(x => x.locationId === locationId)
                          ,addressHide: false
                      })
                  }}
              />
                  }
                 
                </span>

              </Col>
                                    {/* <MultipleSelectDropdown
                                        style={{ width: '70%' }}
                                        items={this.props.allRelationshipTaxIdentificatins}
                                        placeholder="Tax Identification(s)"
                                        mode='multiple'
                                        onDeSelect={(value) => {
                                            let { selectedRelationshipTaxIdentifications } = this.props
                                            selectedRelationshipTaxIdentifications = filter(selectedRelationshipTaxIdentifications, (taxId) => {
                                                return taxId !== value;
                                            });
                                            this.props.updateState({ selectedRelationshipTaxIdentifications });
                                        }}
                                        valueKeyName='taxIdentificationType'
                                        valueKeyName2='taxIdentificationNumber'
                                        selectedValue={this.props.selectedRelationshipTaxIdentifications}
                                        optionKeyName='relationshipTaxIdentificationId'
                                        onSelect={(relationshipTaxIdentificationId) => {
                                            let selectedRelationshipTaxIdentifications = this.props.selectedRelationshipTaxIdentifications || [];
                                            if (selectedRelationshipTaxIdentifications.indexOf(relationshipTaxIdentificationId) === -1) {
                                                selectedRelationshipTaxIdentifications.push(relationshipTaxIdentificationId);
                                            } else {
                                                selectedRelationshipTaxIdentifications = selectedRelationshipTaxIdentifications.filter((el) => {
                                                    return el !== selectedRelationshipTaxIdentifications
                                                });
                                            }
                                            this.props.updateState({
                                                
                                                selectedRelationshipTaxIdentifications
                                            });
                                        }}
                                        canAddNew={true}
                                        canDelete={true}
                                        deleteClickHandler={(payload) => {
                                            this.deleteClickHandler(this.props, MODAL_TYPE.NEW_TAX_IDENTIFICATION, payload);
                                        }}
                                        onAddNewClick={(payload) => {
                                            payload.formData = {
                                                taxIdentificationNumber: payload.textEntered,
                                                submittedOnce: false,
                                            }
                                            this.handleNewTaxIdentificationAddition(this.props, payload, MODAL_TYPE.NEW_TAX_IDENTIFICATION)
                                        }}
                                    /><Tooltip placement="right" title={<FormattedMessage id='tooltip.taIdentification' defaultMessage='' />} trigger="click">
                                        <i className="fa fa-question-circle-o pl5 cursor-pointer" />
                                    </Tooltip> */}
                                </Row>
                          </Skeleton>
                        </Col>
                       <Col span={1}></Col>
                        <Col span={5} >
                        <Skeleton loading={this.props.detailLoading} active paragraph={{ rows: 5 }}>


                            <Row gutter={[0,3]}>
                            <Col span={24} className="details-heading">
                                Supplier Information
                            </Col>
                            {
                                supplier.name
                                    ? 
                                    <>
                                      
                                            <Col span={24}>
                                                <div className="details-heading-subheading">
                                                    <FormattedMessage id='purchase.landindCostPage.table.heading.supplier' defaultMessage='' />
                                                </div>
                                            </Col>
                                            <Col span={24}>
                                                <div className="deatils-field-value">
                                                    {supplier.name}
                                                </div>
                                            </Col>
                                       
                                       
                                            <Col span={24}>
                                                <div className="details-heading-subheading">
                                                    <FormattedMessage id='contact' defaultMessage='' />
                                                    <Tooltip placement="right" title={<FormattedMessage id='common.supplier.contact' defaultMessage='' />} trigger="click">
                                                    <i className="fa fa-question-circle-o pl5 cursor-pointer" />
                                                </Tooltip>
                                                </div>
                                            </Col>
                                            <Col span={24}>
                                                <Dropdown
                                                    style={{ width: '70%' }}
                                                    value={((this.props.selectedContact || {}).firstName || "") + " " + ((this.props.selectedContact || {}).lastName || "")}
                                                    optionKeyName="contactId"
                                                    items={this.props.boContactList}
                                                    valueKeyName='firstName'
                                                    lastNameKey='lastName'
                                                    isFavouriteKeyName='isPrimaryContact'
                                                    placeholder='Please select contact name'
                                                    moduleName={MODULE_CODED_VALUES.SUPPLIER_CONTACTS}
                                                    canAddNew={true}
                                                    canDelete={true}
                                                    allowClear={true}
                                                    onClear={() => {
                                                        this.props.updateState({ selectedContact: undefined })
                                                    }}
                                                    deleteClickHandler={(payload) => {
                                                        this.deleteClickHandler(this.props, MODAL_TYPE.CONTACT, payload);
                                                    }}
                                                    onAddNewClick={(payload) => {
                                                        payload.formData = {
                                                            firstName: payload.textEntered,
                                                            submittedOnce: false,
                                                            supplierId: (this.props.supplier || {}).id,
                                                            pageNumber: this.props.pageNumber || 1,
                                                            supplierName: supplier.name
                                                        }
                                                        this.handleNewContactAddition(this.props, payload, MODAL_TYPE.CONTACT)
                                                    }}
                                                    onSelect={(contactId) => {
                                                        let contact = find(this.props.boContactList, { contactId: +contactId }) || {};
                                                        this.props.updateState({
                                                            selectedContact: contact
                                                        })
                                                    }}
                                                />
                                             
                                            </Col>
                                        
                                            <Col span={24}>
                                                <div className="details-heading-subheading">
                                                    <FormattedMessage id='signup.tab.address' defaultMessage='' />
                                                    <Tooltip placement="right" title={<FormattedMessage id='common.supplier.address' defaultMessage='' />} trigger="click">
                                                    <i className="fa fa-question-circle-o pl5 cursor-pointer" />
                                                </Tooltip>
                                                </div>
                                            </Col>
                                            <Col span={24}>
                                                <Dropdown
                                                    style={{ width: '70%' }}
                                                    value={(this.props.supplierBillingAddress || {}).streetAddress1}
                                                    items={this.props.allBillingAddress}
                                                    valueKeyName='streetAddress1'
                                                    addressLineKey1='streetAddress2'
                                                    addressLineKey2='cityName'
                                                    addressLineKey3='stateName'
                                                    addressLineKey4='zipCode'
                                                    optionClassAddress='custom-address-dropdown'
                                                    optionKeyName='locationId'
                                                    isFavouriteKeyName='isDefault'
                                                    moduleName={MODULE_CODED_VALUES.SUPPLIER_BILLING_ADDRESS}
                                                    canAddNew={true}
                                                    canDelete={true}
                                                    allowClear={true}
                                                    onClear={() => {
                                                        this.props.updateState({ supplierBillingAddress: undefined })
                                                    }}
                                                    deleteClickHandler={(payload) => {
                                                        this.deleteClickHandler(this.props, MODAL_TYPE.BILLING_ADDRESS, payload);
                                                    }}
                                                    onAddNewClick={(payload) => {
                                                        payload.formData = {
                                                            locationType: CONSTANTS.BILLING_ADDRESS,
                                                            supplierId: (this.props.supplier || {}).supplierId,
                                                            pageNumber: this.props.pageNumber,
                                                        }
                                                        this.handleNewAddressAddition(this.props, payload, MODAL_TYPE.BILLING_ADDRESS)
                                                    }}
                                                    onSelect={(locationId) => {
                                                        this.props.updateState({
                                                            supplierBillingAddress: this.props.allBillingAddress.find(x => +x.locationId === +locationId) || {}
                                                        })
                                                    }}
                                                />
                                             
                                                
                                            </Col>
                              
                                        {/* <Row>
                                            <Col span={4} className='detail-column'>
                                                <FormattedMessage id='customer.form.taxIdentification.label' defaultMessage='' />
                                            </Col>
                                            <Col span={20} style={{ 'text-align': 'left' }}>
                                                <MultipleSelectDropdown
                                                    style={{ width: '70%' }}
                                                    items={this.props.supplier.supplierTaxIdentificationsList || []}
                                                    placeholder="Tax Identification(s)"
                                                    mode='multiple'
                                                    onDeSelect={(value) => {
                                                        let { selectedSupplierTaxIdentifications } = this.props
                                                        selectedSupplierTaxIdentifications = filter(selectedSupplierTaxIdentifications, (taxId) => {
                                                            return taxId !== value;
                                                        });
                                                        this.props.updateState({ selectedSupplierTaxIdentifications });
                                                    }}
                                                    valueKeyName='taxIdentificationType'
                                                    valueKeyName2='taxIdentificationNumber'
                                                    selectedValue={(this.props.supplier.supplierTaxIdentificationsList || []).length ? (this.props.selectedSupplierTaxIdentifications || []) : []}
                                                    optionKeyName='supplierTaxIdentificationId'
                                                    onSelect={(supplierTaxIdentificationId) => {
                                                        let selectedSupplierTaxIdentifications = this.props.selectedSupplierTaxIdentifications || [];
                                                        if (selectedSupplierTaxIdentifications.indexOf(supplierTaxIdentificationId) === -1) {
                                                            selectedSupplierTaxIdentifications.push(supplierTaxIdentificationId);
                                                        } else {
                                                            selectedSupplierTaxIdentifications = selectedSupplierTaxIdentifications.filter((el) => {
                                                                return el !== supplierTaxIdentificationId
                                                            });
                                                        }
                                                        this.props.updateState({
                                                            selectedSupplierTaxIdentifications
                                                        });
                                                    }}
                                                    canAddNew={true}
                                                    canDelete={true}
                                                    deleteClickHandler={(payload) => {
                                                        this.deleteClickHandler(this.props, MODAL_TYPE.NEW_TAX_IDENTIFICATION_SUPPLIER, payload);
                                                    }}
                                                    onAddNewClick={(payload) => {
                                                        payload.formData = {
                                                            taxIdentificationNumber: payload.textEntered,
                                                            submittedOnce: false,
                                                            supplierId: (this.props.supplier || {}).id,
                                                            pageNumber: this.props.pageNumber || 1,
                                                            supplierName: supplier.name
                                                        }
                                                        this.handleNewTaxIdentificationAddition(this.props, payload, MODAL_TYPE.NEW_TAX_IDENTIFICATION)
                                                    }}
                                                /><Tooltip placement="right" title={<FormattedMessage id='title.Supplier.Tax.Identification' defaultMessage='' />} trigger="click">
                                                    <i className="fa fa-question-circle-o pl5 cursor-pointer" />
                                                </Tooltip>

                                            </Col>
                                        </Row> */}
                                    </>
                                    : ''
                            }
                            </Row>
                        </Skeleton>
                        </Col>
                        <Col span={8}></Col>
                        <Col span={5}>
                        <Skeleton loading={this.props.detailLoading} active paragraph={{ rows: 5 }}>


                            <Row gutter={[0,3]}>
                                   
                            <Col span={24} className="details-heading">
                            GRN Information 
                            </Col>

                                    {
                                      <>
                                                <Col span={24}>
                                                    <div className="details-heading-subheading">

                                                <FormattedMessage id='common.poNumber' defaultMessage='' /> 
                                                    </div>
                                                </Col>
                                                <Col span={24}>
                                                    <Dropdown
                                                        className="text-left"
                                                        style={{ width: '100%' }}
                                                        items={this.props.poList || []}
                                                        value={this.props.poNumber}
                                                        placeholder='Select Purchase Order'
                                                        valueKeyName='poNumber'
                                                        salesOrderAmountKey='totalPOAmount'
                                                        currencyCodeKey='currencyCode'
                                                        addressLineKey1='supplierName'
                                                        optionClassAddress='custom-address-dropdown'
                                                        optionKeyName='poMasterId'
                                                        onSelect={(selectedValue) => {
                                                            const data = find(this.props.poList, { poMasterId: Number(selectedValue) });
                                                            this.props.updateState({
                                                                stateSupplierId: data.supplierId
                                                            });
                                                           // dispatch({type: INBOUND_DELIVERY_ACTION_LIST.ID_PO_DATA, data});
                                                            this.props.resetIDDetail();
                                                            this.props.fetchIDDataForPo({poMasterId: data.poMasterId, relationshipId: data.relationshipId, supplierId: data.supplierId});
                                                            this.props.fetchSupplierDetailsBySupplierId({
                                                                relationshipId: data.relationshipId,
                                                                supplierId: data.supplierId,
                                                                pageNumber: this.props.pageNumber || 1,
                                                                pageSize: this.props.pageSize || 100
                                                              });
                                                        }
                                                    }
                                                    />
                                                </Col>
                                            </>
                                        
                                    }

                                  
                                            <Col span={24}>
                                                <div className="details-heading-subheading">

                                            <FormattedMessage id='modal.txDetails.inboundDelivery.inboundDeliveryDate' defaultMessage='' /> 
                                                </div>
                                            </Col>
                                            <Col span={24}>
                                                <DatePicker
                                                    allowClear={false}
                                                    format={format}
                                                    key={`${this.props.inboundDeliveryDate ? moment(new Date(this.props.inboundDeliveryDate), format) : moment(new Date(), format)}`}
                                                    defaultValue={this.props.inboundDeliveryDate ? moment(new Date(this.props.inboundDeliveryDate), format) : moment(new Date(), format)}
                                                    onChange={(selectedDate) => {
                                                        this.props.updateState({
                                                            inboundDeliveryDate: selectedDate
                                                        })
                                                        //this.props.getNextTxNumber({ date: selectedDate, relationshipId: (this.props.companyInfo || {}).relationshipId });
                                                    }}
                                                    size={size}
                                                />
                                            </Col>
                                       
                                    



                                    {/* <div className="project-dropdown">
                                        <Row>
                                            <Col span={8} className='detail-column'>
                                                <FormattedMessage id='supplier.purchaseOrder.form.projectDropdown.label' defaultMessage='' />
                                            </Col>
                                            <Col span={16}>
                                                <Dropdown
                                                    items={this.props.projectList}
                                                    valueKeyName='projectName'
                                                    value={this.props.projectName}
                                                    optionKeyName='projectMasterId'
                                                    canAddNew={true}
                                                    canDelete={true}
                                                    deleteClickHandler={(payload) => {
                                                        this.deleteClickHandler(this.props, MODAL_TYPE.PROJECT, payload);
                                                    }}
                                                    onAddNewClick={(payload) => {
                                                        payload.formData = {
                                                            projectName: payload.projectName,
                                                        }
                                                        this.handleNewProjectAddition(this.props, payload, MODAL_TYPE.PROJECT)
                                                    }}
                                                    onSelect={(projectMasterId) => {
                                                        const selectedProject = find(this.props.projectList, { projectMasterId: Number(projectMasterId) }) || {};
                                                        this.props.updateState({
                                                            projectMasterId,
                                                            projectName: selectedProject.projectName,
                                                            projectNumber: selectedProject.projectNumber
                                                        });
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                    </div> */}
                                  
                                            <Col span={24}>
                                                <div className="details-heading-subheading">
                                                            <FormattedMessage id='customer.salesOrder.form.documentDropdown.label' defaultMessage='' />
                                                    <Tooltip placement="right" title={<FormattedMessage id='documentTitle.tooltip' defaultMessage='' />}>
                                                      

                                                        <QuestionCircleTwoTone/>
                                                    </Tooltip>
                                                </div>
                                            </Col>
                                            <Col span={24}>
                                                <Dropdown
                                                    style={{ width: '100%' }}
                                                    items={filter((this.props.documentList || []), (document) => { return document.txType === CONSTANTS_TRANSACTIONS_KEY[CONSTANTS_TRANSACTIONS.INBOUND_DELIVERY]; })}
                                                    placeholder={<FormattedMessage id='addItem.text.documentName' defaultMessage='' />}
                                                    value={this.props.docName && this.props.documentNameId}
                                                    canAddNew={true}
                                                    canDelete={true}

                                                    deleteClickHandler={(payload) => {
                                                        this.deleteClickHandler(this.props, MODAL_TYPE.DOCUMENT_NAME, payload);
                                                    }}
                                                    onAddNewClick={(payload) => {
                                                        payload.formData = {
                                                            textBoxValue: payload.textEntered,
                                                            submittedOnce: false,
                                                            maxLength: 30
                                                        }
                                                        this.handleNewItemAddition(this.props, payload, MODAL_TYPE.DOCUMENT_NAME)
                                                    }}
                                                    onSelect={(selectedValue) => {
                                                        const selectedObj = find((this.props.documentList || []), { documentNameId: Number(selectedValue) });
                                                        this.props.updateState({
                                                            documentNameId: selectedValue,
                                                            docName: selectedObj.docName
                                                        })
                                                    }}
                                                    valueKeyName='docName'
                                                    optionKeyName='documentNameId'
                                                    isLockKeyName='isSystemGenerated'
                                                />
                                            </Col>
                                       
                                    {this.props.customerId ? 
                                  
                                        < >
                                            <Col span={24}>
                                                <div className="details-heading-subheading">

                                                <FormattedMessage id='common.customer.text' defaultMessage='' />
                                                </div>
                                            </Col>
                                            <Col span={24}>
                                                <div className="deatils-field-value">
                                                    {this.props.customerName || ""}
                                                </div>
                                            </Col>
                                        </>
                                    : ''
                                    }
                                </Row>
                        </Skeleton>   
                        </Col>
                    
                </Row>
                <Row>
                    <Col span={24}>
                    <Skeleton loading={this.props.detailLoading} active paragraph={{ rows: 5 }}>

                        <div className="txn-table">
                            <table>
                                <thead>
                                    <tr>
                                        {(this.props.companyInfo || {}).countryName === 'India'&&<th><FormattedMessage id="supplier.purchaseOrder.form.gridHeader.placeOfSupply" defaultMessage=""/></th>}
                                        {this.props.supplierPONumber && this.props.supplierPONumber !== '0' && <th><FormattedMessage id='supplierPONumber.text' defaultMessage='' /></th>}
                                        {this.props.customerInquiryNumber && this.props.customerInquiryNumber !== '0' && <th><FormattedMessage id='modal.txDetails.inquiry.customerInquiryNumber' defaultMessage='' /></th>}
                                        {this.props.customerPONumber && this.props.customerPONumber !== '0' && <th><FormattedMessage id='customerPONumber.text' defaultMessage='' /></th>}
                                        { this.props.customerRFQNumber && this.props.customerRFQNumber !== '0'  && <th><FormattedMessage id='supplier.rfq.form.gridHeader.customerRFQNumber' defaultMessage='' /></th>}
                                        <th><FormattedMessage id="supplier.rfq.form.gridHeader.supplierQuoteNumber" defaultMessage=""/></th>
                                        <th><FormattedMessage id="supplier.purchaseOrder.form.gridHeader.refNumber" defaultMessage=""/></th>
                                        <th><FormattedMessage id="supplier.purchaseOrder.form.gridHeader.shippingAddress" defaultMessage=""/></th>
                                        <th><FormattedMessage id="supplier.purchaseOrder.form.projectDropdown.label" defaultMessage=""/></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                     {(this.props.companyInfo || {}).countryName === 'India' && <td><Dropdown
                                        lastColumnCell= {false}
                                        value={this.props.placeOfSupply}
                                        items= {this.props.states || []}
                                        optionKeyName= 'stateId'
                                        valueKeyName= 'stateName'
                                        onSelect= {(selectedValue, optionObj) => {
                                            const state = find(this.props.states, { stateId: Number(optionObj.key) });
                                            this.props.updateState({
                                                placeOfSupply: state.stateName
                                            })
                                            //obj.node.setDataValue(obj.colDef.field, state.stateName)
                                        }}
                                        /></td>}
                                        {this.props.supplierPONumber && this.props.supplierPONumber !== '0' && <td>{this.props.supplierPONumber}</td>}
                                        {this.props.customerInquiryNumber && this.props.customerInquiryNumber !== '0' && <td>{this.props.customerInquiryNumber}</td>}
                                        {this.props.customerPONumber && this.props.customerPONumber !== '0' && <td>{this.props.customerPONumber}</td>}
                                        { this.props.customerRFQNumber && this.props.customerRFQNumber !== '0'  && <td>{this.props.customerRFQNumber}</td>}
                                        
                                        <td>
                                            {this.props.supplierQuoteNumber || '-'}
                                        </td>
                                        <td>
                                            <TextBox
                                             maxLength= {20}
                                             onChange= {(e) => {
                                                 this.props.updateState({
                                                     reference: e.target.value
                                                 });
                                             }}
                                             value={this.props.reference}
                                            />
                                        </td>
                                        <td>
                                        <Dropdown
                        lastColumnCell={false}
                        value={
                          (this.props.relationshipShippingAddress || {})
                            .streetAddress1
                        }
                        items={this.props.allRelationshipShippingAddress || []}
                        optionKeyName="locationId"
                        valueKeyName="streetAddress1"
                        addressLineKey1="streetAddress2"
                        addressLineKey2="cityName"
                        addressLineKey3="stateName"
                        addressLineKey4="zipCode"
                        isFavouriteKeyName="isDefault"
                        optionClassAddress="custom-address-dropdown"
                        canAddNew={true}
                        canDelete={true}
                        deleteClickHandler={(payload) => {
                          this.deleteClickHandler(
                            this.props,
                            MODAL_TYPE.RELATIONSHIP_SHIPPING_ADDRESS,
                            payload
                          );
                        }}
                        onAddNewClick={(payload) => {
                          payload.formData = {
                            locationType:
                              CONSTANTS.RELATIONSHIP_SHIPPING_ADDRESS,
                            addressType: "relationship",
                          };
                          this.handleNewAddressAddition(
                            this.props,
                            payload,
                            MODAL_TYPE.RELATIONSHIP_SHIPPING_ADDRESS
                          );
                        }}
                        onSelect={(selectedValue, optionObj) => {
                          //obj.node.setDataValue(obj.colDef.field, selectedValue)
                          const relationshipShippingAddress = find(
                            this.props.allRelationshipShippingAddress || [],
                            { locationId: Number(optionObj.key) }
                          );
                          this.props.updateState({
                            relationshipShippingAddress,
                            placeOfSupply: (relationshipShippingAddress || {})
                              .stateName,
                          });
                        }}
                        allowClear={true}
                        onClear={() => {
                          //obj.node.setDataValue(obj.colDef.field, undefined);
                          this.props.updateState({
                            relationshipShippingAddress: undefined,
                          });
                        }}
                      />
                                        </td>
                                        <td>
                      <Dropdown
                        items={this.props.projectList}
                        valueKeyName="projectName"
                        value={this.props.projectName}
                        optionKeyName="projectMasterId"
                        projectIdKey="projectMasterId"
                        canAddNew={true}
                        canDelete={true}
                        deleteClickHandler={(payload) => {
                          this.deleteClickHandler(
                            this.props,
                            MODAL_TYPE.PROJECT,
                            payload
                          );
                        }}
                        onAddNewClick={(payload) => {
                          payload.formData = {
                            projectName: payload.projectName,
                          };
                          this.handleNewProjectAddition(
                            this.props,
                            payload,
                            MODAL_TYPE.PROJECT
                          );
                        }}
                        onSelect={(projectMasterId, option) => {
                          const selectedProject =
                            find(this.props.projectList, {
                              projectMasterId: Number(option.key),
                            }) || {};
                          this.props.updateState({
                            projectMasterId: selectedProject.projectMasterId,
                            projectName: selectedProject.projectName,
                            projectNumber: selectedProject.projectNumber,
                          });
                        }}
                        allowClear={true}
                        onClear={() => {
                          this.props.updateState({
                            projectMasterId: undefined,
                            projectName: undefined,
                            projectNumber: undefined,
                          });
                        }}
                      />
                    </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        </Skeleton>
                    </Col>
                </Row>
                {/* <Row>
                    <CustomAgGrid
                        columnDefs={columnDefs}
                        onGridReady={onGridReady}
                        disableFocusOnClickOutside={true}
                        rowData={[{}]}
                        gridStyle={{
                            width: '100%',
                            height: '100px'
                        }} />
                </Row> */}

            </Fragment>
        );
    }
}

const getAddressInfoFragment = (obj, supplierContactObj, supplierObj) => {
    if (!obj || !Object.keys(obj).length) {
        return '';
    }

    const fragmentObj = <Fragment>
       <div className="billing-address first-caps">
        {obj.streetAddress1 || obj.streetAddress2 ? (
          <div>
            {obj.streetAddress1} {obj.streetAddress2}{", "}
          </div>
        ) : ""}
        {obj.cityName || obj.stateName || obj.zipCode ? (
          <div>
            {obj.cityName} {obj.stateName}{obj.stateName && obj.zipCode ? ", " : ""}{obj.zipCode}
          </div>
        ) : ""}
        {obj.countryName ? (
          <div>{obj.countryName}</div>
        ) : ""}
      </div>
        {/* {
            contactName
                ? <div className='billing-address'>
                    {contactName}
                </div> :
                ''
        } */}
        {
            supplierContactObj.workPhone
                ? <div className='billing-address'>
                    <FormattedMessage id='supplier.purchaseOrder.form.phone.label' defaultMessage='' /> - {supplierContactObj.workPhone}
                </div> :
                ''
        }
        {
            supplierContactObj.email
                ? <div className='billing-address'>
                    <FormattedMessage id='supplier.purchaseOrder.form.email.label' defaultMessage='' /> - {supplierContactObj.email}
                </div> :
                ''
        }
        {
            supplierObj.gstNumber
                ? <div className='billing-address'>
                    <FormattedMessage id='supplier.purchaseOrder.form.gstIn.label' defaultMessage='' /> - {supplierObj.gstNumber}
                </div> :
                ''
        }
        {
            supplierObj.panNumber
                ? <div className='billing-address'>
                    <FormattedMessage id='supplier.purchaseOrder.form.pan.label' defaultMessage='' /> - {supplierObj.panNumber}
                </div> :
                ''
        }

    </Fragment>

    return fragmentObj;
}



export default injectIntl(InboundDeliveryDetails);
