import React, { Fragment, useMemo } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
// import { AgGridReact } from "ag-grid-react";
import { Pagination, Menu, Skeleton, Empty } from "antd";
import { fetchPaginationDataIfNeeded, getPermissionData, getUserDefinedName, sortTable } from "../../../utils";
import PageBreadcrumb from "../../PageBreadcrumb";
import {ICONS, MODULE_CODED_VALUES} from '../../../static/constants';
import CourseDetail from "../../../containers/modal/modalBody/schoolManagement/CourseDetail";
import {MoreOutlined} from '@ant-design/icons';
import { Dropdown } from "../../general/Dropdown";
import DropdownAction from "antd/lib/dropdown";
import ImportCourses from "../../../containers/modal/modalBody/schoolManagement/ImportCourses";
import PageHeader from "../../Common/pageHeader";


const School = (props) => {
  const { courseList, updateState, companyInfo, courseCount, pageNumber,permissions, dir=0 } = props;
  courseList[pageNumber].sort((a, b)=> a.dateCreated < b.dateCreated ? 1 : -1); 

  // const agGridStyle = {
  //   height: "100%",
  //   width: "100%",
  // };

  const breadCrumbList = [
    {
      name: <FormattedMessage id='sidebar.menuItem.school' defaultMessage='' />
    }, 
    {
      name: <FormattedMessage id='sidebar.menuItem.curriculum' defaultMessage='' />,
    },
    {
      name: getUserDefinedName('sidebar.menuItem.course' ,props),
    }
  ];
  const primaryPerm = (permissions && permissions.primary) || [];

  const permissionsData = useMemo(()=>{
    return getPermissionData(primaryPerm, MODULE_CODED_VALUES.SCHOOL_COURSE_DETAIL )
  },[])

  const showTotal = (total) => {
    return (
      props.intl.formatMessage({
        id: "pagination.text.total",
        defaultMessage: "",
      }) +
      ` ${total || 0} ` +
      props.intl.formatMessage({
        id: "pagination.text.items",
        defaultMessage: "",
      })
    );
  };

  const loadPageData = (pageNumber, pageSize) => {
    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      relationshipEmployeeId: (props.userInfo || {}).relationshipEmployeeId,
      pageNumber: pageNumber,
      pageSize: pageSize,
    };
    fetchPaginationDataIfNeeded("getSchoolCourse", "courseList", props, payload);
  };

  const handlePageSizeChange = (pageNumber, pageSize, props) => {
    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      relationshipEmployeeId: (props.userInfo || {}).relationshipEmployeeId,
      pageNumber: pageNumber,
      pageSize: pageSize,
    };
    props.resetPaginatedData();
    props.getSchoolCourse(payload);
    props.updateState({ pageSize: pageSize, pageNumber: pageNumber });
  };

  const newCourse = (courseData) => {
    props.updateHeaderState({
      schoolDrawers: {
        ...props.schoolDrawers,
        addCourseDrawerVisible: true,
      },
      getSchoolCourse: props.getSchoolCourse,
      courseData: courseData
    });
  };

  const openCourseDetails = (data) => {
   
    props.pushModalToStack({
      modalBody: <CourseDetail {...props} courseDetail={data} />,
      width: '100%',
      hideFooter: true,
      keyboard: true,
      wrapClassName: 'modal-custom-detail'
    })
  }

  
  const sortColumn = (e) => {
    sortTable(document.getElementById('course-table'), e.target.cellIndex, 1 - dir);
    updateState({ dir: 1 - dir })
  }

  const actionMenu = (e) => {
    return (
      <Menu className="row-action-dropdown">
        <Menu.Item key="0" value="title">
          Action
        </Menu.Item>

        <Menu.Divider />

      {permissionsData.update ?  <Menu.Item key="1" value="edit" onClick={() => 
          newCourse(e)
        }
        >
          <i className={ICONS.EDIT} />
            Edit
        </Menu.Item> : "" }
        
      {permissionsData.delete ?  <Menu.Item key="2" onClick={()=>
          props.showModal({
            modalBody: "Are you sure you want to delete?",
            handleSubmit: () => {
              props.deleteCourse(e, props);
            }
          })
        }>
          <i className={ICONS.DELETE}/>
            Delete
        </Menu.Item> : "" }
      </Menu >
    )
  };

  const handleImportClick = (e) => {
    const modalData = {
      title: getUserDefinedName("Import Courses", props),
      modalBody: <ImportCourses {...props} />,
      hideFooter: true,
      width: "100%",
      handleSubmit: () => {
      },
      wrapClassName: "import-details-modal",
    };
    props.pushModalToStack(modalData);

  };

  return (
    <Fragment>
   
      <PageHeader {...props}
        pageName='sidebar.menuItem.curriculum'
        breadCrumbList={breadCrumbList}
        canCreate={true}
        onCreate={newCourse}
        moduleCode = {MODULE_CODED_VALUES.SCHOOL_COURSE_DETAIL}
      
        />
      <div className="view-container">
          <div className="view-container-actions">
          <Skeleton loading={props.headerLoading} paragraph={false} active>

            <div className="left-actions">
              <div className="table-heading">
                {getUserDefinedName('schoolManagement.course.table.heading',props)}
              </div>
              <div className="vertical-sep" />
              {
                courseCount ? 
                <>
               
                  <div>{courseCount}</div>
                  </>
                :0
              }
            </div>
            <div className="right-actions">
              <button onClick={handleImportClick}><i className={`${ICONS.DOWNLOAD} mr5`} /> Import</button>
            </div>
          </Skeleton>
          </div>
          <Skeleton loading={props.listLoading} paragraph={{ rows: 13 }} active>

          <div className="table-container">
            <table id='course-table'>
              <thead>
                <tr>
                  <th><FormattedMessage id='serial.no' defaultMessage=''/></th>
                  <th onClick={sortColumn}>{getUserDefinedName('schoolManagement.course.name',props)} <i class="fi fi-rr-sort-alt"></i></th>
                  <th onClick={sortColumn}>{getUserDefinedName('schoolManagement.course.code',props)} <i class="fi fi-rr-sort-alt"></i></th>
                  <th onClick={sortColumn}>{getUserDefinedName('modal.common.project.departmentName',props)} <i class="fi fi-rr-sort-alt"></i></th>
                  <th onClick={sortColumn}>{getUserDefinedName('subject.text',props)} <i class="fi fi-rr-sort-alt"></i></th>
                  {/* <th><FormattedMessage id="subject.text" defaultMessage="" /></th> */}
                  {permissionsData.delete || permissionsData.update ?  <th width='6%' className="text-center"><FormattedMessage id='common.listing.header.action' defaultMessage="" /></th> : "" }
                </tr>
              </thead>
              <tbody>
                {
                 (courseList[pageNumber] || [])? (courseList[pageNumber] || []).map((e, i) => {
                    return <tr key={'course-'+i}>
                      <td>{i + 1}</td>
                      <td><div className="cursor-pointer" onClick={()=> {openCourseDetails(e)}}>{e.courseName||<div className="empty-data-box"/>}</div></td>
                      <td>{e.courseCode||<div className="empty-data-box"/>}</td>
                      <td>{e.deptName||<div className="empty-data-box"/>}</td>
                      <td>{e.countSubject > 0 ? <span>{e.countSubject} {e.countSubject > 1 ? getUserDefinedName('Subjects', props) : getUserDefinedName('Subject', props)} </span> : <div className="empty-data-box"/>} {e.countCourseTerm > 0 ? <span> From {e.countCourseTerm} {e.countCourseTerm > 1 ? getUserDefinedName('Terms', props) : getUserDefinedName('Term', props)} </span> : ''}</td>
                      {/* <td>{e.countSubject} {e.countSubject > 1 ?'Subjects' : 'Subject'}</td> */}
                      {permissionsData.delete || permissionsData.update ? 
                      <td className="text-center"> 
                        <DropdownAction overlay={actionMenu(e)} trigger={['click']}>
                          <i className={ICONS.MORE} />
                        </DropdownAction>
                        
                      </td> :""}
                    </tr>
                  }) :  (<tr key="empty">
                  <td colSpan={"100%"}>
                    <Empty />
                  </td>
                </tr>)
                }
              </tbody>
            </table>
          </div>
        </Skeleton>
        <div className="footer">
        <Skeleton loading={props.headerLoading} paragraph={false} active>

          <div className="f-left">
              
          </div>
          <div className="f-right">
            <Pagination
            size="small"
            total={courseCount}
            showTotal={showTotal}
            defaultPageSize={props.pageSize}
            showSizeChanger
            pageSizeOptions={[10, 25, 50, 100, 200]}
            // showQuickJumper
            onChange={(pageNumber, pageSize) => {
              loadPageData(pageNumber, pageSize);
              updateState({ pageSize: pageSize, pageNumber: pageNumber });
            }}
            onShowSizeChange={(pageNumber, pageSize) => {
              handlePageSizeChange(pageNumber || 1, pageSize, props);
            }}
            />
          </div>
        </Skeleton>
        </div>
      </div>
    </Fragment>
  );
};

export default injectIntl(School);
