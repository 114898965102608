import React, { memo } from 'react';
import { Row, Col, Button, Tabs, Checkbox, Tooltip, Skeleton } from 'antd';
import { FormattedMessage } from 'react-intl';
import { TextBox } from '../../../../general/TextBox';
import { Dropdown } from "../../../../general/Dropdown";
import Integration from "./Integration";
import Customer from "./Customer";
import Supplier from "./Supplier";
import Inventory from "./Inventory";
import Finance from "./Finance";
import Administration from "./Administration";
import School from "./School";
import { validateEmail, formatDateForBackend } from '../../../../../utils';
import { ErrorMsg } from '../../../../general/ErrorMessage';
import { CONSTANTS } from '../../../../../static/constants';
import HRMS from "./Hrms";
import CRM from "./Crm";
import Estore from "./Estore";
import * as find from 'lodash.find';
import Dashboard from './Dashboard';
import Communication from "./Communication";

const { TabPane } = Tabs;


const ManageUser = (props) => {

    let { intl } = props;
    function callback(key) {
        if(key === "2"){
            handleNextClick(props);
        }
    }

    function callbackSubTab(key) {
       
    }

    const handleNextClick = (payload) => {
        const { password, repeatPassword, selectedEmployee= {} } = payload;
        let email = selectedEmployee.emailAddress;
        if (!payload.submittedOnce) {
            payload.updateState({ submittedOnce: true });
        }
        if (!validateEmail(email) || !password || !repeatPassword || !(password === repeatPassword)){
            props.updateState({
                activeTabKey : '1',
                submittedOnce: true,
                invalidPasswordClass: !password ? "input-text-error" : '',
                invalidRepeatPasswordClass: (!repeatPassword || !(password === repeatPassword)) ? "input-text-error" : '',
                invalidEmailClass: !validateEmail(email) ? "input-text-error" : '',
                invalidSelectClass: "select-error",
               // invalidProgramClass: (selectedEmployee.jobRoleName === 'Teacher' && !selectedForm.registrationFormSettingId) ? "input-text-error": ''
            })
        } else{
            payload.updateState({activeTabKey:'2'})
        }
    }
    
    const handleCreateUser = (payload) => {
        const { password, repeatPassword, selectedEmployee= {} } = payload;
        let email = selectedEmployee.emailAddress;
        if (!payload.submittedOnce) {
            payload.updateState({ submittedOnce: true });
        }
        if (validateEmail(email) && password && repeatPassword && password === repeatPassword) {
            const payload = {
                userId: (props.userData || {}).userId,
                version: (props.userData || {}).version,
                relationshipId: (props.companyInfo || {}).relationshipId,
                password: props.password,
                homeCountry: (props.companyInfo || {}).countryName,
                firstName: (props.companyInfo || {}).firstName,
                middleName: (props.companyInfo || {}).middleName,
                lastName: (props.companyInfo || {}).lastName,
                callName: (props.companyInfo || {}).callName,
                cellPhone: (props.companyInfo || {}).cellPhone,
                workPhone: (props.companyInfo || {}).workPhone,
                faxNumber: (props.companyInfo || {}).faxNumber,
                ...selectedEmployee,
                title: selectedEmployee.salutationName,
                email: email,
                status: CONSTANTS.STATUS_TYPES.ACTIVE,
                statusColor: "success",
                dateCreated: formatDateForBackend(new Date() ),
                notifyUser: props.notifyUser ? 1: 0,
                boUserPermissionList: [],
                // formId: selectedForm.registrationFormSettingId ||(props.userData || {}).formId,
                // formName: selectedForm.formShortName
            }
            const permissionPayload = {
                clientUserAccountId: (props.userData || {}).userId,
                relationshipId: (props.companyInfo || {}).relationshipId,
                boUserPrimaryPermission: [],
                version: props.version,
                userPermissionId: props.userPermissionId || 0
            }

            props.purchaseDataSource.forEach((e) => {
                let data = {};
                data.relationshipId = (props.companyInfo || {}).relationshipId;
                data.userPrimaryPermissionId = e.userPrimaryPermissionId;
                data.moduleCode = e.moduleCode;
                data.isDelete = e.delete ? 1 : 0;
                data.isCreate = e.create ? 1 : 0;
                data.isRead = e.view ? 1 : 0;
                data.isUpdate = e.update ? 1 : 0;
                data.version = e.version;
                permissionPayload.boUserPrimaryPermission.push(data);
            });

            props.salesDataSource.forEach((e) => {
                let data = {};
                data.relationshipId = (props.companyInfo || {}).relationshipId;
                data.userPrimaryPermissionId = e.userPrimaryPermissionId;
                data.moduleCode = e.moduleCode;
                data.isDelete = e.delete ? 1 : 0;
                data.isCreate = e.create ? 1 : 0;
                data.isRead = e.view ? 1 : 0;
                data.isUpdate = e.update ? 1 : 0;
                data.version = e.version;
                permissionPayload.boUserPrimaryPermission.push(data);
            });

            props.inventoryDataSource.forEach((e) => {
                let data = {};
                data.relationshipId = (props.companyInfo || {}).relationshipId;
                data.userPrimaryPermissionId = e.userPrimaryPermissionId;
                data.moduleCode = e.moduleCode;
                data.isDelete = e.delete ? 1 : 0;
                data.isCreate = e.create ? 1 : 0;
                data.isRead = e.view ? 1 : 0;
                data.isUpdate = e.update ? 1 : 0;
                data.version = e.version;
                permissionPayload.boUserPrimaryPermission.push(data);
            });

            props.financeDataSource.forEach((e) => {
                let data = {};
                data.relationshipId = (props.companyInfo || {}).relationshipId;
                data.userPrimaryPermissionId = e.userPrimaryPermissionId;
                data.moduleCode = e.moduleCode;
                data.isDelete = e.delete ? 1 : 0;
                data.isCreate = e.create ? 1 : 0;
                data.isRead = e.view ? 1 : 0;
                data.isUpdate = e.update ? 1 : 0;
                data.version = e.version;
                permissionPayload.boUserPrimaryPermission.push(data);
            });

            props.adminDataSource.forEach((e) => {
                let data = {};
                data.relationshipId = (props.companyInfo || {}).relationshipId;
                data.userPrimaryPermissionId = e.userPrimaryPermissionId;
                data.moduleCode = e.moduleCode;
                data.isDelete = e.delete ? 1 : 0;
                data.isCreate = e.create ? 1 : 0;
                data.isRead = e.view ? 1 : 0;
                data.isUpdate = e.update ? 1 : 0;
                data.version = e.version;
                permissionPayload.boUserPrimaryPermission.push(data);
            });

            props.integrationDataSource.forEach((e) => {
                let data = {};
                data.relationshipId = (props.companyInfo || {}).relationshipId;
                data.userPrimaryPermissionId = e.userPrimaryPermissionId;
                data.moduleCode = e.moduleCode;
                data.isDelete = e.delete ? 1 : 0;
                data.isCreate = e.create ? 1 : 0;
                data.isRead = e.view ? 1 : 0;
                data.isUpdate = e.update ? 1 : 0;
                data.version = e.version;
                permissionPayload.boUserPrimaryPermission.push(data);
            });

            props.schoolDataSource.forEach((e) => {
                let data = {};
                data.relationshipId = (props.companyInfo || {}).relationshipId;
                data.userPrimaryPermissionId = e.userPrimaryPermissionId;
                data.moduleCode = e.moduleCode;
                data.isDelete = e.delete ? 1 : 0;
                data.isCreate = e.create ? 1 : 0;
                data.isRead = e.view ? 1 : 0;
                data.isUpdate = e.update ? 1 : 0;
                data.version = e.version;
                permissionPayload.boUserPrimaryPermission.push(data);
            });

            props.crmDataSource.forEach((e) => {
                let data = {};
                data.relationshipId = (props.companyInfo || {}).relationshipId;
                data.userPrimaryPermissionId = e.userPrimaryPermissionId;
                data.moduleCode = e.moduleCode;
                data.isDelete = e.delete ? 1 : 0;
                data.isCreate = e.create ? 1 : 0;
                data.isRead = e.view ? 1 : 0;
                data.isUpdate = e.update ? 1 : 0;
                data.version = e.version;
                permissionPayload.boUserPrimaryPermission.push(data);
            });



            props.hrmsDataSource.forEach((e) => {
                let data = {};
                data.relationshipId = (props.companyInfo || {}).relationshipId;
                data.userPrimaryPermissionId = e.userPrimaryPermissionId;
                data.moduleCode = e.moduleCode;
                data.isDelete = e.delete ? 1 : 0;
                data.isCreate = e.create ? 1 : 0;
                data.isRead = e.view ? 1 : 0;
                data.isUpdate = e.update ? 1 : 0;
                data.version = e.version;
                permissionPayload.boUserPrimaryPermission.push(data);
            });

            props.eStoreDataSource.forEach((e) => {
                let data = {};
                data.relationshipId = (props.companyInfo || {}).relationshipId;
                data.userPrimaryPermissionId = e.userPrimaryPermissionId;
                data.moduleCode = e.moduleCode;
                data.isDelete = e.delete ? 1 : 0;
                data.isCreate = e.create ? 1 : 0;
                data.isRead = e.view ? 1 : 0;
                data.isUpdate = e.update ? 1 : 0;
                data.version = e.version;
                permissionPayload.boUserPrimaryPermission.push(data);
            });

            props.communicationDataSource.forEach((e) => {
                let data = {};
                data.relationshipId = (props.companyInfo || {}).relationshipId;
                data.userPrimaryPermissionId = e.userPrimaryPermissionId || 0;
                data.moduleCode = e.moduleCode;
                data.isDelete = e.delete ? 1 : 0;
                data.isCreate = e.create ? 1 : 0;
                data.isRead = e.view ? 1 : 0;
                data.isUpdate = e.update ? 1 : 0;
                data.version = e.version;
                permissionPayload.boUserPrimaryPermission.push(data);
            });

            // if(!props.isAlifApp){
            // }

            // if(props.isAlifApp){
            //     props.alifAppDataSource.forEach((e) => {
            //         let data = {};
            //         data.relationshipId = (props.companyInfo || {}).relationshipId;
            //         data.userPrimaryPermissionId = e.userPrimaryPermissionId;
            //         data.moduleCode = e.moduleCode;
            //         data.isDelete = e.delete ? 1 : 0;
            //         data.isCreate = e.create ? 1 : 0;
            //         data.isRead = e.view ? 1 : 0;
            //         data.isUpdate = e.update ? 1 : 0;
            //         data.version = e.version;
            //         permissionPayload.boUserPrimaryPermission.push(data);
            //     });
            //     props.schoolDataSource.forEach((e) => {
            //         let data = {};
            //         data.relationshipId = (props.companyInfo || {}).relationshipId;
            //         data.userPrimaryPermissionId = e.userPrimaryPermissionId;
            //         data.moduleCode = e.moduleCode;
            //         data.isDelete = e.delete ? 1 : 0;
            //         data.isCreate = e.create ? 1 : 0;
            //         data.isRead = e.view ? 1 : 0;
            //         data.isUpdate = e.update ? 1 : 0;
            //         data.version = e.version;
            //         permissionPayload.boUserPrimaryPermission.push(data);
            //     });
            //     props.alifAppAdministrationDataSource.forEach((e) => {
            //         let data = {};
            //         data.relationshipId = (props.companyInfo || {}).relationshipId;
            //         data.userPrimaryPermissionId = e.userPrimaryPermissionId;
            //         data.moduleCode = e.moduleCode;
            //         data.isDelete = e.delete ? 1 : 0;
            //         data.isCreate = e.create ? 1 : 0;
            //         data.isRead = e.view ? 1 : 0;
            //         data.isUpdate = e.update ? 1 : 0;
            //         data.version = e.version;
            //         permissionPayload.boUserPrimaryPermission.push(data);
            //     });
                
            // }

            payload.boUserPermissionList.push(permissionPayload);
            const modalData = {
                title: <div className='ant-modal-title'>Confirmation</div>,
                modalBody: "Are you sure you want to create new user?",
                handleSubmit: () => {
                    payload.relationshipEmployeeId = (props.selectedEmployee || {}).relationshipEmployeeId; 
                    props.createOrUpdteUser(payload);
                    props.hideModal();
                }
            };
            props.pushModalToStack(modalData);


        } else {
            props.updateState({
                activeTabKey : '1',
                submittedOnce: true,
                invalidPasswordClass: "input-text-error",
                invalidRepeatPasswordClass: "input-text-error",
                invalidEmailClass: "input-text-error",
               // invalidProgramClass: (selectedEmployee.jobRoleName === 'Teacher' && !selectedForm.registrationFormSettingId) ? "input-text-error": ''
            })
        }
    }
    
    let permissionToshow = props.tabsToshow;
    const ManagePermissionsTabs = memo(({
        activeTab,
        updateState,
        ...props
    }) => {
        const tabsConfig = [
            { key: "Administration_Mg", tab: <FormattedMessage id="Administration"defaultMessage='' />, component: Administration, permission: permissionToshow.administraction },
            { key: "Sales_Mg", tab: <FormattedMessage id="sidebar.menuItem.sales"defaultMessage='' />, component: Customer, permission: permissionToshow.sales },
            { key: "Purchase_Mg", tab: <FormattedMessage id="sidebar.menuItem.purchase"defaultMessage='' />, component: Supplier, permission: permissionToshow.purchase },
            { key: "Communication_Mg", tab: <FormattedMessage id="sidebar.menuItem.communication"defaultMessage='' />, component: Communication, permission: permissionToshow.purchase },
            { key: "Inventory_Mg", tab: <FormattedMessage id="sidebar.menuItem.inventory"defaultMessage='' />, component: Inventory, permission: permissionToshow.inventory },
            { key: "Finance_Mg", tab: <FormattedMessage id="sidebar.menuItem.accounting"defaultMessage='' />, component: Finance, permission: permissionToshow.finance },
            { key: "Integration_Mg", tab: <FormattedMessage id="sidebar.menuItem.integrations"defaultMessage='' />, component: Integration, permission: permissionToshow.integration },
            { key: "School_Mg", tab: <FormattedMessage id="sidebar.menuItem.school"defaultMessage='' />, component: School, permission: permissionToshow.school },
            { key: "CRM_Mg", tab: <FormattedMessage id="sidebar.menuItem.crm"defaultMessage='' />, component: CRM, permission: permissionToshow.crm },
            { key: "HRMS_Mg", tab: <FormattedMessage id="sidebar.menuItem.hrms"defaultMessage='' />, component: HRMS, permission: permissionToshow.hrms },
            { key: "E-Store_Mg", tab: <FormattedMessage id="sidebar.menuItem.eStore"defaultMessage='' />, component: Estore, permission: permissionToshow.eStore }
        ];

        const defaultActiveTab = tabsConfig.find(tab => tab.permission)?.key || "Administration_Mg";
        return (
            <Tabs
                activeKey={activeTab || defaultActiveTab}
                onChange={key => updateState({ activeTab: key })}
                type="card"
            >
                {tabsConfig.map(({ key, tab, component: Component, permission }) =>
                    permission ? (
                        <TabPane tab={tab} key={key}>
                            <Component {...props} updateState={updateState} /> 
                        </TabPane>
                    ) : null
                )}
            </Tabs>
        );
    });
    

   

    return (
        <>
            <div className="new-user-form">
                <div className="prl30">
                    {!props.isUserUpdate  ? 
                        <Tabs activeKey={props.activeTabKey || "1"} onChange={callback} type="line">
                            <TabPane tab="Add User" key="1">
                                <Skeleton loading={props.listLoading} paragraph={{ rows: 8}} active>
                                    <div className="new-user-form" style={{'minHeight': '425px'}}>
                                        <Row gutter={[16, 16]}>
                                            <Col span={12}>
                                                <FormattedMessage id='modal.settings.manageUser.employee' defaultMessage='' /> <span className="required">*</span>
                                                <Dropdown
                                                    items={props.allEmployee || []}
                                                    style={{'width': '100%'}}
                                                    optionKeyName= 'relationshipEmployeeId'
                                                    value={((props.selectedEmployee || {}).firstName || "") + ((props.selectedEmployee || {}).lastName || '')}
                                                    valueKeyName= 'firstName'
                                                    lastNameKey='lastName'
                                                    addressLineKey1='jobTitleName'
                                                    hierarchyLevelKey='hierarchyLevel'
                                                    placeholder='Please Select title'
                                                    managerKey='managerName'
                                                    canAddNew={true}
                                                    // canDelete={true}
                                                    allowClear={true}
                                                    onClear={() => { 
                                                        props.updateState({ selectedEmployee: undefined })
                                                    }}
                                                    onSelect={(employeeId, optionObj) => {
                                                            const selectedEmployee = find(props.allEmployee, { relationshipEmployeeId: Number(optionObj.key) });
                                                            props.updateState({ selectedEmployee, invalidEmailClass: '' })
                                                    }}
                                                    // deleteClickHandler={(payload) => {
                                                    // }}
                                                    onAddNewClick={(payload) => {
                                                        props.updateHeaderState({
                                                            company: {
                                                                ...props.company,
                                                                employeeDrawerVisible: true,
                                                            }
                                                        })
                                                    }}
                                                    className={props.invalidSelectClass}
                                                    validator={(val) => { return validateEmail(val); }}
                                                />
                                                <ErrorMsg
                                                    validator={() => { return (!props.submittedOnce || !!((props.selectedEmployee || {}).emailAddress) ) }}
                                                    message={<FormattedMessage id='modal.settings.manageUser.employee.requiredMessage' defaultMessage='' />}
                                                />
                                                <ErrorMsg
                                                    validator={() => { return !props.submittedOnce || !(props.selectedEmployee || {}).emailAddress || validateEmail((props.selectedEmployee || {}).emailAddress) }}
                                                    message={<FormattedMessage id='modal.settings.manageUser.employee.validationMessage' defaultMessage='' />}
                                                />
                                            </Col>

                                            <Col span={12}>
                                                <FormattedMessage id='modal.settings.manageUser.email' defaultMessage='' />
                                                <TextBox
                                                    disabled={true}
                                                    placeholder={intl.formatMessage(
                                                        { id: 'modal.settings.manageUser.email.placeholder', defaultMessage: ''  }
                                                    )}
                                                    type='text'
                                                    value={(props.selectedEmployee || {}).emailAddress}
                                                    onChange={(e) => {
                                                        props.updateState({
                                                            email: e.target.value,
                                                            invalidEmailClass: validateEmail(e.target.value) ? '' : 'input-text-error'
                                                        });
                                                    }}

                                                />
                                            </Col>

                                            <Col span={12}>
                                                <FormattedMessage id='modal.settings.manageUser.password' defaultMessage='' />  <span className="required">*</span>
                                                <TextBox
                                                    placeholder={intl.formatMessage(
                                                        { id: 'modal.settings.manageUser.password.placeholder', defaultMessage: ''  }
                                                    )}
                                                    value={props.password}
                                                    type='password'
                                                    className={props.invalidPasswordClass}
                                                    onChange={(e) => {
                                                        props.updateState({
                                                            password: e.target.value,
                                                            invalidPasswordClass: !e.target.value ? 'input-text-error' : '',
                                                            passwordMismatch: props.repeatPassword !== e.target.value,
                                                        });
                                                    }}
                                                    validator={() => { return !!props.password; }}
                                                />
                                                <ErrorMsg
                                                    validator={() => { return !props.submittedOnce || !!props.password; }}
                                                    message={<FormattedMessage id='modal.settings.manageUser.password.validationMessage' defaultMessage='' /> }
                                                />
                                            </Col>

                                            <Col span={12}>
                                                <Row>
                                                    <Col span={5}>
                                                        <div className='i-lable'>
                                                            <FormattedMessage id='modal.settings.manageUser.notifyUser' defaultMessage='' />
                                                            <Tooltip placement="top" title={<FormattedMessage id='modal.settings.manageUser.notifyUser.tooltip' defaultMessage='' />} trigger="click">
                                                                <i className="fa fa-question-circle-o pl5 cursor-pointer" />
                                                            </Tooltip>
                                                        </div>
                                                    
                                                        <span className="currency-tag" style={{ left: '10px', position: 'absolute' }}>
                                                            <Checkbox   checked={props.notifyUser} value={props.notifyUser} onChange={(e) => {
                                                                props.updateState({
                                                                    notifyUser: e.target.checked,
                                                                });
                                                            }} />
                                                        </span>
                                                    </Col>
                                                    <Col span={19}>
                                                        <div className='i-lable'>Required</div>
                                                        <TextBox
                                                            disabled={true}
                                                            placeholder={intl.formatMessage(
                                                                { id: 'modal.settings.manageUser.email.placeholder', defaultMessage: ''  }
                                                            )}
                                                            type='text'
                                                            value={(props.selectedEmployee || {}).emailAddress}
                                                            onChange={(e) => {
                                                            }}

                                                        />
                                                    </Col>
                                                </Row>

                                            </Col>

                                            <Col span={12}>
                                                <FormattedMessage id='modal.settings.manageUser.confirmPassword' defaultMessage='' />  <span className="required">*</span>
                                                <TextBox
                                                    placeholder={intl.formatMessage(
                                                        { id: 'modal.settings.manageUser.confirmPassword.placeholder', defaultMessage: ''  }
                                                    )}
                                                    type='password'
                                                    value={props.repeatPassword}
                                                    className={props.invalidRepeatPasswordClass}
                                                    validator={() => { return !!props.repeatPassword; }}
                                                    onChange={(e) => {
                                                        props.updateState({
                                                            repeatPassword: e.target.value,
                                                            invalidRepeatPasswordClass: !e.target.value || (props.password !== e.target.value) ? 'input-text-error' : '',
                                                            passwordMismatch: props.password !== e.target.value,
                                                        });
                                                    }}
                                                />
                                                <ErrorMsg
                                                    message={"Password does not match"}
                                                    validator={() => { return !props.submittedOnce || (props.repeatPassword && !props.passwordMismatch); }}
                                                />
                                            </Col>
                                        </Row>

                                        {/* {props.selectedEmployee && props.selectedEmployee.jobRoleName === 'Teacher' ?
                                            <Row>
                                                <Col span={8} className="text-right">
                                                    <FormattedMessage id='program.text' defaultMessage='' /> <span className="required">*</span>
                                                </Col>
                                                <Col span={8}>
                                                    <Dropdown
                                                        items={props.studentFormList || []}
                                                        valueKeyName='formShortName'
                                                        optionKeyName='registrationFormSettingId'
                                                        className={props.invalidProgramClass}
                                                        style={{ 'width': '99%' }}
                                                        value={(props.selectedForm || {}).formShortName}
                                                        placeholder='Please Select program'
                                                        onSelect={(optionValue, option) => {
                                                            let selectedForm = find(props.studentFormList || [], { registrationFormSettingId: option.value }) || {};
                                                            props.updateState({
                                                                selectedForm: selectedForm,
                                                            })
                                                        }}

                                                        validator={(val) => { return !!props.selectedForm; }}
                                                    />
                                                    <ErrorMsg
                                                        validator={() => { return (!props.submittedOnce || !!((props.selectedForm || {}).registrationFormSettingId)) }}
                                                        message={<FormattedMessage id='programName.required.validation' defaultMessage='' />}
                                                    />

                                                </Col>
                                            </Row> : ''
                                        } */}

                                        {/* <Row>
                                            <Col span={8} className="text-right">
                                                Title
                                </Col>
                                            <Col span={8}>
                                                <Dropdown
                                                    items={props.salutations || []}
                                                    optionKeyName='salutationId'
                                                    valueKeyName='titleName'
                                                    placeholder='Please Select title'
                                                    canAddNew={true}
                                                    canDelete={false}
                                                    onSelect={(salutationName) => {
                                                        props.updateState({ salutationName })
                                                    }}
                                                    deleteClickHandler={(payload) => {
                                                        // deleteClickHandler(props, MODAL_TYPE.SALUTATION, payload);
                                                    }}
                                                    onAddNewClick={(payload) => {
                                                        // payload.formData = {
                                                        //     textBoxValue: payload.textEntered,
                                                        //     submittedOnce: false
                                                        // }
                                                        // handleNewItemAddition(props, payload, MODAL_TYPE.SALUTATION)
                                                    }}
                                                />
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col span={8} className="text-right">
                                                First Name
                                </Col>
                                            <Col span={8}>
                                                <TextBox
                                                    placeholder="Required"
                                                    value={props.firstName}
                                                    type="text"
                                                    onChange={(e)=>{
                                                        props.updateState({
                                                            firstName: e.target.value
                                                        })
                                                    }}
                                                />
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col span={8} className="text-right">
                                                Middle Name
                                </Col>
                                            <Col span={8}>
                                                <TextBox
                                                    placeholder="Optional"
                                                    value={props.middleName}
                                                    type="text"
                                                    onChange={(e)=>{
                                                        props.updateState({
                                                            middleName: e.target.value
                                                        })
                                                    }}
                                                />
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col span={8} className="text-right">
                                                Last Name
                                </Col>
                                            <Col span={8}>
                                                <TextBox
                                                    placeholder="Required"
                                                    value={props.lastName}
                                                    type="text"
                                                    onChange={(e)=>{
                                                        props.updateState({
                                                            lastName: e.target.value
                                                        })
                                                    }}
                                                />
                                            </Col>
                                        </Row> */}

                                        {/* <Row>
                                            <Col span={8} className="text-right">
                                                Call Name
                                </Col>
                                            <Col span={8}>
                                                <TextBox
                                                    placeholder="Required"
                                                    value={props.callName}
                                                    type="text"
                                                    onChange={(e)=>{
                                                        props.updateState({
                                                            callName: e.target.value
                                                        })
                                                    }}
                                                />
                                            </Col>
                                        </Row> */}

                                        {/* <Row>
                                            <Col span={8} className="text-right">
                                                Work Phone
                                </Col>
                                            <Col span={8}>
                                                <TextBox
                                                    placeholder="Required"
                                                    value={props.workPhone}
                                                    type="text"
                                                    onChange={(e)=>{
                                                        props.updateState({
                                                            workPhone: e.target.value
                                                        })
                                                    }}
                                                />
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col span={8} className="text-right">
                                                Mobile Number
                                </Col>
                                            <Col span={8}>
                                                <TextBox
                                                    placeholder="Required"
                                                    value={props.cellPhone}
                                                    type="text"
                                                    onChange={(e)=>{
                                                        props.updateState({
                                                            cellPhone: e.target.value
                                                        })
                                                    }}
                                                />
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col span={8} className="text-right">
                                                Fax Number
                                </Col>
                                            <Col span={8}>
                                                <TextBox
                                                    placeholder="Optional"
                                                    value={props.faxNumber}
                                                    type="text"
                                                    onChange={(e)=>{
                                                        props.updateState({
                                                            faxNumber: e.target.value
                                                        })
                                                    }}
                                                />
                                            </Col>
                                        </Row> */}
                                    </div>
                                </Skeleton>
                            </TabPane>

                            {/* Inner tab view */}
                            <TabPane tab="Manage Permissions" key="2">
                                <Skeleton loading={props.listLoading} paragraph={{ rows: 8}} active>
                                    <div className="custom-tabs-components create">
                                        <ManagePermissionsTabs {...props} callbackSubTab={callbackSubTab} />
                                    </div>
                                </Skeleton>
                            </TabPane>
                        </Tabs>
                        : 
                        <div className="custom-tabs-components" style={{'minHeight': '425px'}}>
                            <ManagePermissionsTabs {...props} callbackSubTab={callbackSubTab} />
                        </div>}
                </div>
            </div>

            <div className="button-group-action mrl0 pb15" style={{marginBottom:"0"}}>
                <div className="align-footer">
                    <Button type="default" className="back-white" onClick={() => {
                        props.hideModal();
                    }}>
                        <FormattedMessage id='button.cancel.label' defaultMessage='' />
                    </Button>

                    {((props.activeTabKey && props.activeTabKey === "2") || props.isUserUpdate)?
                        <Button type="primary" onClick={() => {
                                handleCreateUser(props)
                            }
                        }>
                            <FormattedMessage id='button.save.label' defaultMessage='' />
                        </Button>
                        :
                        <Button type="primary" onClick={() => {
                                handleNextClick(props);
                            }
                        }>
                            <FormattedMessage id='button.managePermissions.nextLabel' defaultMessage='' />
                        </Button>
                    }
                </div>
            </div>
        </>
    );

}

export default ManageUser;
