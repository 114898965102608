import React, { Fragment } from "react";
import {  FormattedMessage } from "react-intl";
import { Form } from "@ant-design/compatible";
import "@ant-design/compatible/assets/index.css";
import { Drawer, Col, Row, Input, Select } from "antd";
import {  DropdownRef } from "../../general/Dropdown";
import {  Tooltip } from "antd";
import { CustomButton } from "../../general/CustomButton";
import { MODAL_TYPE } from "../../../static/constants";
import { showToasterMessage } from "../../../utils";

function TaxIdDrawer(props) {
  const { Option } = Select;

  const { getFieldDecorator } = props.form;
  const closeDrawer = () => {
    props.updateState({
      tax: {
        taxDrawer: false,
      },
    });
  };

  const tailFormItemLayout = {
    wrapperCol: {
      xs: {
        span: 24,
        offset: 0,
      },
      sm: {
        span: 24,
        offset: 0,
      },
    },
  };
  const addItemConfirmationModal = (props, text, modalType) => {
    const { pushModalToStack, companyInfo } = props;
    const labelName = (
      <FormattedMessage id="addItem.text.taxIdentification" defaultMessage="" />
    );
    const modalBody = (
      <span>
        <FormattedMessage
          id="addItem.confirmation"
          values={{ value: `${text} to ` }}
          defaultMessage={``}
        />
        {labelName} ?
      </span>
    );

    let addFunc;

    switch (modalType) {
      case MODAL_TYPE.TAX_IDENTIFICATION: {
        addFunc = props.addTaxIdentification;
        break;
      }
      default: {
        addFunc = () => {};
      }
    }

    const modalData = {
      modalBody,
      handleSubmit: () => {
        addFunc({ text: text, relationshipId: companyInfo.relationshipId });
        props.popModalFromStack();
      },
    };
    pushModalToStack(modalData);
  };

  const getModalTitle = (modalType) => {
    let title;

    switch (modalType) {
      case MODAL_TYPE.TAX_IDENTIFICATION: {
        title = (
          <FormattedMessage
            id="addItem.text.taxIdentification"
            defaultMessage=""
          />
        );
        break;
      }
      default:
        title = (
          <FormattedMessage id="signup.confirmation.title" defaultMessage="" />
        );
    }
    return title;
  };

  const handleNewItemAddition = (props, payload, modalType) => {
    const formData = payload.formData;
    const { pushModalToStack } = props;

    if (
      formData.textBoxValue &&
      formData.textBoxValue.length &&
      !payload.existsInData
    ) {
      addItemConfirmationModal(props, formData.textBoxValue, modalType);
    } else {
      const data = {
        title: getModalTitle(payload, modalType),
        formData,
        modalData: { modalType, data: payload },
        handleSubmit: (formData = {}) => {
          if (formData.textBoxValue && formData.textBoxValue.length) {
            addItemConfirmationModal(props, formData.textBoxValue, modalType);
          } else {
            data.formData.submittedOnce = true;
            props.popModalFromStack();
            pushModalToStack(data);
          }
        },
      };
      pushModalToStack(data);
    }
  };

  const deleteClickHandler = (props, modalType, payload) => {
    const { pushModalToStack, companyInfo } = props;
    const modalBody = (
      <FormattedMessage
        id="deleteItem.confirmation"
        defaultMessage=""
        values={{ value: payload.text }}
      />
    );

    let deleteFunc;

    switch (modalType) {
      case MODAL_TYPE.TAX_IDENTIFICATION: {
        deleteFunc = props.deleteTaxIdentifications;
        break;
      }
      default: {
        deleteFunc = () => {};
      }
    }

    const modalData = {
      modalBody,
      handleSubmit: () => {
        deleteFunc({
          id: payload.id,
          relationshipId: companyInfo.relationshipId,
        });
        props.popModalFromStack();
      },
    };
    pushModalToStack(modalData);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    props.form.validateFieldsAndScroll((err, values) => {

      if (!err) {
        const payload = {
          taxIdentificationType: values.type,
          taxIdentificationNumber: values.taxIdentificationNumber,
          //dateCreated: getCurrentDateForBackend(new Date()),
          relationshipId: (props.companyInfo || {}).relationshipId,
          populateOnTransaction: values.populateOnTransaction =="yes" ? 1 : 0,
          supplierTaxIdentificationId:props.isUpdate?props.supplierTaxIdentificationId:0,
          version:props.isUpdate?props.version:0
        };
        if (
          props &&
          props.formData &&
          props.formData.customerId
        ) {
          payload.customerId = props.formData.customerId;
        } else if (
          props &&
          props.formData &&
          props.formData.supplierId
        ) {
          payload.supplierId = props.formData.supplierId;
        }
        const modalData = {
          modalBody: props.formData ?"Are you sure you want to submit": "Are you sure you want to save?",
          handleSubmit: () => {
            if(props.taxIdentificationNumberDublicate){
               showToasterMessage({messageType: 'error',  description: ' Tax-Identification Number Exists!' })
            }else{

              if(props.formData.supplierId){
  
                props.createTaxIdentification(payload, (props || {}).formData,props);
                closeDrawer()
              }else{
                props.createTaxIdentification(payload, (props  || {}).formData);
              }
              props.handleSubmit && props.handleSubmit();
            }
          },
        };
        props.pushModalToStack(modalData);
      }
    });
  };
  return (
    <Fragment>
      <Drawer
        title={
          <div className="drawer-head-txt">
            {/* <FormattedMessage id={props.isEditLc ? 'purchase.landindCostSetup.drawer.title.update' : 'purchase.landindCostSetup.drawer.title.create'} defaultMessage='' /> */}

            <FormattedMessage id="addItem.text.taxIdentification" />
          </div>
        }
        width={600}
        zIndex={1}
        maskClosable={false}
        keyboard={false}
        onClose={closeDrawer}
        className="custom-drawer "
        // style={{
        //   position:'relative'
        // }}
        // afterVisibleChange={
        //   (visible) => {
        //     if (visible) {
        //       props.initCalls();
        //     }
        //   }
        // }
        visible={props?.taxDrawer}
        // footer={
        //   <>
        //   <CustomButton
        //     intlId="modal.contact.button.cancel"
        //     defaultMessage=''
        //     type='default'
        //     key={0}
        //     style={{ marginRight: 8 }}
        //     onClick={closeDrawer}
        //   />
        //   <CustomButton
        //     intlId='confirmation.submit'
        //     defaultMessage=''
        //     htmlType='submit'
        //     onClick={()=>{handleSubmit(props)}}
        //     key={1}
        //   />
        //   </>
        // }
      >
        <Form
          layout="vertical"
          // onSubmit={handleSubmit}
          hideRequiredMark={true}
          {...props}
        >
          <div className="modal-content employee-modal-control form-item-customize">
            <Form.Item
              style={{ display: "flex", flexDirection: "column" }}
              label={
                <span>
                  <FormattedMessage
                    id="modal.common.taxIdentification.taxType"
                    defaultMessage=""
                  />
                  <span className="required">*</span>
                </span>
              }
              colon={false}
            >
              {getFieldDecorator("type", {
              initialValue: props.taxIdentificationType,
                rules: [
                  {
                    required: true,
                    message: "Tax Type is required.",
                  },
                ],
              })(
                <DropdownRef
                  items={props.allTaxIdentificatinType || []}
                  valueKeyName="type"
                  optionKeyName="taxIdentificationId"
                  placeholder="Select"
                  canAddNew={true}
                  canDelete={true}
                  deleteClickHandler={(payload) => {
                    deleteClickHandler(
                      props,
                      MODAL_TYPE.TAX_IDENTIFICATION,
                      payload
                    );
                  }}
                  onAddNewClick={(payload) => {
                    payload.formData = {
                      textBoxValue: payload.textEntered,
                      submittedOnce: false,
                      maxLength: 25,
                    };
                    handleNewItemAddition(
                      props,
                      payload,
                      MODAL_TYPE.TAX_IDENTIFICATION
                    );
                  }}
                  onSelect={(selectedValue, option) => {
                    props.form.setFieldsValue({ type: option.name });
                  }}
                />
            
           
              )}
            </Form.Item>

            <Form.Item
              style={{ display: "flex", flexDirection: "column" }}
              label={
                <span>
                  <FormattedMessage
                    id="modal.common.taxIdentification.number"
                    defaultMessage=""
                  />
                  <span className="required">*</span>
                </span>
              }
              colon={false}
            >
              {getFieldDecorator("taxIdentificationNumber", {
              initialValue: props.taxIdentificationNumber,

                rules: [
                  {
                    required: true,
                    message: "Number is required.",
                    initialValue: props.taxIdentificationNumber || "",
                    maxLength: 25,
                  },
                ],
              })(
                <Input
                  placeholder="Number"
                  // {
                  //   <FormattedMessage id= "modal.common.taxIdentification.number"/>
                  // }
                  // {props.intl.formatMessage({
                  //   id: "modal.common.taxIdentification.number",
                  //   defaultMessage: "",
                  // })}
                  maxLength={25}
                  onBlur={(e) => {
                    const callback  = (val)=>{
 
                     props.updateDrawerState(
                      { 
                        taxIdentificationNumberDublicate: val ,
                        dwbdcbjkj:"sdcmdcmcdc",
                        wdinwkcwklc:"wpecwcmwckmwkcm"
                      }
                      );

                    }
                    const  payload={
                         relationshipId:props.companyInfo.relationshipId,
                         taxIdentificationNumber:props.form.getFieldValue("taxIdentificationNumber"),
                     }
                  if(props.preValue !== payload.taxIdentificationNumber){

                    props.checktaxIdentificationNumber(payload,props,"supplier",callback)
                  }
                   
                   }}
                  onChange={(e) => {
                    props.updateDrawerState({
                      taxIdentificationNumber: e.target.value,
                    });
                  }}
                  // onFocus={() => {
                  //   props.updateState({ isFocus: true });
                  // }}
                  // onBlur={() => {
                  //   props.updateState({ isFocus: false });
                  // }}
                />
              )}
            </Form.Item>
            {props.state ? (
              <div style={{ textAlign: "right" }}>
                {(props.state.taxIdentificationNumber || "").length
                  ? props.state.taxIdentificationNumber.length +
                    " / " +
                    25 +
                    " characters"
                  : ""}
              </div>
            ) : null}
            <div style={{ marginTop: "15px" }}>
              <Form.Item
                label={
                  <>
                    <span className="">
                      <FormattedMessage
                        id="modal.common.taxIdentification.populateOnTransaction"
                        defaultMessage=""
                      />
                    </span>
                    <Tooltip
                      placement="top"
                      title={
                        <FormattedMessage id="modal.common.taxIdentification.populateOnTransaction.tooltip" />
                      }
                      trigger="click"
                    >
                      <i className="fa fa-question-circle-o pl5 cursor-pointer" />
                    </Tooltip>
                  </>
                }
                colon={false}
              >
                {getFieldDecorator(
                  "populateOnTransaction",
                  {
              initialValue: props.populateOnTransaction?"Yes":"NO",
                  }
                )(
                  // <Checkbox className="login-form-checkbox"></Checkbox>
                  <Select defaultValue="Select">
                    <Option value="yes">Yes</Option>
                    <Option value="no">No</Option>
                  </Select>
                )}
              </Form.Item>
            </div>
          </div>
          <Form.Item
            {...tailFormItemLayout}
            className="modal-footer address-submit-btn"
            // style={{ width: "38%" }}
          >
            <div key={0} className="customModal-button-group">
              <CustomButton
                intlId={"modal.contact.button.cancel"}
                defaultMessage={""}
                type={"default"}
                key={1}
                onClick={() => {
                  closeDrawer();
                }}
              />
              <CustomButton
                intlId={"button.save.label"}
                defaultMessage={""}
                // htmlType="submit"
                onClick={handleSubmit}
                key={2}
              />
            </div>
          </Form.Item>
        </Form>
      </Drawer>
    </Fragment>
  );
}
export default Form.create()(TaxIdDrawer);
