import React, { Fragment } from 'react';
import ReactApexChart from "react-apexcharts"


const Finances = (props) => {
    const chartSeries = [
        {
            name: 'Income',
            data: [30, 40, 35, 90, 49, 160, 170],
        },
        {
            name: 'Expenses',
            data: [20, 70, 25, 40, 90, 150, 200],
        },
    ];
    
    const chartOptions = {
        chart: {
            type: 'line',
            toolbar: {
                show: false,
            },
        },
      
        xaxis: {
            categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul'],
        },
        yaxis: {
          
            tickAmount: 4, 
            min: 0, 
            max: 200, 
            labels: {
                formatter: (val) => val.toFixed(0), 
            },
        },
        colors: ['#0075FF', '#1CB980'],

        markers: {
            size: 0,
        },
        legend: {
            position: 'top',
            horizontalAlign: 'right',
        },
        

    };
    return<>
     <div className="coming-soon-text">Coming soon</div>
     <div className="background-blur">
    <ReactApexChart options={chartOptions} series={chartSeries} type="line" height={220} />
    </div>
    </>
};

export default Finances;