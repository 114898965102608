import React, { Fragment, useMemo } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { Checkbox, Menu, Skeleton, Pagination } from "antd";
import {
  exportToSheet,
  getMomentDateForUIReadOnly,
  getPermissionData,
  getUserDefinedName,
  showToasterMessage,
  sortTable,
} from "../../../utils";
import { CONSTANTS, ICONS, MODULE_CODED_VALUES } from "../../../static/constants";
import PageBreadcrumb from "../../PageBreadcrumb";
import ClassDetails from "./ClassDetails";
import AssignClassToTeacher from "../../../containers/modal/modalBody/schoolManagement/AssignClassToTeacher";
import DropdownAction from "antd/lib/dropdown";
// import adminAccess from '../../../assets/images/school/admin-access.png';
import { DownloadOutlined, FilterOutlined } from "@ant-design/icons";
// const format = CONSTANTS.DISPLAY_DATE_FORMAT;
import * as find from "lodash.find";

const Teacher = (props) => {
  const {
    selectedItems = [],
    teacherList,
    studentList,
    updateState,
    pageNumber,
    teacherCount = 25,
    permissions,
    dir = 0,
  } = props;
  // const agGridStyle = {
  //   height: "100%",
  //   width: "100%",
  // };

  const breadCrumbList = [
    {
      name: <FormattedMessage id="sidebar.menuItem.school" defaultMessage="" />,
    },
    {
      name: <FormattedMessage id="sidebar.menuItem.teachers" defaultMessage="" />,
    },
    {
      name: (
        <FormattedMessage
          id="sidebar.menuItem.teacherProfile"
          defaultMessage=""
        />
      ),
    },
  ];
  const primaryPerm = (permissions && permissions.primary) || [];

  const permissionsData = useMemo(()=>{
    return getPermissionData(primaryPerm, MODULE_CODED_VALUES.SCHOOL_TEACHER )
  },[])
  const itemSelection = (item) => {
    let selectItem = find(selectedItems, {
      relationshipEmployeeId: Number(item.relationshipEmployeeId),
    });
    let updatedItems = [];
    if (selectItem) {
      updatedItems = selectedItems.filter(
        (obj) => obj.relationshipEmployeeId !== item.relationshipEmployeeId
      );
    } else {
      updatedItems = JSON.parse(JSON.stringify(selectedItems));
      updatedItems.push(item);
    }
    props.updateState({ selectedItems: updatedItems });
  };

  const selectAll = (context) => {
    if (!context) {
      selectedItems.splice(0, selectedItems.length);
      props.updateState({ selectedItems: selectedItems });
      return;
    }
    for (let i = 0; i < teacherList[pageNumber].length; i++) {
      let item = teacherList[pageNumber][i];
      let selectItem = find(selectedItems, {
        relationshipEmployeeId: Number(item.relationshipEmployeeId),
      });
      if (!selectItem) {
        selectedItems.push(item);
      }
    }
    props.updateState({ selectedItems: selectedItems });
  };

  const isCheckedAll = () => {
    if (
      !props.teacherList ||
      !props.teacherList[pageNumber] ||
      props.teacherList[pageNumber].length === 0
    ) {
      return false;
    }
    for (let i = 0; i < props.teacherList[pageNumber].length; i++) {
      let usr = props.teacherList[pageNumber][i];
      let selectItem = find(selectedItems, {
        relationshipEmployeeId: Number(usr.relationshipEmployeeId),
      });
      if (!selectItem) {
        return false;
      }
    }
    return true;
  };

  const checkMenu = (e) => {
    return (
      <Menu className="row-action-dropdown">
        <Menu.Item key="0" value="title">
          Actions
        </Menu.Item>

        <Menu.Divider />

        <Menu.Item
          key="1"
          onClick={() => {
            selectAll(true);
          }}
        >
          <i className="fi fi-rs-check-circle" /> Select all
        </Menu.Item>

        <Menu.Item
          key="2"
          onClick={() => {
            selectAll(false);
          }}
        >
          <i className="fi fi-rr-cross-circle" /> Unselect all
        </Menu.Item>

        <Menu.Item
          key="3"
          onClick={() => {
            const teacherList = [];
            selectedItems.forEach((obj) => {
              teacherList.push({
                relationshipEmployeeId: obj.relationshipEmployeeId,
                relationshipId: obj.relationshipId,
                subcourseId: obj.subcourseId,
                courseId: obj.courseId,
                orgRegistrationId: obj.orgRegistrationId,
                customerId: obj.customerId,
                contactId: obj.contactId || 0,
                firstName: obj.firstName,
                lastName: obj.lastName,
              });
            });
            if (studentList.length) {
              props.updateState({
                emailDetailDrawerVisible: true,
                isSendBulkInvoice: true,
                isBulkInvoice: true,
                selectedStudentList: studentList,
                docName: "students",
              });
              // props.pushModalToStack(modalData);
            } else {
              showToasterMessage({
                messageType: "error",
                description: props.intl.formatMessage({
                  id: "common.record.notSelected.proceeding",
                }),
              });
            }
          }}
        >
          <i className="fi fi-rr-envelope" /> Send Email
        </Menu.Item>
      </Menu>
    );
  };

  // const showTotal = (total) => {
  //   return (
  //     props.intl.formatMessage({
  //       id: "pagination.text.total",
  //       defaultMessage: "",
  //     }) +
  //     ` ${total || 0} ` +
  //     props.intl.formatMessage({
  //       id: "pagination.text.items",
  //       defaultMessage: "",
  //     })
  //   );
  // };

  // const loadPageData = (pageNumber, pageSize) => {
  //   const payload = {
  //     companyInfo,
  //     relationshipId: companyInfo.relationshipId,
  //     relationshipEmployeeId: (props.userInfo || {}).relationshipEmployeeId,
  //     pageNumber: pageNumber,
  //     pageSize: pageSize,
  //   };
  //   fetchPaginationDataIfNeeded("getTeacher", "teacherList", props, payload);
  // };

  // const handlePageSizeChange = (pageNumber, pageSize, props) => {
  //   const payload = {
  //     companyInfo,
  //     relationshipId: companyInfo.relationshipId,
  //     relationshipEmployeeId: (props.userInfo || {}).relationshipEmployeeId,
  //     pageNumber: pageNumber,
  //     pageSize: pageSize,
  //   };
  //   props.resetPaginatedData();
  //   props.getTeacher(payload);
  //   props.updateState({ pageSize: pageSize, pageNumber: pageNumber });
  // };

  //   const handleNewContactAddition = (contactData, props) => {
  //     const { pushModalToStack, companyInfo } = props;
  //     let payload = {};
  //     let formData = {
  //         customerId: props.customerId,
  //         contactData,
  //         isTeacher: true
  //         // pageNumber: pageNumber || 1,
  //         // relationshipId: companyInfo.relationshipId
  //     }

  //     formData.callback = function (contact) {
  //         props.fetchAllContacts({
  //             companyInfo: props.companyInfo,
  //             relationshipId: props.companyInfo.relationshipId,
  //         })
  //     }
  //     payload.formData = formData;
  //     const data = {
  //         title: <FormattedMessage id='sidebar.menuItem.teachers' defaultMessage='' />,
  //         formData,
  //         hideFooter: true,
  //         modalData: {
  //             modalType: MODAL_TYPE.CONTACT,
  //             data: payload,

  //         },
  //         handleSubmit: (formData = {}) => {
  //             data.formData.submittedOnce = true;
  //         }
  //     };
  //     pushModalToStack(data);
  // }

  const exportExcelData = (selectedTeachersList, fileFormat) => {
    const dataList = (selectedTeachersList || []).map((item, i) => {
      return {
        "S.No": i + 1 || "",
        "Title": item.salutationName,
        "Teacher Name": `${item.firstName} ${item.lastName || ""}`,
        "Email": item.emailAddress || "",
        "Job Role ": item.jobRoleName || "",
        [getUserDefinedName('Class Count', props)]: (item.instructorClassRefList || []).length,
        "Date Created": getMomentDateForUIReadOnly({
          date: item.dateCreated,
          format: CONSTANTS.TABLE_DATE_FORMAT,
        }),
      };
    });
    // exportExcel(dataList, "teachers");
    exportToSheet(dataList, "teacher_data", fileFormat, {});
  };

  const newEmployee = (employeeData) => {
    // props.updateHeaderState({
    //   company: {
    //     ...props.company,
    //     employeeDrawerVisible: true,
    //     isRoleTeacher: true,
    //     employeeData: employeeData,
    //   },
    // });
    props.updateState({
      employeeDrawer: true,
      empInfo: employeeData,
      isRoleTeacher: true,


    })
  };

  const openClassDetailPopup = (instructorObj) => {
    const modalData = {
      title: getUserDefinedName("sidebar.menuItem.classDetails", props),
      modalBody: <ClassDetails {...props} instructorObj={instructorObj} />,
      width: "50%",
      hideFooter: true,
    };

    props.pushModalToStack(modalData);
  };

  const openAssignClass = (data) => {
    props.pushModalToStack({
      title: (
        <div>
          {getUserDefinedName("classAssignment.text", props)}
          <h6>{`${data.firstName}${
            data.lastName ? " " + data.lastName : ""
          }`}</h6>
        </div>
      ),
      modalBody: <AssignClassToTeacher {...props} teacherDetail={data} />,
      width: "50%",
      hideFooter: true,
      keyboard: true,
    });
  };

  const actionMenu = (item) => {
    return (
      <Menu className="row-action-dropdown">
        <Menu.Item key="0" value="title">
          Action
        </Menu.Item>
        <Menu.Divider />
        {permissionsData.update ? <Menu.Item
          key="4"
          value="portalAccess"
          onClick={() => accessAccount(item)}
        >
          <i class="fi fi-rr-user-gear" /> Portal Access
        </Menu.Item> :""}
        {permissionsData.update ?  <Menu.Item
          key="1"
          value="assignClass"
          onClick={() => openAssignClass(item)}
        >
          <i className="fi fi-rr-pen-field" />{" "}
          {getUserDefinedName("Assign Class", props)}
        </Menu.Item> : "" }

        {permissionsData.update ? <Menu.Item key="2" value="update" onClick={() => newEmployee(item)}>
          <i className={ICONS.EDIT} /> Edit
        </Menu.Item> : "" }

        {permissionsData.delete ?<Menu.Item
          key="3"
          value="delete"
          onClick={() => {
            props.showModal({
              modalBody: "Are you sure you want to delete?",
              handleSubmit: () => {
                props.deleteEmployee({ ...item, isTeacher: true }, props);
              },
            });
          }}
        >
          <i class="fi fi-rr-trash" /> <FormattedMessage id="delete.text" />
        </Menu.Item> : "" }

      </Menu>
    );
  };

  const sortColumn = (e) => {
    sortTable(
      document.getElementById("teachers-table"),
      e.target.cellIndex,
      1 - dir
    );
    updateState({ dir: 1 - dir });
  };

  const downloadMenu = () => {
    return (
      <Menu className="row-action-dropdown">
        <Menu.Item key="0" value="title">
          Download as
        </Menu.Item>

        <Menu.Divider />

        {/* <Menu.Item
          key="1"
          value="pdf"
          onClick={() => {
            const payload = {
              companyInfo,
              relationshipId: companyInfo.relationshipId,
              formId: (props.selectedForm || {}).registrationFormSettingId || 0,
              relationshipEmployeeId: userInfo.relationshipEmployeeId,
            };
              exportToSheet(payload, props);
          }}
        >
          <FilePdfOutlined />
          CSV File
        </Menu.Item> */}

        <Menu.Item
          key="1"
          value="csv"
          onClick={() => {
            const teacherList = [];
            // props.gridApi.forEachNode(obj => {
            //     if (obj.data.isChecked) {
            //         teacherList.push(obj.data);
            //     }
            // });
            selectedItems.forEach((obj) => {
              teacherList.push(obj);
            });

            if (teacherList.length) {
              exportExcelData(teacherList, "csv");
            } else {
              showToasterMessage({
                messageType: "error",
                description: props.intl.formatMessage({
                  id: "common.record.notSelected.proceeding",
                }),
              });
            }
          }}
        >
          <i className={ICONS.CSV} /> CSV File
        </Menu.Item>

        <Menu.Item
          key="5"
          value="exportToExcel"
          onClick={() => {
            const teacherList = [];
            selectedItems.forEach((obj) => {
              teacherList.push(obj);
            });

            if (teacherList.length) {
              exportExcelData(teacherList, "xls");
            } else {
              showToasterMessage({
                messageType: "error",
                description: props.intl.formatMessage({
                  id: "common.record.notSelected.proceeding",
                }),
              });
            }
          }}
        >
          <i className={ICONS.EXCEL} /> Export to Excel
        </Menu.Item>
      </Menu>
    );
  };

  const accessAccount = (e) => {
    // props.getAccessToken({
    //   relationshipId: companyInfo.relationshipId,
    //   relationshipEmployeeId: (props.userInfo || {}).relationshipEmployeeId,
    //   clientOnlineUserAccountId: e.clientOnlineUserAccountId
    // }, props);
    let url = `${props.subdomainName}/app?at=${e.relationshipId}_${e.relationshipEmployeeId}_${e.emailAddress}`;
    Object.assign(document.createElement("a"), {
      target: "_blank",
      rel: "noopener noreferrer",
      href: url,
    }).click();
  };

  return (
    <Fragment>
      <div className="view-top-bar">
        <div className="top-view-left">
          <div className="page-heading">
            <FormattedMessage
              id="sidebar.menuItem.teachers"
              defaultMessage=""
            />
          </div>
          <div className="vertical-sep" />
          <PageBreadcrumb breadCrumbList={breadCrumbList} />
        </div>
        <div className="top-view-right">
          {/* <button
            className="create-action-btn"
            onClick={() => {
              newEmployee();
            }}
          >
            <i className="fi fi-br-plus mr5" />
            <FormattedMessage id="button.create.label" defaultMessage="" />
          </button> */}
        </div>
      </div>
      <div className="view-container">

        <div className="view-container-actions">
      <Skeleton loading={props.headerLoading} paragraph={false} active>
          <div className="left-actions">
            <div className="table-heading">
              <FormattedMessage
                id="schoolManagement.teacher.table.heading"
                defaultMessage=""
              />
            </div>
            <div className="vertical-sep" />
            {teacherCount ? (
              <>
                
                <div>{teacherCount}</div>
              </>
            ) : (
              ""
            )}
          </div>
          <div className="right-actions">
            <button
              className="action-btn"
              onClick={() => {
                props.updateState({
                  teacherListFilterDrawerVisible: true,
                });
              }}
            >
              <FilterOutlined /> &nbsp;
              <FormattedMessage id="filter.text" defaultMessage="" />
            </button>
            <DropdownAction overlay={downloadMenu()} trigger={["click"]}>
              <span>
                <DownloadOutlined /> &nbsp; Download as
              </span>
            </DropdownAction>
          </div>
          </Skeleton>
        </div>
        {/* <Skeleton loading={props.isLoading && !props.company?.employeeDrawerVisible}> */}
        <Skeleton loading={props.listLoading} paragraph={{ rows: 16 }} active>

          <div className="table-container">
            <table id="teachers-table">
              <thead>
                <tr>
                  <th width="5%">
                    <Checkbox
                      onChange={(e) => {
                        selectAll(e.target.checked);
                      }}
                      checked={isCheckedAll()}
                    />{" "}
                    &nbsp;
                    <DropdownAction overlay={checkMenu()} trigger={["click"]}>
                      <i className="fi fi-rr-angle-down" />
                    </DropdownAction>
                  </th>
                  <th>
                    <FormattedMessage id="serial.no" />
                  </th>
                  {/* <th onClick={sortColumn}><FormattedMessage id='accessAccount.text' defaultMessage="" /> <i class={ICONS.SORT}></i></th> */}
                  {/* <th onClick={sortColumn}>
                    <FormattedMessage id="contact.title" defaultMessage="" />{" "}
                    <i className={ICONS.SORT}></i>
                  </th> */}
                  <th onClick={sortColumn}>
                    <FormattedMessage id="teacher.name" defaultMessage="" />{" "}
                    <i className={ICONS.SORT}></i>
                  </th>
                  {/* <th><FormattedMessage id='contact.lastName' defaultMessage="" /></th> */}
                  <th onClick={sortColumn}>
                    <FormattedMessage id="contact.email" defaultMessage="" />{" "}
                    <i className={ICONS.SORT}></i>
                  </th>
                  {/* <th><FormattedMessage id='contact.phone' defaultMessage="" /></th> */}
                  {/* <th><FormattedMessage id='company.employee.jobTitle' defaultMessage="" /></th> */}
                  <th onClick={sortColumn}>
                    <FormattedMessage id="jobRole.text" defaultMessage="" />{" "}
                    <i className={ICONS.SORT}></i>
                  </th>
                  <th onClick={sortColumn}>
                    {getUserDefinedName("Class Count", props)}{" "}
                    <i className={ICONS.SORT}></i>
                  </th>
                  <th onClick={sortColumn}>
                    <FormattedMessage
                      id="common.dateCreated"
                      defaultMessage=""
                    />{" "}
                    <i className={ICONS.SORT}></i>
                  </th>
                  {permissionsData.delete || permissionsData.update ?    <th className="text-center">
                    <FormattedMessage
                      id="common.listing.header.action"
                      defaultMessage=""
                    />
                  </th> : "" }
                </tr>
              </thead>
              <tbody>
                {(teacherList[pageNumber] || []).map((e, i) => {
                  return (
                    <tr key={"teacher" + i}>
                      <td>
                        <Checkbox
                          onClick={() => {
                            itemSelection(e);
                          }}
                          checked={
                            find(selectedItems, {
                              relationshipEmployeeId: Number(
                                e.relationshipEmployeeId
                              ),
                            })
                              ? true
                              : false
                          }
                        />
                      </td>

                      <td width={"5%"}>{i + 1}</td>
                      {/* <td><Tooltip placement="topLeft" title={`Access ${e.firstName} Account`}>
                        <img src={adminAccess} style={{ 'height': '25px', 'width': '25px' }} className="cursor-pointer" alt="Access Account" onClick={() => { accessAccount(e); }} />
                      </Tooltip>
                    </td> */}

                      {/* <td width={"5%"}>{e.salutationName}</td> */}
                       <td>{e.fullName || <div className="empty-data-box"/>}</td>
                      {/* <td>{e.lastName}</td> */}
                      <td>{e.emailAddress||<div className="empty-data-box"/>}</td>
                      {/* <td>{e.workPhone}</td> */}
                      <td>{e.jobRoleName||<div className="empty-data-box"/>}</td>
                      <td>
                        {(e.instructorClassRefList || []).length ? (
                          <span
                            onClick={() => {
                              openClassDetailPopup(e);
                            }}
                            className="cursor-pointer"
                          >
                            {(e.instructorClassRefList || []).length}
                          </span>
                        ) : (
                          <div className="empty-data-box"/>
                        )}
                      </td>

                      <td>
                        {e.dateCreated
                          ? getMomentDateForUIReadOnly({
                              date: new Date(e.dateCreated),
                              format: CONSTANTS.TABLE_DATE_FORMAT,
                            })
                          : <div className="empty-data-box"/>}
                      </td>
                      { (permissionsData.delete || permissionsData.update) ?
                      <td className="text-center">
                        {/* <span className="cursor-pointer" onClick={() => { openAssignClass(e) }}>Assign Class</span> |
                      <span style={{ fontSize: '20px', marginLeft: '10px', cursor: 'pointer' }} onClick={() => {
                          newEmployee(e);
                        }}><i className='fa fa-pencil' /></span> | 
                      <span style={{ fontSize: '20px', marginLeft: '10px', cursor: 'pointer' }} onClick={() => {
                        props.showModal({
                          modalBody: "Are you sure you want to delete?",
                          handleSubmit: () => {
                            //props.getTeacher(e, props);
                            props.deleteEmployee({...e, isTeacher: true}, props);
                          }
                        })
                      }}>
                        <i className="fa fa-trash" title={props.intl.formatMessage({ id: 'delete.text' })} />
                      </span> */}
                    
                        <div className="action-icon">
                          <DropdownAction
                            overlay={actionMenu(e)}
                            trigger={["click"]}
                          >
                            <i className="fi fi-rs-menu-dots-vertical" />
                          </DropdownAction>
                        </div>
                      </td>
                      :""}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </Skeleton>
        <div className="footer">
      <Skeleton loading={props.headerLoading} paragraph={false} active>

          <div className="f-left"></div>
          <div className="f-right">
            <Pagination
              size="small"
              total={teacherCount}
              // showTotal={showTotal}
              defaultPageSize={props.pageSize}
              showSizeChanger
              pageSizeOptions={[10, 25, 50, 100, 200]}
              onChange={(pageNumber, pageSize) => {
                // loadPageData(pageNumber, pageSize);
                // updateState({ pageSize: pageSize, pageNumber: pageNumber });
              }}
              onShowSizeChange={(pageNumber, pageSize) => {
                // handlePageSizeChange(pageNumber || 1, pageSize, props);
              }}
            />
          </div>
        </Skeleton>
        </div>
      </div>
    </Fragment>
  );
};

export default injectIntl(Teacher);
