import React, { Fragment } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import PageBreadcrumb from "../../PageBreadcrumb";
import {
  checkACLPermission,
  getMomentDateForUIReadOnly,
  sortTable,
} from "../../../utils";
import * as find from "lodash.find";
import { Dropdown } from "../../general/Dropdown";
import { CONSTANTS, MODULE_CODED_VALUES, PERMISSION_VALUES } from "../../../static/constants";


const MyLeadList = (props) => {
  const {
    pageNumber = 0,
    dir=0,permissions
  } = props;
 
  const primaryPerm = (permissions && permissions.primary) || [];
 
  const breadCrumbList = [
    {
      name: <FormattedMessage id="sidebar.menuItems.Leads" defaultMessage="" />,
    },
    {
      name: <FormattedMessage id="sidebar.menuItems.MyLeads" defaultMessage="" />,
    },
  ];

  const sortColumn = (e) => {
    //sortTable(document.getElementById('invoiceTable'), e.target.parentElement.cellIndex, 1 - dir);
    let cellIndex = e.target.cellIndex;
    if (!cellIndex) {
      cellIndex = e.target.parentElement.cellIndex;
    }
    if (!cellIndex) {
      cellIndex = e.target.parentElement.parentElement.cellIndex;
    }
    sortTable(document.getElementById("student-table"), cellIndex, 1 - dir);
    // updateState({ dir: 1 - dir })
  };

  // const showTotal = (total) => {
  //   return (
  //     props.intl.formatMessage({
  //       id: "pagination.text.total",
  //       defaultMessage: "",
  //     }) +
  //     ` ${total || 0} ` +
  //     props.intl.formatMessage({
  //       id: "pagination.text.items",
  //       defaultMessage: "",
  //     })
  //   );
  // };


  // const actionMenu = (item) => {
  //   return (
  //     <Menu className="row-action-dropdown">
  //       <Menu.Item key="0" value="title">
  //         Actions
  //       </Menu.Item>
  //       <Menu.Divider />

  //       <Menu.Item key="2" value="update" onClick={() => {
                    
  //                   }}>
  //         <EditOutlined /> Edit
  //       </Menu.Item>
  //     </Menu>
  //   );
  // };

  const handleRowClick = (e) => {
    // setRowId(e.crmLeadId);
    // props.updateState({
    //   activeRowData: e,
    //   activeRowId: e.crmLeadId,
    // });
  };

  const getLabelValue = (item, label) => {
    if (!item || !item.crmLeadDetailsList || !item.crmLeadDetailsList.length || !label) {
      return '-';
    } else {
      const lblObj = find(item.crmLeadDetailsList, { label: label }) || {};
       return lblObj.value || '-';
    }
  };

  const getFilteredList = (allLeads) =>{
    let tempList = [];
    for(let i=0; i<allLeads.length; i++){
      for(let j=0; j<(allLeads[i]?.crmLeadEmpAssignedList || []).length; j++){
        if(allLeads[i]?.crmLeadEmpAssignedList[j]?.relEmpId === props.userInfo.relationshipEmployeeId){
          tempList.push(allLeads[i]);
          break;
        }
      }
    }
    return tempList;
  }
  // const myLeads = props.allLeadList[pageNumber].crmLeadEmpAssignedList


  return (
    <Fragment>
      <div className="lead-list">
        <div className="lead-list-page">
          <div className="view-top-bar">
            <div className="top-view-left">
              <div className="page-heading">
                <FormattedMessage
                  id="sidebar.menuItems.Leads"
                  defaultMessage=""
                />
              </div>
              <div className="vertical-sep" />
              <PageBreadcrumb breadCrumbList={breadCrumbList} />
            </div>
            <div className="top-view-right">
            </div>
          </div>
          <div className="view-container">
            <div className="view-container-actions">
              <div className="left-actions">
                <div className="table-heading">
                  <FormattedMessage
                    id="sidebar.menuItems.MyLeads"
                    defaultMessage=""
                  />
                </div>
              </div>

              <div className="right-actions"></div>
            </div>
            <div className="table-container">
              <table id="my-leads-table">
                <thead>
                  <tr>
                    <th>
                      S.No.
                    </th>
                    <th onClick={sortColumn}>
                      Date Created <i class="fi fi-rr-sort-alt"></i>
                    </th>
                    <th onClick={sortColumn}>
                      Form Name <i class="fi fi-rr-sort-alt"></i>
                    </th>

                    <th onClick={sortColumn}>
                      Full Name <i class="fi fi-rr-sort-alt"></i>
                    </th>
                    <th onClick={sortColumn}>
                      Mobile No. <i class="fi fi-rr-sort-alt"></i>
                    </th>

                    <th onClick={sortColumn}>
                      Email <i class="fi fi-rr-sort-alt"></i>
                    </th>

                    <th onClick={sortColumn}>
                      Source <i class="fi fi-rr-sort-alt"></i>
                    </th>

                    <th>
                      Status
                    </th>
                    {/* <th style={{minWidth: '250px'}}>
                      Assign to
                    </th> */}
      
                  </tr>
                </thead>
                <tbody>
                  {(getFilteredList(props.allLeadList[pageNumber || 1]) || []).map((e, i) => {
                    return (
                      <tr key={"std" + i}>
                        <td>{i+1}</td>
                        <td className="one-line-view" onClick={() => handleRowClick(e)} >{e.dateCreated ? getMomentDateForUIReadOnly({date: e.dateCreated, format: CONSTANTS.TABLE_DATE_FORMAT}) : '-'}</td>
                        <td  onClick={() => handleRowClick(e)} >{e.formName || "-"}</td>
                        <td onClick={() => handleRowClick(e)} >{getLabelValue(e,'Full Name')}</td>
                        <td onClick={() => handleRowClick(e)}>{getLabelValue(e,'Mobile No.')}</td>
                        <td onClick={() => handleRowClick(e)}>{getLabelValue(e,'Email')}</td>
                        <td >
                          {e.leadSourceName || '-'}
                        </td>
                        {/* <td onClick={() => handleRowClick(e)}>{e.source_referral_url || "-"}</td> */}
                        <td>
                          <Dropdown
                            style={{width: '200px', maxHeight: '38px'}}
                            items={props.crmLeadStatusList}
                            placeholder={'Select Status'}
                            valueKeyName='statusName'
                            optionKeyName='leadStatusId'
                            value={e.statusName}
                            onSelect={(id, options)=>{
                              let payload = e;
                              payload.statusId = options.key;
                              payload.statusName = options.name;
                              payload.relEmpId= props.userInfo.relationshipEmployeeId
                              props.changeLeadStatus(payload, props)
                            }}
                          />
                        </td>
                        {/* <td>
                          <MultipleSelectDropdown
                            style={{minWidth: '200px', maxHeight: '38px'}}
                            items={props.leadAssignList || []}
                            placeholder={'Assign to'}
                            mode='multiple'
                            allowClear={false}
                            // onDeSelect={(empId, options) => {
                            //     let tempList = props.selectedAssignList || [];
                            //     const empExits = tempList.find((obj)=> obj.relEmployeeId === options.key)
                            //     if(empExits){
                            //         tempList = tempList.filter((obj) => obj.relEmployeeId !== options.key);
                            //         props.updateState({selectedAssignList: tempList});
                            //     }
                            // }}
                            selectedValue={e.crmLeadEmpAssignedList.map((obj) => {return obj.relEmpId})}
                            valueKeyName='fullName'
                            valueKeyName2='hierarchyName'
                            optionKeyName='relationshipEmployeeId'
                            onSelect={(empId, options) => {
                              let tempList = [];
                              tempList.push({relEmpId: Number(options.key), relationshipId: props.companyInfo.relationshipId, crmLeadId: e.crmLeadId});
                              props.changeCrmLeadEmpAssignedList(tempList, props);
                            }}
                          />
                        </td> */}
 
                      </tr>
                    );
                    // })
                  })}
                </tbody>
              </table>
            </div>
            <div className="footer">
              <div className="f-left"></div>
              <div className="f-right">
                {/* <Pagination
                  size="small"
                  total={studentCount}
                  showTotal={showTotal}
                  defaultPageSize={props.pageSize}
                  showSizeChanger
                  pageSizeOptions={[10, 25, 50, 100, 200]}
                  // showQuickJumper
                  onChange={(pageNumber, pageSize) => {
                    loadPageData(pageNumber, pageSize);
                    updateState({ pageSize: pageSize, pageNumber: pageNumber });
                  }}
                  onShowSizeChange={(pageNumber, pageSize) => {
                    handlePageSizeChange(pageNumber || 1, pageSize, props);
                  }}
                /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default injectIntl(MyLeadList);
