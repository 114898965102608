import React, { useState, useEffect } from 'react';
//import { LoadScript, GoogleMap, Marker, Circle, OverlayView } from '@react-google-maps/api';
import config from '../../../../../config/environmentConfig';
import { showToasterMessage } from '../../../../../utils';

const MapComponent = (props) => {
  const [initialPosition, setInitialPosition] = useState(props.value || { lat: 0, lng: 0 });
  const [markerPosition, setMarkerPosition] = useState(null);
  const [address, setAddress] = useState('');

  const mapStyles = {        
    height: "50vh",
    width: "100%" };
  
  const defaultCenter = {
    lat: initialPosition.lat, 
    lng: initialPosition.lng
  };

  const success = (position) => {
    const initialPosition = {
      lat: position.coords.latitude,
      lng: position.coords.longitude
    };

    if(props.onChange){
      props.onChange(initialPosition);
    }
    setInitialPosition(initialPosition);
    setMarkerPosition(initialPosition);
    fetchAddress(initialPosition);
  };        

  const haversineDistance = (coords1, coords2) => {
    const toRad = (x) => (x * Math.PI) / 180;

    const lat1 = coords1.lat;
    const lon1 = coords1.lng;
    const lat2 = coords2.lat;
    const lon2 = coords2.lng;

    // this is radius of earth 
    const R = 6371; 
     
    // this is done to find the dia... of the selected location
    const dLat = toRad(lat2 - lat1);
    const dLon = toRad(lon2 - lon1);

    // formula for cal,, area
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(toRad(lat1)) * Math.cos(toRad(lat2)) *
      Math.sin(dLon / 2) * Math.sin(dLon / 2);

    // formula for cal,, circumfi.....(ignore spelling error)

    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));


    // gives distance in meter 
    const distance = R * c * 1000;
    return distance;
  };

  const handleMapClick = (event) => {
    const clickedPosition = {
      lat: event.latLng.lat(),
      lng: event.latLng.lng()
    };

    const distance = haversineDistance(initialPosition, clickedPosition);
    if (distance <= (props.radius || 200)) {
      setMarkerPosition(clickedPosition);
      fetchAddress(clickedPosition);
      if (props.onChange) {
        props.onChange(clickedPosition);
      }
    } else {
      showToasterMessage({
        description: `You can only select a location within ${props.radius || 200} meters of the initial position.`,
        messageType: 'error'
      });
    }
  };

  const fetchAddress = (position) => {
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ location: position }, (results, status) => {
      if (status === 'OK' && results[0]) {
        props.updateState({
          selectedAddress: results[0].formatted_address
        });
        setAddress(results[0].formatted_address);
      } else {
        props.updateState({
          selectedAddress: ""
        });
        setAddress('Address not found');
      }
    });
  };

  const handleFindMyLocation = () => {
    navigator.geolocation.getCurrentPosition(success, (error) => {
      showToasterMessage({
        description: "Unable to fetch your location. Please check your location settings.",
        messageType: 'error'
      });
    });
  };

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(success);
  }, []);

  return (
    <div style={{width:"80%"}}>

    {/* <LoadScript googleMapsApiKey={config.GOOGLE_API_KEY}>
      <GoogleMap
        mapContainerStyle={mapStyles}
        zoom={18}
        center={defaultCenter}
        onClick={handleMapClick}>
        {
          initialPosition.lat !== 0 &&
          <>
            <Marker position={markerPosition} />
            <Circle 
              center={initialPosition}
              radius={props.radius || 200}
              options={{
                fillColor: "rgba(135,206,250,0.5)",
                strokeColor: "rgba(0,191,255,0.8)",
                strokeOpacity: 0.8,
                strokeWeight: 2,
                clickable: false,
                draggable: false,
                editable: false,
                visible: true,
                zIndex: 1
              }}
            />
          
          </>
        }
      </GoogleMap>
      <div className='address-area'>
        <div>
        <h2>Selected Address:</h2>
        <p>{address}</p>

        </div>
        
      <button onClick={handleFindMyLocation} className="find-location-button">
                Find Me
              </button>
      </div>
    </LoadScript> */}
    </div>
  );
}

export default MapComponent;
