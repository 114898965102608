import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { showModal, hideModal } from "../../../../../actions/commonActions";
import { pushModalToStack } from "../../../../../actions/commonActions";
import { updateOrCreateEcomSettings } from "../../../../eStore/menus/action";
import { fetchE_StoreData } from "../../../../../actions/commonActions";
import { savePromoHubOffers } from "./action";
import PromoHubComp from "../../../../../components/modal/modalBody/eStore/PromoHubManagement";
class PromoHub extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isHeaderMenuLoaded: false,
      isOfferShown: false,
      offerDescription: "",
      link: "",
    };
  }
  componentDidMount() {
    const companyInfo = this.props.userInfo || {};
    const payload = {
      relationshipId: companyInfo.relationshipId,
    };

    this.props.fetchE_StoreData(payload);
  }
  componentWillReceiveProps(props) {
    if (
      !this.state.isHeaderMenuLoaded &&props.storeValues &&
      Object.keys(props.storeValues || {})?.length
    ) {
      let promoHub = JSON.parse(props?.storeValues?.welcomeTray || "{}");

      this.setState({
        isOfferShown: promoHub?.isOfferShown,
        offerDescription: promoHub?.offerDescription,
        link: promoHub?.link,
        isHeaderMenuLoaded: true,
      });
    }
  }

  render() {
    return (
      <PromoHubComp
        {...this.props}
        {...this.state}
        updateState={(data) => {
          this.setState(data);
        }}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    userInfo: state.common.userInfo,
    relationshipId: state.common.companyInfo.relationshipId,
    storeValues: state.common.store_values,

    permissions: state.auth.permissions,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      pushModalToStack,
      updateOrCreateEcomSettings,
      fetchE_StoreData,
      savePromoHubOffers,
      showModal,
      hideModal,
    },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(PromoHub);
