import React from 'react';
import axios from 'axios';
import config from '../../../config/environmentConfig';
import { COMMON_ACTIONS, CONSTANTS, PRODUCT_ACTION_LIST } from '../../../static/constants';
import { getFinancialYear, showToasterMessage } from '../../../utils';
import download from 'downloadjs'
import filter from 'lodash.filter';
const { lang } = require('../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');

export const fetchProducts = (payload, history) => {

  const searchedTextQuery = payload.searchedText && payload.searchedText.length ? `&searchString=${payload.searchedText}` : '';
    
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LIST_LOADER });

    if((payload.hideLoder)) {
     // dispatch({ type: COMMON_ACTIONS.SHOW_LOADER })
   }else{
     dispatch({ type: COMMON_ACTIONS.SHOW_LOADER })
 
   } 
    let url = (payload.searchedText && payload.searchedText.length)  ? '/inventoryItem/getAllInventoryItems' : '/inventoryItem/getInventorySummary';
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}${url}?relationshipId=${payload.relationshipId}&warehouseMasterId=0&pageSize=${payload.pageSize || 10}&pageNumber=${(payload.pageNumber || 1) - 1}${searchedTextQuery}`
      )
      .then(res => {
        const data = {
          pageNo: payload.pageNumber,
          list: res.data
        }
        dispatch({ type: PRODUCT_ACTION_LIST.PRODUCT_LIST, data });
      })
      .catch((err) => {
        if(!err.__isRetryRequest){
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        }else{
          err.actionToCall = PRODUCT_ACTION_LIST.PRODUCT_LIST;
          err.data = {
            pageNo: payload.pageNumber,
            list: []
          };
        }
      }).finally(() => {
        dispatch({
          type: COMMON_ACTIONS.HIDE_LIST_LOADER,
        });
      })
  }
}

export const getProductCount = (payload, history) => {
  let FY = getFinancialYear(payload.companyInfo);
  const searchedTextQuery = payload.searchedText && payload.searchedText.length ? `&searchString=${payload.searchedText}` : '';
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_HEADER_LOADER });
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/inventoryItem/getAllInventoryItemsCount?relationshipId=${payload.relationshipId}&startDate=${FY.fyStartDate}&endDate=${FY.fyEndDate}${searchedTextQuery}`)
      .then(res => {
        dispatch({ type: PRODUCT_ACTION_LIST.PRODUCT_COUNT, data: res.data });
      })
      .catch((err) => {
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        } else {
          err.actionToCall = PRODUCT_ACTION_LIST.PRODUCT_COUNT;
        }
      }).finally(() => {
        dispatch({
          type: COMMON_ACTIONS.HIDE_HEADER_LOADER,
        });
      })
  }
}

export const resetPaginatedData = () => {
  return dispatch => {
    dispatch({ type: PRODUCT_ACTION_LIST.PRODUCT_LIST_PAGE_RESET });
  }
}

export const getOutOfStockCount = (payload, history) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/inventoryItem/countOutOfStockItems?relationshipId=${payload.relationshipId}`)
      .then(res => {
        dispatch({ type: PRODUCT_ACTION_LIST.PRODUCT_OUT_OF_STOCK_COUNT, data: res.data });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if(!err.__isRetryRequest){
          showToasterMessage({messageType: 'error',  description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        }else{
          err.actionToCall = PRODUCT_ACTION_LIST.PRODUCT_OUT_OF_STOCK_COUNT;
        }
      })
  }
}

export const getReorderStockCount = (payload, history) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/inventoryItem/countReorderItems?relationshipId=${payload.relationshipId}`)
      .then(res => {
        dispatch({ type: PRODUCT_ACTION_LIST.PRODUCT_REORDER_STOCK, data: res.data });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if(!err.__isRetryRequest){
          showToasterMessage({messageType: 'error',  description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        }else{
          err.actionToCall = PRODUCT_ACTION_LIST.PRODUCT_REORDER_STOCK;
        }
      })
  }
}

export const getLowStockCount = (payload, history) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/inventoryItem/countLowStockItems?relationshipId=${payload.relationshipId}`)
      .then(res => {
        dispatch({ type: PRODUCT_ACTION_LIST.PRODUCT_LOW_STOCK, data: res.data });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if(!err.__isRetryRequest){
          showToasterMessage({messageType: 'error',  description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        }else{
          err.actionToCall = PRODUCT_ACTION_LIST.PRODUCT_LOW_STOCK;
        }
      })
  }
}

export const getInventoryEvaluation = (payload, history) => {
  let FY = getFinancialYear(payload.companyInfo);
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/inventoryItem/getInventoryEvaluation?relationshipId=${payload.relationshipId}&customerId=${payload.customerId}&startDate=${FY.fyStartDate}&endDate=${FY.fyEndDate}`)
      .then(res => {
        dispatch({ type: PRODUCT_ACTION_LIST.PRODUCT_INVENTORY_VALUATION, data: res.data });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if(!err.__isRetryRequest){
          showToasterMessage({messageType: 'error',  description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        }else{
          err.actionToCall = PRODUCT_ACTION_LIST.PRODUCT_INVENTORY_VALUATION;
        }
      })
  }
}
export const exportPdf = (payload) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios.get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/inventoryItemPdf/getSelectedInventoryItemPdf?relationshipId=${payload.relationshipId}&itemIds=${payload.itemIds}`,
      {
        responseType: 'blob',
      }
    )
      .then(response => {
        const content = response.headers['content-type'];
        download(response.data, 'Product.pdf', content);
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch(err => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
  }
}

export const exportExcel = (payload) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios.get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/inventoryExcel/getSelectedInventoryExcel?relationshipId=${payload.relationshipId}&itemIds=${payload.itemIds}`,
      {
        responseType: 'blob',
      }
    )
      .then(response => {
        const content = response.headers['content-type'];
        download(response.data, 'Product.xlsx', content);
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch(err => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
  }
}

export const deleteProducts = (payload) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios.post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/inventoryItem/deleteMultipleInventoryItems`, payload)
      .then(response => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        resetPaginatedData()(dispatch);
        getProductCount({ relationshipId: payload.relationshipId })(dispatch);
        fetchProducts(payload)(dispatch);
        showToasterMessage({ messageType: 'success', description: 'Product(s) deleted successfully' });
      })
      .catch(err => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        }
      })
  }
}

export const getInventoryItemByIds = (payload,props) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/inventoryItem/getAllMatchingInventoryItems?relationshipId=${
        payload.relationshipId
      }&inventoryItemCol=${[payload.inventoryItemCol]}`)
      .then(res => {
        dispatch({ type: PRODUCT_ACTION_LIST.PRODUCT_INVENTORY_ITEMBYID, data: res.data });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        let val = []
        let data = res.data || [];
        const allRelationshipBillingAddress =
        filter(props.companyInfo.boLocationList, {
          locationType: CONSTANTS.RELATIONSHIP_BILLING_ADDRESS,
        }) || [];
        const relationshipBillingAddress =allRelationshipBillingAddress.find((x) => x.isDefault) || {};
     const value =   data?.forEach((obj) => {
     
         const value2 =  obj.inventoryItemVariantList?.forEach((item) => {
            val.push({
              fileName: ('ProductQr') + '.pdf',
              transactionName: 'productQr',
              sku: item.sku,
              brandName: obj?.brandName || '', // Provide a default value if brandName is potentially undefined
              attributeName1:item?.attributeName1,
              attributeName2:item?.attributeName2,
              attributeName3:item?.attributeName3,
              attributeValue1:item?.attributeValue1,
              attributeValue2:item?.attributeValue2,
              attributeValue3:item?.attributeValue3,
              maxRetailPrice:item?.maxRetailPrice,
              companyEmail:props.companyInfo.email,
              phoneNumber:props.companyInfo.countryCallingCode + "-" + props.companyInfo.phoneNumber,
              storeName:props.companyInfo?.storeName,
              url: `${config.UI_BASE_URL}product-card?relationshipId=${props.companyInfo.relationshipId}&productId=${item.itemId}&productVarieantId=${item.variantId}/${props.companyInfo.relationshipId},${item.itemId},${item.variantId}`,
              pdfSize: "75,50",
              relationshipBillingAddress:relationshipBillingAddress ||allRelationshipBillingAddress? allRelationshipBillingAddress[0]:{} ,
              companyInfo:props.companyInfo,
              bucketName: config.BUCKET_NAME.BO_SALES_ORDER,
            });
          })
        });
        props.generateLabelPdf({transactionName:"productQr",data:[...val],pdfSize: "70,100"})
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if(!err.__isRetryRequest){
          showToasterMessage({messageType: 'error',  description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        }else{
          err.actionToCall = PRODUCT_ACTION_LIST.PRODUCT_INVENTORY_ITEMBYID;
        }
      })
  }
}