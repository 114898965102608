import React from 'react';
import { FormattedMessage } from 'react-intl';
//import { MailOutlined } from '@ant-design/icons';
import {  Row, Col, Select, InputNumber, Button } from 'antd';
//import { TextBox } from "../../../../general/TextBox";
import moment from 'moment-timezone';
import { CONSTANTS } from '../../../../../static/constants';
import { getMomentDateForUIReadOnly, getMomentMonthCountBetweenDates, getMonthsBetweenDates, showToasterMessage } from '../../../../../utils';

const { Option } = Select;
const format = CONSTANTS.DISPLAY_DATE_FORMAT_SESSION;

const RegistrationPaymentPlan = (props) => {
    // function invalidStartDate(current) {
    //     return current && current < moment().endOf('day');
    // }
    // function invalidEndDate(current) {
    //     return current && current < (props.startDate ? moment(props.startDate).endOf('day') : moment().endOf('day') );
    // }
    // const getDays = (item) => {
    //     if (!item.totalInstallment) { return 0 }
    //     if (item.period === 'monthly') {
    //         return Number(item.totalInstallment) * 30;
    //     } else if (item.period === 'quarterly') {
    //         return Number(item.totalInstallment) * 90;
    //     } else if (item.period === 'completeSession') {
    //         if (props.sessionStart && props.sessionEnd) {
    //             return getDaysBetweenDates(props.sessionStart, props.sessionEnd);
    //         }else { return 0 }
    //     }
    // }
    const getTotalInstallment = (item) => {
        if (!item.period) { return 1; }
        
        const totalMonths = Math.ceil(moment(item.endDate, 'YYYY-MM-DDTHH:mm:ss')
                                .diff(moment(item.startDate, 'YYYY-MM-DDTHH:mm:ss'), 'months', true));
        
        if (item.period === 'monthly') {
            return Math.max(totalMonths, 1); 
        } else if (item.period === 'quarterly') {
            return Math.max(Math.floor(totalMonths / 3), 1);  
        } else if (item.period === 'completeSession') {
            return 1;  
        }
    };
    return (
        <div className="modal-dialog ">
            <div className="modal-content full-width">
                <div className='newheight'>
                <Row style={{borderBottom:'solid 1px #e4e4e4', margin:'0px 0px 10px', paddingBottom:'10px'}}>
                    <Col span={1}><b>#</b></Col>
                    <Col span={6}><b><FormattedMessage id='membership.termAndFee.listing.header.frequency' /></b></Col>
                    <Col span={5}><b><FormattedMessage id='totalInstallment.text' /></b></Col>
                    <Col span={5}><b><FormattedMessage id='modal.reports.partyLedger.startDate.label' /></b></Col>
                    <Col span={5}><b><FormattedMessage id='modal.reports.partyLedger.endDate.label' /></b></Col>
                    <Col span={2}><b><FormattedMessage id='common.listing.header.action' /></b></Col>
                </Row>
                    {
                        (props.paymentPlan || []).map((item, i) => {

                            return (
                                <div key={'ques' + i}>
                                    <Row style={{ marginBottom: '10px', borderBottom: 'solid 1px #e4e4e4', paddingBottom: '10px' }}>
                                        <Col span={1}><b>{i + 1}</b></Col>
                                        <Col span={6}>
                                            <Select onChange={(value) => {
                                                item.period = value;
                                                if (item.period === 'completeSession') {
                                                    item.totalInstallment = 1;
                                                }
                                                let paymentPlan = props.paymentPlan;
                                                paymentPlan.splice(i, 1, item);
                                                props.updateState({ paymentPlan });
                                            }}
                                                style={{ width: 150 }}
                                                placeholder="Period"
                                                optionFilterProp="children"
                                                value={item.period}>
                                                <Option value="monthly">
                                                    <div>
                                                        <FormattedMessage id='recurringInvoice.setup.monthly' defaultMessage='' />
                                                        <br />Max month - {getTotalInstallment({ ...item, period: 'monthly' })}
                                                    </div>
                                                </Option>
                                                <Option value="quarterly">
                                                    <div>
                                                        <FormattedMessage id='recurringInvoice.setup.quarterly' defaultMessage='' />
                                                        <br />Max Quarter - {getTotalInstallment({ ...item, period: 'quarterly' })}
                                                    </div>
                                                </Option>
                                                <Option value="completeSession">
                                                    <div>
                                                        <FormattedMessage id='completeSession.text' defaultMessage='' />
                                                        <br />Total months - {getTotalInstallment({ ...item, period: 'completeSession' })}
                                                    </div>
                                                </Option>
                                            </Select>
                                        </Col>
                                        <Col span={5}>
                                            <InputNumber
                                                min={1}
                                                max={getTotalInstallment(item)}
                                                disabled={item.period === 'completeSession'}
                                                style={{ width: '90%' }}
                                                placeholder={props.intl.formatMessage({ id: 'totalInstallment.text' })}
                                                value={item.totalInstallment}
                                                onChange={(e) => {
                                                    item.totalInstallment = e;
                                                    let paymentPlan = props.paymentPlan;
                                                    paymentPlan.splice(i, 1, item);
                                                    props.updateState({ paymentPlan });
                                                }}
                                            />
                                        </Col>
                                        <Col span={5} style={{ paddingRight: '10px' }}>
                                            <div>{getMomentDateForUIReadOnly({ date: props.sessionStart, format: format })}</div>
                                        </Col>
                                        <Col span={5} style={{ paddingRight: '10px' }}>
                                            <div>{getMomentDateForUIReadOnly({ date: props.sessionEnd, format: format })}</div>
                                        </Col>
                                        <Col span={2}>
                                            <span style={{ fontSize: '20px', marginLeft: '10px', cursor: 'pointer' }} onClick={() => {
                                                let paymentPlan = props.paymentPlan;
                                                paymentPlan.splice(i, 1);
                                                props.updateState({ paymentPlan });
                                            }}><i className="fa fa-trash" title={props.intl.formatMessage({ id: 'delete.text' })} /> </span>
                                        </Col>
                                    </Row>
                                </div>
                            );
                        })
                    }

                <Button onClick={
                        () => {
                            let paymentPlan = props.paymentPlan || [];
                            paymentPlan.push({period:'monthly', totalInstallment:getMonthsBetweenDates(props.sessionStart, props.sessionEnd), startDate:  moment(new Date(props.sessionStart)), endDate: moment(new Date(props.sessionEnd)) });
                            props.updateState({ paymentPlan });
                        }
                    }><FormattedMessage id='addPaymentPlan.text' />  </Button>
                 </div>
                    
            </div>
            <div>
           
            <hr className="mt10 "></hr>
                <Row justify='center'>
                    <button type="button" className="ant-btn ant-btn-primary mt20" onClick={() => {
                        let validInstallment = false;
                        if(!props.paymentPlan || !props.paymentPlan.length){
                            return showToasterMessage({
                                messageType: 'error', description: props.intl.formatMessage({ id: 'formBuilder.paymentPlan.emptyPlan.errorMsg' })
                            });
                        }
                        
                        for(let item of (props.paymentPlan || [])){
                            if (item.totalInstallment){
                                validInstallment = true;
                                break;
                            }
                        }
                        if(!validInstallment){
                            return showToasterMessage({
                                messageType: 'error', description: props.intl.formatMessage({ id: 'formBuilder.paymentPlan.emptyInstallments.errorMsg' })
                            });
                        }
                        props.addPaymentPlans(props.paymentPlan);
                        props.popModalFromStack();
                       
                    }}><FormattedMessage id='forgot.btn.save' defaultMessage='' /></button>
                </Row>
            </div>
        </div>
    );

}

export default RegistrationPaymentPlan;