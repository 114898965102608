import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import CommunicationDrawer from '../../../components/drawer/communication/CommunicationDrawer';
import { downloadFileFromBucket } from './../../../actions/downloadFileAction';
import { sendEmail } from '../email/action';

class CommunicationDetailsContainer extends Component {

  constructor(props){
    super(props);
    this.state = {
    }
  }


  render() {
    return <CommunicationDrawer initCalls={() => { this.initCalls();}} {...this.props} {...this.state} updateDrawerState={(data) => { this.setState(data) }} />
  }

}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    userInfo: state.common.userInfo,
    isEmailSpinner: state.common.isEmailSpinner,
    emailAddressList: state.common.emailAddressList,
    payOnlineUrl: state.common.payOnlineUrl
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  downloadFileFromBucket,
  sendEmail
}, dispatch);


export default connect(mapStateToProps, mapDispatchToProps)(CommunicationDetailsContainer);
