import React, { Component, Fragment } from "react";
// import { Form } from "@ant-design/compatible";
import "@ant-design/compatible/assets/index.css";
import { Row, Col, DatePicker, Tooltip,Skeleton } from "antd";
import { FormattedMessage, injectIntl } from "react-intl";
import { Dropdown } from "../../../../general/Dropdown";
// import config from "../../../../../config/environmentConfig";
import * as filter from "lodash.filter";
import * as find from "lodash.find";
import moment from "moment-timezone";

// import { CustomAgGrid } from "../../../../general/CustomAgGrid";
import {
  CONSTANTS,
  MODAL_TYPE,
  CONSTANTS_TRANSACTIONS,
  CONSTANTS_TRANSACTIONS_KEY,
  MODULE_CODED_VALUES
} from "../../../../../static/constants";
import { getLabelName } from "../../../../modal/modalBody/customerInfo";
import { MultipleSelectDropdown } from "../../../../general/MultipleSelectDropdown";
// import { LogoComponent } from "../../../../general/LogoComponent";
import { QuestionCircleTwoTone, EditOutlined } from "@ant-design/icons";
import { TextBox } from "../../../../general/TextBox";
import { getPermissionData } from "../../../../../utils";

const format = CONSTANTS.DISPLAY_DATE_FORMAT;
class RFQDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      size: "default",
      billingAddress: this.defaultBillingAddress,
    };
  }

  handleSizeChange = (e) => {
    this.setState({ size: e.target.value });
  };

  handleNewTaxIdentificationAddition = (props, payload, modalType) => {
    const formData = payload.formData;
    const { showModal, companyInfo } = props;
    formData.relationshipId = companyInfo.relationshipId;
    formData.callback = function (taxIdentification) {
      if (props.supplier) {
        let list = props.supplier.supplierTaxIdentificationsList || [];
        list.push(taxIdentification);
        props.updateState({
          supplier: { ...props.supplier, supplierTaxIdentificationsList: list },
        });
      }
    };
    const data = {
      title: (
        <FormattedMessage
          id="addItem.text.taxIdentification"
          defaultMessage=""
        />
      ),
      formData,
      hideFooter: true,
      modalData: {
        modalType: modalType,
        data: payload,
      },
      handleSubmit: (formData = {}) => {
        data.formData.submittedOnce = true;
        props.hideModal(data);
      },
    };
    showModal(data);
  };

  handleNewAddressAddition = (props, payload, modalType) => {
    const formData = payload.formData;
    const { showModal, companyInfo } = props;
    formData.relationshipId = companyInfo.relationshipId;
    if (modalType === MODAL_TYPE.RELATIONSHIP_BILLING_ADDRESS) {
      payload.formData = {
        addressType: "relationship",
        locationType: "RelationshipBillingAddress",
      };
    }
    payload.formData.callback = function (address) {
      props.hideModal(data);
      if (
        address.locationType === "BillingAddress" ||
        address.locationType === "ShippingAddress"
      ) {
        props.fetchAddresses({
          customerId: address.customerId,
          relationshipId: (props.companyInfo || {}).relationshipId,
          locationType: address.locationType,
        });
      } else {
        props.getRelationshipAddress({
          relationshipId: (props.companyInfo || {}).relationshipId,
        });
      }
    };
    const data = {
      title: this.getModalTitle(payload, modalType),
      formData,
      hideFooter: true,
      keyboard:false,
      modalData: {
        modalType: modalType,
        data: payload,
      },
      handleSubmit: (formData = {}) => {
        data.formData.submittedOnce = true;
        props.hideModal(data);
      },
    };
    showModal(data);
  };

  handleNewEmployeeAddition = (props, payload) => {
    const formData = payload.formData;
    const { showModal, companyInfo } = props;
    formData.relationshipId = companyInfo.relationshipId;
    const data = {
      title: <FormattedMessage id="addItem.text.employee" defaultMessage="" />,
      formData,
      hideFooter: true,
      modalData: {
        modalType: MODAL_TYPE.EMPLOYEE,
        data: payload,
      },
      handleSubmit: (formData = {}) => {
        data.formData.submittedOnce = true;
        props.hideModal(data);
      },
    };
    showModal(data);
  };

  handleNewContactAddition = (props, payload) => {
    const formData = payload.formData;
    const { showModal, companyInfo } = props;
    formData.relationshipId = companyInfo.relationshipId;
    const data = {
      title: (
        <div>
          <FormattedMessage id="addItem.text.contact" defaultMessage="" />
          <div style={{ "font-size": "12px" }}>
            {" "}
            {formData.customerName || ""}
          </div>
        </div>
      ),
      formData,
      hideFooter: true,
      modalData: {
        modalType: MODAL_TYPE.CONTACT,
        data: payload,
      },
      handleSubmit: (formData = {}) => {
        data.formData.submittedOnce = true;
        props.hideModal(data);
      },
    };
    showModal(data);
  };

  handleNewProjectAddition = (props, payload) => {
    const formData = payload.formData;
    const { showModal, companyInfo } = props;
    formData.relationshipId = companyInfo.relationshipId;
    const data = {
      title: <FormattedMessage id="addItem.text.project" defaultMessage="" />,
      formData,
      hideFooter: true,
      modalData: {
        modalType: MODAL_TYPE.PROJECT,
        data: payload,
      },
      handleSubmit: (formData = {}) => {
        data.formData.submittedOnce = true;
        props.hideModal(data);
      },
    };
    showModal(data);
  };

  addItemConfirmationModal = (props, text, modalType) => {
    const { showModal, companyInfo } = props;
    const labelName = getLabelName(modalType);
    const modalBody = (
      <span>
        <FormattedMessage
          id="addItem.confirmation"
          values={{ value: `${text} to ` }}
          defaultMessage={``}
        />
        {labelName} ?
      </span>
    );

    let addFunc;
    let payload = {
      text: text,
      relationshipId: companyInfo.relationshipId,
    };

    switch (modalType) {
      case MODAL_TYPE.DEPARTMENT: {
        addFunc = props.addDepartment;
        break;
      }
      case MODAL_TYPE.DOCUMENT_NAME: {
        addFunc = props.addDocumentName;
        payload.txType = CONSTANTS_TRANSACTIONS_KEY[CONSTANTS_TRANSACTIONS.RFQ];
        break;
      }
      default: {
        addFunc = () => {};
      }
    }

    const modalData = {
      modalBody,
      handleSubmit: () => {
        addFunc(payload);
        props.hideModal();
      },
    };
    showModal(modalData);
  };

  getModalTitle = (newTextObj, modalType) => {
    let title;

    switch (modalType) {
      case MODAL_TYPE.UOM: {
        title = (
          <FormattedMessage id="addItem.text.department" defaultMessage="" />
        );
        break;
      }
      case MODAL_TYPE.RELATIONSHIP_BILLING_ADDRESS: {
        title = (
          <div>
            <FormattedMessage
              id="addItem.text.billingAddress"
              defaultMessage=""
            />
            <h6>{(this.props.companyInfo || {}).storeName} </h6>
          </div>
        );
        break;
      }
      case MODAL_TYPE.BILLING_ADDRESS: {
        title = (
          <div>
            <FormattedMessage
              id="addItem.text.billingAddress"
              defaultMessage=""
            />
            <div style={{ "font-size": "12px" }}>
              {" "}
              {this.props.customerName || ""}
            </div>
          </div>
        );
        break;
      }
      case MODAL_TYPE.DOCUMENT_NAME: {
        title = (
          <FormattedMessage id="addItem.text.documentName" defaultMessage="" />
        );
        break;
      }
      default:
        title = (
          <FormattedMessage id="signup.confirmation.title" defaultMessage="" />
        );
    }
    return title;
  };

  handleNewItemAddition = (props, payload, modalType) => {
    const formData = payload.formData;
    const { showModal } = props;

    if (
      formData.textBoxValue &&
      formData.textBoxValue.length &&
      !payload.existsInData
    ) {
      this.addItemConfirmationModal(props, formData.textBoxValue, modalType);
    } else {
      const data = {
        title: this.getModalTitle(payload, modalType),
        formData,
        modalData: { modalType, data: payload },
        handleSubmit: (formData = {}) => {
          if (formData.textBoxValue && formData.textBoxValue.length) {
            this.addItemConfirmationModal(
              props,
              formData.textBoxValue,
              modalType
            );
          } else {
            data.formData.submittedOnce = true;
            showModal(data);
          }
        },
      };
      showModal(data);
    }
  };

  deleteClickHandler = (props, modalType, payload) => {
    const { showModal, companyInfo } = props;
    const modalBody = (
      <FormattedMessage
        id="deleteItem.confirmation"
        defaultMessage={``}
        values={{ value: payload.text }}
      />
    );

    let deleteFunc;

    switch (modalType) {
      case MODAL_TYPE.DEPARTMENT: {
        deleteFunc = props.deleteDepartment;
        break;
      }
      case MODAL_TYPE.PROJECT: {
        deleteFunc = props.deleteProject;
        break;
      }
      case MODAL_TYPE.RELATIONSHIP_BILLING_ADDRESS:
      case MODAL_TYPE.BILLING_ADDRESS: {
        deleteFunc = props.deleteAddress;
        break;
      }
      case MODAL_TYPE.DOCUMENT_NAME: {
        deleteFunc = props.deleteDocumentName;
        break;
      }
      case MODAL_TYPE.CONTACT: {
        deleteFunc = props.deleteContact;
        break;
      }
      case MODAL_TYPE.EMPLOYEE: {
        deleteFunc = props.deleteEmployee;
        break;
      }
      case MODAL_TYPE.NEW_TAX_IDENTIFICATION: {
        deleteFunc = props.deleteRelationshipTaxIdentifications;
        break;
      }
      default: {
        deleteFunc = () => {};
      }
    }

    const modalData = {
      modalBody,
      handleSubmit: () => {
        deleteFunc({
          id: payload.id,
          relationshipId: companyInfo.relationshipId,
          callback: () => {
            props.getRelationshipAddress({
              relationshipId: (props.companyInfo || {}).relationshipId,
            });
          },
        });
      },
    };
    showModal(modalData);
  };

  render() {
    const { size } = this.state;
    const { permissions } = this.props;
    const primaryPerm = (permissions && permissions.primary) || [];

    const supplierPermissionsData = getPermissionData(primaryPerm, MODULE_CODED_VALUES.SUPPLIERS )
    const rowNode = this.gridApi && this.gridApi.getDisplayedRowAtIndex(0);

    if (rowNode) {
      //this.props.orderPriority && rowNode.setDataValue('priority', this.props.orderPriority);
      rowNode.data.priority = this.props.orderPriority;
      rowNode.data.project = this.props.projectName && this.props.projectName;
      rowNode.data.customerRFQNumber = this.props.customerRFQNumber;
      rowNode.data.customerInquiryNumber = this.props.customerInquiryNumber;
      rowNode.data.customerPONumber = this.props.customerPONumber;
      rowNode.data.refNumber = this.props.refNumber;
      rowNode.data.salesPerson = this.props.salesPersonName;
      rowNode.setDataValue("refNumber_1", this.props.refNumber || "");
      this.gridApi.sizeColumnsToFit();
      this.gridApi.refreshCells({ force: true });
      this.gridApi.sizeColumnsToFit();
    }

    // const columnDefs = [
    //   {
    //     headerComponentFramework: () => {
    //       return (
    //         <div>
    //           <FormattedMessage
    //             id="supplier.rfq.form.gridHeader.customerRFQNumber"
    //             defaultMessage=""
    //           />
    //         </div>
    //       );
    //     },
    //     field: "customerRFQNumber",
    //     hide:
    //       this.props.customerRFQNumber && this.props.customerRFQNumber !== "0"
    //         ? false
    //         : true,
    //   },

    //   {
    //     headerComponentFramework: () => {
    //       return (
    //         <div>
    //           <FormattedMessage
    //             id="supplier.rfq.form.gridHeader.customerInquiryNumber"
    //             defaultMessage=""
    //           />
    //         </div>
    //       );
    //     },
    //     field: "customerInquiryNumber",
    //     hide:
    //       this.props.customerInquiryNumber &&
    //       this.props.customerInquiryNumber !== "0"
    //         ? false
    //         : true,
    //   },
    //   {
    //     headerComponentFramework: () => {
    //       return (
    //         <div>
    //           <FormattedMessage id="customerPONumber.text" defaultMessage="" />
    //         </div>
    //       );
    //     },
    //     field: "customerPONumber",
    //     hide:
    //       this.props.customerPONumber && this.props.customerPONumber !== "0"
    //         ? false
    //         : true,
    //   },
    //   {
    //     headerComponentFramework: () => {
    //       return (
    //         <div>
    //           <FormattedMessage
    //             id="supplier.rfq.form.gridHeader.refNumber"
    //             defaultMessage=""
    //           />
    //           <Tooltip
    //             placement="top"
    //             title={
    //               <FormattedMessage
    //                 id="tooltip.referenceNumberAssign"
    //                 defaultMessage=""
    //               />
    //             }
    //             trigger="click"
    //           >
    //             <i className="fa fa-question-circle-o pl5 cursor-pointer" />
    //           </Tooltip>
    //         </div>
    //       );
    //     },
    //     field: "refNumber",
    //     colId: "refNumber_1",
    //     editable: true,
    //     cellEditor: "customTextEditor",
    //     cellEditorParams: (obj) => {
    //       return {
    //         maxLength: 20,
    //         onChange: (val) => {
    //           this.props.updateState({
    //             refNumber: val,
    //           });
    //         },
    //       };
    //     },
    //   },
    //   {
    //     headerComponentFramework: () => {
    //       return (
    //         <FormattedMessage
    //           id="supplier.rfq.form.gridHeader.salesPerson"
    //           defaultMessage=""
    //         />
    //       );
    //     },
    //     field: "salesPerson",
    //     editable: true,
    //     cellEditor: "customDropDownEditor",
    //     cellEditorParams: (obj) => {
    //       return {
    //         lastColumnCell: false,
    //         items: this.props.employees || [],
    //         optionKeyName: "relationshipEmployeeId",
    //         valueKeyName: "fullName",
    //         canAddNew: true,
    //         canDelete: true,
    //         allowClear: true,
    //         deleteClickHandler: (payload) => {
    //           this.deleteClickHandler(this.props, MODAL_TYPE.EMPLOYEE, payload);
    //         },
    //         onAddNewClick: (payload) => {
    //           // payload.formData = {
    //           //     firstName: payload.textEntered,
    //           //     submittedOnce: false
    //           // }
    //           // this.handleNewEmployeeAddition(this.props, payload, MODAL_TYPE.EMPLOYEE)
    //           this.props.updateHeaderState({
    //             company: {
    //               ...this.props.company,
    //               employeeDrawerVisible: true,
    //             },
    //           });
    //         },
    //         onSelect: (selectedValue, option) => {
    //           //obj.node.setDataValue(obj.colDef.field, selectedValue);
    //           obj.node.data.salesPerson = selectedValue;
    //           this.props.updateState({
    //             salesPersonId: option.key,
    //             salesPersonName: selectedValue,
    //           });
    //         },
    //         onClear: () => {
    //           obj.node.setDataValue(obj.colDef.field, undefined);
    //           obj.node.data.salesPerson = "";
    //           this.props.updateState({
    //             salesPersonId: undefined,
    //             salesPersonName: "",
    //           });
    //           obj.api.stopEditing(true);
    //         },
    //       };
    //     },
    //   },
    //   {
    //     headerComponentFramework: () => {
    //       return (
    //         <FormattedMessage
    //           id="supplier.rfq.form.gridHeader.priority"
    //           defaultMessage=""
    //         />
    //       );
    //     },
    //     field: "priority",
    //     editable: true,
    //     cellEditor: "customDropDownEditor",
    //     cellEditorParams: (obj) => {
    //       return {
    //         lastColumnCell: false,
    //         items: CONSTANTS.PRIORITIES,
    //         onSelect: (selectedValue) => {
    //           this.props.updateState({
    //             orderPriority: selectedValue,
    //           });
    //           //obj.node.setDataValue(obj.colDef.field, selectedValue);
    //         },
    //         allowClear: true,
    //         onClear: () => {
    //           obj.node.setDataValue(obj.colDef.field, undefined);
    //           this.props.updateState({
    //             orderPriority: undefined,
    //           });
    //           obj.api.stopEditing(true);
    //         },
    //       };
    //     },
    //   },
    //   {
    //     headerComponentFramework: () => {
    //       return (
    //         <FormattedMessage
    //           id="supplier.rfq.form.gridHeader.project"
    //           defaultMessage=""
    //         />
    //       );
    //     },
    //     field: "project",
    //     editable: true,
    //     cellEditor: "customDropDownEditor",
    //     cellEditorParams: (obj) => {
    //       return {
    //         lastColumnCell: false,
    //         items: this.props.projectList || [],
    //         optionKeyName: "projectMasterId",
    //         valueKeyName: "projectName",
    //         projectIdKey: "projectMasterId",
    //         canAddNew: true,
    //         canDelete: true,
    //         deleteClickHandler: (payload) => {
    //           this.deleteClickHandler(this.props, MODAL_TYPE.PROJECT, payload);
    //         },
    //         onAddNewClick: (payload) => {
    //           payload.formData = {
    //             projectName: payload.projectName,
    //           };
    //           this.handleNewProjectAddition(
    //             this.props,
    //             payload,
    //             MODAL_TYPE.PROJECT
    //           );
    //         },
    //         onSelect: (selectedValue, option) => {
    //           const selectedProject =
    //             find(this.props.projectList, {
    //               projectMasterId: Number(option.key || 0),
    //             }) || {};
    //           //obj.node.setDataValue(obj.colDef.field, selectedValue);
    //           this.props.updateState({
    //             projectNumber: selectedProject.projectNumber,
    //             projectName: selectedProject.projectName,
    //             projectMasterId: selectedProject.projectMasterId,
    //           });
    //         },
    //         allowClear: true,
    //         onClear: () => {
    //           obj.node.setDataValue(obj.colDef.field, undefined);
    //           this.props.updateState({
    //             projectNumber: undefined,
    //             projectName: undefined,
    //             projectMasterId: undefined,
    //           });
    //           obj.api.stopEditing(true);
    //         },
    //       };
    //     },
    //   },
    //   // {
    //   //     headerComponentFramework: () => {
    //   //         return <FormattedMessage id='supplier.rfq.form.gridHeader.requestingDepartment' defaultMessage='' />;
    //   //     },
    //   //     field: 'department',
    //   //     editable: true,
    //   //     sortable: true,
    //   //     cellEditor: 'customDropDownEditor',
    //   //     cellEditorParams: (obj) => {
    //   //         return {
    //   //             lastColumnCell: false,
    //   //             items: this.props.departments || [],
    //   //             optionKeyName: 'departmentId',
    //   //             valueKeyName: 'departmentName',
    //   //             value: this.props.departmentName || '',
    //   //             canAddNew: true,
    //   //             canDelete: true,
    //   //             deleteClickHandler: (payload) => {
    //   //                 this.deleteClickHandler(this.props, MODAL_TYPE.DEPARTMENT, payload);
    //   //             },
    //   //             onAddNewClick: (payload) => {
    //   //                 payload.formData = {
    //   //                     textBoxValue: payload.textEntered,
    //   //                     submittedOnce: false
    //   //                 }
    //   //                 this.handleNewItemAddition(this.props, payload, MODAL_TYPE.DEPARTMENT)
    //   //             },
    //   //             onSelect: (selectedValue) => {
    //   //                 //obj.node.setDataValue(obj.colDef.field, selectedValue);
    //   //                 this.props.updateState({
    //   //                     departmentName: selectedValue
    //   //                 })
    //   //             },
    //   //         }
    //   //     }
    //   // },
    //   // {
    //   //     headerComponentFramework: () => {
    //   //         return <FormattedMessage id='supplier.rfq.form.gridHeader.purchaseType' defaultMessage='' />;
    //   //     },
    //   //     field: 'ref',
    //   //     valueGetter: () => { return "Purchase Order" },
    //   // },
    //   {
    //     headerComponentFramework: () => {
    //       return (
    //         <FormattedMessage
    //           id="supplier.rfq.form.gridHeader.prRequestedBy"
    //           defaultMessage=""
    //         />
    //       );
    //     },
    //     field: "prRequestedBy",
    //     hide: !this.props.isRFQFromPr,
    //     valueGetter: () => {
    //       return this.props.prRequestedBy;
    //     },
    //   },
    // ];

    // const onGridReady = (params) => {
    //   this.gridApi = params.api;
    //   this.gridApi.sizeColumnsToFit();
    //   this.gridColumnApi = params.columnApi;
    // };

    return (
      <>
        <Row>
          <Col span={5}>
          <Skeleton loading={this.props.detailLoading} active paragraph={{ rows: 5 }}>

            <Row gutter={[0, 3]}>
              <Col span={24} className="details-heading">
                Organization Information
              </Col>
              <Col span={24}>
                <div className="details-heading-subheading">
                  <FormattedMessage
                    id="sales.order.create.organization.name"
                    defaultMessage=""
                  />
                </div>
              </Col>
              <Col span={24}>
                <div className="deatils-field-value">
                  {(this.props.companyInfo || {}).storeName}
                </div>
              </Col>
              <Col span={24}>
                <div className="details-heading-subheading">
                  <FormattedMessage
                    id="sales.order.create.organization.adress"
                    defaultMessage=""
                  />
                  <Tooltip
                    placement="right"
                    title={this.props.intl.formatMessage({
                      id: "tooltip.companyBillingAddress",
                      defaultMessage: "",
                    })}
                    trigger="click"
                  >
                    <QuestionCircleTwoTone />
                  </Tooltip>
                </div>
              </Col>
              <Col span={24}>
                <span>
                  {!this.props.addressHide && this.props.relationshipAddress ? (
                    <>
                      <div className="detail-address-value">
                        <div style={{ height: "96px", paddingTop: "10px" }}>
                          {getAddressInfoFragment(
                            this.props.relationshipAddress,
                            this.props.companyInfo,
                            {}
                          )}
                        </div>
                        <div
                          onClick={() => {
                            this.props.updateState({
                              addressHide: true,
                            });
                          }}
                        >
                          <EditOutlined />
                        </div>
                      </div>
                    </>
                  ) : (
                    <Dropdown
                      style={{ width: "100%" }}
                      value={
                        (this.props.relationshipAddress || {}).streetAddress1
                      }
                      items={this.props.allRelationshipBillingAddress || []}
                      valueKeyName="streetAddress1"
                      addressLineKey1="streetAddress2"
                      addressLineKey2="cityName"
                      addressLineKey3="stateName"
                      addressLineKey4="zipCode"
                      optionClassAddress="custom-address-dropdown"
                      optionKeyName="locationId"
                      isFavouriteKeyName="isDefault"
                      canAddNew={supplierPermissionsData.create ? true : false}
                      canDelete={supplierPermissionsData.delete ? true : false}
                      deleteClickHandler={(payload) => {
                        this.deleteClickHandler(
                          this.props,
                          MODAL_TYPE.RELATIONSHIP_BILLING_ADDRESS,
                          payload
                        );
                      }}
                      onAddNewClick={(payload) => {
                        payload.formData = {
                          locationType: CONSTANTS.RELATIONSHIP_BILLING_ADDRESS,
                        };
                        this.handleNewAddressAddition(
                          this.props,
                          payload,
                          MODAL_TYPE.RELATIONSHIP_BILLING_ADDRESS
                        );
                      }}
                      onSelect={(locationId) => {
                        this.props.updateState({
                          relationshipAddress:
                            this.props.allRelationshipBillingAddress.find(
                              (x) => x.locationId === locationId
                            ),
                          addressHide: false,
                        });
                      }}
                    />
                  )}
                </span>
              </Col>
              {/* <Col span={24} className='address-container mt5 mb5'>
                {
                  getAddressInfoFragment(this.props.relationshipBillingAddress || {}, {}, this.props.companyInfo)
                }
              </Col> */}
              <Col span={24}>
                <div className="details-heading-subheading">
                  <FormattedMessage
                    id="customer.form.taxIdentification.label"
                    defaultMessage=""
                  />
                  <Tooltip
                    placement="right"
                    title={this.props.intl.formatMessage({
                      id: "tooltip.taIdentification",
                      defaultMessage: "",
                    })}
                    trigger="click"
                  >
                    <QuestionCircleTwoTone />
                  </Tooltip>
                </div>

                <MultipleSelectDropdown
                  style={{ width: "100%" }}
                  items={this.props.allRelationshipTaxIdentificatins || []}
                  placeholder="Tax Identification(s)"
                  mode="multiple"
                  onDeSelect={(value) => {
                    let { selectedRelationshipTaxIdentifications } = this.props;
                    selectedRelationshipTaxIdentifications = filter(
                      selectedRelationshipTaxIdentifications,
                      (taxId) => {
                        return taxId !== value;
                      }
                    );
                    this.props.updateState({
                      selectedRelationshipTaxIdentifications,
                    });
                  }}
                  valueKeyName="taxIdentificationType"
                  valueKeyName2="taxIdentificationNumber"
                  selectedValue={
                    (this.props.allRelationshipTaxIdentificatins || []).length
                      ? this.props.selectedRelationshipTaxIdentifications || []
                      : []
                  }
                  optionKeyName="relationshipTaxIdentificationId"
                  onSelect={(relationshipTaxIdentificationId) => {
                    let selectedRelationshipTaxIdentifications =
                      this.props.selectedRelationshipTaxIdentifications || [];
                    if (
                      selectedRelationshipTaxIdentifications.indexOf(
                        relationshipTaxIdentificationId
                      ) === -1
                    ) {
                      selectedRelationshipTaxIdentifications.push(
                        relationshipTaxIdentificationId
                      );
                    } else {
                      selectedRelationshipTaxIdentifications =
                        selectedRelationshipTaxIdentifications.filter((el) => {
                          return el !== selectedRelationshipTaxIdentifications;
                        });
                    }
                    this.props.updateState({
                      selectedRelationshipTaxIdentifications,
                    });
                  }}
                  canAddNew={true}
                  canDelete={true}
                  deleteClickHandler={(payload) => {
                    this.deleteClickHandler(
                      this.props,
                      MODAL_TYPE.NEW_TAX_IDENTIFICATION,
                      payload
                    );
                  }}
                  onAddNewClick={(payload) => {
                    payload.formData = {
                      taxIdentificationNumber: payload.textEntered,
                      submittedOnce: false,
                    };
                    this.handleNewTaxIdentificationAddition(
                      this.props,
                      payload,
                      MODAL_TYPE.NEW_TAX_IDENTIFICATION
                    );
                  }}
                />
              </Col>
            </Row>
            </Skeleton>
          </Col>
          <Col span={5}>
          <Skeleton loading={this.props.detailLoading} active paragraph={{ rows: 5 }}>

            {this.props.customerName && (
              
                <Row gutter={[0,3]}>
                  <Col span={24} >
                    <div className="title">
                      <FormattedMessage
                        id="common.customer.text"
                        defaultMessage=""
                      />
                    </div>
                  </Col>
                  <Col span={24} >
                    <div className="value">{this.props.customerName}</div>
                  </Col>
                </Row>
             
            )}
            </Skeleton>
          </Col>
          {/* <Col span={7} className="customer-details-from-dropdown">
                            {this.props.customerName && <div>
                                <Row>
                                    <Col span={4} className="text-right">
                                        <div className="title">
                                            <FormattedMessage id='supplier.rfq.form.companyName.label' defaultMessage=' />
                                        </div>
                                    </Col>
                                    <Col span={20} style={{ 'text-align': 'left' }}>
                                        <div className="value">
                                            {this.props.customerName}
                                        </div>
                                    </Col>
                                </Row>
                                <div className="mt10">
                                    <Row>
                                        <Col span={4} className="text-right">
                                            <div className="title">
                                                <FormattedMessage id='supplier.rfq.form.contact.label' defaultMessage='' />
                                            </div>
                                        </Col>
                                        <Col span={20} style={{ 'text-align': 'left' }}>
                                            <Dropdown
                                                style={{ width: '70%' }}
                                                value={((this.props.selectedContact || {}).firstName || "") + " " + ((this.props.selectedContact || {}).lastName || "")}
                                                optionKeyName="contactId"
                                                items={this.props.customer.boContactList}
                                                valueKeyName='firstName'
                                                lastNameKey='lastName'
                                                placeholder='Please select contact name'
                                                canAddNew={true}
                                                canDelete={true}
                                                deleteClickHandler={(payload) => {
                                                    this.deleteClickHandler(this.props, MODAL_TYPE.CONTACT, payload);
                                                }}
                                                onAddNewClick={(payload) => {
                                                    payload.formData = {
                                                        firstName: payload.textEntered,
                                                        submittedOnce: false,
                                                        customerId: (this.props.customer || {}).customerId,
                                                        pageNumber: this.props.pageNumber || 1,
                                                        customerName: this.props.customerName
                                                    }
                                                    this.handleNewContactAddition(this.props, payload, MODAL_TYPE.CONTACT)
                                                }}
                                                onSelect={(contactId) => {
                                                    let contact = find(this.props.customer.boContactList, { contactId: +contactId }) || {};
                                                    this.props.updateState({
                                                        selectedContact: contact
                                                    })
                                                }}
                                            />
                                            <Tooltip placement="right" title="Customer Contact" trigger="click">
                                                <i className="fa fa-question-circle-o pl5 cursor-pointer" />
                                            </Tooltip>
                                        </Col></Row>
                                </div>
                                <div className="inner">
                                    <Row>
                                        <Col span={4} className="text-right">
                                            <div className="title">
                                                <FormattedMessage id='supplier.rfq.form.billTo.label' defaultMessage='' />
                                            </div>
                                        </Col>
                                        <Col span={20} style={{ 'text-align': 'left' }}>
                                            <Dropdown
                                                style={{ width: '70%' }}
                                                value={(this.props.customerBillingAddress || {}).streetAddress1}
                                                items={this.props.allBillingAddress}
                                                valueKeyName='streetAddress1'
                                                addressLineKey1='streetAddress2'
                                                addressLineKey2='cityName'
                                                addressLineKey3='stateName'
                                                addressLineKey4='zipCode'
                                                optionClassAddress='custom-address-dropdown'
                                                optionKeyName='locationId'
                                                canAddNew={true}
                                                canDelete={true}
                                                deleteClickHandler={(payload) => {
                                                    this.deleteClickHandler(this.props, MODAL_TYPE.BILLING_ADDRESS, payload);
                                                }}
                                                onAddNewClick={(payload) => {
                                                    payload.formData = {
                                                        locationType: CONSTANTS.BILLING_ADDRESS,
                                                        customerId: (this.props.customer || {}).customerId,
                                                        pageNumber: this.props.pageNumber,
                                                        customerName: this.props.customerName
                                                    }
                                                    this.handleNewAddressAddition(this.props, payload, MODAL_TYPE.BILLING_ADDRESS)
                                                }}
                                                onSelect={(locationId) => {
                                                    this.props.updateState({
                                                        customerBillingAddress: find(this.props.allBillingAddress, { locationId: +locationId }) || {}
                                                    })
                                                }}
                                            />
                                            <Tooltip placement="right" title="Customer Address" trigger="click">
                                                <i className="fa fa-question-circle-o pl5 cursor-pointer" />
                                            </Tooltip>
                                            {
                                                getAddressInfoFragment((this.props.customerBillingAddress || {}), this.props.customer, this.props.selectedContact || {})
                                            }
                                        </Col></Row>
                                </div>
                            </div>
                            }
                        </Col> */}
          <Col span={9}></Col>
          <Col span={5}>
          <Skeleton loading={this.props.detailLoading} active paragraph={{ rows: 5 }}>
           
              <Row gutter={[0,3]}>
             
              <Col  span={24} className='details-heading'>
              RFQ Information
              </Col>  
                
                
               
                {this.props.prNumber && (
                  <>
                  
                      <Col span={12} >                   
                          <FormattedMessage
                            id="supplier.rfq.form.prNumber.label"
                            defaultMessage=""
                          /> - {this.props.prNumber}
                       
                      </Col>
                 
                  </>
                )}
             
                  
                    <Col span={24} >
                      <FormattedMessage
                        id="supplier.rfq.listing.header.rfqDate"
                        defaultMessage=""
                      />
                    </Col>
                    <Col span={24}>
                      <DatePicker
                        allowClear={false}
                        format={format}
                        key={`${
                          this.props.rfqDate
                            ? moment(new Date(this.props.rfqDate), format)
                            : moment(new Date(), format)
                        }`}
                        defaultValue={
                          this.props.rfqDate
                            ? moment(new Date(this.props.rfqDate), format)
                            : moment(new Date(), format)
                        }
                        onChange={(selectedDate) => {
                          this.props.updateState({
                            rfqDate: selectedDate,
                          });
                          //this.props.getNextTxNumber({ rfqDate: selectedDate, relationshipId: (this.props.companyInfo || {}).relationshipId });
                        }}
                        size={size}
                      />
                    </Col>
                 
                    <Col span={24} >
                      <FormattedMessage
                        id="supplier.rfq.biddingSuppliers.rfqDetail.bidClosingDate"
                        defaultMessage=""
                      />
                    </Col>
                    <Col span={24}>
                      <DatePicker
                        allowClear={false}
                        format={format}
                        key={`${
                          this.props.expirationDate
                            ? moment(
                                new Date(this.props.expirationDate),
                                format
                              )
                            : moment(new Date(), format)
                        }`}
                        defaultValue={
                          this.props.expirationDate
                            ? moment(
                                new Date(this.props.expirationDate),
                                format
                              )
                            : moment(new Date(), format)
                        }
                        onChange={(selectedDate) => {
                          this.props.updateState({
                            expirationDate: selectedDate,
                          });
                        }}
                        size={size}
                      />
                    </Col>
                
               

               
               
                    <Col span={24} >
                      <FormattedMessage
                        id="common.suppliers"
                        defaultMessage=""
                      />
                    </Col>
                    <Col span={24}>
                      <MultipleSelectDropdown
                        items={
                          (this.props.suppliers[this.props.pageNumber || 0] || []).sort((a,b) => a.supplierStoreName?.localeCompare(b.supplierStoreName))
                        }       
                        mode="multiple"
                        canDelete={true}
                        canAddNew={true}
                        placeholder={this.props.intl.formatMessage({
                          id : "common.select.placeholder"
                        })}
                        deleteClickHandler={(payload) => {
                          const data = {
                            modalBody: (
                              <FormattedMessage
                                id="deleteItem.confirmation"
                                values={{ value: payload.text }}
                                defaultMessage=""
                              />
                            ),
                            handleSubmit: (formData = {}) => {
                              this.props.deleteSuppliers({
                                groupSupplierIds: [payload.id],
                                relationshipId: (this.props.companyInfo || {})
                                  .relationshipId,
                              });
                              this.props.hideModal(data);
                            },
                          };
                          this.props.showModal(data);
                        }}
                        onAddNewClick={(payload) => {
                          this.props.updateHeaderState({
                            supplier: {
                              supplierDrawerVisible: true,
                              supplierName: payload.textEntered,
                            },
                          });
                        }}
                        onDeSelect={(value, data) => {
                          let {
                            selectedSuppliers,
                            selectedSupplierNames,
                            selectedSupplierObjects,
                          } = this.props;
                          selectedSuppliers = filter(
                            selectedSuppliers,
                            (supplier) => {
                              return supplier !== value;
                            }
                          );

                          selectedSupplierNames = filter(
                            selectedSuppliers,
                            (supplier) => {
                              return supplier !== value;
                            }
                          );

                          selectedSupplierObjects =
                            selectedSupplierObjects.filter((el) => {
                              return el.supplierName !== value;
                            });

                          this.props.updateState({
                            selectedSuppliers,
                            selectedSupplierNames,
                            selectedSupplierObjects,
                          });
                        }}
                        onSearch={(searchedText) => {
                          this.props.fetchSuppliers({
                            searchedText: searchedText,
                            pageNumber: 1,
                            pageSize: 100,
                            relationshipId: (this.props.companyInfo || {})
                              .relationshipId,
                            // hideLoad:true  
                          });
                        }}
                        valueKeyName="supplierStoreName"
                        selectedValue={this.props.selectedSuppliers}
                        optionKeyName="supplierId"
                        onSelect={(supplierId, data) => {
                          this.props.fetchSuppliers({
                            pageNumber: 1,
                            hideSpinner: true,
                            pageSize: 100,
                            relationshipId: (this.props.companyInfo || {})
                              .relationshipId,
                            // hideLoad:true  

                          });
                          let selectedSupplierObjects =
                            this.props.selectedSupplierObjects || [];
                          let selectedSuppliers =
                            this.props.selectedSuppliers || [];
                          let selectedSupplierNames =
                            this.props.selectedSupplierNames || [];
                          if (selectedSuppliers.indexOf(supplierId) === -1) {
                            selectedSupplierNames.push(data.name);
                            selectedSuppliers.push(supplierId);
                            selectedSupplierObjects.push({
                              relationshipId: (this.props.companyInfo || {})
                                .relationshipId,
                              supplierId: supplierId,
                              supplierName: data.name,
                              supplierStoreName: data.name,
                            });
                          } else {
                            selectedSuppliers = selectedSuppliers.filter(
                              (el) => {
                                return el !== supplierId;
                              }
                            );
                            selectedSupplierObjects =
                              selectedSupplierObjects.filter((el) => {
                                return el.supplierId !== supplierId;
                              });
                            selectedSupplierNames =
                              selectedSupplierNames.filter((el) => {
                                return el !== data.name;
                              });
                          }
                          this.props.updateState({
                            selectedSuppliers,
                            selectedSupplierObjects,
                            selectedSupplierNames,
                          });
                        }}

                        // onSelect={(supplierId) => {
                        //     const sup = find(this.props.suppliers[(this.props.pageNumber || 0)], { supplierId});
                        //     let isSupp = find((this.props.selectedSuppliers || []), {supplierId});
                        //     let selectedSuppliers = this.props.selectedSuppliers || [];
                        //     if (!isSupp || !selectedSuppliers.length) {
                        //         selectedSuppliers.push(sup);
                        //     } else {
                        //         selectedSuppliers = selectedSuppliers.filter((el) => {
                        //             return el.supplierId !== supplierId
                        //         });
                        //     }
                        //     this.props.updateState({
                        //         selectedSuppliers
                        //     });
                        // }}
                      />
                    </Col>
                 
              

             
                
                    <Col span={24} >
                      <Tooltip
                        placement="bottom"
                        // title={
                        //   <FormattedMessage
                        //     id="documentTitle.tooltip"
                        //     defaultMessage=""
                        //   />
                        // }
                      >
                        <span className="">
                          <FormattedMessage
                            id="customer.salesOrder.form.documentDropdown.label"
                            defaultMessage=""
                          />
                        </span>
                      </Tooltip>
                      <Tooltip
                    placement="right"
                    title={
                      <FormattedMessage
                        id="documentTitle.tooltip"
                        defaultMessage=""
                      />
                    }
                    trigger="click"
                  >
                    <QuestionCircleTwoTone />
                     </Tooltip>
                    </Col>
                    <Col span={24}>
                      <Dropdown
                        style={{ width: "100%" }}
                        items={filter(
                          this.props.documentList || [],
                          (document) => {
                            return (
                              document.txType ===
                              CONSTANTS_TRANSACTIONS_KEY[
                                CONSTANTS_TRANSACTIONS.RFQ
                              ]
                            );
                          }
                        )}
                        placeholder={this.props.intl.formatMessage({
                          id: "setting.documentName.label",
                          defaultMessage: "",
                        })}
                        value={this.props.docName}
                        canAddNew={true}
                        canDelete={true}
                        deleteClickHandler={(payload) => {
                          this.deleteClickHandler(
                            this.props,
                            MODAL_TYPE.DOCUMENT_NAME,
                            payload
                          );
                        }}
                        onAddNewClick={(payload) => {
                          payload.formData = {
                            textBoxValue: payload.textEntered,
                            submittedOnce: false,
                            maxLength: 30,
                          };
                          this.handleNewItemAddition(
                            this.props,
                            payload,
                            MODAL_TYPE.DOCUMENT_NAME
                          );
                        }}
                        onSelect={(selectedValue) => {
                          const selectedObj = find(
                            this.props.documentList || [],
                            { documentNameId: Number(selectedValue) }
                          );
                          this.props.updateState({
                            documentNameId: selectedValue,
                            docName: selectedObj.docName,
                          });
                        }}
                        valueKeyName="docName"
                        optionKeyName="documentNameId"
                        isLockKeyName="isSystemGenerated"
                      />
                    </Col>
            
               
              </Row>
          </Skeleton>
          </Col>
        </Row>

        <Row>
        <Skeleton loading={this.props.detailLoading} active paragraph={{ rows: 2 }}>

            <Col span={24}>
            <div className='txn-table'>
                      <table>
                        <thead>
                          <tr>
                            {/* <th><FormattedMessage id='customer.salesOrder.form.gridHeader.placeOfSupply' defaultMessage='' /></th> */}
                            <th width="10%"><FormattedMessage id='supplier.rfq.form.gridHeader.refNumber' defaultMessage='' /></th>
                            <th width="10%"><FormattedMessage id='supplier.rfq.form.gridHeader.salesPerson' defaultMessage='' /></th>
                            <th width="10%"><FormattedMessage id='supplier.rfq.form.gridHeader.priority' defaultMessage='' /></th>
                            <th width="10%"><FormattedMessage id='supplier.rfq.form.gridHeader.project' defaultMessage='' /></th>
                            </tr>
                        </thead>
                        <tbody>
                          <tr>
                            {/* <td>
                              <Dropdown
                                items= {this.props.states || []}
                                optionKeyName= 'stateId'
                                valueKeyName= 'stateName'
                                onSelect={ (selectedValue) => {
                                  this.props.updateState({ placeOfSupply: selectedValue });
                                }}
                              
                              />
                            </td> */}
                            

                            <td>
                              <TextBox
                              value={this.props.refNumber}
                              placeholder="Ref #"
                                  maxLength= {20}
                                  onChange= {(val) => {

                                    this.props.updateState({
                                      refNumber: val.target.value,
                                    });
                                  }}
                                
                              />
                            </td>
                            <td>
                              <Dropdown
                                lastColumnCell= {false}
                                items= {this.props.employees || []}
                                optionKeyName= {"relationshipEmployeeId"}
                              //  defaultValue={(this.props.employees || []).length ? (this.props.salesPersonName || this.props.salesPersonId || null) : ""}
                                defaultValue = {(this.props.employees || []).length ? this.props.salesPersonName : ""}
                                valueKeyName= {"fullName"}
                                value={this.props.salesPersonName}
                                canAddNew= {true}
                                canDelete= {true}
                                allowClear= {true}
                                placeholder={this.props.intl.formatMessage({
                                  id : "common.select.placeholder"
                                })}
                                deleteClickHandler= {(payload) => {
                                  this.deleteClickHandler(this.props, MODAL_TYPE.EMPLOYEE, payload);
                                }}
                                onAddNewClick= {(payload) => {
                                  // payload.formData = {
                                  //     firstName: payload.textEntered,
                                  //     submittedOnce: false
                                  // }
                                  // this.handleNewEmployeeAddition(this.props, payload, MODAL_TYPE.EMPLOYEE)
                                  this.props.updateHeaderState({
                                    company: {
                                      ...this.props.company,
                                      employeeDrawerVisible: true,
                                    },
                                  });
                                }}
                                onSelect= {(selectedValue, option) => {
                                  //obj.node.setDataValue(obj.colDef.field, selectedValue);
                                //   this.props.salesPerson = selectedValue;
                                  this.props.updateState({
                                    salesPersonId: option.key,
                                    salesPersonName: option.name,
                                  });
                                }}
                              
                                onClear= {() => {
                                //   this.props.setDataValue(this.props.colDef.field, undefined);
                                //   this.props.salesPerson = "";
                                  this.props.updateState({
                                    salesPersonId: undefined,
                                    salesPersonName: undefined
                                  });
                                //   obj.api.stopEditing(true);
                                }}
                              />
                            </td>
                            <td>
                            <Dropdown
                          items={CONSTANTS.PRIORITIES}
                          defaultValue={this.props.priority}
                          value={this.props.orderPriority}
                          placeholder={this.props.intl.formatMessage({
                            id : "common.select.placeholder"
                          })}
                          onSelect={(selectedValue, option) => {
                            this.props.updateState({
                                orderPriority: selectedValue
                            });
                          }}
                          allowClear={true}
                          onClear={() => {
                            this.props.updateState({
                                orderPriority: undefined
                            });
                          }
                          }>
                          
                        </Dropdown>
                            </td>
                            <td>
                            <Dropdown
                            lastColumnCell= {false}
                            items= {this.props.projectList || []}
                            optionKeyName= {"projectMasterId"}
                            valueKeyName= {"projectName"}
                            projectIdKey= {"projectMasterId"}
                            canAddNew= {true}
                            value={this.props.projectName}
                            canDelete= {true}
                            placeholder={this.props.intl.formatMessage({
                              id : "common.select.placeholder"
                            })}
                            deleteClickHandler= {(payload) => {
                              this.deleteClickHandler(this.props, MODAL_TYPE.PROJECT, payload);
                            }}
                            onAddNewClick= {(payload) => {
                              payload.formData = {
                                projectName: payload.projectName,
                              };
                              this.handleNewProjectAddition(
                                this.props,
                                payload,
                                MODAL_TYPE.PROJECT
                              );
                            }}
                            onSelect= {(selectedValue, option) => {
                              const selectedProject =
                                find(this.props.projectList, {
                                  projectMasterId: Number(option.key || 0),
                                }) || {};
                              //obj.node.setDataValue(obj.colDef.field, selectedValue);
                              this.props.updateState({
                                projectNumber: selectedProject.projectNumber,
                                projectName: selectedProject.projectName,
                                projectMasterId: selectedProject.projectMasterId,
                              });
                            }}
                            allowClear= {true}
                            onClear= {() => {
                            //    obj.node.setDataValue(obj.colDef.field, undefined);
                              this.props.updateState({
                                projectNumber: undefined,
                                projectName: undefined,
                                projectMasterId: undefined,
                              });
                              
                            }}
                            />
                            </td>
                            
                          

                          </tr>
                        </tbody>
                      </table>
                    </div>
            </Col>
            </Skeleton>
        </Row>
        {/* <Row>
          <CustomAgGrid
            columnDefs={columnDefs}
            onGridReady={onGridReady}
            rowData={[{}]}
            gridStyle={{
              width: "100%",
              height: "100px",
            }}
          />
        </Row> */}
      </>
    );
  }
}

const getAddressInfoFragment = (obj, companyObj, customerContactObj) => {
  // if (!obj || !Object.keys(obj).length) {
  //     return '';
  // }

  let contactName = "";
  if (customerContactObj.salutationName)
    contactName += customerContactObj.salutationName + " ";
  if (customerContactObj.firstName) contactName += customerContactObj.firstName;
  if (customerContactObj.middleName)
    contactName += " " + customerContactObj.middleName;
  if (customerContactObj.lastName)
    contactName += " " + customerContactObj.lastName;

  const fragmentObj = (
    <Fragment>
     <div className="billing-address first-caps">
        {obj.streetAddress1 || obj.streetAddress2 ? (
          <div>
            {obj.streetAddress1} {obj.streetAddress2}{", "}
          </div>
        ) : ""}
        {obj.cityName || obj.stateName || obj.zipCode ? (
          <div>
            {obj.cityName} {obj.stateName}{obj.stateName && obj.zipCode ? ", " : ""}{obj.zipCode}
          </div>
        ) : ""}
        {obj.countryName ? (
          <div>{obj.countryName}</div>
        ) : ""}
      </div>
      {customerContactObj.firstName ? (
        <div className="billing-address">{contactName}</div>
      ) : (
        ""
      )}
      {customerContactObj.workPhone ? (
        <div className="billing-address">
          <FormattedMessage
            id="supplier.rfq.form.phone.label"
            defaultMessage=""
          />{" "}
          - {customerContactObj.workPhone}
        </div>
      ) : (
        ""
      )}
      {customerContactObj.email ? (
        <div className="billing-address">
          <FormattedMessage
            id="supplier.rfq.form.email.label"
            defaultMessage=""
          />{" "}
          - {customerContactObj.email}
        </div>
      ) : (
        ""
      )}
      {/* {
            companyObj.gstNumber
                ? <div className='billing-address'>
                    <FormattedMessage id='supplier.rfq.form.gstnIn.label' defaultMessage='' /> - {companyObj.gstNumber}
                </div> :
                ''
        }
        {
            companyObj.panNumber
                ? <div className='billing-address'>
                    <FormattedMessage id='supplier.rfq.form.pan.label' defaultMessage='' /> - {companyObj.panNumber}
                </div> :
                ''
        } */}
    </Fragment>
  );

  return fragmentObj;
};

export default injectIntl(RFQDetails);
