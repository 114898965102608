import React from "react";
import LeadWorkflowList from "../../../../components/leads/LeadWorkflow/Lead";
import LeadWorkflowCreateDrawer from "../../../drawer/leads/LeadWorkflow";
import { getAllLeadSources } from "../../../modal/modalBody/leads/createLead/action";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createLeadSource } from "../../../drawer/leads/leadSourceList/action";
import { getAllWorkFlow } from "./action";
import { showModal, hideModal } from "../../../../actions/commonActions";

class LeadWorkflowListComp extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      pageNumber: 0,
      pageSize: 100,
    };
  }

  componentDidMount() {
    const companyInfo = this.props.companyInfo || {};

    const payload = {
      relationshipId: companyInfo.relationshipId,
      pageNumber: 0,
      pageSize: 100,
    }
    this.props.getAllWorkFlow(payload);
  }

  render() {
    return (
      <>
        <LeadWorkflowList {...this.props} {...this.state} updateState={(data) => {this.setState(data)}}/>

       {this.state.leadSourceListDrawer && <LeadWorkflowCreateDrawer {...this.state} {...this.props} updateState={(data) => { this.setState(data) }} />}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
      companyInfo: state.common.companyInfo,
      userInfo: state.common.userInfo,
      crmLeadSourceList: state.crm.crmLeadSourceList,
      leadWorkflowList:state.crm.leadWorkflowList,
    permissions: state.auth.permissions,
    listLoading: state.common.listLoading

  }
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
  getAllLeadSources,
  createLeadSource,
  showModal,
  hideModal,
   getAllWorkFlow
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(LeadWorkflowListComp);
