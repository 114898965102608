import React from 'react';
import { injectIntl } from 'react-intl';
import { Rate, Card, Empty } from 'antd';
import { getAWSFileURL } from '../../../../../utils';

const RatingPreviewComponent = (props) => {
    const { product } = props
    const imageVal = (product?.ecomFeedbackImages || [])?.map((val) => {
        return getAWSFileURL(val.image, props.companyInfo.bucketName, val.folderName)
    })
    return (
        <div className='agGridWrapper'>
            <div className="modal-table-container">
                
                <div className='review-modal'>
                    <div className='image_container'>
                        {imageVal && imageVal.length > 0 ? (
                            imageVal.map((imageUrl, index) => (
                                <img key={index} src={imageUrl} alt={`Image ${index}`} />
                               
                            ))
                        ) : (
                            <Empty />
                        )}
                    </div>
                    <div className='mt10'>{product.message}</div>
                    <div className='mt10'>
                        <Rate disabled value={product.rating} />
                    </div>
                </div>

            </div>

        </div>
    );
};

export default injectIntl(RatingPreviewComponent);
