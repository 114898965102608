import React, { useMemo } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
// import PageBreadcrumb from "../../PageBreadcrumb";
import { checkACLPermission, getMomentDateForUIReadOnly, getPermissionData, getTimeBetweenDates, sortTable } from "../../../utils";
import { Menu, Switch, Button, Tabs } from "antd";
import DropdownAction from "antd/lib/dropdown";
import { ICONS, MODULE_CODED_VALUES, PERMISSION_VALUES } from "../../../static/constants";
import PageBreadcrumb from "../../PageBreadcrumb";
import LeadAllCallLogsComp from "./allLogs"
import LeadMyCallLogsComp from "./myLogs"

const LeadCallLogsComp = (props) => {
  const { dir = 0 } = props;
  const { TabPane } = Tabs;
  const breadCrumbList = [
    {
      name: <FormattedMessage id="sidebar.menuItems.Leads" defaultMessage="" />,
    },
    {
      name: "Call Logs",
    },

  ];

  const sortColumn = (e) => {
    let cellIndex = e.target.cellIndex;
    if (!cellIndex) {
      cellIndex = e.target.parentElement.cellIndex;
    }
    if (!cellIndex) {
      cellIndex = e.target.parentElement.parentElement.cellIndex;
    }
    sortTable(document.getElementById("leadSource-table"), cellIndex, 1 - dir);
  };

  const actionMenu = (item) => {
    return (
      <Menu className="row-action-dropdown">
        <Menu.Item key="0" value="title">
          Actions
        </Menu.Item>
        <Menu.Divider />

        <Menu.Item
          key="1"
          value="edit"
          onClick={() => {
            props.updateState({
              leadSourceListDrawer: true,
              ...item,
            });
          }}
        >
          <i className={ICONS.EDIT} /> Edit
        </Menu.Item>

        <Menu.Item
          key="2"
          value="delete"
          onClick={() => {
            props.showModal({
              modalBody: "Are you sure you want to delete?",
              handleSubmit: () => {
                props.deleteCrmLeadSource(item, props);
              },
            });
          }}
        >
          <i className={ICONS.DELETE} /> Delete
        </Menu.Item>
      </Menu>
    );
  };
  const callTabApi = (key, props) => {
    const payload = {
      relationshipId: props.companyInfo.relationshipId,
      pageNumber: 0,
      createdByUserId: props.companyInfo?.relationshipEmployees?.relationshipEmployeeId,
      pageSize: 25,
    };
// fetchCallLogsByRid,fetchCallLogsByUserId
    if (key === 'all_logs') {
      // will do something
      props.fetchCallLogsByRid(payload);
      props.updateState({
        isMyLeads: false,
      })
    } else if (key === 'my_logs') {
      //will do something
      props.fetchCallLogsByUserId(payload);
      props.updateState({
        isMyLeads: true,
      })
    }


  }

  const { permissions } = props;
  const primaryPerm = (permissions && permissions.primary) || [];

  const permissionsData = useMemo(()=>{
    return getPermissionData(primaryPerm ,   MODULE_CODED_VALUES.CRM_CALL_LOGS_LIST);
  },[])
 
  return (
    // <div className="lead-list">
    <>
      <div className="view-top-bar">
        <div className="top-view-left">
          <div className="page-heading">
            Call Logs
          </div>
          <div className="vertical-sep" />
          <PageBreadcrumb breadCrumbList={breadCrumbList} />
        </div>
        <div className="top-view-right">

        </div>
      </div>
      <div className="view-container">
     {permissionsData.create ?  <Tabs type="line"
          onChange={(key) => {
            callTabApi(key, props);
          }}
        >
          <TabPane
            tab="All Call Logs"
            key="all_logs"
          >
            <LeadAllCallLogsComp {...props} />

          </TabPane>
          <TabPane
            tab="My Call Logs"
            key="my_logs"
          >
            <LeadMyCallLogsComp {...props} />

          </TabPane>

        </Tabs>:
            <LeadMyCallLogsComp {...props} />
        
        }
      </div>
    </>
  );
};

export default injectIntl(LeadCallLogsComp);
