import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import MonthlyInvSummaryListingComp from '../../../../components/inventory/InventoryValuation/monthlyInvSummary';
import { getFinancialYear } from '../../../../utils';
import { fetchMonthlyInventoryValuationList } from './action';
// import { fetchWarehouseNames} from '../../../../drawer/inventory/action';


class InvValuationMonthlyListing extends Component {
  constructor(props) {
    super(props);
    const {companyInfo} = this.props;
    const FY = getFinancialYear(companyInfo) || {fyStartDate: '', fyEndDate: ''};
    const {startDate, endDate, variantId} = props.location?.state;
    this.state = {
      warehouseMasterId : 0,
      pageSize: 100,
      pageNumber: 1,
      isDataRendered: false,
      startDate: startDate || FY.fyStartDate,
      endDate: endDate || FY.fyEndDate,
      variantId: variantId,
    };
  }

  componentDidMount() {
    const companyInfo = this.props.companyInfo || {};
    const payload = {
      relationshipId: companyInfo.relationshipId,
      pageNumber: 1,
      pageSize: 100,
      warehouseMasterId : 0,
      filters: {
        startDate: this.state.startDate,
        endDate: this.state.endDate,
        variantId: this.state.variantId
      }
    };
    this.props.fetchMonthlyInventoryValuationList(payload);
  }

  render() {
    return (
      <MonthlyInvSummaryListingComp {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
    )
  }
}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    monthlyInventoryValuationList: state.inventoryValuation.monthlyInventoryValuationList,
    listLoading: state.common.listLoading,
    permissions: state.auth.permissions,

  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchMonthlyInventoryValuationList
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(InvValuationMonthlyListing);
