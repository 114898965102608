import React from "react";
import InPageMenu from "../../Common/InPageMenu";
import PageBreadcrumb from "../../PageBreadcrumb";
import { FormattedMessage } from "react-intl";
import { ICONS, MODULE_CODED_VALUES } from '../../../static/constants';

import Leave from '../../../assets/images/leave-icon.svg';

import WorkShiftContainer from '../../../containers/company/AttendanceMeta/WorkShift';

function AttendanceMeta(props) {
    // const { path } = useRouteMatch();

    const handleActiveTab = (index) => {
        props.updateState({
            selectedMenuItem: index
        })
    };

    const activeTab = () => {
        switch (props.selectedMenuItem) {
            case 0:
                return <WorkShiftContainer />;
         
          
            default:
                return null;
        }
    };
    const breadCrumbList = [
        {
            name: <FormattedMessage id='employeeMeta.pageName.dashboard' defaultMessage='' />,
        },
        {
            name: <FormattedMessage id='attendanceMeta.pageName.attendance' defaultMessage='' />,
        },
        {
            name: <FormattedMessage id='employeeMeta.pageName.meta' defaultMessage='' />,
        }
    ];
    const menuList = [
 
    {
        icon: Leave,
        name: "attendanceMeta.menulabel.workShift",
        description: "You are powered with creating multiple users for your business.",
        moduleCode:MODULE_CODED_VALUES.COMPANY_HIERARCY,

    },

    ]
    return (
        <><div className="view-top-bar">
            <div className="top-view-left">
                <div className="page-heading">
                    <FormattedMessage
                        id="heading.team.attendanceMeta"
                        defaultMessage="" />
                </div>
                <div className="vertical-sep" />
                <PageBreadcrumb breadCrumbList={breadCrumbList} />
            </div>
        </div>

            <div className="meta-container">
                <div className="meta-sidenav">
                    <InPageMenu menuList={menuList} onItemClick={handleActiveTab} />
                </div>
                <div className="meta-main-body">
                    {activeTab()}
                    {/* <Switch>
                        {menuList.map((menuItem, index) => {
                            return (
                                <Route key={index} path={`${path}/a`} component={menuItem.component || 'none'} />
                            );
                        })}
                    </Switch> */}
                </div>
            </div></>
    )
}
export default AttendanceMeta;