import axios from 'axios';
import config from '../../../../../config/environmentConfig';
import { SHIPMENT_ACTION_LIST, COMMON_ACTIONS } from '../../../../../static/constants';
import { showToasterMessage } from '../../../../../utils';
const { lang } = require('../../../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');

export const getCarriersPrice = (payload) => {
  return dispatch => {
    dispatch({ type: SHIPMENT_ACTION_LIST.LOADING_CARRIAR_LIST, data: true });
    return axios.post(`${config.API_BASE_URL}/${config.rootContextEasyPost}/epCarrier/getRate`, payload)
      .then(response => {
        let data = response.data?.rates || {};
        dispatch({ type: SHIPMENT_ACTION_LIST.CARRIARS_PRICE_LIST, data });
      })
      .catch(err => {
        showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
      })
      .finally(()=>{
        dispatch({ type: SHIPMENT_ACTION_LIST.LOADING_CARRIAR_LIST, data: false });
      })
  }
}

export const getCarrierDetails = (payload) => {
  return dispatch => {
    dispatch({ type: SHIPMENT_ACTION_LIST.LOADING_CARRIAR_LIST, data: true });
    return axios.post(`${config.API_BASE_URL}/${config.rootContextEasyPost}/epCarrier/getRate`, payload)
      .then(response => {
        let data = response.data;
        dispatch({ type: SHIPMENT_ACTION_LIST.CARRIER_DETAILS, data });
      })
      .catch(err => {
        showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
      })
      .finally(()=>{
        dispatch({ type: SHIPMENT_ACTION_LIST.LOADING_CARRIAR_LIST, data: false });
      })
  }
}

export const createLabel = (payload) => {
    return dispatch => {
      return axios.post(`${config.PAYMENT_SERVER_BASE_URL}/shipstation/createlabel`,
        payload, {
        headers: {
            'content-type': 'application/json'
        },
        cors: true,
        contentType: 'application/json',
        secure: true,
      })
      .then(response => {
          dispatch({ type: SHIPMENT_ACTION_LIST.LOADING_CARRIAR_LIST, data: false });
      })
      .catch(err => {
          dispatch({ type: SHIPMENT_ACTION_LIST.LOADING_CARRIAR_LIST, data: false });
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
          ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
    }
}

export const buyShipment = (payload, props) => {
    return dispatch => {
        dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
        return axios.post(`${config.API_BASE_URL}/${config.rootContextEasyPost}/epCarrier/buyShipment`, payload)
            .then(res => {
                let data = res.data
                if (props.fetchAllShipment) {
                    props.fetchAllShipment({
                      companyInfo: (props.companyInfo || {}),
                      relationshipId: (props.companyInfo || {}).relationshipId,
                      pageNumber: props.pageNumber || 1,
                      pageSize: props.pageSize || 25
                    })
                  }
                dispatch({ type: SHIPMENT_ACTION_LIST.BUY_SHIPMENT, data });
                dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
            })
            .catch(err => {
                dispatch({ type: SHIPMENT_ACTION_LIST.BUY_SHIPMENT});
                showToasterMessage({ messageType: 'error', description: lang[((err.res || {}).data || {}).message] || 'Some error occurred' })
                dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
                ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
            })
    }
}

export const getShipment = (payload) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_DETAIL_LOADER });
    return axios.get(`${config.API_BASE_URL}/${config.rootContextEasyPost}/epCarrier/getShipment?relationshipId=${payload.relationshipId}&shipmentId=${payload.shipmentId}`)
    .then(res => {
      let data = res.data
      dispatch({ type: SHIPMENT_ACTION_LIST.BUY_SHIPMENT, data });
    })
    .catch(err => {
        dispatch({ type: SHIPMENT_ACTION_LIST.BUY_SHIPMENT});
        showToasterMessage({ messageType: 'error', description: lang[((err.res || {}).data || {}).message] || 'Some error occurred' })
    })
    .finally(()=>{
      dispatch({ type: COMMON_ACTIONS.HIDE_DETAIL_LOADER });
    })
  }
}


export const getCarrierPricesShiprocket = (payload) => {
  return dispatch => {
    dispatch({ type: SHIPMENT_ACTION_LIST.LOADING_CARRIAR_LIST, data: true });
    
    const headers = {
      "relationshipId": "73",
      "Content-Type": "application/json"
    };

    return axios.post(`${config.API_BASE_URL}/${config.rootContextShiprocket}/${config.API_VERSION_SHIPROCKET}/shipment/getRates`, payload, {headers})
      .then(response => {
        if(response.data?.status !== 200){
          throw new Error(`${response.data?.message || 'Some Error Occured'}`);
        }
        dispatch({ type: SHIPMENT_ACTION_LIST.SHIPROCKET_CARRIER_DETAILS, data: response.data?.data || {} });
      })
      .catch(err => {
        showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
      })
      .finally(()=>{
        dispatch({ type: SHIPMENT_ACTION_LIST.LOADING_CARRIAR_LIST, data: false });
      })
  }
}


export const createShiprocketShipmentOrder = (payload, props) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_SAVE_LOADER });
    
    const headers = {
      "relationshipId": "73",
      "Content-Type": "application/json"
    };

    return axios.post(`${config.API_BASE_URL}/${config.rootContextShiprocket}/${config.API_VERSION_SHIPROCKET}/orders/createOrder`, payload, {headers})
      .then(res => {
        let data = res.data
        if (props.fetchAllShipment) {
          props.fetchAllShipment({
            companyInfo: (props.companyInfo || {}),
            relationshipId: (props.companyInfo || {}).relationshipId,
            pageNumber: props.pageNumber || 1,
            pageSize: props.pageSize || 25
          })
        }
        dispatch({ type: SHIPMENT_ACTION_LIST.BUY_SHIPMENT, data });
      })
      .catch(err => {
          dispatch({ type: SHIPMENT_ACTION_LIST.BUY_SHIPMENT});
          showToasterMessage({ messageType: 'error', description: lang[((err.res || {}).data || {}).message] || 'Some error occurred' })
      })
      .finally(()=>{
        dispatch({ type: COMMON_ACTIONS.HIDE_SAVE_LOADER });
      })
  }
}