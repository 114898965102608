import React, { useState } from "react";
import { FormattedMessage } from 'react-intl';
import { Row, Col, Button } from 'antd'
import { Dropdown } from "../../../../general/Dropdown";
import { CONSTANTS, LIST_CONSTANTS } from "../../../../../static/constants";
import { TextBox } from "../../../../general/TextBox";
import './index.scss';


const LabelConfig = (props) => {

    const {labelConfig ,permissionsData} = props;

    const [educationLabel, setEducationLabel] = useState(labelConfig.educationLabel || {});

    const handleTextBox = (field, value) =>{
        setEducationLabel(pre=>({
            ...pre,
            [field]: value
        }));
    }

    const handleSubmit = ()=>{

        const payload ={
            "eduType":labelConfig.eduType,
            "educationLabel":{
                "department": educationLabel?.department,
                "subject": educationLabel?.subject,
                "term": educationLabel?.term,
                "course": educationLabel?.course,
                "section": educationLabel?.section,
                "class": educationLabel?.class
            }
        }
        props.saveEducationLabelInfo(payload ,props)
    }
 
    return (
        <>
<div className="popup-tabs">

            <Row gutter={[16, 16]}>
                <Col span={12}>
                    <div className="i-label">
                        Education Type
                    </div>
                    <Dropdown
                        items = {LIST_CONSTANTS.EDUCATION_TYPES}
                        placeholder="Education Type"
                        value={labelConfig.eduType || ''}
                        onSelect={(val) => {
                            setEducationLabel(pre=>({
                                ...pre,
                                eduType: val
                            }));
                        }}
                    />
                </Col>

                <Col span={12}>
                    <div className="i-label">
                        Class
                    </div>
                    <TextBox
                        value={educationLabel.class || ''}
                        maxLength={20}
                        countStyle={{top:"38px"}}
                        placeholder="e.g. Play Wing, Pre-Nursery…"
                        onChange={(e)=>handleTextBox('class', e.target.value)}
                    />
                </Col>

                <Col span={12}>
                    <div className="i-label">
                        Course
                    </div>
                    <TextBox
                        value={educationLabel.course || ''}
                        maxLength={20}
                        countStyle={{top:"38px"}}
                        placeholder="e.g. Play Wing, Pre-Nursery…"
                        onChange={(e)=>handleTextBox('course', e.target.value)}
                    />
                </Col>

                <Col span={12}>
                    <div className="i-label">
                        Department
                    </div>
                    <TextBox
                        value={educationLabel.department || ''}
                        maxLength={20}
                        countStyle={{top:"38px"}}
                        placeholder="eg. Department"
                        onChange={(e)=>handleTextBox('department', e.target.value)}
                    />
                </Col>

                <Col span={12}>
                    <div className="i-label">
                        Section
                    </div>
                    <TextBox
                        value={educationLabel.section || ''}
                        maxLength={20}
                        countStyle={{top:"38px"}}
                        placeholder="e.g. Mighty Kids, Rainbow etc.."
                        onChange={(e)=>handleTextBox('section', e.target.value)}
                    />
                </Col>

                <Col span={12}>
                    <div className="i-label">
                        Subject
                    </div>
                    <TextBox
                        value={educationLabel.subject || ''}
                        maxLength={20}
                        countStyle={{top:"38px"}}
                        placeholder="e.g. Hindi, English, GK etc.."
                        onChange={(e)=>handleTextBox('subject', e.target.value)}
                    />
                </Col>

                <Col span={12}>
                    <div className="i-label">
                        Term
                    </div>
                    <TextBox
                        value={educationLabel.term || ''}
                        maxLength={20}
                        countStyle={{top:"38px"}}
                        placeholder="e.g. 1 Year Session..."
                        onChange={(e)=>handleTextBox('term', e.target.value)}
                    />
                </Col>
            </Row>
            {props.isLabelsUpdated ? 
                <div className="required">
                    <i className="fi fi-rr-triangle-warning mr10" /> 
                    <span>
                        <FormattedMessage id="labelupdate.warning.message" defaultMessage="" />
                    </span>
                </div>
                :
                ''
            }
         
            <div className="popup-btn">
                <Button>
                    Reset
                </Button>
                <Button
                    type="primary"
                    disabled={!(permissionsData.update)}

                     onClick={handleSubmit}
                >
                    <FormattedMessage id='button.save.label' defaultMessage='' />
                </Button>
            </div>
            </div>
        </>
    )
};

export default LabelConfig;