import React, { useMemo } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import "../leads.scss";
import { Menu, Switch, Pagination, Checkbox,Skeleton } from "antd";
import FormbuilderComp from "../../../containers/leads/Formbuilder";
import { checkACLPermission, getMomentDateForUIReadOnly, getPermissionData, sortTable } from "../../../utils";
import DropdownAction from "antd/lib/dropdown";
import { CONSTANTS, ICONS, MODULE_CODED_VALUES, PERMISSION_VALUES } from "../../../static/constants";
import PageHeader from "../../Common/pageHeader";

const LeadForm = (props) => {
  const {

    updateState = "false",
    companyInfo,
    userInfo,
    pageNumber = 1,
    pageSize = 25,
    crmLeadFormList,
    crmLeadFormListCount,
    dir = 0,
    permissions
  } = props;
  const primaryPerm = (permissions && permissions.primary) || [];

  const permissionsData = useMemo(()=>{
    return getPermissionData(primaryPerm ,  MODULE_CODED_VALUES.LEAD_FROM_SETTING);
  },[])
  

  const breadCrumbList = [
    {
      name: <FormattedMessage id="sidebar.menuItems.Leads" defaultMessage="" />,
    },
    {
      name: (
        <FormattedMessage id="sidebar.menuItems.LeadForm" defaultMessage="" />
      ),
    },
    {
      name: (
        <FormattedMessage id="sidebar.menuItems.LeadForm" defaultMessage="" />
      ),
    },
  ];

  const sortColumn = (e) => {
    let cellIndex = e.target.cellIndex;
    if (!cellIndex) {
      cellIndex = e.target.parentElement.cellIndex;
    }
    if (!cellIndex) {
      cellIndex = e.target.parentElement.parentElement.cellIndex;
    }
    sortTable(document.getElementById("lead-form-table"), cellIndex, 1 - dir);
  };

  const showTotal = (total) => {
    return (
      props.intl.formatMessage({
        id: "pagination.text.total",
        defaultMessage: "",
      }) +
      ` ${total || 0} ` +
      props.intl.formatMessage({
        id: "pagination.text.items",
        defaultMessage: "",
      })
    );
  };

  const actionMenu = (e) => {
    return (
      <Menu className="row-action-dropdown">
        <Menu.Item key="0" value="title">
          Actions
        </Menu.Item>
        <Menu.Divider />
{
  permissionsData?.update ?  <Menu.Item
  key="1"
  value="edit"
  onClick={() => {
    let tempFormFieldsSetting = JSON.parse(e.formFieldsSetting);
    let temp = [];
    for (let i = 0; i < tempFormFieldsSetting.length; i++) {
      if (
        tempFormFieldsSetting[i].label !== "Full Name" &&
        tempFormFieldsSetting[i].label !== "Email" &&
        tempFormFieldsSetting[i].label !== "Mobile No."
      ) {
        temp.push(tempFormFieldsSetting[i]);
      }
    }
    e.formFieldsSetting = JSON.stringify(temp);
    openCreateForm(e);
  }}
>
  <i className={ICONS.EDIT} /> Edit
</Menu.Item> : ""
}
       
{
  permissionsData?.delete ?  <Menu.Item
  key="2"
  value="delete"
  onClick={() => {
    props.showModal({
      modalBody: "Are you sure want to delete?",
      handleSubmit: () => {
        props.deleteCrmLeadFormListItem(e, props);
      },
    });
  }}
>
  <i className={ICONS.DELETE} /> Delete
</Menu.Item>: ""
}
       
      </Menu>
    );
  };


  const openCreateForm = (formData) => {
    props.pushModalToStack({
      modalBody: <FormbuilderComp {...props} leadFormData={formData} />,
      width: "100%",
      hideTitle: true,
      hideFooter: true,
      wrapClassName: "full_modal",
      alertCancel: true,
      onCancel: () => {
        props.showModal({
          modalBody: "All changes will be erased. Are you sure want to close?",
          handleSubmit: () => {
            props.popModalFromStack();
          },
        });
      },
    });
  };

  const loadPageData = (pageNumber, pageSize) => {
    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      relationshipEmployeeId: userInfo.relationshipEmployeeId,
      pageNumber: pageNumber || 1,
      pageSize: pageSize || 200,
      formId: (props.selectedForm || {}).registrationFormSettingId || 0,
    };
    // props.getRegisteredMemberByFormId(payload);
    // props.getRegisteredMemberCount(payload);
    props.fetchCrmLeadFormList(payload);
  };

  const handlePageSizeChange = (pageNumber, pageSize, props) => {

    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      // relationshipEmployeeId: userInfo.relationshipEmployeeId,
      pageNumber: pageNumber || 1,
      pageSize: pageSize || 200,
      // formId: (props.selectedForm || {}).registrationFormSettingId || 0,
    };

    // props.resetPaginatedData();
    props.updateState({ pageSize: pageSize, pageNumber: pageNumber });
    props.fetchCrmLeadFormList(payload);
    // props.getRegisteredMemberByFormId(payload);
    // props.getRegisteredMemberCount(payload);
  };


  return (
    <div className="lead-form">
       <PageHeader {...props}
        pageName="sidebar.menuItems.Leads"
        breadCrumbList={breadCrumbList}
        canCreate={true}
        onCreate={openCreateForm}
        moduleCode = {MODULE_CODED_VALUES.LEAD_FROM_SETTING}
      
        />
      
      <div className="view-container">
        <div className="view-container-actions">
          <div className="left-actions">
            <div className="table-heading">
              <FormattedMessage id="leadInfo.text" defaultMessage="" />
            </div>
            {props.parentCount ? (
              <>
                <div className="vertical-sep" />
                <div>{props.parentCount}</div>
              </>
            ) : (
              ""
            )}
          </div>
          <div className="right-actions">

          </div>
        </div>

        <div className="table-container">
        <Skeleton loading={props.listLoading} paragraph={{ rows: 15 }} active>
          <table id="lead-form-table">
            <thead>
              <tr>
                <th width='50px'>
                  <Checkbox key={'h'}/>
                </th>
                <th width='50px'>S.No.</th>
                <th onClick={sortColumn}>
                  Form Id <i className={ICONS.SORT}></i>
                </th>
                <th onClick={sortColumn}>
                  Form Name <i className={ICONS.SORT}></i>
                </th>
                <th onClick={sortColumn}>
                  Campaign <i className={ICONS.SORT}></i>
                </th>
                <th onClick={sortColumn}>
                  Date Created <i className={ICONS.SORT}></i>
                </th>

                <th onClick={sortColumn}>
                  Status <i className={ICONS.SORT}></i>
                </th>
                {
                  (permissionsData?.update || permissionsData?.delete) ?  <th width="6%">Action</th>: ""
                }
                
              </tr>
            </thead>
            <tbody>
              {(crmLeadFormList[pageNumber || 1] || []).map((e, i) => {
                return (
                  <tr key={"std" + i}>
                    <td>
                      <Checkbox key={i}/>
                    </td>
                    <td>{(pageNumber - 1) * pageSize + Number(i + 1)}</td>
                    <td>{e.crmLeadFormSettingId || "-"}</td>
                    <td>{e.formName || "-"}</td>
                    <td>{e.campaignName || 
                    <div className="empty-data"></div>
                    }</td>
                    <td>
                      {e.dateCreated
                        ? getMomentDateForUIReadOnly({
                            date: e.dateCreated,
                            format: CONSTANTS.TABLE_DATE_FORMAT,
                          })
                        :
                        <div className="empty-data"></div>                       
                        }
                    </td>
                    <td>
                      <div className="status-toggle">
                        <Switch
                          key={`stdc`+i}
                          disabled={!(permissionsData.update)}
                          checked={e.isActive ? true : false}
                          onChange={() => {
                            props.SaveCrmLeadFormSetting(
                              { ...e, isActive: e.isActive === 0 ? 1 : 0 },
                              props
                            );
                          }}
                        />
                      </div>
                    </td>
                    <td style={{ paddingLeft: "50px" }}>
                      {
                        (permissionsData?.update || permissionsData?.delete) ?  <div className="action-icon">
                        <DropdownAction
                          overlay={actionMenu(e)}
                          trigger={["click"]}
                        >
                          <i className={ICONS.MORE} />
                        </DropdownAction>
                      </div> : ""
                      }
                     
                    </td>
                  </tr>
                );
                // })
              })}
            </tbody>
          </table>
        </Skeleton>
        </div>

        <div className="footer">
          <div className="f-left"></div>
          <div className="f-right">
            <Pagination
              size="small"
              total={crmLeadFormListCount}
              showTotal={showTotal}
              defaultPageSize={props.pageSize}
              showSizeChanger
              pageSizeOptions={[10, 25, 50, 100, 200]}
              onChange={(pageNumber, pageSize) => {
                loadPageData(pageNumber, pageSize);
                updateState({ pageSize: pageSize, pageNumber: pageNumber });
              }}
              onShowSizeChange={(pageNumber, pageSize) => {
                handlePageSizeChange(pageNumber || 1, pageSize, props);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default injectIntl(LeadForm);
