import axios from 'axios';
import config from '../../../../../config/environmentConfig';
import { INVENTORY_ACTION_LIST } from '../../../../../static/constants';
import { showToasterMessage } from '../../../../../utils';
const { lang } = require('../../../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');


export const fetchOpeningQuantityById = (payload) => {
    return (dispatch) => {
      return axios
        .get(
          `${config.AWS_BASE_URL}${config.API_VERSION}/${config.rootContext}/openingQuantity/getOpeningQuantityById?relationshipId=${payload?.relationshipId}&openingQuantityMasterId=${payload?.openingQuantityMasterId}`,

        )
        .then((res) => {
          
          dispatch({ type: INVENTORY_ACTION_LIST.OPENING_BALANCE_BY_ID, data:res.data });
        })
        .catch((err) => {

        });
    };
  };

  export const resetData =()=>{
    return (dispatch) => {
      dispatch({ type: INVENTORY_ACTION_LIST.OPENING_BALANCE_BY_ID, data: {} });
    }
  }