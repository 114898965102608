import axios from 'axios';
import config from '../../../config/environmentConfig';
import { COMMON_ACTIONS, CRM_ACTION_LIST } from '../../../static/constants';
import { showToasterMessage } from '../../../utils';
const { lang } = require('../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');


export const fetchCallLogsByRid = (payload, ) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LIST_LOADER });
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/leadCallLogs/getCallLogs?relationshipId=${payload.relationshipId}&pageNumber=${payload.pageNumber}&pageSize=${payload.pageSize}`)
      .then(res => {

          const data = {
              list: res.data,
              pageNo: res.pageNo,
            }
        dispatch({ type: CRM_ACTION_LIST.LEAD_CALL_LOGS_LIST, data: data });
      })
      .catch(err => {
        dispatch({ type: COMMON_ACTIONS.HIDE_LOADER_EMAIL });
      }).finally(() => {
        dispatch({
          type: COMMON_ACTIONS.HIDE_LIST_LOADER,
        });
      })
}
}


export const fetchCallLogsByUserId = (payload, ) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LIST_LOADER });
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/leadCallLogs/getCallLogsByUserId?relationshipId=${payload.relationshipId}&createdByUserId=${payload.createdByUserId}&pageNumber=${payload.pageNumber}&pageSize=${payload.pageSize}`)
      .then(res => {

          const data = {
              list: res.data,
              pageNo: res.pageNo,
            }
        dispatch({ type: CRM_ACTION_LIST.LEAD_CALL_LOGS_LIST, data: data });
      })
      .catch(err => {
        dispatch({ type: COMMON_ACTIONS.HIDE_LOADER_EMAIL });
      }).finally(() => {
        dispatch({
          type: COMMON_ACTIONS.HIDE_LIST_LOADER,
        });
      })
}
}