import React, { Fragment } from "react";

import { Button, Divider, PageHeader, Tabs } from "antd";
import { FormattedMessage } from "react-intl";
import { getCurrencySymbol } from "../../../utils";
import { TextBox } from "../../general/TextBox";
import { Dropdown } from "../../general/Dropdown";
import Address from "./address";
import DefaultLedger from "./ledger";
import LabelConfig from "./labelConfig";
import Preferences from "./preferences";
import TaxIdentification from "./taxIdentification";
import GeneralComp from "./general";
import BankInfo from "./bankInfo";
const { TabPane } = Tabs;
function onTabChange(key, props) {
  const payload = {
    companyInfo: props.companyInfo,
    relationshipId: props.companyInfo.relationshipId,
    pageNumber: props.pageNumber,
    pageSize: props.pageSize,
  };

  switch (key) {
    case "General":
      props.updateState({ activeTab: "General", activeTabKey: "General" });
      break;
    case "Preference":
      props.updateState({ activeTab: "Preference", activeTabKey: "Preference" });

      break;
    case "Address":
      props.updateState({ activeTab: "Address", activeTabKey: "Address" });

      break;
    case "Default Ledger":
      props.updateState({ activeTab: "Default Ledger", activeTabKey: "Default Ledger" });

      break;
    case "Bank Details":
      props.updateState({ activeTab: "Bank Details", activeTabKey: "Bank Details" });

      break;
    case "Label Config":
      props.updateState({ activeTab: "Label Config", activeTabKey: "Label Config" });


      break;
    case "Tax Identification":
      props.updateState({ activeTab: "Tax Identification", activeTabKey: "Tax Identification" });

      break;



    default:
      break;
  }
}

function AccountSettingsComp(props) {
  console.log(props ,"STORING VALUESS")
  const breadCrumbList = [
    {
      name: <FormattedMessage id='sidebar.menuItem.dashboard' defaultMessage='' />,
    },
    {
      name: <FormattedMessage id='account.myprofile.breadcrumb.account' defaultMessage='' />,
    }
  ];
  return (
    <Fragment>
      
      <div className="view-container">
        <Tabs
          activeKey={props.activeTabKey || "General"} onChange={(key) => { onTabChange(key, props); }}
          type="line"
          animated={false}
        >
          <TabPane tab={<FormattedMessage id='General' />} key="General">
            <GeneralComp {...props} />
          </TabPane>
          <TabPane tab={<FormattedMessage id='eStore.storeSettings.preferences.label' />} key="Preference">
            <Preferences {...props} />
          </TabPane>
          <TabPane tab={<FormattedMessage id='employeeMeta.branch.label.address' />} key="Address">
            <Address {...props} />
          </TabPane>
          <TabPane tab={<FormattedMessage id='account.company.setting.defaultLedger' />} key="Default Ledger">
            <DefaultLedger {...props} />
          </TabPane>
          <TabPane tab={<FormattedMessage id='customer.salesInvoice.form.bankDetails.label' />} key="Bank Details">
            <BankInfo {...props} />
          </TabPane>
          <TabPane tab={<FormattedMessage id='addItem.text.taxIdentification' />} key="Tax Identification">
            <TaxIdentification {...props} />
          </TabPane>
          {
            props.companyInfo?.industryName === "Education"?  <TabPane tab={<FormattedMessage id='account.company.setting.labelConfig' />} key="Label Config">
            <LabelConfig {...props} />
          </TabPane> :""
          }
         

        </Tabs>
      </div>
    </Fragment>
  );
}
export default AccountSettingsComp;