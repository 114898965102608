import React from "react";

import { Button, Col, Divider, Row, Skeleton, Tabs } from "antd";
import { FormattedMessage } from "react-intl";
import { getCurrencySymbol } from "../../../utils";
import { TextBox } from "../../general/TextBox";
import { Dropdown, DropdownRef } from "../../general/Dropdown";
import find from "lodash.find";

const {TabPane} = Tabs;

const rowGutter =[16,16]

function DefaultLedger(props) {
    console.log(props ,"MANTIAN")
    let { intl } = props;
   
    const values="";
    return (
        <div className="account-setting">
<div className="store-model-container cus-space">
   
   <div className="store-main-container">
   

    <div>
    <div className="account-heading">
    <p className="heading">Default Ledger</p>
    <p className="sub-heading">
    Define and manage the default accounting ledger used for financial transactions and reporting.</p>
    </div>
    <div className="account-information">
    
    
    <form>
                <Row gutter={rowGutter}>
                   <Col span={12}>
                                <div className="i-lable">
                                <FormattedMessage id='company.companySetup.ledgerAccounts.sales' defaultMessage='' />
                                </div>
                                <Dropdown 
                                items={props.allLedgerAccounts}
                                    valueKeyName='ledgerAccountName'
                                    optionKeyName='chartOfAccountDetailsId'
                                    value={(props.allLedgerAccounts.length && (props.defaultSalesLedger || {}).ledgerAccountId) || ''}
                                    onSelect={(optionKey)=>{
                                        const defaultSalesLedger = find(props.allLedgerAccounts, { chartOfAccountDetailsId: Number(optionKey) })
                                        defaultSalesLedger.ledgerAccountId = defaultSalesLedger.chartOfAccountDetailsId;
                                        defaultSalesLedger.clientDefaultCoaLedgerAccountId = props.defaultSalesLedger.clientDefaultCoaLedgerAccountId;
                                        defaultSalesLedger.version = props.defaultSalesLedger.version;
                                        props.updateState({
                                            defaultSalesLedger
                                        })
                                    }}
                                    placeholder={intl.formatMessage(
                                        { id: 'company.companySetup.ledgerAccounts.salesLedger.placeholder', defaultMessage: ''  }
                                    )}
                                />
                            </Col>
                       
             
                    <Col span={12}>
                       
                                <div className="i-lable"><FormattedMessage id='company.companySetup.ledgerAccounts.purchase.title' defaultMessage='' /></div>
                                <Dropdown items={props.allLedgerAccounts}
                                    valueKeyName='ledgerAccountName'
                                    optionKeyName='chartOfAccountDetailsId'
                                    value={( props.allLedgerAccounts.length && (  props.defaultPurchaseLedger || {}).ledgerAccountId) || ''}
                                    onSelect={(optionKey)=>{
                                        const defaultPurchaseLedger = find(props.allLedgerAccounts, { chartOfAccountDetailsId: Number(optionKey) })
                                        defaultPurchaseLedger.ledgerAccountId = defaultPurchaseLedger.chartOfAccountDetailsId;
                                        defaultPurchaseLedger.clientDefaultCoaLedgerAccountId = props.defaultPurchaseLedger.clientDefaultCoaLedgerAccountId;
                                        defaultPurchaseLedger.version = props.defaultPurchaseLedger.version;
                                        props.updateState({
                                            defaultPurchaseLedger
                                        })
                                    }}
                                    placeholder={intl.formatMessage(
                                        { id: 'company.companySetup.ledgerAccounts.purchaseLedger.placeholder', defaultMessage: ''  }
                                    )}
                                />
                     

                    </Col>
                </Row>

                <Row gutter={rowGutter}>
                    <Col span={12}>
               
                         
                                <div className="i-lable"><FormattedMessage id='company.companySetup.ledgerAccounts.debtors.title' defaultMessage='' /></div>
                                <Dropdown items={props.allLedgerAccounts}
                                    valueKeyName='ledgerAccountName'
                                    optionKeyName='chartOfAccountDetailsId'
                                    value={(props.allLedgerAccounts.length && (props.defaultDebtorsLedger || {}).ledgerAccountId) || ''}
                                    onSelect={(optionKey)=>{
                                        const defaultDebtorsLedger = find(props.allLedgerAccounts, { chartOfAccountDetailsId: Number(optionKey) })
                                        defaultDebtorsLedger.ledgerAccountId = defaultDebtorsLedger.chartOfAccountDetailsId;
                                        defaultDebtorsLedger.clientDefaultCoaLedgerAccountId = props.defaultDebtorsLedger.clientDefaultCoaLedgerAccountId;
                                        defaultDebtorsLedger.version = props.defaultDebtorsLedger.version;
                                        
                                        props.updateState({
                                            defaultDebtorsLedger
                                        })
                                    }}
                                    placeholder={intl.formatMessage(
                                        { id: 'company.companySetup.ledgerAccounts.debtorsLedger.placeholder', defaultMessage: ''  }
                                    )}
                                />
                         
                        
                    </Col>
                    <Col span={12}>     
                        
                                <div className="i-lable"><FormattedMessage id='company.companySetup.ledgerAccounts.creditors.title' defaultMessage='' /></div>
                                <Dropdown items={props.allLedgerAccounts}
                                    valueKeyName='ledgerAccountName'
                                    optionKeyName='chartOfAccountDetailsId'
                                    value={(props.allLedgerAccounts.length && (props.defaultCreditorLedger || {}).ledgerAccountId) || ''}
                                    onSelect={(optionKey)=>{
                                        const defaultCreditorLedger = find(props.allLedgerAccounts, { chartOfAccountDetailsId: Number(optionKey) })
                                        defaultCreditorLedger.ledgerAccountId = defaultCreditorLedger.chartOfAccountDetailsId;
                                        defaultCreditorLedger.clientDefaultCoaLedgerAccountId = props.defaultCreditorLedger.clientDefaultCoaLedgerAccountId;
                                        defaultCreditorLedger.version = props.defaultCreditorLedger.version;
                                        
                                        props.updateState({
                                            defaultCreditorLedger
                                        })
                                    }}
                                    placeholder={intl.formatMessage(
                                        { id: 'company.companySetup.ledgerAccounts.creditorsLedger.placeholder', defaultMessage: ''  }
                                    )}
                                />
                         
                    </Col>
                </Row>

                <Row gutter={rowGutter}>
                    <Col span={12}>
                       
                      
                                <div className="i-lable"> <FormattedMessage id='company.companySetup.ledgerAccounts.customerDiscount.title' defaultMessage='' /></div>
                                <Dropdown items={props.allLedgerAccounts}
                                    valueKeyName='ledgerAccountName'
                                    optionKeyName='chartOfAccountDetailsId'
                                    value={( props.allLedgerAccounts.length &&  (props.defaultSalesDiscountLedger || {}).ledgerAccountId )|| ''}
                                    onSelect={(optionKey)=>{
                                        const defaultSalesDiscountLedger = find(props.allLedgerAccounts, { chartOfAccountDetailsId: Number(optionKey) })
                                        defaultSalesDiscountLedger.ledgerAccountId = defaultSalesDiscountLedger.chartOfAccountDetailsId;
                                        defaultSalesDiscountLedger.clientDefaultCoaLedgerAccountId = props.defaultSalesDiscountLedger.clientDefaultCoaLedgerAccountId;
                                        defaultSalesDiscountLedger.version = props.defaultSalesDiscountLedger.version;
                                       
                                        props.updateState({
                                            defaultSalesDiscountLedger
                                        })
                                    }}
                                    placeholder={intl.formatMessage(
                                        { id: 'company.companySetup.ledgerAccounts.customerDiscountLedger.placeholder', defaultMessage: ''  }
                                    )}
                                />
                 
                       
                    </Col>
                    <Col span={12}>
                        
                    
                                <div className="i-lable"><FormattedMessage id='company.companySetup.ledgerAccounts.salesDiscount.title' defaultMessage='' /></div>
                                <Dropdown items={props.allLedgerAccounts}
                                    valueKeyName='ledgerAccountName'
                                    optionKeyName='chartOfAccountDetailsId'
                                    value={(props.allLedgerAccounts.length && (props.defaultDiscountLedger || {}).ledgerAccountId) || ''}
                                    onSelect={(optionKey)=>{
                                        const defaultDiscountLedger = find(props.allLedgerAccounts, { chartOfAccountDetailsId: Number(optionKey) })
                                        defaultDiscountLedger.ledgerAccountId = defaultDiscountLedger.chartOfAccountDetailsId;
                                        defaultDiscountLedger.clientDefaultCoaLedgerAccountId = props.defaultDiscountLedger.clientDefaultCoaLedgerAccountId;
                                        defaultDiscountLedger.version = props.defaultDiscountLedger.version;
                                       
                                        props.updateState({
                                            defaultDiscountLedger
                                        })
                                    }}
                                    placeholder={intl.formatMessage(
                                        { id: 'company.companySetup.ledgerAccounts.supplierDiscountLedger.placeholder', defaultMessage: ''  }
                                    )}
                                />
                       
                        
                    </Col>
                </Row>

                <Row gutter={rowGutter}>
                    <Col span={12}>
                     
                      
                                <div className="i-lable"> <FormattedMessage id='company.companySetup.ledgerAccounts.salesOutputTax.title' defaultMessage='' /></div>
                                <Dropdown items={props.allLedgerAccounts}
                                    valueKeyName='ledgerAccountName'
                                    optionKeyName='chartOfAccountDetailsId'
                                    value={(props.allLedgerAccounts.length && (props.defaultSalesOutputLedger || {}).ledgerAccountId) || ''}
                                    onSelect={(optionKey)=>{
                                        const defaultSalesOutputLedger = find(props.allLedgerAccounts, { chartOfAccountDetailsId: Number(optionKey) })
                                        defaultSalesOutputLedger.ledgerAccountId = defaultSalesOutputLedger.chartOfAccountDetailsId;
                                        defaultSalesOutputLedger.clientDefaultCoaLedgerAccountId = props.defaultSalesOutputLedger.clientDefaultCoaLedgerAccountId;
                                        defaultSalesOutputLedger.version = props.defaultSalesOutputLedger.version;
                                       
                                        props.updateState({
                                            defaultSalesOutputLedger
                                        })
                                    }}
                                    placeholder={intl.formatMessage(
                                        { id: 'company.companySetup.ledgerAccounts.salesoutputTaxLedger.placeholder', defaultMessage: ''  }
                                    )}
                                />
                         
                        
                    </Col>
                    <Col span={12}>
                       
                       
                                <div className="i-lable"><FormattedMessage id='company.companySetup.ledgerAccounts.purchaseInputTax.title' defaultMessage='' /></div>
                                <Dropdown items={props.allLedgerAccounts}
                                    valueKeyName='ledgerAccountName'
                                    optionKeyName='chartOfAccountDetailsId'
                                    value={(props.allLedgerAccounts.length && (props.defaultTaxLedger || {}).ledgerAccountId) || ''}
                                    onSelect={(optionKey)=>{
                                        const defaultTaxLedger = find(props.allLedgerAccounts, { chartOfAccountDetailsId: Number(optionKey) })
                                        defaultTaxLedger.ledgerAccountId = defaultTaxLedger.chartOfAccountDetailsId;
                                        defaultTaxLedger.clientDefaultCoaLedgerAccountId = props.defaultTaxLedger.clientDefaultCoaLedgerAccountId;
                                        defaultTaxLedger.version = props.defaultTaxLedger.version;
                                        
                                        props.updateState({
                                            defaultTaxLedger
                                        })
                                    }}
                                    placeholder={intl.formatMessage(
                                        { id: 'company.companySetup.ledgerAccounts.purchaseInputTaxLedger.placeholder', defaultMessage: ''  }
                                    )}
                                />
                      
                      
                    </Col>
                </Row>

                {/* <Row>
                    <Col span={12}>
                        <Row>
                            <Col span={12}>
                                <div className="i-lable"> Credit Memo</div>
                            </Col>
                            <Col span={12}>
                                <Dropdown items={props.allLedgerAccounts}
                                    valueKeyName='ledgerAccountName'
                                    optionKeyName='chartOfAccountDetailsId'
                                    value={(props.creditMemoLedgerAccount || {}).ledgerAccountName || ''}
                                    onSelect={(optionKey)=>{
                                        const creditMemoLedgerAccount = find(props.allLedgerAccounts, { chartOfAccountDetailsId: Number(optionKey) })
                                        props.updateState({
                                            creditMemoLedgerAccount
                                        })
                                    }}
                                    placeholder='Select Credit Memo Ledger'
                                />
                            </Col>
                        </Row>
                    </Col>
                    <Col span={12}>
                        <Row>
                            <Col span={12}>
                                <div className="i-lable">Dedit Memo</div>
                            </Col>
                            <Col span={12}>
                                <Dropdown items={props.allLedgerAccounts}
                                    valueKeyName='ledgerAccountName'
                                    optionKeyName='chartOfAccountDetailsId'
                                    value={(props.debitMemoLedgerAccount || {}).ledgerAccountName || ''}
                                    onSelect={(optionKey)=>{
                                        const debitMemoLedgerAccount = find(props.allLedgerAccounts, { chartOfAccountDetailsId: Number(optionKey) })
                                        props.updateState({
                                            debitMemoLedgerAccount
                                        })
                                    }}
                                    placeholder='Select Debit Memo Ledger'
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row> */}

                <Row gutter={rowGutter}>
                    <Col span={12}>
               
                          
                                <div className="i-lable"><FormattedMessage id='company.companySetup.ledgerAccounts.customerExpense.title' defaultMessage='' /></div>
                                <Dropdown items={props.allLedgerAccounts}
                                    valueKeyName='ledgerAccountName'
                                    optionKeyName='chartOfAccountDetailsId'
                                    value={(props.allLedgerAccounts.length && (props.defaultExpenseLedger || {}).ledgerAccountId) || ''}
                                    onSelect={(optionKey)=>{
                                        const defaultExpenseLedger = find(props.allLedgerAccounts, { chartOfAccountDetailsId: Number(optionKey) });
                                        defaultExpenseLedger.ledgerAccountId = defaultExpenseLedger.chartOfAccountDetailsId;
                                        defaultExpenseLedger.clientDefaultCoaLedgerAccountId = props.defaultExpenseLedger.clientDefaultCoaLedgerAccountId;
                                        defaultExpenseLedger.version = props.defaultExpenseLedger.version;
                                        props.updateState({
                                            defaultExpenseLedger
                                        })
                                    }}
                                    placeholder={intl.formatMessage(
                                        { id: 'company.companySetup.ledgerAccounts.customerExpenseLedger.placeholder', defaultMessage: '' }
                                    )}
                                />
                  
                  
                    </Col>
                    <Col span={12}>
                    
                          
                                <div className="i-lable"><FormattedMessage id='company.companySetup.ledgerAccounts.supplierExpense.title' defaultMessage='' /></div>
                                <Dropdown items={props.allLedgerAccounts}
                                    valueKeyName='ledgerAccountName'
                                    optionKeyName='chartOfAccountDetailsId'
                                    value={(props.allLedgerAccounts.length && (props.defaultSupplierExpenseLedger || {}).ledgerAccountId) || ''}
                                    onSelect={(optionKey)=>{
                                        const defaultSupplierExpenseLedger = find(props.allLedgerAccounts, { chartOfAccountDetailsId: Number(optionKey) })
                                        defaultSupplierExpenseLedger.ledgerAccountId = defaultSupplierExpenseLedger.chartOfAccountDetailsId;
                                        defaultSupplierExpenseLedger.clientDefaultCoaLedgerAccountId = props.defaultSupplierExpenseLedger.clientDefaultCoaLedgerAccountId;
                                        defaultSupplierExpenseLedger.version = props.defaultSupplierExpenseLedger.version;
                                        props.updateState({
                                            defaultSupplierExpenseLedger
                                        })
                                    }}
                                    placeholder={intl.formatMessage(
                                        { id: 'company.companySetup.ledgerAccounts.supplierExpenseLedger.placeholder', defaultMessage: '' }
                                    )}
                                />
                      
                 
                    </Col>
                </Row>

                <Row gutter={rowGutter}>
                    <Col span={12}>
                      
                          
                                <div className="i-lable"><FormattedMessage id='company.companySetup.ledgerAccounts.paymentRecievedLedger.title' defaultMessage='' /></div>
                                <Dropdown items={props.allLedgerAccounts}
                                    valueKeyName='ledgerAccountName'
                                    optionKeyName='chartOfAccountDetailsId'
                                    value={(props.allLedgerAccounts.length && (props.defaultCustomerPayment || {}).ledgerAccountId) || ''}
                                    onSelect={(optionKey)=>{
                                        const defaultCustomerPayment = find(props.allLedgerAccounts, { chartOfAccountDetailsId: Number(optionKey) });
                                        defaultCustomerPayment.ledgerAccountId = defaultCustomerPayment.chartOfAccountDetailsId;
                                        defaultCustomerPayment.clientDefaultCoaLedgerAccountId = props.defaultCustomerPayment.clientDefaultCoaLedgerAccountId;
                                        defaultCustomerPayment.version = props.defaultCustomerPayment.version;
                                      
                                        props.updateState({
                                            defaultCustomerPayment
                                        })
                                    }}
                                    placeholder={intl.formatMessage(
                                        { id: 'company.companySetup.ledgerAccounts.paymentRecieveLedger.placeholder', defaultMessage: '' }
                                    )}
                                />
                       
               
                    </Col>
                    <Col span={12}>
                    
                       
                                <div className="i-lable"><FormattedMessage id='company.companySetup.ledgerAccounts.paymentMadeLedger.title' defaultMessage='' /></div>
                                <Dropdown items={props.allLedgerAccounts}
                                    valueKeyName='ledgerAccountName'
                                    optionKeyName='chartOfAccountDetailsId'
                                    value={(props.allLedgerAccounts.length && (props.defaultSupplierPayment || {}).ledgerAccountId) || ''}
                                    onSelect={(optionKey)=>{
                                        const defaultSupplierPayment = find(props.allLedgerAccounts, { chartOfAccountDetailsId: Number(optionKey) });
                                        defaultSupplierPayment.ledgerAccountId = defaultSupplierPayment.chartOfAccountDetailsId;
                                        defaultSupplierPayment.clientDefaultCoaLedgerAccountId = props.defaultSupplierPayment.clientDefaultCoaLedgerAccountId;
                                        defaultSupplierPayment.version = props.defaultSupplierPayment.version;
                                        
                                        props.updateState({
                                            defaultSupplierPayment
                                        });
                                    }}
                                    placeholder={intl.formatMessage(
                                        { id: 'company.companySetup.ledgerAccounts.paymentMadeLedger.placeholder', defaultMessage: ''  }
                                    )}
                                />
                 
                       
                    </Col>
                </Row>
               
                <Row gutter={rowGutter}>
                    <Col span={12}>
                      
                          
                                <div className="i-lable"><FormattedMessage id='company.companySetup.ledgerAccounts.roundingOffLedger.title' defaultMessage='' /></div>
                                <Dropdown items={props.allLedgerAccounts}
                                    valueKeyName='ledgerAccountName'
                                    optionKeyName='chartOfAccountDetailsId'
                                    value={(props.allLedgerAccounts.length && (props.defaultRoundingOff || {}).ledgerAccountId) || ''}
                                    onSelect={(optionKey)=>{
                                        
                                        
                                        const defaultRoundingOff = find(props.allLedgerAccounts, { chartOfAccountDetailsId: Number(optionKey) });
                                        defaultRoundingOff.ledgerAccountId = defaultRoundingOff.chartOfAccountDetailsId;
                                        defaultRoundingOff.clientDefaultCoaLedgerAccountId = props.defaultRoundingOff.clientDefaultCoaLedgerAccountId;
                                        defaultRoundingOff.version = props.defaultRoundingOff.version;
                                        props.updateState({
                                            defaultRoundingOff
                                        })
                                    }}
                                    placeholder={intl.formatMessage(
                                        { id: 'company.companySetup.ledgerAccounts.roundingOffLedger.placeholder', defaultMessage: '' }
                                    )}
                                />
                       
               
                    </Col>
                    <Col span={12}>
                    
                       
                                <div className="i-lable"><FormattedMessage id='company.companySetup.ledgerAccounts.couponLedger.title' defaultMessage='' /></div>
                                <Dropdown items={props.allLedgerAccounts}
                                    valueKeyName='ledgerAccountName'
                                    optionKeyName='chartOfAccountDetailsId'
                                    value={(props.allLedgerAccounts.length && (props.defaultCoupon || {}).ledgerAccountId) || ''}
                                    onSelect={(optionKey)=>{
                                        const defaultCoupon = find(props.allLedgerAccounts, { chartOfAccountDetailsId: Number(optionKey) });

                                        defaultCoupon.ledgerAccountId = defaultCoupon.chartOfAccountDetailsId;
                                        defaultCoupon.clientDefaultCoaLedgerAccountId = props.defaultCoupon.clientDefaultCoaLedgerAccountId;
                                        defaultCoupon.version = props.defaultCoupon.version;
                                        props.updateState({
                                            defaultCoupon
                                        });
                                    }}
                                    placeholder={intl.formatMessage(
                                        { id: 'company.companySetup.ledgerAccounts.couponLedger.placeholder', defaultMessage: ''  }
                                    )}
                                />
                 
                       
                    </Col>
                </Row>


            </form>

    </div>
    <div className="">
     <Button type="primary" onClick={()=>{
                     props.updateDefaultLedger(props);
                    }} >Save Changes</Button>
  </div>
    </div>
   </div>
   

   
   


</div>
        </div>
        
    )
}
export default DefaultLedger;