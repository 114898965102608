import CommonReducer from "./CommonReducer";
import AuthReducer from "./AuthReducer";
import CustomerReducer from "./CustomerReducer";
import InventoryReducer from "./InventoryReducer";
import SupplierReducer from "./SupplierReducer";
import ProductReducer from "./ProductReducer";
import { ModalReducer, StackModalReducer } from "./ModalReducer";
import SQReducer from "./SQReducer";
import SOReducer from "./SOReducer";
import SalesInvoiceReducer from "./SalesInvoiceReducer";
import CustomerPaymentReducer from "./CustomerPaymentReducer";
import CreditMemoReducer from "./CreditMemoReducer";
import CustomerExpenseReducer from "./CustomerExpenseReducer";
import PurchaseRequestReducer from "./PurchaseRequestReducer";
import RFQReducer from "./RFQReducer";
import PurchaseOrderReducer from "./PurchaseOrderReducer";
import PurchaseInvoiceReducer from "./PurchaseInvoiceReducer";
import SupplierPaymentReducer from "./SupplierPaymentReducer";
import DebitMemoReducer from "./DebitMemoReducer";
import SupplierExpenseReducer from "./SupplierExpenseReducer";
import LedgerReducer from "./LedgerReducer";
import JournalReducer from "./JournalReducer";
import BusinessExpenseReducer from "./BusinessExpenseReducer";
import SettingReducer from "./SettingReducer";
import AgedStockReducer from "./AgedStockReducer";
import StockSummaryReducer from "./StockSummaryReducer";
import DashboardReducer from "./DashboardReducer";
import SalesInquiryReducer from "./SalesInquiryReducer";
import HeirarchyReducer from "./HeirarchyReducer";
import InboundDeliveryReducer from "./InboundDeliveryReducer";
import PackageReducer from "./PackageReducer";
import ShipmentReducer from "./ShipmentReducer";
import ReportsReducer from "./ReportsReducer";
import SalesComplaintReducer from "./SalesComplaintReducer";
import IntegrationReducer from "./IntegrationReducer";
import FinanceReducer from "./FinanceReducer";
import ProjectReducer from "./ProjectReducer";
import StudentRegistrationReducer from "./StudentRegistrationReducer";
import DonationReducer from "./DonationReducer";
import EcommerceReducer from "./EcommerceReducer";
import MembershipReducer from "./MembershipReducer";
import TaxSettingReducer from "./TaxSettingReducer";
import FundraisingReducer from "./FundraisingReducer";
import MarketplaceShippingReducer from "./ShippingReducer";
import CommunicationReducer from "./CommunicationReducer";
import KioskReducer from "./KioskReducer";
import MarketplaceSalesChannelReducer from "./MarketplaceSalesChannelReducer";
import SchoolManagementReducer from "./SchoolManagementReduer";
import FileUploadReducer from "./FileUploadReducers";
import FinancialYearReducer from "./FinancialYearReducer";
import GSTFillingReducer from "./gstrFillingReducer";
import InventoryValuationReducer from "./InventryValuationReducer";
import LandingCostReducer from "./LandingCostReducer";
import CrmReducer from "./CrmReducer";
import CompanyReducer from "./CompanyReducer";
import EStoreReducer from "./EStoreReducer";
import EmployeeReducer from "./EmployeeReducer";
import ReturnReducer from "./Return";
import ProfileReducer from "./profileInfoReducer";
const reducers = {
  common: CommonReducer,
  auth: AuthReducer,
  customer: CustomerReducer,
  inventory: InventoryReducer,
  supplier: SupplierReducer,
  modal: ModalReducer,
  stackModal: StackModalReducer,
  product: ProductReducer,
  sq: SQReducer,
  so: SOReducer,
  salesInvoice: SalesInvoiceReducer,
  customerPayment: CustomerPaymentReducer,
  creditMemo: CreditMemoReducer,
  customerExpense: CustomerExpenseReducer,
  purchaseRequest: PurchaseRequestReducer,
  rfq: RFQReducer,
  purchaseOrder: PurchaseOrderReducer,
  purchaseInvoice: PurchaseInvoiceReducer,
  supplierPayment: SupplierPaymentReducer,
  debitMemo: DebitMemoReducer,
  journal: JournalReducer,
  ledger: LedgerReducer,
  businessExpense: BusinessExpenseReducer,
  supplierExpense: SupplierExpenseReducer,
  settings: SettingReducer,
  agedStock: AgedStockReducer,
  stockSummary: StockSummaryReducer,
  dashboard: DashboardReducer,
  enquiry: SalesInquiryReducer,
  hierarchy: HeirarchyReducer,
  inboundDelivery: InboundDeliveryReducer,
  package: PackageReducer,
  shipment: ShipmentReducer,
  reports: ReportsReducer,
  salesComplaint: SalesComplaintReducer,
  integration: IntegrationReducer,
  finance: FinanceReducer,
  project: ProjectReducer,
  studentRegistration: StudentRegistrationReducer,
  donation: DonationReducer,
  ecommerce: EcommerceReducer,
  membershipReducer: MembershipReducer,
  taxSettingReducer: TaxSettingReducer,
  fundraisingReducer: FundraisingReducer,
  shippingReducer: MarketplaceShippingReducer,
  communicationReducer: CommunicationReducer,
  kioskReducer: KioskReducer,
  marketplaceSalesChannelReducer: MarketplaceSalesChannelReducer,
  school: SchoolManagementReducer,
  fileUpload : FileUploadReducer,
  financialYear : FinancialYearReducer,
  gstFilling: GSTFillingReducer,
  inventoryValuation : InventoryValuationReducer,
  landingCost : LandingCostReducer,
  crm: CrmReducer,
  company: CompanyReducer,
  eStore: EStoreReducer,
  employee:EmployeeReducer,
  return: ReturnReducer,
  profile: ProfileReducer,
};

export default reducers;
