const configuration = {
    API_BASE_URL: 'https://service.alpidedev.com',
    AWS_BASE_URL: 'https://service.alpidedev.com',
    PDF_API_BASE_URL: 'https://doc.alpidedev.com/api/',
    INVOICE_SERVICE_BASE_URL: 'https://service.alpidedev.com',
    PAYMENT_GATEWAY_BASE_URL: 'https://service.alpidedev.com',
    PLAID_BASE_URL: 'https://service.alpidedev.com',
    UI_BASE_URL: 'https://app.alpidedev.com/',
    CALLBACK_URI_MARKTPLACE_AMAZON: 'https://app.alpidedev.com/admin/sales-channels-amazon',
    CALLBACK_URI_MARKTPLACE_EBAY: 'https://app.alpidedev.com/admin/sales-channels-ebay',
    CALLBACK_URI_AUTH_NET: 'https://app.alpidedev.com/admin/payment-integration',

    PAYMENT_SERVER_BASE_URL: 'http://app.alpidedev.com',
    ENCRYPT_SECRET: 'Unl0ck!!!',
    API_VERSION: '',
    PDF_API_VERSION: 'v1',

    PAYMENT_FORM_ACTION: 'https://accept.authorize.net/payment/payment',

    rootContext: 'alpide',
    rootContextInvoiceService: 'alpide-invoice',
    rootContextPaymentGateway: 'alpide-paypal',
    rootContextPlaid: 'alpide-plaid',
    rootContextTwilio: 'alpide-tcom',
    rootContextSMS: 'alpide-sms',
    rootContextBrevo:'alpide-brevo',
    rootContextCC:'alpide-cc',
    rootContextRazorpay:'alpide-razorpay',
    rootContextSupport:'alpide-support',
    rootContextStripe: 'alpide-stripe',
    rootContextHRMS: 'alpide',
    rootContextCMS: 'alpide-cms',
    rootContextEasyPost: 'alpide-ep',
    rootContextShiprocket: 'alpide-shiprocket',
    API_VERSION_CMS:'api/v1',
    API_VERSION_SHIPROCKET:'api/v1',
    CMS_BASE_URL: 'https://service.alpidedev.com',
    CC_TOKENIZER_URL: 'https://fts-uat.cardconnect.com/itoke/ajax-tokenizer.html',
    SSO_URL: 'https://accounts.alpidedev.com',
    ROOT_DOMAIN: 'alpidedev.com',
    WP_DOMAIN: 'https://login.alpidedev.com',
    KIOSK_BASE_URL: 'https://donate.alpidedev.com',
    COUPON_BASE_URL: 'https://service.alpidedev.com',
    COUPON_CONTEXT: 'alpide-comm',
    CB_PUBLIC_KEY: 'test_9KdLB3laV1fzmNupMYMQyQRwUioTPYVL',
    STRIPE_PUBLISHABLE_KEY: 'pk_test_51IdxxgL81zohSYdnTW0V88mWnfCIhqk8pgZdYgD9S2Xbut00JH58Ac7QHce64AWM0GUbKrcvlv6G5M3BHCp6dOGJ00Ja0AiJgF',
    BUCKET_NAME: {
        BO_RELATIONSHIP: 'relationship-u',
        BO_SALES_INQUIRY: "inquiry-u",
        BO_SALES_QUOTE: "sales-quote-u",
        BO_SALES_ORDER: 'sales-order-u',
        BO_CUSTOMER_INVOICE: "sales-invoice-u",
        BO_CREDIT_MEMO: "credit-memo-u",
        BO_PURCHASE_REQUEST: "purchase-request-u",
        BO_RFQ: "supplier-rfq-u",
        BO_PURCHASE_ORDER: "purchase-order-u",
        BO_INBOUND_DELIVERY: "inbound-delivery-u",
        BO_SUPPLIER_INVOICE: "purchase-invoice-u",
        BO_DEBIT_MEMO: "debit-memo-u",
        BO_SAMPLE_RESOURCES: "sample-resources-u",
        BO_INVENTORY: "inventory-u"
    },
    ERP_THEMES:{
        101: "Vogue",
        129: "Dapper",
        156: "Matrikala"
    },
    RAZORPAY_KEYS: {
        129: "rzp_live_ib6x5GoFPaYWei",
    }
};

export default configuration;
