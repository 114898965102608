import React, { Fragment } from 'react';
import ReactApexChart from "react-apexcharts"


const Admissions = (props) => {
 
  const  getSeriesData =(props) => {
        const dataArr = [];
         Object.values(props.totalStudentCountByGrade || {}).forEach((e)=>{
             dataArr.push(Math.round(Number(e)));
         });
         return  [{
            name: 'Count',
            data: dataArr
        }];
    } 
    
   const getOptionsData =(props) => {
        const categoriesArr = [];
         Object.keys(props.totalStudentCountByGrade || {}).forEach((e)=>{
            categoriesArr.push(e);
         });
         return  {
            chart: {
                height: 250,
                type: 'bar',
                events: {
                    click: function (chart, w, e) {
                    }
                }
            },
           
            plotOptions: {
                bar: {
                    columnWidth: '30%',
                    distributed: true
                }
            },
            dataLabels: {
                enabled: false
            },
            legend: {
                show: false
            },
            xaxis: {
                categories: categoriesArr,
                labels: {
                    style: {
                        fontSize: '12px'
                    }
                }
            },
            colors: ['#0075FF'],
        };
    } 
    return<>
   
    <ReactApexChart options={getOptionsData(props)} series={getSeriesData(props)} type="bar" height={230} />
    </>
};

export default Admissions;