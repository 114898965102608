import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import CustomerExpenseListingComp from '../../../../components/customer/expense/Listing';
import { fetchDataIfNeeded, fetchPaginationDataIfNeeded } from '../../../../utils';
import { fetchCustomerExpenses, getCustomerExpensesCount, resetPaginatedData } from './action';
import { showModal, pushModalToStack, hideModal, popModalFromStack} from '../../../../actions/commonActions';

class CustomerExpenseListing extends Component {
    constructor(props) {
        super(props);
        this.state = {
          pageSize: 100,
          pageNumber: 1,
          isDataRendered: false
        };
    }

    componentDidMount() {
        this.props.updateHeaderState({
            collapsed: true
        });
        const companyInfo = this.props.companyInfo || {};
        const payload = {
          relationshipId: companyInfo.relationshipId,
          pageNumber: 1,
          pageSize: 100
        };
        fetchPaginationDataIfNeeded('fetchCustomerExpenses', 'customerExpenseList', this.props, payload);
        fetchDataIfNeeded('getCustomerExpensesCount', 'customerExpenseCount', this.props, payload);
      }

    render() {
        return (
            <CustomerExpenseListingComp {...this.props} {...this.state} updateState={(data) => { this.setState(data) }}/>
        )
    }
}

const mapStateToProps = (state) => {
    return {
      companyInfo: state.common.companyInfo,
      customerExpenseList:state.customerExpense.customerExpenseList,
      customerExpenseCount: state.customerExpense.customerExpenseCount,
      isLoading: state.common.isLoading ,
      permissions: state.auth.permissions,
    
    }
  }
  
  const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchCustomerExpenses,
    getCustomerExpensesCount,
    resetPaginatedData,
    showModal,
    popModalFromStack,
    pushModalToStack,
    hideModal,
  }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CustomerExpenseListing);
