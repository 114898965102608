



import React from "react";
import ReactApexChart from "react-apexcharts"

class RevenueStatisticChart extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      options: {
        chart: {
          id: "basic-bar"
        },
        xaxis: {
          categories: [2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018]
        }
      },
      series: [
        {
          name: "series-1",
          data: [30, 40, 45, 50, 49, 60, 70, 91]
        }
      ]
    };
  }



  render() {

    // let series = [{
    //   name: `Paid in ${(this.props.companyInfo || {}).currencyCode + " "}`,
    //   data: Object.values(this.props.salesData || {})?.map((e) => {

    //     return e?.paidAmount ? Math.round(e?.paidAmount) : 0;
    //   })
    // }, {
    //   name: "Due",
    //   data: Object.values(this.props.salesData || {})?.map((e) => {

    //     return e?.salesAmount ? Math.round((e?.salesAmount) - (e?.paidAmount)) : 0
    //   })

    // }, {
    //   name: "Total Amount",
    //   data: Object.values(this.props.salesData || {})?.map((e) => {
    //     return e?.salesAmount ? Math.round(e?.salesAmount) : 0;
    //   })
    // }];

    // const options = {
    //   legend: {
    //     position: 'top',
    //     offsetX: 0,
    //     offsetY: 0,
    //     horizontalAlign: 'right'
    //   },
    //   chart: {
    //     background: '#fff',
    //     height: 'auto',
    //     type: 'line',
    //     zoom: {
    //       enabled: false
    //     },
    //     toolbar: {
    //       show: false
    //     },
    //   },




    //   colors: ['#1CB980', '#FF000D', '#3150B9'],
    //   stroke: {
    //     width: 3,
    //     curve: 'straight'
    //   },

    //   grid: {
    //     row: {
    //       colors: ['#fff', 'transparent'], // takes an array which will be repeated on columns
    //       opacity: 0.5
    //     },
    //   },
    //   xaxis: {

    //     categories: Object.keys(this.props.salesData || {}),
    //   }
    // };


    return (


      <div id="chart">
        {

          <ReactApexChart options={this.state.options} series={this.state.series} type="line" height={260} />
        }
      </div>);
  }
}
export default RevenueStatisticChart;