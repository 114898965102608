import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Allowance from '../../../../components/company/PayrollManagement/Allowance';
import AllowanceDrawer from '../../../drawer/company/PayrollManagement/Allowance';
import { createAllowance, getAllowance, getTotalAllowanceCount, deleteAllowance,searchAllowance } from './action';
import { fetchDataIfNeeded, fetchPaginationDataIfNeeded } from '../../../../utils';
import { showModal, hideModal } from '../../../../actions/commonActions';


class AllowanceContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageSize: 25,
      pageNumber: 1,
      searchedText: '',

    }
  };


  componentDidMount(props) {
    const companyInfo = this.props.companyInfo || {};

    const payload = {
      relationshipId: companyInfo.relationshipId,
      pageNumber: 1,
      pageSize: 25,

    }
    fetchPaginationDataIfNeeded('getAllowance', 'allAllowance', this.props, payload, true)
    fetchDataIfNeeded('getTotalAllowanceCount', 'allowanceCount', this.props, payload)

  }



  render() {
    return <>

      <Allowance {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
      {this.state.allowanceDrawer && <AllowanceDrawer {...this.state} {...this.props} updateState={(data) => { this.setState(data) }} />}
    </>

      ;
  }

}

const mapStateToProps = (state) => {
  return {

    companyInfo: state.common.companyInfo,
    listLoading: state.common.listLoading,
    drawerLoading:state.common.drawerLoading,
    headerLoading:state.common.headerLoading,
    userInfo: state.common.userInfo,
    allAllowance: state.employee.allAllowance,
    allowanceCount: state.employee.allowanceCount,
    permissions: state.auth.permissions,

    // allowanceSearchList: state.employee.allowanceSearchList,

  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  showModal,
  hideModal,
  createAllowance,
  getAllowance,
  getTotalAllowanceCount,
  deleteAllowance,
  searchAllowance
  
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AllowanceContainer);
