import React, { useMemo, useState } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Skeleton, Popover, Menu, Pagination,Input  } from 'antd';
import PageBreadcrumb from "../../../PageBreadcrumb";
import DropdownAction from "antd/lib/dropdown";
import { checkACLPermission, getMomentDateForUIReadOnly, getPermissionData } from '../../../../utils';
import {  DownloadOutlined } from "@ant-design/icons";
import { CONSTANTS,ICONS, MODULE_CODED_VALUES, PERMISSION_VALUES } from '../../../../static/constants';
import PageHeader from '../../../Common/pageHeader';
const { Search } = Input;

const PayGrade = (props) => {
  const {permissions} = props
  const primaryPerm = (permissions && permissions.primary) || [];

  const permissionsData = useMemo(()=>{
    return getPermissionData(primaryPerm , MODULE_CODED_VALUES.HRMS_PAYROLL);
  },[])
 
  const [viewMode, setViewMode] = useState(0);

    const breadCrumbList = [
        {
            name: "Dashboard",
        },
        {
          name: <FormattedMessage id='sidebar.menuItems.employee'/>,
      },
        {
            name: "Monthly Pay Grade",
        }
    ];

  

    const actionMenu = (e) => {
      return (
        <Menu className="row-action-dropdown">
          <Menu.Item key="0" value="title">
            Actions
          </Menu.Item>

          <Menu.Divider />
         {
          permissionsData?.update ? <Menu.Item
          key="1"
          value="edit"
          onClick={() => {
          
          }}
        >
          <i className={ICONS.EDIT} />
          Edit
        </Menu.Item>: ""
         }
          

          { (permissionsData?.delete && e.isAccountHolder) ? (
            <Menu.Item
              key="2"
             
            >
              <i className={ICONS.DELETE} />
              Delete
            </Menu.Item>
          ) : (
            ""
          )}

        
        </Menu>
      );
    };

    const viewMenu = () => {
      return (
        <Menu className="row-action-dropdown">
          <Menu.Item key="0" value="title">
            View as
          </Menu.Item>
  
          <Menu.Divider />
  
          <Menu.Item
            key="1"
            value="Monthly View"
            onClick={() => {
              setViewMode(0);
            }}
          >
            <i className="fi fi-rs-standard-definition" /> Monthly View
          </Menu.Item>
  
          <Menu.Item
            key="2"
            value="Hourly View"
            onClick={() => {
              setViewMode(1);
            }}
          >
            <i className="fi fi-rs-notebook" /> Hourly View
          </Menu.Item>
        </Menu>
      );
    };
   const createPayGrade = ()=>{
    !viewMode ? (
      props.updateState({ monthlyPayDrawer: true })
      ):(
      props.updateState({ hourlyPayDrawer: true })

      )
   }
    return (
      <>
      <PageHeader {...props}
        pageName="company.employeeMangement.commonMenu"
        breadCrumbList={breadCrumbList}
        canCreate={true}
        onCreate={createPayGrade}
        moduleCode = {MODULE_CODED_VALUES.HRMS_PAYROLL}
      
        />
        

        <div className="view-container">
          <Skeleton
            loading={props.isLoading && !props.company?.employeeDrawerVisible}
          >
            <div className="view-container-actions">
              <div className="left-actions">
                <div className="table-heading">
                  Pay Grade
                </div>
                {[]?.length ? (
                  <>
                    <div className="vertical-sep" />
                    <div>{[]?.length}</div>
                  </>
                ) : (
                  ""
                )}
              </div>
              <div className="right-actions">
             
              <Search
                placeholder="Search Monthly Pay Grade"
                value={props.searchedText}
                onChange={(e) => {
                  // updateState({ searchedText: e.target.value, pageNumber: 1 });
                  // onSearchedInputChange(e.target.value, props);
                }}
                onSearch={(searchedText) => {
                  // updateState({ searchedText, pageNumber: 1 });
                  // onSearchedInputChange(searchedText, props);
                  // handleSearchedText(searchedText, props);
                }}
              />
              <DropdownAction overlay={viewMenu()} trigger={["click"]}>
              <span>
                <i className={`${ICONS.VIEW} mr5`} />{" "}
                {viewMode ?  "Hourly View" : "Monthly View"}
              </span>
            </DropdownAction>
             <DropdownAction 
            //  overlay={downloadMenu()}
              trigger={["click"]}>
                <span>
                  <DownloadOutlined /> &nbsp;{" "}
                  <FormattedMessage
                    id="common.action.dropdown.downloadAs"
                    defaultMessage=""
                  />
                </span>
              </DropdownAction>
              </div>
            </div>

            
           {!viewMode ? (
            <div className="table-container">
              <table id="employee-table">
                <thead>
                <tr>
                    <th>
                      <FormattedMessage id="serial.no" defaultMessage=" " />
                    </th>

                    <th>
                      <FormattedMessage
                        id="company.payrollManagement.payGradeName"
                        defaultMessage=" "
                      />
                    </th>

                   
                      <th>
                        <FormattedMessage
                          id="company.payrollManagement.grossSalary"
                          defaultMessage=" "
                        />
                      </th>
                  
                
                      <th>
                        <FormattedMessage
                          id="company.payrollManagement.percentage"
                          defaultMessage=" "
                        />
                      </th>
                  
                    <th>
                      <FormattedMessage
                        id="company.payrollManagement.basicSalary"
                        defaultMessage=" "
                      />
                    </th>
                    <th>
                      <FormattedMessage
                        id="company.payrollManagement.overtimeRate"
                        defaultMessage=" "
                      />
                    </th> 

                    {
                       (permissionsData?.update || permissionsData?.delete) ?  <th className="text-center">
                       <FormattedMessage
                         id="actions.text"
                         defaultMessage=" "
                       />
                     </th>
                 : ""
                    }           
                     
                  
                      
                  </tr>
                </thead>
                <tbody>
                  {([]).map((rowData, index) => {
                      return (
                        <tr key={index}>
                          <td>{index + 1}</td>

                          <td>
                            {rowData.employeeCode ? rowData.employeeCode : "-"}
                          </td>

                            <td>
                              {rowData.firstName +
                                (rowData.lastName
                                  ? " " + rowData.lastName
                                  : "")}
                            </td>
                          
                            <td>{rowData.empType}</td>
                         
                          <td>{rowData.hierarchyName}</td>
                          <td>{rowData.hierarchyCode}</td>
                            <td>{rowData.jobTitleName}</td>
                        
                            <td>{rowData.jobRoleName}</td>
                          
                        
                      

                          <td>
                            {rowData.dateCreated
                              ? getMomentDateForUIReadOnly({
                                  date: rowData.dateCreated,
                                  format: CONSTANTS.TABLE_DATE_FORMAT,
                                })
                              : "-"}
                          </td>
                          {
                             (permissionsData?.update || permissionsData?.delete) ? (  <td>
                              <DropdownAction
  
                                overlay={actionMenu(rowData)}
                                trigger={["click"]}
                              >
                                <i className={ICONS.MORE} />
                              </DropdownAction>
                            </td>) :""
                          }
                        
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
           ):
           (
            <div className="table-container">
              <table id="employee-table">
                <thead>
                <tr>
                    <th>
                      <FormattedMessage id="serial.no" defaultMessage=" " />
                    </th>

                    <th>
                      <FormattedMessage
                        id="company.payrollManagement.hourlyPayGradeName"
                        defaultMessage=" "
                      />
                    </th>

                   
                      <th>
                        <FormattedMessage
                          id="company.payrollManagement.hourlyRate"
                          defaultMessage=" "
                        />
                      </th>
                  
                
                         
                      <th className="text-center">
                        <FormattedMessage
                          id="actions.text"
                          defaultMessage=" "
                        />
                      </th>
                  
                  
                      
                  </tr>
                </thead>
                <tbody>
                  {([]).map((rowData, index) => {
                      return (
                        <tr key={index}>
                          <td>{index + 1}</td>

                          <td>
                            {rowData.employeeCode ? rowData.employeeCode : "-"}
                          </td>

                            <td>
                              {rowData.firstName +
                                (rowData.lastName
                                  ? " " + rowData.lastName
                                  : "")}
                            </td>
                          
                            <td>{rowData.empType}</td>
                         
                          <td>{rowData.hierarchyName}</td>
                          <td>{rowData.hierarchyCode}</td>
                            <td>{rowData.jobTitleName}</td>
                        
                            <td>{rowData.jobRoleName}</td>
                          
                        
                      

                          <td>
                            {rowData.dateCreated
                              ? getMomentDateForUIReadOnly({
                                  date: rowData.dateCreated,
                                  format: CONSTANTS.TABLE_DATE_FORMAT,
                                })
                              : "-"}
                          </td>

                          <td>
                            <DropdownAction
                              overlay={actionMenu(rowData)}
                              trigger={["click"]}
                            >
                              <i className={ICONS.MORE} />
                            </DropdownAction>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
           )}
            {/* <div className="footer">
              <div className="f-left"></div>
              <div className="f-right">
                <Pagination
                  size="small"
                  total={30}
                  showTotal={showTotal}
                  defaultPageSize={props.pageSize}
                  showSizeChanger
                  pageSizeOptions={[10, 25, 50, 100, 200]}
                  // showQuickJumper
                  onChange={(pageNumber, pageSize) => {
                    loadPageData(pageNumber, pageSize);
                    updateState({ pageSize: pageSize, pageNumber: pageNumber });
                  }}
                  onShowSizeChange={(pageNumber, pageSize) => {
                    handlePageSizeChange(pageNumber || 1, pageSize, props);
                  }}
                />
              </div>
            </div> */}
          </Skeleton>
        </div>
      </>
    );
};




export default injectIntl(PayGrade);
