import React, { Fragment } from 'react';
import img3 from '../../../assets/images/UserStaff.png';
import { capitalizeFirstLetter, getInitialsFromString } from '../../../utils';
import { Empty,Skeleton } from 'antd';


const EnrollStudents = (props) => {

    return <div style={{ minHeight: "332px" }}>
        <Skeleton loading={props.listLoading} paragraph={{ rows: 5 }} active>

        {(props.schoolStudentList[props.pageNumber] || []).length ? (props.schoolStudentList[props.pageNumber] || []).slice(0, 6)?.map(data => {
            return <div className='icon_name_label pb15' >
                <div className="name-icon">
                    {getInitialsFromString(data.studentName)
                        || "AT"}
                </div>
                <div>
                    <b>{capitalizeFirstLetter(data.studentName)}</b>
                    <div>{data.className}</div>
                </div>
            </div>

        }) : <Empty />}
</Skeleton>
    </div>
};

export default EnrollStudents;