import React, { useState, Fragment } from "react";
import { NavLink } from "react-router-dom";
import { injectIntl, FormattedMessage } from "react-intl";
import "./index.scss";
import ReceivePayment from "../../containers/customer/invoice/PayInvoice";
import PayInvoice from "../../containers/supplier/invoice/PayInvoice";
import {
  MODAL_TYPE,
  MODULE_CODED_VALUES,
  PERMISSION_VALUES,
} from "../../static/constants";
import { checkACLPermission, getInitialsFromString } from "../../utils";
import { getLabelName } from "../modal/modalBody/customerInfo";
import NewLedgerComponent from "../../containers/modal/modalBody/finance/LedgerAccount";
import LockScreen from "../../containers/modal/modalBody/LockScreen";
import RegistrationFormSetting from '../../containers/modal/modalBody/settings/RegistrationFormSetting';
import ImportDocuments from '../../containers/modal/modalBody/schoolManagement/ImportDocuments';
import CreateAssignment from '../../containers/modal/modalBody/schoolManagement/CreateAssignment';
import { BankFilled, DashboardFilled, GoldFilled, ShopFilled, ShoppingFilled, AppstoreFilled, HddFilled,
   BuildFilled, TagsFilled, SettingFilled, SwitcherFilled, TabletFilled, LayoutFilled, FundFilled, DatabaseFilled, SnippetsFilled, 
   ContainerFilled, EditFilled, NotificationFilled, MessageFilled, ContactsFilled, ProjectFilled, DollarCircleFilled,
   ReconciliationFilled, FileTextFilled, BookFilled, MobileFilled, DiffFilled, PieChartFilled, ProfileFilled, 
   CreditCardFilled, FilePptFilled, PlusSquareFilled, BoxPlotFilled,MinusCircleFilled, IdcardFilled, FileAddFilled, CarFilled,
   PlusCircleFilled, ExclamationCircleFilled, ControlFilled, FileFilled, SlidersFilled, EuroCircleFilled, CloudFilled, FolderAddFilled,
   SafetyCertificateFilled, WalletFilled, FileMarkdownFilled, FilterFilled, ApiFilled, CompassFilled, CarryOutFilled, FolderOpenFilled, } from '@ant-design/icons';


const SidebarMenu = React.memo((props) => {
  const { permissions, isCollapsed } = props;
  const primaryPerm = (permissions || {}).primary || [];

  const paymentReceive = (props) => {
    let modalData = {
      title: (
        <FormattedMessage
          id="sidebar.salesPayment.popup.title"
          defaultMessage=""
        />
      ),
      modalBody: <ReceivePayment {...props} invoiceData={{}} />,
      wrapClassName: "pay-invoice-modal",
      width: 500,
      hideFooter: true,
    };
    props.showModal(modalData);
  };

  const newLadgerAccounts = (props) => {
    props.showModal({
      title: (
        <Fragment>
          <div className="ant-modal-title">
            <FormattedMessage
              id="sidebar.newChartOfAccount.popup.title"
              defaultMessage=""
            />
          </div>
        </Fragment>
      ),
      modalBody: <NewLedgerComponent {...props} />,
      width: 1100,
      hideFooter: true,
    });
  };

  const payInvoice = (props) => {
    let modalData = {
      title: (
        <FormattedMessage
          id="sidebar.purchasePayment.popup.title"
          defaultMessage=""
        />
      ),
      modalBody: <PayInvoice {...props} invoiceData={{}} />,
      width: 600,
      hideFooter: true,
      wrapClassName: "supplier-payment-modal",
    };
    props.showModal(modalData);
  };

  const handleNewTaxAddition = (props) => {
    const { showModal, companyInfo } = props;
    const formData = {};
    formData.relationshipId = companyInfo.relationshipId;
    const data = {
      title: <FormattedMessage id="addItem.text.newTax" defaultMessage="" />,
      formData,
      hideFooter: true,
      modalData: {
        modalType: MODAL_TYPE.NEW_TAX,
        data: { formData: formData },
      },
      handleSubmit: (formData = {}) => {
        data.formData.submittedOnce = true;
        showModal(data);
      },
    };
    showModal(data);
  };

  const handleNewWarehouseAddition = (props) => {
    const formData = {};
    const { showModal, companyInfo } = props;
    formData.relationshipId = companyInfo.relationshipId;
    const data = {
      title: <FormattedMessage id="addItem.text.warehouse" defaultMessage="" />,
      formData,
      hideFooter: true,
      modalData: {
        modalType: MODAL_TYPE.WAREHOUSE,
        data: { formData: formData },
      },
      handleSubmit: (formData = {}) => {
        data.formData.submittedOnce = true;
        showModal(data);
      },
    };
    showModal(data);
  };

  const addItemConfirmationModal = (props, text, modalType) => {
    const { showModal, companyInfo } = props;
    const labelName = getLabelName(modalType);
    const modalBody = (
      <span>
        <FormattedMessage
          id="addItem.confirmation"
          values={{ value: `${text} to ` }}
          defaultMessage={``}
        />
        {labelName} ?
      </span>
    );

    let addFunc;

    switch (modalType) {
      case MODAL_TYPE.PRODUCT_CATEGORY: {
        addFunc = props.addCategory;
        break;
      }
      case MODAL_TYPE.PRODUCT_BRAND: {
        addFunc = props.addBrand;
        break;
      }
      case MODAL_TYPE.PRODUCT_MANUFACTURER: {
        addFunc = props.addManufacturer;
        break;
      }
      default: {
        addFunc = () => { };
      }
    }

    const modalData = {
      modalBody,
      handleSubmit: () => {
        addFunc({ text: text, relationshipId: companyInfo.relationshipId });
        props.hideModal();
      },
    };
    showModal(modalData);
  };

  const getModalTitle = (modalType) => {
    let title;
    switch (modalType) {
      case MODAL_TYPE.PRODUCT_CATEGORY: {
        title = (
          <FormattedMessage
            id="addItem.text.productCategory"
            defaultMessage=""
          />
        );
        break;
      }
      case MODAL_TYPE.PRODUCT_BRAND: {
        title = (
          <FormattedMessage id="addItem.text.productBrand" defaultMessage="" />
        );
        break;
      }
      case MODAL_TYPE.PRODUCT_MANUFACTURER: {
        title = (
          <FormattedMessage
            id="addItem.text.productManufacturer"
            defaultMessage=""
          />
        );
        break;
      }
      case MODAL_TYPE.WAREHOUSE: {
        title = (
          <FormattedMessage id="addItem.text.warehouse" defaultMessage="" />
        );
        break;
      }
      case MODAL_TYPE.UOM: {
        title = <FormattedMessage id="addItem.text.uom" defaultMessage="" />;
        break;
      }
      case MODAL_TYPE.PROJECT: {
        title = (
          <FormattedMessage id="addItem.text.project" defaultMessage="" />
        );
        break;
      }
      default:
        title = (
          <FormattedMessage id="signup.confirmation.title" defaultMessage="" />
        );
    }
    return title;
  };

  const handleNewItemAddition = (props, modalType) => {
    const formData = { textBoxValue: "" };
    const { showModal } = props;
    const data = {
      title: getModalTitle(modalType),
      formData,
      modalData: { modalType, data: { formData: formData } },
      handleSubmit: (formData = {}) => {
        if (formData.textBoxValue && formData.textBoxValue.length) {
          addItemConfirmationModal(props, formData.textBoxValue, modalType);
        } else {
          data.formData.submittedOnce = true;
          showModal(data);
        }
      },
    };
    showModal(data);
  };

  const handleNewProjectAddition = (props, modalType) => {
    const formData = { textBoxValue: "" };
    const { showModal } = props;
    const data = {
      title: getModalTitle(modalType),
      formData,
      modalData: { modalType, data: { formData: formData } },
      hideFooter: true,
      handleSubmit: (formData = {}) => {
        if (formData.textBoxValue && formData.textBoxValue.length) {
          addItemConfirmationModal(props, formData.textBoxValue, modalType);
        } else {
          data.formData.submittedOnce = true;
          showModal(data);
        }
      },
    };
    showModal(data);
  };

  const newCustomer = () => {
    props.updateState({
      customer: {
        ...props.customer,
        customerDrawerVisible: true,
      },
    });
  };

  const newTermAndFee = () => {
    props.updateState({
      membership: {
        ...props.membership,
        addTermAndFeeDrawerVisible: true,
        openTermAndFeeDrawerFromListing: false,
      },
    });
  };

  const newMembershipForm = (registrationType) => {
    const modalData = {
      title: <div className='ant-modal-title'><FormattedMessage id='setting.student.registration.form.label' defaultMessage='' /></div>,
      modalBody: <RegistrationFormSetting registrationType={registrationType} {...props} style={{ overflow: 'auto' }} />,
      width: '100%',
      hideFooter: true,
      maskClosable: true,
      hideCloseIcon: true,
      hideTitle: true,
      keyboard: false,
      wrapClassName: "modal-custom-registration"
    };
    props.pushModalToStack(modalData);
  };

  const newMembershipOption = () => {
    props.updateState({
      membership: {
        ...props.membership,
        addMembershipOptionDrawerVisible: true,
        openMembershipOptionDrawerFromListing: false
      },
    });
  };

  const openCreateCampaignDrawer = () => {
    props.updateState({
      fundraising: {
        ...props.fundraising,
        addCampaignDrawerVisible: true,
        openCampaignDrawerFromListing: false,
      },
    });
  };

  const openCreatePledgeDrawer = () => {
    props.updateState({
      fundraising: {
        ...props.fundraising,
        addPledgeDrawerVisible: true,
        openPledgeDrawerFromListing: false,
      },
    });
  };

  const newComplaint = () => {
    props.updateState({
      salesComplaint: {
        ...props.salesComplaint,
        salesComplaintDrawerVisible: true,
      },
    });
  };

  const newCompanyHierarchy = () => {
    props.updateState({
      company: {
        ...props.company,
        hierarchyDrawerVisible: true,
      },
    });
  };

  const newEmployee = () => {
    props.updateState({
      company: {
        ...props.company,
        employeeDrawerVisible: true,
      },
    });
  };

  const newSupplier = () => {
    props.updateState({
      supplier: {
        ...props.supplier,
        supplierDrawerVisible: true,
      },
    });
  };

  const newInventory = () => {
    props.updateState({
      inventory: {
        ...props.inventory,
        inventoryDrawerVisible: true,
      },
    });
  };

  const newPriceListAddition = () => {
    const { showModal, companyInfo } = props;
    const formData = {};
    formData.relationshipId = companyInfo.relationshipId;
    const payload = { formData: formData };
    const data = {
      title: <FormattedMessage id="addItem.text.priceList" defaultMessage="" />,
      formData,
      hideFooter: true,
      modalData: {
        modalType: MODAL_TYPE.PRICE_LIST,
        data: payload,
      },
      handleSubmit: (formData = {}) => {
        data.formData.submittedOnce = true;
        showModal(data);
      },
    };
    showModal(data);
  };

  const lockScreen = (props) => {
    let modalData = {
      title: "",
      modalBody: <LockScreen {...props} />,
      hideFooter: true,
    };
    props.showModal(modalData);
  };

  const toggleDropDownMenu = (menu) => {
    if (menu.ulClassName === "third-level-nav") {
      menu.showSubMenu = !menu.showSubMenu;
      return;
    }
    sideMenuList.forEach((ele) => {
      if (menu.menuName !== ele.menuName) ele.showSubMenu = false;
      ele.children &&
        ele.children.forEach((ele2) => {
          ele2.showSubMenu = false;
        });
    });

    menu.showSubMenu = !menu.showSubMenu;
    props.history.sideMenuJSON = sideMenuList;
    updateSideMenuList(sideMenuList);
  };

  const getPermissionedMenuItem = (sideMenu) => {
    let filteredSideMenu = [];
    if (Array.isArray(sideMenu) && sideMenu.length) {
      filteredSideMenu = sideMenu.filter((menuObj) => {
        if (menuObj.hasAdditionIcon) {
          menuObj.hasAdditionIcon = checkACLPermission(
            primaryPerm,
            menuObj.moduleCode,
            PERMISSION_VALUES.CREATE
          );
        }
        if (menuObj.children) {
          if (Array.isArray(menuObj.children) && menuObj.children.length) {
            menuObj.children = getPermissionedMenuItem(menuObj.children);
            if (menuObj.moduleCode) {
              return checkACLPermission(
                primaryPerm,
                menuObj.moduleCode,
                menuObj.operation
              );
            }
            if (!menuObj.children.length && !menuObj.moduleCode) {
              return false;
            }
            return true;
          } else {
            return checkACLPermission(
              primaryPerm,
              menuObj.moduleCode,
              menuObj.operation
            );
          }
        } else {
          return checkACLPermission(
            primaryPerm,
            menuObj.moduleCode,
            menuObj.operation
          );
        }
      });
    }
    return filteredSideMenu;
  };

  const newDocument = () => {
    const modalData = {
      title: <div className='ant-modal-title'><FormattedMessage id='uploadDocument.button' defaultMessage='' /></div>,
      modalBody: <ImportDocuments  {...props} style={{ overflow: 'auto' }} />,
      hideFooter: true,
      width: 900,
      height: 450,
      maskClosable: true,
      keyboard: false,
      // wrapClassName: "modal-custom-registration"
    };
    props.pushModalToStack(modalData);
  };

  const newAssignment = () => {
    const modalData = {
      title: <div className='ant-modal-title'><FormattedMessage id='newAssignment.text' defaultMessage='' /></div>,
      modalBody: <CreateAssignment  {...props} style={{ overflow: 'auto' }} />,
      hideFooter: true,
      width: 900,
      height: 450,
      maskClosable: true,
      keyboard: false,
      // wrapClassName: "modal-custom-registration"
    };
    props.pushModalToStack(modalData);
  };

  const sideMenuUnFiltered = [
    // {
    //   linkClassName: "link",
    //   onClick: toggleDropDownMenu,
    //   primaryClassName: "title",
    //   secondaryClassName: "title secondary",
    //   iconClassName: "fa fa-user",
    //   rightClassName: "fa fa-angle-right",
    //   icon: <ContactsFilled />,
    //   menuName: (
    //     <FormattedMessage id="sidebar.menuItem.membership" defaultMessage="" />
    //   ),
    //   showSubMenu: false,
    //   ulClassName: "sub-sidebar-nav",
    //   collapseSubMenuTitle: "Membership",
    //   children: [
    //     {
    //       to: "/admin/org-membership",
    //       linkClassName: "sub-link",
    //       onClick: () => {
    //         // newTermAndFee();
    //       },
    //       secondaryClassName: "add-icon",
    //       iconClassName: "fa fa-plus",
    //       icon: <ProjectFilled />,
    //       menuName: (
    //         <FormattedMessage
    //           id="sidebar.menuItem.member"
    //           defaultMessage=""
    //         />
    //       ),
    //       showSubMenu: false,
    //       hasAdditionIcon: false,
    //       moduleCode: MODULE_CODED_VALUES.MEMBERSHIP,
    //       operation: PERMISSION_VALUES.READ,
    //     },
    //     {
    //       to: "/admin/term-and-fee",
    //       linkClassName: "sub-link",
    //       onClick: () => {
    //         newTermAndFee();
    //       },
    //       secondaryClassName: "add-icon",
    //       iconClassName: "fa fa-plus",
    //       icon: <DollarCircleFilled />,
    //       menuName: (
    //         <FormattedMessage
    //           id="sidebar.menuItem.termAndFee"
    //           defaultMessage=""
    //         />
    //       ),
    //       showSubMenu: false,
    //       hasAdditionIcon: true,
    //       moduleCode: MODULE_CODED_VALUES.MEMBERSHIP,
    //       operation: PERMISSION_VALUES.READ,
    //     },
    //     {
    //       to: "/admin/membership-option",
    //       linkClassName: "sub-link",
    //       onClick: () => {
    //         newMembershipOption();
    //       },
    //       secondaryClassName: "add-icon",
    //       iconClassName: "fa fa-plus",
    //       icon: <GoldFilled />,
    //       menuName: (
    //         <FormattedMessage
    //           id="sidebar.menuItem.membershipOption"
    //           defaultMessage=""
    //         />
    //       ),
    //       showSubMenu: false,
    //       hasAdditionIcon: true,
    //       moduleCode: MODULE_CODED_VALUES.MEMBERSHIP,
    //       operation: PERMISSION_VALUES.READ,
    //     },
    //     {
    //       to: "/admin/membership-form-list",
    //       linkClassName: "sub-link",
    //       onClick: () => {
    //         newMembershipForm('membership');
    //       },
    //       secondaryClassName: "add-icon",
    //       iconClassName: "fa fa-plus",
    //       icon: <ReconciliationFilled />,
    //       menuName: (
    //         <FormattedMessage
    //           id="sidebar.menuItem.membershipForm"
    //           defaultMessage=""
    //         />
    //       ),
    //       showSubMenu: false,
    //       hasAdditionIcon: true,
    //       moduleCode: MODULE_CODED_VALUES.MEMBERSHIP,
    //       operation: PERMISSION_VALUES.READ,
    //     },
    //     // {
    //     //   to: "/admin/org-membership",
    //     //   linkClassName: "sub-link",
    //     //   onClick: () => {
    //     //     newMembershipForm('membership');
    //     //   },
    //     //   secondaryClassName: "add-icon",
    //     //   iconClassName: "fa fa-plus",
    //     //   menuName: (
    //     //     <FormattedMessage
    //     //       id="sidebar.menuItem.orgMembership"
    //     //       defaultMessage=""
    //     //     />
    //     //   ),
    //     //   showSubMenu: false,
    //     //   hasAdditionIcon: true,
    //     //   moduleCode: MODULE_CODED_VALUES.MEMBERSHIP,
    //     //   operation: PERMISSION_VALUES.READ,
    //     // },
    //   ],
    // },
    




    
    {
      linkClassName: "link",
      onClick: toggleDropDownMenu,
      primaryClassName: "title",
      secondaryClassName: "title secondary",
      iconClassName: "fa fa-user",
      icon : <BankFilled />,
      rightClassName: "fa fa-angle-right",
      menuName: (
        <FormattedMessage id="sidebar.menuItem.school" defaultMessage="" />
      ),
      showSubMenu: false,
      ulClassName: "sub-sidebar-nav",
      collapseSubMenuTitle: (
        <FormattedMessage id="sidebar.menuItem.school" defaultMessage="" />
      ),
      children: [
        {
          to: "/admin/school-management/dashboard",
          linkClassName: "sub-link",
          onClick: () => {
            // newTermAndFee();
          },
          secondaryClassName: "add-icon",
          iconClassName: "fa fa-plus",
          icon: <DashboardFilled />,
          menuName: (
            <FormattedMessage
              id="sidebar.menuItem.dashboard"
              defaultMessage=""
            />
          ),
          moduleCode: MODULE_CODED_VALUES.SCHOOL_DASHBOARD,
          operation: PERMISSION_VALUES.READ,
        },
        {
          to: "/admin/school-management/department",
          linkClassName: "sub-link",
          icon: <DatabaseFilled />,
          onClick: () => {
            // newTermAndFee();
          },
          secondaryClassName: "add-icon",
          iconClassName: "fa fa-plus",
          menuName: (
            <FormattedMessage
              id="addItem.text.department"
              defaultMessage=""
            />
          ),
          moduleCode: MODULE_CODED_VALUES.SCHOOL_DEPARTMENT,
          operation: PERMISSION_VALUES.READ,
        },
        {
          // to: "/admin/school-management/class",
          onClick: toggleDropDownMenu,
          ulClassName: "third-level-nav",
          linkClassName: "sub-link",
          thirdLevelMenuClass: "sub-link",
          rightClassName: "fa fa-angle-right",
          // icon: <SnippetsFilled />,
          //iconClassName: "fa fa-plus",
          menuName: (
            <FormattedMessage
              id="sidebar.menuItem.courseDetails"
              defaultMessage=""
            />
          ),
          moduleCode: MODULE_CODED_VALUES.SCHOOL_COURSE_DETAIL,
          operation: PERMISSION_VALUES.READ,
          showSubMenu: false,
          hasAdditionIcon: false,
          children: [
            {
              to: "/admin/school-management/course",
              linkClassName: "third-nav-link",
              onClick: () => {
                // newTermAndFee();
              },
              secondaryClassName: "add-icon",
              iconClassName: "fa fa-plus",
              menuName: (
                <FormattedMessage
                  id="sidebar.menuItem.course"
                  defaultMessage=""
                />
              ),
              moduleCode: MODULE_CODED_VALUES.SCHOOL_COURSE_DETAIL,
              operation: PERMISSION_VALUES.READ,
            },
            {
              to: "/admin/school-management/subject",
              linkClassName: "third-nav-link",
              onClick: () => {
                // newTermAndFee();
              },
              secondaryClassName: "add-icon",
              iconClassName: "fa fa-plus",
              menuName: (
                <FormattedMessage
                  id="sidebar.menuItem.subject"
                  defaultMessage=""
                />
              ),
              moduleCode: MODULE_CODED_VALUES.SCHOOL_COURSE_DETAIL,
              operation: PERMISSION_VALUES.READ,
            },
            {
              to: "/admin/school-management/course-term",
              linkClassName: "third-nav-link",
              onClick: () => {
                // newTermAndFee();
              },
              secondaryClassName: "add-icon",
              iconClassName: "fa fa-plus",
              menuName: (
                <FormattedMessage
                  id="sidebar.menuItem.courseTerm"
                  defaultMessage=""
                />
              ),
              moduleCode: MODULE_CODED_VALUES.SCHOOL_COURSE_DETAIL,
              operation: PERMISSION_VALUES.READ,
            }]
        },
        {
          // to: "/admin/school-management/class",
          onClick: toggleDropDownMenu,
          ulClassName: "third-level-nav",
          linkClassName: "sub-link",
          thirdLevelMenuClass: "sub-link",
          rightClassName: "fa fa-angle-right",
          // icon: <ContainerFilled />,
          //iconClassName: "fa fa-plus",
          menuName: (
            <FormattedMessage
              id="sidebar.menuItem.classDetails"
              defaultMessage=""
            />
          ),
          moduleCode: MODULE_CODED_VALUES.SCHOOL_CLASS_DETAIL,
          operation: PERMISSION_VALUES.READ,
          showSubMenu: false,
          hasAdditionIcon: false,
          children: [
            {
              to: "/admin/school-management/class",
              linkClassName: "third-nav-link",
              onClick: () => {
                // newTermAndFee();
              },
              secondaryClassName: "add-icon",
              iconClassName: "fa fa-plus",
              menuName: (
                <FormattedMessage
                  id="sidebar.menuItem.class"
                  defaultMessage=""
                />
              ),
              moduleCode: MODULE_CODED_VALUES.SCHOOL_CLASS_DETAIL,
              operation: PERMISSION_VALUES.READ,
            },
            
            {
              to: "/admin/school-management/section",
              linkClassName: "third-nav-link",
              onClick: () => {
                // newTermAndFee();
              },
              secondaryClassName: "add-icon",
              iconClassName: "fa fa-plus",
              menuName: (
                <FormattedMessage
                  id="sidebar.menuItem.section"
                  defaultMessage=""
                />
              ),
              moduleCode: MODULE_CODED_VALUES.SCHOOL_CLASS_DETAIL,
              operation: PERMISSION_VALUES.READ,
            },
            {
              to: "/admin/school-management/assignment",
              linkClassName: "third-nav-link",
              onClick: () => {
               // newAssignment();
              },
              secondaryClassName: "add-icon",
              iconClassName: "fa fa-plus",
              menuName: (
                <FormattedMessage
                  id="assignment.text"
                  defaultMessage=""
                />
              ),
              showSubMenu: false,
             // hasAdditionIcon: true,
              moduleCode: MODULE_CODED_VALUES.SCHOOL_CLASS_DETAIL,
              operation: PERMISSION_VALUES.READ,
            },
          
          ]
        },
        // {
        //   to: "/admin/school-management/school",
        //   linkClassName: "sub-link",
        //   onClick: () => {
        //     // newTermAndFee();
        //   },
        //   secondaryClassName: "add-icon",
        //   iconClassName: "fa fa-plus",
        //   menuName: (
        //     <FormattedMessage
        //       id="sidebar.menuItem.school"
        //       defaultMessage=""
        //     />
        //   ),
        //   showSubMenu: false,
        //   hasAdditionIcon: false,
        //   moduleCode: MODULE_CODED_VALUES.MEMBERSHIP,
        //   operation: PERMISSION_VALUES.READ,
        // },

        // {
        //   to: "/admin/school-management/grade",
        //   linkClassName: "sub-link",
        //   onClick: () => {
        //     // newTermAndFee();
        //   },
        //   secondaryClassName: "add-icon",
        //   iconClassName: "fa fa-plus",
        //   menuName: (
        //     <FormattedMessage
        //       id="sidebar.menuItem.grade"
        //       defaultMessage=""
        //     />
        //   ),
        //   moduleCode: MODULE_CODED_VALUES.MEMBERSHIP,
        //   operation: PERMISSION_VALUES.READ,
        // },

        {
          to: "/admin/school-management/attendance",
          linkClassName: "sub-link",
          onClick: () => {
            // newTermAndFee();
          },
          secondaryClassName: "add-icon",
          iconClassName: "fa fa-plus",
          icon: <EditFilled />,
          menuName: (
            <FormattedMessage
              id="sidebar.menuItem.attendance"
              defaultMessage=""
            />
          ),
          moduleCode: MODULE_CODED_VALUES.SCHOOL_ATTENDANCE,
          operation: PERMISSION_VALUES.READ,
        }, 
        {
          to: "/admin/school-management/communication",
          linkClassName: "sub-link",
          onClick: () => {
            // newTermAndFee();
          },
          secondaryClassName: "add-icon",
          iconClassName: "fa fa-plus",
          icon: <MessageFilled />,
          menuName: (
            <FormattedMessage
              id="communication.text"
              defaultMessage=""
            />
          ),
          moduleCode: MODULE_CODED_VALUES.SCHOOL_COMMUNICATION,
          operation: PERMISSION_VALUES.READ,
        },
        {
          to: "/admin/school-management/broadcast-message",
          linkClassName: "sub-link",
          onClick: () => {
            // newTermAndFee();
          },
          secondaryClassName: "add-icon",
          iconClassName: "fa fa-plus",
          icon: <NotificationFilled />,
          menuName: (
            <FormattedMessage
              id="broadcastMessage.text"
              defaultMessage=""
            />
          ),
          moduleCode: MODULE_CODED_VALUES.SCHOOL_BROADCAST_MESSAGE,
          operation: PERMISSION_VALUES.READ,
        },
        {
          onClick: toggleDropDownMenu,
          ulClassName: "third-level-nav",
          linkClassName: "sub-link",
          thirdLevelMenuClass: "sub-link",
          rightClassName: "fa fa-angle-right",
          //iconClassName: "fa fa-plus",
          // icon: <HomeFilled />,
          menuName: (
            <FormattedMessage
              id="sidebar.menuItem.parent"
              defaultMessage=""
            />
          ),
          showSubMenu: false,
          hasAdditionIcon: false,
          children: [
            {
              to: "/admin/school-management/customers",
              state: { module: 'school' },
              linkClassName: "third-nav-link",
              onClick: (e, menu) => {
                //props.history.push("/admin/student-invoice");
              },
              secondaryClassName: "add-icon",
              iconClassName: "fa fa-plus",
              menuName: (
                <FormattedMessage
                  id="sidebar.menuItem.parent"
                  defaultMessage=""
                />
              ),
              showSubMenu: false,
              hasAdditionIcon: false,
              moduleCode: MODULE_CODED_VALUES.SCHOOL_CUSTOMERS,
              operation: PERMISSION_VALUES.READ,
            },
            {
              to: "/admin/school-management/parent",
              state: { module: 'school' },
              linkClassName: "third-nav-link",
              onClick: (e, menu) => {
                //props.history.push("/admin/student-invoice");
              },
              secondaryClassName: "add-icon",
              iconClassName: "fa fa-plus",
              menuName: (
                <FormattedMessage
                  id="registration.text"
                  defaultMessage=""
                />
              ),
              showSubMenu: false,
              hasAdditionIcon: false,
              moduleCode: MODULE_CODED_VALUES.SCHOOL_PARENTS,
              operation: PERMISSION_VALUES.READ,
            },
            {
              to: "/admin/school-management/student",
              linkClassName: "third-nav-link",
              onClick: () => {
                // newTermAndFee();
              },
              secondaryClassName: "add-icon",
              iconClassName: "fa fa-plus",
              menuName: (
                <FormattedMessage
                  id="sidebar.menuItem.student"
                  defaultMessage=""
                />
              ),
              moduleCode: MODULE_CODED_VALUES.SCHOOL_STUDENT,
              operation: PERMISSION_VALUES.READ,
            },
          
          ]
        },
        // {
        //   to: "/admin/school-management/parent",
        //   linkClassName: "sub-link",
        //   onClick: () => {
        //     // newTermAndFee();
        //   },
        //   secondaryClassName: "add-icon",
        //   iconClassName: "fa fa-plus",
        //   menuName: (
        //     <FormattedMessage
        //       id="sidebar.menuItem.parent"
        //       defaultMessage=""
        //     />
        //   ),
        //   moduleCode: MODULE_CODED_VALUES.SCHOOL_PARENTS,
        //   operation: PERMISSION_VALUES.READ,
        // },

        // {
        //   to: "/admin/school-management/student",
        //   linkClassName: "sub-link",
        //   onClick: () => {
        //     // newTermAndFee();
        //   },
        //   secondaryClassName: "add-icon",
        //   iconClassName: "fa fa-plus",
        //   menuName: (
        //     <FormattedMessage
        //       id="sidebar.menuItem.student"
        //       defaultMessage=""
        //     />
        //   ),
        //   moduleCode: MODULE_CODED_VALUES.SCHOOL_STUDENT,
        //   operation: PERMISSION_VALUES.READ,
        // },
        
        {
          to: "/admin/school-management/teachers",
          linkClassName: "sub-link",
          onClick: () => {
            // newTermAndFee();
          },
          secondaryClassName: "add-icon",
          iconClassName: "fa fa-plus",
          icon: <ContactsFilled />,
          menuName: (
            <FormattedMessage
              id="sidebar.menuItem.teachers"
              defaultMessage=""
            />
          ),
          moduleCode: MODULE_CODED_VALUES.SCHOOL_TEACHER,
          operation: PERMISSION_VALUES.READ,
        },
        {
          to: "/admin/school-management/online-user",
          linkClassName: "sub-link",
          onClick: () => {
            // newTermAndFee();
          },
          secondaryClassName: "add-icon",
          iconClassName: "fa fa-plus",
          icon: <MobileFilled />,
          menuName: (
            <FormattedMessage
              id="onlineUser.text"
              defaultMessage=""
            />
          ),
          moduleCode: MODULE_CODED_VALUES.SCHOOL_ONLINE_USER,
          operation: PERMISSION_VALUES.READ,
        },
        {
          onClick: toggleDropDownMenu,
          ulClassName: "third-level-nav",
          linkClassName: "sub-link",
          thirdLevelMenuClass: "sub-link",
          rightClassName: "fa fa-angle-right",
          //iconClassName: "fa fa-plus",
          // icon: <DollarCircleFilled />,
          menuName: (
            <FormattedMessage
              id="fees.text"
              defaultMessage=""
            />
          ),
          moduleCode: MODULE_CODED_VALUES.SCHOOL_INVOICE,
          operation: PERMISSION_VALUES.READ,
          showSubMenu: false,
          hasAdditionIcon: false,
          children: [
            {
              to: "/admin/school-management/invoice-list",
              state: {module: 'school'},
              linkClassName: "third-nav-link",
              onClick: (e, menu) => {
                props.history.push("/admin/student-invoice");
              },
              secondaryClassName: "add-icon",
              iconClassName: "fa fa-plus",
              menuName: (
                <FormattedMessage
                  id="tabs.Invoice"
                  defaultMessage=""
                />
              ),
              showSubMenu: false,
              hasAdditionIcon: true,
              moduleCode: MODULE_CODED_VALUES.SCHOOL_INVOICE,
              operation: PERMISSION_VALUES.READ,
            },
            {
              to: "/admin/school-management/recurring-payment",
              linkClassName: "third-nav-link",
              onClick: () => {
                // newTermAndFee();
              },
              secondaryClassName: "add-icon",
              iconClassName: "fa fa-plus",
              menuName: (
                <FormattedMessage
                  id="recurringPayment.text"
                  defaultMessage=""
                />
              ),
              moduleCode: MODULE_CODED_VALUES.SCHOOL_RECURRING_PAYMENTS,
              operation: PERMISSION_VALUES.READ,
            },
          
          ]
        },
        // {
        //   to: "/admin/sales-invoice-list",
        //   state: {module: 'school'},
        //   linkClassName: "sub-link",
        //   onClick: (e, menu) => {
        //     props.history.push("/admin/student-invoice");
        //   },
        //   secondaryClassName: "add-icon",
        //   iconClassName: "fa fa-plus",
        //   menuName: (
        //     <FormattedMessage
        //       id="tabs.Invoice"
        //       defaultMessage=""
        //     />
        //   ),
        //   showSubMenu: false,
        //   hasAdditionIcon: true,
        //   moduleCode: MODULE_CODED_VALUES.SCHOOL_INVOICE,
        //   operation: PERMISSION_VALUES.READ,
        // },
        // {
        //   to: "/admin/school-management/recurring-payment",
        //   linkClassName: "sub-link",
        //   onClick: () => {
        //     // newTermAndFee();
        //   },
        //   secondaryClassName: "add-icon",
        //   iconClassName: "fa fa-plus",
        //   menuName: (
        //     <FormattedMessage
        //       id="recurringPayment.text"
        //       defaultMessage=""
        //     />
        //   ),
        //   moduleCode: MODULE_CODED_VALUES.SCHOOL_DEPARTMENT,
        //   operation: PERMISSION_VALUES.READ,
        // },
        {
          to: "/admin/student-form-list",
          linkClassName: "sub-link",
          onClick: () => {
            newMembershipForm('student');
          },
          secondaryClassName: "add-icon",
          iconClassName: "fa fa-plus",
          icon: <ProfileFilled />,
          menuName: (
            <FormattedMessage
              id="setting.student.registration.list.heading"
              defaultMessage=""
            />
          ),
          showSubMenu: false,
          hasAdditionIcon: true,
          moduleCode: MODULE_CODED_VALUES.SCHOOL_FORM,
          operation: PERMISSION_VALUES.READ,
        },
        {
          to: "/admin/school-management/financial-report",
          linkClassName: "sub-link",
          onClick: () => {
            newAssignment();
          },
          secondaryClassName: "add-icon",
          iconClassName: "fa fa-plus",
          icon: <PieChartFilled />,
          menuName: (
            <FormattedMessage
              id="financialReport.text"
              defaultMessage=""
            />
          ),
          showSubMenu: false,
          hasAdditionIcon: true,
          moduleCode: MODULE_CODED_VALUES.SCHOOL_ASSIGNMENTS,
          operation: PERMISSION_VALUES.READ,
        },
        {
          to: "/admin/document-list",
          linkClassName: "sub-link",
          onClick: () => {
            newDocument();
          },
          secondaryClassName: "add-icon",
          iconClassName: "fa fa-plus",
          icon: <DiffFilled />,
          menuName: (
            <FormattedMessage
              id="activity.documents"
              defaultMessage=""
            />
          ),
          showSubMenu: false,
          hasAdditionIcon: true,
          moduleCode: MODULE_CODED_VALUES.SCHOOL_DOCUMENT,
          operation: PERMISSION_VALUES.READ,
        },
      ],
    },

    // {
    //   linkClassName: "link",
    //   onClick: toggleDropDownMenu,
    //   primaryClassName: "title",
    //   secondaryClassName: "title secondary",
    //   iconClassName: "fa fa-credit-card-alt",
    //   icon: <FundFilled />,
    //   rightClassName: "fa fa-angle-right",
    //   menuName: (
    //     <FormattedMessage id="sidebar.menuItem.fundraising" defaultMessage="" />
    //   ),
    //   showSubMenu: false,
    //   ulClassName: "sub-sidebar-nav",
    //   collapseSubMenuTitle: "Fundraising",
    //   children: [
    //     {
    //       to: "/admin/fundraising-campaign",
    //       linkClassName: "sub-link",
    //       onClick: () => {
    //         openCreateCampaignDrawer();
    //       },
    //       secondaryClassName: "add-icon",
    //       iconClassName: "fa fa-plus",
    //       icon: <FlagFilled />,
    //       menuName: (
    //         <FormattedMessage
    //           id="fundraising.campaign.listing.header.campaign"
    //           defaultMessage=""
    //         />
    //       ),
    //       showSubMenu: false,
    //       hasAdditionIcon: true,
    //       moduleCode: MODULE_CODED_VALUES.FUNDRAISING,
    //       operation: PERMISSION_VALUES.READ,
    //     },
    //     // {
    //     //   to: "/admin/donations",
    //     //   linkClassName: "sub-link",
    //     //   onClick: () => { },
    //     //   secondaryClassName: "add-icon",
    //     //   iconClassName: "fa fa-plus",
    //     //   menuName: (
    //     //     <FormattedMessage
    //     //       id="sidebar.menuItem.donations"
    //     //       defaultMessage=""
    //     //     />
    //     //   ),
    //     //   showSubMenu: false,
    //     //   hasAdditionIcon: false,
    //     //   moduleCode: MODULE_CODED_VALUES.FUNDRAISING,
    //     //   operation: PERMISSION_VALUES.READ,
    //     // },
    //     {
    //       to: "/admin/fundraising-pledges",
    //       linkClassName: "sub-link",
    //       onClick: () => {
    //         openCreatePledgeDrawer()
    //       },
    //       secondaryClassName: "add-icon",
    //       iconClassName: "fa fa-plus",
    //       icon: <ContainerFilled />,
    //       menuName: (
    //         <FormattedMessage
    //           id="sidebar.menuItem.pledges"
    //           defaultMessage=""
    //         />
    //       ),
    //       showSubMenu: false,
    //       hasAdditionIcon: false,
    //       moduleCode: MODULE_CODED_VALUES.FUNDRAISING,
    //       operation: PERMISSION_VALUES.READ,
    //     },
    //     // {
    //     //   to: "/admin/recurring-donation-schedules",
    //     //   linkClassName: "sub-link",
    //     //   onClick: () => { },
    //     //   secondaryClassName: "add-icon",
    //     //   iconClassName: "fa fa-plus",
    //     //   menuName: (
    //     //     <FormattedMessage
    //     //       id="sidebar.menuItem.recurringDonationSchedules"
    //     //       defaultMessage=""
    //     //     />
    //     //   ),
    //     //   showSubMenu: false,
    //     //   hasAdditionIcon: false,
    //     //   moduleCode: MODULE_CODED_VALUES.FUNDRAISING,
    //     //   operation: PERMISSION_VALUES.READ,
    //     // },
    //     // {
    //     //   to: "/admin/peer-to-peer",
    //     //   linkClassName: "sub-link",
    //     //   onClick: () => { },
    //     //   secondaryClassName: "add-icon",
    //     //   iconClassName: "fa fa-plus",
    //     //   menuName: (
    //     //     <FormattedMessage
    //     //       id="sidebar.menuItem.peerToPeer"
    //     //       defaultMessage=""
    //     //     />
    //     //   ),
    //     //   showSubMenu: false,
    //     //   hasAdditionIcon: false,
    //     //   moduleCode: MODULE_CODED_VALUES.FUNDRAISING,
    //     //   operation: PERMISSION_VALUES.READ,
    //     // },
    //     {
    //       to: "/admin/fundraising-donation",
    //       linkClassName: "sub-link",
    //       secondaryClassName: "add-icon",
    //       iconClassName: "",
    //       icon: <DollarCircleFilled />,
    //       menuName: (
    //         <FormattedMessage
    //           id="sidebar.menuItem.donations"
    //           defaultMessage=""
    //         />
    //       ),
    //       showSubMenu: false,
    //       hasAdditionIcon: true,
    //       moduleCode: MODULE_CODED_VALUES.FUNDRAISING,
    //       operation: PERMISSION_VALUES.READ,
    //     },
    //   ],
    // },

    // {
    //   to: "/admin/form-builder",
    //   linkClassName: "link",
    //   secondaryClassName: "",
    //   onClick: () => {
    //     //lockScreen(props);
    //   },
    //   primaryClassName: "title",
    //   iconClassName: "fa fa-plus-square-o",
    //   moduleCode: MODULE_CODED_VALUES.NEWS_LETTER,
    //   operation: PERMISSION_VALUES.READ,
    //   menuName: (
    //     <FormattedMessage id="sidebar.menuItem.formBuilder" defaultMessage="" />
    //   ),
    //   showSubMenu: false,
    //   children: [],
    // },

    // {
    //   linkClassName: "link",
    //   onClick: toggleDropDownMenu,
    //   primaryClassName: "title",
    //   secondaryClassName: "title secondary",
    //   iconClassName: "fa fa-commenting-o",
    //   icon: <MessageFilled />,
    //   rightClassName: "fa fa-angle-right",
    //   menuName: (
    //     <FormattedMessage id="sidebar.menuItem.communication" defaultMessage="" />
    //   ),
    //   showSubMenu: false,
    //   ulClassName: "sub-sidebar-nav",
    //   collapseSubMenuTitle: "Communication",
    //   children: [
    //     {
    //       to: "/admin/account",
    //       linkClassName: "sub-link",
    //       onClick: () => { },
    //       secondaryClassName: "add-icon",
    //       iconClassName: "fa fa-plus",
    //       icon: <ContactsFilled />,
    //       menuName: (
    //         <FormattedMessage
    //           id="sidebar.menuItem.account"
    //           defaultMessage=""
    //         />
    //       ),
    //       showSubMenu: false,
    //       hasAdditionIcon: true,
    //       moduleCode: MODULE_CODED_VALUES.COMMUNICATION,
    //       operation: PERMISSION_VALUES.READ,
    //     },
    //     {
    //       to: "/admin/communication/dg",
    //       linkClassName: "sub-link",
    //       onClick: () => { },
    //       secondaryClassName: "add-icon",
    //       iconClassName: "fa fa-plus",
    //       icon: <GoldFilled />,
    //       menuName: (
    //         <FormattedMessage
    //           id="sidebar.menuItem.distributionGroup"
    //           defaultMessage=""
    //         />
    //       ),
    //       showSubMenu: false,
    //       hasAdditionIcon: true,
    //       moduleCode: MODULE_CODED_VALUES.COMMUNICATION,
    //       operation: PERMISSION_VALUES.READ,
    //     },
    //     {
    //       to: "/admin/news-letter-listing",
    //       linkClassName: "sub-link",
    //       secondaryClassName: "title secondary",
    //       onClick: () => {
    //         //lockScreen(props);
    //       },
    //       primaryClassName: "title",
    //       iconClassName: "fa fa-newspaper-o",
    //       moduleCode: MODULE_CODED_VALUES.COMMUNICATION,
    //       operation: PERMISSION_VALUES.READ,
    //       icon: <ContainerFilled />,
    //       menuName: (
    //         <FormattedMessage id="sidebar.menuItem.newsletter" defaultMessage="" />
    //       ),
    //       showSubMenu: false,
    //       children: [],
    //     },

    //     {
    //       to: "/admin/events",
    //       linkClassName: "sub-link",
    //       secondaryClassName: "title secondary",
    //       onClick: () => {
    //         //lockScreen(props);
    //       },
    //       primaryClassName: "title",
    //       iconClassName: "fa fa-calendar",
    //       moduleCode: MODULE_CODED_VALUES.COMMUNICATION,
    //       operation: PERMISSION_VALUES.READ,
    //       icon: <BookFilled />,
    //       menuName: (
    //         <FormattedMessage id="sidebar.menuItem.events" defaultMessage="" />
    //       ),
    //       showSubMenu: false,
    //       children: [],
    //     },
    //     {
    //       to: "/admin/communication/contact",
    //       linkClassName: "sub-link",
    //       secondaryClassName: "title secondary",
    //       onClick: () => {

    //       },
    //       primaryClassName: "title",
    //       iconClassName: "fa fa-calendar",
    //       moduleCode: MODULE_CODED_VALUES.COMMUNICATION,
    //       operation: PERMISSION_VALUES.READ,
    //       icon: <PhoneFilled />,
    //       menuName: (
    //         <FormattedMessage id="contact" defaultMessage="" />
    //       ),
    //       showSubMenu: false,
    //       children: [],
    //     },
    //     {
    //       to: "/admin/school-management/communication",
    //       linkClassName: "sub-link",
    //       onClick: () => {
    //         // newTermAndFee();
    //       },
    //       secondaryClassName: "add-icon",
    //       iconClassName: "fa fa-plus",
    //       icon: <MailFilled />,
    //       menuName: (
    //         <FormattedMessage
    //           id="communication.emails.text"
    //           defaultMessage=""
    //         />
    //       ),
    //       moduleCode: MODULE_CODED_VALUES.SCHOOL_COMMUNICATION,
    //       operation: PERMISSION_VALUES.READ,
    //     },
    //   ],
    // },
    // {
    //   to: "/admin/news-letter",
    //   linkClassName: "link",
    //   secondaryClassName: "",
    //   primaryClassName: "title",
    //   iconClassName: "fa fa-plus-square-o",
    //   menuName: (
    //     <FormattedMessage id="sidebar.menuItem.formBuilder" defaultMessage="" />
    //   ),
    //   showSubMenu: false,
    //   moduleCode: MODULE_CODED_VALUES.NEWS_LETTER,
    //   operation: PERMISSION_VALUES.READ,
    //   children: [],
    // },


    {
      linkClassName: "link",
      onClick: toggleDropDownMenu,
      primaryClassName: "title",
      secondaryClassName: "title secondary",
      iconClassName: "fa fa-truck",
      icon: <ShopFilled />, 
      rightClassName: "fa fa-angle-right",
      menuName: (
        <FormattedMessage id="sidebar.menuItem.purchase" defaultMessage="" />
      ),
      showSubMenu: false,
      ulClassName: "sub-sidebar-nav",
      collapseSubMenuTitle: "Purchase",
      children: [
        {
          to: "/admin/suppliers",
          linkClassName: "sub-link",
          onClick: (e) => {
            newSupplier();
          },
          secondaryClassName: "add-icon",
          iconClassName: "fa fa-plus",
          icon: <CreditCardFilled />,
          menuName: (
            <FormattedMessage
              id="sidebar.menuItem.suppliers"
              defaultMessage=""
            />
          ),
          showSubMenu: false,
          hasAdditionIcon: true,
          moduleCode: MODULE_CODED_VALUES.SUPPLIERS,
          operation: PERMISSION_VALUES.READ,
        },
        {
          to: "/admin/purchase-request-list",
          linkClassName: "sub-link",
          onClick: (e, menu) => {
            props.history.push("/admin/pr-create");
          },
          secondaryClassName: "add-icon",
          iconClassName: "fa fa-plus",
          icon: <FilePptFilled />,
          menuName: (
            <FormattedMessage
              id="sidebar.menuItem.purchaseRequest"
              defaultMessage=" "
            />
          ),
          showSubMenu: false,
          hasAdditionIcon: true,
          moduleCode: MODULE_CODED_VALUES.PURCHASE_REQUEST,
          operation: PERMISSION_VALUES.READ,
        },
        {
          to: "/admin/rfq-list",
          linkClassName: "sub-link",
          onClick: (e, menu) => {
            props.history.push("/admin/rfq-create");
          },
          secondaryClassName: "add-icon",
          iconClassName: "fa fa-plus",
          icon: <PlusSquareFilled />,
          menuName: (
            <FormattedMessage id="sidebar.menuItem.RFQ" defaultMessage=" " />
          ),
          showSubMenu: false,
          hasAdditionIcon: true,
          moduleCode: MODULE_CODED_VALUES.RFQ,
          operation: PERMISSION_VALUES.READ,
        },
        {
          to: "/admin/purchase-order-list",
          linkClassName: "sub-link",
          onClick: (e, menu) => {
            props.history.push("/admin/po-create");
          },
          secondaryClassName: "add-icon",
          iconClassName: "fa fa-plus",
          icon: <SnippetsFilled />,
          menuName: (
            <FormattedMessage
              id="sidebar.menuItem.purchaseOrder"
              defaultMessage=" "
            />
          ),
          showSubMenu: false,
          hasAdditionIcon: true,
          moduleCode: MODULE_CODED_VALUES.PURCHASE_ORDER,
          operation: PERMISSION_VALUES.READ,
        },
        {
          to: "/admin/inbound-delivery-list",
          linkClassName: "sub-link",
          icon: <BoxPlotFilled />,
          menuName: (
            <FormattedMessage
              id="sidebar.menuItem.inboundDelivery"
              defaultMessage=""
            />
          ),
          showSubMenu: false,
          secondaryClassName: "add-icon",
          iconClassName: "fa fa-plus",
          onClick: (e, menu) => {
            props.history.push({
              pathname: "/admin/inbound-delivery-create",
              state: {
                type: "new",
              },
            });
          },
          hasAdditionIcon: true,
          moduleCode: MODULE_CODED_VALUES.PURCHASE_ORDER,
          operation: PERMISSION_VALUES.READ,
        },
        {
          to: "/admin/purchase-invoice-list",
          linkClassName: "sub-link",
          onClick: (e, menu) => {
            // props.history.push("/admin/purchase-invoice");
          },
          secondaryClassName: "add-icon",
          iconClassName: "fa fa-plus",
          icon: <FileTextFilled />,
          menuName: (
            <FormattedMessage
              id="sidebar.menuItem.purchaseInvoice"
              defaultMessage=""
            />
          ),
          showSubMenu: false,
          hasAdditionIcon: true,
          moduleCode: MODULE_CODED_VALUES.PURCHASE_INVOICE,
          operation: PERMISSION_VALUES.READ,
        },
        {
          to: "/admin/supplier-payment-list",
          linkClassName: "sub-link",
          onClick: (e, menu) => {
            payInvoice(props);
          },
          secondaryClassName: "add-icon",
          iconClassName: "fa fa-plus",
          icon: <DollarCircleFilled />,
          menuName: (
            <FormattedMessage id="sidebar.menuItem.payment" defaultMessage="" />
          ),
          showSubMenu: false,
          hasAdditionIcon: true,
          moduleCode: MODULE_CODED_VALUES.SUPPLIER_PAYMENT,
          operation: PERMISSION_VALUES.READ,
        },
        {
          to: "/admin/debit-memo-list",
          linkClassName: "sub-link",
          onClick: (e, menu) => {
            props.history.push("/admin/debit-memo-create");
          },
          secondaryClassName: "add-icon",
          iconClassName: "fa fa-plus",
          icon: <MinusCircleFilled />,
          menuName: (
            <FormattedMessage
              id="sidebar.menuItem.debitMemo"
              defaultMessage=""
            />
          ),
          showSubMenu: false,
          hasAdditionIcon: true,
          moduleCode: MODULE_CODED_VALUES.DEBIT_MEMO,
          operation: PERMISSION_VALUES.READ,
        },
      ],
    },

    {
      linkClassName: "link",
      onClick: toggleDropDownMenu,
      primaryClassName: "title",
      secondaryClassName: "title secondary",
      iconClassName: "fa fa-shopping-cart",
      icon: <ShoppingFilled />,
      rightClassName: "fa fa-angle-right",
      menuName: (
        <FormattedMessage id="sidebar.menuItem.sales" defaultMessage="" />
      ),
      showSubMenu: false,
      ulClassName: "sub-sidebar-nav",
      collapseSubMenuTitle: "Sales",
      children: [
        {
          to: "/admin/customers",
          linkClassName: "sub-link",
          onClick: (e) => {
            newCustomer();
          },
          secondaryClassName: "add-icon",
          iconClassName: "fa fa-plus",
          icon: <IdcardFilled />,
          menuName: (
            <FormattedMessage
              id="sidebar.menuItem.customers"
              defaultMessage=""
            />
          ),
          showSubMenu: false,
          hasAdditionIcon: true,
          moduleCode: MODULE_CODED_VALUES.CUSTOMERS,
          operation: PERMISSION_VALUES.READ,
        },
        {
          to: "/admin/sales-inquiry-list",
          linkClassName: "sub-link",
          onClick: (e, menu) => {
            props.history.push("/admin/sales-inquiry-create");
          },
          secondaryClassName: "add-icon",
          iconClassName: "fa fa-plus",
          icon: <ContainerFilled />,
          menuName: (
            <FormattedMessage id="sidebar.menuItem.inquiry" defaultMessage="" />
          ),
          showSubMenu: false,
          hasAdditionIcon: true,
          moduleCode: MODULE_CODED_VALUES.SALES_QUOTE,
          operation: PERMISSION_VALUES.READ,
        },
        {
          to: "/admin/sq-list",
          linkClassName: "sub-link",
          onClick: (e, menu) => {
            props.history.push("/admin/create-sales-quote");
          },
          secondaryClassName: "add-icon",
          iconClassName: "fa fa-plus",
          icon: <BookFilled />,
          menuName: (
            <FormattedMessage
              id="sidebar.menuItem.salesQuote"
              defaultMessage=""
            />
          ),
          showSubMenu: false,
          hasAdditionIcon: true,
          moduleCode: MODULE_CODED_VALUES.SALES_QUOTE,
          operation: PERMISSION_VALUES.READ,
        },
        {
          to: "/admin/so-list",
          linkClassName: "sub-link",
          onClick: (e, menu) => {
            props.history.push("/admin/so-create");
          },
          secondaryClassName: "add-icon",
          iconClassName: "fa fa-plus",
          icon: <FileAddFilled />,
          menuName: (
            <FormattedMessage
              id="sidebar.menuItem.salesOrder"
              defaultMessage=""
            />
          ),
          showSubMenu: false,
          hasAdditionIcon: true,
          moduleCode: MODULE_CODED_VALUES.SALES_ORDER,
          operation: PERMISSION_VALUES.READ,
        },
        {
          to: "/admin/package-list",
          linkClassName: "sub-link",
          secondaryClassName: "add-icon",
          iconClassName: "fa fa-plus",
          icon: <BuildFilled />,
          menuName: (
            <FormattedMessage id="sidebar.menuItem.package" defaultMessage="" />
          ),
          onClick: (e, menu) => {
            props.history.push({
              pathname: "/admin/so/package",
              state: {
                type: "new",
              },
            });
          },
          hasAdditionIcon: true,
          showSubMenu: false,
          moduleCode: MODULE_CODED_VALUES.SALES_ORDER_PACKAGE,
          operation: PERMISSION_VALUES.READ,
        },
        {
          to: "/admin/shipment-list",
          linkClassName: "sub-link",
          secondaryClassName: "add-icon",
          iconClassName: "fa fa-plus",
          icon: <CarFilled />,
          menuName: (
            <FormattedMessage
              id="sidebar.menuItem.shipment"
              defaultMessage=""
            />
          ),
          onClick: (e, menu) => {
            props.history.push({
              pathname: "/admin/so/shipment",
              state: {
                type: "new",
              },
            });
          },
          hasAdditionIcon: true,
          showSubMenu: false,
          moduleCode: MODULE_CODED_VALUES.SALES_ORDER_SHIPMENT,
          operation: PERMISSION_VALUES.READ,
        },
        {
          to: "/admin/sales-invoice-list",
          linkClassName: "sub-link",
          onClick: (e, menu) => {
            props.history.push("/admin/sales-invoice");
          },
          secondaryClassName: "add-icon",
          iconClassName: "fa fa-plus",
          icon: <FileTextFilled />,
          menuName: (
            <FormattedMessage
              id="sidebar.menuItem.salesInvoice"
              defaultMessage=""
            />
          ),
          showSubMenu: false,
          hasAdditionIcon: true,
          moduleCode: MODULE_CODED_VALUES.SALES_INVOICE,
          operation: PERMISSION_VALUES.READ,
        },
        {
          to: "/admin/customer-payment-list",
          linkClassName: "sub-link",
          onClick: (e) => {
            paymentReceive(props);
          },
          secondaryClassName: "add-icon",
          iconClassName: "fa fa-plus",
          icon: <DollarCircleFilled />,
          menuName: (
            <FormattedMessage
              id="sidebar.menuItem.customerPayment"
              defaultMessage=""
            />
          ),
          showSubMenu: false,
          hasAdditionIcon: true,
          moduleCode: MODULE_CODED_VALUES.CUSTOMER_PAYMENT,
          operation: PERMISSION_VALUES.READ,
        },
        {
          to: "/admin/credit-memo-list",
          linkClassName: "sub-link",
          onClick: (e, menu) => {
            props.history.push("/admin/create-credit-memo");
          },
          secondaryClassName: "add-icon",
          iconClassName: "fa fa-plus",
          icon: <PlusCircleFilled />,
          menuName: (
            <FormattedMessage
              id="sidebar.menuItem.creditMemo"
              defaultMessage=""
            />
          ),
          showSubMenu: false,
          hasAdditionIcon: true,
          moduleCode: MODULE_CODED_VALUES.CREDIT_MEMO,
          operation: PERMISSION_VALUES.READ,
        },
        {
          to: "/admin/sales-complaint-list",
          linkClassName: "sub-link",
          onClick: (e) => {
            newComplaint();
          },
          secondaryClassName: "add-icon",
          iconClassName: "fa fa-plus",
          icon: <ExclamationCircleFilled />,
          menuName: (
            <FormattedMessage
              id="sidebar.menuItem.salesSupport"
              defaultMessage=""
            />
          ),
          showSubMenu: false,
          hasAdditionIcon: true,
          moduleCode: MODULE_CODED_VALUES.SALES_ORDER,
          operation: PERMISSION_VALUES.READ,
        },
        {
          to: "/admin/student-registration-list",
          linkClassName: "sub-link",
          onClick: (e) => {
            newComplaint();
          },
          secondaryClassName: "add-icon",
          iconClassName: "fa fa-plus",
          icon: <FileTextFilled />,
          menuName: (
            <FormattedMessage id="studentRegistration.text" defaultMessage="" />
          ),
          moduleCode: MODULE_CODED_VALUES.REGISTERED_MEMBER,
          operation: PERMISSION_VALUES.READ,
        },
      ],
    },

    {
      linkClassName: "link",
      onClick: toggleDropDownMenu,
      primaryClassName: "title",
      secondaryClassName: "title secondary",
      iconClassName: "fa fa-cubes",
      icon: <AppstoreFilled />,
      rightClassName: "fa fa-angle-right",
      menuName: (
        <FormattedMessage id="sidebar.menuItem.inventory" defaultMessage="" />
      ),
      showSubMenu: false,
      ulClassName: "sub-sidebar-nav",
      collapseSubMenuTitle: "Inventory",

      children: [
        {
          to: "/admin/products",
          linkClassName: "sub-link",
          onClick: (e) => {
            e.preventDefault();
            e.stopPropagation();
            newInventory();
          },
          secondaryClassName: "add-icon",
          iconClassName: "fa fa-plus",
          icon: <ProjectFilled />,
          menuName: (
            <FormattedMessage
              id="sidebar.menuItem.products"
              defaultMessage=""
            />
          ),
          showSubMenu: false,
          hasAdditionIcon: true,
          moduleCode: MODULE_CODED_VALUES.PRODUCTS,
          operation: PERMISSION_VALUES.READ,
        },
        // {
        //     linkClassName: "sub-link",
        //     onClick: toggleDropDownMenu,
        //     rightClassName: "fa fa-angle-right",
        //     menuName: <FormattedMessage id='sidebar.menuItem.warehouse' defaultMessage=""/>,
        //     ulClassName: "third-level-nav",
        //     thirdLevelMenuClass: "sub-link",
        //     showSubMenu: true,
        //     children: [
        //         {
        //             to: "/admin/warehouse/my-warehouse",
        //             linkClassName: "third-nav-link",
        //             onClick: (e) => (e) => {
        //                 handleNewWarehouseAddition(props);
        //             },
        //             secondaryClassName: "add-icon",
        //             // iconClassName: "fa fa-plus",
        //             menuName: <FormattedMessage id='sidebar.menuItem.myWarehouse' defaultMessage=""/>,
        //             showSubMenu: false,
        //             hasAdditionIcon: true,
        //             moduleCode: MODULE_CODED_VALUES.MY_WAREHOUSE,
        //             operation: PERMISSION_VALUES.READ
        //         },
        //         {
        //             to: "/admin/warehouse/stock-summary",
        //             linkClassName: "third-nav-link",
        //             menuName: <FormattedMessage id='sidebar.menuItem.stockSummary' defaultMessage=""/> ,
        //             showSubMenu: false,
        //             moduleCode: MODULE_CODED_VALUES.STOCK_SUMMARY,
        //             operation: PERMISSION_VALUES.READ
        //         },
        //         {
        //             to: "/admin/warehouse/stock-adjustment",
        //             linkClassName: "third-nav-link",
        //             menuName: <FormattedMessage id='sidebar.menuItem.stockAdjustment' defaultMessage=""/>,
        //             showSubMenu: false,
        //             moduleCode: MODULE_CODED_VALUES.STOCK_ADJUSTMENT,
        //             operation: PERMISSION_VALUES.READ
        //         },
        //         {
        //             to: "/admin/warehouse/aged-stock",
        //             linkClassName: "third-nav-link",
        //             menuName: <FormattedMessage id='sidebar.menuItem.agedStock' defaultMessage=""/>,
        //             showSubMenu: false,
        //             moduleCode: MODULE_CODED_VALUES.AGED_STOCK,
        //             operation: PERMISSION_VALUES.READ
        //         }
        //     ]
        // },
        {
          to: "/admin/warehouse/my-warehouse",
          linkClassName: "sub-link",
          onClick: (e) => (e) => {
            handleNewWarehouseAddition(props);
          },
          secondaryClassName: "add-icon",
          // iconClassName: "fa fa-plus",
          icon: <ControlFilled />,
          menuName: (
            <FormattedMessage
              id="sidebar.menuItem.myWarehouse"
              defaultMessage=""
            />
          ),
          showSubMenu: false,
          hasAdditionIcon: true,
          moduleCode: MODULE_CODED_VALUES.MY_WAREHOUSE,
          operation: PERMISSION_VALUES.READ,
        },
        {
          to: "/admin/warehouse/stock-summary",
          linkClassName: "sub-link",
          icon: <FileFilled />,
          menuName: (
            <FormattedMessage
              id="sidebar.menuItem.stockSummary"
              defaultMessage=""
            />
          ),
          showSubMenu: false,
          moduleCode: MODULE_CODED_VALUES.STOCK_SUMMARY,
          operation: PERMISSION_VALUES.READ,
        },
        {
          to: "/admin/warehouse/stock-adjustment",
          linkClassName: "sub-link",
          icon: <SlidersFilled />,
          menuName: (
            <FormattedMessage
              id="sidebar.menuItem.stockAdjustment"
              defaultMessage=""
            />
          ),
          showSubMenu: false,
          moduleCode: MODULE_CODED_VALUES.STOCK_ADJUSTMENT,
          operation: PERMISSION_VALUES.READ,
        },
        {
          to: "/admin/warehouse/all-inventory-valuation",
          linkClassName: "sub-link",
          icon: <EuroCircleFilled />,
          menuName: (
            <FormattedMessage
              id='sidebar.menuItem.inventoryValuation'
              defaultMessage=""
            />
          ),
          showSubMenu: false,
          moduleCode: MODULE_CODED_VALUES.STOCK_ADJUSTMENT,
          operation: PERMISSION_VALUES.READ,
        },
        // {
        //   to: "/admin/warehouse/monthly-inventory-valuation",
        //   linkClassName: "sub-link",
        //   menuName: (
        //     <FormattedMessage
        //       id='sidebar.menuItem.inventoryValuationMonthly'
        //       defaultMessage=""
        //     />
        //   ),
        //   showSubMenu: false,
        //   moduleCode: MODULE_CODED_VALUES.STOCK_ADJUSTMENT,
        //   operation: PERMISSION_VALUES.READ,
        // },
        // {
        //   to: "/admin/warehouse/daily-inventory-valuation",
        //   linkClassName: "sub-link",
        //   menuName: (
        //     <FormattedMessage
        //       id='sidebar.menuItem.inventoryValuationDaily'
        //       defaultMessage=""
        //     />
        //   ),
        //   showSubMenu: false,
        //   moduleCode: MODULE_CODED_VALUES.STOCK_ADJUSTMENT,
        //   operation: PERMISSION_VALUES.READ,
        // },
        {
          to: "/admin/warehouse/aged-stock",
          linkClassName: "sub-link",
          icon: <CloudFilled />,
          menuName: (
            <FormattedMessage
              id="sidebar.menuItem.agedStock"
              defaultMessage=""
            />
          ),
          showSubMenu: false,
          moduleCode: MODULE_CODED_VALUES.AGED_STOCK,
          operation: PERMISSION_VALUES.READ,
        },
        {
          to: "/admin/product-price",
          linkClassName: "sub-link",
          icon: <DollarCircleFilled />,
          menuName: (
            <FormattedMessage
              id="sidebar.menuItem.productPrice"
              defaultMessage=""
            />
          ),
          showSubMenu: false,
          moduleCode: MODULE_CODED_VALUES.PRODUCT_PRICE,
          operation: PERMISSION_VALUES.READ,
        },
        {
          to: "/admin/price-list",
          linkClassName: "sub-link",
          onClick: (e) => {
            newPriceListAddition();
          },
          secondaryClassName: "add-icon",
          iconClassName: "fa fa-plus",
          icon: <FileTextFilled />,
          menuName: (
            <FormattedMessage
              id="sidebar.menuItem.priceList"
              defaultMessage=""
            />
          ),
          showSubMenu: false,
          hasAdditionIcon: true,
          moduleCode: MODULE_CODED_VALUES.PRICE_LIST,
          operation: PERMISSION_VALUES.READ,
        },
        {
          linkClassName: "sub-link",
          onClick: toggleDropDownMenu,
          rightClassName: "fa fa-angle-right",
          // icon: <FileAddFilled />,
          menuName: (
            <FormattedMessage id="sidebar.menuItem.extras" defaultMessage="" />
          ),
          ulClassName: "third-level-nav",
          thirdLevelMenuClass: "sub-link",
          showSubMenu: true,
          children: [
            {
              to: "/admin/extras/product-category",
              linkClassName: "third-nav-link",
              onClick: (e) => (e) => {
                handleNewItemAddition(props, MODAL_TYPE.PRODUCT_CATEGORY);
              },
              secondaryClassName: "add-icon",
              iconClassName: "fa fa-plus",
              menuName: (
                <FormattedMessage
                  id="sidebar.menuItem.category"
                  defaultMessage=""
                />
              ),
              showSubMenu: false,
              hasAdditionIcon: true,
              moduleCode: MODULE_CODED_VALUES.PRODUCT_CATEGORY,
              operation: PERMISSION_VALUES.READ,
            },
            {
              to: "/admin/extras/product-manufacturer",
              linkClassName: "third-nav-link",
              onClick: (e) => (e) => {
                handleNewItemAddition(props, MODAL_TYPE.PRODUCT_MANUFACTURER);
              },
              secondaryClassName: "add-icon",
              iconClassName: "fa fa-plus",
              menuName: (
                <FormattedMessage
                  id="sidebar.menuItem.manufactures"
                  defaultMessage=""
                />
              ),
              showSubMenu: false,
              hasAdditionIcon: true,
              moduleCode: MODULE_CODED_VALUES.PRODUCT_MANUFACTURER,
              operation: PERMISSION_VALUES.READ,
            },
            {
              to: "/admin/extras/product-brands",
              linkClassName: "third-nav-link",
              onClick: (e) => (e) => {
                handleNewItemAddition(props, MODAL_TYPE.PRODUCT_BRAND);
              },
              secondaryClassName: "add-icon",
              iconClassName: "fa fa-plus",
              menuName: (
                <FormattedMessage
                  id="sidebar.menuItem.brands"
                  defaultMessage=""
                />
              ),
              showSubMenu: false,
              hasAdditionIcon: true,
              moduleCode: MODULE_CODED_VALUES.PRODUCT_BRAND,
              operation: PERMISSION_VALUES.READ,
            },
            {
              to: "/admin/extras/UOM",
              linkClassName: "third-nav-link",
              onClick: (e) => (e) => {
                handleNewItemAddition(props, MODAL_TYPE.UOM);
              },
              secondaryClassName: "add-icon",
              iconClassName: "fa fa-plus",
              menuName: (
                <FormattedMessage id="sidebar.menuItem.uom" defaultMessage="" />
              ),
              showSubMenu: false,
              hasAdditionIcon: true,
              moduleCode: MODULE_CODED_VALUES.UOM,
              operation: PERMISSION_VALUES.READ,
            },
            {
              to: "/admin/extras/opening-quantity",
              linkClassName: "third-nav-link",
              onClick: (e) => (e) => {
                handleNewItemAddition(props, MODAL_TYPE.UOM);
              },
              secondaryClassName: "add-icon",
              iconClassName: "fa fa-plus",
              menuName: (
                <FormattedMessage id="sidebar.menuItem.quantity" defaultMessage="" />
              ),
              showSubMenu: false,
              hasAdditionIcon: true,
              moduleCode: MODULE_CODED_VALUES.UOM,
              operation: PERMISSION_VALUES.READ,
            },
          ],
        },
      ],
    },
    {
      linkClassName: "link",
      onClick: toggleDropDownMenu,
      primaryClassName: "title",
      secondaryClassName: "title secondary",
      iconClassName: "fa fa-money",
      icon: <DollarCircleFilled />,
      rightClassName: "fa fa-angle-right",
      menuName: (
        <FormattedMessage id="sidebar.menuItem.finance" defaultMessage="" />
      ),
      showSubMenu: false,
      ulClassName: "sub-sidebar-nav",
      collapseSubMenuTitle: "Finance",
      children: [
        {
          to: "/admin/business-expense-listing",
          linkClassName: "sub-link",
          onClick: (e, menu) => {
            props.history.push("/admin/business-expense-create");
          },
          secondaryClassName: "add-icon",
          iconClassName: "fa fa-plus",
          icon: <FolderAddFilled />,
          menuName: (
            <FormattedMessage
              id="sidebar.menuItem.businessExpense"
              defaultMessage=""
            />
          ),
          showSubMenu: false,
          hasAdditionIcon: true,
          moduleCode: MODULE_CODED_VALUES.BUSINESS_EXPENSE,
          operation: PERMISSION_VALUES.READ,
        },
        {
          to: "/admin/banking",
          linkClassName: "sub-link",
          icon: <HddFilled />,
          menuName: (
            <FormattedMessage id="sidebar.menuItem.banking" defaultMessage="" />
          ),
          showSubMenu: false,
          moduleCode: MODULE_CODED_VALUES.BANKING,
          operation: PERMISSION_VALUES.READ,
        },
        {
          to: "/admin/ledger-accounts",
          linkClassName: "sub-link",
          onClick: (e) => {
            newLadgerAccounts(props);
          },
          secondaryClassName: "add-icon",
          iconClassName: "fa fa-plus",
          icon: <SafetyCertificateFilled />,
          menuName: (
            <FormattedMessage
              id="sidebar.menuItem.leadgerAccounts"
              defaultMessage=""
            />
          ),
          showSubMenu: false,
          hasAdditionIcon: true,
          moduleCode: MODULE_CODED_VALUES.LEDGER_ACCOUNTS,
          operation: PERMISSION_VALUES.READ,
        },
        {
          to: "/admin/balance-sheet",
          linkClassName: "sub-link",
          icon: <SnippetsFilled />,
          menuName: (
            <FormattedMessage
              id="sidebar.menuItem.balanceSheet"
              defaultMessage=""
            />
          ),
          showSubMenu: false,
          moduleCode: MODULE_CODED_VALUES.BALANCE_SHEET,
          operation: PERMISSION_VALUES.READ,
        },
        {
          to: "/admin/profit-loss",
          linkClassName: "sub-link",
          icon: <FileAddFilled />,
          menuName: (
            <FormattedMessage
              id="sidebar.menuItem.profitLoss"
              defaultMessage=""
            />
          ),
          showSubMenu: false,
          moduleCode: MODULE_CODED_VALUES.PROFIT_LOSS,
          operation: PERMISSION_VALUES.READ,
        },
        {
          to: "/admin/trial-balance",
          linkClassName: "sub-link",
          icon: <WalletFilled />,
          menuName: (
            <FormattedMessage
              id="sidebar.menuItem.trialBalance"
              defaultMessage=""
            />
          ),
          showSubMenu: false,
          moduleCode: MODULE_CODED_VALUES.TRIAL_BALANCE,
          operation: PERMISSION_VALUES.READ,
        },
        {
          to: "/admin/opening-balance",
          linkClassName: "sub-link",
          icon: <ContainerFilled />,
          menuName: (
            <FormattedMessage
              id="sidebar.menuItem.openingBalance"
              defaultMessage=""
            />
          ),
          showSubMenu: false,
          moduleCode: MODULE_CODED_VALUES.OPENING_BALANCE,
          operation: PERMISSION_VALUES.READ,
        },
        {
          to: "/admin/show-journals",
          linkClassName: "sub-link",
          onClick: (e, menu) => {
            props.history.push("/admin/create-journal");
          },
          secondaryClassName: "add-icon",
          iconClassName: "fa fa-plus",
          icon: <CreditCardFilled />,
          menuName: (
            <FormattedMessage
              id="sidebar.menuItem.journals"
              defaultMessage=""
            />
          ),
          showSubMenu: false,
          hasAdditionIcon: true,
          moduleCode: MODULE_CODED_VALUES.JOURNALS,
          operation: PERMISSION_VALUES.READ,
        },
        {
          to: "/admin/bankReconciliation",
          linkClassName: "sub-link",
          onClick: (e, menu) => { },
          secondaryClassName: "add-icon",
          iconClassName: "fa fa-plus",
          icon: <FileMarkdownFilled />,
          menuName: (
            <FormattedMessage
              id="sidebar.menuItem.bankReconciliation"
              defaultMessage=""
            />
          ),
          showSubMenu: false,
          hasAdditionIcon: true,
          moduleCode: MODULE_CODED_VALUES.BANK_RECONCILIATION,
          operation: PERMISSION_VALUES.READ,
        },
        {
          to: "/admin/show-taxes",
          linkClassName: "sub-link",
          onClick: (e) => {
            handleNewTaxAddition(props);
          },
          secondaryClassName: "add-icon",
          iconClassName: "fa fa-plus",
          icon: <DollarCircleFilled />,
          menuName: (
            <FormattedMessage id="sidebar.menuItem.taxes" defaultMessage="" />
          ),
          showSubMenu: false,
          hasAdditionIcon: true,
          moduleCode: MODULE_CODED_VALUES.TAXES,
          operation: PERMISSION_VALUES.READ,
        },
        {
          to: "/admin/show-HSN",
          linkClassName: "sub-link",
          onClick: (e) => {
            handleNewTaxAddition(props);
          },
          secondaryClassName: "add-icon",
          iconClassName: "fa fa-plus",
          icon: <FilterFilled />,
          menuName: (
            <FormattedMessage id="modal.txDetails.common.hsn" defaultMessage="" />
          ),
          showSubMenu: false,
          hasAdditionIcon: true,
          moduleCode: MODULE_CODED_VALUES.TAXES,
          operation: PERMISSION_VALUES.READ,
        },
        {
            to: "/admin/show-fy-years",
            linkClassName: "sub-link",
            icon: <FundFilled />,
            menuName: <FormattedMessage id='sidebar.menuItem.financialYears' defaultMessage=""/>,
            showSubMenu: false,
            moduleCode: MODULE_CODED_VALUES.FINANCIAL_YEARS,
            operation: PERMISSION_VALUES.READ
        },
        // {
        //     to: "/admin/show-cost-centers",
        //     linkClassName: "sub-link",
        //     menuName: <FormattedMessage id='sidebar.menuItem.costCenter' defaultMessage=""/>,
        //     showSubMenu: false,
        //     moduleCode: MODULE_CODED_VALUES.COST_CENTERS,
        //     operation: PERMISSION_VALUES.READ
        // }
      ],
    },
    {
      linkClassName: "link",
      onClick: toggleDropDownMenu,
      primaryClassName: "title",
      secondaryClassName: "title secondary",
      iconClassName: "fa fa-building-o",
      icon: <HddFilled />,
      rightClassName: "fa fa-angle-right",
      menuName: (
        <FormattedMessage id="sidebar.menuItem.company" defaultMessage="" />
      ),
      showSubMenu: false,
      ulClassName: "sub-sidebar-nav",
      collapseSubMenuTitle: "Organization",
      children: [
        {
          to: "/admin/company-hierarchy-list",
          linkClassName: "sub-link",
          onClick: (e) => {
            newCompanyHierarchy();
          },
          secondaryClassName: "add-icon",
          iconClassName: "fa fa-plus",
          menuName: (
            <FormattedMessage
              id="sidebar.menuItem.companyHierarcy"
              defaultMessage=""
            />
          ),
          showSubMenu: false,
          moduleCode: MODULE_CODED_VALUES.COMPANY_HIERARCY,
          operation: PERMISSION_VALUES.READ,
          hasAdditionIcon: true,
        },
        {
          to: "/admin/employee-list",
          linkClassName: "sub-link",
          onClick: (e) => {
            newEmployee();
          },
          secondaryClassName: "add-icon",
          iconClassName: "fa fa-plus",
          menuName: (
            <FormattedMessage
              id="sidebar.menuItem.employee"
              defaultMessage=""
            />
          ),
          showSubMenu: false,
          moduleCode: MODULE_CODED_VALUES.COMPANY_HIERARCY,
          operation: PERMISSION_VALUES.READ,
          hasAdditionIcon: true,
        },
        {
          to: "/admin/projects",
          linkClassName: "sub-link",
          onClick: (e) => {
            handleNewProjectAddition(props, MODAL_TYPE.PROJECT);
          },
          secondaryClassName: "add-icon",
          iconClassName: "fa fa-plus",
          menuName: <FormattedMessage id="common.project" defaultMessage="" />,
          showSubMenu: false,
          moduleCode: MODULE_CODED_VALUES.PROJECT,
          operation: PERMISSION_VALUES.READ,
          hasAdditionIcon: true,
        },
      ],
    },
    {
      to: "/admin/show-reports",
      linkClassName: "link",
      secondaryClassName: "",
      primaryClassName: "title",
      iconClassName: "fa fa-bar-chart-o",
      icon: <ReconciliationFilled />,
      menuName: (
        <FormattedMessage id="sidebar.menuItem.reports" defaultMessage="" />
      ),
      showSubMenu: false,
      moduleCode: MODULE_CODED_VALUES.REPORTS,
      operation: PERMISSION_VALUES.READ,
      children: [],
    },
    {
      linkClassName: "link",
      onClick: toggleDropDownMenu,
      primaryClassName: "title",
      secondaryClassName: "title secondary",
      iconClassName: "fa fa-money",
      icon: <GoldFilled />,
      rightClassName: "fa fa-angle-right",
      menuName: <FormattedMessage id='sidebar.menuItem.salesChannels' defaultMessage="" />,
      showSubMenu: false,
      ulClassName: "sub-sidebar-nav",
      children: [
        {
          to: "/admin/sales-channels-amazon",
          linkClassName: "sub-link",
          icon: <ApiFilled />,
          menuName: <FormattedMessage id='amazon.logo' defaultMessage="" />,
          showSubMenu: false,
          moduleCode: MODULE_CODED_VALUES.SALES_CHANNEL,
          operation: PERMISSION_VALUES.READ
        },
        // {
        //     to: "/admin/sales-channels-shopify",
        //     linkClassName: "sub-link",
        //     menuName: <FormattedMessage id='shopify.logo' defaultMessage=""/>,
        //     showSubMenu: false,
        //     moduleCode: MODULE_CODED_VALUES.ACCOUNTING,
        //     operation: PERMISSION_VALUES.READ
        // },
        {
          to: "/admin/sales-channels-ebay",
          linkClassName: "sub-link",
          icon: <ApiFilled />,
          menuName: <FormattedMessage id='eabay.logo' defaultMessage="" />,
          showSubMenu: false,
          moduleCode: MODULE_CODED_VALUES.SALES_CHANNEL,
          operation: PERMISSION_VALUES.READ
        },
        {
          to: "/admin/sales-channels-walmart",
          linkClassName: "sub-link",
          icon: <ApiFilled />,
          menuName: <FormattedMessage id='walmart.logo' defaultMessage="" />,
          showSubMenu: false,
          moduleCode: MODULE_CODED_VALUES.SALES_CHANNEL,
          operation: PERMISSION_VALUES.READ
        },
        {
          to: "/admin/sales-channels-shopify",
          linkClassName: "sub-link",
          icon: <ApiFilled />,
          menuName: <FormattedMessage id='shopify.logo' defaultMessage="" />,
          showSubMenu: false,
          moduleCode: MODULE_CODED_VALUES.SALES_CHANNEL,
          operation: PERMISSION_VALUES.READ
        },
        {
          to: "/admin/sales-channels-carrefour",
          linkClassName: "sub-link",
          icon: <ApiFilled />,
          menuName: <FormattedMessage id='carrefour.logo' defaultMessage="" />,
          showSubMenu: false,
          moduleCode: MODULE_CODED_VALUES.SALES_CHANNEL,
          operation: PERMISSION_VALUES.READ
        },
        {
          to: "/admin/sales-channels-noon",
          linkClassName: "sub-link",
          icon: <ApiFilled />,
          menuName: <FormattedMessage id='noon.logo' defaultMessage="" />,
          showSubMenu: false,
          moduleCode: MODULE_CODED_VALUES.SALES_CHANNEL,
          operation: PERMISSION_VALUES.READ
        },
      ]
    },
    {
      linkClassName: "link",
      onClick: toggleDropDownMenu,
      primaryClassName: "title",
      secondaryClassName: "title secondary",
      iconClassName: "fa fa-money",
      icon: <BuildFilled />,
      rightClassName: "fa fa-angle-right",
      menuName: <FormattedMessage id='sidebar.menuItem.shipping' defaultMessage="" />,
      showSubMenu: false,
      ulClassName: "sub-sidebar-nav",
      children: [
        {
          to: "/admin/easy-post",
          linkClassName: "sub-link",
          icon: <CarFilled />,
          menuName: <FormattedMessage id='sidebar.menuItem.easyPost' defaultMessage="" />,
          showSubMenu: false,
          moduleCode: MODULE_CODED_VALUES.SHIPPING,
          operation: PERMISSION_VALUES.READ
        },
        {
          to: "/admin/ship-station",
          linkClassName: "sub-link",
          icon: <CarFilled />,
          menuName: <FormattedMessage id='sidebar.menuItem.shipStation' defaultMessage="" />,
          showSubMenu: false,
          moduleCode: MODULE_CODED_VALUES.SHIPPING,
          operation: PERMISSION_VALUES.READ
        }
      ]
    },
    {
      linkClassName: "link",
      onClick: toggleDropDownMenu,
      primaryClassName: "title",
      secondaryClassName: "title secondary",
      iconClassName: "fa fa-money",
      icon: <TagsFilled />,
      rightClassName: "fa fa-angle-right",
      menuName: <FormattedMessage id='sidebar.menuItem.integrations' defaultMessage="" />,
      showSubMenu: false,
      ulClassName: "sub-sidebar-nav",
      children: [
        {
          to: "/admin/sales-channels",
          linkClassName: "sub-link",
          icon: <CompassFilled />,
          menuName: <FormattedMessage id='sidebar.menuItem.salesChannels' defaultMessage="" />,
          showSubMenu: false,
          moduleCode: MODULE_CODED_VALUES.SALES_CHANNEL,
          operation: PERMISSION_VALUES.READ
        },
        {
          to: "/admin/shipping",
          linkClassName: "sub-link",
          icon: <CarFilled />,
          menuName: <FormattedMessage id='sidebar.menuItem.shipping' defaultMessage="" />,
          showSubMenu: false,
          moduleCode: MODULE_CODED_VALUES.SHIPPING,
          operation: PERMISSION_VALUES.READ
        },
        {
          to: "/admin/accounting-integration",
          linkClassName: "sub-link",
          icon: <ContainerFilled />,
          menuName: <FormattedMessage id='sidebar.menuItem.accounting' defaultMessage="" />,
          showSubMenu: false,
          moduleCode: MODULE_CODED_VALUES.ACCOUNTING,
          operation: PERMISSION_VALUES.READ
        }
        ,
        {
          to: "/admin/payment-integration",
          linkClassName: "sub-link",
          icon: <DollarCircleFilled />,
          menuName: <FormattedMessage id='sidebar.menuItem.payment' defaultMessage="" />,
          showSubMenu: false,
          moduleCode: MODULE_CODED_VALUES.ACCOUNTING,
          operation: PERMISSION_VALUES.READ
        }
      ]
    },
    {
      to: "/admin/show-settings",
      linkClassName: "link",
      secondaryClassName: "",
      primaryClassName: "title",
      iconClassName: "fa fa-gears",
      icon: <SettingFilled />,
      menuName: (
        <FormattedMessage id="sidebar.menuItem.settings" defaultMessage="" />
      ),
      showSubMenu: false,
      moduleCode: MODULE_CODED_VALUES.SETTINGS,
      operation: PERMISSION_VALUES.READ,
      children: [],
    },
    {
      to: "/admin/kiosk",
      linkClassName: "link",
      secondaryClassName: "",
      onClick: () => {
        //lockScreen(props);
      },
      primaryClassName: "title",
      iconClassName: "fa fa-desktop",
      icon: <SwitcherFilled />,
      moduleCode: MODULE_CODED_VALUES.KIOSK,
      operation: PERMISSION_VALUES.READ,
      menuName: (
        <FormattedMessage id="sidebar.menuItem.kiosk" defaultMessage="" />
      ),
      showSubMenu: false,
      children: [],
    },
    {
      linkClassName: "link",
      secondaryClassName: "title secondary",
      onClick: () => {
        lockScreen(props);
      },
      primaryClassName: "title",
      iconClassName: "fa fa-lock",
      icon: <TabletFilled />,
      moduleCode: MODULE_CODED_VALUES.LOCK_SCREEN,
      operation: PERMISSION_VALUES.READ,
      menuName: (
        <FormattedMessage id="sidebar.menuItem.lockScreen" defaultMessage="" />
      ),
      showSubMenu: false,
      children: [],
    },

    {
      linkClassName: "link",
      onClick: toggleDropDownMenu,
      primaryClassName: "title",
      secondaryClassName: "title secondary",
      iconClassName: "fa store-icon",
      icon: <LayoutFilled />,
      rightClassName: "fa fa-angle-right",
      menuName: <FormattedMessage id="ecommerce.text" defaultMessage="" />,
      showSubMenu: false,
      ulClassName: "sub-sidebar-nav",
      collapseSubMenuTitle: "Ecommerce",
      children: [
        {
          to: "/admin/published-stock",
          linkClassName: "sub-link",
          // onClick: (e) => {
          //     newSupplier()
          // },
          // secondaryClassName: "add-icon",
          // iconClassName: "fa fa-plus",
          icon: <BuildFilled />,
          menuName: (
            <FormattedMessage id="publishedStock.text" defaultMessage="" />
          ),
          showSubMenu: false,
          //hasAdditionIcon: true,
          moduleCode: MODULE_CODED_VALUES.ONLINE_STORES,
          operation: PERMISSION_VALUES.READ,
        },
        {
          to: "/admin/published-category",
          linkClassName: "sub-link",
          // onClick: (e) => {
          //     newSupplier()
          // },
          // secondaryClassName: "add-icon",
          // iconClassName: "fa fa-plus",
          icon: <CarryOutFilled />,
          menuName: (
            <FormattedMessage id="publishedCategory.text" defaultMessage="" />
          ),
          showSubMenu: false,
          //hasAdditionIcon: true,
          moduleCode: MODULE_CODED_VALUES.ONLINE_STORES,
          operation: PERMISSION_VALUES.READ,
        },
        {
          to: "/admin/e-store/blogs",
          linkClassName: "sub-link",
          // onClick: (e) => {
          //     newSupplier()
          // },
          // secondaryClassName: "add-icon",
          // iconClassName: "fa fa-plus",
          icon: <CarryOutFilled />,
          menuName: (
            <FormattedMessage id="Blogs" defaultMessage="" />
          ),
          showSubMenu: false,
          //hasAdditionIcon: true,
          moduleCode: MODULE_CODED_VALUES.ONLINE_STORES,
          operation: PERMISSION_VALUES.READ,
        },
        {
          to: "/admin/e-store/category",
          linkClassName: "sub-link",
          // onClick: (e) => {
          //     newSupplier()
          // },
          // secondaryClassName: "add-icon",
          // iconClassName: "fa fa-plus",
          icon: <CarryOutFilled />,
          menuName: (
            <FormattedMessage id="Catgeory" defaultMessage="" />
          ),
          showSubMenu: false,
          //hasAdditionIcon: true,
          moduleCode: MODULE_CODED_VALUES.ONLINE_STORES,
          operation: PERMISSION_VALUES.READ,
        },
        {
          to: "/admin/product-online-store-price",
          linkClassName: "sub-link",
          // onClick: (e) => {
          //     newSupplier()
          // },
          // secondaryClassName: "add-icon",
          // iconClassName: "fa fa-plus",
          icon: <DollarCircleFilled />,
          menuName: (
            <FormattedMessage id="sidebar.menuItem.productPrice" defaultMessage="" />
          ),
          showSubMenu: false,
          //hasAdditionIcon: true,
          moduleCode: MODULE_CODED_VALUES.ONLINE_STORES,
          operation: PERMISSION_VALUES.READ,
        }
      ],
    },
  ];

  const sideMenuUnFilteredAlif = [
    {
      linkClassName: "link",
      onClick: toggleDropDownMenu,
      primaryClassName: "title",
      secondaryClassName: "title secondary",
      iconClassName: "fa fa-user",
      rightClassName: "fa fa-angle-right",
      menuName: (
        <FormattedMessage id="sidebar.menuItem.membership" defaultMessage="" />
      ),
      showSubMenu: false,
      ulClassName: "sub-sidebar-nav",
      collapseSubMenuTitle: "Membership",
      children: [
        {
          to: "/admin/org-membership",
          linkClassName: "sub-link",
          onClick: () => {
            // newTermAndFee();
          },
          secondaryClassName: "add-icon",
          iconClassName: "fa fa-plus",
          menuName: (
            <FormattedMessage
              id="sidebar.menuItem.member"
              defaultMessage=""
            />
          ),
          showSubMenu: false,
          hasAdditionIcon: false,
          moduleCode: MODULE_CODED_VALUES.MEMBERSHIP,
          operation: PERMISSION_VALUES.READ,
        },
        {
          to: "/admin/term-and-fee",
          linkClassName: "sub-link",
          onClick: () => {
            newTermAndFee();
          },
          secondaryClassName: "add-icon",
          iconClassName: "fa fa-plus",
          menuName: (
            <FormattedMessage
              id="sidebar.menuItem.termAndFee"
              defaultMessage=""
            />
          ),
          showSubMenu: false,
          hasAdditionIcon: true,
          moduleCode: MODULE_CODED_VALUES.MEMBERSHIP,
          operation: PERMISSION_VALUES.READ,
        },
        {
          to: "/admin/membership-option",
          linkClassName: "sub-link",
          onClick: () => {
            newMembershipOption();
          },
          secondaryClassName: "add-icon",
          iconClassName: "fa fa-plus",
          menuName: (
            <FormattedMessage
              id="sidebar.menuItem.membershipOption"
              defaultMessage=""
            />
          ),
          showSubMenu: false,
          hasAdditionIcon: true,
          moduleCode: MODULE_CODED_VALUES.MEMBERSHIP,
          operation: PERMISSION_VALUES.READ,
        },
        {
          to: "/admin/membership-form-list",
          linkClassName: "sub-link",
          onClick: () => {
            newMembershipForm('membership');
          },
          secondaryClassName: "add-icon",
          iconClassName: "fa fa-plus",
          menuName: (
            <FormattedMessage
              id="sidebar.menuItem.membershipForm"
              defaultMessage=""
            />
          ),
          showSubMenu: false,
          hasAdditionIcon: true,
          moduleCode: MODULE_CODED_VALUES.MEMBERSHIP,
          operation: PERMISSION_VALUES.READ,
        },
        // {
        //   to: "/admin/org-membership",
        //   linkClassName: "sub-link",
        //   onClick: () => {
        //     newMembershipForm('membership');
        //   },
        //   secondaryClassName: "add-icon",
        //   iconClassName: "fa fa-plus",
        //   menuName: (
        //     <FormattedMessage
        //       id="sidebar.menuItem.orgMembership"
        //       defaultMessage=""
        //     />
        //   ),
        //   showSubMenu: false,
        //   hasAdditionIcon: true,
        //   moduleCode: MODULE_CODED_VALUES.MEMBERSHIP,
        //   operation: PERMISSION_VALUES.READ,
        // },
      ],
    },

    {
      linkClassName: "link",
      onClick: toggleDropDownMenu,
      primaryClassName: "title",
      secondaryClassName: "title secondary",
      iconClassName: "fa fa-credit-card-alt",
      rightClassName: "fa fa-angle-right",
      menuName: (
        <FormattedMessage id="sidebar.menuItem.fundraising" defaultMessage="" />
      ),
      showSubMenu: false,
      ulClassName: "sub-sidebar-nav",
      collapseSubMenuTitle: "Fundraising",
      children: [
        {
          to: "/admin/fundraising-campaign",
          linkClassName: "sub-link",
          onClick: () => {
            openCreateCampaignDrawer();
          },
          secondaryClassName: "add-icon",
          iconClassName: "fa fa-plus",
          menuName: (
            <FormattedMessage
              id="fundraising.campaign.listing.header.campaign"
              defaultMessage=""
            />
          ),
          showSubMenu: false,
          hasAdditionIcon: true,
          moduleCode: MODULE_CODED_VALUES.FUNDRAISING,
          operation: PERMISSION_VALUES.READ,
        },
        // {
        //   to: "/admin/donations",
        //   linkClassName: "sub-link",
        //   onClick: () => { },
        //   secondaryClassName: "add-icon",
        //   iconClassName: "fa fa-plus",
        //   menuName: (
        //     <FormattedMessage
        //       id="sidebar.menuItem.donations"
        //       defaultMessage=""
        //     />
        //   ),
        //   showSubMenu: false,
        //   hasAdditionIcon: false,
        //   moduleCode: MODULE_CODED_VALUES.FUNDRAISING,
        //   operation: PERMISSION_VALUES.READ,
        // },
        {
          to: "/admin/fundraising-pledges",
          linkClassName: "sub-link",
          onClick: () => {
            openCreatePledgeDrawer()
          },
          secondaryClassName: "add-icon",
          iconClassName: "fa fa-plus",
          menuName: (
            <FormattedMessage
              id="sidebar.menuItem.pledges"
              defaultMessage=""
            />
          ),
          showSubMenu: false,
          hasAdditionIcon: false,
          moduleCode: MODULE_CODED_VALUES.FUNDRAISING,
          operation: PERMISSION_VALUES.READ,
        },
        // {
        //   to: "/admin/recurring-donation-schedules",
        //   linkClassName: "sub-link",
        //   onClick: () => { },
        //   secondaryClassName: "add-icon",
        //   iconClassName: "fa fa-plus",
        //   menuName: (
        //     <FormattedMessage
        //       id="sidebar.menuItem.recurringDonationSchedules"
        //       defaultMessage=""
        //     />
        //   ),
        //   showSubMenu: false,
        //   hasAdditionIcon: false,
        //   moduleCode: MODULE_CODED_VALUES.FUNDRAISING,
        //   operation: PERMISSION_VALUES.READ,
        // },
        // {
        //   to: "/admin/peer-to-peer",
        //   linkClassName: "sub-link",
        //   onClick: () => { },
        //   secondaryClassName: "add-icon",
        //   iconClassName: "fa fa-plus",
        //   menuName: (
        //     <FormattedMessage
        //       id="sidebar.menuItem.peerToPeer"
        //       defaultMessage=""
        //     />
        //   ),
        //   showSubMenu: false,
        //   hasAdditionIcon: false,
        //   moduleCode: MODULE_CODED_VALUES.FUNDRAISING,
        //   operation: PERMISSION_VALUES.READ,
        // },
        {
          to: "/admin/fundraising-donation",
          linkClassName: "sub-link",
          secondaryClassName: "add-icon",
          iconClassName: "",
          menuName: (
            <FormattedMessage
              id="sidebar.menuItem.donations"
              defaultMessage=""
            />
          ),
          showSubMenu: false,
          hasAdditionIcon: true,
          moduleCode: MODULE_CODED_VALUES.FUNDRAISING,
          operation: PERMISSION_VALUES.READ,
        },
        {
          to: "/admin/sponsorship",
          linkClassName: "sub-link",
          onClick: () => {
            props.history.push({
              pathname: "/admin/sponsorship-create",
              state: {
                type: "new",
              },
            });
          },
          secondaryClassName: "add-icon",
          iconClassName: "fa fa-plus",
          menuName: (
            <FormattedMessage
              id="sidebar.menuItem.sponsorship"
              defaultMessage=""
            />
          ),
          showSubMenu: false,
          hasAdditionIcon: true,
          moduleCode: MODULE_CODED_VALUES.FUNDRAISING,
          operation: PERMISSION_VALUES.READ,
        },
      ],
    },



    {
      linkClassName: "link",
      onClick: toggleDropDownMenu,
      primaryClassName: "title",
      secondaryClassName: "title secondary",
      iconClassName: "fa fa-commenting-o",
      rightClassName: "fa fa-angle-right",
      menuName: (
        <FormattedMessage id="sidebar.menuItem.communication" defaultMessage="" />
      ),
      showSubMenu: false,
      ulClassName: "sub-sidebar-nav",
      collapseSubMenuTitle: "Communication",
      children: [
        {
          to: "/admin/account",
          linkClassName: "sub-link",
          onClick: () => { },
          secondaryClassName: "add-icon",
          iconClassName: "fa fa-plus",
          menuName: (
            <FormattedMessage
              id="sidebar.menuItem.account"
              defaultMessage=""
            />
          ),
          showSubMenu: false,
          hasAdditionIcon: true,
          moduleCode: MODULE_CODED_VALUES.COMMUNICATION,
          operation: PERMISSION_VALUES.READ,
        },
        {
          to: "/admin/communication/dg",
          linkClassName: "sub-link",
          onClick: () => { },
          secondaryClassName: "add-icon",
          iconClassName: "fa fa-plus",
          menuName: (
            <FormattedMessage
              id="sidebar.menuItem.distributionGroup"
              defaultMessage=""
            />
          ),
          showSubMenu: false,
          hasAdditionIcon: true,
          moduleCode: MODULE_CODED_VALUES.SETTINGS,
          operation: PERMISSION_VALUES.READ,
        },
        {
          to: "/admin/news-letter-listing",
          linkClassName: "sub-link",
          secondaryClassName: "title secondary",
          onClick: () => {
            //lockScreen(props);
          },
          primaryClassName: "title",
          iconClassName: "fa fa-newspaper-o",
          moduleCode: MODULE_CODED_VALUES.SETTINGS,
          operation: PERMISSION_VALUES.READ,
          menuName: (
            <FormattedMessage id="sidebar.menuItem.newsletter" defaultMessage="" />
          ),
          showSubMenu: false,
          children: [],
        },

        // {
        //   to: "/admin/events",
        //   linkClassName: "sub-link",
        //   secondaryClassName: "title secondary",
        //   onClick: () => {
        //     //lockScreen(props);
        //   },
        //   primaryClassName: "title",
        //   iconClassName: "fa fa-calendar",
        //   moduleCode: MODULE_CODED_VALUES.EVENTS,
        //   operation: PERMISSION_VALUES.READ,
        //   menuName: (
        //     <FormattedMessage id="sidebar.menuItem.events" defaultMessage="" />
        //   ),
        //   showSubMenu: false,
        //   children: [],
        // },
        {
          to: "/admin/communication/contact",
          linkClassName: "sub-link",
          secondaryClassName: "title secondary",
          onClick: () => {

          },
          primaryClassName: "title",
          iconClassName: "fa fa-calendar",
          moduleCode: MODULE_CODED_VALUES.COMMUNICATION,
          operation: PERMISSION_VALUES.READ,
          menuName: (
            <FormattedMessage id="contact" defaultMessage="" />
          ),
          showSubMenu: false,
          children: [],
        },
      ],
    },
    {
      to: "/admin/events",
      linkClassName: "link",
      secondaryClassName: "",
      primaryClassName: "title",
      iconClassName: "fa fa-calendar",
      moduleCode: MODULE_CODED_VALUES.EVENTS,
      operation: PERMISSION_VALUES.READ,
      menuName: (
        <FormattedMessage id="sidebar.menuItem.events" defaultMessage="" />
      ),
      showSubMenu: false,
      children: [],
    },
    {
      linkClassName: "link",
      onClick: toggleDropDownMenu,
      primaryClassName: "title",
      secondaryClassName: "title secondary",
      iconClassName: "fa fa-graduation-cap",
      rightClassName: "fa fa-angle-right",
      menuName: (
        <FormattedMessage id="sidebar.menuItem.school" defaultMessage="" />
      ),
      showSubMenu: false,
      ulClassName: "sub-sidebar-nav",
      collapseSubMenuTitle: "School",
      children: [
        
        {
          to: "/admin/school-management/dashboard",
          linkClassName: "sub-link",
          onClick: () => {
            // newTermAndFee();
          },
          secondaryClassName: "add-icon",
          iconClassName: "fa fa-plus",
          menuName: (
            <FormattedMessage
              id="sidebar.menuItem.dashboard"
              defaultMessage=""
            />
          ),
          moduleCode: MODULE_CODED_VALUES.SCHOOL_DASHBOARD,
          operation: PERMISSION_VALUES.READ,
        },
        {
          to: "/admin/school-management/department",
          linkClassName: "sub-link",
          onClick: () => {
            // newTermAndFee();
          },
          secondaryClassName: "add-icon",
          iconClassName: "fa fa-plus",
          menuName: (
            <FormattedMessage
              id="addItem.text.department"
              defaultMessage=""
            />
          ),
          moduleCode: MODULE_CODED_VALUES.SCHOOL_DEPARTMENT,
          operation: PERMISSION_VALUES.READ,
        },
        {
          // to: "/admin/school-management/class",
          onClick: toggleDropDownMenu,
          ulClassName: "third-level-nav",
          linkClassName: "sub-link",
          thirdLevelMenuClass: "sub-link",
          rightClassName: "fa fa-angle-right",
          //iconClassName: "fa fa-plus",
          menuName: (
            <FormattedMessage
              id="sidebar.menuItem.courseDetails"
              defaultMessage=""
            />
          ),
          moduleCode: MODULE_CODED_VALUES.SCHOOL_COURSE_DETAIL,
          operation: PERMISSION_VALUES.READ,
          showSubMenu: false,
          hasAdditionIcon: false,
          children: [
        {
          to: "/admin/school-management/course",
          linkClassName: "third-nav-link",
          onClick: () => {
            // newTermAndFee();
          },
          secondaryClassName: "add-icon",
          iconClassName: "fa fa-plus",
          menuName: (
            <FormattedMessage
              id="sidebar.menuItem.course"
              defaultMessage=""
            />
          ),
          moduleCode: MODULE_CODED_VALUES.SCHOOL_COURSE_DETAIL,
          operation: PERMISSION_VALUES.READ,
        },
        {
          to: "/admin/school-management/subject",
          linkClassName: "third-nav-link",
          onClick: () => {
            // newTermAndFee();
          },
          secondaryClassName: "add-icon",
          iconClassName: "fa fa-plus",
          menuName: (
            <FormattedMessage
              id="sidebar.menuItem.subject"
              defaultMessage=""
            />
          ),
          moduleCode: MODULE_CODED_VALUES.SCHOOL_COURSE_DETAIL,
          operation: PERMISSION_VALUES.READ,
        },
        {
          to: "/admin/school-management/course-term",
          linkClassName: "third-nav-link",
          onClick: () => {
            // newTermAndFee();
          },
          secondaryClassName: "add-icon",
          iconClassName: "fa fa-plus",
          menuName: (
            <FormattedMessage
              id="sidebar.menuItem.courseTerm"
              defaultMessage=""
            />
          ),
          moduleCode: MODULE_CODED_VALUES.SCHOOL_COURSE_DETAIL,
          operation: PERMISSION_VALUES.READ,
        }]
      },
        {
          // to: "/admin/school-management/class",
          onClick: toggleDropDownMenu,
          ulClassName: "third-level-nav",
          linkClassName: "sub-link",
          thirdLevelMenuClass: "sub-link",
          rightClassName: "fa fa-angle-right",
          //iconClassName: "fa fa-plus",
          menuName: (
            <FormattedMessage
              id="sidebar.menuItem.classDetails"
              defaultMessage=""
            />
          ),
          moduleCode: MODULE_CODED_VALUES.SCHOOL_COURSE_DETAIL,
          operation: PERMISSION_VALUES.READ,
          showSubMenu: false,
          hasAdditionIcon: false,
          children: [
            {
              to: "/admin/school-management/class",
              linkClassName: "third-nav-link",
              onClick: () => {
                // newTermAndFee();
              },
              secondaryClassName: "add-icon",
              iconClassName: "fa fa-plus",
              menuName: (
                <FormattedMessage
                  id="sidebar.menuItem.class"
                  defaultMessage=""
                />
              ),
              moduleCode: MODULE_CODED_VALUES.SCHOOL_CLASS_DETAIL,
              operation: PERMISSION_VALUES.READ,
            },
            {
              to: "/admin/school-management/section",
              linkClassName: "third-nav-link",
              onClick: () => {
                // newTermAndFee();
              },
              secondaryClassName: "add-icon",
              iconClassName: "fa fa-plus",
              menuName: (
                <FormattedMessage
                  id="sidebar.menuItem.section"
                  defaultMessage=""
                />
              ),
              moduleCode: MODULE_CODED_VALUES.SCHOOL_CLASS_DETAIL,
              operation: PERMISSION_VALUES.READ,
            },
            {
              to: "/admin/school-management/assignment",
              linkClassName: "third-nav-link",
              onClick: () => {
              //  newAssignment();
              },
              secondaryClassName: "add-icon",
              iconClassName: "fa fa-plus",
              menuName: (
                <FormattedMessage
                  id="assignment.text"
                  defaultMessage=""
                />
              ),
              showSubMenu: false,
              //hasAdditionIcon: true,
              moduleCode: MODULE_CODED_VALUES.SCHOOL_CLASS_DETAIL,
              operation: PERMISSION_VALUES.READ,
            }
          ]
        },
        {
          to: "/admin/school-management/attendance",
          linkClassName: "sub-link",
          onClick: () => {
            // newTermAndFee();
          },
          secondaryClassName: "add-icon",
          iconClassName: "fa fa-plus",
          menuName: (
            <FormattedMessage
              id="sidebar.menuItem.attendance"
              defaultMessage=""
            />
          ),
          moduleCode: MODULE_CODED_VALUES.SCHOOL_ATTENDANCE,
          operation: PERMISSION_VALUES.READ,
        },
        {
          to: "/admin/school-management/communication",
          linkClassName: "sub-link",
          onClick: () => {
            // newTermAndFee();
          },
          secondaryClassName: "add-icon",
          iconClassName: "fa fa-plus",
          menuName: (
            <FormattedMessage
              id="communication.text"
              defaultMessage=""
            />
          ),
          moduleCode: MODULE_CODED_VALUES.SCHOOL_COMMUNICATION,
          operation: PERMISSION_VALUES.READ,
        },
        {
          to: "/admin/school-management/broadcast-message",
          linkClassName: "sub-link",
          onClick: () => {
            // newTermAndFee();
          },
          secondaryClassName: "add-icon",
          iconClassName: "fa fa-plus",
          menuName: (
            <FormattedMessage
              id="broadcastMessage.text"
              defaultMessage=""
            />
          ),
          moduleCode: MODULE_CODED_VALUES.SCHOOL_BROADCAST_MESSAGE,
          operation: PERMISSION_VALUES.READ,
        },
        {
          onClick: toggleDropDownMenu,
          ulClassName: "third-level-nav",
          linkClassName: "sub-link",
          thirdLevelMenuClass: "sub-link",
          rightClassName: "fa fa-angle-right",
          //iconClassName: "fa fa-plus",
          menuName: (
            <FormattedMessage
              id="sidebar.menuItem.parent"
              defaultMessage=""
            />
          ),
          showSubMenu: false,
          hasAdditionIcon: false,
          children: [
            {
              to: "/admin/school-management/customers",
              state: { module: 'school' },
              linkClassName: "third-nav-link",
              onClick: (e, menu) => {
                //props.history.push("/admin/student-invoice");
              },
              secondaryClassName: "add-icon",
              iconClassName: "fa fa-plus",
              menuName: (
                <FormattedMessage
                  id="sidebar.menuItem.parent"
                  defaultMessage=""
                />
              ),
              showSubMenu: false,
              hasAdditionIcon: false,
              moduleCode: MODULE_CODED_VALUES.SCHOOL_CUSTOMERS,
              operation: PERMISSION_VALUES.READ,
            },
            {
              to: "/admin/school-management/parent",
              state: { module: 'school' },
              linkClassName: "third-nav-link",
              onClick: (e, menu) => {
                //props.history.push("/admin/student-invoice");
              },
              secondaryClassName: "add-icon",
              iconClassName: "fa fa-plus",
              menuName: (
                <FormattedMessage
                  id="registration.text"
                  defaultMessage=""
                />
              ),
              showSubMenu: false,
              hasAdditionIcon: false,
              moduleCode: MODULE_CODED_VALUES.SCHOOL_PARENTS,
              operation: PERMISSION_VALUES.READ,
            },
            {
              to: "/admin/school-management/student",
              linkClassName: "third-nav-link",
              onClick: () => {
                // newTermAndFee();
              },
              secondaryClassName: "add-icon",
              iconClassName: "fa fa-plus",
              menuName: (
                <FormattedMessage
                  id="sidebar.menuItem.student"
                  defaultMessage=""
                />
              ),
              moduleCode: MODULE_CODED_VALUES.SCHOOL_STUDENT,
              operation: PERMISSION_VALUES.READ,
            },
          
          ]
        },
// {
//           to: "/admin/school-management/parent",
//           linkClassName: "sub-link",
//           onClick: () => {
//             // newTermAndFee();
//           },
//           secondaryClassName: "add-icon",
//           iconClassName: "fa fa-plus",
//           menuName: (
//             <FormattedMessage
//               id="sidebar.menuItem.parent"
//               defaultMessage=""
//             />
//           ),
//           moduleCode: MODULE_CODED_VALUES.SCHOOL_PARENTS,
//           operation: PERMISSION_VALUES.READ,
//         },
//         {
//           to: "/admin/school-management/student",
//           linkClassName: "sub-link",
//           onClick: () => {
//             // newTermAndFee();
//           },
//           secondaryClassName: "add-icon",
//           iconClassName: "fa fa-plus",
//           menuName: (
//             <FormattedMessage
//               id="sidebar.menuItem.student"
//               defaultMessage=""
//             />
//           ),
//           moduleCode: MODULE_CODED_VALUES.SCHOOL_STUDENT,
//           operation: PERMISSION_VALUES.READ,
//         },
        
        {
          to: "/admin/school-management/teachers",
          linkClassName: "sub-link",
          onClick: () => {
            // newTermAndFee();
          },
          secondaryClassName: "add-icon",
          iconClassName: "fa fa-plus",
          menuName: (
            <FormattedMessage
              id="sidebar.menuItem.teachers"
              defaultMessage=""
            />
          ),
          moduleCode: MODULE_CODED_VALUES.SCHOOL_TEACHER,
          operation: PERMISSION_VALUES.READ,
        },
        {
          to: "/admin/school-management/online-user",
          linkClassName: "sub-link",
          onClick: () => {
            // newTermAndFee();
          },
          secondaryClassName: "add-icon",
          iconClassName: "fa fa-plus",
          menuName: (
            <FormattedMessage
              id="onlineUser.text"
              defaultMessage=""
            />
          ),
          moduleCode: MODULE_CODED_VALUES.SCHOOL_ONLINE_USER,
          operation: PERMISSION_VALUES.READ,
        },
        {
          onClick: toggleDropDownMenu,
          ulClassName: "third-level-nav",
          linkClassName: "sub-link",
          thirdLevelMenuClass: "sub-link",
          rightClassName: "fa fa-angle-right",
          //iconClassName: "fa fa-plus",
          menuName: (
            <FormattedMessage
              id="fees.text"
              defaultMessage=""
            />
          ),
          moduleCode: MODULE_CODED_VALUES.SCHOOL_INVOICE,
          operation: PERMISSION_VALUES.READ,
          showSubMenu: false,
          hasAdditionIcon: false,
          children: [
            {
              to: "/admin/school-management/invoice-list",
              state: {module: 'school'},
              linkClassName: "third-nav-link",
              onClick: (e, menu) => {
                props.history.push("/admin/student-invoice");
              },
              secondaryClassName: "add-icon",
              iconClassName: "fa fa-plus",
              menuName: (
                <FormattedMessage
                  id="tabs.Invoice"
                  defaultMessage=""
                />
              ),
              showSubMenu: false,
              hasAdditionIcon: true,
              moduleCode: MODULE_CODED_VALUES.SCHOOL_INVOICE,
              operation: PERMISSION_VALUES.READ,
            },
            {
              to: "/admin/school-management/recurring-payment",
              linkClassName: "third-nav-link",
              onClick: () => {
                // newTermAndFee();
              },
              secondaryClassName: "add-icon",
              iconClassName: "fa fa-plus",
              menuName: (
                <FormattedMessage
                  id="recurringPayment.text"
                  defaultMessage=""
                />
              ),
              moduleCode: MODULE_CODED_VALUES.SCHOOL_RECURRING_PAYMENTS,
              operation: PERMISSION_VALUES.READ,
            },
          
          ]
        },
        // {
        //   to: "/admin/sales-invoice-list",
        //   linkClassName: "sub-link",
        //   onClick: (e, menu) => {
        //     props.history.push("/admin/student-invoice");
        //   },
        //   secondaryClassName: "add-icon",
        //   iconClassName: "fa fa-plus",
        //   menuName: (
        //     <FormattedMessage
        //       id="tabs.Invoice"
        //       defaultMessage=""
        //     />
        //   ),
        //   showSubMenu: false,
        //   hasAdditionIcon: true,
        //   moduleCode: MODULE_CODED_VALUES.SCHOOL_INVOICE,
        //   operation: PERMISSION_VALUES.READ,
        // }, 
        // {
        //   to: "/admin/school-management/recurring-payment",
        //   linkClassName: "sub-link",
        //   onClick: () => {
        //     // newTermAndFee();
        //   },
        //   secondaryClassName: "add-icon",
        //   iconClassName: "fa fa-plus",
        //   menuName: (
        //     <FormattedMessage
        //       id="recurringPayment.text"
        //       defaultMessage=""
        //     />
        //   ),
        //   moduleCode: MODULE_CODED_VALUES.SCHOOL_DEPARTMENT,
        //   operation: PERMISSION_VALUES.READ,
        // },
        {
          to: "/admin/student-form-list",
          linkClassName: "sub-link",
          onClick: () => {
            newMembershipForm('student');
          },
          secondaryClassName: "add-icon",
          iconClassName: "fa fa-plus",
          menuName: (
            <FormattedMessage
              id="setting.student.registration.list.heading"
              defaultMessage=""
            />
          ),
          showSubMenu: false,
          hasAdditionIcon: true,
          moduleCode: MODULE_CODED_VALUES.SCHOOL_FORM,
          operation: PERMISSION_VALUES.READ,
        },
       
        {
          to: "/admin/school-management/financial-report",
          linkClassName: "sub-link",
          onClick: () => {
            newAssignment();
          },
          secondaryClassName: "add-icon",
          iconClassName: "fa fa-plus",
          menuName: (
            <FormattedMessage
              id="financialReport.text"
              defaultMessage=""
            />
          ),
          showSubMenu: false,
          hasAdditionIcon: true,
          moduleCode: MODULE_CODED_VALUES.SCHOOL_ASSIGNMENTS,
          operation: PERMISSION_VALUES.READ,
        },
        {
          to: "/admin/document-list",
          linkClassName: "sub-link",
          onClick: () => {
            newDocument('student');
          },
          secondaryClassName: "add-icon",
          iconClassName: "fa fa-plus",
          menuName: (
            <FormattedMessage
              id="activity.documents"
              defaultMessage=""
            />
          ),
          showSubMenu: false,
          hasAdditionIcon: true,
          moduleCode: MODULE_CODED_VALUES.SCHOOL_FORM,
          operation: PERMISSION_VALUES.READ,
        },
      ]
    },
    // {
    //   to: "/admin/news-letter",
    //   linkClassName: "link",
    //   secondaryClassName: "",
    //   primaryClassName: "title",
    //   iconClassName: "fa fa-plus-square-o",
    //   menuName: (
    //     <FormattedMessage id="sidebar.menuItem.formBuilder" defaultMessage="" />
    //   ),
    //   showSubMenu: false,
    //   moduleCode: MODULE_CODED_VALUES.NEWS_LETTER,
    //   operation: PERMISSION_VALUES.READ,
    //   children: [],
    // },
    // {
    //   to: "/admin/kiosk",
    //   linkClassName: "link",
    //   secondaryClassName: "",
    //   onClick: () => {
    //     //lockScreen(props);
    //   },
    //   primaryClassName: "title",
    //   iconClassName: "fa fa-desktop",
    //   moduleCode: MODULE_CODED_VALUES.KIOSK,
    //   operation: PERMISSION_VALUES.READ,
    //   menuName: (
    //     <FormattedMessage id="sidebar.menuItem.kiosk" defaultMessage="" />
    //   ),
    //   showSubMenu: false,
    //   children: [],
    // },

    {
      to: "/admin/customer-payment-list",
      linkClassName: "link",
      onClick: (e) => {
        paymentReceive(props);
      },
      secondaryClassName: "",
      primaryClassName: "title",
      iconClassName: "fa fa-money",
      menuName: (
        <FormattedMessage
          id="sidebar.menuItem.customerPayment"
          defaultMessage=""
        />
      ),
      showSubMenu: false,
      hasAdditionIcon: true,
      moduleCode: MODULE_CODED_VALUES.CUSTOMER_PAYMENT,
      operation: PERMISSION_VALUES.READ,
    },

    {
      linkClassName: "link",
      onClick: toggleDropDownMenu,
      primaryClassName: "title",
      secondaryClassName: "title secondary",
      iconClassName: "fa fa-money",
      rightClassName: "fa fa-angle-right",
      menuName: (
        <FormattedMessage id="sidebar.menuItem.finance" defaultMessage="" />
      ),
      showSubMenu: false,
      ulClassName: "sub-sidebar-nav",
      collapseSubMenuTitle: "Finance",
      children: [
        {
          to: "/admin/business-expense-listing",
          linkClassName: "sub-link",
          onClick: (e, menu) => {
            props.history.push("/admin/business-expense-create");
          },
          secondaryClassName: "add-icon",
          iconClassName: "fa fa-plus",
          menuName: (
            <FormattedMessage
              id="sidebar.menuItem.businessExpense"
              defaultMessage=""
            />
          ),
          showSubMenu: false,
          hasAdditionIcon: true,
          moduleCode: MODULE_CODED_VALUES.BUSINESS_EXPENSE,
          operation: PERMISSION_VALUES.READ,
        },
        {
          to: "/admin/banking",
          linkClassName: "sub-link",
          menuName: (
            <FormattedMessage id="sidebar.menuItem.banking" defaultMessage="" />
          ),
          showSubMenu: false,
          moduleCode: MODULE_CODED_VALUES.BANKING,
          operation: PERMISSION_VALUES.READ,
        },
        {
          to: "/admin/ledger-accounts",
          linkClassName: "sub-link",
          onClick: (e) => {
            newLadgerAccounts(props);
          },
          secondaryClassName: "add-icon",
          iconClassName: "fa fa-plus",
          menuName: (
            <FormattedMessage
              id="sidebar.menuItem.leadgerAccounts"
              defaultMessage=""
            />
          ),
          showSubMenu: false,
          hasAdditionIcon: true,
          moduleCode: MODULE_CODED_VALUES.LEDGER_ACCOUNTS,
          operation: PERMISSION_VALUES.READ,
        },
        {
          to: "/admin/balance-sheet",
          linkClassName: "sub-link",
          menuName: (
            <FormattedMessage
              id="sidebar.menuItem.balanceSheet"
              defaultMessage=""
            />
          ),
          showSubMenu: false,
          moduleCode: MODULE_CODED_VALUES.BALANCE_SHEET,
          operation: PERMISSION_VALUES.READ,
        },
        {
          to: "/admin/profit-loss",
          linkClassName: "sub-link",
          menuName: (
            <FormattedMessage
              id="sidebar.menuItem.profitLoss"
              defaultMessage=""
            />
          ),
          showSubMenu: false,
          moduleCode: MODULE_CODED_VALUES.PROFIT_LOSS,
          operation: PERMISSION_VALUES.READ,
        },
        {
          to: "/admin/trial-balance",
          linkClassName: "sub-link",
          menuName: (
            <FormattedMessage
              id="sidebar.menuItem.trialBalance"
              defaultMessage=""
            />
          ),
          showSubMenu: false,
          moduleCode: MODULE_CODED_VALUES.TRIAL_BALANCE,
          operation: PERMISSION_VALUES.READ,
        },
        {
          to: "/admin/opening-balance",
          linkClassName: "sub-link",
          menuName: (
            <FormattedMessage
              id="sidebar.menuItem.openingBalance"
              defaultMessage=""
            />
          ),
          showSubMenu: false,
          moduleCode: MODULE_CODED_VALUES.OPENING_BALANCE,
          operation: PERMISSION_VALUES.READ,
        },
        {
          to: "/admin/show-journals",
          linkClassName: "sub-link",
          onClick: (e, menu) => {
            props.history.push("/admin/create-journal");
          },
          secondaryClassName: "add-icon",
          iconClassName: "fa fa-plus",
          menuName: (
            <FormattedMessage
              id="sidebar.menuItem.journals"
              defaultMessage=""
            />
          ),
          showSubMenu: false,
          hasAdditionIcon: true,
          moduleCode: MODULE_CODED_VALUES.JOURNALS,
          operation: PERMISSION_VALUES.READ,
        },
        {
          to: "/admin/bankReconciliation",
          linkClassName: "sub-link",
          onClick: (e, menu) => { },
          secondaryClassName: "add-icon",
          iconClassName: "fa fa-plus",
          menuName: (
            <FormattedMessage
              id="sidebar.menuItem.bankReconciliation"
              defaultMessage=""
            />
          ),
          showSubMenu: false,
          hasAdditionIcon: true,
          moduleCode: MODULE_CODED_VALUES.BANK_RECONCILIATION,
          operation: PERMISSION_VALUES.READ,
        },
        {
          to: "/admin/show-taxes",
          linkClassName: "sub-link",
          onClick: (e) => {
            handleNewTaxAddition(props);
          },
          secondaryClassName: "add-icon",
          iconClassName: "fa fa-plus",
          menuName: (
            <FormattedMessage id="sidebar.menuItem.taxes" defaultMessage="" />
          ),
          showSubMenu: false,
          hasAdditionIcon: true,
          moduleCode: MODULE_CODED_VALUES.TAXES,
          operation: PERMISSION_VALUES.READ,
        },
        // {
        //     to: "/admin/show-fy-years",
        //     linkClassName: "sub-link",
        //     menuName: <FormattedMessage id='sidebar.menuItem.financialYears' defaultMessage=""/>,
        //     showSubMenu: false,
        //     moduleCode: MODULE_CODED_VALUES.FINANCIAL_YEARS,
        //     operation: PERMISSION_VALUES.READ
        // },
        // {
        //     to: "/admin/show-cost-centers",
        //     linkClassName: "sub-link",
        //     menuName: <FormattedMessage id='sidebar.menuItem.costCenter' defaultMessage=""/>,
        //     showSubMenu: false,
        //     moduleCode: MODULE_CODED_VALUES.COST_CENTERS,
        //     operation: PERMISSION_VALUES.READ
        // }
      ],
    },
    {
      linkClassName: "link",
      onClick: toggleDropDownMenu,
      primaryClassName: "title",
      secondaryClassName: "title secondary",
      iconClassName: "fa fa-building-o",
      rightClassName: "fa fa-angle-right",
      menuName: (
        <FormattedMessage id="sidebar.menuItem.company" defaultMessage="" />
      ),
      showSubMenu: false,
      ulClassName: "sub-sidebar-nav",
      collapseSubMenuTitle: "Organization",
      children: [
        {
          to: "/admin/company-hierarchy-list",
          linkClassName: "sub-link",
          onClick: (e) => {
            newCompanyHierarchy();
          },
          secondaryClassName: "add-icon",
          iconClassName: "fa fa-plus",
          icon: <GoldFilled />,
          menuName: (
            <FormattedMessage
              id="sidebar.menuItem.companyHierarcy"
              defaultMessage=""
            />
          ),
          showSubMenu: false,
          moduleCode: MODULE_CODED_VALUES.COMPANY_HIERARCY,
          operation: PERMISSION_VALUES.READ,
          hasAdditionIcon: true,
        },

        {
          to: "/admin/employee-list",
          linkClassName: "sub-link",
          onClick: (e) => {
            newEmployee();
          },
          secondaryClassName: "add-icon",
          iconClassName: "fa fa-plus",
          icon: <ContainerFilled />,
          menuName: (
            <FormattedMessage
              id="sidebar.menuItem.employee"
              defaultMessage=""
            />
          ),
          showSubMenu: false,
          moduleCode: MODULE_CODED_VALUES.COMPANY_HIERARCY,
          operation: PERMISSION_VALUES.READ,
          hasAdditionIcon: true,
        },
        {
          to: "/admin/projects",
          linkClassName: "sub-link",
          onClick: (e) => {
            handleNewProjectAddition(props, MODAL_TYPE.PROJECT);
          },
          secondaryClassName: "add-icon",
          iconClassName: "fa fa-plus",
          icon: <FolderOpenFilled />,
          menuName: <FormattedMessage id="common.project" defaultMessage="" />,
          showSubMenu: false,
          moduleCode: MODULE_CODED_VALUES.PROJECT,
          operation: PERMISSION_VALUES.READ,
          hasAdditionIcon: true,
        },
      ],
    },
    {
      linkClassName: "link",
      onClick: toggleDropDownMenu,
      primaryClassName: "title",
      secondaryClassName: "title secondary",
      iconClassName: "fa fa-money",
      rightClassName: "fa fa-angle-right",
      menuName: <FormattedMessage id='sidebar.menuItem.integrations' defaultMessage="" />,
      ulClassName: "sub-sidebar-nav",
      showSubMenu: false,
      collapseSubMenuTitle: "Integrations",
      children: [
        {
          to: "/admin/sales-channels",
          linkClassName: "sub-link",
          menuName: <FormattedMessage id='sidebar.menuItem.salesChannels' defaultMessage="" />,
          showSubMenu: false,
          moduleCode: MODULE_CODED_VALUES.SALES_CHANNEL,
          operation: PERMISSION_VALUES.READ
        },
        {
          to: "/admin/shipping",
          linkClassName: "sub-link",
          menuName: <FormattedMessage id='sidebar.menuItem.shipping' defaultMessage="" />,
          showSubMenu: false,
          moduleCode: MODULE_CODED_VALUES.SHIPPING,
          operation: PERMISSION_VALUES.READ
        },
        {
          to: "/admin/accounting-integration",
          linkClassName: "sub-link",
          menuName: <FormattedMessage id='sidebar.menuItem.accounting' defaultMessage="" />,
          showSubMenu: false,
          moduleCode: MODULE_CODED_VALUES.ACCOUNTING,
          operation: PERMISSION_VALUES.READ
        }
        ,
        {
          to: "/admin/payment-integration",
          linkClassName: "sub-link",
          menuName: <FormattedMessage id='sidebar.menuItem.payment' defaultMessage="" />,
          showSubMenu: false,
          moduleCode: MODULE_CODED_VALUES.ACCOUNTING,
          operation: PERMISSION_VALUES.READ
        }
      ]
    },

    {
      to: "/admin/show-reports",
      linkClassName: "link",
      secondaryClassName: "",
      primaryClassName: "title",
      iconClassName: "fa fa-bar-chart-o",
      menuName: (
        <FormattedMessage id="sidebar.menuItem.reports" defaultMessage="" />
      ),
      showSubMenu: false,
      moduleCode: MODULE_CODED_VALUES.REPORTS,
      operation: PERMISSION_VALUES.READ,
      children: [],
    },
    {
      to: "/admin/show-settings",
      linkClassName: "link",
      secondaryClassName: "",
      primaryClassName: "title",
      iconClassName: "fa fa-gears",
      menuName: (
        <FormattedMessage id="sidebar.menuItem.settings" defaultMessage="" />
      ),
      showSubMenu: false,
      moduleCode: MODULE_CODED_VALUES.SETTINGS,
      operation: PERMISSION_VALUES.READ,
      children: [],
    },
    {
      linkClassName: "link",
      onClick: toggleDropDownMenu,
      primaryClassName: "title",
      secondaryClassName: "title secondary",
      iconClassName: "fa fa-money",
      rightClassName: "fa fa-angle-right",
      menuName: <FormattedMessage id='sidebar.menuItem.advances' defaultMessage="" />,
      showSubMenu: false,
      ulClassName: "sub-sidebar-nav",
      children: [
        {
          linkClassName: "sub-link",
          onClick: toggleDropDownMenu,
          primaryClassName: "title",
          secondaryClassName: "title secondary",
          iconClassName: "fa fa-truck",
          rightClassName: "fa fa-angle-right",
          menuName: (
            <FormattedMessage id="sidebar.menuItem.purchase" defaultMessage="" />
          ),
          showSubMenu: false,
          ulClassName: "third-level-nav",
          thirdLevelMenuClass: "sub-link",
          collapseSubMenuTitle: "Purchase",
          children: [
            {
              to: "/admin/suppliers",
              linkClassName: "sub-link",
              onClick: (e) => {
                newSupplier();
              },
              secondaryClassName: "add-icon",
              iconClassName: "fa fa-plus",
              menuName: (
                <FormattedMessage
                  id="sidebar.menuItem.suppliers"
                  defaultMessage=""
                />
              ),
              showSubMenu: false,
              hasAdditionIcon: true,
              ulClassName: "third-level-nav",
              thirdLevelMenuClass: "sub-link",
              moduleCode: MODULE_CODED_VALUES.SUPPLIERS,
              operation: PERMISSION_VALUES.READ,
            },
            {
              to: "/admin/purchase-request-list",
              linkClassName: "sub-link",
              onClick: (e, menu) => {
                props.history.push("/admin/pr-create");
              },
              secondaryClassName: "add-icon",
              iconClassName: "fa fa-plus",
              menuName: (
                <FormattedMessage
                  id="sidebar.menuItem.purchaseRequest"
                  defaultMessage=" "
                />
              ),
              showSubMenu: false,
              hasAdditionIcon: true,
              moduleCode: MODULE_CODED_VALUES.PURCHASE_REQUEST,
              operation: PERMISSION_VALUES.READ,
            },
            {
              to: "/admin/rfq-list",
              linkClassName: "sub-link",
              onClick: (e, menu) => {
                props.history.push("/admin/rfq-create");
              },
              secondaryClassName: "add-icon",
              iconClassName: "fa fa-plus",
              menuName: (
                <FormattedMessage id="sidebar.menuItem.RFQ" defaultMessage=" " />
              ),
              showSubMenu: false,
              hasAdditionIcon: true,
              moduleCode: MODULE_CODED_VALUES.RFQ,
              operation: PERMISSION_VALUES.READ,
            },
            {
              to: "/admin/purchase-order-list",
              linkClassName: "sub-link",
              onClick: (e, menu) => {
                props.history.push("/admin/po-create");
              },
              secondaryClassName: "add-icon",
              iconClassName: "fa fa-plus",
              menuName: (
                <FormattedMessage
                  id="sidebar.menuItem.purchaseOrder"
                  defaultMessage=" "
                />
              ),
              showSubMenu: false,
              hasAdditionIcon: true,
              moduleCode: MODULE_CODED_VALUES.PURCHASE_ORDER,
              operation: PERMISSION_VALUES.READ,
            },
            {
              to: "/admin/inbound-delivery-list",
              linkClassName: "sub-link",
              menuName: (
                <FormattedMessage
                  id="sidebar.menuItem.inboundDelivery"
                  defaultMessage=""
                />
              ),
              showSubMenu: false,
              secondaryClassName: "add-icon",
              iconClassName: "fa fa-plus",
              onClick: (e, menu) => {
                props.history.push({
                  pathname: "/admin/inbound-delivery-create",
                  state: {
                    type: "new",
                  },
                });
              },
              hasAdditionIcon: true,
              moduleCode: MODULE_CODED_VALUES.PURCHASE_ORDER,
              operation: PERMISSION_VALUES.READ,
            },
            {
              to: "/admin/purchase-invoice-list",
              linkClassName: "sub-link",
              onClick: (e, menu) => {
                // props.history.push("/admin/purchase-invoice");
              },
              secondaryClassName: "add-icon",
              iconClassName: "fa fa-plus",
              menuName: (
                <FormattedMessage
                  id="sidebar.menuItem.purchaseInvoice"
                  defaultMessage=""
                />
              ),
              showSubMenu: false,
              hasAdditionIcon: true,
              moduleCode: MODULE_CODED_VALUES.PURCHASE_INVOICE,
              operation: PERMISSION_VALUES.READ,
            },
            {
              to: "/admin/supplier-payment-list",
              linkClassName: "sub-link",
              onClick: (e, menu) => {
                payInvoice(props);
              },
              secondaryClassName: "add-icon",
              iconClassName: "fa fa-plus",
              menuName: (
                <FormattedMessage id="sidebar.menuItem.payment" defaultMessage="" />
              ),
              showSubMenu: false,
              hasAdditionIcon: true,
              moduleCode: MODULE_CODED_VALUES.SUPPLIER_PAYMENT,
              operation: PERMISSION_VALUES.READ,
            },
            {
              to: "/admin/debit-memo-list",
              linkClassName: "sub-link",
              onClick: (e, menu) => {
                props.history.push("/admin/debit-memo-create");
              },
              secondaryClassName: "add-icon",
              iconClassName: "fa fa-plus",
              menuName: (
                <FormattedMessage
                  id="sidebar.menuItem.debitMemo"
                  defaultMessage=""
                />
              ),
              showSubMenu: false,
              hasAdditionIcon: true,
              moduleCode: MODULE_CODED_VALUES.DEBIT_MEMO,
              operation: PERMISSION_VALUES.READ,
            },
          ],
        },

        {
          linkClassName: "link",
          onClick: toggleDropDownMenu,
          primaryClassName: "title",
          secondaryClassName: "title secondary",
          iconClassName: "fa fa-shopping-cart",
          rightClassName: "fa fa-angle-right",
          menuName: (
            <FormattedMessage id="sidebar.menuItem.sales" defaultMessage="" />
          ),
          ulClassName: "third-level-nav",
          thirdLevelMenuClass: "sub-link",
          showSubMenu: true,
          collapseSubMenuTitle: "Sales",
          children: [
            {
              to: "/admin/customers",
              linkClassName: "sub-link",
              onClick: (e) => {
                newCustomer();
              },
              secondaryClassName: "add-icon",
              iconClassName: "fa fa-plus",
              menuName: (
                <FormattedMessage
                  id="sidebar.menuItem.customers"
                  defaultMessage=""
                />
              ),
              showSubMenu: false,
              hasAdditionIcon: true,
              moduleCode: MODULE_CODED_VALUES.CUSTOMERS,
              operation: PERMISSION_VALUES.READ,
            },
            {
              to: "/admin/sales-inquiry-list",
              linkClassName: "sub-link",
              onClick: (e, menu) => {
                props.history.push("/admin/sales-inquiry-create");
              },
              secondaryClassName: "add-icon",
              iconClassName: "fa fa-plus",
              menuName: (
                <FormattedMessage id="sidebar.menuItem.inquiry" defaultMessage="" />
              ),
              showSubMenu: false,
              hasAdditionIcon: true,
              moduleCode: MODULE_CODED_VALUES.SALES_QUOTE,
              operation: PERMISSION_VALUES.READ,
            },
            {
              to: "/admin/sq-list",
              linkClassName: "sub-link",
              onClick: (e, menu) => {
                props.history.push("/admin/create-sales-quote");
              },
              secondaryClassName: "add-icon",
              iconClassName: "fa fa-plus",
              menuName: (
                <FormattedMessage
                  id="sidebar.menuItem.salesQuote"
                  defaultMessage=""
                />
              ),
              showSubMenu: false,
              hasAdditionIcon: true,
              moduleCode: MODULE_CODED_VALUES.SALES_QUOTE,
              operation: PERMISSION_VALUES.READ,
            },
            {
              to: "/admin/so-list",
              linkClassName: "sub-link",
              onClick: (e, menu) => {
                props.history.push("/admin/so-create");
              },
              secondaryClassName: "add-icon",
              iconClassName: "fa fa-plus",
              menuName: (
                <FormattedMessage
                  id="sidebar.menuItem.salesOrder"
                  defaultMessage=""
                />
              ),
              showSubMenu: false,
              hasAdditionIcon: true,
              moduleCode: MODULE_CODED_VALUES.SALES_ORDER,
              operation: PERMISSION_VALUES.READ,
            },
            {
              to: "/admin/package-list",
              linkClassName: "sub-link",
              secondaryClassName: "add-icon",
              iconClassName: "fa fa-plus",
              menuName: (
                <FormattedMessage id="sidebar.menuItem.package" defaultMessage="" />
              ),
              onClick: (e, menu) => {
                props.history.push({
                  pathname: "/admin/so/package",
                  state: {
                    type: "new",
                  },
                });
              },
              hasAdditionIcon: true,
              showSubMenu: false,
              moduleCode: MODULE_CODED_VALUES.SALES_ORDER_PACKAGE,
              operation: PERMISSION_VALUES.READ,
            },
            {
              to: "/admin/shipment-list",
              linkClassName: "sub-link",
              secondaryClassName: "add-icon",
              iconClassName: "fa fa-plus",
              menuName: (
                <FormattedMessage
                  id="sidebar.menuItem.shipment"
                  defaultMessage=""
                />
              ),
              onClick: (e, menu) => {
                props.history.push({
                  pathname: "/admin/so/shipment",
                  state: {
                    type: "new",
                  },
                });
              },
              hasAdditionIcon: true,
              showSubMenu: false,
              moduleCode: MODULE_CODED_VALUES.SALES_ORDER_SHIPMENT,
              operation: PERMISSION_VALUES.READ,
            },
            {
              to: "/admin/sales-invoice-list",
              linkClassName: "sub-link",
              onClick: (e, menu) => {
                props.history.push("/admin/sales-invoice");
              },
              secondaryClassName: "add-icon",
              iconClassName: "fa fa-plus",
              menuName: (
                <FormattedMessage
                  id="sidebar.menuItem.salesInvoice"
                  defaultMessage=""
                />
              ),
              showSubMenu: false,
              hasAdditionIcon: true,
              moduleCode: MODULE_CODED_VALUES.SALES_INVOICE,
              operation: PERMISSION_VALUES.READ,
            },
            {
              to: "/admin/customer-payment-list",
              linkClassName: "sub-link",
              onClick: (e) => {
                paymentReceive(props);
              },
              secondaryClassName: "add-icon",
              iconClassName: "fa fa-plus",
              menuName: (
                <FormattedMessage
                  id="sidebar.menuItem.customerPayment"
                  defaultMessage=""
                />
              ),
              showSubMenu: false,
              hasAdditionIcon: true,
              moduleCode: MODULE_CODED_VALUES.CUSTOMER_PAYMENT,
              operation: PERMISSION_VALUES.READ,
            },
            {
              to: "/admin/credit-memo-list",
              linkClassName: "sub-link",
              onClick: (e, menu) => {
                props.history.push("/admin/create-credit-memo");
              },
              secondaryClassName: "add-icon",
              iconClassName: "fa fa-plus",
              menuName: (
                <FormattedMessage
                  id="sidebar.menuItem.creditMemo"
                  defaultMessage=""
                />
              ),
              showSubMenu: false,
              hasAdditionIcon: true,
              moduleCode: MODULE_CODED_VALUES.CREDIT_MEMO,
              operation: PERMISSION_VALUES.READ,
            },
            {
              to: "/admin/sales-complaint-list",
              linkClassName: "sub-link",
              onClick: (e) => {
                newComplaint();
              },
              secondaryClassName: "add-icon",
              iconClassName: "fa fa-plus",
              menuName: (
                <FormattedMessage
                  id="sidebar.menuItem.salesSupport"
                  defaultMessage=""
                />
              ),
              showSubMenu: false,
              hasAdditionIcon: true,
              moduleCode: MODULE_CODED_VALUES.SALES_ORDER,
              operation: PERMISSION_VALUES.READ,
            },
            {
              to: "/admin/student-registration-list",
              linkClassName: "sub-link",
              onClick: (e) => {
                newComplaint();
              },
              secondaryClassName: "add-icon",
              iconClassName: "fa fa-plus",
              menuName: (
                <FormattedMessage id="studentRegistration.text" defaultMessage="" />
              ),
              moduleCode: MODULE_CODED_VALUES.SALES_ORDER,
              operation: PERMISSION_VALUES.READ,
            },
          ],
        },

        {
          linkClassName: "link",
          onClick: toggleDropDownMenu,
          primaryClassName: "title",
          secondaryClassName: "title secondary",
          iconClassName: "fa fa-cubes",
          rightClassName: "fa fa-angle-right",
          menuName: (
            <FormattedMessage id="sidebar.menuItem.inventory" defaultMessage="" />
          ),
          ulClassName: "third-level-nav",
          thirdLevelMenuClass: "sub-link",
          showSubMenu: true,
          collapseSubMenuTitle: "Inventory",

          children: [
            {
              to: "/admin/products",
              linkClassName: "sub-link",
              onClick: (e) => {
                e.preventDefault();
                e.stopPropagation();
                newInventory();
              },
              secondaryClassName: "add-icon",
              iconClassName: "fa fa-plus",
              menuName: (
                <FormattedMessage
                  id="sidebar.menuItem.products"
                  defaultMessage=""
                />
              ),
              showSubMenu: false,
              hasAdditionIcon: true,
              moduleCode: MODULE_CODED_VALUES.PRODUCTS,
              operation: PERMISSION_VALUES.READ,
            },
            // {
            //     linkClassName: "sub-link",
            //     onClick: toggleDropDownMenu,
            //     rightClassName: "fa fa-angle-right",
            //     menuName: <FormattedMessage id='sidebar.menuItem.warehouse' defaultMessage=""/>,
            //     ulClassName: "third-level-nav",
            //     thirdLevelMenuClass: "sub-link",
            //     showSubMenu: true,
            //     children: [
            //         {
            //             to: "/admin/warehouse/my-warehouse",
            //             linkClassName: "third-nav-link",
            //             onClick: (e) => (e) => {
            //                 handleNewWarehouseAddition(props);
            //             },
            //             secondaryClassName: "add-icon",
            //             // iconClassName: "fa fa-plus",
            //             menuName: <FormattedMessage id='sidebar.menuItem.myWarehouse' defaultMessage=""/>,
            //             showSubMenu: false,
            //             hasAdditionIcon: true,
            //             moduleCode: MODULE_CODED_VALUES.MY_WAREHOUSE,
            //             operation: PERMISSION_VALUES.READ
            //         },
            //         {
            //             to: "/admin/warehouse/stock-summary",
            //             linkClassName: "third-nav-link",
            //             menuName: <FormattedMessage id='sidebar.menuItem.stockSummary' defaultMessage=""/> ,
            //             showSubMenu: false,
            //             moduleCode: MODULE_CODED_VALUES.STOCK_SUMMARY,
            //             operation: PERMISSION_VALUES.READ
            //         },
            //         {
            //             to: "/admin/warehouse/stock-adjustment",
            //             linkClassName: "third-nav-link",
            //             menuName: <FormattedMessage id='sidebar.menuItem.stockAdjustment' defaultMessage=""/>,
            //             showSubMenu: false,
            //             moduleCode: MODULE_CODED_VALUES.STOCK_ADJUSTMENT,
            //             operation: PERMISSION_VALUES.READ
            //         },
            //         {
            //             to: "/admin/warehouse/aged-stock",
            //             linkClassName: "third-nav-link",
            //             menuName: <FormattedMessage id='sidebar.menuItem.agedStock' defaultMessage=""/>,
            //             showSubMenu: false,
            //             moduleCode: MODULE_CODED_VALUES.AGED_STOCK,
            //             operation: PERMISSION_VALUES.READ
            //         }
            //     ]
            // },
            {
              to: "/admin/warehouse/my-warehouse",
              linkClassName: "sub-link",
              onClick: (e) => (e) => {
                handleNewWarehouseAddition(props);
              },
              secondaryClassName: "add-icon",
              // iconClassName: "fa fa-plus",
              menuName: (
                <FormattedMessage
                  id="sidebar.menuItem.myWarehouse"
                  defaultMessage=""
                />
              ),
              showSubMenu: false,
              hasAdditionIcon: true,
              moduleCode: MODULE_CODED_VALUES.MY_WAREHOUSE,
              operation: PERMISSION_VALUES.READ,
            },
            {
              to: "/admin/warehouse/stock-summary",
              linkClassName: "sub-link",
              menuName: (
                <FormattedMessage
                  id="sidebar.menuItem.stockSummary"
                  defaultMessage=""
                />
              ),
              showSubMenu: false,
              moduleCode: MODULE_CODED_VALUES.STOCK_SUMMARY,
              operation: PERMISSION_VALUES.READ,
            },
            {
              to: "/admin/warehouse/stock-adjustment",
              linkClassName: "sub-link",
              menuName: (
                <FormattedMessage
                  id="sidebar.menuItem.stockAdjustment"
                  defaultMessage=""
                />
              ),
              showSubMenu: false,
              moduleCode: MODULE_CODED_VALUES.STOCK_ADJUSTMENT,
              operation: PERMISSION_VALUES.READ,
            },
            {
              to: "/admin/warehouse/aged-stock",
              linkClassName: "sub-link",
              menuName: (
                <FormattedMessage
                  id="sidebar.menuItem.agedStock"
                  defaultMessage=""
                />
              ),
              showSubMenu: false,
              moduleCode: MODULE_CODED_VALUES.AGED_STOCK,
              operation: PERMISSION_VALUES.READ,
            },
            {
              to: "/admin/product-price",
              linkClassName: "sub-link",
              menuName: (
                <FormattedMessage
                  id="sidebar.menuItem.productPrice"
                  defaultMessage=""
                />
              ),
              showSubMenu: false,
              moduleCode: MODULE_CODED_VALUES.PRODUCT_PRICE,
              operation: PERMISSION_VALUES.READ,
            },
            {
              to: "/admin/price-list",
              linkClassName: "sub-link",
              onClick: (e) => {
                newPriceListAddition();
              },
              secondaryClassName: "add-icon",
              iconClassName: "fa fa-plus",
              menuName: (
                <FormattedMessage
                  id="sidebar.menuItem.priceList"
                  defaultMessage=""
                />
              ),
              showSubMenu: false,
              hasAdditionIcon: true,
              moduleCode: MODULE_CODED_VALUES.PRICE_LIST,
              operation: PERMISSION_VALUES.READ,
            },
            {
              linkClassName: "sub-link",
              onClick: toggleDropDownMenu,
              rightClassName: "fa fa-angle-right",
              menuName: (
                <FormattedMessage id="sidebar.menuItem.extras" defaultMessage="" />
              ),
              ulClassName: "third-level-nav",
              thirdLevelMenuClass: "sub-link",
              showSubMenu: true,
              children: [
                {
                  to: "/admin/extras/product-category",
                  linkClassName: "third-nav-link",
                  onClick: (e) => (e) => {
                    handleNewItemAddition(props, MODAL_TYPE.PRODUCT_CATEGORY);
                  },
                  secondaryClassName: "add-icon",
                  iconClassName: "fa fa-plus",
                  menuName: (
                    <FormattedMessage
                      id="sidebar.menuItem.category"
                      defaultMessage=""
                    />
                  ),
                  showSubMenu: false,
                  hasAdditionIcon: true,
                  moduleCode: MODULE_CODED_VALUES.PRODUCT_CATEGORY,
                  operation: PERMISSION_VALUES.READ,
                },
                {
                  to: "/admin/extras/product-manufacturer",
                  linkClassName: "third-nav-link",
                  onClick: (e) => (e) => {
                    handleNewItemAddition(props, MODAL_TYPE.PRODUCT_MANUFACTURER);
                  },
                  secondaryClassName: "add-icon",
                  iconClassName: "fa fa-plus",
                  menuName: (
                    <FormattedMessage
                      id="sidebar.menuItem.manufactures"
                      defaultMessage=""
                    />
                  ),
                  showSubMenu: false,
                  hasAdditionIcon: true,
                  moduleCode: MODULE_CODED_VALUES.PRODUCT_MANUFACTURER,
                  operation: PERMISSION_VALUES.READ,
                },
                {
                  to: "/admin/extras/product-brands",
                  linkClassName: "third-nav-link",
                  onClick: (e) => (e) => {
                    handleNewItemAddition(props, MODAL_TYPE.PRODUCT_BRAND);
                  },
                  secondaryClassName: "add-icon",
                  iconClassName: "fa fa-plus",
                  menuName: (
                    <FormattedMessage
                      id="sidebar.menuItem.brands"
                      defaultMessage=""
                    />
                  ),
                  showSubMenu: false,
                  hasAdditionIcon: true,
                  moduleCode: MODULE_CODED_VALUES.PRODUCT_BRAND,
                  operation: PERMISSION_VALUES.READ,
                },
                {
                  to: "/admin/extras/UOM",
                  linkClassName: "third-nav-link",
                  onClick: (e) => (e) => {
                    handleNewItemAddition(props, MODAL_TYPE.UOM);
                  },
                  secondaryClassName: "add-icon",
                  iconClassName: "fa fa-plus",
                  menuName: (
                    <FormattedMessage id="sidebar.menuItem.uom" defaultMessage="" />
                  ),
                  showSubMenu: false,
                  hasAdditionIcon: true,
                  moduleCode: MODULE_CODED_VALUES.UOM,
                  operation: PERMISSION_VALUES.READ,
                },
              ],
            },
          ],
        },

        {
          linkClassName: "link",
          onClick: toggleDropDownMenu,
          primaryClassName: "title",
          secondaryClassName: "title secondary",
          iconClassName: "fa fa-money",
          rightClassName: "fa fa-angle-right",
          menuName: <FormattedMessage id='sidebar.menuItem.salesChannels' defaultMessage="" />,
          ulClassName: "third-level-nav",
          thirdLevelMenuClass: "sub-link",
          showSubMenu: true,
          children: [
            {
              to: "/admin/sales-channels-amazon",
              linkClassName: "sub-link",
              menuName: <FormattedMessage id='amazon.logo' defaultMessage="" />,
              showSubMenu: false,
              moduleCode: MODULE_CODED_VALUES.SALES_CHANNEL,
              operation: PERMISSION_VALUES.READ
            },
            // {
            //     to: "/admin/sales-channels-shopify",
            //     linkClassName: "sub-link",
            //     menuName: <FormattedMessage id='shopify.logo' defaultMessage=""/>,
            //     showSubMenu: false,
            //     moduleCode: MODULE_CODED_VALUES.ACCOUNTING,
            //     operation: PERMISSION_VALUES.READ
            // },
            {
              to: "/admin/sales-channels-ebay",
              linkClassName: "sub-link",
              menuName: <FormattedMessage id='eabay.logo' defaultMessage="" />,
              showSubMenu: false,
              moduleCode: MODULE_CODED_VALUES.SALES_CHANNEL,
              operation: PERMISSION_VALUES.READ
            },
            {
              to: "/admin/sales-channels-walmart",
              linkClassName: "sub-link",
              menuName: <FormattedMessage id='walmart.logo' defaultMessage="" />,
              showSubMenu: false,
              moduleCode: MODULE_CODED_VALUES.SALES_CHANNEL,
              operation: PERMISSION_VALUES.READ
            },
          ]
        },
        {
          linkClassName: "link",
          onClick: toggleDropDownMenu,
          primaryClassName: "title",
          secondaryClassName: "title secondary",
          iconClassName: "fa fa-money",
          rightClassName: "fa fa-angle-right",
          menuName: <FormattedMessage id='sidebar.menuItem.shipping' defaultMessage="" />,
          ulClassName: "third-level-nav",
          thirdLevelMenuClass: "sub-link",
          showSubMenu: true,
          children: [
            {
              to: "/admin/easy-post",
              linkClassName: "sub-link",
              menuName: <FormattedMessage id='sidebar.menuItem.easyPost' defaultMessage="" />,
              showSubMenu: false,
              moduleCode: MODULE_CODED_VALUES.SHIPPING,
              operation: PERMISSION_VALUES.READ
            },
            {
              to: "/admin/ship-station",
              linkClassName: "sub-link",
              menuName: <FormattedMessage id='sidebar.menuItem.shipStation' defaultMessage="" />,
              showSubMenu: false,
              moduleCode: MODULE_CODED_VALUES.SHIPPING,
              operation: PERMISSION_VALUES.READ
            }
          ]
        },

      ],
    },
    // {
    //   linkClassName: "link",
    //   secondaryClassName: "title secondary",
    //   onClick: () => {
    //     lockScreen(props);
    //   },
    //   primaryClassName: "title",
    //   iconClassName: "fa fa-lock",
    //   moduleCode: MODULE_CODED_VALUES.LOCK_SCREEN,
    //   operation: PERMISSION_VALUES.READ,
    //   menuName: (
    //     <FormattedMessage id="sidebar.menuItem.lockScreen" defaultMessage="" />
    //   ),
    //   showSubMenu: false,
    //   children: [],
    // },

    // {
    //   linkClassName: "link",
    //   onClick: toggleDropDownMenu,
    //   primaryClassName: "title",
    //   secondaryClassName: "title secondary",
    //   iconClassName: "fa store-icon",
    //   rightClassName: "fa fa-angle-right",
    //   menuName: <FormattedMessage id="ecommerce.text" defaultMessage="" />,
    //   showSubMenu: false,
    //   ulClassName: "sub-sidebar-nav",
    //   collapseSubMenuTitle: "Ecommerce",
    //   children: [
    //     {
    //       to: "/admin/published-stock",
    //       linkClassName: "sub-link",
    //       // onClick: (e) => {
    //       //     newSupplier()
    //       // },
    //       // secondaryClassName: "add-icon",
    //       // iconClassName: "fa fa-plus",
    //       menuName: (
    //         <FormattedMessage id="publishedStock.text" defaultMessage="" />
    //       ),
    //       showSubMenu: false,
    //       //hasAdditionIcon: true,
    //       moduleCode: MODULE_CODED_VALUES.ONLINE_STORES,
    //       operation: PERMISSION_VALUES.READ,
    //     },
    //   ],
    // },
  ];

  const sideMenu = getPermissionedMenuItem(props.isAlifApp ? sideMenuUnFilteredAlif : sideMenuUnFiltered);
  sideMenu.unshift({
    to: "/admin/dashboard",
    linkClassName: "link",
    secondaryClassName: "",
    primaryClassName: "title",
    iconClassName: "fa fa-dashboard",
    icon: <DashboardFilled style={{color: 'white' }} />,
    menuName: (
      <FormattedMessage id="sidebar.menuItem.dashboard" defaultMessage="" />
    ),
    showSubMenu: false,
    children: [],
  });

  const [sideMenuList, updateSideMenuList] = useState(sideMenu);

  const getChildMenu = (menu, i) => {
    return (
      <li key={i}>
        <span
          className={
            (menu.thirdLevelMenuClass && menu.showSubMenu
              ? menu.thirdLevelMenuClass + " open"
              : menu.thirdLevelMenuClass) ||
            (menu.showSubMenu ? "link open " : "link")
          }
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            menu.onClick(menu);
          }}
        >
          {/* <i className={menu.iconClassName} /> */}
          <span className= {menu.secondaryClassName ? 'icon-class': ''}>{menu.icon ? menu.icon : ''}</span>
          <span className={menu.secondaryClassName}>{menu.menuName}</span>
          <i className={menu.rightClassName} />
          {(menu.children || []).length ? (
            <ul className={menu.ulClassName}>
              {menu.collapseSubMenuTitle && (
                <span className="collapse-submenu-title">
                  {menu.collapseSubMenuTitle}
                </span>
              )}
              {menu.children.map((menuChild, i) => {
                return menuChild.to ? (
                  <li
                    key={i}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                  >
                    <NavLink
                    //  to={menuChild.to}
                      to={{
                        pathname:menuChild.to,
                        state: menuChild.state  
                      }}
                      className={menuChild.linkClassName}
                    >
                      {/* <span className= 'icon-class'>{menuChild.icon ? menuChild.icon : ''}</span> */}
                      {/* above line is for anticon in side menu for second level */}
                      {/* {menuChild.iconClassNameChild && <i className={menuChild.iconClassNameChild} />} */}
                      {menuChild.menuName}
                      {menuChild.hasAdditionIcon && (
                        <span
                          className={menuChild.secondaryClassName}
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            menuChild.onClick(e, menu);
                          }}
                        >
                          <i className={menuChild.iconClassName} />
                        </span>
                      )}
                    </NavLink>
                  </li>
                ) : (
                    getChildMenu(menuChild, i)
                  );
              })}
            </ul>
          ) : null}
        </span>
      </li>
    );
  };

  function getFirstLetterCapital(words){
    const wordArr = words.split(" ");
    let capWords='';
    for (let i = 0; i < wordArr.length; i++){
      const first = wordArr[i].slice(0, 1).toUpperCase();
      const fWord = first.concat(wordArr[i].slice(1))
      capWords = capWords ? capWords + ' ' + fWord : capWords + fWord;
    }
    return capWords;
  }

  return (
    <div className={`${isCollapsed ? "collapsedSidebar" : "fullviewSidebar"}`}>
      <div className={`toggle-li ${!props.collapsed ? 'toggle-li-bg' : ''}`}>
        <span onClick={()=>{
            props.updateState({collapsed: !props.collapsed});
          }}
          className='toggle-menu-icon'>
            {/* <span className="menu-icon">
                <div className="bar line-1" />
                <div className="bar line-2" />
                <div className="bar line-3" />
            </span> */}
            <span className="org-icon">
              <span>{(props.companyInfo || {}).storeName ? getInitialsFromString((props.companyInfo || {}).storeName) : 'AC'}</span>
            </span>
        </span>
        <span className="company-title">
          {!props.collapsed && (props.companyInfo || {}).storeName ? getFirstLetterCapital((props.companyInfo || {}).storeName) : ''}
        </span>
      </div>
      <ul className="sidebar-nav">
        {sideMenuList.map((menu, i) => {
          return menu.to ? (
            <li key={i}>
              <NavLink to={menu.to} className={menu.linkClassName}>
                <span className= 'icon-class'>{menu.icon ? menu.icon : ''}</span>
                {/* <i className={menu.iconClassName} /> */}
                <span className={menu.primaryClassName}>{menu.menuName}</span>
                {menu.rightClassName ? (
                  <i className={menu.rightClassName} />
                ) : null}
              </NavLink>
            </li>
          ) : (
              getChildMenu(menu, i)
            );
        })}
      </ul>
    </div>
  );
});

export default injectIntl(SidebarMenu);

SidebarMenu.defaultProps = {
  isCollapsed: false,
};
