import { Tabs, Button } from "antd";
import React from "react";
import "../../../../eStore/storeSettings/store-settings.scss";
import CouponListComp from "./couponList";
import { FormattedMessage } from 'react-intl';
import { ICONS, MODULE_CODED_VALUES } from "../../../../../static/constants";
import CouponUsageListCom from "./couponUsageList";
import PageHeader from "../../../../Common/pageHeader";
import { useLocation } from 'react-router-dom';
import { getPermissionData } from "../../../../../utils";
const { TabPane } = Tabs;

const CouponComp = (props) => {
    const {permissions} = props;
    const primaryPerm = (permissions && permissions.primary) || [];
    const permissionsData = getPermissionData(primaryPerm, MODULE_CODED_VALUES.E_STORE_SETTING);
    function onTabChange(key, props) {
        const companyInfo = props.userInfo || {};
        const payload = {
            relationshipId: companyInfo?.relationshipId,
        };
        if (key === "1") {
            props.updateState({ activeTab: "Coupon", activeTabKey: "1" });
            props.getAllCoupons(payload);
        } else if (key === "2") {
            props.updateState({ activeTab: "Usage", activeTabKey: "2" });
            props.getAllCouponUsage(payload);
            props.getCouponUsageCount(payload);
        }
    }
    const breadCrumbList = [
        {
          name: <FormattedMessage id='common.sales' defaultMessage='' />,
        },
        {
          name: "Coupon Management",
        }
    ];
    const location = useLocation();
    const pathName = location.pathname;
    return (<>
    { pathName !=="/admin/coupon-management"?
        <div className="store-model-container">
            <div className="store-main-container">
            <div className="model-head custom-row-coupon">
                <div>
                    <p className="heading">Coupon Management</p>
                    <p className="sub-heading">
                        To create, Update and Delete Coupons for your store
                    </p>
                </div>
                {
                    permissionsData?.create ?<Button
                    type="primary"
                    className="create-action-btn"
                    onClick={() => 
                        {
                        props.updateState({
                            createCouponDrawerVisible : true,
                            couponData: {}
                        })
                        // const modalData = {
                        //     title: <FormattedMessage id='expensePayment.text' defaultMessage='' />,
                        //     modalBody: <CreateCoupon {...props} />,
                        //     width: 500,
                        //     hideFooter: true,
                        //     wrapClassName: 'create-coupon-modal'
                        // };
                        // props.showModal(modalData);
                    }}
                    
                >   
                    <i className={`${ICONS.ADD} mr5`} />
                    <FormattedMessage id='eStore.coupons.create.button.text' defaultMessage='Create Coupon' />
                </Button> : ""
                }
                
            </div>
            <Tabs 
                type="line"
                defaultActiveKey={"1"}
                activeKey={props.activeTabKey || "1"}
                onChange={(key) => {
                    onTabChange(key, props);
                }}
                tabBarGutter={16}
                tabBarStyle={{marginBottom: '0px'}}
            >
                <TabPane tab="Coupon" key="1">
                    <CouponListComp {...props} />
                </TabPane>
                <TabPane tab="Usage" key="2">
                    <CouponUsageListCom {...props} />
                </TabPane>
            </Tabs>
            </div>
        </div>
           :<>
             <PageHeader {...props}
          pageName="Coupon Management"
          breadCrumbList={breadCrumbList}
          canCreate={true}
          onCreate={() => 
            {
            props.updateState({
                createCouponDrawerVisible : true,
                couponData: {}
            })
        }}
        moduleCode={MODULE_CODED_VALUES.E_STORE_SETTING}
        />
            <div className="view-container">
            <Tabs 
                type="line"
                defaultActiveKey={"1"}
                activeKey={props.activeTabKey || "1"}
                onChange={(key) => {
                    onTabChange(key, props);
                }}
                tabBarGutter={16}
                tabBarStyle={{marginBottom: '0px'}}
            >
                <TabPane tab="Coupon" key="1">
                    <CouponListComp {...props} />
                </TabPane>
                <TabPane tab="Usage" key="2">
                    <CouponUsageListCom {...props} />
                </TabPane>
            </Tabs>
            </div>
           </>}
    </>
    );
};

export default CouponComp;
