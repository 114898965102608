import React from "react";
import { Tabs } from 'antd';
import PurchaseInvoice from "./purchaseInvoice";
import { FormattedMessage } from 'react-intl';
import PurchaseInvoiceDraft from "./purchaseInvoiceDraft";
import { fetchDataIfNeeded, fetchPaginationDataIfNeeded } from '../../../../utils';
import StandardPurchaseOrder from "../../../../containers/supplier/invoice/StandardPurchaseInvoice";
import PageHeader from "../../../Common/pageHeader";
import { MODULE_CODED_VALUES } from "../../../../static/constants";

const { TabPane } = Tabs;

function callback(key, props) {
    const payload = { companyInfo: props.companyInfo, relationshipId: props.companyInfo.relationshipId, pageNumber: 1, pageSize: 25 };
    if (key === '1') {
        props.updateState({activeTab: 'Purchase Invoice', activeTabKey: "1"});
    } else if (key === '2') {
        props.updateState({activeTab: 'Draft', activeTabKey: "2",pageNumber: 1, pageSize:25});
        fetchDataIfNeeded('getPurchaseInvoiceDraftCount', 'purchaseInvoiceDraftCount', props, payload);
        fetchPaginationDataIfNeeded('fetchPurchaseInvoiceDrafts', 'purchaseInvoiceDraftList', props, payload);
    } 
}

const PurchaseInvoiceListingComp = (props) => {
    const breadCrumbList = [
        {
            name: <FormattedMessage id='common.purchase' defaultMessage='' />,
        },
        {
            name: <FormattedMessage id='sidebar.menuItem.purchaseInvoice' defaultMessage='' />,
        }
    ];

    const openNewPol = () => 
    {
        props.pushModalToStack({
            modalBody: <StandardPurchaseOrder {...props} type = 'new'/>,
            width: '100%',
            hideTitle: true,
            hideFooter: true,
            keyboard: false ,
            wrapClassName: 'new-transaction-wrapper',
            onCancel: () => {
                props.showModal({
                  modalBody: <FormattedMessage id="modal.cancel.confirmation" defaultMessage=""/>,
                  footer:{submit:{intlId:"button.close.label"}},
                  handleSubmit: () => {
                    props.popModalFromStack();
                  }
                })
              }
        })
    }
    return (
        <>
            <PageHeader {...props}
                pageName="sidebar.menuItem.purchaseInvoice"
                breadCrumbList={breadCrumbList}
                canCreate={true}
                onCreate={openNewPol}
                moduleCode = {MODULE_CODED_VALUES.PURCHASE_INVOICE}
            />
            <div className="view-container">
                <Tabs activeKey={props.activeTabKey || "1"} onChange={(key) => { callback(key, props); }} type="line">
                    <TabPane tab={<FormattedMessage id='sidebar.menuItem.purchaseInvoice' defaultMessage='' />} key="1">
                        <PurchaseInvoice {...props} />
                    </TabPane>
                    <TabPane tab={<FormattedMessage id='draft.text' defaultMessage='' />} key="2">
                        <PurchaseInvoiceDraft {...props} />
                    </TabPane>
                </Tabs>
            </div>
 
        </>
    )
};

export default PurchaseInvoiceListingComp;
