import React, { Fragment } from "react";
import { Skeleton, Input,Empty } from 'antd';
import * as debounce from 'lodash.debounce';
import { CONSTANTS } from '../../../static/constants';
import { injectIntl, FormattedMessage } from 'react-intl';
// import { AG_GRID_CLASS_CONSTANTS } from "../../../static/cssClassConstants";
import PageBreadcrumb from "../../PageBreadcrumb";
// import { CustomAgGrid } from "../../general/CustomAgGrid";
import { getFinancialYear, getMomentDateForUIReadOnly, sortTable  } from "../../../utils";
import { SortAscendingOutlined, SortDescendingOutlined} from '@ant-design/icons';


const { Search } = Input;

let gridApi = {};

// const agGridStyle = {
//     height: '100%',
//     width: '100%'
// };

// const onFirstDataRendered = (params) => {
//     params.api.sizeColumnsToFit();
// }
// const getRowHeight = (params) => {
//     return 30;
// }


const OpeningBalanceComp = (props) => {
    const { allLedgerAccounts, companyInfo,updateState,dir=0 } = props;
    const financialYear = getFinancialYear(companyInfo);



    // const onGridReady = (params) => {
    //     gridApi = params.api;
    //     params.api.sizeColumnsToFit();
    // }

    // const columnDefs = [
    //     {
    //         headerComponentFramework: () => {
    //             return <Tooltip placement="top" title={props.intl.formatMessage(
    //                 {
    //                   id: 'tooltip.ledgerAccount',
    //                   defaultMessage: ''
    //                 }
    //               )}>
    //                 <div className='table-header-column-container'>
    //                     <span className='table-header-column'>Ledger Account</span>
    //                 </div>
    //             </Tooltip>;
    //         },
    //         field: "ledgerAccountName",
    //         resizable: true,
    //         rowDrag: true,
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <Tooltip placement="top" title={props.intl.formatMessage(
    //                 {
    //                   id: 'tooltip.amount',
    //                   defaultMessage: ''
    //                 }
    //               )}>
    //                 <div className='table-header-column-container'>
    //                     <span className='table-header-column'> Amount   ({props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "})</span>
    //                 </div>
    //             </Tooltip>;
    //         },
    //         field: "openingBalance",
    //         valueGetter: function (params) {
    //             return params.data.openingBalance;
    //         },
    //         valueSetter: function (params) {
    //             if (Number(params.newValue || 0) !== Number(params.oldValue || 0)) {
    //                 params.data[params.colDef.field] = Number(params.newValue);
    //                 params.data.balanceAmount = 0;
    //                 params.data.relationshipId = props.companyInfo.relationshipId;
    //                 props.updateOpeningBalance(params.data);
    //             }
    //             return true;
    //         },
    //         editable: true,
    //         sortable: true,
    //         cellEditor: 'customNumberEditor',
    //         cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <Tooltip placement="top" title={props.intl.formatMessage(
    //                 {
    //                   id: 'tooltip.nature',
    //                   defaultMessage: ''
    //                 }
    //               )}>
    //                 <div className='table-header-column-container'>
    //                     <span className='table-header-column'> Nature</span>
    //                 </div>
    //             </Tooltip>;
    //         },
    //         field: "accountingEntry",
    //         resizable: true,
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <Tooltip placement="top" title={props.intl.formatMessage(
    //                 {
    //                   id: 'tooltip.txDate',
    //                   defaultMessage: ''
    //                 }
    //               )}>
    //                 <div className='table-header-column-container'>
    //                     <span className='table-header-column'>Transaction Date</span>
    //                 </div>
    //             </Tooltip>;
    //         },
    //         field: "txDate",
    //         resizable: true,
    //         valueFormatter: (data) => {
    //             data.value = getMomentDateForUIReadOnly({date: financialYear.fyStartDate, format: CONSTANTS.DISPLAY_DATE_FORMAT_FY});
    //             return data.value;
    //         }
    //     }
    // ]

    const onSearchedInputChange = debounce((searchedText, props) => {
        handleSearchedText(searchedText, props)
    }, 2000);

    const handleSearchedText = (searchedText, props) => {
        gridApi.setQuickFilter(searchedText || '');
    }

    const breadCrumbList = [
        // {
        //     name: 'Dashboard',
        //     link: 'dashboard'
        // },
        {
            name: 'Finance'
        }, {
            name: 'Opening Balance'
        }
    ];

    const sortColumn = (e) => {
        sortTable(document.getElementById('opening-balance'), e.target.cellIndex, 1 - dir);
        updateState({ dir: 1 - dir })
      }
      
    return (
      <Fragment>
        <div className="view-top-bar">
          <div className="top-view-left">
            <div className="page-heading">
              <FormattedMessage
                id="heading.finance.openingBalance"
                defaultMessage=""
              />
            </div>
            <div className="vertical-sep" />
            <PageBreadcrumb breadCrumbList={breadCrumbList} />
          </div>
          <div className="top-view-right"></div>
        </div>
        <div className="view-container">
        
            <div className="view-container-actions">
              <div className="left-actions">
                <div className="table-heading">
                  <FormattedMessage
                    id="heading.finance.openingBalance.table.heading"
                    defaultMessage=""
                  />
                </div>
                {props.pageSize ? (
                  <>
                    <div className="vertical-sep" />
                    <div>{props.pageSize}</div>
                  </>
                ) : (
                  ""
                )}
              </div>
              <div className="right-actions">
              <Search
                  placeholder="Search"
                  value={props.searchedText}
                  onChange={(e) => {
                    props.updateState({ searchedText: e.target.value });
                    onSearchedInputChange(e.target.value, props);
                  }}
                  onSearch={(searchedText) => {
                    props.updateState({ searchedText });
                    onSearchedInputChange(searchedText, props);
                  }}
                />
              </div>
            </div>
            
            {/* <div className="agGridWrapper">
              <div className="ag-theme-balham" style={agGridStyle}>
                <CustomAgGrid
                  columnDefs={columnDefs}
                  rowData={allLedgerAccounts || []}
                  rowDragManaged={true}
                  onGridReady={onGridReady}
                  domLayout={"autoHeight"}
                  animateRows={true}
                  onGridSizeChanged={onFirstDataRendered}
                  getRowHeight={getRowHeight}
                />
              </div>
            </div> */}
            <div className="table-container">
            <Skeleton loading={props.listLoading} active paragraph={{ rows: 16 }}>
                <table id="opening-balance">
                    <thead>
                        <tr>
                        <th><FormattedMessage id="serial.no"/></th>
                        <th onClick={sortColumn}><FormattedMessage id="tooltip.ledgerAccount" defaultMessage="" /> {dir ? <SortAscendingOutlined /> : <SortDescendingOutlined />}</th>
                        <th onClick={sortColumn}><FormattedMessage id="tooltip.amount" defaultMessage="" /> {dir ? <SortAscendingOutlined /> : <SortDescendingOutlined />}</th>
                        <th onClick={sortColumn}><FormattedMessage id="tooltip.nature" defaultMessage="" /> {dir ? <SortAscendingOutlined /> : <SortDescendingOutlined />}</th>
                        <th onClick={sortColumn}><FormattedMessage id='tooltip.txDate' defaultMessage="" /> {dir ? <SortAscendingOutlined /> : <SortDescendingOutlined />}</th>

                        </tr>
                    </thead>
                    <tbody>
                  {(allLedgerAccounts || [])?.length ? allLedgerAccounts.map((rowData, index) => {
                    return (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{rowData.ledgerAccountName}</td>
                        <td>{rowData.openingBalance}</td>
                        <td>{rowData.accountingEntry}</td>
                        <td>{
                          rowData.value = getMomentDateForUIReadOnly({ date: financialYear.fyStartDate, format: CONSTANTS.DISPLAY_DATE_FORMAT_FY })}</td>

                      </tr>
                    )
                  }) :
                    <tr key="empty-data-box">
                      <td colSpan={"100%"} style={{ borderBottom: "none" }}>
                        <Empty />
                      </td>
                    </tr>
                  }
                    </tbody>

                </table>
          </Skeleton>
            </div>
          <div className="footer">
            <div className="f-left"></div>
            <div className="f-right"></div>
          </div>
        </div>
      </Fragment>
    );
};

export default injectIntl(OpeningBalanceComp);