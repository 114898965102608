import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import AllInventorySummaryListingComp from '../../../../components/inventory/InventoryValuation/allInvSummary';
import { getFinancialYear } from '../../../../utils';
import { fetchAllInventoryValuationList } from './action';


class AllInventorySummaryListing extends Component {
  constructor(props) {
    super(props);
    const companyInfo = this.props.companyInfo || {};
    const FY = getFinancialYear(companyInfo) || {fyStartDate: '', fyEndDate: ''};
    this.state = {
      warehouseMasterId : 0,
      pageSize: 100,
      pageNumber: 1,
      isDataRendered: false,
      startDate: FY.fyStartDate,
      endDate: FY.fyEndDate,
    };
  }

  componentDidMount() {
    const payload = {
      relationshipId: this.props.companyInfo.relationshipId,
      companyInfo: this.props.companyInfo,
      pageNumber: 1,
      pageSize: 100,
      warehouseMasterId : 0,
      filters: {
        startDate: this.state.startDate,
        endDate: this.state.endDate,
      }
    };

    this.props.fetchAllInventoryValuationList(payload);
  }

  render() {
    return (
      <AllInventorySummaryListingComp {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
    )
  }
}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    allInventoryValuationList: state.inventoryValuation.allInventoryValuationList,
    listLoading: state.common.listLoading,
    permissions: state.auth.permissions,

  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchAllInventoryValuationList
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AllInventorySummaryListing);
