import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import StudentFormListComp from "../../../components/Student/studentFormList";
import { fetchPaginationDataIfNeeded } from "../../../utils";
import { getAllStudentForm, deleteRegistrationForm, updateFormStatus, 
  fetchRegistrationFormSettingDraftList, countSchoolFormSettingDraft, deleteRegistrationFormDraft } from "./action";
import { showModal, hideModal, pushModalToStack, popModalFromStack } from './../../../actions/commonActions';
import { fetchRegistrationFormSettingList } from '../../modal/modalBody/settings/RegisterFormList/action';
import { getSubdomain } from '../../modal/modalBody/settings/CompanyInfo/action';


class StudentFormList extends Component {
  constructor(props) {
    super(props);
    const linkProps = (this.props.location && this.props.location.state) || {};
    this.state = {
      templateType: linkProps.templateType,
      pageNumber: 1,
      pageSize: 25,
    };
  }

  componentDidMount() {
    const companyInfo = this.props.companyInfo || {};
    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      pageNumber: this.state.pageNumber,
      pageSize: this.state.pageSize,
      relationshipEmployeeId: 0
    };
    fetchPaginationDataIfNeeded("getAllStudentForm", "studentFormList", this.props, payload);
    // this.props.getSubdomain(payload);
    this.props.fetchRegistrationFormSettingDraftList(payload);
    this.props.countSchoolFormSettingDraft(payload);
  }
  componentWillReceiveProps(props) {
    // if (props.subdomain && props.subdomain.alpideSubdomainId && !this.state.subdomainName) {
    //   this.setState({
    //     subdomainName: props.subdomain.subdomainName
    //   });
    // }
    // if (props.studentFormList && props.studentFormList.length && props.userInfo) {
    //   let filterStudentFormList = [];
    //   (props.studentFormList || []).map(formObj => {
    //     let userPermissions = formObj.orgRegFormSettingPermissionList || [];
    //     userPermissions.forEach((permObj) => {
    //       if (permObj.relEmployeeId === props.userInfo.relationshipEmployeeId) {
    //         filterStudentFormList.push(formObj);
    //       }
    //     })
    //   });
    //   this.setState({
    //     filterStudentFormList
    //   });
    // }
  }

  render() {
    return <StudentFormListComp 
      {...this.props}
      {...this.state}
      updateState={(data) => {
        this.setState(data);
      }} 
    />;
  }
}
const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    relationshipId: state.common.companyInfo.relationshipId,
    userInfo: state.common.userInfo,
    studentFormList: state.membershipReducer.studentFormList,
    subdomain: state.common.subdomain,
    isLoading: state.common.isLoading,
    schoolFormDraftList: state.school.schoolFormDraftList,
    schoolFormDraftCount: state.school.schoolFormDraftCount,
    listLoading: state.common.listLoading,
    headerLoading: state.common.headerLoading,
    permissions: state.auth.permissions,

  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getAllStudentForm,
      showModal,
      hideModal,
      pushModalToStack,
      popModalFromStack,
      deleteRegistrationForm,
      fetchRegistrationFormSettingList,
      getSubdomain,
      updateFormStatus,
      fetchRegistrationFormSettingDraftList,
      countSchoolFormSettingDraft,
      deleteRegistrationFormDraft,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(StudentFormList);
