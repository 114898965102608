import React, { useMemo } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Skeleton, Popover, Menu, Pagination, Input, Empty } from 'antd';
import { InsertRowRightOutlined, MoreOutlined, UserOutlined } from '@ant-design/icons';
import PageBreadcrumb from "../../PageBreadcrumb";
import DropdownAction from "antd/lib/dropdown";
import { checkACLPermission, fetchPaginationDataIfNeeded, getPermissionData } from '../../../utils';
import find from 'lodash.find';
import { CONSTANTS, ICONS, MODULE_CODED_VALUES, PERMISSION_VALUES } from '../../../static/constants';
import { DownloadOutlined } from "@ant-design/icons";
import debounce from 'lodash.debounce';

import PageHeader from '../../Common/pageHeader';
const { Search } = Input;

const Deduction = (props) => {
  const { companyInfo, deductionList, pageNumber, pageSize , permissions} = props;
  const primaryPerm = (permissions && permissions.primary) || [];
  const permissionsData = useMemo(()=>{
    return getPermissionData(primaryPerm , MODULE_CODED_VALUES.HRMS_PAYROLL);
  },[])
 
  const breadCrumbList = [
    {
      name: "Dashboard",
    },
    {
      name: <FormattedMessage id='sidebar.menuItems.employee' />,
    },
    {
      name: "Deduction",
    }
  ];



  const showTotal = (total) => {
    return (
      props.intl.formatMessage({
        id: "pagination.text.total",
        defaultMessage: "",
      })
      +
      ` ${total} ` +
      props.intl.formatMessage({
        id: "pagination.text.items",
        defaultMessage: "",
      })
    );
  };
  const loadPageData = (pageNumber, pageSize) => {
    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      pageNumber: pageNumber,
      pageSize: pageSize,
    };
    fetchPaginationDataIfNeeded('getDeduction', 'deductionList', props, payload);

  };

  const handlePageSizeChange = (pageNumber, pageSize) => {
    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      pageNumber: pageNumber,
      pageSize: pageSize,
    };
    // props.resetPaginatedData();
    props.getDeduction(payload);
    props.updateState({ pageSize: pageSize, pageNumber: pageNumber });
  };

  const actionMenu = (e) => {
    return (
      <Menu className="row-action-dropdown">
        <Menu.Item key="0" value="title">
          Actions
        </Menu.Item>
        <Menu.Divider />
        {
          permissionsData?.update ?  <Menu.Item
          key="1"
          value="edit"
          onClick={() => {
            props.updateState({
              deductionDrawer: true,
              deductionData: e,
            })
          }}
        >
          <i className={ICONS.EDIT} /> Edit
        </Menu.Item>: ""
        }
       
{
  permissionsData?.delete ? <Menu.Item
  key="2"
  value="delete"
  onClick={() => {
    props.showModal({
      modalBody: "Are you sure want to delete?",
      handleSubmit: () => {
        props.deleteAllowance(e, props)
      },
    });
  }}
>
  <i className={ICONS.DELETE} /> Delete
</Menu.Item>: ""
}
        
      </Menu>
    );
  };

  const onSearchedInputChange = debounce((searchedText, props) => {
    handleSearchedText(searchedText, props);
  }, 100);

  const handleSearchedText = (searchedText, props) => {
    const payload = {
      relationshipId: props.companyInfo.relationshipId,
      pageNumber: 1,
      pageSize: props.pageSize,
      searchedText: searchedText,
    };
    if (searchedText.length) {
      if (
        searchedText.length >= 3
        &&
        !props.searchedText.toLowerCase().startsWith(searchedText.toLowerCase())
      ) {
        props.getTotalDeductionCount(payload);
        props.getDeduction(payload);
      }
    } else {
      props.getTotalDeductionCount(payload);
      props.getDeduction(payload);
    }

  };

  return (
    <>
    <PageHeader {...props}
        pageName="heading.team.payrollManagement.deduction"
        breadCrumbList={breadCrumbList}
        canCreate={true}
        onCreate={()=>{
          props.updateState({ deductionDrawer: true, deductionData: {} });

        }}
        moduleCode = {MODULE_CODED_VALUES.HRMS_PAYROLL}
      
        />
     

      <div className="view-container">

        <div className="view-container-actions">
          <Skeleton loading={props.headerLoading} paragraph={false} rows={1} active>
            <div className="left-actions">
              <div className="table-heading">
                <FormattedMessage
                  id="company.payrollMangement.deduction.table.heading"
                  defaultMessage=""
                />
              </div>
              {[]?.length ? (
                <>
                  <div className="vertical-sep" />
                  <div>{[]?.length}</div>
                </>
              ) : (
                ""
              )}
            </div>
            <div className="right-actions">
              <Search
                placeholder="Enter 3 characters Deduction name "
                value={props.searchedText}
                onChange={(e) => {
                  props.updateState({ searchedText: e.target.value, pageNumber: 1 });
                  onSearchedInputChange(e.target.value, props);
                }}
                onSearch={(searchedText) => {
                  props.updateState({ searchedText, pageNumber: 1 });
                  onSearchedInputChange(searchedText, props);
                  handleSearchedText(searchedText, props);
                }}
              />
              <DropdownAction
                //  overlay={downloadMenu()}
                trigger={["click"]}>
                <span>
                  <DownloadOutlined /> &nbsp;{" "}
                  <FormattedMessage
                    id="common.action.dropdown.downloadAs"
                    defaultMessage=""
                  />
                </span>
              </DropdownAction>

            </div>
          </Skeleton>
        </div>

        <div className="table-container">
          <table id="deduction-table">
            <Skeleton loading={props.listLoading || true} paragraph={{ rows: 17 }} active >
              <thead>
                <tr>
                  <th>
                    <FormattedMessage id="serial.no" defaultMessage=" " />
                  </th>

                  <th>
                    <FormattedMessage
                      id="company.payrollManagement.deductionName"
                      defaultMessage=" "
                    />
                  </th>


                  <th>
                    <FormattedMessage
                      id="company.payrollManagement.deductionType"
                      defaultMessage=" "
                    />
                  </th>


                  <th>
                    <FormattedMessage
                      id="company.payrollManagement.percentage"
                      defaultMessage=" "
                    />
                  </th>

                  <th>
                    <FormattedMessage
                      id="company.payrollManagement.limit"
                      defaultMessage=" "
                    />
                  </th>
{
   (permissionsData?.update || permissionsData?.delete) ?  <th className="text-center">
  <FormattedMessage
    id="actions.text"
    defaultMessage=" "
  />
</th>: ""
}
                 

                </tr>
              </thead>
              <tbody>
                {deductionList && deductionList[pageNumber]
                  ? deductionList[pageNumber]
                    // ?.sort((a, b) => (a.deductionId < b.deductionId ? 1 : -1))
                    .map((rowData, index) => {
                      return (
                        <tr key={index}>
                          <td>{(pageNumber - 1) * pageSize + (index + 1)}</td>

                          <td>
                            {rowData?.deductionName}
                          </td>

                          <td>
                            {rowData.deductionType}
                          </td>

                          <td>{rowData.deductionRate}</td>

                          <td>{rowData.limitAmount}</td>
{
   (permissionsData?.update || permissionsData?.delete) ?   <td className='text-center'>
  <DropdownAction

    overlay={actionMenu(rowData)}
    trigger={["click"]}
  >
    <i className={ICONS.MORE} />
  </DropdownAction>
</td>: ""
}
                        
                        </tr>
                      );
                    })
                  :
                  <tr key="empty-data-box">
                    <td colSpan={"100%"} style={{ borderBottom: "none" }}>
                      <Empty />
                    </td>
                  </tr>
                }
              </tbody>
            </Skeleton>
          </table>
        </div>

        <div className="footer">
          <Skeleton loading={props.headerLoading} paragraph={false} rows={1} active>
            <div className="f-left"></div>
            <div className="f-right">
              <Pagination
                size="small"
                total={props.deductionListCount}
                showTotal={showTotal}
                defaultPageSize={props.pageSize}
                showSizeChanger
                pageSizeOptions={[10, 25, 50, 100, 200]}
                // showQuickJumper
                onChange={(pageNumber, pageSize) => {
                  loadPageData(pageNumber, pageSize);
                  props.updateState({ pageSize: pageSize, pageNumber: pageNumber });
                }}
                onShowSizeChange={(pageNumber, pageSize) => {
                  handlePageSizeChange(pageNumber || 1, pageSize, props);
                }}
              />
            </div>
          </Skeleton>
        </div>
      </div>
    </>
  );
};


export default injectIntl(Deduction);
