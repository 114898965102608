import React, { useState } from "react";

import { Button, Col, Divider, Row, Skeleton, Tabs } from "antd";
import { FormattedMessage } from "react-intl";
import { getCurrencySymbol } from "../../../utils";
import { TextBox } from "../../general/TextBox";
import { Dropdown, DropdownRef } from "../../general/Dropdown";
import { LIST_CONSTANTS } from "../../../static/constants";

const {TabPane} = Tabs;



function LabelConfig(props) {

    const {labelConfig} = props;

    const [educationLabel, setEducationLabel] = useState(labelConfig.educationLabel || {});

    const handleTextBox = (field, value) =>{
        setEducationLabel(pre=>({
            ...pre,
            [field]: value
        }));
    }

    const handleSubmit = ()=>{

        const payload ={
            "eduType":labelConfig.eduType,
            "educationLabel":{
                "department": educationLabel?.department,
                "subject": educationLabel?.subject,
                "term": educationLabel?.term,
                "course": educationLabel?.course,
                "section": educationLabel?.section,
                "class": educationLabel?.class
            }
        }
        props.saveEducationLabelInfo(payload ,props)
    }
    const values="";
    return (
        <div className="account-setting">
<div className="store-model-container cus-space">
   
   <div className="store-main-container">
   

    <div>
    <div className="account-heading">
    <p className="heading">General Settings</p>
    <p className="sub-heading">
    Configure fundamental organization details, including company name, logo, and contact information.   </p>

    </div>
    <div className="account-information">
    <div className="popup-tabs">

            <Row gutter={[16, 16]}>
                <Col span={12}>
                    <div className="i-label">
                        Education Type
                    </div>
                    <Dropdown
                        items = {LIST_CONSTANTS.EDUCATION_TYPES}
                        placeholder="Education Type"
                        value={labelConfig.eduType || ''}
                        onSelect={(val) => {
                            setEducationLabel(pre=>({
                                ...pre,
                                eduType: val
                            }));
                        }}
                    />
                </Col>

                <Col span={12}>
                    <div className="i-label">
                        Class
                    </div>
                    <TextBox
                        value={educationLabel.class || ''}
                        maxLength={20}
                        countStyle={{top:"38px"}}
                        placeholder="e.g. Play Wing, Pre-Nursery…"
                        onChange={(e)=>handleTextBox('class', e.target.value)}
                    />
                </Col>

                <Col span={12}>
                    <div className="i-label">
                        Course
                    </div>
                    <TextBox
                        value={educationLabel.course || ''}
                        maxLength={20}
                        countStyle={{top:"38px"}}
                        placeholder="e.g. Play Wing, Pre-Nursery…"
                        onChange={(e)=>handleTextBox('course', e.target.value)}
                    />
                </Col>

                <Col span={12}>
                    <div className="i-label">
                        Department
                    </div>
                    <TextBox
                        value={educationLabel.department || ''}
                        maxLength={20}
                        countStyle={{top:"38px"}}
                        placeholder="eg. Department"
                        onChange={(e)=>handleTextBox('department', e.target.value)}
                    />
                </Col>

                <Col span={12}>
                    <div className="i-label">
                        Section
                    </div>
                    <TextBox
                        value={educationLabel.section || ''}
                        maxLength={20}
                        countStyle={{top:"38px"}}
                        placeholder="e.g. Mighty Kids, Rainbow etc.."
                        onChange={(e)=>handleTextBox('section', e.target.value)}
                    />
                </Col>

                <Col span={12}>
                    <div className="i-label">
                        Subject
                    </div>
                    <TextBox
                        value={educationLabel.subject || ''}
                        maxLength={20}
                        countStyle={{top:"38px"}}
                        placeholder="e.g. Hindi, English, GK etc.."
                        onChange={(e)=>handleTextBox('subject', e.target.value)}
                    />
                </Col>

                <Col span={12}>
                    <div className="i-label">
                        Term
                    </div>
                    <TextBox
                        value={educationLabel.term || ''}
                        maxLength={20}
                        countStyle={{top:"38px"}}
                        placeholder="e.g. 1 Year Session..."
                        onChange={(e)=>handleTextBox('term', e.target.value)}
                    />
                </Col>
            </Row>
            {props.isLabelsUpdated ? 
                <div className="required">
                    <i className="fi fi-rr-triangle-warning mr10" /> 
                    <span>
                        <FormattedMessage id="labelupdate.warning.message" defaultMessage="" />
                    </span>
                </div>
                :
                ''
            }
         
            {/* <div className="popup-btn">
                <Button>
                    Reset
                </Button>
                <Button
                    type="primary"
                     onClick={handleSubmit}
                >
                    <FormattedMessage id='button.save.label' defaultMessage='' />
                </Button>
            </div> */}
             <div className="">
     <Button type="primary" onClick={()=>{
      handleSubmit();
     }} >Save Changes</Button>
  </div>
            </div>
    


    </div>
   
    </div>
   </div>
   

   
   


</div>
        </div>
        
    )
}
export default LabelConfig;