import React, { useState } from "react";
import "../../../../eStore/storeSettings/store-settings.scss";
import { Button, Col, Row, Radio, Switch, Checkbox, Card, Upload } from "antd";
import { TextBox } from "../../../../general/TextBox";
import { getAWSFileURL, getPermissionData } from "../../../../../utils";
import storeDef from "../../../../../assets/images/store-def.jpg";
import { UploadOutlined, CloseOutlined } from "@ant-design/icons";
import { MODULE_CODED_VALUES } from "../../../../../static/constants";






const PopUpComp = (props) => {
  const {permissions} = props;
  const primaryPerm = (permissions && permissions.primary) || [];
  const permissionsData = getPermissionData(primaryPerm, MODULE_CODED_VALUES.E_STORE_SETTING);
  const onChange = (e) => {
    props.updateState({ popUpType: e.target.value })
  };

  const [fileList, setFileList] = useState([]);

  const image = getAWSFileURL(props.storeData?.headerLogo, props.companyInfo.bucketName, props?.storeData?.folderName)
  const handleRemove = () => {
    setFileList([]);
  };

  const handleChange = (info) => {
    if (info.file.name) {
      const payload = {
        relationshipId: props.relationshipId,
        file: info.file,
        bucketName: props?.companyInfo?.bucketName,
      };
      props.uploadPopUpIcon(payload);
    } else {
    }
  };
const handelSave = ()=>{
  let data = {
    siteVisitCode: props.siteVisitCode,
    googleTagManagerCode: props.googleTagManagerCode,
    metaPixelCode: props.metaPixelCode,

};

const payload = {
    relationshipId: props.relationshipId,
    isActive: 1,
    ecomSettingId: props.storeValues.ecomSettingId,
    analytics: JSON.stringify(data || "{}")
};
props.updatePopUpSetting(payload, props);
}

  return (
    <div className="store-model-container cus-space">
      <div className="store-main-container">
        <div className="model-head ">
          <Row>
            <Col span={23}>
              <p className="heading">Pop-up Mangement</p>
              <p className="sub-heading">
                Flexibility to set the type of Pop-up on first Screen.
              </p>
            </Col>

          </Row>

        </div>
        <div className="model-container">
          <Row style={{ marginTop: "10px" }} gutter={[16, 8]}>
            <Col span={12}><h2>Show Pop-up on Website</h2></Col>
            <Col span={12}> <Switch

              value={props.showModal}
              onChange={() => {
                props.updateState({ showModal: !(props.showModal) })
              }}
            /></Col>

          </Row>

          {
            props.showModal ? <div>
              <Row style={{ marginTop: "10px" }} gutter={[8, 8]} >
                <Col span={24}>
                  <h2>Types of Pop-up Form</h2>
                </Col>
                <Col span={24}> <Radio.Group
                  onChange={onChange}
                  value={
                    props.popUpType
                  }
                  style={{ width: "50%" }}
                >
                  <Row>
                    <Col span={8}>
                      <Radio
                        value={'Subscribe'}

                      >
                        Subscribe Pop-up
                      </Radio>
                    </Col>
                    <Col span={8}>
                      <Radio
                        value={'Image'}

                      >
                        Image Pop-up
                      </Radio>
                    </Col>
                    <Col span={8}>
                      <Radio
                        value={'Form'}

                      >
                        Lead form Pop-up

                      </Radio>
                    </Col>

                  </Row>


                </Radio.Group></Col>


              </Row>




              <Row style={{ marginTop: "10px" }} gutter={[16, 8]}>
                <Col span={12}><h2>Form Filled Required </h2></Col>
                <Col span={12}> <Switch

                  value={props.fieldsRequired}
                  onChange={() => {
                    props.updateState({ fieldsRequired: !(props.fieldsRequired) })
                  }}
                /></Col>

              </Row>

              <Row style={{ marginTop: "10px" }} gutter={[16, 8]}>
                <Col span={24}>
                  <h2>Show Popup For - Number of milliseconds</h2>
                </Col>
                <Col span={12}><Checkbox onChange={() => {
                  props.updateState({ timeDuration: !(props.timeDuration) })
                }}>Time Duration</Checkbox></Col>
                {
                  props.timeDuration ? (<Col span={3}>

                    <TextBox
                      placeholder="Enter Time Duration"
                      type="text"
                      maxLength={100}
                      value={props.timeValue}
                      onChange={(e) => {
                        props.updateState({ timeValue: e.target.value });
                      }}
                    /> </Col>) : ""
                }


              </Row>
              {
                props.popUpType === "Image" ? <Row style={{ marginTop: "10px" }} gutter={[16, 8]}>
                  <Col span="24">
                    <Card className="common-card">
                      <div className="inner-card-content">
                        <h5 className="card-heading">Pop up Image</h5>
                        <Row>
                          <Col span={12}>
                            {/* <h5 className="">Dark background logo</h5> */}
                            <div className="upload-profile-img">
                              {fileList.length > 0 ? (
                                <Card className="card-img-prev">
                                  <img src={URL.createObjectURL(fileList[0])} alt="Preview" />
                                  <Button className="close-btn-pre" onClick={handleRemove}>
                                    <CloseOutlined />
                                  </Button>
                                </Card>
                              ) : (
                                <Card
                                  className="card-img-prev"
                                  cover={<img src={image ? image : storeDef} alt="Placeholder" />}
                                />
                              )}
                              <Upload
                                fileList={fileList}
                                name="file"

                                onChange={handleChange}
                                beforeUpload={(file) => {
                                  setFileList([file]);
                                  return false;
                                }}
                              >
                                <Button icon={<UploadOutlined />}>Upload Image</Button>
                              </Upload>
                            </div>
                          </Col>

                        </Row>

                      </div>
                    </Card>
                  </Col>

                </Row> : ""
              }
              {
                props.popUpType === "Form" ? <Row style={{ marginTop: "10px" }} gutter={[16, 8]}>
                  <Col span={24}>
                    <div className="model-head">
                      <p className="heading">Lead form</p>
                      <p className="sub-heading">
                        Customers will be able to check out as guests without verifying their mobile number.
                      </p>
                    </div>
                  </Col>
                  <Col span={20}>
                    <div className="model-container">
                      <Row gutter={[16, 8]}>
                      <Col span={12}>
                        <span className="i-label">Full Name</span><span className="required">*</span>
                        <TextBox
                                  placeholder={"Full Name"}
                                  type="text"
                                  maxLength={20}
                                  disabled={true}
                                />
                        </Col>
                        <Col span={12}>
                        <span className="i-label">Email</span><span className="required">*</span>
                        <TextBox
                                  placeholder={"Email"}
                                  type="text"
                                  maxLength={20}
                                  disabled={true}
                                />
                        </Col>
                        <Col span={12}>
                        <span className="i-label">Mobile No.</span><span className="required">*</span>
                        <TextBox
                                  placeholder={"Mobile No."}
                                  type="text"
                                  maxLength={20}
                                  disabled={true}
                                />
                        </Col>
                      </Row>
                      <Row gutter={[16, 8]}>

                     

                        {
                          (props?.leadFormValues || [])?.map((item, i) => {
                            return (
                              <Col span={12} key={'form' + i}>
                                {
                                   (<div style={{ display: "flex", gap: "10px", justifyContent: "end" }}>

                                    <div className="cursor-pointer" onClick={() => {
                                      const data = {
                                        placeholder: item.placeholder,
                                        label: item.label,
                                        key: item.key,
                                        isRequired: item.required,
                                        index: i
                                      }
                                      const shippingVal = { ...props.additionalValue, }
                                      props.updateState({
                                        addNewField: true,
                                        data: data,
                                        leadFormValues: props.leadFormValues
                                      })
                                    }}>Edit</div>
                                    <div className="cursor-pointer" onClick={() => {
                                      let val = (props.leadFormValues || [])?.filter((data) => data.key !== item.key)

                                      props.updateState({
                                        leadFormValues: val
                                      })
                                      // props.updateAdditionalValue({
                                      //     additionalValues:JSON.stringify(val || []),
                                      //     relationshipId:props.userInfo.relationshipId
                                      // },props)

                                    }}>Delete</div>
                                  </div>) 
                                }
                                <span className="i-label">{item.label}</span>
                                {item?.isRequired ? (<span className="required">*</span>) : ""}
                                <TextBox
                                  placeholder={item.placeholder}
                                  type="text"
                                  maxLength={20}
                                  disabled={true}
                                />
                              </Col>
                            )
                          })
                        }

                      </Row>
                      <Row >
                        <Col style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }} span={24}>

                          <div>
                            <Button onClick={() => {
                              // AddLabel(props)
                              props.updateState({
                                addNewField: true,
                                data:{},
                                label:"",
                                placeholder:"",
                                isRequired:false,
                              })
                            }} type="primary">Add New</Button>
                          </div>
                        </Col>
                      </Row>

                    </div>
                  </Col>

                </Row> : ""
              }
            </div> : ""
          }


        </div>
      </div>
      {
        <div className="footer-btn">
          <Button 
                                disabled={!(permissionsData.update)}

          onClick={()=>{
            handelSave();
          }} type="primary" >
            Submit
          </Button>
        </div>
      }

    </div>
  );
};



export default PopUpComp;
