import React, { Fragment,useMemo } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
// import { AgGridReact } from "ag-grid-react";
import { getMomentDateForUIReadOnly, sortTable,getPermissionData } from "../../../utils";
import { Row, Col, Progress, Menu, Dropdown, Tooltip, Pagination,Skeleton, Empty } from "antd";
import PageBreadcrumb from "../../PageBreadcrumb";
import { CONSTANTS, ICONS, MODULE_CODED_VALUES } from "../../../static/constants";
import ReactHtmlParser from "react-html-parser";
import * as find from "lodash.find";
import { DropdownRef } from "../../general/Dropdown";
import CreateAssignment from "../../../containers/modal/modalBody/schoolManagement/CreateAssignment";
import PageHeader from "../../Common/pageHeader";
const format = CONSTANTS.TABLE_DATE_FORMAT;

const Assignment = (props) => {
  const {
    assignmentList,
    companyInfo,
    updateState,
    pageNumber,
    assignmentCount,
    pageSize,
    permissions,
    dir = 0,
  } = props;
  const breadCrumbList = [
    {name: <FormattedMessage id="sidebar.menuItem.school" defaultMessage="" />},
    {
      name: <FormattedMessage id="sidebar.menuItem.assessment" defaultMessage="" />,
    },
    {
      name: <FormattedMessage id="sidebar.menuItem.assignment" defaultMessage="" />,
    },
  ];
  const primaryPerm = (permissions && permissions.primary) || [];

  const permissionsData = useMemo(()=>{
    return getPermissionData(primaryPerm, MODULE_CODED_VALUES.SCHOOL_ASSIGNMENTS )
  },[])

  const showTotal = (total) => {
    return (
      props.intl.formatMessage({
        id: "pagination.text.total",
        defaultMessage: "",
      }) +
      ` ${total || 0} ` +
      props.intl.formatMessage({
        id: "pagination.text.items",
        defaultMessage: "",
      })
    );
  };

  
  const newAssignment = (assignment = {}) => {
    const modalData = {
      title: <div><div className="ant-modal-title">
        <FormattedMessage id="assignment.text" defaultMessage="" /></div>
        {/* <div className="modal-sub-title">{(props.selectedForm || {}).formShortName}</div> */}
      </div>,
      modalBody:
        <CreateAssignment
          {...props}
          assignment={assignment}
        />,
        onCancel: () => {
          props.showModal({
            modalBody: <FormattedMessage id="modal.cancel.confirmation" defaultMessage=""/>,
            footer:{submit:{intlId:"button.close.label"}},
            handleSubmit: () => {
              props.popModalFromStack();
            }
          })
        },
      hideFooter: true,
      width: '100%',
      height: 450,
      maskClosable: true,
      keyboard: false,
      wrapClassName: "new-transaction-wrapper",
    };
    props.pushModalToStack(modalData);
  };

  const openAssignmentRsponse = (assignment, props) => {
    props.history.replace({
      pathname: "/admin/school-management/assignment-response",
      state: {
        assignmentId: assignment.assignmentId,
        classId: assignment.classId,
      },
    });

  };
  const getQuestionCount = (assignment) => {
    let questionList = JSON.parse(assignment.questionList || "[]");
    return questionList.length;
  };

  const openLongtText = (title, Text) => {
    props.showModal({
      title: title,
      modalBody: (
        <div style={{ maxHeight: "550px", overflow: "auto" }}>
          {ReactHtmlParser(Text)}
        </div>
      ),
      hideFooter: true,
      width: "70%",
    });
  };

  const sortColumn = (e) => {
    let cellIndex = e.target.cellIndex;
    if (!cellIndex) {
      cellIndex = e.target.parentElement.cellIndex;
    }
    if (!cellIndex) {
      cellIndex = e.target.parentElement.parentElement.cellIndex;
    }
    sortTable(document.getElementById("student-table"), cellIndex, 1 - dir);
    updateState({ dir: 1 - dir });
  };

  const borderLeftStyle = {
    borderLeft: "1px solid #ddd"
  }

  const openShowFileList = (asmData) => {
    props.showModal({
      title: "Attachement",
      modalBody: (
        <div style={{ maxHeight: "550px", overflow: "auto" }}>
          {asmData.docDetailList && asmData.docDetailList.length ? (
            <Row>
              <Col span={24}>
                <table style={{ width: "100%", textAlign: "center" }}>
                  <thead>
                    <tr style={{ border: "1px solid #ddd" }}>
                      <th style={borderLeftStyle}>
                        File Name
                      </th>
                      <th style={borderLeftStyle}>Size</th>
                      <th style={borderLeftStyle}>Type</th>
                      <th style={borderLeftStyle}>Date</th>
                      <th style={borderLeftStyle}>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {asmData.docDetailList.map((doc, i) => {
                      return (
                        <tr
                          key={i}
                          style={{ border: "1px solid #ddd" }}
                          className="pt5"
                        >
                          <td style={borderLeftStyle}>
                            {doc.fileName}
                          </td>
                          <td style={borderLeftStyle}>
                            {doc.fileSize || ""}
                          </td>
                          <td style={borderLeftStyle}>
                            {doc.fileType || ""}
                          </td>
                          <td style={borderLeftStyle}>
                            {doc.dateCreated
                              ? getMomentDateForUIReadOnly({
                                  date: doc.dateCreated,
                                  format: CONSTANTS.DISPLAY_DATE_FORMAT,
                                })
                              : ""}
                          </td>
                          <td style={borderLeftStyle}>
                            <span
                              className={"cursor-pointer"}
                              onClick={() => {
                                props.downloadFileFromBucket({
                                  bucketName: doc.bucketName,
                                  awsKeyName: doc.awsKeyName,
                                  fileName: doc.fileName,
                                });
                              }}
                            >
                              Download
                            </span>{" "}
                            |
                            <span
                              style={{
                                cursor: "pointer",
                                color: "#888",
                                fontSize: "14px",
                                padding: "10px 0px",
                              }}
                              onClick={() => {
                                props.pushModalToStack({
                                  modalBody: "Are you sure you want to delete?",
                                  handleSubmit: () => {
                                    props.popModalFromStack();
                                    props.hideModal();
                                    props.deleteAssignmentDoc(doc, props);
                                  },
                                });
                              }}
                            >
                              {" "}
                              <i
                                className="fa fa-trash"
                                style={{ width: "20px" }}
                              ></i>
                            </span>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </Col>
            </Row>
          ) : (
            ""
          )}
        </div>
      ),
      hideFooter: true,
      width: "35%",
    });
  };

  const loadPageData = (pageNumber, pageSize) => {
    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      pageNumber: pageNumber,
      pageSize: pageSize,
    };
    props.getSchoolAssignment(payload);
  };

  const handlePageSizeChange = (pageNumber, pageSize, props) => {
    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      pageNumber: pageNumber,
      pageSize: pageSize,
    };
    props.resetPaginatedData();
    props.getSchoolAssignment(payload);
    props.updateState({ pageSize: pageSize, pageNumber: pageNumber });
  };


  const actionMenu = (e) =>{
    return <Menu className="row-action-dropdown">

            <Menu.Item key="0" value="title">
              Action
            </Menu.Item>

            <Menu.Divider />
           {permissionsData.read ? <Menu.Item 
              key="3"
              onClick={() => {
                openAssignmentRsponse(e, props);
              }}
            >
              <i className="fi fi-rr-eye" /> View Responses
            </Menu.Item> : "" }
           {permissionsData.create ? <Menu.Item 
              key="1"
              onClick={() => {
                props.updateState({
                  emailDetailDrawerVisible: true,
                  isSendAssignment: true,
                  isBulkInvoice: true,
                  assignmentId: e.assignmentId,
                  classId: e.classId,
                  docName: "Class Assignment",
                  assignmentData: e,
                });
              }}
            >
              <i className="fi fi-rr-envelope" /> Email
            </Menu.Item> : "" }
            {permissionsData.update ? <Menu.Item 
              key="2"
              onClick={() => {
                newAssignment(e, props);
                
              }}
            >
              <i className="fi fi-rr-edit" /> Edit
            </Menu.Item> : "" }
            {permissionsData.delete ?   <Menu.Item 
              key="3"
              onClick={() => {
                props.pushModalToStack({
                  modalBody:
                    "Are you sure you want to delete?",
                  handleSubmit: () => {
                    props.popModalFromStack();
                    props.hideModal();
                    props.deleteAssignment(e, props);
                  },
                });
              }}
            >
              <i class="fi fi-rr-trash" /> Delete
            </Menu.Item> : "" }
          </Menu>
  }

  return (
    <Fragment>
     
      <PageHeader {...props}
        pageName='sidebar.menuItem.assessment'
        breadCrumbList={breadCrumbList}
        canCreate={true}
        // newAssignment({}, props);
        onCreate={newAssignment}
        moduleCode = {MODULE_CODED_VALUES.SCHOOL_ASSIGNMENTS}
      
        />
      <div className="view-container">
        <div className="view-container-actions">
        <Skeleton loading={props.headerLoading} paragraph={false} active>

          <div className="left-actions">
            <div className="table-heading">
              <FormattedMessage
                id="heading.schoolManagement.assignment.table"
                defaultMessage=""
              />
            </div>
            <div className="vertical-sep" />
            {
              assignmentCount ? 
               <>
               
               <div>{assignmentCount}</div>
             
               </>
                
              : 0
            }
          </div>
          <div className="right-action">
            <DropdownRef
              style={{ width: "200px" }}
              items={props.classList[1] || []}
              valueKeyName="className"
              optionKeyName="classId"
              className={
                props.submittedOnce && !props.classId ? "input-text-error" : ""
              }
              value={(props.selectedClass || {}).className}
              placeholder="Filter by class"
              onSelect={(optionValue, option) => {
                let selectedClass =
                  find(props.classList[1] || [], { classId: option.value }) ||
                  {};
                props.updateState({
                  selectedClass: selectedClass,
                });
                const payload = {
                  relationshipId: companyInfo.relationshipId,
                  relationshipEmployeeId: (props.userInfo || {})
                    .relationshipEmployeeId,
                  pageNumber: 1,
                  pageSize: 200,
                  classId: option.value,
                };
                props.getSchoolAssignment(payload);
                props.getSchoolAssignmentCount(payload);
              }}
              allowClear={true}
              onClear={() => {
                props.updateState({
                  selectedClass: null,
                });
                const payload = {
                  relationshipId: companyInfo.relationshipId,
                  relationshipEmployeeId: (props.userInfo || {})
                    .relationshipEmployeeId,
                  pageNumber: 1,
                  pageSize: 200,
                  classId: 0,
                };
                props.getSchoolAssignment(payload);
                props.getSchoolAssignmentCount(payload);
              }}
            />
          </div>
       </Skeleton>
          {/* <button className="normal-btn" onClick={() => {
              newAssignment({}, props);
            }}>
              <FormattedMessage id="button.create.label" defaultMessage="" />
            </button>
          <span style={{ fontSize: '20px', marginLeft: '10px', cursor: 'pointer' }} onClick={() => {
              newAssisgnment({}, props);
            }}>
                <FormattedMessage id="newAssignment.text" defaultMessage="" />
            </span>
          <span className="action-dropdown-btn right-align" style={{ display: 'inline', float: 'right' }}>
            </span> */}
        </div>


      {false &&  <Row>
            {(assignmentList[pageNumber] || []).map((e, i) => {
            return (
              <Col xs={24} md={6} key={"asss" + i}>
                <div className="asstmarl" style={{ position: "relative" }}>
                  <div className="actopn-circle">
                    <Dropdown
                      overlay={
                        <Menu>
                          <Menu.Item key="1">
                            <span
                              style={{
                                cursor: "pointer",
                                color: "#888",
                                fontSize: "14px",
                                padding: "10px 0px",
                              }}
                              onClick={() => {
                                props.updateState({
                                  emailDetailDrawerVisible: true,
                                  isSendAssignment: true,
                                  isBulkInvoice: true,
                                  assignmentId: e.assignmentId,
                                  classId: e.classId,
                                  docName: "Class Assignment",
                                  assignmentData: e,
                                });
                              }}
                            >
                              <i
                                className="fa fa-envelope"
                                style={{ width: "20px" }}
                              ></i>
                              Email
                            </span>{" "}
                          </Menu.Item>
                          <Menu.Item key="2">
                            <span
                              style={{
                                cursor: "pointer",
                                color: "#888",
                                fontSize: "14px",
                                padding: "10px 0px",
                              }}
                              onClick={() => {
                                newAssignment(e, props);
                              }}
                            >
                              <i
                                className="fa fa-pencil"
                                style={{ width: "20px" }}
                              ></i>
                              Edit
                            </span>{" "}
                          </Menu.Item>
                          <Menu.Item key="3">
                            <span
                              style={{
                                cursor: "pointer",
                                color: "#888",
                                fontSize: "14px",
                                padding: "10px 0px",
                              }}
                              onClick={() => {
                                props.pushModalToStack({
                                  modalBody: "Are you sure you want to delete?",
                                  handleSubmit: () => {
                                    props.popModalFromStack();
                                    props.hideModal();
                                    props.deleteAssignment(e, props);
                                  },
                                });
                              }}
                            >
                              <i
                                className="fa fa-trash"
                                style={{ width: "20px" }}
                              ></i>
                              Delete
                            </span>
                          </Menu.Item>
                        </Menu>
                      }
                      trigger={["hover"]}
                    >
                      {/* <a onClick={(e) => e.preventDefault()}>
                            <Space>
                              <i className="fa fa-ellipsis-v" aria-hidden="true"></i>

                            </Space>
                            

                          </a> */}
                      <div className="cirtb">
                        <i
                          className="fa fa-ellipsis-v"
                          style={{ fontSize: "18px" }}
                        />
                      </div>
                    </Dropdown>
                  </div>

                  <div className="texthert">
                    <p> {e.assignmentName || ""}</p>
                  </div>
                  <div className="bor"></div>
                  {e.assignmentDescription ? (
                    <p className="liguy-max">
                      {ReactHtmlParser(e.assignmentDescription || "")}
                    </p>
                  ) : (
                    ""
                  )}
                  {e.assignmentDescription ? (
                    <span
                      className="more-details-btn"
                      onClick={() => {
                        openLongtText("Description", e.assignmentDescription);
                      }}
                    >
                      Show More
                    </span>
                  ) : (
                    ""
                  )}
                  <div className="assignment-details">
                    <Row>
                      <Col span={12}>Class</Col>
                      <Col span={12} className="leftt">
                        {e.className}
                      </Col>
                    </Row>

                    <Row>
                      <Col span={12}>Group</Col>
                      <Col span={12} className="leftt">
                        {e.assignmentGroupName}
                      </Col>
                    </Row>
                    <Row>
                      <Col span={12}>Questions</Col>
                      <Col span={12} className="leftt">
                        {" "}
                        {getQuestionCount(e) > 1
                          ? getQuestionCount(e) + " Questions"
                          : getQuestionCount(e) + " Question"}
                      </Col>
                    </Row>
                    <Row>
                      <Col span={12}>Due Date</Col>
                      <Col span={12} className="leftt">
                        {e.dueDate &&
                          getMomentDateForUIReadOnly({
                            date: e.dueDate,
                            format: format,
                          })}
                      </Col>
                    </Row>
                    {/* <Row>
                      <Col span={12} >Status</Col>
                      <Col span={12} className='leftt'><button className="submitt-but"> Submitted</button></Col>
                    </Row>
                    <Row>
                      <Col span={12} >Submission Date </Col>
                      <Col span={12} className='leftt'>{e.startDate && getMomentDateForUIReadOnly({date: e.startDate, format: format})}</Col>
                    </Row> */}

                    <Row>
                      <Col span={12}>Grade </Col>
                      <Col span={12} className="leftt">
                        {e.gradingSystem}
                      </Col>
                    </Row>
                    {e.gradingSystem === "Points" ? (
                      <Row>
                        <Col span={12}>Points </Col>
                        <Col span={12} className="leftt">
                          {e.assignmentPoints}
                        </Col>
                      </Row>
                    ) : (
                      ""
                    )}
                    {e.docDetailList && e.docDetailList.length ? (
                      <div>
                        <Row>
                          <Col span={12}>Attachemnt</Col>
                          <Col
                            span={12}
                            className="leftt cursor-pointer"
                            onClick={() => {
                              openShowFileList(e);
                            }}
                          >
                            {e.docDetailList.length}{" "}
                            <span>
                              {e.docDetailList.length > 1 ? " Files " : " File"}
                            </span>
                          </Col>
                        </Row>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  {/* <Row>
                       <Col span={12} ><span  style={{fontSize:'13px'}}> Comment <span className="pointer-cu" style={{ color:'#3c0aff'}}>Show more</span></span>  </Col>
                      <Col span={12} className='leftt'><span className="pointer-cu" style={{fontSize:'13px'}}> Show comment here  </span></Col>
                                 
                    </Row> */}

                  {/* <div className="texthert1"><p> Group Name - {e.assignmentGroupName}</p></div> */}
                  {/* <div className="texthert1"><p> Class Name - {e.className}</p></div>
                   {(e.docDetailList || []).length > 0 ? <p>Attachment - <span style={{ cursor: 'pointer', color: '#1a73e8' }}>{(e.docDetailList || []).length} file attached  </span></p> : ''} */}

                  {/* <p>This Assignment has  {getQuestionCount(e) > 1 ? getQuestionCount(e)+ ' Questions': getQuestionCount(e)+ ' Question'}</p> */}
                  {/* <p>Grading System - {e.gradingSystem}</p> */}

                  {/* <div className="leftsl"><span style={{ color: '#888' }}> Start Date</span></div> */}
                  {/* <div className="leftsl"><span style={{ color: '#888' }}> Due Date</span></div> */}
                  {/* <div className="leftsl"><span style={{ cursor: 'pointer',  }}>{e.startDate && getMomentDateForUIReadOnly({date: e.startDate, format: format})}</span></div> */}
                  {/* <div className="leftsl"><span style={{ cursor: 'pointer',  }}> {e.dueDate && getMomentDateForUIReadOnly({date: e.dueDate, format: format})}</span></div> */}
                  {/* <p><span style={{ paddingTop: '25px', display: 'block' }}> Progress</span></p> */}
                  <div className="leftsl">
                    <Tooltip
                      title={`${e.countSubmission || 0} / ${
                        e.countStudentByClassId || 0
                      }`}
                    >
                      <Progress
                        percent={Number(
                          ((e.countSubmission || 0) * 100) /
                            (e.countStudentByClassId || 1)
                        ).toFixed(2)}
                      />
                    </Tooltip>
                  </div>
                  {/* <p><span style={{ color: '#888', paddingTop: '15px', display: 'block' }}>Students</span></p> */}
                  <div
                    style={{
                      float: "right",
                      width: "50%",
                      lineHeight: "28px",
                      paddingLeft: "7px",
                    }}
                  >
                    <p
                      className="more-details-btn"
                      onClick={() => {
                        openAssignmentRsponse(e, props);
                      }}
                    >
                      {" "}
                      {e.countSubmission || 0}/{e.countStudentByClassId || 0}{" "}
                      {e.countStudentByClassId > 1 ? "Students" : "Student"}
                    </p>
                  </div>
                  {/* <div >
                      <div className="bor"></div>
                      <button className="ant-btn ant-btn-primary" style={{ textAlign:'center', margin:'0px auto', display:'table', cursor:'pointer'}}>Submit Assignment</button>
                      <span style={{ cursor: 'pointer', color: '#888', fontSize: '22px', padding: '10px' }}> <i className="fa fa-envelope" ></i></span> 
                      <span style={{ cursor: 'pointer', color: '#888', fontSize: '22px', padding: '10px' }}> <i className="fa fa-pencil" ></i></span> 
                      <span style={{ cursor: 'pointer', color: '#888', fontSize: '22px', padding: '10px' }}> <i className="fa fa-trash" ></i></span>
                    </div> */}
                </div>
              </Col>
            );
          })} 
          {/*  <Col xs={24} md={6}>
               <div className='asstmarl'>
                 <h3 style={{ textAlign: 'center' }}>Subtraction Word Problem</h3>
                 <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. </p>
                 <h4>Attachment - <span style={{ cursor: 'pointer', color: '#1a73e8' }}><b>2 files attached </b> </span></h4>

                 <p><b> 7 Questions | Grade - Pass or Fail </b></p>
                 <div className="bor"></div>
                 <h3>Group Name - Math</h3>
                 <div className="leftsl"><b style={{ color: '#888' }}> Start Date</b></div>
                 <div className="leftsl"><b style={{ color: '#888' }}> End Date</b></div>
                 <div className="leftsl"><b style={{ cursor: 'pointer', color: '#1a73e8' }}> 08/10/22</b></div>
                 <div className="leftsl"><b style={{ cursor: 'pointer', color: '#1a73e8' }}> 08/10/22</b></div>
                 <p><b style={{ paddingTop: '25px', display: 'block' }}> Progress</b></p>
                 <Progress percent={30} />
                 <p><b style={{ color: '#888', paddingTop: '15px', display: 'block' }}>Students</b></p>
                 <div className="leftsl"><b style={{ cursor: 'pointer', color: '#1a73e8', fontSize: '20px' }}> 22 Students</b></div>
                 <div className="leftsl"><span style={{ cursor: 'pointer', color: '#888', fontSize: '22px', padding: '10px' }}> <i className="fa fa-envelope" ></i>
                 </span> <span style={{ cursor: 'pointer', color: '#888', fontSize: '22px', padding: '10px' }}> <i className="fa fa-pencil" ></i>
                   </span> <span style={{ cursor: 'pointer', color: '#888', fontSize: '22px', padding: '10px' }}> <i className="fa fa-trash" ></i>
                   </span></div>
               </div>
             </Col> */}
        </Row> }
        <Skeleton loading={props.listLoading} paragraph={{ rows: 16 }} active>

        <div className="table-container">
          <table id="student-table">
            <thead>
              <tr>
                <th width="5%">S.No.</th>
                <th onClick={sortColumn}>
                  Assignment Name <i className={ICONS.SORT} />
                </th>
               
                <th onClick={sortColumn}>
                  Description <i className={ICONS.SORT} />
                </th>
          
                <th onClick={sortColumn}>
                  Grade System <i className={ICONS.SORT} />
                </th>
              
                <th onClick={sortColumn}>
                  Submitted <i className={ICONS.SORT} />
                </th>
            
                <th onClick={sortColumn}>
                  Create Dated <i className={ICONS.SORT} />
                </th>
            
                <th onClick={sortColumn}>
                  Due Date <i className={ICONS.SORT} />
                </th>
            
                {permissionsData.read || permissionsData.create || permissionsData.delete || permissionsData.update ?   <th width={"6%"} className="text-center">
                  Action
                </th> : "" }
              </tr>
            </thead>
            <tbody>
              {
              (assignmentList[pageNumber] || [])?.length ?
              (assignmentList[pageNumber] || []).map((e, i) => {
                return (
                  <tr key={"std" + i}>
                    <td>{(pageNumber-1)*pageSize+(i+1)}</td>
                    <td>{e.assignmentName ||<div className="empty-data-box"/>}</td>
                   {
                    <td style={{maxWidth  : "170px"}}>{e.assignmentDescription ? ReactHtmlParser(e.assignmentDescription || "-") : <div className="empty-data-box"/>}</td>
                   }
                    <td>{e.gradingSystem || <div className="empty-data-box"/>}</td>
                    <td>
                      <Tooltip
                        title={`${e.countSubmission || 0} / ${
                          e.countStudentByClassId || 0
                        }`}
                      >
                        <Progress
                          percent={Number(
                            ((e.countSubmission || 0) * 100) /
                              (e.countStudentByClassId || 1)
                          ).toFixed(2)}
                        />
                      </Tooltip>
                      <div>
                        {e.countSubmission || 0}/{e.countStudentByClassId || 0}
                        {e.countStudentByClassId > 1
                          ? " Students"
                          : " Student"}
                      </div>
                    </td>
                    <td>
                      {e.dateCreated ?
                        getMomentDateForUIReadOnly({
                          date: e.dateCreated,
                          format: format,
                        }):
                        <div className="empty-data-box"/>}
                    </td>
                    <td>
                      {e.dueDate ?
                        getMomentDateForUIReadOnly({
                          date: e.dueDate,
                          format: format,
                        }):<div className="empty-data-box"/>}
                    </td>
                    {permissionsData.read || permissionsData.create || permissionsData.delete || permissionsData.update ?   <td className="text-center">
                      <Dropdown
                        overlay={actionMenu(e)}
                        trigger={["click"]}
                      >
                        <i className={ICONS.MORE_P} />
                      </Dropdown>
                    </td> : "" }
                  </tr>
                );
              }) : ( 
                <tr key="empty">
                  <td colSpan={"100%"}>
                    <Empty />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        </Skeleton>
        <div className="footer">
        <Skeleton loading={props.headerLoading} paragraph={false} active>

          <div className="f-left"></div>
          <div className="f-right">
            <Pagination
              size="small"
              total={assignmentCount}
              showTotal={showTotal}
              defaultPageSize={props.pageSize}
              showSizeChanger
              pageSizeOptions={[10, 25, 50, 100, 200]}
              onChange={(pageNumber, pageSize) => {
                loadPageData(pageNumber, pageSize);
                updateState({ pageSize: pageSize, pageNumber: pageNumber });
              }}
              onShowSizeChange={(pageNumber, pageSize) => {
                handlePageSizeChange(pageNumber || 1, pageSize, props);
              }}
            />
          </div>
        </Skeleton>
        </div>
      </div>
    </Fragment>
  );
};

export default injectIntl(Assignment);
