import React, { Fragment, useMemo } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { Pagination, Menu, Skeleton, Dropdown, Switch, Empty } from "antd";
import { fetchPaginationDataIfNeeded, sortTable, getMomentDateForUIReadOnly, getUserDefinedName, getPermissionData } from "../../../utils";
import PageBreadcrumb from "../../PageBreadcrumb";
import { FilterOutlined } from '@ant-design/icons';
import { CONSTANTS, ICONS, MODULE_CODED_VALUES } from "../../../static/constants";
import cloneDeep from 'lodash/cloneDeep';
import PageHeader from "../../Common/pageHeader";


const School = (props) => {
  const { classList, updateState, companyInfo, classCount, pageNumber,permissions, dir=0  } = props;
  classList[pageNumber].sort((a, b)=> a.dateCreated < b.dateCreated ? 1 : -1);

  const breadCrumbList = [
    {
      name: <FormattedMessage id='sidebar.menuItem.school' defaultMessage='' />
    },
    {
      name: getUserDefinedName('sidebar.menuItem.class',props),
    },
    {
      name: getUserDefinedName('sidebar.menuItem.classInfo',props),
    },
  ];
  const primaryPerm = (permissions && permissions.primary) || [];

  const permissionsData = useMemo(()=>{
    return getPermissionData(primaryPerm, MODULE_CODED_VALUES.SCHOOL_CLASS_DETAIL )
  },[])
  const showTotal = (total) => {
    return (
      props.intl.formatMessage({
        id: "pagination.text.total",
        defaultMessage: "",
      }) +
      ` ${total || 0} ` +
      props.intl.formatMessage({
        id: "pagination.text.items",
        defaultMessage: "",
      })
    );
  };

  const loadPageData = (pageNumber, pageSize) => {
    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      pageNumber: pageNumber,
      pageSize: pageSize,
    };
    fetchPaginationDataIfNeeded("getClass", "classList", props, payload);
  };

  const handlePageSizeChange = (pageNumber, pageSize, props) => {
    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      pageNumber: pageNumber,
      pageSize: pageSize,
    };
    props.resetPaginatedData();
    props.getClass(payload);
    props.updateState({ pageSize: pageSize, pageNumber: pageNumber });
  };

  const newSchool = (classData) => {
    props.updateHeaderState({
      schoolDrawers: {
        ...props.schoolDrawers,
        addClassDrawerVisible: true,
      },
      classData: cloneDeep(classData)
    });
  };

  const sortColumn = (e) => {
    sortTable(document.getElementById('class-table'), e.target.cellIndex, 1 - dir);
    updateState({ dir: 1 - dir })
  }

  // const newAssignment = (classData) => {
  //   const modalData = {
  //     title: <div className='ant-modal-title'><FormattedMessage id='newAssignment.text' defaultMessage='' /></div>,
  //     modalBody: <CreateAssignment  {...props} classData={classData} style={{ overflow: 'auto' }} />,
  //     hideFooter: true,
  //     width: 1100,
  //     height: 450,
  //     maskClosable: true,
  //     keyboard: false,
  //      wrapClassName: "newAssignment-new"
  //   };
  //   props.pushModalToStack(modalData);
  // };

  const actionMenu = (e) => {
    return (
        <Menu className="row-action-dropdown">
            <Menu.Item key="0" value="title">
              Action
            </Menu.Item>

            <Menu.Divider />

         {permissionsData.update ?  <Menu.Item key="1" value="edit" onClick={()=>{
              newSchool(e);
              }}
            >
              <i className={ICONS.EDIT} />
              Edit
            </Menu.Item> : "" }

            {permissionsData.delete ? <Menu.Item key="2" onClick={()=>
              {
                props.showModal({
                  modalBody: "Are you sure you want to delete?",
                  handleSubmit: () => {
                    props.deleteClass(e, props);
                  }
                })
              }
            }>
              <i className={ICONS.DELETE}/>
              Delete
            </Menu.Item> : "" }
        </Menu >
    )
  };

  return (
    <Fragment>
  
      <PageHeader {...props}
        pageName={getUserDefinedName('sidebar.menuItem.class',props)}
        breadCrumbList={breadCrumbList}
        canCreate={true}
        onCreate={newSchool}
        moduleCode = {MODULE_CODED_VALUES.SCHOOL_CLASS_DETAIL}
      
        />
      <div className='view-container'>

        <div className="view-container-actions">
              <Skeleton loading={props.headerLoading} paragraph={false} active>
          <div className="left-actions">
            <div className="table-heading">
              {getUserDefinedName('schoolManagement.class.table.heading',props)}
            </div>
            <div className="vertical-sep" />
            {
              classCount ? <>
                
                <div>{ classCount}</div>
              </>:0
            }
          </div>
          <div className="right-actions">
          <button
              className="action-btn"
              onClick={() => {
                props.updateState({
                  classListFilterDrawerVisible: true,
                });
              }}
              >
              <FilterOutlined /> &nbsp;
              <FormattedMessage id="filter.text" defaultMessage="" />
            </button>
          </div>
          </Skeleton>
        </div>
        <Skeleton loading={props.listLoading} paragraph={{ rows: 13 }} active>
          <div className="table-container">
            <table id="class-table">
              <thead>
                <tr>
                  <th><FormattedMessage id="serial.no" defaultMessage=""/></th>
                  <th onClick={sortColumn}> {getUserDefinedName('schoolManagement.class.name',props)} <i class="fi fi-rr-sort-alt"></i></th>
                  <th onClick={sortColumn}> {getUserDefinedName('schoolManagement.class.code',props)} <i class="fi fi-rr-sort-alt"></i></th>
                  <th onClick={sortColumn}>{getUserDefinedName('schoolManagement.class.section',props)} <i class="fi fi-rr-sort-alt"></i></th>
                  <th onClick={sortColumn}><FormattedMessage id="schoolManagement.class.teacher" defaultMessage="" /> <i class="fi fi-rr-sort-alt"></i></th>
                  <th onClick={sortColumn}>{getUserDefinedName('schoolManagement.class.subject',props)} <i class="fi fi-rr-sort-alt"></i></th>
                  <th onClick={sortColumn}><FormattedMessage id="studentAllocation.text" defaultMessage="" /> <i class="fi fi-rr-sort-alt"></i></th>
                  <th onClick={sortColumn}><FormattedMessage id="common.dateCreated" defaultMessage=""/> <i class="fi fi-rr-sort-alt"></i></th>
                  {/* <th onClick={sortColumn}><FormattedMessage id="addItem.text.userStatus" defaultMessage=""/> <i class="fi fi-rr-sort-alt"></i></th> */}
                  {permissionsData.delete || permissionsData.update ? <th  className="text-center" ><FormattedMessage id='common.listing.header.action' defaultMessage=""/></th> : ""}
                </tr>
              </thead>
              <tbody>
                {

(classList[pageNumber] || [])?.length ?  (classList[pageNumber] || []).map((e, i) => {
  return <tr key={i}>
    <td>{(pageNumber-1)*props.pageSize+(i+1)}</td>
    <td>{e.className||<div className="empty-data-box"/>}</td>
    <td>{e.classCode||<div className="empty-data-box"/>}</td>
    <td>{(e.classSectionList || []).length === 1 && e.classSectionList[0].sectionName === e.className ? <div className="empty-data-box"/> : ((e.classSectionList || []).length ? e.classSectionList.map(obj=> obj.sectionName).join(', '):<div className="empty-data-box"/>)}</td>
    <td>{e.teachersName ? e.teachersName.replaceAll(',', ', ') : <div className="empty-data-box"/>}</td>
    <td>
      {e.subjectList && e.subjectList.length ? e.subjectList.map(obj =>  obj.subjectName).join(', ') : <div className="empty-data-box"/>}
    </td>
    <td>{(e.studentCountByClass|| 0)} of {e.classCapacity || 'Unlimited'} Seats</td>
    <td>{e.dateCreated?getMomentDateForUIReadOnly({date: e.dateCreated, format: CONSTANTS.TABLE_DATE_FORMAT}):<div className="empty-data-box"/>}</td>
    {/* <td>
      <div className="status-toggle">
        <Switch defaultChecked/>
      </div>
      </td> */}
      {permissionsData.delete || permissionsData.update ? <td className="text-center"> 
      <Dropdown overlay={actionMenu(e)} trigger={['click']}>
        <i className={ICONS.MORE} />
      </Dropdown>
    </td> : "" }
  </tr>
}) :(<tr key="empty">
  <td colSpan={"100%"}>
    <Empty />
  </td>
</tr>)
                
                }
              </tbody>
            </table>
          </div>
        </Skeleton>
        <div className="footer">
              <Skeleton loading={props.headerLoading} paragraph={false} active>

          <div className="f-left"></div>
          <div className="f-right">
            <Pagination
              size="small"
              total={classCount}
              showTotal={showTotal}
              defaultPageSize={props.pageSize}
              showSizeChanger
              pageSizeOptions={[10, 25, 50, 100, 200]}
              // showQuickJumper
              onChange={(pageNumber, pageSize) => {
                loadPageData(pageNumber, pageSize);
                updateState({ pageSize: pageSize, pageNumber: pageNumber });
              }}
              onShowSizeChange={(pageNumber, pageSize) => {
                handlePageSizeChange(pageNumber || 1, pageSize, props);
              }}
            />
          </div>
          </Skeleton>
        </div>
      </div>
    </Fragment>
  );
};

export default injectIntl(School);
