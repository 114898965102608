import React, { useMemo } from "react";
import { ICONS, MODULE_CODED_VALUES, PERMISSION_VALUES } from "../../../static/constants";
import { FormattedMessage, injectIntl } from "react-intl";
import { Switch, Menu, Button, Empty, Pagination, Skeleton } from "antd";
import DropdownAction from "antd/lib/dropdown";
import { checkACLPermission, fetchPaginationDataIfNeeded, getPermissionData } from "../../../utils";

function EmployeeTypeComp(props) {
    const { companyInfo, employeeTypeList, pageNumber, pageSize,permissions } = props
    const primaryPerm = (permissions && permissions.primary) || [];
    const permissionsData = useMemo(()=>{
        return getPermissionData(primaryPerm , MODULE_CODED_VALUES.COMPANY_HIERARCY );
      },[])
    

    const showTotal = (total) => {
        return (
            props.intl.formatMessage({
                id: "pagination.text.total",
                defaultMessage: "",
            })
            +
            ` ${total} ` +
            props.intl.formatMessage({
                id: "pagination.text.items",
                defaultMessage: "",
            })
        );
    };
    const loadPageData = (pageNumber, pageSize) => {
        const payload = {
            companyInfo,
            relationshipId: companyInfo.relationshipId,
            pageNumber: pageNumber,
            pageSize: pageSize,
        };
        fetchPaginationDataIfNeeded('getEmployeeType', 'employeeTypeList', props, payload);

    };

    const handlePageSizeChange = (pageNumber, pageSize) => {
        const payload = {
            companyInfo,
            relationshipId: companyInfo.relationshipId,
            pageNumber: pageNumber,
            pageSize: pageSize,
        };
        // props.resetPaginatedData();
        props.getEmployeeType(payload);
        props.updateState({ pageSize: pageSize, pageNumber: pageNumber });
    };
    const actionMenu = (e) => {
        return (
            <Menu className="row-action-dropdown">
                <Menu.Item key="0" value="title">
                    Actions
                </Menu.Item>
                <Menu.Divider />
{
    permissionsData?.update ?  <Menu.Item
    key="1"
    value="edit"
    onClick={() => {
        props.updateState({
            employeeTypeDrawer: true,
            empTypeItem: e,
        })
    }}
>
    <i className={ICONS.EDIT} /> Edit
</Menu.Item>:""
}
               {
                permissionsData?.delete ?  <Menu.Item
                key="2"
                value="delete"
                onClick={() => {
                    props.showModal({
                        modalBody: "Are you sure want to delete?",
                        handleSubmit: () => {
                            props.deleteEmployeeType(e, props)
                        },
                    });
                }}
            >
                <i className={ICONS.DELETE} /> Delete
            </Menu.Item>:""
               }

               
            </Menu>
        );
    }
    return (
        <><div className="view-container">
            <div className="view-container-actions">
                <Skeleton loading={props.headerLoading} paragraph={false} rows={1} active>

                    <div className="left-actions">
                        <div className="table-heading">
                            <FormattedMessage
                                id="employeeMeta.employeeType.pageHeading"
                                defaultMessage="" />
                            <div className="table-sub-heading">
                                Manage Employee Type meta here
                            </div>
                        </div>
                    </div>

                    <div className="right-actions">
                        {
                            permissionsData?.create ? <Button
                            style={{ backgroundColor: "#1cb961", color: "#ffffff" }}
                            className="create-action-btn"
                            onClick={() => {
                                props.updateState({ employeeTypeDrawer: true, empTypeItem: {} });
                            }}
                        >
                            <i className={ICONS.ADD} style={{ margin: "4px 5px 0px 0px" }} />{" "}
                            <FormattedMessage id="button.create.label" defaultMessage="" />
                        </Button>:""
                        }
                        
                    </div>
                </Skeleton>
            </div>

            <div className="table-container">
                <Skeleton loading={props.listLoading} paragraph={{ rows: 18 }} active>
                    <table id="employeeType-table">
                        <thead>
                            <tr>
                                <th>
                                    <FormattedMessage id="serial.no" defaultMessage=" " />
                                </th>
                                <th>
                                    Employee Type Name
                                </th>
                                <th>Employee Type Code</th>
                                <th>
                                    Status
                                </th>
                                {
                                      (permissionsData?.update || permissionsData?.delete) ?  <th width="6%">Action</th>: ""
                                }
                               
                            </tr>
                        </thead><tbody>
                            {employeeTypeList && employeeTypeList[pageNumber].length
                                ? employeeTypeList[pageNumber]
                                    // ?.sort((a, b) => (a.empTypeId < b.empTypeId ? 1 : -1))

                                    .map((rowData, i) => {
                                        return (
                                            <tr key={i}>
                                                <td>{(pageNumber - 1) * pageSize + (i + 1)}</td>
                                                <td>{rowData.employeeType || "-"}</td>
                                                <td>{rowData.empTypeCode || "-"}</td>

                                                <td>
                                                    <div className="status-toggle">
                                                        <Switch
                                                        disabled={(permissionsData?.update)}
                                                            checked={rowData.status === 1}
                                                            onClick={(checked, e) => {
                                                                props.setEmpTypeStatus(
                                                                    {
                                                                        empTypeId: rowData.empTypeId,
                                                                        relationshipId: rowData.relationshipId,
                                                                        status: checked ? 1 : 0
                                                                    }, props

                                                                );
                                                            }}
                                                        />
                                                    </div>
                                                </td>
                                                {
                                                      (permissionsData?.update || permissionsData?.delete) ?    <td className="text-center">
                                                     <DropdownAction
 
                                                         overlay={actionMenu(rowData)}
                                                         trigger={["click"]}
                                                     >
                                                         <i className={ICONS.MORE} />
                                                     </DropdownAction>
                                                 </td> : ""
                                                }
                                             
                                            </tr>
                                        );
                                        // })
                                    })
                                :
                                <tr key="empty-data-box">
                                    <td colSpan={"100%"} style={{ borderBottom: "none" }}>
                                        <Empty />
                                    </td>
                                </tr>
                            }
                        </tbody>
                    </table>
                </Skeleton>
            </div>

            <div className="footer">
                <Skeleton loading={props.headerLoading} paragraph={false} rows={1} active>

                    <div className="f-left"></div>
                    <div className="f-right">
                        <Pagination
                            size="small"
                            total={props.employeeTypeCount}
                            showTotal={showTotal}
                            defaultPageSize={props.pageSize}
                            showSizeChanger
                            pageSizeOptions={[10, 25, 50, 100, 200]}
                            onChange={(pageNumber, pageSize) => {
                                loadPageData(pageNumber, pageSize);
                                props.updateState({ pageSize: pageSize, pageNumber: pageNumber });
                            }}
                            onShowSizeChange={(pageNumber, pageSize) => {
                                handlePageSizeChange(pageNumber || 1, pageSize, props);
                            }}

                        />
                    </div>
                </Skeleton>

            </div>
        </div>
        </>
    )
}
export default injectIntl(EmployeeTypeComp);