import axios from 'axios';
import config from '../../../config/environmentConfig';
import { COMMON_ACTIONS, SUPPLIER_ACTION_LIST } from '../../../static/constants'
import { showToasterMessage, getFinancialYear } from '../../../utils';
import download from 'downloadjs';
const { lang } = require('../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');

export const fetchSuppliers = (payload, history) => {
  let FY = getFinancialYear(payload.companyInfo);
  const searchedTextQuery = payload.searchedText && payload.searchedText.length ? `&searchString=${payload.searchedText}` : '';
  return dispatch => {
    payload.hideLoad && dispatch({ type: COMMON_ACTIONS.SHOW_LIST_LOADER });
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/suppliers/getSuppliers?relationshipId=${payload.relationshipId}&pageNumber=${(payload.pageNumber || 1)-1}&pageSize=${payload.pageSize || 10}&startDate=${FY.fyStartDate}&endDate=${FY.fyEndDate}${searchedTextQuery}&supplierIdArr=${payload.supplierIdArr || ''}`
    )
      .then(res => {

        const data = {
          pageNo: payload.pageNumber,
          list: payload.filterData ? (res.data?.filter((item)=>item.isActive == 1) ):res.data
        }
        dispatch({ type: SUPPLIER_ACTION_LIST.SUPPLIERS_LIST, data });
      })
      .catch((err) => {
        showToasterMessage({messageType: 'error',  description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
      })
      .finally(() => {
        dispatch({
          type: COMMON_ACTIONS.HIDE_LIST_LOADER,
        });
      })
  }
}

export const getSupplierCount = (payload, history) => {
  let FY = getFinancialYear(payload.companyInfo);
  const searchedTextQuery = payload.searchedText && payload.searchedText.length ? `&searchString=${payload.searchedText}` : '';
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_HEADER_LOADER });
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/suppliers/getSupplierCount?relationshipId=${payload.relationshipId}&startDate=${FY.fyStartDate}&endDate=${FY.fyEndDate}${searchedTextQuery}`)
      .then(res => {
        dispatch({ type: SUPPLIER_ACTION_LIST.SUPPLIER_COUNT, data: res.data });
        dispatch({ type: COMMON_ACTIONS.HIDE_HEADER_LOADER });
      })
      .catch((err) => {
        showToasterMessage({messageType: 'error',  description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' });
        dispatch({ type: COMMON_ACTIONS.HIDE_HEADER_LOADER });
      })
  }
}

export const resetPaginatedData = () => {
  return dispatch => {
    dispatch({ type: SUPPLIER_ACTION_LIST.SUPPLIERS_PAGE_RESET });
  }
}

export const getTotalSupplierOrdersCount = (payload, history) => {
  let FY = getFinancialYear(payload.companyInfo);
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/purchaseOrder/getTotalOrderCountByRid?relationshipId=${payload.relationshipId}&supplierId=${payload.supplierId}&startDate=${FY.fyStartDate}&endDate=${FY.fyEndDate}`)
      .then(res => {
        dispatch({ type: SUPPLIER_ACTION_LIST.SUPPLIER_OPEN_ORDER_COUNT, data: res.data });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if(!err.__isRetryRequest){
          showToasterMessage({messageType: 'error',  description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        }else{
          err.actionToCall = SUPPLIER_ACTION_LIST.SUPPLIER_OPEN_ORDER_COUNT;
        }
      })
  }
}

export const getTotalSupplierPOCount = (payload, history) => {
  let FY = getFinancialYear(payload.companyInfo);
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/purchaseOrder/getOpenOrdersCountByRid?relationshipId=${payload.relationshipId}&supplierId=${payload.supplierId}&startDate=${FY.fyStartDate}&endDate=${FY.fyEndDate}`)
      .then(res => {
        dispatch({ type: SUPPLIER_ACTION_LIST.SUPPLIER_OPEN_PO_COUNT, data: res.data });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if(!err.__isRetryRequest){
          showToasterMessage({messageType: 'error',  description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        }else{
          err.actionToCall = SUPPLIER_ACTION_LIST.SUPPLIER_OPEN_PO_COUNT;
        }
      })
  }
}

export const getTotalSupplierUnpaidBalance = (payload, history) => {
  let FY = getFinancialYear(payload.companyInfo);
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/supplierInvoice/getUnpaidInvoiceCount?relationshipId=${payload.relationshipId}&supplierId=${payload.supplierId}&startDate=${FY.fyStartDate}&endDate=${FY.fyEndDate}`)
      .then(res => {
        dispatch({ type: SUPPLIER_ACTION_LIST.SUPPLIER_UNPAID_BALANCE, data: res.data });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if(!err.__isRetryRequest){
          showToasterMessage({messageType: 'error',  description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        }else{
          err.actionToCall = SUPPLIER_ACTION_LIST.SUPPLIER_UNPAID_BALANCE;
        }
      })
  }
}

export const getTotalPurchase = (payload, history) => {
  let FY = getFinancialYear(payload.companyInfo);
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/supplierInvoice/getTotalInvoiceCount?relationshipId=${payload.relationshipId}&supplierId=${payload.supplierId}&startDate=${FY.fyStartDate}&endDate=${FY.fyEndDate}`)
      .then(res => {
        dispatch({ type: SUPPLIER_ACTION_LIST.SUPPLIER_TOTAL_PURCHASE, data: res.data });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if(!err.__isRetryRequest){
          showToasterMessage({messageType: 'error',  description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        }else{
          err.actionToCall = SUPPLIER_ACTION_LIST.SUPPLIER_TOTAL_PURCHASE;
        }
      })
  }
}

export const exportPdf = (payload) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios.get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/supplierPdf/getSelectedSupplierPdf?relationshipId=${payload.relationshipId}&supplierIds=${payload.supplierIds}`,
      {
        responseType: 'blob',
      }
    )
      .then(response => {
        const content = response.headers['content-type'];
        download(response.data, 'Supplier.pdf', content);
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch(err => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
  }
}

export const exportExcel = (payload) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios.get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/supplierExcel/getSelectedSupplierExcel?relationshipId=${payload.relationshipId}&supplierIds=${payload.supplierIds}`,
      {
        responseType: 'blob',
      }
    )
      .then(response => {
        const content = response.headers['content-type'];
        download(response.data, 'Supplier.xlsx', content);
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch(err => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
  }
}

export const deleteSuppliers = (payload) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios.post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/suppliers/deleteMultipleSuppliers`, payload)
      .then(response => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        showToasterMessage({ messageType: 'success', description: (response.data || {}).message || 'Supplier(s) deleted successfully' });
        resetPaginatedData()(dispatch);
        getSupplierCount(payload)(dispatch);
        fetchSuppliers(payload)(dispatch);
      })
      .catch(err => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        }
      })
  }
}

export const updateStatus = (payload,props) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios.patch(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/suppliers/activate?relationshipId=${payload.relationshipId}&supplierId=${payload.supplierId}&active=${(payload.active)}`,)
      .then(response => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        showToasterMessage({ messageType: 'success', description: `Status change` });
        fetchSuppliers({...payload,pageSize:props.pageSize})(dispatch);
      })
      .catch(err => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        }
      })
  }
}