import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import EmployeeComp from '../../../../components/company/EmployeeMeta/EmployeeType';
import EmployeeDrawer from '../../../drawer/company/EmployeeMeta/Employee';
import { showModal, hideModal,pushModalToStack,popModalFromStack } from '../../../../actions/commonActions';
import { createEmployeeType,getEmployeeType,getEmployeeTypeCount,deleteEmployeeType,setEmpTypeStatus } from './action';
import { fetchPaginationDataIfNeeded,fetchDataIfNeeded } from '../../../../utils';


class EmployeeContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageSize: 25,
      pageNumber: 1,
    }
    };
  

  componentDidMount() {
    const companyInfo = this.props.companyInfo || {};

    const payload = {
      relationshipId: companyInfo.relationshipId,
      pageNumber: 1,
      pageSize: 25,

    }
    fetchPaginationDataIfNeeded('getEmployeeType', 'employeeTypeList', this.props, payload, true);
    fetchDataIfNeeded('getEmployeeTypeCount', 'employeeTypeCount', this.props, payload);
  }

  

  render() {
      return <>

<EmployeeComp {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
{this.state.employeeTypeDrawer && <EmployeeDrawer {...this.state} {...this.props} updateState={(data) => { this.setState(data) }} />}
      </>
    
    ;
  }

}

const mapStateToProps = (state) => {
  return {

    companyInfo: state.common.companyInfo,
    listLoading: state.common.listLoading,
    drawerLoading:state.common.drawerLoading,
    userInfo: state.common.userInfo,
    isLoading: state.common.isLoading,
    employeeTypeList: state.employee.employeeTypeList,
    employeeTypeCount: state.employee.employeeTypeCount,
    permissions: state.auth.permissions,
   
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  showModal,
  hideModal,
  pushModalToStack,
  popModalFromStack,
  createEmployeeType,
  getEmployeeType,
  getEmployeeTypeCount,
  deleteEmployeeType,
  setEmpTypeStatus
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeContainer);
