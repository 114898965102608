import React, { Fragment } from "react";
import Profile from "./profile";
import { Button, Divider, Tabs } from "antd";
import { FormattedMessage } from "react-intl";
import { getCurrencySymbol } from "../../../utils";
import { TextBox } from "../../general/TextBox";
import { Dropdown } from "../../general/Dropdown";
import PageHeader from "../../Common/pageHeader";
import "../style.scss"
import Security from "./security";
const { TabPane } = Tabs;
function onTabChange(key, props) {
    const payload = {
      companyInfo: props.companyInfo,
      relationshipId: props.companyInfo.relationshipId,
      pageNumber: props.pageNumber,
      pageSize: props.pageSize,
    };
  
    switch (key) {
      case "My Profile":
        props.updateState({ activeTab: "My Profile", activeTabKey: "My Profile" });
        break;
      case "Security":
        props.updateState({ activeTab: "Security", activeTabKey: "Security" });
       
        break;
     
       
    
      default:
        break;
    }
  }

function ProfileComp(props) {

    const breadCrumbList = [
        {
          name: <FormattedMessage id='sidebar.menuItem.dashboard' defaultMessage='' />,
        },
        {
          name: <FormattedMessage id='account.myprofile.breadcrumb.account' defaultMessage='' />,
        }
    ];
    return (
        <Fragment>
        
         <div className="view-container">
              <Tabs
                activeKey={props.activeTabKey || "My Profile"} onChange={(key) => { onTabChange(key, props); }}
                type="line"
                animated={false}
              >
                <TabPane tab={<FormattedMessage id='sidebar.menuItem.account.myProfile' />} key="My Profile">
                  <Profile {...props} />
                </TabPane>
                <TabPane tab={<FormattedMessage id='account.myprofile.account.security' />} key="Security">
                  <Security {...props} />
                </TabPane>
                
              </Tabs>
            </div>
        </Fragment>
      );
}
export default ProfileComp;