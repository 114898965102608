import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { showModal, hideModal, pushModalToStack, popModalFromStack } from './../../../actions/commonActions';
 import { getCompanyInfo } from '../../../utils';
import Email from '../../../components/integrations/Email';
import { saveBrevo ,fetchAllSettings} from './action';

class EmailCon extends Component {
    constructor(props) {
        super(props);
        this.state = {
    
        };
    
      }
    componentDidMount() {
         const companyInfo = this.props.companyInfo || getCompanyInfo() || {};
        const payload = {
          relationshipId: companyInfo.relationshipId
        };
        this.props.fetchAllSettings(payload)
      }

    render() {
        return <Email {...this.props} {...this.state}  updateState={(data) => {this.setState(data)}}/>
    }

}

const mapStateToProps = (state) => {
    return {
        companyInfo: state.common.companyInfo,
        relationshipId: state.common.companyInfo.relationshipId,
        brevoSettings: state.integration.brevoSettings,
        permissions: state.auth.permissions
    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    getCompanyInfo,
    showModal, hideModal, pushModalToStack, popModalFromStack,
    saveBrevo,
    fetchAllSettings
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(EmailCon);
