import React, { Component, Fragment } from "react";
// import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Row, Col, DatePicker, Checkbox, Tooltip, Skeleton } from 'antd';
import { FormattedMessage, injectIntl } from 'react-intl';
import moment from 'moment-timezone';
import * as filter from 'lodash.filter';
import * as find from 'lodash.find';
import { MultipleSelectDropdown } from "../../../../general/MultipleSelectDropdown";
import { Dropdown } from "../../../../general/Dropdown";
import { TextBox } from '../../../../general/TextBox';
import { CONSTANTS, MODAL_TYPE, CONSTANTS_TRANSACTIONS, CONSTANTS_TRANSACTIONS_KEY,MODULE_CODED_VALUES } from '../../../../../static/constants';
// import config from '../../../../../config/environmentConfig';
import { showToasterMessage } from '../../../../../utils';
// import { LogoComponent } from "../../../../general/LogoComponent";
import { QuestionCircleTwoTone ,EditOutlined} from '@ant-design/icons';
import { flushSync } from "react-dom";

const format = CONSTANTS.DISPLAY_DATE_FORMAT;

class InvoiceDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            size: 'default'
        };
    }

    handleSizeChange = e => {
        this.setState({ size: e.target.value });
    };

    handleNewAddressAddition = (props, payload, modalType) => {
        const formData = payload.formData;
        if (!formData || (modalType === MODAL_TYPE.BILLING_ADDRESS && !formData.supplierId)) {
            return showToasterMessage({ messageType: 'error', description: 'Please Select Supplier' });
        }
        const { showModal, companyInfo } = props;
        formData.relationshipId = companyInfo.relationshipId;
        if (modalType === MODAL_TYPE.RELATIONSHIP_BILLING_ADDRESS) {
            payload.formData = { addressType:'relationship', locationType: 'RelationshipBillingAddress' };
        }
        payload.formData.callback = function (address) {
            props.hideModal(data);
            if (address.locationType === 'BillingAddress' || address.locationType === 'ShippingAddress') {
              props.fetchAddresses({
                supplierId: address.supplierId,
                relationshipId: (props.companyInfo || {}).relationshipId,
                locationType: address.locationType
              });
            } else {
              props.getRelationshipAddress({
                relationshipId: (props.companyInfo || {}).relationshipId
              });
            }
          }
        const data = {
            title: this.getModalTitle(payload, modalType),
            formData,
            hideFooter: true,
            keyboard:false,
            modalData: {
                modalType: modalType,
                data: payload,
            },
            handleSubmit: (formData = {}) => {
                data.formData.submittedOnce = true;
                props.hideModal(data);
            }
        };
        showModal(data);
    }

    handleNewTaxIdentificationAddition = (props, payload, modalType) => {
        const formData = payload.formData;
        const { showModal, companyInfo } = props;
        formData.relationshipId = companyInfo.relationshipId;
        formData.callback = function (taxIdentification) {
            if (props.supplier) {
                let list = props.supplier.supplierTaxIdentificationsList || [];
                list.push(taxIdentification)
                props.updateState({ supplier: { ...props.supplier, supplierTaxIdentificationsList: list } })
            }
        }
        const data = {
            title: <FormattedMessage id='addItem.text.taxIdentification' defaultMessage='' />,
            formData,
            hideFooter: true,
            modalData: {
                modalType: modalType,
                data: payload,
            },
            handleSubmit: (formData = {}) => {
                data.formData.submittedOnce = true;
                props.hideModal(data);
            }
        };
        showModal(data);
    }

    handleNewContactAddition = (props, payload) => {
        const formData = payload.formData;
        const { showModal, companyInfo } = props;
        formData.relationshipId = companyInfo.relationshipId;
        formData.callback = function (contact) {
            props.hideModal(data);
            if (contact.supplierId) {
                props.fetchAllContacts({
                    supplierId: contact.supplierId,
                    relationshipId: (props.companyInfo || {}).relationshipId,
                });
            }
        }
        const data = {
            title: <div>
                <FormattedMessage id='addItem.text.contact' defaultMessage='' />
                <div style={{ 'font-size': '12px' }}> {formData.supplierName || ''}</div>
            </div>,
            formData,
            hideFooter: true,
            modalData: {
                modalType: MODAL_TYPE.CONTACT,
                data: payload,

            },
            handleSubmit: (formData = {}) => {
                data.formData.submittedOnce = true;
                props.hideModal(data);
            }
        };
        showModal(data);
    }

    getModalTitle = (newTextObj, modalType) => {
        let title;

        switch (modalType) {
            case MODAL_TYPE.BILLING_ADDRESS: {
                title = <div><FormattedMessage id='addItem.text.billingAddress' defaultMessage='' />
                    <h6>{(this.props.supplier || {}).name} </h6>
                </div >
                break;
            }
            case MODAL_TYPE.RELATIONSHIP_BILLING_ADDRESS: {
                title = <div><FormattedMessage id='addItem.text.relationshipBillingAddress'
                    defaultMessage='' />
                    <h6>{(this.props.companyInfo || {}).storeName} </h6>
                </div>
                break;
            }
            case MODAL_TYPE.RELATIONSHIP_SHIPPING_ADDRESS: {
                title = <div><FormattedMessage id='addItem.text.relationshipShippingAddress'
                    defaultMessage='' />
                    <h6>{(this.props.companyInfo || {}).storeName} </h6>
                </div>
                break;
            }
            case MODAL_TYPE.DOCUMENT_NAME: {
                title = <FormattedMessage id='addItem.text.documentName' defaultMessage='' />;
                break;
            }
            default:
                title = <FormattedMessage id='signup.confirmation.title' defaultMessage='' />;
        }
        return title;
    }

    handleNewItemAddition = (props, payload, modalType) => {
        const formData = payload.formData;
        const { showModal } = props;

        if (formData.textBoxValue && formData.textBoxValue.length && !payload.existsInData) {
            this.addItemConfirmationModal(props, formData.textBoxValue, modalType);
        } else {
            const data = {
                title: this.getModalTitle(payload, modalType),
                formData,
                modalData: { modalType, data: payload },
                handleSubmit: (formData = {}) => {
                    if (formData.textBoxValue && formData.textBoxValue.length) {
                        this.addItemConfirmationModal(props, formData.textBoxValue, modalType);
                    } else {
                        data.formData.submittedOnce = true;
                        showModal(data);
                    }
                }
            };
            showModal(data);
        }
    }

    addItemConfirmationModal = (props, text, modalType) => {
        const { showModal, companyInfo } = props;
        const labelName = this.getModalTitle(null, modalType);
        const modalBody = <span>
            <FormattedMessage
                id='addItem.confirmation'
                values={{ value: `${text} to ` }}
                defaultMessage={``}
            />
            {labelName} ?
        </span>;

        let addFunc;
        let payload = {
            text: text,
            relationshipId: companyInfo.relationshipId
        }

        switch (modalType) {
            case MODAL_TYPE.DOCUMENT_NAME: {
                addFunc = props.addDocumentName;
                payload.txType = CONSTANTS_TRANSACTIONS_KEY[CONSTANTS_TRANSACTIONS.DEBIT_MEMO];
                break;
            }
            default: {
                addFunc = () => {
                }
            }
        }

        const modalData = {
            modalBody,
            handleSubmit: () => {
                addFunc(payload);
                props.hideModal();
            },
        };
        showModal(modalData);
    }

    deleteClickHandler = (props, modalType, payload) => {
        const { showModal, companyInfo } = props;
        const modalBody =
            <FormattedMessage
                id='deleteItem.confirmation'
                defaultMessage={``}
                values={{ value: payload.text }}
            />;

        let deleteFunc;
        let callback = function (data) {
            props.getRelationshipAddress({
                relationshipId: (props.companyInfo || {}).relationshipId
              });
            if (modalType === MODAL_TYPE.CONTACT && props.supplier.supplierId) {
                props.fetchAllContacts({
                    supplierId: props.supplier.supplierId,
                    relationshipId: (props.companyInfo || {}).relationshipId,
                });
            }
            if (modalType === MODAL_TYPE.BILLING_ADDRESS && props.supplier.supplierId) {
                props.fetchAddresses({
                    supplierId: props.supplier.supplierId,
                    relationshipId: (props.companyInfo || {}).relationshipId,
                    locationType: 'BillingAddress'
                });
            }
        }

        switch (modalType) {
            case MODAL_TYPE.BILLING_ADDRESS:
            case MODAL_TYPE.RELATIONSHIP_BILLING_ADDRESS:
            case MODAL_TYPE.RELATIONSHIP_SHIPPING_ADDRESS: {
                deleteFunc = props.deleteAddress;
                break;
            }
            case MODAL_TYPE.DOCUMENT_NAME: {
                deleteFunc = props.deleteDocumentName;
                break;
            }
            case MODAL_TYPE.CONTACT: {
                deleteFunc = props.deleteContact;
                break;
            }
            case MODAL_TYPE.NEW_TAX_IDENTIFICATION: {
                deleteFunc = props.deleteRelationshipTaxIdentifications;
                break;
            }
            case MODAL_TYPE.NEW_TAX_IDENTIFICATION_SUPPLIER: {
                deleteFunc = props.deleteSupplierTaxIdentifications;
                break;
            }
            default: {
                deleteFunc = () => {
                }
            }
        }

        const modalData = {
            modalBody,
            handleSubmit: () => {
                deleteFunc({ id: payload.id, relationshipId: companyInfo.relationshipId, callback: callback });
                if (modalType === MODAL_TYPE.NEW_TAX_IDENTIFICATION_SUPPLIER && props.supplier && props.supplier.supplierTaxIdentificationsList) {
                    let list = (props.supplier.supplierTaxIdentificationsList || []).filter(x => x.supplierTaxIdentificationId !== payload.id);
                    props.updateState({ supplier: { ...props.supplier, supplierTaxIdentificationsList: list } })
                }
                if (modalType === MODAL_TYPE.DOCUMENT_NAME && payload.id === this.props.documentNameId) {
                    const selectedObj = find((this.props.documentList || []), { txType: CONSTANTS_TRANSACTIONS_KEY[CONSTANTS_TRANSACTIONS.DEBIT_MEMO], isDefault: 1 }) || {};
                    this.props.updateState({
                        documentNameId: selectedObj.documentNameId,
                        docName: selectedObj.docName
                    });
                }
            },
        };
        showModal(modalData);
    }

    render() {

        const { size } = this.state;
        const { supplier = {}, intl } = this.props;
        
       // const supplierObj = this.props.supplierDetail || {};
        //const supplierAddressObjs = (supplierObj.boLocationList || []).filter(x => x.locationType === CONSTANTS.BILLING_ADDRESS) || [];

        return (
            <Fragment>
             
                    <Row>
                        <Col span={5}>
                            {/* <div className="d-inline-flex w-100">
                            <LogoComponent src={CONSTANTS.BASE_URL_S3 + '/' + config.BUCKET_NAME.BO_RELATIONSHIP + '/' + (this.props.companyInfo || {}).logoAwsObjectKey + "?" + Date.now()}/> */}
                                {/* <Avatar shape='square' size={64} icon={<UserOutlined />}
                                    src={CONSTANTS.BASE_URL_S3 +  '/'+ config.BUCKET_NAME.BO_RELATIONSHIP +'/' + (this.props.companyInfo || {}).logoAwsObjectKey + "?"+Date.now()} /> */}
                        <Skeleton loading={this.props.detailLoading} active paragraph={{ rows: 5 }}>

                        <Row gutter={[0,3]}>
                                <Col span={24} className='details-heading'>
                                    Organization Information
                                </Col>
                                <Col span={24}>
                                <div className='details-heading-subheading'>

                                <FormattedMessage id="sales.order.create.organization.name" defaultMessage=''/>
                                </div>
                                </Col>
                                <Col span={24}>
                                <div className='deatils-field-value'>
                                {(this.props.companyInfo || {}).storeName}
                                </div>
                                </Col>
                                <Col span={24}>
                                <div className='details-heading-subheading'>

                                <FormattedMessage id="sales.order.create.organization.adress" defaultMessage=''/>
                                <Tooltip placement="right" title={this.props.intl.formatMessage(
                                    {
                                    id: 'tooltip.companyBillingAddress',
                                    defaultMessage: ''
                                    }
                                )} trigger="click">
                                    <QuestionCircleTwoTone />
                                </Tooltip>
                                </div>
                                </Col>
                                <Col span={24}>
                <span>{
                  !this.props.addressHide && this.props.relationshipBillingAddress ?
                  <>
                  
                  <div className='detail-address-value'>

                     <div style={{height:'96px',paddingTop:'10px'}}>
                     {
                                        getAddressInfoFragment(this.props.relationshipBillingAddress || {}, {}, (this.props.companyInfo || {}))
                                    }
                     </div>
                     <div onClick={()=>{
                        
                        this.props.updateState({
                          addressHide: true,
                        })
                      }}>
                        <EditOutlined />
                     </div>
                  </div>
                  </> 
                  : 
                  <Dropdown
                                        style={{ width: '100%'}}
                                        value={(this.props.relationshipBillingAddress || {}).streetAddress1}
                                        items={this.props.allRelationshipBillingAddress}
                                        valueKeyName='streetAddress1'
                                        addressLineKey1='streetAddress2'
                                        addressLineKey2='cityName'
                                        addressLineKey3='stateName'
                                        addressLineKey4='zipCode'
                                        optionClassAddress='custom-address-dropdown'
                                        optionKeyName='locationId'
                                        isFavouriteKeyName= 'isDefault'
                                        canAddNew={true}
                                        canDelete={true}
                                        deleteClickHandler={(payload) => {
                                            this.deleteClickHandler(this.props, MODAL_TYPE.RELATIONSHIP_BILLING_ADDRESS, payload);
                                        }}
                                        onAddNewClick={(payload) => {
                                            payload.formData = {
                                                locationType: CONSTANTS.RELATIONSHIP_BILLING_ADDRESS
                                            }
                                            this.handleNewAddressAddition(this.props, payload, MODAL_TYPE.RELATIONSHIP_BILLING_ADDRESS)
                                        }}
                                        onSelect={(locationId) => {
                                            this.props.updateState({
                                                relationshipBillingAddress: this.props.allRelationshipBillingAddress.find(x => x.locationId === locationId)
                                                ,addressHide: false
                                            })
                                        }}
                                        allowClear={true}
                                        onClear={() => {
                                            this.props.updateState({
                                                relationshipBillingAddress: undefined,
                                            })
                                        }}
                                    />
                  }
                 
                </span>

                                </Col>

                                    {/* <Tooltip placement="right" title={<FormattedMessage id='relationshipAddress.tooltip' defaultMessage='' />} trigger="click">
                                        <i className="fa fa-question-circle-o pl5 cursor-pointer" />
                                    </Tooltip> */}
                                <Col span={24}>
                                <div className='details-heading-subheading'>

                                <FormattedMessage id='customer.form.taxIdentification.label' defaultMessage='' />
                                <Tooltip placement="right" title={this.props.intl.formatMessage(
                                {
                                    id: 'tooltip.taIdentification',
                                    defaultMessage: ''
                                }
                                )} trigger="click">
                                <QuestionCircleTwoTone />
                                </Tooltip>
                                </div>
                                
                                                <MultipleSelectDropdown
                                                    style={{ width: '100%'}}
                                                    items={this.props.allRelationshipTaxIdentificatins || []}
                                                    placeholder="Tax Identification(s)"
                                                    mode='multiple'
                                                    onDeSelect={(value) => {
                                                        let { selectedRelationshipTaxIdentifications } = this.props
                                                        selectedRelationshipTaxIdentifications = filter(selectedRelationshipTaxIdentifications, (taxId) => {
                                                            return taxId !== value;
                                                        });
                                                        this.props.updateState({ selectedRelationshipTaxIdentifications });
                                                    }}
                                                    valueKeyName='taxIdentificationType'
                                                    valueKeyName2='taxIdentificationNumber'
                                                    selectedValue={(this.props.allRelationshipTaxIdentificatins || []).length ? (this.props.selectedRelationshipTaxIdentifications || []) : []}
                                                    optionKeyName='relationshipTaxIdentificationId'
                                                    onSelect={(relationshipTaxIdentificationId) => {
                                                        let selectedRelationshipTaxIdentifications = this.props.selectedRelationshipTaxIdentifications || [];
                                                        if (selectedRelationshipTaxIdentifications.indexOf(relationshipTaxIdentificationId) === -1) {
                                                            selectedRelationshipTaxIdentifications.push(relationshipTaxIdentificationId);
                                                        } else {
                                                            selectedRelationshipTaxIdentifications = selectedRelationshipTaxIdentifications.filter((el) => {
                                                                return el !== selectedRelationshipTaxIdentifications
                                                            });
                                                        }
                                                        this.props.updateState({
                                                            selectedRelationshipTaxIdentifications
                                                        });
                                                    }}
                                                    canAddNew={true}
                                                        canDelete={true}
                                                        deleteClickHandler={(payload) => {
                                                            this.deleteClickHandler(this.props, MODAL_TYPE.NEW_TAX_IDENTIFICATION, payload);
                                                        }}
                                                    onAddNewClick={(payload) => {
                                                        payload.formData = {
                                                            taxIdentificationNumber: payload.textEntered,
                                                            submittedOnce: false,
                                                        }
                                                        this.handleNewTaxIdentificationAddition(this.props, payload, MODAL_TYPE.NEW_TAX_IDENTIFICATION)
                                                    }}
                                                />

                                </Col>                 
                         </Row>
                        </Skeleton>
                        </Col>
                        <Col span={1}></Col>
                        <Col span={5}>
                        <Skeleton loading={this.props.detailLoading} active paragraph={{ rows: 5 }}>

                            <Row gutter={[0,3]}>
                            <Col span={24} className='details-heading'>
                              Supplier Information
                            </Col> 
                            {
                                supplier.name
                                    ? 
                                    <>
                                        
                                            <Col span={24} >
                                                <div className="details-heading-subheading">
                                                    <FormattedMessage id='purchase.landindCostPage.table.heading.supplier' defaultMessage='' />
                                                </div>
                                            </Col>
                                            <Col span={24}>
                                                <div className="deatils-field-value">
                                                    {/* <FormattedMessage id='supplier.debitMemo.form.supplier.label' defaultMessage='' /> */}
                                                    {supplier.name}
                                                </div>
                                            </Col>
                                       

                                       
                                            <Col span={24}>
                                                <div className="details-heading-subheading">
                                                    <FormattedMessage id='contact' defaultMessage='' />
                                                    <Tooltip placement="right" title={<FormattedMessage id='common.supplier.contact' defaultMessage='' />} trigger="click">
                                                    <i className="fa fa-question-circle-o pl5 cursor-pointer" />
                                                </Tooltip>
                                                </div>
                                            </Col>
                                            <Col span={24}>
                                                <Dropdown
                                                    style={{ width: '100%' }}
                                                    value={((this.props.selectedContact || {}).firstName || "") + " " + ((this.props.selectedContact || {}).lastName || "")}
                                                    optionKeyName="contactId"
                                                    items={this.props.boContactList}
                                                    valueKeyName='firstName'
                                                    lastNameKey='lastName'
                                                    isFavouriteKeyName='isPrimaryContact'
                                                    placeholder='Please select contact name'
                                                    moduleName={MODULE_CODED_VALUES.SUPPLIER_CONTACTS}
                                                    canAddNew={true}
                                                    canDelete={true}
                                                    deleteClickHandler={(payload) => {
                                                        this.deleteClickHandler(this.props, MODAL_TYPE.CONTACT, payload);
                                                    }}
                                                    onAddNewClick={(payload) => {
                                                        payload.formData = {
                                                            firstName: payload.textEntered,
                                                            submittedOnce: false,
                                                            supplierId: (this.props.supplier || {}).id,
                                                            pageNumber: this.props.pageNumber || 1,
                                                            supplierName: supplier.name
                                                        }
                                                        this.handleNewContactAddition(this.props, payload, MODAL_TYPE.CONTACT)
                                                    }}
                                                    onSelect={(contactId) => {
                                                        let contact = find(this.props.supplier.boContactList, { contactId: +contactId }) || {};
                                                        this.props.updateState({
                                                            selectedContact: contact
                                                        })
                                                    }}
                                                    allowClear={true}
                                                    onClear={() => {
                                                        this.props.updateState({
                                                            selectedContact: undefined,
                                                        })
                                                    }}
                                                />
                                              
                                            </Col>
                                       
                                       
                                            <Col span={24}>
                                                <div className="details-heading-subheading">
                                                    <FormattedMessage id='signup.tab.address' defaultMessage='' />
                                                    <Tooltip placement="right" title={<FormattedMessage id='common.supplier.address' defaultMessage='' />} trigger="click">
                                                    <i className="fa fa-question-circle-o pl5 cursor-pointer" />
                                                </Tooltip>
                                                </div>
                                            </Col>
                                            <Col span={24}>
                                                <Dropdown
                                                    style={{ width: '100%' }}
                                                    value={(this.props.supplierBillingAddress || {}).streetAddress1}
                                                    items={this.props.allBillingAddress}
                                                    valueKeyName='streetAddress1'
                                                    addressLineKey1='streetAddress2'
                                                    addressLineKey2='cityName'
                                                    addressLineKey3='stateName'
                                                    addressLineKey4='zipCode'
                                                    isFavouriteKeyName= 'isDefault'
                                                    optionClassAddress='custom-address-dropdown'
                                                    optionKeyName='locationId'
                                                    moduleName={MODULE_CODED_VALUES.SUPPLIER_BILLING_ADDRESS}
                                                    canAddNew={true}
                                                    canDelete={true}
                                                    deleteClickHandler={(payload) => {
                                                        this.deleteClickHandler(this.props, MODAL_TYPE.BILLING_ADDRESS, payload);
                                                    }}
                                                    onAddNewClick={(payload) => {
                                                        payload.formData = {
                                                            locationType: CONSTANTS.BILLING_ADDRESS,
                                                            supplierId: (this.props.supplier || {}).supplierId,
                                                            pageNumber: this.props.pageNumber,
                                                        }
                                                        this.handleNewAddressAddition(this.props, payload, MODAL_TYPE.BILLING_ADDRESS)
                                                    }}
                                                    onSelect={(locationId) => {
                                                        this.setState({
                                                            supplierBillingAddress: this.props.allBillingAddress.find(x => +x.locationId === +locationId) || {}
                                                        })
                                                    }}
                                                    allowClear={true}
                                                    onClear={() => {
                                                        this.props.updateState({
                                                            supplierBillingAddress: undefined,
                                                        })
                                                    }}
                                                />
                                               
                                                
                                            </Col>
                                       
                                                <Col span={24}>
                                                    <div className="details-heading-subheading">
                                                    <FormattedMessage id='customer.form.taxIdentification.label' defaultMessage='' />
                                                    <Tooltip placement="right" title={<FormattedMessage id='taxIdentification.tooltip' defaultMessage='' />} trigger="click">
                                                        <i className="fa fa-question-circle-o pl5 cursor-pointer" />
                                                    </Tooltip>
                                                    </div>
                                                </Col>
                                                <Col span={24}>
                                                    <MultipleSelectDropdown
                                                        style={{ width: '100%' }}
                                                        items={this.props.supplier.supplierTaxIdentificationsList || []}
                                                        placeholder="Tax Identification(s)"
                                                        mode='multiple'
                                                        onDeSelect={(value) => {
                                                            let { selectedSupplierTaxIdentifications } = this.props
                                                            selectedSupplierTaxIdentifications = filter(selectedSupplierTaxIdentifications, (taxId) => {
                                                                return taxId !== value;
                                                            });
                                                            this.props.updateState({ selectedSupplierTaxIdentifications });
                                                        }}
                                                        valueKeyName='taxIdentificationType'
                                                        valueKeyName2='taxIdentificationNumber'
                                                        selectedValue={(this.props.supplier.supplierTaxIdentificationsList || []).length ? (this.props.selectedSupplierTaxIdentifications || []) : []}
                                                        optionKeyName='supplierTaxIdentificationId'
                                                        onSelect={(supplierTaxIdentificationId) => {
                                                            let selectedSupplierTaxIdentifications = this.props.selectedSupplierTaxIdentifications || [];
                                                            if (selectedSupplierTaxIdentifications.indexOf(supplierTaxIdentificationId) === -1) {
                                                                selectedSupplierTaxIdentifications.push(supplierTaxIdentificationId);
                                                            } else {
                                                                selectedSupplierTaxIdentifications = selectedSupplierTaxIdentifications.filter((el) => {
                                                                    return el !== supplierTaxIdentificationId
                                                                });
                                                            }
                                                            this.props.updateState({
                                                                selectedSupplierTaxIdentifications
                                                            });
                                                        }}
                                                        canAddNew={true}
                                                        canDelete={true}
                                                        deleteClickHandler={(payload) => {
                                                            this.deleteClickHandler(this.props, MODAL_TYPE.NEW_TAX_IDENTIFICATION_SUPPLIER, payload);
                                                        }}
                                                        onAddNewClick={(payload) => {
                                                            payload.formData = {
                                                                taxIdentificationNumber: payload.textEntered,
                                                                submittedOnce: false,
                                                                supplierId: (this.props.supplier || {}).id,
                                                                pageNumber: this.props.pageNumber || 1,
                                                                supplierName: supplier.name
                                                            }
                                                            this.handleNewTaxIdentificationAddition(this.props, payload, MODAL_TYPE.NEW_TAX_IDENTIFICATION)
                                                        }}
                                                    />

                                                </Col>
                                           
                                      
                                    </>
                                    : ''
                            }
                            </Row>
                        </Skeleton>
                        </Col>
                        <Col span={8}></Col>

                        <Col span={5}>
                        <Skeleton loading={this.props.detailLoading} active paragraph={{ rows: 5 }}>

                               <Row gutter={[0,3]}>
                               <Col  span={24} className='details-heading'>
                               Debit Memo Information
                                </Col>
                                    {/* {supplier.name ?
                                        <div className="date-picker-container">
                                            <Row>
                                                <Col span={8} className='detail-column'>
                                                <FormattedMessage id='supplier.debitMemo.form.supplierName.label' defaultMessage='' />
                                                </Col>
                                                <Col span={16}>
                                                    {supplier.name}
                                                </Col>
                                            </Row>
                                            {
                                            this.props.supplier.gstNumber &&
                                            <Row>
                                                <Col span={8} className='detail-column'>
                                                        <div>
                                                            <FormattedMessage id='supplier.debitMemo.form.gstnIn.label' defaultMessage='' />
                                                        </div>
                                                </Col>
                                                <Col span={16}>
                                                    <div className="value">
                                                        {this.props.supplier.gstNumber}
                                                    </div>
                                                </Col>
                                            </Row>
                                        }

                                        {
                                            this.props.supplier.panNumber &&
                                            <Row>
                                                <Col span={8} className='detail-column'>
                                                        <div>
                                                            <FormattedMessage id='supplier.debitMemo.form.pan.label' defaultMessage='' />
                                                        </div>
                                                </Col>
                                                <Col span={16}>
                                                    <div className="value">
                                                        {this.props.supplier.panNumber}
                                                    </div>
                                                </Col>
                                            </Row>
                                        }
                                        </div> : null} */}
                                    
                            
                                          
                            
                                
                                  
                                    
                                            <Col span={24}>
                                                <div className="details-heading-subheading">
                                                <FormattedMessage id='supplier.debitMemo.form.debitMemoDate.label'
                                                    defaultMessage='' />

                                                </div>
                                            </Col>
                                            <Col span={24}>
                                                <DatePicker
                                                    allowClear={false}
                                                    format={format}
                                                    key={`${this.props.debitMemoDate ? moment(new Date(this.props.debitMemoDate), format) : moment(new Date(), format)}`}
                                                    defaultValue={this.props.debitMemoDate ? moment(new Date(this.props.debitMemoDate), format) : moment(new Date(), format)}
                                                    onChange={(selectedDate) => {
                                                        this.props.updateState({
                                                            debitMemoDate: selectedDate
                                                        })
                                                        // this.props.getNextTxNumber({
                                                        //     date: selectedDate,
                                                        //     relationshipId: (this.props.companyInfo || {}).relationshipId
                                                        // });
                                                    }}
                                                    size={size}
                                                />
                                            </Col>
                                       
                                  

                  
                                       
                                            <Col span={24}>
                                                <div className="details-heading-subheading">
                                                <FormattedMessage id='customer.salesOrder.form.documentDropdown.label' defaultMessage='' />
                                                <Tooltip placement="right" title={ <FormattedMessage id='documentTitle.tooltip' defaultMessage='' />} >
                                                   <QuestionCircleTwoTone/>
                                                </Tooltip>
                                                </div>
                                            </Col>
                                            <Col span={24}>
                                                <Dropdown
                                                    style={{ width: '100%', marginLeft: 0 }}
                                                    items={filter((this.props.documentList || []), (document) => {
                                                        return document.txType === CONSTANTS_TRANSACTIONS_KEY[CONSTANTS_TRANSACTIONS.DEBIT_MEMO];
                                                    })}
                                                    placeholder={this.props.intl.formatMessage(
                                                        { id: 'setting.documentName.label', defaultMessage: '' }
                                                    )}
                                                    value={this.props.docName && this.props.documentNameId}
                                                    canAddNew={true}
                                                    canDelete={true}
                                                    deleteClickHandler={(payload) => {
                                                        this.deleteClickHandler(this.props, MODAL_TYPE.DOCUMENT_NAME, payload);
                                                    }}
                                                    onAddNewClick={(payload) => {
                                                        payload.formData = {
                                                            textBoxValue: payload.textEntered,
                                                            submittedOnce: false,
                                                            maxLength: 30
                                                        }
                                                        this.handleNewItemAddition(this.props, payload, MODAL_TYPE.DOCUMENT_NAME)
                                                    }}
                                                    onSelect={(selectedValue) => {
                                                        const selectedObj = find((this.props.documentList || []), { documentNameId: Number(selectedValue) });
                                                        this.props.updateState({
                                                            documentNameId: selectedValue,
                                                            docName: selectedObj.docName
                                                        })
                                                    }}
                                                    valueKeyName='docName'
                                                    optionKeyName='documentNameId'
                                                    isLockKeyName='isSystemGenerated'
                                                    // allowClear={true}
                                                    // onClear={() => {
                                                    //     this.props.updateState({
                                                    //         documentNameId: undefined,
                                                    //         docName: undefined
                                                    //     })
                                                    // }}
                                                />
                                            </Col>
                                        
                                   
                                    
                                      
                                            <Col span={24}>
                                                <div className="details-heading-subheading">
                                                    <FormattedMessage id='supplier.purchaseRequest.form.refNumber.label' defaultMessage='' />
                                                </div>
                                            </Col>
                                            <Col span={24}>
                                                <TextBox
                                                    placeholder={intl.formatMessage(
                                                        { id: 'supplier.purchaseRequest.form.refNumber.placeholder', defaultMessage: '' }
                                                    )}
                                                    type='text'
                                                    maxLength= {20}
                                                    value={this.props.reference}
                                                    onChange={(e) => {
                                                        this.props.updateState({
                                                            reference: e.target.value
                                                        })
                                                    }}
                                                />
                                            </Col>
                                    
                                   

                                    
                                       
                                            <Col span={24} >
                                                <div className="details-heading-subheading">

                                                <FormattedMessage id='supplier.debitMemo.form.updateInventory.label' defaultMessage='' />
                                                </div>
                                            </Col>
                                            <Col span={24}>
                                                <div className="value">
                                                    <Checkbox checked={this.props.updateInventory} onClick={(e) => {
                                                        this.props.updateState({ updateInventory: e.target.checked ? 1 : 0, })
                                                    }} />
                                                </div>
                                            </Col>
                                       
                                    
                                </Row>
                        </Skeleton>
                        </Col>
                    </Row>
              

                

            </Fragment>
        );
    }
}

const getAddressInfoFragment = (obj, supplierContactObj, supplierObj) => {
    if (!obj || !Object.keys(obj).length) {
        return '';
    }

    // let contactName = "";
    // if (supplierContactObj.salutationName) contactName += supplierContactObj.salutationName + ' ';
    // if (supplierContactObj.firstName) contactName += supplierContactObj.firstName;
    // if (supplierContactObj.middleName) contactName += ' ' + supplierContactObj.middleName;
    // if (supplierContactObj.lastName) contactName += ' ' + supplierContactObj.lastName;

    const fragmentObj = <Fragment>
      <div className="billing-address first-caps">
        {obj.streetAddress1 || obj.streetAddress2 ? (
          <div>
            {obj.streetAddress1} {obj.streetAddress2}{", "}
          </div>
        ) : ""}
        {obj.cityName || obj.stateName || obj.zipCode ? (
          <div>
            {obj.cityName} {obj.stateName}{obj.stateName && obj.zipCode ? ", " : ""}{obj.zipCode}
          </div>
        ) : ""}
        {obj.countryName ? (
          <div>{obj.countryName}</div>
        ) : ""}
      </div>
        {/* {
            contactName
                ? <div className='billing-address'>
                    {contactName}
                </div> :
                ''
        } */}
        {
            supplierContactObj.workPhone
                ? <div className='billing-address'>
                    <FormattedMessage id='supplier.debitMemo.form.phone.label'
                        defaultMessage='' /> - {supplierContactObj.workPhone}
                </div> :
                ''
        }
        {
            supplierContactObj.email
                ? <div className='billing-address'>
                    <FormattedMessage id='supplier.debitMemo.form.email.label'
                        defaultMessage='' /> - {supplierContactObj.email}
                </div> :
                ''
        }
        {/* {
            supplierObj.gstNumber
                ? <div className='billing-address'>
                    <FormattedMessage id='supplier.debitMemo.form.gstIn.label'
                        defaultMessage='' /> - {supplierObj.gstNumber}
                </div> :
                ''
        }
        {
            supplierObj.panNumber
                ? <div className='billing-address'>
                    <FormattedMessage id='supplier.debitMemo.form.pan.label'
                        defaultMessage='' /> - {supplierObj.panNumber}
                </div> :
                ''
        } */}

    </Fragment>

    return fragmentObj;
}



export default injectIntl(InvoiceDetails);
