import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {  showModal, hideModal, pushModalToStack, popModalFromStack } from '../../../actions/commonActions';
import { fetchCategory } from "../../drawer/inventory/action"; 
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend';
import Page from "../../../components/eStore/page";
import { fetchE_StoreData } from "../../../actions/commonActions";
import { postPagePolicyData ,getPagePublishedListData,getPageAllListData,deletePageListItem} from "./action";
 

class PageComp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageNumber: 1,
      pageSize: 25,
    };
  }
  componentDidMount() {
    const companyInfo = this.props.companyInfo || {};
    const payload = {
      relationshipId: companyInfo.relationshipId,
   
    };
    const payloadValue ={
      relationshipId: companyInfo.relationshipId,
      pageNumber: 0,
      pageSize: 100,
      isPublish:1,
      customerId:companyInfo.relationshipId,
    }
    this.props.fetchCategory(payload);
    this.props.fetchE_StoreData(payload);
    this.props.getPagePublishedListData(payloadValue)
    this.props.getPageAllListData(payloadValue)
  }

  render() {
    return (
      <DndProvider backend={HTML5Backend}>
      <Page
        {...this.props}
        {...this.state}
        updateState={(data) => {
          this.setState(data);
        }}
      />
      </DndProvider>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    userInfo: state.common.userInfo,
    relationshipId: state.common.companyInfo.relationshipId,
    productCategories: state.inventory.productCategories,
    storeValues: state.common.store_values,
    allPageListData: state.eStore.allPageListData,
    pagePublishedData: state.eStore.pagePublishedData,
    permissions: state.auth.permissions,

  };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
  showModal,
  fetchCategory,
  hideModal,
  pushModalToStack,
  popModalFromStack,
  fetchE_StoreData,
  postPagePolicyData,
  getPagePublishedListData,
  getPageAllListData,
  deletePageListItem,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PageComp);
