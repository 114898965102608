import React from 'react';
import { FormattedMessage } from 'react-intl';
//import { Link } from 'react-router-dom';
import { EditOutlined, FilePdfOutlined, MailOutlined, PrinterOutlined, DeleteOutlined, CopyOutlined, HistoryOutlined } from '@ant-design/icons';
import { Button, Select } from 'antd';
import { Dropdown } from '../../../../general/Dropdown';
import * as find from 'lodash.find';
import * as filter from 'lodash.filter';
import config from '../../../../../config/environmentConfig';
import MultiCurrencySalesOrder from '../../../../customer/salesOrder/MultiCurrencySalesOrder';
import { CONSTANTS, TRANSACTION_TYPES, MODAL_TYPE, PERMISSION_VALUES, MODULE_CODED_VALUES, CONSTANTS_TRANSACTIONS} from '../../../../../static/constants';
import { fixedDecimalNumber, checkACLPermission } from '../../../../../utils';
import StandardTemplate from "./standard";
// import Template2 from "./template2"; 
import StandardSalesOrder from "../../../../../containers/customer/salesOrder/StandardSalesOrder"
import StandardPackage from "../../../../../containers/customer/salesOrder/package/index"
import StandardPurchaseRequest from "../../../../../containers/supplier/purchaseRequest/StandardPurchaseRequest"
const { Option } = Select;

const EcomDetails = (props) => {
    const { ecomDetail, companyInfo, templateName = 'Professional', permissions } = props;
    const primaryPerm = permissions.primary;
    const { customerSalesOrderDetailsList } = ecomDetail;
    
    const relationshipAddress = find(ecomDetail.boLocationSOList, { locationType: CONSTANTS.RELATIONSHIP_BILLING_ADDRESS }) || {};
    const billingAddress = find(ecomDetail.boLocationSOList, { locationType: CONSTANTS.BILLING_ADDRESS }) || {};
    const shippingAddress = find(ecomDetail.boLocationSOList, { locationType: CONSTANTS.SHIPPING_ADDRESS }) || {};
    let isPlaceOfSupplySameAsRelationship =relationshipAddress.stateName === (shippingAddress.stateName || shippingAddress.stateName);
    let itemLevelTaxes = [];
    if (customerSalesOrderDetailsList) {
        itemLevelTaxes = calculateTaxRateList(JSON.parse(customerSalesOrderDetailsList||"[]"), isPlaceOfSupplySameAsRelationship);
    }
    let isColumnEmpty = {
        product: true,
        service: true,
        variant: true,
        description: true,
        qty: true,
        uomName: true,
        rate: true,
        hsn: true,
        discount: true,
        tax: true,
        specialDiscount: true,
        documentDetails: true,
        comment: true
    };
    if (ecomDetail) {
        isColumnEmpty = updateColumnEmptyCheck(isColumnEmpty, ecomDetail, props);
    }   






 

    // const updatePRStatus = (props, soData) => {
    //     let payload = { formData: {maxLength: 500}, txData: soData };
    //     const formData = payload.formData;
    //     const { pushModalToStack } = props;

    //     if (formData.textBoxValue && formData.textBoxValue.length && !payload.existsInData) {
    //         addItemConfirmationModal(props, formData.textBoxValue, soData);
    //     } else {
    //         formData.textBoxValue= soData.statusPRMessage;
    //         const data = {
    //             title: <FormattedMessage id='modal.txDetails.soStatus' defaultMessage='' />,
    //             formData,
    //             modalData: { modalType: MODAL_TYPE.UPDATE_STATUS, data: payload, transactionName: CONSTANTS_TRANSACTIONS.SALES_ORDER },
    //             handleSubmit: (formData = {}) => {
    //                 if (formData.textBoxValue && formData.textBoxValue.length) {
    //                     addItemConfirmationModal(props, formData.textBoxValue, soData);
    //                 } else {
    //                     data.formData.submittedOnce = true;
    //                     pushModalToStack(data);
    //                 }
    //             }
    //         };
    //         pushModalToStack(data);
    //     }
    // }

    // const addItemConfirmationModal = (props, text, soData) => {
    //     const { showModal, companyInfo } = props;
    //     const labelName = <FormattedMessage id='modal.txDetails.soStatus' defaultMessage='' />;
    //     const modalBody = <span>
    //         <FormattedMessage
    //             id='setItem.confirmation'
    //             values={{ value: `${text} to ` }}
    //             defaultMessage=''
    //         />
    //         {labelName} ?
    //   </span>;

    //     let payload = {
    //         statusPRMessage: text,
    //         relationshipId: companyInfo.relationshipId,
    //         salesOrderMasterId: soData.salesOrderMasterId,
    //         customerId: soData.customerId
    //     };

    //     const modalData = {
    //         modalBody,
    //         handleSubmit: () => {
    //            props.updatePRStatus(payload, props);
    //             props.hideModal();
    //             props.popModalFromStack();
    //         },
    //     };
    //     showModal(modalData);
    // }

    const updateUserStatusConfirmationModal = (props, text, color, soData) => {
        const { showModal, companyInfo } = props;
       
        const modalBody = <span>
            <FormattedMessage
                id='setItem.confirmation'
                defaultMessage=''
            />
           
      </span>;

        let payload = {
            userStatus: text,
            userStatusColor: color,
            relationshipId: companyInfo.relationshipId,
            salesOrderMasterId: soData.salesOrderMasterId,
            customerId: soData.customerId
        };

        const modalData = {
            modalBody,
            handleSubmit: () => {
                props.updateUserStatus(payload, props);
                props.hideModal();
                props.popModalFromStack();
            },
        };
        showModal(modalData);
    }

    const updateUserStatus = (props, soData) => {
        let payload = { formData: {}, txData: soData };
        const formData = payload.formData;
        const { pushModalToStack } = props;

        if (formData.textBoxValue && formData.textBoxValue.length && !payload.existsInData) {
            updateUserStatusConfirmationModal(props, formData.textBoxValue, formData.statusColor, soData);
        } else {
            const data = {
                title: <FormattedMessage id='addItem.text.userStatus' defaultMessage='' />,
                formData,
                hideFooter: true,
                modalData: { modalType: MODAL_TYPE.USER_STATUS, data: payload, transactionName: CONSTANTS_TRANSACTIONS.SALES_ORDER },
                handleSubmit: (formData = {}) => {
                    if (formData.textBoxValue && formData.textBoxValue.length) {
                        updateUserStatusConfirmationModal(props, formData.textBoxValue, formData.statusColor, soData);
                    } else {
                        data.formData.submittedOnce = true;
                        pushModalToStack(data);
                    }
                }
            };
            pushModalToStack(data);
        }
    }

    const handleConvertTo = (actionName) => {
        switch(actionName){
            case 'Purchase Request': {
                props.popModalFromStack();
                props.pushModalToStack({
                    modalBody: <StandardPurchaseRequest {...props} customerId={ecomDetail.customerId} customerName= {ecomDetail.customerName} salesOrderMasterId= {ecomDetail.salesOrderMasterId}/>,
                    width: '100%',
                    hideTitle: true,
                    hideFooter: true,
                    wrapClassName: 'new-transaction-wrapper'
                })
                
                // props.history.push({
                //     pathname: '/admin/pr-create',
                //     state: {
                //         customerId: ecomDetail.customerId,
                //         customerName: ecomDetail.customerName,
                //         salesOrderMasterId: ecomDetail.salesOrderMasterId
                //     }
                // })
                break;
            }
            case 'Performa Invoice': {
                props.checkAndConvertToPerformaInvoice(props, ecomDetail)
                break;
            }
            case 'Sales Invoice': {
                // props.popModalFromStack();
                props.canInvoiceBeGenerated(ecomDetail, props, [ecomDetail.salesOrderMasterId]);
                // props.history.push({
                //     pathname: ecomDetail.isMultiCurrency ? 'sales-invoice-multi-currency' : '/admin/sales-invoice',
                //     state: {
                //         customerId: ecomDetail.customerId,
                //         soMasterId: ecomDetail.salesOrderMasterId,
                //         soMasterIdArr: ecomDetail.salesOrderMasterId
                //     }
                // })
                break;
            }
            case 'Package': {
                props.popModalFromStack();
                props.pushModalToStack({
                    modalBody: <StandardPackage {...props} connected={true} customerId={ecomDetail.customerId} soMasterId= {ecomDetail.salesOrderMasterId} />,
                    width: '100%',
                    hideTitle: true,
                    hideFooter: true,
                    wrapClassName: 'new-transaction-wrapper'
                })
                // props.history.push({
                //     pathname: '/admin/so/package',
                //     state: {
                //         customerId: ecomDetail.customerId,
                //         soMasterId: ecomDetail.salesOrderMasterId
                //     }
                // })
                break;
            }
              default: {
                break;
              }
        }

    }
    return (
        <div className={"custom-modal show"}>
            
            <StandardTemplate {...props} /> 

            <div className="modal-dialog">
                <div className="modal-content full-width">
                    
                <div className=" button-group-action header-control"> 
                                   { props.isConvertable? <Button style={{height:'38px'}}
                                        type="primary"
                                    >
                                        <div onClick={() => {
                                           
                                            props.popModalFromStack();
                                        
                                       
                                            props.pushModalToStack({
                                                modalBody: <StandardSalesOrder {...props} customerId={props.ecomDetail.customerId} ecomdata={true} ecomDetailsData={ecomDetail}  />,
                                                width: '100%',
                                                hideTitle: true,
                                                hideFooter: true,
                                                wrapClassName: 'new-transaction-wrapper',keyboard:false
                                            })
                                        }}
                                       
                                        >
                                            <FormattedMessage id='modal.txDetails.salesQuote.convertToSO' defaultMessage='' />
                            </div>
                                    </Button>:""}
                                    {/* // : '' */}
                                
                </div>
                   
                </div>
            </div>
        </div>
    );

}


const getTxTypeData = (row, type, toReturn) => {
    if (!row || !row.customerSalesOrderCOATxList) return;
    let data = row.customerSalesOrderCOATxList.find(tx => (tx.txType === type));
    if (data) {
        if (data[toReturn] && toReturn === 'amountPercent') {
            if (type === 'VAT') {
                return "VAT(" + data[toReturn] + "%)";
            }
            return "(" + data[toReturn] + "%)";
        } else if (data[toReturn] && toReturn === 'amount') {
            return (fixedDecimalNumber(data[toReturn]));
        } else {
            return ""
        }

    } else if (!data && toReturn === 'amount') {
        return "";
    }
    else {
        return "-";
    }
}

const calculateTaxRateList = (customerSalesOrderDetailsList, isPlaceOfSupplySameAsRelationship) => {
    let subtotal = 0;
    let totalDiscount = 0;
    let totalTax = 0;
    let itemLevelTaxes = []

    customerSalesOrderDetailsList.forEach(function (rowdata, index) {
        let totalAmountOfColumn = 0;
        if (rowdata.quantityToInvoice && rowdata.anItemSalePrice) {
            let taxObj = {
                subtotal: 0,
                taxPercent: 0,
                taxAmount: 0
            }
            totalAmountOfColumn = rowdata.quantityToInvoice * rowdata.anItemSalePrice;
            let discount = getTxTypeData(rowdata, 'discount', 'amount');
            if (discount) {
                totalAmountOfColumn = totalAmountOfColumn - discount;
                totalDiscount = totalDiscount + discount;
            }

            let tax1 = rowdata.customerSalesOrderCOATxList.find(tx => (tx.txType === 'tax')) || {};
           // let tax2 = rowdata.customerSalesOrderCOATxList.find(tx => (tx.txType === 'sgst'));
            //let tax3 = rowdata.customerSalesOrderCOATxList.find(tx => (tx.txType === 'igst'));
           // let tax4 = rowdata.customerSalesOrderCOATxList.find(tx => (tx.txType === 'vat'));
            // if (isPlaceOfSupplySameAsRelationship && tax1 && tax2) {
            //     taxObj.taxPercent = Number(tax1.amountPercent) + Number(tax2.amountPercent);
            //     taxObj.taxAmount = Number(tax1.amount) + Number(tax2.amount);
            //     totalTax = totalTax + taxObj.taxAmount;
            // }
            // else if (!isPlaceOfSupplySameAsRelationship && tax3) {
            //     taxObj.taxPercent = Number(tax3.amountPercent);
            //     taxObj.taxAmount = Number(tax3.amount);
            //     totalTax = totalTax + taxObj.taxAmount;
            // }
            // else if (tax4) {
            //     taxObj.taxPercent = Number(tax4.amountPercent);
            //     taxObj.taxAmount = Number(tax4.amount);
            //     totalTax = totalTax + taxObj.taxAmount;
            // }
            taxObj.taxPercent = Number(tax1.amountPercent);
            taxObj.subtotal = Number(fixedDecimalNumber(totalAmountOfColumn));
            taxObj.taxName = tax1.taxName;
            taxObj.taxAmount = Number(fixedDecimalNumber(tax1.amount))
            totalTax = totalTax + taxObj.taxAmount;
            if (taxObj.taxPercent) {
                taxObj.subtotal = Number(fixedDecimalNumber(totalAmountOfColumn));
                const availableTaxObj = find(itemLevelTaxes, { taxPercent: taxObj.taxPercent });
                if (availableTaxObj) {
                    itemLevelTaxes = filter(itemLevelTaxes, (ele) => {
                        if (ele.taxPercent === taxObj.taxPercent) {
                            ele.subtotal = ele.subtotal + taxObj.subtotal;
                            ele.taxAmount = ele.taxAmount + taxObj.taxAmount;
                        }
                        return true;
                    });
                } else {
                    taxObj.subtotal = Number(fixedDecimalNumber(taxObj.subtotal || 0));
                    itemLevelTaxes.push(taxObj);
                }
            }
            subtotal = subtotal + totalAmountOfColumn;
        }
    });

    return itemLevelTaxes;
}

const updateColumnEmptyCheck = (isColumnEmpty, ecomDetail, props) => {
    if (!ecomDetail) return isColumnEmpty;
    for (let i in ecomDetail.customerSalesOrderDetailsList) {
        let row = ecomDetail.customerSalesOrderDetailsList[i];
        if (row.itemName) { isColumnEmpty.product = false; };
        if (row.serviceName) { isColumnEmpty.service = false; };
        if (row.itemVariantName) { isColumnEmpty.variant = false; }
        if (row.description) { isColumnEmpty.description = false; }
        if (row.quantityToInvoice) { isColumnEmpty.qty = false; }
        if (row.uomName) { isColumnEmpty.uomName = false; }
        if (row.anItemSalePrice) { isColumnEmpty.rate = false; }
        if (row.specialDiscount) { isColumnEmpty.specialDiscount = false; }
        if (row.hsnCode && props.companyInfo.countryName === 'India') { isColumnEmpty.hsn = false; }
        if (!isTxTypeEmpty(row, 'discount')) { isColumnEmpty.discount = false; }
        if (!isTxTypeEmpty(row, 'tax')) { isColumnEmpty.tax = false; }
       // if (!isTxTypeEmpty(row, 'sgst')) { isColumnEmpty.tax = false; }
       // if (!isTxTypeEmpty(row, 'igst')) { isColumnEmpty.tax = false; }
      //  if (!isTxTypeEmpty(row, 'vat')) { isColumnEmpty.tax = false; }
        if (row.documentDetails && row.documentDetails.length) { isColumnEmpty.documentDetails = false; }
       // if (row.comment) { isColumnEmpty.comment = false; }
    }
    return isColumnEmpty;
}

const isTxTypeEmpty = (row, type) => {
    // if (!row) return true;
    // let data = row.customerSalesOrderCOATxList.find(tx => (tx.txType === type));
    // if (data && data.amount) {
    //     return false;
    // }
    // else {
    //     return true;
    // }
    return true;
}

export default EcomDetails;
